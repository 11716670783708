import { connect } from 'react-redux';

import userActionCreators from '_store/_actions/user';
import Profile from './Profile';

const mapStateToProps = (state) => ({
  isLoading: state.user.isLoading,
  user: state.user.user,
});

const mapDispatchToProps = {
  onFetchUser: userActionCreators.getUserProfile,
  onUpdateUser: userActionCreators.updateUserProfile,
  onAvatarUpload: userActionCreators.uploadAvatar,
  onAvatarDelete: userActionCreators.deleteAvatar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
