import * as yup from 'yup';
import { ptShort } from 'yup-locale-pt';

yup.setLocale(ptShort);

const OneDebitOneCreditSchema = yup.object().shape({
  entry_type: yup.mixed().oneOf(['1D1C', '1DVC', '1CVD', 'VCVD']).required(),
  debit_account_id: yup.string().required().nullable(),
  credit_account_id: yup.string().required().nullable(),
  event_date: yup.date().required().nullable(),
  narration: yup.string().required().nullable(),
  amount: yup.number().min(0.01).required().nullable(),
});

export default OneDebitOneCreditSchema;
