import constants from '../../_constants/invoices.constants';

const clearInvoices = () => {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return {
      type: constants.CLEAR_INVOICES,
      payload: {},
    };
  }
};

export default clearInvoices;
