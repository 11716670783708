import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import companyActionCreators from '_store/_actions/company';
import receiptActionsCreators from '_store/_actions/receipt';

import TransactionReceipt from './TransactionReceipt';

const mapStateToProps = (state) => ({
  transaction: state.companyTransactions.transaction,
  activeCompany: state.company.activeCompany,
  receipts: state.receipts.receipts,
  user: state.auth.user,
});

const mapDispatchToProps = {
  onFetchTransaction: companyTransactionsActionCreators.fetchTransaction,
  onFetchActiveCompany: companyActionCreators.getActiveCompany,
  onFetchAllReceipts: receiptActionsCreators.fetchAllReceipts,
  onFetchContact: companyTransactionsActionCreators.fetchContactById,
  onFindInstalmentsTransactions: companyTransactionsActionCreators.fetchInstalmentsTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionReceipt);
