import { connect } from 'react-redux';

import employeeActionCreators from '_store/_actions/employees';

import Salaries from './Salaries';

const mapStateToProps = (state) => ({
  employeeSalaries: state.employees.employeeSalaries,
});

const mapDispatchToProps = {
  onFetchEmployeeSalaries: employeeActionCreators.getEmployeeSalaries,
  onAddEmployeeSalary: employeeActionCreators.addEmployeeSalary,
  onUpdateEmployeeSalary: employeeActionCreators.updateEmployeeSalary,
  onDeleteEmployeeSalary: employeeActionCreators.deleteEmployeeSalary,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Salaries);
