import constants from '../../_constants/invoices.constants';
import service from '../../../services/invoices.service';
import alertActionCreators from '../alert.actions';

const generateInvoiceEntries = (params, callback) => {
  return (dispatch) => {
    dispatch(request());

    service.generateInvoiceEntries(params).then(
      (response) => {
        const { data } = response;

        dispatch(success(data));
        dispatch(alertActionCreators.success('Notas Fiscais enviadas para o módulo Contábil!'));

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.GENERATE_INVOICE_ENTRIES_REQUEST,
      payload: { },
    };
  }
  function success(data) {
    return {
      type: constants.GENERATE_INVOICE_ENTRIES_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.GENERATE_INVOICE_ENTRIES_FAILURE,
      payload: error,
    };
  }
};

export default generateInvoiceEntries;
