import styled, { css } from 'styled-components';

export const StyledDiv = styled.div`
  justify-content: space-around;
`;

export const SignatureDiv = styled.div`
  width: 40%;
  margin-top: 80px;
  text-align: center;
  align-items: center;
`;

export const StyledHr = styled.hr`
  width: 100%;
  border-color: #354168;
`;

export const StyledP = styled.p`
  font-size: 1em;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: center;
  align-items: center;

  ${({ smallMargin }) => smallMargin && css`
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  `}
`;
