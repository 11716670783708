import HttpService from './http.service';

export default {
  getAll,
  getById,
  insert,
  insertMany,
  update,
  remove,
  removeMany,
  updateMany,
};

async function getAll(params) {
  return HttpService.get('/entries', params);
}

async function getById(id) {
  return HttpService.get(`/entries/${id}`);
}

async function insert(entry) {
  return HttpService.post('/entries', entry);
}

async function insertMany(entries) {
  return HttpService.post('/multiple-entries', entries);
}

async function update(id, entry) {
  return HttpService.put(`/entries/${id}`, entry);
}

async function remove(id) {
  return HttpService.delete(`/entries/${id}`);
}

async function removeMany(params) {
  return HttpService.delete('/multiple-entries', params);
}

async function updateMany(params) {
  return HttpService.put('/multiple-entries', params);
}
