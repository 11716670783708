import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Tabs } from '_components/_core';

import { CalculatedDarfs, CalculatedTaxes } from './components';

function LucroPresumido({
  activeCompany,
  selectedDate,
  taxes,
  darfs,
  onCalculateTaxes,
  onFetchTaxes,
  onFetchDarfs,
  onDeleteDarf,
  onDeleteMultipleDarfs,
  isLoading,
}) {
  useEffect(() => {
    onFetchDarfs(selectedDate.toFormat('yyyy'));
  }, [onFetchDarfs, selectedDate]);

  const getTabs = useCallback(() => {
    const props = {
      activeCompany,
      selectedDate,
      taxes,
      darfs,
      onCalculateTaxes,
      onFetchTaxes,
      onFetchDarfs,
      onDeleteDarf,
      onDeleteMultipleDarfs,
      isLoading,
    };

    return [
      {
        id: 1,
        title: 'Apuração',
        content: <CalculatedTaxes {...props} />,
      },
      {
        id: 2,
        title: 'Guias',
        content: <CalculatedDarfs {...props} />,
      },
    ];
  }, [
    activeCompany,
    selectedDate,
    taxes,
    darfs,
    onCalculateTaxes,
    onFetchTaxes,
    onFetchDarfs,
    onDeleteDarf,
    onDeleteMultipleDarfs,
    isLoading,
  ]);

  return (
    <Row>
      <Col>
        <Tabs
          variant="secondary"
          tabs={getTabs()}
        />
      </Col>
    </Row>
  );
}

LucroPresumido.defaultProps = {
  taxes: [],
  darfs: [],
  isLoading: false,
};

LucroPresumido.propTypes = {
  activeCompany: PropTypes.object,
  taxes: PropTypes.array,
  darfs: PropTypes.array,
  selectedDate: PropTypes.any,
  onFetchTaxes: PropTypes.func.isRequired,
  onFetchDarfs: PropTypes.func.isRequired,
  onDeleteDarf: PropTypes.func.isRequired,
  onDeleteMultipleDarfs: PropTypes.func.isRequired,
  onCalculateTaxes: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default LucroPresumido;
