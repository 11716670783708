import styled from 'styled-components';

import Tag from '_components/_core/Tag/Tag';

export const StyledTag = styled(Tag)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border: 1px solid #e5e5e5;
  background-color: #F2F8F1;
  border: 1px solid #C4DFC0;
  color: #0D3804;

  span:first-child {
    font-weight: 700;
    font-size: 16px;
  }
`;

export const StyledContent = styled.div`

`;

export const StyledName = styled.span`
  font-weight: 700;
  color: #38A915;  
`;

export const StyledText = styled.p`
  font-weight: 400;
  margin-top: 2px;
  margin-bottom: 0;
  white-space: initial;
`;
