import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';

const fetchUserPermissions = (id, callback) => {
  return (dispatch) => {
    dispatch(request(id));

    service.fetchUserPermissions(id).then(
      (response) => {
        const userPermissions = response.data;

        dispatch(success(userPermissions));

        if (callback) {
          callback(userPermissions);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(id) {
    return {
      type: constants.FETCH_USER_PERMISSIONS_REQUEST,
      payload: {
        id,
      },
    };
  }
  function success(userPermissions) {
    return {
      type: constants.FETCH_USER_PERMISSIONS_SUCCESS,
      payload: userPermissions,
    };
  }
  function failure(message) {
    return {
      type: constants.FETCH_USER_PERMISSIONS_FAILURE,
      payload: { message },
    };
  }
};

export default fetchUserPermissions;
