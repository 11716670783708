import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import FORMATTERS from 'helpers/formatters';
import { Card, CardHeader, CardBody } from '_components/_core';

import { StyledDescription } from '../../styles';

function BasicInfo({ company }) {
  const {
    company_name,
    trading_name,
    document_number,
    email,
    phone_number,
    municipal_registration,
    date_founded,
    client_since,
    status_irs,
    tax_regime,
  } = company || {};

  return (
    <Card className="mb-3">
      <CardHeader title="Informações Cadastrais" />
      <CardBody>
        <Form>
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>CNPJ</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.CNPJ(document_number)}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Data de Abertura</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.DATE_DDMMYYYY(date_founded)}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Cliente Desde</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.DATE_DDMMYYYY(client_since)}
                </StyledDescription>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Razão Social</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.UPPERCASE(company_name)}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Nome Fantasia</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.UPPERCASE(trading_name)}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Inscrição Municipal</Form.Label>
                <StyledDescription column>
                  {municipal_registration}
                </StyledDescription>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>E-mail</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.LOWERCASE(email)}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Telefone</Form.Label>
                <StyledDescription column>
                  {phone_number}
                </StyledDescription>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Regime de Tributação</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.TAX_REGIME(tax_regime)}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Situação RFB</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.STATUS_IRS(status_irs)}
                </StyledDescription>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}

BasicInfo.propTypes = {
  company: PropTypes.object.isRequired,
};

export default BasicInfo;
