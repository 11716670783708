import styled from 'styled-components';

export const CertificateItem = styled.div`
  padding: 16px 24px;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #f9f9fd;
`;

export const FileExtension = styled.div`
  margin-right: 24px;
`;

export const CertificateName = styled.div`
  font-weight: 700;
  margin-right: 36px;
`;

export const CertificateExpireDate = styled.p`
  margin-bottom: 0;
`;
