import { connect } from 'react-redux';

import authSelectors from '_store/_selectors/auth';
import reportsActionCreators from '_store/_actions/reports';
import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import CashflowSemester from './CashflowSemester';

const mapStateToProps = (state) => ({
  user: authSelectors.currentUserSelector(state),
  revenue: state.reports.revenue,
  isLoading: state.reports.isLoading,
  activeCompany: state.company.activeCompany,
  semesterOverview: state.companyTransactions.semesterOverview,
  selected_account_id: state.companyTransactions.selected_account_id,
  accounts: state.companyTransactions.accounts,
  selectedAccountIds: state.companyTransactions.selected_account_ids,
});

const mapDispatchToProps = {
  onFetchRevenueReport: reportsActionCreators.getRevenueReport,
  onFetchCashflow: companyTransactionsActionCreators.fetchCashflow,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CashflowSemester);
