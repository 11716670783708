import styled, { css } from 'styled-components';
import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  MenuItem,
  SubMenu,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

export const Container = styled.nav`
  max-width: 88px;
  position: fixed;
  z-index: 994 !important;

  @media (max-width: 768px) {    
    ${({ toggled }) => toggled && css`
      left: 0;
    `} 
    
    ${({ toggled }) => !toggled && css`
      left: -88px;
    `}      
  }
`;

export const NavbarContent = styled.div`
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  background-color: var(--color-sidebar-bg);
  box-shadow: var(--sidebar-navbar-box-shadow);
  padding: 68px 16px 0px;
  will-change: max-width;
  transition: max-width 0.2s ease 0s;
  white-space: nowrap;

  position: sticky;
  top: 0px;

  ${({ isHovering, isMobile }) => isHovering && !isMobile && css`
    max-width: 224px;

    p {
      transition: max-width 0.1s ease 0s, opacity 0.1s ease 0s;
    }    
  `}

  @media (max-width: 768px) {
    ${({ toggled }) => toggled && css`
      max-width: 224px;

      p {
        transition: max-width 0.1s ease 0s, opacity 0.1s ease 0s;
      }
    `}
  }
 
`;

export const MainMenu = styled.ul`
  margin: 16px 0px 0px;
  padding: 0px;
  list-style: none;
`;

export const LowerMenu = styled.ul`
  margin: 62px 0px 0px;
  padding: 0px 0px 24px;
  list-style: none;
  position: sticky;
  top: calc(100vh - 0px);
`;

export const LogoContainer = styled.a`
  padding: 0px 12px;
  margin: 0px;
  overflow: hidden;
  display: block;
`;

export const MenuItemV2 = styled.li`
  padding: 16px;
  width: 100%;
  color: var(--title-color);
  border-radius: 12px;

  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  ${({ isActive }) => isActive && css`
    background-color: #f5f5f5;
    cursor: pointer;
  `}

  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.4rem;
    width: 100%;
    position: relative;
    text-decoration: none;
    color: var(--title-color);

    div {
      display: flex;
      margin-right: 16px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    p {
      max-width: 500px;
      will-change: max-width, opacity;
      overflow: hidden;
      margin: 0;
      font-weight: 400;
      font-size: 14px;
    }
  }

  ${({ isMobile }) => isMobile && css`
    padding: 0;
    margin: 0;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: yellow; */
    line-height: 0;
    ${({ isActive }) => isActive && css`
      background-color: transparent !important;
      cursor: pointer;
    `}
    &:hover {
      background-color: transparent;
      cursor: pointer;
    }
    margin-bottom: 0px !important;
    a {
      width: auto;
      display: block;
      div {
        margin-right: 0px;
      }
    }
    p {
      display: none;      
    }
  `}

`;

export const StyledProSidebar = styled(ProSidebar)`
  padding-top: 66px;
  position: fixed !important;
  border-right: var(--sidebar-border-right);
  box-shadow: var(--sidebar-navbar-box-shadow);
  z-index: 994 !important;
  min-width: var(--sidebar-size) !important;
  width: var(--sidebar-size) !important;
  background-color: var(--color-sidebar-bg);

  ${({ collapsed }) => collapsed && css`
    &&& {
      .pro-sidebar .pro-menu .pro-menu-item .pro-inner-item .pro-icon-wrapper {
        margin-left: 10px !important;
      }
    }
  `}  
`;

export const StyledSidebarHeader = styled(SidebarHeader)`
  padding: 26px;
  padding-top: 31px;
  background-color: var(--color-sidebar-bg);
`;

export const StyledSidebarContent = styled(SidebarContent)`
  background-color: var(--color-sidebar-bg);
`;

export const StyledSidebarFooter = styled(SidebarFooter)`
  background-color: var(--color-sidebar-bg);
`;

export const StyledMenuItem = styled(MenuItem)`
  padding: 4px 12px 4px 12px;
  
  span {
    color: var(--color-text-primary);
    font-weight: 500;
  }

  div.pro-inner-item {
    padding: 5px !important;
  }

  &:hover {
    span {
      color: var(--accent-color);
      transition: color .3s;
    }

    svg {
      color: var(--accent-color);
    }
  }

  ${({ active }) => active && css`
    svg {
      color: var(--accent-color);
    }

    .pro-inner-item {
      span {
        color: var(--accent-color);
      } 
    }
  `}
`;

export const StyledSubMenu = styled(SubMenu)`
   padding: 4px 12px 4px 12px;

  span {
    color: var(--color-text-primary);
    font-weight: 500;
  }

  div.pro-inner-item {
    padding: 5px !important;
  }

  div.react-slidedown {
    ul {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  ${({ isActiveParent, level }) => isActiveParent && level === 1 && css`

   > .pro-inner-item {      
      svg {
        color: #464de4;
      }

      span {
        color: var(--accent-color);
      }      
    }
  `}

  div.pro-inner-list-item {
    background-color: var(--color-sidebar-bg) !important;
  }

  div.popper-inner {
    margin-left: -3px;
    margin-top: -3px;
    background-color: var(--color-sidebar-bg) !important;
    box-shadow: 0 0 0 1px rgba(89, 105, 129, 0.1),
                0 3px 20px 0 rgba(89, 105, 129, 0.3), 
                0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .pro-item-content {
    &:hover {
      color: var(--accent-color);
    }
  }
`;

export const TabBar = styled.div`
  background-color: var(--color-sidebar-bg);
  border-top: var(--sidebar-border-bottom);
  box-shadow: var(--sidebar-navbar-box-shadow);
  z-index: 9 !important;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  transition: all 0.3s ease 0s;
  border-top: 1px solid #e8e8e8;
`;

export const ItemsContainer = styled.ul`
  margin: 0px;
  padding-left: 0px;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  height: 60px;
`;

export const Spacer = styled.div`
  flex: 1;
  height: 10px;
`;
