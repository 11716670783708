import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';

import { Button, FormCurrencyField, FormDateField } from '_components/_core';
import { EmployeeSalarySchema } from '../../utilities';

import { StyledModal, ModalFooter } from './styles';

export default function AddEmployeeSalaryModal({
  isVisible,
  selectedSalary,
  onModalToggle,
  onAddEmployeeSalary,
  onUpdateEmployeeSalary,
  employeeId,
}) {
  const handleAddEditEmployeeSalary = useCallback((values, { resetForm }) => {
    if (selectedSalary) {
      onUpdateEmployeeSalary(
        employeeId,
        selectedSalary.id,
        values,
      );
    } else {
      onAddEmployeeSalary(employeeId, values);
    }

    onModalToggle();
    resetForm();
  }, [employeeId, selectedSalary, onUpdateEmployeeSalary, onAddEmployeeSalary, onModalToggle]);

  const renderModalFooter = useCallback((handleSubmit, isValid) => (
    <ModalFooter>
      <Button variant="secondary" onClick={onModalToggle}>
        Cancelar
      </Button>
      <Button type="submit" variant="primary" onClick={handleSubmit} disabled={!isValid}>
        Salvar
      </Button>
    </ModalFooter>
  ), [onModalToggle]);

  return (
    <Formik
      initialValues={{
        id: selectedSalary ? selectedSalary.id : null,
        salary: selectedSalary ? selectedSalary.salary : '',
        start_date: selectedSalary ? selectedSalary.start_date : '',
      }}
      validationSchema={EmployeeSalarySchema}
      onSubmit={handleAddEditEmployeeSalary}
      enableReinitialize
    >
      {({ handleSubmit, isValid }) => (
        <>
          <StyledModal
            title={selectedSalary ? 'Editar Salário' : 'Cadastrar novo Salário'}
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit, isValid)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="3">
                  <Form.Label>Data inicial</Form.Label>
                  <FormDateField name="start_date" placeholder="Data Inicial" />
                </Form.Group>
                <Form.Group as={Col} md="9">
                  <Form.Label>Salário do Funcionário</Form.Label>
                  <FormCurrencyField name="salary" placeholder="Salário do Funcionário" />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
}

AddEmployeeSalaryModal.defaultProps = {
  isVisible: false,
  selectedSalary: null,
};

AddEmployeeSalaryModal.propTypes = {
  employeeId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  selectedSalary: PropTypes.object,
  onModalToggle: PropTypes.func.isRequired,
  onAddEmployeeSalary: PropTypes.func.isRequired,
  onUpdateEmployeeSalary: PropTypes.func.isRequired,
};
