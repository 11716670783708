import React from 'react';

function AlwaysLastDayHelpText() {
  return (
    <p>
      Esta opção irá manter as datas dos
      lançamentos selecionados, alterando somente o dia para o
      {' '}
      <strong>último</strong>
      {' '}
      dia do mês.
      <br />
      <br />
      Exemplos:
      <br />
      <ul className="mt-2 mb-0">
        <li>
          Lançamento com a data
          {' '}
          <strong>15/01/2021</strong>
          {' '}
          será alterado para
          {' '}
          <strong>31/01/2021</strong>
        </li>
        <li>
          Lançamento com a data
          {' '}
          <strong>25/01/2021</strong>
          {' '}
          será alterado para
          {' '}
          <strong>31/01/2021</strong>
        </li>
        <li>
          Lançamento com a data
          {' '}
          <strong>13/02/2021</strong>
          {' '}
          será alterado para
          {' '}
          <strong>28/02/2021</strong>
        </li>
      </ul>
    </p>
  );
}

export default AlwaysLastDayHelpText;
