import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import isEmpty from 'lodash/isEmpty';
import { Formik } from 'formik';
import { IoTrash } from 'react-icons/io5';
import { FaDownload } from 'react-icons/fa';

import FORMATTERS from 'helpers/formatters';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Dropzone,
  Button,
} from '_components/_core';

import {
  CertificateItem,
  CertificateName,
  CertificateExpireDate,
} from './styles';

function DigitalCertificate({
  company,
  isLoading,
  onAddDigitalCertificate,
  onDeleteDigitalCertificate,
  onCompanyInfoChange,
}) {
  const { certificate_name, certificate_expire_date } = company || {};

  const hasCertificate = useMemo(() => {
    const { certificate_url, certificate_password } = company || {};

    return !isEmpty(certificate_url) && !isEmpty(certificate_password);
  }, [company]);

  const handleFilesChanged = useCallback((files, setFieldValue) => {
    if (isEmpty(files)) {
      return;
    }

    const [certificateFile] = files || [];

    const { name, type } = certificateFile;

    setFieldValue('certificate_file', certificateFile);
    setFieldValue('certificate_file_name', name);
    setFieldValue('certificate_file_type', type);
  }, []);

  const handleSubmit = useCallback((values, { resetForm }) => {
    const { id: company_id } = company || {};

    const formData = new FormData();

    formData.append('certificate_file', values.certificate_file);
    formData.append('certificate_password', values.certificate_password);

    onAddDigitalCertificate(company_id, formData, (updatedCompany) => {
      resetForm();
      onCompanyInfoChange(updatedCompany);
    });
  }, [
    company,
    onAddDigitalCertificate,
    onCompanyInfoChange,
  ]);

  const handleDelete = useCallback(() => {
    const { id: company_id } = company || {};

    onDeleteDigitalCertificate(company_id, (updatedCompany) => {
      onCompanyInfoChange(updatedCompany);
    });
  }, [company, onDeleteDigitalCertificate, onCompanyInfoChange]);

  const handleDownload = useCallback(() => {
    const { certificate_url } = company || {};

    window.open(certificate_url, '_blank');
  }, [company]);

  return (
    <Formik
      initialValues={{
        certificate_file: null,
        certificate_file_name: null,
        certificate_file_type: null,
      }}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnMount
    >
      {({
        handleSubmit,
        values,
        handleChange,
        setFieldValue,
      }) => (
        <>
          <Card className="mt-3">
            <CardHeader
              title="Certificado Digital A1"
              description="Faça o upload do certificado digital A1 da empresa, necessário para buscar notas, acessar o e-CAC, e outras funções."
            />
            <CardBody noPaddingTop>
              {!hasCertificate && (
                <Form>
                  <Form.Row>
                    <Col xs lg="4">
                      <Form.Group>
                        <Form.Label>Enviar arquivo</Form.Label>
                        <Dropzone onChange={(files) => handleFilesChanged(files, setFieldValue)} accept=".pfx, .p12, application/x-pkcs12" maxFiles={1} />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs lg="3">
                      <Form.Group>
                        <Form.Label>Senha do Certificado</Form.Label>
                        <Form.Control
                          name="certificate_password"
                          placeholder="Senha do Certificado"
                          onChange={handleChange}
                          value={values.certificate_password}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs lg="3">
                      <Form.Group>
                        <Form.Label>Confirmar Senha do Certificado</Form.Label>
                        <Form.Control
                          name="certificate_password_confirmation"
                          placeholder="Confirmar Senha do Certificado"
                          onChange={handleChange}
                          value={values.certificate_password_confirmation}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </Form>
              )}
              {hasCertificate && (
                <>
                  <CertificateItem>

                    <div>
                      <CertificateName>
                        {certificate_name}
                      </CertificateName>
                      <CertificateExpireDate>
                        Expira em:
                        {' '}
                        {FORMATTERS.DATE_DDMMYYYY(certificate_expire_date)}
                      </CertificateExpireDate>
                    </div>
                    <div>
                      <Button
                        noMargin
                        icon={<FaDownload />}
                        variant="inverse-primary"
                        onClick={handleDownload}
                        className="mr-3"
                      />
                      <Button
                        noMargin
                        icon={<IoTrash />}
                        variant="inverse-danger"
                        onClick={handleDelete}
                      />
                    </div>
                  </CertificateItem>
                </>
              )}
            </CardBody>
            <CardFooter className="d-flex justify-content-between">
              <Button
                isLoading={isLoading}
                disabled={hasCertificate}
                onClick={handleSubmit}
              >
                Salvar certificado A1
              </Button>
            </CardFooter>
          </Card>
        </>
      )}
    </Formik>

  );
}

export default DigitalCertificate;

DigitalCertificate.defaultProps = {
  isLoading: false,
  company: null,
};

DigitalCertificate.propTypes = {
  isLoading: PropTypes.bool,
  company: PropTypes.object,
  onAddDigitalCertificate: PropTypes.func.isRequired,
  onDeleteDigitalCertificate: PropTypes.func.isRequired,
  onCompanyInfoChange: PropTypes.func.isRequired,
};
