import React from 'react';
import PropTypes from 'prop-types';

import { Skeleton } from './styles';

function CustomSkeleton({
  width,
  height = '18px',
  variant,
  className,
}) {
  return (
    <Skeleton
      width={width}
      height={height}
      variant={variant}
      className={className}
    />
  );
}

CustomSkeleton.defaultProps = {
  width: '100%',
  height: '18px',
  variant: 'row',
  className: null,
};

CustomSkeleton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default CustomSkeleton;
