import styled from 'styled-components';
import { CSVLink } from 'react-csv';

export const DownloadButton = styled(CSVLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  font-weight: normal;
`;
