import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';

import { ZENPAPER_VIDEOS } from 'helpers';
import { PageHeader } from '_components/_core';
import { VideoTutorialTag } from '_components/_shared';
import { BankStatementUpload } from './components';

export default function Import({
  onFetchBankStatementTransactions,
  activeCompany,
}) {
  useEffect(() => {
    onFetchBankStatementTransactions();
  }, [onFetchBankStatementTransactions, activeCompany]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title={(
          <div className="d-flex justify-content-center align-items-center">
            Importar Extrato Bancário
            <VideoTutorialTag text="VÍDEO TUTORIAL" link={ZENPAPER_VIDEOS.BANK_STATEMENT_UPLOAD} />
          </div>
        )}
        description="Faça importação de extratos bancários no formato OFX"
        variant="small"
      />
      <BankStatementUpload />
    </Container>
  );
}

Import.propTypes = {
  activeCompany: PropTypes.object,
  onFetchBankStatementTransactions: PropTypes.func.isRequired,
};
