import HttpService from './http.service';

export default {
  parse,
  getAll,
  removeMany,
  updateMany,
};

async function parse(bankStatements) {
  return HttpService.post('/bank-statement-entries', bankStatements);
}

async function getAll(params) {
  return HttpService.get('/bank-statement-entries', params);
}

async function removeMany(params) {
  return HttpService.delete('/bank-statement-entries', params);
}

async function updateMany(params) {
  return HttpService.put('/bank-statement-entries', params);
}
