import styled from 'styled-components';

import Modal from '_components/_core/Modal';

export const StyledModal = styled(Modal)`
  padding: 0 !important;

  div.modal-body {
    max-height: 60vh;
    overflow-y: auto;
  }

  div.modal-footer {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableContainer = styled.div`

`;

export const CompanyInfo = styled.td`
  display: flex;
  flex-direction: column;
`;

export const CompanyName = styled.span`
  font-weight: bold;
  padding-bottom: 5px;
`;

export const DocumentNumber = styled.span``;

export const Table = styled.table`
  background-color: #fcfdfe;

  thead {
    th {
      padding: 15px;
    }
  }

  tbody {
    tr {
      border-bottom: solid 1px #d5dcec;
      &:hover {
        cursor: pointer;
      }
    }
    td {
      padding: 15px 15px;
      white-space: normal !important;
    }
  }
`;

export const SearchContainer = styled.div`
  padding: 15px;
  border-bottom: solid 1px #d5dcec;
`;
