import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Formik } from 'formik';
import { IoArrowForward } from 'react-icons/io5';

import {
  Button,
  FormTextField,
  FormCpfCnpjField,
  FormPasswordField,
  FormMaskedInput,
  Select,
} from '_components/_core';
import FORMATTERS from 'helpers/formatters';

import { signUpFormSchema } from '../../utilities/validationSchema';
import { SearchButton, SectionHeader } from './styles';

const PLACEHOLDERS = {
  document_type_PF: 'CPF',
  document_type_PJ: 'CNPJ',
  first_name_PF: 'Primeiro nome',
  first_name_PJ: 'Razão Social',
  last_name_PF: 'Sobrenome',
  last_name_PJ: 'Nome Fantasia',
};

function BasicInfo({
  isLoading,
  isLoadingCep,
  onSignUp,
  onFetchCep,
  onFetchCities,
  onFetchStates,
  states,
  cities,
}) {
  const handleCreateAccount = useCallback((values) => {
    const user = {
      ...values,
      document_number: FORMATTERS.CLEAN_CPF_CNPJ(values.document_number),
    };

    onSignUp(user);
  }, [onSignUp]);

  const handleCepSearch = useCallback(async (values, setFieldValue) => {
    onFetchCep(values.address_zip_code, (address) => {
      const {
        bairro,
        complemento,
        localidade,
        logradouro,
        uf,
        ibge,
        ddd,
      } = address;

      setFieldValue('address_street', logradouro);
      setFieldValue('address_number', null);
      setFieldValue('address_complement', complemento);
      setFieldValue('address_district', bairro);
      setFieldValue('address_state', uf);
      setFieldValue('address_state_ibge', Number(ddd));
      onFetchCities(Number(ddd), () => {
        setFieldValue('address_city_ibge', Number(ibge));
        setFieldValue('address_city', localidade);
      });
    });
  }, [onFetchCep, onFetchCities]);

  const handleStateIbgeChange = useCallback((option, setFieldValue) => {
    setFieldValue('address_city', null);
    setFieldValue('address_city_ibge', null);
    setFieldValue('address_state', option.sigla);
    setFieldValue('address_state_ibge', Number(option.value));

    onFetchCities(option.value);
  }, [onFetchCities]);

  const handleCityIbgeChange = useCallback((option, setFieldValue) => {
    setFieldValue('address_city_ibge', option.value);
    setFieldValue('address_city', option.label);
  }, []);

  useEffect(() => {
    onFetchStates();
  }, [onFetchStates]);

  const getSelectedState = useCallback((values) => {
    if (!values.address_state_ibge) {
      return null;
    }

    return states.find((state) => state.value === Number(values.address_state_ibge));
  }, [states]);

  const getSelectedCity = useCallback(
    (values) => {
      if (!values.address_city_ibge) {
        return null;
      }

      return cities.find((city) => Number(city.value) === Number(values.address_city_ibge));
    }, [cities],
  );

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          first_name: '',
          last_name: '',
          document_type: 'PF',
          document_number: '',
          email: '',
          email_confirmation: '',
          password: '',
          password_confirmation: '',
          address_zip_code: '',
          address_street: '',
          address_number: '',
          address_complement: '',
          address_district: '',
          address_state_ibge: '',
          address_city_ibge: '',
        }}
        validationSchema={signUpFormSchema}
        onSubmit={handleCreateAccount}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          isValid,
        }) => (
          <>
            <SectionHeader className="mt-3 d-flex justify-content-between">
              Informações do Usuário
            </SectionHeader>
            <hr className="mt-2" />
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Tipo de Cadastro</Form.Label>
                <br />
                <ButtonGroup>
                  <Button
                    variant="outline-secondary"
                    className={values.document_type === 'PF' ? 'active' : ''}
                    onClick={() => setFieldValue('document_type', 'PF')}
                  >
                    Pessoa Física
                  </Button>
                  <Button
                    variant="outline-secondary"
                    className={values.document_type === 'PJ' ? 'active' : ''}
                    onClick={() => setFieldValue('document_type', 'PJ')}
                  >
                    Pessoa Jurídica
                  </Button>
                </ButtonGroup>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6">
                <Form.Label>{PLACEHOLDERS[`document_type_${values.document_type}`]}</Form.Label>
                <FormCpfCnpjField
                  type={values.document_type === 'PF' ? 'CPF' : 'CNPJ'}
                  name="document_number"
                  placeholder={PLACEHOLDERS[`document_type_${values.document_type}`]}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm="12" md="6">
                <Form.Label>{PLACEHOLDERS[`first_name_${values.document_type}`]}</Form.Label>
                <FormTextField name="first_name" placeholder={PLACEHOLDERS[`first_name_${values.document_type}`]} />
              </Form.Group>
              <Form.Group as={Col} sm="12" md="6">
                <Form.Label>{PLACEHOLDERS[`last_name_${values.document_type}`]}</Form.Label>
                <FormTextField name="last_name" placeholder={PLACEHOLDERS[`last_name_${values.document_type}`]} />
              </Form.Group>
            </Form.Row>
            <SectionHeader className="mt-3">Endereço</SectionHeader>
            <hr className="mt-2" />
            <Form.Row>
              <Form.Group as={Col} xs="7" sm="4">
                <Form.Label>CEP</Form.Label>
                <FormMaskedInput type="ZIP_CODE" name="address_zip_code" placeholder="CEP" />
              </Form.Group>
              <Form.Group as={Col} xs="5" sm="8">
                <SearchButton
                  disabled={!values.address_zip_code}
                  type="button"
                  variant="secondary"
                  isLoading={isLoadingCep}
                  onClick={() => handleCepSearch(values, setFieldValue)}
                >
                  Buscar CEP
                </SearchButton>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="4">
                <Form.Label>Logradouro</Form.Label>
                <FormTextField name="address_street" placeholder="Logradouro" />
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>Número</Form.Label>
                <FormTextField name="address_number" placeholder="Número" />
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>Complemento</Form.Label>
                <FormTextField
                  name="address_complement"
                  placeholder="Complemento"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="4">
                <Form.Label>Bairro</Form.Label>
                <FormTextField name="address_district" placeholder="Bairro" />
              </Form.Group>
              <Form.Group as={Col} lg="4">
                <Form.Label>Unidade Federativa</Form.Label>
                <Select
                  name="address_state_ibge"
                  onChange={(option) => handleStateIbgeChange(option, setFieldValue)}
                  value={getSelectedState(values)}
                  options={states}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4">
                <Form.Label>Cidade</Form.Label>
                <Select
                  name="address_city_ibge"
                  onChange={(option) => handleCityIbgeChange(option, setFieldValue)}
                  value={getSelectedCity(values)}
                  options={cities}
                />
              </Form.Group>
            </Form.Row>
            <SectionHeader className="mt-3">Dados de Acesso</SectionHeader>
            <hr className="mt-2" />
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>E-mail</Form.Label>
                <FormTextField name="email" placeholder="E-mail" />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Confirmar E-mail</Form.Label>
                <FormTextField name="email_confirmation" autoComplete="off" placeholder="E-mail" />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Senha</Form.Label>
                <FormPasswordField name="password" autoComplete="off" type="password" placeholder="Criar uma senha" />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Confirmar Senha</Form.Label>
                <FormPasswordField name="password_confirmation" autoComplete="off" type="password" placeholder="Confirmar senha" />
              </Form.Group>
            </Form.Row>
            <Button
              type="button"
              className="d-flex flex-direction-column"
              isLoading={isLoading}
              disabled={!isValid || isLoading}
              onClick={handleSubmit}
            >
              Avançar
              <IoArrowForward className="ml-2" />
            </Button>
          </>
        )}
      </Formik>
    </>
  );
}

BasicInfo.propTypes = {
  isLoadingCep: PropTypes.bool.isRequired,
  onFetchCities: PropTypes.func.isRequired,
  onFetchStates: PropTypes.func.isRequired,
  onFetchCep: PropTypes.func.isRequired,
  onSignUp: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  states: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
};

export default BasicInfo;
