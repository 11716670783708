import { connect } from 'react-redux';

import AdvancedSearch from './AdvancedSearch';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  searchResults: state.companyTransactions.searchResults,
  tags: state.companyTransactions.tags,
  costCenters: state.companyTransactions.costsCenter,
  recipients: state.companyTransactions.recipients,
  categories: state.companyTransactions.categories,
  accounts: state.companyTransactions.accounts,
});

export default connect(mapStateToProps, null)(AdvancedSearch);
