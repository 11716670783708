import constants from '../../_constants/employees.constants';
import service from '../../../services/employees.service';
import alertActionCreators from '../alert.actions';

const updateEmployee = (id, employee, callback) => {
  return (dispatch) => {
    dispatch(request(employee));

    service.update(id, employee).then(
      (response) => {
        const updatedEmployee = response.data;

        dispatch(success(updatedEmployee));
        dispatch(alertActionCreators.success('Dados atualizados com sucesso!'));

        if (callback) {
          callback();
        }
      },
    ).catch(({ response }) => {
      if (response.status === 422) {
        const { message } = response.data;

        dispatch(failure(message));
        dispatch(alertActionCreators.error(message));
      }
    });
  };

  function request(employee) {
    return {
      type: constants.EMPLOYEE_UPDATE_REQUEST,
      payload: { employee },
    };
  }
  function success(employee) {
    return {
      type: constants.EMPLOYEE_UPDATE_SUCCESS,
      payload: { employee },
    };
  }
  function failure(message) {
    return {
      type: constants.EMPLOYEE_UPDATE_FAILURE,
      payload: { message },
    };
  }
};

export default updateEmployee;
