import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import { AccountSelect } from '_components/_shared';

function FormAccountSelectField({
  placeholder,
  multiple,
  only,
  disabled,
  creatable,
  ...props
}) {
  const [field, meta, helpers] = useField(props);

  const error = meta.error ? meta.error : '';

  const handleChange = useCallback((selected) => {
    if (!selected) {
      helpers.setValue(null);
      return;
    }

    if (multiple) {
      const ids = selected.map((selectedItem) => selectedItem.value);

      helpers.setValue(ids);
      return;
    }

    const { value } = selected;

    helpers.setValue(value);
  }, [helpers, multiple]);

  return (
    <>
      <AccountSelect
        name={field.name}
        value={field.value}
        onChange={handleChange}
        disabled={disabled}
        multiple={multiple}
        placeholder={placeholder}
        isClearable
        creatable={creatable || null}
        onBlur={field.onBlur}
        isErrorred={!!error}
      />
      {error && <small className="text-danger">{error}</small>}
    </>
  );
}

FormAccountSelectField.defaultProps = {
  multiple: false,
  only: null,
  disabled: false,
  placeholder: 'Selecione...',
};

FormAccountSelectField.propTypes = {
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  only: PropTypes.string,
  creatable: PropTypes.string,
};

export default FormAccountSelectField;
