import { connect } from 'react-redux';

import invoicesActionCreators from '_store/_actions/invoices';

import AccountingIntegrationModal from './SyncInvoicesModal';

const mapStateToProps = (state) => ({
  isLoadingInvoices: state.invoices.isLoading,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onSyncInvoices: invoicesActionCreators.syncInvoices,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountingIntegrationModal);
