import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsPlus } from 'react-icons/bs';

import { Button, TableLookup, ConfirmDialog } from '_components/_core';
import { Pagination, ItemsPerPage } from '_components/_core/Table/components';
import { useTable } from '_components/_core/Table/utils';

import { AddEmployeeJourneyModal } from './components';
import { DEFAULT_COLUMNS, TABLE_COLUMNS } from './utilities';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { field: 'journey', order: 'asc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function EmployeeJourneys({
  total,
  journeys,
  onFetchEmployeeJourneys,
  onAddEmployeeJourney,
  onUpdateEmployeeJourney,
  onDeleteEmployeeJourney,
}) {
  const {
    pagination,
    onPageChange,
    onPageSizeChange,
  } = useTable({
    ...tableConfig,
    data: journeys,
    onFetchData: onFetchEmployeeJourneys,
  });

  const [selectedJourney, setSelectedJourney] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const deleteEmployeeJourneyMessage = useMemo(() => {
    if (!selectedJourney) {
      return null;
    }

    return 'Tem certeza que deseja excluir a jornada?';
  }, [selectedJourney]);

  const handleDeleteEmployeeJourney = useCallback((id) => {
    const journey = journeys.find((journey) => journey.id === id);

    setSelectedJourney(journey);
    setIsDeleteConfirmOpen(true);
  }, [journeys]);

  const handleDeleteEmployeeJourneyConfirmation = useCallback(() => {
    const { id } = selectedJourney;

    onDeleteEmployeeJourney(id);
    setIsModalOpen(false);
    setIsDeleteConfirmOpen(false);
    setSelectedJourney(null);
  }, [selectedJourney, onDeleteEmployeeJourney]);

  const handleEditEmployeeJourney = useCallback((id) => {
    setSelectedJourney(null);

    const journey = journeys.find((journey) => journey.id === id);

    setSelectedJourney(journey);
    setIsModalOpen(true);
  }, [journeys]);

  const handleAddEmployeeJourney = useCallback(() => {
    setSelectedJourney(null);
    setIsModalOpen(true);
  }, []);

  return (
    <>
      <AddEmployeeJourneyModal
        isVisible={isModalOpen}
        employeeJourney={selectedJourney}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
        onAddEmployeeJourney={onAddEmployeeJourney}
        onUpdateEmployeeJourney={onUpdateEmployeeJourney}
      />
      <ConfirmDialog
        title="Excluir Jornada"
        icon="question"
        confirmButtonColor="#DB504A"
        confirmButtonText="Sim, deletar!"
        html={deleteEmployeeJourneyMessage}
        isOpen={isDeleteConfirmOpen}
        showCancelButton
        onConfirm={handleDeleteEmployeeJourneyConfirmation}
        onCancel={() => {
          setIsDeleteConfirmOpen(false);
          setSelectedJourney(null);
        }}
      />
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h4 className="mb-0">Jornadas</h4>
        <Button
          variant="primary"
          icon={<BsPlus />}
          onClick={handleAddEmployeeJourney}
        >
          Nova Jornada
        </Button>
      </div>
      <TableLookup
        keyName="id"
        data={journeys}
        columns={TABLE_COLUMNS}
        defaultColumns={DEFAULT_COLUMNS}
        onEdit={handleEditEmployeeJourney}
        onDelete={handleDeleteEmployeeJourney}
      />
      <Row>
        <Col sm={2}>
          <ItemsPerPage
            itemsPerPage={pagination.itemsPerPage}
            onChange={onPageSizeChange}
            className="mt-3"
          />
        </Col>
        <Col className="d-flex flex-row-reverse">
          <Pagination
            {...pagination}
            total={total}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
    </>
  );
}

EmployeeJourneys.propTypes = {
  total: PropTypes.number.isRequired,
  journeys: PropTypes.array,
  onFetchEmployeeJourneys: PropTypes.func.isRequired,
  onAddEmployeeJourney: PropTypes.func.isRequired,
  onUpdateEmployeeJourney: PropTypes.func.isRequired,
  onDeleteEmployeeJourney: PropTypes.func.isRequired,
};

export default EmployeeJourneys;
