import { connect } from 'react-redux';

import accountantActionCreators from '_store/_actions/accountants';

import Accountants from './Accountants';

const mapStateToProps = (state) => ({
  accountants: state.accountants.accountants,
  total: state.accountants.total,
  isLoading: state.accountants.isLoading,
});

const mapDispatchToProps = {
  onFetchAccountants: accountantActionCreators.getAllAccountants,
  onAddAccountant: accountantActionCreators.addAccountant,
  onUpdateAccountant: accountantActionCreators.updateAccountant,
  onDeleteAccountant: accountantActionCreators.deleteAccountant,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Accountants);
