import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DateTime } from 'luxon';

import {
  Card,
  CardHeader,
  CardBody,
  LoadingOverlay,
} from '_components/_core';

import {
  TABLE_COLUMNS,
  DEFAULT_COLUMNS,
} from './utilities';

import { StyledTable } from './styles';

function SimplesNacional({
  selectedDate,
  taxes,
  onCalculateTaxes,
  onFetchTaxes,
  isLoading,
}) {
  const transformed_data = useMemo(() => {
    const transformed = taxes.map((tax) => ({
      id: tax.id,
      date_competence: tax.date_competence,
      status: tax.metadata.situacao,
      das_number: tax.metadata.das_number,
      due_date: tax.metadata.data_vencimento,
      paid_date: tax.metadata.data_pagamento,
      das_url: tax.metadata.url_das,
      simples_nacional: tax.simples_nacional,
      gross_revenue: tax.gross_revenue,
      valor_principal: tax.metadata.normalizado_principal,
      valor_multa: tax.metadata.normalizado_multas,
      valor_juros: tax.metadata.normalizado_juros,
      valor_total: tax.metadata.normalizado_total,
      numero_apuracao: tax.metadata.numero_apuracao,
      numero_das: tax.metadata.numero_das,
      situacao: tax.metadata.situacao,
    }));

    return transformed;
  }, [taxes]);

  const handleCalculateTaxes = useCallback((id) => {
    const tax = taxes.find((tax) => tax.id === id);
    const start_date = DateTime.fromISO(tax.date_competence, { zone: 'utc' }).startOf('month');
    const end_date = DateTime.fromISO(start_date, { zone: 'utc' }).endOf('month');

    const fomattedStartDate = start_date.toFormat('yyyy-MM-dd');
    const fomattedEndDate = end_date.toFormat('yyyy-MM-dd');

    const { year } = start_date;

    onCalculateTaxes({
      start_date: fomattedStartDate,
      end_date: fomattedEndDate,
    },
    () => {
      onFetchTaxes({
        year,
      });
    });
  }, [onCalculateTaxes, onFetchTaxes, taxes]);

  return (
    <>
      <Row>
        <Col sm={12} md={12} lg={12} className="mb-3">
          <LoadingOverlay active={isLoading}>
            <Card>
              <CardHeader
                title="Visão Anual"
                description={(
                  <div>
                    Impostos apurados no ano de&nbsp;
                    <span style={{ color: '#727cf5' }}>
                      <b>{selectedDate.toFormat('yyyy')}</b>
                    </span>
                  </div>
              )}
              />
              <CardBody>
                <div className="table-responsive">
                  <StyledTable
                    keyName="competence"
                    className="table-hover table-striped"
                    data={transformed_data}
                    columns={TABLE_COLUMNS}
                    defaultColumns={DEFAULT_COLUMNS}
                    name="mei-yearly-taxes-table"
                    onRefresh={handleCalculateTaxes}
                    showFooter
                    showColumnToggle
                  />
                </div>
              </CardBody>
            </Card>
          </LoadingOverlay>
        </Col>
      </Row>
    </>
  );
}

SimplesNacional.defaultProps = {
  taxes: [],
  isLoading: false,
};

SimplesNacional.propTypes = {
  taxes: PropTypes.array,
  selectedDate: PropTypes.any,
  onFetchTaxes: PropTypes.func.isRequired,
  onCalculateTaxes: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default SimplesNacional;
