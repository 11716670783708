import Swal from 'sweetalert2';

import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const uploadTransactionsSpreadsheetConfirm = (
  formData,
  callback,
  errorCallback,
  onStart,
  onCancel,
) => {
  return async (dispatch) => {
    confirmDialog.warning({
      title: 'Atenção!',
      message: 'Ao importar esta planilha, as movimentações contidas nela serão adicionadas na sua empresa ativa. <br /> Deseja continuar?',
      confirmButtonText: 'Sim, importar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request());

        if (onStart) {
          onStart();
        }

        service.uploadTransactionsSpreadsheetConfirm(formData).then(
          (response) => {
            dispatch(success(response.data));

            if (callback) {
              callback(response.data);
            }

            Swal.close();
          },
        ).catch(
          (err) => {
            dispatch(failure(err));

            if (errorCallback) {
              errorCallback(err);
            }

            Swal.close();
          },
        );
      },
      onCancel: () => {
        if (onCancel) {
          onCancel();
        }
      },
    });
  };

  function request() {
    return {
      type: constants.UPLOAD_TRANSACTIONS_SPREADSHEET_CONFIRM_REQUEST,
      payload: {},
    };
  }
  function success(payload) {
    return {
      type: constants.UPLOAD_TRANSACTIONS_SPREADSHEET_CONFIRM_SUCCESS,
      payload,
    };
  }
  function failure(tempId, error) {
    return {
      type: constants.UPLOAD_TRANSACTIONS_SPREADSHEET_CONFIRM_FAILURE,
      payload: {
        tempId,
        error,
      },
    };
  }
};

export default uploadTransactionsSpreadsheetConfirm;
