import FORMATTERS from 'helpers/formatters';

import getTableTopDistance from './getTableTopDistance';

const FILTERS_CONFIGURATION = {
  date_competence_start: {
    label: 'Competência a partir de',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  date_competence_end: {
    label: 'Competência até',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  date_emission_start: {
    label: 'Emissão a partir de',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  date_emission_end: {
    label: 'Emissão até',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  invoice_number: {
    label: 'Número da Nota Fiscal',
  },
  invoice_status: {
    label: 'Status da Nota',
    formatter: FORMATTERS.INVOICE_STATUS_TEXT,
  },
  service_iss_withheld: {
    label: 'ISS Retido',
    formatter: FORMATTERS.ISS_WITHHELD_TEXT,
  },
  service_taker: {
    label: 'Tomador contém',
    formatter: FORMATTERS.STRING_WITH_QUOTES,
  },
  service_gross_amount_start: {
    label: 'Valor Bruto a partir de',
    formatter: FORMATTERS.NUMBER,
  },
  service_gross_amount_end: {
    label: 'Valor Bruto até',
    formatter: FORMATTERS.NUMBER,
  },
  service_net_amount_start: {
    label: 'Valor Líquido a partir de',
    formatter: FORMATTERS.NUMBER,
  },
  service_net_amount_end: {
    label: 'Valor Líquido até',
    formatter: FORMATTERS.NUMBER,
  },
  service_iss_amount_start: {
    label: 'Valor ISS a partir de',
    formatter: FORMATTERS.NUMBER,
  },
  service_iss_amount_end: {
    label: 'Valor ISS até',
    formatter: FORMATTERS.NUMBER,
  },
  service_pis_amount_start: {
    label: 'Valor PIS a partir de',
    formatter: FORMATTERS.NUMBER,
  },
  service_pis_amount_end: {
    label: 'Valor PIS até',
    formatter: FORMATTERS.NUMBER,
  },
  service_cofins_amount_start: {
    label: 'Valor COFINS a partir de',
    formatter: FORMATTERS.NUMBER,
  },
  service_cofins_amount_end: {
    label: 'Valor COFINS até',
    formatter: FORMATTERS.NUMBER,
  },
  service_ir_amount_start: {
    label: 'Valor IR a partir de',
    formatter: FORMATTERS.NUMBER,
  },
  service_ir_amount_end: {
    label: 'Valor IR até',
    formatter: FORMATTERS.NUMBER,
  },
  service_csll_amount_start: {
    label: 'Valor CSLL a partir de',
    formatter: FORMATTERS.NUMBER,
  },
  service_csll_amount_end: {
    label: 'Valor CSLL até',
    formatter: FORMATTERS.NUMBER,
  },
};

export {
  getTableTopDistance,
  FILTERS_CONFIGURATION,
};
