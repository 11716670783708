import { CustomSelectField } from '_components/Financial/BankStatement/Reconcile/components';
import styled from 'styled-components';

export const StyledTable = styled.table`  
  &&& {
    thead tr th {
      height: 32px;
      line-height: 32px;
      vertical-align: middle;
      text-align: left;
      /* border-left: 1px solid #D4D7DC; */
      padding: 0 8px 0 9px !important;

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #d5dcec;
        /* box-shadow: inset 0px 25px 1px -10px #d5dcec; */
      }
    }

    tbody tr td {
      height: 26px;
      line-height: 26px;
      padding: 9px !important;
      vertical-align: middle;
      border-top: 1px solid #D4D7DC;
      cursor: pointer;
      font-weight: 400;
      cursor: default;
    }
  }
`;

export const StyledUser = styled.span`
  border-bottom: 1px dashed #ccc;
`;

export const RecipientSelect = styled(CustomSelectField)`
  min-width: 15%;
  margin-right: 12px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-top: 8px;
  }
`;
