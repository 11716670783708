import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';

const updateCompanyCredential = (company_id, id, credential, callback) => {
  return async (dispatch) => {
    dispatch(request(id, credential));

    service.updateCredential(company_id, id, credential).then(
      (response) => {
        dispatch(success(response));
        dispatch(alertActionCreators.success('Credencial atualizada com sucesso!'));

        if (callback) {
          service.getById(company_id).then((response) => {
            callback(response.data);
          });
        }
      },
    ).catch(
      (err) => {
        dispatch(failure(err));
        dispatch(alertActionCreators.error('Erro ao atualizar credencial!'));
      },
    );
  };

  function request(id, credential) {
    return {
      type: constants.UPDATE_COMPANY_CREDENTIAL_REQUEST,
      payload: { id, credential },
    };
  }
  function success(response) {
    return {
      type: constants.UPDATE_COMPANY_CREDENTIAL_SUCCESS,
      payload: { response },
    };
  }
  function failure(error) {
    return {
      type: constants.UPDATE_COMPANY_CREDENTIAL_FAILURE,
      payload: { error },
    };
  }
};

export default updateCompanyCredential;
