import React from 'react';
import PropTypes from 'prop-types';

import FORMATTERS from 'helpers/formatters';
import { Alert } from '_components/_core';

function ActiveCompanyInfo({ activeCompany }) {
  const {
    company_name,
    document_number,
    tax_regime,
    date_founded,
  } = activeCompany || {};

  return (
    <Alert
      variant="info"
      description={(
        <>
          <p className="pb-1">
            <b>EMPRESA: </b>
            {`${company_name} (${FORMATTERS.CPF_CNPJ(document_number)})`}
          </p>
          <p className="pb-1">
            <b>REGIME ATUAL: </b>
            {FORMATTERS.TAX_REGIME_TEXT(tax_regime)}
          </p>
          <p>
            <b>DATA DE ABERTURA: </b>
            {FORMATTERS.DATE_DDMMYYYY(date_founded)}
          </p>
        </>
      )}
    />
  );
}

ActiveCompanyInfo.defaultProps = {
  activeCompany: {},
};

ActiveCompanyInfo.propTypes = {
  activeCompany: PropTypes.shape({
    company_name: PropTypes.string,
    document_number: PropTypes.string,
    tax_regime: PropTypes.string,
    date_founded: PropTypes.string,
  }),
};

export default ActiveCompanyInfo;
