import { connect } from 'react-redux';

import payrollActionCreators from '_store/_actions/payroll';

import IrrfTables from './IrrfTables';

const mapStateToProps = (state) => ({
  irrf: state.payroll.irrfTables,
  total: state.payroll.irrfTotal,
});

const mapDispatchToProps = {
  onFetchIrrf: payrollActionCreators.getIrrfTables,
};

export default connect(mapStateToProps, mapDispatchToProps)(IrrfTables);
