import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { StyledLabel, StyledFormGroup, StyledContent } from './styles';

function FormGroup({
  label,
  icon,
  children,
  className,
  ...rest
}) {
  return (
    <StyledFormGroup {...rest} className={classNames('d-flex align-items-center', className)}>
      {icon}
      <StyledContent>
        {label && <StyledLabel>{label}</StyledLabel>}
        {children}
      </StyledContent>
    </StyledFormGroup>
  );
}

FormGroup.defaultProps = {
  label: '',
  icon: null,
  className: '',
};

FormGroup.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default FormGroup;
