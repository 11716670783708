import { push } from 'connected-react-router';

import constants from '../../_constants/bank-statements.constants';
import service from '../../../services/bank-statement.service';
import alertActionCreators from '../alert.actions';

const parseBankStatements = (payload) => {
  return (dispatch) => {
    dispatch(request(payload));

    service
      .parse(payload)
      .then((response) => {
        dispatch(success(response.data));
        dispatch(push('/contabil/extrato-bancario/conciliar'));
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(alertActionCreators.error('Erro ao salvar no Contabills'));
      });
  };

  function request(payload) {
    return {
      type: constants.BANK_STATEMENTS_PARSE_REQUEST,
      payload: { payload },
    };
  }
  function success(uploadResults) {
    return {
      type: constants.BANK_STATEMENTS_PARSE_SUCCESS,
      payload: uploadResults,
    };
  }
  function failure(error) {
    return {
      type: constants.BANK_STATEMENTS_PARSE_FAILURE,
      payload: { error },
    };
  }
};

export default parseBankStatements;
