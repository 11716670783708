import { connect } from 'react-redux';

import accountsActionCreators from '_store/_actions/accounts';
import entriesActionCreators from '_store/_actions/entries';

import Create from './Create';

const mapStateToProps = (state) => ({
  isLoading: state.entries.isLoading,
  entryType: state.entries.type,
});

const mapDispatchToProps = {
  onCreateEntry: entriesActionCreators.addEntry,
  onUpdateEntry: entriesActionCreators.updateEntry,
  onFetchEntry: entriesActionCreators.fetchEntry,
  onFetchAccounts: accountsActionCreators.getAllAccounts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Create);
