import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';
import { GoPlus } from 'react-icons/go';
import isEmpty from 'lodash/isEmpty';

import { FormSelectField, FormMaskedInput } from '_components/_core';
import confirmDialog from 'helpers/confirmDialog';
import FORMATTERS from 'helpers/formatters';

import { CnaeFormSchema } from './utilities';
import { StyledButton } from './styles';

function CnaeForm({
  isLoading,
  cnaes,
  onFetchCnae,
  onAddCnae,
}) {
  const validateCnae = useCallback((cnae) => {
    const cleanCnae = FORMATTERS.CLEAN_CNAE(cnae);

    if (cnaes.map((cnae) => cnae.cnae).includes(cleanCnae)) {
      confirmDialog.open({
        icon: 'error',
        title: 'CNAE duplicado.',
        message: `O CNAE ${cnae} já existe na lista de CNAE's`,
        confirmButtonText: 'Entendi',
        showCancelButton: false,
      });

      return false;
    }

    return true;
  }, [cnaes]);

  const handleCnaeSearch = useCallback((values, { resetForm }) => {
    const isValid = validateCnae(values.cnae);

    if (!isValid) {
      return;
    }

    onFetchCnae(values.cnae, values.isPrimary, (cnae) => {
      if (!cnae) {
        confirmDialog.open({
          icon: 'error',
          title: 'CNAE inválido.',
          message: `O CNAE ${values.cnae} não existe.`,
          confirmButtonText: 'Entendi',
          showCancelButton: false,
        });
        return;
      }

      onAddCnae(cnae);
      resetForm();
    });
  }, [onFetchCnae, onAddCnae, validateCnae]);

  const getCnaeTypesOptions = useCallback(() => {
    const containsPrimaryCnae = isEmpty(cnaes) ? false : cnaes.find((cnae) => cnae.is_primary);

    return [
      {
        value: true,
        label: 'Principal',
        isDisabled: containsPrimaryCnae,
      },
      {
        value: false,
        label: 'Secundário',
      },
    ];
  }, [cnaes]);

  return (
    <Formik
      initialValues={{
        cnae: '',
        isPrimary: false,
      }}
      onSubmit={handleCnaeSearch}
      validationSchema={CnaeFormSchema}
      enableReinitialize
    >
      {({ handleSubmit, isValid }) => (
        <>
          <Form>
            <Form.Row>
              <Col md="3">
                <Form.Group>
                  <Form.Label>CNAE</Form.Label>
                  <FormMaskedInput
                    name="cnae"
                    placeholder="Digite um CNAE"
                    type="CNAE"
                  />
                </Form.Group>
              </Col>
              <Col md="2">
                <Form.Group>
                  <Form.Label>Tipo</Form.Label>
                  <FormSelectField
                    name="isPrimary"
                    options={getCnaeTypesOptions()}
                  />
                </Form.Group>
              </Col>
              <Col xl="2">
                <StyledButton
                  variant="secondary"
                  isLoading={isLoading}
                  disabled={isLoading || !isValid}
                  onClick={handleSubmit}
                  icon={<GoPlus />}
                  noMargin
                />
              </Col>
            </Form.Row>
          </Form>
        </>
      )}
    </Formik>
  );
}

CnaeForm.defaultProps = {
  cnaes: [],
};

CnaeForm.propTypes = {
  cnaes: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  onFetchCnae: PropTypes.func.isRequired,
  onAddCnae: PropTypes.func.isRequired,
};

export default CnaeForm;
