import constants from '../../_constants/receipts.constants';
import service from '../../../services/receipt.service';

const fetchAllReceipts = (callback) => {
  return async (dispatch) => {
    dispatch(request());

    service.fetchAllReceipts().then(
      (response) => {
        dispatch(success(response));

        if (callback) {
          callback(response.data);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.RECEIPT_GET_ALL_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.RECEIPT_GET_ALL_SUCCESS,
      payload: response.data,
    };
  }
  function failure(error) {
    return {
      type: constants.RECEIPT_GET_ALL_FAILURE,
      payload: error,
    };
  }
};

export default fetchAllReceipts;
