import constants from '../../_constants/accounts.constants';

const toggleAccountForm = () => (dispatch) => {
  dispatch({
    type: constants.ACCOUNT_TOGGLE_FORM,
    payload: {},
  });
};

export default toggleAccountForm;
