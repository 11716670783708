import getRevenueReport from './getRevenueReport';
import clearRevenueReport from './clearRevenueReport';
import getIssReport from './getIssReport';
import clearIssReport from './clearIssReport';

export default {
  clearRevenueReport,
  getRevenueReport,
  getIssReport,
  clearIssReport,
};
