import constants from '../../_constants/invoices.constants';
import service from '../../../services/invoices.service';

import getIssuedInvoices from './getIssuedInvoices';
import alertActionCreators from '../alert.actions';

const duplicateIssuedInvoices = (params, callback) => {
  return (dispatch) => {
    dispatch(request());

    service.duplicate(params).then(() => {
      dispatch(success(params));
      dispatch(getIssuedInvoices());
      dispatch(alertActionCreators.success('Notas Fiscais duplicadas com sucesso.'));

      if (callback) {
        callback();
      }
    }).catch((err) => {
      dispatch(failure(err));
    });
  };

  function request() {
    return {
      type: constants.DUPLICATE_ISSUED_INVOICES_REQUEST,
      payload: { },
    };
  }
  function success(params) {
    return {
      type: constants.DUPLICATE_ISSUED_INVOICES_SUCCESS,
      payload: params,
    };
  }
  function failure(error) {
    return {
      type: constants.DUPLICATE_ISSUED_INVOICES_FAILURE,
      payload: error,
    };
  }
};

export default duplicateIssuedInvoices;
