import { sidebarConstants } from '../_constants';

const INITIAL_STATE = {
  collapsed: true,
};

export function sidebar(state = INITIAL_STATE, action) {
  switch (action.type) {
    case sidebarConstants.TOGGLE_SIDEBAR_REQUEST:

      return {
        ...state,
        collapsed: !state.collapsed,
      };
    default:
      return state;
  }
}
