import FORMATTERS from 'helpers/formatters';

import formatData from './formatData';

const DEFAULT_COLUMNS = [
  'competence',
  'tax_name',
  'amount_due',
];

const TABLE_COLUMNS = [
  {
    label: '',
    keyName: 'tax_name',
    align: 'left',
    width: 90,
    order: 2,
  },
  {
    label: 'Valor',
    keyName: 'amount_due',
    align: 'right',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    sumOnFooter: true,
    order: 3,
    customLabel: (item) => FORMATTERS.DATE_MMYYYY(item.competence),
  },
];

export {
  formatData,
  DEFAULT_COLUMNS,
  TABLE_COLUMNS,
};
