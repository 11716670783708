import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActions from '../alert.actions';

const reconcileMultipleTransactions = (params, callback) => {
  return async (dispatch) => {
    dispatch(request(params));

    service.updateMultipleTransactions(params).then((response) => {
      dispatch(success(response.data));

      dispatch(alertActions.success('Transações atualizadas com sucesso!'));

      if (callback) {
        callback();
      }
    }).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(params) {
    return {
      type: constants.RECONCILE_MULTIPLE_TRANSACTIONS_REQUEST,
      payload: {
        params,
      },
    };
  }
  function success(transactions) {
    return {
      type: constants.RECONCILE_MULTIPLE_TRANSACTIONS_SUCCESS,
      payload: {
        transactions,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.RECONCILE_MULTIPLE_TRANSACTIONS_FAILURE,
      payload: error,
    };
  }
};

export default reconcileMultipleTransactions;
