import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const deleteTag = (id, callback) => {
  return async (dispatch) => {
    confirmDialog.warning({
      title: 'Excluir Tag',
      message: 'Tem certeza que deseja excluir esta tag? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, excluir!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.deleteTag(id).then(
          (response) => {
            dispatch(success(response));
            dispatch(alertActionCreators.success('Tag excluída com sucesso!'));

            if (callback) {
              callback();
            }
          },
        ).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request(id) {
    return {
      type: constants.DELETE_TRANSACTION_TAGS_REQUEST,
      payload: {
        id,
      },
    };
  }
  function success(response) {
    return {
      type: constants.DELETE_TRANSACTION_TAGS_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_TRANSACTION_TAGS_FAILURE,
      payload: {
        id,
        error,
      },
    };
  }
};

export default deleteTag;
