import { connect } from 'react-redux';
import { compose } from 'redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import TransactionsUpload from './TransactionsUpload';

const mapStateToProps = (state) => ({
  isLoading: state.companyTransactions.isLoading,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onUploadTransactionsSpreadsheet: companyTransactionsActionCreators.uploadTransactionsSpreadsheet,
  onUploadTransactionsSpreadsheetConfirm:
  companyTransactionsActionCreators.uploadTransactionsSpreadsheetConfirm,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['import_files']),
)(TransactionsUpload);
