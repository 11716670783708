import { connect } from 'react-redux';

import ibgeActionCreators from '_store/_actions/ibge';

import BasicInfo from './BasicInfo';

const mapStateToProps = (state) => ({
  isLoadingCities: state.ibge.isLoadingCities,
  isLoadingStates: state.ibge.isLoadingStates,
  isLoadingCep: state.ibge.isLoadingCep,
  cities: state.ibge.cities,
  states: state.ibge.states,
});

const mapDispatchToProps = {
  onFetchStates: ibgeActionCreators.fetchStates,
  onFetchCities: ibgeActionCreators.fetchCities,
  onFetchCep: ibgeActionCreators.fetchCep,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);
