import styled from 'styled-components';

export const Container = styled.tr``;

export const Column = styled.td`
  padding-bottom: 7px !important;
  padding-top: 7px !important;
  padding-left: ${(props) => (props.paddingLeft ? '15px' : '0px')} !important;
  border-top: 1px solid #d5dcec !important;
  text-align: ${(props) => (props.textCenter ? 'center' : 'left')} !important;
  height: 32px;
`;

export const ColumnPlaceholder = styled.div`
  background: lightgray;
  width: ${(props) => (props.width ? `${props.width}%` : '100%')} !important;
  height: 15px;
  border-radius: 4px;
`;
