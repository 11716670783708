import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';

import { Button, FormTextField } from '_components/_core';

import { StyledModal, ModalFooter } from './styles';
import { EmployeeJourneySchema } from '../../utilities';

export default function AddEmployeeJourneyModal({
  isVisible,
  employeeJourney,
  onModalToggle,
  onAddEmployeeJourney,
  onUpdateEmployeeJourney,
  userInputValue,
}) {
  const handleAddEditEmployeeJourney = useCallback((values) => {
    if (values.id) {
      onUpdateEmployeeJourney(values);
    } else {
      onAddEmployeeJourney(values);
    }

    onModalToggle();
  }, [onUpdateEmployeeJourney, onAddEmployeeJourney, onModalToggle]);

  const renderModalFooter = useCallback((handleSubmit, isValid) => (
    <ModalFooter>
      <Button variant="secondary" onClick={onModalToggle}>
        Cancelar
      </Button>
      <Button type="submit" variant="primary" onClick={handleSubmit} disabled={!isValid}>
        Salvar
      </Button>
    </ModalFooter>
  ), [onModalToggle]);

  return (
    <Formik
      initialValues={{
        id: employeeJourney ? employeeJourney.id : null,
        journey: employeeJourney ? employeeJourney.journey : userInputValue,
      }}
      validationSchema={EmployeeJourneySchema}
      onSubmit={handleAddEditEmployeeJourney}
      enableReinitialize
    >
      {({ handleSubmit, isValid }) => (
        <>
          <StyledModal
            title={employeeJourney ? 'Editar jornada' : 'Cadastrar nova jornada'}
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit, isValid)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>Jornada Semanal</Form.Label>
                  <FormTextField
                    name="journey"
                    placeholder="Jornada semanal (em horas) ex: 40"
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
}

AddEmployeeJourneyModal.defaultProps = {
  isVisible: false,
  employeeJourney: null,
  userInputValue: '',
};

AddEmployeeJourneyModal.propTypes = {
  isVisible: PropTypes.bool,
  employeeJourney: PropTypes.object,
  onModalToggle: PropTypes.func.isRequired,
  onAddEmployeeJourney: PropTypes.func.isRequired,
  onUpdateEmployeeJourney: PropTypes.func.isRequired,
  userInputValue: PropTypes.string,
};
