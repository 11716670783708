import constants from '../../_constants/mei.constants';
import service from '../../../services/mei.service';

const fetchMeiDas = (id, params) => {
  return (dispatch) => {
    dispatch(request(id, params));

    service.fetchMeiDas(id, params).then(
      (response) => {
        dispatch(success(response.data));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(id, params) {
    return {
      type: constants.FETCH_MEI_DAS_REQUEST,
      payload: { id, params },
    };
  }
  function success(data) {
    return {
      type: constants.FETCH_MEI_DAS_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_MEI_DAS_FAILURE,
      payload: error,
    };
  }
};

export default fetchMeiDas;
