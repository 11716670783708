import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';

const updateBlockedPeriod = (periods, callback) => {
  return async dispatch => {
    dispatch(request());

    service
      .updateBlockedPeriod(periods)
      .then(response => {
        dispatch(success(response.data));
        dispatch(alertActionCreators.success('Perído bloqueado atualizado com sucesso!'));

        if (callback) {
          callback(response.data);
        }
      })
      .catch(err => {
        dispatch(failure(err));
      });
  };

  function request(periods) {
    return {
      type: constants.UPDATE_COMPANY_ACTIVE_PERIOD_REQUEST,
      payload: { periods },
    };
  }
  function success(data) {
    return {
      type: constants.UPDATE_COMPANY_ACTIVE_PERIOD_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.UPDATE_COMPANY_ACTIVE_PERIOD_FAILURE,
      payload: { error },
    };
  }
};

export default updateBlockedPeriod;
