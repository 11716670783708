import styled from 'styled-components';

export const StyledTable = styled.table`
&&& {
    thead tr th {
      border-bottom: solid 1px #d5dcec;
      padding: 15px 15px !important;
    }
    tbody tr:first-child td {
      border-top: solid 1px #d5dcec; 
    }
    tbody tr td {
      padding: 12px 15px !important;
      font-weight: normal;
      text-align: center;
    }
    tfoot tr td {
      padding: 15px 15px !important;
      border-top: solid 1px #d5dcec;
      text-align: center;
      font-weight: bold;
    }
  }
`;
