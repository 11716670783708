import constants from '../../_constants/balance-sheet.constants';

const eraseBalanceSheet = () => {
  return (dispatch) => {
    dispatch(request());

    dispatch(success());
  };

  function request() {
    return {
      type: constants.BALANCE_SHEET_ERASE_REQUEST,
    };
  }
  function success() {
    return {
      type: constants.BALANCE_SHEET_ERASE_SUCCESS,
    };
  }
};

export default eraseBalanceSheet;
