import styled from 'styled-components';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';

export const StyledListGroupItem = styled(ListGroup.Item)`  
  :hover {
    background-color: var(--tr-color-hover) !important;
    cursor: pointer;
  }

  &.active {
    background-color: #eceef1 !important;
    color: var(--table-color);
    border-color: rgba(0, 0, 0, 0.125)
  }
`;

export const StyledFormCheck = styled(Form.Check)`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-check-input {
    margin-top: 0;
    margin-left: 0;

    width: 17px !important;
    height: 17px !important;

    &:hover {
      cursor: pointer !important;
    }
  }
`;
