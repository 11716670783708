import styled from 'styled-components';

import LoadingOverlay from 'react-loading-overlay';

export const LoadOverlay = styled(LoadingOverlay)`
  .LoadingOverlayoverlay {
    background-color: rgba(255, 255, 255, 0.70);
    backdrop-filter: blur(1.2px);
    border: 0;
    border-radius: 4px;
    font-weight: 500;
    color: var(--accent-color);
  }
  .css-50etie svg circle {
    stroke: var(--accent-color);
    stroke-width: 3;
  }
`;
