import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';

import { PageHeader } from '_components/_core';
import { useTable } from '_components/_core/Table/utils';
import { DEFAULT_FILTERS } from '_components/_shared/InvoiceFilters/utilities';

import { Filters, Results } from './components';

const tableConfig = {
  defaultFilters: {},
  requiredFilters: ['date_competence_start', 'date_competence_end'],
  defaultSorting: { field: 'date_competence', order: 'desc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 500 },
};

function InvoicesReport({
  isLoading,
  invoices,
  activeCompany,
  onFetchInvoices,
  onClearInvoices,
}) {
  const location = useLocation();

  useEffect(() => {
    onClearInvoices();
  }, [onClearInvoices]);

  const {
    sorting,
    onFilter,
    onSorting,
  } = useTable({
    ...tableConfig,
    data: invoices,
    onFetchData: onFetchInvoices,
  });

  const defaultFilters = useMemo(() => {
    if (location.state) {
      const { defaultFilters } = location.state;

      onFilter(defaultFilters);

      return defaultFilters;
    }

    return DEFAULT_FILTERS;
  }, [location, onFilter]);

  const handleGenerateReport = useCallback((values) => {
    onFilter(values);
  }, [onFilter]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader title="Relatório de Notas Fiscais" description="Gere um relatório com o faturamento acumulado no período informado." />
      <Formik
        initialValues={defaultFilters}
        onSubmit={handleGenerateReport}
        enableReinitialize
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <>
            <Tab.Container defaultActiveKey="month">
              <Row>
                <Col lg={3} md={12}>
                  <Filters
                    values={values}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    isLoading={isLoading}
                  />
                </Col>
                <Col lg={9} md={12}>
                  <Results
                    values={values}
                    invoices={invoices}
                    activeCompany={activeCompany}
                    sorting={sorting}
                    onSorting={onSorting}
                  />
                </Col>
              </Row>
            </Tab.Container>
          </>
        )}
      </Formik>
    </Container>
  );
}

InvoicesReport.defaultProps = {
  invoices: null,
  isLoading: false,
};

InvoicesReport.propTypes = {
  isLoading: PropTypes.bool,
  invoices: PropTypes.object,
  activeCompany: PropTypes.object,
  onFetchInvoices: PropTypes.func.isRequired,
  onClearInvoices: PropTypes.func.isRequired,
};

export default InvoicesReport;
