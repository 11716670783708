import constants from '../../_constants/taxes.constants';
import service from '../../../services/taxes.service';

const fetchManagerDarfs = ({ year, hideLoading }, callback) => {
  return (dispatch) => {
    dispatch(request(hideLoading));

    service.fetchManagerDarfs(year).then(
      (response) => {
        const { data } = response;

        if (callback) {
          callback(data);
        }

        dispatch(success(data));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(hideLoading) {
    return {
      type: constants.FETCH_MANAGER_DARFS_REQUEST,
      payload: {
        hideLoading,
      },
    };
  }
  function success(data) {
    return {
      type: constants.FETCH_MANAGER_DARFS_SUCCESS,
      payload: {
        data,
      },
    };
  }
  function failure(data) {
    return {
      type: constants.FETCH_MANAGER_DARFS_FAILURE,
      payload: {
        data,
      },
    };
  }
};

export default fetchManagerDarfs;
