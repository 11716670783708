import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { TableLookup } from '_components/_core';
import { Pagination, ItemsPerPage } from '_components/_core/Table/components';
import { useTable } from '_components/_core/Table/utils';

import { TABLE_COLUMNS, DEFAULT_COLUMNS } from './utilities';

const tableConfig = {
  defaultFilters: {},
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

export default function ChildBenefitTables({
  isLoading,
  total,
  childBenefit,
  onFetchChildBenefit,
}) {
  const {
    pagination,
    onPageChange,
    onPageSizeChange,
  } = useTable({
    ...tableConfig,
    data: childBenefit,
    onFetchData: onFetchChildBenefit,
  });
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h4 className="mb-0">Salário Família</h4>
      </div>
      <TableLookup
        keyName="id"
        data={childBenefit}
        columns={TABLE_COLUMNS}
        defaultColumns={DEFAULT_COLUMNS}
        isLoading={isLoading}
        topDistance="calc(100vh - (70px + 61px + 65px + 1rem + 3rem + 66px + 1rem))"
      />
      <Row>
        <Col sm={2}>
          <ItemsPerPage
            itemsPerPage={pagination.itemsPerPage}
            onChange={onPageSizeChange}
            className="mt-3"
          />
        </Col>
        <Col className="d-flex flex-row-reverse">
          <Pagination
            {...pagination}
            total={total}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
    </>
  );
}

ChildBenefitTables.defaultProps = {
  isLoading: false,
};

ChildBenefitTables.propTypes = {
  total: PropTypes.number.isRequired,
  childBenefit: PropTypes.array.isRequired,
  onFetchChildBenefit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
