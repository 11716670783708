import styled, { css } from 'styled-components';
import Alert from 'react-bootstrap/Alert';

export const Plans = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  padding: 0;
`;

export const Plan = styled.li`
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 32px 24px;
  border-radius: 6px;
  box-shadow:0 1px 5px -3px rgb(0 0 0 / 75%);
  cursor: pointer;
  border: 2px solid rgba(70, 77, 228, 0.05);
  transition: all 200ms ease;
  transition-property: margin-top, border;

  &:hover {
    margin-top: -12px;
    border: 2px solid rgba(70, 77, 228, 1);
  }

  ${({ active }) => active && css`    
    margin-top: -12px;
    border: 2px solid rgba(70, 77, 228, 1);
  `}

  hr {
    width: 100%;
    border-top: 2px solid #EDF1F2;
    border-radius: 6px;
  }

  small {

  }
`;

export const PlanName = styled.h4`
  font-size: 1em;
  color: rgba(70, 77, 228, 1);
  text-transform: uppercase;
`;

export const PlanPrice = styled.h5`
  font-size: 2.5em;
  margin-bottom: 8px;
`;

export const PlanFeatures = styled.ul`
  padding: 0;
  list-style: none;
  font-size: 1.1em;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      margin-right: 8px;
      color: #FF6F59;
    }
  }
`;

export const TrialPeriodMessage = styled.h4`
  margin-top: 24px;
  font-size: 1em;
  color: rgba(70, 77, 228, 1);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
`;

export const StyledAlert = styled(Alert)`
  padding: 24px;
  font-weight: 400;
`;

export const StyledAlertHeading = styled(Alert.Heading)`
  color: #353bad !important;
`;
