import sortBy from 'lodash/sortBy';

import { SPECIAL_COLUMNS } from '_components/_core/Table';

import FORMATTERS from 'helpers/formatters';

import transformToFormValues from './transformToFormValues';

export const DEFAULT_COLUMNS = [
  'status',
  'created_at',
  'invoice_number',
  'date_competence',
  'service_taker_document_number',
  'service_taker_company_name',
  'service_gross_amount',
  'service_net_amount',
  'id',
];

export const TABLE_COLUMNS = [
  {
    label: 'Ações',
    keyName: 'id',
    align: 'center',
    width: 5,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'Data da Emissão',
    keyName: 'created_at',
    sortingKey: 'created_at',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.DATE_DDMMYYYYHHMMSS,
    order: 1,
  },
  {
    label: 'Status da Emissão',
    keyName: 'status',
    sortingKey: 'status',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.ISSUED_INVOICE_STATUS,
    order: 2,
  },
  {
    label: 'Número',
    keyName: 'invoice_number',
    sortingKey: 'invoice_number',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.SIGN_IF_EMPTY,
    order: 3,
  },
  {
    label: 'Documento Prestador',
    keyName: 'service_provider_document_number',
    sortingKey: 'service_provider_document_number',
    formatter: FORMATTERS.CPF_CNPJ,
    align: 'center',
    width: 10,
    order: 4,
  },
  {
    label: 'Prestador',
    keyName: 'service_provider_company_name',
    sortingKey: 'service_provider_company_name',
    width: 30,
    formatter: FORMATTERS.UPPERCASE,
    order: 5,
  },
  {
    label: 'Competência',
    keyName: 'date_competence',
    sortingKey: 'date_competence',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.DATE_MMYYYY,
    order: 6,
  },
  {
    label: 'Documento Tomador',
    keyName: 'service_taker_document_number',
    sortingKey: 'service_taker_document_number',
    formatter: FORMATTERS.CPF_CNPJ,
    align: 'center',
    width: 10,
    order: 7,
  },
  {
    label: 'Tomador',
    keyName: 'service_taker_company_name',
    sortingKey: 'service_taker_company_name',
    width: 40,
    formatter: FORMATTERS.UPPERCASE,
    order: 8,
  },
  {
    label: 'Descrição',
    keyName: 'service_description',
    sortingKey: 'service_description',
    width: 30,
    formatter: FORMATTERS.UPPERCASE,
    order: 9,
  },
  {
    label: 'Valor Bruto',
    keyName: 'service_gross_amount',
    sortingKey: 'service_gross_amount',
    align: 'center',
    width: 8,
    formatter: FORMATTERS.NUMBER,
    order: 10,
    sumOnFooter: true,
  },
  {
    label: 'Valor Líquido',
    keyName: 'service_net_amount',
    sortingKey: 'service_net_amount',
    align: 'center',
    width: 8,
    formatter: FORMATTERS.NUMBER,
    order: 11,
    sumOnFooter: true,
  },
  {
    label: 'Item Lista Serviço',
    keyName: 'service_item_code',
    sortingKey: 'service_item_code',
    align: 'center',
    width: 8,
    formatter: FORMATTERS.STRING,
    order: 12,
  },
  {
    label: 'Cód. Tributação no Município',
    keyName: 'service_district_tax_code',
    sortingKey: 'service_district_tax_code',
    align: 'center',
    width: 8,
    formatter: FORMATTERS.STRING,
    order: 13,
  },
];

export const getPersonsOptions = (invoiceGenerationParams) => {
  const { customers } = invoiceGenerationParams || {};

  if (!customers) {
    return [];
  }

  const formattedPerson = customers.map((person) => {
    const formattedPersonName = `${FORMATTERS.UPPERCASE(person.name)} - ${FORMATTERS.CPF_CNPJ(person.document_number)}`;

    return {
      value: person._id,
      label: formattedPersonName,
      name: FORMATTERS.UPPERCASE(person.name),
      document_number: person.document_number,
      document_type: person.document_type,
      email: person.email,
      phone_number: person.phone_number,
      address_zip_code: person.address_zip_code,
      address_street: person.address_street,
      address_number: person.address_number,
      address_complement: person.address_complement,
      address_district: person.address_district,
      address_city: person.address_city,
      address_city_ibge: person.address_city_ibge,
      address_state: person.address_state,
      address_state_ibge: person.address_state_ibge,
    };
  });

  return sortBy(formattedPerson, ['name']);
};

export const getCustomersOptions = (invoiceGenerationParams) => {
  const { customers } = invoiceGenerationParams || {};

  if (!customers) {
    return [];
  }

  const formatted = customers.map((customer) => {
    const isExterior = !customer.document_number;
    const formattedDocumentNumber = isExterior ? '00.000.000/0000-00' : FORMATTERS.CPF_CNPJ(customer.document_number);
    const formattedName = FORMATTERS.UPPERCASE(customer.name);

    return {
      value: customer.id,
      label: `${formattedName} - ${formattedDocumentNumber}`,
      document_number: customer.document_number,
      document_type: customer.document_type,
      email: customer.email,
      phone_number: customer.phone_number,
      address_zip_code: customer.address_zip_code,
      address_street: customer.address_street,
      address_number: customer.address_number,
      address_complement: customer.address_complement,
      address_district: customer.address_district,
      address_city: customer.address_city,
      address_city_ibge: customer.address_city_ibge,
      address_state: customer.address_state,
      address_state_ibge: customer.address_state_ibge,
    };
  });

  return sortBy(formatted, 'name');
};

export const getServicesOptions = (invoiceGenerationParams) => {
  const { service_list } = invoiceGenerationParams || {};

  if (!service_list) {
    return [];
  }

  const formatted = service_list.map((serviceListItem) => ({
    value: serviceListItem.id,
    label: `${serviceListItem.subitem} - ${serviceListItem.service_district_tax_code} - ${serviceListItem.description}`,
    service_district_tax_code: serviceListItem.service_district_tax_code,
    subitem: serviceListItem.subitem,
  }));

  return formatted;
};

const FILTERS_CONFIGURATION = {
  date_competence_start: {
    label: 'Competência a partir de',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  date_competence_end: {
    label: 'Competência até',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  date_emission_start: {
    label: 'Emissão a partir de',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  date_emission_end: {
    label: 'Emissão até',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  invoice_number: {
    label: 'Número da Nota Fiscal',
  },
  emission_status: {
    label: 'Status da Emissão',
    formatter: FORMATTERS.ISSUED_INVOICE_STATUS_TEXT,
  },
  service_taker: {
    label: 'Tomador contém',
    formatter: FORMATTERS.STRING_WITH_QUOTES,
  },
  service_amount_start: {
    label: 'Valor Bruto a partir de',
    formatter: FORMATTERS.NUMBER,
  },
  service_amount_end: {
    label: 'Valor Bruto até',
    formatter: FORMATTERS.NUMBER,
  },
};

export {
  transformToFormValues,
  FILTERS_CONFIGURATION,
};

export default TABLE_COLUMNS;
