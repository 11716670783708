import styled, { css } from 'styled-components';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';

import { Button, Table } from '_components/_core';

export const Menu = styled(ListGroup)`
  font-family: var(--font-family);
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
  rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
  rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, 
  rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: var(--card-border);
  background-color: var(--card-bg-color);

  button.list-group-item {
    cursor: pointer;
    border: none;
    border-radius: 0 !important;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 32px;
  }

  button.list-group-item.active {
    color: #727cf5;
    background-color: rgba(114, 124, 245, 0.1);
    font-weight: 500;
  }
`;

export const MenuCategory = styled.span`
  padding: 18px 20px 14px 20px;
  color: rgba(26,51,83,.6);
  font-size: 12px;
  line-height: 1.5;
  font-weight: 700;
  text-transform: uppercase;

  ${({ noPaddingBottom }) => noPaddingBottom && css`
    padding-bottom: 0px;
  `}

  ${({ noPaddingTop }) => noPaddingTop && css`
    padding-top: 0px;
  `}
`;

export const MenuItem = styled(ListGroup.Item)`
  
`;

export const StyledFormRow = styled(Form.Row)`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
`;

export const GenerateReportButton = styled(Button)`
  margin-top: 16px !important;
  margin-bottom: 8px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
  border-radius: 4px;
`;

export const StyledTable = styled(Table)`
  &&& {

    thead tr th {
      padding: 10px 5px !important;
    }

    tbody tr td {
      padding: 10px 5px !important;
      font-size: 11px !important;
    }

    @media print {
      table-layout: fixed;

      thead tr th {
        padding: 10px 5px !important;
      }

      tbody tr td {
        padding: 10px 5px !important;
      }
    }
  }
`;
