import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import StyledFormCheck from '../StyledFormCheck';

import styles from '../../styles.module.scss';

function Footer({
  data,
  footerData,
  keyName,
  selectedItems,
  visibleColumns,
  onItemSelected,
  onSelectAll,
}) {
  const renderValue = useCallback((column) => {
    if (column.sumOnFooter) {
      if (isEmpty(selectedItems)) {
        let total = 0;

        const correctData = !isEmpty(footerData) ? footerData : data;

        for (const row of correctData) {
          total += Number(row[column.keyName]);
        }

        return column.formatter ? column.formatter(total) : total;
      }

      let total = 0;

      const correctData = !isEmpty(footerData) ? footerData : data;

      const selectedData = correctData.filter((row) => selectedItems.includes(row[keyName]));

      for (const row of selectedData) {
        total += Number(row[column.keyName]);
      }

      return column.formatter ? column.formatter(total) : total;
    }

    return '';
  }, [data, footerData, keyName, selectedItems]);

  return (
    <tfoot>
      <tr>
        {onItemSelected && (
          <th width="2%" className={classNames(styles.footerCell, styles.noPrint)}>
            <StyledFormCheck
              type="checkbox"
              checked={!isEmpty(data) && selectedItems.length === data.length}
              onClick={onSelectAll}
            />
          </th>
        )}
        {visibleColumns.map((column, index) => (
          <td
            key={`td-footer-${index}`}
            className={styles.footerCell}
            align={column.align}
            style={{ textAlign: column.align, width: `${column.width}%` }}
          >
            <span>
              {renderValue(column)}
            </span>
          </td>
        ))}
      </tr>
    </tfoot>
  );
}

Footer.defaultProps = {
  onItemSelected: null,
  data: [],
  footerData: [],
  selectedItems: [],
  onSelectAll: null,
};

Footer.propTypes = {
  keyName: PropTypes.string.isRequired,
  onItemSelected: PropTypes.func,
  data: PropTypes.array,
  footerData: PropTypes.array,
  selectedItems: PropTypes.array,
  visibleColumns: PropTypes.array.isRequired,
  onSelectAll: PropTypes.func,
};

export default Footer;
