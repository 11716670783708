import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { DateTime } from 'luxon';

import {
  Card,
  CardHeader,
  CardBody,
  FormTextField,
  FormCpfCnpjField,
  FormDateField,
  FormSelectField,
} from '_components/_core';

import { STATUS_IRS } from 'helpers/constants';
import { SearchButton } from './styles';

function CompanyInfoTab({
  isSearching,
  onClearSearchedCompany,
  onSearchCompanyByCnpj,
  onFetchCities,
}) {
  const formik = useFormikContext();
  const { id } = useParams();

  const handleCompanySearch = useCallback(() => {
    onClearSearchedCompany();
    onSearchCompanyByCnpj(formik.values.document_number, (searchedCompany) => {
      onFetchCities(searchedCompany.address_state_ibge, () => {
        formik.setFieldValue('company_name', searchedCompany.company_name);
        formik.setFieldValue('date_opened', searchedCompany.date_opened);
        formik.setFieldValue('phone_number', searchedCompany.phone_number);
        formik.setFieldValue('email', searchedCompany.email);
        formik.setFieldValue('status_irs', searchedCompany.status_irs);
        formik.setFieldValue('address_zip_code', searchedCompany.address_zip_code);
        formik.setFieldValue('address_street', searchedCompany.address_street);
        formik.setFieldValue('address_number', searchedCompany.address_number);
        formik.setFieldValue('address_district', searchedCompany.address_district);
        formik.setFieldValue('address_complement', searchedCompany.complement);
        formik.setFieldValue('address_city', searchedCompany.address_city);
        formik.setFieldValue('address_city_ibge', Number(searchedCompany.address_city_ibge));
        formik.setFieldValue('address_state', searchedCompany.address_state);
        formik.setFieldValue('address_state_ibge', Number(searchedCompany.address_state_ibge));
        formik.setFieldValue('cnaes', searchedCompany.cnaes);
        formik.setFieldValue('date_founded', DateTime.fromISO(searchedCompany.date_founded).toJSDate());

        if (!id) {
          formik.setFieldValue('tax_regimes', searchedCompany.tax_regimes);
        }

        if (searchedCompany.trading_name) {
          formik.setFieldValue('trading_name', searchedCompany.trading_name);
        }
      });
    });
  }, [formik, onFetchCities, onClearSearchedCompany, onSearchCompanyByCnpj, id]);

  return (
    <Card noBorders>
      <CardHeader title="Dados cadastrais" description="Dados da empresa contidos no cartão CNPJ." />
      <CardBody>
        <Form>
          <Form.Row>
            <Col md="3">
              <Form.Group>
                <Form.Label>CNPJ</Form.Label>
                <FormCpfCnpjField type="CNPJ" name="document_number" placeholder="Digite o CNPJ da empresa" />
              </Form.Group>
            </Col>
            <Col xl={2}>
              <SearchButton
                variant="secondary"
                isLoading={isSearching}
                disabled={isSearching}
                onClick={handleCompanySearch}
              >
                Buscar CNPJ
              </SearchButton>
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="3">
              <Form.Label>Razão Social</Form.Label>
              <FormTextField name="company_name" placeholder="Razão Social" />
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>Nome Fantasia</Form.Label>
              <FormTextField name="trading_name" placeholder="Nome Fantasia" />
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>Inscrição Municipal</Form.Label>
              <FormTextField name="municipal_registration" placeholder="Inscrição Municipal" />
            </Form.Group>
          </Form.Row>
          <Form.Row />
          <Form.Row>
            <Form.Group as={Col} md="3">
              <Form.Label>Data de Abertura</Form.Label>
              <FormDateField name="date_founded" placeholder="Data de Abertura" />
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>Cliente desde</Form.Label>
              <FormDateField name="client_since" placeholder="Cliente desde" />
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>Telefone</Form.Label>
              <FormTextField name="phone_number" placeholder="Telefone" />
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>E-mail</Form.Label>
              <FormTextField name="email" placeholder="E-mail" />
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>Situação na Receita Federal</Form.Label>
              <FormSelectField name="status_irs" options={STATUS_IRS} />
            </Form.Group>
          </Form.Row>
        </Form>
      </CardBody>
    </Card>
  );
}

CompanyInfoTab.propTypes = {
  isSearching: PropTypes.bool,
  onClearSearchedCompany: PropTypes.func.isRequired,
  onSearchCompanyByCnpj: PropTypes.func.isRequired,
  onFetchCities: PropTypes.func.isRequired,
};

export default CompanyInfoTab;
