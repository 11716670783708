import { connect } from 'react-redux';

import taxesActionCreators from '_store/_actions/taxes';

import CalculateTaxesModal from './CalculateTaxesModal';

const mapStateToProps = (state) => ({
  isLoading: state.taxes.isLoading,
  calculatedTaxes: state.taxes.calculatedTaxes,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onCalculateTaxes: taxesActionCreators.calculateTaxes,
  onFetchTaxes: taxesActionCreators.fetchTaxes,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculateTaxesModal);
