import { connect } from 'react-redux';
import { compose } from 'redux';

import logsActionCreators from '_store/_actions/logs';
import userActionCreators from '_store/_actions/user';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Logs from './Logs';

const mapStateToProps = (state) => ({
  total: state.logs.total,
  logs: state.logs.logs,
  // entities: state.logs.entities,
  possibleEntities: state.logs.possibleEntities,
  users: state.user.usersForSelect,
  isLoading: state.logs.isLoading,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onFetchLogs: logsActionCreators.fetchLogs,
  onFetchUsers: userActionCreators.fetchUsersForSelect,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['manager_logs']),
)(Logs);
