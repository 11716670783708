import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';

import { Button, FormTextField } from '_components/_core';

import { StyledModal, ModalFooter } from './styles';

export default function AddEmployeeRoleModal({
  isVisible,
  employeeRole,
  onModalToggle,
  onAddEmployeeRole,
  onUpdateEmployeeRole,
  userInputValue,
}) {
  const handleAddEditEmployeeRole = useCallback((values) => {
    if (values.id) {
      onUpdateEmployeeRole(values);
    } else {
      onAddEmployeeRole(values);
    }

    onModalToggle();
  }, [onUpdateEmployeeRole, onAddEmployeeRole, onModalToggle]);

  const renderModalFooter = useCallback((handleSubmit) => (
    <ModalFooter>
      <Button variant="secondary" onClick={onModalToggle}>
        Cancelar
      </Button>
      <Button type="submit" variant="primary" onClick={handleSubmit}>
        Salvar
      </Button>
    </ModalFooter>
  ), [onModalToggle]);

  return (
    <Formik
      initialValues={{
        id: employeeRole ? employeeRole.id : null,
        description: employeeRole ? employeeRole.description : userInputValue,
        cbo: employeeRole ? employeeRole.cbo : '',
      }}
      onSubmit={handleAddEditEmployeeRole}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <>
          <StyledModal
            title={employeeRole ? 'Editar cargo' : 'Cadastrar novo cargo'}
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>Descrição</Form.Label>
                  <FormTextField
                    name="description"
                    placeholder="Digite o nome do cargo, exemplo: Vendedor"
                  />
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Label>CBO</Form.Label>
                  <FormTextField
                    name="cbo"
                    placeholder="CBO"
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
}

AddEmployeeRoleModal.defaultProps = {
  isVisible: false,
  employeeRole: null,
  userInputValue: '',
};

AddEmployeeRoleModal.propTypes = {
  isVisible: PropTypes.bool,
  employeeRole: PropTypes.object,
  onModalToggle: PropTypes.func.isRequired,
  onAddEmployeeRole: PropTypes.func.isRequired,
  onUpdateEmployeeRole: PropTypes.func.isRequired,
  userInputValue: PropTypes.string,
};
