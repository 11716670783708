import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const deleteCategory = (id, callback) => {
  return async (dispatch) => {
    confirmDialog.warning({
      title: 'Excluir Categoria',
      message: 'Tem certeza que deseja excluir esta categoria? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, excluir!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.deleteCategory(id).then(
          (response) => {
            dispatch(success(response));
            dispatch(alertActionCreators.success('Categoria excluída com sucesso!'));

            if (callback) {
              callback();
            }
          },
        ).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request(id) {
    return {
      type: constants.DELETE_TRANSACTION_CATEGORY_REQUEST,
      payload: {
        id,
      },
    };
  }
  function success(response) {
    return {
      type: constants.DELETE_TRANSACTION_CATEGORY_SUCCESS,
      payload: response.data,
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_TRANSACTION_CATEGORY_FAILURE,
      payload: error,
    };
  }
};

export default deleteCategory;
