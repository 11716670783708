import { connect } from 'react-redux';

import employeeActionCreators from '_store/_actions/employees';

import Vacations from './Vacations';

const mapStateToProps = (state) => ({
  employeeVacations: state.employees.employeeVacations,
});

const mapDispatchToProps = {
  onFetchEmployeeVacations: employeeActionCreators.getEmployeeVacations,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Vacations);
