import FORMATTERS from 'helpers/formatters';
import { SPECIAL_COLUMNS } from '_components/_core/Table';

export const DEFAULT_COLUMNS = [
  'id',
  'date_competence',
  'situacao',
  'gross_revenue',
  'due_date',
  'das_url',
  'numero_apuracao',
  'numero_das',
  'simples_nacional',
  'valor_principal',
  'valor_multa',
  'valor_juros',
  'valor_total',
];

export const TABLE_COLUMNS = [
  {
    label: 'Recalcular',
    keyName: 'id',
    align: 'center',
    width: 10,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'Competência',
    keyName: 'date_competence',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.DATE_MMYYYY,
    order: 2,
  },
  {
    label: 'Situação',
    keyName: 'situacao',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.MEI_STATUS,
    order: 2,
  },
  {
    label: 'Receita Bruta',
    keyName: 'gross_revenue',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 3,
    sumOnFooter: true,
  },
  {
    label: 'Data Vencimento',
    keyName: 'due_date',
    align: 'center',
    formatter: FORMATTERS.DATE_DDMMYYYY,
    width: 10,
    order: 4,
  },
  {
    label: 'Valor Principal',
    keyName: 'valor_principal',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 6,
    sumOnFooter: true,
  },
  {
    label: 'Multa',
    keyName: 'valor_multa',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 6,
    sumOnFooter: true,
  },
  {
    label: 'Juros',
    keyName: 'valor_juros',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 6,
    sumOnFooter: true,
  },
  {
    label: 'Valor Total',
    keyName: 'valor_total',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 6,
    sumOnFooter: true,
  },
  {
    label: 'Guia DAS',
    keyName: 'das_url',
    align: 'center',
    formatter: FORMATTERS.DOWNLOAD_LINK,
    width: 10,
    order: 5,
  },
];
