import styled, { css } from 'styled-components';

export const StyledSection = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    
  }
`;

export const ListContainer = styled.ul``;

export const List = styled.ul`
  list-style: none;
  padding: 0;
`;

export const ListItem = styled.li`
  
  strong {
    margin-left: 0;
    font-weight: 600;
  }
`;

export const PerformanceCard = styled.div`
  border-left: 5px solid #D4D7DC;
  padding: 2px 0 2px 10px;

  ${({ borderColor }) => borderColor && css`
    border-left-color: ${borderColor};
  `}

  p {
    font-size: 0.9rem;
    text-transform: uppercase;
    margin-bottom: 0.25rem;
  }

  h4 {
    font-size: 2rem;
    color: #a8abad;
  }
`;
