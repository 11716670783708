import getInstalmentDate from './getInstalmentDate';
import getInstalmentOptions from './getInstalmentsOptions';

export const INTALMENTS_OPTIONS = Array.from({ length: 60 }, (_, i) => i + 1).map((instalment) => ({
  value: instalment,
  label: instalment === 1 ? 'À vista' : `${instalment} parcelas`,
}));

export {
  getInstalmentDate,
  getInstalmentOptions,
};
