import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Tag from '_components/_core/Tag/Tag';
import { FaStar } from 'react-icons/fa';

function BankAccountNameTag({ account_id, accounts }) {
  const account = useMemo(() => {
    const account = accounts.find((account) => account.id === account_id) || {};

    const { description, is_principal } = account || {};

    return {
      description,
      is_principal,
    };
  }, [accounts, account_id]);

  return (
    <>
      {account.description}
      {account.is_principal && (
        <Tag className="ml-3" variant="warning">
          <FaStar className="mr-1" />
          CONTA PRINCIPAL
        </Tag>
      )}
    </>
  );
}

BankAccountNameTag.defaultProps = {
  accounts: [],
};

BankAccountNameTag.propTypes = {
  account_id: PropTypes.string.isRequired,
  accounts: PropTypes.array,
};

export default BankAccountNameTag;
