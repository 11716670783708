import React from 'react';
import PropTypes from 'prop-types';

import { StyledAlert, StyledAlertHeading } from './styles';

function CustomAlert({
  variant,
  title,
  description,
  ...rest
}) {
  return (
    <StyledAlert variant={variant} {...rest}>
      {title && (<StyledAlertHeading>{title}</StyledAlertHeading>)}
      {description}
    </StyledAlert>
  );
}

CustomAlert.defaultProps = {
  variant: 'primary',
};

CustomAlert.propTypes = {
  variant: PropTypes.oneOf(
    [
      'primary',
      'secondary',
      'success',
      'danger',
      'warning',
      'info',
      'dark',
      'light',
    ],
  ),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default CustomAlert;
