import { DateTime } from 'luxon';
import has from 'lodash/has';

const getTransactionDate = (transaction = {}, date_type) => {
  if (!transaction) {
    return '-';
  }

  const field = date_type || 'event_date';

  let dateValue = null;

  if (has(transaction, field)) {
    dateValue = transaction[field];
  } else {
    dateValue = transaction.event_date;
  }

  if (!dateValue) {
    return '-';
  }

  return DateTime.fromISO(dateValue, { zone: 'utc' }).set({ hour: 12 }).setLocale('pt-BR').toJSDate();
};

export default getTransactionDate;
