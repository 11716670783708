import { DateTime } from 'luxon';
import isEmpty from 'lodash/isEmpty';
import * as XLSX from 'sheetjs-style';

import { CELL_TYPES } from './constants';

const reportToExcel = ({
  report_name,
  reports,
}) => {
  const { results } = reports[report_name] || [];

  const verifyIsEmpty = (results) => {
    if (!isEmpty(results.categories)) return false;
    if (!isEmpty(results.incomes)) return false;
    return true;
  };

  if (verifyIsEmpty(results)) return;

  const total = results.incomes.reduce((total, transaction) => total + transaction[2], 0);

  const spreadsheetData = [['Data', 'Porcentagem', 'Valor']];

  const transactionsType = [null, 'HEADERS'];

  for (const transaction of results.incomes) {
    const formattedEventDate = DateTime.fromISO(transaction[1], {
      zone: 'utc',
    }).set({ hour: 12 }).setLocale('pt-BR').toJSDate();

    const percent = (transaction[2] / total);

    spreadsheetData.push([formattedEventDate, percent, transaction[2]]);
    transactionsType.push('INCOME');
  }

  spreadsheetData.push(
    [null, null, null],
    ['Total', '-', total],
  );

  transactionsType.push(null, 'HEADERS');

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(spreadsheetData);

  worksheet['!cols'] = [{ width: 16 }, { width: 16 }, { width: 16 }];

  for (let i = 1; i <= spreadsheetData.length; i += 1) {
    if (transactionsType[i] !== null) {
      worksheet[`A${i}`].s = CELL_TYPES[transactionsType[i]];
      worksheet[`B${i}`].s = CELL_TYPES[transactionsType[i]];
      worksheet[`C${i}`].s = CELL_TYPES[transactionsType[i]];
    }
    if (i !== 1 && i !== spreadsheetData.length - 1 && i !== spreadsheetData.length) {
      worksheet[`B${i}`].t = 'n';
      worksheet[`B${i}`].z = '0.00%';
      worksheet[`C${i}`].t = 'n';
      worksheet[`C${i}`].z = '#,##0.00';
    } else if (i === spreadsheetData.length) {
      worksheet[`C${i}`].t = 'n';
      worksheet[`C${i}`].z = '#,##0.00';
    }
  }

  worksheet['!merges'] = [
    { s: { r: spreadsheetData.length - 1, c: 0 }, e: { r: spreadsheetData.length - 1, c: 1 } },
  ];

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Receitas_Por_Dia');

  XLSX.writeFile(workbook, 'Zenply_Receitas_Por_Dia.xlsx', {
    type: 'array',
    bookType: 'xlsx',
  });
};

export default reportToExcel;
