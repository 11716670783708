import styled from 'styled-components';

import { Select } from '_components/_core';

export const StyledSelect = styled(Select)`
  &&& {
    .css-1t7hbm9-option,
    .css-1dinwnf-option {
      color: #6c757d;
      font-size: 13px !important;
    }

    .css-b8ldur-Input input {
      font-size: 14px !important;
    }

    .css-12jo7m5 {
      font-size: 95% !important;
    }

    .css-1wa3eu0-placeholder {
      color: #c9c8c8;
    }

    .css-26l3qy-menu {
      z-index: 2 !important;
    }
  }
`;
