export const companyConstants = {
  COMPANY_GET_REQUEST: 'COMPANY_GET_REQUEST',
  COMPANY_GET_SUCCESS: 'COMPANY_GET_SUCCESS',
  COMPANY_GET_FAILURE: 'COMPANY_GET_FAILURE',
  COMPANY_GET_ALL_REQUEST: 'COMPANY_GET_ALL_REQUEST',
  COMPANY_GET_ALL_SUCCESS: 'COMPANY_GET_ALL_SUCCESS',
  COMPANY_GET_ALL_FAILURE: 'COMPANY_GET_ALL_FAILURE',
  COMPANY_GET_ACTIVE_REQUEST: 'COMPANY_GET_ACTIVE_REQUEST',
  COMPANY_GET_ACTIVE_SUCCESS: 'COMPANY_GET_ACTIVE_SUCCESS',
  COMPANY_GET_ACTIVE_FAILURE: 'COMPANY_GET_ACTIVE_FAILURE',
  COMPANY_SET_ACTIVE_REQUEST: 'COMPANY_SET_ACTIVE_REQUEST',
  COMPANY_SET_ACTIVE_SUCCESS: 'COMPANY_SET_ACTIVE_SUCCESS',
  COMPANY_SET_ACTIVE_FAILURE: 'COMPANY_SET_ACTIVE_FAILURE',
  COMPANY_ADD_REQUEST: 'COMPANY_ADD_REQUEST',
  COMPANY_ADD_SUCCESS: 'COMPANY_ADD_SUCCESS',
  COMPANY_ADD_FAILURE: 'COMPANY_ADD_FAILURE',
  COMPANY_UPDATE_REQUEST: 'COMPANY_UPDATE_REQUEST',
  COMPANY_UPDATE_SUCCESS: 'COMPANY_UPDATE_SUCCESS',
  COMPANY_UPDATE_FAILURE: 'COMPANY_UPDATE_FAILURE',
  COMPANY_DELETE_REQUEST: 'COMPANY_DELETE_REQUEST',
  COMPANY_DELETE_SUCCESS: 'COMPANY_DELETE_SUCCESS',
  COMPANY_DELETE_FAILURE: 'COMPANY_DELETE_FAILURE',
  COMPANY_SET_ACTIVE_PERIOD_REQUEST: 'COMPANY_SET_ACTIVE_PERIOD_REQUEST',
  COMPANY_SET_ACTIVE_PERIOD_SUCCESS: 'COMPANY_SET_ACTIVE_PERIOD_SUCCESS',
  COMPANY_SET_ACTIVE_PERIOD_FAILURE: 'COMPANY_SET_ACTIVE_PERIOD_FAILURE',
  FETCH_COMPANIES_REQUEST: 'FETCH_COMPANIES_REQUEST',
  FETCH_COMPANIES_SUCCESS: 'FETCH_COMPANIES_SUCCESS',
  FETCH_COMPANIES_FAILED: 'FETCH_COMPANIES_FAILED',
  COMPANY_SEARCH_BY_CNPJ_REQUEST: 'COMPANY_SEARCH_BY_CNPJ_REQUEST',
  COMPANY_SEARCH_BY_CNPJ_SUCCESS: 'COMPANY_SEARCH_BY_CNPJ_SUCCESS',
  COMPANY_SEARCH_BY_CNPJ_FAILURE: 'COMPANY_SEARCH_BY_CNPJ_FAILURE',
  CLEAR_SEARCHED_COMPANY_REQUEST: 'CLEAR_SEARCHED_COMPANY_REQUEST',
  COMPANY_CERTIFICATE_SAVE_REQUEST: 'COMPANY_CERTIFICATE_SAVE_REQUEST',
  COMPANY_CERTIFICATE_SAVE_SUCCESS: 'COMPANY_CERTIFICATE_SAVE_SUCCESS',
  COMPANY_CERTIFICATE_SAVE_FAILURE: 'COMPANY_CERTIFICATE_SAVE_FAILURE',
  SET_SELECTED_COMPANY_REQUEST: 'SET_SELECTED_COMPANY_REQUEST',
  SET_SELECTED_COMPANY_SUCCESS: 'SET_SELECTED_COMPANY_SUCCESS',
  SET_SELECTED_COMPANY_FAILURE: 'SET_SELECTED_COMPANY_FAILURE',
  COMPANY_FETCH_CNPJ_CERTIFICATE_REQUEST: 'COMPANY_FETCH_CNPJ_CERTIFICATE_REQUEST',
  COMPANY_FETCH_CNPJ_CERTIFICATE_SUCCESS: 'COMPANY_FETCH_CNPJ_CERTIFICATE_SUCCESS',
  COMPANY_FETCH_CNPJ_CERTIFICATE_FAILURE: 'COMPANY_FETCH_CNPJ_CERTIFICATE_FAILURE',

  ADD_COMPANY_CREDENTIAL_REQUEST: 'ADD_COMPANY_CREDENTIAL_REQUEST',
  ADD_COMPANY_CREDENTIAL_SUCCESS: 'ADD_COMPANY_CREDENTIAL_SUCCESS',
  ADD_COMPANY_CREDENTIAL_FAILURE: 'ADD_COMPANY_CREDENTIAL_FAILURE',

  UPDATE_COMPANY_CREDENTIAL_REQUEST: 'UPDATE_COMPANY_CREDENTIAL_REQUEST',
  UPDATE_COMPANY_CREDENTIAL_SUCCESS: 'UPDATE_COMPANY_CREDENTIAL_SUCCESS',
  UPDATE_COMPANY_CREDENTIAL_FAILURE: 'UPDATE_COMPANY_CREDENTIAL_FAILURE',

  DELETE_COMPANY_CREDENTIAL_REQUEST: 'DELETE_COMPANY_CREDENTIAL_REQUEST',
  DELETE_COMPANY_CREDENTIAL_SUCCESS: 'DELETE_COMPANY_CREDENTIAL_SUCCESS',
  DELETE_COMPANY_CREDENTIAL_FAILURE: 'DELETE_COMPANY_CREDENTIAL_FAILURE',

  ADD_COMPANY_FEE_REQUEST: 'ADD_COMPANY_FEE_REQUEST',
  ADD_COMPANY_FEE_SUCCESS: 'ADD_COMPANY_FEE_SUCCESS',
  ADD_COMPANY_FEE_FAILURE: 'ADD_COMPANY_FEE_FAILURE',

  UPDATE_COMPANY_FEE_REQUEST: 'UPDATE_COMPANY_FEE_REQUEST',
  UPDATE_COMPANY_FEE_SUCCESS: 'UPDATE_COMPANY_FEE_SUCCESS',
  UPDATE_COMPANY_FEE_FAILURE: 'UPDATE_COMPANY_FEE_FAILURE',

  DELETE_COMPANY_FEE_REQUEST: 'DELETE_COMPANY_FEE_REQUEST',
  DELETE_COMPANY_FEE_SUCCESS: 'DELETE_COMPANY_FEE_SUCCESS',
  DELETE_COMPANY_FEE_FAILURE: 'DELETE_COMPANY_FEE_FAILURE',

  ACTIVATE_INVOICE_ISSUING_REQUEST: 'ACTIVATE_INVOICE_ISSUING_REQUEST',
  ACTIVATE_INVOICE_ISSUING_SUCCESS: 'ACTIVATE_INVOICE_ISSUING_SUCCESS',
  ACTIVATE_INVOICE_ISSUING_FAILURE: 'ACTIVATE_INVOICE_ISSUING_FAILURE',

  COMPANY_GET_REVENUE_REQUEST: 'COMPANY_GET_REVENUE_REQUEST',
  COMPANY_GET_REVENUE_SUCCESS: 'COMPANY_GET_REVENUE_SUCCESS',
  COMPANY_GET_REVENUE_FAILURE: 'COMPANY_GET_REVENUE_FAILURE',

  COMPANY_ADD_REVENUE_REQUEST: 'COMPANY_ADD_REVENUE_REQUEST',
  COMPANY_ADD_REVENUE_SUCCESS: 'COMPANY_ADD_REVENUE_SUCCESS',
  COMPANY_ADD_REVENUE_FAILURE: 'COMPANY_ADD_REVENUE_FAILURE',

  ADD_COMPANY_DIGITAL_CERTIFICATE_REQUEST: 'ADD_COMPANY_DIGITAL_CERTIFICATE_REQUEST',
  ADD_COMPANY_DIGITAL_CERTIFICATE_SUCCESS: 'ADD_COMPANY_DIGITAL_CERTIFICATE_SUCCESS',
  ADD_COMPANY_DIGITAL_CERTIFICATE_FAILURE: 'ADD_COMPANY_DIGITAL_CERTIFICATE_FAILURE',

  DELETE_COMPANY_DIGITAL_CERTIFICATE_REQUEST: 'DELETE_COMPANY_DIGITAL_CERTIFICATE_REQUEST',
  DELETE_COMPANY_DIGITAL_CERTIFICATE_SUCCESS: 'DELETE_COMPANY_DIGITAL_CERTIFICATE_SUCCESS',
  DELETE_COMPANY_DIGITAL_CERTIFICATE_FAILURE: 'DELETE_COMPANY_DIGITAL_CERTIFICATE_FAILURE',

  FETCH_COMPANIES_FOR_SELECT_REQUEST: 'FETCH_COMPANIES_FOR_SELECT_REQUEST',
  FETCH_COMPANIES_FOR_SELECT_SUCCESS: 'FETCH_COMPANIES_FOR_SELECT_SUCCESS',
  FETCH_COMPANIES_FOR_SELECT_FAILURE: 'FETCH_COMPANIES_FOR_SELECT_FAILURE',

  ADD_COMPANY_AVATAR_REQUEST: 'ADD_COMPANY_AVATAR_REQUEST',
  ADD_COMPANY_AVATAR_SUCCESS: 'ADD_COMPANY_AVATAR_SUCCESS',
  ADD_COMPANY_AVATAR_FAILURE: 'ADD_COMPANY_AVATAR_FAILURE',

  DELETE_COMPANY_AVATAR_REQUEST: 'DELETE_COMPANY_AVATAR_REQUEST',
  DELETE_COMPANY_AVATAR_SUCCESS: 'DELETE_COMPANY_AVATAR_SUCCESS',
  DELETE_COMPANY_AVATAR_FAILURE: 'DELETE_COMPANY_AVATAR_FAILURE',

  FETCH_COMPANY_MARKET_SEGMENT_REQUEST: 'FETCH_COMPANY_MARKET_SEGMENT_REQUEST',
  FETCH_COMPANY_MARKET_SEGMENT_SUCCESS: 'FETCH_COMPANY_MARKET_SEGMENT_SUCCESS',
  FETCH_COMPANY_MARKET_SEGMENT_FAILURE: 'FETCH_COMPANY_MARKET_SEGMENT_FAILURE',

  ADD_FINANCIAL_COMPANY_REQUEST: 'ADD_FINANCIAL_COMPANY_REQUEST',
  ADD_FINANCIAL_COMPANY_SUCCESS: 'ADD_FINANCIAL_COMPANY_SUCCESS',
  ADD_FINANCIAL_COMPANY_FAILURE: 'ADD_FINANCIAL_COMPANY_FAILURE',

  UPDATE_FINANCIAL_COMPANY_REQUEST: 'UPDATE_FINANCIAL_COMPANY_REQUEST',
  UPDATE_FINANCIAL_COMPANY_SUCCESS: 'UPDATE_FINANCIAL_COMPANY_SUCCESS',
  UPDATE_FINANCIAL_COMPANY_FAILURE: 'UPDATE_FINANCIAL_COMPANY_FAILURE',

  DELETE_FINANCIAL_COMPANY_REQUEST: 'DELETE_FINANCIAL_COMPANY_REQUEST',
  DELETE_FINANCIAL_COMPANY_SUCCESS: 'DELETE_FINANCIAL_COMPANY_SUCCESS',
  DELETE_FINANCIAL_COMPANY_FAILURE: 'DELETE_FINANCIAL_COMPANY_FAILURE',

  ALLOWED_PERIOD_GET_REQUEST: 'ALLOWED_PERIOD_GET_REQUEST',
  ALLOWED_PERIOD_GET_SUCCESS: 'ALLOWED_PERIOD_GET_SUCCESS',
  ALLOWED_PERIOD_GET_FAILURE: 'ALLOWED_PERIOD_GET_FAILURE',

  UPDATE_COMPANY_ACTIVE_PERIOD_REQUEST: 'UPDATE_COMPANY_ACTIVE_PERIOD_REQUEST',
  UPDATE_COMPANY_ACTIVE_PERIOD_SUCCESS: 'UPDATE_COMPANY_ACTIVE_PERIOD_SUCCESS',
  UPDATE_COMPANY_ACTIVE_PERIOD_FAILURE: 'UPDATE_COMPANY_ACTIVE_PERIOD_FAILURE',
};

export default companyConstants;
