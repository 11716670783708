import { connect } from 'react-redux';

import payrollActionCreators from '_store/_actions/payroll';

import ChildBenefitTables from './ChildBenefitTables';

const mapStateToProps = (state) => ({
  childBenefit: state.payroll.childBenefitTables,
  total: state.payroll.childBenefitTotal,
});

const mapDispatchToProps = {
  onFetchChildBenefit: payrollActionCreators.getChildBenefitTables,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChildBenefitTables);
