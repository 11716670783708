import axios from 'axios';

import constants from '../../_constants/ibge.constants';

const fetchStates = (callback) => (dispatch) => {
  dispatch(({ type: constants.FETCH_STATES_REQUEST }));

  axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
    .then((response) => {
      const { data } = response;

      const formattedStates = data.map((state) => ({
        value: state.id,
        label: state.nome,
        sigla: state.sigla,
      }));

      dispatch({
        type: constants.FETCH_STATES_SUCCESS,
        payload: {
          states: formattedStates,
        },
      });

      if (callback) {
        callback(formattedStates);
      }
    }).catch((error) => {
      dispatch(({ type: constants.FETCH_STATES_FAILURE, payload: error }));
    });
};

export default fetchStates;
