import constants from '../../_constants/dre.constants';
import service from '../../../services/dre.service';

const generateDRE = (filters) => {
  return (dispatch) => {
    dispatch(request(filters));

    service.generate(filters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.response.data));
      },
    );
  };

  function request(filters) {
    return {
      type: constants.DRE_GET_REQUEST,
      payload: { filters },
    };
  }
  function success(DRE) {
    return {
      type: constants.DRE_GET_SUCCESS,
      payload: { DRE },
    };
  }
  function failure(errors) {
    return {
      type: constants.DRE_GET_FAILURE,
      payload: { errors },
    };
  }
};

export default generateDRE;
