const handleSidebarOutsideClick = (onSidebarToggle, isMobile, isTablet) => {
  const [firstElement] = document.getElementsByClassName('overlay') || [];

  const closeSidebar = () => {
    onSidebarToggle();
  };

  if (firstElement && (isMobile || isTablet)) {
    firstElement.addEventListener('click', closeSidebar);
  }

  return () => {
    firstElement.removeEventListener('click', closeSidebar);
  };
};

export default handleSidebarOutsideClick;
