import { parsePaginationParams } from 'helpers';

import constants from '../../_constants/mei.constants';
import service from '../../../services/mei.service';

const fetchAllCompanyMeis = (params = {}) => {
  return (dispatch) => {
    dispatch(request());

    const parsedParams = parsePaginationParams(params);

    service.fetchAllCompanyMeis(parsedParams).then(
      (response) => {
        dispatch(success(response.data));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.FETCH_ALL_COMPANY_MEIS_REQUEST,
      payload: { },
    };
  }
  function success(data) {
    return {
      type: constants.FETCH_ALL_COMPANY_MEIS_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_ALL_COMPANY_MEIS_FAILURE,
      payload: error,
    };
  }
};

export default fetchAllCompanyMeis;
