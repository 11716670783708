export const ibgeConstants = {
  FETCH_CITIES_REQUEST: 'FETCH_CITIES_REQUEST',
  FETCH_CITIES_SUCCESS: 'FETCH_CITIES_SUCCESS',
  FETCH_CITIES_FAILURE: 'FETCH_CITIES_FAILURE',
  FETCH_STATES_REQUEST: 'FETCH_STATES_REQUEST',
  FETCH_STATES_SUCCESS: 'FETCH_STATES_SUCCESS',
  FETCH_STATES_FAILURE: 'FETCH_STATES_FAILURE',
  FETCH_CEP_REQUEST: 'FETCH_CEP_REQUEST',
  FETCH_CEP_SUCCESS: 'FETCH_CEP_SUCCESS',
  FETCH_CEP_FAILURE: 'FETCH_CEP_FAILURE',
  FETCH_CNAE_REQUEST: 'FETCH_CNAE_REQUEST',
  FETCH_CNAE_SUCCESS: 'FETCH_CNAE_SUCCESS',
  FETCH_CNAE_FAILURE: 'FETCH_CNAE_FAILURE',
};

export default ibgeConstants;
