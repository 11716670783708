import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const fetchTransactionOverview = () => {
  return async (dispatch) => {
    dispatch(request());

    service.fetchTransactionOverview().then(
      (response) => {
        dispatch(success(response));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(params) {
    return {
      type: constants.FETCH_TRANSACTIONS_OVERVIEW_REQUEST,
      payload: params,
    };
  }
  function success(response) {
    return {
      type: constants.FETCH_TRANSACTIONS_OVERVIEW_SUCCESS,
      payload: response.data,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_TRANSACTIONS_OVERVIEW_FAILURE,
      payload: error,
    };
  }
};

export default fetchTransactionOverview;
