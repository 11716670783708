import { fiscalConstants, authConstants } from '../_constants';

const INITIAL_STATE = {
  total: 0,
  persons: [],
  efd: null,
  isLoading: false,
};

export function fiscal(state = INITIAL_STATE, action) {
  switch (action.type) {
    case authConstants.LOGIN_SUCCESS: {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }
    case fiscalConstants.PERSONS_GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case fiscalConstants.PERSONS_GET_ALL_SUCCESS: {
      const { data, total } = action.payload;

      return {
        ...state,
        persons: data,
        total,
        isLoading: false,
      };
    }
    case fiscalConstants.PERSONS_GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case fiscalConstants.PERSONS_ADD_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case fiscalConstants.PERSONS_ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case fiscalConstants.PERSONS_ADD_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case fiscalConstants.PERSON_UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case fiscalConstants.PERSON_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case fiscalConstants.PERSON_UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case fiscalConstants.PERSON_DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case fiscalConstants.PERSON_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case fiscalConstants.PERSON_DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case fiscalConstants.GET_EFD_CONTRIBUTIONS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case fiscalConstants.GET_EFD_CONTRIBUTIONS_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        efd: payload,
        isLoading: false,
      };
    }
    case fiscalConstants.GET_EFD_CONTRIBUTIONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case fiscalConstants.CLEAR_EFD_CONTRIBUTIONS_REQUEST: {
      return {
        ...state,
        isLoading: false,
        efd: null,
      };
    }
    case fiscalConstants.DOWNLOAD_EFD_CONTRIBUTIONS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case fiscalConstants.DOWNLOAD_EFD_CONTRIBUTIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case fiscalConstants.DOWNLOAD_EFD_CONTRIBUTIONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
