import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: rgba(70, 77, 228, 0.05);
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
   
  ul {
    margin: 0;
    list-style: none;
    display: flex;
    width: 100%;
    font-size: 10px;
    justify-content: center;
    @media (min-width: 1024px) {
    font-size: 14px;
  }
  }
`;

export const StepItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  @media (min-width: 1024px) {
    margin-right: 32px;
  }

  div {
    font-size: 14px;
    background-color: #FFF;   
    color: rgba(70, 77, 228, 1);
    margin-right: 18px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 200ms ease;
    transition-property: color, background-color;

    box-shadow: 0 1px 5px -3px rgb(0 0 0 / 75%);
  }

  ${({ active }) => active && css`    
    div {
      color: #FFF;
      background-color: rgba(70, 77, 228, 1);
    }

    span {
      color: rgba(70, 77, 228, 1);
      font-weight: 500 !important;
    }
  `}

  ${({ isDone }) => isDone && css`    
    div {
      color: #FFF;
      background-color: #3eab8e;
    }

    span {
      color: #3eab8e;
      font-weight: 500 !important;
    }
  `}
`;
