import { accountantsConstants } from '../_constants';

const INITIAL_STATE = {
  total: 0,
  accountants: [],
  isLoading: false,
};

export function accountants(state = INITIAL_STATE, action) {
  switch (action.type) {
    case accountantsConstants.ACCOUNTANTS_GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case accountantsConstants.ACCOUNTANTS_GET_ALL_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        total,
        accountants: data,
        isLoading: false,
      };
    }
    case accountantsConstants.ACCOUNTANTS_GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case accountantsConstants.ACCOUNTANT_ADD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case accountantsConstants.ACCOUNTANT_ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case accountantsConstants.ACCOUNTANT_ADD_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case accountantsConstants.ACCOUNTANT_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case accountantsConstants.ACCOUNTANT_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case accountantsConstants.ACCOUNTANT_UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case accountantsConstants.ACCOUNTANT_DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case accountantsConstants.ACCOUNTANT_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case accountantsConstants.ACCOUNTANT_DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
