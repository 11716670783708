import constants from '../../_constants/balance-sheet.constants';
import service from '../../../services/balance-sheet.service';

const generateBalanceSheet = (filters) => {
  return (dispatch) => {
    dispatch(request(filters));

    service.generate(filters).then(
      (response) => {
        const { start_date, end_date } = filters;

        localStorage.setItem('balance_sheet_date_filters', JSON.stringify({ start_date, end_date }));

        dispatch(success(response.data));
      },
    );
  };

  function request(filters) {
    return {
      type: constants.BALANCE_SHEET_GET_REQUEST,
      payload: { filters },
    };
  }
  function success(balanceSheet) {
    return {
      type: constants.BALANCE_SHEET_GET_SUCCESS,
      payload: { balanceSheet },
    };
  }
};

export default generateBalanceSheet;
