const parsePaginationParams = (params = {}) => {
  const {
    sorting = {},
    filters = {},
    pagination = {},
  } = params;

  const parsedParams = {
    ...filters,
  };

  if (sorting && sorting.field) {
    parsedParams.order_by = `${sorting.field}${sorting.order === 'desc' ? '-' : ''}`;
  }

  if (pagination) {
    const { currentPage, itemsPerPage } = pagination;

    parsedParams.page = currentPage;
    parsedParams.limit = itemsPerPage;
  }

  return parsedParams;
};

export default parsePaginationParams;
