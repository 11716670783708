import FORMATTERS from 'helpers/formatters';

import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';

const searchCompany = (cnpj, callback) => {
  return (dispatch) => {
    const cleanCnpj = FORMATTERS.CLEAN_CPF_CNPJ(cnpj);

    dispatch(request(cleanCnpj));

    service.search(cleanCnpj).then(
      (response) => {
        dispatch(success(response.data));

        callback(response.data);
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(cnpj) {
    return {
      type: constants.COMPANY_SEARCH_BY_CNPJ_REQUEST,
      payload: { cnpj },
    };
  }
  function success(company) {
    return {
      type: constants.COMPANY_SEARCH_BY_CNPJ_SUCCESS,
      payload: { company },
    };
  }
  function failure(error) {
    return {
      type: constants.COMPANY_SEARCH_BY_CNPJ_FAILURE,
      payload: { error },
    };
  }
};

export default searchCompany;
