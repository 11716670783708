export const receiptConstants = {
  RECEIPT_GET_REQUEST: 'RECEIPT_GET_REQUEST',
  RECEIPT_GET_SUCCESS: 'RECEIPT_GET_SUCCESS',
  RECEIPT_GET_FAILURE: 'RECEIPT_GET_FAILURE',

  RECEIPT_GET_ALL_REQUEST: 'RECEIPT_GET_ALL_REQUEST',
  RECEIPT_GET_ALL_SUCCESS: 'RECEIPT_GET_ALL_SUCCESS',
  RECEIPT_GET_ALL_FAILURE: 'RECEIPT_GET_ALL_FAILURE',

  RECEIPT_ADD_REQUEST: 'RECEIPT_ADD_REQUEST',
  RECEIPT_ADD_SUCCESS: 'RECEIPT_ADD_SUCCESS',
  RECEIPT_ADD_FAILURE: 'RECEIPT_ADD_FAILURE',

  RECEIPT_EDIT_REQUEST: 'RECEIPT_EDIT_REQUEST',
  RECEIPT_EDIT_SUCCESS: 'RECEIPT_EDIT_SUCCESS',
  RECEIPT_EDIT_FAILURE: 'RECEIPT_EDIT_FAILURE',

  RECEIPT_UPDATE_REQUEST: 'RECEIPT_UPDATE_REQUEST',
  RECEIPT_UPDATE_SUCCESS: 'RECEIPT_UPDATE_SUCCESS',
  RECEIPT_UPDATE_FAILURE: 'RECEIPT_UPDATE_FAILURE',

  RECEIPT_DELETE_REQUEST: 'RECEIPT_DELETE_REQUEST',
  RECEIPT_DELETE_SUCCESS: 'RECEIPT_DELETE_SUCCESS',
  RECEIPT_DELETE_FAILURE: 'RECEIPT_DELETE_FAILURE',
};

export default receiptConstants;
