import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import { FormSelectField } from '_components/_core';

import { MenuCategory, StyledRow } from './styles';

export default function ReportSelectFields({
  accountants,
  employees,
}) {
  const formattedAccountants = useMemo(
    () => accountants.map((accountant) => ({
      value: accountant.id,
      label: accountant.name,
    })),
    [accountants],
  );

  const formattedEmployees = useMemo(
    () => employees.map((employee) => ({
      value: employee.id,
      label: employee.name,
    })),
    [employees],
  );

  return (
    <StyledRow>
      <Col>
        <MenuCategory>
          Assinaturas
        </MenuCategory>
        <Form.Group>
          <Form.Label>
            Contador
          </Form.Label>
          <FormSelectField name="signature_accountants" options={formattedAccountants} multiple />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Colaborador
          </Form.Label>
          <FormSelectField name="signature_employees" options={formattedEmployees} multiple />
        </Form.Group>
      </Col>
    </StyledRow>
  );
}

ReportSelectFields.defaultProps = {
  accountants: [],
  employees: [],
};

ReportSelectFields.propTypes = {
  accountants: PropTypes.array,
  employees: PropTypes.array,
};
