import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import { Formik } from 'formik';

import { PageHeader } from '_components/_core';

import { Filters, Results } from './components';

function RevenueReport({
  isLoading,
  revenueReport,
  activeCompany,
  onFetchRevenueReport,
  onClearRevenueReport,
}) {
  useEffect(() => {
    onClearRevenueReport();
  }, [onClearRevenueReport]);

  const handleGenerateReport = useCallback((values) => {
    onFetchRevenueReport({
      ...values,
    });
  }, [onFetchRevenueReport]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader title="Relatório de Faturamento" description="Gere um relatório com o faturamento acumulado no período informado." />
      <Formik
        initialValues={{
          start_date: null,
          end_date: null,
          type: 'month',
        }}
        onSubmit={handleGenerateReport}
        enableReinitialize
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <>
            <Tab.Container defaultActiveKey="month">
              <Row>
                <Col lg={3} md={12}>
                  <Filters
                    isLoading={isLoading}
                    values={values}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col lg={9} md={12}>
                  <Results
                    revenueReport={revenueReport}
                    values={values}
                    activeCompany={activeCompany}
                  />
                </Col>
              </Row>
            </Tab.Container>
          </>
        )}
      </Formik>
    </Container>
  );
}

RevenueReport.defaultProps = {
  revenueReport: null,
  isLoading: false,
};

RevenueReport.propTypes = {
  isLoading: PropTypes.bool,
  revenueReport: PropTypes.object,
  activeCompany: PropTypes.object,
  onClearRevenueReport: PropTypes.func.isRequired,
  onFetchRevenueReport: PropTypes.func.isRequired,
};

export default RevenueReport;
