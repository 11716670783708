import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import AccountModal from './AccountModal';

const mapStateToProps = (state) => ({
  isLoading: state.companyTransactions.isLoading,
});

const mapDispatchToProps = {
  onAddTransactionAccount: companyTransactionsActionCreators.createTransactionAccount,
  onUpdateTransactionAccount: companyTransactionsActionCreators.editTransactionAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountModal);
