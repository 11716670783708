import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';
import { IoTrash, IoCheckmark } from 'react-icons/io5';
import { RiErrorWarningFill } from 'react-icons/ri';
import { DateTime } from 'luxon';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';
import {
  Button,
  CardBody,
  FormTextField,
  FormDateField,
  FormCurrencyField,
  FormAccountSelectField,
} from '_components/_core';

import { CreateEntrySchema } from './utilities';
import {
  Container,
  CardContainer,
  StyledCard,
  StyledFormCheck,
} from './styles';

function EntriesForm({
  isLoading,
  onSubmitEntry,
  onIgnoreEntry,
  onSelectEntry,
  entries,
  selectedItems,
}) {
  const handleDeleteEntry = useCallback((entry, pending_entries) => {
    onIgnoreEntry(entry, pending_entries);
  }, [onIgnoreEntry]);

  const handleSubmitEntry = useCallback((entry, pending_entries) => {
    onSubmitEntry(entry, pending_entries);
  }, [onSubmitEntry]);

  const handleSelectEntry = useCallback((entry) => {
    onSelectEntry(entry.temp_id);
  }, [onSelectEntry]);

  return (
    <Formik
      initialValues={{
        pending_entries: entries,
      }}
      onSubmit={() => {}}
      validationSchema={CreateEntrySchema}
      enableReinitialize
      validateOnMount
    >
      {({ values, errors }) => (
        <Container>
          {values.pending_entries.map((entry, index) => (
            <CardContainer>
              <StyledFormCheck
                type="checkbox"
                checked={selectedItems.includes(entry.temp_id)}
                onClick={() => handleSelectEntry(entry)}
              />
              <StyledCard key={entry.temp_id} className="mb-3">
                <CardBody>
                  <Row>
                    <Col md={2}>
                      <Form.Group>
                        <Form.Label>Data</Form.Label>
                        <FormDateField
                          disabled
                          name={`pending_entries[${index}].event_date`}
                          placeholder="Data"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Débito</Form.Label>
                        <FormAccountSelectField
                          disabled={values.pending_entries[index].disable_debit_account}
                          name={`pending_entries[${index}].debit_account_id`}
                          creatable="account"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Crédito</Form.Label>
                        <FormAccountSelectField
                          disabled={values.pending_entries[index].disable_credit_account}
                          name={`pending_entries[${index}].credit_account_id`}
                          creatable="account"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group>
                        <Form.Label>Valor</Form.Label>
                        <FormCurrencyField
                          disabled
                          name={`pending_entries[${index}].amount`}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8}>
                      <Form.Group>
                        <Form.Label>Histórico</Form.Label>
                        <FormTextField
                          name={`pending_entries[${index}].narration`}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end align-items-center">
                      <Button
                        variant="outline-danger mr-2"
                        icon={<IoTrash />}
                        onClick={() => handleDeleteEntry(entry, values.pending_entries)}
                        noMargin
                        isLoading={isLoading}
                      >
                        Ignorar&nbsp;&nbsp;
                      </Button>
                      <Button
                        variant="outline-primary"
                        icon={<IoCheckmark />}
                        onClick={() => handleSubmitEntry(entry, values.pending_entries)}
                        noMargin
                        isLoading={isLoading}
                        disabled={
                          isEmpty(errors.pending_entries) ? false : !!errors.pending_entries[index]
                        }
                      >
                        Importar&nbsp;&nbsp;
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8}>
                      {values.pending_entries[index].related_entry && (
                        <>
                          <p className="d-flex align-items-center text-danger text-bold">
                            <RiErrorWarningFill size="1.1rem" className=" mr-2" />
                            ATENÇÃO: Já existe um lançamento parecido com esta transação:
                          </p>
                          <p>
                            <strong>Data:</strong>
                            {` ${DateTime.fromISO(values.pending_entries[index].related_entry.event_date, { zone: 'utc' }).toFormat('dd/MM/yyyy')}`}
                            {' | '}
                            <strong>Histórico:</strong>
                            {` ${FORMATTERS.UPPERCASE(values.pending_entries[index].related_entry.narration)}`}
                            {' | '}
                            <strong>Valor:</strong>
                            {` ${FORMATTERS.NUMBER(values.pending_entries[index].related_entry.amount)}`}
                          </p>
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </StyledCard>
            </CardContainer>
          ))}
        </Container>
      )}
    </Formik>
  );
}

EntriesForm.defaultProps = {
  entries: [],
  selectedItems: [],
  isLoading: false,
};

EntriesForm.propTypes = {
  entries: PropTypes.array,
  selectedItems: PropTypes.array,
  onSubmitEntry: PropTypes.func.isRequired,
  onIgnoreEntry: PropTypes.func.isRequired,
  onSelectEntry: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default EntriesForm;
