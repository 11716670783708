import styled from 'styled-components';

export const MenuCategory = styled.span`
  padding: 18px 20px 14px 20px;
  color: rgba(26,51,83,.6);
  font-size: 12px;
  line-height: 1.5;
  font-weight: 700;
  text-transform: uppercase;
`;
