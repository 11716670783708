import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input';

function CustomCurrentInput({
  name,
  onChange,
  innerRef,
  ...rest
}) {
  const handleChange = useCallback((_event, maskedvalue, floatvalue) => {
    if (maskedvalue === '') {
      onChange(null);
      return;
    }

    onChange(floatvalue);
  }, [onChange]);

  return (
    <CurrencyInput
      name={name}
      onChangeEvent={handleChange}
      decimalSeparator=","
      thousandSeparator="."
      ref={innerRef}
      {...rest}
    />
  );
}

CustomCurrentInput.defaultProps = {
  innerRef: null,
};

CustomCurrentInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  innerRef: PropTypes.object,
};

export default CustomCurrentInput;
