export const employeesConstants = {
  EMPLOYEES_GET_ALL_REQUEST: 'EMPLOYEES_GET_ALL_REQUEST',
  EMPLOYEES_GET_ALL_SUCCESS: 'EMPLOYEES_GET_ALL_SUCCESS',
  EMPLOYEES_GET_ALL_FAILURE: 'EMPLOYEES_GET_ALL_FAILURE',

  EMPLOYEE_ROLES_GET_ALL_REQUEST: 'EMPLOYEE_ROLES_GET_ALL_REQUEST',
  EMPLOYEE_ROLES_GET_ALL_SUCCESS: 'EMPLOYEE_ROLES_GET_ALL_SUCCESS',
  EMPLOYEE_ROLES_GET_ALL_FAILURE: 'EMPLOYEE_ROLES_GET_ALL_FAILURE',
  EMPLOYEE_ROLES_ADD_REQUEST: 'EMPLOYEE_ROLES_ADD_REQUEST',
  EMPLOYEE_ROLES_ADD_SUCCESS: 'EMPLOYEE_ROLES_ADD_SUCCESS',
  EMPLOYEE_ROLES_ADD_FAILURE: 'EMPLOYEE_ROLES_ADD_FAILURE',
  EMPLOYEE_ROLES_UPDATE_REQUEST: 'EMPLOYEE_ROLES_UPDATE_REQUEST',
  EMPLOYEE_ROLES_UPDATE_SUCCESS: 'EMPLOYEE_ROLES_UPDATE_SUCCESS',
  EMPLOYEE_ROLES_UPDATE_FAILURE: 'EMPLOYEE_ROLES_UPDATE_FAILURE',
  EMPLOYEE_ROLES_DELETE_REQUEST: 'EMPLOYEE_ROLES_DELETE_REQUEST',
  EMPLOYEE_ROLES_DELETE_SUCCESS: 'EMPLOYEE_ROLES_DELETE_SUCCESS',
  EMPLOYEE_ROLES_DELETE_FAILURE: 'EMPLOYEE_ROLES_DELETE_FAILURE',

  EMPLOYEE_JOURNEYS_GET_ALL_REQUEST: 'EMPLOYEE_JOURNEYS_GET_ALL_REQUEST',
  EMPLOYEE_JOURNEYS_GET_ALL_SUCCESS: 'EMPLOYEE_JOURNEYS_GET_ALL_SUCCESS',
  EMPLOYEE_JOURNEYS_GET_ALL_FAILURE: 'EMPLOYEE_JOURNEYS_GET_ALL_FAILURE',
  EMPLOYEE_JOURNEYS_ADD_REQUEST: 'EMPLOYEE_JOURNEYS_ADD_REQUEST',
  EMPLOYEE_JOURNEYS_ADD_SUCCESS: 'EMPLOYEE_JOURNEYS_ADD_SUCCESS',
  EMPLOYEE_JOURNEYS_ADD_FAILURE: 'EMPLOYEE_JOURNEYS_ADD_FAILURE',
  EMPLOYEE_JOURNEYS_UPDATE_REQUEST: 'EMPLOYEE_JOURNEYS_UPDATE_REQUEST',
  EMPLOYEE_JOURNEYS_UPDATE_SUCCESS: 'EMPLOYEE_JOURNEYS_UPDATE_SUCCESS',
  EMPLOYEE_JOURNEYS_UPDATE_FAILURE: 'EMPLOYEE_JOURNEYS_UPDATE_FAILURE',
  EMPLOYEE_JOURNEYS_DELETE_REQUEST: 'EMPLOYEE_JOURNEYS_DELETE_REQUEST',
  EMPLOYEE_JOURNEYS_DELETE_SUCCESS: 'EMPLOYEE_JOURNEYS_DELETE_SUCCESS',
  EMPLOYEE_JOURNEYS_DELETE_FAILURE: 'EMPLOYEE_JOURNEYS_DELETE_FAILURE',

  EMPLOYEE_SALARIES_GET_ALL_REQUEST: 'EMPLOYEE_SALARIES_GET_ALL_REQUEST',
  EMPLOYEE_SALARIES_GET_ALL_SUCCESS: 'EMPLOYEE_SALARIES_GET_ALL_SUCCESS',
  EMPLOYEE_SALARIES_GET_ALL_FAILURE: 'EMPLOYEE_SALARIES_GET_ALL_FAILURE',
  EMPLOYEE_SALARIES_ADD_REQUEST: 'EMPLOYEE_SALARIES_ADD_REQUEST',
  EMPLOYEE_SALARIES_ADD_SUCCESS: 'EMPLOYEE_SALARIES_ADD_SUCCESS',
  EMPLOYEE_SALARIES_ADD_FAILURE: 'EMPLOYEE_SALARIES_ADD_FAILURE',
  EMPLOYEE_SALARIES_UPDATE_REQUEST: 'EMPLOYEE_SALARIES_UPDATE_REQUEST',
  EMPLOYEE_SALARIES_UPDATE_SUCCESS: 'EMPLOYEE_SALARIES_UPDATE_SUCCESS',
  EMPLOYEE_SALARIES_UPDATE_FAILURE: 'EMPLOYEE_SALARIES_UPDATE_FAILURE',
  EMPLOYEE_SALARIES_DELETE_REQUEST: 'EMPLOYEE_SALARIES_DELETE_REQUEST',
  EMPLOYEE_SALARIES_DELETE_SUCCESS: 'EMPLOYEE_SALARIES_DELETE_SUCCESS',
  EMPLOYEE_SALARIES_DELETE_FAILURE: 'EMPLOYEE_SALARIES_DELETE_FAILURE',

  EMPLOYEE_DEPENDENTS_GET_ALL_REQUEST: 'EMPLOYEE_DEPENDENTS_GET_ALL_REQUEST',
  EMPLOYEE_DEPENDENTS_GET_ALL_SUCCESS: 'EMPLOYEE_DEPENDENTS_GET_ALL_SUCCESS',
  EMPLOYEE_DEPENDENTS_GET_ALL_FAILURE: 'EMPLOYEE_DEPENDENTS_GET_ALL_FAILURE',
  EMPLOYEE_DEPENDENTS_ADD_REQUEST: 'EMPLOYEE_DEPENDENTS_ADD_REQUEST',
  EMPLOYEE_DEPENDENTS_ADD_SUCCESS: 'EMPLOYEE_DEPENDENTS_ADD_SUCCESS',
  EMPLOYEE_DEPENDENTS_ADD_FAILURE: 'EMPLOYEE_DEPENDENTS_ADD_FAILURE',
  EMPLOYEE_DEPENDENTS_UPDATE_REQUEST: 'EMPLOYEE_DEPENDENTS_UPDATE_REQUEST',
  EMPLOYEE_DEPENDENTS_UPDATE_SUCCESS: 'EMPLOYEE_DEPENDENTS_UPDATE_SUCCESS',
  EMPLOYEE_DEPENDENTS_UPDATE_FAILURE: 'EMPLOYEE_DEPENDENTS_UPDATE_FAILURE',
  EMPLOYEE_DEPENDENTS_DELETE_REQUEST: 'EMPLOYEE_DEPENDENTS_DELETE_REQUEST',
  EMPLOYEE_DEPENDENTS_DELETE_SUCCESS: 'EMPLOYEE_DEPENDENTS_DELETE_SUCCESS',
  EMPLOYEE_DEPENDENTS_DELETE_FAILURE: 'EMPLOYEE_DEPENDENTS_DELETE_FAILURE',

  EMPLOYEE_GET_ADD_PARAMS_REQUEST: 'EMPLOYEE_GET_ADD_PARAMS_REQUEST',
  EMPLOYEE_GET_ADD_PARAMS_SUCCESS: 'EMPLOYEE_GET_ADD_PARAMS_SUCCESS',
  EMPLOYEE_GET_ADD_PARAMS_FAILURE: 'EMPLOYEE_GET_ADD_PARAMS_FAILURE',

  EMPLOYEE_ADD_REQUEST: 'EMPLOYEE_ADD_REQUEST',
  EMPLOYEE_ADD_SUCCESS: 'EMPLOYEE_ADD_SUCCESS',
  EMPLOYEE_ADD_FAILURE: 'EMPLOYEE_ADD_FAILURE',

  EMPLOYEE_UPDATE_REQUEST: 'EMPLOYEE_UPDATE_REQUEST',
  EMPLOYEE_UPDATE_SUCCESS: 'EMPLOYEE_UPDATE_SUCCESS',
  EMPLOYEE_UPDATE_FAILURE: 'EMPLOYEE_UPDATE_FAILURE',

  EMPLOYEE_GET_REQUEST: 'EMPLOYEE_GET_REQUEST',
  EMPLOYEE_GET_SUCCESS: 'EMPLOYEE_GET_SUCCESS',
  EMPLOYEE_GET_FAILURE: 'EMPLOYEE_GET_FAILURE',

  EMPLOYEE_DELETE_REQUEST: 'EMPLOYEE_DELETE_REQUEST',
  EMPLOYEE_DELETE_SUCCESS: 'EMPLOYEE_DELETE_SUCCESS',
  EMPLOYEE_DELETE_FAILURE: 'EMPLOYEE_DELETE_FAILURE',

  GET_EMPLOYEE_VACATIONS_REQUEST: 'GET_EMPLOYEE_VACATIONS_REQUEST',
  GET_EMPLOYEE_VACATIONS_SUCCESS: 'GET_EMPLOYEE_VACATIONS_SUCCESS',
  GET_EMPLOYEE_VACATIONS_FAILURE: 'GET_EMPLOYEE_VACATIONS_FAILURE',
};

export default employeesConstants;
