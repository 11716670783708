import constants from '../../_constants/auth.constants';
import service from '../../../services/auth.service';

const requestEmailConfirmToken = (params, callback, errorCallback) => {
  return (dispatch) => {
    dispatch(request());

    service.requestEmailConfirmation(params).then(
      (response) => {
        dispatch(success(response));

        if (callback) {
          callback(response.data);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));

      if (errorCallback) {
        errorCallback(error);
      }
    });
  };

  function request() {
    return {
      type: constants.REQUEST_EMAIL_CONFIRM_TOKEN_REQUEST,
    };
  }
  function success(response) {
    return {
      type: constants.REQUEST_EMAIL_CONFIRM_TOKEN_SUCCESS,
      payload: { response },
    };
  }
  function failure(error) {
    return {
      type: constants.REQUEST_EMAIL_CONFIRM_TOKEN_FAILURE,
      payload: { error },
    };
  }
};

export default requestEmailConfirmToken;
