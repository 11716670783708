import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';

const fetchCompaniesForSelect = () => {
  return (dispatch) => {
    dispatch(request());

    const parsedParams = {
      order_by: 'company_name',
      page: 1,
      limit: -1,
    };

    service.getAll(parsedParams).then(
      (response) => {
        const { total, data } = response.data;

        dispatch(success({ total, data }));
      },
    );
  };

  function request() {
    return {
      type: constants.FETCH_COMPANIES_FOR_SELECT_REQUEST,
      payload: {

      },
    };
  }
  function success(companies) {
    return {
      type: constants.FETCH_COMPANIES_FOR_SELECT_SUCCESS,
      payload: companies,
    };
  }
};

export default fetchCompaniesForSelect;
