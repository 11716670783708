import * as yup from 'yup';

import {
  REQUIRED,
  EMAIL,
} from 'helpers/validation-messages';

const ProfileSchema = yup.object().shape({
  first_name: yup.string().required(REQUIRED),
  last_name: yup.string().required(REQUIRED),
  email: yup.string().max(255).email(EMAIL).required(REQUIRED),
});

export default ProfileSchema;
