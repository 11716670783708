import styled from 'styled-components';

import { Button } from '_components/_core';

export const StyledDropdownCalendar = styled(Button)`
  margin: 0;
  padding: 0;
  border: none;
  font-weight: 400;
  font-size: 0.8rem;
  /* background-color: transparent;
  text-decoration: none;
  color: var(--text-color);

  &:hover, &:focus, &:active, &:visited {
    background-color: transparent !important;
    text-decoration: none;
  } */

`;
