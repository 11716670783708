import { stringDateRemoveHours } from 'helpers';

const MONTH_KEYS = {
  0: 'first_month',
  1: 'second_month',
  2: 'third_month',
};

const TAXES_KEYS = {
  'PIS à Recolher': 'pis_pasep',
  'COFINS à Recolher': 'cofins',
  'IRPJ à Recolher': 'irpj',
  'CSLL à Recolher': 'csll',
  'ISS à Recolher': 'iss',
};

const taxes = [
  'PIS à Recolher',
  'COFINS à Recolher',
  'IRPJ à Recolher',
  'CSLL à Recolher',
  'ISS à Recolher',
];

const formatData = (calculatedTaxes, selectedMonth) => {
  const firstMonth = selectedMonth.startOf('quarter');

  const formattedFirstMonth = firstMonth.toFormat('yyyy-MM-dd');
  const formattedSecondMonth = firstMonth.plus({ month: 1 }).toFormat('yyyy-MM-dd');
  const formattedThirdMonth = firstMonth.plus({ months: 2 }).toFormat('yyyy-MM-dd');

  const months = [formattedFirstMonth, formattedSecondMonth, formattedThirdMonth];

  const data = [];

  taxes.forEach((tax) => {
    const item = {
      tax_name: tax,
      total: 0,
    };

    months.forEach((month, index) => {
      const taxesInMonth = calculatedTaxes.find(
        (item) => stringDateRemoveHours(item.date_competence) === month,
      ) || {};

      item[MONTH_KEYS[index]] = month;

      const tax_amount = taxesInMonth[`${TAXES_KEYS[tax]}_due`] || 0;
      item[`amount_due_${MONTH_KEYS[index]}`] = tax_amount;

      item.total += tax_amount;
    });

    data.push(item);
  });

  return data;
};

export default formatData;
