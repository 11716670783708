import constants from '../../_constants/taxes.constants';
import service from '../../../services/taxes.service';

const fetchTaxes = (params) => {
  return (dispatch) => {
    dispatch(request());

    service.fetchTaxes(params).then(
      (response) => {
        const { data } = response;

        dispatch(success(data));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.FETCH_TAXES_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.FETCH_TAXES_SUCCESS,
      payload: {
        data,
      },
    };
  }
  function failure(data) {
    return {
      type: constants.FETCH_TAXES_FAILURE,
      payload: {
        data,
      },
    };
  }
};

export default fetchTaxes;
