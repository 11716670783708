import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { TRANSACTION_FREQUENCY_OPTIONS } from 'helpers/constants';

import {
  RadioButtonListContainer,
  RadioButtonOptionContainer,
  RadioButtonLabel,
  RadioButtonInput,
} from './styles';

function RecurrenceForm({ values, setFieldValue, onChangeFormValid }) {
  useEffect(() => {
    onChangeFormValid(true);
  });

  const handleOptionChange = useCallback((e) => {
    setFieldValue('frequency', e.target.value);
  }, [setFieldValue]);

  return (
    <RadioButtonListContainer>
      {TRANSACTION_FREQUENCY_OPTIONS.map((option) => (
        <RadioButtonOptionContainer key={option.value}>
          <RadioButtonInput
            type="radio"
            value={option.value}
            id={option.value}
            checked={values.frequency === option.value}
            onChange={handleOptionChange}
          />
          <RadioButtonLabel htmlFor={option.value}>{option.label}</RadioButtonLabel>
        </RadioButtonOptionContainer>
      ))}
    </RadioButtonListContainer>
  );
}

RecurrenceForm.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  onChangeFormValid: PropTypes.func.isRequired,
};

export default RecurrenceForm;
