import constants from '../../_constants/associates.constants';
import service from '../../../services/associates.service';

const deleteAssociate = (id) => {
  return (dispatch) => {
    dispatch(request(id));

    service.remove(id).then(
      () => {
        dispatch(success(id));
      },
    );
  };

  function request(id) {
    return { type: constants.ASSOCIATES_DELETE_REQUEST, payload: { id } };
  }
  function success(id) {
    return {
      type: constants.ASSOCIATES_DELETE_SUCCESS,
      payload: {
        id,
      },
    };
  }
};

export default deleteAssociate;
