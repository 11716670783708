import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';

import { Button, FormCurrencyField, FormDateField } from '_components/_core';

import { FeesSchema } from '../../utilities';
import { StyledModal, ModalFooter } from './styles';

const FeeModal = ({
  company,
  fee,
  isVisible,
  isLoading,
  onModalToggle,
  onAddFee,
  onUpdateFee,
  onCompanyInfoChange,
}) => {
  const handleAddEditCompanyFees = useCallback((values, { resetForm }) => {
    const { id: company_id } = company || {};
    if (fee) {
      onUpdateFee(company_id, fee.id, values, (updatedCompany) => {
        resetForm();
        onModalToggle();
        onCompanyInfoChange(updatedCompany);
      });
    } else {
      onAddFee(company_id, values, (updatedCompany) => {
        resetForm();
        onModalToggle();
        onCompanyInfoChange(updatedCompany);
      });
    }
  }, [onAddFee, onUpdateFee, onModalToggle, company, fee, onCompanyInfoChange]);

  const renderModalFooter = useCallback((handleSubmit, isValid) => (
    <ModalFooter>
      <Button variant="secondary" onClick={onModalToggle}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={!isValid}
      >
        Salvar
      </Button>
    </ModalFooter>
  ), [onModalToggle, isLoading]);

  if (!isVisible) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        start_date: fee ? fee.start_date : '',
        amount: fee ? fee.amount : '',
      }}
      validationSchema={FeesSchema}
      onSubmit={handleAddEditCompanyFees}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isValid }) => (
        <>
          <StyledModal
            title={fee ? 'Editar honorário' : 'Cadastrar novo honorário'}
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit, isValid)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>
                    Vigência
                  </Form.Label>
                  <FormDateField name="start_date" placeholder="Vigência" />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>
                    Valor
                  </Form.Label>
                  <FormCurrencyField name="amount" placeholder="Valor" />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default FeeModal;

FeeModal.defaultProps = {
  isVisible: false,
  isLoading: false,
  company: null,
};

FeeModal.propTypes = {
  fee: PropTypes.object,
  company: PropTypes.object,
  isVisible: PropTypes.bool,
  onModalToggle: PropTypes.func.isRequired,
  onAddFee: PropTypes.func.isRequired,
  onUpdateFee: PropTypes.func.isRequired,
  isLoading: PropTypes.func,
  onCompanyInfoChange: PropTypes.func.isRequired,
};
