import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaDownload } from 'react-icons/fa';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';

import {
  Card,
  CardBody,
  Button,
  FormDateField,
} from '_components/_core';

import { AsyncOperations } from '_components/_shared';
import useAsyncOperations from '_components/_shared/AsyncOperations/useAsyncOperations';

import confirmDialog from 'helpers/confirmDialog';

function Download({ subscription, onDownloadFinancialData }) {
  const {
    hasPending,
    pendingAsyncOperation,
    onSetAsyncOperations,
  } = useAsyncOperations({
    type: 'EXPORT_FINANCIAL_DATA',
    successTitle: 'Back-up concluído!',
    successMessage: 'Confira sua caixa de entrada para baixar o arquivo de back-up.',
    downloadFile: true,
    showConfirmation: false,
  });

  const handleDownload = useCallback((values, { setSubmitting }) => {
    setSubmitting(true);

    const { status } = subscription || {};

    const validStatus = ['active', 'past_due'];

    if (isEmpty(status) || !validStatus.includes(status)) {
      confirmDialog.open({
        icon: 'error',
        title: 'Nenhuma assinatura ativa',
        message: 'Esta funcionalidade está disponível apenas para usuários com assinatura ativa.',
        confirmButtonText: 'Entendi',
        showCancelButton: false,
      });

      setSubmitting(false);

      return;
    }

    onDownloadFinancialData(values, (asyncOperation) => {
      onSetAsyncOperations([asyncOperation]);
    });
  }, [onDownloadFinancialData, subscription, onSetAsyncOperations]);

  return (
    <>
      {!hasPending && (
        <Card>
          <CardBody>
            <Formik
              initialValues={{
                start_date: null,
                end_date: null,
                downloadType: 'LOCAL',
              }}
              onSubmit={handleDownload}
              validateOnMount
            >
              {({
                handleSubmit,
                isSubmitting,
                values,
              }) => (
                <>
                  <Row>
                    <Form.Group as={Col} lg={3}>
                      <Form.Label>
                        Data Inicial
                      </Form.Label>
                      <FormDateField
                        placeholder="Data Inicial"
                        name="start_date"
                      />
                    </Form.Group>
                    <Form.Group as={Col} lg={3}>
                      <Form.Label>
                        Data Final
                      </Form.Label>
                      <FormDateField
                        placeholder="Data Final"
                        name="end_date"
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <div className="d-flex  align-items-center">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          onClick={handleSubmit}
                          isLoading={isSubmitting}
                          disabled={!values.start_date || !values.end_date}
                        >
                          Exportar dados
                          <FaDownload className="ml-3" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </Formik>
          </CardBody>
        </Card>
      )}
      {hasPending && (
        <AsyncOperations
          hasPending={hasPending}
          pendingAsyncOperation={pendingAsyncOperation}
        />
      )}
    </>
  );
}

Download.defaultProps = {
  subscription: {},
};

Download.propTypes = {
  subscription: PropTypes.object,
  onDownloadFinancialData: PropTypes.func,
};

export default Download;
