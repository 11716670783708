import { connect } from 'react-redux';

import employeesActionCreators from '_store/_actions/employees';

import Associates from './Associates';

const mapStateToProps = (state) => ({
  total: state.employees.total,
  employees: state.employees.employees,
  activeCompany: state.company.activeCompany,
  isLoading: state.employees.isLoading,
});

const mapDispatchToProps = {
  onFetchEmployees: employeesActionCreators.getAllEmployees,
  onDeleteEmployee: employeesActionCreators.deleteEmployee,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Associates);
