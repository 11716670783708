import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Card,
  Title,
  Text,
  FilterIcon,
} from './styles';

const EmptyState = ({
  title,
  description,
  actionButton,
}) => (
  <Container>
    <Card>
      <FilterIcon />
      <Title>
        {title}
      </Title>
      <Text>
        {description}
      </Text>
      {actionButton}
    </Card>
  </Container>
);

EmptyState.defaultProps = {
  title: 'Nenhum resultado encontrado',
  description: 'Não encontramos resultados para os filtros informados. Experimente mudar os filtros no painel acima, ou aumentar o período de tempo desejado.',
  actionButton: null,
};

EmptyState.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  actionButton: PropTypes.node,
};

export default EmptyState;
