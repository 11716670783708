import uniqueId from 'lodash/uniqueId';

import constants from '../../_constants/receipts.constants';
import service from '../../../services/receipt.service';

import alertActionCreators from '../alert.actions';

const addReceipt = (values, callback, errorCallback) => {
  return (dispatch) => {
    const tempId = uniqueId();

    dispatch(request(tempId, values));

    service.insert(values).then(
      (response) => {
        const { data } = response;

        dispatch(success(data));

        dispatch(alertActionCreators.success('Recibo inserido com sucesso.'));

        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        dispatch(failure(error));

        if (errorCallback) {
          errorCallback();
        }
      },
    );
  };

  function request(tempId, values) {
    return {
      type: constants.RECEIPT_ADD_REQUEST,
      payload: {
        tempId,
        values,
      },
    };
  }
  function success(receipt) {
    return {
      type: constants.RECEIPT_ADD_SUCCESS,
      payload: receipt,
    };
  }
  function failure(tempId, error) {
    return {
      type: constants.RECEIPT_ADD_FAILURE,
      payload: {
        tempId,
        error,
      },
    };
  }
};

export default addReceipt;
