import styled from 'styled-components';

export const Content = styled.div`
  width: 60%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledTitle = styled.h1`
  font-size: 30px;
  padding-left: 25px;
`;

export const StyledSubtitle = styled.h5`
  font-weight: 400;
`;

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
`;

export const FormTitle = styled.h1`
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 0;

  @media (max-width: 768px) {
    font-size: 1em;

    small {
    }
  }
`;

export const StyledFormHeader = styled.div`
  background-color: #fff;

  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    padding: 10px 20px;

    svg {
      width: 30px;
    }

    small {
      font-size: 0.8em;
    }
  }
`;
