export const accountsConstants = {
  ACCOUNT_GET_ALL_REQUEST: 'ACCOUNT_GET_ALL_REQUEST',
  ACCOUNT_GET_ALL_SUCCESS: 'ACCOUNT_GET_ALL_SUCCESS',
  ACCOUNT_GET_ALL_FAILURE: 'ACCOUNT_GET_ALL_FAILURE',
  ACCOUNT_GET_REQUEST: 'ACCOUNT_GET_REQUEST',
  ACCOUNT_GET_SUCCESS: 'ACCOUNT_GET_SUCCESS',
  ACCOUNT_GET_FAILURE: 'ACCOUNT_GET_FAILURE',
  ACCOUNT_NEW_REQUEST: 'ACCOUNT_NEW_REQUEST',
  ACCOUNT_NEW_SUCCESS: 'ACCOUNT_NEW_SUCCESS',
  ACCOUNT_NEW_FAILURE: 'ACCOUNT_NEW_FAILURE',
  ACCOUNT_NEW_CANCEL_REQUEST: 'ACCOUNT_NEW_CANCEL_REQUEST',
  ACCOUNT_ADD_REQUEST: 'ACCOUNT_ADD_REQUEST',
  ACCOUNT_ADD_SUCCESS: 'ACCOUNT_ADD_SUCCESS',
  ACCOUNT_ADD_FAILURE: 'ACCOUNT_ADD_FAILURE',
  ACCOUNT_EDIT_REQUEST: 'ACCOUNT_EDIT_REQUEST',
  ACCOUNT_EDIT_SUCCESS: 'ACCOUNT_EDIT_SUCCESS',
  ACCOUNT_EDIT_FAILURE: 'ACCOUNT_EDIT_FAILURE',
  ACCOUNT_UPDATE_REQUEST: 'ACCOUNT_UPDATE_REQUEST',
  ACCOUNT_UPDATE_SUCCESS: 'ACCOUNT_UPDATE_SUCCESS',
  ACCOUNT_UPDATE_FAILURE: 'ACCOUNT_UPDATE_FAILURE',
  ACCOUNT_DELETE_REQUEST: 'ACCOUNT_DELETE_REQUEST',
  ACCOUNT_DELETE_SUCCESS: 'ACCOUNT_DELETE_SUCCESS',
  ACCOUNT_DELETE_FAILURE: 'ACCOUNT_DELETE_FAILURE',
  ACCOUNT_DELETE_CONFIRM_REQUEST: 'ACCOUNT_DELETE_CONFIRM_REQUEST',
  ACCOUNT_DELETE_CONFIRM_SUCCESS: 'ACCOUNT_DELETE_CONFIRM_SUCCESS',
  ACCOUNT_DELETE_CONFIRM_FAILURE: 'ACCOUNT_DELETE_CONFIRM_FAILURE',
  ACCOUNT_DUPLICATE_REQUEST: 'ACCOUNT_DUPLICATE_REQUEST',
  ACCOUNT_DUPLICATE_SUCESS: 'ACCOUNT_DUPLICATE_SUCCESS',
  ACCOUNT_DUPLICATE_FAILURE: 'ACCOUNT_DUPLICATE_FAILURE',
  ACCOUNT_TOGGLE_FORM: 'ACCOUNT_TOGGLE_FORM',
};

export default accountsConstants;
