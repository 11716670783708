import isEmpty from 'lodash/isEmpty';
import * as XLSX from 'sheetjs-style';

import { CELL_TYPES } from './constants';
import getTransactionDescription from '../getTransactionDescription';
import getTransactionDate from '../getTransactionDate';

const reportToExcel = ({
  report_name,
  reports,
  date_type,
}) => {
  const {
    result,
    result_other,
  } = reports[report_name] || [];

  if (isEmpty(result)) return;

  const transactions = [...result, ...result_other];

  if ('transaction' in transactions[0]) {
    const spreadsheetData = [['Pago?', 'Data', 'Descrição', 'Contato', 'Valor']];

    const transactionsType = [null, 'HEADERS'];

    for (const item of transactions) {
      spreadsheetData.push([
        item.transaction.paid ? 'Sim' : 'Não',
        getTransactionDate(item.transaction, date_type),
        getTransactionDescription(item.transaction),
        item.transaction.recipient.name || '-',
        item.transaction.amount * -1,
      ]);

      transactionsType.push('EXPENSE');
    }

    const total = transactions.reduce((total, item) => total + item.transaction.amount, 0);

    spreadsheetData.push(
      [null, null, null, null, null],
      ['Total', '-', '-', '-', total * -1],
    );

    transactionsType.push(null, 'HEADERS');

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(spreadsheetData);

    worksheet['!cols'] = [{ width: 7 }, { width: 12 }, { width: 35 }, { width: 30 }, { width: 16 }];

    for (let i = 1; i <= spreadsheetData.length; i += 1) {
      if (transactionsType[i] !== null) {
        worksheet[`A${i}`].s = CELL_TYPES[transactionsType[i]];
        worksheet[`B${i}`].s = CELL_TYPES[transactionsType[i]];
        worksheet[`C${i}`].s = CELL_TYPES[transactionsType[i]];
        worksheet[`D${i}`].s = CELL_TYPES[transactionsType[i]];
        worksheet[`E${i}`].s = CELL_TYPES[transactionsType[i]];
      }

      if (i !== 1 && i !== spreadsheetData.length - 1) {
        worksheet[`E${i}`].t = 'n';
        worksheet[`E${i}`].z = '#,##0.00';
      }
    }

    worksheet['!merges'] = [
      {
        s: { r: spreadsheetData.length - 1, c: 0 },
        e: { r: spreadsheetData.length - 1, c: 3 },
      },
    ];

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Despesas_Por_Contatos');

    XLSX.writeFile(workbook, 'Zenply_Despesas_Por_Contato_Transacoes.xlsx', {
      type: 'array',
      bookType: 'xlsx',
    });
  } else {
    const spreadsheetData = [['Contato', '-', 'Valor']];

    const transactionsType = [null, 'HEADERS'];

    const total = transactions.reduce((total, item) => total + item.amount, 0);

    for (const transaction of transactions) {
      const percent = (transaction.amount / total);

      spreadsheetData.push([
        transaction.label || '-',
        percent,
        transaction.amount * -1,
      ]);

      transactionsType.push('EXPENSE');
    }

    spreadsheetData.push(
      [null, null, null],
      ['Total', '-', total * -1],
    );

    transactionsType.push(null, 'HEADERS');

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(spreadsheetData);

    worksheet['!cols'] = [{ width: 40 }, { width: 15 }, { width: 20 }];

    for (let i = 1; i <= spreadsheetData.length; i += 1) {
      if (transactionsType[i] !== null) {
        worksheet[`A${i}`].s = CELL_TYPES[transactionsType[i]];
        worksheet[`B${i}`].s = CELL_TYPES[transactionsType[i]];
        worksheet[`C${i}`].s = CELL_TYPES[transactionsType[i]];
      }
      if (i !== 1 && i !== spreadsheetData.length - 1 && i !== spreadsheetData.length) {
        worksheet[`B${i}`].t = 'n';
        worksheet[`B${i}`].z = '0.00%';
        worksheet[`C${i}`].t = 'n';
        worksheet[`C${i}`].z = '#,##0.00';
      }
      if (i === spreadsheetData.length) {
        worksheet[`C${i}`].t = 'n';
        worksheet[`C${i}`].z = '#,##0.00';
      }
    }

    worksheet['!merges'] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } },
      { s: { r: spreadsheetData.length - 1, c: 0 }, e: { r: spreadsheetData.length - 1, c: 1 } },
    ];

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Despesas_Por_Contatos');

    XLSX.writeFile(workbook, 'Zenply_Despesas_Por_Contato.xlsx', {
      type: 'array',
      bookType: 'xlsx',
    });
  }
};

export default reportToExcel;
