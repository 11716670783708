import FORMATTERS from 'helpers/formatters';

import filterEntries from './filterEntries';

const getTableTopDistance = (uploadResults) => {
  let alertHeight = 0;

  if (uploadResults) {
    alertHeight = 147;
  }

  const calcHeight = `calc(100vh - (110px + 61px + 65px + 1rem + 3rem + 66px + 1rem + ${alertHeight}px))`;

  return calcHeight;
};

const FILTERS_CONFIGURATION = {
  event_date_start: {
    label: 'Data a partir de',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  event_date_end: {
    label: 'Data até',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  amount_start: {
    label: 'Valor a partir de',
    formatter: FORMATTERS.NUMBER,
  },
  amount_end: {
    label: 'Valor até',
    formatter: FORMATTERS.NUMBER,
  },
  narration: {
    label: 'Histórico contém',
  },
};

export const DEFAULT_COLUMNS = [
  'id',
  'status',
  'event_date',
  'debit_account_id',
  'credit_account_id',
  'amount',
  'narration',
];

export const TABLE_COLUMNS = [
  {
    label: 'Status',
    keyName: 'status',
    sortingKey: 'status',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.BANK_STATEMENT_ENTRY_STATUS,
    order: 2,
  },
  {
    label: 'Data',
    keyName: 'event_date',
    sortingKey: 'event_date',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.DATE_DDMMYYYY,
    order: 3,
  },
  {
    label: 'Histórico',
    keyName: 'narration',
    sortingKey: 'narration',
    align: 'left',
    width: 40,
    order: 4,
  },
  {
    label: 'Débito',
    keyName: 'debit_account_id',
    sortingKey: 'debit_account_id',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.ACCOUNT_NAME,
    order: 5,
  },
  {
    label: 'Crédito',
    keyName: 'credit_account_id',
    sortingKey: 'credit_account_id',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.ACCOUNT_NAME,
    order: 6,
  },
  {
    label: 'Valor',
    keyName: 'amount',
    sortingKey: 'amount',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    sumOnFooter: true,
    order: 7,
  },
];

export {
  FILTERS_CONFIGURATION,
  filterEntries,
  getTableTopDistance,
};
