import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const fetchBankAccountBalance = (params) => {
  return async (dispatch) => {
    dispatch(request(params));

    service.fetchBankAccountBalance(params).then(
      (response) => {
        dispatch(success(response));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(params) {
    return {
      type: constants.FETCH_BANK_ACCOUNT_BALANCE_REQUEST,
      payload: params,
    };
  }
  function success(response) {
    return {
      type: constants.FETCH_BANK_ACCOUNT_BALANCE_SUCCESS,
      payload: response.data,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_BANK_ACCOUNT_BALANCE_FAILURE,
      payload: error,
    };
  }
};

export default fetchBankAccountBalance;
