const VALID_OPERATIONS = [
  {
    text: /\./g,
    value: '',
  },
  {
    text: /,/g,
    value: '.',
  },
  {
    text: /\xf7/g,
    value: '/',
  },
  {
    text: /x/g,
    value: '*',
  },
  {
    text: /(mais)/g,
    value: '+',
  },
  {
    text: /(menos)/g,
    value: '-',
  },
  {
    text: /(dividido por)/g,
    value: '/',
  },
  {
    text: /(vezes)/g,
    value: '*',
  },
  {
    text: /(pi)/g,
    value: Math.PI,
  },
  {
    text: /(phi)/g,
    value: 1.61803398875,
  },
  {
    text: /e/g,
    value: Math.E,
  },
];

const CONTROLS = [
  {
    text: '7',
    value: '7',
    isOperator: false,
  },
  {
    text: '8',
    value: '8',
    isOperator: false,
  },
  {
    text: '9',
    value: '9',
    isOperator: false,
  },
  {
    text: '÷',
    value: '/',
    isOperator: true,
  },
  {
    text: '4',
    value: '4',
    isOperator: false,
  },
  {
    text: '5',
    value: '5',
    isOperator: false,
  },
  {
    text: '6',
    value: '6',
    isOperator: false,
  },
  {
    text: 'x',
    value: '*',
    isOperator: true,
  },
  {
    text: '1',
    value: '1',
    isOperator: false,
  },
  {
    text: '2',
    value: '2',
    isOperator: false,
  },
  {
    text: '3',
    value: '3',
    isOperator: false,
  },
  {
    text: '-',
    value: '-',
    isOperator: true,
  },
  {
    text: '0',
    value: '0',
    isOperator: false,
  },

  {
    text: '00',
    value: '00',
    isOperator: false,
  },
  {
    text: ',',
    value: ',',
    isOperator: false,
  },
  {
    text: '+',
    value: '+',
    isOperator: true,
  },
];

const MAX_NUMBER = 99999999;

export {
  VALID_OPERATIONS,
  CONTROLS,
  MAX_NUMBER,
};
