import * as yup from 'yup';

import {
  REQUIRED,
} from 'helpers/validation-messages';

const AccountSchema = yup.object().shape({
  classifier: yup.string().required(REQUIRED),
  description: yup.string().required(REQUIRED),
  account_type: yup.mixed().oneOf(['ANALITICA', 'SINTETICA']).required(),
  account_nature: yup.mixed().oneOf(['ATIVO', 'PASSIVO', 'RECEITA', 'DESPESA']).required(),
});

export default AccountSchema;
