import styled, { css } from 'styled-components';

export const Container = styled.div`
  &&& {
    @media print {
      table thead th svg {
        display: none;
      }

      table thead tr th {
        width: auto;
        padding: 15px 15px;
      }

      table tbody tr td {
        width: auto;
        padding: 15px 15px;
      }

      table tfoot tr td {
        position: static;
      }
    }

    ${({ product }) => product === 'contabills-empresas' && css`
      @page {
        size: A4 portrait;
        margin: 5mm 5mm 5mm 5mm;
        padding: 0mm 0mm 0mm 0mm;
      }

      @media print {
        font-family: 'Poppins' !important;
        color: #000;

        table thead th svg {
          display: none;
        }

        table thead tr th {
          width: auto;
          padding: 8px 15px;
          color: #000;
        }

        table tbody tr td {
          width: auto;
          padding: 8px 15px;
          color: #000;
        }

        table tfoot tr td {
          position: static;
          color: #000;
        }
      }
    `}
  }
`;
