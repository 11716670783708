import { associatesConstants } from '../_constants';

const INITIAL_STATE = {
  associates: [],
  ui: {
    isLoading: false,
    hasErrors: false,
    errors: [],
  },
};

export function associates(state = INITIAL_STATE, action) {
  switch (action.type) {
    case associatesConstants.ASSOCIATES_GET_ALL_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoading: true,
        },
      };
    case associatesConstants.ASSOCIATES_GET_ALL_SUCCESS:
      return {
        ...state,
        associates: action.payload.associates,
        ui: {
          ...state.ui,
          isLoading: false,
        },
      };
    case associatesConstants.ASSOCIATES_ADD_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoading: true,
        },
      };
    case associatesConstants.ASSOCIATES_ADD_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoading: false,
        },
      };
    case associatesConstants.ASSOCIATES_ADD_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoading: false,
          errors: action.payload.errors,
        },
      };

    case associatesConstants.ASSOCIATES_DELETE_SUCCESS: {
      const previousState = state.associates;
      const newState = previousState.filter((associate) => associate.id !== action.payload.id);

      return {
        ...state,
        associates: newState,
      };
    }
    default:
      return state;
  }
}
