import { DateTime } from 'luxon';

import constants from '../../_constants/company-transactions.constants';

import fetchBankAccountBalance from './fetchBankAccountBalance';
import fetchCashflow from './fetchCashflow';
import fetchSemesterOverview from './fetchSemesterOverview';
import fetchAllTransactions from './fetchAllTransactions';
import fetchAllExpiredTransactions from './fetchAllExpiredTransactions';
import generateTransactionReport from './generateTransactionReport';
import fetchMonthComparison from './fetchMonthComparison';
import getConsumedStorage from './getConsumedStorage';

const loadDashboardData = (date = null, allAccounts, selectedAccountIds) => {
  return async (dispatch) => {
    dispatch(request());

    try {
      const today = date || DateTime.local();

      // Load balance for all accounts
      dispatch(fetchBankAccountBalance({
        account_ids: allAccounts.map((account) => account.id),
      }));

      dispatch(fetchCashflow({
        year: today.year,
        month: today.month,
        account_ids: selectedAccountIds,
      }));

      dispatch(fetchSemesterOverview({
        account_ids: selectedAccountIds,
      }));

      dispatch(fetchAllTransactions({
        account_ids: selectedAccountIds,
        year: today.year,
        month: today.month,
        paid: false,
      }));

      dispatch(fetchAllExpiredTransactions({
        account_ids: selectedAccountIds,
      }));

      dispatch(fetchMonthComparison({
        start_date: today.startOf('month').toISODate(),
        account_ids: selectedAccountIds,
      }));

      dispatch(generateTransactionReport({
        start_date: today.startOf('month').toISODate(),
        end_date: today.endOf('month').startOf('day').toISODate(),
        report_name: 'demonstrative_results',
        account_ids: allAccounts.map((account) => account.id),
      }));

      dispatch(getConsumedStorage());

      dispatch(success());
    } catch (error) {
      dispatch(failure(error));
    }
  };

  function request(id) {
    return {
      type: constants.LOAD_DASHBOARD_DATA_REQUEST,
      payload: {
        id,
      },
    };
  }
  function success(response) {
    return {
      type: constants.LOAD_DASHBOARD_DATA_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.LOAD_DASHBOARD_DATA_FAILURE,
      payload: error,
    };
  }
};

export default loadDashboardData;
