import FORMATTERS from 'helpers/formatters';

import filterEntries from './filterEntries';

const getTableTopDistance = (uploadResults) => {
  let alertHeight = 0;

  if (uploadResults) {
    alertHeight = 147;
  }

  const calcHeight = `calc(100vh - (110px + 61px + 65px + 1rem + 3rem + 66px + 1rem + ${alertHeight}px))`;

  return calcHeight;
};

const FILTERS_CONFIGURATION = {
  event_date_start: {
    label: 'Data a partir de',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  event_date_end: {
    label: 'Data até',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  amount_start: {
    label: 'Valor a partir de',
    formatter: FORMATTERS.NUMBER,
  },
  amount_end: {
    label: 'Valor até',
    formatter: FORMATTERS.NUMBER,
  },
  description: {
    label: 'Descrição contém',
  },
  amount_type: {
    label: 'Tipo de valor',
    formatter: FORMATTERS.BANK_STATEMENT_AMOUNT_TYPE,
  },
  status: {
    label: 'Status',
    formatter: FORMATTERS.BANK_STATEMENT_TRANSACTION_STATUS_STRING,
  },
};

export const DEFAULT_COLUMNS = [
  'id',
  'status',
  'event_date',
  'debit_account_id',
  'credit_account_id',
  'amount',
  'description',
];

export const getSortableColumns = (handleSort) => [
  {
    field: 'is_valid',
    path: 'is_valid',
    label: 'STATUS',
    sortable: true,
    sort: handleSort,
    className: 'text-left',
  },
  {
    field: 'event_date',
    path: 'event_date',
    label: 'DATA',
    sortable: true,
    sort: handleSort,
    className: 'text-center',
  },

  {
    field: 'description',
    path: 'description',
    label: 'DESCRIÇÃO',
    sortable: true,
    sort: handleSort,
  },
  {
    field: 'temp_type',
    path: 'temp_type',
    label: 'TIPO',
    sortable: true,
    sort: handleSort,
    className: 'text-left',
  },
  {
    field: 'amount',
    path: 'amount',
    label: 'VALOR',
    sortable: true,
    sort: handleSort,
    className: 'text-left',
  },
  {
    field: 'recipient',
    path: 'r.name',
    label: 'CONTATO',
    sortable: true,
    sort: handleSort,
  },
  {
    field: 'category',
    path: 'c.description',
    label: 'CATEGORIA',
    sortable: true,
    sort: handleSort,
  },
];

export {
  FILTERS_CONFIGURATION,
  filterEntries,
  getTableTopDistance,
};
