import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function Column({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

Column.propTypes = {
  children: PropTypes.node,
};
