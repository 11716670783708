const getTransactionsFiltersByTab = ({
  activeTab,
  selectedDate,
  account_ids,
  transactionFilters,
}) => {
  const year = Number(selectedDate.toFormat('yyyy'));
  const month = Number(selectedDate.toFormat('MM'));

  switch (activeTab) {
    case 'INCOME':
      return {
        account_ids,
        month,
        year,
        type: 'INCOME',
        with_split: true,
        ...transactionFilters,
      };
    case 'EXPENSE':
      return {
        account_ids,
        month,
        year,
        type: 'EXPENSE',
        with_split: true,
        ...transactionFilters,
      };
    case 'FIXED_EXPENSE':
      return {
        account_ids,
        month,
        year,
        type: 'EXPENSE',
        sub_type: 'FIXED_EXPENSE',
        with_split: true,
        ...transactionFilters,
      };
    case 'VARIABLE_EXPENSE':
      return {
        account_ids,
        month,
        year,
        type: 'EXPENSE',
        sub_type: 'VARIABLE_EXPENSE',
        with_split: true,
        ...transactionFilters,
      };
    case 'PEOPLE':
      return {
        account_ids,
        month,
        year,
        type: 'EXPENSE',
        sub_type: 'PEOPLE',
        with_split: true,
        ...transactionFilters,
      };
    case 'TAXES':
      return {
        account_ids,
        month,
        year,
        type: 'EXPENSE',
        sub_type: 'TAXES',
        with_split: true,
        ...transactionFilters,
      };
    case 'TRANSFER':
      return {
        account_ids,
        month,
        year,
        type: 'TRANSFER',
        with_split: true,
        ...transactionFilters,
      };
    default:
      return {};
  }
};

export default getTransactionsFiltersByTab;
