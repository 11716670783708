import * as yup from 'yup';

const CreateEmployeeSchema = yup.object().shape({
  employee_type: yup.string().required().nullable(true),
  employee_role: yup.string().required().nullable(true),
  employee_journey: yup.string().required().nullable(true),
  name: yup.string().required().nullable(true),
  salary: yup.number().required().nullable(true),
  date_admission: yup.date().nullable(false),
  birthdate: yup.date().nullable(false),
  document_type: yup.mixed().oneOf(['', 'CPF', 'CNPJ']).required(),
  document_number: yup.string().required().nullable(true),
  rg_number: yup.string().nullable(true),
  ctps_number: yup.string().nullable(true),
  pis_pasep_number: yup.string().nullable(true),
  mother_name: yup.string().nullable(true),
  email: yup.string().nullable(true),
  phone_number: yup.string().nullable(true),
});

export default CreateEmployeeSchema;
