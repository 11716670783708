import styled from 'styled-components';
import DropdownItem from 'react-bootstrap/DropdownItem';

import Modal from '_components/_core/Modal';

import { Button } from '_components/_core';

export const StyledModal = styled(Modal)`
  padding: 0 !important;

  div.modal-body {
    padding: 24px !important;
    max-height: 60vh;
    overflow: auto;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Option = styled(DropdownItem)`
  font-size: 14px;
  font-weight: 500;
  padding: 0;

  :hover, :focus {
    color: #00784e;
    background-color: transparent;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 0.8rem;
  vertical-align: top;
  font-weight: 600;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: 0.5rem;

  :hover, :focus {
    text-decoration: none;
    color: var(--text-primary);
    background-color: transparent;
  }

`;
