import constants from '../../_constants/invoices.constants';

const clearUploadResults = () => {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return {
      type: constants.CLEAR_UPLOAD_RESULTS,
      payload: {},
    };
  }
};

export default clearUploadResults;
