const hasPermissions = ({ permissions, userPermissions, type }) => {
  if (!userPermissions) return false;

  const userPermissionsKeys = Object.keys(userPermissions).filter((key) => userPermissions[key]);

  if (type === 'all') {
    return permissions.every((permission) => userPermissionsKeys.includes(permission));
  }

  if (type === 'any') {
    return permissions.some((permission) => userPermissionsKeys.includes(permission));
  }

  return false;
};

export default hasPermissions;
