import { connect } from 'react-redux';
import { compose } from 'redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import userActionCreators from '_store/_actions/user';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import ExportFinancialData from './ExportFinancialData';

const mapStateToProps = (state) => ({
  isLoading: state.fiscal.isLoading,
  activeCompany: state.company.activeCompany,
  subscription: state.subscription.subscription,
  users: state.user.usersForSelect,
});

const mapDispatchToProps = {
  onDownloadFinancialData: companyTransactionsActionCreators.downloadFinancialData,
  onFetchUsers: userActionCreators.fetchUsersForSelect,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['export_files'], 'all', true),
)(ExportFinancialData);
