import { parsePaginationParams } from 'helpers';

import constants from '../../_constants/employees.constants';
import service from '../../../services/employees.service';

const getAddEmployeeParams = (params = {}) => {
  return (dispatch) => {
    dispatch(request());

    const parsedParams = parsePaginationParams(params);
    const removePaginationLimitParams = { ...parsedParams, limit: -1 };

    const promises = [
      service.getAllTypes(),
      service.getAllJourneys(removePaginationLimitParams),
      service.getAllRoles(removePaginationLimitParams),
    ];

    Promise.all(promises).then(
      (values) => {
        const [types, journeys, roles] = values;

        dispatch(success({
          types: types.data,
          journeys: journeys.data,
          roles: roles.data,
        }));
      },
    );
  };

  function request() {
    return {
      type: constants.EMPLOYEE_GET_ADD_PARAMS_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.EMPLOYEE_GET_ADD_PARAMS_SUCCESS,
      payload: response,
    };
  }
};

export default getAddEmployeeParams;
