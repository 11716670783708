import { connect } from 'react-redux';

import TransactionsOverview from './TransactionsOverview';

const mapStateToProps = (state) => ({
  transactionsOverview: state.companyTransactions.transactionsOverview,
});

const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionsOverview);
