import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';
import alertActionCreators from '../alert.actions';

const updateEmailRequest = (data, callback, errorCallback) => {
  return (dispatch) => {
    dispatch(request(data));

    service.updateEmailRequest(data).then(
      (response) => {
        dispatch(success(response.data));

        if (callback) {
          callback(response.data);
        }

        dispatch(alertActionCreators.success('Código de confirmação enviado para o novo e-mail!'));
      },
    ).catch((error) => {
      dispatch(failure(error));

      if (errorCallback) {
        errorCallback(error);
      }
    });
  };

  function request() {
    return { type: constants.UPDATE_EMAIL_REQUEST_REQUEST };
  }
  function success(data) {
    return {
      type: constants.UPDATE_EMAIL_REQUEST_SUCCESS,
      payload: data,
    };
  }
  function failure(message) {
    return {
      type: constants.UPDATE_EMAIL_REQUEST_FAILURE,
      payload: { message },
    };
  }
};

export default updateEmailRequest;
