import React, { useCallback } from 'react';
import { DateTime } from 'luxon';
import has from 'lodash/has';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';

import FORMATTERS from 'helpers/formatters';

const useTransactionsHelpers = ({ values }) => {
  const isPastDue = useCallback((transaction) => {
    const today = DateTime.now();

    const field = values.date_type || 'event_date';

    let dateValue = null;

    if (has(transaction, field)) {
      dateValue = transaction[field];
    } else {
      dateValue = transaction.event_date;
    }

    return DateTime.fromISO(dateValue) < today;
  }, [values]);

  const getPaidIcon = useCallback((transaction) => {
    if (!transaction) {
      return null;
    }

    if (isPastDue(transaction) && !transaction.paid) {
      return (
        <span>
          <FaExclamationCircle className="text-danger mr-2" data-place="bottom" data-tip="Esta movimentação está com o pagamento atrasado." />
        </span>
      );
    }

    if (transaction.paid) {
      return (
        <span>
          <FaCheckCircle className="text-success mr-2" data-place="bottom" data-tip="Esta movimentação foi efetivada." />
        </span>
      );
    }

    return null;
  }, [isPastDue]);

  const getItemDate = useCallback((transaction) => {
    if (!transaction) {
      return null;
    }

    const field = values.date_type || 'event_date';

    let dateValue = null;

    if (has(transaction, field)) {
      dateValue = transaction[field];
    } else {
      dateValue = transaction.event_date;
    }

    return FORMATTERS.DATE_DDMMYYYY(dateValue);
  }, [values]);

  const getItemDescription = useCallback((transaction) => {
    if (!transaction) {
      return null;
    }

    if (transaction.payment_plan === 'INSTALMENT') {
      return `${transaction.description} (${transaction.frequency_number}/${transaction.frequency_total})`;
    }

    return transaction.description;
  }, []);

  return {
    getDescription: getItemDescription,
    getDate: getItemDate,
    getPaidIcon,
    isPastDue,
  };
};

export default useTransactionsHelpers;
