import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '_components/_core';

import { LineItem } from '../../styles';

function ManageCostCentersItem() {
  const history = useHistory();

  return (
    <LineItem className="text-center">
      <Button
        variant="link"
        className="p-0 m-0 pt-1 pb-1"
        onClick={() => history.push('/centros-de-custo')}
        noMargin
      >
        Gerenciar centros de custo
      </Button>
    </LineItem>
  );
}

export default ManageCostCentersItem;
