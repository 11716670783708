import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';
import alertActionCreators from '../alert.actions';

const deleteAvatar = (user_id, callback) => {
  return async (dispatch) => {
    dispatch(request(user_id));
    service.deleteUserAvatar(user_id).then(
      (response) => {
        const { avatar_url } = response.data;

        dispatch(success(user_id, avatar_url));
        dispatch(alertActionCreators.success('Avatar excluído com sucesso!'));

        if (callback) {
          callback();
        }
      },
    ).catch(
      (err) => {
        dispatch(failure(err));
      },
    );
  };

  function request() {
    return {
      type: constants.USER_AVATAR_DELETE_REQUEST,
      payload: { user_id },
    };
  }
  function success(user_id, avatar_url) {
    return {
      type: constants.USER_AVATAR_DELETE_SUCCESS,
      payload: { user_id, avatar_url },
    };
  }
  function failure(error) {
    return {
      type: constants.USER_AVATAR_DELETE_FAILURE,
      payload: { error },
    };
  }
};

export default deleteAvatar;
