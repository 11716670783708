import { push } from 'connected-react-router';

import constants from '../../_constants/auth.constants';
import service from '../../../services/auth.service';
import alertActionCreators from '../alert.actions';

const passwordReset = (email) => {
  return (dispatch) => {
    dispatch(request({ email }));

    service.passwordReset(email).then(
      (response) => {
        dispatch(success(response));
        dispatch(push('/entrar'));

        dispatch(alertActionCreators.success(response.data.message));
      },
    ).catch(({ response }) => {
      if (response.status === 422) {
        dispatch(failure(response.data));
        dispatch(alertActionCreators.error(response.data.message));
      } else {
        dispatch(failure(response.data));
      }
    });
  };

  function request(email) {
    return { type: constants.PASSWORD_RESET_REQUEST, payload: { email } };
  }
  function success(response) {
    return { type: constants.PASSWORD_RESET_SUCCESS, payload: { response } };
  }
  function failure(error) {
    return { type: constants.PASSWORD_RESET_FAILURE, payload: { error } };
  }
};

export default passwordReset;
