import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  Button,
  TableLookup,
  PageHeader,
  ConfirmDialog,
} from '_components/_core';
import { Pagination, ItemsPerPage } from '_components/_core/Table/components';
import { useTable } from '_components/_core/Table/utils';

import { DEFAULT_COLUMNS, TABLE_COLUMNS } from './utilities';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { field: 'name', order: 'asc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function List({
  history,
  total,
  employees,
  onFetchEmployees,
  onDeleteEmployee,
}) {
  const {
    sorting,
    pagination,
    selectedItems,
    onSorting,
    onPageChange,
    onPageSizeChange,
    onItemSelected,
    onSelectAll,
  } = useTable({
    ...tableConfig,
    data: employees,
    onFetchData: onFetchEmployees,
  });
  const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const renderAddButton = () => (
    <Button onClick={handleAddEmployee}>
      Cadastrar funcionário
    </Button>
  );

  const handleAddEmployee = useCallback(() => {
    history.push('/funcionarios/criar');
  }, [history]);

  const handleEdit = useCallback((id) => {
    history.push(`/funcionarios/${id}/editar`);
  }, [history]);

  const showConfirmDialog = useCallback((id) => {
    setSelectedEmployeeId(id);
    setIsOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleConfirm = useCallback(() => {
    onDeleteEmployee(selectedEmployeeId);
    setIsOpen(false);
  }, [onDeleteEmployee, selectedEmployeeId]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Funcionários"
        description="Listagem de colaboradores e sócios da empresa"
        sideContent={renderAddButton()}
      />
      <Row>
        <Col>
          <ConfirmDialog
            isOpen={isOpen}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            title="Excluir Funcionário"
            html={`
            Todos os dados desse funcionário serão excluídos. <br/><br/>
            Esta ação não pode ser revertida. <br/>
            `}
            icon="warning"
            iconColor="#DB504A"
            confirmButtonColor="#DB504A"
            confirmButtonText="Sim, deletar!"
            showCancelButton
          />
          <TableLookup
            keyName="id"
            name="employees-lookup-table"
            data={employees}
            columns={TABLE_COLUMNS}
            defaultColumns={DEFAULT_COLUMNS}
            sorting={sorting}
            onSorting={onSorting}
            selectedItems={selectedItems}
            onItemSelected={onItemSelected}
            onSelectAll={onSelectAll}
            onEdit={handleEdit}
            onDelete={showConfirmDialog}
            showColumnToggle
            topDistance="calc(100vh - (70px + 61px + 65px + 1rem + 3rem + 66px + 1rem))"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <ItemsPerPage
            itemsPerPage={pagination.itemsPerPage}
            onChange={onPageSizeChange}
            className="mt-3"
          />
        </Col>
        <Col className={classNames('d-flex flex-row-reverse', pagination.itemsPerPage > 10 ? 'mr-5' : '')}>
          <Pagination
            {...pagination}
            total={total}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
    </Container>
  );
}

List.defaultProps = {
  employees: [],
};

List.propTypes = {
  total: PropTypes.number.isRequired,
  employees: PropTypes.array,
  history: PropTypes.object,
  onFetchEmployees: PropTypes.func.isRequired,
  onDeleteEmployee: PropTypes.func.isRequired,
};

export default List;
