import styled, { css } from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';

import { Button, FormDateField } from '_components/_core';

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTable = styled.table`  
  &&& {
    font-weight: 400;

    thead tr th {
      height: 32px;
      line-height: 32px;
      padding: 0 9px;
      position: relative;
      background: #FFFFFF;
      vertical-align: middle;
      border-left: 1px solid #D4D7DC;
      border-bottom: 2px solid #D4D7DC;
      text-align: left;
      font-size: 0.9em;
      font-weight: 500;
      text-transform: uppercase;
    }

    thead tr th:first-child {
      border-left: none;
    }

    tbody tr td {
      line-height: 32px;
      padding: 6px 9px;
      vertical-align: middle;
      border-top: 1px solid #D4D7DC;
      display: table-cell;
      align-items: center;
      justify-content: center;
    }

    tbody tr.transaction-deleted td {
      background: #ffd !important;
    }

    tfoot tr td {
      line-height: 32px;
      padding: 6px 9px;
      vertical-align: middle;
      border-top: 1px solid #D4D7DC;
      display: table-cell;
      align-items: center;
      justify-content: center;
    }

    .table-dre {
      tbody tr td {
        background-color: #F9F9F9;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  font-size: 0.8rem;
  vertical-align: top;
  font-weight: 600;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: 0.5rem;

  :hover, :focus {
    text-decoration: none;
    color: var(--text-primary);
    background-color: transparent;
  }

`;

export const InstalmentsTable = styled.table`
   &&& {
    font-weight: 400;
    width: auto;

    thead tr th {
      height: 32px;
      line-height: 32px;
      background: #FFFFFF;
      padding: 0 9px;
      vertical-align: middle;
      border-left: 1px solid #D4D7DC;
      border-bottom: 2px solid #D4D7DC;
      text-align: left;
      font-size: 0.9em;
      font-weight: 500;
      text-transform: uppercase; 
      position: relative;
    }

    thead tr th:first-child {
      border-left: none;
    }

    thead, tfoot, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    thead {
      width: calc(100% - 1em);
    }

    tfoot {
      width: calc(100% - 1em);
    }

    tbody {
    }

    tbody tr td {
      line-height: 32px;
      padding: 6px 9px;
      border-top: 1px solid #D4D7DC;
    }

    tfoot tr td {
      line-height: 32px;
      padding: 6px 9px;
      vertical-align: middle;
      border-top: 1px solid #D4D7DC;
      display: table-cell;
      align-items: center;
      justify-content: center;
    }

    thead tr th.number-column,
    tbody tr td.number-column,
    tfoot tr td.number-column {
      width: 5%;
      text-align: center;

      ${({ isMobile }) => isMobile && css`
        width: 10%;
      `}
    }

    thead tr th.event-date-column,
    tbody tr td.event-date-column,
    tfoot tr td.event-date-column {    
      width: 20%;
      text-align: center;
      cursor: pointer;

      ${({ isMobile }) => isMobile && css`
        width: 35%;
        text-align: center;
      `}
    }
    
    tfoot tr td.event-date-column {
      text-align: right;

      
    }

    thead tr th.amount-column,
    tbody tr td.amount-column,
    tfoot tr td.amount-column {
      width: 50%;
      cursor: pointer;

      ${({ isMobile }) => isMobile && css`
        width: 20%;
        text-align: center;
      `}
    }

    thead tr th.amount-deleted-column,
    tbody tr td.amount-deleted-column,
    tfoot tr td.amount-deleted-column {
      width: 50%;
    }

    tbody tr td.amount-column {
      span:last-child {
        float: right;
        color: #21abf6;
        text-decoration: none;
        cursor: pointer;
        font-size: 0.8rem;
      }
    }

    .table-dre {
      tbody tr td {
        background-color: #F9F9F9;
      }
    }

    ${({ isMobile }) => isMobile && css`
      thead {
        display: none;
      }

      tbody, tfoot {
        tr {
          display: block;
        }

        td {
          display: flex;
          flex: 1;
          justify-content: space-between;
          padding: 4px 12px !important;
          width: 100% !important;
        }

        td {
          &:before {
            content: attr(data-label);
            flex-basis: 50%;
            text-align: left;
            font-weight: 600;
          }
        }
      }
    `}
  }
`;

export const StyledFormDateField = styled(FormDateField)`
  &&& {
    .react-datepicker-wrapper > .form-control {
      text-align: center !important;
    }
  }
`;

export const Option = styled.option`
  font-size: 13px;
  font-weight: 500;
  padding: 4px 16px;

   :hover, :focus {
    color: #00784e;
  }
`;

export const StyledDropdownItem = styled(Dropdown.Item)`
  :hover, :active {
    color: #00784e;
  }
`;

export const StyledDropdownMenu = styled(Dropdown.Menu)`
  transform: translate(0px, 35px);
  margin: 0;
  padding: 0;
`;
