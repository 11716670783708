import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Table,
} from '_components/_core';
import { TABLE_COLUMNS, DEFAULT_COLUMNS } from './utilities';
import { AddEmployeeSalaryModal } from './components';

function Salaries({
  employeeSalaries,
  onFetchEmployeeSalaries,
  onAddEmployeeSalary,
  onUpdateEmployeeSalary,
  onDeleteEmployeeSalary,
}) {
  const [selectedSalary, setSelectedSalary] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    onFetchEmployeeSalaries(id);
  }, [id, onFetchEmployeeSalaries]);

  const handleAddEmployeeSalary = useCallback(() => {
    setSelectedSalary(null);
    setIsModalOpen(true);
  }, []);

  const handleEditEmployeeSalary = useCallback((selectedSalary) => {
    setSelectedSalary(null);

    const salary = employeeSalaries.find((salary) => salary.id === selectedSalary);

    setSelectedSalary(salary);
    setIsModalOpen(true);
  }, [employeeSalaries]);

  const handleDeleteEmployeeSalary = useCallback((salaryId) => {
    onDeleteEmployeeSalary(id, salaryId);
  }, [onDeleteEmployeeSalary, id]);

  return (
    <>
      <AddEmployeeSalaryModal
        isVisible={isModalOpen}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
        onAddEmployeeSalary={onAddEmployeeSalary}
        onUpdateEmployeeSalary={onUpdateEmployeeSalary}
        selectedSalary={selectedSalary}
        employeeId={id}
      />
      <Card className="mb-3">
        <CardHeader
          title="Salários"
          description="Histórico salarial do funcionário selecionado"
          sideContent={(
            <Button className="mb-4" onClick={handleAddEmployeeSalary}>
              Novo Salário
            </Button>
          )}
        />
        <CardBody>
          <div className="table-responsive">
            <Table
              className="table-hover"
              keyName="id"
              data={employeeSalaries}
              columns={TABLE_COLUMNS}
              defaultColumns={DEFAULT_COLUMNS}
              onEdit={handleEditEmployeeSalary}
              onDelete={handleDeleteEmployeeSalary}
              showFooter
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
}

Salaries.propTypes = {
  employeeSalaries: PropTypes.array,
  onFetchEmployeeSalaries: PropTypes.func,
  onAddEmployeeSalary: PropTypes.func,
  onUpdateEmployeeSalary: PropTypes.func,
  onDeleteEmployeeSalary: PropTypes.func,
};

export default Salaries;
