import React, { useMemo, useRef, useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import classNames from 'classnames';

import FORMATTERS from 'helpers/formatters';
import { Card, CardBody } from '_components/_core';
import { Report } from '_components/_shared';

import { REPORT_FRIENDLY_NAMES, TRANSACTION_TYPES } from '../utilities';
import { ReportContext } from './ReportContext';
import PrintHeader from './PrintHeaderContainer';
import { StyledTable, CashflowByTypeTotalRow, StyledTransactionRow } from '../styles';
import useTransactionsHelpers from '../utilities/useTransactionsFormatters';

const StyledTableHeader = styled.thead`
  &&& {
    tr {
      th {
        box-sizing: content-box;
      }
    }
  }
`;

function TableReport({
  name,
  formattedPeriod,
  accounts,
  reports,
  account_ids,
  cost_center_ids,
  onEditTransaction,
  values,
  isMobile,
}) {
  const { setValue } = useContext(ReportContext);

  const { getDescription, getDate, getPaidIcon } = useTransactionsHelpers({ values });

  const reportRef = useRef();

  useEffect(() => {
    setValue(reportRef);
  }, [setValue]);

  const getFormattedRecipient = useCallback((type, item) => {
    let formatted = '';

    if (type === 'EXPENSE') {
      formatted = `Pago a: ${item.recipient.name}`;
    } else if (type === 'INCOME') {
      formatted = `Recebido de: ${item.recipient.name}`;
    }

    return formatted;
  }, []);

  const data = useMemo(() => {
    if (!reports) {
      return {};
    }

    const report = reports[name] || {};
    const {
      results = {
        transactions: [],
      },
    } = report;

    return results;
  }, [reports, name]);

  const renderSectionByType = useCallback(
    (key, renderSectionTotal, renderGroupTotal, groupTotalKey) => {
      const { total, transactions = [] } = data[key] || {};

      const { color, borderColor, label, parentLabel, type } = TRANSACTION_TYPES[key];

      let groupTotal = total;

      if (groupTotalKey) {
        groupTotal = data[groupTotalKey];
      }

      if (isEmpty(transactions)) {
        return null;
      }

      return (
        <StyledTable className="table table-hover">
          <StyledTableHeader style={{ display: 'table-row-group' }}>
            <tr>
              <th
                style={{ color, borderBottomColor: borderColor, fontWeight: '600' }}
                colSpan={type === 'TRANSFER' ? 5 : 4}
              >
                <span>{label}</span>
              </th>
            </tr>
            <tr>
              <th style={{ width: '130px' }}>Data</th>
              <th>Descrição</th>
              {type !== 'TRANSFER' && (
                <>
                  <th style={{ width: '160px' }}>Categoria</th>
                </>
              )}
              {type === 'TRANSFER' && (
                <>
                  <th>Conta de Origem</th>
                  <th>Conta de Destino</th>
                </>
              )}
              <th style={{ width: '140px' }} className="text-right">
                Valor
              </th>
            </tr>
          </StyledTableHeader>
          <tbody className="report-body">
            {transactions.map(item => (
              <StyledTransactionRow
                className="report-transaction-row"
                onClick={() => onEditTransaction(item)}
              >
                <td>
                  {getPaidIcon(item)}
                  {getDate(item)}
                </td>
                <td>
                  <span className="d-flex flex-column">
                    <span>{getDescription(item)}</span>
                    <span>
                      {item.type !== 'TRANSFER' && item.recipient.name && (
                        <small className="text-muted">
                          {getFormattedRecipient(type, item)}
                        </small>
                      )}
                      {!['transfers_send', 'transfers_received'].includes(key) &&
                        item.type === 'TRANSFER' &&
                        item.transfer_details && (
                          <small className="text-muted">
                            {FORMATTERS.REPORT_CASH_FLOW_TRANSFER(item, accounts)}
                          </small>
                        )}
                    </span>
                  </span>
                </td>
                {type !== 'TRANSFER' && (
                  <td>
                    <span>{item.category.description}</span>
                  </td>
                )}
                {type === 'TRANSFER' && (
                  <>
                    <td>
                      {FORMATTERS.REPORT_TRANSFER(accounts, item.origin_account, 'SENT')}
                    </td>
                    <td>
                      {FORMATTERS.REPORT_TRANSFER(
                        accounts,
                        item.destination_account,
                        'RECEIVED',
                      )}
                    </td>
                  </>
                )}
                <td className="text-right">
                  {FORMATTERS.REPORT_AMOUNT(item.amount, item.type, item.sub_type)}
                </td>
              </StyledTransactionRow>
            ))}
            {renderSectionTotal && (
              <tr>
                <td colSpan={type === 'TRANSFER' ? 4 : 3}>
                  <strong>Total de {label}</strong>
                </td>
                <td className="text-right">
                  <strong>{FORMATTERS.REPORT_AMOUNT(total, type, null, false)}</strong>
                </td>
              </tr>
            )}
            {renderGroupTotal && (
              <CashflowByTypeTotalRow>
                <td colSpan={type === 'TRANSFER' ? 4 : 3}>Total de {parentLabel}</td>
                <td className="text-right">
                  {FORMATTERS.REPORT_AMOUNT(groupTotal, type, null, false)}
                </td>
              </CashflowByTypeTotalRow>
            )}
          </tbody>
        </StyledTable>
      );
    },
    [
      data,
      accounts,
      onEditTransaction,
      getFormattedRecipient,
      getDate,
      getPaidIcon,
      getDescription,
    ],
  );

  const lastExpenseType = useMemo(() => {
    let last_type = null;

    const expense_types = ['fixed_expenses', 'variable_expenses', 'people', 'taxes'];

    expense_types.forEach(type => {
      if (data[type] && data[type].total > 0) {
        last_type = type;
      }
    });

    return last_type;
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <>
      <Report ref={reportRef}>
        <Card>
          <PrintHeader
            title={REPORT_FRIENDLY_NAMES[name].toUpperCase()}
            description={formattedPeriod}
            formattedPeriod={formattedPeriod}
            account_ids={account_ids}
            cost_center_ids={cost_center_ids}
          />
          <CardBody className="p-0 mt-0">
            <div className={classNames({ 'table-responsive': isMobile })}>
              {renderSectionByType('incomes', false, true)}
              {renderSectionByType(
                'fixed_expenses',
                true,
                lastExpenseType === 'fixed_expenses',
                'total_expenses',
              )}
              {renderSectionByType(
                'variable_expenses',
                true,
                lastExpenseType === 'variable_expenses',
                'total_expenses',
              )}
              {renderSectionByType(
                'people',
                true,
                lastExpenseType === 'people',
                'total_expenses',
              )}
              {renderSectionByType(
                'taxes',
                true,
                lastExpenseType === 'taxes',
                'total_expenses',
              )}
              <StyledTable className="table table-hover">
                {renderSectionByType('transfers_send', true)}
                {renderSectionByType('transfers_received', true)}
              </StyledTable>
            </div>
            <StyledTable className="table table-hover">
              <tbody>
                <CashflowByTypeTotalRow>
                  <td colSpan={4}>Saldo Anterior</td>
                  <td className="text-right">
                    {FORMATTERS.REPORT_BALANCE_AMOUNT(data.initial_balance)}
                  </td>
                </CashflowByTypeTotalRow>
                <tr>
                  <td colSpan={4}>
                    <strong>Total de Recebimentos no Período</strong>
                  </td>
                  <td className="text-right">
                    <strong>
                      {FORMATTERS.REPORT_BALANCE_AMOUNT(data.total_incomes)}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <strong>Total de Despesas no Período</strong>
                  </td>
                  <td className="text-right">
                    <strong>
                      {FORMATTERS.REPORT_BALANCE_AMOUNT(data.total_expenses * -1)}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <strong>Total de Transferências no Período</strong>
                  </td>
                  <td className="text-right">
                    <strong>
                      {FORMATTERS.REPORT_BALANCE_AMOUNT(data.total_transfers)}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <strong>Balanço no Período</strong>
                  </td>
                  <td className="text-right">
                    <strong>
                      {FORMATTERS.REPORT_BALANCE_AMOUNT(data.balance_at_time)}
                    </strong>
                  </td>
                </tr>
                <CashflowByTypeTotalRow>
                  <td colSpan={4}>
                    <strong>Saldo Final</strong>
                  </td>
                  <td className="text-right">
                    <strong>
                      {FORMATTERS.REPORT_BALANCE_AMOUNT(data.final_balance)}
                    </strong>
                  </td>
                </CashflowByTypeTotalRow>
              </tbody>
            </StyledTable>
          </CardBody>
        </Card>
      </Report>
    </>
  );
}

TableReport.defaultProps = {
  reports: {},
  accounts: [],
  formattedPeriod: '',
  values: {},
  isMobile: false,
};

TableReport.propTypes = {
  accounts: PropTypes.array,
  values: PropTypes.object,
  formattedPeriod: PropTypes.string,
  name: PropTypes.string.isRequired,
  activeCompany: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  reports: PropTypes.object,
  account_ids: PropTypes.array,
  cost_center_ids: PropTypes.array,
  onEditTransaction: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default TableReport;
