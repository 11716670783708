import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardHeader,
  Table,
  Button,
} from '_components/_core';

import { CredentialModal } from './components';
import { TABLE_COLUMNS, DEFAULT_COLUMNS } from './utilities';

function Credentials({
  company,
  isLoading,
  onAddCredential,
  onUpdateCredential,
  onDeleteCredential,
  onCompanyInfoChange,
}) {
  const [selectedCredential, setSelectedCredential] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const credentials = useMemo(() => {
    if (!company) {
      return [];
    }

    const { credentials: data } = company || {};

    if (!data) {
      return [];
    }

    return data;
  }, [company]);

  const handleAddCredential = useCallback(() => {
    setSelectedCredential(null);
    setIsModalOpen(true);
  }, []);

  const handleEditCredential = useCallback((id) => {
    setSelectedCredential(null);

    const credential = credentials.find((credential) => credential.id === id);

    setSelectedCredential(credential);
    setIsModalOpen(true);
  }, [credentials]);

  const handleDeleteCredential = useCallback((id) => {
    const { id: company_id } = company || {};

    onDeleteCredential(company_id, id, (updatedCompany) => {
      onCompanyInfoChange(updatedCompany);
    });
  }, [onDeleteCredential, onCompanyInfoChange, company]);

  return (
    <Card className="mt-3">
      <CredentialModal
        company={company}
        isVisible={isModalOpen}
        credential={selectedCredential}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
        isLoading={isLoading}
        onAddCredential={onAddCredential}
        onUpdateCredential={onUpdateCredential}
        onCompanyInfoChange={onCompanyInfoChange}
      />
      <CardHeader
        title="Credenciais"
        description="Cadastre aqui as credenciais necessárias para que o Contabills consiga buscar informações automaticamente nos sites do governo."
        sideContent={(
          <Button className="mb-4" onClick={handleAddCredential}>
            Nova Credencial
          </Button>
        )}
      />
      <Table
        key="id"
        data={credentials}
        defaultColumns={DEFAULT_COLUMNS}
        columns={TABLE_COLUMNS}
        onEdit={handleEditCredential}
        onDelete={handleDeleteCredential}
        hover
      />
    </Card>
  );
}

Credentials.propTypes = {
  company: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onAddCredential: PropTypes.func.isRequired,
  onUpdateCredential: PropTypes.func.isRequired,
  onDeleteCredential: PropTypes.func.isRequired,
  onCompanyInfoChange: PropTypes.func.isRequired,
};

export default Credentials;
