import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import classNames from 'classnames';
import { FaStar } from 'react-icons/fa';

import FORMATTERS from 'helpers/formatters';

import { PermissionsGate } from '_components/_shared';
import {
  Checkbox,
  BalanceContainer,
  LineItem,
} from '../../styles';

function AccountItem({
  account,
  balance,
  selectedAccountIds,
  displayBalance,
  variant,
  isMultiple,
  displayPrincipalStar,
  onAccountSelected,
  shortenAccountNames,
}) {
  const {
    id,
    is_principal,
    description,
  } = account || {};

  const isChecked = useCallback((account_id) => {
    if (isEmpty(selectedAccountIds)) {
      return false;
    }

    return selectedAccountIds.includes(account_id);
  }, [selectedAccountIds]);

  const isAccountActive = useCallback((account_id) => {
    if (isEmpty(selectedAccountIds)) {
      return false;
    }

    return selectedAccountIds.includes(account_id);
  }, [selectedAccountIds]);

  const handleCheckboxClick = useCallback((e) => {
    e.stopPropagation();

    onAccountSelected(id);
  }, [id, onAccountSelected]);

  const handleAccountSelected = useCallback(() => {
    onAccountSelected(id);
  }, [id, onAccountSelected]);

  const renderBalance = useCallback((account) => {
    if (!displayBalance) {
      return null;
    }

    if (!balance) {
      return null;
    }

    if (!account) {
      return null;
    }

    if (!has(balance, id)) {
      return null;
    }

    const { balance: account_balance } = balance[id];

    let className = account_balance < 0 ? 'text-danger' : 'text-success';

    if (account_balance === 0) {
      className = 'text-muted';
    }

    return (
      <PermissionsGate permissions={['view_balance']}>
        <span className={classNames('ml-5', className)}>
          {FORMATTERS.NUMBER(account_balance)}
        </span>
      </PermissionsGate>
    );
  }, [id, balance, displayBalance]);

  const renderStar = useCallback(() => {
    if (!displayPrincipalStar) {
      return null;
    }

    if (!is_principal) {
      return null;
    }

    return <FaStar className="mr-2 text-muted" />;
  }, [is_principal, displayPrincipalStar]);

  const renderDescription = useCallback(() => {
    if (!description) {
      return null;
    }

    let formattedDescription = description;

    if (shortenAccountNames) {
      formattedDescription = FORMATTERS.MAX_X_CHARS(description, 18);
    }

    if (!displayPrincipalStar) {
      <span>
        {formattedDescription}
      </span>;
    }

    let className = '';

    if (variant === 'default' && !is_principal) {
      className = 'ml-4';
    }

    return (
      <span className={className}>
        {formattedDescription}
      </span>
    );
  }, [description, displayPrincipalStar, is_principal, variant, shortenAccountNames]);

  return (
    <LineItem
      key={id}
      eventKey={id}
      onClick={handleAccountSelected}
      className="d-flex justify-content-start align-items-center"
      variant={variant}
      active={isAccountActive(id)}
      as="div"
      role="button"
    >
      {isMultiple && (
        <Checkbox
          key={`checkbox-${id}`}
          type="checkbox"
          checked={isChecked(id)}
          onClick={(e) => handleCheckboxClick(e, id)}
        />
      )}
      <BalanceContainer onClick={handleAccountSelected} className={isMultiple ? 'ml-3' : ''}>
        <span>
          {renderStar()}
          {renderDescription()}
        </span>
        {renderBalance(account)}
      </BalanceContainer>
    </LineItem>
  );
}

AccountItem.defaultProps = {
  isMultiple: true,
  displayBalance: false,
  balance: {},
  variant: 'default',
  displayPrincipalStar: false,
  selectedAccountIds: [],
};

AccountItem.propTypes = {
  selectedAccountIds: PropTypes.array,
  account: PropTypes.object.isRequired,
  balance: PropTypes.object,
  isMultiple: PropTypes.bool,
  displayBalance: PropTypes.bool,
  displayPrincipalStar: PropTypes.bool,
  variant: PropTypes.string,
  onAccountSelected: PropTypes.func.isRequired,
  shortenAccountNames: PropTypes.bool.isRequired,
};

export default AccountItem;
