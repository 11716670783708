import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';
import { DateTime } from 'luxon';

import {
  Card,
  CardBody,
  FormCurrencyField,
  FormSelectField,
  FormTextField,
  Alert,
} from '_components/_core';
import { AddressFormFields } from '_components/_shared';

import FormHeader from '../FormHeader/FormHeader';
import FormFooter from '../FormFooter/FormFooter';
import FormInitialBalanceField from '../FormInitialBalanceField/FormInitialBalanceField';

import CreateFinancialCompanySchema from '../../utilities/CreateFinancialCompanySchema';

function FormFamily({
  user,
  menuItem,
  onChange,
  onAddFinancialCompany,
  isLoading,
  isOnboarding,
}) {
  const EMPLOYEE_COUNT_OPTIONS = [
    { value: 'NONE', label: 'Nenhum' },
    { value: 'ONE_TO_THREE', label: 'de 1 a 3' },
    { value: 'MORE_THAN_THREE', label: 'mais de 3' },
  ];

  const handleSubmitForm = useCallback((values) => {
    const params = {
      status_irs: 'ATIVA',
      date_founded: DateTime.now().toFormat('yyyy-MM-dd'),
      trading_name: values.company_name,
      ...values,
    };
    onAddFinancialCompany(params, isOnboarding);
  }, [onAddFinancialCompany, isOnboarding]);

  return (
    <>
      <Formik
        initialValues={{
          type: menuItem.key,
          company_name: user.document_type === 'PF' ? user.full_name : null,
          trading_name: '   ',
          address_state_ibge: null,
          address_state: null,
          address_city_ibge: null,
          address_city: null,
          employees_count: 'NONE',
          balance_type: 'POSITIVO',
          initial_balance: 0,
        }}
        onSubmit={handleSubmitForm}
        validationSchema={CreateFinancialCompanySchema}
        enableReinitialize
      >
        {({ handleSubmit, isValid }) => (
          <>
            <Card>
              <CardBody className="col-12 pb-0">
                <FormHeader menuItem={menuItem} />
                <hr className="pb-2 mt-4" />
                <Form.Row>
                  <Form.Group as={Col} lg="12">
                    <Form.Label>Nome da empresa</Form.Label>
                    <FormTextField
                      name="company_name"
                      placeholder="Nome da empresa"
                    />
                  </Form.Group>
                </Form.Row>
                <AddressFormFields variant="STATE_AND_CITY" />
                <Form.Row>
                  <Form.Group as={Col} lg="6">
                    <Form.Label>Quantos funcionários você possui em sua casa?</Form.Label>
                    <FormSelectField
                      name="employees_count"
                      options={EMPLOYEE_COUNT_OPTIONS}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} lg="12">
                    <Alert description="Marque a quantidade de funcionários contratados pela família, como jardineiros, diaristas, empregados domésticos etc." />
                  </Form.Group>
                </Form.Row>
                <hr className="mt-2 mb-4" />
                <Form.Row>
                  <Form.Group as={Col} lg="4">
                    <Form.Label>Saldo inicial em conta:</Form.Label>
                    <FormCurrencyField name="initial_balance" placeholder="Valor Inicial" />
                  </Form.Group>
                  <Form.Group as={Col} xl="8">
                    <FormInitialBalanceField name="balance_type" />
                  </Form.Group>
                </Form.Row>
              </CardBody>
            </Card>
            <FormFooter
              isLoading={isLoading}
              onSubmit={handleSubmit}
              onChange={onChange}
              isValid={isValid}
            />
          </>
        )}
      </Formik>
    </>
  );
}

FormFamily.defaultProps = {
  isLoading: false,
  isOnboarding: false,
};

FormFamily.propTypes = {
  user: PropTypes.object,
  isLoading: PropTypes.bool,
  isOnboarding: PropTypes.bool,
  menuItem: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onAddFinancialCompany: PropTypes.func.isRequired,
};

export default FormFamily;
