import React from 'react';
import FORMATTERS from 'helpers/formatters';
import { IoArrowDown, IoArrowUp, IoSwapHorizontalOutline } from 'react-icons/io5';
import { IoMdPeople } from 'react-icons/io';
import { HiReceiptTax } from 'react-icons/hi';

export const DEFAULT_CHART_OPTIONS = {
  chart: {
    id: 'revenue-chart',
    toolbar: {
      show: true,
      tools: {
        download: true,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
      },
    },
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    curve: 'smooth',
    lineCap: 'round',
    width: 4,
  },
  title: {
    align: 'left',
  },
  dataLabels: {
    formatter: value => FORMATTERS.NUMBER_WITHOUT_CURRENCY(value),
  },
  yaxis: {
    labels: {
      formatter: value => FORMATTERS.NUMBER_WITHOUT_CURRENCY(value),
    },
  },
};

export const DOCUMENT_TYPE_OPTIONS = [
  {
    value: 'CPF',
    label: 'CPF',
  },
  {
    value: 'CNPJ',
    label: 'CNPJ',
  },
];

export const ENTRY_TYPE_OPTIONS = [
  {
    value: '1D1C',
    label: '1 Débito para 1 Crédito',
  },
  {
    value: '1DVC',
    label: '1 Débito para Vários Créditos',
  },
  {
    value: '1CVD',
    label: '1 Crédito para Vários Débitos',
  },
];

export const TAX_REGIME = [
  {
    value: 'SIMPLES_NACIONAL',
    label: 'Simples Nacional',
  },
  {
    value: 'LUCRO_PRESUMIDO',
    label: 'Lucro Presumido',
  },
  {
    value: 'LUCRO_REAL',
    label: 'Lucro Real',
  },
  {
    value: 'MEI',
    label: 'MEI',
  },
];

export const TAX_REGIME_OPTIONS = [
  {
    value: null,
    label: 'Todos',
  },
  {
    value: 'SIMPLES_NACIONAL',
    label: 'Simples Nacional',
  },
  {
    value: 'LUCRO_PRESUMIDO',
    label: 'Lucro Presumido',
  },
  {
    value: 'LUCRO_REAL',
    label: 'Lucro Real',
  },
  {
    value: 'MEI',
    label: 'MEI',
  },
];

export const STATUS_IRS = [
  {
    value: 'ATIVA',
    label: 'Ativa',
  },
  {
    value: 'SUSPENSA',
    label: 'Suspensa',
  },
  {
    value: 'INAPTA',
    label: 'Inapta',
  },
  {
    value: 'BAIXADA',
    label: 'Baixada',
  },
  {
    value: 'NULA',
    label: 'Nula',
  },
];

export const STATUS_IRS_OPTIONS = [
  {
    value: null,
    label: 'Todos',
  },
  {
    value: 'ATIVA',
    label: 'Ativa',
  },
  {
    value: 'SUSPENSA',
    label: 'Suspensa',
  },
  {
    value: 'INAPTA',
    label: 'Inapta',
  },
  {
    value: 'BAIXADA',
    label: 'Baixada',
  },
  {
    value: 'NULA',
    label: 'Nula',
  },
];

export const ISS_WITHHELD_OPTIONS = [
  {
    label: 'Todos',
    value: null,
  },
  {
    label: 'Sim',
    value: true,
  },
  {
    label: 'Não',
    value: false,
  },
];

export const ACCOUNT_TYPE_OPTIONS = [
  {
    label: 'Analítica',
    value: 'ANALITICA',
  },
  {
    label: 'Sintética',
    value: 'SINTETICA',
  },
];

export const ACCOUNT_NATURE_OPTIONS = [
  {
    label: 'Ativo',
    value: 'ATIVO',
  },
  {
    label: 'Passivo',
    value: 'PASSIVO',
  },
  {
    label: 'Receita',
    value: 'RECEITA',
  },
  {
    label: 'Despesa',
    value: 'DESPESA',
  },
];

export const ANEXO_OPTIONS = [
  { value: 'FROM_INVOICE', label: "Usar CNAE's das Notas Fiscais" },
  { value: 'I', label: 'Anexo I' },
  { value: 'II', label: 'Anexo II' },
  { value: 'III', label: 'Anexo III' },
  { value: 'IV', label: 'Anexo IV' },
  { value: 'V', label: 'Anexo V' },
];

export const LUCRO_PRESUMIDO_IRPJ_OPTIONS = [
  { value: '1,6%', label: '1,6% - Revenda de combustíveis e gás natural' },
  { value: '8%', label: '8% - Regra geral' },
  { value: '16%', label: '16% - Serviços de transporte (que não sejam de carga)' },
  { value: '32%', label: '32% - Prestação de serviços em geral' },
];

export const LUCRO_PRESUMIDO_CSLL_OPTIONS = [
  {
    value: '12%',
    label: '12% - Atividades comerciais, industriais, hospitalares e de transporte',
  },
  { value: '32%', label: '32% - Prestação de serviços em geral' },
];

export const MEI_TYPE_OPTIONS = [
  { value: 'SERVICO', label: 'Serviço' },
  { value: 'COMERCIO', label: 'Comércio' },
  { value: 'COMERCIO_SERVICO', label: 'Comércio & Serviço' },
];

export const CREDENTIAL_TYPE_OPTIONS = [
  {
    value: 'CITY_HALL',
    label: 'Prefeitura',
  },
];

export const DATE_TYPE_OPTIONS = [
  {
    value: 'date_emission',
    label: 'Data de Emissão',
  },
  {
    value: 'date_competence',
    label: 'Data de Competência',
  },
];

export const LUCRO_PRESUMIDO_TAXES_OPTIONS = [
  {
    value: 'PIS',
    label: 'PIS',
  },
  {
    value: 'COFINS',
    label: 'COFINS',
  },
  {
    value: 'IRPJ',
    label: 'IRPJ',
  },
  {
    value: 'CSLL',
    label: 'CSLL',
  },
];

export const TRANSACTIONS_ACCOUNTS_TYPE_OPTIONS = [
  {
    value: 'CHECKING',
    label: 'Conta Corrente',
  },
  {
    value: 'SAVINGS',
    label: 'Conta Poupança',
  },
  {
    value: 'WALLET',
    label: 'Carteira',
  },
  {
    value: 'CASH',
    label: 'Cofre',
  },
  {
    value: 'INVESTMENT',
    label: 'Investimento',
  },
  {
    value: 'CREDIT_CARD',
    label: 'Cartão de Crédito',
  },
  {
    value: 'DEBIT_CARD',
    label: 'Cartão de Débito',
  },
  {
    value: 'OTHER',
    label: 'Outro',
  },
];

export const TRANSACTION_TYPE_OPTIONS = [
  {
    value: 'INCOME',
    label: 'Recebimento',
  },
  {
    value: 'EXPENSE',
    label: 'Despesa',
  },
];

export const TRANSACTION_SUB_TYPE_OPTIONS = [
  {
    value: 'FIXED_EXPENSE',
    label: 'Despesa Fixa',
  },
  {
    value: 'VARIABLE_EXPENSE',
    label: 'Despesa Variável',
  },
  {
    value: 'PEOPLE',
    label: 'Pessoas',
  },
  {
    value: 'TAXES',
    label: 'Impostos',
  },
];

export const CONTACT_TYPE_OPTIONS = [
  {
    value: 'CUSTOMER',
    label: 'Cliente',
  },
  {
    value: 'SUPPLIER',
    label: 'Fornecedor',
  },
  {
    value: 'EMPLOYEE',
    label: 'Funcionário',
  },
];

export const TRANSACTION_FREQUENCY_OPTIONS = [
  {
    value: 'WEEKLY',
    label: 'Semanal',
  },
  {
    value: 'BIWEEKLY',
    label: 'Quinzenal',
  },
  {
    value: 'MONTHLY',
    label: 'Mensal',
  },
  {
    value: 'BIMONTHLY',
    label: 'Bimestral',
  },
  {
    value: 'QUARTERLY',
    label: 'Trimestral',
  },
  {
    value: 'BIANNUAL',
    label: 'Semestral',
  },
  {
    value: 'ANNUAL',
    label: 'Anual',
  },
];

export const TRANSACTION_TYPE_SUB_TYPE_OPTIONS = [
  {
    label: 'Receita',
    value: 'INCOME::',
    icon: <IoArrowUp className="text-success mr-2" />,
  },
  {
    label: 'Despesa Fixa',
    value: 'EXPENSE::FIXED_EXPENSE',
    icon: <IoArrowDown className="text-danger mr-2" />,
  },
  {
    label: 'Despesa Variável',
    value: 'EXPENSE::VARIABLE_EXPENSE',
    icon: <IoArrowDown className="text-danger mr-2" />,
  },
  {
    label: 'Despesa com Pessoas',
    value: 'EXPENSE::PEOPLE',
    icon: <IoMdPeople className="text-danger mr-2" />,
  },
  {
    label: 'Despesa com Impostos',
    value: 'EXPENSE::TAXES',
    icon: <HiReceiptTax className="text-danger mr-2" />,
  },
  {
    label: 'Transferência',
    value: 'TRANSFER::',
    icon: <IoSwapHorizontalOutline className="text-info mr-2" />,
  },
];

export const TRANSACTION_TYPE_SUB_TYPE_OPTIONS_V2 = [
  {
    label: 'Receitas',
    value: 'INCOME::',
    icon: <IoArrowUp className="text-success mr-2" />,
  },
  {
    label: 'Despesas',
    value: 'EXPENSE::',
    icon: <IoArrowDown className="text-danger mr-2" />,
  },
  {
    label: 'Despesas Fixas',
    value: 'EXPENSE::FIXED_EXPENSE',
    icon: <IoArrowDown className="text-danger mr-2" />,
  },
  {
    label: 'Despesas Variáveis',
    value: 'EXPENSE::VARIABLE_EXPENSE',
    icon: <IoArrowDown className="text-danger mr-2" />,
  },
  {
    label: 'Despesas com Pessoas',
    value: 'EXPENSE::PEOPLE',
    icon: <IoMdPeople className="text-danger mr-2" />,
  },
  {
    label: 'Despesas com Impostos',
    value: 'EXPENSE::TAXES',
    icon: <HiReceiptTax className="text-danger mr-2" />,
  },
  {
    label: 'Transferências',
    value: 'TRANSFER::',
    icon: <IoSwapHorizontalOutline className="text-info mr-2" />,
  },
  {
    label: 'Transferência Enviada',
    value: 'TRANSFER::SENT',
    icon: <IoSwapHorizontalOutline className="text-info mr-2" />,
  },
  {
    label: 'Transferência Recebida',
    value: 'TRANSFER::RECEIVED',
    icon: <IoSwapHorizontalOutline className="text-info mr-2" />,
  },
];

export const INCOME_TYPE_SUB_TYPE_OPTIONS = [
  {
    label: 'Receita',
    value: 'INCOME::',
  },
  {
    label: 'Transferência',
    value: 'TRANSFER::',
  },
];

export const EXPENSE_TYPE_SUB_TYPE_OPTIONS = [
  {
    label: 'Despesa Fixa',
    value: 'EXPENSE::FIXED_EXPENSE',
  },
  {
    label: 'Despesa Variável',
    value: 'EXPENSE::VARIABLE_EXPENSE',
  },
  {
    label: 'Despesa com Pessoas',
    value: 'EXPENSE::PEOPLE',
  },
  {
    label: 'Despesa com Impostos',
    value: 'EXPENSE::TAXES',
  },
  {
    label: 'Transferência',
    value: 'TRANSFER::',
  },
];

const PERMISSIONS = [
  {
    key: 'aba_recebimento_view',
    label: 'Visualizar aba',
    type: 'INCOME',
  },
  {
    key: 'aba_recebimento_create',
    label: 'Incluir transação',
    type: 'INCOME',
  },
  {
    key: 'aba_recebimento_edit',
    label: 'Alterar transação',
    type: 'INCOME',
  },
  {
    key: 'aba_recebimento_delete',
    label: 'Excluir transação',
    type: 'INCOME',
  },
  {
    key: 'aba_despesa_fixa_view',
    label: 'Visualizar aba',
    type: 'FIXED_EXPENSE',
  },
  {
    key: 'aba_despesa_fixa_create',
    label: 'Incluir transação',
    type: 'FIXED_EXPENSE',
  },
  {
    key: 'aba_despesa_fixa_edit',
    label: 'Alterar transação',
    type: 'FIXED_EXPENSE',
  },
  {
    key: 'aba_despesa_fixa_delete',
    label: 'Excluir transação',
    type: 'FIXED_EXPENSE',
  },
  {
    key: 'aba_despesa_variavel_view',
    label: 'Visualizar aba',
    type: 'VARIABLE_EXPENSE',
  },
  {
    key: 'aba_despesa_variavel_create',
    label: 'Incluir transação',
    type: 'VARIABLE_EXPENSE',
  },
  {
    key: 'aba_despesa_variavel_edit',
    label: 'Alterar transação',
    type: 'VARIABLE_EXPENSE',
  },
  {
    key: 'aba_despesa_variavel_delete',
    label: 'Excluir transação',
    type: 'VARIABLE_EXPENSE',
  },
  {
    key: 'aba_pessoal_view',
    label: 'Visualizar aba',
    type: 'PEOPLE',
  },
  {
    key: 'aba_pessoal_create',
    label: 'Incluir transação',
    type: 'PEOPLE',
  },
  {
    key: 'aba_pessoal_edit',
    label: 'Alterar transação',
    type: 'PEOPLE',
  },
  {
    key: 'aba_pessoal_delete',
    label: 'Excluir transação',
    type: 'PEOPLE',
  },
  {
    key: 'aba_imposto_view',
    label: 'Visualizar aba',
    type: 'TAXES',
  },
  {
    key: 'aba_imposto_create',
    label: 'Incluir transação',
    type: 'TAXES',
  },
  {
    key: 'aba_imposto_edit',
    label: 'Alterar transação',
    type: 'TAXES',
  },
  {
    key: 'aba_imposto_delete',
    label: 'Excluir transação',
    type: 'TAXES',
  },
  {
    key: 'aba_transferencia_view',
    label: 'Visualizar aba',
    type: 'TRANSFER',
  },
  {
    key: 'aba_transferencia_create',
    label: 'Incluir transação',
    type: 'TRANSFER',
  },
  {
    key: 'aba_transferencia_edit',
    label: 'Alterar transação',
    type: 'TRANSFER',
  },
  {
    key: 'aba_transferencia_delete',
    label: 'Excluir transação',
    type: 'TRANSFER',
  },

  {
    key: 'view_month_forecast_result',
    label: 'Resultado previsto do mês',
    type: 'TRANSACTIONS_1',
  },
  {
    key: 'view_month_incomes',
    label: 'Recebimentos do mês',
    type: 'TRANSACTIONS_1',
  },
  {
    key: 'view_month_expenses',
    label: 'Despesas do mês',
    type: 'TRANSACTIONS_1',
  },

  {
    key: 'view_quarter_forecast',
    label: 'Resultado do trimestre',
    type: 'TRANSACTIONS_2',
  },
  {
    key: 'view_month_forecast_balance',
    label: 'Previsão de fechamento do mês',
    type: 'TRANSACTIONS_2',
  },

  {
    key: 'view_balance',
    label: 'Visualizar saldos das contas',
    type: 'VIEW_DATA',
  },

  {
    key: 'view_dashboard',
    label: 'Dashboard',
    type: 'VIEW_DATA',
  },
  {
    key: 'reports_view',
    label: 'Relatórios',
    type: 'VIEW_DATA',
  },
  {
    key: 'manager_contacts',
    label: 'Contatos',
    type: 'VIEW_DATA',
  },
  {
    key: 'manager_search',
    label: 'Busca geral',
    type: 'VIEW_DATA',
  },
  {
    key: 'manager_bank_accounts',
    label: 'Gerenciar Contas Bancárias',
    type: 'CORE',
  },
  {
    key: 'manager_categories',
    label: 'Gerenciar Categorias',
    type: 'CORE',
  },
  {
    key: 'manager_cost_centers',
    label: 'Gerenciar Centros de Custo',
    type: 'CORE',
  },
  {
    key: 'manager_tags',
    label: 'Gerenciar Tags',
    type: 'CORE',
  },
  {
    key: 'manager_receipt',
    label: 'Gerenciar Recibos Personalizados',
    type: 'CORE',
  },
  {
    key: 'manager_company',
    label: 'Gerenciar Empresas',
    type: 'CORE',
  },
  {
    key: 'manager_permissions',
    label: 'Gerenciar Permissões',
    type: 'CORE',
  },
  {
    key: 'buy_services',
    label: 'Gerenciar Assinatura',
    type: 'CORE',
  },
  {
    key: 'manager_users',
    label: 'Gerenciar Usuários',
    type: 'CORE',
  },
  {
    key: 'access_api',
    label: 'Gerenciar Integrações (API)',
    type: 'CORE',
    hidden: true,
  },
  {
    key: 'manager_logs',
    label: 'Visualizar Logs',
    type: 'CORE',
  },
  {
    key: 'manager_invoices',
    label: 'Gerenciar Notas Fiscais',
    hidden: true,
    type: 'CORE',
  },
  {
    key: 'manager_bankslip',
    label: 'Gerenciar Boletos',
    hidden: true,
    type: 'CORE',
  },
  {
    key: 'import_files',
    label: 'Importar OFX e Planilhas',
    type: 'FILES',
  },
  {
    key: 'export_files',
    label: 'Exportar dados financeiros',
    type: 'FILES',
  },
  {
    key: 'file_upload',
    label: 'Enviar anexos',
    type: 'FILES',
  },
  {
    key: 'file_view',
    label: 'Visualizar anexos',
    type: 'FILES',
  },
  {
    key: 'file_delete',
    label: 'Excluir anexos',
    type: 'FILES',
  },
  {
    key: 'manager_blocked_periods',
    label: 'Bloqueio de períodos',
  },
  {
    key: 'receive_email_agenda',
    label: 'Receber e-mails de vencimentos',
  },
  {
    key: 'serasa',
    label: 'Consultar CPF/CNPJ',
    hidden: true,
  },
];

export const RECEIPTS_TYPE_OPTIONS = [
  {
    label: 'Despesa',
    value: 'EXPENSE',
  },
  {
    label: 'Recebimento',
    value: 'INCOME',
  },
];

export const ZENPAPER_VIDEOS = {
  BANK_STATEMENT_UPLOAD: 'https://www.youtube.com/watch?v=FSG_MSHngEI',
};

export const ZENPLY_TERMS_URL = 'https://www.zenply.com.br/termos-de-servico';
export const ZENPLY_PRIVACY_POLICY_URL =
  'https://www.zenply.com.br/politica-de-privacidade';

export const DEFAULT_LOGO_URL =
  'https://contabil-easy.s3.sa-east-1.amazonaws.com/assets/zenply-company-icon.png';

export { PERMISSIONS };
