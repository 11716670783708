import { connect } from 'react-redux';

import accountActionCreators from '_store/_actions/accounts';

import AccountFormModal from './AccountFormModal';

const mapStateToProps = (state) => ({
  isLoading: state.accounts.isLoading,
});

const mapDispatchToProps = {
  onAddAccount: accountActionCreators.addAccount,
  onFetchAccounts: accountActionCreators.getAllAccounts,
  onUpdateAccount: accountActionCreators.updateAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountFormModal);
