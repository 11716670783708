import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';

const getActiveCompany = () => {
  return (dispatch) => {
    dispatch(request());

    const product = process.env.REACT_APP_CONTABILLS_APP;

    const params = {
      product,
    };

    service.getActive(params).then(
      (company) => {
        dispatch(success(company));
      },
      (error) => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return {
      type: constants.COMPANY_GET_ACTIVE_REQUEST,
      payload: {},
    };
  }
  function success(company) {
    return {
      type: constants.COMPANY_GET_ACTIVE_SUCCESS,
      payload: { company: company.data },
    };
  }
  function failure(error) {
    return {
      type: constants.COMPANY_GET_ACTIVE_FAILURE,
      payload: { error },
    };
  }
};

export default getActiveCompany;
