import FORMATTERS from 'helpers/formatters';
import groupByYear from './groupByYear';

export const DEFAULT_COLUMNS = [
  'formatted_competence',
  'gross_revenue',
];

export const TABLE_COLUMNS = [
  {
    label: 'Período',
    keyName: 'formatted_competence',
    sortingKey: 'formatted_competence',
    align: 'center',
    width: 30,
    formatter: FORMATTERS.STRING,
    order: 1,
  },
  {
    label: 'Faturamento',
    keyName: 'gross_revenue',
    sortingKey: 'gross_revenue',
    formatter: FORMATTERS.NUMBER,
    align: 'center',
    width: 30,
    order: 2,
    sumOnFooter: true,
  },
];

export {
  groupByYear,
};
