import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from '_components/_core';

import { ModalFooter } from './styles';

const getFileExtension = (filename) => {
  const parts = filename.split('.');

  return parts[parts.length - 1];
};

function FileViewer({
  isVisible,
  onModalToggle,
  fileForPreview,
}) {
  const renderModalFooter = useCallback(() => (
    <ModalFooter>
      <Button variant="secondary" onClick={onModalToggle}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
      >
        Salvar
      </Button>
    </ModalFooter>
  ), [onModalToggle]);

  const renderContent = useCallback(() => {
    const { file_name, signed_url } = fileForPreview || {};

    const extension = getFileExtension(file_name);

    const isImage = ['png', 'jpeg', 'jpg'].includes(extension);
    const isPDF = ['pdf'].includes(extension);

    if (isImage) {
      return (
        <div style={{ height: '60vh' }}>
          <img src={signed_url} alt="Visualização do arquivo" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        </div>
      );
    }

    if (isPDF) {
      return (
        <div style={{ height: '60vh' }}>
          <embed src={signed_url} type="application/pdf" width="100%" height="100%" />
        </div>
      );
    }

    return (
      <div className="text-center">
        <h5>
          {file_name}
        </h5>
        <p>
          Este arquivo não pode ser pré-visualizado, faça o download.
        </p>
      </div>
    );
  }, [fileForPreview]);

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      title="Visualizar arquivo"
      isVisible={isVisible}
      toggleModal={onModalToggle}
      footer={renderModalFooter()}
    >
      {renderContent()}

    </Modal>
  );
}

FileViewer.defaultProps = {
  fileForPreview: {},
};

FileViewer.propTypes = {
  fileForPreview: PropTypes.object,
  onModalToggle: PropTypes.func,
  isVisible: PropTypes.bool,
};

export default FileViewer;
