export const apiKeysConstants = {
  API_KEYS_GET_ALL_REQUEST: 'API_KEYS_GET_ALL_REQUEST',
  API_KEYS_GET_ALL_SUCCESS: 'API_KEYS_GET_ALL_SUCCESS',
  API_KEYS_GET_ALL_FAILURE: 'API_KEYS_GET_ALL_FAILURE',

  API_KEYS_DELETE_REQUEST: 'API_KEYS_DELETE_REQUEST',
  API_KEYS_DELETE_SUCCESS: 'API_KEYS_DELETE_SUCCESS',
  API_KEYS_DELETE_FAILURE: 'API_KEYS_DELETE_FAILURE',

  API_KEYS_CREATE_REQUEST: 'API_KEYS_CREATE_REQUEST',
  API_KEYS_CREATE_SUCCESS: 'API_KEYS_CREATE_SUCCESS',
  API_KEYS_CREATE_FAILURE: 'API_KEYS_CREATE_FAILURE',

  API_KEYS_UPDATE_REQUEST: 'API_KEYS_UPDATE_REQUEST',
  API_KEYS_UPDATE_SUCCESS: 'API_KEYS_UPDATE_SUCCESS',
  API_KEYS_UPDATE_FAILURE: 'API_KEYS_UPDATE_FAILURE',
};

export default apiKeysConstants;
