import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { DateTime } from 'luxon';

import { MonthPicker } from '_components/_core';
import FORMATTERS from 'helpers/formatters';

const FormMonthPickerField = ({
  placeholder,
  disabled,
  endOfMonth,
  minDate,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const error = useMemo(
    () => ((meta.touched && meta.error) ? meta.error : ''),
    [meta.error, meta.touched],
  );

  const handleChange = useCallback((value) => {
    if (value) {
      const luxonDate = value;

      if (!luxonDate.isValid) {
        helpers.setError('Data inválida');
        return;
      }

      helpers.setValue(luxonDate.toFormat('yyyy-MM-dd'));
    } else {
      helpers.setValue(value);
    }
  }, [helpers]);

  const value = useMemo(() => {
    if (typeof field.value === 'string') {
      return FORMATTERS.DATE_FROM_STRING_DATE(field.value);
    }

    if (field.value) {
      const formattedDate = DateTime.fromJSDate(field.value, { zone: 'utc' }).toFormat('yyyy-MM-dd');

      return FORMATTERS.DATE_FROM_STRING_DATE(formattedDate);
    }

    return field.value || null;
  }, [field]);

  return (
    <>
      <MonthPicker
        value={value}
        onChange={handleChange}
        minDate={minDate}
        placeholder={placeholder}
        endOfMonth={endOfMonth}
        {...props}
      />
      {error && <small className="text-danger">{error}</small>}
    </>
  );
};

FormMonthPickerField.defaultProps = {
  disabled: false,
  endOfMonth: false,
  minDate: null,
};

FormMonthPickerField.propTypes = {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  endOfMonth: PropTypes.bool,
  minDate: PropTypes.any,
};

export default FormMonthPickerField;
