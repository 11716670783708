import * as Yup from 'yup';
import { ptShort } from 'yup-locale-pt';

import { REQUIRED } from 'helpers/validation-messages';

Yup.setLocale(ptShort);

const EditCredentialSchema = Yup.object().shape({
  type: Yup.mixed().oneOf(['CITY_HALL']).required(),
  login: Yup.string().max(255).required(REQUIRED).nullable(true),
  password: Yup.string().max(255).nullable(true),
});

export default EditCredentialSchema;
