import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import isEmpty from 'lodash/isEmpty';

import { DOCUMENT_TYPE_OPTIONS, CONTACT_TYPE_OPTIONS } from 'helpers/constants';
import {
  Button,
  FormSelectField,
  FormTextField,
  FormCpfCnpjField,
  FormMaskedInput,
  FloatingCard,
} from '_components/_core';
import { AddressFormFields, LoadingIcon } from '_components/_shared';

import { StyledFooter, SearchButton } from './styles';
import { FormSchema } from './utilities';

function ContactForm({
  isOpen,
  isSearching,
  contact_id,
  contact: oldContact,
  onCreateContact,
  onUpdateContact,
  onSearchCompanyByCnpj,
  onClearSearchedCompany,
  onFetchCities,
  onFetchContact,
  onToggleForm,
  onBeforeSaveCallback,
  onAfterSaveCallback,
  onAfterSaveCallbackWithReset,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [newContact, setNewContact] = useState({});

  const contact = useMemo(() => {
    if (contact_id) {
      return newContact;
    }

    return oldContact;
  }, [contact_id, newContact, oldContact]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (!contact_id) {
      return;
    }

    setIsLoading(true);

    onFetchContact(contact_id, (found_contact) => {
      setNewContact(found_contact);

      setIsLoading(false);
    });
  }, [contact_id, onFetchContact, isOpen]);

  const handleSubmitForm = useCallback((values, { resetForm }) => {
    setIsLoading(true);

    if (onBeforeSaveCallback) {
      onBeforeSaveCallback(values);
    }

    if (values.id) {
      onUpdateContact(values.id, values, (updated_contact) => {
        if (onAfterSaveCallback) {
          onAfterSaveCallback(updated_contact);
        }

        if (onAfterSaveCallbackWithReset) {
          onAfterSaveCallbackWithReset(updated_contact);

          resetForm();
          onToggleForm();
        }

        if (!onBeforeSaveCallback && !onAfterSaveCallback) {
          resetForm();
          onToggleForm();
        }

        setIsLoading(false);
      });
    } else {
      onCreateContact(values, (created_contact) => {
        if (onAfterSaveCallback) {
          onAfterSaveCallback(created_contact);
        }

        if (onAfterSaveCallbackWithReset) {
          onAfterSaveCallbackWithReset(created_contact);

          resetForm();
          onToggleForm();
        }

        if (!onBeforeSaveCallback && !onAfterSaveCallback) {
          resetForm();
          onToggleForm();
        }

        setIsLoading(false);
      });
    }
  }, [
    onCreateContact,
    onUpdateContact,
    onToggleForm,
    onBeforeSaveCallback,
    onAfterSaveCallback,
    onAfterSaveCallbackWithReset,
  ]);

  const renderFooter = useCallback((handleSubmit, isValid) => (
    <StyledFooter>
      <Button
        type="submit"
        variant="success-2"
        className="mr-2 flex-fill"
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={!isValid || isLoading}
      >
        {contact.id ? 'Salvar Alterações' : 'Salvar'}
      </Button>
      <Button
        className="flex-fill"
        variant="inverse-dark"
        onClick={onToggleForm}
      >
        Cancelar
      </Button>
    </StyledFooter>
  ), [onToggleForm, contact, isLoading]);

  const handleCompanySearch = useCallback((values, setFieldValue) => {
    onClearSearchedCompany();

    onSearchCompanyByCnpj(values.document_number, (searchedCompany) => {
      onFetchCities(searchedCompany.address_state_ibge, () => {
        setFieldValue('name', searchedCompany.company_name);
        setFieldValue('phone_number', searchedCompany.phone_number);
        setFieldValue('email', searchedCompany.email);
        setFieldValue('address_zip_code', searchedCompany.address_zip_code);
        setFieldValue('address_street', searchedCompany.address_street);
        setFieldValue('address_number', searchedCompany.address_number);
        setFieldValue('address_district', searchedCompany.address_district);
        setFieldValue('address_complement', searchedCompany.complement);
        setFieldValue('address_city', searchedCompany.address_city);
        setFieldValue('address_city_ibge', Number(searchedCompany.address_city_ibge));
        setFieldValue('address_state', searchedCompany.address_state);
        setFieldValue('address_state_ibge', Number(searchedCompany.address_state_ibge));
      });
    });
  }, [onSearchCompanyByCnpj, onFetchCities, onClearSearchedCompany]);

  const initialValues = useMemo(() => ({
    id: contact.id,
    document_number: contact.document_number || '',
    document_type: contact.document_type,
    name: contact.name || '',
    email: contact.email || '',
    phone_number: contact.phone_number || '',
    address_zip_code: contact.address_zip_code || '',
    address_street: contact.address_street || '',
    address_number: contact.address_number || '',
    address_district: contact.address_district || '',
    address_complement: contact.address_complement || '',
    address_city: contact.address_city || '',
    address_city_ibge: contact.address_city_ibge || '',
    address_state: contact.address_state || '',
    address_state_ibge: contact.address_state_ibge || '',
    type: contact.type,
  }), [contact]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      validationSchema={FormSchema}
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
        isValid,
      }) => (
        <>
          <FloatingCard
            title={!isEmpty(contact) ? 'Editar contato' : 'Novo contato'}
            isVisible={isOpen}
            onToggleVisibility={onToggleForm}
            footerContent={renderFooter(handleSubmit, isValid)}
            side="right"
            fullHeight
            bodyClassName="p-0"
          >
            {isLoading && (
              <div className="h-100 d-flex justify-content-center">
                <LoadingIcon text="Aguarde ..." />
              </div>
            )}
            {!isLoading && (
            <Form onSubmit={handleSubmit} className="mr-3 mt-3 ml-3">
              <Form.Row>
                <Form.Group as={Col} xl="4" className="mb-0">
                  <Form.Label>Tipo de contato</Form.Label>
                  <br />
                  <ButtonGroup>
                    {CONTACT_TYPE_OPTIONS.map((option) => (
                      <Button
                        key={option.value}
                        variant="inverse-dark"
                        onClick={() => setFieldValue('type', option.value)}
                        active={values.type === option.value}
                        className={values.type === option.value ? 'active' : ''}
                      >
                        {option.label}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Form.Group>
              </Form.Row>
              <hr />
              <Form.Row>
                <Form.Group as={Col} xl="12">
                  <Form.Label>Tipo de documento</Form.Label>
                  <FormSelectField
                    name="document_type"
                    options={DOCUMENT_TYPE_OPTIONS}
                  />
                </Form.Group>
                <Form.Group as={Col} xs="8" sm="8" md="8">
                  <Form.Label>{values.document_type || 'CPF/CNPJ'}</Form.Label>
                  <FormCpfCnpjField
                    name="document_number"
                    placeholder={values.document_type}
                    type={values.document_type}
                  />
                </Form.Group>
                <Form.Group as={Col} xs="4" sm="4" md="4">
                  <SearchButton
                    variant="secondary"
                    isLoading={isSearching}
                    disabled={isSearching || values.document_type !== 'CNPJ'}
                    onClick={() => handleCompanySearch(values, setFieldValue)}
                  >
                    Buscar CNPJ
                  </SearchButton>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    {values.document_type === 'CPF' ? 'Nome completo' : 'Razão Social'}
                  </Form.Label>
                  <FormTextField
                    name="name"
                    placeholder="Digite o nome do Cliente."
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>E-mail</Form.Label>
                  <FormTextField
                    name="email"
                    placeholder="E-mail"
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group as={Col} md="12">
                  <Form.Label>Telefone</Form.Label>
                  <FormMaskedInput
                    name="phone_number"
                    placeholder="Telefone"
                    type="PHONE"
                  />
                </Form.Group>
              </Form.Row>
              <hr />
              <AddressFormFields
                columnSizes={{
                  cep: 6,
                  searchButton: 4,
                  street: 6,
                  number: 6,
                  district: 6,
                  complement: 6,
                  city: 6,
                  state: 6,
                }}
              />
            </Form>
            )}

          </FloatingCard>
        </>
      )}
    </Formik>
  );
}

ContactForm.defaultProps = {
  contact: {},
  contact_id: null,
  onBeforeSaveCallback: null,
  onAfterSaveCallback: null,
  onAfterSaveCallbackWithReset: null,
};

ContactForm.propTypes = {
  contact: PropTypes.object,
  onCreateContact: PropTypes.func,
  onUpdateContact: PropTypes.func,
  onToggleForm: PropTypes.func,
  isOpen: PropTypes.bool,
  onSearchCompanyByCnpj: PropTypes.func,
  onClearSearchedCompany: PropTypes.func,
  isSearching: PropTypes.bool,
  onFetchCities: PropTypes.func,
  contact_id: PropTypes.string,
  onFetchContact: PropTypes.func.isRequired,
  onBeforeSaveCallback: PropTypes.func,
  onAfterSaveCallback: PropTypes.func,
  onAfterSaveCallbackWithReset: PropTypes.func,
};

export default ContactForm;
