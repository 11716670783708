import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import { FaCheckCircle, FaFilter, FaInbox } from 'react-icons/fa';

import { Button } from '_components/_core';

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: start;
  border: 2px solid var(--td-border-color);
  padding: 20px 30px;
  margin-bottom: 10px;
  width: 350px;
  border-radius: 6px;
  color: var(--color-text-primary);
  background-color: #ffffff;

  h5 {
    font-size: 14px;
  }

  :hover {
    border: 2px solid var(--accent-color);
    color: var(--color-text-primary);
    background-color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    svg {
      color: var(--accent-color);
    }

    h5 {
      color: var(--accent-color);
    }
  }

  :active {
    color: var(--color-text-primary) !important;
    background-color: #ffffff !important;
  }
`;

export const StyledForm = styled(Form)`  
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: unset !important;
  }
`;

export const SectionHeader = styled.p`
  font-size: 14px;
  margin-bottom: 4px;
  color: var(--accent-color);
  font-weight: 600;
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2em;

  @media screen and (max-width: 768px) {
    padding-top: 24px;
  }
`;

export const Card = styled.div`
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 32px 32px;
  width: 100%;
  /* text-align: center; */

  @media screen and (min-width: 1024px) {
    width: 45%;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
  margin-bottom: 8px;
`;

export const Text = styled.p`
  font-size: 14px;
  color: grey;
  margin-bottom: 0;
`;

export const EmptyIcon = styled(FaInbox)`
  font-size: 48px;
  color: #2980b9;
  margin-bottom: 16px;
`;

export const FilterIcon = styled(FaFilter)`
  font-size: 48px;
  color: #2980b9;
  margin-bottom: 16px;
`;

export const SuccessIcon = styled(FaCheckCircle)`
  font-size: 48px;
  color: #2ecc71;
  margin-bottom: 16px;
`;
