import addAccount from './addAccount';
import cancelNewAccount from './cancelNewAccount';
import confirmDeleteAccount from './confirmDeleteAccount';
import deleteAccount from './deleteAccount';
import duplicateAccount from './duplicateAccount';
import editAccount from './editAccount';
import getAccountById from './getAccountById';
import getAllAccounts from './getAllAccounts';
import newAccount from './newAccount';
import toggleAccountForm from './toggleAccountForm';
import updateAccount from './updateAccount';

export default {
  addAccount,
  cancelNewAccount,
  confirmDeleteAccount,
  deleteAccount,
  duplicateAccount,
  editAccount,
  getAccountById,
  getAllAccounts,
  newAccount,
  toggleAccountForm,
  updateAccount,
};
