import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

export const Plans = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
`;

export const Plan = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PlanInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PlanName = styled.h4`
  color: var(--color-text-primary);
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  small {
    display: block;
  }
`;

export const StyledDescription = styled(Form.Label)`
  font-weight: 400 !important;
  color: #AAAEBB;
  padding-left: 0;
  padding-top: 0;
`;
