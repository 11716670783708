import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { IoArrowBackOutline, IoArrowForwardOutline } from 'react-icons/io5';

import { useMediaQuery } from 'helpers';

import {
  Card,
  CardHeader,
  CardBody,
} from '_components/_core';

import { TABLE_COLUMNS, DEFAULT_COLUMNS, formatData } from './utilities';
import { StyledTable, StyledButtonGroup, StyledButton } from './styles';

const QUARTERS = {
  '01': '1º Trimestre de',
  '04': '2º Trimestre de',
  '07': '3º Trimestre de',
  10: '4º Trimestre de',
};

function QuarterTaxes({ selectedDate, taxes, taxRegime }) {
  const [selectedMonth, setSelectedMonth] = useState(selectedDate);

  const { isMobile } = useMediaQuery();

  const formattedData = useMemo(() => {
    if (isEmpty(taxes)) {
      return [];
    }

    return formatData(taxes, selectedMonth, taxRegime);
  }, [taxes, taxRegime, selectedMonth]);

  const handleQuarterIncreased = useCallback(() => {
    setSelectedMonth((previousDate) => previousDate.plus({ quarter: 1 }));
  }, []);

  const handleQuarterDecreased = useCallback(() => {
    setSelectedMonth((previousDate) => previousDate.minus({ quarter: 1 }));
  }, []);

  const selectedQuarter = useMemo(() => {
    const { year } = selectedMonth;
    const quarter = selectedMonth.startOf('quarter').toFormat('MM');

    return `${QUARTERS[quarter]} ${year}`;
  }, [selectedMonth]);

  const isBackButtonDisabled = useMemo(
    () => selectedMonth.startOf('quarter').toFormat('MM') === '01',
    [selectedMonth],
  );

  const isNextButtonDisabled = useMemo(
    () => selectedMonth.startOf('quarter').toFormat('MM') === '10',
    [selectedMonth],
  );

  const description = useMemo(() => {
    if (taxRegime === 'LUCRO_PRESUMIDO') {
      return 'Tributos do Lucro Presumido no trimestre selecionado';
    }

    return null;
  }, [taxRegime]);

  const renderControls = useCallback(() => (
    <StyledButtonGroup>
      <StyledButton variant="secondary" onClick={handleQuarterDecreased} disabled={isBackButtonDisabled}>
        <IoArrowBackOutline />
      </StyledButton>
      <StyledButton variant="secondary" special>
        <b>{selectedQuarter}</b>
      </StyledButton>
      <StyledButton variant="secondary" onClick={handleQuarterIncreased} disabled={isNextButtonDisabled}>
        <IoArrowForwardOutline />
      </StyledButton>
    </StyledButtonGroup>
  ), [
    handleQuarterDecreased,
    handleQuarterIncreased,
    isBackButtonDisabled,
    isNextButtonDisabled,
    selectedQuarter,
  ]);

  useEffect(() => {
    setSelectedMonth(selectedDate);
  }, [selectedDate]);

  return (
    <Card>
      <CardHeader
        title="Visão Trimestral"
        description={description}
        sideContent={!isMobile ? renderControls() : null}
        className={isMobile ? 'mb-3' : 'mb-0'}
      />
      <CardBody noPaddingTop noPaddingBottom={isMobile}>
        <div className="table-responsive">
          <StyledTable
            id="quarterly-table"
            className="table-hover table-striped"
            keyName="competence"
            data={formattedData}
            columns={TABLE_COLUMNS}
            defaultColumns={DEFAULT_COLUMNS}
            showFooter
          />
        </div>
      </CardBody>
      {isMobile && (
        <div className="d-flex justify-content-center mb-2 mt-2">
          {renderControls()}
        </div>
      )}
    </Card>
  );
}

QuarterTaxes.defaultProps = {
  taxes: [],
  selectedDate: {},
};

QuarterTaxes.propTypes = {
  taxes: PropTypes.array,
  selectedDate: PropTypes.object,
  taxRegime: PropTypes.oneOf(['LUCRO_PRESUMIDO', 'SIMPLES_NACIONAL', 'LUCRO_REAL', 'MEI']),
};

export default QuarterTaxes;
