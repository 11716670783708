import styled, { css } from 'styled-components';

export const MenuCategory = styled.span`
  display: block;
  padding-top: 8px;
  padding-bottom: 16px;
  color: rgba(26,51,83,.6);
  font-size: 12px;
  line-height: 1.5;
  font-weight: 700;
  text-transform: uppercase;

  ${({ noPaddingBottom }) => noPaddingBottom && css`
    padding-bottom: 0px;
  `}

  ${({ noPaddingTop }) => noPaddingTop && css`
    padding-top: 0px;
  `}
`;

export const StyledRow = styled.div`
  padding: 0 8px; 
`;
