import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-bootstrap/Popover';
import { useHistory } from 'react-router-dom';

import { useMediaQuery } from 'helpers';

import {
  StyledWrapper,
  StyledAvatar,
  StyledUserName,
  StyledUserRole,
  StyledMenuText,
  StyledOverlayTrigger,
  StyledPopover,
  MenuEntry,
  StyledIoLogOutOutline,
  StyledIoReaderOutline,
  StyledIoPersonCircleSharp,
  AvatarWrapper,
} from './styles';

function AvatarMenu({
  user,
  onLogout,
  onFetchUser,
}) {
  const history = useHistory();

  const { isMobile } = useMediaQuery();

  useEffect(() => {
    onFetchUser();
  }, [onFetchUser]);

  const isManager = useMemo(() => {
    if (!user) {
      return false;
    }

    return user.manager_id === null;
  }, [user]);

  const popover = useCallback(() => (
    <StyledPopover>
      <Popover.Content>
        <MenuEntry
          className="row"
          onClick={() => {
            history.push('/meu-perfil');
            document.body.click();
          }}
        >
          <StyledIoPersonCircleSharp size="1rem" className="ml-3 mr-3 mt-2 mb-2" />
          <StyledMenuText>Meu Perfil</StyledMenuText>
        </MenuEntry>
        {isManager && (
          <MenuEntry
            className="row"
            onClick={() => {
              history.push('/meu-plano');
              document.body.click();
            }}
          >
            <StyledIoReaderOutline size="1rem" className="ml-3 mr-3 mt-2 mb-2" />
            <StyledMenuText>Meu Plano</StyledMenuText>
          </MenuEntry>
        )}

        <hr />
        <MenuEntry className="row" onClick={onLogout}>
          <StyledIoLogOutOutline size="1rem" className="ml-3 mr-3 mt-2 mb-2" />
          <StyledMenuText>Sair</StyledMenuText>
        </MenuEntry>
      </Popover.Content>
    </StyledPopover>
  ), [history, isManager, onLogout]);

  return (
    <>
      {!isMobile && (
        <StyledOverlayTrigger
          trigger="click"
          placement="bottom"
          overlay={popover()}
          rootClose
        >
          <StyledWrapper>
            <div className="row">
              <AvatarWrapper>
                <StyledAvatar
                  src={user && user.avatar_url ? user.avatar_url : 'assets/img/default-avatar.svg'}
                  alt="user-avatar"
                />
              </AvatarWrapper>
              <div>
                <StyledUserName>
                  {user.email}
                </StyledUserName>
                <StyledUserRole>
                  {isManager ? 'Gerente' : 'Colaborador'}
                </StyledUserRole>
              </div>
            </div>
          </StyledWrapper>
        </StyledOverlayTrigger>
      )}
      {isMobile && (
        <StyledWrapper>
          <StyledOverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={popover()}
            rootClose
          >
            <div>
              <StyledAvatar
                src={user && user.avatar_url ? user.avatar_url : 'assets/img/default-avatar.svg'}
                alt="user-avatar"
              />
            </div>
          </StyledOverlayTrigger>
        </StyledWrapper>
      )}
    </>
  );
}

AvatarMenu.defaultProps = {
  user: null,
};

AvatarMenu.propTypes = {
  user: PropTypes.object,
  onFetchUser: PropTypes.func,
  onLogout: PropTypes.func.isRequired,
};

export default AvatarMenu;
