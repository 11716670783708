import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';

import DigitalCertificate from './DigitalCertificate';

const mapStateToProps = (state) => ({
  isLoading: state.company.isLoadingCertificate,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onAddDigitalCertificate: companyActionCreators.addCompanyDigitalCertificate,
  onDeleteDigitalCertificate: companyActionCreators.deleteCompanyDigitalCertificate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DigitalCertificate);
