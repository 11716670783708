import React from 'react';
import PropTypes from 'prop-types';

import { FormTitle } from '../../styles';

function FormHeader({ menuItem }) {
  return (
    <div className="d-flex align-items-center">
      {menuItem.icon}
      <FormTitle className="ml-4">
        Adicionar nova empresa
        <br />
        <small className="text-muted">
          {menuItem.description}
        </small>
      </FormTitle>
    </div>
  );
}

FormHeader.propTypes = {
  menuItem: PropTypes.object.isRequired,
};

export default FormHeader;
