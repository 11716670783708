import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Formik } from 'formik';
import { IoAdd, IoArrowForward } from 'react-icons/io5';
import { Row, Col } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import pluralize from 'pluralize';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';
import { Button, Tag } from '_components/_core';

import { SuccessMessage, PastDueMessage } from './components';

import {
  Container,
  Navbar,
  StyledAccordion,
  PlanPrice,
  Plans,
  Plan,
  PlanName,
  QuestionContainer,
  QuestionHeader,
  QuestionBody,
} from './styles';

const FAQ = [
  {
    question: 'O que é o Zenply?',
    answer: 'O Zenply é um sistema de gestão financeira e controle de fluxo de caixa feito para micro e pequenas empresas ou para uso pessoal.',
  },
  {
    question: 'Quais são os planos disponíveis?',
    answer: 'Atualmente temos 2 planos disponíveis: Pessoal e Empresarial, mas você pode escolher como quer pagar, mensalmente, a cada 6 meses ou anualmente. Os planos exibidos são de acordo com o seu tipo de conta.',
  },
  {
    question: 'Como faço para cancelar minha assinatura?',
    answer: 'Você pode cancelar sua assinatura a qualquer momento. Entre em contato através do e-mail contato@zenply.com.br para cancelar seu plano. Em breve teremos uma área para isso na plataforma.',
  },
  {
    question: 'Como faço para alterar meu plano?',
    answer: 'Você pode entrar em contato conosco através do contato@Zenply.com.br para alterar seu plano. Em breve teremos uma área para isso na plataforma.',
  },
  {
    question: 'Gostaria de testar por mais alguns dias, é possível?',
    answer: 'Entre em contato com a gente através do e-mail contato@zenply.com.br e nos informe o motivo do seu interesse em testar e também seu e-mail utilizado na plataforma. Nós vamos analisar seu caso e te responderemos o mais breve possível',
  },
];

const getIntervalName = (interval, interval_count) => {
  if (interval === 'month' && interval_count === 1) {
    return 'por mês';
  }

  if (interval === 'month' && interval_count === 6) {
    return 'por semestre';
  }

  if (interval === 'year') {
    return 'por ano';
  }

  return '';
};

function ActivateSubscription({
  plans,
  onFetchPlans,
  onGoToCheckoutSession,
}) {
  const stripe = useStripe();
  const history = useHistory();
  const location = useLocation();
  const accountType = localStorage.getItem('account_type');

  const [isLoading, setIsLoading] = useState(false);

  const formattedAccountType = useMemo(() => {
    if (accountType === 'PJ') {
      return 'Empresarial';
    }

    if (accountType === 'PF') {
      return 'Pessoal';
    }

    return '';
  }, [accountType]);

  const isSuccessPage = useMemo(() => {
    const step = new URLSearchParams(location.search).get('step');

    return step === 'success';
  }, [location]);

  const isPastDuePage = useMemo(() => {
    const step = new URLSearchParams(location.search).get('step');

    return step === 'past_due';
  }, [location]);

  useEffect(() => {
    onFetchPlans();
  }, [onFetchPlans]);

  const handleConfirm = useCallback((values) => {
    setIsLoading(true);

    onGoToCheckoutSession({
      ...values,
    }, (session_id) => {
      setIsLoading(false);

      stripe.redirectToCheckout({ sessionId: session_id });
    });
  },
  [
    onGoToCheckoutSession,
    stripe,
  ]);

  const getFilteredPlans = useCallback((plan_type) => plans.filter(
    (plan) => plan.name.includes(plan_type),
  ), [plans]);

  useEffect(() => {
    document.body.style.backgroundImage = 'url(assets/img/bg-activate-subscription.png)';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundRepeat = 'no-repeat';

    return () => {
      document.body.style.backgroundImage = 'none';
    };
  }, []);

  const trialMessage = 'Seu período de testes terminou e agora você precisa escolher um plano para continuar usando o Zenply.';

  const greetingMessage = useMemo(() => {
    const name = new URLSearchParams(location.search).get('name');
    const accountName = localStorage.getItem('name');

    if (isEmpty(name)) {
      return `Bem vindo de volta ao Zenply, ${accountName}!`;
    }

    return `Bem vindo de volta ao Zenply, ${name}!`;
  }, [location]);

  return (
    <Container className="h-100 mb-3">
      <Navbar>
        <img src="assets/img/logo-zenply-icon.svg" alt="Zenply" onClick={() => history.push('/entrar')} />
      </Navbar>
      {isSuccessPage && <SuccessMessage />}
      {isPastDuePage && <PastDueMessage />}
      {!isSuccessPage && !isPastDuePage && (
        <>
          <Row>
            <Col>
              <p className="greetings">
                {greetingMessage}
              </p>
              <Tag variant="warning" className="p-3 mt-3 mt-lg-4">
                {trialMessage}
              </Tag>
            </Col>
          </Row>
          <Row className="mt-5">
            <Formik
              initialValues={{
                user_id: new URLSearchParams(location.search).get('id'),
                plan_id: null,
                plan_type: 'Empresarial',
              }}
              onSubmit={handleConfirm}
              enableReinitialize
            >
              {({
                handleSubmit,
                values,
                setFieldValue,
              }) => (
                <>
                  <Col xs={12} md={12} xl={7}>
                    <div>
                      <h2>
                        Escolha um plano para continuar
                      </h2>
                      <StyledAccordion className="mt-5 mt-md-0" defaultActiveKey={FAQ[0].question}>
                        {FAQ.map((faq) => (
                          <QuestionContainer>
                            <QuestionHeader>
                              <StyledAccordion.Toggle as="h5" eventKey={faq.question}>
                                {faq.question}
                                <IoAdd size="1.5em" />
                              </StyledAccordion.Toggle>
                            </QuestionHeader>
                            <StyledAccordion.Collapse eventKey={faq.question}>
                              <QuestionBody>
                                {faq.answer}
                              </QuestionBody>
                            </StyledAccordion.Collapse>
                          </QuestionContainer>
                        ))}
                      </StyledAccordion>
                    </div>
                  </Col>
                  <Col xs={12} md={12} xl={5}>
                    <Plans>
                      {getFilteredPlans(formattedAccountType).map((plan) => (
                        <Plan
                          key={plan.id}
                          active={values.plan_id === plan.id}
                          onClick={() => setFieldValue('plan_id', plan.id)}
                        >
                          <div>
                            <PlanName>
                              <span>
                                {plan.name}
                              </span>
                            </PlanName>
                            <PlanPrice>
                              {FORMATTERS.NUMBER(plan.price)}
                              <small>
                                &nbsp;/ {getIntervalName(plan.interval, plan.interval_count)}
                              </small>
                            </PlanPrice>
                          </div>
                          <div>
                            <div>
                              Até {plan.metadata.max_users_count} {pluralize('usuário', plan.metadata.max_users_count)}
                            </div>
                            <div>
                              Até {plan.metadata.max_companies_count}  {pluralize('empresas', plan.metadata.max_companies_count)}
                            </div>
                          </div>
                        </Plan>
                      ))}
                    </Plans>
                    <Button
                      isLoading={isLoading}
                      disabled={!values.plan_id || !values.user_id}
                      block
                      icon
                      variant="success-2"
                      className="p-3"
                      onClick={handleSubmit}
                    >
                      Confirmar e ir para o checkout
                      <IoArrowForward />
                    </Button>
                  </Col>
                </>
              )}
            </Formik>
          </Row>
          <Row>
            <Col>
              <p className="mt-3">
                © {DateTime.now().year} Zenply Software Ltda
              </p>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

ActivateSubscription.defaultProps = {
  plans: [],
};

ActivateSubscription.propTypes = {
  plans: PropTypes.array,
  onFetchPlans: PropTypes.func.isRequired,
  onGoToCheckoutSession: PropTypes.func.isRequired,
};

export default ActivateSubscription;
