import fetchReceipt from './fetchReceipt';
import fetchAllReceipts from './fetchAllReceipts';
import addReceipt from './addReceipt';
import deleteReceipt from './deleteReceipt';
import updateReceipt from './updateReceipt';

export default {
  fetchReceipt,
  fetchAllReceipts,
  addReceipt,
  deleteReceipt,
  updateReceipt,
};
