import styled, { css } from 'styled-components';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

export const TitleDescription = styled(Link)`

`;

export const TitleDescriptionDisabledLink = styled(Link)`
  pointer-events: none;
  cursor: default;
  text-decoration: none;
`;

export const HeaderTitle = styled.h4`
  font-weight: 500;
  margin-bottom: 0;
  text-transform: capitalize;
  font-size: 1.1em;
`;

export const HeaderDescription = styled.p`
  color: #B0B5C0;
  font-weight: 500;
  margin-bottom: 0;
  font-size: 0.800em;
`;

export const ActiveCompany = styled.div`
  padding: 16px 16px;
  padding-top: 24px;
  border-bottom: 1px solid rgba(173, 173, 173, 0.2);

  ${({ collapsed }) => collapsed && css`
    &&& {
      {
        padding: 8px;
        border-bottom: none;
      }
    }
  `} 
`;

export const ActiveCompanyLogo = styled.img`
  width: 46px;
  height: 46px;
  border-radius: 8px;
  padding: 4px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
              rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
              rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, 
              rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
`;

export const ActiveCompanyInfo = styled.div`
  button {
    margin-left: 0px;
    padding-left: 0px;
    color: var(--accent-color);

    :hover {
      color: var(--accent-color);
    }
  }
`;

export const SwitchCompanyButton = styled(Button)`
  width: 46px;
  height: 46px;
  border-radius: 8px;
  padding: 4px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
              rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
              rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, 
              rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  svg {
    color: #464de4;
  }
`;

export const AlertMessage = styled.h6`
  font-weight: 400;
  color: #b1b3bc;
`;
