import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import FORMATTERS from 'helpers/formatters';

import {
  StyledDiv,
  SignatureDiv,
  StyledHr,
  StyledP,
} from './styles';

export default function ReportSignature({ employees, accountants }) {
  const formik = useFormikContext();

  const signatures = useMemo(() => {
    let finalArray = [];

    if (formik.values.signature_employees) {
      const signature_employees = formik.values.signature_employees.map((employee) => ({
        ...employee,
        type: 'employee',
      }));

      finalArray = [...finalArray, ...signature_employees];
    }

    if (formik.values.signature_accountants) {
      const signature_accountants = formik.values.signature_accountants.map((employee) => ({
        ...employee,
        type: 'accountant',
      }));

      finalArray = [...finalArray, ...signature_accountants];
    }

    return finalArray;
  }, [formik.values]);

  const getRole = useCallback((signature) => {
    if (signature.type === 'employee') {
      const employee = employees.find((employee) => employee.id === signature.value);

      return `${employee.type} - `;
    }

    return 'Contador(a) - ';
  }, [employees]);

  const getDocumentNumber = useCallback((signature) => {
    if (signature.type === 'employee') {
      const employee = employees.find((employee) => employee.id === signature.value);

      return `${employee.document_type}: ${FORMATTERS.CPF_CNPJ(employee.document_number)}`;
    } if (signature.type === 'accountant') {
      const accountant = accountants.find((accountant) => accountant.id === signature.value);

      return `${accountant.document_type}: ${FORMATTERS.CPF_CNPJ(accountant.document_number)}`;
    }

    return '';
  }, [employees, accountants]);

  const getCrcNumber = useCallback((signature) => {
    if (signature.type === 'accountant') {
      const accountant = accountants.find((accountant) => accountant.id === signature.value);

      return `${accountant.crc_number ? `CRC: ${accountant.crc_number || ''}` : ''}`;
    }

    return '';
  }, [accountants]);

  return (
    <div viewType="signature_field">
      <StyledDiv className="row">
        {signatures.map((signature) => (
          <SignatureDiv>
            <StyledHr />
            <div>
              <StyledP>
                {getRole(signature)}
                {signature.label}
              </StyledP>
            </div>
            <div>
              <StyledP>
                {getDocumentNumber(signature)}
              </StyledP>
              <StyledP>
                {getCrcNumber(signature)}
              </StyledP>
            </div>
          </SignatureDiv>
        ))}
      </StyledDiv>
    </div>
  );
}

ReportSignature.propTypes = {
  employees: PropTypes.array,
  accountants: PropTypes.array,
};
