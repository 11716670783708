import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const updateCategory = (id, values, callback) => {
  return async (dispatch) => {
    dispatch(request(id, values));

    service.updateCategory(id, values).then(
      (response) => {
        dispatch(success(response));
        dispatch(alertActionCreators.success('Categoria atualizada com sucesso!'));

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(id, values) {
    return {
      type: constants.UPDATE_TRANSACTION_CATEGORY_REQUEST,
      payload: {
        id,
        values,
      },
    };
  }
  function success(response) {
    return {
      type: constants.UPDATE_TRANSACTION_CATEGORY_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.UPDATE_TRANSACTION_CATEGORY_FAILURE,
      payload: error,
    };
  }
};

export default updateCategory;
