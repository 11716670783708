import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Tab from 'react-bootstrap/Tab';
import { IoPrintOutline } from 'react-icons/io5';
import ReactToPrint from 'react-to-print';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';
import { Report, ReportPage, ReportHeader } from '_components/_shared';

import { DEFAULT_COLUMNS, TABLE_COLUMNS, groupByYear } from './utilities';
import { StyledTable } from './styles';

function Results({ values, activeCompany, issReport }) {
  const reportRef = useRef();

  const { company_name, document_number } = activeCompany || {};

  const groupedByYear = useMemo(() => {
    if (isEmpty(issReport)) {
      return {};
    }

    const grouped = groupByYear(issReport);

    return grouped;
  }, [issReport]);

  if (isEmpty(issReport)) {
    return null;
  }

  return (
    <Tab.Content className="mt-3 mt-lg-0">
      <div className="d-flex align-items-end">
        <ReactToPrint
          trigger={() => (
            <button
              type="button"
              placement="left"
              className="btn btn-sm btn-icons btn-secondary mr-2 mb-3"
            >
              <IoPrintOutline />
            </button>
          )}
          content={() => reportRef.current}
          documentTitle="Relatório de Faturamento"
        />
      </div>

      <Report ref={reportRef}>
        <div>
          {Object.keys(groupedByYear).map((key, index) => (
            <ReportPage>
              <ReportHeader
                key={key}
                name="RELATÓRIO DE ISS"
                companyName={company_name}
                companyDocumentNumber={FORMATTERS.CPF_CNPJ(document_number)}
                startDate={values.start_date}
                endDate={values.end_date}
                currentPage={index + 1}
                totalPages={groupedByYear ? Object.keys(groupedByYear).length : 0}
              />
              <StyledTable
                keyName="id"
                name={`iss-report-table-${values.type}`}
                className="table-hover"
                data={groupedByYear[key]}
                columns={TABLE_COLUMNS[values.type]}
                defaultColumns={DEFAULT_COLUMNS[values.type]}
                onItemSelect={() => {}}
                showFooter
                showColumnToggle
              />
            </ReportPage>
          ))}
        </div>
      </Report>

    </Tab.Content>
  );
}

Results.defaultProps = {
  issReport: [],
};

Results.propTypes = {
  values: PropTypes.object,
  activeCompany: PropTypes.object,
  issReport: PropTypes.array,
};

export default Results;
