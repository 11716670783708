import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const fetchAllTransactions = (params, callback) => {
  return async (dispatch) => {
    delete params.uiValues;

    dispatch(request(params));

    service.getAll(params).then(
      (response) => {
        dispatch(success(response));

        if (callback) {
          callback(response.data);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.FETCH_ALL_COMPANY_TRANSACTIONS_REQUEST,
      payload: params,
    };
  }
  function success(response) {
    return {
      type: constants.FETCH_ALL_COMPANY_TRANSACTIONS_SUCCESS,
      payload: response.data,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_ALL_COMPANY_TRANSACTIONS_FAILURE,
      payload: error,
    };
  }
};

export default fetchAllTransactions;
