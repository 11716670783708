import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

const SubmitListener = () => {
  const formik = useFormikContext();
  const [lastValues, updateState] = React.useState(formik.values);

  const submitForm = useCallback(
    debounce(
      () => {
        formik.submitForm();
      },
      100,
      { maxWait: 100 },
    ),
    [],
  );

  React.useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValues, formik.values);

    if (!valuesEqualLastValues) {
      updateState(formik.values);
    }

    if (!valuesEqualLastValues && formik.isValid) {
      submitForm();
    }
  }, [formik.values, formik.initialValues, lastValues, submitForm, formik.isValid]);

  return null;
};

export default SubmitListener;
