import { connect } from 'react-redux';

import authActionCreators from '_store/_actions/auth';
import subscriptionActionCreators from '_store/_actions/subscription';
import companyActionCreators from '_store/_actions/company';

import SignUp from './SignUp';

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  plans: state.subscription.plans,
  segments: state.company.segments,
});

const mapDispatchToProps = {
  onSignUp: authActionCreators.signUp,
  onFetchPlans: subscriptionActionCreators.fetchSubscriptionPlans,
  onGoToCheckoutSession: subscriptionActionCreators.goToCheckoutSession,
  onEmailConfirmation: authActionCreators.emailConfirmation,
  onFetchMarketSegments: companyActionCreators.fetchMarketSegments,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
