import { connect } from 'react-redux';

import invoicesActionCreators from '_store/_actions/invoices';

import DeleteInvoices from './DeleteInvoices';

const mapStateToProps = (state) => ({
  isLoading: state.invoices.isLoading,
});

const mapDispatchToProps = {
  onDeleteInvoices: invoicesActionCreators.deleteManyInvoices,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteInvoices);
