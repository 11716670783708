import * as yup from 'yup';

const AllowedPeriodSchema = yup.object().shape({
  start_date: yup.date().required('Campo obrigatório').nullable(),
  end_date: yup
    .date()
    .min(yup.ref('start_date'), 'A Data Final deve ser maior ou igual a Data Inicial')
    .required('Campo obrigatório')
    .nullable(),
});

export default AllowedPeriodSchema;
