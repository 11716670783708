const getTransactionsMobile = ({
  activeTab,
  selectedDate,
  account_ids,
  transactionFilters,
}) => {
  const year = Number(selectedDate.toFormat('yyyy'));
  const month = Number(selectedDate.toFormat('MM'));

  switch (activeTab) {
    case null:
      return {
        account_ids,
        month,
        year,
        type: null,
        sub_type: null,
        with_split: true,
        ...transactionFilters,
      };
    default:
      return {};
  }
};

export default getTransactionsMobile;
