import React, { useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import { useHistory, withRouter } from 'react-router-dom';

import { ZENPLY_TERMS_URL, ZENPLY_PRIVACY_POLICY_URL } from 'helpers';

import { Button } from '_components/_core';
import LogoZenpaper from '_components/_shared/NavBar/components/LogoZenpaper';
import { EmailForm } from './components';

import {
  FormContainer,
  StyledHeader,
  StyledFormWrapper,
  BgContainer,
  CustomContainer,
  StyledMobileFooter,
  StyledFooter,
} from './styles';

function PasswordReset({
  onPasswordReset,
  isLoading,
}) {
  const history = useHistory();
  const ZenpaperPasswordReset = () => (
    <>
      <CustomContainer>
        <BgContainer />
        <FormContainer>
          <StyledHeader className="pb-5 smb-5">
            <div className="d-flex align-items-center">
              <LogoZenpaper />
            </div>
            <div className="row">
              <p className="mt-3 pt-3">Já possui uma conta?</p>
              <Button
                variant="secondary"
                className="m-3 p-3"
                onClick={() => history.push('/entrar')}
              >
                ENTRAR
              </Button>
            </div>
          </StyledHeader>
          <StyledFormWrapper>
            <h1 className="mr-auto animated fadeInDown">
              Recuperação de Senha
            </h1>
            <p className="mb-4 mr-auto animated fadeInDown">
              Preencha seu e-mail corretamente para dar início a recuperação de senha.
            </p>
            <EmailForm onPasswordReset={onPasswordReset} isLoading={isLoading} />
          </StyledFormWrapper>
          <StyledFooter>
            <Col>
              <p className="footer-text">
                {`Copyright © Zenply - ${new Date().getFullYear()}`}
              </p>
            </Col>
            <Col>
              <ul className="auth-footer text-gray">
                <li>
                  <a target="_blank" href={ZENPLY_TERMS_URL} rel="noreferrer">Termos de Serviço</a>
                </li>
                <li>
                  <a target="_blank" href={ZENPLY_PRIVACY_POLICY_URL} rel="noreferrer">Política de Privacidade</a>
                </li>
              </ul>
            </Col>
          </StyledFooter>
          <StyledMobileFooter>
            <Col>
              <p className="footer-text">
                {`Copyright © Zenply - ${new Date().getFullYear()}`}
              </p>
              <ul className="auth-footer text-gray">
                <li>
                  <a target="_blank" href={ZENPLY_TERMS_URL} rel="noreferrer">Termos de Serviço</a>
                </li>
                <li>
                  <a target="_blank" href={ZENPLY_PRIVACY_POLICY_URL} rel="noreferrer">Política de Privacidade</a>
                </li>
              </ul>
            </Col>
          </StyledMobileFooter>
        </FormContainer>
      </CustomContainer>
    </>
  );

  const renderBody = useCallback(() => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    if (product === 'contabills-empresas') {
      return <ZenpaperPasswordReset />;
    }

    return (
      <Container fluid className="p-0">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper auth p-0 theme-two">
            <div className="row d-flex align-items-stretch">
              <div className="col-12 col-md-8 h-100 bg-white">
                <div className="auto-form-wrapper d-flex align-items-center justify-content-center flex-column">
                  <div className="nav-get-started">
                    <p>Ainda não tem uma conta?</p>
                    {/* <Link className="btn get-started-btn" to="/cadastrar">
                        COMECE AGORA
                      </Link> */}
                  </div>
                  <div>
                    <h1 className="mr-auto animated fadeInDown">
                      Recuperação de Senha
                    </h1>
                    <p className="mb-4 mr-auto animated fadeInDown">
                      Preencha seu e-mail corretamente para dar início a recuperação de senha.
                    </p>
                    <EmailForm onPasswordReset={onPasswordReset} isLoading={isLoading} />
                    <div className="wrapper mt-5 text-gray animated fadeInUp">
                      <p className="footer-text">
                        {`Copyright © ${new Date().getFullYear()} Creation Sistemas. Todos os direitos reservados.`}
                      </p>
                      <ul className="auth-footer text-gray">
                        <li>
                          <a href="termos-condicoes">Termos & Condições</a>
                        </li>
                        <li>
                          <a href="politica-cookies">Política de Cookies</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 banner-section d-none d-md-flex align-items-stretch justify-content-center pr-0 pl-0">
                <div className="slide-content bg-1" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }, [isLoading, onPasswordReset]);

  return (
    <>
      {renderBody()}
    </>
  );
}

PasswordReset.propTypes = {
  onPasswordReset: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default withRouter(PasswordReset);
