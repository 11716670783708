import styled, { css } from 'styled-components';
import { Tag } from '_components/_core';

export const Container = styled.div`
  ${({ hasActiveFilters }) => hasActiveFilters && css`
    margin-bottom: 16px;

    ${({ isMobile }) => isMobile && css`
      margin-top: 16px;
      background-color: #ffd;
      padding: 8px 16px;
      margin: 0;
      margin-left: -16px;
      margin-right: -16px;
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
    `}
  `}
`;

export const StyledTag = styled(Tag)`
  border-radius: 15px;

  &&& {
    svg {
      margin-left: 8px;
      color: #db504a;
      font-size: 1rem !important;

      :hover {
        cursor: pointer;
      }
    }
  }
`;
