import React, {
  useMemo,
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import FORMATTERS from 'helpers/formatters';
import { useMediaQuery } from 'helpers';

import { StyledContainer, StyledHint } from './styles';

function FooterSummary({
  type,
  transactions,
  allTransactionsLength,
  filteredTransactionsLength,
  selectedDate,
  selectedAccountIds,
}) {
  const {
    isMobile,
    isTablet,
    isDesktopExtraLarge,
  } = useMediaQuery();

  const hiddenRef = useRef(null);
  const [isFloating, setIsFloating] = useState(false);

  useEffect(() => {
    const element = hiddenRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio < 1) {
          setIsFloating(true);
        } else {
          setIsFloating(false);
        }
      });
    });
    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, []);

  const totals = useMemo(() => {
    const initial = {
      total_amount: 0,
      total_paid_amount: 0,
      total_unpaid_amount: 0,
    };

    if (!transactions) {
      return initial;
    }

    let finalTransactions = [];

    const normalTransactions = transactions.filter((transaction) => !transaction.split);
    const splitTransactions = transactions.filter((transaction) => transaction.split);

    const childSplitTransactions = splitTransactions.reduce((acc, transaction) => {
      const { splits = [] } = transaction || {};

      return [...acc, ...splits];
    }, []);

    finalTransactions = [...normalTransactions, ...childSplitTransactions];

    finalTransactions = finalTransactions.filter(
      (transaction) => selectedAccountIds.includes(transaction.account_id),
    );

    const dateFilter = (transaction) => {
      const transactionDate = DateTime.fromISO(transaction.event_date, { zone: 'utc' });

      return transactionDate.month === selectedDate.month
      && transactionDate.year === selectedDate.year;
    };

    const totalAmount = finalTransactions.filter(dateFilter).reduce((acc, transaction) => {
      const { split = false, splits = [] } = transaction || {};

      const splitsPaidAmount = splits.reduce(
        (acc, split) => acc + (split.paid ? split.amount : 0), 0,
      );

      const splitsUnpaidAmount = splits.reduce(
        (acc, split) => acc + (!split.paid ? split.amount : 0), 0,
      );

      let paidAmount = 0;
      let unpaidAmount = 0;

      if (!split) {
        paidAmount = transaction.paid ? transaction.amount : 0;
        unpaidAmount = !transaction.paid ? transaction.amount : 0;
      } else {
        paidAmount = splitsPaidAmount;
        unpaidAmount = splitsUnpaidAmount;
      }

      return {
        total_amount: acc.total_amount + transaction.amount,
        total_paid_amount: acc.total_paid_amount + paidAmount,
        total_unpaid_amount: acc.total_unpaid_amount + unpaidAmount,
      };
    }, initial);

    return totalAmount;
  }, [transactions, selectedDate, selectedAccountIds]);

  const hasTransactions = useMemo(
    () => transactions && transactions.length > 0, [transactions],
  );

  return (
    <>
      {!isMobile && !isTablet && (
        <StyledHint className="text-muted">
          Exibindo {filteredTransactionsLength} {filteredTransactionsLength === 1 ? 'item' : 'itens'} de um total de {allTransactionsLength}.
        </StyledHint>
      )}
      <StyledContainer
        ref={hiddenRef}
        id="transactions-footer"
        isFloating={hasTransactions && isFloating}
        isMobile={isMobile}
        isTablet={isTablet}
        isDesktopExtraLarge={isDesktopExtraLarge}
      >
        <tr className="transactions-summary__total">
          <td colSpan="4" className="transactions-summary__title">
            <div className="text-right">
              Total
            </div>
          </td>
          <td colSpan="5" className="transactions-summary__value">
            <div className="number-sm">
              {FORMATTERS.NUMBER(totals.total_amount)}
            </div>
          </td>
        </tr>
        <tr className="transactions-summary__paid">
          <td colSpan="4" className="transactions-summary__title">
            <div className="text-right">
              Pago
            </div>
          </td>
          <td colSpan="5" className="transactions-summary__value">
            <div>
              {FORMATTERS.NUMBER(totals.total_paid_amount)}
            </div>
          </td>
        </tr>
        <tr className="transactions-summary__conditional-values">
          <td colSpan="4" className="transactions-summary__title">
            <div className="text-right">
              {type === 'INCOME' ? 'A receber' : 'A pagar'}
            </div>
          </td>
          <td colSpan="5" className="transactions-summary__value">
            <div>
              {FORMATTERS.NUMBER(totals.total_unpaid_amount)}
            </div>
          </td>
        </tr>
      </StyledContainer>
    </>
  );
}

FooterSummary.defaultProps = {
  selectedDate: null,
  selectedAccountIds: [],
};

FooterSummary.propTypes = {
  type: PropTypes.string.isRequired,
  transactions: PropTypes.array.isRequired,
  allTransactionsLength: PropTypes.number.isRequired,
  filteredTransactionsLength: PropTypes.number.isRequired,
  selectedDate: PropTypes.object,
  selectedAccountIds: PropTypes.array,
};

export default FooterSummary;
