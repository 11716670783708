import styled from 'styled-components';
import Button from '_components/_core/Button';

export const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchButton = styled(Button)`
  margin-top: 26px;
  width: 100%;
`;
