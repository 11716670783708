import styled from 'styled-components';
import MonthPicker from 'react-month-picker';
import 'react-month-picker/css/month-picker.css';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { darken } from 'polished';

export const StyledMonthPicker = styled(MonthPicker)`
  &&& {
    .month-picker {
      cursor: pointer;
    }

    @media screen and (max-width: 767px) {
      .rmp-container.show {
        left: 0 !important;    
      }
    }
  }
`;

export const SelectedDate = styled.span`
  cursor: pointer;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  border: none;
  background-color: transparent;
  
  button  {
    border: none;
    background-color: transparent;
    color: #00ab6f;
    padding: 8px;
    margin-bottom: 2px;
    font-weight: 600;
  }

  button:active  {
    border: none !important;
    background-color: transparent !important;
    color: #00ab6f !important;
    box-shadow: none !important;
  }

  button:hover {
    background-color: transparent;

    font-weight: 600;
    transition: 0.1s ease;

    color: ${darken(0.1, '#00ab6f')};
  }

  button:nth-child(1) {
    color: #babec5;
  }

  button:nth-child(1):hover {
    color: #8d9096;
  }

  button:nth-child(2) {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: #8d9096;
    }
  }

  button:nth-child(3) {
    color: #babec5;
  }

  button:nth-child(3):hover {
    color: #8d9096;
  }

`;
