import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { DateTime } from 'luxon';
import { isEmpty, orderBy } from 'lodash';
import { FaRegFilePdf } from 'react-icons/fa';

import { LUCRO_PRESUMIDO_TAXES_OPTIONS } from 'helpers/constants';
import { Button, FormSelectField } from '_components/_core';

import FORMATTERS from 'helpers/formatters';
import { StyledModal, ModalFooter, DarfTable } from './styles';

const GenerateDarfsModal = ({
  isLoading,
  taxes,
  selectedDate,
  onFetchDarfs,
  onGenerateDarfs,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleGenerateDarfs = useCallback((values, { resetForm }) => {
    const params = [];

    values.tax_names.forEach(({ value: tax_name }) => {
      const amount_key = `amounts_${tax_name}`;
      const total = values[amount_key].reduce((acc, curr) => {
        const amount = curr.value.split('_')[1];

        acc += Number(amount);

        return acc;
      }, 0);

      const ordered = orderBy(values[amount_key], ['date_competence'], ['asc']);
      const lastItem = ordered[ordered.length - 1];

      params.push({
        tax_name,
        competence: lastItem.date_competence,
        amount: total,
      });
    });

    onGenerateDarfs(params, () => {
      resetForm();
      setIsVisible(false);
      onFetchDarfs(selectedDate.toFormat('yyyy'));
    });
  }, [selectedDate, onGenerateDarfs, onFetchDarfs]);

  const competenceTaxesOptions = useMemo(() => {
    if (isEmpty(taxes)) {
      return [];
    }

    const taxesWithAmountsMap = {
      PIS: [],
      COFINS: [],
      IRPJ: [],
      CSLL: [],
    };

    taxes.reduce((acc, tax) => {
      const { date_competence } = tax;
      const competence = DateTime.fromISO(date_competence, { zone: 'utc' }).toFormat('MM/yyyy');

      const pis_amount = tax.pis_pasep_due;
      const pis_option = {
        value: `${competence}_${pis_amount}`,
        label: `${competence} - ${FORMATTERS.NUMBER(pis_amount)}`,
        date_competence,
      };

      const cofins_amount = tax.cofins_due;
      const cofins_option = {
        value: `${competence}_${cofins_amount}`,
        label: `${competence} - ${FORMATTERS.NUMBER(cofins_amount)}`,
        date_competence,
      };

      const irpj_amount = tax.irpj_due;
      const irpj_option = {
        value: `${competence}_${irpj_amount}`,
        label: `${competence} - ${FORMATTERS.NUMBER(irpj_amount)}`,
        date_competence,
      };

      const csll_amount = tax.csll_due;
      const csll_option = {
        value: `${competence}_${csll_amount}`,
        label: `${competence} - ${FORMATTERS.NUMBER(csll_amount)}`,
        date_competence,
      };

      if (pis_amount > 0) {
        acc.PIS.push(pis_option);
      }

      if (cofins_amount > 0) {
        acc.COFINS.push(cofins_option);
      }

      if (irpj_amount > 0) {
        acc.IRPJ.push(irpj_option);
      }

      if (csll_amount > 0) {
        acc.CSLL.push(csll_option);
      }

      return acc;
    }, taxesWithAmountsMap);

    return taxesWithAmountsMap;
  }, [taxes]);

  const renderModalFooter = useCallback((handleSubmit) => (
    <ModalFooter>
      <Button variant="secondary" onClick={() => setIsVisible(false)}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={isLoading}
      >
        Gerar guias
      </Button>
    </ModalFooter>
  ), [isLoading]);

  return (
    <Formik
      initialValues={{
        tax_names: [],
        amounts_PIS: [],
        amounts_COFINS: [],
        amounts_IRPJ: [],
        amounts_CSLL: [],
      }}
      onSubmit={handleGenerateDarfs}
      enableReinitialize
    >
      {({ handleSubmit, values }) => (
        <>
          <Button
            className="mr-2"
            variant="default"
            onClick={() => setIsVisible(true)}
            icon={<FaRegFilePdf size="1.2em" />}
            iconPosition="left"
          >
            Gerar Guias
          </Button>
          <StyledModal
            title="Gerar guias"
            isVisible={isVisible}
            toggleModal={() => setIsVisible(false)}
            footer={renderModalFooter(handleSubmit)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>Quais guias deseja gerar?</Form.Label>
                  <FormSelectField
                    name="tax_names"
                    options={LUCRO_PRESUMIDO_TAXES_OPTIONS}
                    placeholder="Quais guias deseja gerar?"
                    multiple
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <div className="">
                    <DarfTable className="table table-striped">
                      <thead>
                        <tr>
                          <th width="10%">Tributo</th>
                          <th width="60%">
                            Valores que serão lançados na guia
                          </th>
                          <th className="text-right" width="30%">Valor total da guia</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.tax_names.map(({ label, value: tax_name }) => {
                          const amount_key = `amounts_${tax_name}`;
                          const total = values[amount_key].reduce((acc, curr) => {
                            const [, amount] = curr.value.split('_');

                            acc += Number(amount);

                            return acc;
                          }, 0);

                          return (
                            <tr key={tax_name}>
                              <td>{label}</td>
                              <td>
                                <FormSelectField
                                  name={amount_key}
                                  options={competenceTaxesOptions[tax_name]}
                                  placeholder="Quais guias deseja gerar?"
                                  multiple
                                />
                              </td>
                              <td className="text-right">
                                {FORMATTERS.NUMBER(total)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </DarfTable>
                  </div>
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default GenerateDarfsModal;

GenerateDarfsModal.defaultProps = {
  isLoading: false,
  taxes: [],
  selectedDate: null,
};

GenerateDarfsModal.propTypes = {
  isLoading: PropTypes.func,
  taxes: PropTypes.array,
  selectedDate: PropTypes.object,
  onGenerateDarfs: PropTypes.func.isRequired,
  onFetchDarfs: PropTypes.func.isRequired,
};
