import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';

import { FloatingCard } from '_components/_core';
import { IssuedInvoiceFilters } from '_components/_shared';
import { DEFAULT_FILTERS } from '_components/_shared/IssuedInvoiceFilters/utilities';

function Filters({
  filters,
  isVisible,
  onToggleFilters,
  onFilter,
}) {
  const handleFilter = useCallback((values) => {
    onFilter(values);
  }, [onFilter]);

  const handleKeyPress = useCallback((e, handleSubmit) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }, []);

  const renderFooter = useCallback((handleSubmit) => (
    <div className="d-flex">
      <Button className="mr-2 flex-fill" onClick={handleSubmit}>Filtrar</Button>
      <Button className="flex-fill" variant="secondary" onClick={onToggleFilters}>Cancelar</Button>
    </div>
  ), [onToggleFilters]);

  return (
    <Formik
      enableReinitialize
      initialValues={filters || DEFAULT_FILTERS}
      onSubmit={handleFilter}
    >
      {({ handleSubmit }) => (
        <>
          <FloatingCard
            title="Filtrar Notas Fiscais"
            fullHeight
            isVisible={isVisible}
            onToggleVisibility={onToggleFilters}
            footerContent={renderFooter(handleSubmit)}
          >
            <Container
              tabIndex={-1}
              onKeyPress={(e) => handleKeyPress(e, handleSubmit)}
            >
              <IssuedInvoiceFilters />
            </Container>
          </FloatingCard>
        </>
      )}
    </Formik>
  );
}

Filters.defaultProps = {
  filters: null,
};

Filters.propTypes = {
  filters: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
  onToggleFilters: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default Filters;
