import { connect } from 'react-redux';

import transactionActionCreators from '_store/_actions/companyTransactions';

import BankAccountOverview from './BankAccountOverview';

const mapStateToProps = (state) => ({
  isLoadingBalance: state.companyTransactions.isLoadingBalance,
  balance: state.companyTransactions.balance,
  accounts: state.companyTransactions.accountsForSelect,
  selected_account_id: state.companyTransactions.selected_account_id,
});

const mapDispatchToProps = {
  onChangeSelectedAccount: transactionActionCreators.setSelectedAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankAccountOverview);
