import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import isEmpty from 'lodash/isEmpty';
import {
  IoArrowForward,
  IoBookmarkOutline,
  IoFilter,
  IoRefresh,
  IoTextOutline,
} from 'react-icons/io5';
import {
  FaChevronCircleLeft,
  FaChevronCircleRight,
} from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

import { ZENPAPER_VIDEOS, useMediaQuery } from 'helpers';
import {
  PageHeader,
  Button,
  Tabs,
  Card,
} from '_components/_core';
import { Pagination, ItemsPerPage } from '_components/_core/Table/components';
import { useTableV2 } from '_components/_core/Table/utils';
import { VideoTutorialTag } from '_components/_shared';

import { getSortableColumns } from './utilities';
import {
  Progress,
  Filters,
  Table,
  FeedbackMessages,
  MultipleFieldsTab,
  ReplaceTextTab,
} from './components';
import { AbortButton } from './styles';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { field: 'event_date', order: 'asc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 20 },
};

export default function Reconcile({
  isLoading,
  activeCompany,
  total,
  transactions,
  accounts,
  recipients,
  categories,
  bankStatementSummary,
  onFetchAccounts,
  onFetchBankStatementTransactions,
  onFetchCategories,
  onFetchRecipients,
  onFetchTags,
  onFetchCostCenters,
  onFetchBankStatementSummary,
  onConfirmBankStatementUpload,
  onAbortBankStatementUpload,
  onUpdateMultipleTransactions,
  onUpdateTransactionsDescriptions,
  onUpdateTransactionsTransferDetails,
  onDeleteMultipleTransactions,
}) {
  const [isUploading, setIsUploading] = useState(false);

  const [activeTab, setActiveTab] = useState('filters');
  const [tabsExpanded, setTabsExpanded] = useState(() => {
    const localExpanded = localStorage.getItem('ofxTabsExpanded');

    if (localExpanded) {
      return JSON.parse(localExpanded);
    }

    return true;
  });

  const { isMobile } = useMediaQuery();

  useEffect(() => {
    onFetchAccounts();
    onFetchCategories();
    onFetchRecipients();
    onFetchTags();
    onFetchCostCenters();
    onFetchBankStatementSummary();
  }, [
    onFetchAccounts,
    onFetchCategories,
    onFetchRecipients,
    onFetchTags,
    onFetchCostCenters,
    onFetchBankStatementSummary,
    activeCompany,
  ]);

  const {
    pagination,
    selectedItems,
    onItemSelected,
    onSelectAll,
    onClearSelectedItems,
    displayFilters,
    filters,
    onFilter,
    onToggleFilters,
    sorting,
    onSorting,
    onPageChange,
    onPageSizeChange,
  } = useTableV2({
    ...tableConfig,
    data: transactions,
    onFetchData: onFetchBankStatementTransactions,
  });

  const isComplete = useMemo(() => {
    if (isEmpty(bankStatementSummary)) {
      return false;
    }

    const { valid_transactions } = bankStatementSummary;

    if (valid_transactions === 0) {
      return false;
    }

    return valid_transactions > 0;
  }, [bankStatementSummary]);

  const handleSort = useCallback((field, order) => {
    onSorting({
      field,
      order: order === 'asc' ? 'desc' : 'asc',
    });
  }, [onSorting]);

  const sortableColumns = useMemo(() => getSortableColumns(handleSort), [handleSort]);

  const handleFilter = useCallback((filterValues) => {
    onClearSelectedItems();

    onFilter(filterValues);
  }, [onFilter, onClearSelectedItems]);

  const onReconcileTransactions = useCallback(() => {
    onClearSelectedItems();
    onFetchBankStatementSummary();
    handleFilter(filters);
  }, [onClearSelectedItems, onFetchBankStatementSummary, filters, handleFilter]);

  const handleClearFilters = useCallback(() => {
    onClearSelectedItems();
    onFilter({});
  }, [onFilter, onClearSelectedItems]);

  const handleConfirmUpload = useCallback(() => {
    setIsUploading(true);

    onConfirmBankStatementUpload(() => {
      setIsUploading(false);
    });
  }, [onConfirmBankStatementUpload]);

  const bank_statement_account_id = useMemo(() => {
    if (isEmpty(transactions)) {
      return null;
    }

    const [transaction] = transactions;
    const { account_id } = transaction;

    return account_id;
  }, [transactions]);

  const renderImportButton = useCallback(() => (
    <Button
      icon={<IoArrowForward />}
      variant="success-2"
      onClick={handleConfirmUpload}
      disabled={!isComplete}
      className="ml-4"
      isLoading={isUploading}
    >
      Importar
    </Button>
  ), [handleConfirmUpload, isUploading, isComplete]);

  const renderRestartButton = useCallback(() => (
    <AbortButton onClick={() => onAbortBankStatementUpload()} className="mr-4 text-danger">
      <IoRefresh className="mr-2 text-danger" />
      <small>
        Recomeçar
      </small>
    </AbortButton>
  ), [onAbortBankStatementUpload]);

  const renderControls = useCallback(() => (
    <div className="d-flex justify-content-center align-items-center">
      {renderRestartButton()}
      <Progress
        variant="extra-small"
        onConfirmUpload={handleConfirmUpload}
      />
      {renderImportButton()}
    </div>
  ), [handleConfirmUpload, renderRestartButton, renderImportButton]);

  const commonProps = useMemo(() => ({
    selectedItems,
    allItems: transactions.map((transaction) => transaction.id),
    recipients,
    accounts,
    categories,
    bank_statement_account_id,
    onUpdateTransactionsDescriptions,
    onUpdateTransactionsTransferDetails,
    onUpdateMultipleTransactions,
    onReconcileTransactions,
    showIcon: !tabsExpanded,
  }), [
    selectedItems,
    transactions,
    recipients,
    accounts,
    categories,
    bank_statement_account_id,
    tabsExpanded,
    onUpdateTransactionsDescriptions,
    onUpdateTransactionsTransferDetails,
    onUpdateMultipleTransactions,
    onReconcileTransactions,
  ]);

  const handleDeleteMultipleTransactions = useCallback(() => {
    onDeleteMultipleTransactions({ ids: selectedItems }, () => {
      onReconcileTransactions();
    }, {
      ignorePreConfirm: true,
    });
  }, [onDeleteMultipleTransactions, onReconcileTransactions, selectedItems]);

  const getTabs = useCallback(() => [
    {
      id: 'filters',
      title: tabsExpanded ? 'Filtros' : 'Filtrar / Categorizar / Substituir Texto',
      icon: tabsExpanded ? <IoFilter className="mr-2" /> : null,
      content: (
        <>
          <Filters
            isOpen={displayFilters}
            filters={filters}
            onToggleFilters={onToggleFilters}
            onFilter={handleFilter}
            onClearFilters={handleClearFilters}
            total={total}
            totalBeingShown={transactions.length}
            showIcon={!tabsExpanded}
          />
          {!tabsExpanded && <MultipleFieldsTab {...commonProps} transactions={transactions} variant="inline" />}
          {!tabsExpanded && <ReplaceTextTab {...commonProps} variant="inline" />}
        </>
      ),
    },
    {
      id: 'multiple-fields',
      title: 'Categorizar',
      icon: <IoBookmarkOutline className="mr-2" />,
      content: <MultipleFieldsTab {...commonProps} transactions={transactions} variant="inline" />,
      hidden: !tabsExpanded,
    },
    {
      id: 'replace-text',
      title: 'Substituir Texto',
      icon: <IoTextOutline className="mr-2" />,
      content: <ReplaceTextTab {...commonProps} variant="inline" />,
      hidden: !tabsExpanded,
    },
    {
      id: 'EXPAND',
      icon: tabsExpanded ? <FaChevronCircleLeft className="ml-1 mr-1 mt-1" /> : <FaChevronCircleRight className="ml-1 mr-1 mt-1" />,
      content: null,
    },
    {
      id: 'DELETE_SELECTED',
      title: (
        <>
          {!isEmpty(selectedItems) && (
            <Button
              onClick={() => handleDeleteMultipleTransactions()}
              variant="link"
              className="p-0 m-0 text-danger"
            >
              {`Excluir ${selectedItems.length} ${selectedItems.length === 1 ? 'item selecionado' : 'itens selecionados'}`}
            </Button>
          )}
          {isEmpty(selectedItems) && `Exibindo ${transactions.length} ${transactions.length === 1 ? 'item' : 'itens'} de ${total} encontrados`}
        </>
      ),
    },
  ], [
    selectedItems,
    commonProps,
    displayFilters,
    filters,
    handleClearFilters,
    handleFilter,
    onToggleFilters,
    total,
    transactions,
    tabsExpanded,
    handleDeleteMultipleTransactions,
  ]);

  const handleTabChange = useCallback((tab) => {
    if (tab === 'EXPAND') {
      setTabsExpanded(!tabsExpanded);
      setActiveTab('filters');

      localStorage.setItem('ofxTabsExpanded', !tabsExpanded);

      return;
    }

    if (tab === 'DELETE_SELECTED' || tab === 'TOTAL_ITEMS') {
      return;
    }

    setActiveTab(tab);
  }, [tabsExpanded]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <Container fluid className="content-wrapper fixedHeader pb-5">
      <PageHeader
        title={(
          <div className="d-flex justify-content-center align-items-center">
            Conciliar Lançamentos
            <VideoTutorialTag text="VÍDEO TUTORIAL" link={ZENPAPER_VIDEOS.BANK_STATEMENT_UPLOAD} />
          </div>
        )}
        description="Concilie os lançamentos gerados e depois clique em Importar"
        fixedHeader
        variant="small"
        sideContent={(
          <>
            {!isMobile && renderControls()}
            {isMobile && renderRestartButton()}
          </>
        )}
        bottomContent={(
          <>
            {isMobile && (
              <Row className="mt-3">
                <Col>
                  <div className="d-flex justify-content-between align-items-center">
                    <Progress
                      variant="extra-small"
                      onConfirmUpload={handleConfirmUpload}
                    />
                    {renderImportButton()}
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      />
      <Row className="mt-3">
        <Col
          xl={12}
          className="mb-3"
          style={{
            position: 'sticky',
            top: isMobile ? '182px' : '145px',
            zIndex: 980,
          }}
        >
          <Card style={{
            boxShadow: '5px 5px 10px 5px rgb(30 32 37 / 12%)',
          }}
          >
            <Tabs
              tabs={getTabs()}
              variant="secondary"
              onTabChange={handleTabChange}
              activeTab={activeTab}
              floatRightLastTab
            />
          </Card>
        </Col>
        {!isEmpty(transactions) && (
        <>
          <Col xl={12}>
            <Table
              selectedItems={selectedItems}
              transactions={transactions}
              accounts={accounts}
              sorting={sorting}
              sortableColumns={sortableColumns}
              onItemSelected={onItemSelected}
              onSelectAll={onSelectAll}
              onUpdateMultipleTransactions={onUpdateMultipleTransactions}
              bank_statement_account_id={bank_statement_account_id}
              onReconcileTransactions={onReconcileTransactions}
              onDeleteMultipleTransactions={onDeleteMultipleTransactions}
            />
          </Col>
          <Col xl={6} xs={5} className="d-flex justify-content-start">
            <ItemsPerPage
              itemsPerPage={pagination.itemsPerPage}
              onChange={onPageSizeChange}
              noMarginsOnTotals
              className="mr-3"
            />
          </Col>
          <Col xl={6} xs={7} className="d-flex justify-content-end">
            <Pagination
              {...pagination}
              total={total}
              onPageChange={onPageChange}
            />
          </Col>
        </>
        )}
        {isEmpty(transactions) && !isLoading && (
          <Col xl={12}>
            <FeedbackMessages
              icon="empty"
              title="Nenhum lançamento encontrado"
              description={(
                <p style={{ fontSize: '1.1em' }}>
                  Não encontramos nenhum lançamento com os filtros informados. <br />
                  Experimente alterar os filtros e tentar novamente.
                </p>
              )}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
}

Reconcile.defaultProps = {
  isLoading: false,
  transactions: [],
  accounts: [],
  recipients: [],
  categories: [],
  activeCompany: null,
  bankStatementSummary: {},
};

Reconcile.propTypes = {
  activeCompany: PropTypes.object,
  total: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  transactions: PropTypes.array,
  recipients: PropTypes.array,
  categories: PropTypes.array,
  accounts: PropTypes.array,
  bankStatementSummary: PropTypes.object,
  onFetchAccounts: PropTypes.func.isRequired,
  onFetchBankStatementTransactions: PropTypes.func.isRequired,
  onFetchCategories: PropTypes.func.isRequired,
  onFetchRecipients: PropTypes.func.isRequired,
  onUpdateMultipleTransactions: PropTypes.func.isRequired,
  onFetchBankStatementSummary: PropTypes.func.isRequired,
  onConfirmBankStatementUpload: PropTypes.func.isRequired,
  onAbortBankStatementUpload: PropTypes.func.isRequired,
  onFetchTags: PropTypes.func.isRequired,
  onFetchCostCenters: PropTypes.func.isRequired,
  onUpdateTransactionsDescriptions: PropTypes.func.isRequired,
  onUpdateTransactionsTransferDetails: PropTypes.func.isRequired,
  onReconcileTransactions: PropTypes.func.isRequired,
  onDeleteMultipleTransactions: PropTypes.func.isRequired,
};
