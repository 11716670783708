import FORMATTERS from 'helpers/formatters';
import { SPECIAL_COLUMNS } from '_components/_core/Table';

export const DEFAULT_COLUMNS = ['cnae', 'is_primary', 'description'];

export const TABLE_COLUMNS = [
  {
    label: 'Remover',
    keyName: 'cnae',
    align: 'center',
    width: 5,
    specialType: SPECIAL_COLUMNS.ACTIONS,
  },
  {
    label: 'CNAE',
    keyName: 'cnae',
    align: 'center',
    formatter: FORMATTERS.CNAE,
    width: 10,
  },
  {
    label: 'Tipo',
    keyName: 'is_primary',
    align: 'center',
    formatter: FORMATTERS.CNAE_TYPE,
    width: 10,
  },
  {
    label: 'Descrição',
    keyName: 'description',
    align: 'left',
    formatter: FORMATTERS.UPPERCASE,
    width: 75,
  },
];
