import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsPlus } from 'react-icons/bs';

import { Button, TableLookup } from '_components/_core';
import { Pagination, ItemsPerPage } from '_components/_core/Table/components';
import { useTable } from '_components/_core/Table/utils';

import AddPersonModal from '../AddPersonModal/AddPersonModalContainer';
import { DEFAULT_COLUMNS, TABLE_COLUMNS } from '../AddPersonModal/utilities';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { field: 'name', order: 'asc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function InvoicePersons({
  persons,
  personsTotal,
  activeCompany,
  onFetchPersons,
  onAddPerson,
  onUpdatePerson,
  onDeletePerson,
}) {
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { id, company_name, trading_name } = activeCompany || {};

  const onFetchPersonsModified = useCallback((params) => {
    onFetchPersons(id, params);
  }, [id, onFetchPersons]);

  const {
    sorting,
    pagination,
    onSorting,
    onPageChange,
    onPageSizeChange,
  } = useTable({
    ...tableConfig,
    data: persons,
    onFetchData: onFetchPersonsModified,
  });

  const handleAddPerson = useCallback(() => {
    setSelectedPerson(null);
    setIsModalOpen(true);
  }, []);

  const handleEditPerson = useCallback((_id) => {
    setSelectedPerson(null);

    const person = persons.find((person) => person._id === _id);

    setSelectedPerson(person);
    setIsModalOpen(true);
  }, [persons]);

  const handleDeletePerson = useCallback((personId) => {
    onDeletePerson(id, personId);
  }, [onDeletePerson, id]);

  return (
    <>
      <AddPersonModal
        isVisible={isModalOpen}
        person={selectedPerson}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
        onAddPerson={onAddPerson}
        onUpdatePerson={onUpdatePerson}
        id={id}
        personId
      />
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="ml-2">
          <h4>Clientes</h4>
          <p>
            {`Listagem de clientes da empresa ${company_name} (${trading_name})`}
          </p>
        </div>
        <Button variant="primary" icon={<BsPlus />} onClick={handleAddPerson}>
          Novo Cliente
        </Button>
      </div>
      <TableLookup
        keyName="_id"
        name="invoice-persons-lookup-table"
        data={persons}
        columns={TABLE_COLUMNS}
        defaultColumns={DEFAULT_COLUMNS}
        sorting={sorting}
        onSorting={onSorting}
        onEdit={handleEditPerson}
        onDelete={handleDeletePerson}
        topDistance="calc(100vh - (66px + 8rem + 62px + 49px + 8em))"
        showColumnToggle
      />
      <Row>
        <Col sm={4}>
          <ItemsPerPage
            itemsPerPage={pagination.itemsPerPage}
            onChange={onPageSizeChange}
            className="mt-3"
            total={personsTotal}
            totalBeingShown={persons.length}
          />
        </Col>
        <Col className="d-flex flex-row-reverse">
          <Pagination
            {...pagination}
            total={personsTotal}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
    </>
  );
}

InvoicePersons.defaultProps = {
  persons: [],
  personsTotal: 0,
};

InvoicePersons.propTypes = {
  persons: PropTypes.array,
  personsTotal: PropTypes.number,
  activeCompany: PropTypes.object,
  onFetchPersons: PropTypes.func,
  onAddPerson: PropTypes.func,
  onUpdatePerson: PropTypes.func,
  onDeletePerson: PropTypes.func,
};

export default InvoicePersons;
