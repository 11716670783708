import { reportsConstants } from '../_constants';

const INITIAL_STATE = {
  revenue: null,
  isLoading: false,
  isLoadingIssReport: false,
  issReport: [],
};

export function reports(state = INITIAL_STATE, action) {
  switch (action.type) {
    case reportsConstants.GET_REVENUE_REPORT_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case reportsConstants.GET_REVENUE_REPORT_SUCCESS: {
      const revenue = action.payload;

      return {
        ...state,
        isLoading: false,
        revenue,
      };
    }
    case reportsConstants.GET_REVENUE_REPORT_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case reportsConstants.CLEAR_REVENUE_REPORT_REQUEST: {
      return {
        ...state,
        isLoading: false,
        revenue: null,
      };
    }
    case reportsConstants.GET_ISS_REPORT_REQUEST: {
      return {
        ...state,
        isLoadingIssReport: true,
      };
    }
    case reportsConstants.GET_ISS_REPORT_SUCCESS: {
      const issReport = action.payload;

      return {
        ...state,
        isLoadingIssReport: false,
        issReport,
      };
    }
    case reportsConstants.GET_ISS_REPORT_FAILURE: {
      return {
        ...state,
        isLoadingIssReport: false,
      };
    }
    case reportsConstants.CLEAR_ISS_REPORT_REQUEST: {
      return {
        ...state,
        isLoadingIssReport: false,
        issReport: null,
      };
    }
    default:
      return state;
  }
}
