import { connect } from 'react-redux';

import authActionCreators from '_store/_actions/auth';

import PasswordReset from './PasswordReset';

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
});

const mapDispatchToProps = {
  onPasswordReset: authActionCreators.passwordReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
