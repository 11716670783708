import { connect } from 'react-redux';

import reportsActionCreators from '_store/_actions/reports';

import RevenueReport from './RevenueReport';

const mapStateToProps = (state) => ({
  isLoading: state.reports.isLoading,
  revenueReport: state.reports.revenue,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onClearRevenueReport: reportsActionCreators.clearRevenueReport,
  onFetchRevenueReport: reportsActionCreators.getRevenueReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(RevenueReport);
