import { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useContextMenu } from 'react-contexify';
import { IoSearch, IoTrash } from 'react-icons/io5';
import Mark from 'mark.js';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import FORMATTERS from 'helpers/formatters';
import { LoadingIcon, SplitTransactionModal } from '_components/_shared';
import { SortableColumns } from '_components/_core/Table/components';
import { useTable } from '_components/_core/Table/utils';
import { hasPermissions } from '_components/_shared/PermissionsGate/utilities';
import { isMonthBlocked, useMediaQuery } from 'helpers';

import ActionButton from './ActionButton';
import TransactionRow from './TransactionRow';
import TransactionRowForm from './TransactionRowForm/TransactionRowFormContainer';
import FooterSummary from './FooterSummary/FooterSummaryContainer';
import TransactionContextMenu from './TransactionContextMenu/TransactionContextMenu';
import EmptyMessage from './EmptyMessage/EmptyMessage';
import TransferForm from './TransferForm/TransferFormContainer';
import TransactionFilters from './TransactionFilters/TransactionFilters';

import useTransferForm from '../utilities/useTransferForm';
import useTransactions from '../utilities/useTransactions';
import { StyledTable, StyledFormCheck, TableContainer } from '../styles';

import filterTransactions from '../utilities/filterTransactions';

import {
  FiltersContainer,
  StyledCreateTd,
  StyledCreateTr,
  StyledSearchInput,
} from './styles';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: {},
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function TransactionsTable({
  type,
  subType,
  activeTab,
  tabsExpanded,
  selected_account_id,
  categories,
  recipients,
  costsCenter,
  tags,
  preferences,
  onMoveTransactions,
  onUpdateMultipleTransactions,
  onUpdateTransactionFilters,
  onApplyTagMultipleTransactions,
  onSortTransactions,
  sorting,
  availableTabs,
  getParams,
  blockedPeriod,
}) {
  const userPermissions = useSelector(
    state => state.userPermissions.permissions[state.auth.user.id],
  );

  const { isMobile, isTablet, isDesktopMedium, isDesktopLarge, isDesktopExtraLarge } =
    useMediaQuery();
  const transactionFormRef = useRef();
  const transferFormRef = useRef();

  const {
    isLoading,
    transactions: allTransactions,
    selectedDate,
    onCreateUpdateTransaction,
    onDeleteMultipleTransactions,
    onDeleteTransaction,
    onRefreshBalance,
    selectedAccountIds,
  } = useTransactions();

  const hasCreatePermission = useMemo(() => {
    const permissionMap = {
      'INCOME-null': 'aba_recebimento_create',
      'EXPENSE-FIXED_EXPENSE': 'aba_despesa_fixa_create',
      'EXPENSE-VARIABLE_EXPENSE': 'aba_despesa_variavel_create',
      'EXPENSE-PEOPLE': 'aba_pessoal_create',
      'EXPENSE-TAXES': 'aba_imposto_create',
      'TRANSFER-null': 'aba_transferencia_create',
    };

    const permissionName = permissionMap[`${type}-${subType}`];

    return hasPermissions({
      permissions: [permissionName],
      userPermissions,
      type: 'all',
    });
  }, [type, subType, userPermissions]);

  const deletePermission = useMemo(() => {
    const permissionMap = {
      'INCOME-null': 'aba_recebimento_delete',
      'EXPENSE-FIXED_EXPENSE': 'aba_despesa_fixa_delete',
      'EXPENSE-VARIABLE_EXPENSE': 'aba_despesa_variavel_delete',
      'EXPENSE-PEOPLE': 'aba_pessoal_delete',
      'EXPENSE-TAXES': 'aba_imposto_delete',
      'TRANSFER-null': 'aba_transferencia_delete',
    };

    const permissionName = permissionMap[`${type}-${subType}`];

    return permissionName;
  }, [type, subType]);

  const { isTransferFormOpen } = useTransferForm();

  const [selectedTransactionId] = useState(null);
  const [transactionContextMenu, setTransactionContextMenu] = useState(null);
  const [transactionSplit, setTransactionSplit] = useState(null);
  const [viewType, setViewType] = useState(() => {
    const savedViewType = localStorage.getItem('transactions_view_type');

    if (savedViewType) {
      return savedViewType;
    }

    return 'compact';
  });

  const [isSplitModalOpen, setIsSplitModalOpen] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState('');
  const [textFilter, setTextFilter] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(0);

  const markInstance = new Mark(document.querySelector('#transactions-table'));

  const [newTransaction, setNewTransaction] = useState(null);

  const transactions = useMemo(() => {
    if (isEmpty(allTransactions)) {
      return [];
    }

    let filteredTransactions = allTransactions.filter(t => t.split_id === null);

    if (textFilter) {
      filteredTransactions = filterTransactions(allTransactions, textFilter);
    }

    return filteredTransactions;
  }, [allTransactions, textFilter]);

  const delayedOnFilter = query => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTypingTimeout = setTimeout(() => {
      setTextFilter(query);

      markInstance.unmark({
        done: () => {
          markInstance.mark(query);
        },
      });
    }, 475);

    setTypingTimeout(newTypingTimeout);
  };

  const handleInputChange = e => {
    const searchValue = e.target.value;

    setSearchCriteria(searchValue);

    delayedOnFilter(searchValue.trim());
  };

  const handleClearTextFilter = () => {
    setSearchCriteria('');
    setTextFilter('');

    markInstance.unmark();
  };

  const MENU_ID = useMemo(
    () => `transaction-${selectedTransactionId}`,
    [selectedTransactionId],
  );

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  const handlePrepareNewTransaction = useCallback(
    (type, subType) => {
      // onCreateEmptyTransaction(type, subType, (created_transaction) => {
      //   setNewTransaction(created_transaction);
      // });

      const selectedMonthYear = selectedDate.toFormat('yyyy-MM');
      const currentMonthYear = DateTime.now().toFormat('yyyy-MM');

      let event_date = null;

      if (selectedMonthYear === currentMonthYear) {
        event_date = DateTime.now().toFormat('yyyy-MM-dd');
      } else {
        event_date = selectedDate.startOf('month').toISODate();
      }

      setNewTransaction({
        type,
        sub_type: subType,
        account_id: selected_account_id,
        category_id: null,
        recipient_id: null,
        description: '',
        event_date,
        amount: 0,
        paid: false,
        payment_plan: 'ONE_TIME',
      });
    },
    [selected_account_id, selectedDate],
  );

  const { selectedItems, onItemSelected, onSelectAll } = useTable({
    ...tableConfig,
    data: transactions,
    onFetchData: null,
    keyName: 'id',
  });

  const handleContextMenuOpen = useCallback(
    (e, transaction, type = 'normal') => {
      setTransactionContextMenu(transaction);

      if (type !== 'normal') {
        const { clientX, clientY } = e;

        show(e, {
          position: {
            x: clientX - 250,
            y: clientY + 10,
          },
        });

        return;
      }

      show(e);
    },
    [show],
  );

  const handleContextMenuClosed = useCallback(() => {
    setTransactionContextMenu(null);
  }, []);

  const handleItemSelected = useCallback(
    item => {
      const idsOrder = transactions.map(transaction => transaction.id);

      onItemSelected(item, false, idsOrder);
    },
    [onItemSelected, transactions],
  );

  const handleClearSelectedItems = useCallback(() => {
    onItemSelected(null, true);
  }, [onItemSelected]);

  const handleChangeViewType = useCallback(newViewType => {
    setViewType(newViewType);

    localStorage.setItem('transactions_view_type', newViewType);
  }, []);

  const handleSplitTransactionSelected = useCallback(transaction => {
    setTransactionSplit(transaction);

    setIsSplitModalOpen(true);
  }, []);

  const handleSplitModalToggle = useCallback(() => {
    if (isSplitModalOpen) {
      setTransactionSplit(null);
    }

    setIsSplitModalOpen(!isSplitModalOpen);
  }, [isSplitModalOpen]);

  const handleSort = useCallback(
    (field, order) => {
      onSortTransactions({
        field,
        order: order === 'asc' ? 'desc' : 'asc',
      });
    },
    [onSortTransactions],
  );

  const sortableColumns = useMemo(() => {
    const columns = [];

    columns.push({
      field: 'event_date',
      path: 'event_date',
      label: 'Data',
      sortable: true,
      sort: handleSort,
      width: '5%',
    });

    columns.push({
      field: 'description',
      path: 'description',
      label: 'Descrição',
      sortable: true,
      sort: handleSort,
    });

    if (type !== 'TRANSFER') {
      columns.push({
        field: 'recipient',
        path: 'recipient.name',
        label: type === 'INCOME' ? 'Recebido de' : 'Pago a',
        sortable: true,
        sort: handleSort,
        width: '5%',
      });
    }

    columns.push({
      field: 'amount',
      path: 'amount',
      label: 'Valor',
      sortable: true,
      sort: handleSort,
      width: '5%',
    });

    if (type !== 'TRANSFER') {
      columns.push({
        field: 'category',
        path: 'category.description',
        label: 'Categoria',
        sortable: true,
        sort: handleSort,
        width: '5%',
      });
    }

    if (type !== 'TRANSFER') {
      columns.push({
        field: 'payment_plan',
        path: 'payment_plan',
        label: 'Pagamento',
        sortable: true,
        sort: handleSort,
        width: '5%',
      });
    }

    if (type === 'TRANSFER') {
      columns.push({
        field: 'account_id_origin',
        path: 'transfer_details.account_id_origin',
        label: 'Conta de Origem',
        sortable: true,
        sort: handleSort,
      });
    }

    if (type === 'TRANSFER') {
      columns.push({
        field: 'account_id_destination',
        path: 'transfer_details.account_id_destination',
        label: 'Conta de Destino',
        sortable: true,
        sort: handleSort,
      });
    }

    columns.push({
      field: 'paid',
      path: 'paid',
      label: 'Pago?',
      sortable: true,
      sort: handleSort,
    });

    return columns;
  }, [type, handleSort]);

  const handleClearNewTransaction = useCallback(() => {
    setNewTransaction(null);
  }, []);

  const handleCancelEditTransaction = useCallback(() => {
    setNewTransaction(null);
  }, []);

  const responsiveProps = useMemo(
    () => ({
      isMobile,
      isTablet,
      isDesktopMedium,
      isDesktopLarge,
      isDesktopExtraLarge,
    }),
    [isMobile, isTablet, isDesktopMedium, isDesktopLarge, isDesktopExtraLarge],
  );

  useEffect(() => {
    onItemSelected(null, true);
  }, [selectedDate]);

  return (
    <>
      <TableContainer>
        <FiltersContainer id="filters-container" className="no-print">
          <TransactionFilters
            activeTab={activeTab}
            tabsExpanded={tabsExpanded}
            categories={categories}
            recipients={recipients}
            costsCenter={costsCenter}
            tags={tags}
            selectedItems={selectedItems}
            transactions={transactions}
            isMobile={isMobile}
            viewType={viewType}
            selectedDate={selectedDate}
            onUpdateTransactionFilters={onUpdateTransactionFilters}
            onSetViewType={handleChangeViewType}
            onDeleteMultipleTransactions={onDeleteMultipleTransactions}
            onDeleteTransaction={onDeleteTransaction}
            deletePermission={deletePermission}
            onClearSelectedItems={handleClearSelectedItems}
            blockedPeriod={blockedPeriod}
            customFilters={
              <span
                className="d-flex align-items-center pl-2"
                style={{
                  border: '1px solid #E8E7EA',
                  borderRadius: '8px',
                  height: '31.41px',
                  backgroundColor: '#F9FBFC',
                }}
              >
                {!textFilter && <IoSearch size="1.2em" />}
                {textFilter && (
                  <IoTrash
                    className="text-danger"
                    size="1.2em"
                    onClick={handleClearTextFilter}
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                )}
                <StyledSearchInput
                  type="text"
                  placeholder="Pesquisar na tabela"
                  value={searchCriteria}
                  onChange={handleInputChange}
                  className="ml-2"
                />
              </span>
            }
          />
          {isSplitModalOpen && (
            <SplitTransactionModal
              isEditing
              isVisible={isSplitModalOpen}
              isMobile={isMobile}
              transaction={transactionSplit}
              recipients={recipients}
              onModalToggle={handleSplitModalToggle}
              onAfterSaveCallback={() => {
                onRefreshBalance();
                setTransactionSplit(null);
                setIsSplitModalOpen(false);
              }}
            />
          )}
        </FiltersContainer>
        <div>
          <StyledTable
            id="transactions-table"
            {...responsiveProps}
            className="table table-hover"
          >
            <thead className="text-center">
              <tr>
                <th className="checkbox-column">
                  <StyledFormCheck
                    type="checkbox"
                    checked={
                      !isEmpty(transactions) &&
                      selectedItems.length === transactions.length
                    }
                    id="checkbox-select-all"
                    onClick={onSelectAll}
                  />
                </th>
                <SortableColumns sorting={sorting} columns={sortableColumns} />
                <th className="action-column">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr className="loading-row">
                  <td className="pt-4 pb-4" colSpan={9}>
                    <LoadingIcon
                      id="loading-message"
                      isLoading={isLoading}
                      text="Carregando transações..."
                    />
                  </td>
                </tr>
              )}
              {!isLoading && !newTransaction && !isTransferFormOpen && (
                <StyledCreateTr
                  className="no-print"
                  hasCreatePermission={hasCreatePermission}
                >
                  <td>
                    <TransactionContextMenu
                      menuId={MENU_ID}
                      transaction={transactionContextMenu}
                      selectedItems={selectedItems}
                      onClearSelectedItems={handleClearSelectedItems}
                      type={type}
                      subType={subType}
                      costsCenter={costsCenter}
                      tags={tags}
                      onMenuClosed={handleContextMenuClosed}
                      onSubmitTransaction={onCreateUpdateTransaction}
                      onMoveTransactions={onMoveTransactions}
                      onUpdateMultipleTransactions={onUpdateMultipleTransactions}
                      onApplyTagMultipleTransactions={onApplyTagMultipleTransactions}
                      allTransactions={transactions}
                      availableTabs={availableTabs}
                      blockedPeriod={blockedPeriod}
                    />
                  </td>
                  <StyledCreateTd colSpan="8">
                    <ActionButton
                      type={type}
                      subType={subType}
                      onToggleTransactionCreateForm={handlePrepareNewTransaction}
                      transactionFormRef={transactionFormRef}
                      transferFormRef={transferFormRef}
                      onClearNewTransaction={handleClearNewTransaction}
                      blockedPeriod={blockedPeriod}
                      selectedDate={selectedDate}
                    />
                    {isMonthBlocked(blockedPeriod, selectedDate) && (
                      <span className="text-danger">
                        <p className="m-0">
                          {`O período ${FORMATTERS.BLOCKED_PERIOD(blockedPeriod)} está bloqueado para alterações`}
                        </p>
                      </span>
                    )}
                  </StyledCreateTd>
                </StyledCreateTr>
              )}
              {!isLoading && newTransaction && (
                <TransactionRowForm
                  isCreationMode
                  key="new-transaction-form-row"
                  type={type}
                  subType={subType}
                  transaction={newTransaction}
                  transactionFormRef={transactionFormRef}
                  selectedDate={selectedDate}
                  selectedFieldName="description"
                  selected_account_id={selected_account_id}
                  onSubmit={onCreateUpdateTransaction}
                  onCancelEditTransaction={handleCancelEditTransaction}
                />
              )}
              {type === 'TRANSFER' && isTransferFormOpen && (
                <TransferForm
                  selectedDate={selectedDate}
                  transferFormRef={transferFormRef}
                />
              )}
              {!isLoading &&
                transactions.map(transaction => (
                  <TransactionRow
                    key={`normal-row-${transaction.id}`}
                    type={type}
                    subType={subType}
                    transactionFormRef={transactionFormRef}
                    transaction={transaction}
                    selectedItems={selectedItems}
                    onItemSelected={handleItemSelected}
                    onTransactionContextMenu={handleContextMenuOpen}
                    onUpdateMultipleTransactions={onUpdateMultipleTransactions}
                    viewType={viewType}
                    preferences={preferences}
                    getParams={getParams}
                    onEditSplitTransaction={handleSplitTransactionSelected}
                    blockedPeriod={blockedPeriod}
                  />
                ))}
            </tbody>
            {!isLoading && !isEmpty(transactions) && (
              <FooterSummary
                type={type}
                transactions={transactions}
                selectedItems={selectedItems}
                allTransactionsLength={allTransactions.length}
                filteredTransactionsLength={transactions.length}
                selectedDate={selectedDate}
                selectedAccountIds={selectedAccountIds}
              />
            )}
          </StyledTable>
        </div>
        {!isLoading && !isTransferFormOpen && isEmpty(transactions) && (
          <EmptyMessage type={type} subType={subType} textFilter={textFilter} />
        )}
      </TableContainer>
    </>
  );
}

TransactionsTable.defaultProps = {
  categories: [],
  recipients: [],
  costsCenter: [],
  tags: [],
  preferences: {},
  availableTabs: [],
  blockedPeriod: {},
  selectedDate: null,
};

TransactionsTable.propTypes = {
  categories: PropTypes.array,
  recipients: PropTypes.array,
  costsCenter: PropTypes.array,
  tags: PropTypes.array,
  type: PropTypes.string,
  subType: PropTypes.string,
  activeTab: PropTypes.string,
  tabsExpanded: PropTypes.bool,
  preferences: PropTypes.object,
  selected_account_id: PropTypes.string.isRequired,
  onMoveTransactions: PropTypes.func.isRequired,
  onUpdateMultipleTransactions: PropTypes.func.isRequired,
  onUpdateTransactionFilters: PropTypes.func.isRequired,
  onApplyTagMultipleTransactions: PropTypes.func.isRequired,
  onSortTransactions: PropTypes.func.isRequired,
  sorting: PropTypes.object,
  availableTabs: PropTypes.array,
  getParams: PropTypes.func.isRequired,
  blockedPeriod: PropTypes.object,
  selectedDate: PropTypes.object,
};

export default TransactionsTable;
