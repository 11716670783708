import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaArrowRight } from 'react-icons/fa';

import { Card, CardBody, Button } from '_components/_core';

const public_folder = process.env.PUBLIC_URL;

function InvoiceIssuingPaywall({ onShowActivateForm }) {
  const text = {
    LINE_1: 'Você sabia que pode aumentar a receita do seu escritório emitindo',
    LINE_2: 'as Notas Fiscais de Serviço dos seus clientes diretamente pelo Contabills?',
    LINE_3: '1. Você cobra do seu cliente o valor que quiser pelo serviço de emissão, exemplo: R$ 150,00',
    LINE_4: '2. Você emite as Notas pelo Contabills preenchendo apenas alguns campos.',
    LINE_5: '3. Sem precisar digitar senhas repetidas vezes, salvar PDF\'s, o Contabills faz tudo automaticamente.',
  };

  return (
    <Row>
      <Col md={{ span: 12 }}>
        <Card className="p-4">
          <CardBody>
            <Row>
              <Col className="d-flex justify-content-center align-items-center">
                <div className="mr-5">
                  <h3 className="mb-4">Emissão de Notas Fiscais</h3>
                  <p className="w-65">
                    {text.LINE_1}
                    <br />
                    {text.LINE_2}
                  </p>
                  <p>
                    {text.LINE_3}
                    <br />
                    {text.LINE_4}
                    <br />
                    {text.LINE_5}
                  </p>
                  <Button className="mb-2 mt-3" icon={<FaArrowRight />} onClick={onShowActivateForm}>
                    Ativar emissão para este CNPJ
                  </Button>
                  {' '}
                  <br />
                  <small className="text-muted">
                    (custo de R$ 50,00/mês para cada CNPJ que você ativar a emissão de NFS-e )
                  </small>
                </div>
                <img
                  src={`${public_folder}/assets/img/Group 87.png`}
                  alt="logo"
                  className="d-inline-block align-top ml-1 mr-1"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

InvoiceIssuingPaywall.propTypes = {
  onShowActivateForm: PropTypes.func.isRequired,
};

export default InvoiceIssuingPaywall;
