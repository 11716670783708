import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  IoBookmark,
  IoCalendarOutline,
  IoNotificationsOutline,
} from 'react-icons/io5';

import {
  StyledButton,
  StyledDropdownMenu,
  StyledDropdownItem,
  StyledDropdown,
  StyledBadge,
  AlertsContainer,
  NotificationsContainer,
} from './styles';

function TransactionsOverview({ transactionsOverview }) {
  const past_due = useMemo(
    () => (Number(transactionsOverview.past_due)), [transactionsOverview],
  );

  const uncategorized = useMemo(
    () => (Number(transactionsOverview.uncategorized)), [transactionsOverview],
  );

  const renderPastDue = useCallback(() => {
    if (!past_due) {
      return null;
    }

    if (past_due > 1) {
      return (
        <StyledDropdownItem>
          <span className="mr-2">
            <IoCalendarOutline size="1.4em" />
          </span>
          <span>
            Você possui <b>{past_due} despesas em atraso</b>,
            estas despesas podem gerar juros e prejudicar o seu fechamento.
          </span>
        </StyledDropdownItem>
      );
    }

    return (
      <StyledDropdownItem>
        <span className="mr-2">
          <IoCalendarOutline size="1.4em" />
        </span>
        <span>
          Você possui <b>1 despesa em atraso</b>,
          esta despesa pode gerar juros e prejudicar o seu fechamento.
        </span>
      </StyledDropdownItem>
    );
  }, [past_due]);

  const renderUncategorized = useCallback(() => {
    if (!uncategorized) {
      return null;
    }

    if (uncategorized > 1) {
      return (
        <StyledDropdownItem className="mt-3">
          <span className="mr-2">
            <IoBookmark size="1.4em" />
          </span>
          <span>
            Você possui <b>{uncategorized} movimentações que não foram categorizadas</b> ainda.
          </span>
        </StyledDropdownItem>
      );
    }

    return (
      <StyledDropdownItem className="mt-3">
        <span className="mr-2">
          <IoBookmark size="1.4em" />
        </span>
        <span>
          Você possui <b>{uncategorized} movimentação que não foi categorizada</b> ainda.
        </span>
      </StyledDropdownItem>
    );
  }, [uncategorized]);

  const renderEmptyAlertMessage = useCallback(() => {
    if (past_due || uncategorized) {
      return null;
    }

    return (
      <StyledDropdownItem disabled className="mt-3">
        <span>
          Nenhum alerta a ser exibido.
        </span>
      </StyledDropdownItem>
    );
  }, [past_due, uncategorized]);

  const renderNotifications = useCallback(() => (
    <StyledDropdownItem disabled className="mt-3">
      <span>
        Nenhuma notificação encontrada.
      </span>
    </StyledDropdownItem>
  ), []);

  const renderBadge = useCallback(() => {
    if (!past_due && !uncategorized) {
      return null;
    }

    return <StyledBadge />;
  }, [past_due, uncategorized]);

  return (
    <StyledDropdown className="mr-2" alignRight>
      <StyledButton variant="link" as={Dropdown.Toggle}>
        <IoNotificationsOutline size="1.6em" />
        {renderBadge()}
      </StyledButton>
      <StyledDropdownMenu>
        <h4 className="mb-3">Alertas</h4>
        <AlertsContainer>
          {renderPastDue()}
          {renderUncategorized()}
          {renderEmptyAlertMessage()}
        </AlertsContainer>
        <h4 className="mb-3 mt-3">Notificações</h4>
        <NotificationsContainer>
          {renderNotifications()}
        </NotificationsContainer>
      </StyledDropdownMenu>
    </StyledDropdown>
  );
}

TransactionsOverview.defaultProps = {
  transactionsOverview: {
    past_due: 0,
    uncategorized: 0,
  },
};

TransactionsOverview.propTypes = {
  transactionsOverview: PropTypes.object,
};

export default TransactionsOverview;
