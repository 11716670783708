import * as yup from 'yup';
import { ptShort } from 'yup-locale-pt';

yup.setLocale(ptShort);

const CreateFinancialCompanySchema = yup.object().shape({
  company_name: yup
    .string()
    .max(255)
    .required('O campo Nome da Empresa é obrigatório')
    .nullable(),
  trading_name: yup
    .string()
    .max(255)
    .required('O campo Nome Fantasia é obrigatório')
    .nullable(),
  address_state_ibge: yup
    .number()
    .required('O campo Estado é obrigatório')
    .nullable(),
  address_state: yup
    .string()
    .required('O campo Estado é obrigatório')
    .nullable(),
  address_city_ibge: yup
    .number()
    .required('O campo Cidade é obrigatório')
    .nullable(),
  employees_count: yup
    .mixed()
    .oneOf(['NONE', 'ONE_TO_THREE', 'MORE_THAN_THREE'])
    .nullable(),
  balance_type: yup.mixed().oneOf(['POSITIVO', 'NEGATIVO', 'ZERADO']),
  address_city: yup
    .string()
    .max(255)
    .required('O campo Cidade é obrigatório')
    .nullable(),
  initial_balance: yup.number().nullable(),
});

export default CreateFinancialCompanySchema;
