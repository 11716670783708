import constants from '../../_constants/mei.constants';
import service from '../../../services/mei.service';

const createMei = (values, callback) => {
  return (dispatch) => {
    dispatch(request(values));

    service.createMei(values).then(
      (response) => {
        dispatch(success(response.data));

        if (callback) {
          callback(response.data);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(data) {
    return {
      type: constants.CREATE_MEI_REQUEST,
      payload: data,
    };
  }
  function success(data) {
    return {
      type: constants.CREATE_MEI_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.CREATE_MEI_FAILURE,
      payload: error,
    };
  }
};

export default createMei;
