import React from 'react';
import PropTypes from 'prop-types';

import { LoadOverlay } from './styles';

function LoadingOverlay({ active, children }) {
  return (
    <LoadOverlay
      active={active}
      spinner
      text="Carregando..."
      classNamePrefix="LoadingOverlay"
    >
      {children}
    </LoadOverlay>
  );
}

LoadingOverlay.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default LoadingOverlay;
