import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import {
  StyledLabel,
  StyledInput,
  StyledAvatar,
  EditIcon,
  AvatarWrapper,
} from './styles';

export default function AvatarUpload({
  avatar_url,
  user_id,
  onAvatarUpload,
  onAvatarDelete,
  onFetchUser,
}) {
  const handleFilesChanged = useCallback((e) => {
    if (isEmpty(e)) {
      return;
    }

    const file = e.target.files[0];

    onAvatarUpload(file, () => {
      onFetchUser();
    });
  }, [onAvatarUpload, onFetchUser]);

  const handleDeleteAvatar = useCallback(() => {
    onAvatarDelete(user_id);
  }, [onAvatarDelete, user_id]);

  const renderAvatar = useCallback(() => {
    if (avatar_url) {
      return (
        <div className="row justify-content-center mb-2">
          <StyledLabel htmlFor={`inputTag_${user_id}`}>
            <div
              role="button"
              tabIndex={0}
              onKeyPress
              onClick={handleDeleteAvatar}
            >
              <AvatarWrapper>
                <EditIcon src="assets/img/delete-avatar.svg" />
                <StyledAvatar
                  src={avatar_url || 'assets/img/default-avatar.svg'}
                  alt="user-avatar"
                />
              </AvatarWrapper>
            </div>
          </StyledLabel>
        </div>
      );
    }

    return (
      <div className="row justify-content-center mb-2">
        <StyledLabel htmlFor={`inputTag_${user_id}`}>
          <AvatarWrapper>
            <EditIcon src="assets/img/edit-avatar.svg" />
            <StyledAvatar
              src="assets/img/default-avatar.svg"
              alt="user-avatar"
            />
          </AvatarWrapper>
          <StyledInput
            onChange={handleFilesChanged}
            accept=".png, .jpg, .jpeg"
            maxFiles={1}
            id={`inputTag_${user_id}`}
            type="file"
          />
        </StyledLabel>
      </div>
    );
  }, [handleFilesChanged, handleDeleteAvatar, avatar_url, user_id]);

  return (
    <>
      {renderAvatar()}
    </>
  );
}

AvatarUpload.propTypes = {
  user_id: PropTypes.string.isRequired,
  avatar_url: PropTypes.string,
  onFetchUser: PropTypes.func,
  onAvatarUpload: PropTypes.func,
  onAvatarDelete: PropTypes.func,
};
