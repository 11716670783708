import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';

import FormCompaniesSelectField from './FormCompaniesSelectField';

const mapStateToProps = (state) => ({
  isLoading: state.company.isLoadingCompaniesForSelect,
  companies: state.company.companiesForSelect,
});

const mapDispatchToProps = {
  onFetchCompanies: companyActionCreators.fetchCompaniesForSelect,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormCompaniesSelectField);
