import styled from 'styled-components';

import { Separator, Submenu } from 'react-contexify';
import { DatePicker, ContextMenu } from '_components/_core';
import { ListGroup } from 'react-bootstrap';

export const StyledVariableGroups = styled(ListGroup)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;

  div.list-group-item  {
    border-radius: 4px;
  }

  div.list-group-item:hover {
    cursor: pointer;
    background-color: #f1f1fa;
    color: var(--text-primary);
  }

  div.list-group-item.active {
    background-color: #f1f1fa;
    color: var(--text-primary);
  }
`;

export const StyledContextMenu = styled(ContextMenu)`
  max-height: 500px;
  overflow-y: auto;

  small {
    text-transform: uppercase;
    font-weight: 500;
  }
`;

export const ItemsContainer = styled.div`
`;

export const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 11px;
`;

export const StyledSeparator = styled(Separator)`
  
`;

export const StyledSubmenu = styled(Submenu)`
  
`;

export const StyledDatePicker = styled(DatePicker)`

`;

export const StyledVariableList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: 400;

  li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4px 12px;

    :hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }
`;

export const StyledListGroup = styled(ListGroup)`
  font-size: 14px;
  max-height: auto;
  overflow-y: auto;
`;

export const StyledListGroupItem = styled(ListGroup.Item)`
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 12px;
  
  strong {
    font-weight: 600;
  }

  small {
    font-size: 10px;
  }

  :hover {
    cursor: pointer;
    background-color: #f1f1fa;
  }
`;
