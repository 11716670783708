import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';
import alertActionCreators from '../alert.actions';

const deleteUser = (id, callback) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Excluir Usuário',
      message: 'Tem certeza que deseja excluir este usuário? <br /> Ele não poderá acessar o Zenply.',
      confirmButtonText: 'Sim, excluir!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.deleteUser(id).then(
          (response) => {
            dispatch(success(response.data));
            dispatch(alertActionCreators.success('Usuário excluído com sucesso!'));

            if (callback) {
              callback();
            }
          },
        ).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request() {
    return {
      type: constants.USER_DELETE_REQUEST,
      payload: { },
    };
  }
  function success(users) {
    return {
      type: constants.USER_DELETE_SUCCESS,
      payload: users,
    };
  }
  function failure(error) {
    return {
      type: constants.USER_DELETE_FAILURE,
      payload: error,
    };
  }
};

export default deleteUser;
