import React from 'react';

import { ResponsiveContainer, Container } from './styles';

import Header from './components/Header';
import Body from './components/Body';

export default function TablePlaceholder() {
  return (
    <ResponsiveContainer className="table-responsive">
      <Container className="table table-striped table-hover">
        <Header />
        <Body />
      </Container>
    </ResponsiveContainer>
  );
}
