import constants from '../../_constants/employees.constants';
import service from '../../../services/employees.service';

const getAllEmployees = (params = {}, company_id) => {
  return (dispatch) => {
    dispatch(request());
    const {
      sorting = {},
      filters = {},
      pagination = {},
    } = params;

    const parsedParams = {
      ...filters,
    };

    if (sorting && sorting.field) {
      parsedParams.order_by = `${sorting.field}${sorting.order === 'desc' ? '-' : ''}`;
    }

    if (pagination) {
      const { currentPage, itemsPerPage } = pagination;

      parsedParams.page = currentPage;
      parsedParams.limit = itemsPerPage;
    }

    service.getAll(parsedParams, company_id).then(
      (response) => {
        const { total, data } = response.data;

        dispatch(success({ total, data }));
      },
    );
  };

  function request() {
    return {
      type: constants.EMPLOYEES_GET_ALL_REQUEST,
      payload: { },
    };
  }
  function success(invoices) {
    return {
      type: constants.EMPLOYEES_GET_ALL_SUCCESS,
      payload: invoices,
    };
  }
};

export default getAllEmployees;
