import HttpService from './http.service';

export default {
  getAll,
  getById,
  insert,
  update,
  remove,
};

async function getAll() {
  return HttpService.get('/associates');
}

async function getById(id) {
  return HttpService.get(`/associates/${id}`);
}

async function insert(associate) {
  return HttpService.post('/associates', associate);
}

async function update(id, associate) {
  return HttpService.put(`/associates/${id}`, associate);
}

async function remove(id) {
  return HttpService.delete(`/associates/${id}`);
}
