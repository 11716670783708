import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CreatableSelectModal } from '_components/_shared';

import { isEmpty } from 'lodash';
import { StyledCreatableSelect } from './styles';

const defaultStyles = {
  option: (provided, state) => ({
    ...provided,
    fontWeight: 500,
    fontSize: state.isHover ? '13px' : '13px',
  }),
  control: (provided) => ({
    ...provided,
    fontWeight: 500,
    borderColor: '#d5dcec',
    fontSize: '13px',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

function Select({
  options,
  creatable,
  name,
  customStyles,
  noOptionsMessage,
  placeholder,
  disabled,
  onCreateRecipient,
  onCreateCategory,
  onCreateBankAccount,
  onCreateCostCenter,
  onCreateTag,
  onCreateCallback,
  metadata,
  innerRef,
  menuPlacement,
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInputValue, setUserInputValue] = useState('');

  const handleCreate = (userInput) => {
    if (isEmpty(userInput)) {
      return;
    }

    switch (creatable) {
      case 'recipient': {
        setIsLoading(true);

        const params = {
          name: userInput,
          ...metadata,
        };

        onCreateRecipient(params, (created_recipient) => {
          setIsLoading(false);

          if (onCreateCallback) {
            onCreateCallback(created_recipient);
          }
        });
        break;
      }

      case 'category': {
        setIsLoading(true);

        const params = {
          description: userInput,
          ...metadata,
        };

        onCreateCategory(params, (created_category) => {
          setIsLoading(false);

          if (onCreateCallback) {
            onCreateCallback(created_category);
          }
        });
        break;
      }

      case 'bank_account': {
        setIsLoading(true);

        const params = {
          account_type: 'OTHER',
          description: userInput,
          initial_balance: 0,
          is_principal: false,
        };

        onCreateBankAccount(params, (created_account) => {
          setIsLoading(false);

          if (onCreateCallback) {
            onCreateCallback(created_account);
          }
        });
        break;
      }

      case 'cost_center': {
        setIsLoading(true);

        const params = {
          description: userInput,
        };

        onCreateCostCenter(params, (created) => {
          setIsLoading(false);

          if (onCreateCallback) {
            onCreateCallback(created);
          }
        });
        break;
      }
      case 'tag': {
        setIsLoading(true);

        const params = {
          description: userInput,
        };

        onCreateTag(params, (created) => {
          setIsLoading(false);

          if (onCreateCallback) {
            onCreateCallback(created);
          }
        });
        break;
      }
      default: {
        setIsOpen(true);
        setUserInputValue(userInput);
        break;
      }
    }
  };

  return (
    <>
      <CreatableSelectModal
        isVisible={isOpen}
        creatable={creatable}
        userInputValue={userInputValue}
        onModalToggle={() => setIsOpen(!isOpen)}
      />
      <StyledCreatableSelect
        menuPlacement={menuPlacement}
        styles={{
          ...defaultStyles,
          ...customStyles,
        }}
        placeholder={placeholder}
        name={name}
        options={options}
        creatable={creatable}
        isDisabled={disabled}
        onCreateOption={handleCreate}
        noOptionsMessage={noOptionsMessage}
        formatCreateLabel={(userInput) => `Cadastrar "${userInput}"`}
        ref={innerRef}
        {...rest}
        isLoading={isLoading}
        loadingMessage={() => 'Carregando...'}
      />
    </>
  );
}

Select.defaultProps = {
  placeholder: 'Selecione...',
  options: [],
  customStyles: {},
  disabled: false,
  noOptionsMessage: () => 'Nenhuma opção encontrada',
  metadata: {},
  innerRef: null,
  onCreateCallback: null,
  menuPlacement: 'auto',
};

Select.propTypes = {
  options: PropTypes.array,
  creatable: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  customStyles: PropTypes.object,
  noOptionsMessage: PropTypes.func,
  onCreateRecipient: PropTypes.func,
  onCreateCategory: PropTypes.func,
  onCreateBankAccount: PropTypes.func,
  onCreateCostCenter: PropTypes.func,
  onCreateTag: PropTypes.func,
  metadata: PropTypes.object,
  innerRef: PropTypes.object,
  onCreateCallback: PropTypes.func,
  menuPlacement: PropTypes.string,
};

export default Select;
