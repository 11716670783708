import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Card, CardBody } from '_components/_core';
import { EmptyIcon } from '_components/_shared';

function EmptyMessage({ title, description, action }) {
  return (
    <Card>
      <CardBody className="text-center mt-4 mb-3">
        <EmptyIcon size="large" />
        <p className="display-5 mx-auto">
          {title}
          <br />
          {description && description.split('<br />').map((item, index) => (
            <small
              className={classNames('text-muted d-block w-50 ml-auto mr-auto font-weight-light', index === 0 ? 'mt-2' : '')}
            >
              {item}
            </small>
          ))}
        </p>
        {action && action}
      </CardBody>
    </Card>
  );
}

EmptyMessage.defaultProps = {
  title: 'Nenhum registro encontrado',
  description: null,
  action: null,
};

EmptyMessage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  action: PropTypes.node,
};

export default EmptyMessage;
