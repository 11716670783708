import styled, { css } from 'styled-components';
import { darken } from 'polished';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { Table, Button } from '_components/_core';

export const StyledTable = styled(Table)`
  &&& {
    thead tr th {
      padding: 15px 15px !important;
    }
    tbody tr td {
      padding: 12px 15px !important;
    }
    tfoot tr td {
      padding: 15px 15px !important;
    }

    tbody tr:hover {
      td {
        background-color: rgba(133, 123, 255, 0.3);
      }
    }

  }
`;

export const DarfTable = styled.table`  
  &&& {

    thead th {
      font-size: 13px;
      line-height: 13px;
      font-weight: bold;
      padding: 10px 15px !important;
      position: sticky; 
      top: 0; 
      z-index: 1;
      background-color: var(--th-bg-color);

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #d5dcec;
        box-shadow: inset 0px 25px 1px -10px #d5dcec;
      }
    }

    tbody {
      background-color: var(--th-bg-color);
    }

    tbody tr td {
      font-size: 13px;
      line-height: 13px;
      font-weight: normal;
      padding: 10px 15px !important;
    }

    tbody tr td span.employee-name {
      font-size: 14px;
      font-weight: bold;
      display: block;
    }

    tbody tr td span.event-type {
      display: block;
      margin-top: 8px;
      font-size: 12px;
      color: #8c8c8c !important;
    }

    tfoot tr th {
      font-size: 13px;
      line-height: 13px;
      font-weight: bold;
      padding: 10px 15px !important;
      position: sticky; 
      top: 0; 
      z-index: 1;
      background-color: var(--th-bg-color); 


      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--th-border-color);
        box-shadow: inset 0px 25px 1px -10px #d5dcec;
      }
    }
  }

`;

export const StyledButtonGroup = styled(ButtonGroup)`
  border: none;
`;

export const StyledButton = styled(Button)`
  background-color: #FFF;
  border: none;
  padding: 12px;

  &:hover {
    background-color: ${darken(0.05, '#FFF')};
  }
  &:active {
    color: #727cf5;
    background-color: ${darken(0.05, '#FFF')} !important;
  }
  &:disabled {
    color: #727cf5 !important;
    background-color: ${darken(0.05, '#FFF')} !important;
    cursor: not-allowed;
  }

  ${({ special }) => special && css`
    color: #727cf5;
    cursor: default !important;

    &:hover {
      color: #727cf5 !important;
      background-color: ${darken(0.05, '#FFF')} !important;
    }
    &:active {
      color: #727cf5 !important;
      background-color: ${darken(0.05, '#FFF')} !important;
    }
  `}
`;
