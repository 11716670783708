import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaExclamationTriangle, FaTimes } from 'react-icons/fa';

import { CloseContainer, OfflineBannerContainer, Overlay } from './styles';

export default function InternetConnection({ withPaddingTop }) {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [forceClose, setForceClose] = useState(false);

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);

      setForceClose(false);
    };
  }, []);

  if (isOnline) {
    return null;
  }

  if (forceClose) {
    return null;
  }

  return (
    <>
      <OfflineBannerContainer withPaddingTop={withPaddingTop}>
        <div>
          &nbsp;
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <FaExclamationTriangle className="mr-2" />
          <p className="m-0 p-0">
            Você não está conectado à internet!
          </p>
        </div>
        <CloseContainer className="mr-3" onClick={() => setForceClose(true)}>
          <FaTimes />
        </CloseContainer>
      </OfflineBannerContainer>
      <Overlay />
    </>
  );
}

InternetConnection.defaultProps = {
  withPaddingTop: false,
};

InternetConnection.propTypes = {
  withPaddingTop: PropTypes.bool,
};
