import uniqueId from 'lodash/uniqueId';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const createTransaction = (values, callback) => {
  return async (dispatch) => {
    const tempId = uniqueId();

    dispatch(request(tempId, values));

    service.insert(values).then(
      (response) => {
        const { data } = response;

        dispatch(success({
          tempId,
          data,
        }));

        if (callback) {
          callback(data);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(id, values) {
    return {
      type: constants.CREATE_COMPANY_TRANSACTION_REQUEST,
      payload: {
        id,
        values,
      },
    };
  }
  function success(payload) {
    return {
      type: constants.CREATE_COMPANY_TRANSACTION_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return {
      type: constants.CREATE_COMPANY_TRANSACTION_FAILURE,
      payload: error,
    };
  }
};

export default createTransaction;
