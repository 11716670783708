import * as Yup from 'yup';
import { validateCNPJ, validateCPF } from 'validations-br';
import { ptShort } from 'yup-locale-pt';

import {
  REQUIRED,
} from 'helpers/validation-messages';

Yup.setLocale(ptShort);

const signUpFormSchema = Yup.object().shape({
  first_name: Yup.string().min(3).max(255).required(),
  last_name: Yup.string().min(1).max(255).required(),
  document_type: Yup.mixed().oneOf(['PF', 'PJ']).required(),
  document_number: Yup.string().when('document_type', {
    is: 'PF',
    then: Yup.string().test('is-cpf', 'CPF inválido', (value) => validateCPF(value)),
    otherwise: Yup.string().test('is-cnpj', 'CNPJ inválido', (value) => validateCNPJ(value)),
  }),
  email: Yup.string().max(255).email().required(),
  email_confirmation: Yup.string().max(255).email().oneOf([Yup.ref('email')], 'Os e-mails não conferem')
    .required(),
  password: Yup.string().max(60).required(),
  password_confirmation: Yup.string().oneOf([Yup.ref('password')], 'As senhas não conferem').required(),
  address_zip_code: Yup.string().max(255).required(REQUIRED).nullable(true),
  address_street: Yup.string().max(255).required(REQUIRED).nullable(true),
  address_number: Yup.string().max(255).required(REQUIRED).nullable(true),
  address_district: Yup.string().max(255).required(REQUIRED).nullable(true),
  address_complement: Yup.string().max(255).nullable(true),
  address_city: Yup.string().max(255).required(REQUIRED).nullable(true),
  address_state: Yup.string().max(2).required(REQUIRED).nullable(true),
  address_city_ibge: Yup.number().nullable(true).required(REQUIRED),
  address_state_ibge: Yup.number().nullable(true).required(REQUIRED),
});

const zenpaperSignUpFormSchema = Yup.object().shape({
  first_name: Yup.string().min(3).max(255).required(),
  last_name: Yup.string().min(1).max(255).required(),
  document_type: Yup.mixed().oneOf(['PF', 'PJ']).required(),
  document_number: Yup.string().when('document_type', {
    is: 'PF',
    then: Yup.string().test('is-cpf', 'CPF inválido', (value) => validateCPF(value)),
    otherwise: Yup.string().test('is-cnpj', 'CNPJ inválido', (value) => validateCNPJ(value)),
  }),
  email: Yup.string().max(255).email().required(),
  password: Yup.string().max(60).required(),
  market_segment_id: Yup.string().required('Campo obrigatório').nullable(true),
  address_city: Yup.string().max(255).required('Campo obrigatório').nullable(true),
  address_state: Yup.string().max(2).required('Campo obrigatório').nullable(true),
  address_city_ibge: Yup.number().nullable(true).required('Campo obrigatório'),
  address_state_ibge: Yup.number().nullable(true).required('Campo obrigatório'),
  accepted_terms: Yup.boolean().oneOf([true]).required(),
});

export { signUpFormSchema, zenpaperSignUpFormSchema };
