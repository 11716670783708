import { connect } from 'react-redux';

import companyActionCreators from '../../../_store/_actions/company';

import ActivePeriod from './ActivePeriod';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  isLoading: state.company.isLoading,
});

const mapDispatchToProps = {
  onSetActivePeriod: companyActionCreators.setActivePeriod,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivePeriod);
