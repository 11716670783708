import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { IoArrowBackOutline, IoArrowForwardOutline } from 'react-icons/io5';
import { DateTime } from 'luxon';

import {
  Card,
  CardHeader,
  CardBody,
  Button,
  FormCurrencyField,
  CardFooter,
} from '_components/_core';

function PastRevenues({
  company,
  isLoading,
  onSaveRevenues,
  onFetchPastRevenue,
  revenue,
}) {
  const [selectedDate, setSelectedDate] = useState(DateTime.now());

  const handleYearIncreased = useCallback(() => {
    setSelectedDate((previousDate) => previousDate.plus({ year: 1 }));
  }, []);

  const handleYearDecreased = useCallback(() => {
    setSelectedDate((previousDate) => previousDate.minus({ year: 1 }));
  }, []);

  useEffect(() => {
    const { id: company_id } = company || {};
    if (!company_id) {
      return;
    }

    const year = Number(selectedDate.toFormat('yyyy'));

    onFetchPastRevenue(company_id, year);
  }, [company, selectedDate, onFetchPastRevenue]);

  const handleSubmitManualRevenue = useCallback((values) => {
    const { id: company_id } = company || {};
    onSaveRevenues({
      year: Number(selectedDate.toFormat('yyyy')),
      revenue: {
        ...values,
      },
      company_id,
    });
  }, [onSaveRevenues, company, selectedDate]);

  return (
    <>
      <Row>
        <Col xs={12} sm={12} lg={12}>
          <Card className="h-100">
            <CardHeader
              title="Receitas Anteriores"
              description="Preencha as receitas anteriores"
              sideContent={(
                <ButtonGroup>
                  <Button variant="secondary" onClick={handleYearDecreased}>
                    <IoArrowBackOutline />
                  </Button>
                  <Button variant="secondary">
                    <b>
                      {selectedDate.toFormat('yyyy')}
                    </b>
                  </Button>
                  <Button variant="secondary" onClick={handleYearIncreased}>
                    <IoArrowForwardOutline />
                  </Button>
                </ButtonGroup>
              )}
            />
            <Formik
              initialValues={revenue || {}}
              onSubmit={handleSubmitManualRevenue}
              enableReinitialize
            >
              {({
                handleSubmit,
                values,
              }) => (
                <>
                  <CardBody>
                    <Form onSubmit={handleSubmit}>
                      <Form.Row>
                        {Object.keys(values).map((key) => (
                          <>
                            <Form.Group key={key} as={Col} md="2" style={{ padding: 0 }}>
                              <Form.Label md="12" style={{ paddingLeft: 12.5 }}>
                                {key}
                              </Form.Label>
                              <Form.Group as={Col} md="12">
                                <Form.Label>
                                  Mercado Interno
                                </Form.Label>
                                <FormCurrencyField
                                  name={`${key}.internal_market`}
                                  placeholder="Valor de Mercado Interno"
                                />
                              </Form.Group>
                              <Form.Group as={Col} md="12">
                                <Form.Label>
                                  Mercado Externo
                                </Form.Label>
                                <FormCurrencyField
                                  name={`${key}.external_market`}
                                  placeholder="Valor de Mercado Externo"
                                />
                              </Form.Group>
                            </Form.Group>
                          </>
                        ))}
                      </Form.Row>
                    </Form>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col>
                        <Button isLoading={isLoading} disabled={isLoading} onClick={handleSubmit}>
                          Confirmar Alterações
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </>
              )}
            </Formik>
          </Card>
        </Col>
      </Row>
    </>
  );
}

PastRevenues.propTypes = {
  isLoading: PropTypes.bool,
  company: PropTypes.object,
  revenue: PropTypes.object,
  onFetchPastRevenue: PropTypes.func.isRequired,
  onSaveRevenues: PropTypes.func.isRequired,
};

export default PastRevenues;
