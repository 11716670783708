import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

function SingleOptionFilter({
  name,
  label,
  value,
  onChange,
  options,
}) {
  const valueLabelMap = useMemo(() => {
    const map = {};

    options.forEach((option) => {
      map[option.value] = option.label;
    });

    return map;
  }, [options]);

  const handleClick = useCallback((value) => {
    onChange({
      target: {
        name,
        value,
      },
    });
  }, [name, onChange]);

  return (
    <DropdownButton
      title={`${label}: ${valueLabelMap[value]}`}
      size="sm"
      variant={value === null ? 'outline-secondary' : 'secondary'}
      className="mr-3"
      as={ButtonGroup}
    >
      {options.map(({ label, value: itemValue }) => (
        <Dropdown.Item active={value === itemValue} key={`single-option-filter-${itemValue}`} onClick={() => handleClick(itemValue)}>
          {label}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

SingleOptionFilter.defaultProps = {
  value: null,
  options: [],
};

SingleOptionFilter.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
    }),
  ),
  onChange: PropTypes.func.isRequired,
};

export default SingleOptionFilter;
