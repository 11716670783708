import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';

import { Button } from '_components/_core';
import { Dropdown } from 'react-bootstrap';

export const Trigger = styled(Button)`
  background-color: var(--input-bg-color);
  width: 100%;

  ${({ variant }) => variant === 'default' && css`
    padding: 16px;
    display: flex;
    border-radius: 4px;
    background-color: var(--card-bg-color);
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                rgb(0 0 0 / 10%) 0px 1px 3px 0px, 
                rgb(0 0 0 / 6%) 0px 1px 2px 0px;
    

    &:focus, &:active, &:hover {
      box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px,
                  rgb(0 0 0 / 0%) 0px 0px 0px 0px,
                  rgb(0 0 0 / 10%) 0px 1px 3px 0px,
                  rgb(0 0 0 / 6%) 0px 1px 2px 0px;
    }

    &:hover {
      background-color: #E3E5E8;
    }

  `}

  ${({ variant }) => variant === 'input' && css`
    line-height: 20px;
    font-weight: 400;
    border: var(--form-control-border);
    border-radius: 4px;  
    text-align: left;

    &:focus, &:active {
      color: var(--form-control-focus-color);
      background-color: var(--form-control-background-color);
      border-color: var(--form-control-border-color);
    }  
  `}

  ${({ variant }) => variant === 'transactions-page' && css`
    display: block;
    background-color: transparent;
    padding-left: 0;

    span {
      margin-top: 12px !important;
    }

    &:hover {
      color: #00ab6f;
      font-weight: 600;
      transition: none;
    }
  `}
  
`;

export const StyledTriggerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  div:first-child {
    display: flex;
    flex-direction: column;
  }

  div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  span {
    font-size: 22px;
    font-weight: 600;
    margin-top: 8px;
  }

  ${({ variant }) => variant === 'transactions-page' && css`
    justify-content: flex-start;

    svg {
      margin-left: 16px;
    }
  `}
`;

export const LineItem = styled(Dropdown.Item)`
  font-size: 14px;
  font-weight: 400;
  padding: 0.5rem 1.2rem;
  width: 100%;  

  ${({ active }) => active && css`
    background-color: transparent !important;
    color: var(--color-text-primary) !important
    font-weight: 700 !important;

    &:hover {
      color: #16181b !important;
      text-decoration: none !important;
      background-color: #f8f9fa !important;
    }
  `}
`;

export const Checkbox = styled(Form.Check)`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-check-input {
    margin-top: 0;
    margin-left: 0;

    width: 15px !important;
    height: 15px !important;

    &:hover {
      cursor: pointer !important;
    }
  }
`;

export const Content = styled(Dropdown.Menu)`
  ${({ variant }) => variant === 'default' && css`
    width: 90%;
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                rgb(0 0 0 / 10%) 0px 1px 3px 0px, 
                rgb(0 0 0 / 6%) 0px 1px 2px 0px;
  `}
`;

export const BalanceContainer = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;
