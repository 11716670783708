import React from 'react';
import PropTypes from 'prop-types';

import { StyledSelect } from './styles';

function CustomSelect({
  value,
  options,
  onChange,
  placeholder,
  className,
  customStyles,
  noOptionsMessage,
  disabled,
  innerRef,
  menuPlacement,
  ...restProps
}) {
  return (
    <StyledSelect
      menuPlacement={menuPlacement}
      styles={{
        ...defaultStyles,
        ...customStyles,
      }}
      placeholder={placeholder}
      className={className}
      value={value}
      onChange={onChange}
      options={options}
      isDisabled={disabled}
      noOptionsMessage={noOptionsMessage}
      ref={innerRef}
      {...restProps}
    />
  );
}

const defaultStyles = {
  option: (provided) => ({
    ...provided,
    fontWeight: 500,
    cursor: 'pointer',
    fontSize: '13px',
  }),
  control: (provided) => ({
    ...provided,
    fontWeight: 500,
    borderColor: '#d5dcec',
    cursor: 'pointer !important',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

CustomSelect.defaultProps = {
  placeholder: 'Selecione...',
  className: null,
  customStyles: {},
  disabled: false,
  noOptionsMessage: () => 'Nenhuma opção encontrada',
  innerRef: null,
  menuPlacement: 'auto',
};

CustomSelect.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  customStyles: PropTypes.object,
  noOptionsMessage: PropTypes.func,
  innerRef: PropTypes.object,
  menuPlacement: PropTypes.string,
};

export default CustomSelect;
