import constants from '../../_constants/ledger.constants';
import service from '../../../services/ledger.service';

const generateLedger = (filters) => {
  return (dispatch) => {
    dispatch(request(filters));

    service.generate(filters).then(
      (response) => {
        dispatch(success(response.data));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(filters) {
    return {
      type: constants.LEDGER_GET_REQUEST,
      payload: { filters },
    };
  }
  function success(ledger) {
    return {
      type: constants.LEDGER_GET_SUCCESS,
      payload: { ledger },
    };
  }
  function failure(ledger) {
    return {
      type: constants.LEDGER_GET_FAILURE,
      payload: { ledger },
    };
  }
};

export default generateLedger;
