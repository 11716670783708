import { connect } from 'react-redux';

import sidebarActionCreators from '_store/_actions/sidebar';

import Sidebar from './Sidebar';

const mapStateToProps = (state) => ({
  collapsed: state.sidebar.collapsed,
  product: state.auth.product,
});

const mapDispatchToProps = {
  onSidebarToggle: sidebarActionCreators.toggleSidebar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Sidebar);
