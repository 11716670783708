import getIrrfTables from './getIrrfTables';
import getInssTables from './getInssTables';
import getChildBenefitTables from './getChildBenefitTables';
import getMinimumWageTables from './getMinimumWageTables';
import getManuallyInsertedPayroll from './getManuallyInsertedPayroll';
import saveManualPayroll from './saveManualPayroll';
import generatePayrollEntries from './generatePayrollEntries';
import getPayrolls from './getPayrolls';
import generatePayroll from './generatePayroll';
import deletePayoll from './deletePayoll';
import sendSalaryReceiptMail from './sendSalaryReceiptMail';

export default {
  getIrrfTables,
  getInssTables,
  getChildBenefitTables,
  getMinimumWageTables,
  getManuallyInsertedPayroll,
  saveManualPayroll,
  generatePayrollEntries,
  getPayrolls,
  generatePayroll,
  deletePayoll,
  sendSalaryReceiptMail,
};
