import HttpService from './http.service';

export default {
  getAll,
  getById,
  insert,
  update,
  remove,
};

async function getAll(params) {
  return HttpService.get('/accountants', params);
}

async function getById(id) {
  return HttpService.get(`/accountants/${id}`);
}

async function insert(accountant) {
  return HttpService.post('/accountants', accountant);
}

async function update(id, accountant) {
  return HttpService.put(`/accountants/${id}`, accountant);
}

async function remove(id) {
  return HttpService.delete(`/accountants/${id}`);
}
