import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import {
  Checkbox,
  BalanceContainer,
  LineItem,
} from '../../styles';

function CostCenterItem({
  costCenter,
  selectedCostCenterIds,
  variant,
  isMultiple,
  displayPrincipalStar,
  onCostCenterSelected,
}) {
  const {
    id,
    is_principal,
    description,
  } = costCenter || {};

  const isChecked = useCallback((cost_center_id) => {
    if (isEmpty(selectedCostCenterIds)) {
      return false;
    }

    return selectedCostCenterIds.includes(cost_center_id);
  }, [selectedCostCenterIds]);

  const isCostCenterActive = useCallback((cost_center_id) => {
    if (isEmpty(selectedCostCenterIds)) {
      return false;
    }

    return selectedCostCenterIds.includes(cost_center_id);
  }, [selectedCostCenterIds]);

  const handleCheckboxClick = useCallback((e) => {
    e.stopPropagation();

    onCostCenterSelected(id);
  }, [id, onCostCenterSelected]);

  const handleCostCenterSelected = useCallback(() => {
    onCostCenterSelected(id);
  }, [id, onCostCenterSelected]);

  const renderDescription = useCallback(() => {
    if (!description) {
      return null;
    }

    if (!displayPrincipalStar) {
      <span>
        {description}
      </span>;
    }

    let className = '';

    if (variant === 'default' && !is_principal) {
      className = 'ml-4';
    }

    return (
      <span className={className}>
        {description}
      </span>
    );
  }, [description, displayPrincipalStar, is_principal, variant]);

  return (
    <LineItem
      key={id}
      eventKey={id}
      onClick={handleCostCenterSelected}
      className="d-flex justify-content-start align-items-center"
      variant={variant}
      active={isCostCenterActive(id)}
      as="div"
      role="button"
    >
      {isMultiple && (
        <Checkbox
          key={`checkbox-${id}`}
          type="checkbox"
          checked={isChecked(id)}
          onClick={(e) => handleCheckboxClick(e, id)}
        />
      )}
      <BalanceContainer onClick={handleCostCenterSelected} className={isMultiple ? 'ml-3' : ''}>
        <span>
          {renderDescription()}
        </span>
      </BalanceContainer>
    </LineItem>
  );
}

CostCenterItem.defaultProps = {
  isMultiple: true,
  variant: 'default',
  displayPrincipalStar: false,
  selectedCostCenterIds: [],
};

CostCenterItem.propTypes = {
  selectedCostCenterIds: PropTypes.array,
  costCenter: PropTypes.object.isRequired,
  isMultiple: PropTypes.bool,
  displayPrincipalStar: PropTypes.bool,
  variant: PropTypes.string,
  onCostCenterSelected: PropTypes.func.isRequired,
};

export default CostCenterItem;
