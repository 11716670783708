import React from 'react';
import PropTypes from 'prop-types';

import LoadingIcon from '_components/_shared/LoadingIcon';

import { Container, IconContainer } from './styles';

function Button({
  children,
  variant,
  icon,
  isLoading,
  disabled,
  noMargin,
  type,
  iconPosition,
  loadingText,
  ...restProps
}) {
  const renderContent = () => {
    if (isLoading) {
      return <LoadingIcon text={loadingText} />;
    }

    if (icon) {
      return (
        <IconContainer noMargin={noMargin} iconPosition={iconPosition}>
          {iconPosition === 'left' && icon}
          {children}
          {iconPosition === 'right' && icon}
        </IconContainer>
      );
    }

    return children;
  };

  return (
    <Container disabled={isLoading || disabled} type={type} variant={variant} {...restProps}>
      {renderContent()}
    </Container>
  );
}

Button.defaultProps = {
  variant: 'primary',
  type: 'button',
  isLoading: false,
  disabled: false,
  icon: null,
  noMargin: false,
  iconPosition: 'right',
  loadingText: null,
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.node,
  isLoading: PropTypes.bool,
  noMargin: PropTypes.bool,
  disabled: PropTypes.bool,
  iconPosition: PropTypes.string,
  loadingText: PropTypes.string,
};

export default Button;
