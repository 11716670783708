const ITEMS_PER_PAGE = 31;

const paginateResults = (ledger) => {
  const newLedger = {};

  Object.keys(ledger).reduce((acc, curr) => {
    const ledgerItem = ledger[curr];

    const { account, entries } = ledgerItem;

    let page = 1;
    let counter = 1;

    const paginatedEntries = entries.reduce((finalObject, currentItem) => {
      if (finalObject.hasOwnProperty(page)) {
        finalObject[page].push(currentItem);
      } else {
        finalObject[page] = [currentItem];
      }

      counter++;

      if (counter === ITEMS_PER_PAGE) {
        page++;
        counter = 1;
      }

      return finalObject;
    }, {});

    acc[curr] = {
      account,
      entries: paginatedEntries,
    };

    return acc;
  }, newLedger);

  return newLedger;
};

export default paginateResults;
