export const taxesConstants = {
  CALCULATE_TAXES_REQUEST: 'CALCULATE_TAXES_REQUEST',
  CALCULATE_TAXES_SUCCESS: 'CALCULATE_TAXES_SUCCESS',
  CALCULATE_TAXES_FAILURE: 'CALCULATE_TAXES_FAILURE',

  FETCH_TAXES_REQUEST: 'FETCH_TAXES_REQUEST',
  FETCH_TAXES_SUCCESS: 'FETCH_TAXES_SUCCESS',
  FETCH_TAXES_FAILURE: 'FETCH_TAXES_FAILURE',

  CLEAR_TAXES_REQUEST: 'CLEAR_TAXES_REQUEST',

  GENERATE_TAXES_ENTRIES_REQUEST: 'GENERATE_TAXES_ENTRIES_REQUEST',
  GENERATE_TAXES_ENTRIES_SUCCESS: 'GENERATE_TAXES_ENTRIES_SUCCESS',
  GENERATE_TAXES_ENTRIES_FAILURE: 'GENERATE_TAXES_ENTRIES_FAILURE',

  FETCH_DARFS_REQUEST: 'FETCH_DARFS_REQUEST',
  FETCH_DARFS_SUCCESS: 'FETCH_DARFS_SUCCESS',
  FETCH_DARFS_FAILURE: 'FETCH_DARFS_FAILURE',

  FETCH_MANAGER_DARFS_REQUEST: 'FETCH_MANAGER_DARFS_REQUEST',
  FETCH_MANAGER_DARFS_SUCCESS: 'FETCH_MANAGER_DARFS_SUCCESS',
  FETCH_MANAGER_DARFS_FAILURE: 'FETCH_MANAGER_DARFS_FAILURE',

  GENERATE_DARFS_REQUEST: 'GENERATE_DARFS_REQUEST',
  GENERATE_DARFS_SUCCESS: 'GENERATE_DARFS_SUCCESS',
  GENERATE_DARFS_FAILURE: 'GENERATE_DARFS_FAILURE',

  DELETE_DARF_REQUEST: 'DELETE_DARF_REQUEST',
  DELETE_DARF_SUCCESS: 'DELETE_DARF_SUCCESS',
  DELETE_DARF_FAILURE: 'DELETE_DARF_FAILURE',

  DELETE_MULTIPLE_DARFS_REQUEST: 'DELETE_MULTIPLE_DARFS_REQUEST',
  DELETE_MULTIPLE_DARFS_SUCCESS: 'DELETE_MULTIPLE_DARFS_SUCCESS',
  DELETE_MULTIPLE_DARFS_FAILURE: 'DELETE_MULTIPLE_DARFS_FAILURE',
};

export default taxesConstants;
