import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import { Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { ZENPLY_TERMS_URL, ZENPLY_PRIVACY_POLICY_URL } from 'helpers';
import { Button } from '_components/_core';

import LogoZenpaper from '_components/_shared/NavBar/components/LogoZenpaper';
import SignInForm from './components/SignInForm';
import ConfirmEmailModal from './components/ConfirmEmailModal';
import Logo from './components/Logo';

import './styles.scss';

import {
  StyledHeader,
  CustomContainer,
  FormContainer,
  StyledFooter,
  BgContainer,
  StyledText,
  StyledSubtext,
  StyledMobileFooter,
} from './styles';

const SignIn = ({ history, onSignIn, isLoading }) => {
  function getQueryVariable(variable) {
    const query = window.location.search.substring(1);

    const vars = query.split('&');

    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] === variable) {
        return pair[1];
      }
    }

    return null;
  }

  const ZenpaperLogin = () => (
    <>
      <CustomContainer>
        <FormContainer>
          <StyledHeader>
            <p className="mt-3 pt-3">Ainda não tem uma conta?</p>
            <Button
              variant="inverse-dark"
              className="m-3 p-3"
              onClick={() => history.push('/cadastrar')}
            >
              Cadastre-se!
            </Button>
          </StyledHeader>
          <div style={{ pointerEvents: 'none' }}>
            <LogoZenpaper width={200} />
          </div>
          <p className="pt-2 pb-3">Faça login para continuar</p>
          <SignInForm onSignIn={onSignIn} isLoading={isLoading} />
          <StyledFooter>
            <Col>
              <p className="footer-text">
                {`Copyright © Zenply - ${new Date().getFullYear()}`}
              </p>
            </Col>
            <Col>
              <ul className="auth-footer text-gray">
                <li>
                  <a target="_blank" href={ZENPLY_TERMS_URL} rel="noreferrer">Termos de Serviço</a>
                </li>
                <li>
                  <a target="_blank" href={ZENPLY_PRIVACY_POLICY_URL} rel="noreferrer">Política de Privacidade</a>
                </li>
              </ul>
            </Col>
          </StyledFooter>
          <StyledMobileFooter>
            <Col>
              <p className="footer-text">
                {`Copyright © Zenply - ${new Date().getFullYear()}`}
              </p>
              <ul className="auth-footer text-gray">
                <li>
                  <a target="_blank" href={ZENPLY_TERMS_URL} rel="noreferrer">Termos de Serviço</a>
                </li>
                <li>
                  <a target="_blank" href={ZENPLY_PRIVACY_POLICY_URL} rel="noreferrer">Política de Privacidade</a>
                </li>
              </ul>
            </Col>
          </StyledMobileFooter>
        </FormContainer>
        <BgContainer>
          <StyledText>
            Controle financeiro simples e inteligente.
          </StyledText>
          <StyledSubtext>
            Suas finanças em um só lugar.
          </StyledSubtext>
        </BgContainer>
      </CustomContainer>
    </>
  );

  const renderBody = useCallback(() => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    if (product === 'contabills-empresas') {
      return <ZenpaperLogin />;
    }

    return (
      <Container fluid className="p-0">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper auth p-0 theme-two">
            <ConfirmEmailModal isVisible={!isEmpty(getQueryVariable('confirm_email'))} email={getQueryVariable('email')} />
            <div className="row d-flex align-items-stretch">
              <div className="col-12 col-md-8 h-100 bg-white">
                <div className="auto-form-wrapper d-flex align-items-center justify-content-center flex-column">
                  <div className="nav-get-started">
                    <p>Ainda não tem uma conta?</p>
                    {/* <Link className="btn get-started-btn" to="/cadastrar">
                    COMECE AGORA
                  </Link> */}
                  </div>
                  <form>
                    <Logo />
                    <h1 className="mr-auto animated fadeInDown">
                      Acesse sua conta.
                    </h1>
                    <p className="mb-4 mr-auto animated fadeInDown">
                      Digite seu Login e Senha para acessar o Contabills.
                    </p>
                    <SignInForm onSignIn={onSignIn} isLoading={isLoading} />
                    <div className="d-flex flex-column justify-content-start align-items-start pt-2">
                      {/*
                    <Button variant="link" className="ml-0 pl-0 pt-3"
                    onClick={() => history.push('/cadastrar')}>
                      Ainda não tem uma conta? Cadastre-se!
                    </Button>
                    */}
                      <Button variant="link" className="ml-0 pl-0 pt-0" onClick={() => history.push('/recuperar-senha')}>
                        Esqueci minha senha
                      </Button>
                    </div>

                    <div className="wrapper mt-5 text-gray animated fadeInUp">
                      <p className="footer-text">
                        {`Copyright © ${new Date().getFullYear()} Contabills Software. Todos os direitos reservados.`}
                      </p>
                      <ul className="auth-footer text-gray">
                        <li>
                          <a href="termos-condicoes">Termos & Condições</a>
                        </li>
                        <li>
                          <a href="politica-cookies">Política de Cookies</a>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-4 banner-section d-none d-md-flex align-items-stretch justify-content-center pr-0 pl-0">
                <div className="slide-content bg-1" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }, [history, isLoading, onSignIn]);

  return (
    <>
      {renderBody()}
    </>
  );
};

SignIn.propTypes = {
  onSignIn: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(SignIn);
