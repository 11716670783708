import constants from '../../_constants/accounts.constants';
import service from '../../../services/accounts.service';

const confirmDeleteAccount = (id, callback) => {
  return (dispatch) => {
    dispatch(request(id));

    service.remove(id).then(
      () => {
        dispatch(success());
        callback();
      },
    );
  };

  function request(id) {
    return {
      type: constants.ACCOUNT_DELETE_CONFIRM_REQUEST,
      payload: { id },
    };
  }
  function success() {
    return {
      type: constants.ACCOUNT_DELETE_CONFIRM_SUCCESS,
      payload: {},
    };
  }
};

export default confirmDeleteAccount;
