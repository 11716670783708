import React from 'react';
import PropTypes from 'prop-types';
import { IoLogoYoutube } from 'react-icons/io5';

import { StyledTag } from './styles';

function VideoTutorialTag({ text, link }) {
  if (!link) return null;

  return (
    <StyledTag
      variant="danger"
      className="d-flex justify-content-center align-items-center ml-3"
      onClick={() => window.open(link, '_blank')}
    >
      <IoLogoYoutube size="1.5em" className="mr-2" /> {text}
    </StyledTag>
  );
}

VideoTutorialTag.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
};

export default VideoTutorialTag;
