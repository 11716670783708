import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';
import alertActionCreators from '../alert.actions';

const updateUserProfile = (user) => {
  return (dispatch) => {
    dispatch(request(user));

    service.updateUserProfile(user).then(
      (response) => {
        const updatedUser = response.data;

        dispatch(success(updatedUser));
        dispatch(alertActionCreators.success('Perfil atualizado com sucesso!'));
      },
    ).catch(({ response }) => {
      if (response.status === 422) {
        const { message } = response.data;

        dispatch(failure(message));
        dispatch(alertActionCreators.error(message));
      }
    });
  };

  function request(user) {
    return { type: constants.USER_UPDATE_REQUEST, payload: { user } };
  }
  function success(user) {
    return {
      type: constants.USER_UPDATE_SUCCESS,
      payload: { user },
    };
  }
  function failure(message) {
    return {
      type: constants.USER_UPDATE_FAILURE,
      payload: { message },
    };
  }
};

export default updateUserProfile;
