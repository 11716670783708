import constants from '../../_constants/company-transactions.constants';

const clearTransactions = () => {
  return async (dispatch) => {
    dispatch(request());
  };

  function request() {
    return {
      type: constants.CLEAR_TRANSACTIONS_REQUEST,
      payload: {},
    };
  }
};

export default clearTransactions;
