import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from 'react-router-dom';
import { IoArrowForward, IoArrowBack } from 'react-icons/io5';

import {
  Button,
  Card,
  CardBody,
} from '_components/_core';
import { EmptyIcon } from '_components/_shared';

import { StyledMessage } from './styles';

function EmptyMessage() {
  const history = useHistory();

  return (
    <Row>
      <Col lg={12} md={8}>
        <Card>
          <CardBody className="text-center mt-5 mb-5">
            <EmptyIcon size="large" />
            <StyledMessage>Nenhum lançamento encontrado</StyledMessage>
            <div className="mt-4">
              <Button
                variant="outline-primary"
                iconPosition="left"
                icon={<IoArrowBack />}
                className="mr-2"
                onClick={() => history.push('/contabil/extrato-bancario/importar')}
              >
                Nova Importação
              </Button>
              <Button
                variant="outline-success"
                icon={<IoArrowForward />}
                onClick={() => history.push('/contabil/lancamentos/consultar')}
              >
                Ver Lançamentos
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default EmptyMessage;
