import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Checkbox, LineItem, BalanceContainer } from '../../styles';

function SelectAllTagsItem({
  variant,
  isMultiple,
  isAllChecked,
  onSelectAllTags,
}) {
  const handleSelectAll = useCallback(() => {
    onSelectAllTags();
  }, [onSelectAllTags]);

  const handleCheckboxClickAll = useCallback((e) => {
    e.stopPropagation();

    onSelectAllTags();
  }, [onSelectAllTags]);

  const renderDescription = useCallback(() => {
    let className = '';

    if (variant === 'default') {
      className = 'ml-4';
    }

    return (
      <span className={className}>
        Selecionar todos
      </span>
    );
  }, [variant]);

  return (
    <LineItem
      key="all_tags"
      eventKey="all_tags"
      className="d-flex justify-content-start align-items-center mb-0"
      onSelect={handleSelectAll}
      variant={variant}
      active={isAllChecked}
    >
      {isMultiple && (
        <Checkbox
          type="checkbox"
          checked={isAllChecked}
          onClick={(e) => handleCheckboxClickAll(e)}
        />
      )}
      <BalanceContainer className={isMultiple ? 'ml-3' : ''}>
        {renderDescription()}
      </BalanceContainer>
    </LineItem>
  );
}

SelectAllTagsItem.propTypes = {
  variant: PropTypes.string.isRequired,
  isMultiple: PropTypes.bool.isRequired,
  isAllChecked: PropTypes.bool.isRequired,
  onSelectAllTags: PropTypes.func.isRequired,
};

export default SelectAllTagsItem;
