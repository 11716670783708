import { connect } from 'react-redux';
import { compose } from 'redux';

import userActionCreators from '_store/_actions/user';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Permissions from './Permissions';

const mapStateToProps = (state) => {
  let users = state.user.usersForSelect;

  users = users.filter((u) => u.manager_id !== null);

  return {
    isLoading: state.fiscal.isLoading,
    activeCompany: state.company.activeCompany,
    users,
    allUserPermissions: state.userPermissions.permissions,
    isLoadingPermissions: state.userPermissions.isLoading,
    user: state.auth.user,
  };
};

const mapDispatchToProps = {
  onFetchUsers: userActionCreators.fetchUsersForSelect,
  onFetchUserPermissions: userActionCreators.fetchUsersPermissions,
  onUpdateUserPermissions: userActionCreators.updateUserPermissions,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['manager_permissions']),
)(Permissions);
