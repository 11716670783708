import * as Yup from 'yup';
import { ptShort } from 'yup-locale-pt';

Yup.setLocale(ptShort);

const FormSchema = Yup.object().shape({
  name: Yup.string().required().max(115).nullable(),
});

export default FormSchema;
