import { connect } from 'react-redux';

import subscriptionActionCreators from '../../../_store/_actions/subscription';

import TrialCounter from './TrialCounter';

const mapStateToProps = (state) => ({
  subscription: state.subscription.subscription,
  user: state.auth.user,
});

const mapDispatchToProps = {
  onFetchSubscription: subscriptionActionCreators.getUserSubscriptionStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrialCounter);
