import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IoFilterSharp } from 'react-icons/io5';

import {
  Button,
  TableLookup,
  PageHeader,
  ConfirmDialog,
  ActiveFilters,
} from '_components/_core';
import { Search, Pagination, ItemsPerPage } from '_components/_core/Table/components';
import { useTable } from '_components/_core/Table/utils';

import { DEFAULT_COLUMNS, TABLE_COLUMNS, FILTERS_CONFIGURATION } from './utils';
import { Filters } from './components';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { field: 'company_name', order: 'asc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function List({
  total,
  companies,
  onFetchCompanies,
  onDeleteCompany,
  history,
}) {
  const {
    filters,
    displayFilters,
    onToggleFilters,
    sorting,
    pagination,
    onFilter,
    onSorting,
    onPageChange,
    onPageSizeChange,
  } = useTable({
    ...tableConfig,
    data: companies,
    onFetchData: onFetchCompanies,
  });
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const renderAddButton = () => (
    <>
      <Filters
        filters={filters}
        isVisible={displayFilters}
        onToggleFilters={onToggleFilters}
        onFilter={onFilter}
      />
      <Button
        variant="outline-primary"
        icon={<IoFilterSharp />}
        onClick={onToggleFilters}
      >
        Filtros
      </Button>
      <Button
        onClick={() => history.push('/empresas/criar')}
        className="mr-3"
      >
        Cadastrar empresa
      </Button>
    </>
  );

  const handleEdit = useCallback((id) => {
    history.push(`/empresas/${id}/editar`);
  }, [history]);

  const showConfirmDialog = (id) => {
    setSelectedCompanyId(id);
    setIsOpen(true);
  };

  const handleConfirm = () => {
    onDeleteCompany(selectedCompanyId);
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Empresas"
        description="Gerencie os clientes da sua empresa de contabilidade por aqui."
        sideContent={renderAddButton()}
      />
      <Row>
        <Col sm={3}>
          <Search
            className="mb-3"
            placeholder="Pesquise por CNPJ, Razão Social ou Nome Fantasia"
            value={filters.company}
            onChange={(value) => onFilter({ company: value })}
          />
        </Col>
        <Col>
          <ActiveFilters
            filtersConfiguration={FILTERS_CONFIGURATION}
            filters={filters}
            onFilter={onFilter}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ConfirmDialog
            isOpen={isOpen}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            title="Excluir Empresa"
            html={`
            Todos os dados como Notas Fiscais, lançamentos contábeis, impostos serão excluídos. <br/><br/>
            Esta ação não pode ser revertida. <br/>
            `}
            icon="warning"
            iconColor="#DB504A"
            confirmButtonColor="#DB504A"
            confirmButtonText="Sim, deletar!"
            showCancelButton
          />
          <TableLookup
            keyName="id"
            name="companies-lookup-table"
            data={companies}
            columns={TABLE_COLUMNS}
            defaulColumns={DEFAULT_COLUMNS}
            sorting={sorting}
            onSorting={onSorting}
            onEdit={handleEdit}
            onDelete={showConfirmDialog}
            showColumnToggle
            showFooter={false}
            topDistance="calc(100vh - (70px + 54px + 81px + 3rem + 66px + 1rem))"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <ItemsPerPage
            itemsPerPage={pagination.itemsPerPage}
            onChange={onPageSizeChange}
            className="mt-3"
            total={total}
            totalBeingShown={companies.length}
          />
        </Col>
        <Col className="d-flex flex-row-reverse">
          <Pagination
            {...pagination}
            total={total}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
    </Container>
  );
}

List.defaultProps = {
  companies: [],
};

List.propTypes = {
  total: PropTypes.number.isRequired,
  companies: PropTypes.array,
  history: PropTypes.object,
  onFetchCompanies: PropTypes.func.isRequired,
  onDeleteCompany: PropTypes.func.isRequired,
};

export default withRouter(List);
