import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import accountActionCreators from '../../../_store/_actions/accounts';

import Container from './components/Container';

const mapStateToProps = (state) => ({
  accountState: state.accounts,
  companyState: state.company,
});

const mapDispatchToProps = (dispatch) => ({
  accountsActions: bindActionCreators(accountActionCreators, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
