import styled from 'styled-components';

import Tag from '_components/_core/Tag/Tag';

export const StyledTag = styled(Tag)`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
