import constants from '../../_constants/company.constants';

const clearSearchedCompany = () => {
  return (dispatch) => {
    dispatch(request());
  };

  function request(cnpj) {
    return {
      type: constants.CLEAR_SEARCHED_COMPANY_REQUEST,
      payload: { cnpj },
    };
  }
};

export default clearSearchedCompany;
