import Swal from 'sweetalert2';
import { push } from 'connected-react-router';

import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const deleteFinancialData = () => {
  return async (dispatch) => {
    confirmDialog.warning({
      title: 'Excluir todos os dados',
      message: 'Tem certeza que deseja excluir todos os dados financeiro desta empresa? Esta operação não pode ser revertida. <br /> <small>Dica: Faça um back-up antes de qualquer exclusão na tela Configurações - Exportar Dados</small>',
      confirmButtonText: 'Sim, excluir!',
      icon: 'warning',
      input: 'text',
      inputAttributes: {
        style: 'width: 80%;',
      },
      dangerMode: true,
      inputPlaceholder: 'Digite "excluir todos os meus dados"',
      preConfirm: (inputValue) => {
        if (inputValue !== 'excluir todos os meus dados') {
          Swal.showValidationMessage('Por favor, digite "excluir todos os meus dados" para confirmar a exclusão.');
        }
      },
      onConfirm: () => {
        dispatch(request());

        Swal.fire({
          title: 'Excluindo...',
          html: 'Aguarde enquanto excluímos os dados da empresa.',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();

            service.removeAllFinancialData().then(
              (response) => {
                dispatch(success(response));
                dispatch(alertActionCreators.success('Dados da empresa excluídos com sucesso!'));

                Swal.close();

                dispatch(push('/dashboard'));
              },
            ).catch((error) => {
              dispatch(failure(error));

              Swal.close();
            });
          },
        });
      },
      onCancel: () => {},
    });
  };

  function request() {
    return {
      type: constants.DELETE_COMPANY_FINANCIAL_DATA_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.DELETE_COMPANY_FINANCIAL_DATA_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_COMPANY_FINANCIAL_DATA_FAILURE,
      payload: error,
    };
  }
};

export default deleteFinancialData;
