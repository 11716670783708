import { connect } from 'react-redux';

import DREActionCreators from '_store/_actions/DRE';

import DRE from './DRE';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  DRE: state.DRE.DRE,
  isLoading: state.DRE.isLoading,
});

const mapDispatchToProps = {
  onFetchDRE: DREActionCreators.generateDRE,
  onClearDRE: DREActionCreators.eraseDRE,
};

export default connect(mapStateToProps, mapDispatchToProps)(DRE);
