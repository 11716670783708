import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { Button } from '_components/_core';
import {
  Menu,
  FormFamily,
  FormSelfEmployed,
  FormSmallCompany,
} from './components';

import { Content } from './styles';

function Create({
  user,
  onAddFinancialCompany,
  onUpdateFinancialCompany,
  isLoading,
  isOnboarding,
  onFetchMarketSegments,
}) {
  const history = useHistory();

  const [selectedMenu, setSelectedMenu] = useState(null);

  useEffect(() => {
    onFetchMarketSegments();
  }, [onFetchMarketSegments]);

  const handleChange = useCallback((option) => {
    setSelectedMenu(option);
  }, []);

  const renderForm = useCallback(() => {
    if (!selectedMenu) {
      return null;
    }

    const commonProps = {
      user,
      menuItem: selectedMenu,
      onChange: handleChange,
      onAddFinancialCompany,
      onUpdateFinancialCompany,
      isLoading,
      isOnboarding,
    };

    switch (selectedMenu.key) {
      case 'FAMILY':
        return <FormFamily {...commonProps} />;
      case 'SELF_EMPLOYED':
        return <FormSelfEmployed {...commonProps} />;
      case 'MEI':
        return <FormSmallCompany {...commonProps} />;
      case 'COMPANY':
        return <FormSmallCompany {...commonProps} />;
      default:
        return null;
    }
  }, [
    user,
    selectedMenu,
    handleChange,
    onAddFinancialCompany,
    onUpdateFinancialCompany,
    isLoading,
    isOnboarding,
  ]);

  const renderCancelButton = useCallback(() => {
    if (isOnboarding) {
      return null;
    }

    if (selectedMenu) {
      return null;
    }

    return (
      <Button
        variant="inverse-dark"
        onClick={() => history.push('/empresas')}
        className="mt-4 mb-4"
      >
        Cancelar
      </Button>
    );
  }, [isOnboarding, selectedMenu, history]);

  return (
    <Container className="d-flex flex-column justify-content-center align-items-center mt-3 mb-3">
      <Content>
        {selectedMenu === null && (
        <Menu onChange={handleChange} />
        )}
        {renderForm()}
      </Content>
      {renderCancelButton()}
    </Container>
  );
}

Create.defaultProps = {
  isLoading: false,
  isOnboarding: false,
};

Create.propTypes = {
  user: PropTypes.object.isRequired,
  isLoading: PropTypes.func,
  isOnboarding: PropTypes.bool,
  onAddFinancialCompany: PropTypes.func.isRequired,
  onUpdateFinancialCompany: PropTypes.func.isRequired,
  onFetchMarketSegments: PropTypes.func.isRequired,
};

export default Create;
