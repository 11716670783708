import constants from '../../_constants/company-transactions.constants';

const setSelectedAccount = (id) => {
  return async (dispatch) => {
    dispatch(request(id));
  };

  function request(id) {
    return {
      type: constants.SET_SELECTED_ACCOUNT_REQUEST,
      payload: {
        account_id: id,
      },
    };
  }
};

export default setSelectedAccount;
