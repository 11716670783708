import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/taxes.constants';
import service from '../../../services/taxes.service';

import alertActionCreators from '../alert.actions';

const deleteDarf = (id, callback) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Exclusão de Guia',
      message: 'Tem certeza que deseja excluir esta guia? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, deletar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.deleteDarf(id).then(() => {
          dispatch(success(id));
          dispatch(alertActionCreators.success('Guia excluída com sucesso.'));

          if (callback) {
            callback();
          }
        }).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request() {
    return {
      type: constants.DELETE_DARF_REQUEST,
      payload: { },
    };
  }
  function success(data) {
    return {
      type: constants.DELETE_DARF_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_DARF_FAILURE,
      payload: error,
    };
  }
};

export default deleteDarf;
