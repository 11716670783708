import React, { useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { useHistory, withRouter } from 'react-router-dom';

import { ZENPLY_TERMS_URL, ZENPLY_PRIVACY_POLICY_URL } from 'helpers';

import { Button } from '_components/_core';

import LogoZenpaper from '_components/_shared/NavBar/components/LogoZenpaper';
import { PasswordUpdateForm } from './components';

import {
  CustomContainer,
  FormContainer,
  BgContainer,
  StyledHeader,
  StyledFooter,
  StyledMobileFooter,
  StyledText,
  StyledSubtext,
} from './styles';

const PasswordUpdate = ({ onPasswordUpdate, isLoading }) => {
  const history = useHistory();

  const ZenpaperPasswordUpdate = () => (
    <>
      <CustomContainer>
        <FormContainer>
          <StyledHeader>
            <p className="mt-3 pt-3">Ainda não tem uma conta?</p>
            <Button
              variant="inverse-dark"
              className="m-3 p-3"
              onClick={() => history.push('/cadastrar')}
            >
              Cadastre-se!
            </Button>
          </StyledHeader>
          <LogoZenpaper width={200} disableClick />
          <p className="pt-2 pb-3">Insira sua nova senha.</p>
          <PasswordUpdateForm
            onPasswordUpdate={onPasswordUpdate}
            isLoading={isLoading}
          />
          <StyledFooter>
            <Col>
              <p className="footer-text">
                {`Copyright © Zenply - ${new Date().getFullYear()}`}
              </p>
            </Col>
            <Col>
              <ul className="auth-footer text-gray">
                <li>
                  <a target="_blank" href={ZENPLY_TERMS_URL} rel="noreferrer">Termos de Serviço</a>
                </li>
                <li>
                  <a target="_blank" href={ZENPLY_PRIVACY_POLICY_URL} rel="noreferrer">Política de Privacidade</a>
                </li>
              </ul>
            </Col>
          </StyledFooter>
          <StyledMobileFooter>
            <Col>
              <p className="footer-text">
                {`Copyright © Zenply - ${new Date().getFullYear()}`}
              </p>
              <ul className="auth-footer text-gray">
                <li>
                  <a target="_blank" href={ZENPLY_TERMS_URL} rel="noreferrer">Termos de Serviço</a>
                </li>
                <li>
                  <a target="_blank" href={ZENPLY_PRIVACY_POLICY_URL} rel="noreferrer">Política de Privacidade</a>
                </li>
              </ul>
            </Col>
          </StyledMobileFooter>
        </FormContainer>
        <BgContainer>
          <StyledText>
            Controle financeiro simples e inteligente.
          </StyledText>
          <StyledSubtext>
            Suas finanças em um só lugar.
          </StyledSubtext>
        </BgContainer>
      </CustomContainer>
    </>
  );

  const renderBody = useCallback(() => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    if (product === 'contabills-empresas') {
      return <ZenpaperPasswordUpdate />;
    }

    return (
      <Container fluid className="p-0">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper auth p-0 theme-two">
            <div className="row d-flex align-items-stretch">
              <div className="col-12 col-md-8 h-100 bg-white">
                <div className="auto-form-wrapper d-flex align-items-center justify-content-center flex-column">
                  <div className="nav-get-started">
                    <p>Ainda não tem uma conta?</p>
                    {/* <Link className="btn get-started-btn" to="/cadastrar">
                    COMECE AGORA
                  </Link> */}
                  </div>
                  <form>
                    <h1 className="mr-auto animated fadeInDown">
                      Redefinir Senha
                    </h1>
                    <p className="mb-4 mr-auto animated fadeInDown">
                      Insira sua nova senha.
                    </p>
                    <PasswordUpdateForm
                      onPasswordUpdate={onPasswordUpdate}
                      isLoading={isLoading}
                    />
                    <div className="wrapper mt-5 text-gray animated fadeInUp">
                      <p className="footer-text">
                        {`Copyright © ${new Date().getFullYear()} Creation Sistemas. Todos os direitos reservados.`}
                      </p>
                      <ul className="auth-footer text-gray">
                        <li>
                          <a href="termos-condicoes">Termos & Condições</a>
                        </li>
                        <li>
                          <a href="politica-cookies">Política de Cookies</a>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-4 banner-section d-none d-md-flex align-items-stretch justify-content-center pr-0 pl-0">
                <div className="slide-content bg-1" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }, [isLoading, onPasswordUpdate]);

  return (
    <>
      {renderBody()}
    </>
  );
};

PasswordUpdate.propTypes = {
  onPasswordUpdate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default withRouter(PasswordUpdate);
