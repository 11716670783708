export const entryConstants = {
  ENTRY_GET_SUCCESS: 'ENTRY_GET_SUCCESS',
  ENTRY_GET_REQUEST: 'ENTRY_GET_REQUEST',
  ENTRY_GET_FAILURE: 'ENTRY_GET_FAILURE',
  ENTRY_GET_ALL_REQUEST: 'ENTRY_GET_ALL_REQUEST',
  ENTRY_GET_ALL_SUCCESS: 'ENTRY_GET_ALL_SUCCESS',
  ENTRY_GET_ALL_FAILURE: 'ENTRY_GET_ALL_FAILURE',
  ENTRY_NEW_REQUEST: 'ENTRY_NEW_REQUEST',
  ENTRY_NEW_SUCCESS: 'ENTRY_NEW_SUCCESS',
  ENTRY_NEW_FAILURE: 'ENTRY_NEW_FAILURE',
  ENTRY_NEW_CANCEL_REQUEST: 'ENTRY_NEW_CANCEL_REQUEST',
  ENTRY_ADD_REQUEST: 'ENTRY_ADD_REQUEST',
  ENTRY_ADD_SUCCESS: 'ENTRY_ADD_SUCCESS',
  ENTRY_ADD_FAILURE: 'ENTRY_ADD_FAILURE',
  ENTRY_EDIT_REQUEST: 'ENTRY_EDIT_REQUEST',
  ENTRY_EDIT_SUCCESS: 'ENTRY_EDIT_SUCCESS',
  ENTRY_EDIT_FAILURE: 'ENTRY_EDIT_FAILURE',
  ENTRY_UPDATE_REQUEST: 'ENTRY_UPDATE_REQUEST',
  ENTRY_UPDATE_SUCCESS: 'ENTRY_UPDATE_SUCCESS',
  ENTRY_UPDATE_FAILURE: 'ENTRY_UPDATE_FAILURE',
  ENTRY_DELETE_REQUEST: 'ENTRY_DELETE_REQUEST',
  ENTRY_DELETE_SUCCESS: 'ENTRY_DELETE_SUCCESS',
  ENTRY_DELETE_FAILURE: 'ENTRY_DELETE_FAILURE',
  ENTRY_SET_ENTRY_TYPE: 'ENTRY_SET_ENTRY_TYPE',
  ENTRY_SET_EVENT_DATE: 'ENTRY_SET_EVENT_DATE',
  ENTRY_SET_DEBIT_ACCOUNT: 'ENTRY_SET_DEBIT_ACCOUNT',
  ENTRY_SET_CREDIT_ACCOUNT: 'ENTRY_SET_CREDIT_ACCOUNT',
  ENTRY_SET_AMOUNT: 'ENTRY_SET_AMOUNT',
  ENTRY_SET_HISTORY: 'ENTRY_SET_HISTORY',
  ENTRY_NEW_CHILD_ENTRY: 'ENTRY_NEW_CHILD_ENTRY',
  ENTRY_ERASE_CHILD_ENTRY: 'ENTRY_ERASE_CHILD_ENTRY',
  ENTRY_SET_CHILD_ENTRY_DEBIT_ACCOUNT: 'ENTRY_SET_CHILD_ENTRY_DEBIT_ACCOUNT',
  ENTRY_SET_CHILD_ENTRY_CREDIT_ACCOUNT: 'ENTRY_SET_CHILD_ENTRY_CREDIT_ACCOUNT',
  ENTRY_SET_CHILD_ENTRY_AMOUNT: 'ENTRY_SET_CHILD_ENTRY_AMOUNT',
  ENTRY_SET_CHILD_ENTRY_HISTORY: 'ENTRY_SET_CHILD_ENTRY_HISTORY',
  ENTRY_SET_CHILD_ENTRY_EVENT_DATE: 'ENTRY_SET_CHILD_ENTRY_EVENT_DATE',
  ENTRY_ADD_CHILD_ENTRY: 'ENTRY_ADD_CHILD_ENTRY',
  ENTRY_REMOVE_CHILD_ENTRY: 'ENTRY_REMOVE_CHILD_ENTRY',
  ENTRY_SET_ENTRY_TOTALS: 'ENTRY_SET_ENTRY_TOTALS',

  ENTRY_ADD_MANY_REQUEST: 'ENTRY_ADD_MANY_REQUEST',
  ENTRY_ADD_MANY_SUCCESS: 'ENTRY_ADD_MANY_SUCCESS',
  ENTRY_ADD_MANY_FAILURE: 'ENTRY_ADD_MANY_FAILURE',

  ENTRY_UPDATE_MANY_REQUEST: 'ENTRY_UPDATE_MANY_REQUEST',
  ENTRY_UPDATE_MANY_SUCCESS: 'ENTRY_UPDATE_MANY_SUCCESS',
  ENTRY_UPDATE_MANY_FAILURE: 'ENTRY_UPDATE_MANY_FAILURE',

  ENTRY_DELETE_MANY_REQUEST: 'ENTRY_DELETE_MANY_REQUEST',
  ENTRY_DELETE_MANY_SUCCESS: 'ENTRY_DELETE_MANY_SUCCESS',
  ENTRY_DELETE_MANY_FAILURE: 'ENTRY_DELETE_MANY_FAILURE',
};

export default entryConstants;
