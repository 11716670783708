import { push } from 'connected-react-router';

import { parsePaginationParams } from 'helpers';

import constants from '../../_constants/bank-statements.constants';
import service from '../../../services/bank-statement.service';
import alertActionCreators from '../alert.actions';

const fetchBankStatementEntries = (params = {}) => {
  return (dispatch) => {
    dispatch(request());

    const parsedParams = parsePaginationParams(params);

    service
      .getAll(parsedParams)
      .then((response) => {
        dispatch(success(response.data));

        const { total } = response.data;

        if (total > 0) {
          dispatch(push('/contabil/extrato-bancario/conciliar'));
        }
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(alertActionCreators.error('Erro ao salvar no Contabills'));
      });
  };

  function request() {
    return {
      type: constants.FETCH_BANK_STATEMENT_ENTRIES_REQUEST,
      payload: { },
    };
  }
  function success(data) {
    return {
      type: constants.FETCH_BANK_STATEMENT_ENTRIES_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_BANK_STATEMENT_ENTRIES_FAILURE,
      payload: { error },
    };
  }
};

export default fetchBankStatementEntries;
