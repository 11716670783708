import * as yup from 'yup';
import { ptShort } from 'yup-locale-pt';

yup.setLocale(ptShort);

const PasswordUpdateSchema = yup.object().shape({
  token: yup.string().required(),
  password: yup.string().required(),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password')], 'As senhas não conferem')
    .required(),
});

export default PasswordUpdateSchema;
