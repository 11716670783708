import FORMATTERS from 'helpers/formatters';
import { SPECIAL_COLUMNS } from '_components/_core/Table';

export const DEFAULT_COLUMNS = [
  'id',
  'date_competence',
  'gross_revenue',
  'pis_pasep_owed',
  'pis_pasep_withheld',
  'pis_pasep_due',
  'cofins_owed',
  'cofins_withheld',
  'cofins_due',
  'irpj_owed',
  'irpj_withheld',
  'irpj_due',
  'csll_owed',
  'csll_withheld',
  'csll_due',
  'iss_owed',
  'iss_withheld',
  'iss_due',
];

export const TABLE_COLUMNS = [
  {
    label: 'Ações',
    keyName: 'id',
    align: 'center',
    width: 10,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'Competência',
    keyName: 'date_competence',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.DATE_MMYYYY,
    order: 2,
  },
  {
    label: 'Receita Bruta',
    keyName: 'gross_revenue',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 3,
    sumOnFooter: true,
  },
  {
    label: 'PIS Devido',
    keyName: 'pis_pasep_owed',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 4,
    sumOnFooter: true,
  },
  {
    label: 'PIS Retido',
    keyName: 'pis_pasep_withheld',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 5,
    sumOnFooter: true,
  },
  {
    label: 'PIS à Recolher',
    keyName: 'pis_pasep_due',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 6,
    sumOnFooter: true,
  },
  {
    label: 'COFINS Devido',
    keyName: 'cofins_owed',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 7,
    sumOnFooter: true,
  },
  {
    label: 'COFINS Retido',
    keyName: 'cofins_withheld',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 8,
    sumOnFooter: true,
  },
  {
    label: 'COFINS à Recolher',
    keyName: 'cofins_due',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 9,
    sumOnFooter: true,
  },
  {
    label: 'IRPJ Devido',
    keyName: 'irpj_owed',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 10,
    sumOnFooter: true,
  },
  {
    label: 'IRPJ Retido',
    keyName: 'irpj_withheld',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 11,
    sumOnFooter: true,
  },
  {
    label: 'IRPJ à Recolher',
    keyName: 'irpj_due',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 12,
    sumOnFooter: true,
  },
  {
    label: 'CSLL Devido',
    keyName: 'csll_owed',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 13,
    sumOnFooter: true,
  },
  {
    label: 'CSLL Retido',
    keyName: 'csll_withheld',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 14,
    sumOnFooter: true,
  },
  {
    label: 'CSLL à Recolher',
    keyName: 'csll_due',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 15,
    sumOnFooter: true,
  },
  {
    label: 'ISS Devido',
    keyName: 'iss_owed',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 16,
    sumOnFooter: true,
  },
  {
    label: 'ISS Retido',
    keyName: 'iss_withheld',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 17,
    sumOnFooter: true,
  },
  {
    label: 'ISS à Recolher',
    keyName: 'iss_due',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 18,
    sumOnFooter: true,
  },
];

export const MONTH_TABLE_DEFAULT_COLUMNS = [
  'tax_name',
  'amount_due',
];

export const MONTH_TABLE_COLUMNS = [
  {
    label: '',
    keyName: 'tax_name',
    align: 'left',
    width: 10,
    order: 1,
  },
  {
    label: 'Valor',
    keyName: 'amount_due',
    align: 'right',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 2,
    sumOnFooter: true,
  },
];

export const GROUPED_TABLE_COLUMNS = [
  {
    keyName: 'tax_name',
    label: 'Tributo',
    align: 'left',
    width: 20,
    order: 1,
  },
  {
    label: 'Valor apurado',
    keyName: 'amount_owed',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.NUMBER,
    order: 2,
    sumOnFooter: true,
  },
  {
    label: 'Valor retido',
    keyName: 'amount_withheld',
    align: 'center',
    width: 5,
    order: 3,
    formatter: FORMATTERS.NUMBER,
    sumOnFooter: true,
  },
  {
    label: 'Valor à recolher',
    keyName: 'amount_due',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.NUMBER,
    sumOnFooter: true,
    order: 4,
  },
  {
    label: 'Guia / DARF',
    keyName: 'url',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.DARF_URL,
    order: 4,
  },
];

export const GROUPED_DEFAULT_COLUMNS = [
  'id',
  'tax_name',
  'amount_owed',
  'amount_withheld',
  'amount_due',
  'url',
];
