import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { FaArrowRight, FaSearch } from 'react-icons/fa';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';

import FORMATTERS from 'helpers/formatters';
import {
  Card,
  FormDateField,
  Tag,
  PageHeader,
  Button,
  HintIcon,
} from '_components/_core';
import { useTableV2 } from '_components/_core/Table/utils';
import { ItemsPerPage, Pagination } from '_components/_core/Table/components';
import CustomFormSelectField from '_components/Transactions/components/CustomFormSelectField/CustomFormSelectField';
import { LoadingIcon } from '_components/_shared';

import {
  ACTION_TRANSLATION,
  ACTION_VARIANT,
  TRANSLATIONS,
  ACTIONS_DROPDOWN,
  ENTITY_TRANSLATION,
} from './utilities';
import { StyledTable, StyledUser } from './styles';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { field: 'timestamp', order: 'desc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function Logs({
  total,
  logs,
  users,
  activeCompany,
  isLoading,
  onFetchUsers,
  onFetchLogs,
  // entities,
  possibleEntities,
}) {
  const {
    pagination,
    onFilter,
    onPageChange,
    onPageSizeChange,
  } = useTableV2({
    ...tableConfig,
    data: logs,
    onFetchData: onFetchLogs,
    withSavedFilters: false,
    withInitialLoading: false,
  });

  useEffect(() => {
    onFetchUsers();
    onFetchLogs();
  }, [onFetchUsers, onFetchLogs, activeCompany]);

  const enhancedLogs = useMemo(() => logs.map((log) => {
    const user = users.find((u) => u.value === log.user_id);

    return {
      ...log,
      userExists: !!user,
    };
  }), [logs, users]);

  const ENTITIES_DROPDOWN = useMemo(() => {
    const options = [];

    // entities.forEach((entity) => {
    //   options.push({
    //     value: entity,
    //     label: ENTITY_TRANSLATION[entity],
    //   });
    // });

    possibleEntities.forEach((entity) => {
      options.push({
        value: entity,
        label: ENTITY_TRANSLATION[entity] || 'Outras',
      });
    });

    return sortBy(options, 'label');
  }, [possibleEntities]);

  const handleFilter = (values) => {
    const {
      start_date,
      end_date,
      users: usersFilter,
      actions,
      entities,
    } = values;

    const filters = {};

    if (start_date) {
      filters.start_date = start_date;
    }

    if (end_date) {
      filters.end_date = end_date;
    }

    if (usersFilter && !isEmpty(usersFilter)) {
      filters.user_ids = usersFilter.map((user) => user.value);
    }

    if (actions && !isEmpty(actions)) {
      filters.actions = actions.map((action) => action.value);
    }

    if (entities && !isEmpty(entities)) {
      filters.entities = entities.map((entity) => entity.value);
    }

    onFilter(filters);
  };

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Logs de Atividades"
        variant="small"
        sideContent={(
          <div className="d-flex justify-content-center align-items-center">
            <HintIcon hint="Tempo de retenção dos logs: 30 dias" />
          </div>
        )}
      />
      <Formik
        initialValues={{
          start_date: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
          end_date: DateTime.now().endOf('month').toFormat('yyyy-MM-dd'),
          users: [],
          actions: [],
          entities: [],
          company_id: activeCompany ? activeCompany.id : null,
        }}
        onSubmit={handleFilter}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <Form className="tab-content" onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} xs={6} md={6} lg={2} xl={2}>
                <Form.Label>Data Inicial</Form.Label>
                <FormDateField
                  name="start_date"
                  placeholder="Data Inicial"
                  className="mr-2"
                />
              </Form.Group>
              <Form.Group as={Col} xs={6} md={6} lg={2} xl={2}>
                <Form.Label>Data Final</Form.Label>
                <FormDateField
                  name="end_date"
                  placeholder="Data Final"
                  className="ml-2"
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} md={12} lg={4} xl={3}>
                <Form.Label>Usuários</Form.Label>
                <CustomFormSelectField
                  name="users"
                  options={users}
                  placeholder="Usuários (Todos)"
                  isMulti
                  multiple
                  isClearable
                  width="100%"
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} lg={4} xl={3}>
                <Form.Label>Tipo de Item</Form.Label>
                <CustomFormSelectField
                  name="entities"
                  options={ENTITIES_DROPDOWN}
                  placeholder="Tipo de Item (Todos)"
                  isMulti
                  multiple
                  isClearable
                  width="100%"
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} lg={4} xl={2}>
                <Form.Label>Tipo de ação</Form.Label>
                <CustomFormSelectField
                  name="actions"
                  options={ACTIONS_DROPDOWN}
                  placeholder="Tipo de ações (Todas)"
                  isMulti
                  multiple
                  isClearable
                  width="100%"
                />
              </Form.Group>
              <Form.Group as={Col} lg={3}>
                <Form.Label>&nbsp;</Form.Label>
                <Button className="mt-0 mt-md-4 mt-xl-0" icon={<FaSearch />} variant="dark" onClick={handleSubmit}>
                  Buscar
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        )}
      </Formik>
      {!isLoading && isEmpty(logs) && (
        <div className="mt-5 mb-5">
          <h4 className="text-center">Nenhum registro encontrado</h4>
        </div>
      )}
      {isLoading && (
        <div className="mt-5 mb-5">
          <LoadingIcon text="Carregando..." />
        </div>
      )}
      {!isLoading && !isEmpty(logs) && (
        <>
          <Row>
            <Col>
              <Card className="p-0">
                <StyledTable className="table table-hover">
                  <thead>
                    <tr>
                      <th width="20%" className="text-right">Data</th>
                      <th width="80%">Descrição</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enhancedLogs.map((log) => {
                      const isCustom = log.action === 'CUSTOM';

                      return (
                        <tr key={log._id}>
                          <td className="text-right">
                            {FORMATTERS.DATE_DDMMYYYYHHMMSS(log.timestamp)}
                          </td>
                          <td>
                            <span>
                              <span className="font-weight-bold mr-3">
                                <StyledUser title={log.user.email}>
                                  {log.user.name}
                                </StyledUser>
                              </span>
                              <Tag variant={isCustom ? 'primary' : ACTION_VARIANT[log.action]} className="p-0 pl-1 pr-1 mr-2">
                                {isCustom ? log.message : ACTION_TRANSLATION[log.action]}
                              </Tag>
                              {!isCustom && (
                              <span className="mr-2">
                                {TRANSLATIONS[log.entity]}
                              </span>
                              )}
                              {log.entity_display_text && (
                                <>
                                  <FaArrowRight className="text-muted mr-2" size="0.8em" />
                                  <span className="text-muted">
                                    <span dangerouslySetInnerHTML={{ __html: log.entity_display_text }} />
                                  </span>
                                </>
                              )}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </StyledTable>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={7} className="d-flex justify-content-start">
              <ItemsPerPage
                itemsPerPage={pagination.itemsPerPage}
                onChange={onPageSizeChange}
                noMarginsOnTotals
                className="mr-3"
                total={total}
                totalBeingShown={logs.length}
                maxItemsPerPage={100}
              />
            </Col>
            <Col xs={5} className="d-flex justify-content-end">
              <Pagination
                {...pagination}
                total={total}
                onPageChange={onPageChange}
              />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

Logs.defaultProps = {
  logs: [],
  total: 0,
  users: [],
  isLoading: false,
  // entities: [],
  possibleEntities: [],
};

Logs.propTypes = {
  activeCompany: PropTypes.object,
  total: PropTypes.number,
  users: PropTypes.array,
  logs: PropTypes.array,
  isLoading: PropTypes.bool,
  onFetchUsers: PropTypes.func,
  onFetchLogs: PropTypes.func,
  // entities: PropTypes.array,
  possibleEntities: PropTypes.array,
};

export default Logs;
