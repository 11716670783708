import React, {
  useMemo,
  useRef,
  useContext,
  useEffect,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Card, CardBody } from '_components/_core';
import { Report } from '_components/_shared';

import { REPORT_FRIENDLY_NAMES } from '../../utilities';
import { ReportContext } from '../ReportContext';
import PrintHeader from '../PrintHeaderContainer';
import PieChart from './PieChart';
import EmptyState from '../EmptyState/EmptyState';
import ReportTable from './components/ReportTable/ReportTable';
import { StyledCheckbox } from '../../styles';

function PieChartReport({
  reports,
  name,
  colorType,
  formattedPeriod,
  account_ids,
  cost_center_ids,
  values,
  setFieldValue,
  setValues,
  onEditTransaction,
  categories,
  onUpdateMultipleTransactions,
  isMobile,
}) {
  const { setValue } = useContext(ReportContext);
  const reportRef = useRef();

  const [showTable, setShowTable] = useState(true);
  const [showChart, setShowChart] = useState(true);

  useEffect(() => {
    setValue(reportRef);
  }, [setValue]);

  const data = useMemo(() => {
    if (!reports) {
      return [];
    }

    const report = reports[name] || {};
    const { result = [], result_other = [] } = report;

    const series = result.map((item) => [item.description, item.amount]);
    const other_total = result_other.reduce((acc, item) => acc + item.amount, 0);

    if (other_total > 0) {
      series.push(['Outros', other_total]);
    }

    return series;
  }, [reports, name]);

  const all_data = useMemo(() => {
    if (!reports) {
      return [];
    }

    const report = reports[name] || {};
    const { result = [], result_other = [] } = report;

    return [...result, ...result_other];
  }, [reports, name]);

  const main_data = useMemo(() => {
    if (!reports) {
      return [];
    }

    const report = reports[name] || {};
    const { result = [] } = report;

    return result;
  }, [reports, name]);

  const other_data = useMemo(() => {
    if (!reports) {
      return [];
    }

    const report = reports[name] || {};
    const { result_other = [] } = report;

    return result_other;
  }, [reports, name]);

  const handleFilter = useCallback(({
    sub_type,
    category_id,
    category_name,
    recipient_id,
    cost_center_id,
    cost_center_name,
  }) => {
    setValues((prev) => ({
      ...prev,
      sub_type,
      category_id,
      category_name,
      recipient_id,
      cost_center_id,
      cost_center_name,
    }));
  }, [setValues]);

  const handleResetReport = useCallback(() => {
    setValues((prev) => ({
      ...prev,
      sub_type: null,
      category_id: null,
      category_name: null,
      cost_center_name: null,
    }));
  }, [setValues]);

  const tableProps = useMemo(() => ({
    all_data,
    main_data,
    other_data,
    values,
    setFieldValue,
    name,
    onFilter: handleFilter,
    onResetReport: handleResetReport,
    onEditTransaction,
    onUpdateMultipleTransactions,
    categories,
  }), [
    all_data,
    main_data,
    other_data,
    name,
    values,
    setFieldValue,
    handleFilter,
    handleResetReport,
    onEditTransaction,
    onUpdateMultipleTransactions,
    categories,
  ]);

  if (isEmpty(all_data)) {
    return <EmptyState />;
  }

  return (
    <Report ref={reportRef} className="mb-5">
      <Card>
        <PrintHeader
          title={REPORT_FRIENDLY_NAMES[name].toUpperCase()}
          description={formattedPeriod}
          formattedPeriod={formattedPeriod}
          account_ids={account_ids}
          cost_center_ids={cost_center_ids}
        />
        <CardBody className="p-0 mt-0">
          {showChart && (
            <PieChart isMobile={isMobile} data={data} colorType={colorType} />
          )}
          {showTable && (
            <ReportTable isMobile={isMobile} {...tableProps} />
          )}
        </CardBody>
        <div className="no-print mt-3 mb-3 d-flex justify-content-center">
          <StyledCheckbox
            type="checkbox"
            label="Exibir gráfico"
            checked={showChart}
            onChange={(e) => setShowChart(e.target.checked)}
            className="mr-3"
            id="reportOptionsChart"
          />
          <StyledCheckbox
            type="checkbox"
            label="Exibir tabela"
            checked={showTable}
            onChange={(e) => setShowTable(e.target.checked)}
            className="mr-3"
            id="reportOptionsTable"
          />
        </div>
      </Card>
    </Report>
  );
}

PieChartReport.defaultProps = {
  reports: {},
  formattedPeriod: '',
  values: {},
  navigationOptions: null,
  categories: [],
  isMobile: false,
};

PieChartReport.propTypes = {
  navigationOptions: PropTypes.object,
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  colorType: PropTypes.oneOf(['EXPENSE', 'INCOME']).isRequired,
  name: PropTypes.string.isRequired,
  reports: PropTypes.object,
  formattedPeriod: PropTypes.string,
  account_ids: PropTypes.array,
  categories: PropTypes.array,
  cost_center_ids: PropTypes.array,
  setValues: PropTypes.func.isRequired,
  onEditTransaction: PropTypes.func.isRequired,
  onUpdateMultipleTransactions: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

export default PieChartReport;
