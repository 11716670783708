import FORMATTERS from 'helpers/formatters';
import { SPECIAL_COLUMNS } from '_components/_core/Table';

import paginateResults from './paginateResults';

export {
  paginateResults,
};

export const DEFAULT_COLUMNS = [
  'event_date',
  'counterpart',
  'narration',
  'amount_debit',
  'amount_credit',
  'saldo',
];

export const TABLE_COLUMNS = [
  {
    label: 'Data',
    keyName: 'event_date',
    align: 'left',
    width: 8,
    formatter: FORMATTERS.DATE_DDMMYYYY,
    order: 1,
  },
  {
    label: 'Histórico',
    keyName: 'narration',
    formatter: FORMATTERS.UPPERCASE,
    align: 'left',
    width: 40,
    order: 2,
    specialType: SPECIAL_COLUMNS.REDIRECT,
    redirectPath: '/contabil/lancamentos/[key]/editar',
    redirectKey: 'id',
    redirectOptions: {
      openNewTab: true,
      styleOnHover: true,
    },
  },
  {
    label: 'Contrapartida',
    keyName: 'counterpart',
    formatter: FORMATTERS.UPPERCASE,
    align: 'center',
    width: 10,
    order: 3,
  },
  {
    label: 'Débito',
    keyName: 'amount_debit',
    align: 'center',
    formatter: FORMATTERS.LEDGER_DEBIT_CREDIT,
    width: 10,
    sumOnFooter: true,
    order: 4,
  },
  {
    label: 'Crédito',
    keyName: 'amount_credit',
    align: 'center',
    formatter: FORMATTERS.LEDGER_DEBIT_CREDIT,
    sumOnFooter: true,
    width: 10,
    order: 5,
  },
  {
    label: 'Saldo',
    keyName: 'saldo',
    align: 'right',
    formatter: FORMATTERS.NUMBER_WITH_ACCOUNTING_SIGN,
    width: 15,
    order: 6,
  },
];
