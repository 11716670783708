import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  Card,
  CardBody,
  CardHeader,
} from '_components/_core';
import { ActivePeriodForm } from './components';
import { StyledButton } from './styles';

function ActivePeriod({
  isLoading,
  activeCompany,
  onSetActivePeriod,
}) {
  const [isEditing, setIsEditing] = useState(false);

  const renderPeriod = useCallback(() => {
    if (isLoading) {
      return (
        <h3 className="mb-3 display-4">
          <span>
            Carregando...
          </span>
        </h3>
      );
    }

    const { active_period_start, active_period_end } = activeCompany || {};

    if (!active_period_start && !active_period_end) {
      return (
        <h3 className="mb-3 display-4">Nenhum período selecionado.</h3>
      );
    }

    if (active_period_start !== null && active_period_end !== null) {
      const start_date = DateTime.fromISO(active_period_start, { zone: 'utc' }).toFormat('MM/yyyy');
      const end_date = DateTime.fromISO(active_period_end, { zone: 'utc' }).toFormat('MM/yyyy');

      const isSame = start_date === end_date;

      return (
        <h3 className="mb-3 mt-1 display-4">
          <span>
            {isSame ? `${start_date}` : `${start_date} à ${end_date}`}
          </span>
        </h3>
      );
    }

    return (
      <h3 className="display-4">Nenhum período selecionado.</h3>
    );
  }, [activeCompany, isLoading]);

  const toggleEdit = useCallback(() => {
    setIsEditing(!isEditing);
  }, [isEditing]);

  return (
    <Card>
      <CardHeader title="Período Ativo" />
      <CardBody noPaddingTop>
        <Row>
          <Col className="d-flex flex-column justify-content-center">
            {!isEditing && (
            <>
              <div>{renderPeriod()}</div>
              <small className="text-gray">
                <StyledButton variant="link" onClick={() => toggleEdit()}>
                  Alterar Período
                </StyledButton>
              </small>
            </>
            )}
            {isEditing && (
              <ActivePeriodForm
                cancelEdit={toggleEdit}
                onSetActivePeriod={onSetActivePeriod}
                activeCompany={activeCompany}
              />
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default ActivePeriod;

ActivePeriod.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  activeCompany: PropTypes.object.isRequired,
  onSetActivePeriod: PropTypes.func.isRequired,
};
