import constants from '../../_constants/company-transactions.constants';

const sortTransactions = (params) => {
  return async (dispatch) => {
    dispatch(request(params));
  };

  function request(params) {
    return {
      type: constants.SORT_TRANSACTIONS,
      payload: params,
    };
  }
};

export default sortTransactions;
