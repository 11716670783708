export const payrollConstants = {
  IRRF_GET_ALL_REQUEST: 'IRRF_GET_ALL_REQUEST',
  IRRF_GET_ALL_SUCCESS: 'IRRF_GET_ALL_SUCCESS',
  IRRF_GET_ALL_FAILURE: 'IRRF_GET_ALL_FAILURE',

  INSS_GET_ALL_REQUEST: 'INSS_GET_ALL_REQUEST',
  INSS_GET_ALL_SUCCESS: 'INSS_GET_ALL_SUCCESS',
  INSS_GET_ALL_FAILURE: 'INSS_GET_ALL_FAILURE',

  CHILD_BENEFIT_GET_ALL_REQUEST: 'CHILD_BENEFIT_GET_ALL_REQUEST',
  CHILD_BENEFIT_GET_ALL_SUCCESS: 'CHILD_BENEFIT_GET_ALL_SUCCESS',
  CHILD_BENEFIT_GET_ALL_FAILURE: 'CHILD_BENEFIT_GET_ALL_FAILURE',

  MINIMUM_WAGE_GET_ALL_REQUEST: 'MINIMUM_WAGE_GET_ALL_REQUEST',
  MINIMUM_WAGE_GET_ALL_SUCCESS: 'MINIMUM_WAGE_GET_ALL_SUCCESS',
  MINIMUM_WAGE_GET_ALL_FAILURE: 'MINIMUM_WAGE_GET_ALL_FAILURE',

  MANUAL_PAYROLL_GET_ALL_REQUEST: 'MANUAL_PAYROLL_GET_ALL_REQUEST',
  MANUAL_PAYROLL_GET_ALL_SUCCESS: 'MANUAL_PAYROLL_GET_ALL_SUCCESS',
  MANUAL_PAYROLL_GET_ALL_FAILURE: 'MANUAL_PAYROLL_GET_ALL_FAILURE',

  MANUAL_PAYROLL_SAVE_REQUEST: 'MANUAL_PAYROLL_SAVE_REQUEST',
  MANUAL_PAYROLL_SAVE_SUCCESS: 'MANUAL_PAYROLL_SAVE_SUCCESS',
  MANUAL_PAYROLL_SAVE_FAILURE: 'MANUAL_PAYROLL_SAVE_FAILURE',

  GENERATE_PAYROLL_ENTRIES_REQUEST: 'GENERATE_PAYROLL_ENTRIES_REQUEST',
  GENERATE_PAYROLL_ENTRIES_SUCCESS: 'GENERATE_PAYROLL_ENTRIES_SUCCESS',
  GENERATE_PAYROLL_ENTRIES_FAILURE: 'GENERATE_PAYROLL_ENTRIES_FAILURE',

  PAYROLL_GET_ALL_REQUEST: 'PAYROLL_GET_ALL_REQUEST',
  PAYROLL_GET_ALL_SUCCESS: 'PAYROLL_GET_ALL_SUCCESS',
  PAYROLL_GET_ALL_FAILURE: 'PAYROLL_GET_ALL_FAILURE',

  GENERATE_PAYROLL_REQUEST: 'GENERATE_PAYROLL_REQUEST',
  GENERATE_PAYROLL_SUCCESS: 'GENERATE_PAYROLL_SUCCESS',
  GENERATE_PAYROLL_FAILURE: 'GENERATE_PAYROLL_FAILURE',

  DELETE_PAYROLL_REQUEST: 'DELETE_PAYROLL_REQUEST',
  DELETE_PAYROLL_SUCCESS: 'DELETE_PAYROLL_SUCCESS',
  DELETE_PAYROLL_FAILURE: 'DELETE_PAYROLL_FAILURE',

  SEND_SALARY_RECEIPT_MAIL_REQUEST: 'SEND_SALARY_RECEIPT_MAIL_REQUEST',
  SEND_SALARY_RECEIPT_MAIL_SUCCESS: 'SEND_SALARY_RECEIPT_MAIL_SUCCESS',
  SEND_SALARY_RECEIPT_MAIL_FAILURE: 'SEND_SALARY_RECEIPT_MAIL_FAILURE',
};

export default payrollConstants;
