export const ledgerConstants = {
  LEDGER_GET_REQUEST: 'LEDGER_GET_REQUEST',
  LEDGER_GET_SUCCESS: 'LEDGER_GET_SUCCESS',
  LEDGER_GET_FAILURE: 'LEDGER_GET_FAILURE',
  LEDGER_ERASE_REQUEST: 'LEDGER_ERASE_REQUEST',
  LEDGER_ERASE_SUCCESS: 'LEDGER_ERASE_SUCCESS',
  LEDGER_ERASE_FAILURE: 'LEDGER_ERASE_FAILURE',
};

export default ledgerConstants;
