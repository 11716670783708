import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Pluralize from 'pluralize';
import { useHistory } from 'react-router-dom';

import { Tag } from '_components/_core';

import { Container, Content } from './styles';

function TrialCounter({
  subscription,
  onFetchSubscription,
  user,
}) {
  const [isHidden] = useState(() => localStorage.getItem('hideTrialAlert'));

  const { manager_id } = user || {};

  const { status } = subscription || {};
  const history = useHistory();

  const prefix = useMemo(() => {
    if (!subscription) {
      return '';
    }

    const { remaining_trial_days } = subscription;
    const word = remaining_trial_days > 1 ? 'Restam' : 'Resta';

    return word;
  }, [subscription]);

  const days = useMemo(() => {
    if (!subscription) {
      return '';
    }

    const { remaining_trial_days } = subscription;

    return (
      <strong className="ml-1 mr-1">
        {`${remaining_trial_days} ${Pluralize('dia', remaining_trial_days)}`}
      </strong>
    );
  }, [subscription]);

  const trialMessage = useMemo(() => {
    if (!subscription) {
      return '';
    }

    return 'de teste grátis.';
  }, [subscription]);

  useEffect(() => {
    onFetchSubscription();
  }, [onFetchSubscription]);

  const isManager = manager_id === null;

  const handleRedirectToPlans = () => {
    if (isManager) {
      history.push('/meu-plano');
    }
  };

  if (isHidden || status !== 'trialing') {
    return null;
  }

  return (
    <Container>
      <Tag variant="danger" className="mr-3">
        <Content isManager={isManager} onClick={handleRedirectToPlans}>
          {prefix}
          {days}
          {trialMessage}
        </Content>
      </Tag>
    </Container>
  );
}

TrialCounter.defaultProps = {
  subscription: {},
};

TrialCounter.propTypes = {
  onFetchSubscription: PropTypes.func.isRequired,
  subscription: PropTypes.object,
  user: PropTypes.object,
};

export default TrialCounter;
