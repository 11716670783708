import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';

import Switch from 'react-switch';

function FormSwitchField({ placeholder, disabled, sideContent, id, ...props }) {
  const formik = useFormikContext();

  const [field, meta] = useField(props);

  const error = useMemo(
    () => (meta.touched && meta.error ? meta.error : ''),
    [meta.error, meta.touched],
  );

  const handleChange = useCallback(
    value => {
      formik.setFieldValue(field.name, value);
    },
    [field, formik],
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Switch
          id={id}
          name={field.name}
          onChange={handleChange}
          checked={field.value || false}
          disabled={disabled}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#00ab6f"
          offColor="#d4d7dc"
          onHandleColor="#fff"
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          handleDiameter={24}
          height={20}
          width={44}
        />
        {sideContent}
      </div>
      {error && <small className="text-danger">{error}</small>}
    </div>
  );
}

FormSwitchField.defaultProps = {
  disabled: false,
  sideContent: null,
  id: null,
};

FormSwitchField.propTypes = {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  sideContent: PropTypes.node,
  id: PropTypes.string,
};

export default FormSwitchField;
