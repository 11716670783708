import { SPECIAL_COLUMNS } from '_components/_core/Table';
import FORMATTERS from 'helpers/formatters';

export const DEFAULT_COLUMNS = [
  'name',
  'email',
  'phone_number',
  'document_number',
  'date_admission',
  'salary',
  'type',
  'role',
  'journey',
  'id',
];

export const TABLE_COLUMNS = [
  {
    label: 'Ações',
    keyName: 'id',
    align: 'center',
    width: 5,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'CPF/CNPJ',
    keyName: 'document_number',
    sortingKey: 'document_number',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.CPF_CNPJ,
    order: 2,
  },
  {
    label: 'Nome',
    keyName: 'name',
    sortingKey: 'name',
    width: 10,
    formatter: FORMATTERS.UPPERCASE,
    specialType: SPECIAL_COLUMNS.REDIRECT,
    redirectPath: '/funcionarios/[key]/detalhes',
    redirectKey: 'id',
    order: 3,
  },
  {
    label: 'Data de Admissão',
    keyName: 'date_admission',
    sortingKey: 'date_admission',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.DATE_DDMMYYYY,
    order: 4,
  },
  {
    label: 'Salário',
    keyName: 'salary',
    sortingKey: 'salary',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 5,
  },
  {
    label: 'Tipo',
    keyName: 'type',
    sortingKey: 'type',
    align: 'center',
    width: 10,
    order: 5,
  },
  {
    label: 'Cargo',
    keyName: 'role',
    sortingKey: 'role',
    align: 'center',
    width: 10,
    order: 5,
  },
  {
    label: 'Jornada',
    keyName: 'journey',
    sortingKey: 'journey',
    align: 'center',
    formatter: FORMATTERS.JOURNEY,
    width: 10,
    order: 5,
  },
  {
    label: 'Telefone',
    keyName: 'phone_number',
    sortingKey: 'phone_number',
    align: 'center',
    width: 10,
    order: 6,
  },
  {
    label: 'E-mail',
    keyName: 'email',
    sortingKey: 'email',
    align: 'center',
    width: 10,
    order: 7,
  },
  {
    label: 'CEP',
    keyName: 'address_zip_code',
    sortingKey: 'address_zip_code',
    align: 'center',
    formatter: FORMATTERS.CEP,
    width: 11,
    order: 8,
  },
  {
    label: 'Logradouro',
    keyName: 'address_street',
    sortingKey: 'address_street',
    formatter: FORMATTERS.UPPERCASE,
    width: 12,
    order: 9,
  },
  {
    label: 'Número',
    keyName: 'address_number',
    sortingKey: 'address_number',
    align: 'center',
    width: 13,
    order: 10,
  },
  {
    label: 'Bairro',
    keyName: 'address_district',
    sortingKey: 'address_district',
    formatter: FORMATTERS.UPPERCASE,
    width: 14,
    order: 11,
  },
  {
    label: 'Complemento',
    keyName: 'address_complement',
    sortingKey: 'address_complement',
    formatter: FORMATTERS.UPPERCASE,
    width: 30,
    order: 12,
  },
  {
    label: 'Cidade',
    keyName: 'address_city',
    sortingKey: 'address_city',
    formatter: FORMATTERS.UPPERCASE,
    align: 'center',
    width: 15,
    order: 13,
  },
  {
    label: 'Estado',
    keyName: 'address_state',
    sortingKey: 'address_state',
    formatter: FORMATTERS.UPPERCASE,
    align: 'center',
    width: 16,
    order: 14,
  },
];

export default TABLE_COLUMNS;
