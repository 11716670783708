import { balanceSheetConstants } from '../_constants';

const INITIAL_STATE = {
  isLoading: false,
  balanceSheet: [],
};

export function balanceSheet(state = INITIAL_STATE, action) {
  switch (action.type) {
    case balanceSheetConstants.BALANCE_SHEET_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case balanceSheetConstants.BALANCE_SHEET_GET_SUCCESS: {
      const { balanceSheet } = action.payload;

      return {
        ...state,
        balanceSheet,
        isLoading: false,
      };
    }
    case balanceSheetConstants.BALANCE_SHEET_GET_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case balanceSheetConstants.BALANCE_SHEET_ERASE_REQUEST:
      return {
        ...state,
        balanceSheet: [],
        isLoading: false,
      };
    case balanceSheetConstants.BALANCE_SHEET_ERASE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case balanceSheetConstants.BALANCE_SHEET_ERASE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
