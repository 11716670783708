import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Form from 'react-bootstrap/Form';

import { MaskedInput } from '_components/_core';

function FormMaskedInput({
  placeholder,
  type,
  className,
  ...props
}) {
  const [field, meta] = useField(props);

  const error = meta.error && meta.touched ? meta.error : '';

  return (
    <>
      <Form.Control
        as={MaskedInput}
        type="tel"
        maskType={type}
        placeholder={placeholder}
        className={className}
        {...field}
      />
      {error && <small className="text-danger">{error}</small>}
    </>
  );
}

FormMaskedInput.defaultProps = {
  type: 'PHONE',
  className: null,
};

FormMaskedInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'CPF',
    'CNPJ',
    'DATE',
    'PHONE',
    'ZIP_CODE',
    'CNAE',
  ]),
};

export default FormMaskedInput;
