import styled, { css } from 'styled-components';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

export const DropzoneContainer = styled.div`
flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

export const Message = styled.p`
  margin-bottom: 0;
  
  ${({ withFiles }) => withFiles && css`
    color: var(--color-text-primary);
  `}
`;

export const FileLengthMessage = styled.div`
  margin-top: 24px;
`;
