import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import round from 'lodash/round';
// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
import isNaN from 'lodash/isNaN';
import { OverlayTrigger } from 'react-bootstrap';
import { IoSpeedometerOutline } from 'react-icons/io5';

import { Button } from '_components/_core';

import { CurrentProgress, StyledPopover, StyledPopoverContent } from './styles';

function Progress({ bankStatementSummary, variant }) {
  const {
    valid_transactions = 0,
    invalid_transactions = 0,
    total_transactions = 0,
  } = bankStatementSummary;

  const chart_values = useMemo(() => {
    const { valid_transactions, total_transactions } = bankStatementSummary;

    const current_progress = round((valid_transactions / total_transactions) * 100);

    return {
      current_progress: isNaN(current_progress) ? 0 : current_progress,
      remaining_progress: 100 - current_progress,
    };
  }, [bankStatementSummary]);

  const Overlay = (
    <StyledPopover>
      <StyledPopoverContent>
        <Row>
          <Col
            xl={12}
            className="mb-2 d-flex align-items-center justify-content-between"
            data-tip={`Faltam ${invalid_transactions} lançamentos`}
          >
            <p className="mb-0">
              {`${valid_transactions} de ${total_transactions} concluídos.`}
            </p>
            <CurrentProgress className="mb-0">
              {`${chart_values.current_progress}%`}
            </CurrentProgress>
          </Col>
        </Row>
        <Row>
          <Col>
            <ProgressBar animated now={chart_values.current_progress} variant="success" />
          </Col>
        </Row>
      </StyledPopoverContent>
    </StyledPopover>
  );

  if (variant === 'popover') {
    return (
      <OverlayTrigger
        trigger="click"
        placement="bottom-start"
        overlay={Overlay}
        rootClose
      >
        <Button
          variant="dark"
          icon={<IoSpeedometerOutline className="ml-auto ml-2" size="1.5em" />}
          className="mr-3"
        >
          Progresso
        </Button>
      </OverlayTrigger>
    );
  }

  return (
    <div style={{ width: '280px' }}>
      <strong className="d-flex justify-content-between">
        <span>
          {`${valid_transactions} conciliados de ${total_transactions}.`}
        </span>
        <span>
          {`${chart_values.current_progress}%`}
        </span>
      </strong>
      <ProgressBar animated now={chart_values.current_progress} variant="success" />
      <span className="d-flex justify-content-center align-items-center" />
    </div>
  );
}

Progress.defaultProps = {
  bankStatementSummary: {},
  variant: 'default',
};

Progress.propTypes = {
  variant: PropTypes.string,
  bankStatementSummary: PropTypes.object,
};

export default Progress;
