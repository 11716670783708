import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const duplicateMultipleTransactions = (params, callback) => {
  return async (dispatch) => {
    dispatch(request(params));

    service.duplicateMultipleTransactions(params).then(
      (response) => {
        dispatch(success(params, response));

        const { type } = params;

        if (type === 'next_month') {
          dispatch(alertActionCreators.success('Transações duplicadas e movidas para o próximo mês!'));
        } else {
          dispatch(alertActionCreators.success('Transações duplicadas com sucesso!'));
        }

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(params) {
    return {
      type: constants.DUPLICATE_MULTIPLE_COMPANY_TRANSACTION_REQUEST,
      payload: {
        params,
      },
    };
  }
  function success(params, response) {
    return {
      type: constants.DUPLICATE_MULTIPLE_COMPANY_TRANSACTION_SUCCESS,
      payload: {
        params,
        transactions: response.data,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.DUPLICATE_MULTIPLE_COMPANY_TRANSACTION_FAILURE,
      payload: error,
    };
  }
};

export default duplicateMultipleTransactions;
