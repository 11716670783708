import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DateTime } from 'luxon';

import {
  Card,
  CardHeader,
  CardBody,
  LoadingOverlay,
} from '_components/_core';

import {
  TABLE_COLUMNS,
  DEFAULT_COLUMNS,
} from '../../utilities';
import { MonthTaxes, QuarterTaxes } from '../../..';
import { StyledTable } from './styles';

function CalculatedTaxes({
  activeCompany,
  selectedDate,
  taxes,
  onCalculateTaxes,
  onFetchTaxes,
  isLoading,
}) {
  const handleCalculateTaxes = useCallback((id) => {
    const tax = taxes.find((tax) => tax.id === id);
    const start_date = DateTime.fromISO(tax.date_competence, { zone: 'utc' }).startOf('month');
    const end_date = DateTime.fromISO(start_date, { zone: 'utc' }).endOf('month');

    const fomattedStartDate = start_date.toFormat('yyyy-MM-dd');
    const fomattedEndDate = end_date.toFormat('yyyy-MM-dd');

    const { year } = start_date;

    onCalculateTaxes({
      start_date: fomattedStartDate,
      end_date: fomattedEndDate,
    },
    () => {
      onFetchTaxes({
        year,
      });
    });
  }, [onCalculateTaxes, onFetchTaxes, taxes]);

  return (
    <LoadingOverlay active={isLoading}>
      <Row className="mb-3">
        <Col>
          <Card>
            <CardHeader
              title="Visão Anual"
              description={(
                <div>
                  Impostos apurados no ano de&nbsp;
                  <span style={{ color: '#727cf5' }}>
                    <b>{selectedDate.toFormat('yyyy')}</b>
                  </span>
                </div>
                )}
            />
            <CardBody noPaddingTop>
              <div className="table-responsive">
                <StyledTable
                  keyName="id"
                  name="lucro-presumido-yearly-taxes-table"
                  className="table-hover table-striped"
                  data={taxes}
                  columns={TABLE_COLUMNS}
                  defaultColumns={DEFAULT_COLUMNS}
                  onRefresh={handleCalculateTaxes}
                  refreshHint="Apurar mês novamente"
                  showFooter
                  showColumnToggle
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={5}>
          <MonthTaxes
            activeCompany={activeCompany}
            selectedDate={selectedDate}
            taxes={taxes}
            taxRegime="LUCRO_PRESUMIDO"
          />
        </Col>
        <Col sm={12} lg={7}>
          <QuarterTaxes
            activeCompany={activeCompany}
            selectedDate={selectedDate}
            taxes={taxes}
            taxRegime="LUCRO_PRESUMIDO"
          />
        </Col>
      </Row>
    </LoadingOverlay>
  );
}

CalculatedTaxes.defaultProps = {
  taxes: [],
  isLoading: false,
};

CalculatedTaxes.propTypes = {
  activeCompany: PropTypes.object,
  taxes: PropTypes.array,
  selectedDate: PropTypes.any,
  onFetchTaxes: PropTypes.func.isRequired,
  onCalculateTaxes: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default CalculatedTaxes;
