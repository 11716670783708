import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const createTransferTransaction = (values, callback) => {
  return async (dispatch) => {
    dispatch(request(values));

    service.createTransferTransaction(values).then(
      (response) => {
        dispatch(success(response));

        dispatch(alertActionCreators.success('Transferência cadastrada com sucesso!'));

        if (callback) {
          callback(response.data);
        }
      },
    ).catch((error) => {
      if (callback) {
        callback();
      }

      dispatch(failure(error));
    });
  };

  function request(values) {
    return {
      type: constants.CREATE_TRANSFER_TRANSACTION_REQUEST,
      payload: {
        values,
      },
    };
  }
  function success(response) {
    return {
      type: constants.CREATE_TRANSFER_TRANSACTION_SUCCESS,
      payload: {
        transfer: response.data,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.CREATE_TRANSFER_TRANSACTION_FAILURE,
      payload: error,
    };
  }
};

export default createTransferTransaction;
