import styled from 'styled-components';
import Button from '_components/_core/Button';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export const StyledOverlayTrigger = styled(OverlayTrigger)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Separator = styled.div`
  border-bottom: 1px solid rgba(173, 173, 173, 0.2);
`;

export const PopoverButton = styled(Button)`
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 24px 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2F395D !important;
  background-color: #E7E8FE;
  border: 2px solid #2F395D;
  border-radius: 8px;
  font-weight: 700;
  box-shadow: 0 10px 6px -6px #C8C8C8;

  :focus {
    box-shadow: 0 10px 6px -6px #C8C8C8;
  }

  :hover {
    background-color: #C2C5FF;
    border-color: #2F395D;
    box-shadow: 0 10px 6px -6px #C8C8C8;
  }
`;

export const StyledAvatar = styled.img`
  display: block;
  width: 45px;
  margin: 24px 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;

  :hover {
    cursor: pointer;
  }
`;

export const StyledPopover = styled(Popover)`
  margin-left: 15px;
  border: 2px solid var(--accent-color);
  border-radius: 8px;
  box-shadow: 0 10px 6px -6px #C8C8C8;

  &.popover, .popover-body {
    background-color: #FFFFFF !important;
    border-radius: 8px;
    z-index: 1031;
  }

  &.bs-popover-right, .arrow::before, .bs-popover-auto[x-placement^="right"], .arrow::before {
    border-right-color: var(--accent-color);
  }

  &.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 2px;
    border-right-color: #FFFFFF;
  }
`;
