import React from 'react';
import PropTypes from 'prop-types';
import { IoPricetags } from 'react-icons/io5';

function TriggerContent({ tagText }) {
  return (
    <span className="d-flex align-items-center">
      <IoPricetags className="text-muted m-0 mr-3" />
      {tagText}
    </span>
  );
}

TriggerContent.propTypes = {
  tagText: PropTypes.string.isRequired,
};

export default TriggerContent;
