import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';
import getActiveCompany from './getActiveCompany';

const setActivePeriod = (startDate, finalDate) => {
  return (dispatch) => {
    dispatch(request(startDate, finalDate));

    service.setActivePeriod(startDate, finalDate).then(
      () => {
        dispatch(success(startDate, finalDate));
        dispatch(alertActionCreators.success('Período Aberto atualizado com sucesso!'));
        dispatch(getActiveCompany());
      },
    );
  };

  function request(startDate, finalDate) {
    return {
      type: constants.COMPANY_SET_ACTIVE_PERIOD_REQUEST,
      payload: { startDate, finalDate },
    };
  }
  function success(startDate, finalDate) {
    return {
      type: constants.COMPANY_SET_ACTIVE_PERIOD_SUCCESS,
      payload: { startDate, finalDate },
    };
  }
};

export default setActivePeriod;
