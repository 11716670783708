import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';
import companySelectors from '_store/_selectors/company';

import Details from './Details';

const mapStateToProps = (state) => ({
  isLoading: companySelectors.isLoadingCompanySelector(state),
  activeCompany: companySelectors.activeCompanySelector(state),
});

const mapDispatchToProps = {
  onFetchCompany: companyActionCreators.getCompanyById,
  onFetchCnpjCertificate: companyActionCreators.fetchCnpjCertificate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Details);
