import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FaCaretDown } from 'react-icons/fa';
import { VscOpenPreview, VscCloudDownload } from 'react-icons/vsc';
import { Dropdown } from 'react-bootstrap';
import { IoTrashBin } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';

import alertActions from '_store/_actions/alert.actions';

import confirmDialog from 'helpers/confirmDialog';
import { Dropzone, Tag } from '_components/_core';
import { hasPermissions } from '_components/_shared/PermissionsGate/utilities';
import PermissionsGate from '_components/_shared/PermissionsGate/PermissionsGateContainer';
import { LoadingIcon } from '_components/_shared';
import FORMATTERS from 'helpers/formatters';
import FileIcon from './components/FileIcon';
import FileViewer from './components/FileViewer';

import {
  StyledDropdown,
  StyledDropdownItem,
  StyledDropdownToggle,
  StyledFile,
  StyledFileList,
  StyledFileName,
} from './styles';

function TransactionFiles({
  isFetchingFiles,
  files,
  tempFiles,
  transaction,
  onFilesUpload,
  onFilesDelete,
  onFetchTransactionFiles,
  onClearTransactionFiles,
  onFilesSelected,
}) {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [fileForPreview, setFileForPreview] = useState(null);
  const dispatch = useDispatch();

  const userPermissions = useSelector(
    (state) => state.userPermissions.permissions[state.auth.user.id],
  );

  useEffect(() => {
    onClearTransactionFiles();

    const transaction_id = transaction.id;

    if (!transaction_id) {
      return;
    }

    onFetchTransactionFiles(transaction_id);
  }, [onFetchTransactionFiles, onClearTransactionFiles, transaction]);

  const canViewFiles = useMemo(() => hasPermissions({
    permissions: ['file_view'],
    userPermissions,
    type: 'all',
  }), [userPermissions]);

  const handleFilesUpload = useCallback((files) => {
    const transaction_id = transaction.id;
    const formData = new FormData();

    if (isEmpty(files)) {
      return;
    }

    if (files.length > 5) {
      confirmDialog.open({
        icon: 'error',
        title: 'Muitos arquivos',
        message: 'Por favor, envie no máximo 5 arquivos por upload.',
        confirmButtonText: 'Entendi',
        showCancelButton: false,
      });

      return;
    }

    files.forEach((file) => {
      formData.append('receipt', file);
    });

    if (!transaction.id) {
      onFilesSelected(files);
    } else {
      onFilesUpload(formData, transaction_id);
    }
  }, [onFilesUpload, onFilesSelected, transaction]);

  const handleDeleteFiles = useCallback((fileId) => {
    onFilesDelete(fileId);
  }, [onFilesDelete]);

  const handlePreviewFile = useCallback((file) => {
    if (!canViewFiles) {
      dispatch(alertActions.error('Você não tem permissão para visualizar anexos.'));

      return;
    }

    setFileForPreview(file);

    setIsPreviewOpen(true);
  }, [canViewFiles, dispatch]);

  const handleDownloadFile = useCallback((file) => {
    if (!canViewFiles) {
      dispatch(alertActions.error('Você não tem permissão para visualizar anexos.'));

      return;
    }

    const link = document.createElement('a');
    link.href = file.signed_url;
    link.target = '_blank';
    link.setAttribute('download', file.file_name);
    document.body.appendChild(link);

    link.click();
    link.remove();
  }, [canViewFiles, dispatch]);

  const handleModalToggle = useCallback(() => {
    setIsPreviewOpen((prevState) => !prevState);
  }, []);

  return (
    <Form className="mr-3 ml-3">
      <FileViewer
        isVisible={isPreviewOpen}
        fileForPreview={fileForPreview}
        onModalToggle={handleModalToggle}
      />
      <Form.Row>
        <Form.Group as={Col} lg={12}>
          <Form.Label>
            Arquivos dessa movimentação
          </Form.Label>
          <PermissionsGate permissions={['file_upload']} type="all">
            <Dropzone
              onChange={(files) => handleFilesUpload(files)}
              accept=".pdf, .png, .jpeg, .jpg, .xlsx, .xml, .docx, .txt"
              maxFiles={5}
              shouldSelectFiles={false}
            />
          </PermissionsGate>
          {isFetchingFiles && (
            <div className="mt-3">
              <LoadingIcon text="Carregando arquivos" />
            </div>
          )}
          {isEmpty(files) && isEmpty(tempFiles) && !isFetchingFiles && (
            <div className="mt-3 text-center">
              <span className="text-muted">
                Nenhum arquivo anexado a essa movimentação.
              </span>
            </div>
          )}
          {!isEmpty(tempFiles) && (
            <Tag className="mt-3" variant="warning">
              Os arquivos serão enviados após clicar em Salvar Alterações
            </Tag>
          )}
          <StyledFileList>
            {!isEmpty(tempFiles) && tempFiles.map((file) => (
              <StyledDropdown className="mt-3" key={file.path}>
                <StyledFile variant="default">
                  <FileIcon fileName={file.path} />
                  <StyledFileName className="d-flex flex-column">
                    <span>
                      {file.path}
                    </span>
                    <small className="text-muted">
                      Não enviado
                    </small>
                  </StyledFileName>
                </StyledFile>
                <StyledDropdownToggle disabled variant="default" split id={`dropdown-custom-${file.index}`}>
                  <FaCaretDown />
                </StyledDropdownToggle>
                <Dropdown.Menu className="p-0">
                  <StyledDropdownItem eventKey="1" onClick={() => handlePreviewFile(file)}>
                    <VscOpenPreview size="1.5em" className="mr-3" />
                    Visualizar
                  </StyledDropdownItem>
                  <StyledDropdownItem eventKey="2" onClick={() => handleDownloadFile(file)}>
                    <VscCloudDownload size="1.5em" className="mr-3" />
                    Baixar
                  </StyledDropdownItem>
                  <PermissionsGate permissions={['file_delete']} type="all">
                    <StyledDropdownItem eventKey="4" onClick={() => handleDeleteFiles(file.id)}>
                      <IoTrashBin size="1.5em" className="mr-3 text-danger" />
                      Excluir
                    </StyledDropdownItem>
                  </PermissionsGate>
                </Dropdown.Menu>
              </StyledDropdown>
            ))}
          </StyledFileList>
          <StyledFileList>
            {!isEmpty(files) && files.map((file) => (
              <StyledDropdown key={file.id}>
                <StyledFile variant="default" onClick={() => handlePreviewFile(file)}>
                  <FileIcon fileName={file.file_name} />
                  <StyledFileName className="d-flex flex-column">
                    <span>
                      {file.file_name}
                    </span>
                    <small className="text-muted">
                      {file.file_size && `${(file.file_size / 1000000).toFixed(2)} MB`}
                      &nbsp;|&nbsp;
                      Enviado em: {FORMATTERS.DATE_DDMMYYYYHHMMSS(file.created_at)}
                    </small>
                  </StyledFileName>
                </StyledFile>
                <StyledDropdownToggle variant="default" split id={`dropdown-custom-${file.index}`}>
                  <FaCaretDown />
                </StyledDropdownToggle>
                <Dropdown.Menu className="p-0">
                  <StyledDropdownItem eventKey="1" onClick={() => handlePreviewFile(file)}>
                    <VscOpenPreview size="1.5em" className="mr-3" />
                    Visualizar
                  </StyledDropdownItem>
                  <StyledDropdownItem eventKey="2" onClick={() => handleDownloadFile(file)}>
                    <VscCloudDownload size="1.5em" className="mr-3" />
                    Baixar
                  </StyledDropdownItem>
                  <PermissionsGate permissions={['file_delete']} type="all">
                    <StyledDropdownItem eventKey="4" onClick={() => handleDeleteFiles(file.id)}>
                      <IoTrashBin size="1.5em" className="mr-3 text-danger" />
                      Excluir
                    </StyledDropdownItem>
                  </PermissionsGate>
                </Dropdown.Menu>
              </StyledDropdown>
            ))}
          </StyledFileList>
        </Form.Group>
      </Form.Row>
    </Form>
  );
}

TransactionFiles.defaultProps = {
  transaction: {},
  isFetchingFiles: false,
  tempFiles: [],
};

TransactionFiles.propTypes = {
  transaction: PropTypes.object,
  isFetchingFiles: PropTypes.bool,
  onFilesUpload: PropTypes.func.isRequired,
  onFilesDelete: PropTypes.func.isRequired,
  files: PropTypes.array,
  onFetchTransactionFiles: PropTypes.func.isRequired,
  onClearTransactionFiles: PropTypes.func.isRequired,
  onFilesSelected: PropTypes.func.isRequired,
  tempFiles: PropTypes.array,
};

export default TransactionFiles;
