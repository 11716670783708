import { SPECIAL_COLUMNS } from '_components/_core/Table';
import FORMATTERS from 'helpers/formatters';

import EmployeeJourneySchema from './EmployeeJourneySchema';

export const DEFAULT_COLUMNS = [
  'journey',
  'id',
];

export const TABLE_COLUMNS = [
  {
    label: 'Ações',
    keyName: 'id',
    align: 'center',
    width: 5,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'Jornada Semanal',
    keyName: 'journey',
    sortingKey: 'journey',
    formatter: FORMATTERS.JOURNEY,
    align: 'left',
    width: 95,
    order: 2,
  },
];

export default TABLE_COLUMNS;

export { EmployeeJourneySchema };
