import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';

import {
  FastFilterSelect,
  FastFilterText,
  FormikSubmitListener,
} from '_components/_core';

const status_options = [
  {
    value: 'ALL',
    label: 'Todas as Movimentações',
    variant: 'default',
  },
  {
    value: 'COMPLETE',
    label: 'Conciliadas',
    variant: 'default',
  },
  {
    value: 'INCOMPLETE',
    label: 'Não Conciliadas',
    variant: 'default',
  },
];

const amount_type_options = [
  {
    value: 'ALL',
    label: 'Entradas e Saídas',
    variant: 'default',
  },
  {
    value: 'POSITIVE',
    label: 'Somente Entradas',
    variant: 'default',
  },
  {
    value: 'NEGATIVE',
    label: 'Somente Saídas',
    variant: 'default',
  },
];

function Filters({ filters, onFilter, showIcon }) {
  return (
    <div style={{
      paddingLeft: 18,
      paddingBottom: 18,
    }}
    >
      <Formik
        initialValues={{
          event_date_start: filters.event_date_start || null,
          event_date_end: filters.event_date_end || null,
          amount_start: filters.amount_start || null,
          amount_end: filters.amount_end || null,
          amount_type: filters.amount_type || 'ALL',
          description: filters.description || null,
          status: filters.status || 'INCOMPLETE',
        }}
        onSubmit={onFilter}
        enableReinitialize
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <FormikSubmitListener />
            <div
              className="d-flex align-items-center"
              style={{
                flexWrap: 'wrap',
              }}
            >
              {showIcon && <strong style={{ fontWeight: 600, width: '130px' }}>Filtrar</strong> }
              <FastFilterSelect
                label="Movimentações"
                description="Movimentações"
                className="mr-3"
                value={values.status}
                options={status_options}
                onChange={(value) => {
                  setFieldValue('status', value);
                }}
                hideLabelOnSelect
                isChanged={values.status !== 'INCOMPLETE'}
              />
              <FastFilterSelect
                label="Tipo do Lançamento"
                description="Tipo do Lançamento"
                className="mr-3"
                value={values.amount_type}
                options={amount_type_options}
                onChange={(value) => {
                  setFieldValue('amount_type', value);
                }}
                hideLabelOnSelect
                isChanged={values.amount_type !== 'ALL'}
              />
              <FastFilterText
                label="Descrição"
                description="Descrição do Lançamento"
                value={values.description}
                onChange={(value) => {
                  setFieldValue('description', value);
                }}
                className="mr-3"
                isChanged={values.description !== null}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

Filters.defaultProps = {
  filters: {},
  showIcon: false,
};

Filters.propTypes = {
  onFilter: PropTypes.func.isRequired,
  filters: PropTypes.object,
  showIcon: PropTypes.bool,
};

export default Filters;
