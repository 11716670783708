import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';

import { FastFilterSelect } from '_components/_core';

function FormQuickDateField() {
  const formik = useFormikContext();

  const handlePeriodChange = useCallback((option) => {
    if (option === 'day') {
      formik.setFieldValue('start_date', DateTime.now().toFormat('yyyy-MM-dd'));
      formik.setFieldValue('end_date', DateTime.now().toFormat('yyyy-MM-dd'));
    } else if (option === 'yesterday') {
      formik.setFieldValue('start_date', DateTime.now().startOf('day').minus({ days: 1 }).toFormat('yyyy-MM-dd'));
      formik.setFieldValue('end_date', DateTime.now().startOf('day').minus({ days: 1 }).toFormat('yyyy-MM-dd'));
    } else if (option === 'last7days') {
      formik.setFieldValue('start_date', DateTime.now().startOf('day').minus({ days: 7 }).toFormat('yyyy-MM-dd'));
      formik.setFieldValue('end_date', DateTime.now().endOf('day').toFormat('yyyy-MM-dd'));
    } else if (option === 'last30days') {
      formik.setFieldValue('start_date', DateTime.now().startOf('day').minus({ months: 1 }).toFormat('yyyy-MM-dd'));
      formik.setFieldValue('end_date', DateTime.now().toFormat('yyyy-MM-dd'));
    } else if (option === 'prevMonth') {
      formik.setFieldValue('start_date', DateTime.now().minus({ months: 1 }).startOf('month').toFormat('yyyy-MM-dd'));
      formik.setFieldValue('end_date', DateTime.now().minus({ months: 1 }).endOf('month').toFormat('yyyy-MM-dd'));
    } else if (option === 'thisMonth') {
      formik.setFieldValue('start_date', DateTime.now().startOf('month').toFormat('yyyy-MM-dd'));
      formik.setFieldValue('end_date', DateTime.now().endOf('month').toFormat('yyyy-MM-dd'));
    } else if (option === 'thisYear') {
      formik.setFieldValue('start_date', DateTime.now().startOf('year').toFormat('yyyy-MM-dd'));
      formik.setFieldValue('end_date', DateTime.now().endOf('year').toFormat('yyyy-MM-dd'));
    } else if (option === 'previousYear') {
      formik.setFieldValue('start_date', DateTime.now().minus({ years: 1 }).startOf('year').toFormat('yyyy-MM-dd'));
      formik.setFieldValue('end_date', DateTime.now().minus({ years: 1 }).endOf('year').toFormat('yyyy-MM-dd'));
    }

    formik.setFieldValue('quick_date', option);
  },
  [formik]);

  const options = [
    { value: 'day', label: 'Hoje' },
    { value: 'yesterday', label: 'Ontem' },
    { value: 'last7days', label: 'Últimos 7 dias' },
    { value: 'last30days', label: 'Últimos 30 dias' },
    { value: 'prevMonth', label: 'Mês passado' },
    { value: 'thisMonth', label: 'Este mês' },
    { value: 'previousYear', label: 'Ano passado' },
    { value: 'thisYear', label: 'Este ano' },
  ];

  return (
    <>
      <FastFilterSelect
        label="Data rápida:"
        value={formik.values.quick_date || 'thisMonth'}
        options={options}
        onChange={handlePeriodChange}
        renderDoubleDots={false}
        renderEmptyText={false}
        triggerStyle={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        hideLabelOnSelect
        isChanged={formik.values.quick_date !== 'thisMonth'}
      />
    </>
  );
}

export default FormQuickDateField;
