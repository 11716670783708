import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { TRANSACTION_SUB_TYPE_OPTIONS } from 'helpers';
import { Button, FormTextField } from '_components/_core';

function CategoryForm({
  category,
  type,
  sub_type,
  onCreateCategory,
  onUpdateCategory,
  onClearCategory,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const label = useMemo(() => {
    if (type === 'INCOME') {
      return 'Recebimento';
    }

    const { label } = TRANSACTION_SUB_TYPE_OPTIONS.find(
      (item) => item.value === sub_type,
    );

    return label;
  }, [type, sub_type]);

  const handleKeyPress = useCallback((e, handleSubmit) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }, []);

  const handleSubmitCategory = useCallback((values) => {
    setIsLoading(true);

    if (values.id) {
      onUpdateCategory(values.id, values, () => {
        onClearCategory();
        setIsLoading(false);
      });
    } else {
      onCreateCategory(values, () => {
        onClearCategory();
        setIsLoading(false);
      });
    }
  },
  [onCreateCategory, onClearCategory, onUpdateCategory]);

  return (
    <Formik
      initialValues={{
        id: category ? category.id : null,
        description: category ? category.description : '',
        type: category ? category.type : type,
        sub_type: category ? category.sub_type : sub_type,
      }}
      onSubmit={handleSubmitCategory}
      enableReinitialize
    >
      {({ handleSubmit, values }) => (
        <>
          <Form as={Row} onSubmit={handleSubmit}>
            <Form.Group as={Col} md="6" className="pr-0">
              <FormTextField
                name="description"
                placeholder={`Adicione uma nova categoria de ${label}`}
                onKeyPress={(e) => handleKeyPress(e, handleSubmit)}
                autoFocus
              />
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Button
                type="submit"
                className="mt-1 btn-sm"
                variant="dark"
                onClick={handleSubmit}
                disabled={!values.description}
                isLoading={isLoading}
              >
                {category ? 'Salvar Alterações' : 'Salvar'}
              </Button>
              {category && (
                <Button variant="link" className="p-0 m-0 ml-3" onClick={onClearCategory}>
                  Cancelar
                </Button>
              )}
            </Form.Group>
          </Form>
        </>
      )}
    </Formik>
  );
}

CategoryForm.defaultProps = {
  category: null,
  sub_type: null,
};

CategoryForm.propTypes = {
  category: PropTypes.object,
  type: PropTypes.string.isRequired,
  sub_type: PropTypes.string,
  onCreateCategory: PropTypes.func.isRequired,
  onUpdateCategory: PropTypes.func.isRequired,
  onClearCategory: PropTypes.func.isRequired,
};

export default CategoryForm;
