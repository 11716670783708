import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsPlus } from 'react-icons/bs';

import { Button, TableLookup, ConfirmDialog } from '_components/_core';
import { Pagination, ItemsPerPage } from '_components/_core/Table/components';
import { useTable } from '_components/_core/Table/utils';

import { AccountantModal } from './components';
import { DEFAULT_COLUMNS, TABLE_COLUMNS } from './utilities';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { field: 'name', order: 'asc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function Accountants({
  total,
  accountants,
  onFetchAccountants,
  onAddAccountant,
  onUpdateAccountant,
  onDeleteAccountant,
}) {
  const {
    pagination,
    onPageChange,
    onPageSizeChange,
  } = useTable({
    ...tableConfig,
    data: accountants,
    onFetchData: onFetchAccountants,
  });

  const [selectedAccountant, setSelectedAccountant] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const deleteAccountantMessage = useMemo(() => {
    if (!selectedAccountant) {
      return null;
    }

    return 'Tem certeza que deseja excluir o Contador?';
  }, [selectedAccountant]);

  const handleDeleteAccountant = useCallback((id) => {
    const accountant = accountants.find((accountant) => accountant.id === id);

    setSelectedAccountant(accountant);
    setIsDeleteConfirmOpen(true);
  }, [accountants]);

  const handleDeleteAccountantConfirmation = useCallback(() => {
    const { id } = selectedAccountant;

    onDeleteAccountant(id);
    setIsModalOpen(false);
    setIsDeleteConfirmOpen(false);
    setSelectedAccountant(null);
  }, [selectedAccountant, onDeleteAccountant]);

  const renderConfirmDialog = useMemo(() => {
    if (!isDeleteConfirmOpen) {
      return null;
    }

    return (
      <ConfirmDialog
        title="Excluir Contador"
        icon="question"
        confirmButtonColor="#DB504A"
        confirmButtonText="Sim, deletar!"
        html={deleteAccountantMessage}
        isOpen={selectedAccountant && isDeleteConfirmOpen}
        showCancelButton
        onConfirm={handleDeleteAccountantConfirmation}
        onCancel={() => {
          setIsDeleteConfirmOpen(false);
          setSelectedAccountant(null);
        }}
      />
    );
  }, [
    deleteAccountantMessage,
    selectedAccountant,
    isDeleteConfirmOpen,
    handleDeleteAccountantConfirmation]);

  const handleEditAccountant = useCallback((id) => {
    setSelectedAccountant(null);

    const accountant = accountants.find((accountant) => accountant.id === id);

    setSelectedAccountant(accountant);
    setIsModalOpen(true);
  }, [accountants]);

  const handleAddAccountant = useCallback(() => {
    setSelectedAccountant(null);
    setIsModalOpen(true);
  }, []);

  return (
    <>
      <AccountantModal
        isVisible={isModalOpen}
        accountant={selectedAccountant}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
        onAddAccountant={onAddAccountant}
        onUpdateAccountant={onUpdateAccountant}
      />
      {renderConfirmDialog}
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h4 className="mb-0">Contadores</h4>
        <Button variant="primary" icon={<BsPlus />} onClick={handleAddAccountant}>
          Novo Contador
        </Button>
      </div>
      <TableLookup
        keyName="id"
        data={accountants}
        columns={TABLE_COLUMNS}
        defaultColumns={DEFAULT_COLUMNS}
        onEdit={handleEditAccountant}
        onDelete={handleDeleteAccountant}
      />
      <Row>
        <Col sm={2}>
          <ItemsPerPage
            itemsPerPage={pagination.itemsPerPage}
            onChange={onPageSizeChange}
            className="mt-3"
          />
        </Col>
        <Col className="d-flex flex-row-reverse">
          <Pagination
            {...pagination}
            total={total}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
    </>
  );
}

Accountants.propTypes = {
  accountants: PropTypes.object,
  total: PropTypes.number.isRequired,
  onFetchAccountants: PropTypes.func.isRequired,
  onAddAccountant: PropTypes.func.isRequired,
  onUpdateAccountant: PropTypes.func.isRequired,
  onDeleteAccountant: PropTypes.func.isRequired,
};

export default Accountants;
