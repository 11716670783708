import { connect } from 'react-redux';
import { compose } from 'redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Tags from './Tags';

const mapStateToProps = (state) => ({
  isLoading: state.companyTransactions.isLoading,
  tags: state.companyTransactions.tags,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onFetchTags: companyTransactionsActionCreators.fetchAllTags,
  onCreateTag: companyTransactionsActionCreators.createTag,
  onUpdateTag: companyTransactionsActionCreators.updateTag,
  onDeleteTag: companyTransactionsActionCreators.deleteTag,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['manager_tags'], 'all', true),
)(Tags);
