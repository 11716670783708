import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import DemonstrativeResults from './DemonstrativeResults';

const mapStateToProps = (state) => ({
  reports: state.companyTransactions.reports,
  accounts: state.companyTransactions.accounts,
  selectedAccountIds: state.companyTransactions.selected_account_ids,
});

const mapDispatchToProps = {
  onGenerateReport: companyTransactionsActionCreators.generateTransactionReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DemonstrativeResults);
