import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import TransactionsListMobile from './TransactionsListMobile';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  accounts: state.companyTransactions.accounts,
  categories: state.companyTransactions.categories,
  recipients: state.companyTransactions.recipients,
  tags: state.companyTransactions.tags,
  preferences: state.user.preferences,
});

const mapDispatchToProps = {
  onCreateTransferTransaction: companyTransactionsActionCreators.createTransferTransaction,
  onUpdateMultipleTransactions: companyTransactionsActionCreators.updateMultipleTransactions,
  onMoveTransactions: companyTransactionsActionCreators.moveTransactions,
  onSortTransactions: companyTransactionsActionCreators.sortTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsListMobile);
