import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { IoCloseCircle, IoFilterOutline } from 'react-icons/io5';
import { TiPin, TiPinOutline } from 'react-icons/ti';
import { RiFileExcel2Line } from 'react-icons/ri';
import classNames from 'classnames';
import { ButtonGroup } from 'react-bootstrap';

import {
  ActionSheet,
  Button,
  FormDateFromToField,
  FormRadioField,
} from '_components/_core';
import {
  FormPaidField,
  FormBankAccountSelectField,
  FormQuickDateField,
  FormCostCenterSelectField,
} from '_components/_shared';

import { Menu } from './styles';
import PrintControls from '../PrintControls';
import PrintOptions from '../PrintOptions/PrintOptions';

function Filters({
  isMobile,
  isTablet,
  isDesktopMedium,
  isDesktopLarge,
  isDesktopExtraLarge,
  generateExcel,
  values,
}) {
  const [actionSheetOpen, setActionSheetOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(() => {
    const isFixedStorage = localStorage.getItem('financial-reports-filters-is-fixed');

    if (isFixedStorage === null) {
      return false;
    }

    return JSON.parse(isFixedStorage);
  });

  const handleClose = useCallback(() => {
    setActionSheetOpen(false);
  }, []);

  const handleFixUnfix = useCallback(() => {
    setIsFixed(prevState => {
      localStorage.setItem(
        'financial-reports-filters-is-fixed',
        JSON.stringify(!prevState),
      );

      return !prevState;
    });
  }, []);

  const columnSizes = useMemo(() => {
    if (isTablet) {
      return {
        date: 12,
        account_ids: 12,
        cost_center_ids: 12,
        paid_status: 6,
        date_type: 6,
        controls: 12,
      };
    }

    if (isDesktopMedium) {
      return {
        date: 12,
        account_ids: 6,
        cost_center_ids: 6,
        paid_status: 5,
        date_type: 5,
        controls: 2,
      };
    }

    if (isDesktopLarge) {
      return {
        date: 4,
        account_ids: 4,
        cost_center_ids: 4,
        paid_status: 4,
        date_type: 4,
      };
    }

    if (isDesktopExtraLarge) {
      return {
        date: 3,
        account_ids: 2,
        cost_center_ids: 2,
        paid_status: 2,
        date_type: 2,
        controls: 1,
      };
    }

    return {
      date: 12,
      account_ids: 12,
      cost_center_ids: 12,
      date_type: 6,
      paid_status: 6,
    };
  }, [isTablet, isDesktopMedium, isDesktopLarge, isDesktopExtraLarge]);

  const renderContent = useCallback(
    showPrint => (
      <Form>
        <Form.Row as={Row}>
          <Form.Group as={Col} xs={columnSizes.date}>
            <Form.Label>Data</Form.Label>
            <FormDateFromToField
              label={null}
              leftContent={<FormQuickDateField />}
              fromName="start_date"
              fromPlaceholder="De:"
              fromStyle={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderLeft: 0,
                width: '100%',
              }}
              fromId="start_date"
              fromPlacement="bottom-start"
              toName="end_date"
              toPlaceholder="Até:"
              toPlacement="bottom-end"
              toId="end_date"
            />
          </Form.Group>
          <Form.Group as={Col} xs={columnSizes.account_ids}>
            <Form.Label>Contas bancárias</Form.Label>
            <FormBankAccountSelectField
              name="account_ids"
              variant="input"
              shortenAccountNames
            />
          </Form.Group>
          <Form.Group as={Col} xs={columnSizes.cost_center_ids}>
            <Form.Label>Centros de custo</Form.Label>
            <FormCostCenterSelectField name="cost_center_ids" variant="input" />
          </Form.Group>
          <Form.Group as={Col} xs={columnSizes.paid_status}>
            <Form.Label>Incluir lançamentos</Form.Label>
            <FormPaidField name="paid_status" />
          </Form.Group>
          <Form.Group as={Col} xs={columnSizes.date_type}>
            <Form.Label>Mostrar por data de</Form.Label>
            <FormRadioField
              name="date_type"
              options={[
                { value: 'event_date', label: 'Pagamento' },
                { value: 'due_date', label: 'Competência' },
              ]}
              className={classNames({
                'mt-1': true,
              })}
            />
          </Form.Group>
          {showPrint && (
            <Form.Group
              as={Col}
              xs={columnSizes.controls}
              className="d-flex align-items-center justify-content-end"
            >
              <span className="d-flex">
                <ButtonGroup size="sm">
                  <Button
                    size="sm"
                    variant="default"
                    className="d-flex justify-content-center align-items-center"
                    onClick={handleFixUnfix}
                  >
                    {isFixed ? <TiPin size="1.2em" /> : <TiPinOutline size="1.2em" />}
                  </Button>
                  <PrintControls report_name={values.report_name} />
                  <Button
                    size="sm"
                    variant="default"
                    className="d-flex justify-content-center align-items-center"
                    onClick={() => generateExcel(values)}
                  >
                    <RiFileExcel2Line size="1.2em" />
                  </Button>
                  <PrintOptions />
                </ButtonGroup>
              </span>
            </Form.Group>
          )}
        </Form.Row>
      </Form>
    ),
    [columnSizes, isFixed, handleFixUnfix, generateExcel, values],
  );

  if (isMobile || isTablet) {
    return (
      <>
        <Button
          className="d-flex justify-content-center align-items-center"
          onClick={() => setActionSheetOpen(true)}
          variant="default"
          size="sm"
        >
          <IoFilterOutline size="1.2em" />
        </Button>
        <ActionSheet
          isOpen={actionSheetOpen}
          onToggle={() => setActionSheetOpen(!actionSheetOpen)}
          detent="content-height"
          prefersReducedMotion
        >
          <div>
            <div className="ml-3 mr-3 mb-3 d-flex justify-content-between align-items-center">
              <h5 className="m-0">Filtros</h5>
              <IoCloseCircle className="text-muted" size="2em" onClick={handleClose} />
            </div>
            <div className="p-3">{renderContent(false)}</div>
            <Button
              onClick={handleClose}
              className="w-100 m-0 p-0 mt-4 mb-4"
              variant="link"
            >
              Fechar
            </Button>
          </div>
        </ActionSheet>
      </>
    );
  }

  return (
    <Menu id="report-filters" className="pt-3 pl-3 pr-3 mb-3 no-print" isFixed={isFixed}>
      {renderContent(true)}
    </Menu>
  );
}

Filters.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  isDesktopMedium: PropTypes.bool.isRequired,
  isDesktopLarge: PropTypes.bool.isRequired,
  isDesktopExtraLarge: PropTypes.bool.isRequired,
  generateExcel: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default Filters;
