import { connect } from 'react-redux';

import entriesActionCreators from '_store/_actions/entries';
import accountsActionCreators from '_store/_actions/accounts';

import EntryLookup from './EntryLookup';

const mapStateToProps = (state) => ({
  isLoading: state.entries.isLoading,
  entries: state.entries.entries,
  total: state.entries.total,
});

const mapDispatchToProps = {
  onFetchEntries: entriesActionCreators.fetchEntries,
  onDeleteEntry: entriesActionCreators.deleteEntry,
  onFetchAccounts: accountsActionCreators.getAllAccounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryLookup);
