import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { DateTime } from 'luxon';
import { Formik } from 'formik';
import { IoCloseCircle } from 'react-icons/io5';
import { Col } from 'react-bootstrap';
import { MdFilterAlt } from 'react-icons/md';

import {
  Button,
  ActionSheet,
  FormButtonGroupField,
  FormGroup,
} from '_components/_core';

import {
  DATE_OPTIONS_MOBILE,
  EXPENSES_OPTIONS_MOBILE,
  PAYMENT_STATUS_OPTIONS_MOBILE,
} from '.';

import { StyledContainer } from './styles';

function TransactionFiltersMobile({
  transactionFilters,
  onUpdateTransactionFilters,
}) {
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);

  const handleFilter = useCallback((values) => {
    let params = {};

    if (values.date === 'WEEK') {
      params.event_date_start = DateTime.now().startOf('week').toFormat('yyyy-MM-dd');
      params.event_date_end = DateTime.now().startOf('week').toFormat('yyyy-MM-dd');
    } else if (values.date === 'TODAY') {
      params.event_date_start = DateTime.now().toFormat('yyyy-MM-dd');
      params.event_date_end = DateTime.now().toFormat('yyyy-MM-dd');
    }

    if (values.payment_status === 'PAYED') {
      params.paid = true;
    } else if (values.payment_status === 'ALL_TO_PAY') {
      params.paid = false;
    } else if (values.payment_status === 'EXPIRING_TODAY') {
      params.paid = false;
      params.event_date_start = DateTime.now().toFormat('yyyy-MM-dd');
      params.event_date_end = DateTime.now().toFormat('yyyy-MM-dd');
    } else if (values.payment_status === 'EXPIRED') {
      params.paid = false;
      params.event_date_end = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd');
    }

    if (values.expenses === 'FIXED_EXPENSE') {
      params.type = 'EXPENSE';
      params.sub_type = 'FIXED_EXPENSE';
    } else if (values.expenses === 'VARIABLE_EXPENSE') {
      params.type = 'EXPENSE';
      params.sub_type = 'VARIABLE_EXPENSE';
    } else if (values.expenses === 'PEOPLE') {
      params.type = 'EXPENSE';
      params.sub_type = 'PEOPLE';
    } else if (values.expenses === 'TAXES') {
      params.type = 'EXPENSE';
      params.sub_type = 'TAXES';
    } else if (values.expenses === 'INCOME') {
      params.type = 'INCOME';
    } else if (values.expenses === 'EXPENSE') {
      params.type = 'EXPENSE';
    } else if (values.expenses === 'TRANSFER') {
      params.type = 'TRANSFER';
    }

    params = {
      ...params,
      uiValues: values,
    };

    onUpdateTransactionFilters(params);
    setIsFilterOpen(false);
  }, [onUpdateTransactionFilters]);

  const handleToggleFilter = useCallback(() => {
    setIsFilterOpen(!isFilterOpen);
  }, [isFilterOpen]);

  return (
    <>
      <ActionSheet
        isOpen={isFilterOpen}
        onToggle={handleToggleFilter}
        detent="content-height"
        prefersReducedMotion
      >
        <Formik
          initialValues={{
            date: null,
            payment_status: null,
            expenses: null,
            ...transactionFilters.uiValues,
          }}
          onSubmit={handleFilter}
          enableReinitialize
        >
          {({ handleSubmit }) => (
            <StyledContainer isMobile>
              <div className="mb-3 d-flex justify-content-between align-items-center">
                <h5 className="m-0">
                  Filtrar
                </h5>
                <IoCloseCircle className="text-muted" size="2em" onClick={() => setIsFilterOpen(false)} />
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Row>
                  <FormGroup as={Col} xs={12} label="Data">
                    <FormButtonGroupField
                      label="Data"
                      name="date"
                      options={DATE_OPTIONS_MOBILE}
                      columns={2}
                    />
                  </FormGroup>
                </Form.Row>
                <Form.Row>
                  <FormGroup
                    as={Col}
                    xs={12}
                    label="Tipo de movimentação"
                  >
                    <FormButtonGroupField
                      label="Tipo de movimentação"
                      name="expenses"
                      options={EXPENSES_OPTIONS_MOBILE}
                      columns={2}
                    />
                  </FormGroup>
                </Form.Row>
                <Form.Row>
                  <FormGroup
                    as={Col}
                    xs={12}
                    label="Status de pagamento"
                  >
                    <FormButtonGroupField
                      label="Status de pagamento"
                      name="payment_status"
                      options={PAYMENT_STATUS_OPTIONS_MOBILE}
                      columns={2}
                    />
                  </FormGroup>
                </Form.Row>
                <Form.Row>
                  <FormGroup as={Col} xs={12}>
                    <Button variant="dark" className="w-100" onClick={handleSubmit}>
                      Filtrar
                    </Button>
                  </FormGroup>
                </Form.Row>
              </Form>
            </StyledContainer>
          )}
        </Formik>
      </ActionSheet>
      <Button
        size="sm"
        variant="default"
        className="d-flex justify-content-center align-items-center"
        onClick={() => setIsFilterOpen(true)}
      >
        <MdFilterAlt size="1.2em" onClick={() => setIsFilterOpen(true)} />
      </Button>
    </>
  );
}

TransactionFiltersMobile.defaultProps = {
  transactionFilters: {},
};

TransactionFiltersMobile.propTypes = {
  transactionFilters: PropTypes.object,
  onUpdateTransactionFilters: PropTypes.func.isRequired,
};

export default TransactionFiltersMobile;
