import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Card from '_components/_core/Card';
import Button from '_components/_core/Button';
import Table from '_components/_core/Table';

import { TABLE_COLUMNS } from '../../utils';
import AddAssociateModal from '../AddAssociateModal';
import DeleteConfirmModal from '../DeleteConfirmModal';

export default function Container({
  associateState,
  fetchAssociates,
  onAddAssociate,
  onUpdateAssociate,
  onDeleteAssociate,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedAssociate, setSelectedAssociate] = useState({});

  const { associates, ui } = associateState;
  const { isLoading } = ui;

  useEffect(() => {
    fetchAssociates();
  }, [fetchAssociates]);

  const handleModalOpen = () => {
    setSelectedAssociate({});
    setIsOpen(!isOpen);
  };

  const handleEditAssociate = (id) => {
    const associate = associates.find((item) => item.id === id);

    setSelectedAssociate(associate);
    setIsOpen(true);
  };

  const showDeleteConfirmation = (id) => {
    const associate = associates.find((item) => item.id === id);

    setSelectedAssociate(associate);
    setDeleteModalIsOpen(true);
  };

  const handleDeleteAssociate = () => {
    onDeleteAssociate(selectedAssociate.id);
    setDeleteModalIsOpen(false);
  };

  return (
    <Card
      title="Quadro Societário"
      description="Informe quem está no quadro societário da empresa"
      sideContent={<Button variant="primary" onClick={handleModalOpen}>Cadastrar Sócio</Button>}
    >
      <DeleteConfirmModal
        isOpen={deleteModalIsOpen}
        onHide={() => setDeleteModalIsOpen(false)}
        onDeleteConfirm={handleDeleteAssociate}
      />
      <AddAssociateModal
        isOpen={isOpen}
        associates={associates}
        isLoading={isLoading}
        selectedAssociate={selectedAssociate}
        onModalClose={() => setIsOpen(false)}
        onAddAssociate={onAddAssociate}
        onUpdateAssociate={onUpdateAssociate}
        getAllAssociates={fetchAssociates}
      />
      <Table
        columns={TABLE_COLUMNS}
        data={associates}
        onEdit={handleEditAssociate}
        onDelete={showDeleteConfirmation}
        striped
        hover
      />
    </Card>
  );
}

Container.propTypes = {
  associateState: PropTypes.object,
  fetchAssociates: PropTypes.func.isRequired,
  onAddAssociate: PropTypes.func.isRequired,
  onUpdateAssociate: PropTypes.func.isRequired,
  onDeleteAssociate: PropTypes.func.isRequired,
};
