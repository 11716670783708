import styled, { css } from 'styled-components';

import Alert from 'react-bootstrap/Alert';

export const StyledAlert = styled(Alert)`
  color: #1a3353;
  font-size: 13px;
  font-weight: 400;
  padding: 1.25rem 1rem;
  margin-bottom: 0;

  ${({ variant }) => variant === 'primary' && css`
    background-color: #f9f9fd;
    border: 1px solid #dee2e6;
  `}

  ${({ variant }) => variant === 'success' && css`
    background-color: #e6fff1;
    border: 1px solid #7cf7bc;
  `}

  ${({ variant }) => variant === 'info' && css`
    background-color: #f0f7ff;
    border: 1px solid #bad7ff;
  `}

  &&& {
    p {
      margin-bottom: 0;
    }
  }

`;

export const StyledAlertHeading = styled(Alert.Heading)`
  color: #1a3353;
  font-weight: 600;
  font-size: 16px;
`;
