import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import Container from 'react-bootstrap/Container';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { IoArrowBackOutline, IoArrowForwardOutline } from 'react-icons/io5';

import { PageHeader, Button } from '_components/_core';

import { GenerateDarf } from './components';

function IssueDarfs({
  activeCompany,
  onFetchManagerDarfs,
}) {
  const [selectedDate, setSelectedDate] = useState(
    () => DateTime.now().minus({ months: 1 }),
  );

  const handleYearIncreased = useCallback(() => {
    setSelectedDate(selectedDate.plus({ year: 1 }));
  }, [selectedDate]);

  const handleYearDecreased = useCallback(() => {
    setSelectedDate(selectedDate.minus({ year: 1 }));
  }, [selectedDate]);

  useEffect(() => {
    onFetchManagerDarfs({
      year: selectedDate.year,
    });
  }, [selectedDate, activeCompany, onFetchManagerDarfs]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Emissão de Guias"
        description="Faça a emissão de guias de impostos de forma rápida e fácil."
        fixedHeader
        sideContent={(
          <div className="float-right">
            <ButtonGroup className="mr-2">
              <Button variant="secondary" onClick={handleYearDecreased}>
                <IoArrowBackOutline />
              </Button>
              <Button variant="secondary">
                <b>{selectedDate.year}</b>
              </Button>
              <Button variant="secondary" onClick={handleYearIncreased}>
                <IoArrowForwardOutline />
              </Button>
            </ButtonGroup>
          </div>
        )}
        variant="small"
      />
      <GenerateDarf selectedDate={selectedDate} />
    </Container>
  );
}

IssueDarfs.defaultProps = {
  activeCompany: {},
};

IssueDarfs.propTypes = {
  activeCompany: PropTypes.object,
  onFetchManagerDarfs: PropTypes.func.isRequired,
};

export default IssueDarfs;
