export const subscriptionConstants = {
  FETCH_SUBSCRIPTION_PLANS_REQUEST: 'FETCH_SUBSCRIPTION_PLANS_REQUEST',
  FETCH_SUBSCRIPTION_PLANS_SUCCESS: 'FETCH_SUBSCRIPTION_PLANS_SUCCESS',
  FETCH_SUBSCRIPTION_PLANS_FAILURE: 'FETCH_SUBSCRIPTION_PLANS_FAILURE',
  CREATE_CHECKOUT_SESSION_REQUEST: 'CREATE_CHECKOUT_SESSION_REQUEST',
  CREATE_CHECKOUT_SESSION_SUCCESS: 'CREATE_CHECKOUT_SESSION_SUCCESS',
  CREATE_CHECKOUT_SESSION_FAILURE: 'CREATE_CHECKOUT_SESSION_FAILURE',
  FETCH_SUBSCRIPTION_STATUS_REQUEST: 'FETCH_SUBSCRIPTION_STATUS_REQUEST',
  FETCH_SUBSCRIPTION_STATUS_SUCCESS: 'FETCH_SUBSCRIPTION_STATUS_SUCCESS',
  FETCH_SUBSCRIPTION_STATUS_FAILURE: 'FETCH_SUBSCRIPTION_STATUS_FAILURE',
  CREATE_AUTHORIZED_CHECKOUT_SESSION_REQUEST: 'CREATE_AUTHORIZED_CHECKOUT_SESSION_REQUEST',
  CREATE_AUTHORIZED_CHECKOUT_SESSION_SUCCESS: 'CREATE_AUTHORIZED_CHECKOUT_SESSION_SUCCESS',
  CREATE_AUTHORIZED_CHECKOUT_SESSION_FAILURE: 'CREATE_AUTHORIZED_CHECKOUT_SESSION_FAILURE',
  UPDATE_PLAN_REQUEST: 'UPDATE_PLAN_REQUEST',
  UPDATE_PLAN_SUCCESS: 'UPDATE_PLAN_SUCCESS',
  UPDATE_PLAN_FAILURE: 'UPDATE_PLAN_FAILURE',
  CREATE_UPDATE_PAYMENT_SESSION_REQUEST: 'CREATE_UPDATE_PAYMENT_SESSION_REQUEST',
  CREATE_UPDATE_PAYMENT_SESSION_SUCCESS: 'CREATE_UPDATE_PAYMENT_SESSION_SUCCESS',
  CREATE_UPDATE_PAYMENT_SESSION_FAILURE: 'CREATE_UPDATE_PAYMENT_SESSION_FAILURE',
};

export default subscriptionConstants;
