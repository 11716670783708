import styled from 'styled-components';

export const FileIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 8px;

  color: #ccc;

  /* display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
   */
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
