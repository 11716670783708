import constants from '../../_constants/invoices.constants';
import service from '../../../services/invoices.service';

const getFiscalOverview = (params) => {
  return (dispatch) => {
    dispatch(request());

    service.getFiscalOverview(params).then(
      (response) => {
        const { data } = response;

        dispatch(success(data));
      },
    );
  };

  function request() {
    return {
      type: constants.GET_FISCAL_OVERVIEW_REQUEST,
      payload: { },
    };
  }
  function success(data) {
    return {
      type: constants.GET_FISCAL_OVERVIEW_SUCCESS,
      payload: data,
    };
  }
};

export default getFiscalOverview;
