import { connect } from 'react-redux';

import employeeActionCreators from '_store/_actions/employees';

import EmployeeRoles from './EmployeeRoles';

const mapStateToProps = (state) => ({
  employeeRoles: state.employees.employeeRoles,
  total: state.employees.totalRoles,
});

const mapDispatchToProps = {
  onFetchEmployeeRoles: employeeActionCreators.getEmployeeRoles,
  onAddEmployeeRole: employeeActionCreators.addEmployeeRole,
  onUpdateEmployeeRole: employeeActionCreators.updateEmployeeRole,
  onDeleteEmployeeRole: employeeActionCreators.deleteEmployeeRole,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeRoles);
