import HttpService from './http.service';

export default {
  getAllPlans,
  createCheckoutSession,
  createAuthorizedCheckoutSession,
  getUserSubscriptionStatus,
  updatePlan,
  createUpdatePaymentSession,
};

async function getAllPlans() {
  return HttpService.get('/subscription/plans');
}

async function createCheckoutSession(params) {
  return HttpService.post('/checkout-session', params);
}

async function createAuthorizedCheckoutSession(params) {
  return HttpService.post('/authorized-checkout-session', params);
}

async function getUserSubscriptionStatus() {
  return HttpService.get('/subscription');
}

async function updatePlan(params) {
  return HttpService.put('/subscription', params);
}

async function createUpdatePaymentSession() {
  return HttpService.post('/update-payment-session');
}
