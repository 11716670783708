import React, { useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';

import {
  Card,
  CardHeader,
  CardBody,
  Dropzone,
} from '_components/_core';

function CertificateTab() {
  const formik = useFormikContext();

  const handleFilesChanged = useCallback((files) => {
    if (isEmpty(files)) {
      return;
    }

    const [certificateFile] = files || [];

    const { name, type } = certificateFile;

    formik.setFieldValue('certificate_file', certificateFile);
    formik.setFieldValue('certificate_file_name', name);
    formik.setFieldValue('certificate_file_type', type);
  }, [formik]);

  return (
    <Card noBorders>
      <CardHeader title="Certificado Digital A1" description="Faça o upload do certificado digital A1 para sincronizar as Notas Fiscais da empresa." />
      <CardBody>
        <Form>
          <Form.Row>
            <Col xs lg="4">
              <Form.Group>
                <Form.Label>Enviar arquivo</Form.Label>
                <Dropzone onChange={handleFilesChanged} accept=".pfx, .p12, application/x-pkcs12" maxFiles={1} />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs lg="3">
              <Form.Group>
                <Form.Label>Senha do Certificado</Form.Label>
                <Form.Control
                  name="certificate_password"
                  placeholder="Senha do Certificado"
                  onChange={formik.handleChange}
                  value={formik.values.certificate_password}
                />
              </Form.Group>
            </Col>
            <Col xs lg="3">
              <Form.Group>
                <Form.Label>Confirmar Senha do Certificado</Form.Label>
                <Form.Control
                  name="certificate_password_confirmation"
                  placeholder="Confirmar Senha do Certificado"
                  onChange={formik.handleChange}
                  value={formik.values.certificate_password_confirmation}
                />
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
      </CardBody>
    </Card>
  );
}

export default CertificateTab;
