import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

const useTable = ({
  data,
  keyName = 'id',
  onFetchData,
  defaultFilters,
  defaultSorting,
  defaultPagination,
  requiredFilters,
  withSavedFilters = true,
  withInitialLoading = true,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const activeCompany = useSelector((state) => state.company.activeCompany);

  const [filters, setFilters] = useState(() => {
    if (!withSavedFilters) {
      return defaultFilters;
    }

    const pathName = window.location.pathname;
    const savedFilters = localStorage.getItem(`${pathName}_filters`);

    const localStorageFilters = JSON.parse(savedFilters);

    if (localStorageFilters) {
      return localStorageFilters;
    }

    return defaultFilters;
  });
  const [sorting, setSorting] = useState(() => {
    if (!withSavedFilters) {
      return defaultSorting;
    }

    const pathName = window.location.pathname;
    const savedSorting = localStorage.getItem(`${pathName}_sorting`);

    const localStorageSorting = JSON.parse(savedSorting);

    if (localStorageSorting) {
      return localStorageSorting;
    }

    return defaultSorting;
  });
  const [pagination, setPagination] = useState(defaultPagination);
  const [selectedItems, setSelectedItems] = useState([]);

  const [filtersVisible, setIsFiltersVisible] = useState(false);

  const handleItemSelected = useCallback((selectedItem, eraseAll = false) => {
    if (eraseAll) {
      setSelectedItems([]);
      return;
    }

    if (selectedItems.includes(selectedItem)) {
      setSelectedItems((previous) => [...previous.filter((p) => p !== selectedItem)]);
    } else {
      setSelectedItems((previous) => [...previous, selectedItem]);
    }
  }, [selectedItems]);

  const handleSelectAll = useCallback((e) => {
    e.preventDefault();
    // e.stopPropagation();

    if (isEmpty(selectedItems)) {
      setSelectedItems(data.map((i) => i[keyName]));
    } else {
      setSelectedItems([]);
    }
  }, [selectedItems, data, keyName]);

  const handleToggleFilters = useCallback(() => {
    setIsFiltersVisible((previous) => !previous);
  }, []);

  const updateFilters = async (filters) => {
    setFilters(() => ({
      ...filters,
    }));
  };

  const handleRefreshData = useCallback(({
    sorting: sortingParam,
    filters: filtersParam,
    pagination: paginationParam,
    activeCompany: activeCompanyParam,
  }) => {
    onFetchData({
      sorting: sortingParam,
      filters: filtersParam,
      pagination: paginationParam,
      activeCompany: activeCompanyParam,
    });
  }, [onFetchData]);

  const handleFilter = useCallback(async (filters, callback) => {
    await updateFilters(filters);

    const path = window.location.pathname;
    localStorage.setItem(`${path}_filters`, JSON.stringify(filters));

    handleRefreshData({
      sorting,
      filters,
      pagination: { ...pagination, currentPage: 1 },
      activeCompany,
    });

    if (callback) {
      callback();
    }

    setIsFiltersVisible(false);
    setPagination((previous) => ({ ...previous, currentPage: 1 }));
  }, [
    activeCompany,
    handleRefreshData,
    pagination,
    sorting,
  ]);

  const handleSorting = useCallback((sorting) => {
    handleRefreshData({
      sorting,
      filters,
      pagination,
      activeCompany,
    });

    setSorting(sorting);

    const path = window.location.pathname;
    localStorage.setItem(`${path}_sorting`, JSON.stringify(sorting));
  }, [handleRefreshData, activeCompany, filters, pagination]);

  const handlePageChange = useCallback((page) => {
    handleRefreshData({
      sorting,
      filters,
      pagination: { ...pagination, currentPage: page },
      activeCompany,
    });

    setPagination((previous) => ({ ...previous, currentPage: page }));
  }, [filters, pagination, sorting, activeCompany, handleRefreshData]);

  const handlePageSizeChange = useCallback((size) => {
    handleRefreshData({
      sorting,
      filters,
      pagination: { ...pagination, itemsPerPage: size, currentPage: 1 },
      activeCompany,
    });

    setPagination((previous) => ({ ...previous, itemsPerPage: size, currentPage: 1 }));
  }, [
    filters,
    pagination,
    sorting,
    activeCompany,
    handleRefreshData,
  ]);

  const hasRequiredFilters = useCallback(() => {
    if (isEmpty(requiredFilters)) {
      return true;
    }

    const existingFilters = Object.keys(filters);

    let hasAllFilters = true;

    requiredFilters.forEach((filterName) => {
      if (!existingFilters.includes(filterName)) {
        hasAllFilters = false;
      }
    });

    return hasAllFilters;
  }, [requiredFilters, filters]);

  useEffect(() => {
    if (isLoaded || !withInitialLoading) {
      return;
    }

    handleRefreshData({
      sorting,
      filters,
      pagination,
      activeCompany,
    });

    setIsLoaded(true);
  },
  [
    isLoaded,
    sorting,
    filters,
    hasRequiredFilters,
    pagination,
    activeCompany,
    handleRefreshData,
    withInitialLoading,
  ]);

  return {
    filters,
    pagination,
    sorting,
    selectedItems,
    displayFilters: filtersVisible,
    onToggleFilters: handleToggleFilters,
    onFilter: handleFilter,
    onSorting: handleSorting,
    onPageChange: handlePageChange,
    onPageSizeChange: handlePageSizeChange,
    onItemSelected: handleItemSelected,
    onSelectAll: handleSelectAll,
    onClearSelectedItems: () => setSelectedItems([]),
  };
};

export default useTable;
