import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import FORMATTERS from 'helpers/formatters';
import { Card, CardHeader, CardBody } from '_components/_core';

import { StyledDescription } from '../../styles';

function Address({ company }) {
  const {
    address_zip_code,
    address_street,
    address_number,
    address_district,
    address_complement,
    address_city,
    address_state,
  } = company || {};

  return (
    <Card>
      <CardHeader title="Endereço" />
      <CardBody>
        <Form>
          <Row>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>CEP</Form.Label>
                <StyledDescription column>
                  {address_zip_code}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Rua</Form.Label>
                <StyledDescription column>
                  {address_street}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Número</Form.Label>
                <StyledDescription column>
                  {address_number}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Bairro</Form.Label>
                <StyledDescription column>
                  {address_district}
                </StyledDescription>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Complemento</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.SIGN_IF_EMPTY(address_complement)}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Município</Form.Label>
                <StyledDescription column>
                  {address_city}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Estado</Form.Label>
                <StyledDescription column>
                  {address_state}
                </StyledDescription>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}

Address.propTypes = {
  company: PropTypes.object.isRequired,
};

export default Address;
