export const integrationsConstants = {
  FETCH_BANK_INTEGRATION_SETTINGS_REQUEST: 'FETCH_BANK_INTEGRATION_SETTINGS_REQUEST',
  FETCH_BANK_INTEGRATION_SETTINGS_SUCCESS: 'FETCH_BANK_INTEGRATION_SETTINGS_SUCCESS',
  FETCH_BANK_INTEGRATION_SETTINGS_FAILURE: 'FETCH_BANK_INTEGRATION_SETTINGS_FAILURE',

  ADD_BANK_INTEGRATION_SETTINGS_REQUEST: 'ADD_BANK_INTEGRATION_SETTINGS_REQUEST',
  ADD_BANK_INTEGRATION_SETTINGS_SUCCESS: 'ADD_BANK_INTEGRATION_SETTINGS_SUCCESS',
  ADD_BANK_INTEGRATION_SETTINGS_FAILURE: 'ADD_BANK_INTEGRATION_SETTINGS_FAILURE',

  CREATE_INTEGRATION_REQUEST: 'CREATE_INTEGRATION_REQUEST',
  CREATE_INTEGRATION_SUCCESS: 'CREATE_INTEGRATION_SUCCESS',
  CREATE_INTEGRATION_FAILURE: 'CREATE_INTEGRATION_FAILURE',

  DELETE_INTEGRATION_SETTINGS_REQUEST: 'DELETE_INTEGRATION_SETTINGS_REQUEST',
  DELETE_INTEGRATION_SETTINGS_SUCCESS: 'DELETE_INTEGRATION_SETTINGS_SUCCESS',
  DELETE_INTEGRATION_SETTINGS_FAILURE: 'DELETE_INTEGRATION_SETTINGS_FAILURE',

  UPDATE_INTEGRATION_REQUEST: 'UPDATE_INTEGRATION_REQUEST',
  UPDATE_INTEGRATION_SUCCESS: 'UPDATE_INTEGRATION_SUCCESS',
  UPDATE_INTEGRATION_FAILURE: 'UPDATE_INTEGRATION_FAILURE',
};

export default integrationsConstants;
