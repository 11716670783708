import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';
import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import CategorySelect from './CategorySelect';

const mapStateToProps = (state) => ({
  categories: state.companyTransactions.categories,
});

const mapDispatchToProps = {
  onFetchCompanies: companyActionCreators.fetchCompaniesForSelect,
  onCreateCategory: companyTransactionsActionCreators.createTransactionCategory,
  onDeleteCategory: companyTransactionsActionCreators.deleteCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategorySelect);
