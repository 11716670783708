import styled from 'styled-components';

export const StyledRow = styled.tr`

  td {
    padding: 8px !important;
    background-color: #e3e5e8;
    border-top: 2px solid lightgray;
    border-bottom: 2px solid lightgray;

  }

`;
