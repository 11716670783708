import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Menu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import ReactTooltip from 'react-tooltip';

import { ActivatedCompany } from '_components/_shared';
import { useMediaQuery } from 'helpers';
import useActiveCompany from '_store/_hooks/useActiveCompany';
import {
  SIDEBAR_CONTABILLS_MENU_ITEMS,
  SIDEBAR_CONTABILLS_FOOTER_ITEMS,
  SIDEBAR_CONTABILLS_EMPRESAS_MENU_ITEMS,
  SIDEBAR_CONTABILLS_EMPRESAS_FOOTER_ITEMS,
  getPropertyRecursive,
  handleSidebarOutsideClick,
  TABBAR_CONTABILLS_EMPRESAS_MENU_ITEMS,
} from './utilities';

import {
  Container,
  NavbarContent,
  MenuItemV2,
  StyledProSidebar,
  StyledSidebarContent,
  StyledSidebarFooter,
  StyledSubMenu,
  StyledMenuItem,
  MainMenu,
  LowerMenu,
  TabBar,
  ItemsContainer,
} from './styles';
import SettingsMenu from '../NavBar/components/SettingsMenu/SettingsMenuContainer';

function Sidebar({ collapsed, product, onSidebarToggle }) {
  useActiveCompany();

  const [isHovering, setIsHovering] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const { pathname } = useLocation();
  const { isMobile, isTablet } = useMediaQuery();
  const history = useHistory();

  const SIDEBAR_MENU_ITEMS = useMemo(() => {
    if (product === 'contabills-empresas') {
      return SIDEBAR_CONTABILLS_EMPRESAS_MENU_ITEMS;
    }

    return SIDEBAR_CONTABILLS_MENU_ITEMS;
  }, [product]);

  const SIDEBAR_FOOTER_ITEMS = useMemo(() => {
    if (product === 'contabills-empresas') {
      return SIDEBAR_CONTABILLS_EMPRESAS_FOOTER_ITEMS;
    }

    return SIDEBAR_CONTABILLS_FOOTER_ITEMS;
  }, [product]);

  const isSingleMenu = (menuItem) => isEmpty(menuItem.items);

  const isMenuActive = (menuItem) => pathname.includes(menuItem.path);

  const hasActiveChildren = (menuItem) => {
    const childrenPaths = getPropertyRecursive(menuItem, 'path').filter((path) => path !== null);

    return childrenPaths.includes(pathname);
  };

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  const handleClick = useCallback(() => {
    if (isMobile || isTablet) {
      onSidebarToggle();
    }
  }, [isMobile, isTablet, onSidebarToggle]);

  const renderSingleItem = (menuItem) => {
    const isActive = isMenuActive(menuItem);

    return (
      <>
        <ReactTooltip />
        <StyledMenuItem
          active={isActive}
          icon={menuItem.getIcon(isActive)}
          data-tip={collapsed ? menuItem.title : ''}
          data-place="right"
          data-effect="solid"
        >
          {menuItem.title}
          {menuItem.path && <Link to={menuItem.path} onClick={handleClick} />}
        </StyledMenuItem>

      </>
    );
  };

  const renderMenuItem = (menuItem) => {
    const isActive = isMenuActive(menuItem);
    const hasChildren = !isSingleMenu(menuItem);

    if (!hasChildren) {
      return renderSingleItem(menuItem);
    }

    const hasActiveChild = hasActiveChildren(menuItem);

    return (
      <StyledSubMenu
        title={menuItem.title}
        icon={menuItem.getIcon(isActive)}
        isActiveParent={hasActiveChild}
        level={menuItem.level}
        defaultOpen={hasActiveChild}
        key={`${menuItem.level}-${menuItem.title}`}
      >
        {menuItem.items.map((childItem) => renderMenuItem(childItem))}
      </StyledSubMenu>
    );
  };

  const renderSingleItemV2 = (menuItem, mobileItem = false) => {
    const isActive = isMenuActive(menuItem);

    return (
      <MenuItemV2
        isActive={isActive}
        isMobile={mobileItem}
        onClick={() => history.push(menuItem.path)}
      >
        <Link to={menuItem.path} onClick={handleClick}>
          <div>
            {menuItem.getIcon(isActive)}
          </div>
          {!mobileItem && (
            <p>
              {menuItem.title}
            </p>
          )}
        </Link>
      </MenuItemV2>
    );
  };

  useEffect(() => {
    handleSidebarOutsideClick(onSidebarToggle, isMobile, isTablet);
  }, [isMobile, isTablet, onSidebarToggle]);

  if (product === 'contabills') {
    return (
      <StyledProSidebar
        breakPoint="md"
        className="no-print"
        collapsed={(isMobile || isTablet) ? false : collapsed}
        toggled={collapsed}
      >
        <StyledSidebarContent>
          <ActivatedCompany
            widgetType={collapsed && (!isMobile && !isTablet) ? 'POPOVER' : 'SMALL'}
          />
          <Menu>
            {SIDEBAR_MENU_ITEMS.map(
              (menuItem) => renderMenuItem(menuItem),
            )}
          </Menu>
        </StyledSidebarContent>
        <StyledSidebarFooter>
          <Menu>
            {SIDEBAR_FOOTER_ITEMS.map(
              (menuItem) => renderMenuItem(menuItem),
            )}
          </Menu>
        </StyledSidebarFooter>
      </StyledProSidebar>
    );
  }

  if (isMobile) {
    return (
      <>
        <TabBar>
          <ItemsContainer>
            {TABBAR_CONTABILLS_EMPRESAS_MENU_ITEMS.map(
              (menuItem) => renderSingleItemV2(menuItem, true),
            )}
          </ItemsContainer>
        </TabBar>
      </>
    );
  }

  return (
    <Container
      className="no-print"
      collapsed={(isMobile || isTablet) ? false : collapsed}
      toggled={!collapsed}
    >
      <NavbarContent
        isHovering={isHovering || settingsOpen}
        isMobile={isMobile || isTablet}
        toggled={!collapsed || settingsOpen}
      >
        <MainMenu onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {SIDEBAR_MENU_ITEMS.map(
            (menuItem) => renderSingleItemV2(menuItem),
          )}
        </MainMenu>
        <LowerMenu onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {SIDEBAR_FOOTER_ITEMS.map(
            (menuItem) => renderSingleItemV2(menuItem),
          )}
          <SettingsMenu
            variant="sidebar"
            onOpenStateChange={(newValue) => {
              setSettingsOpen(newValue);

              if (!newValue) {
                setIsHovering(false);
              }
            }}
          />
        </LowerMenu>
      </NavbarContent>
    </Container>
  );
}

Sidebar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  onSidebarToggle: PropTypes.func.isRequired,
  product: PropTypes.string.isRequired,
};

export default Sidebar;
