import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { Modal, Button } from '_components/_core';

import useTransactions from '_components/Transactions/utilities/useTransactions';
import { InstalmentsForm, RecurrenceForm } from './components';
import { ModalFooter } from './styles';

function FrequencyModal({
  transaction,
  paymentPlan,
  isVisible,
  onModalToggle,
  onCreateRecurringTransaction,
  onUpdateRecurringTransaction,
  onCloseTransactionForm,
  onFindInstalmentsTransactions,
  selectedDate,
  blockedPeriod,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedInstalmentForEdit, setSelectedInstalmentForEdit] = useState(null);

  const [isFormValid, setIsFormValid] = useState(false);

  const { onRefreshBalance } = useTransactions();

  const initialValues = useMemo(
    () => ({
      id: transaction.id,
      frequency_main_id: transaction.frequency_main_id,
      event_date: transaction.event_date,
      description: transaction.description,
      amount: transaction.amount,
      recipient_id: transaction.recipient_id,
      category_id: transaction.category_id,
      paid: transaction.paid,
      instalment_count: 3,
      type: 'TOTAL_AMOUNT',
      frequency: transaction.frequency_type || 'MONTHLY',
      main_transaction: transaction,
      child_transactions: [],
    }),
    [transaction],
  );

  const isEditing = useMemo(() => {
    if (transaction) {
      return ['INSTALMENT', 'RECURRENT'].includes(transaction.payment_plan);
    }

    return false;
  }, [transaction]);

  const handleSubmitForm = useCallback(
    values => {
      setIsLoading(true);

      if (isEditing) {
        onUpdateRecurringTransaction(
          {
            ...values,
            payment_plan: paymentPlan,
            selectedDate,
          },
          () => {
            setIsLoading(false);
            onModalToggle();

            if (onCloseTransactionForm) {
              onCloseTransactionForm();
            }

            onRefreshBalance();
          },
        );
      } else {
        onCreateRecurringTransaction(
          {
            ...values,
            payment_plan: paymentPlan,
            selectedDate,
          },
          () => {
            setIsLoading(false);
            onModalToggle();

            if (onCloseTransactionForm) {
              onCloseTransactionForm();
            }

            onRefreshBalance();
          },
        );
      }
    },
    [
      isEditing,
      paymentPlan,
      selectedDate,
      onCreateRecurringTransaction,
      onUpdateRecurringTransaction,
      onModalToggle,
      onCloseTransactionForm,
      onRefreshBalance,
    ],
  );

  const renderModalFooter = useCallback(
    (handleSubmit, isValid) => (
      <ModalFooter>
        <Button
          id="btn-cancel-frequency-modal"
          variant="secondary"
          onClick={onModalToggle}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="dark"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={!isValid || isLoading || !isFormValid || selectedInstalmentForEdit}
          id="btn-save-frequency-modal"
        >
          Salvar
        </Button>
      </ModalFooter>
    ),
    [onModalToggle, isLoading, isFormValid, selectedInstalmentForEdit],
  );

  const modalTitle = useMemo(() => {
    if (!isEditing && paymentPlan === 'INSTALMENT') {
      return 'Parcelas';
    }

    if (isEditing && paymentPlan === 'INSTALMENT') {
      return 'Editar parcelas';
    }

    if (!isEditing && paymentPlan === 'RECURRENT') {
      return 'Repetir transação';
    }

    if (isEditing && paymentPlan === 'RECURRENT') {
      return 'Editar repetição';
    }

    return '';
  }, [paymentPlan, isEditing]);

  const handleSelectInstalmentForEdit = useCallback(instalment => {
    setSelectedInstalmentForEdit(instalment);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmitForm} enableReinitialize>
      {({ handleSubmit, isValid, values, setFieldValue }) => (
        <Modal
          title={modalTitle}
          isVisible={isVisible}
          toggleModal={onModalToggle}
          footer={renderModalFooter(handleSubmit, isValid)}
          noMaxWidth={paymentPlan === 'RECURRENT'}
        >
          <div>
            {paymentPlan === 'INSTALMENT' && (
              <>
                <InstalmentsForm
                  values={values}
                  setFieldValue={setFieldValue}
                  handleSubmit={handleSubmit}
                  isEditing={isEditing}
                  onFindInstalmentsTransactions={onFindInstalmentsTransactions}
                  onChangeFormValid={setIsFormValid}
                  onSelectInstalmentForEdit={handleSelectInstalmentForEdit}
                  blockedPeriod={blockedPeriod}
                />
              </>
            )}
            {paymentPlan === 'RECURRENT' && (
              <RecurrenceForm
                values={values}
                setFieldValue={setFieldValue}
                handleSubmit={handleSubmit}
                isEditing={isEditing}
                onChangeFormValid={setIsFormValid}
              />
            )}
          </div>
        </Modal>
      )}
    </Formik>
  );
}

FrequencyModal.defaultProps = {
  transaction: null,
  isVisible: false,
  selectedDate: null,
};

FrequencyModal.propTypes = {
  isVisible: PropTypes.bool,
  transaction: PropTypes.object,
  paymentPlan: PropTypes.string.isRequired,
  onModalToggle: PropTypes.func.isRequired,
  onCreateRecurringTransaction: PropTypes.func.isRequired,
  onUpdateRecurringTransaction: PropTypes.func.isRequired,
  onCloseTransactionForm: PropTypes.func.isRequired,
  onFindInstalmentsTransactions: PropTypes.func.isRequired,
  selectedDate: PropTypes.object,
};

export default FrequencyModal;
