import { SPECIAL_COLUMNS } from '_components/_core/Table';
import FORMATTERS from 'helpers/formatters';

import AddCredentialSchema from './AddCredentialSchema';
import EditCredentialSchema from './EditCredentialSchema';

export const DEFAULT_COLUMNS = [
  'id',
  'type',
  'login',
  'password',
  'created_at',
];

export const TABLE_COLUMNS = [
  {
    label: 'Ações',
    keyName: 'id',
    align: 'center',
    width: 5,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'Tipo',
    keyName: 'type',
    align: 'center',
    formatter: FORMATTERS.CREDENTIAL_TYPE,
    width: 5,
  },
  {
    label: 'Login',
    keyName: 'login',
    align: 'left',
    formatter: FORMATTERS.UPPERCASE,
    width: 10,
  },
  {
    label: 'Senha',
    keyName: 'password',
    align: 'left',
    width: 10,
  },
  {
    label: 'Criado em',
    keyName: 'created_at',
    align: 'center',
    formatter: FORMATTERS.DATE_DDMMYYYYHHMMSS,
    width: 10,
  },
];

export {
  AddCredentialSchema,
  EditCredentialSchema,
};
