import { useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

import { typeSubtypeStringToObject } from 'helpers';
import transactionsService from 'services/company-transactions.service';

const TRANSLATIONS = {
  [null]: {
    label: 'Receitas',
    type: 'INCOME',
  },
  FIXED_EXPENSE: {
    label: 'Despesas Fixas',
    type: 'EXPENSE',
  },
  VARIABLE_EXPENSE: {
    label: 'Despesas Variáveis',
    type: 'EXPENSE',
  },
  PEOPLE: {
    label: 'Pessoas',
    type: 'EXPENSE',
  },
  TAXES: {
    label: 'Impostos',
    type: 'EXPENSE',
  },
};

const SUB_TYPES = [
  null,
  'FIXED_EXPENSE',
  'VARIABLE_EXPENSE',
  'PEOPLE',
  'TAXES',
];

const useCategories = ({ typeSubType, categories }) => {
  const options = useMemo(() => {
    if (!categories) {
      return [];
    }

    const groupedBySubType = groupBy(categories, 'sub_type');

    const options = [];

    SUB_TYPES.forEach((key) => {
      const group = groupedBySubType[key];

      if (group) {
        options.push({
          label: TRANSLATIONS[key].label,
          type: TRANSLATIONS[key].type,
          sub_type: key,
          options: sortBy(group, ['description']).map((category) => ({
            value: category.id,
            label: category.description,
          })),
        });
      }
    });

    if (typeSubType && ['INCOME::'].includes(typeSubType)) {
      const { type, sub_type } = typeSubtypeStringToObject(typeSubType);

      return options.filter(
        (option) => option.type === type && option.sub_type === sub_type,
      );
    }

    if (typeSubType && typeSubType.includes('EXPENSE::')) {
      const { type } = typeSubtypeStringToObject(typeSubType);

      return options.filter(
        (option) => option.type === type,
      );
    }

    return options;
  }, [categories, typeSubType]);

  const handleLoadSuggestions = async (params, callback) => {
    const response = await transactionsService.getSuggestions({
      suggestion_for: 'category_id',
      ...params,
    });

    if (callback) {
      callback(response.data);
    }
  };

  return {
    categories,
    options,
    onLoadSuggestions: handleLoadSuggestions,
  };
};

export default useCategories;
