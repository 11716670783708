import React from 'react';
import PropTypes from 'prop-types';
import {
  FaFileAlt,
  FaFile,
} from 'react-icons/fa';
import { FcImageFile } from 'react-icons/fc';
// import { GoFilePdf } from 'react-icons/go';

import { FileIconContainer } from './styles';

const FileIcon = ({ fileName }) => {
  const getFileExtension = (file) => {
    const parts = file.split('.');
    return parts.length > 1 ? parts[parts.length - 1].toLowerCase() : '';
  };

  const renderIcon = (ext) => {
    switch (ext) {
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        return <FcImageFile size="1.8em" />;
      case 'pdf':
        return <FcImageFile size="1.8em" color="#b0403a" />;
      case 'txt':
        return <FaFileAlt size="1.8em" />;
      default:
        return <FaFile size="1.8em" />;
    }
  };

  const fileExtension = getFileExtension(fileName);

  return <FileIconContainer>{renderIcon(fileExtension)}</FileIconContainer>;
};

FileIcon.propTypes = {
  fileName: PropTypes.string.isRequired,
};

export default FileIcon;
