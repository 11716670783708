import styled from 'styled-components';

import { Button } from '_components/_core';

import { Dropdown } from 'react-bootstrap';

export const Option = styled.input`
  font-size: 14px;
  font-weight: 500;
  margin-right: 5px;

   :hover {
    cursor: pointer;
    color: #00784e;
  }
`;

export const StyledHeader = styled(Dropdown.Header)`
  font-size: 14px;
  font-weight: 500;
`;

export const StyledDropdownItem = styled(Dropdown.Item)`
  font-size: 14px;
  font-weight: 500;
   
  :hover, :active {
    color: #00784e;
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 0;

  :hover {
    background-color: #e3e3e3;
  }
`;
