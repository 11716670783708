import { connect } from 'react-redux';

import efdContributionsActionCreators from '_store/_actions/fiscal';

import EFDContributions from './EFDContributions';

const mapStateToProps = (state) => ({
  isLoading: state.fiscal.isLoading,
  efd: state.fiscal.efd,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onClearEFDContributions: efdContributionsActionCreators.clearEFDContributions,
  onFetchEFDContributions: efdContributionsActionCreators.getEFDContributions,
  onDownloadEFD: efdContributionsActionCreators.downloadEFDContributions,
};

export default connect(mapStateToProps, mapDispatchToProps)(EFDContributions);
