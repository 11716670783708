import React from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  FormTextAreaField,
} from '_components/_core';

function NotesTab() {
  return (
    <Card noBorders>
      <CardHeader
        title="Anotações"
        description="Aqui você pode fazer anotações relacionadas a esta empresa como particularidades, etc."
      />
      <CardBody>
        <FormTextAreaField name="notes" placeholder="Digite suas anotações aqui..." />
      </CardBody>
    </Card>
  );
}

export default NotesTab;
