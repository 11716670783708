import { push } from 'connected-react-router';

import constants from '../../_constants/entries.constants';
import service from '../../../services/entry.service';
import alertActionCreators from '../alert.actions';

const updateEntry = (id, entry) => {
  return (dispatch) => {
    dispatch(request(entry));

    service.update(id, entry).then(
      (response) => {
        dispatch(success(response.data));
        dispatch(push('/contabil/lancamentos/consultar'));
        dispatch(alertActionCreators.success('Lançamento atualizado com sucesso.'));
      },
      (error) => {
        dispatch(failure(error));
      },
    );
  };

  function request(entry) {
    return {
      type: constants.ENTRY_UPDATE_REQUEST,
      payload: { entry },
    };
  }
  function success(entry) {
    return {
      type: constants.ENTRY_UPDATE_SUCCESS,
      payload: { entry },
    };
  }
  function failure(error) {
    return {
      type: constants.ENTRY_UPDATE_FAILURE,
      payload: { error },
    };
  }
};

export default updateEntry;
