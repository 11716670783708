import { connect } from 'react-redux';

import taxesActionCreators from '_store/_actions/taxes';

import GenerateDarfsModal from './GenerateDarfsModal';

const mapStateToProps = (state) => ({
  isLoading: state.taxes.isLoading,
  activeCompany: state.company.activeCompany,
  darfs: state.taxes.darfs,
});

const mapDispatchToProps = {
  onGenerateDarfs: taxesActionCreators.generateDarfs,
  onFetchDarfs: taxesActionCreators.fetchDarfs,

};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateDarfsModal);
