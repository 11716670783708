import constants from '../../_constants/accountants.constants';
import service from '../../../services/accountants.service';

import getAllAccountants from './getAllAccountants';

const addAccountant = (accountant, callback) => {
  return (dispatch) => {
    dispatch(request());

    service.insert(accountant).then(
      (response) => {
        const createdAccountant = response.data;

        dispatch(success(createdAccountant));
        dispatch(getAllAccountants());

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.ACCOUNTANT_ADD_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.ACCOUNTANT_ADD_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.ACCOUNTANT_ADD_FAILURE,
      payload: error,
    };
  }
};

export default addAccountant;
