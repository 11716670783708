import Swal from 'sweetalert2';

import 'styles/confirm-dialog.css';
import { rest } from 'lodash';

export default {
  success,
  warning,
  error,
  showError,
  info,
  show,
  open,
};

const SUCCESS_COLOR = '#4CCEAC';
const DANGER_COLOR = 'rgb(219, 80, 74)';
const CONFIRM_BUTTON_TEXT = 'Confirmar';
const CANCEL_BUTTON_COLOR = '#587bc2';
const CANCEL_BUTTON_TEXT = 'Cancelar';

function success(title, message, onConfirm, onCancel, icon = 'success') {
  Swal.fire({
    heightAuto: false,
    backdrop: 'rgba(0,0,0,0.4)',
    title,
    html: message,
    icon,
    confirmButtonColor: SUCCESS_COLOR,
    confirmButtonText: CONFIRM_BUTTON_TEXT,
    cancelButtonColor: CANCEL_BUTTON_COLOR,
    cancelButtonText: CANCEL_BUTTON_TEXT,
    showCancelButton: true,
    ...rest,
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm(result);
    } else {
      onCancel(result);
    }
  });
}

function warning({
  title,
  message,
  onConfirm = () => {},
  onCancel = () => {},
  icon = 'warning',
  showCancelButton = true,
  confirmButtonText = CONFIRM_BUTTON_TEXT,
  ...rest
}) {
  Swal.fire({
    heightAuto: false,
    backdrop: 'rgba(0,0,0,0.4)',
    title,
    html: message,
    icon,
    confirmButtonColor: DANGER_COLOR,
    confirmButtonText,
    cancelButtonColor: CANCEL_BUTTON_COLOR,
    cancelButtonText: CANCEL_BUTTON_TEXT,
    showCancelButton,
    focusCancel: true,
    ...rest,
  }).then((result) => {
    if (result.isConfirmed) {
      if (onConfirm) {
        onConfirm(result);
      }
    } else if (onCancel) {
      onCancel(result);
    }
  });
}

function error(title, message, onConfirm = null, onCancel = null, icon = 'error') {
  Swal.fire({
    heightAuto: false,
    backdrop: 'rgba(0,0,0,0.4)',
    title,
    text: message,
    icon,
    confirmButtonColor: SUCCESS_COLOR,
    confirmButtonText: CONFIRM_BUTTON_TEXT,
  }).then((result) => {
    if (result.isConfirmed) {
      if (onConfirm) {
        onConfirm();
      }
    } else if (onCancel) {
      onCancel();
    }
  });
}

function showError({
  title,
  message,
  onConfirm = () => {},
  onCancel = () => {},
  icon = 'error',
  showCancelButton = true,
  confirmButtonText = CONFIRM_BUTTON_TEXT,
  ...rest
}) {
  Swal.fire({
    heightAuto: false,
    backdrop: 'rgba(0,0,0,0.4)',
    title,
    html: message,
    icon,
    confirmButtonColor: DANGER_COLOR,
    confirmButtonText,
    cancelButtonColor: CANCEL_BUTTON_COLOR,
    cancelButtonText: CANCEL_BUTTON_TEXT,
    showCancelButton,
    ...rest,
  }).then((result) => {
    if (result.isConfirmed) {
      if (onConfirm) {
        onConfirm();
      }
    } else if (onCancel) {
      onCancel();
    }
  });
}

function info({
  title,
  message,
  onConfirm = () => {},
  onCancel = () => {},
  icon = 'info',
  showCancelButton = true,
  confirmButtonText = CONFIRM_BUTTON_TEXT,
  ...rest
}) {
  Swal.fire({
    heightAuto: false,
    backdrop: 'rgba(0,0,0,0.4)',
    title,
    html: message,
    icon,
    confirmButtonColor: SUCCESS_COLOR,
    confirmButtonText,
    cancelButtonColor: CANCEL_BUTTON_COLOR,
    cancelButtonText: CANCEL_BUTTON_TEXT,
    showCancelButton,
    ...rest,
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm(result);
    } else {
      onCancel(result);
    }
  });
}

function show(title, message, onConfirm, onCancel, icon = 'question') {
  Swal.fire({
    heightAuto: false,
    backdrop: 'rgba(0,0,0,0.4)',
    title,
    html: message,
    icon,
    confirmButtonColor: SUCCESS_COLOR,
    confirmButtonText: CONFIRM_BUTTON_TEXT,
    cancelButtonColor: CANCEL_BUTTON_COLOR,
    cancelButtonText: CANCEL_BUTTON_TEXT,
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm();
    } else {
      onCancel();
    }
  });
}

function open({
  title,
  message,
  onConfirm = () => {},
  onCancel = () => {},
  icon = 'question',
  showCancelButton = true,
  confirmButtonText = CONFIRM_BUTTON_TEXT,
  cancelButtonText = CANCEL_BUTTON_TEXT,
  ...restProps
}) {
  Swal.fire({
    heightAuto: false,
    backdrop: 'rgba(0,0,0,0.4)',
    title,
    html: message,
    icon,
    confirmButtonColor: SUCCESS_COLOR,
    confirmButtonText,
    cancelButtonColor: CANCEL_BUTTON_COLOR,
    cancelButtonText,
    showCancelButton,
    ...restProps,
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm();
    } else {
      onCancel();
    }
  });
}
