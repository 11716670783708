import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IoFilterSharp } from 'react-icons/io5';

import {
  TableLookup,
  LoadingOverlay,
  Alert,
  Button,
  ActiveFilters,
} from '_components/_core';
import { Pagination, ItemsPerPage, TableActions } from '_components/_core/Table/components';
import { useTable } from '_components/_core/Table/utils';

import IssueInvoices from './IssueInvoices';
import DeleteIssuedInvoices from './DeleteIssuedInvoices';
import DuplicateIssuedInvoices from './DuplicateIssuedInvoices';
import { DEFAULT_COLUMNS, TABLE_COLUMNS, FILTERS_CONFIGURATION } from '../utilities';
import { Filters } from './components';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { field: 'created_at', order: 'desc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function InvoiceHistory({
  isLoading,
  issuedInvoices,
  issuedInvoicesTotal,
  onFetchIssuedInvoices,
  onEditIssuedInvoice,
  onConfirmInvoicesIssuing,
  onDeleteIssuedInvoices,
  onDeleteIssuedInvoice,
  onDuplicateIssuedInvoices,
}) {
  const [displayEditAlert, setDisplayEditAlert] = useState(false);
  const [displayDeleteAlert, setDisplayDeleteAlert] = useState(false);
  const [displayDuplicateAlert, setDisplayDuplicateAlert] = useState(false);

  const {
    filters,
    displayFilters,
    onToggleFilters,
    sorting,
    pagination,
    selectedItems,
    onFilter,
    onSorting,
    onPageChange,
    onPageSizeChange,
    onItemSelected,
    onSelectAll,
  } = useTable({
    ...tableConfig,
    data: issuedInvoices,
    onFetchData: onFetchIssuedInvoices,
  });

  const handleEdit = useCallback((id) => {
    const invoice = issuedInvoices.find((i) => i.id === id);
    const { status } = invoice;

    if (['user_authorized', 'issued', 'pending_city_hall_authorization'].includes(status)) {
      setDisplayEditAlert(true);
      return;
    }

    onEditIssuedInvoice(id);
  }, [issuedInvoices, onEditIssuedInvoice]);

  const handleDelete = useCallback((id) => {
    const invoice = issuedInvoices.find((i) => i.id === id);
    const { status } = invoice;

    if (['user_authorized', 'issued', 'pending_city_hall_authorization'].includes(status)) {
      setDisplayDeleteAlert(true);
      return;
    }

    onDeleteIssuedInvoice(id);
  }, [issuedInvoices, onDeleteIssuedInvoice]);

  const handleDuplicate = useCallback((params) => {
    const { invoice_ids } = params;

    const invoices = issuedInvoices.filter((i) => invoice_ids.includes(i.id));

    const invalid_status = ['user_authorized', 'pending_city_hall_authorization', 'issued_error'];

    if (invoices.some((i) => invalid_status.includes(i.status))) {
      setDisplayDuplicateAlert(true);
      return;
    }

    onDuplicateIssuedInvoices(params);
  }, [issuedInvoices, onDuplicateIssuedInvoices]);

  return (
    <>
      <Row>
        <Col>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="ml-2">
              <h4>Lista de Emissões</h4>
              <p>
                Listagem de Notas Fiscais que foram emitidas aqui pelo Contabills.
              </p>
              <ActiveFilters
                filtersConfiguration={FILTERS_CONFIGURATION}
                filters={filters}
                onFilter={onFilter}
              />
            </div>
            <Button
              variant="outline-primary"
              icon={<IoFilterSharp />}
              onClick={onToggleFilters}
            >
              Filtros
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Filters
            filters={filters}
            isVisible={displayFilters}
            onToggleFilters={onToggleFilters}
            onFilter={onFilter}
          />
          <LoadingOverlay active={isLoading}>
            {displayEditAlert && (
              <Alert
                title="Ação não permitida"
                variant="danger"
                on
                dismissible
                onClose={() => setDisplayEditAlert(false)}
                description={(
                  <p>
                    Não é permitido editar Notas Fiscais que tenham o status:
                    <ul className="mt-3">
                      <li>NA FILA DE EMISSÃO</li>
                      <li>AGUARDANDO PREFEITURA</li>
                      <li>EMITIDA COM SUCESSO</li>
                    </ul>
                  </p>
                )}
                className="mb-3"
              />
            )}
            {displayDeleteAlert && (
              <Alert
                title="Ação não permitida"
                variant="danger"
                on
                dismissible
                onClose={() => setDisplayDeleteAlert(false)}
                description={(
                  <p>
                    Não é permitido excluir Notas Fiscais que tenham o status:
                    <ul className="mt-3">
                      <li>NA FILA DE EMISSÃO</li>
                      <li>AGUARDANDO PREFEITURA</li>
                      <li>EMITIDA COM SUCESSO</li>
                    </ul>
                  </p>
                )}
                className="mb-3"
              />
            )}
            {displayDuplicateAlert && (
              <Alert
                title="Ação não permitida"
                variant="danger"
                on
                dismissible
                onClose={() => setDisplayDuplicateAlert(false)}
                description={(
                  <p>
                    Não é permitido duplicar Notas Fiscais que tenham o status:
                    <ul className="mt-3">
                      <li>NA FILA DE EMISSÃO</li>
                      <li>AGUARDANDO PREFEITURA</li>
                      <li>EMITIDA COM ERRO</li>
                    </ul>
                  </p>
                )}
                className="mb-3"
              />
            )}
            <TableLookup
              keyName="id"
              name="invoice-history-lookup-table"
              data={issuedInvoices}
              columns={TABLE_COLUMNS}
              defaultColumns={DEFAULT_COLUMNS}
              sorting={sorting}
              onSorting={onSorting}
              selectedItems={selectedItems}
              onItemSelected={onItemSelected}
              onSelectAll={onSelectAll}
              isLoading={isLoading}
              onEdit={handleEdit}
              onDelete={handleDelete}
              topDistance="calc(100vh - (66px + 8rem + 62px + 49px + 12em))"
              showColumnToggle
              showFooter
            />
            <TableActions
              filters={filters}
              onFilter={onFilter}
              selectedItems={selectedItems}
              tableActions={[
                DuplicateIssuedInvoices,
                IssueInvoices,
                DeleteIssuedInvoices,
              ]}
              customProps={{
                isLoading,
                issuedInvoices,
                onConfirmInvoicesIssuing,
                onDeleteIssuedInvoices,
                onDuplicateIssuedInvoices: handleDuplicate,
              }}
            />
          </LoadingOverlay>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <ItemsPerPage
            itemsPerPage={pagination.itemsPerPage}
            onChange={onPageSizeChange}
            className="mt-3"
            total={issuedInvoicesTotal}
            totalBeingShown={issuedInvoices.length}
          />
        </Col>
        <Col className="d-flex flex-row-reverse">
          <Pagination
            {...pagination}
            total={issuedInvoicesTotal}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
    </>
  );
}

InvoiceHistory.defaultProps = {
  isLoading: false,
  issuedInvoices: [],
  issuedInvoicesTotal: 0,
};

InvoiceHistory.propTypes = {
  isLoading: PropTypes.bool,
  issuedInvoices: PropTypes.array,
  issuedInvoicesTotal: PropTypes.number,
  onFetchIssuedInvoices: PropTypes.func.isRequired,
  onEditIssuedInvoice: PropTypes.func.isRequired,
  onConfirmInvoicesIssuing: PropTypes.func.isRequired,
  onDeleteIssuedInvoices: PropTypes.func.isRequired,
  onDeleteIssuedInvoice: PropTypes.func.isRequired,
  onDuplicateIssuedInvoices: PropTypes.func.isRequired,
};

export default InvoiceHistory;
