import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { InvoiceIssuingPaywall } from '_components/_shared';

import { ActivateForm } from './components';

function InvoicesTab({
  isLoading,
  company,
  subscription,
  onActivateInvoiceIssuing,
  onCompanyInfoChange,
}) {
  const location = useLocation();

  const [showActivateForm, setShowActivateForm] = useState(() => {
    const urlParams = new URLSearchParams(location.search);

    const tabName = urlParams.get('tab');

    if (tabName === 'emissao-nfse') {
      return true;
    }

    return false;
  });

  const isInvoiceIssuingActive = useMemo(() => {
    if (!company) {
      return false;
    }

    const { allow_invoice_issuing } = company || {};

    return allow_invoice_issuing;
  }, [company]);

  if (showActivateForm || isInvoiceIssuingActive) {
    return (
      <ActivateForm
        isLoading={isLoading}
        isActive={isInvoiceIssuingActive}
        company={company}
        subscription={subscription}
        onCancel={() => setShowActivateForm(false)}
        onActivateInvoiceIssuing={onActivateInvoiceIssuing}
        onCompanyInfoChange={onCompanyInfoChange}
      />
    );
  }

  if (!isInvoiceIssuingActive) {
    return <InvoiceIssuingPaywall onShowActivateForm={() => setShowActivateForm(true)} />;
  }

  return null;
}

InvoicesTab.defaultProps = {
  company: null,
  subscription: {},
  isLoading: false,
};

InvoicesTab.propTypes = {
  company: PropTypes.object,
  subscription: PropTypes.object,
  isLoading: PropTypes.bool,
  onActivateInvoiceIssuing: PropTypes.func.isRequired,
  onCompanyInfoChange: PropTypes.func.isRequired,
};

export default InvoicesTab;
