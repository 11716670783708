import React from 'react';

import { EmployeeInfoTab, EmployeeAddressTab } from '../components';

const getCreateEmployeeTabs = (props) => [
  {
    id: 1,
    title: 'Dados Cadastrais',
    content: <EmployeeInfoTab {...props} />,
  },
  {
    id: 2,
    title: 'Endereço',
    content: <EmployeeAddressTab {...props} />,
  },
];

export default getCreateEmployeeTabs;
