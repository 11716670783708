import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';
import { IoSwapHorizontalOutline } from 'react-icons/io5';
import pluralize from 'pluralize';

import { Button, FormAccountSelectField } from '_components/_core';

import { StyledModal, ModalFooter } from './styles';

const UpdateEntriesCounterpart = ({
  isLoading,
  selectedItems,
  onComplete,
  onUpdateMany,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleUpdateCounterpart = useCallback((values, { resetForm }) => {
    const params = {
      ids: selectedItems,
      type: 'accounts',
      payload: {
        ...values,
      },
    };

    onUpdateMany(params, () => {
      resetForm();
      setIsVisible(false);

      if (onComplete) {
        onComplete();
      }
    });
  }, [selectedItems, onUpdateMany, onComplete]);

  const renderModalFooter = useCallback((handleSubmit) => (
    <ModalFooter>
      <Button variant="secondary" onClick={() => setIsVisible(false)}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={isLoading}
      >
        {`Alterar contrapartida de ${selectedItems.length} ${pluralize('lançamento', selectedItems.length)}`}
      </Button>
    </ModalFooter>
  ), [isLoading, selectedItems]);

  return (
    <Formik
      initialValues={{
        account_id: null,
      }}
      onSubmit={handleUpdateCounterpart}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <>
          <Button
            className="mr-2 btn-sm"
            variant="light"
            onClick={() => setIsVisible(true)}
            icon={<IoSwapHorizontalOutline />}
          >
            Alterar contrapartida
          </Button>
          <StyledModal
            title="Alterar Contrapartida"
            isVisible={isVisible}
            toggleModal={() => setIsVisible(false)}
            footer={renderModalFooter(handleSubmit)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>Nova contrapartida</Form.Label>
                  <FormAccountSelectField name="account_id" placeholder="Selecionar uma nova contrapartida" creatable="account" />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default UpdateEntriesCounterpart;

UpdateEntriesCounterpart.defaultProps = {
  isLoading: false,
  onComplete: null,
};

UpdateEntriesCounterpart.propTypes = {
  isLoading: PropTypes.bool,
  selectedItems: PropTypes.array.isRequired,
  onComplete: PropTypes.func,
  onUpdateMany: PropTypes.func.isRequired,

};
