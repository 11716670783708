import React from 'react';
import PropTypes from 'prop-types';

import { AddEmployeeRoleModal } from '_components/Settings/components/EmployeeRoles/components';
import { AddEmployeeJourneyModal } from '_components/Settings/components/EmployeeJourneys/components';
import { AccountFormModal } from '_components/Accounting/Accounts/components';
import { AddPersonModal } from '_components/Fiscal/Invoices/Create/components';

const CreatableSelectModal = ({
  isVisible,
  onModalToggle,
  onAddEmployeeRole,
  onUpdateEmployeeRole,
  creatable,
  onAddEmployeeJourney,
  onUpdateEmployeeJourney,
  onAddAccount,
  onFetchAccounts,
  isLoadingAccount,
  onFetchPersons,
  onAddPerson,
  onFetchStates,
  onFetchCities,
  onFetchCep,
  states,
  cities,
  activeCompany,
  userInputValue,
  isLoadingPerson,
  isLoadingCities,
  isLoadingStates,
  isLoadingCep,
}) => {
  if (creatable === 'employee_role') {
    return (
      <AddEmployeeRoleModal
        isVisible={isVisible}
        employeeRole={null}
        onModalToggle={onModalToggle}
        onAddEmployeeRole={onAddEmployeeRole}
        onUpdateEmployeeRole={onUpdateEmployeeRole}
        userInputValue={userInputValue}
      />
    );
  }

  if (creatable === 'employee_journey') {
    return (
      <AddEmployeeJourneyModal
        isVisible={isVisible}
        employeeJourney={null}
        onModalToggle={onModalToggle}
        onAddEmployeeJourney={onAddEmployeeJourney}
        onUpdateEmployeeJourney={onUpdateEmployeeJourney}
        userInputValue={userInputValue}
      />
    );
  }

  if (creatable === 'account') {
    return (
      <AccountFormModal
        isVisible={isVisible}
        account={null}
        onModalToggle={onModalToggle}
        onAddAccount={onAddAccount}
        onFetchAccounts={onFetchAccounts}
        isLoading={isLoadingAccount}
        userInputValue={userInputValue}
      />
    );
  }

  if (creatable === 'person') {
    return (
      <AddPersonModal
        isVisible={isVisible}
        person={null}
        onModalToggle={onModalToggle}
        onAddPerson={onAddPerson}
        onFetchPersons={onFetchPersons}
        onFetchStates={onFetchStates}
        onFetchCities={onFetchCities}
        onFetchCep={onFetchCep}
        states={states}
        cities={cities}
        activeCompany={activeCompany}
        userInputValue={userInputValue}
        isLoading={isLoadingPerson}
        isLoadingCities={isLoadingCities}
        isLoadingStates={isLoadingStates}
        isLoadingCep={isLoadingCep}
      />
    );
  }
  return null;
};

export default CreatableSelectModal;

CreatableSelectModal.defaultProps = {
  isVisible: false,
  userInputValue: '',
};

CreatableSelectModal.propTypes = {
  isVisible: PropTypes.bool,
  onModalToggle: PropTypes.func.isRequired,
  onAddEmployeeRole: PropTypes.func,
  onUpdateEmployeeRole: PropTypes.func,
  creatable: PropTypes.string,
  onAddEmployeeJourney: PropTypes.func,
  onUpdateEmployeeJourney: PropTypes.func,
  onAddAccount: PropTypes.func,
  onFetchAccounts: PropTypes.func,
  isLoadingAccount: PropTypes.bool,
  onAddPerson: PropTypes.func,
  onFetchPersons: PropTypes.func,
  onFetchStates: PropTypes.func,
  onFetchCities: PropTypes.func,
  onFetchCep: PropTypes.func,
  states: PropTypes.array,
  cities: PropTypes.array,
  activeCompany: PropTypes.object,
  userInputValue: PropTypes.string,
  isLoadingPerson: PropTypes.bool,
  isLoadingCities: PropTypes.bool,
  isLoadingStates: PropTypes.bool,
  isLoadingCep: PropTypes.bool,
};
