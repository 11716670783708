import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Dropzone,
  FormTextField,
  FormSelectField,
  LoadingOverlay,
} from '_components/_core';

import ValidationSchema from './utilities/ValidationSchema';
import ActiveState from '../ActiveState/ActiveState';

function ActivateForm({
  company,
  subscription,
  isLoading,
  isActive,
  onCancel,
  onActivateInvoiceIssuing,
  onCompanyInfoChange,
}) {
  const {
    id: company_id,
    company_name,
    trading_name,
    document_number,
    municipal_registration,
  } = company || {};

  const { plan_price } = subscription || {};

  const handleFilesChanged = useCallback((files, setFieldValue) => {
    if (isEmpty(files)) {
      return;
    }

    const [certificateFile] = files || [];

    let encoded = certificateFile.toString().replace(/^data:(.*,)?/, '');

    if ((encoded.length % 4) > 0) {
      encoded += '='.repeat(4 - (encoded.length % 4));
    }

    setFieldValue('certificate_file', encoded);
  }, []);

  const handleActiveInvoiceIssuing = useCallback((values) => {
    if (!company_id) {
      return;
    }

    onActivateInvoiceIssuing({
      company_id,
      ...values,
      new_plan_price: plan_price + 50,
    }, (updatedCompany) => {
      onCompanyInfoChange(updatedCompany);
    });
  }, [onActivateInvoiceIssuing, onCompanyInfoChange, plan_price, company_id]);

  if (isActive) {
    return <ActiveState company={company} />;
  }

  return (
    <Formik
      initialValues={{
        certificate_file: '',
        certificate_password: '',
        certificate_password_confirmation: '',
        company_name,
        trading_name,
        document_number,
        tax_regime: 1,
        municipal_registration: municipal_registration || '',
      }}
      onSubmit={handleActiveInvoiceIssuing}
      validationSchema={ValidationSchema}
      enableReinitialize
      validateOnMount
    >
      {({ isValid, handleSubmit, setFieldValue }) => (
        <>
          <LoadingOverlay active={isLoading}>
            <Card className="mt-3">
              <CardHeader
                title="Ativar Emissão de NFS-e"
                description="Complete as informações necessárias para ativar a emissão de Notas para este cliente"
              />
              <Form onSubmit={handleSubmit}>
                <CardBody>
                  <Form.Row>
                    <Col xs lg="2">
                      <Form.Group>
                        <Form.Label>CNPJ</Form.Label>
                        <FormTextField
                          name="document_number"
                          placeholder="CNPJ"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col xs lg="4">
                      <Form.Group>
                        <Form.Label>Razão Social</Form.Label>
                        <FormTextField
                          name="company_name"
                          placeholder="Razão Social"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col xs lg="3">
                      <Form.Group>
                        <Form.Label>Nome Fantasia</Form.Label>
                        <FormTextField
                          name="trading_name"
                          placeholder="Nome Fantasia"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col xs lg="3">
                      <Form.Group>
                        <Form.Label>Inscrição Municipal</Form.Label>
                        <FormTextField
                          name="municipal_registration"
                          placeholder="Inscrição Municipal"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs lg="3">
                      <Form.Group>
                        <Form.Label>Tipo de Regime de Tributação</Form.Label>
                        <FormSelectField
                          name="tax_regime"
                          placeholder="Regime de Tributação"
                          options={[
                            { label: '1 - Simples Nacional', value: 1 },
                            { label: '2 - Simples Nacional - Excesso de sublimite de receita bruta', value: 2 },
                            { label: '3 - Regime Normal', value: 3 },
                          ]}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs lg="12">
                      <Form.Group>
                        <Form.Label>Certificado Digital A1</Form.Label>
                        <Dropzone
                          onChange={(files) => handleFilesChanged(files, setFieldValue)}
                          accept=".pfx, .p12, application/x-pkcs12"
                          maxFiles={1}
                          readAsBase64
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs lg="3">
                      <Form.Group>
                        <Form.Label>Senha do Certificado</Form.Label>
                        <FormTextField
                          name="certificate_password"
                          placeholder="Senha do Certificado"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs lg="3">
                      <Form.Group>
                        <Form.Label>Confirmar Senha do Certificado</Form.Label>
                        <FormTextField
                          name="certificate_password_confirmation"
                          placeholder="Confirmar Senha do Certificado"
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </CardBody>
                <CardFooter className="d-flex justify-content-between">
                  <Button
                    disabled={!isValid || isLoading}
                    isLoading={isLoading}
                    className="mr-3"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Confirmar Ativação
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => onCancel()}
                  >
                    Cancelar
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </LoadingOverlay>
        </>
      )}
    </Formik>
  );
}

ActivateForm.defaultProps = {
  company: null,
  subscription: {},
  isActive: false,
  isLoading: false,
};

ActivateForm.propTypes = {
  company: PropTypes.object,
  subscription: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  isLoading: PropTypes.bool,
  onActivateInvoiceIssuing: PropTypes.func.isRequired,
  onCompanyInfoChange: PropTypes.func.isRequired,
};

export default ActivateForm;
