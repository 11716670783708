import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const changeTransferOriginAccount = (transaction, account_id_origin) => {
  return async (dispatch) => {
    dispatch(request(transaction, account_id_origin));

    const newTransaction = {
      ...transaction,
      transfer_details: {
        ...transaction.transfer_details,
        account_id_origin,
      },
    };

    service.updateTransaction(transaction.id, newTransaction).then(
      (response) => {
        dispatch(success(response));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(transaction, account_id_origin) {
    return {
      type: constants.CHANGE_TRANSACTION_TRANSFER_ORIGIN_ACCOUNT_REQUEST,
      payload: {
        transaction,
        account_id_origin,
      },
    };
  }
  function success(response) {
    return {
      type: constants.CHANGE_TRANSACTION_TRANSFER_ORIGIN_ACCOUNT_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.CHANGE_TRANSACTION_TRANSFER_ORIGIN_ACCOUNT_FAILURE,
      payload: error,
    };
  }
};

export default changeTransferOriginAccount;
