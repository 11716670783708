import useTable from './useTable';
import useTableV2 from './useTableV2';

const SPECIAL_COLUMNS = {
  RADIO: 'RADIO',
  CHECKBOX: 'CHECKBOX',
  ACTIONS: 'ACTIONS',
  REDIRECT: 'REDIRECT',
};

export {
  useTable,
  useTableV2,
  SPECIAL_COLUMNS,
};

export default SPECIAL_COLUMNS;
