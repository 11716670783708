import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import {
  Checkbox,
  BalanceContainer,
  LineItem,
} from '../../styles';

function TagItem({
  tag,
  selectedTagIds,
  variant,
  isMultiple,
  displayPrincipalStar,
  onTagSelected,
}) {
  const {
    id,
    is_principal,
    description,
  } = tag || {};

  const isChecked = useCallback((tag_id) => {
    if (isEmpty(selectedTagIds)) {
      return false;
    }

    return selectedTagIds.includes(tag_id);
  }, [selectedTagIds]);

  const isTagActive = useCallback((tag_id) => {
    if (isEmpty(selectedTagIds)) {
      return false;
    }

    return selectedTagIds.includes(tag_id);
  }, [selectedTagIds]);

  const handleCheckboxClick = useCallback((e) => {
    e.stopPropagation();

    onTagSelected(id);
  }, [id, onTagSelected]);

  const handleTagSelected = useCallback(() => {
    onTagSelected(id);
  }, [id, onTagSelected]);

  const renderDescription = useCallback(() => {
    if (!description) {
      return null;
    }

    if (!displayPrincipalStar) {
      <span>
        {description}
      </span>;
    }

    let className = '';

    if (variant === 'default' && !is_principal) {
      className = 'ml-4';
    }

    return (
      <span className={className}>
        {description}
      </span>
    );
  }, [description, displayPrincipalStar, is_principal, variant]);

  return (
    <LineItem
      key={id}
      eventKey={id}
      onSelect={() => handleTagSelected()}
      className="d-flex justify-content-start align-items-center"
      variant={variant}
      active={isTagActive(id)}
    >
      {isMultiple && (
        <Checkbox
          key={`checkbox-${id}`}
          type="checkbox"
          checked={isChecked(id)}
          onClick={(e) => handleCheckboxClick(e, id)}
        />
      )}
      <BalanceContainer className={isMultiple ? 'ml-3' : ''}>
        <span>
          {renderDescription()}
        </span>
      </BalanceContainer>
    </LineItem>
  );
}

TagItem.defaultProps = {
  isMultiple: true,
  variant: 'default',
  displayPrincipalStar: false,
  selectedTagIds: [],
};

TagItem.propTypes = {
  selectedTagIds: PropTypes.array,
  tag: PropTypes.object.isRequired,
  isMultiple: PropTypes.bool,
  displayPrincipalStar: PropTypes.bool,
  variant: PropTypes.string,
  onTagSelected: PropTypes.func.isRequired,
};

export default TagItem;
