import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import ReceiptEditor from '_components/Settings/components/Receipts/ReceiptsContainer';
import SignIn from '../_components/SignIn/SignInContainer';
import SignUp from '../_components/SignUp/SignUpContainer';

import PasswordReset from '../_components/PasswordReset/PasswordResetContainer';
import PasswordUpdate from '../_components/PasswordUpdate/PasswordUpdateContainer';
import CompleteSignUp from '../_components/CompleteSignUp/CompleteSignUpContainer';
import SignUpConfirmation from '../_components/SignUpConfirmation/SignUpConfirmationContainer';

import ActivateSubscription from '../_components/Financial/ActivateSubscription/ActivateSubscriptionContainer';

import Dashboard from '../_components/Dashboard/DashboardContainer';

import Onboarding from '../_components/Financial/Onboarding/OnboardingContainer';

import BankStatementImport from '../_components/Financial/BankStatement/Import/ImportContainer';
import BankStatementReconcile from '../_components/Financial/BankStatement/Reconcile/ReconcileContainer';
import Reports from '../_components/Financial/Reports/ReportsContainer';
import DataUpload from '../_components/Financial/DataUpload/DataUpload';
import TransactionsUpload from '../_components/Financial/TransactionsUpload/TransactionsUploadContainer';
import ContactsUpload from '../_components/Financial/ContactsUpload/ContactsUploadContainer';
import Transactions from '../_components/Transactions/TransactionsContainer';
import Contacts from '../_components/Financial/Contacts/ContactsContainer';
import CreateCompany from '../_components/Financial/Companies/Create/CreateContainer';
import TransactionReceipt from '../_components/Financial/TransactionReceipt/TransactionReceiptContainer';
import UserPermissions from '../_components/Permissions/PermissionsContainer';

import Logs from '../_components/Settings/components/Logs/LogsContainer';
import Search from '../_components/Financial/Search/SearchContainer';

import AllowedPeriod from '../_components/Settings/components/AllowedPeriod/AllowedPeriodContainer';

import {
  Subscription,
  Profile,
  Users,
  DeleteFinancialData,
  Accounts,
  Categories,
  CostsCenter,
  Tags,
  Companies,
  ExportFinancialData,
  Preferences,
  Payments,
} from '../_components/Settings/components';

import { isAuthenticated } from '../services/auth.service';

import AuthorizedRoute from './AuthorizedRoute';
import UnauthorizedRoute from './UnauthorizedRoute';
import FullWidthRoute from './FullWidthRoute';

const Routes = () => (
  <Switch>
    <Route exact path="/">
      <Redirect to={isAuthenticated() ? '/dashboard' : '/entrar'} />
    </Route>
    <UnauthorizedRoute path="/entrar" component={SignIn} title="Entrar" />
    <UnauthorizedRoute path="/cadastrar" component={SignUp} title="Cadastrar" />
    <UnauthorizedRoute path="/recuperar-senha" component={PasswordReset} title="Recuperar Senha" />
    <UnauthorizedRoute path="/redefinir-senha" component={PasswordUpdate} title="Redefinir Senha" />
    <UnauthorizedRoute path="/completar-cadastro" component={CompleteSignUp} title="Completar Cadastro" />
    <UnauthorizedRoute path="/confirmar-email" component={SignUpConfirmation} title="Validar E-mail" />

    <UnauthorizedRoute path="/ativar-assinatura" component={ActivateSubscription} title="Ativar Assinatura" />

    <FullWidthRoute path="/onboarding" component={Onboarding} title="Começar" />

    <AuthorizedRoute path="/dashboard" component={Dashboard} title="Dashboard" />

    <AuthorizedRoute path="/relatorios" component={Reports} title="Relatórios" />
    <AuthorizedRoute path="/contatos" component={Contacts} title="Contatos" showFooter={false} showFooterMobile={false} fullHeight />

    <AuthorizedRoute path="/importar" component={DataUpload} title="Importar" />
    <AuthorizedRoute path="/importar-movimentacoes" component={TransactionsUpload} title="Importar Movimentações" />
    <AuthorizedRoute path="/importar-extrato" component={BankStatementImport} title="Importar Extrato Bancário" />
    <AuthorizedRoute path="/importar-contatos" component={ContactsUpload} title="Importar Contatos" />
    <AuthorizedRoute path="/conciliar-extrato" component={BankStatementReconcile} title="Importar Lançamentos" />

    <AuthorizedRoute path="/busca" component={Search} title="Busca" />

    <AuthorizedRoute path="/transacoes" component={Transactions} showFooterMobile={false} title="Transações" />

    <FullWidthRoute path="/nova-empresa" component={CreateCompany} title="Nova Empresa" />

    <AuthorizedRoute path="/meu-perfil" component={Profile} title="Meu Perfil" />
    <AuthorizedRoute path="/preferencias" component={Preferences} title="Preferências" />
    <AuthorizedRoute path="/pagamentos" component={Payments} title="Pagamentos" />
    <AuthorizedRoute path="/meu-plano" component={Subscription} title="Meu Plano" />
    <AuthorizedRoute path="/usuarios" component={Users} title="Usuários" />
    <AuthorizedRoute path="/empresas" component={Companies} title="Empresas" />
    <AuthorizedRoute path="/contas-bancarias" component={Accounts} title="Contas Bancárias" />
    <AuthorizedRoute path="/categorias" component={Categories} title="Categorias" />
    <AuthorizedRoute path="/centros-de-custo" component={CostsCenter} title="Centros de Custo" />
    <AuthorizedRoute path="/tags" component={Tags} title="Tags" />
    <AuthorizedRoute path="/exportar-dados" component={ExportFinancialData} title="Exportar Dados" />
    <AuthorizedRoute path="/excluir-dados" component={DeleteFinancialData} title="Excluir Dados" />

    <AuthorizedRoute path="/logs" component={Logs} title="Logs" />

    <AuthorizedRoute path="/permissoes" component={UserPermissions} title="Permissões" />

    <AuthorizedRoute path="/recibos" component={ReceiptEditor} title="Recibos" />
    <AuthorizedRoute path="/recibo" component={TransactionReceipt} title="Recibo" />

    <AuthorizedRoute path="/bloquear-periodo" component={AllowedPeriod} title="Bloquear período" />

  </Switch>
);

export default Routes;
