import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';

function CashflowOverview({
  account_ids,
  cashflow,
  competence,
  displayMonthResult,
  displayUnpaid,
  total_paid_incomes_label,
  total_unpaid_incomes_label,
  total_income_label,
  total_paid_expenses_label,
  total_unpaid_expenses_label,
  total_expense_label,
  canViewIncomes,
  canViewExpenses,
  canViewForecast,
}) {
  const info = useMemo(() => {
    if (isEmpty(account_ids) || isEmpty(cashflow)) {
      return {};
    }

    // group by account id and sum all properties
    const grouped = account_ids.reduce((acc, accountId) => {
      if (!has(cashflow, accountId)) {
        return acc;
      }

      const accountCashflow = cashflow[accountId];

      if (!has(accountCashflow, competence)) {
        return acc;
      }

      const monthCashflow = accountCashflow[competence];

      return {
        total_paid_incomes: acc.total_paid_incomes + monthCashflow.total_paid_incomes,
        total_unpaid_incomes: acc.total_unpaid_incomes + monthCashflow.total_unpaid_incomes,
        total_income: acc.total_income + monthCashflow.total_income,
        total_paid_expenses: acc.total_paid_expenses + monthCashflow.total_paid_expenses,
        total_unpaid_expenses: acc.total_unpaid_expenses + monthCashflow.total_unpaid_expenses,
        total_expense: acc.total_expense + monthCashflow.total_expense,
      };
    }, {
      total_paid_incomes: 0,
      total_unpaid_incomes: 0,
      total_income: 0,
      total_paid_expenses: 0,
      total_unpaid_expenses: 0,
      total_expense: 0,
    });

    return grouped;
  }, [cashflow, competence, account_ids]);

  const incomeProgress = useMemo(() => {
    if (isEmpty(info)) {
      return 0;
    }

    return (info.total_paid_incomes / info.total_income) * 100;
  }, [info]);

  const expenseProgress = useMemo(() => {
    if (isEmpty(info)) {
      return 0;
    }

    return (info.total_paid_expenses / info.total_expense) * 100;
  }, [info]);

  const Line = ({
    label,
    value,
    muted,
    canView = true,
  }) => (
    <small
      style={{
        fontSize: '11.9px',
      }}
      className={classNames('d-flex justify-content-between align-items-center mt-2', muted ? 'text-muted' : '')}
    >
      <span>
        {label}
      </span>
      <span>
        <strong>
          {canView ? FORMATTERS.NUMBER(value) : '---'}
        </strong>
      </span>
    </small>
  );

  return (
    <Col>
      {displayMonthResult && (
        <>
          <p>
            RESULTADO PREVISTO PARA O MÊS
          </p>
          <h2 className={classNames({
            'text-muted': Math.abs(info.total_income - info.total_expense) === 0,
          })}
          >
            {canViewForecast ? FORMATTERS.NUMBER(info.total_income - info.total_expense) : '---'}
          </h2>
        </>
      )}
      <Row className="mt-4 p-0">
        <Col xs="6">
          <p>RECEBIMENTOS</p>
          <ProgressBar animated now={incomeProgress || 0} variant="success" />
          <Line
            label={total_paid_incomes_label}
            value={info.total_paid_incomes}
            canView={canViewIncomes}
          />
          {displayUnpaid && (
            <Line
              label={total_unpaid_incomes_label}
              value={info.total_unpaid_incomes}
              canView={canViewIncomes}
            />
          )}
          <Line
            label={total_income_label}
            value={info.total_income}
            canView={canViewIncomes}
            muted
          />
        </Col>
        <Col xs="6">
          <p>
            DESPESAS
          </p>
          <ProgressBar animated now={expenseProgress || 0} variant="danger" />
          <Line
            label={total_paid_expenses_label}
            value={info.total_paid_expenses}
            canView={canViewExpenses}
          />
          {displayUnpaid && (
            <Line
              label={total_unpaid_expenses_label}
              value={info.total_unpaid_expenses}
              canView={canViewExpenses}
            />
          )}
          <Line
            label={total_expense_label}
            value={info.total_expense}
            muted
            canView={canViewExpenses}
          />
        </Col>
      </Row>
    </Col>
  );
}

CashflowOverview.defaultProps = {
  account_ids: [],
  cashflow: {},
  displayMonthResult: false,
  displayUnpaid: false,
  total_paid_incomes_label: 'Recebido:',
  total_unpaid_incomes_label: 'Falta:',
  total_income_label: 'Previsto:',
  total_paid_expenses_label: 'Pago:',
  total_unpaid_expenses_label: 'Falta:',
  total_expense_label: 'Previsto:',
  canViewIncomes: true,
  canViewExpenses: true,
  canViewForecast: true,
};

CashflowOverview.propTypes = {
  account_ids: PropTypes.array,
  displayMonthResult: PropTypes.bool,
  displayUnpaid: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.number.isRequired,
  muted: PropTypes.bool,
  canView: PropTypes.bool,
  cashflow: PropTypes.object,
  competence: PropTypes.string.isRequired,
  wordings: PropTypes.object,
  total_paid_incomes_label: PropTypes.string,
  total_income_label: PropTypes.string,
  total_unpaid_incomes_label: PropTypes.string,
  total_paid_expenses_label: PropTypes.string,
  total_expense_label: PropTypes.string,
  total_unpaid_expenses_label: PropTypes.string,
  canViewIncomes: PropTypes.bool,
  canViewExpenses: PropTypes.bool,
  canViewForecast: PropTypes.bool,
};

export default CashflowOverview;
