import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';

import ContabillsForm from './components/Form';
import ZenpaperForm from './components/Form/components/ZenpaperForm/ZenpaperForm';

function SignUp({
  isLoading,
  segments,
  onFetchPlans,
  onSignUp,
  onEmailConfirmation,
  onFetchMarketSegments,
}) {
  useEffect(() => {
    onFetchPlans();
    onFetchMarketSegments();
  }, [onFetchPlans, onFetchMarketSegments]);

  useEffect(() => {
    document.body.style.backgroundImage = 'url(assets/img/bg-activate-subscription.png)';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundRepeat = 'no-repeat';

    return () => {
      document.body.style.backgroundImage = 'none';
    };
  }, []);

  const renderForm = useCallback(() => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    if (product === 'contabills-empresas') {
      return (
        <ZenpaperForm
          onSignUp={onSignUp}
          onEmailConfirmation={onEmailConfirmation}
          segments={segments}
        />
      );
    }

    return (
      <ContabillsForm isLoading={isLoading} onSignUp={onSignUp} />
    );
  }, [
    isLoading,
    segments,
    onSignUp,
    onEmailConfirmation,
  ]);

  return (
    <Container className="h-100">
      {renderForm()}
    </Container>
  );
}

SignUp.defaultProps = {
  segments: [],
};

SignUp.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  segments: PropTypes.array,
  onSignUp: PropTypes.func.isRequired,
  onFetchPlans: PropTypes.func.isRequired,
  onEmailConfirmation: PropTypes.func.isRequired,
  onFetchMarketSegments: PropTypes.func.isRequired,
};

export default SignUp;
