import uniqueId from 'lodash/uniqueId';

import constants from '../../../_constants/company-transactions.constants';
import service from '../../../../services/company-transactions.service';
import alertActionCreators from '../../alert.actions';

const createContact = (values, callback, errorCallback) => {
  return async (dispatch) => {
    const tempId = uniqueId();

    dispatch(request(tempId, values));

    service.createContact(values).then(
      (response) => {
        const { data } = response;

        dispatch(alertActionCreators.success('Contato cadastrado com sucesso!'));

        dispatch(success(tempId, data));

        if (callback) {
          callback(data);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));

      if (callback && !errorCallback) {
        callback(null);
      } else if (errorCallback) {
        errorCallback(error);
      }
    });
  };

  function request(id, values) {
    return {
      type: constants.CREATE_CONTACT_REQUEST,
      payload: {
        id,
        values,
      },
    };
  }
  function success(id, values) {
    return {
      type: constants.CREATE_CONTACT_SUCCESS,
      payload: {
        id,
        values,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.CREATE_CONTACT_FAILURE,
      payload: error,
    };
  }
};

export default createContact;
