import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { FormMonthPickerField, Menu } from '_components/_core';

import { MenuCategory, MenuRow, MenuButton } from '_components/_core/Menu/Menu';

function Filters({
  isLoading,
  handleSubmit,
  activeCompany,
}) {
  const { date_founded } = activeCompany || {};

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }, [handleSubmit]);

  return (
    <Menu className="mb-3 mb-md-0" onKeyPress={handleKeyPress}>
      <MenuCategory noPaddingBottom>
        Filtros
      </MenuCategory>
      <MenuRow>
        <Col>
          <Form.Group>
            <Form.Label>
              Data Inicial
            </Form.Label>
            <FormMonthPickerField
              variant="input"
              name="start_date"
              placeholder="Data Inicial"
              minDate={date_founded}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>
              Data Final
            </Form.Label>
            <FormMonthPickerField
              variant="input"
              name="end_date"
              placeholder="Data Final"
              minDate={date_founded}
              endOfMonth
            />
          </Form.Group>
        </Col>
      </MenuRow>
      <MenuButton isLoading={isLoading} onClick={handleSubmit}>
        Gerar Arquivo
      </MenuButton>
    </Menu>
  );
}

Filters.defaultProps = {
  isLoading: false,
  activeCompany: {},
};

Filters.propTypes = {
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  activeCompany: PropTypes.object,
};

export default Filters;
