import * as yup from 'yup';
import { ptShort } from 'yup-locale-pt';

yup.setLocale(ptShort);

const CreateIssuedInvoiceSchema = yup.object().shape({
  emission_date: yup.date().nullable().required(),
  nature_of_operation: yup.number().nullable().required(),
  special_tax_regime: yup.number().nullable(),
  simples_nacional_optant: yup.bool().nullable().required(),
  service_taker: yup.object().shape({
    document_number: yup.string().max(18).nullable(),
    name: yup.string().max(255).required().nullable(),
    phone_number: yup.string().max(255).nullable(),
    email: yup.string().max(255).nullable(),
    address: yup.object().shape({
      address_zip_code: yup.string().max(255).nullable(),
      address_street: yup.string().max(255).required().nullable(),
      address_number: yup.string().max(255).required().nullable(),
      address_district: yup.string().max(255).required().nullable(),
      address_complement: yup.string().max(255).nullable(),
      address_city: yup.string().max(255).nullable(),
      address_state: yup.string().max(2).nullable().required(),
      address_city_ibge: yup.number().nullable().required(),
      address_state_ibge: yup.number().nullable().required(),
    }),
  }),
  service: yup.object().shape({
    service_list_item_id: yup.string().nullable().required(),
    service_list_item_code: yup.string().nullable().required(),
    service_district_tax_code: yup.string().nullable().required(),
    description: yup.string().nullable().required(),
    amount: yup.number().nullable().required(),
    city_ibge: yup.string().nullable().required(),
  }),
});

export default CreateIssuedInvoiceSchema;
