import { connect } from 'react-redux';

import balanceSheetActionCreators from '_store/_actions/balanceSheet';

import BalanceSheet from './BalanceSheet';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  balanceSheet: state.balanceSheet.balanceSheet,
  isLoading: state.balanceSheet.isLoading,
});

const mapDispatchToProps = {
  onFetchBalanceSheet: balanceSheetActionCreators.generateBalanceSheet,
  onClearBalanceSheet: balanceSheetActionCreators.eraseBalanceSheet,
};

export default connect(mapStateToProps, mapDispatchToProps)(BalanceSheet);
