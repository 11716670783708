import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

import { Button } from '_components/_core';

export const CustomContainer = styled.div`
  display: grid;
  grid-template-columns: 1.7fr 1fr;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #ffffff;

  @media (max-width: 1084px) {
    grid-template-columns: 1fr;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
`;

export const BgContainer = styled.div`
  flex-direction: row;
  align-items: center;
  height: 100vh;
  padding: 15%;
  justify-content: center;
  background: url(https://contabil-easy.s3.sa-east-1.amazonaws.com/assets/zenply-sign-in.png);
  background-size: cover;

  @media (max-width: 1084px) {
    display: none;
  }
`;

export const StyledForm = styled(Form)`
  width: 40%;

  @media (max-width: 1084px) {
    width: 80%;
  }
`;

export const StyledHeader = styled.header`
  position: absolute;
  top: 0;
  width: 60%;
  display: flex;
  justify-content: end;

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
  }

  a {
    margin-left: 25px;
  }

  @media (max-width: 1084px) {
    width: 90%;
    justify-content: center;
  }
`;

export const StyledFooter = styled.footer`
  position: absolute;
  bottom: 0;
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
  }

  a {
    margin-left: 25px;
  }

  @media (max-width: 1084px) {
    display: none;
  }
`;

export const StyledMobileFooter = styled.footer`
  position: absolute;
  bottom: 0;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
  }

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
  }

  a {
    justify-content: space-between;

    :first-child {
      padding-right: 15px;
    }
  }

  @media (min-width: 1084px) {
    display: none;
  }
`;

export const StyledButton = styled(Button)`
  background-color: #11924F;
  border: 0;
  min-width: 200px;
  
  :hover {
    background-color: #0A502B;
  }
`;

export const StyledText = styled.h3`
  font-weight: 600;
  padding-top: 10%;
  color: #ffffff;
  font-size: 30px!important;
  display: inline-block;

`;

export const StyledSubtext = styled.h3`
  font-weight: 400;
  color: #ffffff;
  font-size: 20px!important;
  display: inline-block;
`;
