import constants from '../../_constants/accountants.constants';
import service from '../../../services/accountants.service';

import getAllAccountants from './getAllAccountants';

const deleteAccountant = (id) => {
  return (dispatch) => {
    dispatch(request());

    service.remove(id).then(
      () => {
        dispatch(success(id));
        dispatch(getAllAccountants());
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.ACCOUNTANT_DELETE_REQUEST,
      payload: { },
    };
  }
  function success(id) {
    return {
      type: constants.ACCOUNTANT_DELETE_SUCCESS,
      payload: id,
    };
  }
  function failure(error) {
    return {
      type: constants.ACCOUNTANT_DELETE_FAILURE,
      payload: error,
    };
  }
};

export default deleteAccountant;
