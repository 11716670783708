import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';
import { DateTime } from 'luxon';
import isEmpty from 'lodash/isEmpty';

import { DatePicker } from '_components/_core';

function TransactionDateChanger({
  onUpdateMultipleTransactions,
  dayTransactions,
  selectedItems,
  onSetSelectedItems,
}) {
  const handleChangeDate = useCallback((value) => {
    const luxonDate = DateTime.fromJSDate(value);

    let ids = [];

    if (!isEmpty(selectedItems)) {
      ids = selectedItems;
    } else {
      ids = dayTransactions.map((dayTransaction) => dayTransaction.id);
    }

    const event_date = luxonDate.toFormat('yyyy-MM-dd');

    const params = {
      ids,
      event_date,
    };

    if (!dayTransactions.length) {
      return;
    }

    onUpdateMultipleTransactions(params, () => {
      onSetSelectedItems([]);
    });
  }, [onUpdateMultipleTransactions, selectedItems, onSetSelectedItems, dayTransactions]);

  const triggerText = useMemo(() => {
    if (!isEmpty(selectedItems)) {
      return `Mover ${selectedItems.length} para outra data`;
    }

    return 'Mover todos para outra data';
  }, [selectedItems]);

  const renderFormDateField = useCallback(() => (
    <>
      <Formik
        initialValues={{
          event_date: null,
        }}
        onSubmit={handleChangeDate}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Col} md="6" className="p-0">
              <DatePicker
                name="event_date"
                placeholder="Data"
                onChange={handleChangeDate}
                variant="dropdown"
                triggerText={triggerText}
                placement="bottom-end"
              />
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  ), [handleChangeDate, triggerText]);

  return (
    <>
      {renderFormDateField()}
    </>
  );
}

TransactionDateChanger.defaultProps = {
  dayTransactions: [],
  selectedItems: [],
};

TransactionDateChanger.propTypes = {
  onUpdateMultipleTransactions: PropTypes.func.isRequired,
  dayTransactions: PropTypes.array,
  selectedItems: PropTypes.array,
  onSetSelectedItems: PropTypes.func.isRequired,
};

export default TransactionDateChanger;
