import styled, { css } from 'styled-components';
import { Dropdown } from 'react-bootstrap';

import Button from '_components/_core/Button';
import { darken } from 'polished';

export const CustomFilterButton = styled(Button)`
  font-size: 0.8rem;
  vertical-align: top;
  font-weight: 500;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: 0 !important;
  margin-right: 8px;
  background-color: #F9FBFC;
  border: 1px solid #E8E7EA;
  border-radius: 8px;

  :hover, :focus {
    background-color: #F9FBFC;
    
    border: 1px solid #E8E7EA;
    text-decoration: none;
    color: var(--text-primary);

    ${({ isChanged }) => isChanged && css`
      background-color: ${darken(0.05, '#ffd')};
    `}
  }

  ${({ isChanged }) => isChanged && css`
    background-color: #ffd;
  `}

`;

export const FilterMenu = styled(Dropdown.Menu)`
  width: auto;
  min-width: 200px;
  padding: 0;
  z-index: 990;
  box-shadow: 0 5px 10px rgb(30 32 37 / 12%);

  /* max-height: 300px; */
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 4px;
  padding-top: 4px;
`;

export const FilterDescription = styled.div`
  color: #17212E;
  font-weight: 600;
  padding: 8px 16px;
  border-bottom: 1px solid #E8E7EA;
`;

export const FilterGroupHeader = styled.span`
 
`;

export const FilterOption = styled.div`
  background-color: transparent;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  padding: 8px 16px;
  width: 100%;
  user-select: none;
  box-sizing: border-box;
  font-weight: 400;

  :hover, :focus {
    color: #00784e;
    background-color: #eceef1;
    font-weight: 500;
  }

  ${({ isActive }) => isActive && css`
    color: #00784e;
    background-color: #eceef1;
    font-weight: 500;
  `}

  ${({ groupHeader }) => groupHeader && css`
    padding: 0;
    cursor: default;
    pointer-events: none !important;
    color: #999;
    text-transform: uppercase;
    
    color: #999;
    cursor: default !important;
    display: block !important;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0.25em;
    padding-left: 12px;
    padding-right: 12px;
    text-transform: uppercase;
    box-sizing: border-box;
    padding-bottom: 8px;
    border-bottom: 1px solid #e0e0e0;

    &:first-child {
      padding-top: 8px;
    }

    :hover, :focus {
      color: var(--color-text-primary) !important;
      background-color: transparent;
    }
  `}

  ${({ noStyling }) => noStyling && css`
    padding: 0;
    display: block !important;

    :hover, :focus {
      color: transparent;
      background-color: transparent;
    }
  `}
`;

export const FilterLabelStatusContainer = styled.div`

`;

export const FilterLabel = styled.span`
  color: #666B77;
  margin-right: 8px;
`;

export const FilterValue = styled.span`
  color: #17212E;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const InputContainer = styled.div`
  padding: 8px 16px;

  input[type="text"] {
    min-width: 250px;
  }
`;
