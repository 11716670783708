import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Tab from 'react-bootstrap/Tab';
import { IoPrintOutline } from 'react-icons/io5';
import ReactToPrint from 'react-to-print';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';
import {
  Report,
  ReportPage,
  ReportHeader,
  ReportSignature,
} from '_components/_shared';

import { TABLE_COLUMNS, DEFAULT_COLUMNS } from './utilities';
import { StyledTable } from './styles';

function Results({
  values,
  activeCompany,
  balanceSheet,
}) {
  const reportRef = useRef();

  const { company_name, document_number } = activeCompany || {};

  if (isEmpty(balanceSheet)) {
    return null;
  }

  return (
    <Tab.Content>
      <div className="d-flex align-items-end">
        <ReactToPrint
          trigger={() => (
            <button type="button" placement="left" className="btn btn-sm btn-icons btn-secondary mr-2 mb-3">
              <IoPrintOutline />
            </button>
          )}
          content={() => reportRef.current}
          documentTitle="Balancete Analítico"
        />
      </div>
      <Report ref={reportRef}>
        <ReportPage>
          <ReportHeader
            name="BALANCETE ANALÍTICO"
            companyName={company_name}
            companyDocumentNumber={FORMATTERS.CPF_CNPJ(document_number)}
            startDate={values.start_date}
            endDate={values.end_date}
            currentPage={1}
            totalPages={1}
          />
          <div className="table-responsive">
            <StyledTable
              keyName="id"
              className="balance-sheet-table table-hover mt-3"
              data={balanceSheet}
              columns={TABLE_COLUMNS}
              defaultColumns={DEFAULT_COLUMNS}
            />
          </div>
          <ReportSignature viewType="signature_field" />
        </ReportPage>
      </Report>
    </Tab.Content>
  );
}

Results.defaultProps = {
  balanceSheet: [],
};

Results.propTypes = {
  values: PropTypes.object,
  activeCompany: PropTypes.object,
  balanceSheet: PropTypes.array,
};

export default Results;
