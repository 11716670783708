import { payrollConstants } from '../_constants';

const INITIAL_STATE = {
  irrfTotal: 0,
  irrfTables: [],
  inssTotal: 0,
  inssTables: [],
  childBenefitTotal: 0,
  childBenefitTables: [],
  minimumWageTotal: 0,
  minimumWageTables: [],
  manual_payroll: {},
  isLoading: false,
  payroll: {
    payroll_events: [],
    totals: {},
  },
};

export function payroll(state = INITIAL_STATE, action) {
  switch (action.type) {
    case payrollConstants.IRRF_GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case payrollConstants.IRRF_GET_ALL_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        irrfTotal: total,
        irrfTables: data,
        isLoading: false,
      };
    }
    case payrollConstants.IRRF_GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case payrollConstants.INSS_GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case payrollConstants.INSS_GET_ALL_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        inssTotal: total,
        inssTables: data,
        isLoading: false,
      };
    }
    case payrollConstants.INSS_GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case payrollConstants.CHILD_BENEFIT_GET_ALL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case payrollConstants.CHILD_BENEFIT_GET_ALL_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        childBenefitTotal: total,
        childBenefitTables: data,
        isLoading: false,
      };
    }
    case payrollConstants.CHILD_BENEFIT_GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case payrollConstants.MINIMUM_WAGE_GET_ALL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case payrollConstants.MINIMUM_WAGE_GET_ALL_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        minimumWageTotal: total,
        minimumWageTables: data,
        isLoading: false,
      };
    }
    case payrollConstants.MINIMUM_WAGE_GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case payrollConstants.MANUAL_PAYROLL_GET_ALL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case payrollConstants.MANUAL_PAYROLL_GET_ALL_SUCCESS: {
      const manual_payroll = action.payload;

      return {
        ...state,
        manual_payroll,
        isLoading: false,
      };
    }
    case payrollConstants.MANUAL_PAYROLL_GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case payrollConstants.MANUAL_PAYROLL_SAVE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case payrollConstants.MANUAL_PAYROLL_SAVE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case payrollConstants.MANUAL_PAYROLL_SAVE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case payrollConstants.GENERATE_PAYROLL_ENTRIES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case payrollConstants.GENERATE_PAYROLL_ENTRIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case payrollConstants.GENERATE_PAYROLL_ENTRIES_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case payrollConstants.PAYROLL_GET_ALL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case payrollConstants.PAYROLL_GET_ALL_SUCCESS: {
      const payroll = action.payload;

      return {
        ...state,
        payroll,
        isLoading: false,
      };
    }
    case payrollConstants.PAYROLL_GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case payrollConstants.GENERATE_PAYROLL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case payrollConstants.GENERATE_PAYROLL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case payrollConstants.GENERATE_PAYROLL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case payrollConstants.DELETE_PAYROLL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case payrollConstants.DELETE_PAYROLL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case payrollConstants.DELETE_PAYROLL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case payrollConstants.SEND_SALARY_RECEIPT_MAIL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case payrollConstants.SEND_SALARY_RECEIPT_MAIL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case payrollConstants.SEND_SALARY_RECEIPT_MAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
