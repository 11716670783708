import { connect } from 'react-redux';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';

import withPermissionGate from './withPermissionGate';

const mapStateToProps = (state) => {
  const { id } = state.auth.user;

  const { isLoading } = state.userPermissions;
  const isReady = !isLoading && !isEmpty(state.userPermissions.permissions[id]);

  return {
    user: state.auth.user,
    userPermissions: state.userPermissions.permissions[id] || {},
    isLoading,
    isReady,
  };
};

export default (permissions, type, fallback, variant) => compose(
  connect(mapStateToProps),
  withPermissionGate(permissions, type, fallback, variant),
);
