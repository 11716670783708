import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useFormikContext } from 'formik';

import {
  Card,
  CardHeader,
  CardBody,
  FormTextField,
  FormCpfCnpjField,
  FormDateField,
  FormSelectField,
  FormCurrencyField,
} from '_components/_core';
import FORMATTERS from 'helpers/formatters';
import { DOCUMENT_TYPE_OPTIONS } from 'helpers/constants';

import { StyledHr } from './styles';

function EmployeeInfoTab({
  employeeTypes,
  employeeJourneys,
  employeeRoles,
}) {
  const formik = useFormikContext();

  const formattedJourneys = useMemo(() => employeeJourneys.map((item) => ({
    value: item.id,
    label: FORMATTERS.JOURNEY(item.journey),
  })), [employeeJourneys]);

  const formattedTypes = useMemo(() => {
    const { data = [] } = employeeTypes || {};

    return data.map((item) => ({
      value: item.id,
      label: item.description,
    }));
  }, [employeeTypes]);

  const formattedRoles = useMemo(() => employeeRoles.map((item) => ({
    value: item.id,
    label: item.description,
  })), [employeeRoles]);

  return (
    <Card noBorders>
      <CardHeader title="Dados cadastrais" description="Informações pessoais do colaborador" />
      <CardBody noPaddingTop className="mt-3">
        <Form>
          <Form.Row>
            <Form.Group as={Col} lg="3">
              <Form.Label>Tipo de Funcionário</Form.Label>
              <FormSelectField name="employee_type" options={formattedTypes} />
            </Form.Group>
          </Form.Row>
          <StyledHr />
          <Form.Row>
            <Form.Group as={Col} lg="2">
              <Form.Label>Tipo de Documento</Form.Label>
              <FormSelectField name="document_type" options={DOCUMENT_TYPE_OPTIONS} />
            </Form.Group>
            <Form.Group as={Col} lg="3">
              <Form.Label>{formik.values.document_type}</Form.Label>
              <FormCpfCnpjField name="document_number" placeholder={formik.values.document_type} type={formik.values.document_type} />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg="4">
              <Form.Label>Nome</Form.Label>
              <FormTextField name="name" placeholder="Nome completo do colaborador" />
            </Form.Group>

            <Form.Group as={Col} lg="2">
              <Form.Label>Data de Nascimento</Form.Label>
              <FormDateField name="birthdate" placeholder="Data de Nascimento" />
            </Form.Group>

          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg="4">
              <Form.Label>Nome da Mãe</Form.Label>
              <FormTextField name="mother_name" placeholder="Nome da Mãe" />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg="4">
              <Form.Label>Número do RG</Form.Label>
              <FormTextField name="rg_number" placeholder="Número do RG" />
            </Form.Group>
            <Form.Group as={Col} lg="4">
              <Form.Label>Número da CTPS</Form.Label>
              <FormTextField name="ctps_number" placeholder="Número da CTPS" />
            </Form.Group>
            <Form.Group as={Col} lg="4">
              <Form.Label>Número do PIS</Form.Label>
              <FormTextField name="pis_pasep_number" placeholder="Número do PIS" />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg="4">
              <Form.Label>E-mail</Form.Label>
              <FormTextField name="email" placeholder="E-mail" />
            </Form.Group>
            <Form.Group as={Col} lg="4">
              <Form.Label>Telefone</Form.Label>
              <FormTextField name="phone_number" placeholder="Telefone" />
            </Form.Group>
          </Form.Row>
          <StyledHr />
          <Form.Row>
            <Form.Group as={Col} lg="2">
              <Form.Label>Data de Admissão</Form.Label>
              <FormDateField name="date_admission" placeholder="Data de Admissão" />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg="3">
              <Form.Label>Cargo do Funcionário</Form.Label>
              <FormSelectField name="employee_role" options={formattedRoles} creatable="employee_role" />
            </Form.Group>
            <Form.Group as={Col} lg="3">
              <Form.Label>Salário do Funcionário</Form.Label>
              <FormCurrencyField name="salary" placeholder="Salário do Funcionário" />
            </Form.Group>
            <Form.Group as={Col} lg="3">
              <Form.Label>Jornada do Funcionário</Form.Label>
              <FormSelectField name="employee_journey" options={formattedJourneys} creatable="employee_journey" />
            </Form.Group>
          </Form.Row>
        </Form>
      </CardBody>
    </Card>
  );
}

EmployeeInfoTab.defaultProps = {
  employeeRoles: [],
  employeeJourneys: [],
};

EmployeeInfoTab.propTypes = {
  employeeTypes: PropTypes.array,
  employeeJourneys: PropTypes.array,
  employeeRoles: PropTypes.array,
};

export default EmployeeInfoTab;
