import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column; 
  background-color: #fff;
  padding: 4px 16px;
  padding-bottom: 16px;
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  ${({ isTablet }) => isTablet && css`
    bottom: 0;
  `}
`;

export const BalanceContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Handler = styled.div`
  width: 30px;
  height: 4px;
  border-radius: 2px;
  background-color: #e5e5e5;
  margin: 8px auto;
`;

export default Container;
