import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import createRootReducer from '../_store/_reducers';

export const history = createBrowserHistory();
const middlewares = [thunkMiddleware, routerMiddleware(history)];
const storeParams = [];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger());
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    storeParams.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }
}

storeParams.unshift(applyMiddleware(...middlewares));

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    compose(
      ...storeParams,
    ),
  );

  return store;
}
