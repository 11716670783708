import { connect } from 'react-redux';

import bankStatementActionCreators from '_store/_actions/bankStatements';
import accountsActionCreators from '_store/_actions/accounts';

import BankStatementUpload from './BankStatementUpload';

const mapStateToProps = (state) => ({
  isLoading: state.bankStatements.isLoading,
  accounts: state.accounts.accounts,
});

const mapDispatchToProps = {
  onParseBankStatements: bankStatementActionCreators.parseBankStatements,
  onFetchAccounts: accountsActionCreators.getAllAccounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankStatementUpload);
