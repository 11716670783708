import styled, { css } from 'styled-components';
import Col from 'react-bootstrap/Col';

export const Container = styled.div`
  padding-top: 25px;
  padding-right: 25px;
  padding-bottom: 0;
  padding-left: 25px;
`;

export const Title = styled.h4`
  text-transform: none;
  margin-bottom: 0.5rem;
`;

export const StyledCol = styled(Col)`
  ${({ adjustSideContentMobile }) => adjustSideContentMobile && css`
    overflow-x: auto;
    padding-top: 14px;
    padding-bottom: 8px;
  `}
`;

export const Description = styled.p`
  margin-bottom: 0px;
`;

export const LeftContent = styled.div``;

export const RightContent = styled.div``;
