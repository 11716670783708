import * as Yup from 'yup';
import { validateCNPJ, validateCPF } from 'validations-br';
import { ptShort } from 'yup-locale-pt';

import {
  REQUIRED,
} from 'helpers/validation-messages';

Yup.setLocale(ptShort);

const signUpFormSchema = Yup.object().shape({
  first_name: Yup.string().min(3).max(255).required(REQUIRED),
  last_name: Yup.string().min(1).max(255).required(REQUIRED),
  document_type: Yup.mixed().oneOf(['PF', 'PJ']).required(REQUIRED),
  document_number: Yup.string().when('document_type', {
    is: 'PF',
    then: Yup.string().test('is-cpf', 'CPF inválido', (value) => validateCPF(value)),
    otherwise: Yup.string().test('is-cnpj', 'CNPJ inválido', (value) => validateCNPJ(value)),
  }),
  email: Yup.string().max(255).email().required(REQUIRED),
  email_confirmation: Yup.string().max(255).email().oneOf([Yup.ref('email')], 'Os e-mails não conferem')
    .required(REQUIRED),
  password: Yup.string().max(60).required(REQUIRED),
  password_confirmation: Yup.string().oneOf([Yup.ref('password')], 'As senhas não conferem').required(REQUIRED),
});

export default signUpFormSchema;
