import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const createTransactionAccount = (values, callback) => {
  return async (dispatch) => {
    dispatch(request(values));

    service.addTransactionAccount(values).then(
      (response) => {
        dispatch(success(response));
        dispatch(alertActionCreators.success('Conta Bancária cadastrada com sucesso!'));

        if (callback) {
          callback(response.data);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));

      callback(null);
    });
  };

  function request() {
    return {
      type: constants.CREATE_TRANSACTION_ACCOUNT_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.CREATE_TRANSACTION_ACCOUNT_SUCCESS,
      payload: response.data,
    };
  }
  function failure(error) {
    return {
      type: constants.CREATE_TRANSACTION_ACCOUNT_FAILURE,
      payload: error,
    };
  }
};

export default createTransactionAccount;
