import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import {
  ActionSheet,
  Button,
} from '_components/_core';

function TransactionPaymentAlert({
  isOpen,
  transaction,
  onClose,
  onUpdateMultipleTransactions,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const title = 'Atualizar Data de Pagamento?';
  const description = 'Você gostaria que o Zenply atualizasse a data de pagamento deste item para o dia de hoje?';
  const hint = 'Desative esse alerta no menu Configurações -> Preferências';

  const handleConfirm = useCallback(() => {
    setIsLoading(true);

    onUpdateMultipleTransactions({
      ids: [transaction.id],
      event_date: DateTime.now().toFormat('yyyy-MM-dd'),
    }, () => {
      setIsLoading(false);

      onClose();
    });
  }, [onUpdateMultipleTransactions, onClose, transaction]);

  return (
    <ActionSheet
      isOpen={isOpen}
      onToggle={onClose}
      detent="content-height"
      prefersReducedMotion
    >
      <div>
        <div className="p-3 text-center">
          <AiOutlineQuestionCircle size="5em" className="text-info" />
          <div className="mb-3">
            <h4 className="mt-3">{title}</h4>
            <p className="mb-3">
              {description} <br />
              <small className="text-muted">
                {hint}
              </small>
            </p>
          </div>
          <div className="mt-3 d-flex justify-content-center align-items-center">
            <Button loadingText="Aguarde" isLoading={isLoading} variant="success-2" onClick={handleConfirm}>
              SIM, atualize!
            </Button>
            <Button disabled={isLoading} variant="secondary" className="ml-2" onClick={onClose}>
              NÃO, deixe como está!
            </Button>
          </div>
        </div>
      </div>
    </ActionSheet>
  );
}

TransactionPaymentAlert.defaultProps = {
  transaction: {},
};

TransactionPaymentAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  transaction: PropTypes.object,
  onUpdateMultipleTransactions: PropTypes.func.isRequired,
};

export default TransactionPaymentAlert;
