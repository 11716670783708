import constants from '../../_constants/receipts.constants';
import service from '../../../services/receipt.service';
import alertActionCreators from '../alert.actions';

const updateReceipt = (id, values, callback, errorCallback) => {
  return async (dispatch) => {
    dispatch(request(id, values));

    service.update(id, values).then(
      (response) => {
        dispatch(success(response.data));

        dispatch(alertActionCreators.success('Recibo atualizado com sucesso.'));

        if (callback) {
          callback(response.data);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));

      if (errorCallback) {
        errorCallback();
      }
    });
  };

  function request(id, values) {
    return {
      type: constants.RECEIPT_UPDATE_REQUEST,
      payload: {
        id,
        values,
      },
    };
  }
  function success(updatedReceipt) {
    return {
      type: constants.RECEIPT_UPDATE_SUCCESS,
      payload: updatedReceipt,
    };
  }
  function failure(error) {
    return {
      type: constants.RECEIPT_UPDATE_FAILURE,
      payload: error,
    };
  }
};

export default updateReceipt;
