import { connect } from 'react-redux';

import ibgeActionCreators from '_store/_actions/ibge';

import EmployeeAddressTab from './EmployeeAddressTab';

const mapStateToProps = (state) => ({
  isLoadingCep: state.ibge.isLoadingCep,
  isLoading: state.accountants.isLoading,
  cities: state.ibge.cities,
  states: state.ibge.states,
});

const mapDispatchToProps = {
  onFetchStates: ibgeActionCreators.fetchStates,
  onFetchCities: ibgeActionCreators.fetchCities,
  onFetchCep: ibgeActionCreators.fetchCep,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeAddressTab);
