import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';
import authSelectors from '_store/_selectors/auth';

import Create from './Create';

const mapStateToProps = (state) => ({
  user: authSelectors.currentUserSelector(state),
  isLoading: state.company.isLoading,
});

const mapDispatchToProps = {
  onAddFinancialCompany: companyActionCreators.addFinancialCompany,
  onUpdateFinancialCompany: companyActionCreators.updateFinancialCompany,
  onFetchMarketSegments: companyActionCreators.fetchMarketSegments,
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
