import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
} from '_components/_core';
import { TABLE_COLUMNS, DEFAULT_COLUMNS } from './utilities';
import { StyledTable } from './styles';

function EmployeeList({ employees }) {
  const history = useHistory();

  return (
    <Card>
      <CardHeader
        title="Colaboradores Atuais"
        description="Listagem de sócios/colaboradores ativos."
      />
      <CardBody noPaddingTop>
        <div className="table-responsive">
          <StyledTable
            className="table-hover"
            keyName="id"
            data={employees}
            columns={TABLE_COLUMNS}
            defaultColumns={DEFAULT_COLUMNS}
            showFooter
          />
        </div>
      </CardBody>
      <CardFooter>
        <Button
          onClick={() => history.push('/funcionarios/criar')}
          className="float-right"
          variant="primary"
          size="sm"
        >
          Inserir Funcionário
        </Button>
      </CardFooter>
    </Card>
  );
}

EmployeeList.propTypes = {
  employees: PropTypes.array,
};

export default EmployeeList;
