import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { IoPrintOutline, IoMailOutline } from 'react-icons/io5';
import ReactToPrint from 'react-to-print';

import { Button } from '_components/_core';

import SalaryReceipt from './components/SalaryReceipt';

import {
  StyledModal,
  ModalFooter,
} from './styles';

export default function SalaryReceiptModal({
  isVisible,
  isLoading,
  onModalToggle,
  selectedPayroll,
  company,
  onSendSalaryReceiptMail,
}) {
  const reportRef = useRef();

  const isDisabled = isEmpty(selectedPayroll.employee_email);

  const handleSendEmail = useCallback(() => {
    onSendSalaryReceiptMail(selectedPayroll._id, selectedPayroll.employee_email);
  }, [onSendSalaryReceiptMail, selectedPayroll]);

  const renderModalFooter = useCallback(() => (
    <ModalFooter>
      <Button variant="secondary" onClick={onModalToggle}>
        Fechar
      </Button>
      <div>
        <Button
          className="mr-3"
          icon={<IoMailOutline />}
          onClick={handleSendEmail}
          variant="secondary"
          disabled={isLoading || isDisabled}
          isLoading={isLoading}
        >
          Enviar por E-mail
        </Button>
        <ReactToPrint
          trigger={() => (
            <Button variant="secondary" icon={<IoPrintOutline />}>
              Imprimir
            </Button>
          )}
          content={() => reportRef.current}
          documentTitle="Recibo de Pagamento de Salário"
        />
      </div>
    </ModalFooter>
  ), [onModalToggle, handleSendEmail, isLoading, isDisabled]);

  return (
    <>
      <StyledModal
        title="Recibo de Pagamento de Salário"
        isVisible={isVisible}
        toggleModal={onModalToggle}
        footer={renderModalFooter(onModalToggle)}
      >
        <div ref={reportRef}>
          <SalaryReceipt company={company} payroll={selectedPayroll} />
          <div className="d-none d-print-block">
            <br />
            <br />
            <hr />
            <br />
            <br />
          </div>
          <SalaryReceipt company={company} payroll={selectedPayroll} className="d-none d-print-block" />
        </div>
      </StyledModal>
    </>
  );
}

SalaryReceiptModal.defaultProps = {
  isVisible: false,
  isLoading: false,
  selectedPayroll: {
    child_events: [],
  },
  company: {},
};

SalaryReceiptModal.propTypes = {
  isVisible: PropTypes.bool,
  isLoading: PropTypes.func,
  onModalToggle: PropTypes.func.isRequired,
  onSendSalaryReceiptMail: PropTypes.func.isRequired,
  selectedPayroll: PropTypes.object,
  company: PropTypes.object,
};
