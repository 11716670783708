import HttpService from './http.service';

export default {
  getAll,
  getAllAssociates,
  remove,
  getAllTypes,
  getAllRoles,
  addRole,
  updateRole,
  deleteRole,
  getAllJourneys,
  addJourney,
  updateJourney,
  deleteJourney,
  insert,
  update,
  getById,
  getAllSalaries,
  addSalary,
  updateSalary,
  deleteSalary,
  getAllVacations,
  getEmployeeDependents,
  addEmployeeDependent,
  updateEmployeeDependent,
  deleteEmployeeDependent,
};

async function getAll(params, company_id) {
  if (company_id) {
    return HttpService.get(`/company/${company_id}/employees`, params);
  }
  return HttpService.get('/employees', params);
}

async function getAllAssociates(company_id, params) {
  return HttpService.get(`/company/${company_id}/employees`, params);
}

async function remove(id) {
  return HttpService.delete(`/employees/${id}`);
}

async function insert(params) {
  return HttpService.post('/employees', params);
}

async function update(id, employee) {
  return HttpService.put(`/employees/${id}`, employee);
}

async function getById(id) {
  return HttpService.get(`/employees/${id}`);
}

// Employee Role

async function getAllRoles(params) {
  return HttpService.get('/employee-role', params);
}

async function addRole(role) {
  return HttpService.post('/employee-role', role);
}

async function updateRole(id, role) {
  return HttpService.put(`/employee-role/${id}`, role);
}

async function deleteRole(id) {
  return HttpService.delete(`/employee-role/${id}`);
}

// Employee Types

async function getAllTypes() {
  return HttpService.get('/employee-type');
}

// Employee Journey

async function getAllJourneys(params) {
  return HttpService.get('/employee-journey', params);
}

async function addJourney(journey) {
  return HttpService.post('/employee-journey', journey);
}

async function updateJourney(id, journey) {
  return HttpService.put(`/employee-journey/${id}`, journey);
}

async function deleteJourney(id) {
  return HttpService.delete(`/employee-journey/${id}`);
}

// Employee Salary

async function addSalary(id, salary) {
  return HttpService.post(`/employee/${id}/salary`, salary);
}

async function getAllSalaries(employeeId, params) {
  return HttpService.get(`/employee/${employeeId}/salary`, params);
}

async function updateSalary(employeeId, salaryId, values) {
  return HttpService.put(`/employee/${employeeId}/salary/${salaryId}`, values);
}

async function deleteSalary(employeeId, salaryId) {
  return HttpService.delete(`/employee/${employeeId}/salary/${salaryId}`);
}

// Employee Vacation

async function getAllVacations(employeeId, params) {
  return HttpService.get(`/employee/${employeeId}/vacation`, params);
}

// Employee Dependents

async function getEmployeeDependents(employeeId) {
  return HttpService.get(`/employee/${employeeId}/dependent`);
}

async function addEmployeeDependent(employeeId, values) {
  const {
    name,
    document_number,
    date_of_birth,
  } = values;

  const body = {
    name,
    document_number,
    date_of_birth,
  };
  return HttpService.post(`/employee/${employeeId}/dependent`, body);
}

async function updateEmployeeDependent(employeeId, dependentId, values) {
  const {
    name,
    document_number,
    date_of_birth,
  } = values;

  const body = {
    name,
    document_number,
    date_of_birth,
  };

  return HttpService.put(`/employee/${employeeId}/dependent/${dependentId}`, body);
}

async function deleteEmployeeDependent(employeeId, dependentId) {
  return HttpService.delete(`/employee/${employeeId}/dependent/${dependentId}`);
}
