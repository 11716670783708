import HttpService from './http.service';

export default {
  getRevenueReport,
  getIssReport,
};

async function getRevenueReport(params) {
  return HttpService.post('/reports/revenue', params);
}

async function getIssReport(params) {
  return HttpService.post('/reports/iss', params);
}
