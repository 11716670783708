import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { Select } from '_components/_core';
import { CreatableSelect } from '_components/_shared';

function FormSelectField({
  placeholder,
  options,
  creatable,
  multiple,
  disabled,
  innerRef,
  useValueForArrayOption,
  ...props
}) {
  const formik = useFormikContext();
  const [field, meta] = useField(props);

  const error = meta.error && meta.touched ? meta.error : '';

  const handleChange = useCallback((option) => {
    if (Array.isArray(option)) {
      if (useValueForArrayOption) {
        formik.setFieldValue(field.name, option.map((item) => item.value));
      } else {
        formik.setFieldValue(field.name, option);
      }
    } else {
      formik.setFieldValue(field.name, option ? option.value : null);
    }
  }, [field, formik, useValueForArrayOption]);

  const value = useMemo(() => {
    if (multiple) {
      if (useValueForArrayOption) {
        return options.filter((option) => field.value.includes(option.value));
      }

      return field.value;
    }

    if (field.value === null) {
      return null;
    }

    const hasGroupedOptions = options.some((option) => option.options);

    if (hasGroupedOptions) {
      const option = options
        .map((group) => group.options)
        .flat()
        .find((option) => option.value === field.value);

      return option || '';
    }

    return !isEmpty(options) ? options.find((option) => option.value === field.value) : null;
  }, [field, options, useValueForArrayOption, multiple]);

  return (
    <>
      {!creatable && (
        <Select
          options={options}
          name={field.name}
          value={value}
          onChange={handleChange}
          onBlur={field.onBlur}
          isMulti={multiple}
          disabled={disabled}
          innerRef={innerRef}
          placeholder={placeholder}
          {...props}
        />
      )}
      {creatable && (
        <CreatableSelect
          options={options}
          name={field.name}
          value={value}
          onChange={handleChange}
          onBlur={field.onBlur}
          creatable={creatable}
          isMulti={multiple}
          disabled={disabled}
          innerRef={innerRef}
          placeholder={placeholder}
          {...props}
        />
      )}
      {error && <small className="text-danger">{error}</small>}
    </>
  );
}

FormSelectField.defaultProps = {
  options: [],
  creatable: null,
  multiple: false,
  disabled: false,
  innerRef: null,
  useValueForArrayOption: false,
};

FormSelectField.propTypes = {
  multiple: PropTypes.bool,
  useValueForArrayOption: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  creatable: PropTypes.string,
  disabled: PropTypes.bool,
  innerRef: PropTypes.object,
};

export default FormSelectField;
