import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  Card,
  CardHeader,
  Table,
  Button,
} from '_components/_core';
import { Pagination, ItemsPerPage } from '_components/_core/Table/components';
import { useTable } from '_components/_core/Table/utils';

import { AssociatesModal } from './components';
import { DEFAULT_COLUMNS, TABLE_COLUMNS } from './utilities';

const EMPLOYEE_TYPE = 'f6fa9b88-c966-4f3c-9af7-4612b04099c9';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { field: 'name', order: 'asc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function Associates({
  total,
  employees,
  company,
  isLoading,
  onFetchEmployees,
  onDeleteEmployee,
}) {
  const {
    pagination,
    onPageChange,
    onPageSizeChange,
  } = useTable({
    ...tableConfig,
    data: employees,
    onFetchData: onFetchEmployees,
  });
  const [selectedAssociate, setSelectedAssociate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const { id: company_id } = company || {};

    onFetchEmployees({}, company_id);
  }, [onFetchEmployees, company]);

  const associates = useMemo(() => {
    if (!employees) {
      return [];
    }

    return employees.filter((employee) => employee.employee_type === EMPLOYEE_TYPE);
  }, [employees]);

  const handleAddAssociate = useCallback(() => {
    setSelectedAssociate(null);
    setIsModalOpen(true);
  }, []);

  const handleEditAssociate = useCallback((id) => {
    setSelectedAssociate(null);

    const associate = associates.find((associate) => associate.id === id);

    setSelectedAssociate(associate);
    setIsModalOpen(true);
  }, [associates]);

  const handleDeleteAssociate = useCallback((id) => {
    const { id: company_id } = company || {};

    onDeleteEmployee(id, () => {
      onFetchEmployees({}, company_id);
    });
  }, [onDeleteEmployee, onFetchEmployees, company]);

  return (
    <>
      <Card className="mt-3">
        <AssociatesModal
          isVisible={isModalOpen}
          associate={selectedAssociate}
          company={company}
          onModalToggle={() => setIsModalOpen(!isModalOpen)}
          isLoading={isLoading}
        />
        <CardHeader
          title="Sócios"
          description="Listagem de Sócios da empresa"
          sideContent={(
            <Button className="mr-4" onClick={handleAddAssociate}>
              Novo Sócio
            </Button>
          )}
        />
        <Table
          key="id"
          data={associates}
          defaultColumns={DEFAULT_COLUMNS}
          columns={TABLE_COLUMNS}
          onEdit={handleEditAssociate}
          onDelete={handleDeleteAssociate}
          showColumnToggle
          hover
        />
      </Card>
      <Row>
        <Col sm={2}>
          <ItemsPerPage
            itemsPerPage={pagination.itemsPerPage}
            onChange={onPageSizeChange}
            className="mt-3"
          />
        </Col>
        <Col className="d-flex flex-row-reverse">
          <Pagination
            {...pagination}
            total={total}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
    </>
  );
}

Associates.defaultProps = {
  employees: [],
};

Associates.propTypes = {
  company: PropTypes.object.isRequired,
  employees: PropTypes.array,
  total: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onFetchEmployees: PropTypes.func.isRequired,
  onDeleteEmployee: PropTypes.func.isRequired,
};

export default Associates;
