import constants from '../../../_constants/company-transactions.constants';
import service from '../../../../services/company-transactions.service';

const fetchContactsSummary = (callback) => {
  return async (dispatch) => {
    dispatch(request());

    service.fetchContactsSummary().then(
      (response) => {
        const { data } = response;

        dispatch(success(data));

        if (callback) {
          callback(data);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.FETCH_CONTACTS_SUMMARY_REQUEST,
      payload: {},
    };
  }
  function success(payload) {
    return {
      type: constants.FETCH_CONTACTS_SUMMARY_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_CONTACTS_SUMMARY_FAILURE,
      payload: error,
    };
  }
};

export default fetchContactsSummary;
