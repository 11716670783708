import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormDateField } from '_components/_core';

function FormDateFromToField({
  label,
  fromName,
  fromPlaceholder,
  fromId,
  toName,
  toPlaceholder,
  toId,
  className,
  leftContent,
  fromStyle,
  toStyle,
  fromPlacement,
  toPlacement,
  withPortal,
}) {
  const defaultClass = 'd-flex flex-column justify-content-center align-items-start';

  const leftInputStyleProps = {
    fontWeight: 500,
    borderColor: '#d5dcec',
    minHeight: 32,
    maxHeight: 32,
    backgroundColor: '#fff',
    border: '1px solid #E8E7EA',
    borderRadius: '8px',
    width: '100%',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    flex: 1,
    ...fromStyle,
  };

  const rightInputStyleProps = {
    fontWeight: 500,
    borderColor: '#d5dcec',
    minHeight: 32,
    maxHeight: 32,
    backgroundColor: '#fff',
    border: '1px solid #E8E7EA',
    borderRadius: '8px',
    width: '100%',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: 0,
    flex: 1,
    ...toStyle,
  };

  return (
    <div className={classNames(defaultClass, className)}>
      {label && (
        <span
          style={{
            color: 'var(--color-text-primary)',
            fontSize: '0.8rem',
            fontWeight: 600,
          }}
        >
          {label}
        </span>
      )}
      <div className="w-100 d-flex justify-content-center align-items-start">
        {leftContent}
        <FormDateField
          name={fromName}
          placeholder={fromPlaceholder}
          inputStyleProps={leftInputStyleProps}
          placement={fromPlacement}
          withPortal={withPortal}
          id={fromId}
        />
        <FormDateField
          name={toName}
          placeholder={toPlaceholder}
          inputStyleProps={rightInputStyleProps}
          placement={toPlacement}
          withPortal={withPortal}
          id={toId}
        />
      </div>
    </div>
  );
}

FormDateFromToField.defaultProps = {
  label: 'De - Até',
  fromPlaceholder: 'De:',
  toPlaceholder: 'Até:',
  className: '',
  leftContent: null,
  fromStyle: {},
  toStyle: {},
  fromPlacement: 'bottom-start',
  toPlacement: 'bottom-end',
  withPortal: false,
  fromId: null,
  toId: null,
};

FormDateFromToField.propTypes = {
  label: PropTypes.string,
  fromName: PropTypes.string.isRequired,
  fromPlaceholder: PropTypes.string,
  toName: PropTypes.string.isRequired,
  toPlaceholder: PropTypes.string,
  className: PropTypes.string,
  leftContent: PropTypes.node,
  fromStyle: PropTypes.object,
  toStyle: PropTypes.object,
  fromPlacement: PropTypes.string,
  toPlacement: PropTypes.string,
  withPortal: PropTypes.bool,
  fromId: PropTypes.string,
  toId: PropTypes.string,
};

export default FormDateFromToField;
