import addPerson from './addPerson';
import getAllPersons from './getAllPersons';
import updatePerson from './updatePerson';
import deletePerson from './deletePerson';
import getEFDContributions from './getEFDContributions';
import clearEFDContributions from './clearEFDContributions';
import downloadEFDContributions from './downloadEFDContributions';

export default {
  addPerson,
  getAllPersons,
  updatePerson,
  deletePerson,
  getEFDContributions,
  clearEFDContributions,
  downloadEFDContributions,
};
