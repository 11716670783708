export const ACCOUNT_TYPE = {
  SINTETICA: 'SINTETICA',
  ANALITICA: 'ANALITICA',
};

export const ACCOUNT_TYPE_LABELS = {
  [ACCOUNT_TYPE.SINTETICA]: 'SINTÉTICA',
  [ACCOUNT_TYPE.ANALITICA]: 'ANALÍTICA',
};

export const ACCOUNT_TYPE_CLASS = {
  [ACCOUNT_TYPE.SINTETICA]: 'badge-default',
  [ACCOUNT_TYPE.ANALITICA]: 'badge-outline-default',
};

export const ACCOUNT_NATURE = {
  ATIVO: 'ATIVO',
  PASSIVO: 'PASSIVO',
  RECEITA: 'RECEITA',
  DESPESA: 'DESPESA',
};

export const ACCOUNT_NATURE_LABELS = {
  [ACCOUNT_NATURE.ATIVO]: 'ATIVO',
  [ACCOUNT_NATURE.PASSIVO]: 'PASSIVO',
  [ACCOUNT_NATURE.RECEITA]: 'RECEITA',
  [ACCOUNT_NATURE.DESPESA]: 'DESPESA',
  [ACCOUNT_NATURE.PLIQUIDO]: 'P. Líquido',
  [ACCOUNT_NATURE.RESULTADO]: 'Resultado',
};

export const ACCOUNT_NATURE_CLASS = {
  [ACCOUNT_NATURE.ATIVO]: 'primary',
  [ACCOUNT_NATURE.PASSIVO]: 'warning',
  [ACCOUNT_NATURE.RECEITA]: 'success',
  [ACCOUNT_NATURE.DESPESA]: 'danger',
  [ACCOUNT_NATURE.PLIQUIDO]: 'info',
  [ACCOUNT_NATURE.RESULTADO]: 'dark',
};

export const ACCOUNT_VISIBILITY = {
  SHARED: 'SHARED',
  CURRENT_COMPANY: 'CURRENT_COMPANY',
  ALL_COMPANIES: 'ALL_COMPANIES',
};

export const ACCOUNT_VISIBILITY_LABELS = {
  [ACCOUNT_VISIBILITY.SHARED]: 'Visível para: Todas as Empresas',
  [ACCOUNT_VISIBILITY.CURRENT_COMPANY]: 'Visível para: Empresa Ativa',
  [ACCOUNT_VISIBILITY.ALL_COMPANIES]: 'Visível para: Todas as Empresas',
};
