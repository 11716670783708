import { DateTime } from 'luxon';
import isNull from 'lodash/isNull';

const isMonthBlocked = (blocked_period, event_date) => {
  const {
    active_period_start: blocked_period_start,
    active_period_end: blocked_period_end,
  } = blocked_period || {};

  if (isNull(blocked_period_start) || isNull(blocked_period_end)) {
    return false;
  }

  const eventMonthStart = DateTime.fromISO(event_date, { zone: 'utc' }).startOf('month');
  const eventMonthEnd = DateTime.fromISO(event_date, { zone: 'utc' }).endOf('month');

  const periodStart = DateTime.fromISO(blocked_period_start, { zone: 'utc' }).startOf(
    'day',
  );
  const periodEnd = DateTime.fromISO(blocked_period_end, { zone: 'utc' }).endOf('day');

  const isWholeMonthBlocked =
    periodStart <= eventMonthStart && periodEnd >= eventMonthEnd;

  return isWholeMonthBlocked;
};

export default isMonthBlocked;
