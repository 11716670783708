import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { IoSwapHorizontalOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';
import { Button, Skeleton } from '_components/_core';

import {
  TitleDescription,
  HeaderTitle,
  HeaderDescription,
  ActiveCompany,
  ActiveCompanyInfo,
  AlertMessage,
  TitleDescriptionDisabledLink,
} from './styles';

export default function SmallLayout({
  isLoading,
  company,
  onModalToggle,
  collapsed,
  companies,
}) {
  const { trading_name, document_number } = company || {};
  const history = useHistory();

  const formattedCnpj = useMemo(() => {
    if (!document_number) {
      return '';
    }

    return FORMATTERS.CPF_CNPJ(document_number);
  }, [document_number]);

  const renderContent = useCallback(() => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    if (isLoading) {
      return (
        <div>
          <Skeleton className="mb-2" />
          <Skeleton width="80%" />
        </div>
      );
    }

    if (isEmpty(companies) && !company) {
      return (
        <AlertMessage>
          Nenhuma empresa encontrada
        </AlertMessage>
      );
    }

    if (!company) {
      return (
        <AlertMessage>
          Nenhuma empresa ativa
        </AlertMessage>
      );
    }

    return (
      <>
        { product === 'contabills-empresas' ? (
          <TitleDescriptionDisabledLink>
            <HeaderTitle>
              {trading_name && trading_name.toLowerCase()}
            </HeaderTitle>
            <HeaderDescription>
              {formattedCnpj}
            </HeaderDescription>
          </TitleDescriptionDisabledLink>
        )
          : (
            <TitleDescription to={company ? '/empresa-ativa' : ''}>
              <HeaderTitle>
                {trading_name && trading_name.toLowerCase()}
              </HeaderTitle>
              <HeaderDescription>
                {formattedCnpj}
              </HeaderDescription>
            </TitleDescription>
          )}
      </>
    );
  }, [company, companies, formattedCnpj, trading_name, isLoading]);

  const renderButton = useCallback(() => {
    if (isLoading) {
      return (
        <Skeleton width="30%" height="8px" className="mt-2" />
      );
    }
    if (isEmpty(companies) && !company) {
      return (
        <Button
          variant="link"
          onClick={() => history.push('/empresas/criar')}
        >
          Cadastrar Empresa
        </Button>
      );
    }

    return (
      <Button
        variant="link"
        onClick={onModalToggle}
        icon={<IoSwapHorizontalOutline />}
      >
        Trocar Empresa
      </Button>
    );
  }, [companies, history, onModalToggle, isLoading, company]);

  return (
    <ActiveCompany collapsed={collapsed}>
      <ActiveCompanyInfo>
        {renderContent()}
        {renderButton()}
      </ActiveCompanyInfo>
    </ActiveCompany>
  );
}

SmallLayout.propTypes = {
  company: {},
  collapsed: false,
  isLoading: false,
};

SmallLayout.propTypes = {
  companies: PropTypes.array,
  company: PropTypes.object,
  collapsed: PropTypes.bool,
  isLoading: PropTypes.bool,
  onModalToggle: PropTypes.func.isRequired,
};
