import styled, { css } from 'styled-components';
import { TabPane, Tabs } from 'react-bootstrap';

export const StyledTabs = styled(Tabs)`
  text-transform: none;
  border-bottom: 1px solid var(--tabs-border-color) !important;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  ${({ alignCenter }) => alignCenter && css`
    justify-content: center;
  `}

  & a.nav-item.nav-link {
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
    color: #354168;
    padding: 14px 18px;
    border: none;
    margin-bottom: -2px;
    white-space: nowrap;
    
    &:hover {
      color: #232e50;
    }
  }

  & a.nav-item.nav-link.active {
    font-weight: bold;
    border-bottom: 2px solid #354168 !important;
  }

  ${({ variant }) => variant === 'secondary' && css`
    margin-bottom: 16px;

    & a.nav-item.nav-link {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      color: rgb(71, 85, 105);
      padding: 0;
      padding: 14px 18px;
      border: none;
      margin-bottom: -2px;
      white-space: nowrap;

      &:not(:last-child) {
        /* margin-right: 32px; */
      }

      &:hover {
        color: rgb(100, 116, 139);
      }
    }

    & a.nav-item.nav-link.active {
      color: var(--accent-color);
      font-weight: 500;
      border-bottom: none !important;
    }

    ${({ floatRightLastTab }) => floatRightLastTab && css`
      & a.nav-item.nav-link:last-child {
        margin-left: auto;
      }
    `}

  `}

  ${({ variant }) => variant === 'transactions' && css`
    overflow-x: visible !important;
    overflow-y: visible !important;
    border-bottom: none !important;

    & a.nav-item.nav-link {
      line-height: 32px;
      padding: 0px 0px;
      color: #6b6c72;
      text-decoration: none;
      background: #e3e5e8;
      margin: 0 0 0 5px;
      border-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        margin-left: 0;
        padding-left: 0;
        background: none;
      }
      
      &:hover {
        color: #232e50;
      }

      .tab-income {
        border-top: 2px solid #e3e5e8;
      }

      .tab-expense {
        border-top: 2px solid #e3e5e8;
      }

      .tab-transfer {
        border-top: 2px solid #e3e5e8;
      }
    }

    & a.nav-item.nav-link.active {
      font-weight: 600;
      border-bottom: 0 !important;
      background: #FFF;

      .tab-income {
        border-top: 2px solid #4CCEAC;
      }

      .tab-expense {
        border-top: 2px solid #DB504A;
      }

      .tab-transfer {
        border-top: 2px solid #464de4;
      }
    }

    ${({ floatRightLastTab }) => floatRightLastTab && css`
      & a.nav-item.nav-link:last-child {
        margin-left: auto;
      }
    `}

  `}
`;

export const TabTitle = styled(TabPane)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 16px;
  border-radius: 2px;

  ${({ noBackground }) => noBackground && css`
    background: none;  
  `}
`;
