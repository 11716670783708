import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function Tag({ variant, children, ...rest }) {
  return (
    <Container variant={variant} {...rest}>
      {children}
    </Container>
  );
}

Tag.defaultProps = {
  variant: 'primary',
};

Tag.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default Tag;
