import React from 'react';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { HiReceiptTax } from 'react-icons/hi';
import { IoMdPeople } from 'react-icons/io';
import {
  IoArrowDown,
  IoArrowUp,
  IoCalendar,
  IoMenu,
  IoSwapHorizontalOutline,
} from 'react-icons/io5';

export const PAYMENT_STATUS_OPTIONS = [
  {
    label: 'Todas',
    value: null,
  },
  {
    label: 'Pago',
    value: 'PAYED',
  },
  {
    label: 'Todas a pagar',
    value: 'ALL_TO_PAY',
  },
  {
    label: 'Vencendo hoje',
    value: 'EXPIRING_TODAY',
  },
  {
    label: 'Vencidas (em atraso)',
    value: 'EXPIRED',
  },
];

export const PAYMENT_STATUS_OPTIONS_MOBILE = [
  {
    label: 'Todas',
    value: null,
    icon: <IoMenu className="mr-2 text-muted" />,

  },
  {
    label: 'Pago',
    value: 'PAYED',
    icon: <FaCheckCircle className="mr-2 text-success" />,

  },
  {
    label: 'Todas a pagar',
    value: 'ALL_TO_PAY',
    icon: <FaExclamationCircle className="mr-2 text-muted" />,

  },
  {
    label: 'Vencendo hoje',
    value: 'EXPIRING_TODAY',
    icon: <FaExclamationCircle className="mr-2 text-yellow" />,

  },
  {
    label: 'Vencidas',
    value: 'EXPIRED',
    icon: <FaExclamationCircle className="mr-2 text-danger" />,
  },
];

export const EXPENSES_OPTIONS = [
  {
    label: 'Todas',
    value: null,
  },
  {
    label: 'Despesas Fixas',
    value: 'FIXED_EXPENSE',
  },
  {
    label: 'Despesas Variáveis',
    value: 'VARIABLE_EXPENSE',
  },
  {
    label: 'Pessoas',
    value: 'PEOPLE',
  },
  {
    label: 'Impostos',
    value: 'TAXES',
  },
];

export const EXPENSES_OPTIONS_MOBILE = [
  {
    label: 'Todas',
    value: null,
    icon: <IoMenu className="mr-2 text-muted" />,
  },
  {
    label: 'Recebimentos',
    icon: <IoArrowUp className="mr-2 text-success" />,
    value: 'INCOME',
  },
  {
    label: 'Despesas',
    icon: <IoArrowDown className="mr-2 text-danger" />,
    value: 'EXPENSE',
  },
  {
    label: 'Despesas Fixas',
    icon: <IoArrowDown className="mr-2 text-danger" />,
    value: 'FIXED_EXPENSE',
  },
  {
    label: 'Despesas Variáveis',
    icon: <IoArrowDown className="mr-2 text-danger" />,
    value: 'VARIABLE_EXPENSE',
  },
  {
    label: 'Pessoas',
    icon: <IoMdPeople className="mr-2 text-danger" />,
    value: 'PEOPLE',
  },
  {
    label: 'Impostos',
    icon: <HiReceiptTax className="mr-2 text-danger" />,
    value: 'TAXES',
  },
  {
    label: 'Transferências',
    icon: <IoSwapHorizontalOutline className="mr-2 text-primary" />,
    value: 'TRANSFER',
  },
];

export const DATE_OPTIONS = [
  {
    label: 'Este Mês',
    value: null,
  },
  {
    label: 'Esta Semana',
    value: 'WEEK',
  },
  {
    label: 'Hoje',
    value: 'TODAY',
  },
];

export const DATE_OPTIONS_MOBILE = [
  {
    label: 'Este Mês',
    value: null,
    icon: <IoCalendar className="mr-2 text-muted" />,
  },
  {
    label: 'Esta Semana',
    value: 'WEEK',
    icon: <IoCalendar className="mr-2 text-muted" />,

  },
  {
    label: 'Hoje',
    value: 'TODAY',
    icon: <IoCalendar className="mr-2 text-muted" />,
  },
];

export const FILTERS_CONFIGURATION = {
  date: {
    label: 'Data',
    formatter: (value) => DATE_OPTIONS_MOBILE.find((option) => option.value === value).label,
  },
  payment_status: {
    label: 'Status',
    formatter: (value) => PAYMENT_STATUS_OPTIONS_MOBILE.find(
      (option) => option.value === value,
    ).label,
  },
  expenses: {
    label: 'Tipo',
    formatter: (value) => EXPENSES_OPTIONS_MOBILE.find(
      (option) => option.value === value,
    ).label,
  },
};
