import constants from '../../_constants/accounts.constants';
import service from '../../../services/accounts.service';

const duplicateAccount = (account, callback) => {
  return (dispatch) => {
    dispatch(request(account));

    service.duplicate(account).then(
      (account) => {
        dispatch(success(account));
        callback();
      },
      (error) => {
        if (error.response.status === 400) {
          dispatch(failure(error.response.data));
        }
      },
    );
  };

  function request(account) {
    return {
      type: constants.ACCOUNT_DUPLICATE_REQUEST,
      payload: { account },
    };
  }
  function success(account) {
    return {
      type: constants.ACCOUNT_DUPLICATE_SUCESS,
      payload: { account },
    };
  }
  function failure(errors) {
    return {
      type: constants.ACCOUNT_DUPLICATE_FAILURE,
      payload: { errors },
    };
  }
};

export default duplicateAccount;
