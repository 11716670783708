import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import FormControl from 'react-bootstrap/FormControl';

const Search = ({
  className,
  placeholder,
  onChange,
  isMobile,
}) => {
  const [searchCriteria, setSearchCriteria] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(0);

  const inputRef = useRef(null);

  const delayedOnFilter = (query) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTypingTimeout = setTimeout(() => {
      onChange(query);
    }, 475);

    setTypingTimeout(newTypingTimeout);
  };

  const handleInputChange = (e) => {
    const searchValue = e.target.value;

    setSearchCriteria(searchValue);

    delayedOnFilter(searchValue.trim());
  };

  useEffect(() => {
    if (inputRef.current && !isMobile) {
      inputRef.current.focus();
    }
  }, [isMobile]);

  return (
    <FormControl
      ref={inputRef}
      className={className}
      onChange={handleInputChange}
      value={searchCriteria}
      type="text"
      placeholder={placeholder}
    />
  );
};

Search.defaultProps = {
  placeholder: 'Pesquisar...',
  className: null,
  isMobile: false,
};

Search.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default Search;
