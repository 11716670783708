import styled from 'styled-components';
import { Table } from '_components/_core';

export const AccountName = styled.p`
  font-weight: bold;
`;

export const StyledTable = styled(Table)`
  &&& {

    thead tr th {
      padding: 10px 5px !important;
    }

    tbody tr td {
      padding: 10px 5px !important;
      font-size: 11px !important;
    }

    thead tr th:nth-child(1),
    tbody tr td:nth-child(1),
    tfoot tr td:nth-child(1) {
      width: 7% !important;
    }

    thead tr th:nth-child(3),
    tbody tr td:nth-child(3),
    tfoot tr td:nth-child(3) {
      width: 10% !important;
    }

    thead tr th:nth-child(2),
    tbody tr td:nth-child(2),
    tfoot tr td:nth-child(2) {
      width: 40% !important;
      white-space: pre-wrap;

      & { 
        button {
          font-size: 11px !important;
        }
      }
    }

    tbody tr td:nth-child(2) {
      line-height: 15px;
    }

    thead tr th:nth-child(4),
    tbody tr td:nth-child(4),
    tfoot tr td:nth-child(4) {
      width: 14% !important;
    }

    thead tr th:nth-child(5),
    tbody tr td:nth-child(5),
    tfoot tr td:nth-child(5) {
      width: 14% !important;
    }

    thead tr th:nth-child(6),
    tbody tr td:nth-child(6),
    tfoot tr td:nth-child(6) {
      width: 10% !important;
    }

    @media print {
      table-layout: fixed;

      thead tr th {
        padding: 10px 5px !important;
      }

      tbody tr td {
        padding: 10px 5px !important;
      }
    }
  }
`;
