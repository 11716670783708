import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';

import { Button, FormTextField, FormSelectField } from '_components/_core';
import { CREDENTIAL_TYPE_OPTIONS } from 'helpers/constants';

import { AddCredentialSchema, EditCredentialSchema } from '../../utilities';
import { StyledModal, ModalFooter } from './styles';

const CredentialModal = ({
  company,
  credential,
  isVisible,
  isLoading,
  onModalToggle,
  onAddCredential,
  onUpdateCredential,
  onCompanyInfoChange,
}) => {
  const handleSubmit = useCallback((values, { resetForm }) => {
    const { id: company_id } = company || {};
    if (values.id) {
      onUpdateCredential(company_id, values.id, values, (updatedCompany) => {
        resetForm();
        onModalToggle();
        onCompanyInfoChange(updatedCompany);
      });
    } else {
      onAddCredential(company_id, values, (updatedCompany) => {
        resetForm();
        onModalToggle();
        onCompanyInfoChange(updatedCompany);
      });
    }
  }, [
    company,
    onUpdateCredential,
    onAddCredential,
    onModalToggle,
    onCompanyInfoChange,
  ]);

  const renderModalFooter = useCallback((handleSubmit, isValid) => (
    <ModalFooter>
      <Button variant="secondary" onClick={onModalToggle}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={!isValid || isLoading}
      >
        Salvar
      </Button>
    </ModalFooter>
  ), [onModalToggle, isLoading]);

  if (!isVisible) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        type: credential ? credential.type : 'CITY_HALL',
        login: credential ? credential.login : '',
        password: '',
      }}
      validationSchema={credential ? EditCredentialSchema : AddCredentialSchema}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isValid }) => (
        <>
          <StyledModal
            title={credential ? 'Editar credencial' : 'Cadastrar nova credencial'}
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit, isValid)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="4">
                  <Form.Label>Tipo de credencial</Form.Label>
                  <FormSelectField name="type" options={CREDENTIAL_TYPE_OPTIONS} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>
                    Login
                  </Form.Label>
                  <FormTextField name="login" placeholder="Login utilizado para acessar o portal da prefeitura" />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>
                    Senha
                    {credential && (<span> (deixe em branco para não alterar)</span>)}
                  </Form.Label>
                  <FormTextField name="password" placeholder="Senha utilizada para acessar o portal da prefeitura" />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default CredentialModal;

CredentialModal.defaultProps = {
  isVisible: false,
  credential: null,
  isLoading: false,
  company: null,
};

CredentialModal.propTypes = {
  company: PropTypes.object,
  isVisible: PropTypes.bool,
  credential: PropTypes.object,
  onModalToggle: PropTypes.func.isRequired,
  onAddCredential: PropTypes.func.isRequired,
  onUpdateCredential: PropTypes.func.isRequired,
  onCompanyInfoChange: PropTypes.func.isRequired,
  isLoading: PropTypes.func,
};
