import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { FormSelectField } from '_components/_core';

function CustomSelectField({
  name,
  options,
  disabled,
  placeholder,
  innerRef,
  creatable,
  menuWidth,
  smallStyling,
  customStyleName,
  ...rest
}) {
  const normalStyles = useMemo(() => ({
    control: (baseStyles) => ({
      ...baseStyles,
      fontWeight: 400,
      cursor: 'pointer',
      borderColor: '#d5dcec',
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      fontSize: '13px',
      width: '100% !important',
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      fontSize: '13px',
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: 9999,
      width: menuWidth,
    }),
    groupHeading: (baseStyles) => ({
      ...baseStyles,
      fontSize: '12px',
      paddingBottom: '8px',
      borderBottom: '1px solid #e0e0e0',
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      cursor: 'pointer',
      backgroundColor: state.isFocused ? '#eceef1' : 'transparent',
      color: state.isFocused ? '#00784e' : 'rgb(0, 0, 0)',
      fontWeight: state.isFocused ? 500 : 400,
      fontSize: '13px',
    }),
  }), [menuWidth]);

  const smallStyles = useMemo(() => ({
    control: (baseStyles) => ({
      ...baseStyles,
      fontWeight: 500,
      cursor: 'pointer',
      borderColor: '#d5dcec',
      minHeight: 32,
      maxHeight: 32,
      backgroundColor: '#F9FBFC',
      border: '1px solid #E8E7EA',
      borderRadius: '8px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      fontSize: '13px',
      width: '100% !important',
      margin: 0,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: 32,
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      fontSize: '13px',
      top: '46%',
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: 9999,
      width: menuWidth,
    }),
    groupHeading: (baseStyles) => ({
      ...baseStyles,
      fontSize: '12px',
      paddingBottom: '8px',
      borderBottom: '1px solid #e0e0e0',
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      cursor: 'pointer',
      backgroundColor: state.isFocused ? '#eceef1' : 'transparent',
      color: state.isFocused ? '#00784e' : 'rgb(0, 0, 0)',
      fontWeight: state.isFocused ? 500 : 400,
      fontSize: '13px',
    }),
  }), [menuWidth]);

  const transactionsStyles = useMemo(() => ({
    control: (baseStyles) => ({
      ...baseStyles,
      cursor: 'pointer',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),

    indicatorsContainer: (provided) => ({
      ...provided,
      height: 32,
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: 9999,
      width: menuWidth,
    }),
    groupHeading: (baseStyles) => ({
      ...baseStyles,
      fontSize: '12px',
      paddingBottom: '8px',
      borderBottom: '1px solid #e0e0e0',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      cursor: 'pointer',
      backgroundColor: state.isFocused ? '#eceef1' : 'transparent',
      color: state.isFocused ? '#00784e' : 'rgb(0, 0, 0)',
      fontWeight: state.isFocused ? 500 : 400,
    }),
  }), [menuWidth]);

  const styles = useMemo(() => ({
    normal: normalStyles,
    small: smallStyles,
    transactions: transactionsStyles,
  }), [normalStyles, smallStyles, transactionsStyles]);

  const getCustomStyles = useCallback(() => {
    if (customStyleName) {
      return styles[customStyleName];
    }

    if (smallStyling) {
      return smallStyles;
    }

    return normalStyles;
  }, [smallStyling, normalStyles, smallStyles, customStyleName, styles]);

  return (
    <FormSelectField
      name={name}
      isClearable
      options={options}
      disabled={disabled}
      placeholder={placeholder}
      tabSelectsValue={false}
      innerRef={innerRef}
      creatable={creatable}
      customStyles={getCustomStyles()}
      {...rest}
    />
  );
}

CustomSelectField.defaultProps = {
  options: [],
  disabled: false,
  placeholder: '',
  innerRef: null,
  creatable: null,
  menuWidth: '100%',
  smallStyling: false,
  customStyleName: 'normal',
};

CustomSelectField.propTypes = {
  menuWidth: PropTypes.string,
  innerRef: PropTypes.object,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  creatable: PropTypes.string,
  smallStyling: PropTypes.bool,
  customStyleName: PropTypes.string,
};

export default CustomSelectField;
