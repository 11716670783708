import React from 'react';
import { Link } from 'react-router-dom';

export default function LogoZenpaperSmall() {
  const publicFolder = process.env.PUBLIC_URL;

  return (
    <Link className="navbar-brand p-0" to="/dashboard">
      <img
        src={`${publicFolder}/assets/img/logo-zenply-small.svg`}
        alt="logo"
        width="30"
        className="ml-1 mr-1"
      />
    </Link>
  );
}
