import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ product }) => product === 'contabills-empresas' && css`
    @media print {
      font-family: 'Poppins' !important;
      color: #000 !important;
      text-transform: uppercase;

      table {
        font-family: 'Poppins' !important;
        color: #000 !important;
      }
    }
  `}
`;

export const Table = styled.table`
  font-family: var(--font-family) !important;

  small {
    font-size: 100%;
  }
`;

export const Label = styled.small`
  font-weight: bold;
`;

export const Header = styled.small`
  font-size: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const CompanyAvatar = styled.img`
  width: 80px;
  max-height: 80px;
  border-radius: 10%;
`;

export const FirstLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledHeaderContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  ${({ logoPosition }) => logoPosition === 'start' && css`
    grid-template-columns: 0.1fr 1fr 1fr;
  `}
  ${({ logoPosition }) => logoPosition === 'end' && css`
    grid-template-columns: 1fr 1fr 0.1fr;
  `}
  ${({ logoPosition }) => logoPosition === 'hide' && css`
    grid-template-columns: 2fr 1fr;
  `}
  ${({ showCompanyData }) => showCompanyData === false && css`
    grid-template-columns: 2fr 1fr;
  `}
  ${({ showCompanyData, logoPosition }) => (showCompanyData === false && logoPosition === 'center') && css`
    grid-template-columns: 1.4fr 1fr;
  `}
  ${({ showCompanyData, logoPosition }) => (showCompanyData === false && logoPosition === 'end') && css`
    grid-template-columns: 3fr 0.5fr;
  `}
  ${({ showCompanyData, logoPosition }) => (showCompanyData === false && logoPosition === 'hide') && css`
    grid-template-columns: 2fr 0fr;
  `}
  ${({ showCompanyData, logoPosition, showPageData }) => (showCompanyData === false && logoPosition === 'end' && showPageData === false) && css`
    grid-template-columns: 2fr 0fr;
  `}
  ${({ showCompanyData, logoPosition, showPageData }) => (showCompanyData === true && logoPosition === 'end' && showPageData === false) && css`
    grid-template-columns: 2fr 0fr;
  `}
`;

export const StyledAvatar = styled.img`
  max-width: 200px;
  max-height: 80px;
  border-radius: 10%;
`;

export const StyledAvatarContainer = styled.div`
  ${({ logoPosition }) => logoPosition === 'start' && css`
    order: 1;
    text-align: start;
  `}
  ${({ logoPosition }) => logoPosition === 'center' && css`
    order: 2;
    text-align: center;
  `}
  ${({ logoPosition }) => logoPosition === 'end' && css`
    order: 3;
    text-align: end;
  `}
  ${({ logoPosition }) => logoPosition === 'hide' && css`
    display: none;
  `}
  ${({ showCompanyData, logoPosition }) => (showCompanyData === false && logoPosition === 'center') && css`
    text-align: end;
  `}
`;

export const StyledCompanyData = styled.div`
  ${({ logoPosition }) => logoPosition === 'start' && css`
    order: 2;
    flex: 1;
    margin-left: 16px;
  `}
  ${({ logoPosition }) => logoPosition === 'center' && css`
    order: 1;
    flex: unset !important;
    margin-left: 0 !important;
  `}
  ${({ logoPosition }) => logoPosition === 'end' && css`
    order: 1;
  `}
`;

export const StyledPageData = styled.div`
  ${({ logoPosition }) => logoPosition === 'start' && css`
    order: 3;
    text-align: end;
  `}
  ${({ logoPosition }) => logoPosition === 'center' && css`
    order: 3;
    text-align: end;
  `}
  ${({ logoPosition }) => logoPosition === 'end' && css`
    order: 2;
    text-align: end;
  `}
  ${({ logoPosition }) => logoPosition === 'hide' && css`
    text-align: end;
  `}
  ${({ showCompanyData }) => showCompanyData === false && css`
    grid-template-columns: 2fr 1fr;
  `}
`;
