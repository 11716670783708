import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import accountsActionCreators from '../_actions/accounts';

const useActiveCompany = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const activeCompany = useSelector((state) => state.company.activeCompany);

  useEffect(() => {
    const refetchPaths = {
      '/contabil/plano-contas': accountsActionCreators.getAllAccounts,
    };

    const action = refetchPaths[pathname];

    if (action) {
      dispatch(action());
    }
  }, [activeCompany, dispatch, pathname]);

  return {
    activeCompany,
  };
};

export default useActiveCompany;
