import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { Button, FormTextField } from '_components/_core';

import { PasswordResetSchema } from './utilities';

import { StyledButton } from './styles';

function EmailForm({ onPasswordReset, isLoading, history }) {
  const product = process.env.REACT_APP_CONTABILLS_APP;

  const handlePasswordReset = async (values) => {
    onPasswordReset(values.email, product);
  };

  return (
    <Form>
      <Row>
        <Col>
          <Formik
            enableReinitialize
            initialValues={{
              email: '',
            }}
            onSubmit={handlePasswordReset}
            validationSchema={PasswordResetSchema}
          >
            {({ handleSubmit, isValid }) => (
              <>
                <Form.Row>
                  <Form.Group as={Col}>
                    <FormTextField name="email" placeholder="E-mail" />
                  </Form.Group>
                </Form.Row>
                {product === 'contabills-empresas' ? (
                  <StyledButton
                    type="submit"
                    variant="success"
                    onClick={handleSubmit}
                    disabled={isLoading || !isValid}
                    isLoading={isLoading}
                  >
                    Recuperar Senha
                  </StyledButton>
                )
                  : (
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      disabled={isLoading || !isValid}
                      isLoading={isLoading}
                    >
                      Recuperar Senha
                    </Button>
                  )}
                <div>
                  <Button className="ml-0 pl-0 pt-3" variant="link" onClick={() => history.push('/entrar')}>Voltar para login</Button>
                </div>
              </>
            )}
          </Formik>
        </Col>
      </Row>
    </Form>
  );
}

EmailForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  onPasswordReset: PropTypes.func.isRequired,
};

export default withRouter(EmailForm);
