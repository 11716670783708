import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import ListGroup from 'react-bootstrap/ListGroup';
import { useLocation } from 'react-router-dom';

import { PageHeader } from '_components/_core';

import ChildBenefitTables from './ChildBenefitTables/ChildBenefitTablesContainer';
import InssTables from './InssTables/InssTablesContainer';
import IrrfTables from './IrrfTables/IrrfTablesContainer';
import MinimumWageTables from './MinimumWageTables/MinimumWageTablesContainer';

import { Menu, MenuCategory } from './styles';

function Tables() {
  const location = useLocation();

  const tabs = useMemo(() => ({
    child_benefit: () => (<ChildBenefitTables />),
    inss: () => (<InssTables />),
    irrf: () => (<IrrfTables />),
    minimum_wage: () => (<MinimumWageTables />),
  }), []);

  const [activeTab, setActiveTab] = useState(() => {
    const urlParams = new URLSearchParams(location.search);

    const tab = urlParams.get('tab');

    if (tab && Object.keys(tabs).includes(tab)) {
      return tab;
    }

    return 'minimum_wage';
  });

  const renderActiveTab = useCallback(() => {
    const ActiveTab = tabs[activeTab];

    return <ActiveTab />;
  }, [tabs, activeTab]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Referências"
        description="Consulte as tabelas de Salário Mínimo, INSS, IRRF e Salário Família."
      />
      <Tab.Container defaultActiveKey={activeTab}>
        <Row>
          <Col sm={3}>
            <Menu>
              <MenuCategory>
                Tabelas
              </MenuCategory>
              <ListGroup.Item as="button" action eventKey="minimum_wage" onClick={() => setActiveTab('minimum_wage')}>
                Salário Mínimo
              </ListGroup.Item>
              <ListGroup.Item as="button" action eventKey="inss" onClick={() => setActiveTab('inss')}>
                INSS
              </ListGroup.Item>
              <ListGroup.Item as="button" action eventKey="irrf" onClick={() => setActiveTab('irrf')}>
                IRRF
              </ListGroup.Item>
              <ListGroup.Item as="button" action eventKey="child_benefit" onClick={() => setActiveTab('child_benefit')}>
                Salário Família
              </ListGroup.Item>
            </Menu>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey={activeTab}>
                {renderActiveTab(activeTab)}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}

export default Tables;
