import styled from 'styled-components';

import Button from '_components/_core/Button';
import Modal from '_components/_core/Modal';

export const StyledModal = styled(Modal)`
  padding: 0 !important;

  div.modal-body {
    padding: 24px !important;
    max-height: 80vh;
    overflow-y: auto;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchButton = styled(Button)`
  margin-top: 26px;
  margin-left: 15px;
`;

export const StyledHr = styled.hr`
  margin-bottom: 2rem;
`;
