import styled, { css } from 'styled-components';

export const Container = styled.div`
  /* background-color: #E3E6E8; */
  border-radius: 8px;
  padding: 8px;
  display: grid;
  grid-column-gap: 8px;
  
  ${({ columns }) => css`  
    grid-template-columns: ${`repeat(${columns}, minmax(0, 1fr))`};
  `}
  
  
  /* white-space: nowrap;
  overflow: auto;
  
  display: flex;
  justify-content: flex-start;
  align-items: center; */
  /* flex-wrap: nowrap !important;
  overflow-x: scroll;
  overflow-y: hidden !important; */
`;

export const Option = styled.div`
  background-color: #fff;
  border-color: #E3E6E8;
  color: rgb(129, 142, 162);
  font-size: 0.8em;
  font-weight: 600;
  padding: 6px 4px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer !important;
  border: 2px solid #fff;
  &:first-child {
    grid-column: 1 / -1;
  }
  ${({ active }) => active && css`  
    /* background-color: #E3E6E8; */
    border-color: var(--title-color);
    border: 2px solid #E3E6E8;
    color: var(--title-color);
    
  `}
`;
