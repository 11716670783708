import styled, { css } from 'styled-components';
import Modal from 'react-bootstrap/Modal';

export const StyledModal = styled(Modal)`
  div.modal-body {
    padding: 24px !important;
    padding-bottom: 12px !important;

    ${({ noPadding }) => noPadding && css`
      padding: 0 !important;
    `}
  }
  
  ${({ noMaxWidth }) => !noMaxWidth && css`
    div.modal-dialog {
      @media (min-width: 768px) {
        max-width: 85%;
      }
      @media (min-width: 830px) {
        max-width: 75%;
      }
      @media (min-width: 1366px) {
        max-width: 45%;
      }
    }
  `}
`;

export const StyledModalBody = styled(Modal.Body)`
  background-color: #FFF !important;
`;

export const StyledModalFooter = styled(Modal.Footer)`
  padding: 15px !important;
`;

export default StyledModal;
