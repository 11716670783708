import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import Form from 'react-bootstrap/Form';

import { StyledFormCheck } from './styles';

// TODO: Mover para _shared
function FormInitialBalanceField({ className, ...props }) {
  const formik = useFormikContext();
  const [field, meta] = useField(props);

  const error = meta.error && meta.touched ? meta.error : '';

  return (
    <>
      <Form inline className={className}>
        <StyledFormCheck
          style={{
            display: 'inline-block',
          }}
          inline
          id="balance_type_positivo"
          label="Positivo"
          name="balance_type"
          type="radio"
          checked={field.value === 'POSITIVO'}
          onClick={() => formik.setFieldValue(field.name, 'POSITIVO')}
        />
        <StyledFormCheck
          style={{
            display: 'inline-block',
          }}
          inline
          id="balance_type_negativo"
          label="Negativo"
          name="balance_type"
          type="radio"
          checked={field.value === 'NEGATIVO'}
          onClick={() => formik.setFieldValue(field.name, 'NEGATIVO')}
        />
        <StyledFormCheck
          style={{
            display: 'inline-block',
          }}
          inline
          id="balance_type_zerado"
          label="Zerado (ou não informar)"
          name="balance_type"
          type="radio"
          checked={field.value === 'ZERADO'}
          onClick={() => formik.setFieldValue(field.name, 'ZERADO')}
        />
      </Form>
      {error && <small className="text-danger">{error}</small>}
    </>
  );
}

FormInitialBalanceField.defaultProps = {
  className: 'mt-3',
};

FormInitialBalanceField.propTypes = {
  className: PropTypes.string,
};

export default FormInitialBalanceField;
