import { connect } from 'react-redux';
import { compose } from 'redux';

import userActionCreators from '_store/_actions/user';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Users from './Users';

const mapStateToProps = (state) => ({
  users: state.user.users,
  total: state.user.total,
  isLoading: state.user.isLoading,
});

const mapDispatchToProps = {
  onFetchUsers: userActionCreators.getAllUsers,
  onInviteUser: userActionCreators.inviteUser,
  onDeleteUser: userActionCreators.deleteUser,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['manager_users'], 'all', true),
)(Users);
