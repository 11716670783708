import FORMATTERS from 'helpers/formatters';

export const DEFAULT_FILTERS = {
  date_competence_start: null,
  date_competence_end: null,
  date_emission_start: null,
  date_emission_end: null,
  invoice_number: null,
  invoice_status: 'NORMAL',
  service_taker: null,
  service_amount_start: null,
  service_amount_end: null,
};

export const DEFAULT_COLUMNS = [
  'date_competence',
  'date_emission',
  'invoice_number',
  'invoice_status',
  'id',
];

export const TABLE_COLUMNS = [
  {
    label: 'Competência',
    keyName: 'date_competence',
    sortingKey: 'date_competence',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.DATE_MMYYYY,
    order: 1,
  },
  {
    label: 'Data de Emissão',
    keyName: 'date_emission',
    sortingKey: 'date_emission',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.DATE_DDMMYYYY,
    order: 2,
  },
  {
    label: 'Número',
    keyName: 'invoice_number',
    sortingKey: 'invoice_number',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.STRING,
    order: 3,
  },
  {
    label: 'Status',
    keyName: 'invoice_status',
    sortingKey: 'invoice_status',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.STRING,
    order: 4,
  },
  {
    label: 'Tomador',
    keyName: 'service_taker',
    sortingKey: 'service_taker',
    width: 40,
    formatter: FORMATTERS.UPPERCASE,
    order: 5,
  },
  {
    label: 'Valor Bruto',
    keyName: 'service_amount',
    sortingKey: 'service_amount',
    align: 'center',
    width: 8,
    formatter: FORMATTERS.NUMBER,
    order: 6,
    sumOnFooter: true,
  },
];

export const EMISSION_STATUS = [
  {
    label: 'Todos',
    value: null,
  },
  {
    label: 'Aguardando Confirmação',
    value: 'pending_user_authorization',
  },
  {
    label: 'Na fila de Emissão',
    value: 'user_authorized',
  },
  {
    label: 'Aguardando Prefeitura',
    value: 'pending_city_hall_authorization',
  },
  {
    label: 'Emitida com Sucesso',
    value: 'issued',
  },
  {
    label: 'Emitida com Erro',
    value: 'issued_error',
  },
];
