import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/pt-br';
import isEmpty from 'lodash/isEmpty';

import { PrintOptionsContext } from '_components/Financial/Reports/components/PrintOptionsContext';
import CompanyData from './components/CompanyData/CompanyData';
import PageData from './components/PageData/PageData';

import styles from './styles.module.scss';
import {
  Container,
  Table,
  Label,
  Header,
  StyledHeaderContent,
  StyledAvatar,
  StyledAvatarContainer,
} from './styles';

export default function ReportHeader({
  name,
  description,
  companyName,
  companyDocumentNumber,
  startDate,
  endDate,
  totalPages,
  currentPage,
  className,
  formattedPeriod,
  avatar_url,
  accounts,
  costsCenter,
  account_ids,
  cost_center_ids,
}) {
  moment.locale('pt-br');

  const { value } = useContext(PrintOptionsContext) || {};

  const reportDate = moment().format('L');
  const reportTime = moment().format('LT');
  const formattedStartDate = moment(startDate).format('L');
  const formattedEndDate = moment(endDate).format('L');

  const product = process.env.REACT_APP_CONTABILLS_APP;

  const filteredAccounts = useMemo(() => {
    if (!accounts || !account_ids) {
      return [];
    }

    return accounts.filter(
      (account) => account_ids.includes(account.value),
    );
  }, [accounts, account_ids]);

  const filteredCostsCenter = useMemo(() => {
    if (!costsCenter || !cost_center_ids) {
      return [];
    }

    return costsCenter.filter(
      (costCenter) => cost_center_ids.includes(costCenter.id),
    );
  }, [costsCenter, cost_center_ids]);

  const getAccountsLabels = useMemo(() => {
    if (filteredAccounts) {
      return filteredAccounts.map((account) => account.label);
    }
    return [];
  }, [filteredAccounts]);

  const getCostsCenterDescriptions = useMemo(() => {
    if (filteredCostsCenter) {
      return filteredCostsCenter.map((costCenter) => costCenter.description);
    }
    return [];
  }, [filteredCostsCenter]);

  const renderAccountsLabels = useMemo(() => {
    if (isEmpty(account_ids)) {
      return null;
    }

    return (
      <div>
        <small contentEditable className="report-description">
          {`Contas: ${getAccountsLabels.join(', ')}`}
        </small>
      </div>
    );
  }, [account_ids, getAccountsLabels]);

  const renderCostsCenterDescriptions = useMemo(() => {
    if (isEmpty(cost_center_ids)) {
      return null;
    }

    return (
      <>
        <small contentEditable className="report-description">
          {`Centros de Custo: ${getCostsCenterDescriptions.join(', ')}`}
        </small>
      </>
    );
  }, [cost_center_ids, getCostsCenterDescriptions]);

  if (product === 'contabills-empresas') {
    const renderCompanyData = (value) => {
      if (!value.showCompanyData) {
        return null;
      }

      return (
        <CompanyData
          companyName={companyName}
          className={className}
          description={description}
          formattedPeriod={formattedPeriod}
          companyDocumentNumber={companyDocumentNumber}
          logoPosition={value.logoPosition}
        />
      );
    };

    const renderPageData = (value) => {
      if (!value.showPageData) {
        return null;
      }

      return (
        <PageData
          reportDate={reportDate}
          reportTime={reportTime}
          description={description}
          logoPosition={value.logoPosition}
        />
      );
    };

    return (
      <Container
        product={product}
        className={classNames(className, styles.container)}
      >
        <div>
          <StyledHeaderContent
            logoPosition={value.logoPosition}
            showCompanyData={value.showCompanyData}
            showPageData={value.showPageData}
          >
            <StyledAvatarContainer
              logoPosition={value.logoPosition}
              showCompanyData={value.showCompanyData}
              showPageData={value.showPageData}
            >
              <StyledAvatar src={avatar_url} />
            </StyledAvatarContainer>
            {renderCompanyData(value)}
            {renderPageData(value)}
          </StyledHeaderContent>
        </div>
        <div className="row mt-4 mb-4">
          <div className="col-xs-12 mt-2 col-md-12 col-lg-12 text-center">
            <Header contentEditable>
              <strong className="report-header">{name}</strong>
            </Header>
            <br />
            {renderAccountsLabels}
            {renderCostsCenterDescriptions}
          </div>
        </div>
      </Container>
    );
  }

  return (
    <div className={classNames(className, styles.container)}>
      <div className="row">
        <div className="col-md-12">
          <Table>
            <tr>
              <td style={{ width: '9%' }}>
                <Label>
                  <strong>Empresa:</strong>
                </Label>
              </td>
              <td style={{ width: '78%' }}>
                <small>
                  <strong className={styles.companyName}>{companyName}</strong>
                </small>
              </td>
              <td style={{ width: '13%' }}>
                <Label>Emissão:</Label>
              </td>
              <td>
                <small>{reportDate}</small>
              </td>
            </tr>
            <tr>
              <td style={{ width: '9%' }}>
                <Label>CNPJ:</Label>
              </td>
              <td style={{ width: '78%' }}>
                <small>{companyDocumentNumber}</small>
              </td>
              <td style={{ width: '13%' }}>
                <Label>Hora:</Label>
              </td>
              <td style={{ textAlign: 'right' }}>
                <small>{reportTime}</small>
              </td>
            </tr>
            <tr>
              <td style={{ width: '9%' }}>
                <Label>Período:</Label>
              </td>
              <td style={{ width: '78%' }}>
                <small>
                  {formattedStartDate}
                  {' '}
                  à
                  {' '}
                  {formattedEndDate}
                </small>
              </td>
              <td style={{ width: '13%' }}>
                <Label>Página:</Label>
              </td>
              <td style={{ textAlign: 'right' }}>
                <small>
                  {currentPage}
                  {' '}
                  de
                  {' '}
                  {totalPages}
                </small>
              </td>
            </tr>
          </Table>
        </div>
      </div>
      <div className="row mt-4 mb-4">
        <div className="col-xs-12 col-md-12 col-lg-12 text-center">
          <Header>
            <strong className="report-header">{name}</strong>
          </Header>
        </div>
      </div>
    </div>
  );
}

ReportHeader.defaultProps = {
  formattedPeriod: null,
};

ReportHeader.propTypes = {
  name: PropTypes.any,
  companyName: PropTypes.any,
  companyDocumentNumber: PropTypes.any,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  totalPages: PropTypes.any,
  currentPage: PropTypes.any,
  className: PropTypes.any,
  formattedPeriod: PropTypes.string,
  description: PropTypes.string,
  avatar_url: PropTypes.string,
  accounts: PropTypes.array,
  costsCenter: PropTypes.array,
  account_ids: PropTypes.array,
  cost_center_ids: PropTypes.array,
};
