import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Dropdown } from 'react-bootstrap';
import { MdOutlineBackspace } from 'react-icons/md';

import Button from '_components/_core/Button';

export const CustomFilterButton = styled(Button)`
  position: absolute;
  right: 4px;
  top: calc(50% - 29px);
  padding: 0;

  &:after {
    display: none !important;
  }

  &:hover {
    svg {
      color: ${darken(0.3, '#a8abad')} !important;
    }
  }
`;

export const FilterMenu = styled(Dropdown.Menu)`

`;

export const StyledDropdown = styled(Dropdown)`
  &&& {
    
  }
`;

export const CalculatorContainer = styled(Dropdown.Menu)`
  padding: 0;
  z-index: 990;
  box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
  border: none;
  font-size: 0.75rem;
  font-family: 'Poppins';
  top: -150px !important;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: -15px;
  top: -15px;
  border-radius: 100%;
  background-color: #223C61;
  color: #fff;    

  svg {
    font-size: 0.8em;
  }
`;

export const StyledBackspace = styled(MdOutlineBackspace)`

  &:hover {
    fill: #00ab6f;
  }
`;

export const CalculatorContent = styled.div`
  z-index: 10;
  position: fixed;
  width: 200px;
  background-color: #fff;
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.25), 0 4px 10px rgba(0, 0, 0, 0.15);
  /* border: 2px solid #e5e5e5; */
  border-radius: 8px;
  color: #6b6c72;
  font-size: 2em;
`;

export const CalculatorInputContainer = styled.div`
  border-bottom: 1px solid #d4d7dc;
  margin: 16px 10px 0;
  text-align: right;
  font-size: 14px;
  padding-bottom: 8px;

   ${({ hasError }) => hasError && css`
    border-bottom: 1px solid #ff0000;
  `};

  input {
    width: 100%;
    border: none;
    box-shadow: none;
    padding-right: 22px;

    &:focus, &:active, &:hover {
      box-shadow: none;
    }
  }

  svg {
    position: absolute;
    top: 43px;
    right: 10px;

    &:hover {
      color: ${darken(0.3, '#a8abad')} !important;      
    }
  }
`;

export const CalculatorResultContainer = styled.div`
  width: 100%;
  text-align: right;
  cursor: text;
  box-sizing: border-box;
  padding: 10px 10px 0;
  font-size: 0.5em;
`;

export const CalculatorButtonsContainer = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(4, 49px);
`;

export const CalculatorButton = styled.div`
  line-height: 38px;
  height: 45px;
  text-align: center;
  padding: 2px 6px 3px;
  font-weight: 300;

  &:hover {
    color: #00ab6f;
    background-color: #f5f5f5;
    border-radius: 4px;
    cursor: pointer;
  }

  ${({ isOperator }) => isOperator && css`
    font-weight: 600;
  `};
`;

export const CalculatorFooter = styled.div`
  display: flex;
  color: #00ab6f;

  div {
    line-height: 38px;
    width: 38px;
    height: 45px;
    text-align: center;
    padding: 2px 6px 3px;

    &:hover {
      color: #00ab6f;
      background-color: #f5f5f5;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  div:first-child {
    width: calc(100% - 50px);
  }

  div:last-child {
    width: 50px;
  }
`;
