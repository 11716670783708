import styled from 'styled-components';
import { Table } from '_components/_core';

export const StyledTable = styled(Table)`
  &&& {
    thead tr th {
      padding: 10px 5px !important;
    }

    tbody tr td {
      padding: 10px 5px !important;
    }
  }
`;
