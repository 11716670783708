import addAssociate from './addAssociate';
import deleteAssociate from './deleteAssociate';
import getAllAssociates from './getAllAssociates';
import getAssociateById from './getAssociateById';
import updateAssociate from './updateAssociate';

export default {
  addAssociate,
  deleteAssociate,
  getAllAssociates,
  getAssociateById,
  updateAssociate,
};
