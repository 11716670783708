import styled from 'styled-components';
import Select from 'react-select';

export const StyledSelect = styled(Select)`
  &&& {
    .css-zk88xw-singleValue,
    .css-1wa3eu0-placeholder,
    .css-1qbdaq9-option,
    .css-ujv57-option,
    .css-11vywy8-option,
    .css-1t7hbm9-option,
    .css-1dinwnf-option,
    .css-2ji0zn-option,
    .css-145fhl4-singleValue {
      font-size: 13px !important;
    }

    .css-elug3-control {
      background-color: #e9ecef;
    }

    .css-12jo7m5 {
      font-size: 95% !important;
    }

    .css-xb97g8 {
      cursor: pointer;
    }

    .css-26l3qy-menu {
      z-index: 9999 !important;
    }

    .css-1t7hbm9-option,
    .css-1dinwnf-option {
      color: rgb(108, 117, 125);
    }
  }
`;
