import * as yup from 'yup';

import {
  REQUIRED,
  TOO_LONG,
  EMAIL,
} from 'helpers/validation-messages';

const AccountantSchema = yup.object().shape({
  name: yup.string().required(REQUIRED),
  document_type: yup.mixed().oneOf(['', 'CPF', 'CNPJ']).required(),
  document_number: yup.string().max(18, TOO_LONG).required(REQUIRED),
  email: yup.string().max(255).email(EMAIL),
  phone_number: yup.string().max(255),
  crc_number: yup.string().max(255).nullable(true),
  crc_sequential: yup.string().max(255).nullable(true),
  crc_expire_date: yup.date().nullable(true),
  crc_uf: yup.string().max(2).nullable(true),
  address_zip_code: yup.string().max(255),
  address_street: yup.string().max(255),
  address_number: yup.string().max(255),
  address_district: yup.string().max(255),
  address_complement: yup.string().max(255).nullable(true),
  address_city: yup.string().max(255).required(REQUIRED),
  address_state: yup.string().max(2).required(REQUIRED),
  address_city_ibge: yup.number().nullable(true),
  address_state_ibge: yup.number().nullable(true),
});

export default AccountantSchema;
