import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Popover from 'react-bootstrap/Popover';

export const TitleDescription = styled(Link)`

`;

export const TitleDescriptionDisabledLink = styled(Link)`
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const HeaderTitle = styled.h4`
  font-weight: 500;
  margin-bottom: 0;
  text-transform: capitalize;
  font-size: 1em;
  margin-left: 0.5rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const HeaderDescription = styled.p`
  color: #B0B5C0;
  font-weight: 500;
  margin-bottom: 0;
  font-size: 0.800em;
`;

export const ActiveCompany = styled.div`
  /* width: 250px; */
  border-radius: 8px;
  height: 48px;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }

  @media (max-width: 768px) {
    /* width: 64px; */
    margin-right: 0.5rem;

    &:hover {
      cursor: pointer;
      background-color: unset;
    }
  }
`;

export const ActiveCompanyLogo = styled.img`
  width: 46px;
  height: 46px;
  border-radius: 8px;
  padding: 4px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
              rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
              rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, 
              rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
`;

export const ActiveCompanyInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 4px;
`;

export const SwitchCompanyButton = styled(Button)`
  width: 46px;
  height: 46px;
  border-radius: 8px;
  padding: 4px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
              rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
              rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, 
              rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  svg {
    color: #464de4;
  }
`;

export const AlertMessage = styled.h6`
  font-weight: 400;
  color: #b1b3bc;
`;

export const StyledCompanyAvatar = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin: 0 5px 0 10px;
  vertical-align: -5px;
`;

export const StyledPopover = styled(Popover)`
  margin-top: 0;
  width: 250px;
  border-radius: 0.25rem;
  box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
  animation: fadeIn 0.08s ease-in-out;

  &.popover, .popover-body {
    background-color: #FFFFFF !important;
    border-radius: 8px;
  }

  &.popover .arrow::before, .popover .arrow::after {
    display: none;
  }

  &.bs-popover-bottom .arrow::after {
    display: none;
  }
`;

export const CustomFilterButton = styled(Button)`
  font-size: 0.8rem;
  vertical-align: top;
  font-weight: 500;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: 0 !important;
  margin-right: 8px;
  background-color: #F9FBFC;
  border: 1px solid #E8E7EA;
  border-radius: 8px;

  :hover, :focus {
    border: 1px solid #E8E7EA;
    text-decoration: none;
    color: var(--text-primary);
  }

  &&& {
    .dropleft .dropdown-toggle::before {
      display: none;
    }

    &::before {
      display: none !important;
    }
  }

`;
