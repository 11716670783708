import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';

import Table from 'react-bootstrap/Table';
import { Button, Tabs } from '_components/_core';

export const StyledTabs = styled(Tabs)`
  &&& {
    nav {
      max-width: 80% !important;
    }

    nav a:last-child {
      margin-left: auto !important;
    }
  }
`;

export const StyledTable = styled(Table)`
  &&& {
    

    thead th {
      height: 32px;
      line-height: 32px;
      vertical-align: middle;
      text-align: left;
      border-left: 1px solid var(--td-border-color);
      padding: 0 8px 0 9px;
      text-transform: uppercase;
      font-size: 0.9em;
    }

    thead th:first-child {
      border: none;
      border-bottom: 2px solid var(--td-border-color);
    }

    thead th {
      border-bottom: 2px solid #D4D7DC !important;
    }

    thead th.sortable {
      cursor: pointer;
    }

    thead th.sort-active {
      background: #ECEEF1;
      
      span {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    thead th.event_date-column {
      text-align: left;
    }

    thead th.description-column {
      width: auto;
      text-align: left;
    }

    thead th.recipient-column {
      text-align: left;
    }

    thead th.amount-column {
      text-align: left;
    }

    thead th.category-column {
      text-align: left;
    }

    thead th.payment_plan-column {
      text-align: left;
    }

    thead th.account_id_origin-column {
      text-align: left;
    }

    thead th.account_id_destination-column {
      text-align: left;
    }

    tr td.event-date-column {
      text-align: left;

      svg {
        float: right;
        font-size: 1.2em;
        color: #2ca01c;
        position: relative;
        right: -5px;
      }
    }

    tr td.description-column {
      text-align: left;
      vertical-align: middle;
    }

    tr td.recipient-column {
      text-align: left;
      vertical-align: middle;
    }
    
    tr td.amount-column {
      text-align: left;
      vertical-align: middle;
    }

    tr td.category-column {
      text-align: left;
      vertical-align: middle;
    }

    tr td.payment_plan-column {
      text-align: left;
      vertical-align: middle;
    }

    tr td.paid-column {
      text-align: center;
      vertical-align: middle;
    }

    tr td.account_id_origin-column {
      text-align: left;
    }

    tr td.account_id_destination-column {
      text-align: left;
    }

    tbody {
      background-color: var(--th-bg-color);
    }    

    tbody tr td {
      border-top: 1px solid var(--td-border-color);
      font-weight: 400;
      vertical-align: middle;
      line-height: 1.4em;
      height: 39px;
      padding: 6px 9px;
    }

    td.main {
      font-weight: 600;
    }

    tbody tr.duplicated td {
      background: #ffd;
    }

    tbody tr.disabled td {
      cursor: default;

      :first-child {
        pointer-events: all;
        cursor: pointer;
      }

      :last-child {
        pointer-events: all;
        cursor: pointer;
      }
    }

    tbody tr.active td {
      background: #eceef1;
    }

    tbody tr:first-child {
      &:hover {
        background-color: transparent;
      }
    }

    tbody tr:first-child td {
      cursor: default;
    }

    tbody tr.loading-row td { 
      padding-top: 24px !important;
      padding-bottom: 24px !important;
    }

    /* tbody tr.action-button-row td { 
      padding: 0 !important;
      padding-top: 6px !important;
      padding-bottom: 6px !important;
    } */

    tbody tr td span.no-description {
      font-size: 12px;
      color: #babec5;
      font-style: italic;
    }

    tbody tr.transaction-form-row td {
      background-color: #e3e5e8;
    }

    /* Styling to look like there is a card component */

    tbody, thead {
      border: var(--card-border);
      border-radius: 4px;
      background-color: var(--card-bg-color);
    }

    thead tr:first-child th:first-child {
      border-top-left-radius: 0px;
    }

    thead tr:first-child th:last-child {
      border-top-right-radius: 0px;
    }

    tbody tr:last-child td:first-child {
      border-bottom-left-radius: 4px; 
    }

    tbody tr:last-child td:last-child {
      border-bottom-right-radius: 4px; 
    }

    tbody tr td {
      font-size: 1em;
      box-sizing: content-box;
      cursor: pointer;
    }


    thead tr th.checkbox-column,
    tbody tr td.checkbox-column {
      width: 16px;
    }

    thead tr th.event_date-column,
    tbody tr td.event_date-column {
      width: 90px;
    }

    thead tr th.description-column,
    tbody tr td.description-column {
      width: auto;
    }

    thead tr th.recipient-column,
    tbody tr td.recipient-column {
      width: 120px;
    }

    thead tr th.amount-column,
    tbody tr td.amount-column {
      width: 130px;
    }
    tbody tr td.amount-column {
      font-size: 1em;
    }

    thead tr th.category-column,
    tbody tr td.category-column {
      width: 120px;
    }

    thead tr th.account_id_origin-column,
    tbody tr td.account_id_origin-column,
    thead tr th.account_id_destination-column,
    tbody tr td.account_id_destination-column {
      width: 150px;
    }

    thead tr th.payment_plan-column,
    tbody tr td.payment_plan-column {
      width: 100px;
    }   

    thead tr th.paid-column,
    tbody tr td.paid-column {
      width: 45px;
      text-align:right;
    }

    thead tr th.action-column,
    tbody tr td.action-column {
      width: 32px
    }

    @media (min-width:1366px) {
      .recipient-column,
      .recipient-column {
        width: 150px !important;
      }

      .amount-column {
        width: 120px !important;
      }

      .category-column {
        width: 150px !important;
      }
      
      .account_id_origin-column,
      .account_id_destination-column {
        width: 200px !important;
      }
    }
  }

`;

export const TransactionFormRow = styled.tr`
  &&& {
    tr td {
      padding: 0 !important;
      background-color: #e3e5e8 !important;
    }

    tr td.checkbox-column {
      width: 16px;
    }

    tr td.event-date-column {
      
    }

    tr td.description-column {
      text-align: left !important;
    }

    tr td.recipient-column {
      
    }

    tr td.amount-column {
      
    }

    tr td.category-column {
      
    }

    tr td.recurrence-column {
      
    }

    tr td.paid-column {
      
    }

    tr td.action-column {
      
    }
  }
`;

export const IrrfButton = styled(Button)`
  font-size: 13px !important; 
`;

export const StyledFormCheck = styled(Form.Check)`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-check-input {
    margin-top: 0;
    margin-left: 0;

    width: 17px !important;
    height: 17px !important;

    &:hover {
      cursor: pointer !important;
    }
  }
`;

export const Line = styled.span`
  display: flex;
`;

export const Left = styled.strong`
  width: 60px;
  text-align: right;  
`;

export const Right = styled.p`
  margin-left: 1rem;
  margin-bottom: 0;
`;

export const StyledActionButton = styled(Button)`
  width: 68%;
  background: transparent;
  border: 2px dashed;
  padding-top: 11px;
  padding-bottom: 11px;
  border-radius: 99px;
  
  ${({ type }) => type === 'INCOME' && css`
    color: #2ca01c;
    background: #eceef1;
    border-color: #2ca01c;
  
    &:hover {
      color: #fff !important;
      background-color: #2ca01c;
      border-color: #2ca01c;
    }
  `}

  ${({ type }) => type === 'TRANSFER' && css`
    color: #6255ff !important;
    background: #eceef1;
    border-color: #6255ff;
  
    &:hover {
      color: #fff !important;
      background-color: #6255ff;
      border-color: #6255ff;
    }

    ${({ isMobile }) => isMobile && css`,
      display: flex !important;
      position: fixed !important;
      margin: 0 !important;
      padding: 0 !important;
      width: 45px !important;
      height: 45px !important;
      bottom: 85px !important;
      right: 1em !important;
      color: #eceef1 !important;
      background: #2ca01c !important;
      border-color: #2ca01c !important;
      box-shadow: var(--mobile-button-box-shadow) !important;
      z-index: 0 !important;
      svg {
        width: 15px !important;
        height: 15px !important;
      }
      &:hover {
        color: #fff !important;
        background-color: #2ca01c;
        border-color: #2ca01c;
      }
  `}
  `}
  
  ${({ type, subType }) => (type === 'EXPENSE' || ['FIXED_EXPENSE', 'VARIABLE_EXPENSE', 'PEOPLE', 'TAXES'].includes(subType)) && css`
    color: #d52b1e !important;
    background: #eceef1;
    border-color: #d52b1e;

    &:hover {
      color: #fff !important;
      background-color: #d52b1e;
      border-color: #d52b1e;
    }
  `}
`;

export const TableContainer = styled.div`
  /* box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent, 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%); */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const StyledMobileActionButton = styled(Button)`
  display: flex !important;
  position: fixed !important;
  width: 68%;
  background: #eceef1;
  border: 2px dashed;
  padding-top: 11px;
  padding-bottom: 11px;
  border-radius: 99px;
  margin: 0 !important;
  padding: 0 !important;
  width: 45px !important;
  height: 45px !important;
  bottom: 150px !important;
  right: 1em !important;
  color: #eceef1 !important;
  background: #2ca01c !important;
  border-color: #2ca01c !important;
  box-shadow: var(--mobile-button-box-shadow) !important;
  z-index: 1 !important;

  svg {
    width: 15px !important;
    height: 15px !important;
  }

  &:hover {
    color: #fff !important;
    background-color: #2ca01c;
    border-color: #2ca01c;
  }
`;
