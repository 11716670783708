import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { GoPlus } from 'react-icons/go';
import { DateTime } from 'luxon';
import isEmpty from 'lodash/isEmpty';

import {
  Card,
  CardHeader,
  CardBody,
  Table,
} from '_components/_core';

import alertActions from '_store/_actions/alert.actions';
import {
  DEFAULT_COLUMNS,
  DEFAULT_COLUMNS_EDITING,
  TABLE_COLUMNS,
  TABLE_COLUMNS_EDITING,
} from './utilities';
import { AddTaxRegimeModal } from './components';
import { StyledButton } from './styles';

function TaxRegimeTab({ errors }) {
  const formik = useFormikContext();
  const [isVisible, setIsVisible] = useState(false);

  const data = useMemo(() => {
    if (!formik.values.tax_regimes) {
      return [];
    }

    return formik.values.tax_regimes;
  }, [formik.values]);

  const tableKey = useMemo(() => {
    if (!formik.values.id) {
      return 'temp_id';
    }

    return 'id';
  }, [formik.values]);

  const tableDefaultColumns = useMemo(() => {
    if (!formik.values.id) {
      return DEFAULT_COLUMNS;
    }

    return DEFAULT_COLUMNS_EDITING;
  }, [formik.values]);

  const tableColumns = useMemo(() => {
    if (!formik.values.id) {
      return TABLE_COLUMNS;
    }

    return TABLE_COLUMNS_EDITING;
  }, [formik.values]);

  const handleAdd = useCallback((tax_regime) => {
    const { year } = DateTime.fromISO(tax_regime.start_date);

    if (!isEmpty(formik.values.tax_regimes)) {
      const existing_tax_regime = formik.values.tax_regimes.find((tax) => {
        const taxYear = DateTime.fromISO(tax.start_date).year;

        return taxYear === year;
      });

      if (existing_tax_regime) {
        alertActions.error('Só é permitido utilizar um Regime de Tributação por ano. Tente adicionar em uma vigência diferente.');
        return;
      }
    }

    const currentValue = formik.values.tax_regimes || [];

    currentValue.push(tax_regime);

    formik.setFieldValue('tax_regimes', currentValue);
  }, [formik]);

  const handleDelete = useCallback((temp_id) => {
    const keyName = formik.values.id ? 'id' : 'temp_id';

    const updatedTaxRegimes = data.filter((item) => item[keyName] !== temp_id);

    formik.setFieldValue('tax_regimes', updatedTaxRegimes);
  }, [formik, data]);

  return (
    <Card noBorders>
      <CardHeader
        title="Regime de Tributação"
        description="Adicione novos registros de Regime de Tributação"
      />
      <CardBody noPaddingTop>
        <Row>
          <Col sm={12}>
            <StyledButton
              variant="secondary"
              onClick={() => (setIsVisible(true))}
              icon={<GoPlus />}
              noMargin
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {errors.tax_regimes && <small className="text-danger">{errors.tax_regimes}</small>}
            <AddTaxRegimeModal
              isVisible={isVisible}
              onToggleModal={() => setIsVisible(!isVisible)}
              onAddTaxRegime={handleAdd}
            />
            <Table
              key={tableKey}
              data={data}
              defaultColumns={tableDefaultColumns}
              columns={tableColumns}
              onDelete={handleDelete}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

TaxRegimeTab.defaultProps = {
  errors: {},
};

TaxRegimeTab.propTypes = {
  errors: PropTypes.object,
};

export default TaxRegimeTab;
