import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FORMATTERS from 'helpers/formatters';

import { StyledFormCheck } from '../../../../styles';
import { StyledRow, StyledDuplicatedRow } from './styles';
import { TABLE_COLUMNS } from './utilities';

function Row({
  transaction,
  selectedTransactionId,
  accounts,
  selectedItems,
  onItemSelected,
  onEditTransaction,
  transactionFormRef,
}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleEditTransaction = useCallback((column) => {
    if (!column.isEditable) {
      return;
    }

    const fieldName = column.key;

    if (selectedTransactionId) {
      if (transactionFormRef.current) {
        transactionFormRef.current.handleSubmit();
      }
    }

    onEditTransaction(transaction.id, fieldName);
  }, [transaction.id, onEditTransaction, transactionFormRef, selectedTransactionId]);

  const duplicatedMessage = useMemo(() => {
    const { related_transaction, possible_duplicate } = transaction || {};

    if (!possible_duplicate) {
      return null;
    }

    const { description } = related_transaction;
    const amount = FORMATTERS.NUMBER(related_transaction.amount);

    return `${description} | ${amount} (item similar encontrado)`;
  }, [transaction]);

  return (
    <>
      <StyledRow
        key={transaction.id}
        className={classNames({ 'table-active': selectedItems.includes(transaction.id) })}
        isHovered={isHovered}
        hasDuplicate={transaction.possible_duplicate}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <td className="checkbox-column">
          <StyledFormCheck
            type="checkbox"
            checked={selectedItems.includes(transaction.id)}
            onClick={(e) => {
              e.stopPropagation();
              onItemSelected(transaction.id);
            }}
          />
        </td>
        {TABLE_COLUMNS.map((column) => (
          <>
            <td
              key={column.key}
              className={classNames(column.className, `${column.key}-column`)}
              onClick={() => handleEditTransaction(column)}
            >
              {column.formatter
                ? column.formatter(transaction[column.key], transaction, accounts)
                : transaction[column.key]}
            </td>
          </>
        ))}
      </StyledRow>
      {transaction.possible_duplicate && (
        <StyledDuplicatedRow
          key={`${transaction.id}-duplicated`}
          isHovered={isHovered}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          isSelected={selectedItems.includes(transaction.id)}
          onClick={() => handleEditTransaction(TABLE_COLUMNS.find((column) => column.key === 'description'))}
        >
          <td colSpan={2}>
            &nbsp;
          </td>
          <td className=" text-center">
            <span className="text-muted" style={{ fontWeight: '500', color: '#455560' }}>
              {FORMATTERS.DATE_DDMMYYYY(transaction.related_transaction.event_date)}
            </span>
          </td>
          <td colSpan={5}>
            <span className="text-muted" style={{ fontWeight: '500', color: '#455560' }}>
              {duplicatedMessage}
            </span>
          </td>
        </StyledDuplicatedRow>
      )}
    </>
  );
}

Row.defaultProps = {
  transaction: {},
  selectedItems: [],
  accounts: [],
  selectedTransactionId: null,
};

Row.propTypes = {
  accounts: PropTypes.array,
  selectedItems: PropTypes.array,
  transaction: PropTypes.object,
  selectedTransactionId: PropTypes.string,
  transactionFormRef: PropTypes.object.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  onEditTransaction: PropTypes.func.isRequired,
};

export default Row;
