import constants from '../../_constants/automations.constants';
import service from '../../../services/automations.service';

const fetchAutomationSettings = () => {
  return async (dispatch) => {
    dispatch(request());

    service.fetchAutomationSettings().then(
      (response) => {
        dispatch(success(response.data));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.FETCH_AUTOMATION_SETTINGS_REQUEST,
      payload: { },
    };
  }
  function success(data) {
    return {
      type: constants.FETCH_AUTOMATION_SETTINGS_SUCCESS,
      payload: {
        data,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_AUTOMATION_SETTINGS_FAILURE,
      payload: error,
    };
  }
};

export default fetchAutomationSettings;
