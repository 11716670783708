import styled, { css } from 'styled-components';

export const StyledContainer = styled.tfoot`
  margin: 24px auto;
  margin: 1.5rem auto;
  color: #8d9096;
  animation-duration: .3s;

  tr {
    td {
      padding: 7px 10px;
    }

    td:nth-child(2) {
      font-weight: 600;
    }
  }

  tr:first-child {
    color: #393a3d;

    td {
      padding-top: 20px;
    }

    td:nth-child(2) {
      font-size: 1.2em;
    }
  }

  tr:last-child {
    td {
      padding-bottom: 16px;
    }
  }

  ${({
    isFloating,
    isMobile,
    isTablet,
  }) => isFloating && !isMobile && !isTablet && css`
    position: fixed;
    bottom: -25px;
    z-index: 0;
    right: 360px;
    border: 1px solid #d4d7dc;
    width: 280px;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    background-color: #F4F5F8;

    tr:last-child {
      td:first-child {
        padding-left: 32px;
      }
    }

    @media (min-width:1366px) {
      right: 410px;
    }
  `}  
`;

export const Line = styled.span`
  display: flex;
`;

export const Left = styled.strong`
  width: 250px;
  text-align: right;  
`;

export const Right = styled.p`
  margin-left: 1rem;
  margin-bottom: 0;
`;

export const StyledHint = styled.p`
  font-weight: 500;
  position: absolute;
  margin-top: 12px;
  margin-left: 15px;
`;
