import { push } from 'connected-react-router';

import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';
import getActiveCompany from './getActiveCompany';

const updateCompany = (id, company) => {
  return (dispatch) => {
    dispatch(request(company));

    delete company.id;
    delete company.manager_id;
    delete company.active;
    delete company.active_period_start;
    delete company.active_period_end;
    delete company.created_at;
    delete company.updated_at;
    delete company.legal_nature_id;
    delete company.certificate_url;
    delete company.certificate_password;

    service.update(id, company).then(
      (response) => {
        const updatedCompany = response.data;

        dispatch(success(updatedCompany));
        dispatch(alertActionCreators.success('Empresa atualizada com sucesso!'));
        dispatch(getActiveCompany());
        dispatch(push('/empresas'));
      },
    ).catch(({ response }) => {
      if (response.status === 422) {
        const { message } = response.data;

        dispatch(failure(message));
        dispatch(alertActionCreators.error(message));
      }
    });
  };

  function request(company) {
    return {
      type: constants.COMPANY_UPDATE_REQUEST,
      payload: { company },
    };
  }
  function success(company) {
    return {
      type: constants.COMPANY_UPDATE_SUCCESS,
      payload: { company },
    };
  }
  function failure(message) {
    return {
      type: constants.COMPANY_UPDATE_FAILURE,
      payload: { message },
    };
  }
};

export default updateCompany;
