import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/automations.constants';
import service from '../../../services/automations.service';

const searchPDFInvoices = (start_date, end_date, date_type) => async (dispatch) => {
  dispatch(request());

  service.searchPDFInvoices(start_date, end_date, date_type).then(
    (response) => {
      dispatch(success(response));

      const handleCancel = () => null;

      confirmDialog.success(
        'Automação Iniciada',
        'Sua solicitação foi adicionada em nossa fila de tarefas e assim que for processada te avisaremos com uma notificação.',
        handleCancel,
        () => {},
      );
    },
  ).catch((error) => {
    dispatch(failure(error));
  });
};

function request() {
  return {
    type: constants.SEARCH_PDF_INVOICES_REQUEST,
    payload: { },
  };
}
function success(response) {
  return {
    type: constants.SEARCH_PDF_INVOICES_SUCCESS,
    payload: response,
  };
}
function failure(error) {
  return {
    type: constants.SEARCH_PDF_INVOICES_FAILURE,
    payload: error,
  };
}

export default searchPDFInvoices;
