import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const downloadFinancialData = (params, callback) => {
  return async (dispatch) => {
    dispatch(request());

    service.downloadFinancialData(params).then((response) => {
      dispatch(success());

      if (callback) {
        callback(response.data);
      }
    }).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.DOWNLOAD_FINANCIAL_DATA_REQUEST,
      payload: { },
    };
  }
  function success(data) {
    return {
      type: constants.DOWNLOAD_FINANCIAL_DATA_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.DOWNLOAD_FINANCIAL_DATA_FAILURE,
      payload: error,
    };
  }
};

export default downloadFinancialData;
