import { push } from 'connected-react-router';

import constants from '../../_constants/auth.constants';
import service from '../../../services/auth.service';
import alertActionCreators from '../alert.actions';

const completeSignUp = (user) => {
  return (dispatch) => {
    dispatch(request(user));

    service.completeSignUp(user).then(
      (response) => {
        dispatch(success(response));
        dispatch(push('/entrar'));
        dispatch(alertActionCreators.success('Cadastro efetuado com sucesso'));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(user) {
    return { type: constants.COMPLETE_SIGN_UP_REQUEST, payload: { user } };
  }
  function success(response) {
    return { type: constants.COMPLETE_SIGN_UP_SUCCESS, payload: { response } };
  }
  function failure(errors) {
    return { type: constants.COMPLETE_SIGN_UP_FAILURE, payload: { errors } };
  }
};

export default completeSignUp;
