import styled, { css } from 'styled-components';

export const HoverBadge = styled.div`
  float: right;
  visibility: hidden;
`;

export const Container = styled.tr`
  &:hover ${HoverBadge} {
    visibility: visible;
  }

  ${({ isSintetica }) => isSintetica && css`
    td {
      font-weight: bold;
    }
  `}
`;

export const ActionButtons = styled.div`
  position: absolute;
  display: block;
  color: red;
  left: -30px;
`;

export const Icon = styled.i`
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')} !important;
`;

export const ActionButton = styled.div`
  cursor: pointer;
  width: 5px;
  height: 5px;
  left: 3px;
  top: 5px;
  border-radius: 50%;
  background-color: #d8d8d8;
  display: ${(props) => (props.visible ? 'flex' : 'none')} !important;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: border-radius 0.15s ease-out, background-color 0.15s ease-out,
  transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  transform: rotate(45deg);
  display: flex;

  &:hover {
    width: 26px;
    height: 26px;
    left: -6px;
    top: -5px;
    background-color: #0081c2;
    border-radius: 13px 4px 13px 13px;
  }

  &:hover ${Icon} {
    visibility: visible;
  }

  &:hover ${Container} {
    border-bottom: 1px solid #0081c2;
  }

  &::before {
    content: "";
    position: absolute;
    background-color: #fff;
    opacity: 1;
    z-index: 1;
    top: 7px;
  }

  &::after {
  }
`;
