import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { IoArrowBackOutline, IoArrowForwardOutline } from 'react-icons/io5';

import { useMediaQuery } from 'helpers';

import {
  Card,
  CardHeader,
  CardBody,
} from '_components/_core';

import { DEFAULT_COLUMNS, TABLE_COLUMNS, formatData } from './utilities';
import { StyledTable, StyledButtonGroup, StyledButton } from './styles';

function MonthTaxes({ selectedDate, taxes, taxRegime }) {
  const [selectedMonth, setSelectedMonth] = useState(selectedDate);

  const { isMobile } = useMediaQuery();

  const formattedData = useMemo(() => {
    if (isEmpty(taxes)) {
      return [];
    }

    return formatData(taxes, selectedMonth, taxRegime);
  }, [taxes, selectedMonth, taxRegime]);

  const handleMonthIncreased = useCallback(() => {
    setSelectedMonth((previousDate) => previousDate.plus({ month: 1 }));
  }, []);

  const handleMonthDecreased = useCallback(() => {
    setSelectedMonth((previousDate) => previousDate.minus({ month: 1 }));
  }, []);

  const isBackButtonDisabled = useMemo(
    () => selectedMonth.month === 1,
    [selectedMonth],
  );

  const isNextButtonDisabled = useMemo(
    () => selectedMonth.month === 12,
    [selectedMonth],
  );

  const description = useMemo(() => {
    if (taxRegime === 'SIMPLES_NACIONAL') {
      return 'Detalhamento do Simples Nacional no mês selecionado';
    }

    if (taxRegime === 'LUCRO_PRESUMIDO') {
      return 'Tributos do Lucro Presumido no mês selecionado';
    }

    return null;
  }, [taxRegime]);

  const renderControls = useCallback(() => (
    <StyledButtonGroup>
      <StyledButton variant="secondary" onClick={handleMonthDecreased} disabled={isBackButtonDisabled}>
        <IoArrowBackOutline />
      </StyledButton>
      <StyledButton variant="secondary" special>
        <b>{selectedMonth.toFormat('MM/yyyy')}</b>
      </StyledButton>
      <StyledButton variant="secondary" onClick={handleMonthIncreased} disabled={isNextButtonDisabled}>
        <IoArrowForwardOutline />
      </StyledButton>
    </StyledButtonGroup>
  ), [
    handleMonthDecreased,
    handleMonthIncreased,
    isBackButtonDisabled,
    isNextButtonDisabled,
    selectedMonth,
  ]);

  useEffect(() => {
    setSelectedMonth(selectedDate);
  }, [selectedDate]);

  return (
    <Card>
      <CardHeader
        title="Visão Mensal"
        description={description}
        sideContent={!isMobile ? renderControls() : null}
        className="mb-0"
      />
      <CardBody noPaddingTop noPaddingBottom={isMobile}>
        <div className="table-responsive">
          <StyledTable
            id="monthly-table"
            className="table-hover table-striped"
            keyName="competence"
            data={formattedData}
            columns={TABLE_COLUMNS}
            defaultColumns={DEFAULT_COLUMNS}
            showFooter
            name={`${taxRegime}-monthly-taxes-table`}
          />
        </div>
      </CardBody>
      {isMobile && (
        <div className="d-flex justify-content-center mb-2">
          {renderControls()}
        </div>
      )}
    </Card>
  );
}

MonthTaxes.defaultProps = {
  taxes: [],
  selectedDate: {},
};

MonthTaxes.propTypes = {
  taxes: PropTypes.array,
  selectedDate: PropTypes.object,
  taxRegime: PropTypes.oneOf(['LUCRO_PRESUMIDO', 'SIMPLES_NACIONAL', 'LUCRO_REAL', 'MEI']),
};

export default MonthTaxes;
