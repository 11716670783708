import { connect } from 'react-redux';

import employeeActionCreators from '_store/_actions/employees';
import accountActionCreators from '_store/_actions/accounts';
import fiscalActionCreators from '_store/_actions/fiscal';
import ibgeActionCreators from '_store/_actions/ibge';

import CreatableSelectModal from './CreatableSelectModal';

const mapStateToProps = (state) => ({
  isLoadingAccount: state.accounts.isLoading,
  isLoadingCities: state.ibge.isLoadingCities,
  isLoadingStates: state.ibge.isLoadingStates,
  isLoadingCep: state.ibge.isLoadingCep,
  isLoading: state.accountants.isLoading,
  isLoadingPerson: state.fiscal.isLoading,
  cities: state.ibge.cities,
  states: state.ibge.states,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onAddEmployeeRole: employeeActionCreators.addEmployeeRole,
  onUpdateEmployeeRole: employeeActionCreators.updateEmployeeRole,
  onAddEmployeeJourney: employeeActionCreators.addEmployeeJourney,
  onUpdateEmployeeJourney: employeeActionCreators.updateEmployeeJourney,
  onAddAccount: accountActionCreators.addAccount,
  onFetchAccounts: accountActionCreators.getAllAccounts,
  onAddPerson: fiscalActionCreators.addPerson,
  onFetchPersons: fiscalActionCreators.getAllPersons,
  onFetchStates: ibgeActionCreators.fetchStates,
  onFetchCities: ibgeActionCreators.fetchCities,
  onFetchCep: ibgeActionCreators.fetchCep,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatableSelectModal);
