import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import { Search, Pagination, ItemsPerPage } from '_components/_core/Table/components';

import { useMediaQuery } from 'helpers';
import {
  TableContainer,
  Table,
  CompanyInfo,
  CompanyName,
  DocumentNumber,
  StyledModal,
  ModalFooter,
  SearchContainer,
} from './styles';

export default function CompaniesModal({
  companies,
  total,
  isVisible,
  onModalToggle,
  onFetchCompanies,
  onActivateCompany,
}) {
  const [companyFilter, setCompanyFilter] = useState('');
  const [pagination, setPagination] = useState({ currentPage: 1, itemsPerPage: 10 });

  const { isMobile } = useMediaQuery();

  const handleActivateCompany = useCallback((company) => {
    onActivateCompany(company);
    onModalToggle();
  }, [onActivateCompany, onModalToggle]);

  const handlePageChange = useCallback((page) => {
    setPagination((previous) => ({ ...previous, currentPage: page }));
  }, []);

  const handlePageSizeChange = useCallback((size) => {
    setPagination((previous) => ({ ...previous, currentPage: 1, itemsPerPage: size }));
  }, []);

  const renderModalFooter = useMemo(() => (
    <ModalFooter>
      <ItemsPerPage
        itemsPerPage={pagination.itemsPerPage}
        onChange={handlePageSizeChange}
      />
      <Pagination
        {...pagination}
        total={total}
        onPageChange={handlePageChange}
      />
    </ModalFooter>
  ), [handlePageChange, handlePageSizeChange, pagination, total]);

  useEffect(() => {
    const filters = {};

    if (companyFilter) {
      filters.company = companyFilter;
    }

    if (isVisible) {
      onFetchCompanies({
        pagination,
        filters,
      });
    }
  }, [isVisible, pagination, companyFilter, onFetchCompanies]);

  return (
    <StyledModal
      title="Selecionar Empresa Ativa"
      isVisible={isVisible}
      toggleModal={onModalToggle}
      footer={renderModalFooter}
      noPadding
    >
      <div>
        <SearchContainer>
          <Search
            placeholder="Pesquise por CNPJ, Razão Social ou Nome Fantasia"
            value={companyFilter}
            onChange={(value) => setCompanyFilter(value)}
            isMobile={isMobile}
          />
        </SearchContainer>
        <TableContainer className="table-responsive">
          <Table className="table table-hover">
            <tbody>
              {companies.map((company) => (
                <tr key={company.id} onClick={() => handleActivateCompany(company)}>
                  <CompanyInfo>
                    <CompanyName>
                      {company.company_name}
                    </CompanyName>
                    <DocumentNumber>
                      {company.trading_name}
                      {company.document_number && ' - '}
                      {company.document_number || ''}
                    </DocumentNumber>
                  </CompanyInfo>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </div>
    </StyledModal>
  );
}

CompaniesModal.defaultProps = {
  companies: [],
  isVisible: false,
  total: 0,
};

CompaniesModal.propTypes = {
  total: PropTypes.number,
  companies: PropTypes.array,
  isVisible: PropTypes.bool,
  onModalToggle: PropTypes.func.isRequired,
  onFetchCompanies: PropTypes.func.isRequired,
  onActivateCompany: PropTypes.func.isRequired,
};
