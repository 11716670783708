import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { IoArrowForward, IoStar } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';

import FORMATTERS from 'helpers/formatters';
import Button from '_components/_core/Button';

import {
  Plans,
  Plan,
  PlanName,
  PlanPrice,
  PlanFeatures,
  TrialPeriodMessage,
  StyledAlert,
  StyledAlertHeading,
} from './styles';

const HELPER_TEXT = {
  LINE1: 'Para completar seu cadastro, selecione o plano que melhor atenda as necessidades do seu escritório.',
  LINE2: 'Não se preocupe, se não gostar do Contabills, você pode cancelar sua assinatura antes do fim do período de testes e nenhuma cobrança será feita.',
  LINE3: '(Ah, também vamos te lembrar por e-mail quando o período de testes estiver quase acabando, ok?)',
};

function SelectPlan({ plans, onGoToCheckoutSession }) {
  const location = useLocation();
  const stripe = useStripe();

  const [selectedPlan, setSelectedPlan] = useState(null);

  const renderFeatures = useCallback((metadata) => {
    const keys = Object.keys(metadata);

    return (
      <PlanFeatures>
        {keys.map((key) => (
          <li>
            <IoStar />
            {' '}
            {metadata[key]}
          </li>
        ))}
      </PlanFeatures>
    );
  }, []);

  const handleSubmit = useCallback(() => {
    const urlParams = new URLSearchParams(location.search);

    const id = urlParams.get('id');

    onGoToCheckoutSession({
      user_id: id,
      plan_id: selectedPlan,
    }, (session_id) => {
      stripe.redirectToCheckout({ sessionId: session_id });
    });
  }, [selectedPlan, stripe, location, onGoToCheckoutSession]);

  return (
    <>
      <StyledAlert variant="info">
        <StyledAlertHeading>
          Escolha um plano e teste gratuitamente por 07 dias
        </StyledAlertHeading>
        {HELPER_TEXT.LINE1}
        <br />
        {HELPER_TEXT.LINE2}
        <br />
        <br />
        {HELPER_TEXT.LINE3}
      </StyledAlert>
      <Plans>
        {plans.map((plan) => (
          <Plan
            key={plan.id}
            active={selectedPlan === plan.id}
            onClick={() => setSelectedPlan(plan.id)}
          >
            <PlanName>
              {plan.name}
            </PlanName>
            <PlanPrice>
              {FORMATTERS.NUMBER(plan.price)}
            </PlanPrice>
            <small>por mês</small>
            <hr />
            {renderFeatures(plan.metadata)}
            <TrialPeriodMessage>
              Teste gratuitamente por 07 dias
            </TrialPeriodMessage>
          </Plan>
        ))}
      </Plans>
      <Button type="button" onClick={handleSubmit} disabled={!selectedPlan} className="d-flex flex-direction-column">
        Ir para Checkout
        <IoArrowForward className="ml-2" />
      </Button>
    </>
  );
}

SelectPlan.propTypes = {
  values: PropTypes.shape({
    plan_id: PropTypes.string.isRequired,
  }),
  plans: PropTypes.array,
  onGoToCheckoutSession: PropTypes.func.isRequired,
};

export default SelectPlan;
