import constants from '../../_constants/associates.constants';
import service from '../../../services/associates.service';
import alertActionCreators from '../alert.actions';

const addAssociate = (associate) => {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(request(associate));

    service.insert(associate).then(
      (response) => {
        dispatch(success(response));
        resolve();
      },
      (error) => {
        if (error.response.status === 400) {
          const [errorMessage] = error.response.data;

          dispatch(alertActionCreators.error(errorMessage.message));
          dispatch(failure(error.response.data));
          reject();
        }
      },
    );
  });

  function request(associate) {
    return {
      type: constants.ASSOCIATES_ADD_REQUEST,
      payload: { associate },
    };
  }
  function success(associate) {
    return {
      type: constants.ASSOCIATES_ADD_SUCCESS,
      payload: { associate },
    };
  }
  function failure(errors) {
    return {
      type: constants.ASSOCIATES_ADD_FAILURE,
      payload: { errors },
    };
  }
};

export default addAssociate;
