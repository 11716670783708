import Swal from 'sweetalert2';

import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const deleteMultipleTransaction = (params, callback, { ignorePreConfirm = false } = {}) => {
  return async (dispatch) => {
    const { ids } = params;
    const itemCount = ids.length;

    const title = `Excluir <strong>${itemCount}</strong> Transações`;
    const html = `Tem certeza que deseja excluir as <strong>${itemCount}</strong> transações selecionadas? Esta operação não pode ser revertida.`;
    const inputAttributes = {
      style: 'width: 70%;',
    };
    let inputPlaceholder = `Digite "excluir ${itemCount} transações"`;
    const confirmButtonText = `Sim, excluir ${itemCount} transações`;
    const confirmButtonColor = '#d33';
    const icon = 'warning';
    const showCancelButton = true;
    const dangerMode = true;

    let preConfirm = null;
    let input = null;

    if (itemCount >= 10) {
      input = 'text';
      inputPlaceholder = `Digite "excluir ${itemCount} transações"`;

      preConfirm = (inputValue) => {
        if (inputValue !== `excluir ${itemCount} transações`) {
          Swal.showValidationMessage(`Por favor, digite "excluir ${itemCount} transações" para confirmar a exclusão.`);
        }
      };
    } else if (itemCount > 1) {
      preConfirm = null;
    } else {
      preConfirm = () => {};
    }

    if (ignorePreConfirm) {
      preConfirm = null;
      input = null;
      inputPlaceholder = null;
    }

    confirmDialog.warning({
      title,
      message: html,
      input,
      inputAttributes,
      inputPlaceholder,
      confirmButtonText,
      confirmButtonColor,
      icon,
      showCancelButton,
      dangerMode,
      preConfirm,
      onConfirm: () => {
        dispatch(request(ids));

        service.removeMultiple(params).then(() => {
          dispatch(success(ids));
          dispatch(alertActionCreators.success('Transações excluídas com sucesso!'));

          if (callback) {
            callback();
          }
        }).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request(ids) {
    return {
      type: constants.DELETE_MULTIPLE_COMPANY_TRANSACTION_REQUEST,
      payload: {
        ids,
      },
    };
  }
  function success(ids) {
    return {
      type: constants.DELETE_MULTIPLE_COMPANY_TRANSACTION_SUCCESS,
      payload: {
        ids,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_MULTIPLE_COMPANY_TRANSACTION_FAILURE,
      payload: error,
    };
  }
};

export default deleteMultipleTransaction;
