import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';
import ibgeActionCreators from '_store/_actions/ibge';

import CompanyInfoTab from './CompanyInfoTab';

const mapStateToProps = (state) => ({
  isSearching: state.company.isSearching,
  searchedCompany: state.company.searchedCompany,
});

const mapDispatchToProps = {
  onSearchCompanyByCnpj: companyActionCreators.searchCompany,
  onClearSearchedCompany: companyActionCreators.clearSearchedCompany,
  onFetchCities: ibgeActionCreators.fetchCities,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfoTab);
