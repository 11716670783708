import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { Card, CardFooter } from '_components/_core';

export const ResultList = styled.ul`
  margin-bottom: 0;

  li {
    font-weight: bold
  }
`;

export const StyledTableHeader = styled.thead`

`;

export const StyledCard = styled(Card)`
  position: fixed !important;
  left: 320px;
  right: 0px;
  max-height: calc(100vh - 185px);
    overflow: auto;
`;

export const StyledFooter = styled(CardFooter)`
  position: fixed !important;
  bottom: 0px;
  left: 320px;
  right: 0px;
`;

export const FilterButton = styled(Button)`
  width: 46px;
  height: 46px;
  border-radius: 8px;
  padding: 4px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
              rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
              rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, 
              rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  svg {
    color: #464de4;
  }
`;

export const AlertHeading = styled(Alert.Heading)`
  color: #3a9d83`;
