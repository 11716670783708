import validationSchema from './validationSchema';

const companyFormFields = {
  document_number: '',
  company_name: '',
  trading_name: '',
  date_founded: '',
  status_irs: '',
  tax_regime: '',
  phone_number: '',
  email: '',
  address_zip_code: '',
  address_street: '',
  address_number: '',
  address_district: '',
  address_complement: '',
  address_city: '',
  address_city_ibge: '',
  address_state: '',
  address_state_ibge: '',
  municipal_registration: '',
  notes: '',
  cnaes: [],
};

export {
  companyFormFields,
  validationSchema,
};
