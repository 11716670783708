import { bankStatementsConstants, entryConstants } from '../_constants';

const INITIAL_STATE = {
  entries: [],
  isLoading: false,
  total: 0,
};

export function bankStatements(state = INITIAL_STATE, action) {
  switch (action.type) {
    case bankStatementsConstants.BANK_STATEMENTS_PARSE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        entries: [],
      };
    }

    case bankStatementsConstants.BANK_STATEMENTS_PARSE_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        isLoading: false,
        total,
        entries: data,
        transactions: action.payload,
      };
    }

    case bankStatementsConstants.BANK_STATEMENTS_PARSE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        total: 0,
        entries: [],
      };
    }
    case bankStatementsConstants.FETCH_BANK_STATEMENT_ENTRIES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case bankStatementsConstants.FETCH_BANK_STATEMENT_ENTRIES_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        isLoading: false,
        total,
        entries: data,
      };
    }

    case bankStatementsConstants.FETCH_BANK_STATEMENT_ENTRIES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        total: 0,
        entries: [],
      };
    }

    case bankStatementsConstants.BANK_STATEMENTS_UPDATE_MANY_ENTRIES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case bankStatementsConstants.BANK_STATEMENTS_UPDATE_MANY_ENTRIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case bankStatementsConstants.BANK_STATEMENTS_UPDATE_MANY_ENTRIES_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case entryConstants.ENTRY_ADD_MANY_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case entryConstants.ENTRY_ADD_MANY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case entryConstants.ENTRY_ADD_MANY_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case bankStatementsConstants.BANK_STATEMENTS_IGNORE_ENTRIES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case bankStatementsConstants.BANK_STATEMENTS_IGNORE_ENTRIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case bankStatementsConstants.BANK_STATEMENTS_IGNORE_ENTRIES_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
}
