import styled from 'styled-components';
import Alert from 'react-bootstrap/Alert';

export const StyledAlert = styled(Alert)`
  padding: 24px;
  font-weight: 400;
`;

export const StyledAlertHeading = styled(Alert.Heading)`
  color: inherit !important;
`;
