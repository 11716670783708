import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import { ListGroup } from 'react-bootstrap';

export const MonthName = styled.p`
  display: block;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-text-primary);
`;

export const FlagsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledFormCheck = styled(Form.Check)`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-check-input {
    margin-top: 0;
    margin-left: 0;

    width: 15px !important;
    height: 15px !important;

    &:hover {
      cursor: pointer !important;
    }
  }
`;

export const StyledListGroupItem = styled(ListGroup.Item)`  
  :hover {
    background-color: var(--tr-color-hover)!important;
    cursor: pointer;
  }
`;

export const StyledHeader = styled.h5`

`;
