import { Form } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledFormCheckbox = styled(Form.Check)`
  &&& {
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;

    label {
      font-weight: 400;
      padding-left: 0;
      cursor: pointer;
    }
  }
`;
