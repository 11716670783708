import styled from 'styled-components';

import { Button } from '_components/_core';

export const StyledButton = styled(Button)`
  background-color: #11924F;
  border: 0;
  min-width: 200px;
  
  :hover {
    background-color: #0A502B;
  }
`;
