import constants from '../../_constants/payroll.constants';
import service from '../../../services/payroll.service';
import alertActionCreators from '../alert.actions';

const saveManualPayroll = (params) => {
  return (dispatch) => {
    dispatch(request());

    service.saveManualPayroll(params).then(
      (response) => {
        const { data } = response;

        dispatch(success(data));
        dispatch(alertActionCreators.success('Folha de Pagamento atualizada com sucesso!'));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(params) {
    return {
      type: constants.MANUAL_PAYROLL_SAVE_REQUEST,
      payload: { params },
    };
  }
  function success(data) {
    return {
      type: constants.MANUAL_PAYROLL_SAVE_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.MANUAL_PAYROLL_SAVE_FAILURE,
      payload: error,
    };
  }
};

export default saveManualPayroll;
