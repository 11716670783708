import styled, { css } from 'styled-components';

export const StyledDiv = styled.div`
  display: flex;
`;

export const StyledButton = styled.div`
  flex: 1;
  color: #9E9E9E;
  text-align: center;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 12px;
  margin: 0 5px;
  cursor: pointer;

  ${({ type, selectedType }) => type === 'EXPENSE' && selectedType === 'EXPENSE' && css`
    color: #d52b1e !important;
    border-color: #d52b1e !important;
  `}

  ${({ type, selectedType }) => type === 'INCOME' && selectedType === 'INCOME' && css`
    color: #11924F !important;
    border-color: #11924F !important;
  `}

  ${({ type, selectedType }) => type === 'TRANSFER' && selectedType === 'TRANSFER' && css`
    color:  #6255ff !important;
    border-color:  #6255ff !important;
  `}
`;
