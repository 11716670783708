import { useMemo } from 'react';

const useSelectOptions = ({
  recipients = [],
  tags = [],
  categories = [],
  costCenters = [],
  accounts = [],
}) => {
  const recipientsOptions = useMemo(() => {
    if (!recipients) return [];

    const formatted = recipients.map((recipient) => ({
      label: recipient.name,
      value: recipient.id,
    }));

    return formatted;
  }, [recipients]);

  const tagsOptions = useMemo(() => {
    if (!tags) return [];

    const formatted = tags.map((tag) => ({
      label: tag.description,
      value: tag.id,
    }));

    return formatted;
  }, [tags]);

  const categoriesOptions = useMemo(() => {
    if (!categories) return [];

    const formatted = categories.map((category) => ({
      label: category.description,
      value: category.id,
    }));

    return formatted;
  }, [categories]);

  const costCentersOptions = useMemo(() => {
    if (!costCenters) return [];

    const formatted = costCenters.map((costCenter) => ({
      label: costCenter.description,
      value: costCenter.id,
    }));

    return formatted;
  }, [costCenters]);

  const accountsOptions = useMemo(() => {
    if (!accounts) return [];

    const formatted = accounts.map((account) => ({
      label: account.description,
      value: account.id,
    }));

    return formatted;
  }, [accounts]);

  return {
    recipientsOptions,
    tagsOptions,
    categoriesOptions,
    costCentersOptions,
    accountsOptions,
  };
};

export default useSelectOptions;
