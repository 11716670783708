import { SPECIAL_COLUMNS } from '_components/_core/Table';
import FORMATTERS from 'helpers/formatters';

import EmployeeSalarySchema from './EmployeeSalarySchema';

export const DEFAULT_COLUMNS = [
  'salary',
  'start_date',
  'id',
];

export const TABLE_COLUMNS = [
  {
    label: 'Ações',
    keyName: 'id',
    align: 'center',
    width: 10,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'Data Inicial',
    keyName: 'start_date',
    align: 'left',
    width: 10,
    formatter: FORMATTERS.DATE_DDMMYYYY,
    order: 2,
  },
  {
    label: 'Salário',
    keyName: 'salary',
    align: 'left',
    width: 80,
    formatter: FORMATTERS.NUMBER,
    order: 3,
  },

];

export default TABLE_COLUMNS;

export { EmployeeSalarySchema };
