import constants from '../../_constants/receipts.constants';
import service from '../../../services/receipt.service';

const fetchReceipt = (id, callback) => {
  return (dispatch) => {
    dispatch(request());

    service.getById(id).then(
      (response) => {
        const receipt = response.data;

        dispatch(success(receipt));

        if (callback) {
          callback(receipt);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.RECEIPT_GET_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.RECEIPT_GET_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.RECEIPT_GET_FAILURE,
      payload: error,
    };
  }
};

export default fetchReceipt;
