import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { FormDateField, FormAccountSelectField, Menu } from '_components/_core';

import { MenuCategory, MenuRow, MenuButton } from '_components/_core/Menu/Menu';

function Filters({
  isLoading,
  onSubmit,
}) {
  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  }, [onSubmit]);

  return (
    <Menu className="mb-3 mb-md-0" onKeyPress={handleKeyPress}>
      <MenuCategory noPaddingBottom>
        Filtros
      </MenuCategory>
      <MenuRow>
        <Col>
          <Form.Group>
            <Form.Label>Data Inicial</Form.Label>
            <FormDateField name="start_date" placeholder="Data Inicial" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Data Final</Form.Label>
            <FormDateField name="end_date" placeholder="Data Final" />
          </Form.Group>
        </Col>
      </MenuRow>
      <MenuRow noPaddingTop>
        <Col>
          <Form.Group>
            <Form.Label>Contas</Form.Label>
            <FormAccountSelectField name="accounts" placeholder="Selecionar contas contábeis" multiple />
          </Form.Group>
        </Col>
      </MenuRow>
      <MenuButton isLoading={isLoading} onClick={onSubmit}>
        Gerar Relatório
      </MenuButton>
    </Menu>
  );
}

Filters.defaultProps = {
  isLoading: false,
};

Filters.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default Filters;
