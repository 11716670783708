import { useEffect } from 'react';

const Page = (props) => {
  const productName = process.env.REACT_APP_CONTABILLS_APP === 'contabills' ? 'Contabills' : 'Zenply';

  useEffect(() => {
    document.title = `${props.title || ''} - ${productName}`;
  }, [props.title, productName]);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");

    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    const product = process.env.REACT_APP_CONTABILLS_APP;

    if (product === 'contabills-empresas') {
      link.href = '/favicon-zenply.ico';
    } else if (product === 'contabills') {
      link.href = '/favicon.ico';
    }
  }, []);

  return props.children;
};

export default Page;
