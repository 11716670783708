import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const fetchInstalmentsTransactions = (frequency_main_id, callback) => {
  return async (dispatch) => {
    dispatch(request(frequency_main_id));

    service.fetchInstalmentsTransactions(frequency_main_id).then(
      (response) => {
        dispatch(success(response));

        if (callback) {
          callback(response.data);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(frequency_main_id) {
    return {
      type: constants.FETCH_INSTALMENT_TRANSACTIONS_REQUEST,
      payload: frequency_main_id,
    };
  }
  function success(response) {
    return {
      type: constants.FETCH_INSTALMENT_TRANSACTIONS_SUCCESS,
      payload: response.data,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_INSTALMENT_TRANSACTIONS_FAILURE,
      payload: error,
    };
  }
};

export default fetchInstalmentsTransactions;
