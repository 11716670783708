import axios from 'axios';

import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';
import getAllCompanies from './getAllCompanies';

const uploadAndSaveCompanyCertificate = (certificate) => {
  return async (dispatch) => {
    dispatch(request());

    const {
      company_id,
      certificate_file,
      signed_url,
      final_url,
      certificate_password,
      certificate_password_confirmation,
    } = certificate;

    await axios.put(signed_url, certificate_file, {
      headers: {
        'Content-Type': certificate_file.type,
      },
    });

    service.uploadCertificate(company_id, {
      certificate_url: final_url,
      certificate_password,
      certificate_password_confirmation,
    }).then((response) => {
      dispatch(success(response));
      dispatch(getAllCompanies());
      dispatch(alertActionCreators.success('Certificado Digital armazenado com sucesso!'));
    }).catch((error) => {
      dispatch(failure(error));
      dispatch(alertActionCreators.error('Erro ao armazenar Certificado Digital.'));
    });
  };

  function request() {
    return {
      type: constants.COMPANY_CERTIFICATE_SAVE_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.COMPANY_CERTIFICATE_SAVE_SUCCESS,
      payload: { response },
    };
  }
  function failure(error) {
    return {
      type: constants.COMPANY_CERTIFICATE_SAVE_FAILURE,
      payload: { error },
    };
  }
};

export default uploadAndSaveCompanyCertificate;
