import React, {
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useFormikContext } from 'formik';

import {
  Card,
  CardHeader,
  CardBody,
  Select,
} from '_components/_core';

import { SearchButton } from './styles';

function AddressTab({
  states,
  cities,
  isLoadingCep,
  onFetchStates,
  onFetchCities,
  onFetchCep,
}) {
  const formik = useFormikContext();

  const handleCepSearch = useCallback(async () => {
    onFetchCep(formik.values.address_zip_code, (address) => {
      const {
        bairro,
        complemento,
        localidade,
        logradouro,
        uf,
        ibge,
        ddd,
      } = address;

      formik.setFieldValue('address_street', logradouro);
      formik.setFieldValue('address_number', null);
      formik.setFieldValue('address_complement', complemento);
      formik.setFieldValue('address_district', bairro);
      formik.setFieldValue('address_state', uf);
      formik.setFieldValue('address_state_ibge', Number(ddd));
      onFetchCities(Number(ddd), () => {
        formik.setFieldValue('address_city_ibge', Number(ibge));
        formik.setFieldValue('address_city', localidade);
      });
    });
  }, [formik, onFetchCep, onFetchCities]);

  useEffect(() => {
    onFetchStates();
  }, [onFetchStates]);

  const handleStateIbgeChange = useCallback((option) => {
    formik.setFieldValue('address_state_ibge', option.value);
    formik.setFieldValue('address_state', option.sigla);

    onFetchCities(option.value, () => {
      formik.setFieldValue('address_city_ibge', null);
    });
  }, [formik, onFetchCities]);

  const handleCityIbgeChange = useCallback((option) => {
    formik.setFieldValue('address_city_ibge', Number(option.value));
    formik.setFieldValue('address_city', option.label);
  }, [formik]);

  const getSelectedState = useCallback(
    (values) => {
      if (!values.address_state_ibge) {
        return null;
      }

      return states.find((state) => state.value === Number(values.address_state_ibge));
    }, [states],
  );

  const getSelectedCity = useCallback(
    (values) => {
      if (!values.address_city_ibge) {
        return null;
      }

      return cities.find((city) => Number(city.value) === Number(values.address_city_ibge));
    }, [cities],
  );

  return (
    <Card noBorders>
      <CardHeader title="Endereço" description="Preencha o endereço da sede da empresa." />
      <CardBody>
        <Form>
          <Form.Row>
            <Col xs lg="3">
              <Form.Group>
                <Form.Label>CEP</Form.Label>
                <Form.Control
                  type="tel"
                  name="address_zip_code"
                  placeholder="CEP da empresa"
                  onChange={formik.handleChange}
                  value={formik.values.address_zip_code}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <SearchButton type="button" variant="secondary" isLoading={isLoadingCep} onClick={handleCepSearch}>
                  Buscar CEP
                </SearchButton>
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Logradouro</Form.Label>
              <Form.Control name="address_street" placeholder="Logradouro" onChange={formik.handleChange} value={formik.values.address_street} />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Número</Form.Label>
              <Form.Control name="address_number" placeholder="Número" onChange={formik.handleChange} value={formik.values.address_number} />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Complemento</Form.Label>
              <Form.Control name="address_complement" placeholder="Complemento" onChange={formik.handleChange} value={formik.values.address_complement} />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Bairro</Form.Label>
              <Form.Control name="address_district" placeholder="Bairro" onChange={formik.handleChange} value={formik.values.address_district} />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} lg="3">
              <Form.Label>Unidade Federativa</Form.Label>
              <Select
                name="address_state_ibge"
                onChange={handleStateIbgeChange}
                value={getSelectedState(formik.values)}
                options={states}
              />
            </Form.Group>

            <Form.Group as={Col} lg="3">
              <Form.Label>Cidade</Form.Label>
              <Select
                name="address_city_ibge"
                onChange={handleCityIbgeChange}
                value={getSelectedCity(formik.values)}
                options={cities}
              />
            </Form.Group>

          </Form.Row>
        </Form>
      </CardBody>
    </Card>
  );
}

AddressTab.propTypes = {
  states: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  isLoadingCep: PropTypes.bool.isRequired,
  onFetchStates: PropTypes.func.isRequired,
  onFetchCities: PropTypes.func.isRequired,
  onFetchCep: PropTypes.func.isRequired,
};

export default AddressTab;
