import FORMATTERS from 'helpers/formatters';
import constants from '../_constants/accounts.constants';

const INITIAL_STATE = {
  accounts: [],
  typeaheadAccounts: [],
  selectedAccount: null,
  ui: {
    isLoading: false,
    hasErrors: false,
    errors: [],
    accountFormLoading: false,
    accountFormVisible: false,
    accountFormDeleteMode: false,
    accountFormErrors: [],
  },
};

export function accounts(state = INITIAL_STATE, action) {
  switch (action.type) {
    case constants.ACCOUNT_GET_ALL_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoading: true,
        },
      };
    case constants.ACCOUNT_GET_ALL_SUCCESS:
      return {
        ...state,
        accounts: action.payload.accounts,
        typeaheadAccounts: action.payload.accounts.map((account) => ({
          id: account.id,
          classifier: account.classifier,
          description: account.description,
          classifierDescription: FORMATTERS.UPPERCASE(`${account.classifier} ${account.description}`),
          disabled: account.account_level !== 4,
          syntetic: account.account_type === 'SINTETICA',
        })),
        selectAccounts: action.payload.accounts.map((account) => ({
          label: FORMATTERS.UPPERCASE(`${account.classifier} ${account.description}`),
          value: account.id,
          isDisabled: account.account_level !== 4,
          isFixed: true,
        })),
        ui: {
          ...state.ui,
          isLoading: false,
        },
      };
    case constants.ACCOUNT_GET_ALL_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoading: false,
          hasErrors: true,
        },
      };
    case constants.ACCOUNT_NEW_REQUEST:
      return {
        ...state,
        selectedAccount: null,
        ui: {
          ...state.ui,
          accountFormVisible: true,
          accountFormErrors: [],
        },
      };
    case constants.ACCOUNT_NEW_CANCEL_REQUEST:
      return {
        ...state,
        selectedAccount: null,
        ui: {
          ...state.ui,
          accountFormLoading: false,
          accountFormVisible: false,
          accountFormErrors: [],
          accountFormDeleteMode: false,
        },
      };
    case constants.ACCOUNT_ADD_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          accountFormErrors: [],
          accountFormLoading: true,
        },
        isLoading: true,
      };
    case constants.ACCOUNT_ADD_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          accountFormLoading: false,
        },
        isLoading: false,
      };
    case constants.ACCOUNT_ADD_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          accountFormLoading: false,
          accountFormErrors: action.payload.errors,
        },
        isLoading: false,
      };
    case constants.ACCOUNT_EDIT_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          accountFormLoading: true,
        },
      };
    case constants.ACCOUNT_EDIT_SUCCESS:
      return {
        ...state,
        selectedAccount: action.payload.account,
        ui: {
          ...state.ui,
          accountFormLoading: false,
          accountFormVisible: true,
        },
      };
    case constants.ACCOUNT_EDIT_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          accountFormLoading: false,
          accountFormErrors: action.payload.errors,
        },
      };
    case constants.ACCOUNT_UPDATE_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          accountFormLoading: true,
        },
      };
    case constants.ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        selectedAccount: action.payload.account,
        ui: {
          ...state.ui,
          accountFormLoading: false,
          accountFormVisible: false,
        },
      };
    case constants.ACCOUNT_UPDATE_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          accountFormLoading: false,
          accountFormErrors: action.payload.errors,
        },
      };
    case constants.ACCOUNT_DELETE_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          accountFormLoading: true,
        },
      };
    case constants.ACCOUNT_DELETE_SUCCESS:
      return {
        ...state,
        selectedAccount: action.payload.account,
        ui: {
          ...state.ui,
          accountFormLoading: false,
          accountFormVisible: true,
          accountFormDeleteMode: true,
        },
      };
    case constants.ACCOUNT_DELETE_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          accountFormLoading: false,
          accountFormDeleteMode: false,
        },
      };
    case constants.ACCOUNT_DELETE_CONFIRM_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          accountFormLoading: true,
        },
      };
    case constants.ACCOUNT_DELETE_CONFIRM_SUCCESS:
      return {
        ...state,
        selectedAccount: null,
        ui: {
          ...state.ui,
          accountFormLoading: false,
          accountFormVisible: false,
          accountFormDeleteMode: false,
        },
      };
    case constants.ACCOUNT_DELETE_CONFIRM_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          accountFormLoading: false,
          accountFormErrors: action.payload.errors,
        },
      };
    case constants.ACCOUNT_TOGGLE_FORM:
      return {
        ...state,
        ui: {
          ...state.ui,
          accountFormVisible: !state.ui.accountFormVisible,
        },
      };
    default:
      return state;
  }
}
