import { connect } from 'react-redux';

import authActionCreators from '_store/_actions/auth';

import PasswordUpdate from './PasswordUpdate';

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
});

const mapDispatchToProps = {
  onPasswordUpdate: authActionCreators.passwordUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordUpdate);
