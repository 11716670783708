import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import DeleteMultipleContacts from './DeleteMultipleContacts';

const mapStateToProps = (state) => ({
  isLoading: state.invoices.isLoading,
});

const mapDispatchToProps = {
  onDeleteMultipleContacts: companyTransactionsActionCreators.deleteMultipleContacts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteMultipleContacts);
