import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledMenu,
  MenuCategory,
  MenuRow,
  MenuButton,
} from './styles';

function Menu({ children }) {
  return (
    <StyledMenu>
      {children}
    </StyledMenu>
  );
}

export { MenuCategory, MenuRow, MenuButton };

Menu.defaultProps = {
  children: null,
};

Menu.propTypes = {
  children: PropTypes.node,
};

export default Menu;
