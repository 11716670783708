import styled from 'styled-components';

import Tag from '_components/_core/Tag/Tag';

export const StyledTag = styled(Tag)`
  display: flex;
  align-items: center;
  font-size: 14px;

  span:first-child {
    font-weight: 700;
    font-size: 16px;
  }
`;
