import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useFormikContext } from 'formik';

import {
  FormTextField,
  FormMaskedInput,
  Select,
  Card,
  CardHeader,
  CardBody,
} from '_components/_core';

import { SearchButton } from './styles';

const EmployeeAddressTab = ({
  states,
  cities,
  isLoadingCep,
  onFetchStates,
  onFetchCities,
  onFetchCep,
}) => {
  const formik = useFormikContext();
  const { setFieldValue, values } = formik;

  const handleCepSearch = useCallback(async (values) => {
    onFetchCep(values.address_zip_code, (address) => {
      const {
        bairro,
        complemento,
        localidade,
        logradouro,
        uf,
        ibge,
        ddd,
      } = address;

      setFieldValue('address_street', logradouro);
      setFieldValue('address_number', null);
      setFieldValue('address_complement', complemento);
      setFieldValue('address_district', bairro);
      setFieldValue('address_state', uf);
      setFieldValue('address_state_ibge', Number(ddd));
      onFetchCities(Number(ddd), () => {
        setFieldValue('address_city_ibge', Number(ibge));
        setFieldValue('address_city', localidade);
      });
    });
  }, [onFetchCep, onFetchCities, setFieldValue]);

  const handleStateIbgeChange = useCallback((option) => {
    setFieldValue('address_state_ibge', option.value);
    setFieldValue('address_state', option.sigla);

    onFetchCities(option.sigla, () => {
      setFieldValue('address_city', null);
      setFieldValue('address_city_ibge', null);
    });
  }, [onFetchCities, setFieldValue]);

  const handleCityIbgeChange = useCallback((option) => {
    setFieldValue('address_city_ibge', option.value);
    setFieldValue('address_city', option.label);
  }, [setFieldValue]);

  useEffect(() => {
    onFetchStates();
  }, [onFetchStates]);

  const getSelectedState = useCallback((values) => {
    if (!values.address_state_ibge) {
      return null;
    }

    return states.find((state) => state.value === Number(values.address_state_ibge));
  }, [states]);

  const getSelectedCity = useCallback(
    (values) => {
      if (!values.address_city_ibge) {
        return null;
      }

      return cities.find((city) => Number(city.value) === Number(values.address_city_ibge));
    }, [cities],
  );

  return (
    <>
      <Card noBorders>
        <CardHeader title="Endereço" description="Preencha o endereço do funcionário" />
        <CardBody noPaddingTop>
          <Form>
            <Form.Row>
              <Form.Group as={Col} md="4">
                <Form.Label>CEP</Form.Label>
                <FormMaskedInput
                  type="ZIP_CODE"
                  name="address_zip_code"
                  placeholder="CEP"
                />
              </Form.Group>
              <Form.Group as={Col} md="3">
                <SearchButton
                  disabled={!values.address_zip_code}
                  type="button"
                  variant="secondary"
                  isLoading={isLoadingCep}
                  onClick={() => handleCepSearch(values)}
                >
                  Buscar CEP
                </SearchButton>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="4">
                <Form.Label>Logradouro</Form.Label>
                <FormTextField
                  name="address_street"
                  placeholder="Logradouro"
                />
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>Número</Form.Label>
                <FormTextField name="address_number" placeholder="Número" />
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>Bairro</Form.Label>
                <FormTextField name="address_district" placeholder="Bairro" />
              </Form.Group>

            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="4">
                <Form.Label>Complemento</Form.Label>
                <FormTextField
                  name="address_complement"
                  placeholder="Complemento"
                />
              </Form.Group>
              <Form.Group as={Col} lg="4">
                <Form.Label>Unidade Federativa</Form.Label>
                <Select
                  name="address_state_ibge"
                  onChange={(option) => handleStateIbgeChange(option)}
                  value={getSelectedState(values)}
                  options={states}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4">
                <Form.Label>Cidade</Form.Label>
                <Select
                  name="address_city_ibge"
                  onChange={(option) => handleCityIbgeChange(option)}
                  value={getSelectedCity(values)}
                  options={cities}
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </CardBody>
      </Card>
    </>

  );
};

export default EmployeeAddressTab;

EmployeeAddressTab.propTypes = {
  states: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  isLoadingCep: PropTypes.bool.isRequired,
  onFetchStates: PropTypes.func.isRequired,
  onFetchCities: PropTypes.func.isRequired,
  onFetchCep: PropTypes.func.isRequired,
};
