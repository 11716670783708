import { connect } from 'react-redux';

import Progress from './Progress';

const mapStateToProps = (state) => ({
  bankStatementSummary: state.companyTransactions.bankStatementSummary,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Progress);
