import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Container as BoostrapContainer } from 'react-bootstrap';

export const NavContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
`;

export const FormContainer = styled.div`
  padding-left: 1em;
  padding-right: 1em;
  @media (min-width: 1024px) {
    padding-left: 11em;
    padding-right: 11em;
  }
`;

export const StyledLink = styled(Link)`
  color: #000;
  font-size: 12px;
  padding: 1em 2em;
  margin-left: 1em;
  margin-right: 1em;
  font-weight: 600;
  border-radius: 50px;
  border: 1px solid #d5dcec;
  &:hover {
    color: #000;
  }
`;

export const StyledImage = styled.div`
  background-image: url('https://contabileasy.blob.core.windows.net/app/login-bg-woman.jpeg');
  height: 100%;
  background-size: cover;
  background-position-x: 70%;
`;

export const Container = styled(BoostrapContainer)`

`;
