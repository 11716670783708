import { push } from 'connected-react-router';

import constants from '../../_constants/auth.constants';
import service from '../../../services/auth.service';

const logout = () => {
  return (dispatch) => {
    dispatch(request());

    service.logout();

    dispatch(push('/entrar'));

    window.location.reload();
  };

  function request() {
    return {
      type: constants.LOGOUT,
      payload: { },
    };
  }
};

export default logout;
