import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';

const addCompanyDigitalCertificate = (company_id, formData, callback) => {
  return async (dispatch) => {
    dispatch(request(formData));

    service.addDigitalCertificate(company_id, formData).then(
      (response) => {
        dispatch(success(response));
        dispatch(alertActionCreators.success('Certificado digital armazenado com sucesso!'));

        if (callback) {
          service.getById(company_id).then((response) => {
            callback(response.data);
          });
        }
      },
    ).catch(
      (err) => {
        dispatch(failure(err));
      },
    );
  };

  function request() {
    return {
      type: constants.ADD_COMPANY_DIGITAL_CERTIFICATE_REQUEST,
      payload: { formData },
    };
  }
  function success(response) {
    return {
      type: constants.ADD_COMPANY_DIGITAL_CERTIFICATE_SUCCESS,
      payload: { response },
    };
  }
  function failure(error) {
    return {
      type: constants.ADD_COMPANY_DIGITAL_CERTIFICATE_FAILURE,
      payload: { error },
    };
  }
};

export default addCompanyDigitalCertificate;
