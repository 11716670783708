import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';
import { IoSearch } from 'react-icons/io5';
import { DateTime } from 'luxon';

import {
  Button,
  Alert,
  FormMonthPickerField,
} from '_components/_core';

import { ValidationSchema } from './utilities';
import { StyledModal, ModalFooter } from './styles';

const AccountingIntegrationModal = ({
  activeCompany,
  isLoadingInvoices,
  filters,
  onSyncInvoices,
  onFilter,
}) => {
  const { date_founded } = activeCompany || {};
  const [isVisible, setIsVisible] = useState(false);

  const handleGenerateEntries = useCallback((values, { resetForm, setFieldError }) => {
    const { year: currentYear } = DateTime.now({ zone: 'utc' });
    const { year: foundedYear } = DateTime.fromISO(date_founded, { zone: 'utc' });

    if (Number(values.year) < Number(foundedYear)) {
      setFieldError('year', 'O ano selecionado deve ser maior ou igual ao ano de abertura da empresa.');
      return;
    }

    if (Number(values.year) > currentYear) {
      setFieldError('year', 'O ano selecionado não pode ser maior que o ano atual.');
      return;
    }

    onSyncInvoices(values, () => {
      onFilter(filters);
      resetForm();
      setIsVisible(false);
    });
  }, [onSyncInvoices, filters, onFilter, date_founded]);

  const renderModalFooter = useCallback((handleSubmit, isValid) => (
    <ModalFooter>
      <Button
        variant="secondary"
        onClick={() => setIsVisible(false)}
      >
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        disabled={!isValid}
        isLoading={isLoadingInvoices}
      >
        Sincronizar
      </Button>
    </ModalFooter>
  ), [isLoadingInvoices]);

  return (
    <Formik
      initialValues={{
        start_date: null,
        end_date: null,
      }}
      onSubmit={handleGenerateEntries}
      validationSchema={ValidationSchema}
      enableReinitialize
    >
      {({
        handleSubmit,
        isValid,
      }) => (
        <>
          <Button
            variant="secondary"
            icon={<IoSearch />}
            onClick={() => setIsVisible(true)}
            className="mr-3"
          >
            Buscar Notas de Serviço
          </Button>
          <StyledModal
            title="Buscar Notas de Serviço"
            isVisible={isVisible}
            toggleModal={() => setIsVisible(false)}
            footer={renderModalFooter(handleSubmit, isValid)}
          >
            <Alert
              title="Busca de Notas de Serviço"
              description={(
                <p>
                  O Contabills irá buscar e conciliar automaticamente todas as Notas Fiscais
                  de Serviço emitidas no mês/ano informado abaixo.
                </p>
              )}
              className="mb-3"
            />
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>Competência Inicial</Form.Label>
                  <FormMonthPickerField
                    variant="input"
                    name="start_date"
                    placeholder="Período Inicial"
                    minDate={date_founded}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Competência Final</Form.Label>
                  <FormMonthPickerField
                    variant="input"
                    name="end_date"
                    placeholder="Período Final"
                    minDate={date_founded}
                    endOfMonth
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default AccountingIntegrationModal;

AccountingIntegrationModal.defaultProps = {
  isLoadingInvoices: false,
  activeCompany: {},
  filters: null,
};

AccountingIntegrationModal.propTypes = {
  isLoadingInvoices: PropTypes.bool,
  filters: PropTypes.object,
  onSyncInvoices: PropTypes.func.isRequired,
  activeCompany: PropTypes.object,
  onFilter: PropTypes.func.isRequired,
};
