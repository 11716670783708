import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';

const getUserSubscriptionStatus = () => {
  return (dispatch) => {
    dispatch(request());

    service.getUserSubscriptionStatus().then(
      (response) => {
        const { data } = response;

        dispatch(success({ data }));
      },
    );
  };

  function request() {
    return {
      type: constants.FETCH_SUBSCRIPTION_STATUS_REQUEST,
      payload: { },
    };
  }
  function success(subscriptionStatus) {
    return {
      type: constants.FETCH_SUBSCRIPTION_STATUS_SUCCESS,
      payload: subscriptionStatus,
    };
  }
};

export default getUserSubscriptionStatus;
