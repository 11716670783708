import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import {
  FormTextField,
} from '_components/_core';

function ContactsFilters() {
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Nome</Form.Label>
            <FormTextField name="name" placeholder="Nome" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>CPF / CNPJ</Form.Label>
            <FormTextField name="document_number" placeholder="CPF/CNPJ" />
          </Form.Group>
        </Col>
      </Form.Row>
    </Form>
  );
}

export default ContactsFilters;
