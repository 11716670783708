import { connect } from 'react-redux';
import { compose } from 'redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import companyActionCreators from '_store/_actions/company';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Companies from './Companies';

const mapStateToProps = (state) => ({
  isLoading: state.companyTransactions.isLoading,
  categories: state.companyTransactions.categories,
  activeCompany: state.company.activeCompany,
  companies: state.company.companies,
  subscription: state.subscription.subscription,
  user: state.auth.user,
});

const mapDispatchToProps = {
  onFetchCompanies: companyActionCreators.getAllCompanies,
  onCreateCategory: companyTransactionsActionCreators.createTransactionCategory,
  onUpdateCategory: companyTransactionsActionCreators.updateCategory,
  onCompanyAvatarUpload: companyActionCreators.addCompanyAvatar,
  onCompanyAvatarDelete: companyActionCreators.deleteCompanyAvatar,
  onDeleteFinancialCompany: companyActionCreators.deleteFinancialCompany,
  onUpdateFinancialCompany: companyActionCreators.updateFinancialCompany,
  onFetchMarketSegments: companyActionCreators.fetchMarketSegments,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['manager_company'], 'all', true),
)(Companies);
