import { connect } from 'react-redux';

import userActionCreators from '_store/_actions/user';
import Preferences from './Preferences';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  preferences: state.user.preferences,
});

const mapDispatchToProps = {
  onFetchUserPreferences: userActionCreators.getUserPreferences,
  onSaveUserPreferences: userActionCreators.updatePreferences,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Preferences);
