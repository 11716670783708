import styled from 'styled-components';

import Button from '_components/_core/Button';
import Modal from '_components/_core/Modal';

export const StyledModal = styled(Modal)`
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchButton = styled(Button)`
  margin-top: 26px;
  margin-left: 15px;
`;

export const StyledHr = styled.hr`
  margin-bottom: 2rem;
`;

export const DarfTable = styled.table`  
  &&& {

    thead th {
      font-size: 13px;
      line-height: 13px;
      font-weight: bold;
      padding: 10px 15px !important;
      position: sticky; 
      top: 0; 
      z-index: 1;
      background-color: var(--th-bg-color);

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #d5dcec;
        box-shadow: inset 0px 25px 1px -10px #d5dcec;
      }
    }

    tbody {
      background-color: var(--th-bg-color);
    }

    tbody tr td {
      font-size: 13px;
      line-height: 13px;
      font-weight: normal;
      padding: 10px 15px !important;
    }

    tbody tr td span.employee-name {
      font-size: 14px;
      font-weight: bold;
      display: block;
    }

    tbody tr td span.event-type {
      display: block;
      margin-top: 8px;
      font-size: 12px;
      color: #8c8c8c !important;
    }

    tfoot tr th {
      font-size: 13px;
      line-height: 13px;
      font-weight: bold;
      padding: 10px 15px !important;
      position: sticky; 
      top: 0; 
      z-index: 1;
      background-color: var(--th-bg-color); 


      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--th-border-color);
        box-shadow: inset 0px 25px 1px -10px #d5dcec;
      }
    }
  }

`;
