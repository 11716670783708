import React from 'react';
import PropTypes from 'prop-types';

import { ResponsiveContainer, Container } from './styles';

import Header from './components/Header';
import Body from './components/Body';

export default function Table({
  isLoading,
  accounts,
  editAccount,
  deleteAccount,
  duplicateAccount,
}) {
  return (
    <ResponsiveContainer className="table-responsive">
      <Container className="table table-striped table-hover">
        <Header />
        <Body
          isLoading={isLoading}
          accounts={accounts}
          editAccount={editAccount}
          deleteAccount={deleteAccount}
          duplicateAccount={duplicateAccount}
        />
      </Container>
    </ResponsiveContainer>
  );
}

Table.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  accounts: PropTypes.array.isRequired,
  editAccount: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  duplicateAccount: PropTypes.func.isRequired,
};
