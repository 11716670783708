import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';

import {
  Button,
  FormDateField,
  FormTextField,
  FormCpfCnpjField,
} from '_components/_core';

import { EmployeeDependentSchema } from '../../utilities';
import { StyledModal, ModalFooter } from './styles';

export default function AddDependentModal({
  isVisible,
  isLoading,
  selectedDependent,
  onModalToggle,
  onAddEmployeeDependent,
  onUpdateEmployeeDependent,
  employeeId,
}) {
  const handleAddEditEmployeeSalary = useCallback((values, { resetForm }) => {
    if (selectedDependent) {
      onUpdateEmployeeDependent(employeeId, selectedDependent._id, values, () => {
        resetForm();
        onModalToggle();
      });
    } else {
      onAddEmployeeDependent(employeeId, values, () => {
        resetForm();
        onModalToggle();
      });
    }
  }, [
    employeeId,
    selectedDependent,
    onAddEmployeeDependent,
    onUpdateEmployeeDependent,
    onModalToggle,
  ]);

  const renderModalFooter = useCallback((handleSubmit, isValid) => (
    <ModalFooter>
      <Button
        variant="secondary"
        onClick={onModalToggle}
      >
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={!isValid || isLoading}
      >
        Salvar
      </Button>
    </ModalFooter>
  ), [onModalToggle, isLoading]);

  return (
    <Formik
      initialValues={{
        _id: selectedDependent ? selectedDependent._id : null,
        name: selectedDependent ? selectedDependent.name : '',
        date_of_birth: selectedDependent ? selectedDependent.date_of_birth : null,
        document_number: selectedDependent ? selectedDependent.document_number : '',
      }}
      validationSchema={EmployeeDependentSchema}
      onSubmit={handleAddEditEmployeeSalary}
      enableReinitialize
    >
      {({ handleSubmit, isValid }) => (
        <>
          <StyledModal
            title={selectedDependent ? 'Editar Dependente' : 'Cadastrar novo Dependente'}
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit, isValid)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>Nome</Form.Label>
                  <FormTextField name="name" placeholder="Nome do dependente" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="8">
                  <Form.Label>CPF</Form.Label>
                  <FormCpfCnpjField
                    name="document_number"
                    placeholder="CPF"
                    type="CPF"
                  />
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Label>Data de Nascimento</Form.Label>
                  <FormDateField
                    name="date_of_birth"
                    placeholder="Data de Nascimento"
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
}

AddDependentModal.defaultProps = {
  isLoading: false,
};

AddDependentModal.propTypes = {
  employeeId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  isLoading: PropTypes.func,
  selectedDependent: PropTypes.object,
  onModalToggle: PropTypes.func.isRequired,
  onAddEmployeeDependent: PropTypes.func.isRequired,
  onUpdateEmployeeDependent: PropTypes.func.isRequired,
};
