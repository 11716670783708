import FORMATTERS from 'helpers/formatters';

export const DEFAULT_FILTERS = {
  invoice_number: null,
  date_competence_start: null,
  date_competence_end: null,
  date_emission_start: null,
  date_emission_end: null,
  service_taker: null,
  invoice_status: 'NORMAL',
  service_gross_amount_start: null,
  service_gross_amount_end: null,
  service_net_amount_start: null,
  service_net_amount_end: null,
  service_pis_amount_start: null,
  service_pis_amount_end: null,
  service_cofins_amount_start: null,
  service_cofins_amount_end: null,
  service_ir_amount_start: null,
  service_ir_amount_end: null,
  service_csll_amount_start: null,
  service_csll_amount_end: null,
  service_inss_amount_start: null,
  service_inss_amount_end: null,
  service_iss_amount_start: null,
  service_iss_amount_end: null,
  service_iss_withheld: null,
};

export const DEFAULT_COLUMNS = [
  'invoice_number',
  'date_competence',
  'date_emission',
  'service_taker_document_number',
  'service_taker_company_name',
  'service_gross_amount',
  'service_net_amount',
  'cancelled',
  'id',
];

export const TABLE_COLUMNS = [
  {
    label: 'Número',
    keyName: 'invoice_number',
    sortingKey: 'invoice_number',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.STRING,
    order: 1,
  },
  {
    label: 'Documento Prestador',
    keyName: 'service_provider_document_number',
    sortingKey: 'service_provider_document_number',
    formatter: FORMATTERS.CPF_CNPJ,
    align: 'center',
    width: 10,
    order: 2,
  },
  {
    label: 'Prestador',
    keyName: 'service_provider_company_name',
    sortingKey: 'service_provider_company_name',
    width: 30,
    formatter: FORMATTERS.UPPERCASE,
    order: 3,
  },
  {
    label: 'Competência',
    keyName: 'date_competence',
    sortingKey: 'date_competence',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.DATE_MMYYYY,
    order: 4,
  },
  {
    label: 'Data de Emissão',
    keyName: 'date_emission',
    sortingKey: 'date_emission',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.DATE_DDMMYYYY,
    order: 5,
  },
  {
    label: 'Documento Tomador',
    keyName: 'service_taker_document_number',
    sortingKey: 'service_taker_document_number',
    formatter: FORMATTERS.CPF_CNPJ,
    align: 'center',
    width: 10,
    order: 6,
  },
  {
    label: 'Tomador',
    keyName: 'service_taker_company_name',
    sortingKey: 'service_taker_company_name',
    width: 40,
    formatter: FORMATTERS.UPPERCASE,
    order: 7,
  },
  {
    label: 'Descrição',
    keyName: 'service_description',
    sortingKey: 'service_description',
    width: 30,
    formatter: FORMATTERS.UPPERCASE,
    order: 8,
  },
  {
    label: 'Valor Bruto',
    keyName: 'service_gross_amount',
    sortingKey: 'service_gross_amount',
    align: 'center',
    width: 8,
    formatter: FORMATTERS.NUMBER,
    order: 9,
    sumOnFooter: true,
  },
  {
    label: 'ISS Retido',
    keyName: 'service_iss_withheld',
    sortingKey: 'service_iss_withheld',
    align: 'center',
    width: 8,
    formatter: FORMATTERS.ISS_WITHHELD,
    order: 10,
  },
  {
    label: 'Valor ISS',
    keyName: 'service_iss_amount',
    sortingKey: 'service_iss_amount',
    align: 'center',
    width: 8,
    formatter: FORMATTERS.NUMBER,
    order: 11,
    sumOnFooter: true,
  },
  {
    label: 'PIS Retido',
    keyName: 'service_pis_amount',
    sortingKey: 'service_pis_amount',
    align: 'center',
    width: 8,
    formatter: FORMATTERS.NUMBER,
    order: 12,
    sumOnFooter: true,
  },
  {
    label: 'COFINS Retido',
    keyName: 'service_cofins_amount',
    sortingKey: 'service_cofins_amount',
    align: 'center',
    width: 8,
    formatter: FORMATTERS.NUMBER,
    order: 13,
    sumOnFooter: true,
  },
  {
    label: 'IRPJ Retido',
    keyName: 'service_ir_amount',
    sortingKey: 'service_ir_amount',
    align: 'center',
    width: 8,
    formatter: FORMATTERS.NUMBER,
    order: 14,
    sumOnFooter: true,
  },
  {
    label: 'CSLL Retido',
    keyName: 'service_csll_amount',
    sortingKey: 'service_csll_amount',
    align: 'center',
    width: 8,
    formatter: FORMATTERS.NUMBER,
    order: 15,
    sumOnFooter: true,
  },
  {
    label: 'INSS Retido',
    keyName: 'service_inss_amount',
    sortingKey: 'service_inss_amount',
    align: 'center',
    width: 8,
    formatter: FORMATTERS.NUMBER,
    order: 16,
    sumOnFooter: true,
  },
  {
    label: 'Valor Líquido',
    keyName: 'service_net_amount',
    sortingKey: 'service_net_amount',
    align: 'center',
    width: 8,
    formatter: FORMATTERS.NUMBER,
    order: 17,
    sumOnFooter: true,
  },
  {
    label: 'Status',
    keyName: 'cancelled',
    sortingKey: 'cancelled',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.INVOICE_STATUS,
    order: 18,
  },
  {
    label: 'Item Lista Serviço',
    keyName: 'service_item_code',
    sortingKey: 'service_item_code',
    align: 'center',
    width: 8,
    formatter: FORMATTERS.STRING,
    order: 19,
  },
  {
    label: 'Cód. Tributação no Município',
    keyName: 'service_district_tax_code',
    sortingKey: 'service_district_tax_code',
    align: 'center',
    width: 8,
    formatter: FORMATTERS.STRING,
    order: 20,
  },
];

export const INVOICE_STATUS = [
  {
    label: 'Todos',
    value: null,
  },
  {
    label: 'Normal',
    value: 'NORMAL',
  },
  {
    label: 'Cancelada',
    value: 'CANCELLED',
  },
];
