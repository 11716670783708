import React from 'react';
import PropTypes from 'prop-types';
import Tab from 'react-bootstrap/Tab';

import { StyledTabs, TabTitle } from './styles';

function CustomTabs({
  tabs,
  variant,
  className,
  activeTab,
  onTabChange,
  alignCenter,
  floatRightLastTab,
}) {
  return (
    <StyledTabs
      alignCenter={alignCenter}
      variant={variant}
      transition={false}
      className={className}
      activeKey={activeTab}
      onSelect={onTabChange}
      floatRightLastTab={floatRightLastTab}
    >
      {tabs.map((item) => {
        const {
          icon,
          content,
          color,
          hidden,
          className: tabClassName,
        } = item;

        if (hidden) {
          return null;
        }

        return (
          <Tab
            key={`tab-${item.id}`}
            eventKey={item.id}
            title={(
              <>
                <TabTitle color={color} className={tabClassName}>
                  {icon}
                  {item.title}
                </TabTitle>
              </>
            )}
          >
            {content}
          </Tab>
        );
      })}
    </StyledTabs>
  );
}

export { StyledTabs };

CustomTabs.propTypes = {
  variant: 'primary',
  className: null,
  onTabChange: null,
  activeTab: null,
  tabs: [],
  alignCenter: false,
  floatRightLastTab: false,
};

CustomTabs.propTypes = {
  alignCenter: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    content: PropTypes.oneOf([
      PropTypes.string,
      PropTypes.node,
    ]),
  })),
  variant: PropTypes.oneOf(['primary', 'secondary']),
  className: PropTypes.string,
  onTabChange: PropTypes.func,
  activeTab: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  floatRightLastTab: PropTypes.bool,
};

export default CustomTabs;
