import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { hasPermissions } from './utilities';

import Fallback from './components/Fallback';

const withPermissionGate = (permissions, type = 'all', fallback = true, variant = 'page') => (WrappedComponent) => {
  const WithPermissionsComponent = (props) => {
    const {
      userPermissions,
      ...rest
    } = props;

    if (!userPermissions || isEmpty(userPermissions)) {
      return <Fallback isLoading />;
    }

    const permissionGranted = hasPermissions({
      permissions,
      userPermissions,
      type,
    });

    if (!permissionGranted && !fallback) return null;

    if (!permissionGranted && fallback) {
      return <Fallback variant={variant} />;
    }

    return <WrappedComponent {...rest} />;
  };

  WithPermissionsComponent.propTypes = {
    userPermissions: PropTypes.any.isRequired,
  };

  return WithPermissionsComponent;
};

export default withPermissionGate;
