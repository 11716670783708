import styled, { css } from 'styled-components';

export const StyledRow = styled.tr`
  td {
    cursor: pointer;
    border-bottom: none !important;
  }

  ${({ isStatusRow }) => isStatusRow && css`
    td {
      padding: 0 !important;
    }
  `}

  ${({ isHovered }) => isHovered && css`
    td {
      background-color: var(--tr-color-hover) !important;
    }
  `}
`;

export const StyledDuplicatedRow = styled.tr`
  td {
    cursor: pointer;
    border-top: none !important;
    height: 30px !important;
    padding-top: 4px !important;
  }  

  ${({ isHovered }) => isHovered && css`
    td {
      background-color: var(--tr-color-hover) !important;
    }
  `}

  ${({ isSelected }) => isSelected && css`
    td {
      background-color: #eceef1 !important;
    }
  `}
`;
