import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';
import ibgeActionCreators from '_store/_actions/ibge';

import ActivitiesTab from './ActivitiesTab';

const mapStateToProps = (state) => ({
  isSearching: state.company.isSearching,
  searchedCompany: state.company.searchedCompany,
  isLoadingCnae: state.ibge.isLoadingCnae,
});

const mapDispatchToProps = {
  onSearchCompanyByCnpj: companyActionCreators.searchCompany,
  onClearSearchedCompany: companyActionCreators.clearSearchedCompany,
  onFetchCities: ibgeActionCreators.fetchCities,
  onFetchCnae: ibgeActionCreators.fetchCnae,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesTab);
