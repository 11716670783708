import { push } from 'connected-react-router';

import { parsePaginationParams } from 'helpers';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const fetchBankStatementTransactions = (params = {}) => {
  return async (dispatch) => {
    dispatch(request(params));

    const parsedParams = parsePaginationParams(params);

    service
      .fetchBankStatementTransactions(parsedParams)
      .then((response) => {
        dispatch(success(response));

        const { total } = response.data;

        if (total > 0) {
          dispatch(push('/conciliar-extrato'));
        }
      }).catch((error) => {
        dispatch(failure(error));
      });
  };

  function request(params) {
    return {
      type: constants.FETCH_BANK_STATEMENT_TRANSACTIONS_REQUEST,
      payload: params,
    };
  }
  function success(response) {
    return {
      type: constants.FETCH_BANK_STATEMENT_TRANSACTIONS_SUCCESS,
      payload: response.data,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_BANK_STATEMENT_TRANSACTIONS_FAILURE,
      payload: error,
    };
  }
};

export default fetchBankStatementTransactions;
