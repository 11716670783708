import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { IoCheckmark } from 'react-icons/io5';

import { Button } from '_components/_core';

const DuplicateIssuedInvoices = ({
  isLoading,
  selectedItems,
  onDuplicateIssuedInvoices,
}) => {
  const handleDuplicateIssuedInvoices = useCallback(() => {
    const params = {
      invoice_ids: selectedItems,
    };

    onDuplicateIssuedInvoices(params);
  }, [onDuplicateIssuedInvoices, selectedItems]);

  return (
    <Button
      className="mr-2 btn-sm"
      variant="secondary"
      onClick={handleDuplicateIssuedInvoices}
      isLoading={isLoading}
      disabled={isLoading}
      icon={<IoCheckmark />}
    >
      {`Duplicar ${selectedItems.length} NFS-e`}
    </Button>
  );
};

export default DuplicateIssuedInvoices;

DuplicateIssuedInvoices.defaultProps = {
  isLoading: false,
};

DuplicateIssuedInvoices.propTypes = {
  isLoading: PropTypes.bool,
  selectedItems: PropTypes.array.isRequired,
  onDuplicateIssuedInvoices: PropTypes.func.isRequired,
};
