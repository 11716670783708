import searchPDFInvoices from './searchPDFInvoices';

import fetchAutomationSettings from './fetchAutomationSettings';
import addAutomationSettings from './addAutomationSettings';
import updateAutomationSettings from './updateAutomationSettings';
import deleteAutomationSettings from './deleteAutomationSettings';
import executeAutomation from './executeAutomation';

export default {
  searchPDFInvoices,
  fetchAutomationSettings,
  addAutomationSettings,
  updateAutomationSettings,
  deleteAutomationSettings,
  executeAutomation,
};
