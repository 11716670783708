import React from 'react';
import { AiOutlineBarChart } from 'react-icons/ai';
import { FaCheckCircle } from 'react-icons/fa';
import { HiReceiptTax } from 'react-icons/hi';
import { IoMdPeople } from 'react-icons/io';
import {
  IoArrowDown,
  IoArrowUp,
  IoBookmark,
  IoCalendar,
  IoGitCompare,
} from 'react-icons/io5';

export const FIELD_OPTIONS_MOBILE = [
  {
    label: 'Data da transação',
    value: 'event_date',
    icon: <IoCalendar className="mr-2 text-info" />,
  },
  {
    label: 'Descrição',
    value: 'description',
    icon: <HiReceiptTax className="mr-2 text-info" />,
  },
  {
    label: 'Contato',
    value: 'recipient.name',
    icon: <IoMdPeople className="mr-2 text-info" />,
  },
  {
    label: 'Valor',
    value: 'amount',
    icon: <AiOutlineBarChart className="mr-2 text-info" />,
  },
  {
    label: 'Categoria',
    value: 'category.description',
    icon: <IoBookmark className="mr-2 text-info" />,
  },
  {
    label: 'Plano de pagamento',
    value: 'payment_plan',
    icon: <IoGitCompare className="mr-2 text-info" />,
  },
  {
    label: 'Pago / Não pago',
    value: 'paid',
    icon: <FaCheckCircle className="mr-2 text-info" />,
  },
];

export const ORDER_OPTIONS_MOBILE = [
  {
    label: 'Crescente',
    value: 'asc',
    icon: <IoArrowUp className="mr-2 text-info" />,
  },
  {
    label: 'Decrescente',
    value: 'desc',
    icon: <IoArrowDown className="mr-2 text-info" />,
  },
];
