import styled from 'styled-components';

import { Button } from '_components/_core';

export const StyledButton = styled(Button)`
  background-color: #11924F;
  border: 0;
  min-width: 200px;
  
  :hover {
    background-color: #0A502B;
  }
  
  :active {
    background-color: #0A502B;
  }
`;

export const StyledFileList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 8px;

  li {
    display: flex;
    align-items: center;
    font-weight: 400;
    

    &:hover {
      color: #11924F;
      cursor: pointer;

      svg {
        fill: unset;
      }
    }
  }

  svg {
    margin-left: 8px;
    cursor: pointer;

    &:hover {
      fill: #d52b1e;
    }
  }
`;
