import constants from '../../_constants/sidebar.constants';

const toggleSidebar = () => {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return {
      type: constants.TOGGLE_SIDEBAR_REQUEST,
      payload: { },
    };
  }
};

export default toggleSidebar;
