import { SPECIAL_COLUMNS } from '_components/_core/Table';
import FORMATTERS from 'helpers/formatters';

import PersonSchema from './PersonSchema';
import ContactSchema from './ContactSchema';

const personFormFields = {
  _id: null,
  name: '',
  email: '',
  document_number: '',
  phone_number: '',
  address_street: '',
  address_number: '',
  address_complement: '',
  address_district: '',
  address_state: '',
  address_state_ibge: '',
  address_city: '',
  address_city_ibge: '',
  address_zip_code: '',
  document_type: 'CPF',
};

export const DEFAULT_COLUMNS = [
  'status',
  'name',
  'document_number',
  'email',
  'phone_number',
  '_id',
];

export const TABLE_COLUMNS = [
  {
    label: 'Ações',
    keyName: '_id',
    align: 'center',
    width: 3,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'CPF / CNPJ',
    keyName: 'document_number',
    sortingKey: 'document_number',
    align: 'center',
    width: 3,
    formatter: FORMATTERS.CPF_CNPJ,
    order: 2,
  },
  {
    label: 'Nome / Razão Social',
    keyName: 'name',
    sortingKey: 'name',
    align: 'left',
    width: 5,
    formatter: FORMATTERS.UPPERCASE,
    order: 3,
  },
  {
    label: 'Telefone',
    keyName: 'phone_number',
    sortingKey: 'phone_number',
    align: 'center',
    width: 5,
    order: 4,
  },
  {
    label: 'E-mail',
    keyName: 'email',
    sortingKey: 'email',
    align: 'center',
    width: 5,
    order: 5,
  },
  {
    label: 'CEP',
    keyName: 'address_zip_code',
    sortingKey: 'address_zip_code',
    align: 'center',
    formatter: FORMATTERS.CEP,
    width: 5,
    order: 6,
  },
  {
    label: 'Logradouro',
    keyName: 'address_street',
    sortingKey: 'address_street',
    align: 'left',
    formatter: FORMATTERS.UPPERCASE,
    width: 5,
    order: 7,
  },
  {
    label: 'Número',
    keyName: 'address_number',
    sortingKey: 'address_number',
    align: 'left',
    width: 5,
    order: 8,
  },
  {
    label: 'Bairro',
    keyName: 'address_district',
    sortingKey: 'address_district',
    formatter: FORMATTERS.UPPERCASE,
    align: 'left',
    width: 5,
    order: 9,
  },
  {
    label: 'Complemento',
    keyName: 'address_complement',
    sortingKey: 'address_complement',
    formatter: FORMATTERS.UPPERCASE,
    width: 5,
    order: 10,
  },
  {
    label: 'Cidade',
    keyName: 'address_city',
    sortingKey: 'address_city',
    formatter: FORMATTERS.UPPERCASE,
    align: 'center',
    width: 5,
    order: 11,
  },
  {
    label: 'Estado',
    keyName: 'address_state',
    sortingKey: 'address_state',
    formatter: FORMATTERS.UPPERCASE,
    align: 'center',
    width: 5,
    order: 12,
  },
];

export { personFormFields, PersonSchema, ContactSchema };
