import HttpService from './http.service';

export default {
  getAllPersons,
  addPerson,
  updatePerson,
  deletePerson,
  getEFDContributions,
  downloadEFDContributions,
};

async function getAllPersons(id, params) {
  return HttpService.get(`/company/${id}/person`, params);
}
async function addPerson(id, params) {
  return HttpService.post(`/company/${id}/person`, params);
}
async function updatePerson(companyId, personId, params) {
  return HttpService.put(`/company/${companyId}/person/${personId}`, params);
}
async function deletePerson(companyId, personId) {
  return HttpService.delete(`/company/${companyId}/person/${personId}`);
}
async function getEFDContributions(start_date, end_date) {
  return HttpService.get(`/efd?start_date=${start_date}&end_date=${end_date}`);
}
async function downloadEFDContributions(start_date, end_date) {
  const params = {
    start_date,
    end_date,
  };

  return HttpService.post('efd', params);
}
