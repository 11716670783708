import { connect } from 'react-redux';

import payrollActionCreators from '_store/_actions/payroll';

import Payroll from './Payroll';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  isLoading: state.payroll.isLoading,
});

const mapDispatchToProps = {
  onFetchPayrolls: payrollActionCreators.getPayrolls,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Payroll);
