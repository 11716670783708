import constants from '../../_constants/payroll.constants';
import service from '../../../services/payroll.service';

import alertActionCreators from '../alert.actions';

const sendSalaryReceiptMail = (_id, recipients) => {
  return (dispatch) => {
    dispatch(request());

    service.sendSalaryReceiptMail(_id, recipients).then(
      (response) => {
        const { data } = response;

        dispatch(success(data));
        dispatch(alertActionCreators.success('E-mail Enviado com Sucesso!'));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.SEND_SALARY_RECEIPT_MAIL_REQUEST,
      payload: { },
    };
  }
  function success(data) {
    return {
      type: constants.SEND_SALARY_RECEIPT_MAIL_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.SEND_SALARY_RECEIPT_MAIL_FAILURE,
      payload: error,
    };
  }
};

export default sendSalaryReceiptMail;
