import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  StepItem,
} from './styles';

function StepsHelper({ steps, doneSteps, currentStep }) {
  const renderHeader = () => {
    const keys = Object.keys(steps);

    return (
      <>
        {keys.map((key, index) => (
          <StepItem
            key={key}
            isDone={doneSteps.includes(key)}
            active={currentStep === key}
          >
            <div>{index + 1}</div>
            <span>{steps[key]}</span>
          </StepItem>
        ))}
      </>
    );
  };

  return (
    <Container>
      <ul>
        {renderHeader()}
      </ul>
    </Container>
  );
}

StepsHelper.defaultProps = {
  steps: {},
  doneSteps: [],
};

StepsHelper.propTypes = {
  steps: PropTypes.object,
  doneSteps: PropTypes.array,
  currentStep: PropTypes.string.isRequired,
};

export default StepsHelper;
