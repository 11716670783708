import React from 'react';
import PropTypes from 'prop-types';

import FORMATTERS from 'helpers/formatters';

import { Container } from './styles';

function Summary({
  total_income,
  total_expenses,
  total_balance,
  total_income_with_transfers,
  total_expenses_with_transfers,
  total_balance_with_transfers,
  considerTransfers,
}) {
  return (
    <Container>
      <div className="d-inline">
        <span className="text-success mr-1" style={{ fontWeight: '600' }}>
          Entradas:
        </span>
        <p className="m-0 p-0">
          {FORMATTERS.NUMBER(
            considerTransfers ? total_income_with_transfers : total_income,
          )}
        </p>
      </div>
      <div>
        <span className="text-danger mr-1" style={{ fontWeight: '600' }}>
          Saídas:
        </span>
        <p className="m-0 p-0">
          {FORMATTERS.NUMBER(
            considerTransfers ? total_expenses_with_transfers : total_expenses,
          )}
        </p>
      </div>
      <div>
        <span className="text-info mr-1" style={{ fontWeight: '600' }}>
          Resultado:
        </span>
        <p className="m-0 p-0">
          {FORMATTERS.NUMBER(
            considerTransfers ? total_balance_with_transfers : total_balance,
          )}
        </p>
      </div>
    </Container>
  );
}

Summary.propTypes = {
  total_income: PropTypes.number.isRequired,
  total_expenses: PropTypes.number.isRequired,
  total_balance: PropTypes.number.isRequired,
  total_income_with_transfers: PropTypes.number.isRequired,
  total_expenses_with_transfers: PropTypes.number.isRequired,
  total_balance_with_transfers: PropTypes.number.isRequired,
  considerTransfers: PropTypes.bool.isRequired,
};

export default Summary;
