import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';

import { PageHeader } from '_components/_core';
import { BankStatementUpload, BankStatementUploadZenpaper } from './components';

export default function Import({
  onFetchBankStatementEntries,
  activeCompany,
}) {
  const product = process.env.REACT_APP_CONTABILLS_APP;

  useEffect(() => {
    onFetchBankStatementEntries();
  }, [onFetchBankStatementEntries, activeCompany]);

  const renderUpload = useCallback(() => {
    if (product === 'contabills-empresas') {
      return <BankStatementUploadZenpaper />;
    }

    return <BankStatementUpload />;
  }, [product]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Importar Extrato Bancário"
        description="Faça importação de extratos bancários no formato OFX"
        variant="small"
      />
      {renderUpload()}
    </Container>
  );
}

Import.propTypes = {
  activeCompany: PropTypes.object,
  onFetchBankStatementEntries: PropTypes.func.isRequired,
};
