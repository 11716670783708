import constants from '../../_constants/accounts.constants';
import service from '../../../services/accounts.service';

const getAccountById = (id) => {
  return (dispatch) => {
    dispatch(request(id));

    service.getById(id).then(
      (response) => {
        dispatch(success(response.data));
      },
    );
  };

  function request(id) {
    return { type: constants.ACCOUNT_GET_REQUEST, payload: { id } };
  }
  function success(account) {
    return {
      type: constants.ACCOUNT_GET_SUCCESS,
      payload: { account },
    };
  }
};

export default getAccountById;
