import { connect } from 'react-redux';

import CashflowMonth from './CashflowMonth';

const mapStateToProps = (state) => ({
  cashflow: state.companyTransactions.cashflow,
  accounts: state.companyTransactions.accounts,
  selectedAccountIds: state.companyTransactions.selected_account_ids,
});

export default connect(
  mapStateToProps,
  null,
)(CashflowMonth);
