import { ledgerConstants } from '../_constants';

const INITIAL_STATE = {
  isLoading: false,
  ledger: {},
};

export function ledger(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ledgerConstants.LEDGER_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ledgerConstants.LEDGER_GET_SUCCESS:
      return {
        ...state,
        ledger: action.payload.ledger,
        isLoading: false,
      };
    case ledgerConstants.LEDGER_GET_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ledgerConstants.LEDGER_ERASE_REQUEST:
      return {
        ...state,
        ledger: {},
        isLoading: false,
      };
    case ledgerConstants.LEDGER_ERASE_SUCCESS:
      return {
        ...state,
        ledger: {},
        isLoading: false,
      };
    case ledgerConstants.LEDGER_ERASE_FAILURE:
      return {
        ...state,
        ledger: {},
        isLoading: false,
      };
    default:
      return state;
  }
}
