import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { FaExclamationCircle, FaEye } from 'react-icons/fa';

import { Button } from '_components/_core';
import { StyledTag } from './styles';
import { LoadingIcon } from '..';

function SubscriptionAlerts({
  user,
  subscription,
  onCreateUpdatePaymentSession,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateUpdatePaymentSession = useCallback((e) => {
    e.preventDefault();

    setIsLoading(true);

    onCreateUpdatePaymentSession((session_url) => {
      const anchor = document.createElement('a');
      anchor.href = session_url;
      anchor.style.display = 'none';

      document.body.appendChild(anchor);
      anchor.click();

      setIsLoading(false);

      document.body.removeChild(anchor);
    });
  }, [onCreateUpdatePaymentSession]);

  const isManager = useMemo(() => {
    if (!user) {
      return false;
    }

    return user.manager_id === null;
  }, [user]);

  if (!subscription || !isManager) {
    return null;
  }

  if (subscription.status !== 'past_due') {
    return null;
  }

  return (
    <>
      <small className="text-muted d-flex align-items-center">
        <FaEye size="1.2em" className="mr-2" />
        Visível somente para o gerente
      </small>
      <StyledTag variant="warning" className="p-3 mt-3 mb-3">
        <div className="d-flex justify-content-center align-items-center">
          <FaExclamationCircle size="1.2em" className="mr-3 ml-2" />
          <div className="d-flex flex-column">
            <span>
              Assinatura vencida
            </span>
            {!isLoading && (
              <span className="mt-1">
                Parece que houve um problema com seu último pagamento.
                Não se preocupe, tentaremos novamente em alguns dias. <br />
                Você também pode <Button variant="link" className="m-0 p-0" onClick={handleCreateUpdatePaymentSession}>clicar aqui para atualizar sua forma de pagamento</Button> e evitar a pausa de sua assinatura.
              </span>
            )}
            {isLoading && (
              <LoadingIcon text="Redirecionando para o portal de pagamentos..." />
            )}
          </div>
        </div>
      </StyledTag>
    </>
  );
}

SubscriptionAlerts.defaultProps = {
  subscription: null,
  user: null,
};

SubscriptionAlerts.propTypes = {
  subscription: PropTypes.object,
  user: PropTypes.object,
  onCreateUpdatePaymentSession: PropTypes.func.isRequired,

};

export default SubscriptionAlerts;
