import constants from '../../_constants/company-transactions.constants';

const clearTransactionFiles = () => {
  return async (dispatch) => {
    dispatch(request());
  };

  function request() {
    return {
      type: constants.CLEAR_TRANSACTION_FILES,
      payload: {},
    };
  }
};

export default clearTransactionFiles;
