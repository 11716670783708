import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import { FaCheckCircle, FaMinusCircle, FaRegClone, FaSitemap } from 'react-icons/fa';
import {
  IoArrowDown,
  IoArrowUp,
  IoPricetags,
  IoSwapHorizontalOutline,
  IoTrashBinOutline,
} from 'react-icons/io5';
import { IoMdPeople } from 'react-icons/io';
import { HiReceiptTax } from 'react-icons/hi';
import { CgFileDocument } from 'react-icons/cg';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import pluralize from 'pluralize';
import { useHistory } from 'react-router-dom';

import checkBlockedPeriod from 'helpers/checkBlockedPeriod';
import { ContextMenu } from '_components/_core';
import useTransactions from '_components/Transactions/utilities/useTransactions';
import { PermissionsGate } from '_components/_shared';
import { hasPermissions } from '_components/_shared/PermissionsGate/utilities';

import { StyledItem, StyledSeparator, StyledSubmenu } from './styles';

pluralize.addIrregularRule('item', 'itens');

function TransactionContextMenu({
  menuId,
  transaction,
  type,
  subType,
  selectedItems,
  costsCenter,
  tags,
  allTransactions,
  onClearSelectedItems,
  onMoveTransactions,
  onUpdateMultipleTransactions,
  onApplyTagMultipleTransactions,
  blockedPeriod,
}) {
  const history = useHistory();

  const userPermissions = useSelector(
    state => state.userPermissions.permissions[state.auth.user.id],
  );

  const {
    selectedDate,
    onDeleteTransaction,
    onDeleteMultipleTransactions,
    onDuplicateTransaction,
    onDuplicateMultipleTransactions,
    onToggleTransactionPaid,
    onToggleMultipleTransactionsPaid,
    onRefreshBalance,
  } = useTransactions();

  const deletePermission = useMemo(() => {
    const permissionMap = {
      'INCOME-null': 'aba_recebimento_delete',
      'EXPENSE-FIXED_EXPENSE': 'aba_despesa_fixa_delete',
      'EXPENSE-VARIABLE_EXPENSE': 'aba_despesa_variavel_delete',
      'EXPENSE-PEOPLE': 'aba_pessoal_delete',
      'EXPENSE-TAXES': 'aba_imposto_delete',
      'TRANSFER-null': 'aba_transferencia_delete',
    };

    const permissionName = permissionMap[`${type}-${subType}`];

    return permissionName;
  }, [type, subType]);

  const createPermission = useMemo(() => {
    const permissionMap = {
      'INCOME-null': 'aba_recebimento_create',
      'EXPENSE-FIXED_EXPENSE': 'aba_despesa_fixa_create',
      'EXPENSE-VARIABLE_EXPENSE': 'aba_despesa_variavel_create',
      'EXPENSE-PEOPLE': 'aba_pessoal_create',
      'EXPENSE-TAXES': 'aba_imposto_create',
      'TRANSFER-null': 'aba_transferencia_create',
    };

    const permissionName = permissionMap[`${type}-${subType}`];

    return permissionName;
  }, [type, subType]);

  const editPermission = useMemo(() => {
    const permissionMap = {
      'INCOME-null': 'aba_recebimento_edit',
      'EXPENSE-FIXED_EXPENSE': 'aba_despesa_fixa_edit',
      'EXPENSE-VARIABLE_EXPENSE': 'aba_despesa_variavel_edit',
      'EXPENSE-PEOPLE': 'aba_pessoal_edit',
      'EXPENSE-TAXES': 'aba_imposto_edit',
      'TRANSFER-null': 'aba_transferencia_edit',
    };

    const permissionName = permissionMap[`${type}-${subType}`];

    return permissionName;
  }, [type, subType]);

  const canDelete = useMemo(() => {
    if (isEmpty(selectedItems) && !transaction) {
      return false;
    }

    if (isEmpty(selectedItems) && transaction) {
      const isAllowedPermission = hasPermissions({
        permissions: [deletePermission],
        userPermissions,
        type: 'all',
      });

      let isAllowedPeriod = checkBlockedPeriod(
        blockedPeriod,
        transaction.event_date,
        transaction.paid,
      );

      if (transaction.split) {
        const isSomeBlocked = transaction.splits.some(
          t => !checkBlockedPeriod(blockedPeriod, t.event_date, t.paid),
        );

        isAllowedPeriod = !isSomeBlocked;
      }

      return isAllowedPermission && isAllowedPeriod;
    }

    if (!isEmpty(selectedItems)) {
      const relatedTransactions =
        allTransactions.filter(t => selectedItems.includes(t.id)) || [];

      const hasChildren = relatedTransactions.some(
        transaction => transaction.splits.length > 0,
      );

      let childTransactions = [];

      if (hasChildren) {
        childTransactions = relatedTransactions.reduce(
          (acc, transaction) => [...acc, ...transaction.splits],
          [],
        );
      }

      const all = [...relatedTransactions, ...childTransactions];

      const isSomeBlocked = all.some(
        t => !checkBlockedPeriod(blockedPeriod, t.event_date, t.paid),
      );

      return (
        hasPermissions({
          permissions: [deletePermission],
          userPermissions,
          type: 'all',
        }) && !isSomeBlocked
      );
    }

    return false;
  }, [blockedPeriod, deletePermission, selectedItems, transaction, allTransactions]);

  const canEdit = useMemo(() => {
    if (isEmpty(selectedItems) && !transaction) {
      return false;
    }

    if (isEmpty(selectedItems) && transaction) {
      const isAllowedPermission = hasPermissions({
        permissions: [editPermission],
        userPermissions,
        type: 'all',
      });

      let isAllowedPeriod = checkBlockedPeriod(
        blockedPeriod,
        transaction.event_date,
        transaction.paid,
      );

      if (transaction.split) {
        const isSomeBlocked = transaction.splits.some(
          t => !checkBlockedPeriod(blockedPeriod, t.event_date, t.paid),
        );

        isAllowedPeriod = !isSomeBlocked;
      }

      return isAllowedPermission && isAllowedPeriod;
    }

    if (!isEmpty(selectedItems)) {
      const relatedTransactions =
        allTransactions.filter(t => selectedItems.includes(t.id)) || [];

      const hasChildren = relatedTransactions.some(
        transaction => transaction.splits.length > 0,
      );

      let childTransactions = [];

      if (hasChildren) {
        childTransactions = relatedTransactions.reduce(
          (acc, transaction) => [...acc, ...transaction.splits],
          [],
        );
      }

      const all = [...relatedTransactions, ...childTransactions];

      const isSomeBlocked = all.some(
        t => !checkBlockedPeriod(blockedPeriod, t.event_date, t.paid),
      );

      const allow =
        hasPermissions({
          permissions: [editPermission],
          userPermissions,
          type: 'all',
        }) && !isSomeBlocked;

      return allow;
    }

    return false;
  }, [
    blockedPeriod,
    userPermissions,
    editPermission,
    selectedItems,
    transaction,
    allTransactions,
  ]);

  const isPeriodAllowed = useMemo(() => {
    if (isEmpty(transaction)) {
      return false;
    }

    const { event_date, paid } = transaction;

    return checkBlockedPeriod(blockedPeriod, event_date, paid);
  }, [transaction, blockedPeriod]);

  const canMarkAsPaid = useMemo(() => {
    if (!isEmpty(selectedItems)) {
      const relatedTransactions = allTransactions.filter(t =>
        selectedItems.includes(t.id),
      );

      const hasChildren = relatedTransactions.some(
        transaction => transaction.splits.length > 0,
      );

      let childTransactions = [];

      if (hasChildren) {
        childTransactions = relatedTransactions.reduce(
          (acc, transaction) => [...acc, ...transaction.splits],
          [],
        );
      }

      const all = [...relatedTransactions, ...childTransactions];

      const isSomeBlocked = all.some(
        t => !checkBlockedPeriod(blockedPeriod, t.event_date),
      );

      return !isSomeBlocked;
    }

    if (!transaction) {
      return false;
    }

    return checkBlockedPeriod(blockedPeriod, transaction.event_date);
  }, [blockedPeriod, transaction, selectedItems, allTransactions]);

  const handleDuplicateTransaction = useCallback(
    type => {
      if (!isEmpty(selectedItems) && selectedItems.length > 1) {
        const params = {
          ids: selectedItems,
          type,
        };

        onDuplicateMultipleTransactions(params, () => {
          onClearSelectedItems();
        });

        return;
      }

      if (!isEmpty(selectedItems) && selectedItems.length === 1) {
        const correctTransaction = allTransactions.find(
          item => item.id === selectedItems[0],
        );

        onDuplicateTransaction(correctTransaction.id, type);

        return;
      }

      if (!transaction) {
        return;
      }

      onDuplicateTransaction(transaction.id, type);
    },
    [
      transaction,
      selectedItems,
      allTransactions,
      onClearSelectedItems,
      onDuplicateTransaction,
      onDuplicateMultipleTransactions,
    ],
  );

  const handleDeleteTransaction = useCallback(() => {
    if (!isEmpty(selectedItems) && selectedItems.length > 1) {
      const params = {
        ids: selectedItems,
      };

      onDeleteMultipleTransactions(params, () => {
        onClearSelectedItems();
      });

      return;
    }

    if (!isEmpty(selectedItems) && selectedItems.length === 1) {
      const correctTransaction = allTransactions.find(
        item => item.id === selectedItems[0],
      );

      onDeleteTransaction(correctTransaction, () => {
        onClearSelectedItems();
      });

      return;
    }

    if (!transaction) {
      return;
    }

    onDeleteTransaction(transaction);
  }, [
    transaction,
    selectedItems,
    allTransactions,
    onClearSelectedItems,
    onDeleteTransaction,
    onDeleteMultipleTransactions,
  ]);

  const handleTogglePaidTransaction = useCallback(
    paid => {
      if (!isEmpty(selectedItems)) {
        const params = {
          ids: selectedItems,
          paid,
        };

        onToggleMultipleTransactionsPaid(params, () => {
          onClearSelectedItems();
        });

        return;
      }

      if (!transaction) {
        return;
      }

      onToggleTransactionPaid(transaction.id, paid);
    },
    [
      transaction,
      selectedItems,
      onClearSelectedItems,
      onToggleTransactionPaid,
      onToggleMultipleTransactionsPaid,
    ],
  );

  const handleApplyCostCenter = useCallback(
    item => {
      if (item.redirect) {
        history.push(item.redirect);

        return;
      }

      if (!isEmpty(selectedItems)) {
        const splitTransactions = allTransactions
          .filter(t => selectedItems.includes(t.id))
          .filter(t => t.split);

        const childSplitsIds = splitTransactions
          .map(t => t.splits)
          .flat()
          .map(split => split.id);

        const params = {
          ids: uniq([...selectedItems, ...childSplitsIds]),
          cost_center_id: item.id,
        };

        onUpdateMultipleTransactions(params, () => {
          onClearSelectedItems();
        });

        return;
      }

      if (!transaction) {
        return;
      }

      let finalIds = [];

      if (transaction.split) {
        finalIds = [transaction.id, ...transaction.splits.map(split => split.id)];
      } else {
        finalIds = [transaction.id];
      }

      const params = {
        ids: finalIds,
        cost_center_id: item.id,
      };

      onUpdateMultipleTransactions(params, () => {
        onClearSelectedItems();
      });
    },
    [
      transaction,
      selectedItems,
      history,
      allTransactions,
      onUpdateMultipleTransactions,
      onClearSelectedItems,
    ],
  );

  const handleApplyTag = useCallback(
    item => {
      if (item.redirect) {
        history.push(item.redirect);

        return;
      }

      if (!isEmpty(selectedItems)) {
        const transaction_splits = allTransactions
          .filter(t => selectedItems.includes(t.id))
          .map(t => ({
            transaction_id: t.id,
            split: t.split,
            split_ids: t.splits.map(split => split.id),
          }));

        const params = {
          transaction_ids: selectedItems,
          tag_id: item.id,
          transaction_splits,
        };

        onApplyTagMultipleTransactions(params);

        return;
      }

      if (!transaction) {
        return;
      }

      const transaction_splits = [
        {
          transaction_id: transaction.id,
          split: transaction.split,
          split_ids: transaction.splits.map(split => split.id),
        },
      ];

      const params = {
        transaction_id: transaction.id,
        tag_id: item.id,
        transaction_splits,
      };

      onApplyTagMultipleTransactions(params);
    },
    [
      transaction,
      allTransactions,
      selectedItems,
      history,
      onApplyTagMultipleTransactions,
    ],
  );

  const handleMoveTransactions = useCallback(
    item => {
      if (!isEmpty(selectedItems)) {
        const params = {
          ids: selectedItems,
          type: item.type,
          sub_type: item.subType,
          sourceType: type,
          sourceSubType: subType,
        };

        onMoveTransactions(params, () => {
          onClearSelectedItems();

          onRefreshBalance();
        });

        return;
      }

      if (!transaction) {
        return;
      }

      const params = {
        ids: [transaction.id],
        type: item.type,
        sub_type: item.subType,
        sourceType: type,
        sourceSubType: subType,
      };

      onMoveTransactions(params, () => {
        onClearSelectedItems();

        onRefreshBalance();
      });
    },
    [
      transaction,
      selectedItems,
      onMoveTransactions,
      type,
      subType,
      onRefreshBalance,
      onClearSelectedItems,
    ],
  );

  const duplicateMessage = useMemo(() => {
    if (isEmpty(selectedItems)) {
      return 'Duplicar ...';
    }

    const { length } = selectedItems;

    return `Duplicar ${length} ${pluralize('item', length)}`;
  }, [selectedItems]);

  const deleteMessage = useMemo(() => {
    if (isEmpty(selectedItems)) {
      return 'Excluir';
    }

    const { length } = selectedItems;

    return `Excluir ${length} ${pluralize('item', length)}`;
  }, [selectedItems]);

  const togglePaidMessage = useMemo(() => {
    if (isEmpty(selectedItems)) {
      return 'Marcar como ...';
    }

    const { length } = selectedItems;

    return `Marcar ${length} ${pluralize('item', length)} como ...`;
  }, [selectedItems]);

  const moveItemsMessage = useMemo(() => {
    if (isEmpty(selectedItems)) {
      return 'Mover para ...';
    }

    const { length } = selectedItems;

    return `Mover ${length} ${pluralize('item', length)} para ...`;
  }, [selectedItems]);

  const moveMenuItems = useMemo(() => {
    const items = [
      {
        icon: <IoArrowUp className="mr-2 text-success" />,
        label: 'Receitas',
        id: 'ctx-menu-move-income',
        type: 'INCOME',
        subType: null,
        permission: 'aba_recebimento_create',
      },
      {
        icon: <IoArrowDown className="mr-2 text-danger" />,
        label: 'Despesas fixas',
        id: 'ctx-menu-move-fixed-expense',
        type: 'EXPENSE',
        subType: 'FIXED_EXPENSE',
        permission: 'aba_despesa_fixa_create',
      },
      {
        icon: <IoArrowDown className="mr-2 text-danger" />,
        label: 'Despesas variáveis',
        id: 'ctx-menu-move-variable-expense',
        type: 'EXPENSE',
        subType: 'VARIABLE_EXPENSE',
        permission: 'aba_despesa_variavel_create',
      },
      {
        icon: <IoMdPeople className="mr-2 text-danger" />,
        label: 'Pessoas',
        id: 'ctx-menu-move-people',
        type: 'EXPENSE',
        subType: 'PEOPLE',
        permission: 'aba_pessoal_create',
      },
      {
        icon: <HiReceiptTax className="mr-2 text-danger" />,
        label: 'Impostos',
        id: 'ctx-menu-move-taxes',
        type: 'EXPENSE',
        subType: 'TAXES',
        permission: 'aba_imposto_create',
      },
    ];

    const availableItems = items.filter(
      item => item.type !== type || item.subType !== subType,
    );

    return availableItems;
  }, [type, subType]);

  const costsCenterMenuItems = useMemo(() => {
    if (isEmpty(costsCenter)) {
      return [
        { label: 'Nenhum item encontrado', id: null, disabled: true },
        {
          id: 'redirect',
          redirect: '/centros-de-custo',
          label: 'Gerenciar Centros de Custo',
        },
      ];
    }

    const sortedCostsCenter = sortBy(costsCenter, 'description');

    const items = [
      {
        id: null,
        label: (
          <span className="text-muted">
            <em>Nenhum</em>
          </span>
        ),
      },
      ...sortedCostsCenter.map(costCenter => ({
        label: costCenter.description,
        id: costCenter.id,
      })),
      {
        id: 'redirect',
        redirect: '/centros-de-custo',
        label: 'Gerenciar Centros de Custo',
      },
    ];

    return items;
  }, [costsCenter]);

  const tagsMenuItems = useMemo(() => {
    if (isEmpty(tags)) {
      return [
        { label: 'Nenhum item encontrado', id: null, disabled: true },
        {
          id: 'redirect',
          redirect: '/tags',
          label: 'Gerenciar Tags',
        },
      ];
    }

    const sortedTags = sortBy(tags, 'description');

    const items = [
      ...sortedTags.map(tag => ({
        label: tag.description,
        id: tag.id,
      })),
      {
        id: 'redirect',
        redirect: '/tags',
        label: 'Gerenciar Tags',
      },
    ];

    return items;
  }, [tags]);

  const renderReceiptMenu = useCallback(() => {
    if (type === 'TRANSFER') {
      return null;
    }

    let receiptUrl = '/recibo?transaction_id=@transactionId';

    if (selectedItems.length > 1) {
      return null;
    }

    if (isEmpty(selectedItems) && !transaction) {
      return null;
    }

    if (!isEmpty(selectedItems)) {
      const [firstTransactionId] = selectedItems;

      receiptUrl = receiptUrl.replace('@transactionId', firstTransactionId);
    }

    if (transaction) {
      receiptUrl = receiptUrl.replace('@transactionId', transaction.id);
    }

    return (
      <StyledItem onClick={() => history.push(receiptUrl)}>
        <span className="d-flex justify-content-center align-items-center">
          <CgFileDocument size="1.1em" className="mr-2" />
          Gerar Recibo
        </span>
      </StyledItem>
    );
  }, [selectedItems, history, transaction, type]);

  return (
    <ContextMenu menuId={menuId}>
      <PermissionsGate permissions={[createPermission]} type="all">
        <StyledSubmenu
          label={
            <span className="d-flex justify-content-center align-items-center">
              <FaRegClone size="1.1em" className="mr-2" />
              {duplicateMessage}
            </span>
          }
          id="ctx-menu-duplicate"
        >
          {isPeriodAllowed && (
            <StyledItem
              id="ctx-menu-duplicate-current"
              onClick={() => handleDuplicateTransaction('current_month')}
            >
              No mês atual
            </StyledItem>
          )}
          <StyledItem
            id="ctx-menu-duplicate-next"
            onClick={() => handleDuplicateTransaction('next_month')}
          >
            No próx. mês
          </StyledItem>
        </StyledSubmenu>
      </PermissionsGate>
      {canEdit && (
        <>
          {canMarkAsPaid && (
            <StyledSubmenu
              id="ctx-menu-toggle-paid"
              label={
                <span className="d-flex justify-content-center align-items-center">
                  <FaCheckCircle size="1.1em" className="mr-2" />
                  {togglePaidMessage}
                </span>
              }
            >
              <StyledItem
                id="ctx-menu-toggle-paid-paid"
                onClick={() => handleTogglePaidTransaction(true)}
              >
                <FaCheckCircle size="1.1em" className="mr-2 text-success" />
                Pago
              </StyledItem>
              <StyledItem
                id="ctx-menu-toggle-paid-unpaid"
                onClick={() => handleTogglePaidTransaction(false)}
              >
                <FaMinusCircle size="1.1em" className="mr-2 text-danger" />
                Não pago
              </StyledItem>
            </StyledSubmenu>
          )}
          {type !== 'TRANSFER' && (
            <StyledSubmenu
              id="ctx-menu-move"
              label={
                <span className="d-flex justify-content-center align-items-center">
                  <IoSwapHorizontalOutline size="1.1em" className="mr-2" />
                  {moveItemsMessage}
                </span>
              }
            >
              {moveMenuItems.map(item => (
                <PermissionsGate permissions={[item.permission]} type="all">
                  <StyledItem id={item.id} onClick={() => handleMoveTransactions(item)}>
                    {item.icon}
                    {item.label}
                  </StyledItem>
                </PermissionsGate>
              ))}
            </StyledSubmenu>
          )}
          {type !== 'TRANSFER' && <StyledSeparator />}
          {type !== 'TRANSFER' && (
            <StyledSubmenu
              id="ctx-menu-tag"
              label={
                <span className="d-flex mr-5 justify-content-center align-items-center">
                  <IoPricetags size="1.1em" className="mr-2" />
                  Aplicar Tags ...
                </span>
              }
            >
              {tagsMenuItems.map(item => (
                <StyledItem
                  isRedirect={item.redirect}
                  disabled={item.disabled}
                  onClick={() => handleApplyTag(item)}
                >
                  {item.label}
                  {transaction &&
                    !isEmpty(transaction.tags) &&
                    transaction.tags.find(t => t.id === item.id) && (
                      <FaCheckCircle className="ml-2 text-success" />
                    )}
                </StyledItem>
              ))}
            </StyledSubmenu>
          )}
          {type !== 'TRANSFER' && (
            <StyledSubmenu
              id="ctx-menu-cost-center"
              label={
                <span className="d-flex mr-3 justify-content-center align-items-center">
                  <FaSitemap size="1.1em" className="mr-2" />
                  Aplicar Centro de Custo
                </span>
              }
            >
              {costsCenterMenuItems.map(item => (
                <StyledItem
                  isRedirect={item.redirect}
                  disabled={item.disabled}
                  onClick={() => handleApplyCostCenter(item)}
                >
                  {item.label}
                  {transaction &&
                    transaction.cost_center &&
                    transaction.cost_center.id &&
                    transaction.cost_center.id === item.id && (
                      <FaCheckCircle className="ml-2 text-success" />
                    )}
                </StyledItem>
              ))}
            </StyledSubmenu>
          )}
        </>
      )}
      {renderReceiptMenu()}
      {canDelete && (
        <>
          <StyledSeparator />
          <StyledItem id="ctx-menu-delete" onClick={() => handleDeleteTransaction()}>
            <span className="d-flex justify-content-center align-items-center">
              <IoTrashBinOutline size="1.1em" className="mr-2 text-danger" />
              {deleteMessage}
            </span>
          </StyledItem>
        </>
      )}
    </ContextMenu>
  );
}

TransactionContextMenu.defaultProps = {
  transaction: null,
  selectedItems: [],
  costsCenter: [],
  tags: [],
  allTransactions: [],
  blockedPeriod: {},
};

TransactionContextMenu.propTypes = {
  menuId: PropTypes.string.isRequired,
  transaction: PropTypes.object,
  costsCenter: PropTypes.array,
  tags: PropTypes.array,
  type: PropTypes.string.isRequired,
  subType: PropTypes.string,
  selectedItems: PropTypes.array,
  allTransactions: PropTypes.array,
  onClearSelectedItems: PropTypes.func.isRequired,
  onMoveTransactions: PropTypes.func.isRequired,
  onUpdateMultipleTransactions: PropTypes.func.isRequired,
  onApplyTagMultipleTransactions: PropTypes.func.isRequired,
  blockedPeriod: PropTypes.object,
};

export default TransactionContextMenu;
