import styled from 'styled-components';

export const Container = styled.td`
  padding-bottom: 7px !important;
  padding-top: 7px !important;
  padding-left: ${(props) => (props.paddingLeft ? '15px' : '0px')} !important;
  border-top: 1px solid var(--td-border-color) !important;
  text-align: ${(props) => (props.textCenter ? 'center' : 'left')} !important;
  height: 32px;
`;
