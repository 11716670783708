import confirmDialog from 'helpers/confirmDialog';
import constants from '../../_constants/payroll.constants';
import service from '../../../services/payroll.service';

import alertActionCreators from '../alert.actions';

const deletePayroll = (params, callback) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Excluir Folha de Pagamento',
      message: 'Tem certeza que deseja excluir todos os eventos da Folha de Pagamento? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, deletar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(params));

        service.deletePayroll(params).then(() => {
          dispatch(success(params));
          dispatch(alertActionCreators.success('Folha de Pagamento excluída com sucesso.'));

          if (callback) {
            callback();
          }
        }).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request() {
    return {
      type: constants.DELETE_PAYROLL_REQUEST,
      payload: { },
    };
  }
  function success(data) {
    return {
      type: constants.DELETE_PAYROLL_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_PAYROLL_FAILURE,
      payload: error,
    };
  }
};

export default deletePayroll;
