import React from 'react';
import PropTypes from 'prop-types';

import UpdateEntriesHistory from '_components/_shared/Actions/UpdateEntriesHistory/UpdateEntriesHistory';

function CustomUpdateEntriesHistory({
  isLoading,
  selectedItems,
  onUpdateMany,
  onComplete,
}) {
  const handleUpdateNarrations = (params, callback) => {
    onUpdateMany(params, () => {
      if (callback) {
        callback();
      }
    });

    if (onComplete) {
      onComplete();
    }
  };

  return (
    <UpdateEntriesHistory
      isLoading={isLoading}
      selectedItems={selectedItems}
      onUpdateEntries={handleUpdateNarrations}
    />
  );
}

CustomUpdateEntriesHistory.defaultProps = {
  onComplete: null,
  isLoading: false,
};

CustomUpdateEntriesHistory.propTypes = {
  selectedItems: PropTypes.array,
  onUpdateMany: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default CustomUpdateEntriesHistory;
