import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import {
  floor,
  isEmpty,
  round,
  uniq,
} from 'lodash';
import { DateTime } from 'luxon';

function useParcelamento({
  valorTotalInicial,
  tipoValorInicial,
  qtdeParcelasInicial,
  frequenciaInicial,
  dataInicial,
  parcelasIniciais,
  onChangeFormValid,
}) {
  const [isMounted, setIsMounted] = useState(false);

  const [valorTotal, setValorTotal] = useState(valorTotalInicial);
  const [tipoValor, setTipoValor] = useState(tipoValorInicial);
  const [qtdeParcelas, setQtdeParcelas] = useState(qtdeParcelasInicial);
  const [frequencia, setFrequencia] = useState(frequenciaInicial);

  const [parcelasCalculadas, setParcelasCalculadas] = useState(parcelasIniciais);

  const parcelasValidas = useMemo(() => parcelasCalculadas.filter(
    (parcela) => !parcela.destroyed,
  ), [parcelasCalculadas]);

  const valorTotalCalculado = useMemo(() => {
    if (tipoValor === 'INSTALMENT_AMOUNT') {
      return round(valorTotal * qtdeParcelas, 2);
    }

    return round(valorTotal, 2);
  }, [valorTotal, tipoValor, qtdeParcelas]);

  const valorTotalParcelasCalculadas = useMemo(() => {
    const parcelasValidas = parcelasCalculadas.filter(
      (parcela) => !parcela.destroyed,
    );

    const valorTotalParcelas = parcelasValidas.reduce(
      (total, parcela) => total + parcela.amount,
      0,
    );

    return round(valorTotalParcelas, 2);
  }, [parcelasCalculadas]);

  const parcelasEditadasValor = useMemo(
    () => parcelasCalculadas.filter(
      (parcela) => !parcela.destroyed && (parcela.hasChangeAmount || parcela.paid),
    ),
    [parcelasCalculadas],
  );

  const idsParcelasEditadasValor = useMemo(
    () => parcelasEditadasValor.map(
      (item) => item.frequency_number,
    ),
    [parcelasEditadasValor],
  );

  const hasValoresDiferentes = useMemo(
    () => valorTotalParcelasCalculadas !== valorTotalCalculado,
    [valorTotalParcelasCalculadas, valorTotalCalculado],
  );

  const parcelasEditadasData = useMemo(
    () => parcelasCalculadas.filter(
      (parcela) => parcela.hasChangeDate && !parcela.destroyed,
    ),
    [parcelasCalculadas],
  );

  const hasOnlyParcelasEditadas = useMemo(
    () => parcelasCalculadas.length === parcelasEditadasValor.length,
    [parcelasCalculadas, parcelasEditadasValor],
  );

  const renderSumWarningFor = useMemo(() => {
    if (!isEmpty(parcelasEditadasValor) && hasValoresDiferentes && !hasOnlyParcelasEditadas) {
      const idsParcelasValoresInvalidos = parcelasCalculadas.filter(
        (parcela) => !idsParcelasEditadasValor.includes(parcela.frequency_number) && !parcela.paid,
      );

      return idsParcelasValoresInvalidos.map((item) => item.frequency_number);
    }

    return [];
  }, [
    hasOnlyParcelasEditadas,
    parcelasEditadasValor,
    parcelasCalculadas,
    hasValoresDiferentes,
    idsParcelasEditadasValor,
  ]);

  const renderRecalcWarningFor = useMemo(() => {
    const hasValoresEditados = !isEmpty(parcelasEditadasValor);
    const hasValoresDiferentes = valorTotalParcelasCalculadas !== valorTotalCalculado;

    const idsParcelasEditadas = parcelasEditadasValor.map((item) => item.frequency_number);

    if (hasValoresEditados && hasValoresDiferentes && !hasOnlyParcelasEditadas) {
      return idsParcelasEditadas;
    }

    return [];
  }, [
    hasOnlyParcelasEditadas,
    parcelasEditadasValor,
    valorTotalParcelasCalculadas,
    valorTotalCalculado,
  ]);

  const renderRecalcTotalWarning = useMemo(() => {
    if (hasValoresDiferentes) {
      return true;
    }

    return false;
  }, [hasValoresDiferentes]);

  useEffect(() => {
    if (onChangeFormValid) {
      onChangeFormValid(!hasValoresDiferentes);
    }
  }, [hasValoresDiferentes, onChangeFormValid]);

  // Gerar Parcelas Iniciais
  useEffect(() => {
    if (!isMounted) {
      const novasParcelas = calcularParcelas(
        valorTotal, tipoValor, qtdeParcelas, frequencia, dataInicial,
      );

      setParcelasCalculadas(novasParcelas);

      setIsMounted(true);
    }
  }, [isMounted, valorTotal, tipoValor, qtdeParcelas, frequencia, dataInicial]);

  // Utilitários
  const parcelaPodeSerEditada = (parcela) => ![
    parcela.destroyed,
    parcela.paid,
    parcela.hasChange,
  ].includes(true);

  const parcelaPodeSerEditadaData = (parcela) => ![
    parcela.destroyed,
    parcela.paid,
    parcela.hasChangeDate,
  ].includes(true);

  const calcularNovaData = (parcela, nova_frequencia, data_referencia) => {
    let nova_data = DateTime.fromISO(data_referencia, { zone: 'utc' });

    const { frequency_number: frequency_number_original } = parcela;

    const frequency_number = frequency_number_original - 1;

    nova_data = nova_data
      .plus({ weeks: nova_frequencia === 'WEEKLY' ? (1 * frequency_number) : 0 })
      .plus({ days: nova_frequencia === 'BIWEEKLY' ? (15 * frequency_number) : 0 })
      .plus({ months: nova_frequencia === 'MONTHLY' ? (1 * frequency_number) : 0 })
      .plus({ months: nova_frequencia === 'BIMONTHLY' ? (2 * frequency_number) : 0 })
      .plus({ months: nova_frequencia === 'QUARTERLY' ? (3 * frequency_number) : 0 })
      .plus({ months: nova_frequencia === 'BIANNUAL' ? (6 * frequency_number) : 0 })
      .plus({ years: nova_frequencia === 'ANNUAL' ? (1 * frequency_number) : 0 });

    return nova_data.toFormat('yyyy-MM-dd');
  };

  // Verificar se ainda precisa desse, ou se iremos usar a lógica do isMounted
  const handleCreateDefaultParcelas = () => {
    const novasParcelas = calcularParcelas(
      valorTotal, tipoValor, qtdeParcelas, frequencia, dataInicial,
    );

    setParcelasCalculadas(novasParcelas);
  };

  const handleCreateExistingParcelas = (child_transactions) => {
    const total = child_transactions.reduce((acc, curr) => acc + curr.amount, 0);
    const instalment_count = child_transactions.length;

    const parcelas = child_transactions.map((item) => ({
      frequency_number: item.frequency_number,
      amount: item.amount,
      event_date: item.event_date,
      paid: item.paid,
      id: item.id,
      split: item.split,
    }));

    setParcelasCalculadas(parcelas);
    setQtdeParcelas(instalment_count);
    setValorTotal(total);
    setTipoValor('TOTAL_AMOUNT');
  };

  // Formulário de cima COMEÇA AQUI

  const handleChangeTipoValor = (tipo_valor) => {
    setTipoValor(tipo_valor);

    const novasParcelas = calcularParcelas(
      valorTotal, tipo_valor, qtdeParcelas, frequencia, dataInicial,
    );

    setParcelasCalculadas(novasParcelas);
  };

  const handleChangeValor = (valor_total) => {
    setValorTotal(valor_total);

    const novasParcelas = calcularParcelas(
      valor_total, tipoValor, qtdeParcelas, frequencia, dataInicial,
    );

    setParcelasCalculadas(novasParcelas);
  };

  const handleChangeQtdeParcelas = ({ value }) => {
    setQtdeParcelas(Number(value));

    const valorTotalEditado = parcelasEditadasValor.reduce(
      (acc, parcela) => acc + parcela.amount,
      0,
    );

    const qtdeParcelasEditadas = parcelasEditadasValor.length;

    // Cálculo do novo valor das parcelas não editadas
    let valorTotalRestante = valorTotal - valorTotalEditado;
    let qtdeParcelasRestantes = Number(value) - qtdeParcelasEditadas;

    if (Number(value) === qtdeParcelasEditadas) {
      valorTotalRestante = valorTotal;
      qtdeParcelasRestantes = Number(value);
    }

    const novasParcelas = calcularParcelas(
      valorTotalRestante,
      tipoValor,
      qtdeParcelasRestantes,
      frequencia,
      dataInicial,
    );

    if (Number(value) === qtdeParcelasEditadas) {
      novasParcelas.forEach((parcela, index) => {
        if (parcelaPodeSerEditada(parcelasEditadasValor[index])) {
          parcela.event_date = parcelasEditadasValor[index].event_date;
          parcela.paid = parcelasEditadasValor[index].paid;
        } else {
          parcela.amount = parcelasEditadasValor[index].amount;
          parcela.event_date = parcelasEditadasValor[index].event_date;
          parcela.paid = parcelasEditadasValor[index].paid;
        }
      });

      setParcelasCalculadas(novasParcelas);

      return;
    }

    const novasParcelasCalculadas = [];

    let contadorNovasParcelas = 0;

    const [primeiraParcela] = parcelasCalculadas;

    for (let i = 1; i <= Number(value); i += 1) {
      const parcela = parcelasCalculadas.find(
        (item) => item.frequency_number === i,
      );

      if (parcela && parcelaPodeSerEditada(parcela)) {
        novasParcelasCalculadas.push({
          ...parcela,
          amount: novasParcelas[contadorNovasParcelas].amount,
          event_date: calcularNovaData(
            novasParcelas[contadorNovasParcelas],
            frequencia,
            primeiraParcela.event_date,
          ),
        });

        contadorNovasParcelas++;
      } else if (parcela && !parcelaPodeSerEditada(parcela)) {
        novasParcelasCalculadas.push(parcela);
      } else {
        novasParcelasCalculadas.push({
          ...novasParcelas[contadorNovasParcelas],
          frequency_number: i,
          event_date: calcularNovaData(
            novasParcelas[contadorNovasParcelas],
            frequencia,
            primeiraParcela.event_date,
          ),
        });

        contadorNovasParcelas++;
      }
    }

    setParcelasCalculadas(novasParcelasCalculadas);

    handleChangeFrequencia({ value: frequencia });
  };

  // OK
  const handleChangeFrequencia = useCallback(({ value: nova_frequencia }) => {
    setFrequencia(nova_frequencia);

    setParcelasCalculadas((prev) => {
      const parcelasAtualizadas = prev.map((parcela) => {
        if (!parcelaPodeSerEditadaData(parcela)) {
          return parcela;
        }

        const [primeiraParcela] = parcelasCalculadas;

        const novaParcela = {
          ...parcela,
          event_date: calcularNovaData(parcela, nova_frequencia, primeiraParcela.event_date),
        };

        return novaParcela;
      });

      return parcelasAtualizadas;
    });
  }, [parcelasCalculadas]);

  // Formulário de cima CONCLUÍDO

  const handleChangeParcela = useCallback((parcela, changedValues = {}) => {
    setParcelasCalculadas((prev) => {
      const novasParcelasCalculadas = prev.map((item) => {
        if (item.frequency_number === parcela.frequency_number) {
          const novaParcela = {
            ...item,
            ...parcela,
            hasChange: true,
          };

          if (changedValues.amount) {
            novaParcela.hasChangeAmount = true;
          }

          if (changedValues.event_date) {
            novaParcela.hasChangeDate = true;
          }

          return novaParcela;
        }

        return item;
      });

      return novasParcelasCalculadas;
    });
  }, []);

  const handleDeleteParcela = useCallback((parcela) => {
    setParcelasCalculadas((prev) => {
      const novaLista = prev.map((item) => {
        if (item.frequency_number === parcela.frequency_number) {
          return {
            ...item,
            destroyed: true,
          };
        }

        return item;
      });

      return novaLista;
    });
  }, []);

  const handleUpdateFrequencyNumbers = useCallback(() => {
    setParcelasCalculadas((prev) => {
      const novaLista = prev.map((item, index) => ({
        ...item,
        frequency_number: index + 1,
      }));

      return novaLista;
    });
  }, []);

  const handleClearDeletedParcelas = useCallback(() => {
    setParcelasCalculadas((prev) => {
      const novaLista = prev.filter((item) => !item.destroyed);

      setQtdeParcelas(novaLista.length);

      return novaLista;
    });

    handleUpdateFrequencyNumbers();
  }, [handleUpdateFrequencyNumbers]);

  const handleRecalcularParcelas = useCallback(() => {
    const idsParcelasEditadas = parcelasEditadasValor.filter((item) => !item.destroyed)
      .map((item) => item.frequency_number);

    const idsParcelasPagas = parcelasCalculadas.filter(
      (item) => item.paid && !item.destroyed,
    ).map((item) => item.frequency_number);

    const allIds = uniq([...idsParcelasEditadas, ...idsParcelasPagas]);

    const allParcelas = parcelasCalculadas.filter(
      (item) => allIds.includes(item.frequency_number),
    );

    const valorTotalEditado = allParcelas.reduce(
      (total, parcela) => total + parcela.amount,
      0,
    );
    const qtdeParcelasEditadas = allParcelas.length;
    const qtdeParcelasExcluidas = parcelasCalculadas.filter(
      (item) => item.destroyed,
    ).length;

    // Cálculo do novo valor das parcelas não editadas
    const valorTotalRestante = valorTotal - valorTotalEditado;
    const qtdeParcelasRestantes = qtdeParcelas - qtdeParcelasEditadas - qtdeParcelasExcluidas;

    // TODO
    const [primeiraParcela] = parcelasCalculadas;

    const novasParcelas = calcularParcelas(
      valorTotalRestante,
      tipoValor,
      qtdeParcelasRestantes,
      frequencia,
      primeiraParcela.event_date,
    );

    let parcelasQueSeraoAlteradas = parcelasCalculadas.filter(
      (item) => !allIds.includes(item.frequency_number) && !item.destroyed,
    );

    parcelasQueSeraoAlteradas = parcelasQueSeraoAlteradas.map((parcela, index) => {
      const novaParcela = novasParcelas[index];

      parcela.amount = novaParcela.amount < 0 ? 0 : novaParcela.amount;
      parcela.destroyed = false;

      return parcela;
    });

    // Aplica o novo valor nas parcelas não editadas
    setParcelasCalculadas((prev) => {
      const novaLista = prev.map((item) => {
        if (!parcelasQueSeraoAlteradas.includes(item.frequency_number)) {
          return item;
        }

        const novaParcela = parcelasQueSeraoAlteradas.find(
          (parcela) => parcela.frequency_number === item.frequency_number,
        );

        return {
          ...item,
          amount: novaParcela.amount,
        };
      });

      return novaLista;
    });
  }, [
    parcelasCalculadas,
    parcelasEditadasValor,
    valorTotal,
    tipoValor,
    qtdeParcelas,
    frequencia,
  ]);

  const handleRecalcularTotal = useCallback(() => {
    const total = parcelasValidas.reduce((total, parcela) => total + parcela.amount, 0);

    setValorTotal(total);
  }, [parcelasValidas]);

  return {
    tipoValor,
    tipoValorDescricao: tipoValor === 'TOTAL_AMOUNT' ? 'Valor Total' : 'Valor de parcela',
    tipoValorAjuda: tipoValor === 'TOTAL_AMOUNT' ? 'Valor total a ser parcelado' : 'Valor de parcela',
    valorTotal,
    qtdeParcelas,
    frequencia,

    valorTotalCalculado,

    // Parcelas geradas que são exibidas
    parcelasCalculadas,
    parcelasEditadasData,
    parcelasEditadasValor,

    // Altera formulário principal
    onChangeValor: handleChangeValor,
    onChangeTipoValor: handleChangeTipoValor,
    onChangeQtdeParcelas: handleChangeQtdeParcelas,
    onChangeFrequencia: handleChangeFrequencia,

    // Validations
    renderSumWarningFor,
    renderRecalcWarningFor,
    renderRecalcTotalWarning,

    // Altera parcelas
    onCreateDefaultParcelas: handleCreateDefaultParcelas,
    onCreateExistingParcelas: handleCreateExistingParcelas,
    onChangeParcela: handleChangeParcela,
    onRecalcularParcelas: handleRecalcularParcelas,
    onRecalcularTotal: handleRecalcularTotal,
    onDeleteParcela: handleDeleteParcela,
    onClearDeletedParcelas: handleClearDeletedParcelas,
  };
}

function calcularParcelas(
  valorTotal,
  tipoValor,
  qtdeParcelas,
  frequencia,
  dataInicial,
  options = {},
) {
  const parcelas = [];

  let valorParcela = 0;
  let valorUltimaParcela = 0;

  if (tipoValor === 'INSTALMENT_AMOUNT') {
    valorParcela = valorTotal;
    valorUltimaParcela = valorTotal;
  } else {
    valorParcela = floor(valorTotal / qtdeParcelas, 2);
    valorUltimaParcela = valorTotal - (valorParcela * (qtdeParcelas - 1));
  }

  let data = DateTime.fromISO(dataInicial, { zone: 'utc' });

  const { availableFrequencyNumbers } = options || {};

  if (availableFrequencyNumbers) {
    availableFrequencyNumbers.forEach((frequencyNumber) => {
      parcelas.push({
        frequency_number: frequencyNumber,
        event_date: data.toFormat('yyyy-MM-dd'),
        amount: frequencyNumber === qtdeParcelas ? valorUltimaParcela : valorParcela,
        paid: false,
      });

      data = data
        .plus({ weeks: frequencia === 'WEEKLY' ? 1 : 0 })
        .plus({ weeks: frequencia === 'BIWEEKLY' ? 2 : 0 })
        .plus({ months: frequencia === 'MONTHLY' ? 1 : 0 })
        .plus({ months: frequencia === 'BIMONTHLY' ? 2 : 0 })
        .plus({ months: frequencia === 'QUARTERLY' ? 3 : 0 })
        .plus({ months: frequencia === 'BIANNUAL' ? 6 : 0 })
        .plus({ years: frequencia === 'YEARLY' ? 1 : 0 });
    });
  } else {
    for (let i = 0; i < qtdeParcelas; i++) {
      parcelas.push({
        frequency_number: i + 1,
        event_date: data.toFormat('yyyy-MM-dd'),
        amount: i === qtdeParcelas - 1 ? valorUltimaParcela : valorParcela,
        paid: false,
      });

      data = data
        .plus({ weeks: frequencia === 'WEEKLY' ? 1 : 0 })
        .plus({ weeks: frequencia === 'BIWEEKLY' ? 2 : 0 })
        .plus({ months: frequencia === 'MONTHLY' ? 1 : 0 })
        .plus({ months: frequencia === 'BIMONTHLY' ? 2 : 0 })
        .plus({ months: frequencia === 'QUARTERLY' ? 3 : 0 })
        .plus({ months: frequencia === 'BIANNUAL' ? 6 : 0 })
        .plus({ years: frequencia === 'ANNUAL' ? 1 : 0 });
    }
  }

  return parcelas;
}

export default useParcelamento;
