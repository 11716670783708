import styled, { css } from 'styled-components';
import Alert from 'react-bootstrap/Alert';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';

import { Button } from '_components/_core';

export const Container = styled.div`


  @media (max-width: 768px) {
    display: none;
  }
`;

export const Content = styled.span`
  ${({ isManager }) => isManager && css`
    &:hover {      
      text-decoration: underline;
      cursor: pointer;      
    }  
  `}
`;

export const StyledAlert = styled(Alert)`
  font-weight: bold;
  margin: 0;
  cursor: pointer;
  padding: 6px 25px;
`;

export const StyledTitle = styled.div`
  color: #354168;
  font-size: 12px;
  display: flex;
`;

export const StyledSubscription = styled.div`
  color: #354168;
  font-size: 19px;
  font-weight: bold;
`;

export const StyledFooter = styled.div`
  color: #354168;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledTooltip = styled(Tooltip)`
  z-index: 1031;

  div.arrow {

    &:before {
      background: yellow !important;
    }
  }
`;

export const StyledPopover = styled(Popover)`
  z-index: 1031;
  border: 2px solid #6F66E8;
  border-radius: 8px;
  box-shadow: 0 10px 6px -6px #C8C8C8;

  &.popover {
    max-width: 700px !important;
  }

  &.popover, .popover-body {
    background-color: #FFFFFF !important;
    border-radius: 8px;
    z-index: 1031;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 16px 36px;
  }

  &.bs-popover-bottom, .arrow::before, .bs-popover-auto[x-placement^="bottom"], .arrow::after {
    border-bottom-color: #6F66E8;
  }

  &.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 2px;
    border-bottom-color: #FFFFFF;
  }
`;

export const StyledButton = styled(Button)`
  font-weight: 500;
  font-size: 12px;
  padding: 0 0 0 8px;
  margin: 0;
`;
