import { stringDateRemoveHours } from 'helpers';

const formatData = (taxes, selectedMonth, taxRegime) => {
  const formattedMonth = selectedMonth.startOf('month').toFormat('yyyy-MM-dd');

  const month_taxes = taxes.find(
    (item) => stringDateRemoveHours(item.date_competence) === formattedMonth,
  ) || {};

  const {
    pis_pasep_due,
    pis_pasep_owed,
    cofins_due,
    cofins_owed,
    irpj_due,
    irpj_owed,
    csll_due,
    csll_owed,
    iss_due,
    iss_owed,
    cpp_owed,
  } = month_taxes;

  const data = [];

  if (taxRegime === 'LUCRO_PRESUMIDO') {
    data.push({ competence: formattedMonth, tax_name: 'PIS à Recolher', amount_due: pis_pasep_due || 0 });
    data.push({ competence: formattedMonth, tax_name: 'COFINS à Recolher', amount_due: cofins_due || 0 });
    data.push({ competence: formattedMonth, tax_name: 'IRPJ à Recolher', amount_due: irpj_due || 0 });
    data.push({ competence: formattedMonth, tax_name: 'CSLL à Recolher', amount_due: csll_due || 0 });
    data.push({ competence: formattedMonth, tax_name: 'ISS à Recolher', amount_due: iss_due || 0 });
  } else if (taxRegime === 'SIMPLES_NACIONAL') {
    data.push({ competence: formattedMonth, tax_name: 'PIS', amount_due: pis_pasep_owed || 0 });
    data.push({ competence: formattedMonth, tax_name: 'COFINS', amount_due: cofins_owed || 0 });
    data.push({ competence: formattedMonth, tax_name: 'IRPJ', amount_due: irpj_owed || 0 });
    data.push({ competence: formattedMonth, tax_name: 'CSLL', amount_due: csll_owed || 0 });
    data.push({ competence: formattedMonth, tax_name: 'ISS', amount_due: iss_owed || 0 });
    data.push({ competence: formattedMonth, tax_name: 'CPP', amount_due: cpp_owed || 0 });
  }

  return data;
};

export default formatData;
