import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const fetchMonthComparison = (params) => {
  return async (dispatch) => {
    dispatch(request(params));

    service.fetchMonthComparison(params).then(
      (response) => {
        dispatch(success(response));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(params) {
    return {
      type: constants.FETCH_MONTH_COMPARISON_REQUEST,
      payload: params,
    };
  }
  function success(response) {
    return {
      type: constants.FETCH_MONTH_COMPARISON_SUCCESS,
      payload: response.data,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_MONTH_COMPARISON_FAILURE,
      payload: error,
    };
  }
};

export default fetchMonthComparison;
