import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';
import getAllCompanies from './getAllCompanies';
import getActiveCompany from './getActiveCompany';

const deleteCompany = (id) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Excluir empresa',
      message: 'Tem certeza que deseja excluir esta empresa? Todas as informações relacionadas a essa empresa serão removidas.',
      confirmButtonText: 'Sim, excluir!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.remove(id).then(
          () => {
            dispatch(success(id));
            dispatch(getActiveCompany());
            dispatch(alertActionCreators.success('Empresa excluída com sucesso!'));
            dispatch(getAllCompanies());
          },
        );
      },
      onCancel: () => {},
    });
  };

  function request(id) {
    return { type: constants.COMPANY_DELETE_REQUEST, payload: { id } };
  }
  function success(id) {
    return {
      type: constants.COMPANY_DELETE_SUCCESS,
      payload: {
        id,
      },
    };
  }
};

export default deleteCompany;
