export const balanceSheetConstants = {
  BALANCE_SHEET_GET_REQUEST: 'BALANCE_SHEET_GET_REQUEST',
  BALANCE_SHEET_GET_SUCCESS: 'BALANCE_SHEET_GET_SUCCESS',
  BALANCE_SHEET_GET_FAILURE: 'BALANCE_SHEET_GET_FAILURE',

  BALANCE_SHEET_ERASE_REQUEST: 'BALANCE_SHEET_ERASE_REQUEST',
  BALANCE_SHEET_ERASE_SUCCESS: 'BALANCE_SHEET_ERASE_SUCCESS',
  BALANCE_SHEET_ERASE_FAILURE: 'BALANCE_SHEET_ERASE_FAILURE',
};

export default balanceSheetConstants;
