import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsPlus } from 'react-icons/bs';

import { Button, TableLookup, ConfirmDialog } from '_components/_core';
import { Pagination, ItemsPerPage } from '_components/_core/Table/components';
import { useTable } from '_components/_core/Table/utils';

import { AddEmployeeRoleModal } from './components';
import { DEFAULT_COLUMNS, TABLE_COLUMNS } from './utilities';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { field: 'description', order: 'asc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function EmployeeRoles({
  total,
  employeeRoles,
  onFetchEmployeeRoles,
  onAddEmployeeRole,
  onUpdateEmployeeRole,
  onDeleteEmployeeRole,
}) {
  const {
    sorting,
    pagination,
    onSorting,
    onPageChange,
    onPageSizeChange,
  } = useTable({
    ...tableConfig,
    data: employeeRoles,
    onFetchData: onFetchEmployeeRoles,
  });

  const [selectedRole, setSelectedRole] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const deleteEmployeeRoleMessage = useMemo(() => {
    if (!selectedRole) {
      return null;
    }

    const { description } = selectedRole;

    return `Tem certeza que deseja excluir o cargo <b>"${description}"</b>?`;
  }, [selectedRole]);

  const handleDeleteEmployeeRole = useCallback((id) => {
    const role = employeeRoles.find((role) => role.id === id);

    setSelectedRole(role);
    setIsDeleteConfirmOpen(true);
  }, [employeeRoles]);

  const handleDeleteEmployeeRoleConfirmation = useCallback(() => {
    const { id } = selectedRole;

    onDeleteEmployeeRole(id);
    setIsModalOpen(false);
    setIsDeleteConfirmOpen(false);
    setSelectedRole(null);
  }, [selectedRole, onDeleteEmployeeRole]);

  const handleEditEmployeeRole = useCallback((id) => {
    setSelectedRole(null);

    const role = employeeRoles.find((role) => role.id === id);

    setSelectedRole(role);
    setIsModalOpen(true);
  }, [employeeRoles]);

  const handleAddEmployeeRole = useCallback(() => {
    setSelectedRole(null);
    setIsModalOpen(true);
  }, []);

  return (
    <>
      <AddEmployeeRoleModal
        isVisible={isModalOpen}
        employeeRole={selectedRole}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
        onAddEmployeeRole={onAddEmployeeRole}
        onUpdateEmployeeRole={onUpdateEmployeeRole}
      />
      <ConfirmDialog
        title="Excluir Cargo"
        icon="question"
        confirmButtonColor="#DB504A"
        confirmButtonText="Sim, deletar!"
        html={deleteEmployeeRoleMessage}
        isOpen={isDeleteConfirmOpen}
        showCancelButton
        onConfirm={handleDeleteEmployeeRoleConfirmation}
        onCancel={() => {
          setIsDeleteConfirmOpen(false);
          setSelectedRole(null);
        }}
      />
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h4 className="mb-0">Cargos</h4>
        <Button
          variant="primary"
          icon={<BsPlus />}
          onClick={handleAddEmployeeRole}
        >
          Novo Cargo
        </Button>
      </div>
      <TableLookup
        keyName="id"
        data={employeeRoles}
        columns={TABLE_COLUMNS}
        defaultColumns={DEFAULT_COLUMNS}
        sorting={sorting}
        onSorting={onSorting}
        onEdit={handleEditEmployeeRole}
        onDelete={handleDeleteEmployeeRole}
      />
      <Row>
        <Col sm={2}>
          <ItemsPerPage
            itemsPerPage={pagination.itemsPerPage}
            onChange={onPageSizeChange}
            className="mt-3"
          />
        </Col>
        <Col className="d-flex flex-row-reverse">
          <Pagination
            {...pagination}
            total={total}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
    </>
  );
}

EmployeeRoles.propTypes = {
  total: PropTypes.number.isRequired,
  employeeRoles: PropTypes.object,
  onFetchEmployeeRoles: PropTypes.func.isRequired,
  onAddEmployeeRole: PropTypes.func.isRequired,
  onUpdateEmployeeRole: PropTypes.func.isRequired,
  onDeleteEmployeeRole: PropTypes.func.isRequired,
};

export default EmployeeRoles;
