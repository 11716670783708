import { connect } from 'react-redux';

import transactionActionCreators from '_store/_actions/companyTransactions';

import CreatableSelect from './CreatableSelect';

const mapStateToProps = (state) => ({
  isLoadingAccount: state.accounts.isLoading,
  isLoadingCities: state.ibge.isLoadingCities,
  isLoadingStates: state.ibge.isLoadingStates,
  isLoadingCep: state.ibge.isLoadingCep,
  isLoadingPerson: state.fiscal.isLoading,
  cities: state.ibge.cities,
  states: state.ibge.states,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onCreateRecipient: transactionActionCreators.createTransactionRecipient,
  onCreateCategory: transactionActionCreators.createTransactionCategory,
  onCreateBankAccount: transactionActionCreators.createTransactionAccount,
  onCreateCostCenter: transactionActionCreators.createCostCenter,
  onCreateTag: transactionActionCreators.createTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatableSelect);
