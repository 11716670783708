import React from 'react';
import PropTypes from 'prop-types';

import { Menu } from '_components/_core';
import { MenuCategory, MenuButton } from '_components/_core/Menu/Menu';

import { InvoiceFilters } from '_components/_shared';

function Filters({ isLoading, handleSubmit }) {
  return (
    <Menu className="mb-3 mb-md-0">
      <MenuCategory>
        Filtros
      </MenuCategory>
      <div className="ml-4 mr-4">
        <InvoiceFilters />
      </div>
      <MenuButton onClick={handleSubmit} isLoading={isLoading}>
        Gerar Relatório
      </MenuButton>
    </Menu>
  );
}

Filters.defaultProps = {
  isLoading: false,
};

Filters.propTypes = {
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

export default Filters;
