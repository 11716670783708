import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const toggleMultipleTransactionsPaid = (params, callback) => {
  return async (dispatch) => {
    dispatch(request(params));

    service.updateMultipleTransactions(params).then(
      (response) => {
        const { ids, paid } = params;

        dispatch(success(ids, paid, response.data));

        if (paid) {
          dispatch(alertActionCreators.success('As transações foram marcadas como pagas!'));
        } else {
          dispatch(alertActionCreators.success('As transações foram marcadas como não pagas!'));
        }

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(params) {
    return {
      type: constants.TOGGLE_MULTIPLE_TRANSACTIONS_PAID_REQUEST,
      payload: {
        params,
      },
    };
  }
  function success(ids, paid, transactions = []) {
    return {
      type: constants.TOGGLE_MULTIPLE_TRANSACTIONS_PAID_SUCCESS,
      payload: {
        ids,
        paid,
        transactions,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.TOGGLE_MULTIPLE_TRANSACTIONS_PAID_FAILURE,
      payload: error,
    };
  }
};

export default toggleMultipleTransactionsPaid;
