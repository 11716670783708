import constants from '../../_constants/employees.constants';
import service from '../../../services/employees.service';

import getAllEmployeeJourneys from './getEmployeeJourneys';

const deleteEmployeeJourney = (id) => {
  return (dispatch) => {
    dispatch(request());

    service.deleteJourney(id).then(
      () => {
        dispatch(success(id));
        dispatch(getAllEmployeeJourneys());
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.EMPLOYEE_JOURNEYS_DELETE_REQUEST,
      payload: { },
    };
  }
  function success(id) {
    return {
      type: constants.EMPLOYEE_JOURNEYS_DELETE_SUCCESS,
      payload: id,
    };
  }
  function failure(error) {
    return {
      type: constants.EMPLOYEE_JOURNEYS_DELETE_FAILURE,
      payload: error,
    };
  }
};

export default deleteEmployeeJourney;
