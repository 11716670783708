import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { IoSettingsOutline } from 'react-icons/io5';
import sortBy from 'lodash/sortBy';

import DownloadCsv from '../DownloadCsv/DownloadCsv';

import StyledFormCheck from '../StyledFormCheck';
import { StyledDropdown, StyledButton } from './styles';

function ColumnToggle({
  data,
  columns,
  name,
  visibleColumns,
  onVisibleColumnsChange,
  ...rest
}) {
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback((isOpen, _ev, metadata) => {
    if (metadata.source === 'select') {
      setOpen(true);
      return;
    }
    setOpen(isOpen);
  }, []);

  const handleColumnToggle = useCallback((key) => {
    if (visibleColumns.map((c) => c.keyName).includes(key)) {
      const newColumns = visibleColumns.filter((p) => p.keyName !== key);

      onVisibleColumnsChange(newColumns);
      localStorage.setItem(`${name}_columnFilters`, JSON.stringify(newColumns));
    } else {
      const newColumn = columns.find((c) => c.keyName === key);

      const newState = sortBy([...visibleColumns, newColumn], 'order');

      onVisibleColumnsChange(newState);
      localStorage.setItem(`${name}_columnFilters`, JSON.stringify(newState));
    }
  }, [columns, onVisibleColumnsChange, visibleColumns, name]);

  return (
    <StyledDropdown
      show={open}
      onToggle={handleToggle}
      drop="down"
      align="right"
      {...rest}
    >
      <StyledButton
        as={Dropdown.Toggle}
        variant="light"
      >
        <IoSettingsOutline size="1rem" />
      </StyledButton>
      <Dropdown.Menu>
        {columns.map((column) => (
          <Dropdown.Item
            onSelect={handleColumnToggle}
            className="d-flex justify-content-start align-items-center"
            eventKey={column.keyName}
          >
            <StyledFormCheck
              checked={visibleColumns.map((c) => c.keyName).includes(column.keyName)}
            />
            <div className="ml-3">
              {column.label}
            </div>
          </Dropdown.Item>
        ))}
        <hr className="mt-1" />
        <DownloadCsv data={data} filename={name} visibleColumns={visibleColumns} />
      </Dropdown.Menu>
    </StyledDropdown>
  );
}

ColumnToggle.defaultProps = {
  data: [],
  onItemSelected: null,
};

ColumnToggle.propTypes = {
  columns: PropTypes.array.isRequired,
  visibleColumns: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  data: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onItemSelected: PropTypes.func,
  onVisibleColumnsChange: PropTypes.func.isRequired,
};

export default ColumnToggle;
