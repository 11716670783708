import styled from 'styled-components';

export const MonthsContainer = styled.div`
  display: flex;
`;

export const PreviousMonth = styled.div`
  flex-basis: 50%;

  & > div.progress, & > div.progress > div.progress-bar {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
`;

export const CurrentMonth = styled.div`
  flex-basis: 50%;
  border-left: 2px solid #e0e0e0;

  & > div.progress, & > div.progress > div.progress-bar {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
`;

export const PreviousMonthSubTypes = styled.div`
  & div.progress, div.progress-bar {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  & div.progress-bar {
    background-color: #FFBB00 !important;
  }
`;

export const CurrentMonthSubTypes = styled.div`
  & div.progress, div.progress-bar {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  & div.progress-bar {
    background-color: #FFBB00 !important;
  }
`;
