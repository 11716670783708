import constants from '../../_constants/mei.constants';
import service from '../../../services/mei.service';

const updateMei = (id, values, callback) => {
  return (dispatch) => {
    dispatch(request(values));

    service.updateMei(id, values).then(
      (response) => {
        dispatch(success(response.data));

        if (callback) {
          callback(response.data);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(data) {
    return {
      type: constants.UPDATE_MEI_REQUEST,
      payload: data,
    };
  }
  function success(data) {
    return {
      type: constants.UPDATE_MEI_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.UPDATE_MEI_FAILURE,
      payload: error,
    };
  }
};

export default updateMei;
