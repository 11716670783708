import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/receipts.constants';
import service from '../../../services/receipt.service';
import alertActionCreators from '../alert.actions';

const deleteReceipt = (id, callback) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Exclusão de Recibo',
      message: 'Tem certeza que deseja excluir este recibo? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, deletar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.remove(id).then(() => {
          dispatch(success(id));
          dispatch(alertActionCreators.success('Recibo excluído com sucesso.'));

          if (callback) {
            callback();
          }
        }).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request(id) {
    return {
      type: constants.RECEIPT_DELETE_REQUEST,
      payload: { id },
    };
  }
  function success(response) {
    return {
      type: constants.RECEIPT_DELETE_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.RECEIPT_DELETE_FAILURE,
      payload: {
        id,
        error,
      },
    };
  }
};

export default deleteReceipt;
