export const DEFAULT_FILTERS = {
  document_number: null,
  company_name: null,
  trading_name: null,
  date_founded_start: null,
  date_founded_end: null,
  client_since_start: null,
  client_since_end: null,
  status_irs: null,
  tax_regime: null,
  phone_number: null,
  email: null,
};
