import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

import { StyledColumn } from './styles';

function SortableColumns({ columns, sorting }) {
  const handleSort = useCallback((column) => {
    if (!column.sortable) {
      return;
    }

    column.sort(column.path, sorting.order);
  }, [sorting]);

  const renderIcon = useCallback((column) => {
    if (!sorting.field) {
      return null;
    }

    if (sorting.field !== column.path) {
      return null;
    }

    if (sorting.order === 'asc') {
      return <FaCaretUp className="ml-2" />;
    }

    return <FaCaretDown className="ml-2" />;
  }, [sorting]);

  return (
    <>
      {columns.map((column) => (
        <StyledColumn
          key={column.field}
          isSortable={column.sortable}
          className={classNames(`${column.field}-column`, column.className, {
            'sort-active': sorting.field === column.path,
          })}
          onClick={() => handleSort(column)}
        >
          <span className="d-flex align-items-center">
            {column.label}
            {renderIcon(column)}
          </span>
        </StyledColumn>
      ))}
    </>
  );
}

SortableColumns.defaultProps = {
  columns: [],
  sorting: {},
};

SortableColumns.propTypes = {
  columns: PropTypes.array,
  sorting: PropTypes.object,
};

export default SortableColumns;
