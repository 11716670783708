import * as Yup from 'yup';
import { ptShort } from 'yup-locale-pt';

Yup.setLocale(ptShort);

const ValidationSchema = Yup.object().shape({
  tax_regime: Yup.string().required().nullable(),
  certificate_file: Yup.string().required().nullable(),
  certificate_password: Yup.string().max(60).required().nullable(),
  certificate_password_confirmation: Yup.string().oneOf([Yup.ref('certificate_password')], 'As senhas não conferem').required(),
  municipal_registration: Yup.string().required().nullable(),
});

export default ValidationSchema;
