import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/entries.constants';
import service from '../../../services/entry.service';
import alertActionCreators from '../alert.actions';

const deleteEntry = (id, callback) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Exclusão de Lançamento',
      message: 'Tem certeza que deseja excluir este lançamento? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, deletar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.remove(id).then(() => {
          dispatch(success(id));
          dispatch(alertActionCreators.success('Lançamento excluído com sucesso.'));

          if (callback) {
            callback();
          }
        }).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request(id) {
    return {
      type: constants.ENTRY_DELETE_REQUEST,
      payload: { id },
    };
  }
  function success(id) {
    return {
      type: constants.ENTRY_DELETE_SUCCESS,
      payload: {
        entry_id: id,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.ENTRY_DELETE_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default deleteEntry;
