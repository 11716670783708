import FORMATTERS from 'helpers/formatters';
import { SPECIAL_COLUMNS } from '_components/_core/Table';

export const DEFAULT_COLUMNS = ['temp_id', 'id', 'start_date', 'tax_regime', 'metadata'];

export const DEFAULT_COLUMNS_EDITING = ['id', 'start_date', 'tax_regime', 'metadata'];

export const TABLE_COLUMNS = [
  {
    label: 'Remover',
    keyName: 'temp_id',
    align: 'center',
    width: 5,
    specialType: SPECIAL_COLUMNS.ACTIONS,
  },
  {
    label: 'Vigência',
    keyName: 'start_date',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.DATE_MMYYYY,
  },
  {
    label: 'Regime de Tributação',
    keyName: 'tax_regime',
    align: 'center',
    width: 15,
    formatter: FORMATTERS.TAX_REGIME,
  },
  {
    label: 'Anexo/Alíquota/Tipo',
    keyName: 'metadata',
    align: 'left',
    width: 50,
    formatter: FORMATTERS.TAX_REGIME_METADATA,
  },
];

export const TABLE_COLUMNS_EDITING = [
  {
    label: 'Remover',
    keyName: 'id',
    align: 'center',
    width: 5,
    specialType: SPECIAL_COLUMNS.ACTIONS,
  },
  {
    label: 'Vigência',
    keyName: 'start_date',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.DATE_MMYYYY,
  },
  {
    label: 'Regime de Tributação',
    keyName: 'tax_regime',
    align: 'center',
    width: 15,
    formatter: FORMATTERS.TAX_REGIME,
  },
  {
    label: 'Anexo/Alíquota/Tipo',
    keyName: 'metadata',
    align: 'left',
    width: 50,
    formatter: FORMATTERS.TAX_REGIME_METADATA,
  },
];

const FACTOR_R_OPTIONS = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
];

export {
  FACTOR_R_OPTIONS,
};
