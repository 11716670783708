import isEmpty from 'lodash/isEmpty';
import * as XLSX from 'sheetjs-style';

import FORMATTERS from 'helpers/formatters';
import { CELL_TYPES } from './constants';

import getTransactionDescription from '../getTransactionDescription';
import getTransactionDate from '../getTransactionDate';

const reportToExcel = ({
  report_name,
  reports,
  accounts,
  date_type,
}) => {
  const { results } = reports[report_name];

  const verifyIsEmpty = (results) => {
    if (!isEmpty(results.fixed_expenses.transactions)) return false;
    if (!isEmpty(results.incomes.transactions)) return false;
    if (!isEmpty(results.people.transactions)) return false;
    if (!isEmpty(results.taxes.transactions)) return false;
    if (!isEmpty(results.transfers_received.transactions)) return false;
    if (!isEmpty(results.transfers_send.transactions)) return false;
    if (!isEmpty(results.variable_expenses.transactions)) return false;
    return true;
  };

  if (verifyIsEmpty(results)) return;

  let lines_to_skip = 2;
  const invalid_amount_lines = [1];
  const invalid_total_lines = [];
  const transactionsType = [null, 'HEADERS'];

  const spreadsheetData = [
    ['Saldo Anterior', 'null', 'null', 'null', 'null', 'null', 'null', results.initial_balance],
  ];

  const columnsToMerge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } },
  ];

  const extract_info = ((array, transactionType) => {
    array.map((item) => {
      let recipient_name = '-';

      if (transactionType !== 'TRANSFER') {
        recipient_name = item.recipient.name || '-';
      }

      if (transactionType === 'TRANSFER' && item.transfer_details) {
        recipient_name = FORMATTERS.REPORT_CASH_FLOW_TRANSFER(item, accounts, false);
      }

      spreadsheetData.push([
        item.paid ? 'Sim' : 'Não',
        getTransactionDate(item, date_type),
        getTransactionDescription(item),
        recipient_name,
        FORMATTERS.REPORT_CATEGORY_NAME(item, '-'),
        item.account.description || '-',
        transactionType === 'EXPENSE' ? item.amount * -1 : item.amount,
        item.balance || '-',
      ]);

      transactionsType.push(transactionType);

      return spreadsheetData;
    });
  });

  if (!isEmpty(results.incomes.transactions)) {
    spreadsheetData.push(
      [null, null, null, null, null, null, null, null],
      ['Recebimentos', 'null', 'null', 'null', 'null', 'null', 'null', results.incomes.total],
      ['Pago?', 'Data', 'Descrição', 'Recebido de / Pago a', 'Categoria', 'Conta Bancária', 'Valor', 'Saldo'],
    );

    invalid_amount_lines.push(lines_to_skip + 1, lines_to_skip + 2);
    invalid_total_lines.push(lines_to_skip, lines_to_skip + 2);

    columnsToMerge.push({
      s: { r: lines_to_skip, c: 0 },
      e: { r: lines_to_skip, c: 6 },
    });

    lines_to_skip += 3 + results.incomes.transactions.length;

    transactionsType.push(null, 'HEADERS', 'HEADERS');

    extract_info(results.incomes.transactions, 'INCOME');
  }

  if (!isEmpty(results.fixed_expenses.transactions)) {
    spreadsheetData.push(
      [null, null, null, null, null, null, null, null],
      ['Despesas Fixas', 'null', 'null', 'null', 'null', 'null', 'null', results.fixed_expenses.total],
      ['Pago?', 'Data', 'Descrição', 'Recebido de / Pago a', 'Categoria', 'Conta Bancária', 'Valor', 'Saldo'],
    );

    invalid_amount_lines.push(lines_to_skip + 1, lines_to_skip + 2);
    invalid_total_lines.push(lines_to_skip, lines_to_skip + 2);

    columnsToMerge.push({
      s: { r: lines_to_skip, c: 0 },
      e: { r: lines_to_skip, c: 6 },
    });

    lines_to_skip += 3 + results.fixed_expenses.transactions.length;

    transactionsType.push(null, 'HEADERS', 'HEADERS');

    extract_info(results.fixed_expenses.transactions, 'EXPENSE');
  }

  if (!isEmpty(results.variable_expenses.transactions)) {
    spreadsheetData.push(
      [null, null, null, null, null, null, null, null],
      ['Despesas Variáveis', 'null', 'null', 'null', 'null', 'null', 'null', results.variable_expenses.total],
      ['Pago?', 'Data', 'Descrição', 'Recebido de / Pago a', 'Categoria', 'Conta Bancária', 'Valor', 'Saldo'],
    );

    invalid_amount_lines.push(lines_to_skip + 1, lines_to_skip + 2);
    invalid_total_lines.push(lines_to_skip, lines_to_skip + 2);

    columnsToMerge.push({
      s: { r: lines_to_skip, c: 0 },
      e: { r: lines_to_skip, c: 6 },
    });

    lines_to_skip += 3 + results.variable_expenses.transactions.length;

    transactionsType.push(null, 'HEADERS', 'HEADERS');

    extract_info(results.variable_expenses.transactions, 'EXPENSE');
  }

  if (!isEmpty(results.people.transactions)) {
    spreadsheetData.push(
      [null, null, null, null, null, null, null, null],
      ['Pessoal', 'null', 'null', 'null', 'null', 'null', 'null', results.people.total],
      ['Pago?', 'Data', 'Descrição', 'Recebido de / Pago a', 'Categoria', 'Conta Bancária', 'Valor', 'Saldo'],
    );

    invalid_amount_lines.push(lines_to_skip + 1, lines_to_skip + 2);
    invalid_total_lines.push(lines_to_skip, lines_to_skip + 2);

    columnsToMerge.push({
      s: { r: lines_to_skip, c: 0 },
      e: { r: lines_to_skip, c: 6 },
    });

    lines_to_skip += 3 + results.people.transactions.length;

    transactionsType.push(null, 'HEADERS', 'HEADERS');

    extract_info(results.people.transactions, 'EXPENSE');
  }

  if (!isEmpty(results.taxes.transactions)) {
    spreadsheetData.push(
      [null, null, null, null, null, null, null, null],
      ['Impostos', 'null', 'null', 'null', 'null', 'null', 'null', results.taxes.total],
      ['Pago?', 'Data', 'Descrição', 'Recebido de / Pago a', 'Categoria', 'Conta Bancária', 'Valor', 'Saldo'],
    );

    invalid_amount_lines.push(lines_to_skip + 1, lines_to_skip + 2);
    invalid_total_lines.push(lines_to_skip, lines_to_skip + 2);

    columnsToMerge.push({
      s: { r: lines_to_skip, c: 0 },
      e: { r: lines_to_skip, c: 6 },
    });

    lines_to_skip += 3 + results.taxes.transactions.length;

    transactionsType.push(null, 'HEADERS', 'HEADERS');

    extract_info(results.taxes.transactions, 'EXPENSE');
  }

  if (!isEmpty(results.transfers_send.transactions)) {
    spreadsheetData.push(
      [null, null, null, null, null, null, null, null],
      ['Transferências Enviadas', 'null', 'null', 'null', 'null', 'null', 'null', results.transfers_send.total],
      ['Pago?', 'Data', 'Descrição', 'Conta de Destino', 'Categoria', 'Conta de Origem', 'Valor', 'Saldo'],
    );

    invalid_amount_lines.push(lines_to_skip + 1, lines_to_skip + 2);
    invalid_total_lines.push(lines_to_skip, lines_to_skip + 2);

    columnsToMerge.push({
      s: { r: lines_to_skip, c: 0 },
      e: { r: lines_to_skip, c: 6 },
    });

    lines_to_skip += 3 + results.transfers_send.transactions.length;

    transactionsType.push(null, 'HEADERS', 'HEADERS');

    extract_info(results.transfers_send.transactions, 'TRANSFER');
  }

  if (!isEmpty(results.transfers_received.transactions)) {
    spreadsheetData.push(
      [null, null, null, null, null, null, null, null],
      ['Transferências Recebidas', 'null', 'null', 'null', 'null', 'null', 'null', results.transfers_received.total],
      ['Pago?', 'Data', 'Descrição', 'Conta de Origem', 'Categoria', 'Conta de Destino', 'Valor', 'Saldo'],
    );

    invalid_amount_lines.push(lines_to_skip + 1, lines_to_skip + 2);
    invalid_total_lines.push(lines_to_skip, lines_to_skip + 2);

    columnsToMerge.push({
      s: { r: lines_to_skip, c: 0 },
      e: { r: lines_to_skip, c: 6 },
    });

    lines_to_skip += 3 + results.transfers_received.transactions.length;

    transactionsType.push(null, 'HEADERS', 'HEADERS');

    extract_info(results.transfers_received.transactions, 'TRANSFER');
  }

  invalid_amount_lines.push(lines_to_skip + 1, lines_to_skip + 2);
  invalid_total_lines.push(lines_to_skip, lines_to_skip + 2);

  spreadsheetData.push(
    [null, null, null, null, null, null, null, null],
    ['Saldo Anterior', 'null', 'null', 'null', 'null', 'null', 'null', results.initial_balance],
    ['Total de Recebimentos no Período', 'null', 'null', 'null', 'null', 'null', 'null', results.total_incomes],
    ['Total de Despesas no Período', 'null', 'null', 'null', 'null', 'null', 'null', results.total_expenses * -1],
    ['Total de Transferências no Período', 'null', 'null', 'null', 'null', 'null', 'null', results.total_transfers],
    ['Balanço no Período', 'null', 'null', 'null', 'null', 'null', 'null', results.balance_at_time],
    ['Saldo Final', 'null', 'null', 'null', 'null', 'null', 'null', results.final_balance],
  );

  transactionsType.push(null, 'HEADERS', 'HEADERS', 'HEADERS', 'HEADERS', 'HEADERS', 'HEADERS');

  columnsToMerge.push(
    { s: { r: spreadsheetData.length - 6, c: 0 }, e: { r: spreadsheetData.length - 6, c: 6 } },
    { s: { r: spreadsheetData.length - 5, c: 0 }, e: { r: spreadsheetData.length - 5, c: 6 } },
    { s: { r: spreadsheetData.length - 4, c: 0 }, e: { r: spreadsheetData.length - 4, c: 6 } },
    { s: { r: spreadsheetData.length - 3, c: 0 }, e: { r: spreadsheetData.length - 3, c: 6 } },
    { s: { r: spreadsheetData.length - 2, c: 0 }, e: { r: spreadsheetData.length - 2, c: 6 } },
    { s: { r: spreadsheetData.length - 1, c: 0 }, e: { r: spreadsheetData.length - 1, c: 6 } },
  );

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(spreadsheetData);

  invalid_amount_lines.push(
    invalid_amount_lines[invalid_amount_lines.length - 1] + 1,
    invalid_amount_lines[invalid_amount_lines.length - 1] + 2,
    invalid_amount_lines[invalid_amount_lines.length - 1] + 3,
    invalid_amount_lines[invalid_amount_lines.length - 1] + 4,
    invalid_amount_lines[invalid_amount_lines.length - 1] + 5,
  );

  for (let i = 1; i <= spreadsheetData.length; i += 1) {
    if (transactionsType[i] !== null) {
      worksheet[`A${i}`].s = CELL_TYPES[transactionsType[i]];
      worksheet[`B${i}`].s = CELL_TYPES[transactionsType[i]];
      worksheet[`C${i}`].s = CELL_TYPES[transactionsType[i]];
      worksheet[`D${i}`].s = CELL_TYPES[transactionsType[i]];
      worksheet[`E${i}`].s = CELL_TYPES[transactionsType[i]];
      worksheet[`F${i}`].s = CELL_TYPES[transactionsType[i]];
      worksheet[`G${i}`].s = CELL_TYPES[transactionsType[i]];
      worksheet[`H${i}`].s = CELL_TYPES[transactionsType[i]];
    }
    if (!invalid_total_lines.includes(i) && !invalid_amount_lines.includes(i)) {
      worksheet[`G${i}`].t = 'n';
      worksheet[`G${i}`].z = '#,##0.00';
      worksheet[`H${i}`].t = 'n';
      worksheet[`H${i}`].z = '#,##0.00';
    } else if (!invalid_total_lines.includes(i) && invalid_amount_lines.includes(i)) {
      worksheet[`H${i}`].t = 'n';
      worksheet[`H${i}`].z = '#,##0.00';
    }
  }

  worksheet['!merges'] = columnsToMerge;

  worksheet['!cols'] = [{ width: 7 }, { width: 12 }, { width: 35 }, { width: 25 }, { width: 25 }, { width: 25 }, { width: 13 }, { width: 13 }];

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Fluxo_de_Caixa');

  XLSX.writeFile(workbook, 'Zenply_Fluxo_Caixa.xlsx', {
    type: 'array',
    bookType: 'xlsx',
  });
};

export default reportToExcel;
