import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';

const deleteCompanyCredential = (company_id, id, callback) => {
  return async (dispatch) => {
    confirmDialog.warning({
      title: 'Excluir credencial',
      message: 'Tem certeza que deseja excluir esta credencial? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, excluir!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.deleteCredential(company_id, id).then(
          (response) => {
            dispatch(success(response));
            dispatch(alertActionCreators.success('Credencial excluída com sucesso!'));

            if (callback) {
              service.getById(company_id).then((response) => {
                callback(response.data);
              });
            }
          },
        ).catch(
          (err) => {
            dispatch(failure(err));
            dispatch(alertActionCreators.error('Erro ao excluir credencial!'));
          },
        );
      },
      onCancel: () => {},
    });
  };

  function request(id) {
    return {
      type: constants.DELETE_COMPANY_CREDENTIAL_REQUEST,
      payload: { id },
    };
  }
  function success(response) {
    return {
      type: constants.DELETE_COMPANY_CREDENTIAL_SUCCESS,
      payload: { response },
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_COMPANY_CREDENTIAL_FAILURE,
      payload: { error },
    };
  }
};

export default deleteCompanyCredential;
