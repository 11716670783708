import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import { IoAdd } from 'react-icons/io5';
import Container from 'react-bootstrap/Container';

import { Button, PageHeader, TableLookup } from '_components/_core';

import { AccountModal } from './components';
import { DEFAULT_COLUMNS, TABLE_COLUMNS } from './utilities';

function Accounts({
  isLoading,
  activeCompany,
  accounts,
  onFetchTransactionAccounts,
  onDeleteTransactionAccount,
  onSetFavoriteBankAccount,
}) {
  const [sorting, setSorting] = useState({
    field: 'description',
    order: 'asc',
  });

  useEffect(() => {
    onFetchTransactionAccounts();
  }, [onFetchTransactionAccounts, activeCompany]);

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddAccount = useCallback(() => {
    setSelectedAccount(null);
    setIsModalOpen(true);
  }, []);

  const handleEditAccount = useCallback((id) => {
    setSelectedAccount(null);

    const account = accounts.find((account) => account.id === id);

    setSelectedAccount(account);
    setIsModalOpen(true);
  }, [accounts]);

  const handleDeleteAccount = useCallback((id) => {
    const account = accounts.find((account) => account.id === id);

    onDeleteTransactionAccount(account.id, account.description);
  }, [accounts, onDeleteTransactionAccount]);

  const handleFavoriteAccount = useCallback((id) => {
    onSetFavoriteBankAccount(id);
  }, [onSetFavoriteBankAccount]);

  const handleSorting = useCallback(({ field, order }) => {
    setSorting({
      field,
      order,
    });
  }, []);

  const sortedData = useMemo(() => {
    if (!sorting) {
      return accounts;
    }

    return orderBy(accounts, sorting.field, sorting.order);
  }, [accounts, sorting]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Contas Bancárias"
        variant="small"
        sideContent={(
          <Button
            variant="dark"
            icon={<IoAdd size="1.2em" />}
            iconPosition="left"
            disabled={isLoading}
            onClick={handleAddAccount}
            name="add-account-button"
          >
            Nova Conta
          </Button>
        )}
      />
      <AccountModal
        isVisible={isModalOpen}
        account={selectedAccount}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
      />
      <TableLookup
        keyName="id"
        data={sortedData}
        onEdit={handleEditAccount}
        onDelete={handleDeleteAccount}
        onFavorite={handleFavoriteAccount}
        sorting={sorting}
        onSorting={handleSorting}
        favoriteHint="Marcar como Conta Principal"
        columns={TABLE_COLUMNS}
        defaultColumns={DEFAULT_COLUMNS}
      />
    </Container>
  );
}

Accounts.propTypes = {
  activeCompany: PropTypes.object,
  accounts: PropTypes.any,
  isLoading: PropTypes.func,
  onFetchTransactionAccounts: PropTypes.func.isRequired,
  onDeleteTransactionAccount: PropTypes.func.isRequired,
  onSetFavoriteBankAccount: PropTypes.func.isRequired,
};

export default Accounts;
