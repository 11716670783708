import { connect } from 'react-redux';

import EmployeeInfoTab from './EmployeeInfoTab';

const mapStateToProps = (state) => ({
  employeeTypes: state.employees.employeeTypes,
  employeeJourneys: state.employees.employeeJourneys,
  employeeRoles: state.employees.employeeRoles,
});

export default connect(mapStateToProps, null)(EmployeeInfoTab);
