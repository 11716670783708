import uniqueId from 'lodash/uniqueId';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const duplicateTransaction = (transaction_id, type, callback) => {
  return async (dispatch) => {
    const tempId = uniqueId();

    dispatch(request(tempId, transaction_id, type));

    const params = {
      transaction_id,
      type,
    };

    service.duplicateTransaction(params).then(
      (response) => {
        dispatch(success(response, transaction_id, type));

        if (type === 'next_month') {
          dispatch(alertActionCreators.success('Transação duplicada e movida para o próximo mês!'));
        } else {
          dispatch(alertActionCreators.success('Transação duplicada com sucesso!'));
        }

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(tempId, transaction_id, type) {
    return {
      type: constants.DUPLICATE_TRANSACTIONS_REQUEST,
      payload: {
        tempId,
        transaction_id,
        type,
      },
    };
  }
  function success(response, transaction_id, type) {
    return {
      type: constants.DUPLICATE_TRANSACTIONS_SUCCESS,
      payload: {
        transaction: response.data,
        transaction_id,
        type,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.DUPLICATE_TRANSACTIONS_FAILURE,
      payload: error,
    };
  }
};

export default duplicateTransaction;
