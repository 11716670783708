export const bankStatementsConstants = {
  BANK_STATEMENTS_PARSE_REQUEST: 'BANK_STATEMENTS_PARSE_REQUEST',
  BANK_STATEMENTS_PARSE_SUCCESS: 'BANK_STATEMENTS_PARSE_SUCCESS',
  BANK_STATEMENTS_PARSE_FAILURE: 'BANK_STATEMENTS_UPLOAD_FAILURE',
  BANK_STATEMENTS_UPLOAD_REQUEST: 'BANK_STATEMENTS_UPLOAD_REQUEST',
  BANK_STATEMENTS_UPLOAD_SUCCESS: 'BANK_STATEMENTS_UPLOAD_SUCCESS',
  BANK_STATEMENTS_UPLOAD_FAILURE: 'BANK_STATEMENTS_UPLOAD_FAILURE',

  FETCH_BANK_STATEMENT_ENTRIES_REQUEST: 'FETCH_BANK_STATEMENT_ENTRIES_REQUEST',
  FETCH_BANK_STATEMENT_ENTRIES_SUCCESS: 'FETCH_BANK_STATEMENT_ENTRIES_SUCCESS',
  FETCH_BANK_STATEMENT_ENTRIES_FAILURE: 'FETCH_BANK_STATEMENT_ENTRIES_FAILURE',

  BANK_STATEMENTS_IGNORE_ENTRIES_REQUEST: 'BANK_STATEMENTS_IGNORE_ENTRIES_REQUEST',
  BANK_STATEMENTS_IGNORE_ENTRIES_SUCCESS: 'BANK_STATEMENTS_IGNORE_ENTRIES_SUCCESS',
  BANK_STATEMENTS_IGNORE_ENTRIES_FAILURE: 'BANK_STATEMENTS_IGNORE_ENTRIES_FAILURE',

  BANK_STATEMENTS_UPDATE_MANY_ENTRIES_REQUEST: 'BANK_STATEMENTS_UPDATE_MANY_ENTRIES_REQUEST',
  BANK_STATEMENTS_UPDATE_MANY_ENTRIES_SUCCESS: 'BANK_STATEMENTS_UPDATE_MANY_ENTRIES_SUCCESS',
  BANK_STATEMENTS_UPDATE_MANY_ENTRIES_FAILURE: 'BANK_STATEMENTS_UPDATE_MANY_ENTRIES_FAILURE',
};

export default bankStatementsConstants;
