import { connect } from 'react-redux';
import { compose } from 'redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import CostsCenter from './CostsCenter';

const mapStateToProps = (state) => ({
  isLoading: state.companyTransactions.isLoading,
  costsCenter: state.companyTransactions.costsCenter,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onFetchCostsCenter: companyTransactionsActionCreators.fetchAllCostsCenter,
  onCreateCostCenter: companyTransactionsActionCreators.createCostCenter,
  onUpdateCostCenter: companyTransactionsActionCreators.updateCostCenter,
  onDeleteCostCenter: companyTransactionsActionCreators.deleteCostCenter,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['manager_cost_centers'], 'all', true),
)(CostsCenter);
