import axios from 'axios';

import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';
import alertActionCreators from '../alert.actions';

const uploadAvatar = (file, callback) => {
  return async (dispatch) => {
    dispatch(request(file));

    if (file.size > 2500000) {
      dispatch(alertActionCreators.error('Escolha um arquivo até 2MB.'));
      return;
    }

    const {
      name: file_name,
      type: file_type,
    } = file;

    service.getAvatarSignedUrl(file_name, file_type).then(
      async (response) => {
        const { signed_url, url } = response.data;

        await axios.put(signed_url, file, {
          headers: {
            'Content-Type': file.type,
          },
        });

        service.updateUserAvatar(url).then((response) => {
          dispatch(success(response.data));
          dispatch(alertActionCreators.success('Avatar atualizado com sucesso!'));
          callback();
        }).catch((error) => {
          dispatch(failure(error));
          dispatch(alertActionCreators.error('Erro ao atualizar avatar.'));
        });
      },
    );
  };

  function request(file) {
    return {
      type: constants.USER_AVATAR_UPLOAD_REQUEST,
      payload: { file },
    };
  }
  function success(data) {
    return {
      type: constants.USER_AVATAR_UPLOAD_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.USER_AVATAR_UPLOAD_FAILURE,
      payload: { error },
    };
  }
};

export default uploadAvatar;
