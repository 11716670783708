import { employeesConstants, authConstants } from '../_constants';

const INITIAL_STATE = {
  total: 0,
  employees: [],
  totalRoles: 0,
  isLoading: false,
  employeeRoles: [],
  employeeJourneys: [],
  employeeSalaries: [],
  employeeDependents: [],
  employee_types: [],
  employeeVacations: [],
  totalVacations: 0,
};

export function employees(state = INITIAL_STATE, action) {
  switch (action.type) {
    case authConstants.LOGIN_SUCCESS: {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }
    case employeesConstants.EMPLOYEES_GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case employeesConstants.EMPLOYEES_GET_ALL_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        total,
        employees: data,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEES_GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case employeesConstants.EMPLOYEE_ROLES_GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case employeesConstants.EMPLOYEE_ROLES_GET_ALL_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        employeeRoles: data,
        totalRoles: total,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_ROLES_GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case employeesConstants.EMPLOYEE_JOURNEYS_GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case employeesConstants.EMPLOYEE_JOURNEYS_GET_ALL_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        employeeJourneys: data,
        totalJourneys: total,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_JOURNEYS_GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case employeesConstants.EMPLOYEE_SALARIES_GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case employeesConstants.EMPLOYEE_SALARIES_GET_ALL_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        employeeSalaries: data,
        totalSalaries: total,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_SALARIES_GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case employeesConstants.EMPLOYEE_GET_ADD_PARAMS_SUCCESS: {
      const {
        types,
        journeys,
        roles,
      } = action.payload;

      return {
        ...state,
        employeeTypes: types,
        employeeJourneys: journeys.data,
        employeeRoles: roles.data,
      };
    }
    case employeesConstants.EMPLOYEE_SALARIES_ADD_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case employeesConstants.EMPLOYEE_SALARIES_ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_SALARIES_ADD_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_SALARIES_UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case employeesConstants.EMPLOYEE_SALARIES_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_SALARIES_UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_SALARIES_DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case employeesConstants.EMPLOYEE_SALARIES_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_SALARIES_DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_ADD_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case employeesConstants.EMPLOYEE_ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_ADD_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case employeesConstants.EMPLOYEE_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case employeesConstants.GET_EMPLOYEE_VACATIONS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case employeesConstants.GET_EMPLOYEE_VACATIONS_SUCCESS: {
      const vacations = action.payload;

      return {
        ...state,
        employeeVacations: vacations,
        isLoading: false,
      };
    }
    case employeesConstants.GET_EMPLOYEE_VACATIONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_DEPENDENTS_GET_ALL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case employeesConstants.EMPLOYEE_DEPENDENTS_GET_ALL_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        employeeDependents: payload,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_DEPENDENTS_GET_ALL_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_DEPENDENTS_ADD_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case employeesConstants.EMPLOYEE_DEPENDENTS_ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_DEPENDENTS_ADD_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_DEPENDENTS_UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case employeesConstants.EMPLOYEE_DEPENDENTS_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_DEPENDENTS_UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_DEPENDENTS_DELETE_REQUEST: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case employeesConstants.EMPLOYEE_DEPENDENTS_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case employeesConstants.EMPLOYEE_DEPENDENTS_DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
