import React from 'react';

import { Container, HeaderRow, HeaderColumn } from './styles';

export default function Header() {
  return (
    <Container>
      <HeaderRow>
        <HeaderColumn textCenter style={{ width: '8%' }} paddingLeft>
          Ações
        </HeaderColumn>
        <HeaderColumn>
          Código
        </HeaderColumn>
        <HeaderColumn>Descrição</HeaderColumn>
        <HeaderColumn textCenter>Tipo</HeaderColumn>
        <HeaderColumn textCenter>Natureza</HeaderColumn>
      </HeaderRow>
    </Container>
  );
}
