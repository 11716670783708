import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ENTRY_TYPE_OPTIONS } from 'helpers/constants';
import {
  FormDateField,
  FormSelectField,
  FormCurrencyField,
  FormTextAreaField,
  FormAccountSelectField,
} from '_components/_core';

export default function OneDebitOneCredit() {
  return (
    <>
      <Row>
        <Col lg={{ span: 2, order: 1 }} md={{ order: 4 }} xs={{ order: 4, span: 12 }}>
          <Form.Group>
            <Form.Label>Data</Form.Label>
            <FormDateField name="event_date" placeholder="Data" />
          </Form.Group>
        </Col>
        <Col lg={{ span: 4, order: 2 }} md={{ order: 5 }} xs={{ order: 5, span: 12 }}>
          <Form.Group>
            <Form.Label>Tipo do Lançamento</Form.Label>
            <FormSelectField name="entry_type" options={ENTRY_TYPE_OPTIONS} />
          </Form.Group>
        </Col>
        <Col lg={{ span: 2, order: 3 }} md={{ order: 1, span: 4 }} xs={{ order: 1, span: 4 }}>
          <Form.Group>
            <Form.Label>Total Débito</Form.Label>
            <FormCurrencyField name="total_debit" placeholder="0,00" disabled />
          </Form.Group>
        </Col>
        <Col lg={{ span: 2, order: 4 }} md={{ order: 2, span: 4 }} xs={{ order: 2, span: 4 }}>
          <Form.Group>
            <Form.Label>Total Crédito</Form.Label>
            <FormCurrencyField name="total_credit" placeholder="0,00" disabled />
          </Form.Group>
        </Col>
        <Col lg={{ span: 2, order: 5 }} md={{ order: 3, span: 4 }} xs={{ order: 3, span: 4 }}>
          <Form.Group>
            <Form.Label>Diferença</Form.Label>
            <FormCurrencyField name="difference" placeholder="0,00" disabled />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Debitar</Form.Label>
                <FormAccountSelectField name="debit_account_id" creatable="account" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Creditar</Form.Label>
                <FormAccountSelectField name="credit_account_id" creatable="account" />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Histórico</Form.Label>
                <FormTextAreaField name="narration" rows={6} />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col lg={2} md={4}>
          <Form.Group>
            <Form.Label>Valor</Form.Label>
            <FormCurrencyField name="amount" placeholder="Valor" />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
