import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';

const fetchSubscriptionPlans = () => {
  return (dispatch) => {
    dispatch(request());

    service.getAllPlans().then(
      (response) => {
        const { data } = response;

        dispatch(success(data));
      },
    );
  };

  function request() {
    return {
      type: constants.FETCH_SUBSCRIPTION_PLANS_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.FETCH_SUBSCRIPTION_PLANS_SUCCESS,
      payload: {
        data,
      },
    };
  }
};

export default fetchSubscriptionPlans;
