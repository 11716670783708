import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';

import { ISS_WITHHELD_OPTIONS } from 'helpers/constants';
import { FormDateField, FormSelectField, Menu } from '_components/_core';

import { MenuCategory, MenuRow, MenuButton } from '_components/_core/Menu/Menu';

function Filters({
  isLoading,
  handleSubmit,
  onClearIssReport,
  setFieldValue,
  values,
}) {
  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }, [handleSubmit]);

  const handleTypeChange = useCallback((type) => {
    if (type === values.type) {
      return;
    }

    onClearIssReport();

    setFieldValue('type', type);
  }, [values, onClearIssReport, setFieldValue]);

  return (
    <Menu className="mb-3 mb-md-0" onKeyPress={handleKeyPress}>
      <MenuCategory noPaddingBottom>
        Filtros
      </MenuCategory>
      <MenuRow noPaddingBottom>
        <Col>
          <Form.Group>
            <Form.Label>
              Data Inicial
            </Form.Label>
            <FormDateField name="start_date" placeholder="Data Inicial" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>
              Data Final
            </Form.Label>
            <FormDateField name="end_date" placeholder="Data Final" />
          </Form.Group>
        </Col>
      </MenuRow>
      <MenuRow noPaddingTop>
        <Col>
          <Form.Group>
            <Form.Label>ISS Retido</Form.Label>
            <FormSelectField name="service_iss_withheld" options={ISS_WITHHELD_OPTIONS} />
          </Form.Group>
        </Col>
      </MenuRow>
      <MenuCategory noPaddingTop>
        Tipo de Relatório
      </MenuCategory>
      <ListGroup.Item as="button" action eventKey="analytical" onClick={() => handleTypeChange('analytical')}>
        Analítico
      </ListGroup.Item>
      <ListGroup.Item as="button" action eventKey="synthetic" onClick={() => handleTypeChange('synthetic')}>
        Sintético
      </ListGroup.Item>
      <MenuButton isLoading={isLoading} onClick={handleSubmit}>
        Gerar Relatório
      </MenuButton>
    </Menu>
  );
}

Filters.defaultProps = {
  isLoading: false,
};

Filters.propTypes = {
  isLoading: PropTypes.bool,
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onClearIssReport: PropTypes.func.isRequired,
};

export default Filters;
