import Swal from 'sweetalert2';
import PropTypes from 'prop-types';

import './styles.css';

export default function ConfirmDialog({
  isOpen,
  title,
  text,
  onConfirm,
  onCancel,
  onDeny,
  icon,
  confirmButtonColor,
  confirmButtonText,
  cancelButtonColor,
  cancelButtonText,
  denyButtonColor,
  denyButtonText,
  ...restProps
}) {
  if (isOpen) {
    Swal.fire({
      title,
      text,
      icon,
      confirmButtonColor,
      confirmButtonText,
      cancelButtonColor,
      cancelButtonText,
      denyButtonColor,
      denyButtonText,
      ...restProps,
    }).then((result) => {
      if (result.isDenied) {
        onDeny();
        return;
      }

      if (result.isConfirmed) {
        onConfirm();
      } else {
        onCancel();
      }
    });

    return null;
  }

  return null;
}

ConfirmDialog.defaultProps = {
  icon: 'warning',
  padding: '38px 24px',
  isOpen: false,
  confirmButtonText: 'Confirmar',
  cancelButtonText: 'Cancelar',
  confirmButtonColor: '#4CCEAC',
  cancelButtonColor: '#587bc2',
  denyButtonColor: '#dfa915',
  denyButtonText: 'Não',
};

ConfirmDialog.propTypes = {
  icon: PropTypes.oneOf(['warning', 'success', 'info', 'question', 'error']),
  isOpen: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  cancelButtonColor: PropTypes.string,
  confirmButtonColor: PropTypes.string,
  denyButtonColor: PropTypes.string,
  denyButtonText: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDeny: PropTypes.func,
};
