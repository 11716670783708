import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Formik, useFormikContext } from 'formik';
import {
  FaExclamationCircle,
  FaExclamationTriangle,
  FaTimes,
  FaTrash,
} from 'react-icons/fa';
import { TbArrowFork } from 'react-icons/tb';
import { Dropdown } from 'react-bootstrap';
import { TiArrowSortedDown } from 'react-icons/ti';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import classNames from 'classnames';

import FORMATTERS from 'helpers/formatters';
import { useMediaQuery, checkBlockedPeriod } from 'helpers';
import {
  Button,
  FormDateField,
  FormTextField,
  FormCurrencyField,
  FormSwitchField,
  Tag,
} from '_components/_core';
import { LoadingIcon } from '_components/_shared';
import Calculator from '_components/_shared/Calculator/Calculator';

import useRecipients from '_store/_hooks/useRecipients';

import useSplitTransaction from './utilities/useSplitTransaction';

import {
  CustomSelectField,
  CustomCategorySelect,
  Option,
  SplitsTable,
  StyledButton,
  StyledCheckbox,
  StyledModal,
  StyledWarning,
} from './styles';
import { DateTime } from 'luxon';

const SubmitListener = () => {
  const formik = useFormikContext();
  const [lastValues, updateState] = React.useState(formik.values);

  React.useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValues, formik.values);

    if (!valuesEqualLastValues) {
      updateState(formik.values);
    }

    if (!valuesEqualLastValues && formik.isValid) {
      formik.submitForm();
    }
  }, [formik.values, formik.initialValues, formik, lastValues, formik.isValid]);

  return null;
};

function SplitTransactionModal({
  isVisible,
  isEditing,
  isLoading,
  transaction,
  recipients,
  onModalToggle,
  onCreateSplitTransaction,
  onUpdateSplitTransaction,
  onDeleteSplitTransaction,
  onFetchSplits,
  onAfterSaveCallback,
  accounts,
  blockedPeriod,
}) {
  const [isMounted, setIsMounted] = useState(false);
  const [isLoadingSplits, setIsLoadingSplits] = useState(false);
  const [viewBankAccounts, setViewBankAccounts] = useState(false);

  const { isMobile } = useMediaQuery();

  const {
    id: transaction_id,
    split,
    amount: totalAmount,
    event_date: eventDate,
    description,
    type,
    recipient_id,
    category_id,
    account_id,
  } = transaction || {};

  const {
    splits,
    splits_total_amount,
    splits_total_amount_difference,
    forceNewAmount,
    onCreateSplits,
    onCreateSplit,
    onUpdateSplits,
    onRemoveSplit,
    onForceNewAmount,
    onClearSplits,
  } = useSplitTransaction({
    transaction_id: transaction ? transaction.id : null,
    total_amount: totalAmount,
    recipient_id,
    category_id,
    account_id,
  });

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const { options: recipientOptions } = useRecipients({ recipients });

  useEffect(() => {
    if (!isMounted && isEditing && transaction_id) {
      setIsLoadingSplits(true);

      onFetchSplits(transaction_id, foundSplits => {
        onUpdateSplits(sortBy(foundSplits, ['event_date', 'created_at']));

        setIsLoadingSplits(false);
        setIsMounted(true);
      });

      setIsMounted(true);
    }
  }, [isMounted, isEditing, transaction_id, onFetchSplits, onUpdateSplits]);

  const errors = useMemo(() => {
    const errorArray = [];

    const hasInvalidSplits = splits.some(split => {
      const { event_date: eventDate } = split;

      return !eventDate;
    });

    if (hasInvalidSplits) {
      errorArray.push(
        'O campo Data é obrigatório. Para salvar, é necessário preencher esse campo nas divisões abaixo.',
      );
    }

    return errorArray;
  }, [splits]);

  const handleSubmitSplits = useCallback(() => {
    const payload = {
      transaction,
      splits,
    };

    const handleSave = () => {
      if (split) {
        onUpdateSplitTransaction(transaction.id, payload, () => {
          onModalToggle();

          if (onAfterSaveCallback) {
            onAfterSaveCallback();
          }
        });
      } else {
        onCreateSplitTransaction(payload, () => {
          onModalToggle();

          if (onAfterSaveCallback) {
            onAfterSaveCallback();
          }
        });
      }
    };

    if (splits_total_amount_difference !== 0 && forceNewAmount) {
      handleSave();

      return;
    }

    handleSave();
  }, [
    transaction,
    split,
    splits,
    splits_total_amount_difference,
    forceNewAmount,
    onCreateSplitTransaction,
    onUpdateSplitTransaction,
    onModalToggle,
    onAfterSaveCallback,
  ]);

  const getValueForCalculator = useCallback(
    (values, index) => values.splits[index].amount,
    [],
  );

  const canSaveSplits = useMemo(() => {
    if (isEmpty(splits)) {
      return false;
    }

    if (splits_total_amount === 0) {
      return false;
    }

    if (splits_total_amount_difference !== 0 && !forceNewAmount) {
      return false;
    }

    if (!isEmpty(errors)) {
      return false;
    }

    return true;
  }, [
    splits,
    splits_total_amount,
    splits_total_amount_difference,
    errors,
    forceNewAmount,
  ]);

  const recipientPlaceholder = useMemo(() => {
    if (type === 'INCOME') {
      return 'Recebido de';
    }

    return 'Pago a';
  }, [type]);

  const handleCreateSplit = useCallback(() => {
    onCreateSplit({
      event_date: eventDate,
      description,
      amount: splits_total_amount_difference,
      recipient_id,
      category_id,
      account_id,
    });
  }, [
    eventDate,
    description,
    splits_total_amount_difference,
    recipient_id,
    category_id,
    account_id,
    onCreateSplit,
  ]);

  const handleForceNewAmount = useCallback(() => {
    onForceNewAmount(!forceNewAmount);
  }, [onForceNewAmount, forceNewAmount]);

  const handleSubmit = useCallback(
    values => {
      onUpdateSplits(values.splits);

      onForceNewAmount(false);
    },
    [onUpdateSplits, onForceNewAmount],
  );

  const handleDeleteSplitTransaction = useCallback(() => {
    const { id, split } = transaction || {};

    if (!split) {
      onClearSplits();

      return;
    }

    if (id) {
      onDeleteSplitTransaction(id, () => {
        onClearSplits();
      });
    }
  }, [transaction, onClearSplits, onDeleteSplitTransaction]);

  const accountsOptions = useMemo(
    () =>
      sortBy(
        accounts.map(
          account => ({
            value: account.id,
            label: account.description,
          }),
          'description',
        ),
      ),
    [accounts],
  );

  const isDayBlocked = useCallback(datePickerDate => {
    const day = datePickerDate.getDate();
    const month = datePickerDate.getMonth() + 1;
    const year = datePickerDate.getFullYear();

    const luxonDate = DateTime.fromObject({
      day,
      month,
      year,
    }).toFormat('yyyy-MM-dd');

    return checkBlockedPeriod(blockedPeriod, luxonDate);
  }, []);

  const isSomeBlocked = useMemo(() => {
    if (isEmpty(splits)) {
      return false;
    }

    return splits.some(
      split => !checkBlockedPeriod(blockedPeriod, split.event_date, split.paid),
    );
  }, [blockedPeriod, splits]);

  const isSomeBlockedUnpaid = useMemo(() => {
    if (isEmpty(splits)) {
      return false;
    }

    return splits.some(split => !checkBlockedPeriod(blockedPeriod, split.event_date));
  }, [blockedPeriod, splits]);

  const renderModalFooter = useCallback(() => {
    if (isMobile) {
      return (
        <div className="d-flex justify-content-between align-items-center w-100">
          {transaction && transaction.split && (
            <Button
              className="mr-2"
              variant="outline-danger"
              size="sm"
              onClick={handleDeleteSplitTransaction}
              disabled={isLoading || isLoadingSplits || isSomeBlocked}
              id="btn-delete-split-transaction"
            >
              Excluir toda a divisão
            </Button>
          )}
          <Button className="mr-2" variant="secondary" onClick={onModalToggle}>
            Fechar
          </Button>
          <Button
            type="submit"
            variant="dark"
            onClick={handleSubmitSplits}
            isLoading={isLoading}
            disabled={!canSaveSplits}
            loadingText="Aguarde..."
            id="btn-save-split-transaction"
          >
            Salvar divisão
          </Button>
        </div>
      );
    }

    return (
      <div
        style={{ flex: 1 }}
        className="d-flex justify-content-between align-items-center"
      >
        <div>
          {transaction && transaction.split && (
            <Button
              className="mr-2"
              variant="outline-danger"
              onClick={handleDeleteSplitTransaction}
              disabled={isLoading || isLoadingSplits || isSomeBlocked}
              id="btn-delete-split-transaction"
            >
              Excluir toda a divisão
            </Button>
          )}
        </div>
        <div>
          <Button className="mr-2" variant="secondary" onClick={onModalToggle}>
            Fechar
          </Button>
          <Button
            type="submit"
            variant="dark"
            onClick={handleSubmitSplits}
            isLoading={isLoading}
            disabled={!canSaveSplits}
            loadingText="Aguarde..."
            id="btn-save-split-transaction"
          >
            Salvar divisão
          </Button>
        </div>
      </div>
    );
  }, [
    onModalToggle,
    isLoading,
    isLoadingSplits,
    handleSubmitSplits,
    handleDeleteSplitTransaction,
    canSaveSplits,
    transaction,
    isMobile,
    isSomeBlocked,
  ]);

  if (!isVisible) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        splits,
      }}
      onSubmit={handleSubmit}
      validateOnChange={false}
      enableReinitialize
    >
      {({ isValid, values, setFieldValue }) => (
        <StyledModal
          title={`Detalhar Valor / ${FORMATTERS.NUMBER(totalAmount)}`}
          isVisible={isVisible}
          toggleModal={onModalToggle}
          footer={renderModalFooter(isValid)}
          size="xl"
          keyboard={false}
        >
          <ReactTooltip />
          {isLoadingSplits && (
            <div className="mt-5 mb-5">
              <LoadingIcon text="Carregando divisões..." />
            </div>
          )}
          {isEmpty(splits) && !isLoading && !isLoadingSplits && (
            <div
              style={{ width: '80%' }}
              className="d-flex justify-content-center align-items-center flex-column mx-auto mt-5 mb-5"
            >
              <TbArrowFork size="3.5em" className="text-muted mb-3" />
              <h3 className="text-center">Detalhe mais suas movimentações</h3>
              <p className="text-center text-muted">
                Você pode criar subdivisões para esta{' '}
                {type === 'INCOME' ? 'receita' : 'despesa'}. <br />
                Clique em <strong>Adicionar divisão</strong> e inicie a separação dos
                valores. O Zenply irá avisar quando a divisão estiver completa.
              </p>
              <Button
                id="btn-add-split-main"
                variant="success-2"
                onClick={() => onCreateSplits(eventDate, description, setFieldValue)}
              >
                Adicionar divisão
              </Button>
            </div>
          )}
          {!isEmpty(splits) && (
            <div>
              <SubmitListener />
              {isEditing && isSomeBlockedUnpaid && (
                <div id="warning-blocked-period">
                  <Tag variant="warning" className="p-3">
                    O período {FORMATTERS.BLOCKED_PERIOD(blockedPeriod)} está bloqueado
                    para alterações. Só é possível editar divisões que não estejam dentro
                    deste período e que não estejam pagas.
                  </Tag>
                </div>
              )}
              <div className="mb-3">
                {errors.map(error => (
                  <small className="text-danger">{error}</small>
                ))}
              </div>
              <div>
                <SplitsTable isMobile={isMobile} className="table">
                  <thead>
                    <tr>
                      <th width="10%">Data</th>
                      <th width="25%">
                        <Dropdown tabIndex={-1}>
                          <StyledButton
                            variant="link"
                            as={Dropdown.Toggle}
                            className="pl-0 pb-0"
                          >
                            {viewBankAccounts ? 'Conta Bancária' : 'Descrição'}
                            <TiArrowSortedDown size="1.1em" className="ml-1" />
                          </StyledButton>
                          <Dropdown.Menu>
                            <Option onClick={() => setViewBankAccounts(false)}>
                              Descrição
                            </Option>
                            <Option onClick={() => setViewBankAccounts(true)}>
                              Conta Bancária
                            </Option>
                          </Dropdown.Menu>
                        </Dropdown>
                      </th>
                      <th width="10%">Valor</th>
                      <th width="15%">{recipientPlaceholder}</th>
                      <th width="15%">Categoria</th>
                      <th width="5%" className="text-center">
                        Pago ?
                      </th>
                      <th width="10%">Competência</th>
                      <th width="3%" className="text-center">
                        &nbsp;
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortBy(splits, ['frequency_number']).map((split, index) => {
                      const isPeriodBlocked = !checkBlockedPeriod(
                        blockedPeriod,
                        split.event_date,
                        split.paid,
                      );

                      const isBlockedForTogglePaid = !checkBlockedPeriod(
                        blockedPeriod,
                        split.event_date,
                      );

                      return (
                        <tr
                          style={{
                            pointerEvents: isPeriodBlocked ? 'none' : 'auto',
                          }}
                          className="t-split-row"
                        >
                          <td data-label="Data" width="10%">
                            <FormDateField
                              name={`splits[${index}].event_date`}
                              placeholder="Data"
                              placement="top-start"
                              filterDate={isDayBlocked}
                              id="transaction-date"
                            />
                          </td>
                          <td data-label="Descrição" width="25%">
                            <div className="d-flex justify-content-center align-items-center">
                              {!viewBankAccounts && (
                                <>
                                  <FormTextField
                                    name={`splits[${index}].description`}
                                    placeholder="Descrição"
                                    className="w-100"
                                    disabled={isPeriodBlocked}
                                    id="transaction-description"
                                  />
                                  <Button
                                    variant="link"
                                    className="m-0 p-0 text-muted ml-2"
                                    size="xs"
                                    onClick={() =>
                                      setFieldValue(`splits[${index}].description`, '')
                                    }
                                    tabIndex={-1}
                                  >
                                    <FaTimes size="1.2em" />
                                  </Button>
                                </>
                              )}
                              {viewBankAccounts && (
                                <>
                                  <CustomSelectField
                                    name={`splits[${index}].account_id`}
                                    options={accountsOptions}
                                    placeholder="Conta bancária"
                                    creatable="bank_account"
                                    loadingMessage={() => 'Carregando...'}
                                    onCreateCallback={crated_account => {
                                      if (crated_account) {
                                        setFieldValue(
                                          `splits[${index}].account_id`,
                                          crated_account.id,
                                        );
                                      }
                                    }}
                                    id="transaction-account"
                                    isSearchable={false}
                                    customStyleName="small"
                                    isClearable={false}
                                    menuWidth="150%"
                                    disabled={isPeriodBlocked}
                                  />
                                  {values.splits[index].account_id !== account_id && (
                                    <FaExclamationCircle
                                      size="1.5em"
                                      className="ml-2 text-yellow"
                                      data-tip="Atenção: a conta bancária desta divisão é diferente da conta definida no item original."
                                      data-place="right"
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </td>
                          <td data-label="Valor" width="10%">
                            <FormCurrencyField
                              name={`splits[${index}].amount`}
                              placeholder="Valor"
                              forceZero
                              disabled={isPeriodBlocked}
                              id="transaction-amount"
                            />
                            {!isMobile && (
                              <Calculator
                                onChange={value =>
                                  setFieldValue(`splits[${index}].amount`, value)
                                }
                                getValueFn={() => getValueForCalculator(values, index)}
                              />
                            )}
                          </td>
                          <td data-label={recipientPlaceholder} width="15%">
                            <CustomSelectField
                              name={`splits[${index}].recipient_id`}
                              options={recipientOptions}
                              placeholder={recipientPlaceholder}
                              creatable="recipient"
                              metadata={{
                                type: null,
                              }}
                              loadingMessage={() => 'Carregando...'}
                              onCreateCallback={created_recipient => {
                                if (created_recipient) {
                                  setFieldValue(
                                    `splits[${index}].recipient_id`,
                                    created_recipient.id,
                                  );
                                }
                              }}
                              customStyleName="small"
                              isClearable
                              menuWidth="150%"
                              disabled={isPeriodBlocked}
                              id="transaction-recipient"
                            />
                          </td>
                          <td data-label="Categoria" width="15%">
                            <CustomCategorySelect
                              isClearable
                              name={`splits[${index}].category_id`}
                              placeholder="Categoria"
                              onCreateCallback={created_category => {
                                if (created_category) {
                                  setFieldValue(
                                    `splits[${index}].category_id`,
                                    created_category.id,
                                  );
                                }
                              }}
                              onChange={value =>
                                setFieldValue(`splits[${index}].category_id`, value)
                              }
                              value={split.category_id}
                              typeSubType={transaction ? transaction.type_sub_type : null}
                              smallStyling
                              showNoOptionsCustomItems={false}
                              width="150%"
                              id="transaction-category"
                              disabled={isPeriodBlocked}
                            />
                          </td>
                          <td data-label="Pago?" width="8%" className="text-center">
                            <div className="d-flex justify-content-center align-items-center">
                              <FormSwitchField
                                name={`splits[${index}].paid`}
                                disabled={isBlockedForTogglePaid}
                                id="transaction-paid"
                              />
                            </div>
                          </td>
                          <td data-label="Competência" width="10%">
                            <FormDateField
                              name={`splits[${index}].due_date`}
                              placeholder="Competência"
                              placement="top-end"
                              disabled={isPeriodBlocked}
                            />
                          </td>
                          <td data-label="Excluir" width="3%" className="text-center">
                            <Button
                              id="btn-remove-split"
                              noMargin
                              variant="link"
                              className="text-muted btn-sm m-0 p-0"
                              icon={<FaTrash size="1.2rem" />}
                              onClick={() => onRemoveSplit(split.id)}
                              disabled={values.splits.length === 2 || isPeriodBlocked}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  {isMobile && (
                    <tfoot className="mobile-footer">
                      <tr>
                        <td data-label="Valor total do item:">
                          <span>{FORMATTERS.NUMBER(totalAmount)}</span>
                        </td>
                        <td data-label="Soma da divisão:">
                          <span
                            className={classNames({
                              'text-success':
                                Math.abs(splits_total_amount_difference) === 0,
                              'text-danger':
                                Math.abs(splits_total_amount_difference) !== 0,
                            })}
                          >
                            {FORMATTERS.NUMBER(splits_total_amount)}
                          </span>
                        </td>
                        <td data-label="Valor restante da divisão:">
                          <span>{FORMATTERS.NUMBER(splits_total_amount_difference)}</span>
                        </td>
                        {splits_total_amount !== 0 &&
                          splits_total_amount_difference !== 0 && (
                            <td data-label="Aviso:">
                              <span
                                className="mr-2 text-yellow text-bold text-right"
                                style={{
                                  lineHeight: '18px',
                                }}
                              >
                                A soma das partes não bate com o valor total da transação.
                              </span>
                            </td>
                          )}
                        {splits_total_amount !== 0 &&
                          splits_total_amount !== totalAmount && (
                            <td className="no-before">
                              <span>
                                <StyledCheckbox
                                  type="checkbox"
                                  label={`Atualizar valor da transação para ${FORMATTERS.NUMBER(splits_total_amount)}`}
                                  checked={forceNewAmount}
                                  onChange={handleForceNewAmount}
                                  id="update_transaction_amount"
                                  className="mt-0 mb-0"
                                />
                              </span>
                            </td>
                          )}
                        <td className="no-before d-flex justify-content-between w-100">
                          {splits_total_amount_difference > 0 && (
                            <Button
                              variant="outline-dark"
                              size="sm"
                              onClick={handleCreateSplit}
                            >
                              Criar entrada de R${' '}
                              {FORMATTERS.NUMBER(splits_total_amount_difference)}
                            </Button>
                          )}
                          <Button
                            id="btn-add-split"
                            variant="link"
                            className="m-0 p-0"
                            onClick={() =>
                              onCreateSplits(eventDate, description, setFieldValue)
                            }
                          >
                            Adicionar divisão
                          </Button>
                        </td>
                      </tr>
                    </tfoot>
                  )}
                  {!isMobile && (
                    <tfoot>
                      <tr>
                        <td
                          width="35%"
                          colSpan={isMobile ? '1' : '2'}
                          className="text-muted"
                        >
                          <span className="d-flex justify-content-between align-items-center">
                            <span>&nbsp;</span>
                            <span className="text-right">
                              <span>Valor total do item:</span>
                              <br />
                              <span>Soma da divisão:</span>
                              <br />
                              <span>Valor restante da divisão:</span>
                            </span>
                          </span>
                        </td>
                        <td className="text-left">
                          <span>{FORMATTERS.NUMBER(totalAmount)}</span>
                          <br />
                          <span
                            className={classNames({
                              'text-success':
                                Math.abs(splits_total_amount_difference) === 0,
                              'text-danger':
                                Math.abs(splits_total_amount_difference) !== 0,
                            })}
                          >
                            {FORMATTERS.NUMBER(splits_total_amount)}
                          </span>
                          <br />
                          <span>{FORMATTERS.NUMBER(splits_total_amount_difference)}</span>
                        </td>
                        <td colSpan="3" style={{ lineHeight: '18px' }}>
                          <span>
                            {splits_total_amount !== 0 &&
                              splits_total_amount_difference !== 0 && (
                                <StyledWarning>
                                  <FaExclamationTriangle
                                    size="1.5em"
                                    className="mr-2 text-yellow"
                                  />
                                  <span>
                                    A soma das partes não bate com o valor total da
                                    transação.
                                  </span>
                                </StyledWarning>
                              )}
                          </span>
                        </td>
                        <td colSpan="2" style={{ verticalAlign: 'top' }}>
                          <Button
                            id="btn-add-split"
                            variant="link"
                            className="m-0 p-0"
                            onClick={() =>
                              onCreateSplits(eventDate, description, setFieldValue)
                            }
                          >
                            Adicionar divisão
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td width="35%" colSpan="2" className="text-right">
                          {splits_total_amount_difference > 0 && (
                            <Button
                              variant="outline-dark"
                              size="sm"
                              onClick={handleCreateSplit}
                            >
                              Criar entrada de R${' '}
                              {FORMATTERS.NUMBER(splits_total_amount_difference)}
                            </Button>
                          )}
                        </td>
                        <td>&nbsp;</td>
                        <td colSpan="2">
                          <span>
                            {splits_total_amount !== 0 &&
                              splits_total_amount !== totalAmount && (
                                <StyledCheckbox
                                  type="checkbox"
                                  label={`Atualizar valor da transação para ${FORMATTERS.NUMBER(splits_total_amount)}`}
                                  checked={forceNewAmount}
                                  onChange={handleForceNewAmount}
                                  id="update_transaction_amount"
                                  className="mt-0 mb-0"
                                />
                              )}
                          </span>
                        </td>
                        <td colSpan="2">&nbsp;</td>
                      </tr>
                    </tfoot>
                  )}
                </SplitsTable>
              </div>
            </div>
          )}
        </StyledModal>
      )}
    </Formik>
  );
}

SplitTransactionModal.defaultProps = {
  isEditing: false,
  isLoading: false,
  recipients: [],
  transaction: {},
  onAfterSaveCallback: null,
  accounts: [],
};

SplitTransactionModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  recipients: PropTypes.array,
  transaction: PropTypes.object,
  onModalToggle: PropTypes.func.isRequired,
  onFetchSplits: PropTypes.func.isRequired,
  onCreateSplitTransaction: PropTypes.func.isRequired,
  onUpdateSplitTransaction: PropTypes.func.isRequired,
  onDeleteSplitTransaction: PropTypes.func.isRequired,
  onAfterSaveCallback: PropTypes.func,
  accounts: PropTypes.array,
  blockedPeriod: PropTypes.object.isRequired,
};

export default SplitTransactionModal;
