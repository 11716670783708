import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import SplitTransactionModal from './SplitTransactionModal';

const mapStateToProps = state => ({
  isLoading: state.companyTransactions.isLoadingSplits,
  activeCompany: state.company.activeCompany,
  accounts: state.companyTransactions.accounts,
  blockedPeriod: state.company.blockedPeriod,
});

const mapDispatchToProps = {
  onCreateSplitTransaction: companyTransactionsActionCreators.createSplitTransaction,
  onUpdateSplitTransaction: companyTransactionsActionCreators.updateSplitTransaction,
  onDeleteSplitTransaction: companyTransactionsActionCreators.deleteSplitTransaction,
  onFetchSplits: companyTransactionsActionCreators.fetchSplitsFromTransaction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SplitTransactionModal);
