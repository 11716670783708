import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import BankAccount from './BankAccount';

const mapStateToProps = (state) => ({
  selectedAccountIds: state.companyTransactions.selected_account_ids,
});

const mapDispatchToProps = {
  onChangeSelectedAccountIds: companyTransactionsActionCreators.setSelectedAccountIds,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BankAccount);
