import constants from '../../_constants/accounts.constants';

const editAccount = (account) => {
  return (dispatch) => {
    dispatch(request(account));
    dispatch(success(account));
  };

  function request(account) {
    return {
      type: constants.ACCOUNT_EDIT_REQUEST,
      payload: { account },
    };
  }
  function success(account) {
    return {
      type: constants.ACCOUNT_EDIT_SUCCESS,
      payload: { account },
    };
  }
};

export default editAccount;
