import { SPECIAL_COLUMNS } from '_components/_core/Table';
import FORMATTERS from 'helpers/formatters';

export const TABLE_COLUMNS = [
  {
    label: 'Código',
    keyName: 'id',
    sortingKey: 'id',
    align: 'center',
    width: 5,
  },
  {
    label: 'CPF',
    keyName: 'document_number',
    sortingKey: 'document_number',
    align: 'center',
    width: 20,
    formatter: FORMATTERS.CPF_CNPJ,
  },
  {
    label: 'Nome',
    keyName: 'name',
    sortingKey: 'name',
    width: 45,
  },
  {
    label: 'Participação',
    keyName: 'share',
    sortingKey: 'share',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.PERCENTAGE,
  },
  {
    label: 'Ações',
    keyName: 'id',
    sortingKey: 'id',
    align: 'center',
    width: 20,
    specialType: SPECIAL_COLUMNS.ACTIONS,
  },
];

export default TABLE_COLUMNS;
