import React from 'react';
import PropTypes from 'prop-types';
import { IoCaretDown } from 'react-icons/io5';

import { StyledTriggerContent } from '../../styles';

function TriggerContent({ costCenterText }) {
  return (
    <StyledTriggerContent className="d-flex align-items-center">
      {costCenterText}
      <IoCaretDown />
    </StyledTriggerContent>
  );
}

TriggerContent.propTypes = {
  costCenterText: PropTypes.string.isRequired,
};

export default TriggerContent;
