import { connect } from 'react-redux';

import userActionCreators from '_store/_actions/user';
import authActionCreators from '_store/_actions/auth';

import UpdateEmail from './UpdateEmail';

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  onUpdateEmailRequest: userActionCreators.updateEmailRequest,
  onUpdateEmailConfirm: userActionCreators.updateEmailConfirm,
  onLogout: authActionCreators.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEmail);
