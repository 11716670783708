import { connect } from 'react-redux';

import authSelectors from '_store/_selectors/auth';

import Menu from './Menu';

const mapStateToProps = (state) => ({
  user: authSelectors.currentUserSelector(state),
});

export default connect(mapStateToProps)(Menu);
