import { connect } from 'react-redux';

import employeesActionCreators from '_store/_actions/employees';

import EmploymentContract from './EmploymentContract';

const mapStateToProps = (state) => ({
  isLoading: state.reports.isLoading,
  activeCompany: state.company.activeCompany,
  employees: state.employees.employees,
});

const mapDispatchToProps = {
  onFetchEmployees: employeesActionCreators.getAllEmployees,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmploymentContract);
