import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import {
  Card,
  CardHeader,
  CardBody,
  Table,
} from '_components/_core';
import { TABLE_COLUMNS, DEFAULT_COLUMNS } from './utilities';

function Vacations({
  employeeVacations,
  onFetchEmployeeVacations,
}) {
  const { id } = useParams();

  useEffect(() => {
    onFetchEmployeeVacations(id);
  }, [id, onFetchEmployeeVacations]);

  return (
    <>
      <Card className="mb-3">
        <CardHeader
          title="Férias"
          description="Histórico de férias adquiridas e pagas do funcionário selecionado."
        />
        <CardBody>
          <div className="table-responsive">
            <Table
              className="table-hover"
              keyName="id"
              data={employeeVacations}
              columns={TABLE_COLUMNS}
              defaultColumns={DEFAULT_COLUMNS}
              showFooter
            />
          </div>
        </CardBody>
      </Card>

    </>
  );
}

Vacations.propTypes = {
  employeeVacations: PropTypes.array,
  onFetchEmployeeVacations: PropTypes.func.isRequired,
};

export default Vacations;
