import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '_components/_core';

import { LineItem } from '../../styles';

function ManageTagsItem() {
  const history = useHistory();

  return (
    <LineItem className="text-center">
      <Button
        variant="link"
        className="p-0 m-0"
        onClick={() => history.push('/tags')}
        noMargin
      >
        Gerenciar tags
      </Button>
    </LineItem>
  );
}

export default ManageTagsItem;
