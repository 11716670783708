import { connect } from 'react-redux';

import CashflowByTypeReport from './CashflowByTypeReport';

const mapStateToProps = (state) => ({
  isLoading: state.companyTransactions.isLoading,
  accounts: state.companyTransactions.accounts,
});

export default connect(mapStateToProps, null)(CashflowByTypeReport);
