import styled from 'styled-components';

import Table from 'react-bootstrap/Table';

export const StyledTable = styled(Table)`
  &&& {
    thead th {
      font-size: 13px;
      line-height: 13px;
      font-weight: bold;
      padding: 15px 15px !important;
      position: sticky; 
      top: 0; 
      z-index: 1;
      background-color: var(--th-bg-color);

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #d5dcec;
        box-shadow: inset 0px 25px 1px -10px #d5dcec;
      }
    }

    tbody {
      background-color: var(--th-bg-color);
    }

    tbody tr td {
      font-size: 13px;
      line-height: 13px;
      font-weight: normal;
      padding: 8px 30px !important;
    }

    tbody tr td span.employee-name {
      font-size: 14px;
      font-weight: bold;
      display: block;
    }

    tbody tr td span.event-type {
      display: block;
      margin-top: 8px;
      font-size: 12px;
      color: #8c8c8c !important;
    }

    tfoot tr th {
      font-size: 13px;
      line-height: 13px;
      font-weight: bold;
      padding: 15px 30px !important;
      position: sticky; 
      top: 0; 
      z-index: 1;
      background-color: var(--th-bg-color); 


      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--th-border-color);
        box-shadow: inset 0px 25px 1px -10px #d5dcec;
      }
    }
  }

`;

export const StyledField = styled.span`
  padding-left: 16px !important;
`;
