import * as yup from 'yup';
import { ptShort } from 'yup-locale-pt';

yup.setLocale(ptShort);

const CnaeFormSchema = yup.object().shape({
  cnae: yup.string().required(),
  isPrimary: yup.bool().required(),
});

export default CnaeFormSchema;
