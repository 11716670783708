import styled, { css } from 'styled-components';

export const Container = styled.span`
  cursor: pointer;
  display: inline-block;

  ${({ hoverOnly }) => hoverOnly && css`
    svg {
      display: none;
    }

    &:hover {
      svg {
        display: inline-block;
      }
    }
  `}
`;
