import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';

import { PageHeader, Tabs } from '_components/_core';

import { AccountingIntegrationModal } from '_components/_shared';

import { GeneratePayroll } from './components';

function Payroll({
  activeCompany,
  isLoading,
  onFetchPayrolls,
}) {
  const getTabs = useCallback(() => {
    const props = {
      company: activeCompany,
      isLoading,
      onFetchPayrolls,
    };

    return [
      {
        id: 1,
        title: 'Apurar Folha',
        content: <GeneratePayroll {...props} />,
      },
    ];
  }, [
    activeCompany,
    isLoading,
    onFetchPayrolls,
  ]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Folha de Pagamento"
        description="Faça a apuração ou insira manualmente o valor da folha de pagamento em um período"
        sideContent={(
          <div>
            <AccountingIntegrationModal type="payroll" />
          </div>
        )}
      />
      <Tabs variant="secondary" tabs={getTabs()} />
    </Container>
  );
}

Payroll.defaultProps = {
  isLoading: false,
};

Payroll.propTypes = {
  isLoading: PropTypes.bool,
  activeCompany: PropTypes.object,
  onFetchPayrolls: PropTypes.func.isRequired,
};

export default Payroll;
