import { DateTime } from 'luxon';
import * as XLSX from 'sheetjs-style';

import { CELL_TYPES } from './constants';

const reportToExcel = ({
  report_name,
  reports,
}) => {
  const { results } = reports[report_name];

  if (!results) return;

  const spreadsheetData = [
    ['Data', 'Receitas', 'Despesas', 'Resultado'],
  ];

  let total_incomes = 0;
  let total_expenses = 0;
  let total_result = 0;

  for (let i = 0; i < results.expenses.length; i += 1) {
    const income_item = results.incomes[i];
    const expense_item = results.expenses[i];

    let formattedDate = null;

    if (income_item[1].length > 7) {
      formattedDate = DateTime.fromFormat(income_item[1], 'yyyy-mm-dd', {
        zone: 'utc',
      }).set({ hour: 12 }).setLocale('pt-BR').toJSDate();
    } else {
      formattedDate = DateTime.fromISO(income_item[1]).setLocale('pt-BR').toFormat('MMMM yyyy');
    }

    total_incomes += income_item[2];
    total_expenses += expense_item[2];
    total_result += income_item[2] + (expense_item[2] * -1);

    spreadsheetData.push([
      formattedDate,
      income_item[2],
      expense_item[2] *= -1,
      income_item[2] + expense_item[2],
    ]);
  }

  spreadsheetData.push(
    [null, null, null, null],
    [
      'Total',
      total_incomes,
      total_expenses *= -1,
      total_result,
    ],
  );

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(spreadsheetData);

  worksheet['!cols'] = [{ width: 12 }, { width: 16 }, { width: 16 }, { width: 16 }];

  const invalidLines = [1, spreadsheetData.length - 1];

  worksheet.A1.s = CELL_TYPES.HEADERS;
  worksheet.B1.s = CELL_TYPES.HEADERS;
  worksheet.C1.s = CELL_TYPES.HEADERS;
  worksheet.D1.s = CELL_TYPES.HEADERS;
  worksheet[`A${spreadsheetData.length}`].s = CELL_TYPES.HEADERS;
  worksheet[`B${spreadsheetData.length}`].s = CELL_TYPES.HEADERS;
  worksheet[`C${spreadsheetData.length}`].s = CELL_TYPES.HEADERS;
  worksheet[`D${spreadsheetData.length}`].s = CELL_TYPES.HEADERS;

  for (let cell = 1; cell <= spreadsheetData.length; cell += 1) {
    if (!invalidLines.includes(cell) && cell !== spreadsheetData.length) {
      // worksheet[`A${cell}`].s = CELL_TYPES.EXPENSE;
      worksheet[`B${cell}`].s = CELL_TYPES.INCOME_cashflow_statement;
      worksheet[`C${cell}`].s = CELL_TYPES.EXPENSE_cashflow_statement;
      worksheet[`D${cell}`].s = spreadsheetData[cell - 1][3] >= 0 ? CELL_TYPES.INCOME_cashflow_statement : CELL_TYPES.EXPENSE_cashflow_statement;
      worksheet[`B${cell}`].t = 'n';
      worksheet[`B${cell}`].z = '#,##0.00';
      worksheet[`C${cell}`].t = 'n';
      worksheet[`C${cell}`].z = '#,##0.00';
      worksheet[`D${cell}`].t = 'n';
      worksheet[`D${cell}`].z = '#,##0.00';
    } else if (cell === spreadsheetData.length && !invalidLines.includes(cell)) {
      worksheet[`B${cell}`].t = 'n';
      worksheet[`B${cell}`].z = '#,##0.00';
      worksheet[`C${cell}`].t = 'n';
      worksheet[`C${cell}`].z = '#,##0.00';
      worksheet[`D${cell}`].t = 'n';
      worksheet[`D${cell}`].z = '#,##0.00';
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Histórico');

  XLSX.writeFile(workbook, 'Zenply_Historico_Receitas_Despesas.xlsx', {
    type: 'array',
    bookType: 'xlsx',
  });
};

export default reportToExcel;
