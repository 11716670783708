import { alertConstants } from '../_constants';

const INITIAL_STATE = {};

export function alert(state = INITIAL_STATE, action) {
  switch (action.type) {
    case alertConstants.ALERT_ERROR:
      return {
        ...state,
      };
    case alertConstants.ALERT_INFO:
      return {
        ...state,
      };
    case alertConstants.ALERT_SUCCESS:
      return {
        ...state,
      };
    case alertConstants.ALERT_WARNING:
      return {
        ...state,
      };
    default:
      return state;
  }
}
