import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/employees.constants';
import service from '../../../services/employees.service';
import alertActionCreators from '../alert.actions';

import getEmployeeDependents from './getEmployeeDependents';

const deleteEmployeeDependent = (employeeId, dependentId) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Exclusão de dependente',
      message: 'Tem certeza que deseja excluir este dependente? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, deletar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(employeeId, dependentId));

        service.deleteEmployeeDependent(employeeId, dependentId).then(
          () => {
            dispatch(success(employeeId));
            dispatch(alertActionCreators.success('Dependente excluído com sucesso!'));
            dispatch(getEmployeeDependents(employeeId));
          },
        ).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });

    dispatch(request());
  };

  function request() {
    return {
      type: constants.EMPLOYEE_DEPENDENTS_DELETE_REQUEST,
      payload: { },
    };
  }
  function success(id) {
    return {
      type: constants.EMPLOYEE_DEPENDENTS_DELETE_SUCCESS,
      payload: id,
    };
  }
  function failure(error) {
    return {
      type: constants.EMPLOYEE_DEPENDENTS_DELETE_FAILURE,
      payload: error,
    };
  }
};

export default deleteEmployeeDependent;
