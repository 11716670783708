import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

import Button from '_components/_core/Button';
import { Card } from '_components/_core';

export const Result = styled.div``;

export const ResultLine = styled.div``;

export const StyledButton = styled(Button)`
  margin-top: 22px;
`;

export const Container = styled.div`
`;

export const CardContainer = styled.div`
  display: flex;
`;

export const StyledCard = styled(Card)`
  flex-basis: 98%;
`;

export const StyledFormCheck = styled(Form.Check)`
  flex-basis: 2%;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;

  .form-check-input {
    cursor: pointer;
    margin-top: 0;
    margin-left: 0;
    width: 18px !important;
    height: 18px !important;
  }
`;
