import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import EFDContributions from '_components/Fiscal/EFDContributions/EFDContributionsContainer';
import SignIn from '../_components/SignIn/SignInContainer';
import SignUp from '../_components/SignUp/SignUpContainer';

import PasswordReset from '../_components/PasswordReset/PasswordResetContainer';
import PasswordUpdate from '../_components/PasswordUpdate/PasswordUpdateContainer';

import CompleteSignUp from '../_components/CompleteSignUp/CompleteSignUpContainer';

import Dashboard from '../_components/Dashboard/DashboardContainer';

import Automations from '../_components/Automations/AutomationsContainer';
import Companies from '../_components/Companies/List/ListContainer';
import CompanyCreate from '../_components/Companies/Create/CreateContainer';
import CompanyDetails from '../_components/Companies/Details/DetailsContainer';

import Users from '../_components/Users/List';
import Settings from '../_components/Settings/Settings';

import EntryLookup from '../_components/Accounting/Entries/EntryLookup/EntryLookupContainer';
import EntryCreate from '../_components/Accounting/Entries/Create/CreateContainer';
import BalanceSheet from '../_components/Accounting/Reports/BalanceSheet/BalanceSheetContainer';
import DRE from '../_components/Accounting/Reports/DRE/DREContainer';
import Ledger from '../_components/Accounting/Reports/Ledger/LedgerContainer';
import Accounts from '../_components/Accounting/Accounts';
import BankStatementImport from '../_components/Accounting/BankStatement/Import/ImportContainer';
import BankStatementReconcile from '../_components/Accounting/BankStatement/Reconcile/ReconcileContainer';

import IssueDarfs from '../_components/IssueDarfs/IssueDarfsContainer';

import Transactions from '../_components/Transactions/TransactionsContainer';

import {
  FiscalDashboard,
  InvoicesUpload,
  InvoicesList,
  RevenueReport,
  InvoicesReport,
  IssReport,
  Taxes,
  InvoicesCreate,
} from '../_components/Fiscal';
import {
  PayrollDashboard,
  EmployeesList,
  EmploymentContract,
  Payroll,
  Tables,
} from '../_components/Payroll';

import EmployeeCreate from '../_components/Payroll/Employees/Create/CreateContainer';

import EmployeeDetails from '../_components/Payroll/Employees/Details/DetailsContainer';

import { isAuthenticated } from '../services/auth.service';

import AuthorizedRoute from './AuthorizedRoute';
import UnauthorizedRoute from './UnauthorizedRoute';

const Routes = () => (
  <Switch>
    <Route exact path="/">
      <Redirect to={isAuthenticated() ? '/dashboard' : '/entrar'} />
    </Route>
    <UnauthorizedRoute path="/entrar" component={SignIn} title="Entrar" />

    <UnauthorizedRoute path="/cadastrar" component={SignUp} title="Cadastrar" />

    <UnauthorizedRoute path="/recuperar-senha" component={PasswordReset} title="Recuperar Senha" />
    <UnauthorizedRoute path="/redefinir-senha" component={PasswordUpdate} title="Redefinir Senha" />

    <UnauthorizedRoute path="/completar-cadastro" component={CompleteSignUp} title="Completar Cadastro" />

    <AuthorizedRoute path="/dashboard" component={Dashboard} title="Dashboard" />
    <AuthorizedRoute path="/automacoes" component={Automations} title="Automações" />

    <AuthorizedRoute exact path="/empresas" component={Companies} title="Empresas" />
    <AuthorizedRoute path="/empresas/criar" component={CompanyCreate} title="Cadastrar Empresa" />
    <AuthorizedRoute path="/empresas/:id/editar" component={CompanyCreate} title="Editar Empresa" />
    <AuthorizedRoute path="/empresas/:id/detalhes" component={CompanyDetails} title="Detalhe Empresa" />
    <AuthorizedRoute path="/empresa-ativa" component={CompanyDetails} title="Empresa Ativa" />

    <AuthorizedRoute path="/usuarios" component={Users} />
    <AuthorizedRoute path="/configuracoes" component={Settings} title="Configurações" />

    <AuthorizedRoute path="/contabil/lancamentos/cadastrar" component={EntryCreate} title="Novo Lançamento" />
    <AuthorizedRoute path="/contabil/lancamentos/:id/editar" component={EntryCreate} title="Editar Lançamento" />
    <AuthorizedRoute path="/contabil/lancamentos/consultar" component={EntryLookup} title="Consultar Lançamentos" />

    <AuthorizedRoute path="/contabil/extrato-bancario/importar" component={BankStatementImport} title="Importar Extrato Bancário" />
    <AuthorizedRoute path="/contabil/extrato-bancario/conciliar" component={BankStatementReconcile} title="Importar Lançamentos" />

    <AuthorizedRoute path="/contabil/relatorios/balancete" component={BalanceSheet} title="Balancete Analítico" />
    <AuthorizedRoute path="/contabil/relatorios/razao" component={Ledger} title="Razão Contábil" />
    <AuthorizedRoute path="/contabil/plano-contas" component={Accounts} title="Plano de Contas" />
    <AuthorizedRoute path="/contabil/relatorios/dre" component={DRE} title="DRE" />

    <AuthorizedRoute path="/fiscal/visao-geral" component={FiscalDashboard} title="Fiscal" />
    <AuthorizedRoute path="/fiscal/notas-fiscais/importar" component={InvoicesUpload} title="Importar Notas Fiscais" />
    <AuthorizedRoute path="/fiscal/notas-fiscais/emitir" component={InvoicesCreate} title="Emitir Notas Fiscais" />
    <AuthorizedRoute path="/fiscal/notas-fiscais/consultar" component={InvoicesList} title="Notas Fiscais" />
    <AuthorizedRoute path="/fiscal/impostos" component={Taxes} title="Impostos" />
    <AuthorizedRoute path="/fiscal/relatorios/faturamento" component={RevenueReport} title="Relatório de Faturamento" />
    <AuthorizedRoute path="/fiscal/relatorios/notas-fiscais" component={InvoicesReport} title="Relatório de Notas" />
    <AuthorizedRoute path="/fiscal/relatorios/iss" component={IssReport} title="Relatório de ISS" />
    <AuthorizedRoute path="/fiscal/declaracoes/efd-contribuicoes" component={EFDContributions} title="EFD Contribuições" />

    <AuthorizedRoute path="/folha/visao-geral" component={PayrollDashboard} title="Folha" />
    <AuthorizedRoute path="/folha/folha-de-pagamento" component={Payroll} title="Folha de Pagamento" />
    <AuthorizedRoute path="/folha/funcionarios" component={EmployeesList} title="Funcionários" />
    <AuthorizedRoute path="/folha/contratos/contrato-de-trabalho" component={EmploymentContract} title="Contrato de Trabalho" />
    <AuthorizedRoute path="/folha/referencias" component={Tables} title="Referências" />

    <AuthorizedRoute path="/utilitarios/emissao-de-guias" component={IssueDarfs} title="Emissão de Guias" />

    <AuthorizedRoute path="/funcionarios/criar" component={EmployeeCreate} title="Cadastrar Funcionário" />
    <AuthorizedRoute path="/funcionarios/:id/editar" component={EmployeeCreate} title="Editar Funcionário" />
    <AuthorizedRoute path="/funcionarios/:id/detalhes" component={EmployeeDetails} title="Detalhes do Funcionário" />

    <AuthorizedRoute path="/financeiro/dashboard" component={Dashboard} title="Dashboard" />
    <AuthorizedRoute path="/financeiro/transacoes" component={Transactions} title="Transações" />
    <AuthorizedRoute path="/financeiro/relatorios" component={Transactions} title="Relatórios" />
    <AuthorizedRoute path="/financeiro/contatos" component={Transactions} title="Contatos" />
    <AuthorizedRoute path="/financeiro/mei" component={Taxes} title="MEI" />

  </Switch>
);

export default Routes;
