import constants from '../../_constants/associates.constants';
import service from '../../../services/associates.service';
import alertActionCreators from '../alert.actions';

const updateAssociate = (id, associate) => {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(request(associate));

    service.update(id, associate).then(
      (associate) => {
        dispatch(success(associate));
        dispatch(alertActionCreators.success('Sócio atualizado com sucesso'));
        resolve();
      },
      (error) => {
        if (error.response.status === 400) {
          dispatch(failure(error.response.data));
        }
        reject();
      },
    );
  });

  function request(associate) {
    return {
      type: constants.ASSOCIATES_UPDATE_REQUEST,
      payload: { associate },
    };
  }
  function success(associate) {
    return {
      type: constants.ASSOCIATES_UPDATE_SUCCESS,
      payload: { associate },
    };
  }
  function failure(errors) {
    return {
      type: constants.ASSOCIATES_UPDATE_FAILURE,
      payload: { errors },
    };
  }
};

export default updateAssociate;
