import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Formik } from 'formik';
import { IoReaderOutline } from 'react-icons/io5';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import { Button, FormTextField } from '_components/_core';

import { StyledModal, ModalFooter } from './styles';

const UpdateEntriesHistory = ({
  isLoading,
  selectedItems,
  onUpdateEntries,
  onComplete,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleUpdateNarrations = useCallback((values, { resetForm }) => {
    onUpdateEntries({
      ids: selectedItems,
      type: 'narration',
      payload: {
        ...values,
      },
    }, () => {
      resetForm();
      setIsVisible(false);

      if (onComplete) {
        onComplete();
      }
    });
  }, [selectedItems, onComplete, onUpdateEntries]);

  const renderModalFooter = useCallback((handleSubmit, values) => (
    <ModalFooter>
      <Button variant="secondary" onClick={() => setIsVisible(false)}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={isLoading || !values.narration}
      >
        {`Alterar histórico de ${selectedItems.length} ${pluralize('lançamento', selectedItems.length)}`}
      </Button>
    </ModalFooter>
  ), [isLoading, selectedItems]);

  return (
    <Formik
      initialValues={{
        narration: null,
        replace_criteria: null,
      }}
      onSubmit={handleUpdateNarrations}
      enableReinitialize
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
      }) => (
        <>
          <Button
            className="mr-2 btn-sm"
            variant="light"
            onClick={() => setIsVisible(true)}
            icon={<IoReaderOutline />}
          >
            Alterar Histórico
          </Button>
          <StyledModal
            title="Alterar Histórico"
            isVisible={isVisible}
            toggleModal={() => setIsVisible(false)}
            footer={renderModalFooter(handleSubmit, values)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12" className="mb-0">
                  <Form.Label>Novo Histórico</Form.Label>
                  <FormTextField name="narration" placeholder="Texto que será usado como o novo histórico dos lançamentos" />
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="radio"
                      id="replace_criteria_current"
                      label=" Substituir ## por MÊS/ANO da transação."
                      checked={values.replace_criteria === 'current'}
                      onChange={() => setFieldValue('replace_criteria', 'current')}
                      disabled={!values.narration || !values.narration.includes('##')}
                    />
                    <Form.Check
                      type="radio"
                      id="replace_criteria_month_before"
                      label=" Substituir ## por MÊS_ANTERIOR/ANO da transação."
                      checked={values.replace_criteria === 'month_before'}
                      onChange={() => setFieldValue('replace_criteria', 'month_before')}
                      disabled={!values.narration || !values.narration.includes('##')}
                    />
                  </Form.Group>
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default UpdateEntriesHistory;

UpdateEntriesHistory.defaultProps = {
  isLoading: false,
  onComplete: null,
};

UpdateEntriesHistory.propTypes = {
  isLoading: PropTypes.func,
  selectedItems: PropTypes.array.isRequired,
  onUpdateEntries: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
};
