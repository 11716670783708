import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { IoTrash } from 'react-icons/io5';
import { FaPencilAlt } from 'react-icons/fa';
import { startCase } from 'lodash';
import toLower from 'lodash/toLower';

import FORMATTERS from 'helpers/formatters';
import {
  PageHeader,
  Button,
  Tabs,
  Skeleton,
} from '_components/_core';

import {
  BasicInfo,
  Address,
  Salaries,
  Vacations,
  Dependents,
} from './components';

export default function Details({
  onFetchEmployee,
  isLoading,
}) {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const { id } = useParams();

  const {
    name,
    employee_role_description,
    document_number,
  } = selectedEmployee || {};

  useEffect(() => {
    if (id && !isLoaded) {
      onFetchEmployee(id, (employee) => {
        setSelectedEmployee({
          ...employee,
        });
      });
      setIsLoaded(true);
    }
  }, [id, selectedEmployee, onFetchEmployee, isLoaded]);

  const history = useHistory();

  const handleEmployeeInfoChange = useCallback((employee) => {
    setSelectedEmployee(employee);
  }, []);

  const renderButtons = useCallback(() => (
    <>
      <Button
        iconPosition="left"
        onClick={() => history.push(`/funcionarios/${id}/editar`)}
        variant="outline-primary ml-2"
        icon={<FaPencilAlt size="0.9rem" />}
        noMargin
      >
        Editar
      </Button>
      <Button
        iconPosition="left"
        variant="outline-danger"
        icon={<IoTrash size="0.9rem" />}
        noMargin
      >
        Excluir
      </Button>
    </>
  ), [id, history]);

  const getTabs = useCallback(() => {
    const props = {
      employee: selectedEmployee,
      onEmployeeInfochange: handleEmployeeInfoChange,
    };

    return [
      {
        id: 1,
        title: 'Informações Cadastrais',
        content: <BasicInfo {...props} />,
      },
      {
        id: 2,
        title: 'Endereço',
        content: <Address {...props} />,
      },
      {
        id: 3,
        title: 'Salários',
        content: <Salaries {...props} />,
      },
      {
        id: 4,
        title: 'Férias',
        content: <Vacations {...props} />,
      },
      {
        id: 5,
        title: 'Dependentes',
        content: <Dependents {...props} />,
      },
    ];
  }, [selectedEmployee, handleEmployeeInfoChange]);

  const employeeName = useMemo(() => {
    if (!name || isLoading) {
      return <Skeleton width="55%" />;
    }

    return startCase(toLower(name));
  }, [name, isLoading]);

  const employeeInfo = useMemo(() => {
    if (!document_number || isLoading) {
      return <Skeleton width="35%" />;
    }

    return `${FORMATTERS.CPF_CNPJ(document_number)} - ${employee_role_description}`;
  }, [document_number, employee_role_description, isLoading]);

  return (
    <Container className="content-wrapper fixedHeader" fluid>
      <PageHeader
        title={employeeName}
        description={employeeInfo}
        withBackButton
        fixedHeader
        sideContent={renderButtons()}
      />
      <Tabs variant="secondary" tabs={getTabs()} />
    </Container>
  );
}

Details.defaultProps = {
  isLoading: false,
};

Details.propTypes = {
  isLoading: PropTypes.bool,
  activeEmployee: PropTypes.object,
  onFetchEmployee: PropTypes.func.isRequired,
};
