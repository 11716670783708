import { logsConstants } from '../_constants';

const INITIAL_STATE = {
  isLoading: false,
  logs: [],
  total: 0,
  possibleEntities: [],
};

export function logs(state = INITIAL_STATE, action) {
  switch (action.type) {
    case logsConstants.FETCH_LOGS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case logsConstants.FETCH_LOGS_SUCCESS: {
      const {
        total,
        data,
        possibleEntities,
      } = action.payload || {};

      return {
        ...state,
        isLoading: false,
        total,
        logs: data,
        possibleEntities,
      };
    }
    case logsConstants.FETCH_LOGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
