import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { HiChevronUpDown } from 'react-icons/hi2';
import { FaPlus } from 'react-icons/fa';

import { useMediaQuery } from 'helpers';
import FORMATTERS from 'helpers/formatters';
import { Button, Skeleton } from '_components/_core';
import { LoadingIcon } from '_components/_shared';

import {
  TitleDescription,
  HeaderTitle,
  HeaderDescription,
  ActiveCompany,
  ActiveCompanyInfo,
  TitleDescriptionDisabledLink,
  StyledCompanyAvatar,
  CustomFilterButton,
} from './styles';

const DEFAULT_LOGO_URL = 'https://contabil-easy.s3.sa-east-1.amazonaws.com/assets/zenply-company-icon.png';

export default function NavbarLayout({
  isLoading,
  company,
  onModalToggle,
  collapsed,
  companies,
}) {
  const { trading_name, document_number, company_avatar_url } = company || {};
  const history = useHistory();

  const { isMobile, isTablet } = useMediaQuery();

  const formattedCnpj = useMemo(() => {
    if (!document_number) {
      return '';
    }

    return FORMATTERS.CPF_CNPJ(document_number);
  }, [document_number]);

  const renderContent = useCallback(() => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    if (isLoading) {
      return (
        <div>
          <LoadingIcon text="" />
        </div>
      );
    }

    if (isEmpty(companies) && !company) {
      return null;
    }

    if (!company) {
      return null;
    }

    if (product === 'contabills-empresas') {
      if (isMobile || isTablet) {
        return (
          <CustomFilterButton variant="link" className="mr-3 d-flex justify-content-center">
            {FORMATTERS.MAX_X_CHARS(trading_name, 10)}
          </CustomFilterButton>
        );
      }

      return (
        <TitleDescriptionDisabledLink>
          <StyledCompanyAvatar src={company_avatar_url || DEFAULT_LOGO_URL} />
          <HeaderTitle className="pr-3">
            {trading_name && trading_name.toLowerCase()}
          </HeaderTitle>
        </TitleDescriptionDisabledLink>
      );
    }

    return (
      <TitleDescription to={company ? '/empresa-ativa' : ''}>
        <HeaderTitle>
          {trading_name && trading_name.toLowerCase()}
        </HeaderTitle>
        <HeaderDescription>
          {formattedCnpj}
        </HeaderDescription>
      </TitleDescription>
    );
  }, [
    company,
    companies,
    formattedCnpj,
    trading_name,
    isLoading,
    company_avatar_url,
    isMobile,
    isTablet,
  ]);

  const renderButton = useCallback(() => {
    if (isLoading) {
      return (
        <Skeleton width="30%" height="8px" className="mt-2" />
      );
    }

    if (isEmpty(companies) && !company) {
      const product = process.env.REACT_APP_CONTABILLS_APP;

      if (product === 'contabills-empresas') {
        return (
          <Button
            variant="link"
            onClick={() => history.push('/nova-empresa')}
          >
            {isMobile ? <FaPlus /> : 'Cadastrar Empresa'}
          </Button>
        );
      }

      return (
        <Button
          variant="link"
          onClick={() => history.push('/empresas/criar')}
        >
          Cadastrar Empresa
        </Button>
      );
    }

    return (
      <Button
        variant="link"
        onClick={onModalToggle}
        icon={<HiChevronUpDown size="1.1em" />}
        className="p-0"
      />
    );
  }, [companies, history, onModalToggle, isLoading, company, isMobile]);

  return (
    <ActiveCompany onClick={onModalToggle} collapsed={collapsed}>
      <ActiveCompanyInfo>
        {renderButton()}
        {renderContent()}
      </ActiveCompanyInfo>
    </ActiveCompany>
  );
}

NavbarLayout.propTypes = {
  company: {},
  collapsed: false,
  isLoading: false,
};

NavbarLayout.propTypes = {
  companies: PropTypes.array,
  company: PropTypes.object,
  collapsed: PropTypes.bool,
  isLoading: PropTypes.bool,
  onModalToggle: PropTypes.func.isRequired,
};
