import constants from '../../_constants/employees.constants';
import service from '../../../services/employees.service';

import getAllEmployeeJourneys from './getEmployeeJourneys';

const addEmployeeJourney = (journey) => {
  return (dispatch) => {
    dispatch(request());

    service.addJourney(journey).then(
      (response) => {
        const createdJourney = response.data;

        dispatch(success(createdJourney));
        dispatch(getAllEmployeeJourneys());
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.EMPLOYEE_JOURNEYS_ADD_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.EMPLOYEE_JOURNEYS_ADD_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.EMPLOYEE_JOURNEYS_ADD_FAILURE,
      payload: error,
    };
  }
};

export default addEmployeeJourney;
