import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';
import { DateTime } from 'luxon';

import {
  Card,
  CardBody,
  FormTextField,
  FormSelectField,
  FormCurrencyField,
} from '_components/_core';
import { AddressFormFields, FormMarketSegmentField } from '_components/_shared';

import { COMPANY_OPTIONS, EMPLOYEES_NUMBER, SIMPLES_NACIONAL_NFE_OPTION } from '../../utilities';

import FormHeader from '../FormHeader/FormHeader';
import FormFooter from '../FormFooter/FormFooter';
import FormInitialBalanceField from '../FormInitialBalanceField/FormInitialBalanceField';

import CreateFinancialCompanySchema from '../../utilities/CreateFinancialCompanySchema';

function FormSmallCompany({
  user,
  menuItem,
  onChange,
  onAddFinancialCompany,
  isLoading,
  isOnboarding,
}) {
  const handleSubmitForm = useCallback((values) => {
    const params = {
      status_irs: 'ATIVA',
      date_founded: DateTime.now().toFormat('yyyy-MM-dd'),
      trading_name: values.company_name,
      ...values,
    };

    onAddFinancialCompany(params, isOnboarding);
  }, [onAddFinancialCompany, isOnboarding]);

  return (
    <Formik
      initialValues={{
        type: menuItem.key,
        company_name: user.document_type === 'PF' ? user.full_name : user.first_name,
        address_state_ibge: null,
        address_state: null,
        address_city_ibge: null,
        address_city: null,
        employees_count: 'NONE',
        balance_type: 'POSITIVO',
        initial_balance: 0,
        trading_name: null,
      }}
      onSubmit={handleSubmitForm}
      validationSchema={CreateFinancialCompanySchema}
      enableReinitialize
    >
      {({ handleSubmit, isValid }) => (
        <>
          <Card>
            <CardBody>
              <FormHeader menuItem={menuItem} />
              <hr className="pb-2 mt-4" />
              <Form.Row>
                <Form.Group as={Col} lg="6">
                  <Form.Label>Nome da sua empresa</Form.Label>
                  <FormTextField name="company_name" placeholder="Digite aqui o nome..." />
                </Form.Group>
                <Form.Group as={Col} lg="6">
                  <Form.Label>Nome Fantasia da sua empresa</Form.Label>
                  <FormTextField name="trading_name" placeholder="Digite aqui o nome fantasia..." />
                </Form.Group>
                <Form.Group as={Col} lg="6">
                  <FormMarketSegmentField />
                </Form.Group>
              </Form.Row>
              <AddressFormFields variant="STATE_AND_CITY" />
              <Form.Row>
                <Form.Group as={Col} lg="4">
                  <Form.Label>Saldo inicial em conta:</Form.Label>
                  <FormCurrencyField name="initial_balance" placeholder="Valor Inicial" />
                </Form.Group>
                <Form.Group as={Col} xl="8">
                  <FormInitialBalanceField name="balance_type" />
                </Form.Group>
              </Form.Row>
              <hr className="mt-2 mb-4" />
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>A minha empresa:</Form.Label>
                  <FormSelectField
                    name="company_type"
                    options={COMPANY_OPTIONS}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Qual a quantidade de funcionários?</Form.Label>
                  <FormSelectField
                    name="company_employee_count"
                    options={EMPLOYEES_NUMBER}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>Sua empresa é optante pelo Simples Nacional?</Form.Label>
                  <FormSelectField
                    name="company_simples_nacional_optant"
                    options={SIMPLES_NACIONAL_NFE_OPTION}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>
                    Sua empresa emite Nota fiscal eletrônica?
                  </Form.Label>
                  <FormSelectField
                    name="company_issues_nfe"
                    options={SIMPLES_NACIONAL_NFE_OPTION}
                  />
                </Form.Group>
              </Form.Row>
            </CardBody>
          </Card>
          <FormFooter
            isLoading={isLoading}
            onSubmit={handleSubmit}
            onChange={onChange}
            isValid={isValid}
          />
        </>
      )}
    </Formik>
  );
}

FormSmallCompany.defaultProps = {
  isLoading: false,
  isOnboarding: false,
};

FormSmallCompany.propTypes = {
  user: PropTypes.object,
  menuItem: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isOnboarding: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onAddFinancialCompany: PropTypes.func.isRequired,
};

export default FormSmallCompany;
