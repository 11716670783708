import constants from '../../_constants/accounts.constants';

const cancelNewAccount = () => {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return {
      type: constants.ACCOUNT_NEW_CANCEL_REQUEST,
      payload: {},
    };
  }
};

export default cancelNewAccount;
