import styled from 'styled-components';
import { Button } from '_components/_core';

import { CategorySelect as CustomCategorySelect } from '_components/_shared';
import CustomSelectField from '../CustomSelectField/CustomSelectField';

export const TypeSelect = styled(CustomSelectField)`
  width: 200px;
  margin-right: 12px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }  
`;

export const CategorySelect = styled(CustomCategorySelect)`
  width: 200px;
  margin-right: 12px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-top: 8px;
  }
`;

export const RecipientSelect = styled(CustomSelectField)`
  width: 220px;
  margin-right: 12px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-top: 8px;
  }
`;

export const TransferSelect = styled(CustomSelectField)`
  width: 250px;
  margin-right: 12px;
`;

export const StyledButton = styled(Button)`
  @media (max-width: 768px) {
    width: 50%;
    height: 30px;
    margin-top: 15px;
    text-align: center;
    margin: 0 auto;
    margin-top: 16px;
  }
`;
