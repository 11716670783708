import { connect } from 'react-redux';

import FormBankAccountSelectField from './FormBankAccountSelectField';

const mapStateToProps = (state) => ({
  accounts: state.companyTransactions.accounts,
  balance: state.companyTransactions.balance,
});

export default connect(mapStateToProps, null)(FormBankAccountSelectField);
