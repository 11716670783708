import { DateTime } from 'luxon';
import isNull from 'lodash/isNull';

const getBlockedPeriodMessage = blocked_period => {
  if (!blocked_period) {
    return '';
  }

  const { active_period_start, active_period_end } = blocked_period || {};

  if (isNull(active_period_start) || isNull(active_period_end)) {
    return '';
  }

  let startLuxon = DateTime.fromISO(active_period_start, { zone: 'utc' });
  let endLuxon = DateTime.fromISO(active_period_end, { zone: 'utc' });

  return `Não é possível modificar este item. O período ${startLuxon.toFormat('dd/MM/yyyy')} até ${endLuxon.toFormat('dd/MM/yyyy')} está bloqueado para alterações.`;
};

export default getBlockedPeriodMessage;
