import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

export const StyledFormCheck = styled(Form.Check)`

  label  {
    display: inline-block !important;
    cursor: pointer;
    user-select: none;
  }

  input {
    cursor: pointer;
  }
`;
