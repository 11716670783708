import { invoicesConstants } from '../_constants';

const INITIAL_STATE = {
  invoices: [],
  total: 0,
  uploadResults: null,
  isLoading: false,
  invoiceGenerationParams: null,
  issued_invoices_total: 0,
  issued_invoices: [],
  isLoadingParams: false,
};

export function invoices(state = INITIAL_STATE, action) {
  switch (action.type) {
    case invoicesConstants.INVOICES_GET_ALL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case invoicesConstants.INVOICES_GET_ALL_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        total,
        invoices: data,
        isLoading: false,
      };
    }
    case invoicesConstants.INVOICES_GET_ALL_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case invoicesConstants.INVOICES_UPLOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case invoicesConstants.INVOICES_UPLOAD_SUCCESS: {
      const { uploadResults } = action.payload;
      return {
        ...state,
        isLoading: false,
        uploadResults,
      };
    }
    case invoicesConstants.INVOICES_UPLOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case invoicesConstants.INVOICES_DELETE_MANY_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case invoicesConstants.INVOICES_DELETE_MANY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case invoicesConstants.INVOICES_DELETE_MANY_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case invoicesConstants.GET_FISCAL_OVERVIEW_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case invoicesConstants.GET_FISCAL_OVERVIEW_SUCCESS: {
      const data = action.payload;

      return {
        ...state,
        isLoading: false,
        fiscalOverview: data,
      };
    }

    case invoicesConstants.GET_FISCAL_OVERVIEW_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case invoicesConstants.CLEAR_UPLOAD_RESULTS: {
      return {
        ...state,
        isLoading: false,
        uploadResults: null,
      };
    }

    case invoicesConstants.CLEAR_INVOICES: {
      return {
        ...state,
        isLoading: false,
        invoices: [],
        total: 0,
      };
    }

    case invoicesConstants.GENERATE_INVOICE_ENTRIES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case invoicesConstants.GENERATE_INVOICE_ENTRIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case invoicesConstants.GENERATE_INVOICE_ENTRIES_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case invoicesConstants.SYNC_INVOICES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case invoicesConstants.SYNC_INVOICES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        uploadResults: {
          ...action.payload,
        },
      };
    }

    case invoicesConstants.SYNC_INVOICES_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case invoicesConstants.GET_INVOICE_GENERATION_PARAMS_REQUEST: {
      return {
        ...state,
        isLoadingParams: true,
      };
    }
    case invoicesConstants.GET_INVOICE_GENERATION_PARAMS_SUCCESS: {
      return {
        ...state,
        isLoadingParams: false,
        invoiceGenerationParams: action.payload,
      };
    }
    case invoicesConstants.GET_INVOICE_GENERATION_PARAMS_FAILURE: {
      return {
        ...state,
        isLoadingParams: false,
      };
    }

    case invoicesConstants.CREATE_ISSUED_INVOICE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case invoicesConstants.CREATE_ISSUED_INVOICE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case invoicesConstants.CREATE_ISSUED_INVOICE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case invoicesConstants.UPDATE_ISSUED_INVOICE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case invoicesConstants.UPDATE_ISSUED_INVOICE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case invoicesConstants.UPDATE_ISSUED_INVOICE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case invoicesConstants.GET_ISSUED_INVOICES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case invoicesConstants.GET_ISSUED_INVOICES_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        issued_invoices_total: total,
        issued_invoices: data,
        isLoading: false,
      };
    }
    case invoicesConstants.GET_ISSUED_INVOICES_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case invoicesConstants.GET_ISSUED_INVOICE_BY_ID_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case invoicesConstants.GET_ISSUED_INVOICE_BY_ID_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case invoicesConstants.GET_ISSUED_INVOICE_BY_ID_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case invoicesConstants.CONFIRM_INVOICE_ISSUING_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case invoicesConstants.CONFIRM_INVOICE_ISSUING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case invoicesConstants.CONFIRM_INVOICE_ISSUING_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case invoicesConstants.DUPLICATE_ISSUED_INVOICES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case invoicesConstants.DUPLICATE_ISSUED_INVOICES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case invoicesConstants.DUPLICATE_ISSUED_INVOICES_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case invoicesConstants.DELETE_ISSUED_INVOICE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case invoicesConstants.DELETE_ISSUED_INVOICE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case invoicesConstants.DELETE_ISSUED_INVOICE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case invoicesConstants.DELETE_MULTIPLE_ISSUED_INVOICES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case invoicesConstants.DELETE_MULTIPLE_ISSUED_INVOICES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case invoicesConstants.DELETE_MULTIPLE_ISSUED_INVOICES_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
}
