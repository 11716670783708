import { Form } from 'react-bootstrap';
import styled, { css } from 'styled-components';

export const StyledRow = styled.tr`

  ${({ viewType }) => viewType === 'complete' && css`
    td {
      height: 60px !important;
    }

  `}
`;

export const StyledSearchInput = styled(Form.Control)`
  border: none;
  box-shadow: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-weight: 500;
  background-color: transparent;

  &:focus, &:active, &:hover {
    background-color: transparent;
  }
`;

export const FiltersContainer = styled.div`
  
`;

export const StyledCreateTr = styled.tr`
  td {
    padding: 0 !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  ${({ hasCreatePermission }) => !hasCreatePermission && css`
    height: 0px;
    padding: 0 !important;

    td {
      height: 0px !important;
      padding: 0px !important;

      button {
        display: none;
      }
    }
  `}
`;

export const StyledCreateTd = styled.td`
  text-align: center;
  /* padding-top: 6px !important;
  padding-bottom: 6px !important; */
`;
