import { connect } from 'react-redux';

import CashflowQuarter from './CashflowQuarter';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  accounts: state.companyTransactions.accounts,
  cashflow: state.companyTransactions.cashflow,
});

export default connect(mapStateToProps, null)(CashflowQuarter);
