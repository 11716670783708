import styled from 'styled-components';

import Modal from '~/_components/_core/Modal';

export const StyledModal = styled(Modal)`
  padding: 0 !important;

  div.modal-body {
    overflow-y: auto;
    -webkit-print-color-adjust:exact;
  }
`;

export const PayrollContentTable = styled.table`
  tbody tr.row-header td {
    padding: 8px;
    color: #999;
    font-size: 9px;
    font-weight: normal;
  }

  tbody tr.row-values td {
    padding: 8px;
    color: #212529;
    font-size: 16px;
    font-weight: bold;

    span {
      display: block;
      color: #999;
      font-size: 14px;
      font-weight: normal;
      margin-top: 8px;
    }
  }

  tbody tr.row-values.normal td {
    padding: 8px;
    color: #212529;
    font-size: 14px;
    font-weight: normal;
  }

  tbody tr.row-header.with-background  {
    td {
      background: #eee !important;
      font-weight: bold;
      font-size: 12px;
      color: #212529;
    }
  }

  tbody tr td.with-background {
    background: #eee !important;
    font-weight: bold !important;
    font-size: 16px !important;
    color: #212529;
  }

  tbody tr td.total-cell {
    font-weight: bold !important;
    font-size: 12px !important;
    color: #212529;
  }

  tbody tr td.no-border {
    border: none;
  }
  
  tbody tr td span.employee-role {
    font-size: 12px;  
  }

  div td {
    font-size: 12px;
    color: #212529;
  }
  
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
