import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import Form from 'react-bootstrap/Form';

import { CurrencyInput } from '_components/_core';

function FormCurrencyField({
  placeholder,
  disabled,
  innerRef,
  className,
  inputStyleProps,
  forceZero,
  id,
  ...props
}) {
  const formik = useFormikContext();

  const [field, meta] = useField(props);

  const error = useMemo(
    () => (meta.touched && meta.error ? meta.error : ''),
    [meta.error, meta.touched],
  );

  const handleChange = useCallback(
    value => {
      if (!value && forceZero) {
        formik.setFieldValue(field.name, 0);
        return;
      }

      formik.setFieldValue(field.name, value);
    },
    [field, formik, forceZero],
  );

  return (
    <>
      <Form.Control
        id={id}
        as={CurrencyInput}
        name={field.name}
        value={field.value}
        type="tel"
        autoComplete="off"
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={e => e.target.select()}
        disabled={disabled}
        innerRef={innerRef}
        className={className}
        style={inputStyleProps}
      />
      {error && <small className="text-danger">{error}</small>}
    </>
  );
}

FormCurrencyField.defaultProps = {
  disabled: false,
  innerRef: null,
  className: '',
  inputStyleProps: {},
  forceZero: false,
  id: null,
};

FormCurrencyField.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  innerRef: PropTypes.object,
  inputStyleProps: PropTypes.object,
  forceZero: PropTypes.bool,
  id: PropTypes.string,
};

export default FormCurrencyField;
