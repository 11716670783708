import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function CardBody({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

CardBody.propTypes = {
  children: PropTypes.any,
};
