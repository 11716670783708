export const DREConstants = {
  DRE_GET_REQUEST: 'DRE_GET_REQUEST',
  DRE_GET_SUCCESS: 'DRE_GET_SUCCESS',
  DRE_GET_FAILURE: 'DRE_GET_FAILURE',

  DRE_ERASE_REQUEST: 'DRE_ERASE_REQUEST',
  DRE_ERASE_SUCCESS: 'DRE_ERASE_SUCCESS',
  DRE_ERASE_FAILURE: 'DRE_ERASE_FAILURE',
};

export default DREConstants;
