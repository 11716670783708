import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import LineChartReport from './LineChartReport';

const mapStateToProps = (state) => ({
  isLoading: state.companyTransactions.isLoading,
  reports: state.companyTransactions.reports,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onGenerateTransactionReport: companyTransactionsActionCreators.generateTransactionReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(LineChartReport);
