import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Tab from 'react-bootstrap/Tab';
import { IoPrintOutline } from 'react-icons/io5';
import ReactToPrint from 'react-to-print';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';
import { Report, ReportPage, ReportHeader } from '_components/_shared';
import { TABLE_COLUMNS, DEFAULT_COLUMNS } from '_components/_shared/InvoiceFilters/utilities';

import { paginateResults } from './utilities';
import { StyledTable } from './styles';

function Results({
  values,
  activeCompany,
  invoices,
  sorting,
  onSorting,
}) {
  const reportRef = useRef();

  const { company_name, document_number } = activeCompany || {};

  const paginatedResults = useMemo(() => {
    if (isEmpty(invoices)) {
      return [];
    }

    return paginateResults(invoices);
  }, [invoices]);

  if (isEmpty(invoices)) {
    return null;
  }

  return (
    <Tab.Content className="mt-3 mt-lg-0">
      <div className="d-flex align-items-end">
        <ReactToPrint
          trigger={() => (
            <button
              type="button"
              placement="left"
              className="btn btn-sm btn-icons btn-secondary mr-2 mb-3"
            >
              <IoPrintOutline />
            </button>
          )}
          content={() => reportRef.current}
          documentTitle="Relatório de Faturamento"
        />
      </div>
      <Report ref={reportRef}>
        {Object.keys(paginatedResults).map((key, index) => (
          <ReportPage>
            <ReportHeader
              name="RELATÓRIO DE NOTAS FISCAIS"
              companyName={company_name}
              companyDocumentNumber={FORMATTERS.CPF_CNPJ(document_number)}
              startDate={values.date_competence_start}
              endDate={values.date_competence_end}
              currentPage={index + 1}
              totalPages={paginatedResults ? Object.keys(paginatedResults).length : 0}
            />
            <div className="table-responsive">
              <StyledTable
                key={key}
                name="invoices-report-table"
                keyName="id"
                data={paginatedResults[key]}
                columns={TABLE_COLUMNS}
                defaultColumns={DEFAULT_COLUMNS}
                sorting={sorting}
                onSorting={onSorting}
                showColumnToggle
                footerData={invoices}
                showFooter={Number(key) === Object.keys(paginatedResults).length}
              />
            </div>

          </ReportPage>
        ))}
      </Report>
    </Tab.Content>
  );
}

Results.defaultProps = {
  invoices: null,
};

Results.propTypes = {
  values: PropTypes.object,
  activeCompany: PropTypes.object,
  invoices: PropTypes.array,
  sorting: PropTypes.object,
  onSorting: PropTypes.func,
};

export default Results;
