import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const deleteSplitTransaction = (id, callback) => {
  return async (dispatch) => {
    confirmDialog.warning({
      title: 'Excluir divisões',
      message: 'Confirma a exclusão de todas as divisões? A transação original continuará existindo.',
      confirmButtonText: 'Sim, excluir!',
      icon: 'info',
      onConfirm: () => {
        dispatch(request(id));

        service.deleteSplitTransaction(id).then(
          (response) => {
            dispatch(success(response.data));

            if (callback) {
              callback();
            }
          },
        ).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request(params) {
    return {
      type: constants.DELETE_SPLIT_TRANSACTION_REQUEST,
      payload: params,
    };
  }
  function success(data) {
    return {
      type: constants.DELETE_SPLIT_TRANSACTION_SUCCESS,
      payload: {
        transactions: data,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_SPLIT_TRANSACTION_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default deleteSplitTransaction;
