import constants from '../../_constants/fiscal.constants';
import service from '../../../services/fiscal.service';
import alertActionCreators from '../alert.actions';

import getAllPersons from './getAllPersons';

const updatePerson = (id, person, params) => {
  return (dispatch) => {
    dispatch(request(person));

    service.updatePerson(id, person, params).then(
      (response) => {
        const updatedPerson = response.data;

        dispatch(success(updatedPerson));
        dispatch(alertActionCreators.success('Cliente atualizado com sucesso!'));
        dispatch(getAllPersons(id));
      },
    ).catch(({ response }) => {
      if (response.status === 422) {
        const { message } = response.data;

        dispatch(failure(message));
        dispatch(alertActionCreators.error(message));
      }
    });
  };

  function request(person) {
    return {
      type: constants.PERSON_UPDATE_REQUEST,
      payload: { person },
    };
  }
  function success(person) {
    return {
      type: constants.PERSON_UPDATE_SUCCESS,
      payload: { person },
    };
  }
  function failure(message) {
    return {
      type: constants.PERSON_UPDATE_FAILURE,
      payload: { message },
    };
  }
};

export default updatePerson;
