import styled, { css } from 'styled-components';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

export const Container = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;

  ${({ fixedHeader }) => fixedHeader === true && css`
    position: sticky;
    top: 66px;
    z-index: 993;
    padding: 2rem;
    background-color: var(--card-bg-color);
    margin-top: -1rem;
    margin-left: -1em;
    margin-right: -1em;
    border: var(--card-border);
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px,
                rgb(0 0 0 / 0%) 0px 0px 0px 0px,
                rgb(0 0 0 / 10%) 0px 1px 3px 0px,
                rgb(0 0 0 / 6%) 0px 1px 2px 0px;

    @media (min-width: 769px) {
      margin-left: -2em;
      margin-right: -2em;
    } 
  `}

  ${({ variant }) => variant === 'small' && css`
    padding: 1em 2em;
    background-color: var(--vz-card-bg-custom);
    box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
    margin: -1em -2em 1em -2em;
    background-color: var(--card-bg-color);

    /*  */

    h3 {
      font-weight: 700;
      font-size: 16px!important;
      text-transform: uppercase;
      margin-bottom: 0;
      display: inline-block;
    }

    p {
      display: none;
    }
  `}
`;

export const StyledCol = styled(Col)`
  ${({ isMobile }) => isMobile && css`
    overflow-x: auto;
    padding-top: 14px;
    padding-bottom: 8px;
  `}
`;

export const Title = styled.h3`
  font-weight: 500;
`;

export const Description = styled.p`
  color: #AAAEBB;
  font-size: 1rem;
  margin-bottom: 0;
`;

export const BackButton = styled(Button)`
  width: 46px;
  height: 46px;
  border-radius: 8px;
  padding: 4px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
              rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
              rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, 
              rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  svg {
    color: #464de4;
  }
`;
