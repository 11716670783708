import { connect } from 'react-redux';
import { compose } from 'redux';

import companyActionCreators from '_store/_actions/company';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import AllowedPeriod from './AllowedPeriod';

const mapStateToProps = state => ({
  activeCompany: state.company.activeCompany,
  blockedPeriod: state.company.blockedPeriod,
  isLoadingBlockedPeriod: state.company.isLoadingBlockedPeriod,
});

const mapDispatchToProps = {
  onFetchBlockedPeriod: companyActionCreators.getBlockedPeriod,
  onUpdateActivePeriod: companyActionCreators.updateBlockedPeriod,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['manager_blocked_periods']),
)(AllowedPeriod);
