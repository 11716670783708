import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/mei.constants';
import service from '../../../services/mei.service';

import alertActionCreators from '../alert.actions';

const deleteMei = (id, callback) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Exclusão de MEI',
      message: 'Tem certeza que deseja excluir este MEI? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, deletar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.deleteMei(id).then(() => {
          dispatch(success(id));
          dispatch(alertActionCreators.success('MEI excluído com sucesso.'));

          if (callback) {
            callback();
          }
        }).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {

      },
    });
  };

  function request() {
    return {
      type: constants.DELETE_MEI_REQUEST,
      payload: { },
    };
  }
  function success(id) {
    return {
      type: constants.DELETE_MEI_SUCCESS,
      payload: id,
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_MEI_FAILURE,
      payload: error,
    };
  }
};

export default deleteMei;
