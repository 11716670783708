import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import CardHeader from '../CardHeader';

import styles from './styles.module.scss';

export default function Card({
  children,
  title,
  description,
  sideContent,
  noBorders,
  className,
  contained,
  style,
}) {
  const renderCardHeader = () => {
    if (!isEmpty(title)) {
      return <CardHeader title={title} description={description} sideContent={sideContent} />;
    }

    return null;
  };

  return (
    <div style={style} className={classNames('card', className, noBorders ? styles.noBorders : '', contained ? styles.contained : '')}>
      {renderCardHeader()}
      {children}
    </div>
  );
}

Card.defaultProps = {
  title: null,
  description: null,
  sideContent: null,
  noBorders: false,
  className: '',
  contained: false,
  style: {},
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  sideContent: PropTypes.node,
  noBorders: PropTypes.bool,
  className: PropTypes.string,
  contained: PropTypes.bool,
  style: PropTypes.object,
};
