import React, {
  useState,
  useLayoutEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

export const ThemeContext = React.createContext({
  dark: false,
  toggle: () => {},
});

export default function ThemeProvider({ children }) {
  // const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

  const collapsed = useSelector((state) => state.sidebar.collapsed);
  const [dark, setDark] = useState(false);

  const applyTheme = useCallback(() => {
    let theme;
    let responsiveTheme;

    if (dark) {
      theme = darkTheme;
    }

    if (!dark) {
      theme = lightTheme;
    }

    const app = process.env.REACT_APP_CONTABILLS_APP;

    if (app === 'contabills-empresas') {
      if (dark) {
        theme = darkThemeZenPaper;
      }

      if (!dark) {
        theme = lightThemeZenPaper;
      }
    }

    if (collapsed) {
      responsiveTheme = collapsedTheme;
    }

    if (!collapsed) {
      responsiveTheme = normalTheme;
    }

    const finalTheme = [
      ...theme,
      ...responsiveTheme,
    ];

    const root = document.getElementsByTagName('html')[0];

    root.style.cssText = finalTheme.join(';');
  }, [dark, collapsed]);

  useLayoutEffect(() => {
    applyTheme();
  }, [dark, applyTheme]);

  const toggle = () => {
    const body = document.getElementsByTagName('body')[0];

    body.style.cssText = 'transition: background .5s ease';

    setDark(!dark);
  };

  return (
    <ThemeContext.Provider
      value={{
        isDarkThemeActive: dark,
        onDarkThemeToggle: toggle,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

const normalTheme = [
  '--wrapper-padding-top: calc(66px + 1rem)',
  '--wrapper-padding-left: calc(13rem + 1rem)',
  '--sidebar-size: 13rem !important',
];

const collapsedTheme = [
  '--wrapper-padding-top: calc(66px + 1rem)',
  '--wrapper-padding-left: calc(80px + 1rem)',
  '--sidebar-size: 80px',
];

const lightTheme = [
  '--color-sidebar-bg: #fff',
  '--color-main-bg: #F1F5F9',
  '--color-text-primary: #354168',
  '--navbar-color: #FFFFFF',
  '--navbar-box-shadow: 0 1px 5px -3px rgb(0 0 0 / 75%)',

  '--title-color: #354168',
  '--accent-color: #727cf5',
  '--accent-color-light: rgba(114, 124, 245, 0.1)',

  '--navbar-border-bottom: none',
  '--sidebar-border-right: none',

  '--card-border: none',
  '--card-bg-color: #fff',

  '--font-family: "IBM Plex Sans"',

  '--th-color: #223C61',
  '--tr-color-hover: #eff3f5',
  '--th-border-color-: #4d5b75',
  '--th-bg-color:  #fff',
  '--td-border-color: #d5dcec',
  '--input-bg-color: #fff',
  '--sidebar-navbar-box-shadow:  0 1px 5px -3px rgb(0 0 0 / 75%)',
  '--mobile-button-box-shadow:  0 8px 5px -3px rgb(0 0 0 / 25%)',

  '--form-control-border: 1px solid #d5dcec',
  '--form-control-focus-color: #495057',
  '--form-control-background-color: #fff',
  '--form-control-border-color: #80bdff',

  '--tabs-border-color: rgb(226, 232, 240)',
];

const lightThemeZenPaper = [
  ...lightTheme,
  '--color-main-bg: #F4F5F8',
  '--font-family: "Poppins"',
  '--color-text-primary: #354168',
  '--color-text-primary-light: rgba(53, 65, 104, 0.1)',
  '--accent-color: #11924F',
  '--accent-color-light: rgba(17, 146, 79, 0.1)',
];

const darkTheme = [
  '--color-sidebar-bg: #0c1427',
  '--color-main-bg: #070d19',
  '--color-text-primary: #d6d7dc',

  '--title-color: #d6d7dc',
  '--accent-color: #727cf5',

  '--navbar-border-bottom: 1px solid #172340',
  '--sidebar-border-right: 1px solid #172340',

  '--card-border: 1px solid #172340',
  '--card-bg-color: #0c1427',

  '--font-family: "IBM Plex Sans"',

  '--table-color: #9aabcb',
  '--th-color: #7886a1',
  '--tr-color-hover: #080e1b',
  '--td-border-color: #4d5b75',
  '--th-bg-color: #283142',
  '--sidebar-navbar-box-shadow: 0 1px 5px -3px rgb(0 0 0 / 75%)',
  '--table-th-td-border-top: 1px solid #262f43',
  '--input-bg-color: transparent',

  '--form-control-border: 1px solid #262f43',
  '--form-control-focus-color: #eef4ff',
  '--form-control-background-color: #04060c',
  '--form-control-border-color: #727cf5',

  '--tabs-border-color: rgb(71, 85, 105)',

];

const darkThemeZenPaper = [
  ...darkTheme,
  '--color-main-bg: #1b2531',
  '--font-family: "Poppins"',
  '--navbar-color: #283142',
  '--color-sidebar-bg: #283142',
  '--card-bg-color: #283142',
  '--navbar-border-bottom: none',
  '--navbar-box-shadow: 0 1px 8px -1px rgb(0 0 0 / 75%)',

];
