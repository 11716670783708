import constants from '../../_constants/entries.constants';
import service from '../../../services/entry.service';
import alertActionCreators from '../alert.actions';

const addEntry = (entry, callback) => {
  return (dispatch) => {
    dispatch(request(entry));

    service.insert(entry).then(
      (response) => {
        dispatch(success(response.data));
        dispatch(alertActionCreators.success('Lançamento realizado com sucesso.'));

        if (callback) {
          callback();
        }
      },
      (error) => {
        dispatch(failure(error));
      },
    );
  };

  function request(entry) {
    return {
      type: constants.ENTRY_ADD_REQUEST,
      payload: { entry },
    };
  }
  function success(entry) {
    return {
      type: constants.ENTRY_ADD_SUCCESS,
      payload: { entry },
    };
  }
  function failure(error) {
    return {
      type: constants.ENTRY_ADD_FAILURE,
      payload: { error },
    };
  }
};

export default addEntry;
