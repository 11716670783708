import { connect } from 'react-redux';

import revenueActionCreators from '_store/_actions/company';

import PastRevenues from './PastRevenues';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  revenue: state.company.revenue,
  isLoading: state.company.isLoading,
});

const mapDispatchToProps = {
  onFetchPastRevenue: revenueActionCreators.getCompanyRevenue,
  onSaveRevenues: revenueActionCreators.saveRevenues,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PastRevenues);
