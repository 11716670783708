import { connect } from 'react-redux';

import employeeActionCreators from '_store/_actions/employees';

import EmployeeJourneys from './EmployeeJourneys';

const mapStateToProps = (state) => ({
  journeys: state.employees.employeeJourneys,
  total: state.employees.totalJourneys,
});

const mapDispatchToProps = {
  onFetchEmployeeJourneys: employeeActionCreators.getEmployeeJourneys,
  onAddEmployeeJourney: employeeActionCreators.addEmployeeJourney,
  onUpdateEmployeeJourney: employeeActionCreators.updateEmployeeJourney,
  onDeleteEmployeeJourney: employeeActionCreators.deleteEmployeeJourney,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeJourneys);
