import constants from '../../_constants/invoices.constants';
import service from '../../../services/invoices.service';

const getIssuedInvoiceById = (id, callback) => {
  return (dispatch) => {
    dispatch(request());

    service.getIssuedInvoiceById(id).then(
      (response) => {
        const invoice = response.data;

        dispatch(success(invoice));

        if (callback) {
          callback(invoice.invoice);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.GET_ISSUED_INVOICE_BY_ID_REQUEST,
      payload: { },
    };
  }
  function success(invoices) {
    return {
      type: constants.GET_ISSUED_INVOICE_BY_ID_SUCCESS,
      payload: invoices,
    };
  }
  function failure(error) {
    return {
      type: constants.GET_ISSUED_INVOICE_BY_ID_FAILURE,
      payload: error,
    };
  }
};

export default getIssuedInvoiceById;
