import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import styles from './styles.module.scss';

export default function InputErrorMessage({ fieldName, errorMessages }) {
  if (!fieldName || isEmpty(errorMessages) || errorMessages.error) return null;

  const errorMessage = errorMessages.find((message) => message.field === fieldName);

  if (!errorMessage) return null;

  return (
    <div className={styles.container}>
      <small className="text-danger">{errorMessage.message}</small>
    </div>
  );
}

InputErrorMessage.propTypes = {
  fieldName: PropTypes.string.isRequired,
  errorMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
};
