import { paymentsConstants } from '../_constants';

const INITIAL_STATE = {
  isLoading: false,
  payments: [],
  total: 0,
};

export function payments(state = INITIAL_STATE, action) {
  switch (action.type) {
    case paymentsConstants.FETCH_PAYMENTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case paymentsConstants.FETCH_PAYMENTS_SUCCESS: {
      const payments = action.payload;

      return {
        ...state,
        payments,
        isLoading: false,
      };
    }
    case paymentsConstants.FETCH_PAYMENTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
