import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from 'react-router-dom';
import { IoArrowBackOutline } from 'react-icons/io5';
import isEmpty from 'lodash/isEmpty';

import { useMediaQuery } from 'helpers';

import {
  Container,
  Title,
  Description,
  BackButton,
} from './styles';

function PageHeader({
  title,
  description,
  withBackButton,
  sideContent,
  actionContent,
  fixedHeader,
  variant,
  className,
  bottomContent,
  ...rest
}) {
  const { isMobile } = useMediaQuery();
  const history = useHistory();

  if (isMobile && isEmpty(sideContent)) {
    return null;
  }

  return (
    <>
      <Container className={className} variant={variant} fixedHeader={fixedHeader} {...rest}>
        {variant === 'small' && (
          <>
            <Row>
              <Col className={`d-flex justify-content-${isMobile ? 'center' : 'between'} align-items-center`}>
                <Title>{!isMobile && title}</Title>
                {sideContent}
              </Col>
            </Row>
            {bottomContent}
          </>
        )}
        {variant !== 'small' && (
          <Row className="d-flex align-items-center justify-content-between">
            <div>
              <div className="d-flex align-items-center">
                {withBackButton && (
                <BackButton variant="light" onClick={() => history.goBack()}>
                  <IoArrowBackOutline size="1.5em" />
                </BackButton>
                )}
                <div className={classNames('w-100', withBackButton ? 'ml-3' : '')}>
                  <Title>{title}</Title>
                  <Description>{description}</Description>
                </div>
              </div>
            </div>
            <div
              xs={12}
              sm={12}
              lg={3}
              isMobile={isMobile}
              className="d-flex flex-row-reverse align-items-center"
            >
              {sideContent}
            </div>
          </Row>
        )}
      </Container>
      {variant === 'small' && actionContent && (
        <Row className="bg-white rounded box-shadow p-3 mb-3">
          <Col sm="12">
            {actionContent}
          </Col>
        </Row>
      )}
    </>
  );
}

PageHeader.propTypes = {
  withBackButton: false,
  fixedHeader: false,
  variant: null,
  actionContent: null,
  description: null,
  className: null,
  bottomContent: null,
  rest: {},
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  sideContent: PropTypes.node,
  bottomContent: PropTypes.node,
  actionContent: PropTypes.node,
  withBackButton: PropTypes.bool,
  fixedHeader: PropTypes.bool,
  variant: PropTypes.string,
  rest: PropTypes.any,
};

export default PageHeader;
