import React from 'react';
import PropTypes from 'prop-types';

import { IoCaretDown, IoChevronDown } from 'react-icons/io5';
import { StyledTriggerContent, StyledNoBalanceTriggerContent } from '../../styles';

function TriggerContent({
  variant,
  displayBalance,
  accountText,
  balanceText,
  balanceClassName,
  canViewBalances,
}) {
  if (!displayBalance) {
    return (
      <StyledNoBalanceTriggerContent>
        {accountText}
        <IoCaretDown />
      </StyledNoBalanceTriggerContent>
    );
  }

  return (
    <StyledTriggerContent variant={variant}>
      <div>
        <div className="test">
          {`Saldo - ${accountText}`}
        </div>
        <div>
          <span className={balanceClassName}>
            {canViewBalances ? balanceText : '---'}
          </span>
        </div>
      </div>
      <IoChevronDown size="1.5rem" />
    </StyledTriggerContent>
  );
}

TriggerContent.propTypes = {
  variant: PropTypes.string.isRequired,
  accountText: PropTypes.node.isRequired,
  balanceText: PropTypes.string.isRequired,
  balanceClassName: PropTypes.string.isRequired,
  displayBalance: PropTypes.bool.isRequired,
  canViewBalances: PropTypes.bool.isRequired,
};

export default TriggerContent;
