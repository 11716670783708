import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { IoArrowForward } from 'react-icons/io5';
import { useStripe } from '@stripe/react-stripe-js';
import pluralize from 'pluralize';

import { Button } from '_components/_core';
import FORMATTERS from 'helpers/formatters';

import { Col, Row } from 'react-bootstrap';
import {
  PlanPrice,
  Plans,
  Plan,
  PlanName,
} from './styles';

const PlansModal = ({
  subscription,
  user,
  plans,
  isLoading,
  onModalToggle,
  onUpdatePlan,
  onCreateAuthorizedCheckoutSession,
}) => {
  const stripe = useStripe();
  const product = process.env.REACT_APP_CONTABILLS_APP;

  const isChangingPlans = useMemo(() => {
    if (!subscription) {
      return false;
    }

    const { status } = subscription;

    if (status === 'canceled' || status === 'trialing' || status === 'trial-ended') {
      return false;
    }

    return true;
  }, [subscription]);

  const handleConfirm = useCallback((values) => {
    if (isChangingPlans) {
      onUpdatePlan({
        plan_id: values.plan_id,
      }, () => {
        onModalToggle();
      });
    } else {
      onCreateAuthorizedCheckoutSession({
        ...values,
      }, (session_id) => {
        stripe.redirectToCheckout({ sessionId: session_id });
      });
    }
  },
  [
    onCreateAuthorizedCheckoutSession,
    onUpdatePlan,
    onModalToggle,
    isChangingPlans,
    stripe,
  ]);

  const renderModalFooter = useCallback((handleSubmit, values) => {
    const isSamePlan = isChangingPlans && values.plan_id === subscription?.plan_id;
    const isDisabled = !values.plan_id || isLoading || isSamePlan;

    return (
      <div className="mt-3">
        <Button
          type="submit"
          variant="dark"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={isDisabled}
          icon={<IoArrowForward />}
        >
          {isChangingPlans ? 'Confirmar Alteração de Plano' : 'Ir para Checkout'}
        </Button>
      </div>
    );
  }, [isLoading, subscription, isChangingPlans]);

  // const renderFeatures = useCallback((metadata) => {
  //   const keys = Object.keys(metadata);

  //   return (
  //     <PlanFeatures>
  //       {keys.map((key) => (
  //         <li>
  //           <IoStar />
  //           {' '}
  //           {metadata[key]}
  //         </li>
  //       ))}
  //     </PlanFeatures>
  //   );
  // }, []);

  const isManager = useMemo(() => {
    if (!user) {
      return false;
    }

    return user.manager_id === null;
  }, [user]);

  const modalTitle = useMemo(() => {
    if (!subscription) {
      return 'Ativar Assinatura';
    }

    const { status } = subscription;

    if (status === 'canceled' || status === 'trialing' || status === 'trial-ended') {
      return 'Ativar Assinatura';
    }

    return 'Alterar Plano';
  }, [subscription]);

  const modalDescription = useMemo(() => {
    if (!subscription) {
      return 'Para ativar sua assinatura, escolha um plano:';
    }

    const { status } = subscription;

    if (status === 'canceled' || status === 'trialing' || status === 'trial-ended') {
      return 'Para ativar sua assinatura, escolha um plano:';
    }

    return 'Selecione o plano desejado, a cobrança atualizada será feita no próximo mês:';
  }, [subscription]);

  const availablePlans = useMemo(() => {
    if (!user) {
      return [];
    }

    if (product === 'contabills') {
      return plans;
    }

    const type = user.document_type === 'PJ' ? 'Empresarial' : 'Pessoal';

    return plans.filter((plan) => plan.metadata.plan_type === type);
  }, [plans, user, product]);

  const getIntervalName = (interval, interval_count) => {
    if (interval === 'month' && interval_count === 1) {
      return 'por mês';
    }

    if (interval === 'month' && interval_count === 6) {
      return 'por semestre';
    }

    if (interval === 'year') {
      return 'por ano';
    }

    return '';
  };

  if (!isManager) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        user_id: user ? user.id : null,
        plan_id: null,
        plan_type: user.document_type === 'PJ' ? 'Empresarial' : 'Pessoal',
      }}
      onSubmit={handleConfirm}
      enableReinitialize
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
      }) => (
        <>
          <h5 className="mt-2">
            {modalTitle}
          </h5>
          <p>{modalDescription}</p>
          <Row>
            <Col lg={{
              span: 6,
              offset: 0,
            }}
            >
              {/* <div className="w-100 d-flex justify-content-center align-items-center mb-3 mt-4">
                <Button
                  variant={values.plan_type === 'Pessoal' ? 'dark' : 'outline-dark'}
                  className="mr-3"
                  onClick={() => {
                    setFieldValue('plan_type', 'Pessoal');
                    setFieldValue('plan_id', null);
                  }}
                >
                  Planos pessoais
                </Button>
                <Button
                  variant={values.plan_type === 'Empresarial' ? 'dark' : 'outline-dark'}
                  onClick={() => {
                    setFieldValue('plan_type', 'Empresarial');
                    setFieldValue('plan_id', null);
                  }}
                >
                  Planos empresariais
                </Button>
              </div> */}
              <Plans>
                {availablePlans.map((plan) => (
                  <>
                    <Plan
                      key={plan.id}
                      active={values.plan_id === plan.id}
                      onClick={() => setFieldValue('plan_id', plan.id)}
                    >
                      <div>
                        <PlanName>
                          <span>
                            {plan.name}
                          </span>
                        </PlanName>
                        <PlanPrice>
                          {FORMATTERS.NUMBER(plan.price)}
                          <small>
                            &nbsp;/ {getIntervalName(plan.interval, plan.interval_count)}
                          </small>
                        </PlanPrice>
                      </div>
                      <div>
                        <div>
                          Até {plan.metadata.max_users_count} {pluralize('usuário', plan.metadata.max_users_count)}
                        </div>
                        <div>
                          Até {plan.metadata.max_companies_count}  {pluralize('empresas', plan.metadata.max_companies_count)}
                        </div>
                      </div>
                    </Plan>
                  </>
                ))}
              </Plans>
              <div className="float-right">
                {renderModalFooter(handleSubmit, values)}
              </div>
            </Col>
          </Row>

        </>
      )}
    </Formik>
  );
};

export default PlansModal;

PlansModal.defaultProps = {
  isLoading: false,
  plans: [],
  user: null,
  subscription: null,
};

PlansModal.propTypes = {
  subscription: PropTypes.object,
  plans: PropTypes.array,
  user: PropTypes.object,
  isLoading: PropTypes.bool,
  onModalToggle: PropTypes.func.isRequired,
  onUpdatePlan: PropTypes.func.isRequired,
  onCreateAuthorizedCheckoutSession: PropTypes.func.isRequired,
};
