export const meiConstants = {
  FETCH_ALL_COMPANY_MEIS_REQUEST: 'FETCH_ALL_COMPANY_MEIS_REQUEST',
  FETCH_ALL_COMPANY_MEIS_SUCCESS: 'FETCH_ALL_COMPANY_MEIS_SUCCESS',
  FETCH_ALL_COMPANY_MEIS_FAILURE: 'FETCH_ALL_COMPANY_MEIS_FAILURE',

  CREATE_MEI_REQUEST: 'CREATE_MEI_REQUEST',
  CREATE_MEI_SUCCESS: 'CREATE_MEI_SUCCESS',
  CREATE_MEI_FAILURE: 'CREATE_MEI_FAILURE',

  UPDATE_MEI_REQUEST: 'UPDATE_MEI_REQUEST',
  UPDATE_MEI_SUCCESS: 'UPDATE_MEI_SUCCESS',
  UPDATE_MEI_FAILURE: 'UPDATE_MEI_FAILURE',

  DELETE_MEI_REQUEST: 'DELETE_MEI_REQUEST',
  DELETE_MEI_SUCCESS: 'DELETE_MEI_SUCCESS',
  DELETE_MEI_FAILURE: 'DELETE_MEI_FAILURE',

  FETCH_MEI_DAS_REQUEST: 'FETCH_MEI_DAS_REQUEST',
  FETCH_MEI_DAS_SUCCESS: 'FETCH_MEI_DAS_SUCCESS',
  FETCH_MEI_DAS_FAILURE: 'FETCH_MEI_DAS_FAILURE',
};

export default meiConstants;
