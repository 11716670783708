import uploadInvoices from './uploadInvoices';
import getAllInvoices from './getAllInvoices';
import getFiscalOverview from './getFiscalOverview';
import clearUploadResults from './clearUploadResults';
import clearInvoices from './clearInvoices';
import deleteManyInvoices from './deleteManyInvoices';
import generateInvoiceEntries from './generateInvoiceEntries';
import syncInvoices from './syncInvoices';
import getInvoiceGenerationParams from './getInvoiceGenerationParams';
import createIssuedInvoice from './createIssuedInvoice';
import updateIssuedInvoice from './updateIssuedInvoice';
import getIssuedInvoices from './getIssuedInvoices';
import getIssuedInvoiceById from './getIssuedInvoiceById';
import confirmInvoicesIssuing from './confirmInvoicesIssuing';
import deleteMultipleIssuedInvoices from './deleteMultipleIssuedInvoices';
import deleteIssuedInvoice from './deleteIssuedInvoice';
import duplicateIssuedInvoices from './duplicateIssuedInvoices';

export default {
  uploadInvoices,
  getAllInvoices,
  getFiscalOverview,
  clearUploadResults,
  clearInvoices,
  deleteManyInvoices,
  generateInvoiceEntries,
  syncInvoices,
  getInvoiceGenerationParams,
  createIssuedInvoice,
  updateIssuedInvoice,
  getIssuedInvoices,
  getIssuedInvoiceById,
  confirmInvoicesIssuing,
  deleteMultipleIssuedInvoices,
  deleteIssuedInvoice,
  duplicateIssuedInvoices,
};
