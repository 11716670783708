import constants from '../../_constants/taxes.constants';

const clearTaxes = () => {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return {
      type: constants.CLEAR_TAXES_REQUEST,
      payload: {},
    };
  }
};

export default clearTaxes;
