import styled from 'styled-components';

import { Table } from '_components/_core';

export const StyledTable = styled(Table)`
  &&& {
    thead tr th {
      padding: 10px 15px !important;
    }
    tbody tr td {
      padding: 10px 15px !important;
    }
    tfoot tr td {
      padding: 10px 15px !important;
    }
  }
`;
