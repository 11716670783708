export const associatesConstants = {
  ASSOCIATES_GET_ALL_REQUEST: 'ASSOCIATES_GET_ALL_REQUEST',
  ASSOCIATES_GET_ALL_SUCCESS: 'ASSOCIATES_GET_ALL_SUCCESS',
  ASSOCIATES_GET_ALL_FAILURE: 'ASSOCIATES_GET_ALL_FAILURE',
  ASSOCIATES_GET: 'ASSOCIATES_GET',
  ASSOCIATES_ADD_REQUEST: 'ASSOCIATES_ADD_REQUEST',
  ASSOCIATES_ADD_SUCCESS: 'ASSOCIATES_ADD_SUCCESS',
  ASSOCIATES_ADD_FAILURE: 'ASSOCIATES_ADD_FAILURE',
  ASSOCIATES_DELETE_REQUEST: 'ASSOCIATES_DELETE_REQUEST',
  ASSOCIATES_DELETE_SUCCESS: 'ASSOCIATES_DELETE_SUCCESS',
  ASSOCIATES_UPDATE_REQUEST: 'ASSOCIATES_UPDATE_REQUEST',
  ASSOCIATES_UPDATE_SUCCESS: 'ASSOCIATES_UPDATE_SUCCESS',
  ASSOCIATES_UPDATE_FAILURE: 'ASSOCIATES_UPDATE_FAILURE',
};

export default associatesConstants;
