import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import { TiArrowSortedDown } from 'react-icons/ti';
import { Formik } from 'formik';
import { DateTime } from 'luxon';

import {
  Button,
  FormTextField,
  FormCpfCnpjField,
} from '_components/_core';
import { AddressFormFields, FormMarketSegmentField } from '_components/_shared';

import { CompanySchema } from '../../utilities';
import {
  StyledModal,
  ModalFooter,
  StyledButton,
  Option,
} from './styles';

const EditFinancialCompanyModal = ({
  isVisible,
  isLoading,
  onUpdateFinancialCompany,
  onModalToggle,
  selectedCompany,
}) => {
  const handleEditCompany = useCallback((values, { resetForm }) => {
    onUpdateFinancialCompany(selectedCompany.id, values, () => {
      resetForm();
      onModalToggle();
    });
  }, [onModalToggle, onUpdateFinancialCompany, selectedCompany]);

  const renderModalFooter = useCallback((handleSubmit, isValid) => (
    <ModalFooter>
      <Button variant="secondary" onClick={onModalToggle}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={!isValid || isLoading}
      >
        Salvar
      </Button>
    </ModalFooter>
  ), [onModalToggle, isLoading]);

  if (!isVisible) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        company_name: selectedCompany ? selectedCompany.company_name : '',
        trading_name: selectedCompany ? selectedCompany.trading_name : '',
        document_number: selectedCompany ? selectedCompany.document_number : '',
        address_state_ibge: selectedCompany ? selectedCompany.address_state_ibge : '',
        address_city_ibge: selectedCompany ? selectedCompany.address_city_ibge : '',
        address_state: selectedCompany ? selectedCompany.address_state : '',
        address_city: selectedCompany ? selectedCompany.address_city : '',
        address_street: selectedCompany ? selectedCompany.address_street : '',
        address_number: selectedCompany ? selectedCompany.address_number : '',
        address_complement: selectedCompany ? selectedCompany.address_complement : '',
        address_district: selectedCompany ? selectedCompany.address_district : '',
        address_zip_code: selectedCompany ? selectedCompany.address_zip_code : '',
        market_segment_id: selectedCompany ? selectedCompany.market_segment_id : '',
        status_irs: 'ATIVA',
        date_founded: DateTime.now().toFormat('yyyy-MM-dd'),
        document_type: selectedCompany ? selectedCompany.document_type : '',
      }}
      validationSchema={CompanySchema}
      onSubmit={handleEditCompany}
      enableReinitialize
    >
      {({
        handleSubmit,
        isValid,
        values,
        setFieldValue,
      }) => (
        <>
          <StyledModal
            title="Editar Empresa"
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit, isValid)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="6" className="mb-4">
                  <Form.Label>Nome da Empresa</Form.Label>
                  <FormTextField
                    name="company_name"
                    placeholder="Digite o nome da empresa."
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-4">
                  <Form.Label>Nome Fantasia da Empresa</Form.Label>
                  <FormTextField
                    name="trading_name"
                    placeholder="Digite o nome fantasia da empresa."
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Dropdown>
                    <StyledButton variant="link" as={Dropdown.Toggle} className="pl-0 pb-0">
                      {values.document_type === 'CPF' ? 'CPF' : 'CNPJ'}
                      <TiArrowSortedDown size="1.1em" className="ml-1" />
                    </StyledButton>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setFieldValue('document_type', 'CPF')}>
                        <Option>CPF</Option>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFieldValue('document_type', 'CNPJ')}>
                        <Option>CNPJ</Option>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <FormCpfCnpjField
                    name="document_number"
                    type={values.document_type === 'CPF' ? 'CPF' : 'CNPJ'}
                    placeholder="Digite o número do documento"
                  />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FormMarketSegmentField />
                </Form.Group>
              </Form.Row>
              <hr />
              <AddressFormFields
                columnSizes={{
                  cep: 6,
                  searchButton: 4,
                  street: 4,
                  number: 4,
                  district: 4,
                  complement: 4,
                  city: 4,
                  state: 4,
                }}
              />
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default EditFinancialCompanyModal;

EditFinancialCompanyModal.propTypes = {
  isVisible: PropTypes.bool,
  onModalToggle: PropTypes.func.isRequired,
  onUpdateFinancialCompany: PropTypes.func.isRequired,
  selectedCompany: PropTypes.any,
  isLoading: PropTypes.bool,
};
