const getTransactionDescription = (transaction = {}) => {
  if (!transaction) {
    return '-';
  }

  if (transaction.payment_plan === 'INSTALMENT') {
    return `${transaction.description} (${transaction.frequency_number}/${transaction.frequency_total})`;
  }

  return transaction.description || '-';
};

export default getTransactionDescription;
