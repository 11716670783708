import styled from 'styled-components';

import { Button } from '_components/_core';

export const SectionHeader = styled.p`
  font-size: 14px;
  margin-bottom: 4px;
  color: var(--accent-color);
  font-weight: 600;
`;

export const StyledFormWrapper = styled.div`
 
`;

export const StyledNavButton = styled(Button)`
  background-color: var(--accent-color);
  border: none;

  :hover {  
    background-color: #0D4D23 !important;
  }

  :active {
    background-color: #0D4D23 !important;
  }

  :disabled {
    background-color: #0D4D23 !important;
  }
`;

export const StyledSmallText = styled.small`
  padding-left: 8px;
`;

export const StyledLink = styled.a`
  text-decoration: underline;
`;
