import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { IoArrowDown, IoArrowUp } from 'react-icons/io5';

import { ISS_WITHHELD_OPTIONS } from 'helpers/constants';
import {
  FormTextField,
  FormDateField,
  FormCurrencyField,
  FormSelectField,
  Button,
} from '_components/_core';

import { INVOICE_STATUS } from './utilities';

function InvoiceFilters() {
  const [expanded, setExpanded] = useState(false);

  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Competência</Form.Label>
            <FormDateField name="date_competence_start" placeholder="Data Inicial" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label />
            <FormDateField name="date_competence_end" placeholder="Data Final" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Emissão</Form.Label>
            <FormDateField name="date_emission_start" placeholder="Data Inicial" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label />
            <FormDateField name="date_emission_end" placeholder="Data Final" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Número da Nota Fiscal</Form.Label>
            <FormTextField name="invoice_number" placeholder="Número da Nota Fiscal" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Status da Nota</Form.Label>
            <FormSelectField name="invoice_status" options={INVOICE_STATUS} />
          </Form.Group>
        </Col>
      </Form.Row>

      <div style={{ display: expanded ? 'block' : 'none' }}>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>ISS Retido</Form.Label>
              <FormSelectField name="service_iss_withheld" options={ISS_WITHHELD_OPTIONS} />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>Tomador (CPF, CNPJ ou Nome)</Form.Label>
              <FormTextField name="service_taker" placeholder="Tomador" />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>Valor Bruto</Form.Label>
              <FormCurrencyField name="service_gross_amount_start" placeholder="Valor Inicial" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label />
              <FormCurrencyField name="service_gross_amount_end" placeholder="Valor Final" />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>Valor Líquido</Form.Label>
              <FormCurrencyField name="service_net_amount_start" placeholder="Valor Inicial" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label />
              <FormCurrencyField name="service_net_amount_end" placeholder="Valor Final" />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>Valor ISS</Form.Label>
              <FormCurrencyField name="service_iss_amount_start" placeholder="Valor Inicial" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label />
              <FormCurrencyField name="service_iss_amount_end" placeholder="Valor Final" />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>PIS Retido</Form.Label>
              <FormCurrencyField name="service_pis_amount_start" placeholder="Valor Inicial" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label />
              <FormCurrencyField name="service_pis_amount_end" placeholder="Valor Final" />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>COFINS Retido</Form.Label>
              <FormCurrencyField name="service_cofins_amount_start" placeholder="Valor Inicial" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label />
              <FormCurrencyField name="service_cofins_amount_end" placeholder="Valor Final" />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>IRPJ Retido</Form.Label>
              <FormCurrencyField name="service_ir_amount_start" placeholder="Valor Inicial" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label />
              <FormCurrencyField name="service_ir_amount_end" placeholder="Valor Final" />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>CSLL Retido</Form.Label>
              <FormCurrencyField name="service_csll_amount_start" placeholder="Valor Inicial" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label />
              <FormCurrencyField name="service_csll_amount_end" placeholder="Valor Final" />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>INSS Retido</Form.Label>
              <FormCurrencyField name="service_inss_amount_start" placeholder="Valor Inicial" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label />
              <FormCurrencyField name="service_inss_amount_end" placeholder="Valor Final" />
            </Form.Group>
          </Col>
        </Form.Row>
      </div>

      <div className="d-flex justify-content-center">
        <Button variant="link" icon={expanded ? <IoArrowUp /> : <IoArrowDown />} onClick={() => setExpanded(!expanded)}>
          {expanded ? 'Recolher' : 'Expandir' }
          {' '}
          filtros avançados
        </Button>
      </div>
    </Form>
  );
}

export default InvoiceFilters;
