import styled from 'styled-components';

import { Button } from '_components/_core';

export const StyledDetailsButton = styled(Button)`
  @keyframes details-button-animation {
    0% {
        -webkit-transform: scale(.4) translateY(-50px);
        transform: scale(.4) translateY(-50px);
        opacity: 0;
    }

    40% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
        opacity: 1;
    }
  }

  position: absolute;
  left: 50%;
  border-radius: 25px;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00ab6f;
  border: none;
  z-index: 0;
  margin-top: -5px;
  box-shadow: 0px 4px 20px 0px rgb(0 0 0 / 35%);
  transform-origin: 50% 0;
  animation: details-button-animation .3s cubic-bezier(0,0,0,1) .15s;
  animation-fill-mode: both;

  &:hover {
    background-color: #00784e !important;
    border: none !important;
    box-shadow: 0px 4px 20px 0px rgb(0 0 0 / 35%);
  }

  &:focus {
    background-color: #00784e !important;
    border: none !important;
    box-shadow: 0px 4px 20px 0px rgb(0 0 0 / 35%);
  }

  &:active {
    background-color: #00784e !important;
    border: none !important;
    box-shadow: 0px 4px 20px 0px rgb(0 0 0 / 35%);
  }

`;
