import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';

import { DatePicker, Menu } from '_components/_core';
import { ReportSignature } from '_components/_shared';

import { MenuCategory, MenuRow, MenuButton } from '_components/_core/Menu/Menu';

function Filters({
  isLoading,
  values,
  setFieldValue,
  handleSubmit,
}) {
  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }, [handleSubmit]);

  return (
    <Menu className="mb-3 mb-md-0" onKeyPress={handleKeyPress}>
      <MenuCategory noPaddingBottom>
        Filtros
      </MenuCategory>
      <MenuRow>
        <Col>
          <Form.Group>
            <Form.Label>
              Data Inicial
            </Form.Label>
            <DatePicker
              placeholder="Data Inicial"
              selectedDate={values.start_date}
              onChange={(value) => setFieldValue('start_date', value)}
              onFocus={(e) => e.target.select()}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>
              Data Final
            </Form.Label>
            <DatePicker
              placeholder="Data Final"
              selectedDate={values.end_date}
              onChange={(value) => setFieldValue('end_date', value)}
              onFocus={(e) => e.target.select()}
            />
          </Form.Group>
        </Col>
      </MenuRow>
      <MenuCategory noPaddingTop>
        Agrupamento
      </MenuCategory>
      <ListGroup.Item as="button" action eventKey="month" onClick={() => setFieldValue('type', 'month')}>
        Por Mês
      </ListGroup.Item>
      <ListGroup.Item as="button" action eventKey="quarter" onClick={() => setFieldValue('type', 'quarter')}>
        Por Trimestre
      </ListGroup.Item>
      <ListGroup.Item as="button" action eventKey="year" onClick={() => setFieldValue('type', 'year')}>
        Por Ano
      </ListGroup.Item>
      <ReportSignature viewType="select_field" />
      <MenuButton isLoading={isLoading} onClick={handleSubmit}>
        Gerar Relatório
      </MenuButton>
    </Menu>
  );
}

Filters.defaultProps = {
  values: null,
  isLoading: false,
};

Filters.propTypes = {
  values: PropTypes.object,
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default Filters;
