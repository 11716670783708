import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';

const updateCompanyFee = (company_id, id, fee, callback) => {
  return async (dispatch) => {
    dispatch(request(id, fee));

    service.updateFee(company_id, id, fee).then(
      (response) => {
        dispatch(success(response));
        dispatch(alertActionCreators.success('Honorário atualizado com sucesso!'));

        if (callback) {
          service.getById(company_id).then((response) => {
            callback(response.data);
          });
        }
      },
    ).catch(
      (err) => {
        dispatch(failure(err));
        dispatch(alertActionCreators.error('Erro ao atualizar honorário!'));
      },
    );
  };

  function request(id, fee) {
    return {
      type: constants.UPDATE_COMPANY_FEE_REQUEST,
      payload: { id, fee },
    };
  }
  function success(response) {
    return {
      type: constants.UPDATE_COMPANY_FEE_SUCCESS,
      payload: { response },
    };
  }
  function failure(error) {
    return {
      type: constants.UPDATE_COMPANY_FEE_FAILURE,
      payload: { error },
    };
  }
};

export default updateCompanyFee;
