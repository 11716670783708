import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';
import companySelectors from '_store/_selectors/company';

import ActivatedCompany from './ActivatedCompany';

const mapStateToProps = (state) => ({
  activeCompany: companySelectors.activeCompanySelector(state),
  companies: state.company.companies,
  isLoading: state.company.isLoadingActiveCompany,
  collapsed: state.sidebar.collapsed,
});

const mapDispatchToProps = {
  onFetchActiveCompany: companyActionCreators.getActiveCompany,
  onFetchCompanies: companyActionCreators.getAllCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivatedCompany);
