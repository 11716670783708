import React, { createContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';

export const ReportContext = createContext();

export const Provider = ({ children }) => {
  const [value, setValue] = useState(useRef());

  return (
    <ReportContext.Provider value={{ value, setValue }}>
      {children}
    </ReportContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};
