import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import jschardet from 'jschardet';

import confirmDialog from 'helpers/confirmDialog';

import { DropzoneContainer, Message, FileLengthMessage } from './styles';

function Dropzone({
  onChange,
  readAsText,
  readAsBase64,
  readAsTextWithEncoding,
  showLengthMessage,
  accept,
  maxFiles,
  shouldSelectFiles = true,
  forceMaxSize,
}) {
  const [readyFiles, setReadyFiles] = useState([]);
  const [selectedFilesLength, setSelectedFilesLength] = useState(0);

  const maxSize = 10 * 1024 * 1024;

  const getFileContentAsBase64 = (files) => new Promise((resolve, reject) => {
    const parsedFiles = [];

    for (const file of files) {
      const reader = new FileReader();

      reader.onload = () => {
        parsedFiles.push(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.onloadend = () => {
        if (parsedFiles.length === files.length) {
          resolve(parsedFiles);
        }
      };

      reader.readAsDataURL(file);
    }
  });

  const getFileContentsAsText = (files) => new Promise((resolve, reject) => {
    const parsedFiles = [];

    for (const file of files) {
      const reader = new FileReader();

      reader.onload = () => {
        parsedFiles.push(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.onloadend = () => {
        if (parsedFiles.length === files.length) {
          resolve(parsedFiles);
        }
      };

      reader.readAsText(file, 'ISO-8859-1');
    }
  });

  const getFileContenxtAsTextWithEncoding = (files) => new Promise((resolve, reject) => {
    const parsedFiles = [];

    for (const file of files) {
      const readerBinary = new FileReader();
      const readerText = new FileReader();

      readerBinary.onload = () => {
        const { encoding } = jschardet.detect(readerBinary.result);

        readerText.readAsText(file, encoding);
      };

      readerText.onload = () => {
        parsedFiles.push(readerText.result);
      };

      readerBinary.onerror = (error) => {
        reject(error);
      };
      readerText.onerror = (error) => {
        reject(error);
      };

      readerText.onloadend = () => {
        if (parsedFiles.length === files.length) {
          resolve(parsedFiles);
        }
      };

      readerBinary.readAsBinaryString(file);
    }
  });

  const onDrop = useCallback(async (acceptedFiles) => {
    if (shouldSelectFiles) {
      setSelectedFilesLength(acceptedFiles.length);
    }

    if (readAsText) {
      const files_text = await getFileContentsAsText(acceptedFiles);

      setReadyFiles(acceptedFiles);
      onChange(files_text);
      return;
    }

    if (readAsTextWithEncoding) {
      const files_text = await getFileContenxtAsTextWithEncoding(acceptedFiles);

      setReadyFiles(acceptedFiles);
      onChange(files_text);
      return;
    }

    if (readAsBase64) {
      const files_base64 = await getFileContentAsBase64(acceptedFiles);

      setReadyFiles(acceptedFiles);
      onChange(files_base64);
      return;
    }

    if (shouldSelectFiles) {
      setReadyFiles(acceptedFiles);
    }

    onChange(acceptedFiles);
  }, [onChange, readAsText, readAsTextWithEncoding, shouldSelectFiles, readAsBase64]);

  const onDropRejected = (rejectedFiles) => {
    const finalMaxSize = forceMaxSize ? forceMaxSize / 1024 / 1024 : maxSize;

    const messages = {
      'file-too-large': `O tamanho do arquivo deve ser no máximo ${finalMaxSize}MB.`,
      'file-too-small': 'O arquivo é menor que o tamanho mínimo permitido.',
      'too-many-files': `Por favor, envie no máximo ${maxFiles} arquivo(s) de cada vez.`,
      'file-invalid-type': `São válidos somente arquivos do tipo ${accept}`,
    };

    const { code } = rejectedFiles[0].errors[0];

    const message = messages[code];

    confirmDialog.open({
      icon: 'error',
      title: 'Atenção',
      message,
      confirmButtonText: 'Entendi',
      showCancelButton: false,
    });
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    maxSize: forceMaxSize || maxSize,
    onDropRejected,
  });

  const renderMessage = useCallback(() => {
    if (isDragActive) {
      return (
        <Message>
          Solte os arquivos aqui...
        </Message>
      );
    }

    if (!readAsText && selectedFilesLength > 0) {
      return (
        <Message withFiles>
          {readyFiles[0] && readyFiles[0].name}
        </Message>
      );
    }

    return (
      <Message>
        Arraste e solte arquivos aqui, ou clique para selecionar
      </Message>
    );
  }, [isDragActive, readAsText, readyFiles, selectedFilesLength]);

  const fileLengthMessage = useMemo(
    () => `${selectedFilesLength} arquivo(s) selecionados`, [selectedFilesLength],
  );

  return (
    <>
      <DropzoneContainer {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        {renderMessage()}
      </DropzoneContainer>
      {showLengthMessage && (
        <FileLengthMessage>
          {fileLengthMessage}
        </FileLengthMessage>
      )}
    </>
  );
}

Dropzone.propTypes = {
  readAsText: false,
  readAsBase64: false,
  readAsTextWithEncoding: false,
  showLengthMessage: false,
  maxFiles: 0,
  shouldSelectFiles: true,
  forceMaxSize: 0,
};

Dropzone.propTypes = {
  onChange: PropTypes.func.isRequired,
  readAsText: PropTypes.bool,
  readAsBase64: PropTypes.bool,
  showLengthMessage: PropTypes.bool,
  accept: PropTypes.string.isRequired,
  maxFiles: PropTypes.number,
  shouldSelectFiles: PropTypes.bool,
  forceMaxSize: PropTypes.number,
  readAsTextWithEncoding: PropTypes.bool,
};

export default Dropzone;
