import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IoFilterSharp } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';

import {
  PageHeader,
  Button,
  TableLookup,
  ActiveFilters,
} from '_components/_core';
import {
  UpdateEntriesDate,
  UpdateEntriesAccounts,
  UpdateEntriesHistory,
  UpdateEntriesAmount,
  DeleteEntries,
} from '_components/_shared';
import {
  Pagination,
  ItemsPerPage,
  TableActions,
} from '_components/_core/Table/components';
import { useTable } from '_components/_core/Table/utils';

import { Filters } from './components';
import {
  getTableTopDistance,
  TABLE_COLUMNS,
  DEFAULT_COLUMNS,
  FILTERS_CONFIGURATION,
} from './utilities';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { field: 'event_date', order: 'desc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

export default function EntriesLookup({
  isLoading,
  total,
  entries,
  uploadResults,
  onFetchEntries,
  onDeleteEntry,
  onFetchAccounts,
}) {
  const history = useHistory();

  const {
    filters,
    sorting,
    pagination,
    selectedItems,
    displayFilters,
    onFilter,
    onSorting,
    onPageChange,
    onPageSizeChange,
    onItemSelected,
    onSelectAll,
    onToggleFilters,
  } = useTable({
    ...tableConfig,
    data: entries,
    onFetchData: onFetchEntries,
  });

  const headerContent = useCallback(() => (
    <>
      <Filters
        filters={filters}
        isVisible={displayFilters}
        onToggleFilters={onToggleFilters}
        onFilter={onFilter}
      />
      <Button
        variant="outline-primary"
        icon={<IoFilterSharp />}
        onClick={onToggleFilters}
      >
        Filtros
      </Button>
    </>
  ), [displayFilters, filters, onFilter, onToggleFilters]);

  const tableTopDistance = useMemo(
    () => getTableTopDistance(uploadResults),
    [uploadResults],
  );

  const handleDelete = useCallback((id) => {
    onDeleteEntry(id);
  }, [onDeleteEntry]);

  const handleEdit = useCallback((id) => {
    history.push(`/contabil/lancamentos/${id}/editar`);
  }, [history]);

  useEffect(() => {
    onFetchAccounts();
  }, [onFetchAccounts]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Lançamentos Contábeis"
        description="Consulta completa dos lançamentos contábeis da empresa."
        sideContent={headerContent()}
      />
      <ActiveFilters
        filtersConfiguration={FILTERS_CONFIGURATION}
        filters={filters}
        onFilter={onFilter}
      />
      <Row>
        <Col>
          <TableLookup
            keyName="id"
            name="entry-lookup-table"
            data={entries}
            columns={TABLE_COLUMNS}
            defaultColumns={DEFAULT_COLUMNS}
            sorting={sorting}
            onSorting={onSorting}
            selectedItems={selectedItems}
            onItemSelected={onItemSelected}
            onSelectAll={onSelectAll}
            isLoading={isLoading}
            showColumnToggle
            showFooter
            topDistance={tableTopDistance}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
          <TableActions
            filters={filters}
            onFilter={onFilter}
            selectedItems={selectedItems}
            tableActions={[
              UpdateEntriesDate,
              UpdateEntriesAccounts,
              UpdateEntriesHistory,
              UpdateEntriesAmount,
              DeleteEntries,
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <ItemsPerPage
            itemsPerPage={pagination.itemsPerPage}
            onChange={onPageSizeChange}
            className="mt-3"
          />
        </Col>
        <Col className="d-flex flex-row-reverse">
          <Pagination
            {...pagination}
            total={total}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
    </Container>
  );
}

EntriesLookup.defaultProps = {
  uploadResults: {},
  isLoading: false,
  entries: [],
};

EntriesLookup.propTypes = {
  entries: PropTypes.array,
  total: PropTypes.number.isRequired,
  uploadResults: PropTypes.object,
  isLoading: PropTypes.bool,
  onClearUploadResults: PropTypes.func.isRequired,
  onFetchEntries: PropTypes.func.isRequired,
  onDeleteEntry: PropTypes.func.isRequired,
  onFetchAccounts: PropTypes.func.isRequired,
};
