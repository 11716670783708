import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FaPencilAlt, FaTimes, FaRegClone } from 'react-icons/fa';

import {
  ACCOUNT_TYPE_LABELS,
  ACCOUNT_NATURE_LABELS,
  ACCOUNT_VISIBILITY_LABELS,
} from './utils';

import Column from './components/Column';
import { Container, HoverBadge } from './styles';

export default function Row({
  account, editAccount, deleteAccount, duplicateAccount,
}) {
  const editClassName = account.manager_id ? 'text-primary' : 'text-muted';
  const deleteClassName = account.manager_id ? 'text-danger' : 'text-muted';
  const duplicateClassName = account.manager_id ? 'text-primary' : 'text-muted';

  return (
    <Container
      isSintetica={account.account_type === 'SINTETICA'}
      level={account.account_level}
    >
      <Column textCenter paddingLeft>
        <FaPencilAlt
          style={{
            cursor: 'pointer',
            pointerEvents: account.manager_id ? 'all' : 'none',
          }}
          disabled={!account.manager_id}
          className={classNames(editClassName, 'mr-3')}
          onClick={() => editAccount(account)}
        />
        <FaTimes
          style={{
            cursor: 'pointer',
            pointerEvents: account.manager_id ? 'all' : 'none',
          }}
          disabled={!account.manager_id}
          className={classNames(deleteClassName, 'mr-3')}
          onClick={() => deleteAccount(account.id)}
        />
        <FaRegClone
          style={{
            cursor: 'pointer',
            pointerEvents: account.manager_id ? 'all' : 'none',
          }}
          disabled={!account.manager_id}
          className={duplicateClassName}
          onClick={() => duplicateAccount(account)}
        />
      </Column>
      <Column>{account.classifier}</Column>
      <Column>
        {account.description}
        <HoverBadge className="badge badge-outline-primary text-capitalize">
          {ACCOUNT_VISIBILITY_LABELS[account.visibility]}
        </HoverBadge>
      </Column>
      <Column textCenter>
        <div className="badge badge-outline-default text-capitalize">
          {ACCOUNT_TYPE_LABELS[account.account_type]}
        </div>
      </Column>
      <Column textCenter>
        <div className="badge badge-outline-default text-capitalize">
          {ACCOUNT_NATURE_LABELS[account.account_nature]}
        </div>
      </Column>
    </Container>
  );
}

Row.propTypes = {
  account: PropTypes.object.isRequired,
  editAccount: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  duplicateAccount: PropTypes.func.isRequired,
};
