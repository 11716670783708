import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';

import {
  FormTextField,
  FormDateField,
  FormCurrencyField,
  Button,
  FloatingCard,
} from '_components/_core';

function SearchForm({
  isVisible,
  onToggleVisibility,
  onSearch,
}) {
  const handleFilter = useCallback((values) => {
    onSearch(values);
    onToggleVisibility();
  }, [onSearch, onToggleVisibility]);

  const renderFooter = useCallback((handleSubmit) => (
    <div className="d-flex">
      <Button
        className="mr-2 flex-fill"
        onClick={handleSubmit}
      >
        Filtrar
      </Button>
      <Button
        className="flex-fill"
        variant="secondary"
        onClick={onToggleVisibility}
      >
        Cancelar
      </Button>
    </div>
  ), [onToggleVisibility]);

  return (
    <Formik
      initialValues={{
        event_date_start: null,
        event_date_end: null,
        amount_start: null,
        amount_end: null,
        narration: null,
      }}
      onSubmit={handleFilter}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <>
          <FloatingCard
            title="Filtrar Lançamentos Pendentes"
            fullHeight
            isVisible={isVisible}
            onToggleVisibility={onToggleVisibility}
            footerContent={renderFooter(handleSubmit)}
          >
            <Form>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Data</Form.Label>
                    <FormDateField name="event_date_start" placeholder="Data Inicial" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label />
                    <FormDateField name="event_date_end" placeholder="Data Final" />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Valor</Form.Label>
                    <FormCurrencyField name="amount_start" placeholder="Valor Inicial" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label />
                    <FormCurrencyField name="amount_end" placeholder="Valor Final" />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Histórico</Form.Label>
                    <FormTextField name="narration" placeholder="Digite uma parte do Histórico" />
                  </Form.Group>
                </Col>
              </Form.Row>
            </Form>
          </FloatingCard>
        </>
      )}
    </Formik>
  );
}

SearchForm.propTypes = {
  isVisible: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  onToggleVisibility: PropTypes.func.isRequired,
};

export default SearchForm;
