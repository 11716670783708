import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { IoCloseOutline } from 'react-icons/io5';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { FaTimes } from 'react-icons/fa';

import { Button } from '_components/_core';

import { getFormattedFilters } from './utilities';
import { Container, StyledTag } from './styles';

function ActiveFilters({
  filters,
  filtersConfiguration,
  onFilter,
  onClearFilters,
  hideClearButton,
  isMobile,
}) {
  const formattedFilters = useMemo(
    () => getFormattedFilters(filters, filtersConfiguration),
    [filters, filtersConfiguration],
  );

  const handleRemoveFilter = useCallback((key) => {
    let newFilter = filters;
    const activeFiltersKeys = Object.keys(filters);

    const keysToRemove = activeFiltersKeys.filter(
      (activeFilterKey) => activeFilterKey.includes(key),
    );

    for (const keyToRemove of keysToRemove) {
      newFilter = omit(newFilter, keyToRemove);
    }

    onFilter(newFilter);
  }, [filters, onFilter]);

  const handleRemoveAllFilters = useCallback(() => {
    let newFilter = filters;
    const keysToRemove = Object.keys(filters);

    for (const keyToRemove of keysToRemove) {
      newFilter = omit(newFilter, keyToRemove);
    }

    if (onClearFilters) {
      onClearFilters();
    }

    onFilter(newFilter);
  }, [filters, onClearFilters, onFilter]);

  const renderActiveFilters = useCallback(() => {
    const formattedFiltersKeys = Object.keys(formattedFilters);

    if (isMobile) {
      const formattedNames = formattedFiltersKeys.map((key) => {
        const { label, value } = formattedFilters[key];

        return `${label}: ${value}`;
      });

      return (
        <p
          className="m-0 mt-2 mb-2 d-flex justify-content-between"
        >
          <span>
            <b>{formattedNames.join(', ')}</b>
          </span>
          <FaTimes size="1.3em" className="text-muted" onClick={handleRemoveAllFilters} />
        </p>
      );
    }

    return (
      <>
        {formattedFiltersKeys.map((key, index) => {
          const { label, value } = formattedFilters[key];

          if (!value) {
            return null;
          }

          const marginClass = isMobile ? 'ml-2' : 'ml-3';

          return (
            <StyledTag className={index > 0 ? marginClass : ''}>
              {`${label}: ${value}`}
              <IoCloseOutline onClick={() => handleRemoveFilter(key)} />
            </StyledTag>
          );
        })}
      </>
    );
  }, [formattedFilters, handleRemoveFilter, handleRemoveAllFilters, isMobile]);

  const renderClearButton = useCallback(() => {
    if (hideClearButton) {
      return null;
    }

    let formattedFiltersKeys = Object.keys(formattedFilters);

    if (isEmpty(formattedFiltersKeys)) {
      return null;
    }

    formattedFiltersKeys = formattedFiltersKeys.filter((key) => !!formattedFilters[key].value);

    if (isEmpty(formattedFiltersKeys)) {
      return null;
    }

    return (
      <Button variant="link" onClick={handleRemoveAllFilters}>
        Limpar filtros
      </Button>
    );
  }, [formattedFilters, hideClearButton, handleRemoveAllFilters]);

  const hasActiveFilters = useMemo(() => {
    const formattedFiltersKeys = Object.keys(formattedFilters);

    return formattedFiltersKeys.some((key) => !!formattedFilters[key].value);
  }, [formattedFilters]);

  if (isMobile) {
    return (
      <Container hasActiveFilters={hasActiveFilters} isMobile>
        {renderActiveFilters()}
      </Container>
    );
  }

  return (
    <Container hasActiveFilters={hasActiveFilters}>
      {renderActiveFilters()}
      {renderClearButton()}
    </Container>
  );
}

ActiveFilters.defaultProps = {
  filters: null,
  hideClearButton: false,
  isMobile: false,
};

ActiveFilters.propTypes = {
  hideClearButton: PropTypes.bool,
  isMobile: PropTypes.bool,
  filters: PropTypes.object,
  filtersConfiguration: PropTypes.object.isRequired,
  onFilter: PropTypes.func,
  onClearFilters: PropTypes.func,
};

export default ActiveFilters;
