import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

export const Plans = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
`;

export const Plan = styled.div`

`;

export const PlanName = styled.h4`
  color: var(--color-text-primary);
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const PlanDescription = styled.p`
  margin: 0 !important;
`;

export const PlanPrice = styled.h5`

`;

export const PlanFeatures = styled.ul`

`;

export const StyledDescription = styled(Form.Label)`
  font-weight: 400 !important;
  color: #AAAEBB;
  padding-left: 0;
  padding-top: 0;
`;

export const StyledPlanName = styled(Form.Label)`

`;

export const StyledAlert = styled(Alert)`
  color: #1a3353;
  font-size: 13px;
  font-weight: 400;
  padding: 1.25rem 1rem;
  margin-bottom: 0;

  ${({ variant }) => variant === 'primary' && css`
    background-color: #f9f9fd;
    border: 1px solid #dee2e6;
  `}

  ${({ variant }) => variant === 'success' && css`
    background-color: #e6fff1;
    border: 1px solid #7cf7bc;
  `}

  ${({ variant }) => variant === 'info' && css`
    background-color: #f0f7ff;
    border: 1px solid #bad7ff;
  `}

  &&& {
    p {
      margin-bottom: 0;
    }
  }

`;

export const StyledAlertHeading = styled(Alert.Heading)`
  color: #1a3353;
  font-weight: 600;
  font-size: 16px;
`;

export const AlertDescription = styled.div`
  display: flex;
  justify-content: space-between;
`;
