import Swal from 'sweetalert2';
import { push } from 'connected-react-router';

import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const abortBankStatementUpload = (callback) => {
  return async (dispatch) => {
    confirmDialog.warning({
      title: 'Excluir Extrato Bancário',
      message: 'Todas as movimentações <strong>mesmo conciliadas </strong> serão excluídas. <br /> Deseja continuar? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, excluir!',
      icon: 'warning',
      showLoaderOnConfirm: true,
      onConfirm: () => {
        dispatch(request());

        Swal.fire({
          title: 'Excluindo...',
          html: 'Aguarde enquanto excluímos o extrato bancário.',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();

            service.abortBankStatementUpload().then(
              (response) => {
                dispatch(success(response));

                if (callback) {
                  callback();
                }

                Swal.close();

                dispatch(alertActionCreators.success('Extrato bancário excluído com sucesso!'));
                dispatch(push('/importar-extrato'));
              },
            ).catch((error) => {
              dispatch(failure(error));

              Swal.close();
            });
          },
        });
      },
      onCancel: () => {},
    });
  };

  function request() {
    return {
      type: constants.ABORT_BANK_STATEMENT_UPLOAD_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.ABORT_BANK_STATEMENT_UPLOAD_SUCCESS,
      response,
    };
  }
  function failure(error) {
    return {
      type: constants.ABORT_BANK_STATEMENT_UPLOAD_FAILURE,
      payload: error,
    };
  }
};

export default abortBankStatementUpload;
