import HttpService from './http.service';

export default {
  searchPDFInvoices,
  fetchAutomationSettings,
  addAutomationSettings,
  updateAutomationSettings,
  deleteAutomationSettings,
  executeAutomation,
};

async function searchPDFInvoices(start_date, end_date, date_type) {
  const body = {
    start_date,
    end_date,
    date_type,
  };

  return HttpService.post('/invoices-pdfs', body);
}

async function fetchAutomationSettings() {
  return HttpService.get('/company/automation-settings');
}

async function addAutomationSettings(values) {
  return HttpService.post('/company/automation-settings', values);
}

async function updateAutomationSettings(id, values) {
  return HttpService.put(`/company/automation-settings/${id}`, values);
}

async function deleteAutomationSettings(id) {
  return HttpService.delete(`/company/automation-settings/${id}`);
}

async function executeAutomation(values) {
  return HttpService.post('/multiple-invoices-sync', values);
}
