import constants from '../../_constants/employees.constants';
import service from '../../../services/employees.service';

import getAllEmployeeRoles from './getEmployeeRoles';

const updateEmployeeRole = (role) => {
  return (dispatch) => {
    dispatch(request());

    const { id } = role;

    service.updateRole(id, role).then(
      (response) => {
        const updatedRole = response.data;

        dispatch(success(updatedRole));
        dispatch(getAllEmployeeRoles());
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.EMPLOYEE_ROLES_UPDATE_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.EMPLOYEE_ROLES_UPDATE_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.EMPLOYEE_ROLES_UPDATE_FAILURE,
      payload: error,
    };
  }
};

export default updateEmployeeRole;
