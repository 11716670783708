import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { StyledPageData } from '../../styles';

function PageData({ logoPosition }) {
  moment.locale('pt-br');
  const reportDate = moment().format('L');
  const reportTime = moment().format('LT');
  return (
    <StyledPageData logoPosition={logoPosition}>
      <div>
        <p className="mb-0 mr-2">{<b>Data:</b>} {reportDate}</p>
      </div>
      <div>
        <p className="mb-0 mr-2">{<b>Hora:</b>} {reportTime}</p>
      </div>
      <div>
        {/* <p className="mb-0 mr-2"><b>Página:</b> 1 de 1</p> */}
      </div>
    </StyledPageData>
  );
}

PageData.defaultProps = {
  logoPosition: 'start',
};

PageData.propTypes = {
  logoPosition: PropTypes.string,
};

export default PageData;
