import React from 'react';
import PropTypes from 'prop-types';

import { hasPermissions } from './utilities';

import Fallback from './components/Fallback';

const PermissionsGate = ({
  children,
  userPermissions,
  permissions,
  type = 'all',
  fallback = false,
}) => {
  const permissionGranted = hasPermissions({
    permissions,
    userPermissions,
    type,
  });

  if (!permissionGranted && !fallback) return null;

  if (!permissionGranted && fallback) {
    return <Fallback variant="component" />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default PermissionsGate;

PermissionsGate.defaultProps = {
  permissions: [],
  fallback: false,
};

PermissionsGate.propTypes = {
  userPermissions: PropTypes.any,
  children: PropTypes.node.isRequired,
  permissions: PropTypes.any,
  fallback: PropTypes.bool,
  type: PropTypes.oneOf(['all', 'any']),
};
