export const userConstants = {
  USER_GET_ALL_REQUEST: 'USER_GET_ALL_REQUEST',
  USER_GET_ALL_SUCCESS: 'USER_GET_ALL_SUCCESS',
  USER_GET_ALL_FAILURE: 'USER_GET_ALL_FAILURE',
  USER_GET_REQUEST: 'USER_GET_REQUEST',
  USER_GET_SUCCESS: 'USER_GET_SUCCESS',
  USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_UPDATE_FAILURE: 'USER_UPDATE_FAILURE',
  USER_INVITE_REQUEST: 'USER_INVITE_REQUEST',
  USER_INVITE_SUCCESS: 'USER_INVITE_SUCCESS',
  USER_INVITE_FAILURE: 'USER_INVITE_FAILURE',
  USER_AVATAR_UPLOAD_REQUEST: 'USER_AVATAR_UPLOAD_REQUEST',
  USER_AVATAR_UPLOAD_SUCCESS: 'USER_AVATAR_UPLOAD_SUCCESS',
  USER_AVATAR_UPLOAD_FAILURE: 'USER_AVATAR_UPLOAD_FAILURE',
  USER_AVATAR_DELETE_REQUEST: 'USER_AVATAR_DELETE_REQUEST',
  USER_AVATAR_DELETE_SUCCESS: 'USER_AVATAR_DELETE_SUCCESS',
  USER_AVATAR_DELETE_FAILURE: 'USER_AVATAR_DELETE_FAILURE',
  USER_DELETE_REQUEST: 'USER_DELETE_REQUEST',
  USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
  USER_DELETE_FAILURE: 'USER_DELETE_FAILURE',
  USER_UPDATE_PREFERENCES_REQUEST: 'USER_UPDATE_PREFERENCES_REQUEST',
  USER_UPDATE_PREFERENCES_SUCCESS: 'USER_UPDATE_PREFERENCES_SUCCESS',
  USER_UPDATE_PREFERENCES_FAILURE: 'USER_UPDATE_PREFERENCES_FAILURE',
  USER_GET_PREFERENCES_REQUEST: 'USER_GET_PREFERENCES_REQUEST',
  USER_GET_PREFERENCES_SUCCESS: 'USER_GET_PREFERENCES_SUCCESS',
  FETCH_USERS_FOR_SELECT_REQUEST: 'FETCH_USERS_FOR_SELECT_REQUEST',
  FETCH_USERS_FOR_SELECT_SUCCESS: 'FETCH_USERS_FOR_SELECT_SUCCESS',
  FETCH_USERS_FOR_SELECT_FAILURE: 'FETCH_USERS_FOR_SELECT_FAILURE',
  FETCH_USER_PERMISSIONS_REQUEST: 'FETCH_USER_PERMISSIONS_REQUEST',
  FETCH_USER_PERMISSIONS_SUCCESS: 'FETCH_USER_PERMISSIONS_SUCCESS',
  FETCH_USER_PERMISSIONS_FAILURE: 'FETCH_USER_PERMISSIONS_FAILURE',
  USER_UPDATE_PERMISSIONS_REQUEST: 'USER_UPDATE_PERMISSIONS_REQUEST',
  USER_UPDATE_PERMISSIONS_SUCCESS: 'USER_UPDATE_PERMISSIONS_SUCCESS',
  USER_UPDATE_PERMISSIONS_FAILURE: 'USER_UPDATE_PERMISSIONS_FAILURE',

  UPDATE_EMAIL_REQUEST_REQUEST: 'UPDATE_EMAIL_REQUEST_REQUEST',
  UPDATE_EMAIL_REQUEST_SUCCESS: 'UPDATE_EMAIL_REQUEST_SUCCESS',
  UPDATE_EMAIL_REQUEST_FAILURE: 'UPDATE_EMAIL_REQUEST_FAILURE',

  UPDATE_EMAIL_CONFIRM_REQUEST: 'UPDATE_EMAIL_CONFIRM_REQUEST',
  UPDATE_EMAIL_CONFIRM_SUCCESS: 'UPDATE_EMAIL_CONFIRM_SUCCESS',
  UPDATE_EMAIL_CONFIRM_FAILURE: 'UPDATE_EMAIL_CONFIRM_FAILURE',
};

export default userConstants;
