import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-bottom: 15px;

  ${({ noPadding }) => noPadding && css`
    .card .card-body {
      padding: 0 !important;
    }
  `}

  ${({ noMargin }) => noMargin && css`
    margin: 0 !important;
  `}

  @media print {
    page-break-after: always;
  }

`;
