import React, { useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from 'react-router-dom';
import { FaUpload } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { hasPermissions } from '_components/_shared/PermissionsGate/utilities';
import { useMediaQuery } from 'helpers';
import { Button } from '_components/_core';
import { CashflowOverview } from '_components/_shared';

import useTransactions from '_components/Transactions/utilities/useTransactions';
import PermissionsGate from '_components/_shared/PermissionsGate/PermissionsGateContainer';
import BankAccountOverview from '../BankAccountOverview/BankAccountOverviewContainer';
import CashflowQuarter from '../CashflowQuarter/CashflowQuarterContainer';

function BalanceSection() {
  const history = useHistory();
  const { isDesktopExtraLarge } = useMediaQuery();

  const userPermissions = useSelector(
    (state) => state.userPermissions.permissions[state.auth.user.id],
  );

  const canViewIncomes = useMemo(() => hasPermissions({
    permissions: ['view_month_incomes'],
    userPermissions,
    type: 'all',
  }), [userPermissions]);

  const canViewExpenses = useMemo(() => hasPermissions({
    permissions: ['view_month_expenses'],
    userPermissions,
    type: 'all',
  }), [userPermissions]);

  const canViewForecast = useMemo(() => hasPermissions({
    permissions: ['view_month_forecast_result'],
    userPermissions,
    type: 'all',
  }), [userPermissions]);

  const { selectedAccountIds, selectedDate } = useTransactions();

  return (
    <Row className="mb-5 mt-3">
      <Col sm={isDesktopExtraLarge ? 3 : 4} className="pr-0">
        <CashflowOverview
          account_ids={selectedAccountIds}
          displayMonthResult
          displayUnpaid
          competence={selectedDate.toFormat('yyyy-MM')}
          canViewIncomes={canViewIncomes}
          canViewExpenses={canViewExpenses}
          canViewForecast={canViewForecast}
        />
      </Col>
      <Col
        sm={{
          span: isDesktopExtraLarge ? 3 : 5,
          offset: isDesktopExtraLarge ? 2 : 0,
        }}
      >
        <PermissionsGate permissions={['view_quarter_forecast']} type="all">
          <CashflowQuarter
            account_ids={selectedAccountIds}
            competence={selectedDate.toFormat('yyyy-MM')}
          />
        </PermissionsGate>
      </Col>
      <Col
        sm={{
          span: isDesktopExtraLarge ? 2 : 3,
          offset: isDesktopExtraLarge ? 2 : 0,
        }}
        className="pr-0"
      >

        <div>
          <BankAccountOverview />
          <br />
          <PermissionsGate permissions={['import_files']} type="all">
            <Button
              className="mt-2 mr-2 btn-sm no-print"
              variant="outline-primary"
              icon={<FaUpload />}
              onClick={() => history.push('/importar-extrato')}
            >
              Importar Extrato OFX
            </Button>
            <Button
              className="mt-2 btn-sm no-print"
              variant="outline-primary"
              icon={<FaUpload />}
              onClick={() => history.push('/importar')}
            >
              Importar Movimentações
            </Button>
          </PermissionsGate>
        </div>
      </Col>
    </Row>
  );
}

BalanceSection.defaultProps = {
  selectedAccountIds: [],
};

BalanceSection.propTypes = {
};

export default BalanceSection;
