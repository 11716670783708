import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Form from 'react-bootstrap/Form';

function FormTextAreaField({ rows, placeholder, ...props }) {
  const [field, meta, { setTouched }] = useField(props);

  const error = useMemo(
    () => ((meta.touched && meta.error) ? meta.error : ''),
    [meta.error, meta.touched],
  );

  return (
    <>
      <Form.Control
        as="textarea"
        rows={rows}
        placeholder={placeholder}
        onBlur={setTouched}
        {...field}
        style={{
          resize: 'auto',
        }}
      />
      {error && <small className="text-danger">{error}</small>}
    </>
  );
}

FormTextAreaField.defaultProps = {
  rows: 8,
};

FormTextAreaField.propTypes = {
  rows: PropTypes.number,
  placeholder: PropTypes.string,
};

export default FormTextAreaField;
