import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';

const getCompanyRevenue = (company_id, year) => {
  return (dispatch) => {
    dispatch(request());

    service.getCompanyRevenue(company_id, year).then(
      (response) => {
        const { data } = response;

        dispatch(success(data));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.COMPANY_GET_REVENUE_REQUEST,
    };
  }
  function success(data) {
    return {
      type: constants.COMPANY_GET_REVENUE_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.COMPANY_GET_REVENUE_FAILURE,
      payload: error,
    };
  }
};

export default getCompanyRevenue;
