import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { IoArrowBack, IoArrowForward } from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router-dom';

import {
  FormTextField,
  Button,
  FormCpfCnpjField,
  FormPasswordField,
  FormSelectField,
  FormSwitchField,
} from '_components/_core';
import { ZENPLY_TERMS_URL, ZENPLY_PRIVACY_POLICY_URL } from 'helpers';

import FORMATTERS from 'helpers/formatters';

import { zenpaperSignUpFormSchema } from '_components/SignUp/components/Form/utilities/validationSchema';
import { AddressFormFields } from '_components/_shared';
import {
  SectionHeader,
  StyledNavButton,
  StyledSmallText,
  StyledLink,
} from './styles';

const PLACEHOLDERS = {
  document_type_PF: 'CPF',
  document_type_PJ: 'CNPJ',
  first_name_PF: 'Primeiro nome',
  first_name_PJ: 'Razão Social',
  last_name_PF: 'Sobrenome',
  last_name_PJ: 'Nome Fantasia',
};

function SignUnForm({
  onSignUp,
  selectedMenu,
  setSelectedMenu,
  segments,
}) {
  const history = useHistory();

  const [isSigningUp, setIsSigningUp] = useState(false);

  const formattedSegments = useMemo(
    () => segments.map((segment) => ({
      value: segment.id,
      label: segment.description,
    })),
    [segments],
  );

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const utmSource = query.get('utm_source');
  const utmMedium = query.get('utm_medium');
  const utmCampaign = query.get('utm_campaign');
  const utmTerm = query.get('utm_term');
  const utmContent = query.get('utm_content');
  const utmId = query.get('utm_id');

  const handleCreateAccount = useCallback((values) => {
    setIsSigningUp(true);

    const user = {
      ...values,
      document_number: FORMATTERS.CLEAN_CPF_CNPJ(values.document_number),
      product: 'contabills-empresas',
      utmSource,
      utmCampaign,
      utmMedium,
      utmTerm,
      utmContent,
      utmId,
    };

    const successCallback = () => {
      setIsSigningUp(false);

      history.push(`/confirmar-email?email=${encodeURIComponent(values.email)}`);
    };
    const failureCallback = () => {
      setIsSigningUp(false);
    };

    onSignUp(user, successCallback, failureCallback);
  }, [
    onSignUp,
    history,
    utmSource,
    utmCampaign,
    utmMedium,
    utmTerm,
    utmContent,
    utmId,
  ]);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          first_name: '',
          last_name: '',
          document_type: selectedMenu,
          document_number: '',
          email: '',
          email_confirmation: '',
          password: '',
          password_confirmation: '',
          market_segment_id: null,
          accepted_terms: false,
        }}
        validationSchema={zenpaperSignUpFormSchema}
        onSubmit={handleCreateAccount}
      >
        {({
          handleSubmit,
          isValid,
        }) => (
          <>
            <div>
              <SectionHeader className="mt-3 d-flex justify-content-between">
                <span>
                  Dados cadastrais
                </span>
                <span>
                  &nbsp;
                </span>
              </SectionHeader>
              <hr className="mt-2" />
              <Form.Row>
                <Form.Group as={Col} xs={12} lg={6}>
                  <Form.Label>{PLACEHOLDERS[`first_name_${selectedMenu}`]}</Form.Label>
                  <FormTextField name="first_name" placeholder={PLACEHOLDERS[`first_name_${selectedMenu}`]} />
                </Form.Group>
                <Form.Group as={Col} xs={12} lg={6}>
                  <Form.Label>{PLACEHOLDERS[`last_name_${selectedMenu}`]}</Form.Label>
                  <FormTextField name="last_name" placeholder={PLACEHOLDERS[`last_name_${selectedMenu}`]} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>{PLACEHOLDERS[`document_type_${selectedMenu}`]}</Form.Label>
                  <FormCpfCnpjField
                    type={selectedMenu === 'PF' ? 'CPF' : 'CNPJ'}
                    name="document_number"
                    placeholder={PLACEHOLDERS[`document_type_${selectedMenu}`]}
                  />
                </Form.Group>
              </Form.Row>
              <AddressFormFields variant="STATE_AND_CITY" />
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Ramo de atuação</Form.Label>
                  <FormSelectField
                    name="market_segment_id"
                    placeholder="Selecione..."
                    options={formattedSegments}
                  />
                </Form.Group>
              </Form.Row>
            </div>
            <div>
              <SectionHeader className="mt-3 d-flex justify-content-between">
                <span>
                  Dados de login
                </span>
                <span>
                  &nbsp;
                </span>
              </SectionHeader>
              <hr className="mt-2" />
              <Form.Row>
                <Form.Group as={Col} lg={6}>
                  <Form.Label>E-mail</Form.Label>
                  <FormTextField name="email" placeholder="E-mail" type="email" autoComplete="off" />
                </Form.Group>
                <Form.Group as={Col} lg={6}>
                  <Form.Label>Senha</Form.Label>
                  <FormPasswordField name="password" autoComplete="off" type="password" placeholder="Criar uma senha" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <FormSwitchField
                  name="accepted_terms"
                  sideContent={(
                    <StyledSmallText>
                      {'Declaro que li e aceito os '}
                      <StyledLink href={ZENPLY_TERMS_URL} target="_blank" rel="noreferrer">Termos de Uso</StyledLink>
                      {' e a '}
                      <StyledLink href={ZENPLY_PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">Política de Privacidade</StyledLink>
                    </StyledSmallText>
                    )}
                />
              </Form.Row>
            </div>
            <Row className="justify-content-center ml-4 mt-3">
              <Button
                variant="inverse-dark"
                type="button"
                className="d-flex align-items-center"
                onClick={() => setSelectedMenu(null)}
              >
                <IoArrowBack className="mr-2" />
                Voltar
              </Button>
              <StyledNavButton
                type="button"
                className="ml-2 d-flex align-items-center"
                isLoading={isSigningUp}
                disabled={!isValid || isSigningUp}
                onClick={handleSubmit}
                variant="success-2"
              >
                Avançar
                <IoArrowForward className="ml-2" />
              </StyledNavButton>
            </Row>
          </>
        )}
      </Formik>
    </>
  );
}

SignUnForm.defaultProps = {
  segments: [],
};

SignUnForm.propTypes = {
  segments: PropTypes.array,
  onSignUp: PropTypes.func.isRequired,
  setSelectedMenu: PropTypes.any,
  selectedMenu: PropTypes.any,
};

export default SignUnForm;
