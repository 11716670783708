import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const fetchBankStatementSummary = () => {
  return async (dispatch) => {
    dispatch(request());

    service
      .fetchBankStatementSummary()
      .then((response) => {
        dispatch(success(response));
      }).catch((error) => {
        dispatch(failure(error));
      });
  };

  function request(params) {
    return {
      type: constants.FETCH_BANK_STATEMENT_SUMMARY_REQUEST,
      payload: params,
    };
  }
  function success(response) {
    return {
      type: constants.FETCH_BANK_STATEMENT_SUMMARY_SUCCESS,
      payload: response.data,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_BANK_STATEMENT_SUMMARY_FAILURE,
      payload: error,
    };
  }
};

export default fetchBankStatementSummary;
