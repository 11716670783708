import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Checkbox, LineItem, BalanceContainer } from '../../styles';

function SelectAllAccountsItem({
  variant,
  isMultiple,
  isAllChecked,
  onSelectAllAccounts,
}) {
  const handleSelectAll = useCallback(() => {
    onSelectAllAccounts();
  }, [onSelectAllAccounts]);

  const handleCheckboxClickAll = useCallback((e) => {
    e.stopPropagation();

    onSelectAllAccounts();
  }, [onSelectAllAccounts]);

  const renderDescription = useCallback(() => {
    let className = '';

    if (variant === 'default') {
      className = 'ml-4';
    }

    return (
      <span className={className}>
        Todas
      </span>
    );
  }, [variant]);

  return (
    <LineItem
      key="all_accounts"
      eventKey="all_accounts"
      className="d-flex justify-content-start align-items-center mb-0"
      onClick={handleSelectAll}
      variant={variant}
      active={isAllChecked}
      as="div"
      role="button"
    >
      {isMultiple && (
        <Checkbox
          type="checkbox"
          checked={isAllChecked}
          onClick={(e) => handleCheckboxClickAll(e)}
        />
      )}
      <BalanceContainer onClick={handleSelectAll} className={isMultiple ? 'ml-3' : ''}>
        {renderDescription()}
      </BalanceContainer>
    </LineItem>
  );
}

SelectAllAccountsItem.propTypes = {
  variant: PropTypes.string.isRequired,
  isMultiple: PropTypes.bool.isRequired,
  isAllChecked: PropTypes.bool.isRequired,
  onSelectAllAccounts: PropTypes.func.isRequired,
};

export default SelectAllAccountsItem;
