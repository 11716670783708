import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  Button,
  PageHeader,
  Card,
  CardBody,
} from '_components/_core';

import Table from '../Table';
import TablePlaceholder from '../TablePlaceholder';
import AccountModal from '../AccountForm/AccountFormModalContainer';

function Accounts({ accountsActions, accountState, companyState }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const {
    deleteAccount,
    getAllAccounts,
    duplicateAccount,
  } = accountsActions;
  const { accounts, ui } = accountState;
  const { company } = companyState;

  const handleCreate = useCallback(() => {
    setSelectedAccount(null);
    setIsModalVisible(true);
  }, []);

  const renderSideContent = useCallback(() => (
    <Button onClick={() => handleCreate()}>
      Nova Conta
    </Button>
  ), [handleCreate]);

  useEffect(() => {
    getAllAccounts();
  }, [getAllAccounts]);

  const handleEdit = useCallback((account) => {
    setIsModalVisible(true);
    setSelectedAccount(account);
  }, []);

  const handleDelete = useCallback((account) => {
    deleteAccount(account, () => {
      getAllAccounts();
    });
  }, [deleteAccount, getAllAccounts]);

  const handleDuplicate = useCallback((account) => {
    duplicateAccount(account, () => {
      getAllAccounts();
    });
  }, [duplicateAccount, getAllAccounts]);

  return (
    <Container fluid className="content-wrapper fixedHeader">
      <PageHeader
        title="Plano de Contas"
        description="Altere o seu plano de contas como quiser para adequar à realidade do seu cliente."
        sideContent={renderSideContent()}
        fixedHeader
      />
      <Row>
        <Col>
          <Card>
            <CardBody noPadding contained>
              {ui.isLoading ? (
                <TablePlaceholder />
              ) : (
                <Table
                  accounts={accounts}
                  editAccount={(account) => { handleEdit(account); }}
                  deleteAccount={(account) => { handleDelete(account); }}
                  duplicateAccount={(account) => { handleDuplicate(account); }}
                  company={company}
                  isLoading={ui.isLoading}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <AccountModal
        isVisible={isModalVisible}
        onModalToggle={() => setIsModalVisible(!isModalVisible)}
        account={selectedAccount}
      />
    </Container>
  );
}

Accounts.propTypes = {
  accountsActions: PropTypes.object.isRequired,
  accountState: PropTypes.object.isRequired,
  companyState: PropTypes.object.isRequired,
};

export default Accounts;
