import { push } from 'connected-react-router';

import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';

import setActiveCompany from './setActiveCompany';
import uploadAndSaveCompanyCertificate from './saveCompanyCertificate';

const addCompany = (company) => {
  return (dispatch) => {
    dispatch(request(company));

    const payload = {
      ...company,
    };

    delete payload.certificate_file;

    service.insert(payload).then(
      (response) => {
        const { company: createdCompany, certificate_upload } = response.data;

        if (certificate_upload) {
          dispatch(uploadAndSaveCompanyCertificate({
            company_id: createdCompany.id,
            certificate_file: company.certificate_file,
            signed_url: certificate_upload.signed_url,
            final_url: certificate_upload.url,
            certificate_type: company.certificate_type,
            certificate_password: company.certificate_password,
            certificate_password_confirmation: company.certificate_password_confirmation,
          }));
        }

        dispatch(success(createdCompany));

        const handleConfirm = () => {
          dispatch(setActiveCompany(createdCompany));
        };

        confirmDialog.success(
          'Empresa cadastrada com sucesso!',
          `Deseja ativar a empresa <b>${createdCompany.company_name}</b>?`,
          handleConfirm,
          () => {},
        );

        dispatch(push('/empresas'));
      },
    ).catch(({ response }) => {
      if (response.status === 422) {
        const { message } = response.data;

        dispatch(failure(message));
        dispatch(alertActionCreators.error(message));
      }
    });
  };

  function request(company) {
    return {
      type: constants.COMPANY_ADD_REQUEST,
      payload: { company },
    };
  }
  function success(company) {
    return {
      type: constants.COMPANY_ADD_SUCCESS,
      payload: { company },
    };
  }
  function failure(message) {
    return {
      type: constants.COMPANY_ADD_FAILURE,
      payload: { message },
    };
  }
};

export default addCompany;
