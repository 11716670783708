import constants from '../../_constants/invoices.constants';
import service from '../../../services/invoices.service';

import getIssuedInvoices from './getIssuedInvoices';
import alertActionCreators from '../alert.actions';

const createIssuedInvoice = (params, callback) => {
  return (dispatch) => {
    dispatch(request());

    service.createIssuedInvoice(params).then(
      (response) => {
        const { data } = response;

        dispatch(success(data));
        dispatch(getIssuedInvoices());
        dispatch(alertActionCreators.success('Nota Fiscal inserida com sucesso. Confirme a emissão na aba Lista de Emissões.'));

        if (callback) {
          callback();
        }
      },
    ).catch((err) => {
      dispatch(failure(err));
    });
  };

  function request() {
    return {
      type: constants.CREATE_ISSUED_INVOICE_REQUEST,
      payload: { },
    };
  }
  function success(data) {
    return {
      type: constants.CREATE_ISSUED_INVOICE_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.CREATE_ISSUED_INVOICE_FAILURE,
      payload: error,
    };
  }
};

export default createIssuedInvoice;
