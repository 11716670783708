import { connect } from 'react-redux';

import companyActionCreators from '../../../_store/_actions/company';

import List from './List';

const mapStateToProps = (state) => ({
  companies: state.company.companies,
  total: state.company.total,
});

const mapDispatchToProps = {
  onFetchCompanies: companyActionCreators.getAllCompanies,
  onDeleteCompany: companyActionCreators.deleteCompany,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(List);
