import { connect } from 'react-redux';

import userActionCreators from '_store/_actions/user';

import UsersModal from './UsersModal';

const mapStateToProps = (state) => ({
  isLoading: state.user.isLoading,
});

const mapDispatchToProps = {
  onInviteUser: userActionCreators.inviteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersModal);
