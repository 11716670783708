import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FORMATTERS from 'helpers/formatters';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  PageHeader,
} from '_components/_core';

import {
  OneDebitOneCredit,
  OneDebitMultipleCredit,
  OneCreditMultipleDebit,
} from './components';

import { OneDebitOneCreditSchema } from './utilities';

export default function Create({
  isLoading,
  onFetchAccounts,
  onCreateEntry,
  onUpdateEntry,
  onFetchEntry,
}) {
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [type, setType] = useState('1D1C');
  const { id } = useParams();
  const isAddMode = !id;

  const onChangeEntryType = useCallback((entry_type) => {
    setType(entry_type);
  }, []);

  const commonProps = useMemo(() => ({
    onChangeEntryType,
  }), [onChangeEntryType]);

  const TYPES = {
    '1D1C': <OneDebitOneCredit {...commonProps} />,
    '1DVC': <OneDebitMultipleCredit {...commonProps} />,
    '1CVD': <OneCreditMultipleDebit {...commonProps} />,
  };

  const VALIDATION_SCHEMAS = {
    '1D1C': OneDebitOneCreditSchema,
    '1DVC': null,
    '1CVD': null,
  };

  useEffect(() => {
    onFetchAccounts();
  }, [onFetchAccounts]);

  useEffect(() => {
    if (id && !selectedEntry) {
      onFetchEntry(id, (entry) => {
        setSelectedEntry(entry);
      });
    }
  }, [id, selectedEntry, onFetchEntry]);

  const handleAddEditEntry = useCallback((values) => {
    if (isAddMode) {
      onCreateEntry(values);
    } else {
      onUpdateEntry(id, values);
    }
  }, [id, isAddMode, onCreateEntry, onUpdateEntry]);

  const formInitialValues = useMemo(() => {
    if (selectedEntry) {
      return {
        entry_type: selectedEntry.entry_type,
        narration: selectedEntry.narration,
        debit_account_id: selectedEntry.debit_account_id,
        credit_account_id: selectedEntry.credit_account_id,
        amount: selectedEntry.amount,
        event_date: FORMATTERS.ISO_TO_JS_DATE(selectedEntry.event_date),
      };
    }

    return {
      entry_type: type,
      event_date: '',
      narration: '',
      debit_account_id: '',
      credit_account_id: '',
      amount: 0,
    };
  }, [type, selectedEntry]);

  return (
    <div className="content-wrapper">
      <PageHeader
        title={isAddMode ? 'Novo Lançamento' : 'Editar Lançamento'}
        description="Utilize esta página para facilmente cadastrar e editar lançamentos contábeis"
        withBackButton={!isAddMode}
      />
      <Row>
        <Col>
          <Card>
            <Formik
              initialValues={formInitialValues}
              validationSchema={VALIDATION_SCHEMAS[type]}
              onSubmit={handleAddEditEntry}
              enableReinitialize
            >
              {({ handleSubmit, values }) => (
                <>
                  <CardBody>
                    {TYPES[values.entry_type]}
                  </CardBody>
                  <CardFooter>
                    <Button
                      onClick={handleSubmit}
                      isLoading={isLoading}
                      disabled={isLoading}
                    >
                      Salvar Lançamento
                    </Button>
                  </CardFooter>
                </>
              )}
            </Formik>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

Create.propTypes = {
  isLoading: PropTypes.bool,
  onFetchAccounts: PropTypes.func.isRequired,
  onCreateEntry: PropTypes.func.isRequired,
  onUpdateEntry: PropTypes.func.isRequired,
  onFetchEntry: PropTypes.func.isRequired,
};
