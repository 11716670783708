import React from 'react';
import { Link } from 'react-router-dom';

import { StyledAlert, StyledAlertHeading } from './styles';

function Success() {
  return (
    <>
      <StyledAlert variant="success">
        <StyledAlertHeading>
          Cadastro confirmado!
        </StyledAlertHeading>
        <Link to="/entrar"> Clique aqui</Link>
        &nbsp;para fazer login.
      </StyledAlert>
    </>
  );
}

export default Success;
