import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { BsPlus } from 'react-icons/bs';

import { Button } from '_components/_core';

import SearchPDFInvoicesModal from './components/SearchPDFInvoicesModal/SearchPDFInvoicesModal';

function SearchPDFInvoices({ onSearchPDFInvoices }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSearch = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  return (
    <>
      <SearchPDFInvoicesModal
        isVisible={isModalOpen}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
        onSearchPDFInvoices={onSearchPDFInvoices}
      />
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h4 className="mb-0">Busca de PDFs de Notas Fiscais de Serviço</h4>
        <Button
          variant="primary"
          icon={<BsPlus />}
          onClick={handleSearch}
        >
          Nova Busca
        </Button>
      </div>
    </>
  );
}

SearchPDFInvoices.propTypes = {
  onSearchPDFInvoices: PropTypes.func.isRequired,
};

export default SearchPDFInvoices;
