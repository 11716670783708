import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Form from 'react-bootstrap/Form';

function FormPasswordField({ placeholder, autoComplete, ...props }) {
  const [field, meta, { setTouched }] = useField(props);

  const error = useMemo(
    () => ((meta.touched && meta.error) ? meta.error : ''),
    [meta.error, meta.touched],
  );

  return (
    <>
      <Form.Control
        type="password"
        placeholder={placeholder}
        onBlur={setTouched}
        autoComplete={autoComplete}
        {...field}
      />
      {error && <small className="text-danger">{error}</small>}
    </>
  );
}

FormPasswordField.propTypes = {
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
};

export default FormPasswordField;
