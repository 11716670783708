import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { IoTrash } from 'react-icons/io5';
import isNil from 'lodash/isNil';

import { Button } from '_components/_core';

const DeleteDuplicatedEntries = ({
  isLoading,
  selectedItems,
  entries,
  onEntriesIgnore,
  onComplete,
}) => {
  const affectedEntries = useMemo(
    () => entries.filter(
      (entry) => selectedItems.includes(entry.temp_id)
            && !isNil(entry.related_entry),
    ), [entries, selectedItems],
  );

  const handleDeleteEntries = useCallback(() => {
    onEntriesIgnore(affectedEntries);

    if (onComplete) {
      onComplete();
    }
  }, [affectedEntries, onEntriesIgnore, onComplete]);

  return (
    <Button
      className="mr-2 btn-sm"
      variant="outline-danger"
      onClick={handleDeleteEntries}
      disabled={isLoading || affectedEntries.length === 0}
      isLoading={isLoading}
      icon={<IoTrash />}
    >
      {`Ignorar ${affectedEntries.length} ${pluralize('lançamento', affectedEntries.length)} duplicados`}
    </Button>
  );
};

export default DeleteDuplicatedEntries;

DeleteDuplicatedEntries.defaultProps = {
  isLoading: false,
  onComplete: null,
  entries: [],
};

DeleteDuplicatedEntries.propTypes = {
  entries: PropTypes.array,
  isLoading: PropTypes.func,
  selectedItems: PropTypes.array.isRequired,
  onEntriesIgnore: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
};
