import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Card,
  Title,
  Text,
  EmptyIcon,
  SuccessIcon,
  FilterIcon,
} from './styles';

const FeedbackMessages = ({
  icon,
  title,
  description,
  actionButton,
}) => {
  const icons = {
    empty: <EmptyIcon />,
    filter: <FilterIcon />,
    success: <SuccessIcon />,
  };

  return (
    <Container>
      <Card>
        {icons[icon]}
        <Title>{title}</Title>
        <Text>
          {description}
        </Text>
        {actionButton}
      </Card>
    </Container>
  );
};

FeedbackMessages.defaultProps = {
  icon: 'empty',
  title: 'Nenhum resultado encontrado',
  description: 'Não encontramos nenhum resultado com os critérios informados.',
  actionButton: null,
};

FeedbackMessages.propTypes = {
  icon: PropTypes.oneOf(['empty', 'filter', 'success']),
  title: PropTypes.string,
  description: PropTypes.string,
  actionButton: PropTypes.node,
};

export default FeedbackMessages;
