import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import { Formik } from 'formik';

import { PageHeader } from '_components/_core';

import { Filters, Results } from './components';

function IssReport({
  isLoading,
  issReport,
  activeCompany,
  onFetchIssReport,
  onClearIssReport,
}) {
  useEffect(() => {
    onClearIssReport();
  }, [onClearIssReport, activeCompany]);

  const handleGenerateReport = useCallback((values) => {
    onFetchIssReport({
      ...values,
    });
  }, [onFetchIssReport]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader title="Relatório de ISS" description="Relatório de ISS no período informado." />
      <Formik
        initialValues={{
          start_date: null,
          end_date: null,
          type: 'analytical',
          service_iss_withheld: null,
        }}
        onSubmit={handleGenerateReport}
        enableReinitialize
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <>
            <Tab.Container defaultActiveKey="analytical">
              <Row>
                <Col lg={3} md={12}>
                  <Filters
                    isLoading={isLoading}
                    handleSubmit={handleSubmit}
                    values={values}
                    setFieldValue={setFieldValue}
                    onClearIssReport={onClearIssReport}
                  />
                </Col>
                <Col lg={9} md={12}>
                  <Results
                    issReport={issReport}
                    values={values}
                    activeCompany={activeCompany}
                  />
                </Col>
              </Row>
            </Tab.Container>
          </>
        )}
      </Formik>
    </Container>
  );
}

IssReport.defaultProps = {
  issReport: [],
  isLoading: false,
};

IssReport.propTypes = {
  isLoading: PropTypes.bool,
  issReport: PropTypes.array,
  activeCompany: PropTypes.object,
  onClearIssReport: PropTypes.func.isRequired,
  onFetchIssReport: PropTypes.func.isRequired,
};

export default IssReport;
