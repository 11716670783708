import * as Yup from 'yup';
import { ptShort } from 'yup-locale-pt';

Yup.setLocale(ptShort);

const ValidationSchema = Yup.object().shape({
  tax_regime: Yup.string().required().nullable(),
  start_date: Yup.date().required().nullable(),
  aliquota_irpj: Yup.string().required().nullable(),
  aliquota_csll: Yup.string().required().nullable(),
  anexo: Yup.string().required().nullable(),
  mei_type: Yup.string().required().nullable(),
});

export default ValidationSchema;
