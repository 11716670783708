import styled from 'styled-components';
import { FaCheckCircle, FaFilter, FaInbox } from 'react-icons/fa';

export const Container = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; */
`;

export const Card = styled.div`
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 64px 32px;
  width: 100%;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 16px;
`;

export const Text = styled.p`
  font-size: 16px;
  color: grey;
  margin-bottom: 0;
`;

export const EmptyIcon = styled(FaInbox)`
  font-size: 48px;
  color: #2980b9;
  margin-bottom: 16px;
`;

export const FilterIcon = styled(FaFilter)`
  font-size: 48px;
  color: #2980b9;
  margin-bottom: 16px;
`;

export const SuccessIcon = styled(FaCheckCircle)`
  font-size: 48px;
  color: #2ecc71;
  margin-bottom: 16px;
`;
