import React from 'react';
import PropTypes from 'prop-types';

import { StyledDiv, StyledButton } from './styles';

function MobileTransactionType({
  selectedType,
  inputActive,
  onSetSelectedType,
  canCreateIncome,
  canCreateExpense,
  canCreateTransfer,
}) {
  return (
    <>
      <StyledDiv>
        {canCreateIncome && (
          <StyledButton
            variant="mobile-success"
            type="INCOME"
            selectedType={selectedType}
            onClick={() => onSetSelectedType('INCOME')}
            className={selectedType === 'INCOME' || inputActive ? 'active' : ''}
          >
            RECEITA
          </StyledButton>
        )}
        {canCreateExpense && (
          <StyledButton
            variant="mobile-danger"
            type="EXPENSE"
            selectedType={selectedType}
            onClick={() => onSetSelectedType('EXPENSE')}
            className={selectedType === 'EXPENSE' || inputActive ? 'active' : ''}
          >
            DESPESA
          </StyledButton>
        )}
        {canCreateTransfer && (
          <StyledButton
            variant="mobile-success"
            type="TRANSFER"
            selectedType={selectedType}
            onClick={() => onSetSelectedType('TRANSFER')}
            className={selectedType === 'TRANSFER' || inputActive ? 'active' : ''}
          >
            TRANSFERÊNCIA
          </StyledButton>
        )}
      </StyledDiv>
    </>
  );
}

MobileTransactionType.defaultProps = {
  selectedType: 'INCOME',
  inputActive: false,
  canCreateIncome: false,
  canCreateExpense: false,
  canCreateTransfer: false,
};

MobileTransactionType.propTypes = {
  onSetSelectedType: PropTypes.func.isRequired,
  selectedType: PropTypes.string,
  inputActive: PropTypes.string,
  canCreateIncome: PropTypes.bool,
  canCreateExpense: PropTypes.bool,
  canCreateTransfer: PropTypes.bool,
};

export default MobileTransactionType;
