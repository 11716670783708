import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RiErrorWarningFill } from 'react-icons/ri';

import FORMATTERS from 'helpers/formatters';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Tabs,
  PageHeader,
} from '_components/_core';

import {
  CompanyInfoTab,
  AddressTab,
  ActivitiesTab,
  NotesTab,
  TaxRegimeTab,
} from './components';
import { companyFormFields, validationSchema } from './utilities';
import { TabTitle } from './styles';

export default function Create({
  isLoading,
  statesReady,
  onCreateCompany,
  onUpdateCompany,
  onFetchCompany,
  onFetchCities,
}) {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const { id } = useParams();
  const isAddMode = !id;

  const handleCreateCompany = (values, { setSubmitting }) => {
    setSubmitting(true);

    if (isAddMode) {
      onCreateCompany({
        document_number: FORMATTERS.CLEAN_CPF_CNPJ(values.document_number),
        ...values,
      });
    } else {
      onUpdateCompany(id, {
        document_number: FORMATTERS.CLEAN_CPF_CNPJ(values.document_number),
        ...values,
      });
    }

    setSubmitting(false);
  };

  const getTabs = useCallback((errors) => {
    const props = {
      errors,
    };

    const { cnaes, tax_regimes } = errors;

    return [
      {
        id: 1,
        title: <TabTitle>Dados Cadastrais</TabTitle>,
        content: <CompanyInfoTab {...props} />,
      },
      {
        id: 2,
        title: (
          <TabTitle>
            Regime de Tributação
            {tax_regimes ? <RiErrorWarningFill className="text-danger ml-2" /> : ''}
          </TabTitle>
        ),
        content: <TaxRegimeTab />,
      },
      {
        id: 3,
        title: (
          <TabTitle>
            Atividades
            {cnaes ? <RiErrorWarningFill className="text-danger ml-2" /> : ''}
          </TabTitle>
        ),
        content: <ActivitiesTab {...props} />,
      },
      {
        id: 4,
        title: <TabTitle>Endereço</TabTitle>,
        content: <AddressTab />,
      },
      {
        id: 5,
        title: <TabTitle>Anotações</TabTitle>,
        content: <NotesTab />,
      },
    ];
  }, []);

  useEffect(() => {
    if (id && !selectedCompany && statesReady) {
      onFetchCompany(id, (company) => {
        onFetchCities(company.address_state_ibge, () => {
          setSelectedCompany({
            ...company,
            address_state_ibge: Number(company.address_state_ibge),
            address_city_ibge: Number(company.address_city_ibge),
          });
        });
      });
    }
  }, [id, selectedCompany, statesReady, onFetchCompany, onFetchCities]);

  const formInitialValues = useMemo(() => {
    if (selectedCompany) {
      return {
        ...selectedCompany,
        date_founded: FORMATTERS.ISO_TO_JS_DATE(selectedCompany.date_founded),
        client_since: FORMATTERS.ISO_TO_JS_DATE(selectedCompany.client_since),
      };
    }

    return companyFormFields;
  }, [selectedCompany]);

  return (
    <div className="content-wrapper">
      <PageHeader
        title={isAddMode ? 'Cadastrar Empresa' : 'Editar Empresa'}
        description="Preencha as informações da empresa."
        withBackButton
      />
      <Row>
        <Col>
          <Card>
            <Formik
              initialValues={formInitialValues}
              validationSchema={validationSchema}
              onSubmit={handleCreateCompany}
              enableReinitialize
            >
              {({ handleSubmit, errors }) => (
                <>
                  <CardBody noPadding>
                    <Tabs tabs={getTabs(errors)} />
                  </CardBody>
                  <CardFooter>
                    <Button onClick={handleSubmit} isLoading={isLoading} disabled={isLoading}>
                      {isAddMode ? 'Salvar Empresa' : 'Salvar Alterações'}
                    </Button>
                  </CardFooter>
                </>
              )}
            </Formik>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

Create.propTypes = {
  isLoading: PropTypes.bool,
  statesReady: PropTypes.bool.isRequired,
  onFetchCities: PropTypes.func.isRequired,
  onFetchCompany: PropTypes.func.isRequired,
  onCreateCompany: PropTypes.func.isRequired,
  onUpdateCompany: PropTypes.func.isRequired,
};
