import React, { useCallback, useMemo } from 'react';
import { useField, useFormikContext } from 'formik';

import CostCenterSelect from '../CostCenterSelect/CostCenterSelectContainer';

function FormCostCenterSelectField({ ...props }) {
  const formik = useFormikContext();
  const [field, meta] = useField(props);

  const error = meta.error && meta.touched ? meta.error : '';

  const handleChange = useCallback((newValues) => {
    formik.setFieldValue(field.name, newValues);
  }, [formik, field]);

  const value = useMemo(() => {
    if (!field.value) {
      return [];
    }

    return field.value;
  }, [field]);

  return (
    <>
      <CostCenterSelect name={field.name} value={value} onChange={handleChange} {...props} />
      {error && <small className="text-danger">{error}</small>}
    </>
  );
}

export default FormCostCenterSelectField;
