import getAllAccountants from './getAllAccountants';
import addAccountant from './addAccountant';
import deleteAccountant from './deleteAccountant';
import updateAccountant from './updateAccountants';

export default {
  getAllAccountants,
  addAccountant,
  deleteAccountant,
  updateAccountant,
};
