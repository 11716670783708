import React from 'react';

import { Container, Column, ColumnPlaceholder } from './styles';

export default function RowPlaceholder() {
  return (
    <Container>
      <Column paddingLeft>
        <ColumnPlaceholder />
      </Column>
      <Column className="d-flex justify-content-between align-items-center">
        <ColumnPlaceholder />
      </Column>
      <Column textCenter>
        <ColumnPlaceholder />
      </Column>
      <Column textCenter>
        <ColumnPlaceholder />
      </Column>
      <Column textCenter>
        <ColumnPlaceholder />
      </Column>
      <Column textCenter>
        <ColumnPlaceholder />
      </Column>
    </Container>
  );
}
