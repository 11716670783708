import React from 'react';
import PropTypes from 'prop-types';

import Modal from '_components/_core/Modal';
import Button from '_components/_core/Button';

import styles from './styles.module.scss';

function DeleteConfirmModal({ isOpen, onHide, onDeleteConfirm }) {
  const renderFooter = () => (
    <div className={styles.footer}>
      <Button variant="secondary" onClick={onHide}>
        Cancelar
      </Button>
      <Button variant="danger" onClick={onDeleteConfirm}>
        Confirmar Remoção
      </Button>
    </div>
  );

  return (
    <Modal
      title="Remover Sócio"
      isVisible={isOpen}
      onHide={onHide}
      footer={renderFooter()}
    >
      <div className={styles.content}>
        <i className="fa fa-warning fa-3x" />
        <h3>Atenção!</h3>
        <p>
          Ao confirmar, os dados do sócio
          {' '}
          <strong> serão excluídos</strong>
          <br />
          <br />
          <strong>Esta ação não pode ser revertida.</strong>
        </p>
      </div>
    </Modal>
  );
}

DeleteConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  onHide: PropTypes.func,
  onDeleteConfirm: PropTypes.func,
};

export default DeleteConfirmModal;
