import constants from '../../_constants/company-transactions.constants';

const setSelectedAccountIds = (account_ids) => {
  return async (dispatch) => {
    dispatch(request(account_ids));
  };

  function request(account_ids) {
    return {
      type: constants.SET_SELECTED_ACCOUNT_IDS_REQUEST,
      payload: {
        account_ids,
      },
    };
  }
};

export default setSelectedAccountIds;
