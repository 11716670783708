import { push } from 'connected-react-router';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const uploadBankStatementTransactions = (bankStatements) => {
  return (dispatch) => {
    dispatch(request(bankStatements));

    service
      .uploadBankStatementTransactions(bankStatements)
      .then((response) => {
        dispatch(success(response.data));
        dispatch(push('/conciliar-extrato'));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request(bankStatements) {
    return {
      type: constants.UPLOAD_BANK_STATEMENT_TRANSACTIONS_REQUEST,
      payload: bankStatements,
    };
  }
  function success(uploadResults) {
    return {
      type: constants.UPLOAD_BANK_STATEMENT_TRANSACTIONS_SUCCESS,
      payload: uploadResults,
    };
  }
  function failure(error) {
    return {
      type: constants.UPLOAD_BANK_STATEMENT_TRANSACTIONS_FAILURE,
      payload: { error },
    };
  }
};

export default uploadBankStatementTransactions;
