import Swal from 'sweetalert2';
import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

import fetchAllAccounts from './fetchAllAccounts';

const deleteTransactionAccount = (id, name, callback) => {
  return async (dispatch) => {
    confirmDialog.warning({
      title: `Excluir a conta "${name}"`,
      message: `
        Tem certeza que deseja excluir esta <strong>conta bancária</strong> e <strong>todas as suas movimentações, transferências e arquivos relacionados</strong>? <br />Esta operação não pode ser revertida.`,
      confirmButtonText: 'Sim, excluir conta bancária',
      icon: 'warning',
      input: 'text',
      inputAttributes: {
        style: 'width: 80%;',
        name: 'delete-account-input',
      },
      dangerMode: true,
      inputPlaceholder: 'Digite "excluir conta bancária"',
      preConfirm: (inputValue) => {
        if (inputValue !== 'excluir conta bancária') {
          Swal.showValidationMessage('Por favor, digite "excluir conta bancária" para confirmar a exclusão.');
        }
      },
      onConfirm: () => {
        dispatch(request());

        Swal.fire({
          title: 'Excluindo...',
          html: 'Aguarde enquanto excluímos essa conta bancária e suas movimentações.',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();

            service.deleteTransactionAccount(id).then(
              (response) => {
                dispatch(success(response));
                dispatch(alertActionCreators.success('Conta excluída com sucesso!'));
                dispatch(fetchAllAccounts());

                Swal.close();

                if (callback) {
                  callback();
                }
              },
            ).catch((error) => {
              dispatch(failure(error));

              Swal.close();
            });
          },
        });
      },
      onCancel: () => {},
    });
  };

  function request() {
    return {
      type: constants.DELETE_TRANSACTION_ACCOUNT_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.DELETE_TRANSACTION_ACCOUNT_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_TRANSACTION_ACCOUNT_FAILURE,
      payload: error,
    };
  }
};

export default deleteTransactionAccount;
