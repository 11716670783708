import { connect } from 'react-redux';
import { compose } from 'redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Categories from './Categories';

const mapStateToProps = (state) => ({
  isLoading: state.companyTransactions.isLoading,
  categories: state.companyTransactions.categories,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onFetchCategories: companyTransactionsActionCreators.fetchAllCategories,
  onCreateCategory: companyTransactionsActionCreators.createTransactionCategory,
  onUpdateCategory: companyTransactionsActionCreators.updateCategory,
  onDeleteCategory: companyTransactionsActionCreators.deleteCategory,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['manager_categories'], 'all', true),
)(Categories);
