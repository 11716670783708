import { connect } from 'react-redux';
import { compose } from 'redux';

import subscriptionActionCreators from '_store/_actions/subscription';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Subscription from './Subscription';

const mapStateToProps = (state) => ({
  subscription: state.subscription.subscription,
  user: state.auth.user,
});

const mapDispatchToProps = {
  onFetchPlans: subscriptionActionCreators.fetchSubscriptionPlans,
  onFetchSubscription: subscriptionActionCreators.getUserSubscriptionStatus,
  onCreateUpdatePaymentSession: subscriptionActionCreators.goToUpdatePaymentSession,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['buy_services']),
)(Subscription);
