import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const uploadTransactionFiles = (formData, transaction_id, callback) => {
  return async (dispatch) => {
    dispatch(request());

    service.uploadTransactionFiles(formData, transaction_id).then(
      (response) => {
        dispatch(success(response.data));
        dispatch(alertActionCreators.success('Arquivos enviados com sucesso!'));

        if (callback) {
          callback(response.data);
        }
      },
    ).catch(
      (err) => {
        dispatch(alertActionCreators.error('Os arquivos não foram enviados.'));

        dispatch(failure(err));
      },
    );
  };

  function request() {
    return {
      type: constants.UPLOAD_TRANSACTION_FILES_REQUEST,
      payload: {},
    };
  }
  function success(files) {
    return {
      type: constants.UPLOAD_TRANSACTION_FILES_SUCCESS,
      payload: files,
    };
  }
  function failure(tempId, error) {
    return {
      type: constants.UPLOAD_TRANSACTION_FILES_FAILURE,
      payload: {
        tempId,
        error,
      },
    };
  }
};

export default uploadTransactionFiles;
