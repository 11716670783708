import { connect } from 'react-redux';

import automationsActionCreators from '_store/_actions/automations';

import SearchPDFInvoices from './SearchPDFInvoices';

const mapDispatchToProps = {
  onSearchPDFInvoices: automationsActionCreators.searchPDFInvoices,
};

export default connect(null, mapDispatchToProps)(SearchPDFInvoices);
