import { connect } from 'react-redux';

import taxesActionCreators from '_store/_actions/taxes';

import Taxes from './Taxes';

const mapStateToProps = (state) => ({
  isLoading: state.taxes.isLoading,
  taxes: state.taxes.taxes,
  darfs: state.taxes.darfs,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onFetchTaxes: taxesActionCreators.fetchTaxes,
  onClearTaxes: taxesActionCreators.clearTaxes,
  onCalculateTaxes: taxesActionCreators.calculateTaxes,
  onFetchDarfs: taxesActionCreators.fetchDarfs,
  onDeleteDarf: taxesActionCreators.deleteDarf,
  onDeleteMultipleDarfs: taxesActionCreators.deleteMultipleDarfs,
};

export default connect(mapStateToProps, mapDispatchToProps)(Taxes);
