import HttpService from './http.service';

export default {
  fetchAllReceipts,
  getById,
  insert,
  update,
  remove,
};

async function fetchAllReceipts(params) {
  return HttpService.get('/custom-receipts', params);
}

async function getById(id) {
  return HttpService.get(`/custom-receipts/${id}`);
}

async function insert(receipt) {
  return HttpService.post('/custom-receipts', receipt);
}

async function update(id, receipt) {
  return HttpService.put(`/custom-receipts/${id}`, receipt);
}

async function remove(id) {
  return HttpService.delete(`/custom-receipts/${id}`);
}
