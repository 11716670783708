import styled from 'styled-components';

import { MonthPicker } from '_components/_core';

export const StyledGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  
`;

export const CashflowMonthColumn = styled.div`
  flex-basis: calc(50% - 1em);
  order: 1;
`;

export const MonthScheduleColumn = styled.div`
  flex-basis: 50%;
  margin-left: 1em;
  order: 2;
`;

export const CashflowSemesterColumn = styled.div`
  flex-basis: calc(50% - 1em);
  order: 3;
`;

export const StyledColumn = styled.div`
  
`;

export const StyledMonthPicker = styled(MonthPicker)`
  &&& {
    div.rmp-popup {
      left: -50px;

      @media (max-width: 768px) {
        left: 0;
      }
    }

    button {
      
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 4px;
      }
    }
  }
`;

export const HelpButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5em;
  border-radius: 50%;
  background-color: var(--accent-color);
  color: var(--white-color);
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 1.2em;
  margin-left: 0.5em;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 20px;
  right: 20px;
`;
