import { push } from 'connected-react-router';

import constants from '../../_constants/auth.constants';
import service from '../../../services/auth.service';

const signUp = (user, callback, failureCallback) => {
  return (dispatch) => {
    dispatch(request(user));

    service.signUp(user).then(
      (response) => {
        dispatch(success(response));

        const product = process.env.REACT_APP_CONTABILLS_APP;

        if (product === 'contabills-empresas') {
          if (callback) {
            callback(response.data);
          }
        } else {
          const { id } = response.data;

          dispatch(push(`/cadastrar?step=plan&id=${id}`));
        }
      },
    ).catch(({ response }) => {
      dispatch(failure(response.data));

      if (failureCallback) {
        failureCallback(response.data);
      }
    });
  };

  function request(user) {
    return { type: constants.SIGN_UP_REQUEST, payload: { user } };
  }
  function success(response) {
    return { type: constants.SIGN_UP_SUCCESS, payload: { response } };
  }
  function failure(errors) {
    return { type: constants.SIGN_UP_FAILURE, payload: { errors } };
  }
};

export default signUp;
