import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';

const updatePlan = ({ plan_id }, callback) => {
  return (dispatch) => {
    dispatch(request());

    service.updatePlan({ plan_id }).then(
      (response) => {
        const subscription = response.data;
        dispatch(success(subscription));

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.UPDATE_PLAN_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.UPDATE_PLAN_SUCCESS,
      payload: {
        data,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.UPDATE_PLAN_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default updatePlan;
