import { connect } from 'react-redux';

import associateActionCreators from '_store/_actions/associates';

import Container from './components/Container';

const mapStateToProps = (state) => ({
  associateState: state.associates,
});

const mapDispatchToProps = {
  fetchAssociates: associateActionCreators.getAllAssociates,
  fetchAssociateById: associateActionCreators.getAssociateById,
  onAddAssociate: associateActionCreators.addAssociate,
  onUpdateAssociate: associateActionCreators.updateAssociate,
  onDeleteAssociate: associateActionCreators.deleteAssociate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
