import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';

import Fees from './Fees';

const mapStateToProps = (state) => ({
  isLoading: state.company.isLoading,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onAddFee: companyActionCreators.addCompanyFee,
  onUpdateFee: companyActionCreators.updateCompanyFee,
  onDeleteFee: companyActionCreators.deleteCompanyFee,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Fees);
