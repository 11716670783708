import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import {
  FormTextField,
  FormDateField,
  FormCurrencyField,
  FormAccountSelectField,
} from '_components/_core';

export default function EntriesFilters() {
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Data do Lançamento</Form.Label>
            <FormDateField name="event_date_start" placeholder="Data Inicial" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label />
            <FormDateField name="event_date_end" placeholder="Data Final" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Valor</Form.Label>
            <FormCurrencyField name="amount_start" placeholder="Valor Inicial" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label />
            <FormCurrencyField name="amount_end" placeholder="Valor Final" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Histórico</Form.Label>
            <FormTextField name="narration" placeholder="Digite uma parte do Histórico" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Conta Débito</Form.Label>
            <FormAccountSelectField name="debit_account_id" multiple creatable="account" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Conta Crédito</Form.Label>
            <FormAccountSelectField name="credit_account_id" multiple creatable="account" />
          </Form.Group>
        </Col>
      </Form.Row>
    </Form>
  );
}
