import { connect } from 'react-redux';

import sidebarActionCreators from '_store/_actions/sidebar';
import userActionCreators from '_store/_actions/user';

import PermissionsGate from './PermissionsGate';

const mapStateToProps = (state) => {
  const { id } = state.auth.user;

  return {
    userPermissions: state.userPermissions.permissions[id] || {},
    isLoading: state.userPermissions.isLoading,
  };
};

const mapDispatchToProps = {
  onSidebarToggle: sidebarActionCreators.toggleSidebar,
  onLoadUserPermissions: userActionCreators.fetchUsersPermissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsGate);
