import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const updateTransactionsTransferDetails = (params, callback) => {
  return async (dispatch) => {
    dispatch(request(params));

    service.updateTransactionsTransferDetails(params).then((response) => {
      dispatch(success(response.data));

      if (callback) {
        callback();
      }
    }).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(params) {
    return {
      type: constants.UPDATE_MULTIPLE_TRANSACTIONS_TRANSFER_DETAILS_REQUEST,
      payload: {
        params,
      },
    };
  }
  function success(transactions) {
    return {
      type: constants.UPDATE_MULTIPLE_TRANSACTIONS_TRANSFER_DETAILS_SUCCESS,
      payload: {
        transactions,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.UPDATE_MULTIPLE_TRANSACTIONS_TRANSFER_DETAILS_FAILURE,
      payload: error,
    };
  }
};

export default updateTransactionsTransferDetails;
