import { DateTime } from 'luxon';

const parseDateIgnoreHours = (date) => {
  if (!date) {
    return date;
  }

  if (typeof date === 'string') {
    return DateTime.fromISO(date, { zone: 'utc' }).toFormat('yyyy-MM-dd');
  }

  return DateTime.fromObject({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    zone: 'utc',
  }).toISO({ suppressMilliseconds: true });
};

export default parseDateIgnoreHours;
