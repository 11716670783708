import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledHeader = styled.header`
  position: absolute;
  top: 0;
  width: 55%;
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
  }

  @media (max-width: 1084px) {
    width: 100%;
    justify-content: space-around;
  }
`;

export const StyledFormWrapper = styled.div`
  @media (max-width: 768px) {
    width: 85%;
  }
`;

export const BgContainer = styled.div`
  flex-direction: row;
  align-items: center;
  height: 100vh;
  padding: 15%;
  justify-content: center;
  background: url('https://contabil-easy.s3.sa-east-1.amazonaws.com/assets/zenpaper-password-reset.png');
  background-size: cover;

  @media (max-width: 1084px) {
    display: none;
  }
`;

export const CustomContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.7fr;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #ffffff;

  @media (max-width: 1084px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledMobileFooter = styled.footer`
  position: absolute;
  bottom: 0;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
  }

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
  }

  a {
    justify-content: space-between;

    :first-child {
      padding-right: 15px;
    }
  }

  @media (min-width: 1199px) {
    display: none;
  }
`;

export const StyledFooter = styled.footer`
  position: absolute;
  bottom: 0;
  width: 55%;
  display: flex;
  flex-direction: row;

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
  }

  a {
    margin-left: 25px;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`;
