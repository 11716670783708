import { DREConstants } from '../_constants';

const INITIAL_STATE = {
  isLoading: false,
  DRE: [],
};

export function DRE(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DREConstants.DRE_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DREConstants.DRE_GET_SUCCESS: {
      const { DRE } = action.payload;

      return {
        ...state,
        DRE,
        isLoading: false,
      };
    }
    case DREConstants.DRE_GET_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case DREConstants.DRE_ERASE_REQUEST:
      return {
        ...state,
        DRE: [],
        isLoading: false,
      };
    case DREConstants.DRE_ERASE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case DREConstants.DRE_ERASE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
