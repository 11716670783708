import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/employees.constants';
import service from '../../../services/employees.service';
import alertActionCreators from '../alert.actions';

const deleteEmployee = (id, callback) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Exclusão de sócio',
      message: 'Tem certeza que deseja excluir este sócio? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, deletar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.remove(id).then(
          () => {
            dispatch(success(id));
            dispatch(alertActionCreators.success('Sócio excluído com sucesso!'));

            if (callback) {
              callback();
            }
          },
        ).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });

    dispatch(request(id));
  };

  function request(id) {
    return {
      type: constants.EMPLOYEE_DELETE_REQUEST,
      payload: { id },
    };
  }
  function success(id) {
    return {
      type: constants.EMPLOYEE_DELETE_SUCCESS,
      payload: { id },
    };
  }
  function failure(error) {
    return {
      type: constants.EMPLOYEE_DELETE_FAILURE,
      payload: error,
    };
  }
};

export default deleteEmployee;
