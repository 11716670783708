import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import { Formik } from 'formik';

import { getQueryVariable, parseDateIgnoreHours } from 'helpers';
import { PageHeader } from '_components/_core';

import { Filters, Results } from './components';

function Ledger({
  isLoading,
  ledger,
  activeCompany,
  onFetchLedger,
  onClearLedger,
  onFetchAccounts,
}) {
  useEffect(() => {
    onClearLedger();
  }, [onClearLedger]);

  useEffect(() => {
    onFetchAccounts();
    onClearLedger();
  }, [onFetchAccounts, onClearLedger, activeCompany]);

  const handleGenerateReport = useCallback((values) => {
    onFetchLedger({
      ...values,
    });
  }, [onFetchLedger]);

  const formInitialValues = useMemo(() => {
    const id = getQueryVariable('id');
    const start_date = getQueryVariable('start_date');
    const end_date = getQueryVariable('end_date');

    if (id) {
      return {
        start_date,
        end_date,
        accounts: [id],
      };
    }

    const { active_period_start, active_period_end } = activeCompany || {};

    if (active_period_start && active_period_end) {
      return {
        start_date: parseDateIgnoreHours(active_period_start),
        end_date: parseDateIgnoreHours(active_period_end),
        accounts: [],
      };
    }

    return {
      start_date: null,
      end_date: null,
      accounts: [],
    };
  }, [activeCompany]);

  useEffect(() => {
    const id = getQueryVariable('id');
    const start_date = getQueryVariable('start_date');
    const end_date = getQueryVariable('end_date');

    if (id && start_date && end_date) {
      onFetchLedger({
        start_date,
        end_date,
        accounts: [id],
      });
    }
  }, [onFetchLedger]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Razão Contábil"
        description="Relatório que demonstra a movimentação analítica das contas contábeis selecionadas."
      />
      <Formik
        initialValues={formInitialValues}
        onSubmit={handleGenerateReport}
        enableReinitialize
      >
        {({ handleSubmit, values }) => (
          <>
            <Tab.Container defaultActiveKey="month">
              <Row>
                <Col lg={3} md={12}>
                  <Filters isLoading={isLoading} onSubmit={handleSubmit} />
                </Col>
                <Col lg={9} md={12}>
                  <Results
                    ledger={ledger}
                    values={values}
                    activeCompany={activeCompany}
                  />
                </Col>
              </Row>
            </Tab.Container>
          </>
        )}
      </Formik>
    </Container>
  );
}

Ledger.defaultProps = {
  ledger: null,
  isLoading: false,
};

Ledger.propTypes = {
  isLoading: PropTypes.bool,
  activeCompany: PropTypes.object,
  ledger: PropTypes.object,
  onClearLedger: PropTypes.func.isRequired,
  onFetchLedger: PropTypes.func.isRequired,
  onFetchAccounts: PropTypes.func.isRequired,
};

export default Ledger;
