import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import {
  FormTextField,
  FormDateField,
  FormCurrencyField,
  FormSelectField,
} from '_components/_core';

import { EMISSION_STATUS } from './utilities';

function IssuedInvoiceFilters() {
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Competência</Form.Label>
            <FormDateField name="date_competence_start" placeholder="Data Inicial" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label />
            <FormDateField name="date_competence_end" placeholder="Data Final" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Emissão</Form.Label>
            <FormDateField name="date_emission_start" placeholder="Data Inicial" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label />
            <FormDateField name="date_emission_end" placeholder="Data Final" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Número da Nota Fiscal</Form.Label>
            <FormTextField name="invoice_number" placeholder="Número da Nota Fiscal" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Status da Emissão</Form.Label>
            <FormSelectField name="emission_status" options={EMISSION_STATUS} />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Tomador (CPF, CNPJ ou Nome)</Form.Label>
            <FormTextField name="service_taker" placeholder="Tomador" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Valor Bruto</Form.Label>
            <FormCurrencyField name="service_amount_start" placeholder="Valor Inicial" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label />
            <FormCurrencyField name="service_amount_end" placeholder="Valor Final" />
          </Form.Group>
        </Col>
      </Form.Row>
    </Form>
  );
}

export default IssuedInvoiceFilters;
