import { connect } from 'react-redux';

import invoicesActionCreators from '_store/_actions/invoices';
import invoicesSelectors from '_store/_selectors/invoices';

import InvoicesLookup from './InvoicesLookup';

const mapStateToProps = (state) => ({
  isLoading: invoicesSelectors.isLoadingInvoicesSelector(state),
  invoices: invoicesSelectors.invoicesSelector(state),
  total: state.invoices.total,
  uploadResults: invoicesSelectors.uploadResultsSelector(state),
});

const mapDispatchToProps = {
  onFetchInvoices: invoicesActionCreators.getAllInvoices,
  onDeleteInvoice: invoicesActionCreators.deleteInvoice,
  onClearUploadResults: invoicesActionCreators.clearUploadResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesLookup);
