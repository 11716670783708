import axios from 'axios';
import FORMATTERS from 'helpers/formatters';

import constants from '../../_constants/ibge.constants';

const fetchCep = (cep, callback, callbackError) => (dispatch) => {
  dispatch(({ type: constants.FETCH_CEP_REQUEST }));

  const cleanCep = FORMATTERS.CLEAN_CEP(cep);

  axios.get(`https://viacep.com.br/ws/${cleanCep}/json`)
    .then((response) => {
      dispatch({
        type: constants.FETCH_CEP_SUCCESS,
        payload: {
          address: response.data,
        },
      });

      callback(response.data);
    }).catch((error) => {
      if (callbackError) {
        callbackError(error);
      }

      dispatch(({
        type: constants.FETCH_CEP_FAILURE,
        payload: error,
      }));
    });
};

export default fetchCep;
