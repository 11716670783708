import styled, { css } from 'styled-components';

export const CategoryOptions = styled.div`
  text-align: left;
`;

export const GroupHeader = styled.div`
  border-bottom: 1px solid #e0e0e0;
  font-size: 13px;
  padding: 6px 12px;
  color: #00784e;
  font-weight: 500;
  cursor: default;
`;

export const OptionsList = styled.ul`
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  padding: 0;
  margin: 0;

  li {
  
  }
`;

export const OptionListItem = styled.li`
  background-color: transparent;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-size: 13px;
  width: 100%;
  padding: 6px 12px;
  user-select: none;
  box-sizing: border-box;
  font-weight: 400;

  &:hover {
    color: #00784e;
    background-color: #eceef1;
    font-weight: 500;
  }

  ${({ isActive }) => isActive && css`
    color: #00784e !important;
    background-color: #eceef1 !important;
    font-weight: 500 !important;
  `}
`;
