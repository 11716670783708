import { connect } from 'react-redux';

import employeesActionCreators from '_store/_actions/employees';

import Dashboard from './Dashboard';

const mapStateToProps = (state) => ({
  employees: state.employees.employees,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onFetchEmployees: employeesActionCreators.getAllEmployees,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
