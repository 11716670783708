import constants from '../../_constants/entries.constants';
import service from '../../../services/entry.service';

const fetchEntry = (id, callback) => {
  return (dispatch) => {
    dispatch(request());

    service.getById(id).then(
      (response) => {
        const entry = response.data;

        dispatch(success(entry));

        if (callback) {
          callback(entry);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.ENTRY_GET_REQUEST,
      payload: { },
    };
  }
  function success(entry) {
    return {
      type: constants.ENTRY_GET_SUCCESS,
      payload: entry,
    };
  }
  function failure(error) {
    return {
      type: constants.ENTRY_GET_FAILURE,
      payload: error,
    };
  }
};

export default fetchEntry;
