import { push } from 'connected-react-router';
// import * as Sentry from '@sentry/browser';

import constants from '../../_constants/auth.constants';
import service from '../../../services/auth.service';
import alertActionCreators from '../alert.actions';

const product = process.env.REACT_APP_CONTABILLS_APP;

const login = (email, password, callback, failureCallback) => {
  return (dispatch) => {
    dispatch(request({ email }));

    service.login(email, password).then(
      (user) => {
        if (product === 'contabills-empresas') {
          dispatch(success(user));

          // const {
          //   id,
          //   full_name: username,
          //   email,
          //   document_type: segment,
          // } = user || {};

          // Sentry.setUser({
          //   id,
          //   username,
          //   email,
          //   segment,
          //   ...user,
          // });

          localStorage.removeItem('hideTrialAlert');

          const { date_onboarding_complete, company_count } = user;

          if (company_count > 0 || date_onboarding_complete !== null) {
            dispatch(push('/dashboard'));
          } else {
            dispatch(push('/onboarding'));
          }

          return;
        }

        dispatch(success(user));

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      if (failureCallback) {
        failureCallback(error);
      }

      if (error && error.response.status === 303) {
        const { metadata } = error.response.data;
        const {
          reason,
          id,
          name,
          account_type,
          payment_url,
          email,
        } = metadata;

        switch (product) {
          case 'contabills-empresas': {
            localStorage.setItem('account_type', account_type);
            localStorage.setItem('name', name);

            if (reason === 'past_due') {
              dispatch(failure(error));
              dispatch(push(`/ativar-assinatura?step=past_due&id=${id}&name=${name}&account_type=${account_type}&payment_url=${payment_url}`));
            }

            if (reason === 'subscription_missing') {
              dispatch(failure(error));
              dispatch(push(`/ativar-assinatura?id=${id}&name=${name}&account_type=${account_type}`));
            }

            if (reason === 'email_not_confirmed') {
              dispatch(failure(error));
              dispatch(push(`/confirmar-email?email=${encodeURIComponent(email)}`));
            }

            break;
          }

          default: {
            if (reason === 'subscription_missing') {
              dispatch(failure(error));
              dispatch(push(`/cadastrar?step=plan&id=${id}`));
            }

            break;
          }
        }
      } else if (error && error.response.status !== 200) {
        dispatch(failure(error));
        dispatch(alertActionCreators.error(error.response.data.message));
      }
    });
  };

  function request(email) {
    return { type: constants.LOGIN_REQUEST, payload: { email } };
  }
  function success(user) {
    return { type: constants.LOGIN_SUCCESS, payload: { user } };
  }
  function failure(error) {
    return { type: constants.LOGIN_FAILURE, payload: { error } };
  }
};

export default login;
