import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import { useHistory } from 'react-router-dom';

import { Button } from '_components/_core';

import { IoSearch } from 'react-icons/io5';
import {
  CustomFilterButton,
  FilterMenu,
  FilterDescription,
  InputContainer,
  StyledDropdown,
} from './styles';

const SearchIcon = ({ id, className, isMobile }) => {
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [tempText, setTempText] = useState('');
  const inputRef = React.useRef(null);

  const handleToggle = useCallback((isOpen, _ev, metadata) => {
    if (metadata.source === 'select') {
      setOpen(true);
      return;
    }

    setOpen(isOpen);
  }, []);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [open]);

  const handleSubmit = useCallback(() => {
    history.push(`/busca?q=${tempText}`);

    setTempText('');
  }, [tempText, history]);

  return (
    <StyledDropdown
      id={id}
      show={open}
      onToggle={handleToggle}
      className={className}
      drop="left"
    >
      <CustomFilterButton className="mr-3" variant="link" as={Dropdown.Toggle}>
        <IoSearch size="1.3em" />
      </CustomFilterButton>
      <FilterMenu isMobile={isMobile}>
        <FilterDescription>
          O que você procura?
        </FilterDescription>
        <InputContainer>
          <Form.Control
            ref={inputRef}
            type="text"
            value={tempText}
            onChange={(e) => setTempText(e.target.value)}
            autoComplete="off"
            placeholder="O que você procura?"
            autoFocus
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();

                handleSubmit();
                setOpen(false);
              }
            }}
          />
          <Button
            className="mt-3 mr-2"
            size="sm"
            variant="dark"
            onClick={() => {
              handleSubmit();
              setOpen(false);
            }}
          >
            Pesquisar
          </Button>
        </InputContainer>
      </FilterMenu>
    </StyledDropdown>
  );
};

export default SearchIcon;

SearchIcon.defaultProps = {
  id: null,
  className: null,
  isMobile: false,
};

SearchIcon.propTypes = {
  isMobile: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
};
