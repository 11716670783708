import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';

import Credentials from './Credentials';

const mapStateToProps = (state) => ({
  isLoading: state.company.isLoading,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onAddCredential: companyActionCreators.addCompanyCredential,
  onUpdateCredential: companyActionCreators.updateCompanyCredential,
  onDeleteCredential: companyActionCreators.deleteCompanyCredential,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Credentials);
