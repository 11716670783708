import has from 'lodash/has';

import {
  exportCashflowStatementByType,
  exportCashflowStatement,
  exportDemostrativeResults,
  exportExpensesByCategory,
  exportExpensesByCostCenter,
  exportExpensesByDay,
  exportExpensesByDescription,
  exportExpensesByRecipient,
  exportExpensesByTags,
  exportExpensesByType,
  exportHistoricalIncomeExpenses,
  exportIncomesByCategory,
  exportIncomesByDay,
  exportIncomesByDescription,
  exportIncomesByTags,
  exportIncomesByCostCenter,
  exportIncomesByRecipient,
} from './reports';

const exportToExcel = ({
  report_name,
  reports,
  accounts,
  selected_tags,
  formattedPeriod,
  date_type,
}) => {
  if (!report_name) {
    alert('Selecione um relatório para exportar.');

    return;
  }

  if (!has(reports, report_name)) {
    return;
  }

  const params = {
    report_name,
    reports,
    accounts,
    selected_tags,
    formattedPeriod,
    date_type,
  };

  switch (report_name) {
    case 'cashflow_statement_by_type': {
      exportCashflowStatementByType(params);

      break;
    }

    case 'cashflow_statement': {
      exportCashflowStatement(params);

      break;
    }

    case 'demonstrative_results': {
      exportDemostrativeResults(params);

      break;
    }

    case 'expenses_by_category': {
      exportExpensesByCategory(params);

      break;
    }

    case 'expenses_by_cost_center': {
      exportExpensesByCostCenter(params);

      break;
    }

    case 'expenses_by_day': {
      exportExpensesByDay(params);

      break;
    }

    case 'expenses_by_description': {
      exportExpensesByDescription(params);

      break;
    }

    case 'expenses_by_recipient': {
      exportExpensesByRecipient(params);

      break;
    }

    case 'expenses_by_tags': {
      exportExpensesByTags(params);

      break;
    }

    case 'expenses_by_type': {
      exportExpensesByType(params);

      break;
    }

    case 'historical_income_expenses': {
      exportHistoricalIncomeExpenses(params);

      break;
    }

    case 'income_by_category': {
      exportIncomesByCategory(params);

      break;
    }

    case 'income_by_day': {
      exportIncomesByDay(params);

      break;
    }

    case 'income_by_description': {
      exportIncomesByDescription(params);

      break;
    }

    case 'income_by_tags': {
      exportIncomesByTags(params);

      break;
    }

    case 'incomes_by_cost_center': {
      exportIncomesByCostCenter(params);

      break;
    }

    case 'incomes_by_recipient': {
      exportIncomesByRecipient(params);

      break;
    }

    default: {
      alert('Este relatório não possui exportação para Excel.');

      break;
    }
  }
};

export default exportToExcel;
