import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/automations.constants';
import service from '../../../services/automations.service';

const deleteAutomationSettings = (id, callback) => {
  return async (dispatch) => {
    dispatch(request());

    confirmDialog.warning({
      title: 'Excluir configuração',
      message: 'Tem certeza que deseja excluir esta configuração de automação? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, deletar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.deleteAutomationSettings(id).then(
          (response) => {
            dispatch(success(response));

            if (callback) {
              callback();
            }
          },
        ).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request() {
    return {
      type: constants.DELETE_AUTOMATION_SETTINGS_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.DELETE_AUTOMATION_SETTINGS_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_AUTOMATION_SETTINGS_FAILURE,
      payload: error,
    };
  }
};

export default deleteAutomationSettings;
