import HttpService from './http.service';

export default {
  calculateTaxes,
  fetchTaxes,
  generateTaxesEntries,
  fetchDarfs,
  generateDarfs,
  deleteDarf,
  deleteMultipleDarfs,
  fetchManagerDarfs,
};

async function calculateTaxes(params) {
  return HttpService.post('/taxes/calculate', params);
}

async function fetchTaxes(params) {
  return HttpService.get('/taxes', params);
}

async function generateTaxesEntries(params) {
  return HttpService.post('/taxes-entries', params);
}

async function fetchDarfs(year) {
  return HttpService.get(`/taxes/darf?year=${year}`);
}

async function fetchManagerDarfs(year) {
  return HttpService.get(`/taxes/manager-darfs?year=${year}`);
}

async function generateDarfs(params) {
  return HttpService.post('/taxes/darf', params);
}

async function deleteDarf(id) {
  return HttpService.delete(`/taxes/darf/${id}`);
}

async function deleteMultipleDarfs(params) {
  return HttpService.delete('/taxes/multiple-darfs', params);
}
