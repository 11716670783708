import { useMemo, useCallback } from 'react';

import { getCustomersOptions, getServicesOptions, getPersonsOptions } from '.';

const useInvoiceForm = ({ invoiceGenerationParams }) => {
  const persons = useMemo(
    () => getPersonsOptions(invoiceGenerationParams),
    [invoiceGenerationParams],
  );

  const handlePersonSelected = useCallback((option, setFieldValue) => {
    if (!option) {
      setFieldValue('service_taker.customer_id', null);
      setFieldValue('service_taker.document_number', null);
      setFieldValue('service_taker.document_type', null);
      setFieldValue('service_taker.name', null);
      setFieldValue('service_taker.email', null);
      setFieldValue('service_taker.phone_number', null);
      setFieldValue('service_taker.address.address_zip_code', null);
      setFieldValue('service_taker.address.address_street', null);
      setFieldValue('service_taker.address.address_number', null);
      setFieldValue('service_taker.address.address_complement', null);
      setFieldValue('service_taker.address.address_district', null);
      setFieldValue('service_taker.address.address_city', null);
      setFieldValue('service_taker.address.address_city_ibge', null);
      setFieldValue('service_taker.address.address_state', null);
      setFieldValue('service_taker.address.address_state_ibge', null);

      return;
    }

    const {
      value,
      document_number,
      document_type,
      name,
      email,
      phone_number,
      address_zip_code,
      address_street,
      address_number,
      address_complement,
      address_district,
      address_city,
      address_city_ibge,
      address_state,
      address_state_ibge,
    } = option;

    setFieldValue('service_taker.customer_id', value);
    setFieldValue('service_taker.document_number', document_number);
    setFieldValue('service_taker.document_type', document_type);
    setFieldValue('service_taker.name', name);
    setFieldValue('service_taker.email', email);
    setFieldValue('service_taker.phone_number', phone_number);
    setFieldValue('service_taker.address.address_zip_code', address_zip_code);
    setFieldValue('service_taker.address.address_street', address_street);
    setFieldValue('service_taker.address.address_number', address_number);
    setFieldValue('service_taker.address.address_complement', address_complement);
    setFieldValue('service_taker.address.address_district', address_district);
    setFieldValue('service_taker.address.address_city', address_city);
    setFieldValue('service_taker.address.address_city_ibge', address_city_ibge);
    setFieldValue('service_taker.address.address_state', address_state);
    setFieldValue('service_taker.address.address_state_ibge', address_state_ibge);
  }, []);

  const getSelectedPerson = useCallback((values) => {
    if (!values.service_taker.customer_id) {
      return null;
    }

    return persons.find((person) => person.value === values.service_taker.customer_id);
  }, [persons]);

  const customers = useMemo(
    () => getCustomersOptions(invoiceGenerationParams),
    [invoiceGenerationParams],
  );

  const handleCustomerSelected = useCallback((option, setFieldValue) => {
    const {
      value,
      document_number,
      name,
      email,
      phone_number,
      address_zip_code,
      address_street,
      address_number,
      address_complement,
      address_district,
      address_city,
      address_city_ibge,
      address_state,
      address_state_ibge,
    } = option;

    setFieldValue('service_taker.customer_id', value);
    setFieldValue('service_taker.document_number', document_number);
    setFieldValue('service_taker.name', name);
    setFieldValue('service_taker.email', email);
    setFieldValue('service_taker.phone_number', phone_number);
    setFieldValue('service_taker.address.address_zip_code', address_zip_code);
    setFieldValue('service_taker.address.address_street', address_street);
    setFieldValue('service_taker.address.address_number', address_number);
    setFieldValue('service_taker.address.address_complement', address_complement);
    setFieldValue('service_taker.address.address_district', address_district);
    setFieldValue('service_taker.address.address_city', address_city);
    setFieldValue('service_taker.address.address_city_ibge', address_city_ibge);
    setFieldValue('service_taker.address.address_state', address_state);
    setFieldValue('service_taker.address.address_state_ibge', address_state_ibge);
  }, []);

  const getSelectedCustomer = useCallback((values) => {
    if (!values.service_taker.customer_id) {
      return null;
    }

    return customers.find((customer) => customer.value === values.service_taker.customer_id);
  }, [customers]);

  const services = useMemo(
    () => getServicesOptions(invoiceGenerationParams),
    [invoiceGenerationParams],
  );

  const handleServiceSelected = useCallback((option, setFieldValue) => {
    const { value, service_district_tax_code, subitem } = option;

    setFieldValue('service.service_list_item_id', value);
    setFieldValue('service.service_list_item_code', subitem);
    setFieldValue('service.service_district_tax_code', service_district_tax_code);
  }, []);

  const getSelectedService = useCallback((values) => {
    if (!values.service.service_list_item_id) {
      return null;
    }

    return services.find((service) => service.value === values.service.service_list_item_id);
  }, [services]);

  const natures = useMemo(() => {
    const { nature_of_operation } = invoiceGenerationParams || {};

    if (!nature_of_operation) {
      return [];
    }

    return nature_of_operation;
  }, [invoiceGenerationParams]);

  const taxRegimes = useMemo(() => {
    const { special_tax_regime } = invoiceGenerationParams || {};

    if (!special_tax_regime) {
      return [
        {
          value: null,
          label: 'Não informar',
        },
      ];
    }

    const finalOptions = [
      {
        value: null,
        label: 'Não informar',
      },
      ...special_tax_regime,
    ];

    return finalOptions;
  }, [invoiceGenerationParams]);

  return {
    persons,
    customers,
    services,
    natures,
    taxRegimes,
    onCustomerSelected: handleCustomerSelected,
    onPersonSelected: handlePersonSelected,
    onServiceSelected: handleServiceSelected,
    onFindSelectedCustomer: getSelectedCustomer,
    onFindSelectedService: getSelectedService,
    onFindSelectedPerson: getSelectedPerson,
  };
};

export default useInvoiceForm;
