import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const createRecurringTransaction = (params, callback) => {
  return async (dispatch) => {
    dispatch(request(params));

    service.createRecurringTransaction(params).then(
      (response) => {
        dispatch(success(response.data, params));
        dispatch(alertActionCreators.success('Transação atualizada com sucesso!'));

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(params) {
    return {
      type: constants.CREATE_RECURRING_TRANSACTION_REQUEST,
      payload: params,
    };
  }
  function success(data, params) {
    return {
      type: constants.CREATE_RECURRING_TRANSACTION_SUCCESS,
      payload: {
        transactions: data,
        params,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.CREATE_RECURRING_TRANSACTION_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default createRecurringTransaction;
