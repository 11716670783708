const getTableTopDistance = (uploadResults) => {
  let alertHeight = 0;

  if (uploadResults) {
    alertHeight = 147;
  }

  const calcHeight = `calc(100vh - (70px + 61px + 65px + 1rem + 3rem + 66px + 1rem + ${alertHeight}px))`;

  return calcHeight;
};

export default getTableTopDistance;
