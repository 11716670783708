import constants from '../../_constants/associates.constants';
import service from '../../../services/associates.service';

const getAllAssociates = () => {
  return (dispatch) => {
    dispatch(request());

    service.getAll().then(
      (response) => {
        dispatch(success(response.data));
      },
    );
  };

  function request() {
    return { type: constants.ASSOCIATES_GET_ALL_REQUEST, payload: {} };
  }
  function success(associates) {
    return {
      type: constants.ASSOCIATES_GET_ALL_SUCCESS,
      payload: { associates },
    };
  }
};

export default getAllAssociates;
