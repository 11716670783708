import { connect } from 'react-redux';

import invoicesActionCreators from '_store/_actions/invoices';
import payrollActionCreators from '_store/_actions/payroll';
import taxesActionCreators from '_store/_actions/taxes';

import AccountingIntegrationModal from './AccountingIntegrationModal';

const mapStateToProps = (state) => ({
  isLoadingInvoices: state.invoices.isLoading,
  isLoadingPayroll: state.payroll.isLoading,
  isLoadingTaxes: state.taxes.isLoading,
});

const mapDispatchToProps = {
  onGenerateInvoiceEntries: invoicesActionCreators.generateInvoiceEntries,
  onGeneratePayrollEntries: payrollActionCreators.generatePayrollEntries,
  onGenerateTaxesEntries: taxesActionCreators.generateTaxesEntries,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountingIntegrationModal);
