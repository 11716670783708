import FORMATTERS from 'helpers/formatters';

export const INTALMENTS_OPTIONS = Array.from({ length: 60 }, (_, i) => i + 1).map((instalment) => ({
  value: instalment,
  label: instalment === 1 ? 'À vista' : `${instalment} parcelas`,
}));

const getInstalmentOptions = (values) => {
  const { amount, type } = values;

  if (type === 'TOTAL_AMOUNT') {
    return INTALMENTS_OPTIONS.map((option) => {
      const { value } = option;

      if (value === 1) {
        return option;
      }

      const instalmentAmount = amount / value;

      return {
        ...option,
        label: `${value}x de ${FORMATTERS.NUMBER(instalmentAmount)}`,
      };
    });
  }

  return INTALMENTS_OPTIONS.map((option) => {
    const { value } = option;

    if (value === 1) {
      return option;
    }

    return {
      ...option,
      label: `${value}x de ${FORMATTERS.NUMBER(amount)}`,
    };
  });
};

export default getInstalmentOptions;
