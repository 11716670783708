import { parsePaginationParams } from 'helpers';

import constants from '../../_constants/employees.constants';
import service from '../../../services/employees.service';

const getEmployeeVacations = (employeeId, params = {}) => {
  return (dispatch) => {
    dispatch(request(employeeId));

    const parsedParams = parsePaginationParams(params);

    service.getAllVacations(employeeId, parsedParams).then(
      (response) => {
        const vacations = response.data;

        dispatch(success(vacations));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(employeeId) {
    return {
      type: constants.GET_EMPLOYEE_VACATIONS_REQUEST,
      payload: { employeeId },
    };
  }
  function success(response) {
    return {
      type: constants.GET_EMPLOYEE_VACATIONS_SUCCESS,
      payload: response,
    };
  }
  function failure(response) {
    return {
      type: constants.GET_EMPLOYEE_VACATIONS_SUCCESS,
      payload: response,
    };
  }
};

export default getEmployeeVacations;
