import { meiConstants as constants } from '../_constants';

const INITIAL_STATE = {
  isLoading: false,
  company_meis: [],
  das: [],
};

export function mei(state = INITIAL_STATE, action) {
  switch (action.type) {
    case constants.FETCH_ALL_COMPANY_MEIS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.FETCH_ALL_COMPANY_MEIS_SUCCESS: {
      const meis = action.payload;

      return {
        ...state,
        company_meis: meis,
        isLoading: false,
      };
    }
    case constants.FETCH_ALL_COMPANY_MEIS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case constants.CREATE_MEI_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.CREATE_MEI_SUCCESS: {
      const created_mei = action.payload;

      const meis = [...state.company_meis, created_mei];

      return {
        ...state,
        company_meis: meis,
        isLoading: false,
      };
    }
    case constants.CREATE_MEI_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case constants.DELETE_MEI_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.DELETE_MEI_SUCCESS: {
      const id = action.payload;

      const meis = state.company_meis.filter((mei) => mei.id !== id);

      return {
        ...state,
        company_meis: meis,
        isLoading: false,
      };
    }
    case constants.DELETE_MEI_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case constants.FETCH_MEI_DAS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case constants.FETCH_MEI_DAS_SUCCESS: {
      const das = action.payload;

      return {
        ...state,
        das,
        isLoading: false,
      };
    }
    case constants.FETCH_MEI_DAS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
