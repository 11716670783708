import React from 'react';

import Associates from '_components/_shared/Associates';

function AssociatesTab() {
  return (
    <Associates />
  );
}

export default AssociatesTab;
