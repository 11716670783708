import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';
import { FaTrash } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

import FORMATTERS from 'helpers/formatters';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  MonthPicker,
  Button,
  LoadingOverlay,
} from '_components/_core';

import { SalaryReceiptModal } from './components';
import { StyledTable, IrrfButton } from './styles';

function GeneratePayroll({
  isLoading,
  payroll,
  activeCompany,
  allEmployees,
  onFetchPayrolls,
  onGeneratePayroll,
  onFetchEmployees,
  onDeletePayroll,
  onSendSalaryReceiptMail,
  onGenerateDarfs,
}) {
  const [selectedDate, setSelectedDate] = useState(DateTime.now());
  const [selectedPayroll, setSelectedPayroll] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isDisabled = !isEmpty(payroll.payroll_events);
  const year = Number(selectedDate.toFormat('yyyy'));
  const month = Number(selectedDate.toFormat('MM'));

  useEffect(() => {
    onFetchEmployees();
  }, [onFetchEmployees, activeCompany]);

  useEffect(() => {
    onFetchPayrolls({ year, month });
  }, [onFetchPayrolls, selectedDate, month, year, activeCompany]);

  const handleGeneratePayrolls = useCallback(() => {
    const employees = allEmployees.map((employee) => employee.id);

    onGeneratePayroll({ year, month, employees });
  }, [onGeneratePayroll, allEmployees, month, year]);

  const handleViewSalaryReceipt = useCallback((payroll_event) => {
    setSelectedPayroll(null);
    setSelectedPayroll(payroll_event);
    setIsModalOpen(true);
  }, []);

  const handleDeletePayroll = useCallback(() => {
    const params = { year, month };

    onDeletePayroll(params, () => {
      onFetchPayrolls(params);
    });
  }, [onDeletePayroll, month, year, onFetchPayrolls]);

  const renderEventTypeLabel = (event_type) => {
    switch (event_type) {
      case 'SALARY':
        return (
          <span className="event-type">
            <strong>Evento:&nbsp;</strong>
            Salário
          </span>
        );

      default:
        return null;
    }
  };

  const handleGenerateIRRF = useCallback((irrf_amount) => {
    const params = [
      {
        tax_name: 'IRRF',
        amount: irrf_amount,
        competence: selectedDate.toFormat('yyyy-MM-dd'),
        company_id: activeCompany?.id,
      },
    ];

    onGenerateDarfs(params);
  }, [onGenerateDarfs, selectedDate, activeCompany]);

  const renderIRRFButton = (irrf_amount) => {
    const formattedIRRF = FORMATTERS.NUMBER(irrf_amount);

    if (irrf_amount === 0 || !irrf_amount) {
      return formattedIRRF;
    }

    const tooltipContent = `Gerar IRRF no valor de <strong>${formattedIRRF}</ strong>`;

    return (
      <>
        <ReactTooltip className="tooltip" place="bottom" html />
        <IrrfButton
          data-tip={tooltipContent}
          variant="link btn-sm"
          onClick={() => handleGenerateIRRF(irrf_amount)}
        >
          {formattedIRRF}
        </IrrfButton>
      </>
    );
  };

  return (
    <>
      <SalaryReceiptModal
        isVisible={isModalOpen}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
        activeCompany={activeCompany}
        selectedPayroll={selectedPayroll}
        company={activeCompany}
        onSendSalaryReceiptMail={onSendSalaryReceiptMail}
        isLoading={isLoading}
      />
      <Row>
        <Col xs={12} sm={12} lg={12}>
          <LoadingOverlay active={isLoading}>
            <Card>
              <CardHeader
                title="Folha de Pagamento"
                description="Geração e listagem da Folha de Pagamento para o período selecionado"
                sideContent={(
                  <>
                    <MonthPicker
                      value={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                    />
                    <Button
                      onClick={handleGeneratePayrolls}
                      className="float-right mr-3"
                      variant="primary"
                      disabled={isLoading || isDisabled}
                    >
                      {`Gerar Folha de ${selectedDate.toFormat('MM/yyyy')}`}
                    </Button>
                  </>
              )}
              />
              <CardBody noPaddingTop>
                <Row>
                  <Col>
                    <div className="table-responsive">
                      <StyledTable className="table table-hover table-striped">
                        <thead className="text-center">
                          <tr>
                            <th>
                              &nbsp;
                            </th>
                            <th colSpan={2}>VENCIMENTOS (A)</th>
                            <th colSpan={3}>DESCONTOS (B)</th>
                            <th>VALOR LÍQUIDO</th>
                            <th colSpan={2}>&nbsp;</th>
                          </tr>
                          <tr>
                            <th className="text-left" width="50%">FUNCIONÁRIO / TIPO DE EVENTO</th>
                            <th width="5%">SALÁRIOS</th>
                            <th width="5%">OUTROS</th>
                            <th width="5%">INSS</th>
                            <th width="5%">IRRF</th>
                            <th width="5%">OUTROS</th>
                            <th width="10%">(A - B)</th>
                            <th width="5%">FGTS</th>
                            <th width="5%">Recibo</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payroll.payroll_events.map((payroll_event) => (
                            <tr className="text-center">
                              <td className="text-left">
                                <span className="employee-name">
                                  {payroll_event.employee_name}
                                </span>
                                {renderEventTypeLabel(payroll_event.event_type)}
                              </td>
                              <td>{FORMATTERS.NUMBER(payroll_event.income_salary)}</td>
                              <td>{FORMATTERS.NUMBER(payroll_event.income_others)}</td>
                              <td>{FORMATTERS.NUMBER(payroll_event.deduction_inss)}</td>
                              <td>
                                {renderIRRFButton(payroll_event.deduction_irrf)}
                              </td>
                              <td>{FORMATTERS.NUMBER(payroll_event.deduction_other)}</td>
                              <td>{FORMATTERS.NUMBER(payroll_event.net_amount)}</td>
                              <td>{FORMATTERS.NUMBER(payroll_event.fgts_amount)}</td>
                              <td>
                                <Button
                                  className="p-0"
                                  size="sm"
                                  variant="link"
                                  onClick={() => handleViewSalaryReceipt(payroll_event)}
                                >
                                  Ver Recibo
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr className="text-center">
                            <th>&nbsp;</th>
                            <th>{FORMATTERS.NUMBER(payroll.totals.income_salary_total)}</th>
                            <th>{FORMATTERS.NUMBER(payroll.totals.income_others_total)}</th>
                            <th>{FORMATTERS.NUMBER(payroll.totals.deduction_inss_total)}</th>
                            <th>
                              {renderIRRFButton(payroll.totals.deduction_irrf_total)}
                            </th>
                            <th>{FORMATTERS.NUMBER(payroll.totals.deduction_other_total)}</th>
                            <th>{FORMATTERS.NUMBER(payroll.totals.net_amount_total)}</th>
                            <th>{FORMATTERS.NUMBER(payroll.totals.fgts_amount_total)}</th>
                            <th>&nbsp;</th>
                          </tr>
                        </tfoot>
                      </StyledTable>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  variant="inverse-danger"
                  icon={<FaTrash />}
                  iconPosition="left"
                  onClick={handleDeletePayroll}
                  disabled={isLoading || isEmpty(payroll.payroll_events)}
                >
                  Excluir
                </Button>
              </CardFooter>
            </Card>
          </LoadingOverlay>
        </Col>
      </Row>
    </>
  );
}

GeneratePayroll.defaultProps = {
  allEmployees: [],
};

GeneratePayroll.propTypes = {
  isLoading: PropTypes.bool,
  payroll: PropTypes.object,
  allEmployees: PropTypes.array,
  activeCompany: PropTypes.object,
  onFetchPayrolls: PropTypes.func.isRequired,
  onGeneratePayroll: PropTypes.func.isRequired,
  onGenerateDarfs: PropTypes.func.isRequired,
  onFetchEmployees: PropTypes.func.isRequired,
  onDeletePayroll: PropTypes.func.isRequired,
  onSendSalaryReceiptMail: PropTypes.func.isRequired,
};

export default GeneratePayroll;
