/* eslint-disable no-case-declarations */
import { apiKeysConstants } from '_store/_constants';

const INITIAL_STATE = {
  apiKeys: [],
  apiKey: {},
  isLoading: false,
};

export function apiKeys(state = INITIAL_STATE, action) {
  switch (action.type) {
    case apiKeysConstants.API_KEYS_GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case apiKeysConstants.API_KEYS_GET_ALL_SUCCESS:
      const data = action.payload;

      return {
        ...state,
        apiKeys: data,
        isLoading: false,
      };

    case apiKeysConstants.API_KEYS_GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case apiKeysConstants.API_KEYS_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case apiKeysConstants.API_KEYS_DELETE_SUCCESS:

      return {
        ...state,
        isLoading: false,
      };

    case apiKeysConstants.API_KEYS_DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case apiKeysConstants.API_KEYS_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case apiKeysConstants.API_KEYS_CREATE_SUCCESS:

      return {
        ...state,
        isLoading: false,
      };

    case apiKeysConstants.API_KEYS_CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case apiKeysConstants.API_KEYS_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case apiKeysConstants.API_KEYS_UPDATE_SUCCESS:

      return {
        ...state,
        isLoading: false,
      };

    case apiKeysConstants.API_KEYS_UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
