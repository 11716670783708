import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ListGroup from 'react-bootstrap/ListGroup';
import { isEmpty } from 'lodash';
import {
  IoChevronBack,
  IoChevronForward,
  IoSwapHorizontalOutline,
  IoTrashBinOutline,
  IoArrowDown,
  IoArrowUp,
  IoGitCompare,
} from 'react-icons/io5';
import { IoMdPeople } from 'react-icons/io';
import { CgFileDocument } from 'react-icons/cg';
import { FaRegClone } from 'react-icons/fa';
import { LuCalendarClock, LuCalendarPlus } from 'react-icons/lu';

import { ActionSheet, Button } from '_components/_core';
import { PermissionsGate } from '_components/_shared';

import { ContentContainer, StyledItem } from './styles';
import { checkBlockedPeriod } from 'helpers';

function TransactionActionSheet({
  isOpen,
  selectedTransaction,
  actionSheetView,
  onToggle,
  onDeleteTransaction,
  onDuplicateTransaction,
  onChangeActionSheetView,
  onMoveTransactions,
  onChangeModalVisibility,
  onChangePaymentPlan,
  onRefreshBalance,
  blockedPeriod,
}) {
  const deletePermission = useMemo(() => {
    const permissionMap = {
      'INCOME-null': 'aba_recebimento_delete',
      'EXPENSE-FIXED_EXPENSE': 'aba_despesa_fixa_delete',
      'EXPENSE-VARIABLE_EXPENSE': 'aba_despesa_variavel_delete',
      'EXPENSE-PEOPLE': 'aba_pessoal_delete',
      'EXPENSE-TAXES': 'aba_imposto_delete',
      'TRANSFER-SENT': 'aba_transferencia_delete',
      'TRANSFER-RECEIVED': 'aba_transferencia_delete',
    };

    const { type, sub_type } = selectedTransaction || {};

    const permissionName = permissionMap[`${type}-${sub_type}`];

    return permissionName;
  }, [selectedTransaction]);

  const createPermission = useMemo(() => {
    const permissionMap = {
      'INCOME-null': 'aba_recebimento_create',
      'EXPENSE-FIXED_EXPENSE': 'aba_despesa_fixa_create',
      'EXPENSE-VARIABLE_EXPENSE': 'aba_despesa_variavel_create',
      'EXPENSE-PEOPLE': 'aba_pessoal_create',
      'EXPENSE-TAXES': 'aba_imposto_create',
      'TRANSFER-SENT': 'aba_transferencia_create',
      'TRANSFER-RECEIVED': 'aba_transferencia_create',
    };

    const { type, sub_type } = selectedTransaction;

    const permissionName = permissionMap[`${type}-${sub_type}`];

    return permissionName;
  }, [selectedTransaction]);

  const editPermission = useMemo(() => {
    const permissionMap = {
      'INCOME-null': 'aba_recebimento_edit',
      'EXPENSE-FIXED_EXPENSE': 'aba_despesa_fixa_edit',
      'EXPENSE-VARIABLE_EXPENSE': 'aba_despesa_variavel_edit',
      'EXPENSE-PEOPLE': 'aba_pessoal_edit',
      'EXPENSE-TAXES': 'aba_imposto_edit',
      'TRANSFER-SENT': 'aba_transferencia_edit',
      'TRANSFER-RECEIVED': 'aba_transferencia_edit',
    };

    const { type, sub_type } = selectedTransaction;

    const permissionName = permissionMap[`${type}-${sub_type}`];

    return permissionName;
  }, [selectedTransaction]);

  const handleDeleteTransaction = useCallback(() => {
    onToggle();

    onDeleteTransaction(selectedTransaction);
  }, [onDeleteTransaction, onToggle, selectedTransaction]);

  const handleDuplicateTransaction = useCallback(
    type => {
      if (!selectedTransaction) {
        return;
      }

      onDuplicateTransaction(selectedTransaction.id, type);

      onToggle();
      onChangeActionSheetView('main');
    },
    [onChangeActionSheetView, onDuplicateTransaction, onToggle, selectedTransaction],
  );

  const handleMoveTransactions = useCallback(
    (item, type, subType) => {
      if (!selectedTransaction) {
        return;
      }

      const params = {
        ids: [selectedTransaction.id],
        type: item.type,
        sub_type: item.subType,
        sourceType: type,
        sourceSubType: subType,
        isMobile: true,
      };

      onMoveTransactions(params, () => {
        onRefreshBalance();

        onToggle();

        onChangeActionSheetView('main');
      });
    },
    [
      selectedTransaction,
      onMoveTransactions,
      onRefreshBalance,
      onToggle,
      onChangeActionSheetView,
    ],
  );

  const handlePaymentPlanChange = useCallback(
    option => {
      if (option === 'INSTALMENT') {
        onChangePaymentPlan('INSTALMENT');
      }

      if (option === 'RECURRENT') {
        onChangePaymentPlan('RECURRENT');
      }

      onToggle(false);
      onChangeModalVisibility(true);
      onChangeActionSheetView('main');
    },
    [onChangeModalVisibility, onToggle, onChangePaymentPlan, onChangeActionSheetView],
  );

  const views = useMemo(() => {
    let repeatText = 'Repetir transação...';
    let repeatAction = () => onChangeActionSheetView('repeat');
    let repeatHideChevron = false;

    let isPeriodBlocked = !checkBlockedPeriod(
      blockedPeriod,
      selectedTransaction.event_date,
      selectedTransaction.paid,
    );

    if (selectedTransaction.payment_plan === 'RECURRENT') {
      repeatText = 'Editar repetição...';
      repeatAction = () => handlePaymentPlanChange('RECURRENT');
      repeatHideChevron = true;
    } else if (selectedTransaction.payment_plan === 'INSTALMENT') {
      repeatText = 'Editar parcelas...';
      repeatAction = () => handlePaymentPlanChange('INSTALMENT');
      repeatHideChevron = true;
    }

    const items = {
      move: [
        {
          action: () =>
            handleMoveTransactions({
              type: 'INCOME',
              subType: null,
            }),
          icon: <IoArrowUp size="1.1em" className="mr-2 text-success" />,
          text: 'Receitas',
          transactionType: 'INCOME',
          hideChevron: true,
          permissions: ['aba_recebimento_create'],
        },
        {
          action: () =>
            handleMoveTransactions({
              type: 'EXPENSE',
              subType: 'FIXED_EXPENSE',
            }),
          icon: <IoArrowDown size="1.1em" className="mr-2 text-danger" />,
          text: 'Despesas fixas',
          type: 'EXPENSE',
          subType: 'FIXED_EXPENSE',
          hideChevron: true,
          permissions: ['aba_despesa_fixa_create'],
        },
        {
          action: () =>
            handleMoveTransactions({
              type: 'EXPENSE',
              subType: 'VARIABLE_EXPENSE',
            }),
          icon: <IoArrowDown size="1.1em" className="mr-2 text-danger" />,
          text: 'Despesas variáveis',
          type: 'EXPENSE',
          subType: 'VARIABLE_EXPENSE',
          hideChevron: true,
          permissions: ['aba_despesa_variavel_create'],
        },
        {
          action: () =>
            handleMoveTransactions({
              type: 'INCOME',
              subType: 'FIXED_EXPENSE',
            }),
          icon: <IoMdPeople size="1.1em" className="mr-2 text-danger" />,
          text: 'Pessoas',
          type: ['EXPENSE', 'INCOME'],
          subType: 'PEOPLE',
          hideChevron: true,
          permissions: ['aba_pessoal_create'],
        },
        {
          action: () =>
            handleMoveTransactions({
              type: 'EXPENSE',
              subType: 'TAXES',
            }),
          icon: <CgFileDocument size="1.1em" className="mr-2 text-danger" />,
          text: 'Impostos',
          type: ['EXPENSE', 'INCOME'],
          subType: 'TAXES',
          hideChevron: true,
          permissions: ['aba_imposto_create'],
        },
      ],
      repeat: [
        {
          action: () => handlePaymentPlanChange('RECURRENT'),
          icon: (
            <LuCalendarClock
              className="mr-2"
              size="1.1em"
              style={{ color: 'var(--title-color)' }}
            />
          ),
          text: 'Recorrente',
        },
        {
          action: () => handlePaymentPlanChange('INSTALMENT'),
          icon: (
            <LuCalendarPlus
              className="mr-2"
              size="1.1em"
              style={{ color: 'var(--title-color)' }}
            />
          ),
          text: 'Parcelamento',
        },
      ],
      main: [
        {
          action: () => handleDuplicateTransaction('current_month'),
          icon: <FaRegClone size="1.1em" className="mr-2" />,
          text: 'Duplicar...',
          permissions: [createPermission],
          hideChevron: true,
        },
        {
          action: () => onChangeActionSheetView('move'),
          icon: <IoSwapHorizontalOutline size="1.1em" className="mr-2" />,
          text: 'Mover para...',
          excludeType: 'TRANSFER',
          permissions: [editPermission],
          isBlocked: isPeriodBlocked,
        },
        {
          action: repeatAction,
          icon: <IoGitCompare size="1.1em" className="mr-2" />,
          text: repeatText,
          permissions: [editPermission],
          excludeType: 'TRANSFER',
          hideChevron: repeatHideChevron,
        },
        {
          action: () => handleDeleteTransaction(selectedTransaction),
          icon: <IoTrashBinOutline size="1.1em" className="mr-2 text-danger" />,
          text: 'Excluir...',
          permissions: [deletePermission],
          hideChevron: true,
          isBlocked: isPeriodBlocked,
        },
      ],
    };

    return items;
  }, [
    selectedTransaction,
    deletePermission,
    createPermission,
    editPermission,
    handleDeleteTransaction,
    handleDuplicateTransaction,
    handleMoveTransactions,
    handlePaymentPlanChange,
    onChangeActionSheetView,
    blockedPeriod,
  ]);

  const titles = {
    main: 'Escolha uma ação',
    duplicate: 'Duplicar transação...',
    move: 'Mover para...',
    repeat: 'Repetir transação...',
  };

  const handleClose = useCallback(() => {
    onToggle();
    onChangeActionSheetView('main');
  }, [onToggle, onChangeActionSheetView]);

  const renderActionSheetContent = () => {
    const items = views[actionSheetView];

    if (!items) {
      return null;
    }

    return (
      <ContentContainer>
        <ListGroup.Item className="d-flex align-items-center justify-content-between">
          <>
            <div className="d-flex align-items-center">
              <b className="font-weight-bold">{titles[actionSheetView]}</b>
            </div>
            <div className="d-flex align-items-center">
              {actionSheetView !== 'main' && (
                <Button
                  className="m-0 p-0 d-flex justify-content-center"
                  variant="link"
                  onClick={() => onChangeActionSheetView('main')}
                >
                  <IoChevronBack size="1em" className="ml-2" />
                  Voltar
                </Button>
              )}
            </div>
          </>
        </ListGroup.Item>
        <ListGroup>
          {items.map((item, index) => {
            if (
              actionSheetView === 'move' &&
              selectedTransaction.type === item.type &&
              selectedTransaction.sub_type === item.subType
            ) {
              return null;
            }
            if (
              actionSheetView === 'move' &&
              selectedTransaction.type === 'INCOME' &&
              !item.type
            ) {
              return null;
            }
            if (
              item.text === 'Mover para...' &&
              selectedTransaction.type === item.excludeType
            ) {
              return null;
            }
            if (
              item.text === 'Repetir transação...' &&
              selectedTransaction.type === item.excludeType
            ) {
              return null;
            }

            if (item.permissions && !isEmpty(item.permissions)) {
              return (
                <PermissionsGate permissions={item.permissions} type="all">
                  <ListGroup.Item
                    key={index}
                    className="d-flex align-items-center justify-content-between"
                    action
                    onClick={item.action}
                    disabled={item.isBlocked}
                  >
                    <div className="d-flex align-items-center">
                      {item.icon}
                      <StyledItem>{item.text}</StyledItem>
                    </div>
                    {!item.hideChevron && (
                      <IoChevronForward size="1.1em" className="ml-2 text-muted" />
                    )}
                  </ListGroup.Item>
                </PermissionsGate>
              );
            }

            return (
              <ListGroup.Item
                key={index}
                className="d-flex align-items-center justify-content-between"
                action
                onClick={item.action}
                disabled={item.isBlocked}
              >
                <div className="d-flex align-items-center">
                  {item.icon}
                  <StyledItem>{item.text}</StyledItem>
                </div>
                {!item.hideChevron && (
                  <IoChevronForward size="1.1em" className="ml-2 text-muted" />
                )}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
        <Button onClick={handleClose} className="w-100 m-0 p-0 mt-4 mb-4" variant="link">
          Fechar
        </Button>
      </ContentContainer>
    );
  };

  return (
    <ActionSheet
      isOpen={isOpen}
      onToggle={onToggle}
      detent="content-height"
      prefersReducedMotion
    >
      {renderActionSheetContent()}
    </ActionSheet>
  );
}

TransactionActionSheet.defaultProps = {
  selectedTransaction: {},
  actionSheetView: 'main',
};

TransactionActionSheet.propTypes = {
  actionSheetView: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  selectedTransaction: PropTypes.object,
  onToggle: PropTypes.func.isRequired,
  onDeleteTransaction: PropTypes.func.isRequired,
  onDuplicateTransaction: PropTypes.func.isRequired,
  onChangeActionSheetView: PropTypes.func.isRequired,
  onMoveTransactions: PropTypes.func.isRequired,
  onChangeModalVisibility: PropTypes.func.isRequired,
  onChangePaymentPlan: PropTypes.func.isRequired,
  onRefreshBalance: PropTypes.func.isRequired,
};

export default TransactionActionSheet;
