import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActions from '../alert.actions';

const setFavoriteBankAccount = (id) => {
  return async (dispatch) => {
    dispatch(request(id));

    service.setFavoriteBankAccount(id).then(
      (response) => {
        dispatch(success(response));

        dispatch(alertActions.success('Conta principal atualizada com sucesso'));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(id) {
    return {
      type: constants.SET_FAVORITE_BANK_ACCOUNT_REQUEST,
      payload: {
        id,
      },
    };
  }
  function success(response) {
    return {
      type: constants.SET_FAVORITE_BANK_ACCOUNT_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.SET_FAVORITE_BANK_ACCOUNT_FAILURE,
      payload: error,
    };
  }
};

export default setFavoriteBankAccount;
