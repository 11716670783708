import { connect } from 'react-redux';

import reportsActionCreators from '_store/_actions/reports';

import IssReport from './IssReport';

const mapStateToProps = (state) => ({
  isLoading: state.reports.isLoadingIssReport,
  issReport: state.reports.issReport,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onClearIssReport: reportsActionCreators.clearIssReport,
  onFetchIssReport: reportsActionCreators.getIssReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(IssReport);
