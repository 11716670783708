import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DateTime } from 'luxon';
import capitalize from 'lodash/capitalize';

import {
  PageHeader,
  MonthPicker,
  DashboardCard,
} from '_components/_core';
import { RevenueChart } from '_components/_shared';
import FORMATTERS from 'helpers/formatters';

const Dashboard = ({
  activeCompany,
  fiscalOverview,
  onFetchFiscalOverview,
}) => {
  const [selectedDate, setSelectedDate] = useState(DateTime.now());

  const formattedMonthYear = useMemo(() => {
    if (!selectedDate) {
      return null;
    }

    const month = capitalize(selectedDate.setLocale('pt-BR').toFormat('MMMM'));
    const year = selectedDate.toFormat('yyyy');

    return `${month}/${year}`;
  }, [selectedDate]);

  const currentMonthPeriod = useMemo(
    () => `Receita bruta total no mês de ${formattedMonthYear}`,
    [formattedMonthYear],
  );

  const currentRbt12Period = useMemo(
    () => `Receita bruta total nos 12 meses anteriores a ${formattedMonthYear}`,
    [formattedMonthYear],
  );

  const currentFpt12Period = useMemo(
    () => `Folha de pagamento total nos 12 meses anteriores a ${formattedMonthYear}`,
    [formattedMonthYear],
  );

  const monthRevenue = useMemo(() => {
    if (!fiscalOverview) {
      return FORMATTERS.NUMBER(0);
    }

    const { month } = fiscalOverview;

    return FORMATTERS.NUMBER(month.find((m) => m.revenue_type === 'all').gross_revenue);
  }, [fiscalOverview]);

  const rbt12Revenue = useMemo(() => {
    if (!fiscalOverview) {
      return FORMATTERS.NUMBER(0);
    }

    const { rbt12 } = fiscalOverview;

    return FORMATTERS.NUMBER(rbt12.find((m) => m.revenue_type === 'all').gross_revenue);
  }, [fiscalOverview]);

  const fpt12Value = useMemo(() => {
    const { fpt12 } = fiscalOverview || {};

    return FORMATTERS.NUMBER(fpt12);
  }, [fiscalOverview]);

  const fatorRValue = useMemo(() => {
    const { fator_r } = fiscalOverview || {};

    return FORMATTERS.PERCENTAGE_CALCULATED(fator_r);
  }, [fiscalOverview]);

  const fatorRMissing = useMemo(() => {
    const { fator_r_missing } = fiscalOverview || {};
    if (!fator_r_missing) {
      return null;
    }

    return `Faltam ${FORMATTERS.NUMBER(fator_r_missing)} para um Fator R de 28%.`;
  }, [fiscalOverview]);

  const currentFatorRPeriod = useMemo(
    () => `Massa salarial sobre a receita bruta total nos 12 meses anteriores a ${formattedMonthYear}`,
    [formattedMonthYear],
  );

  useEffect(() => {
    if (!selectedDate) {
      return;
    }

    onFetchFiscalOverview({
      year: Number(selectedDate.toFormat('yyyy')),
      month: Number(selectedDate.toFormat('MM')),
      day: Number(selectedDate.toFormat('dd')),
    });
  }, [activeCompany, selectedDate, onFetchFiscalOverview]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Dashboard Fiscal"
        sideContent={(
          <MonthPicker value={selectedDate} onChange={(date) => setSelectedDate(date)} />
        )}
      />
      <Row>
        <Col xs={12} sm={6} lg={3}>
          <DashboardCard
            title="Receita bruta no mês"
            hint={currentMonthPeriod}
            content={monthRevenue}
          />
        </Col>
        <Col xs={12} sm={6} lg={3} className="mt-3 mt-sm-0">
          <DashboardCard
            title="RBT12"
            hint={currentRbt12Period}
            content={rbt12Revenue}
          />
        </Col>
        <Col xs={12} sm={6} lg={3} className="mt-3 mt-lg-0">
          <DashboardCard
            title="FPT12"
            hint={currentFpt12Period}
            content={fpt12Value}
            infoMessage={fatorRMissing}
          />
        </Col>
        <Col xs={12} sm={6} lg={3} className="mt-3 mt-lg-0">
          <DashboardCard
            title="Fator R"
            hint={currentFatorRPeriod}
            content={fatorRValue}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} sm={12} lg={6}>
          <RevenueChart />
        </Col>
      </Row>
    </Container>
  );
};

Dashboard.defaultProps = {
  fiscalOverview: null,
  activeCompany: null,
};

Dashboard.propTypes = {
  activeCompany: PropTypes.object,
  fiscalOverview: PropTypes.object,
  onFetchFiscalOverview: PropTypes.func.isRequired,
};

export default Dashboard;
