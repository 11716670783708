import styled from 'styled-components';

import { Button } from '_components/_core';

export const StyledButton = styled(Button)`
  background-color: #11924F;
  border: 0;
  min-width: 200px;
  
  :hover {
    background-color: #0A502B;
  }
  
  :active {
    background-color: #0A502B;
  }
`;

export const StyledFileList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 8px;

  li {
    display: flex;
    align-items: center;
    font-weight: 400;
    

    &:hover {
      color: #11924F;
      cursor: pointer;

      svg {
        fill: unset;
      }
    }
  }

  svg {
    margin-left: 8px;
    cursor: pointer;

    &:hover {
      fill: #d52b1e;
    }
  }
`;

export const StyledPaidToggle = styled.div`
  display: flex;
  padding: 5px 20px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #F6F5FA;

  label {
    font-weight: 600;
  }
`;

export const RepeatContainer = styled.div`
  /* display: flex; */
  /* padding: 16px; */
  /* border-radius: 8px; */
  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: space-between; */
  background-color: #F6F5FA;
  padding: 16px;
  border-radius: 8px;
  background-color: #F8F8F8;

  strong {
    font-weight: 600;
  }
`;

export const SplitContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: #F8F8F8;

  strong {
    font-weight: 600;
  }
`;

export const SplitEditContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: #F8F8F8;

  display: grid;
  grid-template-columns: 1fr 1fr;

  div.with-separator {
    border-right: 1px solid #D4D7DC;
  }

  div:first-child {    
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  div:last-child {
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  strong {
    font-weight: 600;
  }
`;

export const SplitEditReportContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: #F8F8F8;

  div.with-separator {
    border-right: 1px solid #D4D7DC;
  }

  div:first-child {
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    div:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    div:last-child {
      padding-left: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
    }
  }

  div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  strong {
    font-weight: 600;
  }
`;
