import { subscriptionConstants } from '../_constants';

const INITIAL_STATE = {
  isLoading: false,
  plans: [],
  subscription: null,
};

export function subscription(state = INITIAL_STATE, action) {
  switch (action.type) {
    case subscriptionConstants.FETCH_SUBSCRIPTION_PLANS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case subscriptionConstants.FETCH_SUBSCRIPTION_PLANS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        plans: data,
        isLoading: false,
      };
    }
    case subscriptionConstants.FETCH_SUBSCRIPTION_PLANS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case subscriptionConstants.FETCH_SUBSCRIPTION_STATUS_REQUEST: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case subscriptionConstants.FETCH_SUBSCRIPTION_STATUS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        subscription: data,
        start: data.current_period_start,
        end: data.current_period_end,
        isLoading: false,
      };
    }
    case subscriptionConstants.FETCH_SUBSCRIPTION_STATUS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case subscriptionConstants.CREATE_AUTHORIZED_CHECKOUT_SESSION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case subscriptionConstants.CREATE_AUTHORIZED_CHECKOUT_SESSION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case subscriptionConstants.CREATE_AUTHORIZED_CHECKOUT_SESSION_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case subscriptionConstants.UPDATE_PLAN_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case subscriptionConstants.UPDATE_PLAN_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        subscription: data,
        isLoading: false,
      };
    }
    case subscriptionConstants.UPDATE_PLAN_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case subscriptionConstants.CREATE_CHECKOUT_SESSION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case subscriptionConstants.CREATE_CHECKOUT_SESSION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case subscriptionConstants.CREATE_CHECKOUT_SESSION_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
