import { connect } from 'react-redux';

import payrollActionCreators from '_store/_actions/payroll';
import employeesActionCreators from '_store/_actions/employees';
import taxesActionCreators from '_store/_actions/taxes';

import GeneratePayroll from './GeneratePayroll';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  allEmployees: state.employees.employees,
  payroll: state.payroll.payroll,
  isLoading: state.payroll.isLoading,
});

const mapDispatchToProps = {
  onFetchPayrolls: payrollActionCreators.getPayrolls,
  onDeletePayroll: payrollActionCreators.deletePayoll,
  onGeneratePayroll: payrollActionCreators.generatePayroll,
  onFetchEmployees: employeesActionCreators.getAllEmployees,
  onSendSalaryReceiptMail: payrollActionCreators.sendSalaryReceiptMail,
  onGenerateDarfs: taxesActionCreators.generateDarfs,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneratePayroll);
