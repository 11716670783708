import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import { Formik } from 'formik';

import { parseDateIgnoreHours } from 'helpers';
import { PageHeader } from '_components/_core';

import { Filters, Results } from './components';

function BalanceSheet({
  isLoading,
  balanceSheet,
  activeCompany,
  onFetchBalanceSheet,
  onClearBalanceSheet,
}) {
  useEffect(() => {
    onClearBalanceSheet();
  }, [onClearBalanceSheet]);

  useEffect(() => {
    onClearBalanceSheet();
  }, [onClearBalanceSheet, activeCompany]);

  const handleGenerateReport = useCallback((values) => {
    onFetchBalanceSheet({
      ...values,
    });
  }, [onFetchBalanceSheet]);

  const formInitialValues = useMemo(() => {
    const { active_period_start, active_period_end } = activeCompany || {};

    if (active_period_start && active_period_end) {
      return {
        start_date: parseDateIgnoreHours(active_period_start),
        end_date: parseDateIgnoreHours(active_period_end),
        accounts: [],
      };
    }

    return {
      start_date: null,
      end_date: null,
    };
  }, [activeCompany]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Balancete Analítico"
        description="Posição das contas em um determinado período."
      />
      <Formik
        initialValues={formInitialValues}
        onSubmit={handleGenerateReport}
        enableReinitialize
      >
        {({ handleSubmit, values }) => (
          <>
            <Tab.Container defaultActiveKey="month">
              <Row>
                <Col lg={3} md={12}>
                  <Filters isLoading={isLoading} onSubmit={handleSubmit} />
                </Col>
                <Col lg={9} md={12}>
                  <Results
                    balanceSheet={balanceSheet}
                    values={values}
                    activeCompany={activeCompany}
                  />
                </Col>
              </Row>
            </Tab.Container>
          </>
        )}
      </Formik>
    </Container>
  );
}

BalanceSheet.defaultProps = {
  balanceSheet: null,
  isLoading: false,
};

BalanceSheet.propTypes = {
  isLoading: PropTypes.bool,
  activeCompany: PropTypes.object,
  balanceSheet: PropTypes.object,
  onClearBalanceSheet: PropTypes.func.isRequired,
  onFetchBalanceSheet: PropTypes.func.isRequired,
};

export default BalanceSheet;
