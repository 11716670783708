import { connect } from 'react-redux';

import employeeActionCreators from '_store/_actions/employees';
import ibgeActionCreators from '_store/_actions/ibge';

import AccountantModal from './AssociatesModal';

const mapStateToProps = (state) => ({
  isLoading: state.employees.isLoading,
  isLoadingCep: state.ibge.isLoadingCep,
  cities: state.ibge.cities,
  states: state.ibge.states,
  employeeJourneys: state.employees.employeeJourneys,
  employeeRoles: state.employees.employeeRoles,
});

const mapDispatchToProps = {
  onFetchEmployees: employeeActionCreators.getAllEmployees,
  onFetchCreateEmployeeParams: employeeActionCreators.getAddEmployeeParams,
  onCreateEmployee: employeeActionCreators.addEmployee,
  onUpdateEmployee: employeeActionCreators.updateEmployee,
  onFetchCities: ibgeActionCreators.fetchCities,
  onFetchStates: ibgeActionCreators.fetchStates,
  onFetchCep: ibgeActionCreators.fetchCep,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountantModal);
