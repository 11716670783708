import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';

import { Container, Option } from './styles';

function FormButtonGroupField({
  placeholder,
  disabled,
  options,
  columns,
  ...props
}) {
  const formik = useFormikContext();

  const [field, meta] = useField(props);

  const error = useMemo(
    () => ((meta.touched && meta.error) ? meta.error : ''),
    [meta.error, meta.touched],
  );

  return (
    <>
      <Container columns={columns}>
        {options.map((option) => (
          <Option
            key={option.value}
            active={field.value === option.value}
            onClick={() => formik.setFieldValue(field.name, option.value)}
          >
            {option.icon}
            {option.label}
          </Option>
        ))}
      </Container>
      {error && <div className="text-danger">{error}</div>}
    </>
  );
}

FormButtonGroupField.defaultProps = {
  options: [],
  disabled: false,
  columns: 1,
};

FormButtonGroupField.propTypes = {
  columns: PropTypes.number,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
};

export default FormButtonGroupField;
