import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { FormSelectField } from '_components/_core';

function FormCompaniesSelectField({ name, companies, ...rest }) {
  const options = useMemo(() => companies.map((company) => ({
    label: company.company_name,
    value: company.id,
  })), [companies]);

  return (
    <FormSelectField
      name={name}
      options={options}
      placeholder="Empresa"
      {...rest}
    />
  );
}

FormCompaniesSelectField.defaultProps = {
  companies: [],
};

FormCompaniesSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  companies: PropTypes.array,
};

export default FormCompaniesSelectField;
