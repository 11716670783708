import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';

const deleteCompanyFee = (company_id, id, callback) => {
  return async (dispatch) => {
    confirmDialog.warning({
      title: 'Excluir honorário',
      message: 'Tem certeza que deseja excluir este honorário? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, excluir!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.deleteFee(company_id, id).then(
          (response) => {
            dispatch(success(response));
            dispatch(alertActionCreators.success('Honorário excluído com sucesso!'));

            if (callback) {
              service.getById(company_id).then((response) => {
                callback(response.data);
              });
            }
          },
        ).catch(
          (err) => {
            dispatch(failure(err));
            dispatch(alertActionCreators.error('Erro ao excluir honorário!'));
          },
        );
      },
      onCancel: () => {},
    });
  };

  function request(id) {
    return {
      type: constants.DELETE_COMPANY_FEE_REQUEST,
      payload: { id },
    };
  }
  function success(response) {
    return {
      type: constants.DELETE_COMPANY_FEE_SUCCESS,
      payload: { response },
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_COMPANY_FEE_FAILURE,
      payload: { error },
    };
  }
};

export default deleteCompanyFee;
