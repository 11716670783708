import constants from '../../_constants/payments.constants';
import service from '../../../services/payments.service';

const fetchPayments = (params = {}) => {
  return (dispatch) => {
    dispatch(request());

    service.fetchPayments(params).then((response) => {
      dispatch(success(response.data));
    })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request() {
    return {
      type: constants.FETCH_PAYMENTS_REQUEST,
    };
  }
  function success(data) {
    return {
      type: constants.FETCH_PAYMENTS_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_PAYMENTS_FAILURE,
      payload: { error },
    };
  }
};

export default fetchPayments;
