import FORMATTERS from 'helpers/formatters';

const EMPTY_OPTIONS = {
  chart: {
    spacingTop: 25,
    backgroundColor: 'transparent',
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  title: false,
  xAxis: [{
    categories: ['JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
    lineColor: '#DCDCDC',
    tickColor: '#DCDCDC',
    fontWeight: 'bold',
    labels: {
      style: {
        color: '#969696',
        fontFamily: 'Poppins',
      },
    },
    showFirstLabel: true,
  }],
  yAxis: {
    title: false,
    lineColor: '#DCDCDC',
    gridLineColor: 'rgb(182, 182, 182)',
    minorGridLineColor: 'rgb(182, 182, 182)',
    minorGridLineWidth: 1,
    minorTickLength: 0,
    minorTickInterval: 'auto',
    labels: {
      style: {
        color: '#969696',
        fontFamily: 'Poppins',
      },
      y: 2,
      formatter() {
        return `${FORMATTERS.NUMBER(this.value)}`;
      },
    },
    plotLines: [{
      color: '#404040',
      value: 0,
      width: 3,
      zIndex: 5,
    }],
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    series: {
      marker: {
        enabled: true,
      },
      states: {
        hover: {
          enabled: false,
        },
      },
    },
    column: {
      pointPadding: 0.15,
      groupPadding: 0.15,
      borderWidth: 0,
      borderRadius: 1,
      shadow: false,
      stacking: 'normal',
    },
  },
  series: [{
    type: 'column',
    name: 'Despesas',
    color: '#CCCCCC',
    data: [-3e4, -35e3, -55e3, -45e3, -5e4, -55e3],
  }, {
    type: 'column',
    name: 'Receitas',
    color: '#AAAAAA',
    data: [4e4, 5e4, 42e3, 6e4, 7e4, 85e3],
  }, {
    type: 'line',
    name: 'Receitas',
    color: '#404040',
    lineWidth: 1,
    dashStyle: 'dot',
    marker: {
      radius: 3,
    },
    data: [1e4, 15e3, -13e3, 15e3, 2e4, 3e4],
  }],
};

export {
  EMPTY_OPTIONS,
};
