import { connect } from 'react-redux';

import taxesActionCreators from '_store/_actions/taxes';
import companyActionCreators from '_store/_actions/company';

import GenerateDarf from './GenerateDarf';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  companies: state.company.companies,
  darfs: state.taxes.manager_darfs,
  isLoading: state.taxes.isLoading,
});

const mapDispatchToProps = {
  onFetchManagerDarfs: taxesActionCreators.fetchManagerDarfs,
  onDeleteDarf: taxesActionCreators.deleteDarf,
  onGenerateDarfs: taxesActionCreators.generateDarfs,
  onFetchCompaniesForSelect: companyActionCreators.fetchCompaniesForSelect,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenerateDarf);
