import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FORMATTERS from 'helpers/formatters';
import { PageHeader } from '_components/_core';
import { ActivatedCompany, ActivatedPeriod } from '_components/_shared';

import ZenpaperDashboard from './components/ZenpaperDashboard/ZenpaperDashboardContainer';

const Dashboard = ({
  user,
  cashflow,
}) => {
  const renderContent = useCallback(() => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    if (product === 'contabills-empresas') {
      return <ZenpaperDashboard cashflow={cashflow} />;
    }

    return (
      <>
        <Row>
          <Col className="pl-4 pr-4">
            <PageHeader
              title="Dashboard"
              description={`Olá, ${FORMATTERS.DISPLAY_NAME(user)}`}
              className="mb-0 ml-2"
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12} sm={12} lg={4}>
            <ActivatedCompany widgetType="MEDIUM" />
          </Col>
          <Col xs={12} sm={12} lg={8} className="mt-3 mt-lg-0">
            <ActivatedPeriod />
          </Col>
        </Row>
      </>
    );
  }, [cashflow, user]);

  return (
    <Container fluid className="content-wrapper">
      {renderContent()}
    </Container>
  );
};

Dashboard.defaultProps = {
  user: null,
  cashflow: {},
};

Dashboard.propTypes = {
  user: PropTypes.object,
  cashflow: PropTypes.object,
};

export default Dashboard;
