import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FcRefresh } from 'react-icons/fc';
import { toast } from 'react-toastify';
import has from 'lodash/has';

import FORMATTERS from 'helpers/formatters';
import { checkBlockedPeriod, useMediaQuery } from 'helpers';

import { Button } from '_components/_core';

import Row from './Row';
import RowForm from './RowForm';
import { InstalmentsTable } from '../styles';

function Table({
  parcelasCalculadas,
  parcelasEditadasValor,
  parcelasEditadasData,
  qtdeParcelas,
  valorTotalCalculado,
  renderSumWarningFor,
  renderRecalcWarningFor,
  renderRecalcTotalWarning,
  onChangeParcela,
  onDeleteParcela,
  onClearDeletedParcelas,
  onRecalcularParcelas,
  onRecalcularTotal,
  onSelectInstalmentForEdit,
  blockedPeriod,
}) {
  const transactionFormRef = useRef();

  const { isMobile } = useMediaQuery();

  const [selectedParcelaId, setSelectedParcelaId] = useState(null);
  const [selectedFieldName, setSelectedFieldName] = useState(null);

  const showAlert = message =>
    toast.error(
      () => (
        <div>
          <strong>Ação não permitida</strong>
          <br />
          <small>{message}</small>
        </div>
      ),
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      },
    );

  const handleEditParcela = useCallback(
    (frequency_number, fieldName) => {
      setSelectedParcelaId(frequency_number);
      setSelectedFieldName(fieldName);

      onSelectInstalmentForEdit(frequency_number);
    },
    [onSelectInstalmentForEdit],
  );

  const handleCancelEditParcela = useCallback(() => {
    setSelectedParcelaId(null);
    setSelectedFieldName(null);

    onSelectInstalmentForEdit(null);
  }, [onSelectInstalmentForEdit]);

  const handleInstalmentUpdated = useCallback(
    (values, changedValues) => {
      if (
        has(changedValues, 'paid') &&
        !checkBlockedPeriod(blockedPeriod, values.event_date)
      ) {
        showAlert(
          'Não é possível alterar o status de pagamento de uma parcela de um período bloqueado.',
        );

        return;
      }

      onChangeParcela(values, changedValues);
    },
    [onChangeParcela],
  );

  return (
    <>
      <InstalmentsTable isMobile={isMobile} className="table table-striped">
        <thead>
          <tr>
            <th className="number-column">#</th>
            <th className="event-date-column">Vencimento</th>
            <th className="amount-column">Valor</th>
            <th className="paid-column">Pago?</th>
            <th className="buttons-column">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {parcelasCalculadas.map(parcela => {
            const { frequency_number } = parcela;

            return (
              <>
                {selectedParcelaId !== frequency_number && (
                  <Row
                    key={`normal-row-${frequency_number}`}
                    transactionFormRef={transactionFormRef}
                    selectedParcelaId={selectedParcelaId}
                    parcela={parcela}
                    qtdeParcelas={qtdeParcelas}
                    parcelasEditadasValor={parcelasEditadasValor}
                    parcelasEditadasData={parcelasEditadasData}
                    renderSumWarningFor={renderSumWarningFor}
                    renderRecalcWarningFor={renderRecalcWarningFor}
                    isMobile={isMobile}
                    onChangeParcela={onChangeParcela}
                    onDeleteParcela={onDeleteParcela}
                    onClearDeletedParcelas={onClearDeletedParcelas}
                    onRecalcularParcelas={onRecalcularParcelas}
                    onEditParcela={handleEditParcela}
                    blockedPeriod={blockedPeriod}
                  />
                )}
                {selectedParcelaId !== null && selectedParcelaId === frequency_number && (
                  <RowForm
                    key={`form-row-${frequency_number}`}
                    transactionFormRef={transactionFormRef}
                    parcela={parcela}
                    selectedFieldName={selectedFieldName}
                    qtdeParcelas={qtdeParcelas}
                    isMobile={isMobile}
                    onDeleteParcela={onDeleteParcela}
                    onSubmit={handleInstalmentUpdated}
                    onCancelEditInstalment={handleCancelEditParcela}
                    blockedPeriod={blockedPeriod}
                  />
                )}
              </>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="number-column">&nbsp;</td>
            <td className="event-date-column">
              <span>
                <strong>Total</strong>
              </span>
            </td>
            <td className="amount-column">
              <span>
                <span className="d-flex align-items-center">
                  <strong className="mr-3">
                    {FORMATTERS.NUMBER(valorTotalCalculado)}
                  </strong>
                  {renderRecalcTotalWarning && (
                    <Button
                      variant="link"
                      className="pl-0 d-flex align-items-center"
                      size="sm"
                      onClick={() => onRecalcularTotal()}
                    >
                      Recalcular total
                      <FcRefresh className="ml-2" />
                    </Button>
                  )}
                </span>
              </span>
            </td>
            <td className="paid-column">&nbsp;</td>
            <td className="buttons-column">&nbsp;</td>
          </tr>
        </tfoot>
      </InstalmentsTable>
    </>
  );
}

Table.propTypes = {
  parcelasCalculadas: PropTypes.array.isRequired,
  qtdeParcelas: PropTypes.number.isRequired,
  valorTotalCalculado: PropTypes.number.isRequired,
  parcelasEditadasValor: PropTypes.array.isRequired,
  parcelasEditadasData: PropTypes.array.isRequired,
  renderSumWarningFor: PropTypes.array.isRequired,
  renderRecalcWarningFor: PropTypes.array.isRequired,
  renderRecalcTotalWarning: PropTypes.bool.isRequired,
  onChangeParcela: PropTypes.func.isRequired,
  onDeleteParcela: PropTypes.func.isRequired,
  onRecalcularParcelas: PropTypes.func.isRequired,
  onRecalcularTotal: PropTypes.func.isRequired,
  onClearDeletedParcelas: PropTypes.func.isRequired,
  onSelectInstalmentForEdit: PropTypes.func.isRequired,
  blockedPeriod: PropTypes.object.isRequired,
};

export default Table;
