import getAllEmployees from './getAllEmployees';
import getEmployeeRoles from './getEmployeeRoles';
import addEmployeeRole from './addEmployeeRole';
import updateEmployeeRole from './updateEmployeeRole';
import deleteEmployeeRole from './deleteEmployeeRole';
import getEmployeeJourneys from './getEmployeeJourneys';
import addEmployeeJourney from './addEmployeeJourney';
import updateEmployeeJourney from './updateEmployeeJourney';
import deleteEmployeeJourney from './deleteEmployeeJourney';
import getAddEmployeeParams from './getAddEmployeeParams';
import addEmployee from './addEmployee';
import updateEmployee from './updateEmployee';
import getEmployeeById from './getEmployeeById';
import deleteEmployee from './deleteEmployee';
import addEmployeeSalary from './addEmployeeSalary';
import updateEmployeeSalary from './updateEmployeeSalary';
import getEmployeeSalaries from './getEmployeeSalaries';
import deleteEmployeeSalary from './deleteEmployeeSalary';
import getEmployeeVacations from './getEmployeeVacations';
import getEmployeeDependents from './getEmployeeDependents';
import addEmployeeDependent from './addEmployeeDependent';
import updateEmployeeDependent from './updateEmployeeDependent';
import deleteEmployeeDependent from './deleteEmployeeDependent';

export default {
  getAllEmployees,
  getEmployeeRoles,
  addEmployeeRole,
  updateEmployeeRole,
  deleteEmployeeRole,
  getEmployeeJourneys,
  addEmployeeJourney,
  updateEmployeeJourney,
  deleteEmployeeJourney,
  getAddEmployeeParams,
  addEmployee,
  updateEmployee,
  getEmployeeById,
  deleteEmployee,
  addEmployeeSalary,
  updateEmployeeSalary,
  getEmployeeSalaries,
  deleteEmployeeSalary,
  getEmployeeVacations,
  getEmployeeDependents,
  addEmployeeDependent,
  updateEmployeeDependent,
  deleteEmployeeDependent,
};
