import { connect } from 'react-redux';
import { compose } from 'redux';

import paymentsActionsCreators from '_store/_actions/payments';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Payments from './Payments';

const mapStateToProps = (state) => ({
  payments: state.payments.payments,
  isLoading: state.payments.isLoading,
});

const mapDispatchToProps = {
  onFetchPayments: paymentsActionsCreators.fetchPayments,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['buy_services']),
)(Payments);
