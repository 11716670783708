import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button } from '_components/_core';

const DeleteInvoices = ({
  isLoading,
  selectedItems,
  onDeleteInvoices,
  onComplete,
}) => {
  const handleDeleteInvoices = useCallback(() => {
    onDeleteInvoices({
      ids: selectedItems,
    }, () => {
      if (onComplete) {
        onComplete();
      }
    });
  }, [selectedItems, onDeleteInvoices, onComplete]);

  return (
    <Button
      className="mr-2 btn-sm"
      variant="outline-danger"
      onClick={handleDeleteInvoices}
      disabled={isLoading}
    >
      Excluir
    </Button>
  );
};

export default DeleteInvoices;

DeleteInvoices.defaultProps = {
  isLoading: false,
  onComplete: null,
};

DeleteInvoices.propTypes = {
  isLoading: PropTypes.func,
  selectedItems: PropTypes.array.isRequired,
  onDeleteInvoices: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
};
