import * as yup from 'yup';

const ExportSpreadsheetSchema = yup.object().shape({
  start_date: yup.date().required('O campo Data Inicial é obrigatório'),
  end_date: yup.date().required('O campo Data Final é obrigatório'),
  downloadType: yup.string().required('O campo Tipo de Download é obrigatório'),
  recipients: yup.array().when('downloadType', (downloadType, schema) => {
    if (downloadType === 'EMAIL') {
      return schema.min(1, 'Selecione pelo menos um usuário para receber o back-up.');
    }

    return schema;
  }).nullable(),
});

export default ExportSpreadsheetSchema;
