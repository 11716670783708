import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/bank-statements.constants';
import service from '../../../services/bank-statement.service';
import alertActionCreators from '../alert.actions';

const ignoreEntries = (params, callback) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Ignorar Transações',
      message: 'Tem certeza que deseja ignorar os lançamentos selecinados?',
      confirmButtonText: 'Sim, ignorar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(params));

        service.removeMany(params).then(() => {
          dispatch(success(params));
          dispatch(alertActionCreators.success('Lançamentos ignorados com sucesso.'));

          if (callback) {
            callback();
          }
        }).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request(ids) {
    return {
      type: constants.BANK_STATEMENTS_IGNORE_ENTRIES_REQUEST,
      payload: { ids },
    };
  }
  function success(ids) {
    return {
      type: constants.BANK_STATEMENTS_IGNORE_ENTRIES_SUCCESS,
      payload: {
        entry_ids: ids,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.BANK_STATEMENTS_IGNORE_ENTRIES_FAILURE,
      payload: { error },
    };
  }
};

export default ignoreEntries;
