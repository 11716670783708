import { connect } from 'react-redux';
import { compose } from 'redux';

import receiptActionsCreators from '_store/_actions/receipt';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Receipts from './Receipts';

const mapStateToProps = (state) => ({
  receipts: state.receipts.receipts,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onFetchAllReceipts: receiptActionsCreators.fetchAllReceipts,
  onFetchReceipt: receiptActionsCreators.fetchReceipt,
  onAddReceipt: receiptActionsCreators.addReceipt,
  onDeleteReceipt: receiptActionsCreators.deleteReceipt,
  onUpdateReceipt: receiptActionsCreators.updateReceipt,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['manager_receipt']),
)(Receipts);
