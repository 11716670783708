import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

import Button from '_components/_core/Button';

export const StyledTable = styled(Table)`

  thead tr th {
    font-size: 13px;
    line-height: 13px;
    font-weight: bold;
    padding: 15px 8px !important;
    position: sticky; 
    top: 0; 
    z-index: 1;
    background-color: var(--th-bg-color);
    vertical-align: middle;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #d5dcec;
      box-shadow: inset 0px 25px 1px -10px #d5dcec;
    }
  }

  tbody tr td {
    font-size: 13px;
    line-height: 13px;
    font-weight: 400;
    padding: 12px 8px;
    border-top: 1px solid #D4D7DC;
    height: 50px;
  }

  thead tr th.checkbox-column,
  tbody tr td.checkbox-column {
    width: 2% !important;
    padding-left: 16px !important;
  }

  thead tr th.is_valid-column,
  tbody tr td.is_valid-column {
    width: 2% !important;
  }

  thead tr th.event_date-column,
  tbody tr td.event_date-column {
    width: 6% !important;
    text-align: center;
  }

  thead tr th.description-column,
  tbody tr td.description-column {
    width: 200px !important;
  }

  thead tr th.temp_type-column,
  tbody tr td.temp_type-column {
    width: 15% !important;
  }

  thead tr th.amount-column,
  tbody tr td.amount-column {
    width: 15% !important;
  }

 
  thead tr th.category-column,
  tbody tr td.category-column {
    width: 15% !important;
  }

  thead tr th.recipient-column,
  tbody tr td.recipient-column {
    width: 15% !important;
  }


  tbody tr.table-active td {
    background: #eceef1 !important;
  } 

  tbody tr td span.no-description {
    font-size: 12px;
    color: #babec5;
    font-style: italic;
  }

  tfoot tr th {
    font-size: 13px;
    line-height: 13px;
    font-weight: bold;
    padding: 15px 30px !important;
    font-weight: 600 !important;
    color: #223C61;
    position: sticky; 
    bottom: 0; 
    z-index: 1;
    background-color: var(--th-bg-color);
    border-top: none !important;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      left: 0;
      background: var(--th-border-color);
      box-shadow: inset 0px 25px 1px -10px #d5dcec;
    }
  }

`;

export const FiltersContainer = styled.div`

`;

export const FilterHeader = styled.p`
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

export const TesteGroup = styled.div`
  
  label {
    font-weight: bold;
    margin-bottom: 4px;
    font-size: 14px;
  }

  /* input {
    width: auto !important;
  } */

  /* div:first-child {
    display: flex;
  } */

`;

export const StyledFormCheck = styled(Form.Check)`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-check-input {
    margin-top: 0;
    margin-left: 0;

    width: 18px !important;
    height: 18px !important;

    &:hover {
      cursor: pointer !important;
    }
  }
`;

export const Result = styled.div``;

export const ResultLine = styled.div``;

export const StyledButton = styled(Button)`
  margin-top: 22px;
`;

export const FilterButton = styled(Button)`
  border-radius: 18px;
  margin: -18px 8px 28px 0px;
`;

export const CloseButton = styled(Button)`
  background: none;
  border: none;
  margin: 0px;
  padding: 0px;
  :hover {
    background: none;
    background-color: none;
    border: none;
    box-shadow: none;
  }
  :active {
    background: none !important;
    background-color: none !important;
    border: none !important;
    box-shadow: none !important;
  }
`;

export const AbortButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
