import { connect } from 'react-redux';

import authActionCreators from '_store/_actions/auth';
import subscriptionActionCreators from '_store/_actions/subscription';

import ActivateSubscription from './ActivateSubscription';

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  plans: state.subscription.plans,
});

const mapDispatchToProps = {
  onSignUp: authActionCreators.signUp,
  onFetchPlans: subscriptionActionCreators.fetchSubscriptionPlans,
  onGoToCheckoutSession: subscriptionActionCreators.goToCheckoutSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateSubscription);
