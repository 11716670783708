import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import isEmpty from 'lodash/isEmpty';

import confirmDialog from 'helpers/confirmDialog';

import {
  FastFilterSelect,
  FastFilterTextReplace,
} from '_components/_core';

function ReplaceTextTab({
  selectedItems,
  allItems,
  showIcon,
  onUpdateTransactionsDescriptions,
  onUpdateMultipleTransactions,
}) {
  const handleReplaceText = useCallback((values, { resetForm }) => {
    const performUpdate = () => {
      if (isEmpty(values.replace_text) && !isEmpty(values.replace_with)) {
        onUpdateMultipleTransactions({
          ids: isEmpty(selectedItems) ? allItems : selectedItems,
          description: values.replace_with,
        }, () => {
          resetForm();
        });

        return;
      }

      onUpdateTransactionsDescriptions({
        ids: isEmpty(selectedItems) ? allItems : selectedItems,
        ...values,
      }, () => {
        resetForm();
      });
    };

    if (isEmpty(selectedItems)) {
      confirmDialog.warning({
        title: `Substituir Texto em ${allItems.length} itens`,
        message: `Você não selecionou nenhum item. <br /> Deseja atualizar todos os ${allItems.length} itens exibidos?`,
        onConfirm: performUpdate,
        onCancel: () => {

        },
      });
    } else {
      performUpdate();
    }
  }, [
    selectedItems,
    allItems,
    onUpdateTransactionsDescriptions,
    onUpdateMultipleTransactions,
  ]);

  return (
    <div style={{
      paddingLeft: 18,
      paddingBottom: 18,
      paddingRight: 18,
    }}
    >
      <Formik
        initialValues={{
          replace_text: null,
          replace_with: '',
          uppercase_lowercase: null,
          advanced_options: {
            all_uppercase: null,
            all_lowercase: null,
            remove_numbers: null,
            remove_special_characters: null,
            replace_token_with: null,
            find_contact_after: null,
          },
        }}
        onSubmit={handleReplaceText}
        enableReinitialize
      >
        {({ handleSubmit, values, setFieldValue }) => (

          <Form>
            <div
              className="d-flex align-items-center"
              style={{
                flexWrap: 'wrap',
              }}
            >
              {/* {showIcon && <IoTextOutline size="1.2rem" className="mr-3" /> } */}
              {showIcon && <strong style={{ fontWeight: 600, width: '130px' }}>Substituir Texto</strong> }

              <FastFilterTextReplace
                label="Substituir Texto"
                description="Substituir Texto"
                value={values.replace_with}
                onChange={(value) => {
                  const { replace_text, replace_with } = value;

                  setFieldValue('replace_text', replace_text);
                  setFieldValue('replace_with', replace_with);

                  handleSubmit();
                }}
                className="mr-3"
                renderDoubleDots={false}
                renderEmptyText={false}
                showValue={false}
              />
              <FastFilterSelect
                label="Alterar Maiúsculo / Minúsculo"
                className="mr-3"
                value={values.uppercase_lowercase}
                options={[
                  { value: 'ALL_UPPERCASE', label: 'Tudo em maiúsculo' },
                  { value: 'ALL_LOWERCASE', label: 'Tudo em minúsculo' },
                ]}
                onChange={(value) => {
                  if (value === 'ALL_UPPERCASE') {
                    setFieldValue('advanced_options.all_uppercase', true);

                    handleSubmit();

                    return;
                  }

                  if (value === 'ALL_LOWERCASE') {
                    setFieldValue('advanced_options.all_lowercase', true);

                    handleSubmit();
                  }
                }}
                renderDoubleDots={false}
                renderEmptyText={false}
              />
              <FastFilterSelect
                label="Opções de Remoção"
                className="mr-3"
                value={values.uppercase_lowercase}
                options={[
                  { value: 'REMOVE_NUMBERS', label: 'Remover números' },
                  { value: 'REMOVE_SPECIAL_CHARACTERS', label: 'Remover caracteres' },
                ]}
                onChange={(value) => {
                  if (value === 'REMOVE_NUMBERS') {
                    setFieldValue('advanced_options.remove_numbers', true);

                    handleSubmit();

                    return;
                  }

                  if (value === 'REMOVE_SPECIAL_CHARACTERS') {
                    setFieldValue('advanced_options.remove_special_characters', true);

                    handleSubmit();
                  }
                }}
                renderDoubleDots={false}
                renderEmptyText={false}
              />
            </div>

            {/* <Form.Group as={Col} xl={12}>
                  <Form.Label>
                    Substituir ## por...
                  </Form.Label>
                  <FormInlineRadioField
                    name="advanced_options.replace_token_with"
                    label="Tudo maiúsculo"
                    flexColumn
                    options={[
                      { value: 'current_month', label: 'Mês/Ano do Lançamento' },
                      { value: 'month_before', label: 'Mês/Ano anterior ao do Lançamento' },
                    ]}
                  />
                </Form.Group> */}
          </Form>

        )}
      </Formik>
    </div>
  );
}

ReplaceTextTab.defaultProps = {
  selectedItems: [],
  allItems: [],
  showIcon: false,
};

ReplaceTextTab.propTypes = {
  showIcon: PropTypes.bool,
  selectedItems: PropTypes.array,
  allItems: PropTypes.array,
  onUpdateTransactionsDescriptions: PropTypes.func.isRequired,
  onUpdateMultipleTransactions: PropTypes.func.isRequired,
};

export default ReplaceTextTab;
