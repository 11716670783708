import React, {
  useMemo,
  useRef,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { FaArrowCircleUp } from 'react-icons/fa';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import ReactTooltip from 'react-tooltip';

import FORMATTERS from 'helpers/formatters';
import { Card, CardBody } from '_components/_core';
import { Report } from '_components/_shared';

import classNames from 'classnames';
import { REPORT_FRIENDLY_NAMES } from '../../utilities';
import { ReportContext } from '../ReportContext';
import PrintHeader from '../PrintHeaderContainer';

import { StyledTable, StyledTransactionRow } from '../../styles';
import useTransactionsHelpers from '../../utilities/useTransactionsFormatters';
import EmptyState from '../EmptyState/EmptyState';
import { Checkbox, StyledTag, TagsContainer } from './styles';

function TagsReport({
  name,
  formattedPeriod,
  reports,
  account_ids,
  cost_center_ids,
  values,
  onEditTransaction,
  isMobile,
  setFieldValue,
}) {
  const { setValue } = useContext(ReportContext);

  const [selectedTags, setSelectedTags] = useState([]);

  const {
    getDescription,
    getDate,
    getPaidIcon,
  } = useTransactionsHelpers({ values });

  const reportRef = useRef();

  useEffect(() => {
    setValue(reportRef);
    setFieldValue('selected_tags', selectedTags);
  }, [setValue, selectedTags, setFieldValue]);

  const data = useMemo(() => {
    if (!reports) {
      return {};
    }

    const report = reports[name] || {};

    const { result: tags = [], result_other: tags2 = [] } = report;

    return [...tags, ...tags2];
  }, [reports, name]);

  const filteredTransactions = useMemo(() => {
    if (!data) {
      return [];
    }

    if (isEmpty(selectedTags)) {
      return [];
    }

    const transactions = data.reduce((acc, tagData) => {
      if (!selectedTags.includes(tagData.description)) {
        return acc;
      }

      const { transactions: tagTransactions } = tagData;

      return [...acc, ...tagTransactions];
    }, []);

    // Make sure all selected tags are in transaction
    const fullyFilteredTransactions = transactions.filter(
      (transaction) => selectedTags.every(
        (selectedTag) => transaction.tags.some(
          (tag) => tag.description === selectedTag,
        ),
      ),
    );

    return sortBy(uniqBy(fullyFilteredTransactions, 'id'), 'event_date');
  }, [data, selectedTags]);

  const transactionsTotal = useMemo(() => {
    if (isEmpty(filteredTransactions)) {
      return 0;
    }

    const total = filteredTransactions.reduce((acc, transaction) => {
      const { amount } = transaction;

      return acc + amount;
    }, 0);

    return total;
  }, [filteredTransactions]);

  const handleTagSelected = useCallback((tagName) => {
    if (selectedTags.includes(tagName)) {
      setSelectedTags(selectedTags.filter((tagDescription) => tagDescription !== tagName));
    } else {
      setSelectedTags([...selectedTags, tagName]);
    }
  }, [selectedTags]);

  if (isEmpty(data)) {
    return <EmptyState />;
  }

  return (
    <Report ref={reportRef}>
      <Card>
        <ReactTooltip />
        <PrintHeader
          title={REPORT_FRIENDLY_NAMES[name].toUpperCase()}
          description={formattedPeriod}
          formattedPeriod={formattedPeriod}
          account_ids={account_ids}
          cost_center_ids={cost_center_ids}
        />
        <div />
        <CardBody className="p-0 mt-0">
          <div className={classNames({ 'table-responsive': isMobile })}>
            <StyledTable className="table table-hover">
              <thead>
                <tr>
                  <th colSpan={5} className="font-weight-bold no-border">
                    <span>
                      MARCAÇÕES (TAGS)
                    </span>
                  </th>
                </tr>
                <tr>
                  <th colSpan={5} className="no-border text-left">
                    <TagsContainer>
                      {sortBy(data, 'description').map((tag) => (
                        <StyledTag onClick={() => handleTagSelected(tag.description)}>
                          <Checkbox
                            key={`checkbox-${tag.description}`}
                            type="checkbox"
                            checked={selectedTags.includes(tag.description)}
                          />
                          <span>
                            {tag.description}
                          </span>

                        </StyledTag>
                      ))}
                    </TagsContainer>
                  </th>
                </tr>
                <tr className="no-print">
                  <th colSpan={5} className="font-weight-bold text-center text-muted no-border">
                    <FaArrowCircleUp className="mr-3" />
                    Selecione as tags que deseja visualizar
                  </th>
                </tr>
                {!isEmpty(filteredTransactions) && (
                <tr>
                  <th style={{ width: '130px' }}>Data</th>
                  <th>Descrição</th>
                  <th style={{ width: '160px' }}>Categoria</th>
                  <th style={{ width: '120px' }} className="text-right">Valor</th>
                </tr>
                )}
              </thead>
              {!isEmpty(filteredTransactions) && (
              <tbody>
                {filteredTransactions.map((item) => (
                  <StyledTransactionRow onClick={() => onEditTransaction(item)}>
                    <td>
                      {getPaidIcon(item)}
                      {getDate(item)}
                    </td>
                    <td>
                      {getDescription(item)}
                      {item.type !== 'TRANSFER' && item.recipient.name && (
                      <small className="text-muted ml-1">
                        {item.type !== 'TRANSFER' && item.recipient.name && FORMATTERS.REPORT_CASH_FLOW_PREFIX(item)}
                      </small>
                      )}
                    </td>
                    <td>
                      {FORMATTERS.REPORT_CATEGORY_NAME(item)}
                    </td>
                    <td className="text-right">
                      {FORMATTERS.REPORT_AMOUNT(item.amount, item.type, item.sub_type)}
                    </td>
                  </StyledTransactionRow>
                ))}
              </tbody>
              )}
            </StyledTable>
          </div>
          {!isEmpty(filteredTransactions) && (
            <StyledTable>
              <tbody>
                <tr>
                  <td colSpan={4}>
                    <strong>Total</strong>
                  </td>
                  <td className="text-right">
                    <strong>
                      {FORMATTERS.REPORT_AMOUNT(transactionsTotal)}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </StyledTable>
          )}
        </CardBody>
      </Card>
    </Report>
  );
}

TagsReport.defaultProps = {
  reports: {},
  formattedPeriod: '',
  values: {},
  isMobile: false,
};

TagsReport.propTypes = {
  formattedPeriod: PropTypes.string,
  name: PropTypes.string.isRequired,
  reports: PropTypes.object,
  values: PropTypes.object,
  account_ids: PropTypes.array,
  cost_center_ids: PropTypes.array,
  onEditTransaction: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
};

export default TagsReport;
