import * as yup from 'yup';
import { ptShort } from 'yup-locale-pt';

yup.setLocale(ptShort);

const ValidationSchema = yup.object().shape({
  start_date: yup.date().required().nullable(),
  end_date: yup.date().required().nullable(),
});

export default ValidationSchema;
