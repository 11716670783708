import HttpService from './http.service';

export default {
  getAll,
  getById,
  insert,
  update,
  remove,
  duplicate,
};

async function getAll() {
  return HttpService.get('/accounts');
}

async function getById(id) {
  return HttpService.get(`/accounts/${id}`);
}

async function insert(account) {
  return HttpService.post('/accounts', account);
}

async function update(id, account) {
  return HttpService.put(`/accounts/${id}`, account);
}

async function remove(id) {
  return HttpService.delete(`/accounts/${id}`);
}

async function duplicate(account) {
  return HttpService.post('/duplicate-account', account);
}
