import styled, { css } from 'styled-components';

import { CardBody } from '_components/_core';

export const StyledCardBody = styled(CardBody)`
  overflow: auto;

  ${({ topDistance }) => topDistance && css`
    max-height: ${topDistance} !important;
  `}

  &&& {
    .table-responsive {
      overflow-x: visible;
    }
  }
`;
