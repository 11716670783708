import styled, { css } from 'styled-components';

import { Item, Separator, Submenu } from 'react-contexify';
import { DatePicker } from '_components/_core';

export const StyledItem = styled(Item)`
  ${({ isRedirect }) => isRedirect && css`
    div {
      color: #0056b3;
      text-align: center !important;

      &:hover {
        text-decoration: underline;
      }
    }   
  `}  
`;

export const StyledSeparator = styled(Separator)`
  
`;

export const StyledSubmenu = styled(Submenu)`
  
`;

export const StyledDatePicker = styled(DatePicker)`

`;
