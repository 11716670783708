import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Dropzone,
} from '_components/_core';
import { AccountSelect } from '_components/_shared';

function BankStatementUpload({
  isLoading,
  onFetchAccounts,
  onParseBankStatements,
}) {
  const [selectedBankStatements, setSelectedBankStatements] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);

  useEffect(() => {
    onFetchAccounts();
  }, [onFetchAccounts]);

  const handleFilesChanged = useCallback((files) => {
    setSelectedBankStatements(files);
  }, []);

  const handleAccountSelected = useCallback((value) => {
    if (value) {
      setSelectedAccount(value.value);
    } else {
      setSelectedAccount(null);
    }
  }, []);

  const handleSubmit = useCallback(() => {
    if (isEmpty(selectedBankStatements) || !selectedAccount) return;

    onParseBankStatements({
      account_id: selectedAccount,
      bank_statements: selectedBankStatements,
      product: process.env.REACT_APP_CONTABILLS_APP,
    });
  }, [selectedBankStatements, selectedAccount, onParseBankStatements]);

  const renderAccountSelect = useCallback(() => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    if (product === 'contabills-empresas') {
      return (
        <Form.Group>
          <Form.Label>Conta bancária</Form.Label>
          <AccountSelect
            onChange={handleAccountSelected}
            value={[selectedAccount]}
            creatable="transaction_account"
            account_type="transaction_account"
            isClearable
          />
        </Form.Group>

      );
    }

    return (
      <Form.Group>
        <Form.Label>Conta contábil</Form.Label>
        <AccountSelect
          onChange={handleAccountSelected}
          value={[selectedAccount]}
          creatable="account"
          isClearable
        />
      </Form.Group>
    );
  }, [selectedAccount, handleAccountSelected]);

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <Row>
              <Col md={6}>
                {renderAccountSelect()}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Selecionar arquivos</Form.Label>
                  <Dropzone onChange={handleFilesChanged} accept=".ofx" readAsText showLengthMessage />
                </Form.Group>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col>
                <Button
                  onClick={handleSubmit}
                  disabled={isLoading || isEmpty(selectedBankStatements) || !selectedAccount}
                  isLoading={isLoading}
                >
                  Importar
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
}

BankStatementUpload.defaultProps = {
  isLoading: false,
};

BankStatementUpload.propTypes = {
  isLoading: PropTypes.bool,
  onParseBankStatements: PropTypes.func.isRequired,
  onFetchAccounts: PropTypes.func.isRequired,
};

export default BankStatementUpload;
