import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';

const getUserProfile = () => {
  return (dispatch) => {
    dispatch(request());

    service.getCurrentProfile().then(
      (response) => {
        dispatch(success(response.data));
      },
    );
  };

  function request() {
    return { type: constants.USER_GET_REQUEST };
  }
  function success(user) {
    return {
      type: constants.USER_GET_SUCCESS,
      payload: { user },
    };
  }
};

export default getUserProfile;
