import constants from '../../_constants/employees.constants';
import service from '../../../services/employees.service';
import alertActionCreators from '../alert.actions';

import getEmployeeDependents from './getEmployeeDependents';

const updateEmployeeDependent = (
  employeeId,
  dependentId,
  values,
  callback,
) => {
  return (dispatch) => {
    dispatch(request(values));

    service.updateEmployeeDependent(
      employeeId,
      dependentId,
      values,
    ).then(
      (response) => {
        const updatedDependent = response.data;

        dispatch(success(updatedDependent));
        dispatch(alertActionCreators.success('Dependente atualizado com sucesso!'));
        dispatch(getEmployeeDependents(employeeId));

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(dependent) {
    return {
      type: constants.EMPLOYEE_DEPENDENTS_UPDATE_REQUEST,
      payload: { dependent },
    };
  }
  function success(dependent) {
    return {
      type: constants.EMPLOYEE_DEPENDENTS_UPDATE_SUCCESS,
      payload: { dependent },
    };
  }
  function failure(error) {
    return {
      type: constants.EMPLOYEE_DEPENDENTS_UPDATE_FAILURE,
      payload: error,
    };
  }
};

export default updateEmployeeDependent;
