import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { DateTime } from 'luxon';
import { HiOutlineReceiptTax } from 'react-icons/hi';

import { Button, FormMonthPickerField } from '_components/_core';
import { ActiveCompanyInfo } from '_components/_shared';

import { StyledModal, ModalFooter } from './styles';

const CalculateTaxesModal = ({
  isLoading,
  onCalculateTaxes,
  onFetchTaxes,
  activeCompany,
}) => {
  const { date_founded } = activeCompany || {};
  const [isVisible, setIsVisible] = useState(false);

  const handleCalculateTaxes = useCallback((values, { resetForm, setFieldError }) => {
    const { year: start_year } = DateTime.fromISO(values.start_date);
    const { year: end_year } = DateTime.fromISO(values.end_date);

    if (start_year !== end_year) {
      setFieldError('start_date', 'O Período Inicial e Final devem estar no mesmo ano.');
      return;
    }

    onCalculateTaxes(values, () => {
      const { start_date } = values;
      const { year } = DateTime.fromISO(start_date);

      resetForm();
      setIsVisible(false);
      onFetchTaxes({
        year,
      });
    });
  }, [onCalculateTaxes, onFetchTaxes]);

  const renderModalFooter = useCallback((handleSubmit) => (
    <ModalFooter>
      <Button variant="secondary" onClick={() => setIsVisible(false)}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={isLoading}
      >
        Apurar Impostos
      </Button>
    </ModalFooter>
  ), [isLoading]);

  return (
    <Formik
      initialValues={{
        start_date: null,
        end_date: null,
      }}
      onSubmit={handleCalculateTaxes}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <>
          <Button
            className="mr-2"
            variant="default"
            onClick={() => setIsVisible(true)}
            icon={<HiOutlineReceiptTax size="1.2em" />}
            iconPosition="left"
          >
            Nova Apuração
          </Button>
          <StyledModal
            title="Apurar Impostos"
            isVisible={isVisible}
            toggleModal={() => setIsVisible(false)}
            footer={renderModalFooter(handleSubmit)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="6" className="mb-0">
                  <Form.Label>Período Inicial</Form.Label>
                  <FormMonthPickerField
                    variant="input"
                    name="start_date"
                    placeholder="Período Inicial"
                    minDate={date_founded}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-0">
                  <Form.Label>Período Final</Form.Label>
                  <FormMonthPickerField
                    variant="input"
                    name="end_date"
                    placeholder="Período Final"
                    endOfMonth
                    minDate={date_founded}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row className="mt-3">
                <Col>
                  <ActiveCompanyInfo activeCompany={activeCompany} />
                </Col>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default CalculateTaxesModal;

CalculateTaxesModal.defaultProps = {
  isLoading: false,
  activeCompany: {},
};

CalculateTaxesModal.propTypes = {
  isLoading: PropTypes.func,
  onCalculateTaxes: PropTypes.func.isRequired,
  onFetchTaxes: PropTypes.func.isRequired,
  activeCompany: PropTypes.object,
};
