import { connect } from 'react-redux';

import authSelectors from '_store/_selectors/auth';
import reportsActionCreators from '_store/_actions/reports';
import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import MonthComparison from './MonthComparison';

const mapStateToProps = (state) => ({
  user: authSelectors.currentUserSelector(state),
  activeCompany: state.company.activeCompany,
  selected_account_id: state.companyTransactions.selected_account_id,
  accounts: state.companyTransactions.accounts,
  monthComparison: state.companyTransactions.monthComparison,
  selectedAccountIds: state.companyTransactions.selected_account_ids,
});

const mapDispatchToProps = {
  onFetchRevenueReport: reportsActionCreators.getRevenueReport,
  onFetchCashflow: companyTransactionsActionCreators.fetchCashflow,
  onFetchMonthComparison: companyTransactionsActionCreators.fetchMonthComparison,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MonthComparison);
