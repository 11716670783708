import './styles/liberty-ui-theme.css';
import './styles/liberty-ui-custom.css';

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import isEqual from 'lodash/isEqual';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/react-toastify-custom.css';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ReactGA from 'react-ga4';
import isEmpty from 'lodash/isEmpty';

import confirmDialog from 'helpers/confirmDialog';

import configureStore, { history } from './helpers/store';
// import './sentry';
import Theme from './styles/Theme';
import ContabillsRoutes from './routes/Routes';
import ContabillsEmpresasRoutes from './routes/CompanyAppRoutes';

const store = configureStore();

const product = process.env.REACT_APP_CONTABILLS_APP;

const STRIPE_KEY = product === 'contabills-empresas'
  ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_ZENPAPER
  : process.env.REACT_APP_STRIPE_PUBLIC_KEY;

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const stripePromise = loadStripe(STRIPE_KEY, {
  locale: 'pt-BR',
});

const App = () => {
  const Routes = () => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    switch (product) {
      case 'contabills':
        return <ContabillsRoutes />;
      case 'contabills-empresas':
        return <ContabillsEmpresasRoutes />;
      default:
        return <ContabillsRoutes />;
    }
  };

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key !== 'token') return;

      const oldToken = e.oldValue;
      const newToken = e.newValue;

      const isNewLogin = isEmpty(oldToken) && !isEmpty(newToken);
      const isLogout = !isEmpty(oldToken) && isEmpty(newToken);

      if (isLogout) {
        window.location.reload();

        return;
      }

      if (!isNewLogin && !isEqual(oldToken, newToken)) {
        confirmDialog.open({
          title: 'Novo login detectado',
          message: 'Detectamos um novo login em outra aba, a página será recarregada com os dados da nova sessão.',
          confirmButtonText: 'Recarregar',
          onConfirm: () => {
            window.location.reload();
          },
          onCancel: () => {
            window.location.reload();
          },
          showCancelButton: false,
          allowOutsideClick: false,
          icon: 'warning',
        });
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <ConnectedRouter history={history}>
          <Theme>
            <Routes />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </Theme>
        </ConnectedRouter>
      </Elements>
    </Provider>
  );
};
ReactDOM.render(<App />, document.getElementById('root'));
