import authActionCreators from '_store/_actions/auth';

export function handleResponse(response) {
  if (response.status !== 200) {
    if (response.status === 401) {
      authActionCreators.logout();
      window.location.reload(true);
    }

    const { data } = response;

    const error = (data && data.message) || response.statusText;

    return Promise.reject(error);
  }
  return response.data;
}

export default handleResponse;
