import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';
import { IoSwapHorizontalOutline } from 'react-icons/io5';
import pluralize from 'pluralize';

import { Button, FormAccountSelectField } from '_components/_core';

import { StyledModal, ModalFooter } from './styles';

const UpdateEntriesAccounts = ({
  isLoading,
  selectedItems,
  onUpdateEntries,
  onComplete,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleUpdateCreditAccount = useCallback((values, { resetForm }) => {
    onUpdateEntries({
      ids: selectedItems,
      type: 'accounts',
      payload: {
        ...values,
      },
    }, () => {
      resetForm();
      setIsVisible(false);

      if (onComplete) {
        onComplete();
      }
    });
  }, [selectedItems, onComplete, onUpdateEntries]);

  const renderModalFooter = useCallback((handleSubmit, values) => (
    <ModalFooter>
      <Button variant="secondary" onClick={() => setIsVisible(false)}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={isLoading || values.debit_account_id === values.credit_account_id}
      >
        {`Alterar débito/crédito de ${selectedItems.length} ${pluralize('lançamento', selectedItems.length)}`}
      </Button>
    </ModalFooter>
  ), [isLoading, selectedItems]);

  const renderErrorMessages = useCallback((values) => {
    if (!values.debit_account_id && !values.credit_account_id) {
      return null;
    }

    if (values.debit_account_id === values.credit_account_id) {
      return (
        <small className="text-danger">As contas Débito e Crédito devem ser diferentes.</small>
      );
    }

    return null;
  }, []);

  return (
    <Formik
      initialValues={{
        debit_account_id: null,
        credit_account_id: null,
      }}
      onSubmit={handleUpdateCreditAccount}
      enableReinitialize
    >
      {({
        handleSubmit,
        values,
      }) => (
        <>
          <Button
            className="mr-2 btn-sm"
            variant="light"
            onClick={() => setIsVisible(true)}
            icon={<IoSwapHorizontalOutline />}
          >
            Alterar Débito/Crédito
          </Button>
          <StyledModal
            title="Alterar Débito/Crédito"
            isVisible={isVisible}
            toggleModal={() => setIsVisible(false)}
            footer={renderModalFooter(handleSubmit, values)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>Nova conta débito (deixe em branco para não alterar)</Form.Label>
                  <FormAccountSelectField name="debit_account_id" placeholder="Selecionar nova conta débito" creatable="account" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>Nova conta crédito (deixe em branco para não alterar)</Form.Label>
                  <FormAccountSelectField name="credit_account_id" placeholder="Selecionar nova conta crédito" creatable="account" />
                </Form.Group>
              </Form.Row>
              {renderErrorMessages(values)}
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default UpdateEntriesAccounts;

UpdateEntriesAccounts.defaultProps = {
  isLoading: false,
  onComplete: null,
};

UpdateEntriesAccounts.propTypes = {
  isLoading: PropTypes.func,
  selectedItems: PropTypes.array.isRequired,
  onUpdateEntries: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
};
