import constants from '../../_constants/fiscal.constants';

const clearEFDContributions = () => {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return {
      type: constants.CLEAR_EFD_CONTRIBUTIONS_REQUEST,
      payload: { },
    };
  }
};

export default clearEFDContributions;
