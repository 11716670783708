import { receiptConstants } from '../_constants';

const INITIAL_STATE = {
  isLoading: false,
  receipts: [],
};

export function receipts(state = INITIAL_STATE, action) {
  switch (action.type) {
    case receiptConstants.RECEIPT_GET_ALL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case receiptConstants.RECEIPT_GET_ALL_SUCCESS: {
      const receipts = action.payload;

      return {
        ...state,
        receipts,
        isLoading: false,
      };
    }

    case receiptConstants.RECEIPT_GET_ALL_FAILURE: {
      return {
        ...state,
        receipts: [],
        isLoading: false,
      };
    }

    case receiptConstants.RECEIPT_GET_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case receiptConstants.RECEIPT_GET_SUCCESS: {
      const response = action.payload;

      return {
        ...state,
        receipt: response.data,
      };
    }
    case receiptConstants.RECEIPT_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case receiptConstants.RECEIPT_ADD_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case receiptConstants.RECEIPT_ADD_SUCCESS: {
      const receipt = action.payload;

      const newReceipts = [
        ...state.receipts,
        receipt,
      ];

      return {
        ...state,
        isLoading: false,
        receipts: newReceipts,
      };
    }

    case receiptConstants.RECEIPT_ADD_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case receiptConstants.RECEIPT_UPDATE_REQUEST: {
      const { id, values } = action.payload;

      const receipts = state.receipts.map((receipt) => {
        if (receipt._id === id) {
          return {
            ...receipt,
            ...values,
          };
        }

        return receipt;
      });

      return {
        ...state,
        receipts,
      };
    }

    case receiptConstants.RECEIPT_UPDATE_SUCCESS: {
      const receipt = action.payload;

      const receipts = state.receipts.map((r) => {
        if (r._id === receipt._id) {
          return receipt;
        }

        return r;
      });

      return {
        ...state,
        receipts,
      };
    }

    case receiptConstants.RECEIPT_UPDATE_FAILURE: {
      return {
        ...state,
      };
    }

    case receiptConstants.RECEIPT_DELETE_REQUEST: {
      const { id } = action.payload;

      const deletedReceipt = state.receipts.find(
        (receipt) => receipt._id === id,
      );

      const receipts = state.receipts.filter((receipt) => receipt._id !== id);

      return {
        ...state,
        receipts,
        deletedReceipt,
      };
    }

    case receiptConstants.RECEIPT_DELETE_SUCCESS: {
      return {
        ...state,
        deletedReceipt: null,
      };
    }

    case receiptConstants.RECEIPT_DELETE_FAILURE: {
      const { deletedReceipt } = state;

      return {
        ...state,
        receipts: [
          ...state.receipts,
          deletedReceipt,
        ],
        deletedReceipt: null,
      };
    }

    default:
      return state;
  }
}
