import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { StyledButton } from './styles';

function RedirectColumn({
  path,
  keyName,
  openNewTab,
  styleOnHover,
}) {
  const history = useHistory();

  const handleClick = useCallback(() => {
    if (openNewTab) {
      window.open(path, '_blank');
      return;
    }

    history.push(path);
  }, [path, history, openNewTab]);

  return (
    <>
      <StyledButton
        variant="link"
        size="sm"
        onClick={handleClick}
        styleOnHover={styleOnHover}
      >
        {keyName}
      </StyledButton>
    </>
  );
}

RedirectColumn.defaultProps = {
  openNewTab: false,
  styleOnHover: false,
};

RedirectColumn.propTypes = {
  path: PropTypes.string,
  keyName: PropTypes.string,
  openNewTab: PropTypes.bool,
  styleOnHover: PropTypes.bool,
};

export default RedirectColumn;
