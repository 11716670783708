import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/invoices.constants';
import service from '../../../services/invoices.service';

import getIssuedInvoices from './getIssuedInvoices';
import alertActionCreators from '../alert.actions';

const deleteIssuedInvoice = (id, callback) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Exclusão de Nota Fiscal',
      message: 'Tem certeza que deseja excluir esta Nota Fiscal? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, deletar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.deleteIssuedInvoice(id).then(() => {
          dispatch(success(id));
          dispatch(alertActionCreators.success('Nota Fiscal excluída com sucesso.'));

          dispatch(getIssuedInvoices());

          if (callback) {
            callback();
          }
        }).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request() {
    return {
      type: constants.DELETE_ISSUED_INVOICE_REQUEST,
      payload: { },
    };
  }
  function success(data) {
    return {
      type: constants.DELETE_ISSUED_INVOICE_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_ISSUED_INVOICE_FAILURE,
      payload: error,
    };
  }
};

export default deleteIssuedInvoice;
