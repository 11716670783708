import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';

const addCompanyCredential = (company_id, credential, callback) => {
  return async (dispatch) => {
    dispatch(request(credential));

    service.addCredential(company_id, credential).then(
      (response) => {
        dispatch(success(response));
        dispatch(alertActionCreators.success('Credencial adicionada com sucesso!'));

        if (callback) {
          service.getById(company_id).then((response) => {
            callback(response.data);
          });
        }
      },
    ).catch(
      (err) => {
        dispatch(failure(err));
        dispatch(alertActionCreators.error('Erro ao adicionar credencial!'));
      },
    );
  };

  function request() {
    return {
      type: constants.ADD_COMPANY_CREDENTIAL_REQUEST,
      payload: { credential },
    };
  }
  function success(response) {
    return {
      type: constants.ADD_COMPANY_CREDENTIAL_SUCCESS,
      payload: { response },
    };
  }
  function failure(error) {
    return {
      type: constants.ADD_COMPANY_CREDENTIAL_FAILURE,
      payload: { error },
    };
  }
};

export default addCompanyCredential;
