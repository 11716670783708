import { connect } from 'react-redux';

import PrintHeader from './PrintHeader';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  accounts: state.companyTransactions.accountsForSelect,
  costsCenter: state.companyTransactions.costsCenter,
});

export default connect(mapStateToProps, null)(PrintHeader);
