import styled from 'styled-components';

export const TradingName = styled.h4`
  text-transform: capitalize;
`;

export const CompanyInfo = styled.div`
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const AlertMessage = styled.h6`
  font-weight: 400;
  color: #b1b3bc;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;
