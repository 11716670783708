import uniqueId from 'lodash/uniqueId';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const createTransactionRecipient = (values, callback) => {
  return async (dispatch) => {
    const tempId = uniqueId();

    dispatch(request(tempId, values));

    service.insertRecipient(values).then(
      (response) => {
        const { data } = response;

        dispatch(success({
          tempId,
          data,
        }));

        if (callback) {
          callback(data);
        }
      },
    ).catch((error) => {
      dispatch(failure(tempId, error));

      if (callback) {
        callback(null);
      }
    });
  };

  function request(tempId, values) {
    return {
      type: constants.CREATE_COMPANY_TRANSACTION_RECIPIENT_REQUEST,
      payload: {
        tempId,
        values,
      },
    };
  }
  function success(payload) {
    return {
      type: constants.CREATE_COMPANY_TRANSACTION_RECIPIENT_SUCCESS,
      payload,
    };
  }
  function failure(tempId, error) {
    return {
      type: constants.CREATE_COMPANY_TRANSACTION_RECIPIENT_FAILURE,
      payload: {
        tempId,
        error,
      },
    };
  }
};

export default createTransactionRecipient;
