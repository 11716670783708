export const fiscalConstants = {
  PERSONS_ADD_REQUEST: 'PERSONS_ADD_REQUEST',
  PERSONS_ADD_SUCCESS: 'PERSONS_ADD_SUCCESS',
  PERSONS_ADD_FAILURE: 'PERSONS_ADD_FAILURE',

  PERSONS_GET_ALL_REQUEST: 'PERSONS_GET_ALL_REQUEST',
  PERSONS_GET_ALL_SUCCESS: 'PERSONS_GET_ALL_SUCCESS',
  PERSONS_GET_ALL_FAILURE: 'PERSONS_GET_ALL_FAILURE',

  PERSON_UPDATE_REQUEST: 'PERSON_UPDATE_REQUEST',
  PERSON_UPDATE_SUCCESS: 'PERSON_UPDATE_SUCCESS',
  PERSON_UPDATE_FAILURE: 'PERSON_UPDATE_FAILURE',

  PERSON_DELETE_REQUEST: 'PERSON_DELETE_REQUEST',
  PERSON_DELETE_SUCCESS: 'PERSON_DELETE_SUCCESS',
  PERSON_DELETE_FAILURE: 'PERSON_DELETE_FAILURE',

  GET_EFD_CONTRIBUTIONS_REQUEST: 'GET_EFD_CONTRIBUTIONS_REQUEST',
  GET_EFD_CONTRIBUTIONS_SUCCESS: 'GET_EFD_CONTRIBUTIONS_SUCCESS',
  GET_EFD_CONTRIBUTIONS_FAILURE: 'GET_EFD_CONTRIBUTIONS_FAILURE',

  CLEAR_EFD_CONTRIBUTIONS_REQUEST: 'CLEAR_EFD_CONTRIBUTIONS_REQUEST',

  DOWNLOAD_EFD_CONTRIBUTIONS_REQUEST: 'DOWNLOAD_EFD_CONTRIBUTIONS_REQUEST',
  DOWNLOAD_EFD_CONTRIBUTIONS_SUCCESS: 'DOWNLOAD_EFD_CONTRIBUTIONS_SUCCESS',
  DOWNLOAD_EFD_CONTRIBUTIONS_FAILURE: 'DOWNLOAD_EFD_CONTRIBUTIONS_FAILURE',
};

export default fiscalConstants;
