import React from 'react';

function NewDateHelpText() {
  return (
    <p>
      Esta opção irá sobrescrever todas as datas dos
      lançamentos selecionados com a nova data informada.
      <br />
      <br />
      Exemplo: se você definir a data
      {' '}
      <strong>05/03/2021</strong>
      <br />
      <ul className="mt-2 mb-0">
        <li>
          Lançamento com a data
          {' '}
          <strong>15/01/2021</strong>
          {' '}
          será alterado para
          {' '}
          <strong>05/03/2021</strong>
        </li>
        <li>
          Lançamento com a data
          {' '}
          <strong>25/01/2021</strong>
          {' '}
          será alterado para
          {' '}
          <strong>05/03/2021</strong>
        </li>
        <li>
          Lançamento com a data
          {' '}
          <strong>13/02/2021</strong>
          {' '}
          será alterado para
          {' '}
          <strong>05/03/2021</strong>
        </li>
      </ul>
    </p>
  );
}

export default NewDateHelpText;
