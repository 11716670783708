import { SPECIAL_COLUMNS } from '_components/_core/Table';
import FORMATTERS from 'helpers/formatters';

import EmployeeDependentSchema from './EmployeeDependentSchema';

export const DEFAULT_COLUMNS = [
  'name',
  'document_number',
  '_id',
];

export const TABLE_COLUMNS = [
  {
    label: 'Ações',
    keyName: '_id',
    align: 'center',
    width: 10,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'CPF',
    keyName: 'document_number',
    align: 'left',
    width: 10,
    formatter: FORMATTERS.CPF_CNPJ,
    order: 2,
  },
  {
    label: 'Nome',
    keyName: 'name',
    align: 'left',
    width: 80,
    formatter: FORMATTERS.UPPERCASE,
    order: 3,
  },
];

export default TABLE_COLUMNS;

export { EmployeeDependentSchema };
