import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const product = process.env.REACT_APP_CONTABILLS_APP;

const Report = React.forwardRef(({ children }, ref) => (
  <Container product={product} ref={ref} name="report-container">
    {children}
  </Container>
));

export default Report;

Report.defaultProps = {
};

Report.propTypes = {
  children: PropTypes.any,
};
