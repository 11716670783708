import { connect } from 'react-redux';

import FooterSummary from './FooterSummary';

const mapStateToProps = (state) => ({
  cashflow: state.companyTransactions.cashflow,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(FooterSummary);
