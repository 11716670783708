import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { IoMdPeople } from 'react-icons/io';
import { HiReceiptTax } from 'react-icons/hi';
import {
  FaArrowDown,
  FaArrowLeft,
  FaArrowUp,
} from 'react-icons/fa';
import { IoArrowDown, IoArrowUp } from 'react-icons/io5';

import FORMATTERS from 'helpers/formatters';
import {
  Card,
  CardBody,
  CardHeader,
  HintIcon,
} from '_components/_core';

import {
  CurrentMonth,
  CurrentMonthSubTypes,
  MonthsContainer,
  PreviousMonth,
  PreviousMonthSubTypes,
} from './styles';

function MonthComparison({
  accounts,
  monthComparison,
  selectedAccountIds,
}) {
  const accountName = useMemo(() => {
    if (isEmpty(selectedAccountIds) || isEmpty(accounts)) {
      return '';
    }

    if (selectedAccountIds.length === 1) {
      const [accountId] = selectedAccountIds;

      const account = accounts.find((a) => a.id === accountId);

      return account ? account.description : '';
    }

    if (selectedAccountIds.length === accounts.length) {
      return 'Todas as contas';
    }

    const names = accounts
      .filter((a) => selectedAccountIds.includes(a.id))
      .map((a) => a.description);

    if (names.length > 5) {
      return `${names.slice(0, 5).join(', ')}...e mais ${names.length - 5} contas`;
    }

    return names.join(', ');
  }, [accounts, selectedAccountIds]);

  const data = useMemo(() => {
    if (!monthComparison) {
      return {};
    }

    return monthComparison || {};
  }, [monthComparison]);

  const Line = ({ label, value, muted }) => (
    <small className={classNames('d-flex justify-content-between align-items-center mt-2 mb-2', muted ? 'text-muted' : '')}>
      <span>
        {label}
      </span>
      <span>
        {value}
      </span>
    </small>
  );

  const CurrentMonthLine = ({ label, value, muted }) => (
    <small className={classNames('d-flex justify-content-between align-items-center mt-2 mb-2', muted ? 'text-muted' : '')}>
      <span>
        <span className="d-none d-sm-block">
          {label}
        </span>
        <span className="d-block d-sm-none">
          &nbsp;
        </span>
      </span>
      <span>
        {value}
      </span>
    </small>
  );

  const getPercentageComparison = useCallback((key) => {
    if (!data) {
      return null;
    }

    if (!data.DIFFERENCE) {
      return null;
    }

    const { PREVIOUS, DIFFERENCE } = data;

    const current = DIFFERENCE[key];

    let label = null;
    let className = null;
    let percentage = null;
    let icon = null;
    let previous_month_value = null;
    let current_month_value = null;

    if (current === 0) {
      label = 'Manteve';
      className = 'text-muted';
      icon = <FaArrowLeft className={className} />;
    } else {
      label = current > 0 ? 'Aumentou' : 'Reduziu';

      if (key === 'INCOME') {
        className = current > 0 ? 'text-success' : 'text-danger';
        icon = current > 0 ? <FaArrowUp className={className} /> : <FaArrowDown className={className} />;
      } else {
        className = current > 0 ? 'text-danger' : 'text-success';
        icon = current > 0 ? <FaArrowUp className={className} /> : <FaArrowDown className={className} />;
      }

      const calculated = PREVIOUS[key] === 0 ? 1 : current / PREVIOUS[key];

      percentage = calculated < 0 ? calculated * -1 : calculated;

      previous_month_value = calculated < 0 ? (1 - (calculated * -1)) : 100;
      current_month_value = calculated < 0 ? 0 : calculated;
    }

    return {
      className,
      percentage: percentage * 100,
      icon,
      percentageFormatted: `${FORMATTERS.PERCENTAGE_CALCULATED(percentage)}`,
      content: (
        <span className={`${className} d-flex justify-content-start align-items-center`}>
          {icon}
          {`${label} ${percentage || ''}`}
        </span>
      ),
      previous_month_value: previous_month_value * 100,
      current_month_value: current_month_value * 100,
      previous_month_value_calculated: FORMATTERS.PERCENTAGE_CALCULATED(previous_month_value),
      current_month_value_calculated: FORMATTERS.PERCENTAGE_CALCULATED(current_month_value),
      previous_month_formatted: () => (
        <span>
          {FORMATTERS.PERCENTAGE_CALCULATED(previous_month_value)}
        </span>
      ),
      current_month_formatted: () => (
        <span className="d-flex align-items-center">
          {current === 0 && (
            <>
              <span className="text-muted mr-2">
                Sem alteração
              </span>
              {icon}
            </>
          )}
          {current !== 0 && (
            <>
              <span className="mr-2 text-muted">
                {current < 0 ? '-' : '+'}
                <span>
                  {/* ({FORMATTERS.NUMBER(current)}) */}
                </span>
                <span>
                  {FORMATTERS.PERCENTAGE_CALCULATED(percentage)}
                </span>
              </span>
              {icon}
            </>
          )}

        </span>
      ),
      label_percentage: () => (
        <>
          {current !== 0 && (
          <span className="d-flex text-muted align-items-center ml-2">
            <span className="ml-1">
              {current < 0 ? '' : '+'}
              {FORMATTERS.NUMBER(current)}
            </span>
          </span>
          )}
        </>

      ),
    };
  }, [data]);

  return (
    <Card>
      <CardHeader
        title="Comparativo mês anterior"
        description={(
          <span className="text-muted">
            {accountName}
          </span>
        )}
        leftColSize={10}
        rightColSize={2}
        sideContent={(
          <HintIcon hint="Aqui você pode ver o quanto suas receitas e despesas aumentaram ou diminuíram em relação ao mês anterior" />
        )}
        adjustSideContentMobile={false}
      />
      <CardBody noPaddingTop className="pt-3">
        <Row>
          <Col>
            <MonthsContainer>
              <PreviousMonth>
                <Line label={(
                  <span className="d-flex justify-content-center align-items-center">
                    <IoArrowUp className="text-muted mr-1" />
                    RECEBIMENTOS
                  </span>
                  )}
                />
                <ProgressBar now={getPercentageComparison('INCOME')?.previous_month_value || 0} variant="success" />

                <Line label={(
                  <span className="d-flex justify-content-center align-items-center">
                    <IoArrowDown className="text-muted mr-1" />
                    DESPESAS
                  </span>
                  )}
                />
                <ProgressBar now={getPercentageComparison('EXPENSE')?.previous_month_value || 0} variant="danger" />

                <PreviousMonthSubTypes className="ml-3">
                  <Line label={(
                    <span className="d-flex justify-content-center align-items-center">
                      <IoArrowDown className="text-muted mr-1" />
                      Despesas fixas
                    </span>
                  )}
                  />
                  <ProgressBar now={getPercentageComparison('FIXED_EXPENSE')?.previous_month_value || 0} variant="warning" />

                  <Line label={(
                    <span className="d-flex justify-content-center align-items-center">
                      <IoArrowDown className="text-muted mr-1" />
                      Despesas variáveis
                    </span>
                  )}
                  />
                  <ProgressBar now={getPercentageComparison('VARIABLE_EXPENSE')?.previous_month_value || 0} variant="warning" />

                  <Line label={(
                    <span className="d-flex justify-content-center align-items-center">
                      <IoMdPeople className="text-muted mr-1" />
                      Pessoas
                    </span>
                  )}
                  />
                  <ProgressBar now={getPercentageComparison('PEOPLE')?.previous_month_value || 0} variant="warning" />

                  <Line label={(
                    <span className="d-flex justify-content-center align-items-center">
                      <HiReceiptTax className="text-muted mr-1" />
                      Impostos
                    </span>
                  )}
                  />
                  <ProgressBar now={getPercentageComparison('TAXES')?.previous_month_value || 0} variant="warning" />
                </PreviousMonthSubTypes>
              </PreviousMonth>
              <CurrentMonth>
                <CurrentMonthLine label={getPercentageComparison('INCOME')?.label_percentage()} value={getPercentageComparison('INCOME')?.current_month_formatted() || 0} />
                <ProgressBar now={getPercentageComparison('INCOME')?.current_month_value || 0} variant="success" />

                <CurrentMonthLine label={getPercentageComparison('EXPENSE')?.label_percentage()} value={getPercentageComparison('EXPENSE')?.current_month_formatted() || 0} />
                <ProgressBar now={getPercentageComparison('EXPENSE')?.current_month_value || 0} variant="danger" />

                <CurrentMonthSubTypes>
                  <CurrentMonthLine label={getPercentageComparison('FIXED_EXPENSE')?.label_percentage()} value={getPercentageComparison('FIXED_EXPENSE')?.current_month_formatted() || 0} />
                  <ProgressBar now={getPercentageComparison('FIXED_EXPENSE')?.current_month_value || 0} variant="warning" />

                  <CurrentMonthLine label={getPercentageComparison('VARIABLE_EXPENSE')?.label_percentage()} value={getPercentageComparison('VARIABLE_EXPENSE')?.current_month_formatted() || 0} />
                  <ProgressBar now={getPercentageComparison('VARIABLE_EXPENSE')?.current_month_value || 0} variant="warning" />

                  <CurrentMonthLine label={getPercentageComparison('PEOPLE')?.label_percentage()} value={getPercentageComparison('PEOPLE')?.current_month_formatted() || 0} />
                  <ProgressBar now={getPercentageComparison('PEOPLE')?.current_month_value || 0} variant="warning" />

                  <CurrentMonthLine label={getPercentageComparison('TAXES')?.label_percentage()} value={getPercentageComparison('TAXES')?.current_month_formatted() || 0} />
                  <ProgressBar now={getPercentageComparison('TAXES')?.current_month_value || 0} variant="warning" />
                </CurrentMonthSubTypes>
              </CurrentMonth>

            </MonthsContainer>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

MonthComparison.defaultProps = {
  accounts: [],
  selectedAccountIds: [],
  monthComparison: null,
};

MonthComparison.propTypes = {
  monthComparison: PropTypes.object,
  accounts: PropTypes.array,
  selectedAccountIds: PropTypes.array,
};

export default MonthComparison;
