import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { IoCheckmark } from 'react-icons/io5';

import { Button } from '_components/_core';

const IssueInvoices = ({
  isLoading,
  issuedInvoices,
  selectedItems,
  onConfirmInvoicesIssuing,
  onComplete,
}) => {
  const hasInvalidInvoice = useMemo(() => {
    const affectedInvoices = issuedInvoices.filter((invoice) => selectedItems.includes(invoice.id));

    const invalid_status = ['user_authorized', 'pending_city_hall_authorization', 'issued'];

    return affectedInvoices.some((invoice) => invalid_status.includes(invoice.status));
  }, [issuedInvoices, selectedItems]);

  const handleImportEntries = useCallback(() => {
    const affectedInvoices = issuedInvoices.filter((entry) => selectedItems.includes(entry.id));

    onConfirmInvoicesIssuing({
      invoice_ids: affectedInvoices.map((invoice) => invoice.id),
    }, () => {
      onComplete({
        clearSelectedItems: true,
      });
    });
  }, [selectedItems, issuedInvoices, onComplete, onConfirmInvoicesIssuing]);

  return (
    <Button
      className="mr-2 btn-sm"
      variant="primary"
      onClick={handleImportEntries}
      isLoading={isLoading}
      disabled={isLoading || hasInvalidInvoice}
      icon={<IoCheckmark />}
    >
      {`Confirmar emissão de ${selectedItems.length} NFS-e`}
    </Button>
  );
};

export default IssueInvoices;

IssueInvoices.defaultProps = {
  isLoading: false,
  onComplete: null,
  issuedInvoices: [],
};

IssueInvoices.propTypes = {
  issuedInvoices: PropTypes.array,
  isLoading: PropTypes.bool,
  selectedItems: PropTypes.array.isRequired,
  onComplete: PropTypes.func,
  onConfirmInvoicesIssuing: PropTypes.func.isRequired,
};
