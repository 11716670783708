import { useState, useEffect } from 'react';

const useMediaQuery = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 768;
  const isTablet = width >= 768 && width < 1024;
  const isDesktopMedium = width >= 1024 && width < 1366;
  const isDesktopLarge = width >= 1366 && width < 1928;
  const isDesktopExtraLarge = width >= 1928;

  return {
    isMobile,
    isTablet,
    isDesktopMedium,
    isDesktopLarge,
    isDesktopExtraLarge,
  };
};

export default useMediaQuery;
