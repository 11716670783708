import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { BankAccountSelect } from '_components/_shared';
import { hasPermissions } from '_components/_shared/PermissionsGate/utilities';

function BankAccount({ selectedAccountIds, onChangeSelectedAccountIds }) {
  const userPermissions = useSelector(
    (state) => state.userPermissions.permissions[state.auth.user.id],
  );

  const canViewBalances = useMemo(() => hasPermissions({
    permissions: ['view_balance'],
    userPermissions,
    type: 'all',
  }), [userPermissions]);

  const handleAccountsSelected = useCallback((account_ids) => {
    onChangeSelectedAccountIds(account_ids);
  }, [onChangeSelectedAccountIds]);

  return (
    <BankAccountSelect
      value={selectedAccountIds}
      onChange={(account_ids) => handleAccountsSelected(account_ids)}
      isMultiple
      displayBalance
      displayPrincipalStar
      canViewBalances={canViewBalances}
    />
  );
}

BankAccount.defaultProps = {
  selectedAccountIds: [],
};

BankAccount.propTypes = {
  selectedAccountIds: PropTypes.array,
  onChangeSelectedAccountIds: PropTypes.func.isRequired,
};

export default BankAccount;
