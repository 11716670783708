import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const updateRecurringTransaction = (params, callback) => {
  return async (dispatch) => {
    let title = '';
    let message = '';

    const { payment_plan } = params;

    if (payment_plan === 'RECURRENT') {
      title = 'Alteração de periodicidade';
      message = 'Esta alteração afetará somente itens futuros desta série. Itens anteriores permanecerão inalterados.';

      confirmDialog.warning({
        title,
        message,
        icon: 'warning',
        confirmButtonText: 'Confirmar',
        onConfirm: () => {
          dispatch(request(params));

          service.updateRecurringTransaction(params).then(
            (response) => {
              dispatch(success(response.data, params));
              dispatch(alertActionCreators.success('Transação atualizada com sucesso!'));

              if (callback) {
                callback();
              }
            },
          ).catch((error) => {
            dispatch(failure(error));
          });
        },
        onCancel: () => {
          if (callback) {
            callback();
          }
        },
      });

      return;
    }

    dispatch(request(params));

    service.updateRecurringTransaction(params).then(
      (response) => {
        dispatch(success(response.data, params));
        dispatch(alertActionCreators.success('Transação atualizada com sucesso!'));

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(params) {
    return {
      type: constants.UPDATE_RECURRING_TRANSACTION_REQUEST,
      payload: params,
    };
  }
  function success(data, params) {
    return {
      type: constants.UPDATE_RECURRING_TRANSACTION_SUCCESS,
      payload: {
        transactions: data,
        params,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.UPDATE_RECURRING_TRANSACTION_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default updateRecurringTransaction;
