import constants from '../../_constants/fiscal.constants';
import service from '../../../services/fiscal.service';

const downloadEFDContributions = (start_date, end_date, callback) => {
  return (dispatch) => {
    dispatch(request());

    service.downloadEFDContributions(start_date, end_date).then(
      (response) => {
        const { data } = response;
        dispatch(success(data));

        if (callback) {
          callback(data);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.DOWNLOAD_EFD_CONTRIBUTIONS_REQUEST,
      payload: { },
    };
  }
  function success(data) {
    return {
      type: constants.DOWNLOAD_EFD_CONTRIBUTIONS_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.DOWNLOAD_EFD_CONTRIBUTIONS_FAILURE,
      payload: error,
    };
  }
};

export default downloadEFDContributions;
