import { parsePaginationParams } from 'helpers';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const fetchPaginatedRecipients = (params = {}) => {
  return async (dispatch) => {
    dispatch(request());

    const parsedParams = parsePaginationParams(params);

    service.getPaginatedRecipients(parsedParams).then(
      (response) => {
        dispatch(success(response));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.FETCH_PAGINATED_RECIPIENTS_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.FETCH_PAGINATED_RECIPIENTS_SUCCESS,
      payload: response.data,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_PAGINATED_RECIPIENTS_FAILURE,
      payload: error,
    };
  }
};

export default fetchPaginatedRecipients;
