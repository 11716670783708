import * as Yup from 'yup';
import { ptShort } from 'yup-locale-pt';

import {
  REQUIRED,
} from 'helpers/validation-messages';

Yup.setLocale(ptShort);

const FormSchema = Yup.object().shape({
  type_sub_type: Yup.mixed().oneOf(
    [
      'INCOME::',
      'EXPENSE::FIXED_EXPENSE',
      'EXPENSE::VARIABLE_EXPENSE',
      'EXPENSE::PEOPLE',
      'EXPENSE::TAXES',
      'TRANSFER::',
      'TRANSFER::SENT',
      'TRANSFER::RECEIVED',
    ],
  ).required().nullable(),
  event_date: Yup.date().nullable(true).required(REQUIRED).nullable(),
  description: Yup.string().nullable(),
  amount: Yup.number().required(REQUIRED).nullable(),
  recipient_id: Yup.string().when('type_sub_type', (type_sub_type, schema) => {
    if (['TRANSFER::', 'TRANSFER::SENT', 'TRANSFER::RECEIVED'].includes(type_sub_type)) {
      return schema.nullable();
    }

    return schema.nullable();
  }),
  category_id: Yup.string().when('type_sub_type', (type_sub_type, schema) => {
    if (['TRANSFER::', 'TRANSFER::SENT', 'TRANSFER::RECEIVED'].includes(type_sub_type)) {
      return schema.nullable();
    }

    return schema.nullable();
  }),
});

export default FormSchema;
