import React from 'react';
import { toast } from 'react-toastify';

import { alertConstants } from '../_constants';

export default {
  success,
  warning,
  error,
  info,
};

function success(message, options = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 3000,
}) {
  toast.success(() => (
    <div>
      <strong>Sucesso!</strong>
      <br />
      <small>
        {message}
      </small>
    </div>
  ), options);

  return { type: alertConstants.ALERT_SUCCESS, message };
}

function warning(message) {
  toast.warning(() => (
    <div>
      <strong>Atenção!</strong>
      <br />
      <small>
        {message}
      </small>
    </div>
  ), {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
  });
  return { type: alertConstants.ALERT_WARNING, message };
}

function error(message) {
  toast.error(() => (
    <div>
      <strong>Ocorreu um erro!</strong>
      <br />
      <small>
        {message}
      </small>
    </div>
  ), {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
  });
  return { type: alertConstants.ALERT_ERROR, message };
}

function info(message) {
  toast.info(() => (
    <div>
      <strong>Informação:</strong>
      <br />
      <small>
        {message}
      </small>
    </div>
  ), {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
  });
  return { type: alertConstants.ALERT_INFO, message };
}
