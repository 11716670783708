import { connect } from 'react-redux';

import ledgerActionCreators from '_store/_actions/ledger';
import accountsActionCreators from '_store/_actions/accounts';

import Ledger from './Ledger';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  ledger: state.ledger.ledger,
  isLoading: state.ledger.isLoading,
});

const mapDispatchToProps = {
  onFetchAccounts: accountsActionCreators.getAllAccounts,
  onFetchLedger: ledgerActionCreators.generateLedger,
  onClearLedger: ledgerActionCreators.eraseLedger,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ledger);
