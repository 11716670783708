import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import {
  Button,
  FormSelectField,
  FormSwitchField,
  Alert,
} from '_components/_core';

import classNames from 'classnames';
import { StyledModal, ModalFooter } from './styles';

export default function SearchModal({
  isVisible,
  companies,
  selectedConfiguration,
  onModalToggle,
  onAddAutomationSettings,
  onUpdateAutomationSettings,
  onFetchAutomationSettings,
  isLoading,
}) {
  const renderModalFooter = useCallback((values, handleSubmit) => (
    <ModalFooter>
      <Button variant="secondary" onClick={onModalToggle}>
        Cancelar
      </Button>
      <Button
        type="submit"
        disabled={isEmpty(values.companies)}
        variant="primary"
        onClick={handleSubmit}
        isLoading={isLoading}
      >
        Salvar Configuração
      </Button>
    </ModalFooter>
  ), [onModalToggle, isLoading]);

  const handleSearch = useCallback((values) => {
    const {
      automation_name,
      companies,
      is_on,
      day_of_month,
    } = values;

    const params = {
      automation_name,
      company_ids: companies.map((company) => company.value),
      settings: {
        is_on,
        day_of_month,
      },
    };

    if (selectedConfiguration) {
      onUpdateAutomationSettings(selectedConfiguration._id, params, () => {
        onModalToggle();
        onFetchAutomationSettings();
      });
    } else {
      onAddAutomationSettings(params, () => {
        onModalToggle();
        onFetchAutomationSettings();
      });
    }
  }, [
    onModalToggle,
    onAddAutomationSettings,
    onUpdateAutomationSettings,
    selectedConfiguration,
    onFetchAutomationSettings,
  ]);

  const companyOptions = useMemo(() => companies.map((company) => {
    const hasCertificate = !isNil(company.certificate_url);
    const isDisabled = !hasCertificate;
    const reason = !hasCertificate ? '(Certificado A1 não encontrado)' : '';

    return {
      value: company.id,
      label: `${company.company_name} ${reason}`,
      name_company: `${company.company_name}`,
      isDisabled,
    };
  }), [companies]);

  const DAYS = Array.from({ length: 31 }, (_, i) => i + 1).map((day) => ({
    value: day,
    label: `Dia ${day}`,
  }));

  const initialValues = useMemo(() => {
    if (selectedConfiguration) {
      const {
        automation_name,
        company_ids,
        settings,
      } = selectedConfiguration;

      const companies = companyOptions.filter(
        (option) => company_ids.includes(option.value),
      );

      return {
        automation_name,
        companies,
        is_on: settings.is_on,
        day_of_month: settings.day_of_month,
      };
    }

    return {
      automation_name: 'search_invoices',
      companies: [],
      is_on: false,
      day_of_month: 1,
    };
  }, [selectedConfiguration, companyOptions]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSearch}
      enableReinitialize
    >
      {({ handleSubmit, values }) => (
        <>
          <StyledModal
            title="Busca automática de NFS-e"
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(values, handleSubmit)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>Dia do mês</Form.Label>
                  <FormSelectField
                    name="day_of_month"
                    options={DAYS}
                    placeholder="Dia do mês"
                  />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Status</Form.Label>
                  <FormSwitchField
                    name="is_on"
                    sideContent={(
                      <small className={classNames('ml-3', values.is_on ? 'text-success' : 'text-danger')}>
                        {values.is_on ? 'A automação está ativada' : 'A automação está desativada'}
                      </small>
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} md="12">
                  <Form.Label>Empresas</Form.Label>
                  <FormSelectField
                    name="companies"
                    options={companyOptions}
                    placeholder="Empresas"
                    multiple
                  />
                </Form.Group>
              </Form.Row>
            </Form>
            <Alert
              description={(
                <p>
                  O Contabills irá buscar e conciliar automaticamente as Notas Fiscais de Serviço
                  no dia escolhido acima e para as empresas selecionadas.
                  {' '}
                  <br />
                  A busca é iniciada sempre às 00:00 (meia-noite) do dia selecionado.
                </p>
              )}
              className="mb-3"
            />
          </StyledModal>
        </>
      )}
    </Formik>
  );
}

SearchModal.defaultProps = {
  isLoading: false,
};

SearchModal.propTypes = {
  isVisible: PropTypes.bool,
  companies: PropTypes.array,
  selectedConfiguration: PropTypes.object,
  onFetchAutomationSettings: PropTypes.func.isRequired,
  onAddAutomationSettings: PropTypes.func.isRequired,
  onUpdateAutomationSettings: PropTypes.func.isRequired,
  onModalToggle: PropTypes.func.isRequired,
  isLoading: PropTypes.func,
};
