import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  Card,
  CardHeader,
  CardBody,
  LoadingOverlay,
} from '_components/_core';
import { useTable } from '_components/_core/Table/utils';

import { TABLE_COLUMNS, DEFAULT_COLUMNS } from './utilities';
import { StyledTable } from './styles';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function CalculatedDarfs({
  selectedDate,
  darfs,
  onFetchDarfs,
  onDeleteDarf,
  isLoading,
}) {
  const handleDeleteDarf = useCallback((id) => {
    onDeleteDarf(id, () => {
      onFetchDarfs(selectedDate.toFormat('yyyy'));
    });
  }, [onDeleteDarf, onFetchDarfs, selectedDate]);

  const {
    selectedItems,
    onItemSelected,
    onSelectAll,
  } = useTable({
    ...tableConfig,
    keyName: '_id',
    data: darfs,
    onFetchData: null,
  });

  return (
    <Row>
      <Col>
        <LoadingOverlay active={isLoading}>
          <Card>
            <CardHeader
              title="Guias"
              description={(
                <div>
                  Exibindo guias emitidas no ano de&nbsp;
                  <span style={{ color: '#727cf5' }}>
                    <b>{selectedDate.toFormat('yyyy')}</b>
                  </span>
                </div>
                )}
            />
            <CardBody noPaddingTop>
              <div className="table-responsive">
                <StyledTable
                  keyName="_id"
                  name="darfs-table"
                  className="table-hover table-striped"
                  data={darfs}
                  columns={TABLE_COLUMNS}
                  defaultColumns={DEFAULT_COLUMNS}
                  selectedItems={selectedItems}
                  onItemSelected={onItemSelected}
                  onSelectAll={onSelectAll}
                  onDelete={handleDeleteDarf}
                  deleteHint="Excluir guia"
                  showFooter
                  showColumnToggle
                />
              </div>
            </CardBody>
          </Card>
        </LoadingOverlay>
      </Col>
    </Row>
  );
}

CalculatedDarfs.defaultProps = {
  darfs: [],
  isLoading: false,
};

CalculatedDarfs.propTypes = {
  darfs: PropTypes.array,
  selectedDate: PropTypes.any,
  onFetchDarfs: PropTypes.func.isRequired,
  onDeleteDarf: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default CalculatedDarfs;
