import { userConstants } from '../_constants/user.constants';

const INITIAL_STATE = {
  permissions: {},
  isLoading: false,
};

export function userPermissions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case userConstants.FETCH_USER_PERMISSIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.FETCH_USER_PERMISSIONS_SUCCESS: {
      const { user_id, permissions } = action.payload;

      return {
        ...state,
        permissions: {
          ...state.permissions,
          [user_id]: permissions,
        },
        isLoading: false,
      };
    }
    case userConstants.FETCH_USER_PERMISSIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case userConstants.USER_UPDATE_PERMISSIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.USER_UPDATE_PERMISSIONS_SUCCESS: {
      const { user_id, permissions } = action.payload;

      return {
        ...state,
        permissions: {
          ...state.permissions,
          [user_id]: permissions,
        },
        isLoading: false,
      };
    }
    case userConstants.USER_UPDATE_PERMISSIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
