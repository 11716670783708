import { connect } from 'react-redux';

import bankStatementActionCreators from '_store/_actions/bankStatements';

import Import from './Import';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onFetchBankStatementEntries: bankStatementActionCreators.fetchBankStatementEntries,
};

export default connect(mapStateToProps, mapDispatchToProps)(Import);
