import { connect } from 'react-redux';

import accountantsActionCreators from '_store/_actions/accountants';
import employeesActionCreators from '_store/_actions/employees';

import ReportSignature from './ReportSignature';

const mapStateToProps = (state) => ({
  accountants: state.accountants.accountants,
  employees: state.employees.employees,
});

const mapDispatchToProps = {
  onFetchAccountants: accountantsActionCreators.getAllAccountants,
  onFetchEmployees: employeesActionCreators.getAllEmployees,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportSignature);
