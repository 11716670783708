import axios from 'axios';
import jwt_decode from 'jwt-decode';

import HttpService from './http.service';
import { apiConstants } from '../_store/_constants';
import { handleResponse } from '../helpers';

export default {
  signUp,
  login,
  logout,
  passwordReset,
  passwordUpdate,
  completeSignUp,
  confirmEmailToken,
  requestEmailConfirmation,
};

export const isAuthenticated = () => localStorage.getItem('token') !== null;
export const getToken = () => localStorage.getItem('token');

export function requireAuth(nextState, replace, next) {
  if (localStorage.getItem('token') !== null) {
    replace({
      pathname: '/Entrar',
      state: { nextPathname: nextState.location.pathname },
    });
  }
  next();
}

async function signUp(user) {
  return HttpService.post('/auth/register', user);
}

async function login(email, password) {
  const body = {
    email,
    password,
  };

  const response = await axios.post(`${apiConstants.API_URL}/auth/login`, body, {
    headers: {
      product: process.env.REACT_APP_CONTABILLS_APP,
    },
  });

  const { token } = await handleResponse(response);
  const decoded = jwt_decode(token);

  localStorage.setItem('user', JSON.stringify(decoded.user));
  localStorage.setItem('token', token);

  return decoded.user;
}

async function passwordReset(email) {
  const body = {
    email,
  };

  return axios.post(`${apiConstants.API_URL}/password-reset`, body, {
    headers: {
      product: process.env.REACT_APP_CONTABILLS_APP,
    },
  });
}

async function passwordUpdate(token, password, password_confirmation) {
  const body = {
    token,
    password,
    password_confirmation,
  };

  return axios.put(`${apiConstants.API_URL}/password-update`, body, {
    headers: {
      product: process.env.REACT_APP_CONTABILLS_APP,
    },
  });
}

async function completeSignUp(user) {
  return HttpService.post('/auth/complete-signup', user);
}

function logout() {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
}

async function requestEmailConfirmation(params) {
  return HttpService.post('/auth/confirm-email-request', params);
}

async function confirmEmailToken(params) {
  return HttpService.post('/auth/confirm-email', params);
}
