import { connect } from 'react-redux';

import payrollActionCreators from '_store/_actions/payroll';

import InssTables from './InssTables';

const mapStateToProps = (state) => ({
  inss: state.payroll.inssTables,
  total: state.payroll.inssTotal,
});

const mapDispatchToProps = {
  onFetchInss: payrollActionCreators.getInssTables,
};

export default connect(mapStateToProps, mapDispatchToProps)(InssTables);
