import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';

import {
  Button,
  FormDateField,
  FormSelectField,
} from '_components/_core';
import { DATE_TYPE_OPTIONS } from 'helpers/constants';

import { StyledModal, ModalFooter } from './styles';

export default function SearchModal({
  isVisible,
  onModalToggle,
  onSearchPDFInvoices,
}) {
  const renderModalFooter = useCallback((handleSubmit) => (
    <ModalFooter>
      <Button variant="secondary" onClick={onModalToggle}>
        Cancelar
      </Button>
      <Button type="submit" variant="primary" onClick={handleSubmit}>
        Buscar
      </Button>
    </ModalFooter>
  ), [onModalToggle]);

  const handleSearch = useCallback((values) => {
    const { start_date, end_date, date_type } = values;

    onSearchPDFInvoices(start_date, end_date, date_type);
    onModalToggle();
  }, [onModalToggle, onSearchPDFInvoices]);

  return (
    <Formik
      initialValues={{
        start_date: null,
        end_date: null,
        date_type: 'date_emission',
      }}
      onSubmit={handleSearch}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <>
          <StyledModal
            title="Busca de PDF&apos;s (NFS-e)"
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>Buscar por:</Form.Label>
                  <FormSelectField
                    name="date_type"
                    options={DATE_TYPE_OPTIONS}
                    placeholder="Data de Emissão"
                  />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Data Inicial</Form.Label>
                  <FormDateField
                    name="start_date"
                    placeholder="Data Inicial"
                  />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Data Final</Form.Label>
                  <FormDateField
                    name="end_date"
                    placeholder="Data Final"
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
}

SearchModal.defaultProps = {
  isVisible: false,
};

SearchModal.propTypes = {
  isVisible: PropTypes.bool,
  onSearchPDFInvoices: PropTypes.func.isRequired,
  onModalToggle: PropTypes.func.isRequired,
};
