import { parsePaginationParams } from 'helpers';

import constants from '../../_constants/payroll.constants';
import service from '../../../services/payroll.service';

const getIrrfTables = (params = {}) => {
  return (dispatch) => {
    dispatch(request());

    const parsedParams = parsePaginationParams(params);

    service.getIrrfTables(parsedParams).then(
      (response) => {
        const { total, data } = response.data;

        dispatch(success({ total, data }));
      },
    );
  };

  function request() {
    return {
      type: constants.IRRF_GET_ALL_REQUEST,
      payload: { },
    };
  }
  function success(irrf) {
    return {
      type: constants.IRRF_GET_ALL_SUCCESS,
      payload: irrf,
    };
  }
};

export default getIrrfTables;
