import styled, { css } from 'styled-components';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { Form } from 'react-bootstrap';

import { Button } from '_components/_core';
import Modal from '_components/_core/Modal';
import { CustomSelectField as CustomSelect } from '_components/Financial/BankStatement/Reconcile/components';
import { CategorySelect } from '_components/_shared';

export const CustomSelectField = styled(CustomSelect)`
  width: 100%;
`;

export const CustomCategorySelect = styled(CategorySelect)`
  width: 100%;
`;

export const StyledModal = styled(Modal)`

  div.modal-body {
    padding: 8px !important;
  }

  @media (max-width: 768px) {
    div.modal-dialog {
      max-width: 100%;
    }
  }

  @media (max-width: 1920px) {
    div.modal-dialog {
      max-width: 95%;
    }
  }
`;

export const SplitsTable = styled.table`
  &&& {
    font-weight: 400;
    width: 100%;

    thead tr th {
      /* height: 32px;
      line-height: 32px; */
      background: #FFFFFF;
      padding: 9px;
      vertical-align: middle;
      border-left: 1px solid #D4D7DC;
      border-bottom: 1px solid #D4D7DC;
      text-align: left;
      font-size: 0.8em;
      font-weight: 600;
      text-transform: uppercase; 
      position: relative;
    }

    thead tr th:first-child {
      border-left: none;
    }

    thead, tfoot, tbody tr {
      /* display: table; */
      /* width: 100%; */
      /* table-layout: fixed; */
    }

    thead {
      width: calc(100% - 1em);
    }

    tfoot {
      width: calc(100% - 1em);
    }

    tbody tr td {
      line-height: 26px;
      padding: 9px;
      border-top: 1px solid #D4D7DC;
    }

    tfoot tr:first-child td {
      border-top: 2px solid #D4D7DC;
      padding: 6px 9px;
      padding-top: 16px;
    }

    tfoot tr td {
      padding: 6px 9px;
      line-height: 28px;
    }
    
    tfoot tr:last-child td {
      padding-top: 0;
      vertical-align: top;
    }    

    ${({ isMobile }) => isMobile && css`
      thead {
        display: none;
      }

      tbody, tfoot {
        tr {
          display: block;
        }

        td {
          display: flex;
          flex: 1;
          justify-content: space-between;
          width: 100% !important;
        }

        td:last-child {
          border-bottom: 2px solid #D4D7DC;
        }

        td {
          &:before {
            content: attr(data-label);
            flex-basis: 50%;
            text-align: left;
            font-weight: 600;

            display: flex;
            align-items: center;
          }
        }

        td.no-before {
          &:before {
            content: none;
          }
        }
      }
    `}

    tfoot.mobile-footer td {
      padding: 8px !important;
      vertical-align: middle;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export const StyledCheckbox = styled(Form.Check)`
  label {
    padding-left: 0 !important;
    cursor: pointer;
  }
`;

export const StyledWarning = styled.div`
  border: 1px solid #E90;
  padding: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 700;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  vertical-align: top;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: 0.1rem;
  color: var(--th-color);

  font-weight: 600;
  text-transform: uppercase;

  :hover, :focus {
    text-decoration: none;
    color: var(--text-primary);
    background-color: transparent;
  }
`;

export const Option = styled(DropdownItem)`
  font-size: 13px;
  padding: 8px 14px;
  font-weight: 400;
  text-transform: capitalize;

  :hover, :focus {
    font-weight: 500;
    color: #00784e;
    background-color: transparent;
  }

  ${({ isActive }) => isActive && css`
    font-weight: 500 !important;
    color: #00784e !important;
    background-color: yellow !important;    
  `}
`;
