import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Col from 'react-bootstrap/Col';

import { CardBody, Card } from '_components/_core';
import {
  CompanyIcon,
  FamilyIcon,
  MeiIcon,
  SelfEmployedIcon,
} from '../FormIcons';

import {
  StyledCard,
  StyledTitle,
  StyledSubtitle,
  CardContent,
  ItemsContainer,
} from './styles';

function Menu({
  onChange,
  user,
}) {
  const menuItems = [
    {
      icon: <FamilyIcon />,
      title: 'Família',
      description: 'Quero gerenciar despesas pessoais ou do lar.',
      key: 'FAMILY',
    },
    {
      icon: <SelfEmployedIcon />,
      title: 'Autônomo',
      description: 'Sou autônomo ou profissional liberal.',
      key: 'SELF_EMPLOYED',
    },
    {
      icon: <MeiIcon />,
      title: 'MEI',
      description: 'Sou micro-empreendedor individual.',
      key: 'MEI',
    },
    {
      icon: <CompanyIcon />,
      title: 'Microempresa',
      description: 'Possuo uma microempresa ou uma ONG.',
      key: 'COMPANY',
    },
  ];

  const handleMenuChange = useCallback((item) => {
    onChange(item);
  }, [onChange]);

  return (
    <Card>
      <CardBody>
        <StyledTitle>
          {`Olá, ${user.document_type === 'PF' ? user.full_name : user.first_name}`}
        </StyledTitle>
        <StyledSubtitle>
          Vamos ajudar você a criar uma nova empresa no Zenply.
          <br />
          <br />
          Selecione o tipo de negócio:
        </StyledSubtitle>
        <ItemsContainer>
          {menuItems.map((item) => (
            <StyledCard onClick={() => handleMenuChange(item)}>
              <Col className="d-flex">
                <div className="d-flex justify-content-center">
                  {item.icon}
                </div>
                <CardContent>
                  <h4>{item.title}</h4>
                  <p>
                    {item.description}
                  </p>
                </CardContent>
              </Col>
            </StyledCard>
          ))}
        </ItemsContainer>
      </CardBody>
    </Card>
  );
}

Menu.propTypes = {
  onChange: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default Menu;
