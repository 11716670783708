import Swal from 'sweetalert2';

import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const uploadZeropaperSpreadsheet = (formData, callback) => {
  return async (dispatch) => {
    confirmDialog.warning({
      title: 'Atenção!',
      message: 'Ao importar um back-up do ZeroPaper todas as movimentações existentes aqui no Zenply serão apagadas e substituídas pelo novo back-up importado. <br /> Deseja continuar?',
      confirmButtonText: 'Sim, importar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request());

        service.uploadZeropaperSpreadsheet(formData).then(
          (response) => {
            dispatch(success(response));

            if (callback) {
              callback(response.data);
            }
          },
        ).catch(
          (err) => {
            dispatch(failure(err));

            Swal.close();
          },
        );
      },
      onCancel: () => {},
    });
  };

  function request() {
    return {
      type: constants.UPLOAD_ZEROPAPER_SPREADSHEET_REQUEST,
      payload: {},
    };
  }
  function success(payload) {
    return {
      type: constants.UPLOAD_ZEROPAPER_SPREADSHEET_SUCCESS,
      payload,
    };
  }
  function failure(tempId, error) {
    return {
      type: constants.UPLOAD_ZEROPAPER_SPREADSHEET_FAILURE,
      payload: {
        tempId,
        error,
      },
    };
  }
};

export default uploadZeropaperSpreadsheet;
