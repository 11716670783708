import { push } from 'connected-react-router';

import constants from '../../_constants/auth.constants';
import service from '../../../services/auth.service';
import alertActionCreators from '../alert.actions';

const passwordUpdate = (token, password, password_confirmation) => {
  return (dispatch) => {
    dispatch(request());

    service.passwordUpdate(token, password, password_confirmation).then(
      (response) => {
        dispatch(success(response));
        dispatch(push('/entrar'));

        dispatch(alertActionCreators.success(response.data.message));
      },
    ).catch(({ response }) => {
      if (response.status === 401 || response.status === 422) {
        dispatch(failure(response.data.message));
        dispatch(alertActionCreators.error(response.data.message));
      } else {
        dispatch(failure(response.data));
      }
    });
  };

  function request() {
    return { type: constants.PASSWORD_UPDATE_REQUEST };
  }
  function success(response) {
    return { type: constants.PASSWORD_UPDATE_SUCCESS, payload: { response } };
  }
  function failure(error) {
    return { type: constants.PASSWORD_UPDATE_FAILURE, payload: { error } };
  }
};

export default passwordUpdate;
