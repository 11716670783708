import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import TransactionFiles from './TransactionFiles';

const mapStateToProps = (state) => ({
  files: state.companyTransactions.files,
  isFetchingFiles: state.companyTransactions.isFetchingFiles,
});

const mapDispatchToProps = {
  onFilesUpload: companyTransactionsActionCreators.uploadTransactionFiles,
  onFilesDelete: companyTransactionsActionCreators.deleteTransactionFiles,
  onClearTransactionFiles: companyTransactionsActionCreators.clearTransactionFiles,
  onFetchTransactionFiles: companyTransactionsActionCreators.fetchTransactionFiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionFiles);
