import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import Modal from '_components/_core/Modal';
import Button from '_components/_core/Button';

import { isValidEmail } from '../../utils';
import styles from './styles.module.scss';

function InviteUserModal({
  isOpen, isLoading, onHide, onUserInvite,
}) {
  const [email, setEmail] = useState('');

  const renderFooter = () => (
    <div className={styles.footer}>
      <Button variant="secondary" onClick={onHide}>
        Cancelar
      </Button>
      <Button variant="primary" onClick={handleSubmit} disabled={isLoading} isLoading={isLoading}>
        Enviar convite
      </Button>
    </div>
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      return;
    }

    if (!isValidEmail(email)) {
      return;
    }

    onUserInvite(email);
    setEmail('');
  };

  return (
    <Modal
      title="Convidar usuário"
      isVisible={isOpen}
      onHide={onHide}
      footer={renderFooter()}
    >
      <Form onSubmit={handleSubmit}>
        <Form.Control
          type="email"
          placeholder="Digite o e-mail do usuário a ser convidado"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </Form>
    </Modal>
  );
}

InviteUserModal.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  onHide: PropTypes.func,
  onUserInvite: PropTypes.func,
};

export default InviteUserModal;
