import {
  IoBookmark,
  IoCalendarOutline,
  IoFilter,
  IoPencil,
  IoPricetags,
} from 'react-icons/io5';
import { AiOutlineBarChart } from 'react-icons/ai';
import { IoMdPeople } from 'react-icons/io';
import { FaSitemap } from 'react-icons/fa';

import exportToExcel from './exportToExcel';

export const REPORT_NAMES = {
  EXPENSES_BY_DESCRIPTION: 'expenses_by_description',
  EXPENSES_BY_TYPE: 'expenses_by_type',
  EXPENSES_BY_CATEGORY: 'expenses_by_category',
  INCOME_BY_DESCRIPTION: 'income_by_description',
  INCOME_BY_TYPE: 'income_by_type',
  INCOME_BY_CATEGORY: 'income_by_category',
  DEMONSTRATIVE_RESULTS: 'demonstrative_results',
  CASHFLOW_STATEMENT: 'cashflow_statement',
  CASHFLOW_STATEMENT_BY_TYPE: 'cashflow_statement_by_type',
  MONTHLY_PERFORMANCE: 'monthly_performance',
  YEARLY_PERFORMANCE: 'yearly_performance',
  INCOMES_BY_RECIPIENT: 'incomes_by_recipient',
  EXPENSES_BY_RECIPIENT: 'expenses_by_recipient',
  HISTORICAL_INCOME_EXPENSES: 'historical_income_expenses',
  INCOMES_BY_COST_CENTER: 'incomes_by_cost_center',
  EXPENSES_BY_COST_CENTER: 'expenses_by_cost_center',
  EXPENSES_BY_DAY: 'expenses_by_day',
  INCOME_BY_DAY: 'income_by_day',
  EXPENSES_BY_TAGS: 'expenses_by_tags',
  INCOME_BY_TAGS: 'income_by_tags',
};

export const REPORT_FRIENDLY_NAMES = {
  [REPORT_NAMES.EXPENSES_BY_DESCRIPTION]: 'Despesas por Descrição',
  [REPORT_NAMES.EXPENSES_BY_TYPE]: 'Despesas por Tipo',
  [REPORT_NAMES.EXPENSES_BY_CATEGORY]: 'Despesas por Categoria',
  [REPORT_NAMES.INCOME_BY_DESCRIPTION]: 'Receitas por Descrição',
  [REPORT_NAMES.INCOME_BY_TYPE]: 'Receitas por Tipo',
  [REPORT_NAMES.INCOME_BY_CATEGORY]: 'Receitas por Categoria',
  [REPORT_NAMES.DEMONSTRATIVE_RESULTS]: 'Demonstrativo de Resultados',
  [REPORT_NAMES.CASHFLOW_STATEMENT]: 'Extrato',
  [REPORT_NAMES.CASHFLOW_STATEMENT_BY_TYPE]: 'Fluxo de Caixa',
  [REPORT_NAMES.MONTHLY_PERFORMANCE]: 'Performance Mensal',
  [REPORT_NAMES.YEARLY_PERFORMANCE]: 'Performance Anual',
  [REPORT_NAMES.INCOMES_BY_RECIPIENT]: 'Recebimentos de...',
  [REPORT_NAMES.EXPENSES_BY_RECIPIENT]: 'Despesas pagas a...',
  [REPORT_NAMES.HISTORICAL_INCOME_EXPENSES]: 'Histórico de Receitas e Despesas',
  [REPORT_NAMES.INCOMES_BY_COST_CENTER]: 'Receitas por Centro de Custo',
  [REPORT_NAMES.EXPENSES_BY_COST_CENTER]: 'Despesas por Centro de Custo',
  [REPORT_NAMES.EXPENSES_BY_DAY]: 'Despesas por dia',
  [REPORT_NAMES.INCOME_BY_DAY]: 'Receitas por dia',
  [REPORT_NAMES.EXPENSES_BY_TAGS]: 'Despesas por tags',
  [REPORT_NAMES.INCOME_BY_TAGS]: 'Receitas por tags',
};

export const TRANSACTION_TYPES = {
  incomes: {
    label: 'Recebimentos',
    parentLabel: 'Recebimentos',
    color: '#53B700',
    borderColor: '#108000',
    type: 'INCOME',
  },
  fixed_expenses: {
    label: 'Despesas Fixas',
    parentLabel: 'Despesas',
    color: '#FF6A00',
    borderColor: '#F95700',
    type: 'EXPENSE',
    sub_type: 'FIXED_EXPENSE',
  },
  people: {
    label: 'Pessoal',
    parentLabel: 'Despesas',
    color: '#FF6A00',
    borderColor: '#F95700',
    type: 'EXPENSE',
    sub_type: 'PEOPLE',
  },
  variable_expenses: {
    label: 'Despesas Variáveis',
    parentLabel: 'Despesas',
    color: '#FF6A00',
    borderColor: '#F95700',
    type: 'EXPENSE',
    sub_type: 'VARIABLE_EXPENSE',
  },
  taxes: {
    label: 'Impostos',
    parentLabel: 'Despesas',
    color: '#FF6A00',
    borderColor: '#F95700',
    type: 'EXPENSE',
    sub_type: 'TAXES',
  },
  transfers_received: {
    label: 'Transferências Recebidas',
    color: '#0077C5',
    borderColor: '#055393',
    type: 'TRANSFER',
    sub_type: 'RECEIVED',
  },
  transfers_send: {
    label: 'Transferências Enviadas',
    color: '#0077C5',
    borderColor: '#055393',
    type: 'TRANSFER',
    sub_type: 'SENT',
  },
};

export const getReportDescription = (reportName, period) => {
  switch (reportName) {
    case REPORT_NAMES.EXPENSES_BY_DESCRIPTION:
      return 'Relatório de despesas por descrição';
    case REPORT_NAMES.EXPENSES_BY_TYPE:
      return 'Relatório de despesas por tipo';
    case REPORT_NAMES.EXPENSES_BY_CATEGORY:
      return 'Relatório de despesas por categoria';
    case REPORT_NAMES.EXPENSES_BY_COST_CENTER:
      return 'Relatório de despesas por centro de custo';
    case REPORT_NAMES.INCOME_BY_DESCRIPTION:
      return 'Relatório de receitas por descrição';
    case REPORT_NAMES.INCOME_BY_TYPE:
      return 'Relatório de receitas por tipo';
    case REPORT_NAMES.INCOME_BY_CATEGORY:
      return 'Relatório de receitas por categoria';
    case REPORT_NAMES.INCOME_BY_COST_CENTER:
      return 'Relatório de receitas por centro de custo';
    case REPORT_NAMES.DEMONSTRATIVE_RESULTS:
      return 'Demonstrativo de resultados';
    case REPORT_NAMES.CASHFLOW_STATEMENT:
      return `FLUXO DE CAIXA: ${period}`;
    case REPORT_NAMES.CASHFLOW_STATEMENT_BY_TYPE:
      return `${period}`;
    case REPORT_NAMES.MONTHLY_PERFORMANCE:
      return 'Performance da empresa em relação ao mês anterior';
    case REPORT_NAMES.YEARLY_PERFORMANCE:
      return 'Performance da empresa em relação ao ano anterior';
    case REPORT_NAMES.EXPENSES_BY_DAY:
      return 'Relatório de despesas por dia';
    case REPORT_NAMES.INCOME_BY_DAY:
      return 'Relatório de receitas por dia';
    default:
      return '';
  }
};

export const expense_items = [
  {
    name: 'Por Descrição',
    icon: IoPencil,
    tab: 'expenses_by_description',
  },
  {
    name: 'Por Dia',
    icon: IoCalendarOutline,
    tab: 'expenses_by_day',
  },
  {
    name: 'Por Tipo',
    icon: IoFilter,
    tab: 'expenses_by_type',
  },
  {
    name: 'Por Categoria',
    icon: IoBookmark,
    tab: 'expenses_by_category',
  },
  {
    name: 'Por Tags',
    icon: IoPricetags,
    tab: 'expenses_by_tags',
  },
  {
    name: 'Por Centro de Custo',
    icon: FaSitemap,
    tab: 'expenses_by_cost_center',
  },
  // {
  //   name: 'Histórico',
  //   icon: AiOutlineBarChart,
  //   tab: 'expense_history',
  // },
  {
    name: 'Pago a...',
    icon: IoMdPeople,
    tab: 'expenses_by_recipient',
  },
];

export const income_items = [
  {
    name: 'Por Descrição',
    icon: IoPencil,
    tab: 'income_by_description',
  },
  {
    name: 'Por Dia',
    icon: IoCalendarOutline,
    tab: 'income_by_day',
  },
  {
    name: 'Por Categoria',
    icon: IoBookmark,
    tab: 'income_by_category',
  },
  {
    name: 'Por Tags',
    icon: IoPricetags,
    tab: 'income_by_tags',
  },
  {
    name: 'Por Centro de Custo',
    icon: FaSitemap,
    tab: 'incomes_by_cost_center',
  },
  // {
  //   name: 'Histórico',
  //   icon: AiOutlineBarChart,
  //   tab: 'income_history',
  // },
  {
    name: 'Recebido de...',
    icon: IoMdPeople,
    tab: 'incomes_by_recipient',
  },
];

export const cashflow_items = [
  {
    name: 'Extrato',
    icon: IoPencil,
    tab: 'cashflow_statement',
  },
  {
    name: 'Despesas/Receitas',
    icon: IoCalendarOutline,
    tab: 'cashflow_statement_by_type',
  },
  {
    name: 'Histórico',
    icon: IoBookmark,
    tab: 'historical_income_expenses',
  },
  {
    name: 'Demonstrativo (DRE)',
    icon: IoPricetags,
    tab: 'demonstrative_results',
  },
  {
    name: 'Performance Mensal',
    icon: AiOutlineBarChart,
    tab: 'monthly_performance',
  },
  {
    name: 'Performance Anual',
    icon: AiOutlineBarChart,
    tab: 'yearly_performance',
  },
];

export const all_items = [
  ...expense_items,
  ...income_items,
  ...cashflow_items,
];

export {
  exportToExcel,
};
