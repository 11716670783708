import confirmDialog from 'helpers/confirmDialog';

import constants from '../../../_constants/company-transactions.constants';
import service from '../../../../services/company-transactions.service';
import alertActionCreators from '../../alert.actions';
import fetchPaginatedRecipients from '../fetchPaginatedRecipients';

const deleteContact = (id, params = {}) => {
  return async (dispatch) => {
    confirmDialog.warning({
      title: 'Excluir Contato',
      message: 'Tem certeza que deseja excluir este Contato? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, excluir!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.deleteContact(id).then(
          (response) => {
            dispatch(success(id, response));
            dispatch(alertActionCreators.success('Contato excluído com sucesso!'));
            dispatch(fetchPaginatedRecipients(params));
          },
        ).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request(id) {
    return {
      type: constants.DELETE_CONTACT_REQUEST,
      payload: {
        id,
      },
    };
  }
  function success(id, response) {
    return {
      type: constants.DELETE_CONTACT_SUCCESS,
      payload: {
        id,
        response,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_CONTACT_FAILURE,
      payload: {
        id,
        error,
      },
    };
  }
};

export default deleteContact;
