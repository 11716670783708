import { connect } from 'react-redux';

import invoiceActionCreators from '_store/_actions/invoices';
import invoiceSelectors from '_store/_selectors/invoices';
import companySelectors from '_store/_selectors/company';

import InvoiceUpload from '_components/_shared/InvoiceUpload/InvoiceUpload';

const mapStateToProps = (state) => ({
  isLoading: invoiceSelectors.isLoadingInvoicesSelector(state),
  activeCompany: companySelectors.activeCompanySelector(state),
});

const mapDispatchToProps = {
  onUploadInvoices: invoiceActionCreators.uploadInvoices,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceUpload);
