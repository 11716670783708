import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import { Formik } from 'formik';

import { PageHeader } from '_components/_core';

import { Filters, Results } from './components';

function EFDContributions({
  isLoading,
  onFetchEFDContributions,
  onClearEFDContributions,
  onDownloadEFD,
  efd,
  activeCompany,
}) {
  useEffect(() => {
    onClearEFDContributions();
  }, [onClearEFDContributions, activeCompany]);

  const handleGenerateEFDContributions = useCallback((values) => {
    const { start_date, end_date } = values;

    onFetchEFDContributions(start_date, end_date);
  }, [onFetchEFDContributions]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader title="EFD Contribuições" description="Pré-visualização e geração do arquivo EFD Contrinuições no período informado." />
      <Formik
        initialValues={{
          start_date: null,
          end_date: null,
        }}
        onSubmit={handleGenerateEFDContributions}
        enableReinitialize
      >
        {({ handleSubmit, values }) => (
          <>
            <Tab.Container defaultActiveKey="month">
              <Row>
                <Col lg={3} md={12}>
                  <Filters
                    isLoading={isLoading}
                    handleSubmit={handleSubmit}
                    onFetchEFDContributions={onFetchEFDContributions}
                  />
                </Col>
                <Col lg={9} md={12}>
                  <Results
                    efd={efd}
                    activeCompany={activeCompany}
                    values={values}
                    isLoading={isLoading}
                    onDownloadEFD={onDownloadEFD}
                  />
                </Col>
              </Row>
            </Tab.Container>
          </>
        )}
      </Formik>
    </Container>
  );
}

EFDContributions.defaultProps = {
  isLoading: false,
  efd: null,
};

EFDContributions.propTypes = {
  isLoading: PropTypes.bool,
  onFetchEFDContributions: PropTypes.func.isRequired,
  onClearEFDContributions: PropTypes.func.isRequired,
  onDownloadEFD: PropTypes.func.isRequired,
  efd: PropTypes.object,
  activeCompany: PropTypes.object,
};

export default EFDContributions;
