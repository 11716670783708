import React from 'react';

import './custom.css';

function CustomStyleLoader() {
  return (
    <div />
  );
}

export default CustomStyleLoader;
