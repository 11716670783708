import styled from 'styled-components';

export const StyledCard = styled.div`
  padding: 30px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid var(--td-border-color);
  border-radius: 6px;

  :hover {
    border: 2px solid var(--accent-color);
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  svg {
    width: 50px;
    height: auto;
  }
`;

export const StyledTitle = styled.h1`
  font-size: 30px;
`;

export const StyledSubtitle = styled.h5`
  margin-top: 16px; 
  font-weight: 400;
`;

export const StyledSvg = styled.div`
  :hover {
    fill: var(--td-border-color);
  }
`;

export const CardContent = styled.div`
  text-align: left;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h4 {
    font-size: 16px;
    font-weight: 700;
    color: black;
  }
  
  p {
    line-height: 18px;
    font-size: 14px;
    font-weight: 400;
    color: black;
    margin-bottom: 0;
  }
`;

export const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  grid-row-gap: 16px;
  margin-top: 32px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
`;
