import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { IoCopy, IoCopyOutline } from 'react-icons/io5';

import { Container } from './styles';

const CopyToClipboard = ({ text, emptyText, hoverOnly, children }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async e => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await navigator.clipboard.writeText(text);

      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1000);
    } catch {
      setIsCopied(false);
    }
  };

  if (!text || isEmpty(text)) return emptyText;

  return (
    <Container
      role="button"
      onClick={handleCopy}
      hoverOnly={hoverOnly}
      className="d-flex align-items-center"
    >
      <span className="mr-2">{children}</span>
      {isCopied ? (
        <IoCopy size="1em" className="text-success" />
      ) : (
        <IoCopyOutline size="1em" className="text-muted" />
      )}
    </Container>
  );
};

CopyToClipboard.defaultProps = {
  text: '',
  emptyText: '',
  children: null,
  hoverOnly: false,
};

CopyToClipboard.propTypes = {
  text: PropTypes.string,
  emptyText: PropTypes.string,
  children: PropTypes.node,
  hoverOnly: PropTypes.bool,
};

export default CopyToClipboard;
