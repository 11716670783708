import styled from 'styled-components';

export const Container = styled.thead``;

export const HeaderRow = styled.tr``;

export const HeaderColumn = styled.th`
  text-align: ${(props) => (props.textCenter ? 'center' : 'left')};
  padding-left: ${(props) => (props.paddingLeft ? '15px' : '0px')} !important;
  border-bottom: none !important;
`;
