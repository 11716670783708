import { DateTime } from 'luxon';

import constants from '../../_constants/company-transactions.constants';

import fetchAllAccounts from './fetchAllAccounts';
import fetchBankAccountBalance from './fetchBankAccountBalance';
import fetchCashflow from './fetchCashflow';
import fetchAllCategories from './fetchAllCategories';
import fetchAllRecipients from './fetchAllRecipients';
import fetchAllCostsCenter from './fetchAllCostsCenter';
import fetchAllTags from './fetchAllTags';
import getUserPreferences from '../user/getUserPreferences';

const loadTransactionsPageData = (selectedDate = null) => {
  return async (dispatch) => {
    dispatch(request());

    try {
      const today = selectedDate || DateTime.local();

      dispatch(fetchAllAccounts((accounts) => {
        dispatch(getUserPreferences());
        dispatch(fetchAllCategories());
        dispatch(fetchAllRecipients());

        dispatch(fetchBankAccountBalance({
          account_ids: accounts.map((account) => account.id),
        }));
        dispatch(fetchCashflow({
          year: today.year,
          month: today.month,
          account_ids: accounts.map((account) => account.id),
        }));
        dispatch(fetchAllCostsCenter());
        dispatch(fetchAllTags());
        dispatch(success());
      }));
    } catch (error) {
      dispatch(failure(error));
    }
  };

  function request(id) {
    return {
      type: constants.LOAD_TRANSACTIONS_PAGE_DATA_REQUEST,
      payload: {
        id,
      },
    };
  }
  function success(response) {
    return {
      type: constants.LOAD_TRANSACTIONS_PAGE_DATA_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.LOAD_TRANSACTIONS_PAGE_DATA_FAILURE,
      payload: error,
    };
  }
};

export default loadTransactionsPageData;
