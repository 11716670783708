import React from 'react';
import each from 'lodash/each';
import isObject from 'lodash/isObject';
import {
  IoHome,
  IoHomeOutline,
  IoFlashSharp,
  IoFlashOutline,
  IoCalculator,
  IoCalculatorOutline,
  IoReceipt,
  IoReceiptOutline,
  IoPeople,
  IoPeopleOutline,
  IoBriefcase,
  IoBriefcaseOutline,
  IoSettings,
  IoSettingsOutline,
  IoFileTray,
  IoFileTrayOutline,
  IoPieChart,
  IoPieChartOutline,
  IoSearch,
  IoSearchOutline,
} from 'react-icons/io5';
import { HiArrowsRightLeft } from 'react-icons/hi2';
import { BsPeople, BsPeopleFill } from 'react-icons/bs';

import {
  FcComboChart,
  FcConferenceCall,
  FcHome,
  FcCalculator,
  FcUpload,
} from 'react-icons/fc';

import handleSidebarOutsideClick from './handleSidebarOutsideClick';

export const SIDEBAR_CONTABILLS_MENU_ITEMS = [
  {
    key: 1,
    title: 'Dashboard',
    path: '/dashboard',
    getIcon: (isActive) => {
      if (isActive) return <IoHome size="1.5em" />;

      return <IoHomeOutline size="1.5em" />;
    },
    level: 1,
    items: null,
  },
  {
    key: 2,
    title: 'Contábil',
    path: null,
    getIcon: (isActive) => {
      if (isActive) return <IoCalculator size="1.5em" />;

      return <IoCalculatorOutline size="1.5em" />;
    },
    level: 1,
    items: [
      {
        title: 'Lançamentos',
        path: null,
        getIcon: () => null,
        level: 2,
        items: [
          {
            title: 'Novo',
            path: '/contabil/lancamentos/cadastrar',
            getIcon: () => null,
            level: 3,
            items: null,
          },
          {
            title: 'Consultar',
            path: '/contabil/lancamentos/consultar',
            getIcon: () => null,
            level: 3,
            items: null,
          },
        ],
      },
      {
        key: 3,
        title: 'Extrato Bancário',
        path: '/contabil/extrato-bancario/importar',
        getIcon: () => null,
        level: 2,
        items: null,
      },
      {
        title: 'Relatórios',
        path: null,
        getIcon: () => null,
        level: 2,
        items: [
          {
            title: 'Razão',
            path: '/contabil/relatorios/razao',
            getIcon: () => null,
            level: 3,
            items: null,
          },
          {
            title: 'Balancete',
            path: '/contabil/relatorios/balancete',
            getIcon: () => null,
            level: 3,
            items: null,
          },
          {
            title: 'DRE',
            path: '/contabil/relatorios/dre',
            getIcon: () => null,
            level: 3,
            items: null,
          },
        ],
      },
      {
        key: 3,
        title: 'Plano de Contas',
        path: '/contabil/plano-contas',
        getIcon: () => null,
        level: 2,
        items: null,
      },
    ],
  },
  {
    key: 3,
    title: 'Fiscal',
    path: null,
    getIcon: (isActive) => {
      if (isActive) return <IoReceipt size="1.5em" />;

      return <IoReceiptOutline size="1.5em" />;
    },
    level: 1,
    items: [
      {
        title: 'Visão Geral',
        path: '/fiscal/visao-geral',
        getIcon: () => null,
        level: 2,
        items: null,
      },
      {
        key: 5,
        title: 'Notas Fiscais',
        path: null,
        getIcon: () => null,
        level: 2,
        items: [
          {
            key: 6,
            title: 'Consultar',
            path: '/fiscal/notas-fiscais/consultar',
            getIcon: () => null,
            level: 3,
            items: null,
          },
          {
            key: 6,
            title: 'Emitir',
            path: '/fiscal/notas-fiscais/emitir',
            getIcon: () => null,
            level: 3,
            items: null,
          },
          {
            title: 'Importar',
            path: '/fiscal/notas-fiscais/importar',
            getIcon: () => null,
            level: 3,
            items: null,
          },
        ],
      },
      {
        title: 'Impostos',
        path: '/fiscal/impostos',
        getIcon: () => null,
        level: 2,
        items: null,
      },
      {
        title: 'Declarações',
        path: null,
        getIcon: () => null,
        level: 2,
        items: [
          {
            title: 'EFD Contribuições',
            path: '/fiscal/declaracoes/efd-contribuicoes',
            getIcon: () => null,
            level: 3,
            items: null,
          },
        ],
      },
      {
        title: 'Relatórios',
        path: null,
        getIcon: () => null,
        level: 2,
        items: [
          {
            title: 'Faturamento',
            path: '/fiscal/relatorios/faturamento',
            getIcon: () => null,
            level: 3,
            items: null,
          },
          {
            title: 'Notas Fiscais',
            path: '/fiscal/relatorios/notas-fiscais',
            getIcon: () => null,
            level: 3,
            items: null,
          },
          {
            title: 'ISS',
            path: '/fiscal/relatorios/iss',
            getIcon: () => null,
            level: 3,
            items: null,
          },
        ],
      },
    ],
  },
  {
    key: 4,
    title: 'Folha',
    path: null,
    getIcon: (isActive) => {
      if (isActive) return <IoPeople size="1.5em" />;

      return <IoPeopleOutline size="1.5em" />;
    },
    level: 1,
    items: [
      {
        title: 'Visão Geral',
        path: '/folha/visao-geral',
        getIcon: () => null,
        level: 2,
        items: null,
      },
      {
        title: 'Folha de Pagamento',
        path: '/folha/folha-de-pagamento',
        getIcon: () => null,
        level: 2,
        items: null,
      },
      {
        title: 'Funcionários',
        path: '/folha/funcionarios',
        getIcon: () => null,
        level: 2,
        items: null,
      },
      {
        title: 'Referências',
        path: '/folha/referencias',
        getIcon: () => null,
        level: 2,
        items: null,
      },
      {
        title: 'Contratos',
        path: null,
        getIcon: () => null,
        level: 2,
        items: [
          {
            title: 'Contrato de Trabalho',
            path: '/folha/contratos/contrato-de-trabalho',
            getIcon: () => null,
            level: 3,
            items: null,
          },
        ],
      },
    ],
  },
  {
    title: 'Utilitários',
    path: null,
    getIcon: (isActive) => {
      if (isActive) return <IoFileTray size="1.5em" />;

      return <IoFileTrayOutline size="1.5em" />;
    },
    level: 1,
    items: [
      {
        title: 'Emissão de Guias',
        path: '/utilitarios/emissao-de-guias',
        getIcon: () => null,
        level: 2,
        items: null,
      },
    ],
  },
];

export const SIDEBAR_CONTABILLS_FOOTER_ITEMS = [
  {
    key: 1,
    title: 'Empresas',
    path: '/empresas',
    getIcon: (isActive) => {
      if (isActive) return <IoBriefcase size="1.5em" />;

      return <IoBriefcaseOutline size="1.5em" />;
    },
    level: 1,
    items: null,
  },
  {
    key: 2,
    title: 'Automações',
    path: '/automacoes',
    getIcon: (isActive) => {
      if (isActive) return <IoFlashSharp size="1.5em" />;

      return <IoFlashOutline size="1.5em" />;
    },
    level: 1,
    items: null,
  },
  {
    key: 3,
    title: 'Configurações',
    path: '/configuracoes',
    getIcon: (isActive) => {
      if (isActive) return <IoSettings size="1.5em" />;

      return <IoSettingsOutline size="1.5em" />;
    },
    level: 1,
    items: null,
  },
];

export const SIDEBAR_CONTABILLS_EMPRESAS_MENU_ITEMS = [
  {
    key: 1,
    title: 'Dashboard',
    path: '/dashboard',
    getIcon: (isActive) => {
      if (isActive) return <FcHome size="1.5em" />;

      return <FcHome size="1.5em" />;
    },
    level: 1,
    items: null,
  },
  {
    key: 2,
    title: 'Transações',
    path: '/transacoes',
    getIcon: (isActive) => {
      if (isActive) return <FcCalculator size="1.5em" />;

      return <FcCalculator size="1.5em" />;
    },
    level: 1,
    items: null,
  },
  {
    key: 3,
    title: 'Relatórios',
    path: '/relatorios',
    getIcon: (isActive) => {
      if (isActive) return <FcComboChart size="1.5em" />;

      return <FcComboChart size="1.5em" />;
    },
    level: 1,
    items: null,
  },
  {
    key: 4,
    title: 'Contatos',
    path: '/contatos',
    getIcon: (isActive) => {
      if (isActive) return <FcConferenceCall size="1.5em" />;

      return <FcConferenceCall size="1.5em" />;
    },
    level: 1,
    items: null,
  },
  /* {
    title: 'MEI',
    path: '/mei',
    getIcon: (isActive) => {
      if (isActive) return <FcBusinessman size="1.5em" />;

      return <FcBusinessman size="1.5em" />;
    },
    level: 1,
    items: null,
  }, */
];

export const SIDEBAR_CONTABILLS_EMPRESAS_FOOTER_ITEMS = [
  {
    key: 1,
    title: 'Importar',
    path: '/importar',
    getIcon: (isActive) => {
      if (isActive) return <FcUpload size="1.5em" />;

      return <FcUpload size="1.5em" />;
    },
    level: 1,
    items: null,
  },
];

export const TABBAR_CONTABILLS_EMPRESAS_MENU_ITEMS = [
  {
    key: 1,
    title: 'Dashboard',
    path: '/dashboard',
    getIcon: (isActive) => {
      if (isActive) return <IoHome color="#00ab6f" size="1.3em" />;

      return <IoHomeOutline color="#919199" size="1.3em" />;
    },
    level: 1,
    items: null,
  },
  {
    key: 2,
    title: 'Contatos',
    path: '/contatos',
    getIcon: (isActive) => {
      if (isActive) return <BsPeopleFill color="#00ab6f" size="1.3em" />;

      return <BsPeople color="#919199" size="1.3em" />;
    },
    level: 1,
    items: null,
  },
  {
    key: 3,
    title: 'Transações',
    path: '/transacoes',
    getIcon: (isActive) => {
      if (isActive) return <HiArrowsRightLeft color="#00ab6f" size="1.3em" />;

      return <HiArrowsRightLeft color="#919199" size="1.3em" />;
    },
    level: 1,
    items: null,
  },
  {
    key: 4,
    title: 'Relatórios',
    path: '/relatorios',
    getIcon: (isActive) => {
      if (isActive) return <IoPieChart color="#00ab6f" size="1.3em" />;

      return <IoPieChartOutline color="#919199" size="1.3em" />;
    },
    level: 1,
    items: null,
  },

  {
    key: 5,
    title: 'Busca',
    path: '/busca',
    getIcon: (isActive) => {
      if (isActive) return <IoSearch color="#00ab6f" size="1.5em" />;

      return <IoSearchOutline color="#919199" size="1.5em" />;
    },
    level: 1,
    items: null,
  },
];

export const getPropertyRecursive = (obj, property) => {
  let values = [];

  each(obj, (value, key) => {
    if (key === property) {
      values.push(value);
    } else if (isObject(value)) {
      values = values.concat(getPropertyRecursive(value, property));
    }
  });

  return values;
};

export {
  handleSidebarOutsideClick,
};
