import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActions from '../alert.actions';

const moveTransactions = (params, callback) => {
  return async (dispatch) => {
    dispatch(request(params));

    const payload = {
      ids: params.ids,
      type: params.type,
      sub_type: params.sub_type,
    };

    service.moveTransactions(payload).then((response) => {
      const successPayload = {
        transactions: response.data,
        type: params.type,
        sub_type: params.sub_type,
        ids: params.ids,
        sourceType: params.sourceType,
        sourceSubType: params.sourceSubType,
        isMobile: params.isMobile,
      };

      if (callback) {
        callback();
      }

      dispatch(success(successPayload));

      const message = params.ids.length > 1 ? 'Transações movidas com sucesso!' : 'Transação movida com sucesso!';

      dispatch(alertActions.success(message));
    }).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(params) {
    return {
      type: constants.MOVE_TRANSACTIONS_REQUEST,
      payload: {
        params,
      },
    };
  }

  function success({
    transactions,
    type,
    sub_type,
    ids,
    sourceType,
    sourceSubType,
    isMobile,
  }) {
    return {
      type: constants.MOVE_TRANSACTIONS_SUCCESS,
      payload: {
        transactions,
        type,
        sub_type,
        ids,
        sourceType,
        sourceSubType,
        isMobile,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.MOVE_TRANSACTIONS_FAILURE,
      payload: error,
    };
  }
};

export default moveTransactions;
