const TYPE_COLORS = {
  INCOME_TEXT: '000000',
  INCOME_BACKGROUND: 'b4facd',
  EXPENSE_TEXT: '000000',
  EXPENSE_BACKGROUND: 'ffbbb8',
  TRANSFER_TEXT: '000000',
  TRANSFER_BACKGROUND: 'bacdf5',
  HEADER: 'DBDCDD',
  INCOME_TEXT_cashflow_statement: '007A47',
  EXPENSE_TEXT_cashflow_statement: 'B71C1C',
  TRANSFER_TEXT_cashflow_statement: 'bacdf5',
};

const CELL_TYPES = {
  INCOME: {
    fill: {
      patternType: 'solid',
      fgColor: {
        rgb: TYPE_COLORS.INCOME_BACKGROUND,
      },
      bgColor: {
        rgb: 'ffffff',
      },
    },
    font: {
      color: {
        rgb: TYPE_COLORS.INCOME_TEXT,
      },
    },
    border: {
      bottom: { style: 'thin', color: { auto: 1 } },
      top: { style: 'thin', color: { auto: 1 } },
      left: { style: 'thin', color: { auto: 1 } },
      right: { style: 'thin', color: { auto: 1 } },
    },
  },
  EXPENSE: {
    fill: {
      fgColor: {
        rgb: TYPE_COLORS.EXPENSE_BACKGROUND,
      },
    },
    font: {
      color: {
        rgb: TYPE_COLORS.EXPENSE_TEXT,
      },
    },
    border: {
      bottom: { style: 'thin', color: { auto: 1 } },
      top: { style: 'thin', color: { auto: 1 } },
      left: { style: 'thin', color: { auto: 1 } },
      right: { style: 'thin', color: { auto: 1 } },
    },
  },
  TRANSFER: {
    fill: {
      fgColor: {
        rgb: TYPE_COLORS.TRANSFER_BACKGROUND,
      },
    },
    font: {
      color: {
        rgb: TYPE_COLORS.TRANSFER_TEXT,
      },
    },
    border: {
      bottom: { style: 'thin', color: { auto: 1 } },
      top: { style: 'thin', color: { auto: 1 } },
      left: { style: 'thin', color: { auto: 1 } },
      right: { style: 'thin', color: { auto: 1 } },
    },
  },
  HEADERS: {
    fill: { fgColor: { rgb: TYPE_COLORS.HEADER } },
    font: {
      bold: true,
      color: { rgb: '000000' },
    },
    border: {
      bottom: { style: 'thin', color: { auto: 1 } },
      top: { style: 'thin', color: { auto: 1 } },
      left: { style: 'thin', color: { auto: 1 } },
      right: { style: 'thin', color: { auto: 1 } },
    },
  },
  INCOME_cashflow_statement: {
    font: {
      color: {
        rgb: TYPE_COLORS.INCOME_TEXT_cashflow_statement,
      },
      bold: true,
    },
  },
  EXPENSE_cashflow_statement: {
    font: {
      color: {
        rgb: TYPE_COLORS.EXPENSE_TEXT_cashflow_statement,
      },
      bold: true,
    },
  },
  TRANSFER_cashflow_statement: {
    font: {
      color: {
        rgb: TYPE_COLORS.TRANSFER_TEXT,
      },
      bold: true,
    },
  },
};

export {
  TYPE_COLORS,
  CELL_TYPES,
};
