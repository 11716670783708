import { SPECIAL_COLUMNS } from '_components/_core/Table';
import FORMATTERS from 'helpers/formatters';

import AccountantSchema from './AccountantSchema';

const accountantFormFields = {
  id: null,
  name: '',
  email: '',
  document_number: '',
  phone_number: '',
  address_street: '',
  address_number: '',
  address_complement: '',
  address_district: '',
  address_state: '',
  address_state_ibge: '',
  address_city: '',
  address_city_ibge: '',
  address_zip_code: '',
  document_type: 'CPF',
};

export const DEFAULT_COLUMNS = [
  'name',
  'email',
  'phone_number',
  'document_number',
  'id',
];

export const TABLE_COLUMNS = [
  {
    label: 'Ações',
    keyName: 'id',
    align: 'center',
    width: 5,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'CPF/CNPJ',
    keyName: 'document_number',
    sortingKey: 'document_number',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.CPF_CNPJ,
    order: 2,
  },
  {
    label: 'Nome/Razão Social',
    keyName: 'name',
    sortingKey: 'name',
    width: 65,
    formatter: FORMATTERS.UPPERCASE,
    order: 3,
  },
  {
    label: 'Telefone',
    keyName: 'phone_number',
    sortingKey: 'phone_number',
    align: 'center',
    width: 10,
    order: 4,
  },
  {
    label: 'E-mail',
    keyName: 'email',
    sortingKey: 'email',
    align: 'center',
    width: 10,
    order: 5,
  },
];

export { accountantFormFields, AccountantSchema };
