import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';

const getCompanyById = (id, callback) => {
  return (dispatch) => {
    dispatch(request(id));

    service.getById(id).then(
      (response) => {
        dispatch(success(response.data));

        if (callback) {
          callback(response.data);
        }
      },
    );
  };

  function request(id) {
    return { type: constants.COMPANY_GET_REQUEST, payload: { id } };
  }
  function success(company) {
    return {
      type: constants.COMPANY_GET_SUCCESS,
      payload: { company },
    };
  }
};

export default getCompanyById;
