import fetchCities from './fetchCities';
import fetchStates from './fetchStates';
import fetchCep from './fetchCep';
import fetchCnae from './fetchCnae';

export default {
  fetchCities,
  fetchStates,
  fetchCep,
  fetchCnae,
};
