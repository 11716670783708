import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import BankStatementUpload from './BankStatementUpload';

const mapStateToProps = (state) => ({
  isLoading: state.companyTransactions.isLoading,
  accounts: state.companyTransactions.accounts,
});

const mapDispatchToProps = {
  onParseBankStatements: companyTransactionsActionCreators.uploadBankStatementTransactions,
  onFetchAccounts: companyTransactionsActionCreators.fetchAllAccounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankStatementUpload);
