import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import StyledFormCheck from '../StyledFormCheck';
import styles from '../../styles.module.scss';

function Header({
  visibleColumns,
  sorting,
  onItemSelected,
  onSorting,
  selectedItems,
  onSelectAll,
  data,
}) {
  const [sortingField, setSortingField] = useState(() => {
    if (!sorting) {
      return '';
    }

    return sorting.field;
  });
  const [sortingOrder, setSortingOrder] = useState(() => {
    if (!sorting) {
      return 'asc';
    }

    return sorting.order;
  });

  const handleSortingChange = useCallback((keyName) => {
    const order = keyName === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc';

    setSortingOrder(order);
    setSortingField(keyName);

    onSorting(keyName, order);
  }, [sortingField, sortingOrder, onSorting]);

  const SortingIcon = useMemo(() => {
    if (sortingOrder === 'asc') {
      return IoChevronUpOutline;
    }

    return IoChevronDownOutline;
  }, [sortingOrder]);

  const getColumnLabel = useCallback((column) => {
    if (column.customLabel && !isEmpty(data)) {
      return column.customLabel(data[0]);
    }

    return column.label;
  }, [data]);

  const DIRECTION = {
    left: 'justify-content-start',
    center: 'justify-content-center',
    right: 'justify-content-end',
  };

  return (
    <thead>
      <tr>
        {onItemSelected && (
          <th width="2%" className={classNames(styles.headerCell, styles.noPrint)}>
            <StyledFormCheck
              type="checkbox"
              checked={!isEmpty(data) && selectedItems.length === data.length}
              onClick={onSelectAll}
            />
          </th>
        )}
        {visibleColumns.map((column, index) => (
          <th
            key={`th-${index}`}
            align={column.align}
            width={`${column.width}%`}
            style={{
              textAlign: column.align,
              cursor: column.sortingKey ? 'pointer' : 'normal',
            }}
            className={styles.headerCell}
            onClick={() => (column.sortingKey ? handleSortingChange(column.keyName) : null)}
          >
            <span className={
              classNames('d-flex', DIRECTION[column.align])
            }
            >
              {getColumnLabel(column)}
              {sortingField && sortingField === column.keyName && (
                <SortingIcon
                  size="1rem"
                  color="#727cf5"
                  style={{
                    position: 'relative',
                    top: '-1px',
                    right: '-5px',
                  }}
                />
              )}
            </span>
          </th>
        ))}
      </tr>
    </thead>
  );
}

Header.defaultProps = {
  sorting: null,
  onItemSelected: null,
  onSorting: null,
  onSelectAll: null,
  selectedItems: [],
  data: [],
};

Header.propTypes = {
  sorting: PropTypes.object,
  selectedItems: PropTypes.array,
  onItemSelected: PropTypes.func,
  visibleColumns: PropTypes.array.isRequired,
  onSorting: PropTypes.func,
  onSelectAll: PropTypes.func,
  data: PropTypes.array,
};

export default Header;
