import { Table, TableLookup } from '_components/_core';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { CustomSelectField } from '../BankStatement/Reconcile/components';

export const StyledTable = styled(Table)`  
  &&& {
    thead tr th {
      vertical-align: middle;
      padding: 16px 8px !important;

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #d5dcec;
        box-shadow: inset 0px 25px 1px -10px #d5dcec;
      } 
    }

    tbody tr td {
      height: 26px;
      line-height: 18px;
      padding: 9px !important;
      vertical-align: middle;
      border-top: 1px solid #D4D7DC;
      cursor: pointer;
    }

    tbody tr.active td {
      background: #eceef1 !important;
    }

    tbody tr td span.no-description {
      font-size: 12px;
      color: #babec5;
      font-style: italic;
    }
  }
`;

export const StyledSearchInput = styled(Form.Control)`
  border: none;
  box-shadow: none;
  outline: none;
  margin: 0;
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  border-radius: 8px;

  &:focus, &:active, &:hover {
    background-color: transparent;
  }
`;

export const StyledTableLookup = styled(TableLookup)`
  &&& {
    thead tr th {
      font-size: 13px;
      line-height: 13px;
      font-weight: bold;
      padding: 15px 8px !important;
      position: sticky; 
      top: 0; 
      z-index: 1;
      background-color: var(--th-bg-color);
      vertical-align: middle;

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #d5dcec;
        box-shadow: inset 0px 25px 1px -10px #d5dcec;
      }
    }

    tbody tr td {
      font-size: 13px;
      line-height: 13px;
      font-weight: 400;
      padding: 12px 8px;
      border-top: 1px solid #D4D7DC;
      height: 50px;
    }
  }
`;

export const StyledTransactionRow = styled.tr`
  td {
    cursor: pointer;
  }
`;

export const TypeSelect = styled(CustomSelectField)`
  width: 200px;
  margin-right: 12px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }  
`;

export const CategorySelect = styled(CustomSelectField)`
  width: 200px;
  margin-right: 12px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-top: 8px;
  }
`;

export const RecipientSelect = styled(CustomSelectField)`
  width: 220px;
  margin-right: 12px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-top: 8px;
  }
`;

export const ActionBar = styled.div`  
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 22px;
  padding-right: 24px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 8px;
  position: sticky;
  top: 80px;
  z-index: 980;

  p {
    font-size: 1.2em;
    font-weight: 500;
    margin: 0;
    padding: 0;

  }
`;

export const ResultsBar = styled.div`
  height: 60px;
  background-color: #fff;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;

  p.items-count {
    font-size: 1.2em;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 1.2em;
  }
`;

export const SumTransfersDialog = styled.div`
  display: flex;
  
  svg {
    cursor: pointer;
  }
`;

export const FiltersCount = styled.span`
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: red;
  
`;

export const StyledFormCheck = styled(Form.Check)`
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.8em;

  .form-check-input {
    margin-top: 0;
    margin-left: 0;
    width: 17px !important;
    height: 17px !important;
    z-index: 1;

    &:hover {
      cursor: pointer !important;
    }

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      bottom: -10px;
      left: -10px;
      right: -10px;
      background: transparent;
      z-index: 0;
    }
  }
`;
