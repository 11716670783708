import constants from '../../_constants/employees.constants';
import service from '../../../services/employees.service';
import alertActionCreators from '../alert.actions';

import getEmployeeSalaries from './getEmployeeSalaries';

const addEmployeeSalary = (employeeId, salary) => {
  return (dispatch) => {
    dispatch(request(salary));

    service.addSalary(employeeId, salary).then(
      (response) => {
        const createdSalary = response.data;

        dispatch(success(createdSalary));
        dispatch(alertActionCreators.success('Salário cadastrado com sucesso!'));
        dispatch(getEmployeeSalaries(employeeId));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(salary) {
    return {
      type: constants.EMPLOYEE_SALARIES_ADD_REQUEST,
      payload: { salary },
    };
  }
  function success(salary) {
    return {
      type: constants.EMPLOYEE_SALARIES_ADD_SUCCESS,
      payload: { salary },
    };
  }
  function failure(error) {
    return {
      type: constants.EMPLOYEE_SALARIES_ADD_FAILURE,
      payload: error,
    };
  }
};

export default addEmployeeSalary;
