import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/fiscal.constants';
import service from '../../../services/fiscal.service';
import alertActionCreators from '../alert.actions';

import getAllPersons from './getAllPersons';

const deletePerson = (companyId, personId) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Excluir Cliente',
      message: 'Tem certeza que deseja excluir o Cliente? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, deletar!',
      icon: 'question',
      onConfirm: () => {
        dispatch(request(companyId, personId));

        service.deletePerson(companyId, personId).then(
          () => {
            dispatch(success(companyId));
            dispatch(alertActionCreators.success('Cliente excluído com sucesso!'));
            dispatch(getAllPersons(companyId));
          },
        ).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request() {
    return {
      type: constants.PERSON_DELETE_REQUEST,
      payload: { },
    };
  }
  function success(id) {
    return {
      type: constants.PERSON_DELETE_SUCCESS,
      payload: id,
    };
  }
  function failure(error) {
    return {
      type: constants.PERSON_DELETE_FAILURE,
      payload: error,
    };
  }
};

export default deletePerson;
