import FORMATTERS from 'helpers/formatters';
import { SPECIAL_COLUMNS } from '_components/_core/Table';

export const DEFAULT_COLUMNS = [
  '_id',
  'competence',
  'tax_name',
  'status',
  'due_date',
  'valor_principal',
  'valor_multa',
  'valor_juros',
  'valor_total',
  'url',
  'consolidation_date',
  'created_at',
];

export const TABLE_COLUMNS = [
  {
    label: 'Ações',
    keyName: '_id',
    align: 'center',
    width: 10,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'Data de Criação',
    keyName: 'created_at',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.DATE_DDMMYYYYHHMMSS,
    order: 9,
  },
  {
    label: 'Tributo',
    keyName: 'tax_name',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.TAX_NAME,
    order: 2,
  },
  {
    label: 'Competência',
    keyName: 'competence',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.DATE_MMYYYY,
    order: 3,
  },
  {
    label: 'Data Vencimento',
    keyName: 'due_date',
    align: 'center',
    formatter: FORMATTERS.DATE_DDMMYYYY,
    width: 10,
    order: 4,
  },
  {
    label: 'Valor Principal',
    keyName: 'valor_principal',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 5,
    sumOnFooter: true,
  },
  {
    label: 'Multa',
    keyName: 'valor_multa',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 6,
    sumOnFooter: true,
  },
  {
    label: 'Juros',
    keyName: 'valor_juros',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 7,
    sumOnFooter: true,
  },
  {
    label: 'Valor Total',
    keyName: 'valor_total',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 8,
    sumOnFooter: true,
  },
  {
    label: 'Data de Consolidação',
    keyName: 'consolidation_date',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.DATE_DDMMYYYY,
    order: 9,
  },
  {
    label: 'DARF',
    keyName: 'url',
    align: 'center',
    formatter: FORMATTERS.DOWNLOAD_LINK,
    width: 10,
    order: 10,
  },
];
