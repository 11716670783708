import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';

import { Button } from '_components/_core';

export const StyledDropdown = styled(Dropdown)`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1004;

  .dropdown-menu {
    padding-bottom: 0px !important;
  }

  @media print {
    display: none;
  }
`;

export const StyledButton = styled(Button)`
  padding: 8px 6px;
  border-radius: 4px;
  border-color: #d5dcec;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;

  :after {
    display: none !important;
  }
`;

export const StyledSaveButton = styled(Button)`
  margin-top: 8px;
  border-top: 1px solid #d5dcec;
  width: 100%;
  padding: 12px;

  &:hover {
    border-top: 1px solid #d5dcec;
  }
`;
