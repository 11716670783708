import { taxesConstants } from '../_constants';

const INITIAL_STATE = {
  isLoading: false,
  calculatedTaxes: [],
  taxes: [],
  darfs: [],
};

export function taxes(state = INITIAL_STATE, action) {
  switch (action.type) {
    case taxesConstants.CALCULATE_TAXES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case taxesConstants.CALCULATE_TAXES_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        calculatedTaxes: data,
        isLoading: false,
      };
    }
    case taxesConstants.CALCULATE_TAXES_FAILURE: {
      return {
        ...state,
        calculatedTaxes: [],
        isLoading: false,
      };
    }
    case taxesConstants.FETCH_TAXES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case taxesConstants.FETCH_TAXES_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        taxes: data,
        isLoading: false,
      };
    }
    case taxesConstants.FETCH_TAXES_FAILURE: {
      return {
        ...state,
        taxes: [],
        isLoading: false,
      };
    }
    case taxesConstants.GENERATE_TAXES_ENTRIES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case taxesConstants.GENERATE_TAXES_ENTRIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case taxesConstants.GENERATE_TAXES_ENTRIES_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case taxesConstants.FETCH_DARFS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case taxesConstants.FETCH_DARFS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        darfs: data,
        isLoading: false,
      };
    }
    case taxesConstants.FETCH_DARFS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case taxesConstants.FETCH_MANAGER_DARFS_REQUEST: {
      const { hideLoading } = action.payload;

      return {
        ...state,
        isLoading: !hideLoading,
      };
    }
    case taxesConstants.FETCH_MANAGER_DARFS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        manager_darfs: data,
        isLoading: false,
      };
    }
    case taxesConstants.FETCH_MANAGER_DARFS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case taxesConstants.GENERATE_DARFS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case taxesConstants.GENERATE_DARFS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case taxesConstants.GENERATE_DARFS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case taxesConstants.DELETE_DARF_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case taxesConstants.DELETE_DARF_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case taxesConstants.DELETE_DARF_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case taxesConstants.DELETE_MULTIPLE_DARFS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case taxesConstants.DELETE_MULTIPLE_DARFS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case taxesConstants.DELETE_MULTIPLE_DARFS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
}
