import React from 'react';
import PropTypes from 'prop-types';
import { IoArrowBack, IoArrowForward } from 'react-icons/io5';

import { Button } from '_components/_core';

function FormFooter({
  onSubmit,
  onChange,
  isLoading,
  isValid,
}) {
  return (
    <div className="d-flex justify-content-between pt-4 pb-4">
      <Button
        variant="inverse-dark"
        disabled={isLoading}
        onClick={() => onChange(null)}
        icon={<IoArrowBack size="1.3rem" />}
        iconPosition="left"
      >
        Voltar
      </Button>
      <Button
        className="ml-2 btn btn-lg"
        variant="dark"
        isLoading={isLoading}
        disabled={!isValid || isLoading}
        onClick={() => onSubmit()}
        icon={<IoArrowForward size="1.3rem" />}
      >
        Salvar Empresa
      </Button>
    </div>
  );
}

FormFooter.defaultProps = {
  isLoading: false,
  isValid: false,
};

FormFooter.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.func,
  isValid: PropTypes.bool,
};

export default FormFooter;
