import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';

import './custom.css';

const ContextMenu = ({ menuId, children, ...rest }) => (
  <Menu id={menuId} animation="" {...rest}>
    {children}
  </Menu>
);

ContextMenu.propTypes = {
  menuId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ContextMenu;
