import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';
import ibgeActionCreators from '_store/_actions/ibge';
import companySelectors from '_store/_selectors/company';

import Create from './Create';

const mapStateToProps = (state) => ({
  isLoading: companySelectors.isLoadingCompanySelector(state),
  selectedCompany: companySelectors.selectedCompanySelector(state),
  statesReady: state.ibge.statesReady,
});

const mapDispatchToProps = {
  onCreateCompany: companyActionCreators.addCompany,
  onUpdateCompany: companyActionCreators.updateCompany,
  onFetchCompany: companyActionCreators.getCompanyById,
  onFetchCities: ibgeActionCreators.fetchCities,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Create);
