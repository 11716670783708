import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import { useHistory, useLocation } from 'react-router-dom';
import { BsPersonFill } from 'react-icons/bs';
import { MdBusinessCenter } from 'react-icons/md';

import { Button } from '_components/_core';

import { IoArrowBack } from 'react-icons/io5';
import { SignUpForm } from './components';

import {
  SectionHeader,
  Container,
  Card,
  Title,
  Text,
  StyledButton,
} from './styles';

const publicFolder = process.env.PUBLIC_URL;

function ZenpaperForm({
  segments,
  onSignUp,
  onEmailConfirmation,
}) {
  const history = useHistory();
  const location = useLocation();

  const [selectedMenu, setSelectedMenu] = useState(() => {
    const urlParams = new URLSearchParams(location.search);

    const type = urlParams.get('type');

    if (type === 'empresarial') {
      return 'PJ';
    }

    if (type === 'pessoal') {
      return 'PF';
    }

    return null;
  });

  const handleMenuChange = useCallback((option) => {
    setSelectedMenu(option);
  }, [setSelectedMenu]);

  return (
    <Container>
      <Card>
        <div className="w-100 d-flex justify-content-center align-items-center">
          <img
            src={`${publicFolder}/assets/img/logo-zenply-icon.svg`}
            alt="logo"
            width={145}
            className="ml-1 mr-1"
          />
        </div>
        <Title className="mt-4">Crie sua conta</Title>
        <Text>Para utilizar o Zenply, você precisa criar uma conta.</Text>
        {selectedMenu === null && (
        <>
          <SectionHeader className="mt-3 d-flex justify-content-between">
            <span>
              Escolha o tipo de conta
            </span>
            <span>
              {/* Passo 1 de 3 */}
            </span>
          </SectionHeader>
          <hr className="mt-2" />
          <Row className="mt-4 d-flex justify-content-center">
            <StyledButton
              onClick={() => handleMenuChange('PF')}
              iconPosition="left"
              icon={<BsPersonFill size="2em" />}
            >
              <h5 className="pl-3 m-0">Conta Pessoal</h5>
            </StyledButton>
          </Row>
          <Row className="d-flex justify-content-center">
            <StyledButton
              onClick={() => handleMenuChange('PJ')}
              iconPosition="left"
              icon={<MdBusinessCenter size="2em" />}
              className="mt-1"
            >
              <h5 className="pl-3 m-0">Conta Empresarial</h5>
            </StyledButton>
          </Row>
          <div className=" d-flex justify-content-center mt-3">
            <Button className="ml-0 pl-0 d-flex justify-content-center" variant="link" onClick={() => history.push('/entrar')}>
              <IoArrowBack className="mr-3" />
              Voltar para login
            </Button>
          </div>
        </>
        )}
        {selectedMenu && (
          <SignUpForm
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            onSignUp={onSignUp}
            onEmailConfirmation={onEmailConfirmation}
            segments={segments}
          />
        )}
      </Card>
    </Container>

  );
}

ZenpaperForm.defaultProps = {
  segments: [],
};

ZenpaperForm.propTypes = {
  segments: PropTypes.array,
  onSignUp: PropTypes.func.isRequired,
  onEmailConfirmation: PropTypes.func.isRequired,
};

export default ZenpaperForm;
