import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';

const getBlockedPeriod = callback => {
  return dispatch => {
    dispatch(request());

    service.getBlockedPeriod().then(
      response => {
        dispatch(success(response.data));

        if (callback) {
          callback(response.data);
        }
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: constants.ALLOWED_PERIOD_GET_REQUEST, payload: {} };
  }
  function success(allowedPeriod) {
    return {
      type: constants.ALLOWED_PERIOD_GET_SUCCESS,
      payload: allowedPeriod,
    };
  }
  function failure() {
    return { type: constants.ALLOWED_PERIOD_GET_FAILURE, payload: {} };
  }
};

export default getBlockedPeriod;
