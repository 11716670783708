import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Formik } from 'formik';
import { BsToggleOff, BsToggleOn } from 'react-icons/bs';

import {
  FormDateField,
  FormTextField,
  FormCurrencyField,
} from '_components/_core';

// TODO: Mover para shared
import { CustomSelectField } from '_components/Financial/BankStatement/Reconcile/components';

import { Button } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import { truncate } from 'lodash';
import Calculator from '_components/_shared/Calculator/Calculator';
import useTransferForm from '../../utilities/useTransferForm';
import useTransactions from '../../utilities/useTransactions';
import { checkBlockedPeriod } from 'helpers';

const useOutsideClick = (callback) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event) => {
      const isClickOnOption = event.target.id.includes('react-select');
      const isCreatableOption = event.target.id.includes('creatable-option');
      const isDetailButton = event.target.id.includes('details-button');
      const isModalOpen = document.querySelector('.modal.show');
      const isDatePicker = Array.from(event.target.classList).some((c) => c.includes('datepicker'));
      const isPreventClose = document.querySelector('.transaction-form-open');
      const isToggleButton = event.target.id.includes('toggle-payment-button');

      if (ref.current
        && !ref.current.contains(event.target)
        && !isClickOnOption
        && !isModalOpen
        && !isDatePicker
        && !isCreatableOption
        && !isDetailButton
        && !isPreventClose
        && !isToggleButton) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref, callback]);

  return ref;
};

function TransferForm({ selectedDate, accounts, blockedPeriod, transferFormRef }) {
  const [isLoadingTransfer, setIsLoadingTransfer] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { onToggleTransferForm } = useTransferForm();
  const { onCreateTransferTransaction } = useTransactions();

  const handleKeyPress = useCallback((e, handleSubmit) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }, []);

  const handleSubmitTransaction = useCallback((values) => {
    setIsLoadingTransfer(true);

    onCreateTransferTransaction(values, (success) => {
      setIsLoadingTransfer(false);
      setIsSubmitting(false);

      if (success) {
        onToggleTransferForm();
      }
    });
  }, [onCreateTransferTransaction, onToggleTransferForm]);

  const handleClickOutside = useCallback(() => {
    if (transferFormRef.current) {
      const {
        values,
      } = transferFormRef.current;

      const {
        account_id,
        account_id_destination,
      } = values;

      if (!account_id || !account_id_destination) {
        onToggleTransferForm();

        return;
      }

      if (isSubmitting) {
        return;
      }

      transferFormRef.current.handleSubmit();

      setIsSubmitting(true);
    }
  }, [transferFormRef, isSubmitting, onToggleTransferForm]);

  const rowRef = useOutsideClick(handleClickOutside);

  const accounts_options = useMemo(() => {
    const formatted = accounts.map((account) => ({
      value: account.id,
      label: truncate(account.description, {
        length: 31,
        omission: '...',
      }),
    }));

    return formatted;
  }, [accounts]);

  const isDayBlocked = useCallback(datePickerDate => {
    const day = datePickerDate.getDate();
    const month = datePickerDate.getMonth() + 1;
    const year = datePickerDate.getFullYear();

    const luxonDate = DateTime.fromObject({
      day,
      month,
      year,
    }).toFormat('yyyy-MM-dd');

    return checkBlockedPeriod(blockedPeriod, luxonDate);
  }, []);

  const initialValues = useMemo(() => {
    const selectedMonthYear = selectedDate.toFormat('yyyy-MM');
    const currentMonthYear = DateTime.local().toFormat('yyyy-MM');

    let defaultEventDate = DateTime.local().toISODate();

    if (selectedMonthYear !== currentMonthYear) {
      defaultEventDate = selectedDate.startOf('month').toISODate();
    }

    return {
      event_date: defaultEventDate,
      description: '',
      amount: 0,
      paid: false,
      account_id: null,
      account_id_destination: null,
    };
  }, [selectedDate]);

  return (
    <>
      <Formik
        innerRef={transferFormRef}
        initialValues={initialValues}
        onSubmit={handleSubmitTransaction}
        enableReinitialize
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <>
            <tr
              ref={rowRef}
              className="transaction-form-row"
              onKeyPress={(e) => handleKeyPress(e, handleSubmit)}
            >
              <td>
              &nbsp;
              </td>
              <td className="p-0 m-0 event-date-column edit-mode-wrapper">
                <FormDateField
                  name="event_date"
                  placeholder="Data"
                  placement="bottom"
                  inputStyleProps={{
                    display: 'inline-block',
                    height: '32px',
                    padding: '4px 6px',
                    fontSize: '0.75rem',
                    lineHeight: '22px',
                    color: '#393A3D',
                    verticalAlign: 'middle',
                    borderRadius: '3px',
                  }}
                  filterDate={isDayBlocked}
                />
              </td>
              <td className="description-column">
                <FormTextField name="description" placeholder="Descrição" autoComplete="off" autoFocus />
              </td>
              <td className="amount-column">
                <FormCurrencyField
                  name="amount"
                  placeholder="Valor"
                  inputStyleProps={{
                    paddingLeft: '8px',
                  }}
                  forceZero
                />
                <Calculator
                  id="amount-calculator"
                  value={String(values.amount)}
                  onChange={(value) => setFieldValue('amount', value)}
                />
              </td>
              <td className="account_id_origin-column">
                <CustomSelectField
                  isClearable
                  name="account_id"
                  options={accounts_options}
                  creatable="bank_account"
                  loadingMessage={() => 'Carregando...'}
                  placeholder="Selecionar"
                  customStyleName="transactions"
                  menuWidth="170%"
                  onCreateCallback={(crated_account) => {
                    if (crated_account) {
                      setFieldValue('account_id', crated_account.id);
                    }
                  }}
                />
              </td>
              <td className="account_id_destination-column">
                <CustomSelectField
                  isClearable
                  name="account_id_destination"
                  options={accounts_options}
                  creatable="bank_account"
                  loadingMessage={() => 'Carregando...'}
                  placeholder="Selecionar"
                  menuWidth="170%"
                  onCreateCallback={(crated_account) => {
                    if (crated_account) {
                      setFieldValue('account_id_destination', crated_account.id);
                    }
                  }}
                />
              </td>
              <td className="text-center paid-column">
                {!values.paid && (<BsToggleOff size="2.5em" className="text-muted" onClick={() => setFieldValue('paid', true)} />)}
                {values.paid && (<BsToggleOn size="2.5em" className="text-success" onClick={() => setFieldValue('paid', false)} />)}
              </td>
              <td className="action-column">
                <Button
                  noMargin
                  variant="success"
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="p-2"
                  disabled={isLoadingTransfer}
                  isLoading={isLoadingTransfer}
                >
                  <FaCheck />
                </Button>
              </td>
            </tr>
          </>
        )}
      </Formik>
    </>

  );
}

TransferForm.defaultProps = {
  accounts: [],
};

TransferForm.propTypes = {
  selectedDate: PropTypes.object.isRequired,
  accounts: PropTypes.array,
  transferFormRef: PropTypes.object.isRequired,
};

export default TransferForm;
