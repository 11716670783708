import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Tag from '_components/_core/Tag/Tag';
import FORMATTERS from 'helpers/formatters';

function AccountNameTag({ account_id, accounts }) {
  const account_name = useMemo(() => {
    const account = accounts.find((account) => account.id === account_id) || {};

    const { description } = account || {};

    const uppercaseDescription = FORMATTERS.UPPERCASE(description);

    return FORMATTERS.MAX_X_CHARS(uppercaseDescription, 22);
  }, [accounts, account_id]);

  return <Tag variant="primary">{account_name || '-'}</Tag>;
}

AccountNameTag.defaultProps = {
  accounts: [],
};

AccountNameTag.propTypes = {
  account_id: PropTypes.string.isRequired,
  accounts: PropTypes.array,
};

export default AccountNameTag;
