import { Form } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledLabel = styled(Form.Label)`
  font-weight: 600 !important;
  margin-bottom: 0.2em !important;

  @media (max-width: 768px) {    
    color: #A0A0A0 !important;
    font-size: 0.8em !important;    
  }
`;

export const StyledFormGroup = styled(Form.Group)`
  margin-bottom: 1em;

  svg {
    margin-right: 1em !important;
    color: #A0A0A0;
  }
`;

export const StyledContent = styled.div`
  flex: 1;
`;

export default StyledLabel;
