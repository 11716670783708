import { companyConstants, authConstants } from '../_constants';

const INITIAL_STATE = {
  pagination: {},
  companies: [],
  companiesForSelect: [],
  activeCompany: null,
  isLoading: false,
  isLoadingActiveCompany: false,
  isLoadingCompaniesForSelect: false,
  isSearching: false,
  hasErrors: false,
  errors: [],
  searchedCompany: null,
  selectedCompany: null,
  revenue: null,
  blockedPeriod: {},
  isLoadingBlockedPeriod: false,
};

export function company(state = INITIAL_STATE, action) {
  switch (action.type) {
    case authConstants.LOGIN_SUCCESS: {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }
    case companyConstants.COMPANY_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case companyConstants.COMPANY_GET_SUCCESS:
      return {
        ...state,
        companies: action.payload.companies,
        isLoading: false,
      };
    case companyConstants.COMPANY_GET_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasErrors: true,
      };
    case companyConstants.COMPANY_GET_ACTIVE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingActiveCompany: true,
      };
    case companyConstants.COMPANY_GET_ACTIVE_SUCCESS:
      return {
        ...state,
        activeCompany: action.payload.company,
        isLoading: false,
        isLoadingActiveCompany: false,
      };
    case companyConstants.COMPANY_GET_ACTIVE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoadingActiveCompany: false,
        hasErrors: true,
        activeCompany: null,
      };
    case companyConstants.COMPANY_GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case companyConstants.COMPANY_GET_ALL_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        companies: data,
        total,
        isLoading: false,
      };
    }
    case companyConstants.COMPANY_SET_ACTIVE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case companyConstants.COMPANY_SET_ACTIVE_SUCCESS:
      return {
        ...state,
        activeCompany: action.payload.company,
        isLoading: false,
      };
    case companyConstants.COMPANY_SET_ACTIVE_PERIOD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case companyConstants.COMPANY_SET_ACTIVE_PERIOD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case companyConstants.COMPANY_ADD_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyConstants.COMPANY_ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.COMPANY_ADD_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.COMPANY_UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyConstants.COMPANY_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.COMPANY_UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.COMPANY_DELETE_SUCCESS: {
      return {
        ...state,
      };
    }
    case companyConstants.COMPANY_SEARCH_BY_CNPJ_REQUEST: {
      return {
        ...state,
        isSearching: true,
        searchedCompany: null,
      };
    }
    case companyConstants.COMPANY_SEARCH_BY_CNPJ_SUCCESS: {
      const { company } = action.payload;

      return {
        ...state,
        isSearching: false,
        searchedCompany: company,
      };
    }
    case companyConstants.COMPANY_SEARCH_BY_CNPJ_FAILURE: {
      return {
        ...state,
        isSearching: false,
        searchedCompany: null,
      };
    }
    case companyConstants.CLEAR_SEARCHED_COMPANY_REQUEST: {
      return {
        ...state,
        searchedCompany: null,
      };
    }
    case companyConstants.SET_SELECTED_COMPANY_SUCCESS: {
      const { company } = action.payload;

      return {
        ...state,
        selectedCompany: company,
      };
    }
    case companyConstants.ADD_COMPANY_CREDENTIAL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyConstants.ADD_COMPANY_CREDENTIAL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.ADD_COMPANY_CREDENTIAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.DELETE_COMPANY_CREDENTIAL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyConstants.DELETE_COMPANY_CREDENTIAL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.DELETE_COMPANY_CREDENTIAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.UPDATE_COMPANY_CREDENTIAL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyConstants.UPDATE_COMPANY_CREDENTIAL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.UPDATE_COMPANY_CREDENTIAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.ADD_COMPANY_FEE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyConstants.ADD_COMPANY_FEE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.ADD_COMPANY_FEE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.UPDATE_COMPANY_FEE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyConstants.UPDATE_COMPANY_FEE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.UPDATE_COMPANY_FEE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.DELETE_COMPANY_FEE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyConstants.DELETE_COMPANY_FEE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.DELETE_COMPANY_FEE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyConstants.ACTIVATE_INVOICE_ISSUING_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyConstants.ACTIVATE_INVOICE_ISSUING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.ACTIVATE_INVOICE_ISSUING_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyConstants.COMPANY_GET_REVENUE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyConstants.COMPANY_GET_REVENUE_SUCCESS: {
      const revenue = action.payload;

      return {
        ...state,
        revenue,
        isLoading: false,
      };
    }
    case companyConstants.COMPANY_GET_REVENUE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyConstants.COMPANY_ADD_REVENUE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyConstants.COMPANY_ADD_REVENUE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.COMPANY_ADD_REVENUE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyConstants.ADD_COMPANY_DIGITAL_CERTIFICATE_REQUEST: {
      return {
        ...state,
        isLoadingCertificate: true,
      };
    }
    case companyConstants.ADD_COMPANY_DIGITAL_CERTIFICATE_SUCCESS: {
      return {
        ...state,
        isLoadingCertificate: false,
      };
    }
    case companyConstants.ADD_COMPANY_DIGITAL_CERTIFICATE_FAILURE: {
      return {
        ...state,
        isLoadingCertificate: false,
      };
    }

    case companyConstants.DELETE_COMPANY_DIGITAL_CERTIFICATE_REQUEST: {
      return {
        ...state,
        isLoadingCertificate: true,
      };
    }
    case companyConstants.DELETE_COMPANY_DIGITAL_CERTIFICATE_SUCCESS: {
      return {
        ...state,
        isLoadingCertificate: false,
      };
    }
    case companyConstants.DELETE_COMPANY_DIGITAL_CERTIFICATE_FAILURE: {
      return {
        ...state,
        isLoadingCertificate: false,
      };
    }

    case companyConstants.FETCH_COMPANIES_FOR_SELECT_REQUEST: {
      return {
        ...state,
        isLoadingCompaniesForSelect: true,
      };
    }
    case companyConstants.FETCH_COMPANIES_FOR_SELECT_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        companiesForSelect: data,
        isLoadingCompaniesForSelect: false,
      };
    }
    case companyConstants.FETCH_COMPANIES_FOR_SELECT_FAILURE: {
      return {
        ...state,
        isLoadingCompaniesForSelect: false,
      };
    }

    case companyConstants.ADD_COMPANY_AVATAR_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyConstants.ADD_COMPANY_AVATAR_SUCCESS: {
      const { company_id, company_avatar_url } = action.payload;

      const newCompanies = state.companies.map(company => {
        if (company.id === company_id) {
          return {
            ...company,
            company_avatar_url,
          };
        }
        return company;
      });

      let new_company_avatar_url = state.activeCompany.company_avatar_url;

      if (state.activeCompany.id === company_id) {
        new_company_avatar_url = company_avatar_url;
      }

      return {
        ...state,
        companies: newCompanies,
        activeCompany: {
          ...state.activeCompany,
          company_avatar_url: new_company_avatar_url,
        },
        isLoading: false,
      };
    }
    case companyConstants.ADD_COMPANY_AVATAR_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyConstants.DELETE_COMPANY_AVATAR_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyConstants.DELETE_COMPANY_AVATAR_SUCCESS: {
      const { company_id, company_avatar_url } = action.payload;

      const newCompanies = state.companies.map(company => {
        if (company.id === company_id) {
          return {
            ...company,
            company_avatar_url,
          };
        }
        return company;
      });

      let new_company_avatar_url = state.activeCompany.company_avatar_url;

      if (state.activeCompany.id === company_id) {
        new_company_avatar_url = company_avatar_url;
      }

      return {
        ...state,
        companies: newCompanies,
        activeCompany: {
          ...state.activeCompany,
          company_avatar_url: new_company_avatar_url,
        },
        isLoading: false,
      };
    }
    case companyConstants.DELETE_COMPANY_AVATAR_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyConstants.FETCH_COMPANY_MARKET_SEGMENT_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyConstants.FETCH_COMPANY_MARKET_SEGMENT_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        segments: data,
        isLoading: false,
      };
    }
    case companyConstants.FETCH_COMPANY_MARKET_SEGMENT_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyConstants.ADD_FINANCIAL_COMPANY_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyConstants.ADD_FINANCIAL_COMPANY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case companyConstants.ADD_FINANCIAL_COMPANY_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyConstants.UPDATE_FINANCIAL_COMPANY_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyConstants.UPDATE_FINANCIAL_COMPANY_SUCCESS: {
      return {
        ...state,
        companies: action.payload.companies,
        isLoading: false,
      };
    }
    case companyConstants.UPDATE_FINANCIAL_COMPANY_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case companyConstants.DELETE_FINANCIAL_COMPANY_REQUEST: {
      return {
        ...state,
      };
    }
    case companyConstants.DELETE_FINANCIAL_COMPANY_SUCCESS: {
      return {
        ...state,
      };
    }
    case companyConstants.DELETE_FINANCIAL_COMPANY_FAILURE: {
      return {
        ...state,
      };
    }

    case companyConstants.ALLOWED_PERIOD_GET_REQUEST: {
      return {
        ...state,
        isLoadingBlockedPeriod: true,
      };
    }
    case companyConstants.ALLOWED_PERIOD_GET_SUCCESS: {
      const { active_period_start = null, active_period_end = null } =
        action.payload || {};

      return {
        ...state,
        blockedPeriod: { active_period_start, active_period_end },
        isLoadingBlockedPeriod: false,
      };
    }
    case companyConstants.ALLOWED_PERIOD_GET_FAILURE: {
      return {
        ...state,
        isLoadingBlockedPeriod: false,
      };
    }

    case companyConstants.UPDATE_COMPANY_ACTIVE_PERIOD_REQUEST: {
      return {
        ...state,
        isLoadingBlockedPeriod: true,
      };
    }
    case companyConstants.UPDATE_COMPANY_ACTIVE_PERIOD_SUCCESS: {
      return {
        ...state,
        blockedPeriod: action.payload,
        isLoadingBlockedPeriod: false,
      };
    }
    case companyConstants.UPDATE_COMPANY_ACTIVE_PERIOD_FAILURE: {
      return {
        ...state,
        isLoadingBlockedPeriod: false,
      };
    }

    default:
      return state;
  }
}
