import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';

const getUserPreferences = () => {
  return (dispatch) => {
    dispatch(request());

    service.getUserPreferences().then(
      (response) => {
        dispatch(success(response.data));
      },
    );
  };

  function request() {
    return { type: constants.USER_GET_PREFERENCES_REQUEST };
  }
  function success(preferences) {
    return {
      type: constants.USER_GET_PREFERENCES_SUCCESS,
      payload: { preferences },
    };
  }
};

export default getUserPreferences;
