import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { StyledSelect } from './styles';

const OPTIONS = [
  { value: 10, label: 'Exibir 10 registros.' },
  { value: 20, label: 'Exibir 20 registros.' },
  { value: 50, label: 'Exibir 50 registros.' },
  { value: 100, label: 'Exibir 100 registros.' },
  { value: 200, label: 'Exibir 200 registros.' },
  { value: 500, label: 'Exibir 500 registros.' },
];

function ItemsPerPage({
  className,
  itemsPerPage,
  onChange,
  total,
  totalBeingShown,
  noMarginsOnTotals,
  maxItemsPerPage,
}) {
  const [selectedOption, setSelectedOption] = useState(
    () => OPTIONS.find((o) => o.value === itemsPerPage),
  );

  const handleChange = useCallback((option) => {
    setSelectedOption(option);
    onChange(option.value);
  }, [onChange]);

  return (
    <div className="d-flex align-items-center">
      <StyledSelect
        className={className}
        value={selectedOption}
        options={OPTIONS.filter((o) => o.value <= maxItemsPerPage)}
        onChange={handleChange}
        customStyles={{
          option: (provided) => ({
            ...provided,
            fontSize: '.9em',
            fontWeight: 500,
          }),
          control: (provided) => ({
            ...provided,
            fontSize: '.9em',
            fontWeight: 500,
          }),
        }}
      />
      {totalBeingShown > 0 && (
        <p className={!noMarginsOnTotals ? 'mt-3 ml-3 mb-0' : 'mb-0'}>
          {`Exibindo ${totalBeingShown} itens de um total de ${total}.`}
        </p>
      )}
    </div>

  );
}

ItemsPerPage.defaultProps = {
  className: null,
  total: null,
  totalBeingShown: null,
  noMarginsOnTotals: false,
  maxItemsPerPage: 500,
};

ItemsPerPage.propTypes = {
  noMarginsOnTotals: PropTypes.bool,
  className: PropTypes.string,
  itemsPerPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  total: PropTypes.number,
  totalBeingShown: PropTypes.number,
  maxItemsPerPage: PropTypes.number,
};

export default ItemsPerPage;
