import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { FaPlus } from 'react-icons/fa';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import { typeSubtypeStringToObject } from 'helpers';
import { Select } from '_components/_core';

import {
  CategoryOptions,
  GroupHeader,
  OptionListItem,
  OptionsList,
} from './styles';

const TRANSLATIONS = {
  [null]: {
    label: 'Receitas',
    type: 'INCOME',
  },
  FIXED_EXPENSE: {
    label: 'Despesas Fixas',
    type: 'EXPENSE',
  },
  VARIABLE_EXPENSE: {
    label: 'Despesas Variáveis',
    type: 'EXPENSE',
  },
  PEOPLE: {
    label: 'Pessoas',
    type: 'EXPENSE',
  },
  TAXES: {
    label: 'Impostos',
    type: 'EXPENSE',
  },
};

const NO_OPTIONS_CUSTOM_ITEMS = [
  {
    key: 'INCOME',
    label: (
      <span id="creatable-option">
        Em
        <strong id="creatable-option" className="ml-1">Receitas</strong>
      </span>
    ),
    type: 'INCOME',
    sub_type: null,
  },
  {
    key: 'FIXED_EXPENSE',
    label: (
      <span id="creatable-option">
        Em
        <strong id="creatable-option" className="ml-1">Despesas Fixas</strong>
      </span>
    ),
    type: 'EXPENSE',
    sub_type: 'FIXED_EXPENSE',
  },
  {
    key: 'VARIABLE_EXPENSE',
    label: (
      <span id="creatable-option">
        Em
        <strong id="creatable-option" className="ml-1">Despesas Variáveis</strong>
      </span>
    ),
    type: 'EXPENSE',
    sub_type: 'VARIABLE_EXPENSE',
  },
  {
    key: 'PEOPLE',
    label: (
      <span id="creatable-option">
        Em
        <strong id="creatable-option" className="ml-1">Pessoas</strong>
      </span>
    ),
    type: 'EXPENSE',
    sub_type: 'PEOPLE',
  },
  {
    key: 'TAXES',
    label: (
      <span id="creatable-option">
        Em
        <strong id="creatable-option" className="ml-1">Impostos</strong>
      </span>
    ),
    type: 'EXPENSE',
    sub_type: 'TAXES',
  },
];

const SUB_TYPES = [
  null,
  'FIXED_EXPENSE',
  'VARIABLE_EXPENSE',
  'PEOPLE',
  'TAXES',
];
function CategorySelect({
  name,
  categories,
  placeholder,
  disabled,
  value,
  onChange,
  onCreateCategory,
  innerRef,
  menuPlacement,
  typeSubType,
  width,
  prioritizeGroup,
  smallStyling,
  onCreateCallback,
  ...rest
}) {
  const [isLoading, setIsLoading] = useState(false);

  const [isEmptyOptions, setIsEmptyOptions] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [cursor, setCursor] = useState(0);

  const options = useMemo(() => {
    const groupedBySubType = groupBy(categories, 'sub_type');

    const options = [];

    SUB_TYPES.forEach((key) => {
      const group = groupedBySubType[key];

      if (group) {
        options.push({
          label: TRANSLATIONS[key].label,
          type: TRANSLATIONS[key].type,
          sub_type: key,
          options: sortBy(group, ['description']).map((category) => ({
            value: category.id,
            label: category.description,
          })),
        });
      }
    });

    if (['INCOME::'].includes(typeSubType)) {
      const { type, sub_type } = typeSubtypeStringToObject(typeSubType);

      return options.filter(
        (option) => option.type === type && option.sub_type === sub_type,
      );
    }

    if (typeSubType && typeSubType.includes('EXPENSE::')) {
      const { type, sub_type } = typeSubtypeStringToObject(typeSubType);

      if (type && sub_type && !isEmpty(options)) {
        const firstGroup = options.find(
          (option) => option.type === type && option.sub_type === sub_type,
        );

        if (!firstGroup) {
          return options;
        }

        return [firstGroup, ...options.filter((option) => option !== firstGroup)];
      }

      return options.filter(
        (option) => option.type === type,
      );
    }

    return options;
  }, [categories, typeSubType]);

  const handleChange = useCallback((option) => {
    onChange(option ? option.value : null);
  }, [onChange]);

  const getFieldValue = useCallback(() => {
    if (!options || isEmpty(options)) {
      return '';
    }

    const hasGroupedOptions = options.some((option = {}) => option.options);

    if (hasGroupedOptions) {
      const option = options
        .map((group) => group.options)
        .flat()
        .find((option) => option.value === value);

      return option || '';
    }

    return options ? options.find((option) => option.value === value) : '';
  }, [options, value]);

  const handleCreateCategory = useCallback((params) => {
    if (!params.description || params.description === '') {
      return;
    }

    setIsLoading(true);

    onCreateCategory(params, (created_category) => {
      setIsLoading(false);

      if (onCreateCallback) {
        onCreateCallback(created_category);
      }
    });
  }, [onCreateCategory, onCreateCallback]);

  const noOptionsCustomItems = useMemo(() => {
    if (typeSubType && typeSubType.includes('EXPENSE::')) {
      const { type, sub_type } = typeSubtypeStringToObject(typeSubType);

      const firstGroup = NO_OPTIONS_CUSTOM_ITEMS.find(
        (option) => option.type === type && option.sub_type === sub_type,
      );

      if (!firstGroup) {
        return NO_OPTIONS_CUSTOM_ITEMS;
      }

      return [firstGroup, ...NO_OPTIONS_CUSTOM_ITEMS.filter((option) => option !== firstGroup)];
    }

    return NO_OPTIONS_CUSTOM_ITEMS;
  }, [typeSubType]);

  const CustomNoOptionsMessage = useCallback(({ inputValue }) => {
    setIsEmptyOptions(true);
    setInputValue(inputValue);

    return (
      <CategoryOptions>
        <GroupHeader>
          <FaPlus size="1em" className="mr-2" />
          {`Cadastrar "${inputValue}"`}
        </GroupHeader>
        <OptionsList>
          {noOptionsCustomItems.map((item, index) => (
            <OptionListItem
              id="creatable-option"
              key={index}
              onClick={() => handleCreateCategory({
                type: item.type,
                sub_type: item.sub_type,
                description: inputValue,
              })}
              isActive={cursor === index}
            >
              {item.label}
            </OptionListItem>
          ))}
        </OptionsList>
      </CategoryOptions>
    );
  }, [handleCreateCategory, noOptionsCustomItems, cursor]);

  const smallStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      fontWeight: 500,
      cursor: 'pointer',
      borderColor: '#d5dcec',
      minHeight: 32,
      maxHeight: 32,
      backgroundColor: '#F9FBFC',
      border: '1px solid #E8E7EA',
      borderRadius: '8px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      fontSize: '13px',
      width: '100% !important',
      margin: 0,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: 32,
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      fontSize: '13px',
      top: '46%',
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: 9999,
      width: width || '100%',
    }),
    groupHeading: (baseStyles) => ({
      ...baseStyles,
      fontSize: '12px',
      paddingBottom: '8px',
      borderBottom: '1px solid #e0e0e0',
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      cursor: 'pointer',
      backgroundColor: state.isFocused ? '#eceef1' : 'transparent',
      color: state.isFocused ? '#00784e' : 'rgb(0, 0, 0)',
      fontWeight: state.isFocused ? 500 : 400,
      fontSize: '13px',
    }),
    noOptionsMessage: (baseStyles) => ({
      ...baseStyles,
      padding: 0,
    }),
  };

  const normalStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      fontWeight: 500,
      cursor: 'pointer',
      borderColor: '#d5dcec',
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      fontSize: '13px',
      width: '100% !important',
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      fontSize: '13px',
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: 9999,
      width: width || '100%',
    }),
    groupHeading: (baseStyles) => ({
      ...baseStyles,
      fontSize: '12px',
      paddingBottom: '8px',
      borderBottom: '1px solid #e0e0e0',
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      cursor: 'pointer',
      backgroundColor: state.isFocused ? '#eceef1' : 'transparent',
      color: state.isFocused ? '#00784e' : 'rgb(0, 0, 0)',
      fontWeight: state.isFocused ? 500 : 400,
      fontSize: '13px',
    }),
    noOptionsMessage: (baseStyles) => ({
      ...baseStyles,
      padding: 0,
    }),
  };

  const handleKeyDown = useCallback((event) => {
    if (!isEmptyOptions) {
      return;
    }

    if (event.keyCode === 38) {
      event.preventDefault();

      if (cursor > 0) {
        setCursor(cursor - 1);
      }
    }

    if (event.keyCode === 40) {
      event.preventDefault();

      if (cursor < noOptionsCustomItems.length - 1) {
        setCursor(cursor + 1);
      }
    }

    if (event.key === 'Enter') {
      event.preventDefault();

      const item = noOptionsCustomItems[cursor];

      handleCreateCategory({
        type: item.type,
        sub_type: item.sub_type,
        description: inputValue,
      });
    }
  }, [isEmptyOptions, cursor, noOptionsCustomItems, handleCreateCategory, inputValue]);

  const formatOptionLabel = ({ label }) => {
    if (label) {
      setIsEmptyOptions(false);
    }

    return label;
  };

  return (
    <>
      <Select
        innerRef={innerRef}
        options={options}
        value={getFieldValue()}
        onChange={handleChange}
        disabled={disabled}
        placeholder={placeholder}
        isLoading={isLoading}
        formatOptionLabel={formatOptionLabel}
        noOptionsMessage={CustomNoOptionsMessage}
        loadingMessage={() => 'Carregando...'}
        customStyles={smallStyling ? smallStyles : normalStyles}
        onKeyDown={handleKeyDown}
        menuPlacement={menuPlacement}
        {...rest}
      />
    </>
  );
}

CategorySelect.defaultProps = {
  categories: [],
  disabled: false,
  value: null,
  innerRef: null,
  menuPlacement: 'auto',
  type: null,
  sub_type: null,
  typeSubType: null,
  width: '100%',
  prioritizeGroup: null,
  smallStyling: false,
  showNoOptionsCustomItems: true,
  onCreateCallback: null,
};

CategorySelect.propTypes = {
  innerRef: PropTypes.object,
  value: PropTypes.string,
  type: PropTypes.string,
  typeSubType: PropTypes.string,
  sub_type: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  categories: PropTypes.array,
  placeholder: PropTypes.string,
  menuPlacement: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onCreateCategory: PropTypes.func.isRequired,
  width: PropTypes.string,
  prioritizeGroup: PropTypes.string,
  smallStyling: PropTypes.bool,
  showNoOptionsCustomItems: PropTypes.bool,
  onCreateCallback: PropTypes.func,
};

export default CategorySelect;
