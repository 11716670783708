import constants from '../../_constants/reports.constants';
import service from '../../../services/reports.service';

const getRevenueReport = (body) => {
  return (dispatch) => {
    dispatch(request(body));

    service.getRevenueReport(body).then(
      (response) => {
        dispatch(success(response.data));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(body) {
    return {
      type: constants.GET_REVENUE_REPORT_REQUEST,
      payload: { body },
    };
  }
  function success(data) {
    return {
      type: constants.GET_REVENUE_REPORT_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.GET_REVENUE_REPORT_FAILURE,
      payload: error,
    };
  }
};

export default getRevenueReport;
