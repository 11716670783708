import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

import { DownloadButton } from './styles';

function DownloadCsv({
  data,
  filename,
  visibleColumns,
}) {
  const formattedData = useMemo(() => {
    const allowedColumns = visibleColumns.map((visibleColumn) => visibleColumn.keyName);

    let finalData = Array.from(data);

    finalData = finalData.map((row) => {
      const keysToOmit = Object.keys(row).filter((key) => !allowedColumns.includes(key));

      const modified = omit(row, keysToOmit);

      return modified;
    });

    finalData = finalData.map((row) => {
      const keys = Object.keys(row);

      const modified = {};

      keys.forEach((key) => {
        const { label, formatter } = visibleColumns.find((column) => column.keyName === key);

        modified[label] = formatter ? formatter(row[key]) : row[key];
      });

      return modified;
    });

    return finalData;
  }, [data, visibleColumns]);

  return (
    <>
      <DownloadButton
        data={formattedData}
        filename={filename}
      >
        Exportar para CSV
      </DownloadButton>
    </>
  );
}

DownloadCsv.defaultProps = {
  data: [],
  visibleColumns: [],
};

DownloadCsv.propTypes = {
  data: PropTypes.array,
  filename: PropTypes.string,
  visibleColumns: PropTypes.array,
};

export default DownloadCsv;
