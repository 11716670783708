import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FORMATTERS from 'helpers/formatters';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Tabs,
  PageHeader,
} from '_components/_core';

import { getCreateEmployeeTabs, employeeFormFields, validationSchema } from './utilities';

export default function Create({
  isLoading,
  onCreateEmployee,
  onFetchEmployee,
  onFetchCities,
  onFetchCreateEmployeeParams,
  onUpdateEmployee,
}) {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const { id } = useParams();
  const isAddMode = !id;
  const history = useHistory();

  const redirect = useCallback(() => {
    history.push('/folha/funcionarios');
  }, [history]);

  const handleCreateEmployee = useCallback((values, { setSubmitting }) => {
    setSubmitting(true);
    const formattedEmployee = {
      ...values,
      document_number: FORMATTERS.CLEAN_CPF_CNPJ(values.document_number),
      phone_number: FORMATTERS.CLEAN_PHONE_NUMBER(values.phone_number),
    };

    if (isAddMode) {
      onCreateEmployee(formattedEmployee, () => {
        redirect();
      });
    } else {
      onUpdateEmployee(id, formattedEmployee, () => {
        redirect();
      });
    }

    setSubmitting(false);
  }, [isAddMode, onCreateEmployee, onUpdateEmployee, id, redirect]);

  const tabs = useMemo(() => getCreateEmployeeTabs(), []);

  useEffect(() => {
    if (id && !selectedEmployee) {
      onFetchEmployee(id, (employee) => {
        setSelectedEmployee({
          ...employee,
        });
      });
    }
  }, [id, selectedEmployee, onFetchEmployee, onFetchCities]);

  useEffect(() => {
    onFetchCreateEmployeeParams();
  }, [onFetchCreateEmployeeParams]);

  const formInitialValues = useMemo(() => {
    if (selectedEmployee) {
      return {
        ...selectedEmployee,
        date_admission: FORMATTERS.ISO_TO_JS_DATE(selectedEmployee.date_admission),
        birthdate: FORMATTERS.ISO_TO_JS_DATE(selectedEmployee.birthdate),
      };
    }

    return employeeFormFields;
  }, [selectedEmployee]);

  return (
    <div className="content-wrapper">
      <PageHeader
        title={isAddMode ? 'Cadastrar Funcionário' : 'Editar Funcionário'}
        description="Preencha os dados obrigatórios para o cadastro de um novo colaborador"
        withBackButton
      />
      <Row>
        <Col>
          <Card>
            <Formik
              initialValues={formInitialValues}
              validationSchema={validationSchema}
              onSubmit={handleCreateEmployee}
              enableReinitialize
            >
              {({ handleSubmit, isSubmitting, isValid }) => (
                <>
                  <CardBody noPadding>
                    <Tabs tabs={tabs} />
                  </CardBody>
                  <CardFooter>
                    <Button
                      onClick={handleSubmit}
                      isLoading={isLoading}
                      disabled={!isValid || isSubmitting}
                    >
                      Salvar Funcionário
                    </Button>
                  </CardFooter>
                </>
              )}
            </Formik>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

Create.propTypes = {
  isLoading: PropTypes.bool,
  onFetchCities: PropTypes.func.isRequired,
  onFetchEmployee: PropTypes.func.isRequired,
  onCreateEmployee: PropTypes.func.isRequired,
  onFetchCreateEmployeeParams: PropTypes.func.isRequired,
  onUpdateEmployee: PropTypes.func.isRequired,
};
