import * as yup from 'yup';
import { ptShort } from 'yup-locale-pt';
import { validateCPF } from 'validations-br';

yup.setLocale(ptShort);

const EmployeeDependentSchema = yup.object().shape({
  name: yup.string().required(),
  date_of_birth: yup.date().required(),
  document_number: yup
    .string()
    .nullable(true)
    .test('is-cpf', 'CPF inválido', (document_number) => {
      if (document_number === null || document_number === '') {
        return true;
      }

      return validateCPF(document_number);
    }),
});

export default EmployeeDependentSchema;
