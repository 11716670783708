import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

export default function CardFooter({ className, children }) {
  return <div className={classNames('card-footer', className)}>{children}</div>;
}

CardFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};
