import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IoFilterSharp } from 'react-icons/io5';
import { DateTime } from 'luxon';

import {
  PageHeader,
  Button,
  TableLookup,
  ActiveFilters,
} from '_components/_core';
import { DeleteInvoices, AccountingIntegrationModal } from '_components/_shared';
import { Pagination, ItemsPerPage, TableActions } from '_components/_core/Table/components';
import { useTable } from '_components/_core/Table/utils';
import { DEFAULT_COLUMNS, TABLE_COLUMNS } from '_components/_shared/InvoiceFilters/utilities';

import { getTableTopDistance, FILTERS_CONFIGURATION } from './utilities';
import { UploadAlert, Filters, SyncInvoices } from './components';

const tableConfig = {
  defaultFilters: {
    invoice_status: 'NORMAL',
    date_competence_start: new Date(DateTime.now().startOf('month')),
    date_competence_end: new Date(DateTime.now().endOf('month')),
  },
  defaultSorting: { field: 'date_competence', order: 'desc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

export default function InvoicesLookup({
  isLoading,
  total,
  invoices,
  uploadResults,
  onFetchInvoices,
  onClearUploadResults,
}) {
  const {
    filters,
    sorting,
    pagination,
    selectedItems,
    displayFilters,
    onFilter,
    onSorting,
    onPageChange,
    onPageSizeChange,
    onItemSelected,
    onSelectAll,
    onToggleFilters,
  } = useTable({
    ...tableConfig,
    data: invoices,
    onFetchData: onFetchInvoices,
  });

  const headerContent = () => (
    <>
      <Filters
        filters={filters}
        isVisible={displayFilters}
        onToggleFilters={onToggleFilters}
        onFilter={onFilter}
      />
      <Button
        variant="outline-primary"
        icon={<IoFilterSharp />}
        onClick={onToggleFilters}
      >
        Filtros
      </Button>
      <AccountingIntegrationModal type="invoices" />
      <SyncInvoices filters={filters} onFilter={onFilter} />
    </>
  );

  const tableTopDistance = useMemo(() => getTableTopDistance(uploadResults), [uploadResults]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Notas Fiscais"
        description="Todas as Notas Fiscais em um só lugar."
        sideContent={headerContent()}
      />
      <ActiveFilters
        filtersConfiguration={FILTERS_CONFIGURATION}
        filters={filters}
        onFilter={onFilter}
      />
      <Row>
        <Col>
          <UploadAlert uploadResults={uploadResults} onClearUploadResults={onClearUploadResults} />
          <TableLookup
            keyName="id"
            name="invoices-lookup-table"
            data={invoices}
            columns={TABLE_COLUMNS}
            defaultColumns={DEFAULT_COLUMNS}
            sorting={sorting}
            onSorting={onSorting}
            selectedItems={selectedItems}
            onItemSelected={onItemSelected}
            onSelectAll={onSelectAll}
            isLoading={isLoading}
            showColumnToggle
            showFooter
            topDistance={tableTopDistance}
          />
          <TableActions
            filters={filters}
            onFilter={onFilter}
            selectedItems={selectedItems}
            tableActions={[
              DeleteInvoices,
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <ItemsPerPage
            itemsPerPage={pagination.itemsPerPage}
            onChange={onPageSizeChange}
            className="mt-3"
          />
        </Col>
        <Col className={classNames('d-flex flex-row-reverse', pagination.itemsPerPage > 10 ? 'mr-5' : '')}>
          <Pagination
            {...pagination}
            total={total}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
    </Container>
  );
}

InvoicesLookup.defaultProps = {
  uploadResults: {},
  isLoading: false,
};

InvoicesLookup.propTypes = {
  total: PropTypes.number.isRequired,
  invoices: PropTypes.array.isRequired,
  uploadResults: PropTypes.object,
  onFetchInvoices: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onClearUploadResults: PropTypes.func.isRequired,
};
