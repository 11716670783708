import constants from '../../_constants/fiscal.constants';
import service from '../../../services/fiscal.service';
import alertActionCreators from '../alert.actions';

import getAllPersons from './getAllPersons';

const addPerson = (companyId, params, callback) => {
  return (dispatch) => {
    dispatch(request());

    service.addPerson(companyId, params).then(
      (response) => {
        const createdPerson = response.data;

        dispatch(success(createdPerson));
        dispatch(alertActionCreators.success('Cliente cadastrado com sucesso!'));
        dispatch(getAllPersons(companyId));

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.PERSONS_ADD_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.PERSONS_ADD_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.PERSONS_ADD_FAILURE,
      payload: error,
    };
  }
};

export default addPerson;
