import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardHeader,
  Table,
  Button,
} from '_components/_core';

import { FeeModal } from './components';
import { TABLE_COLUMNS, DEFAULT_COLUMNS } from './utilities';

function Fees({
  company,
  isLoading,
  onAddFee,
  onUpdateFee,
  onDeleteFee,
  onCompanyInfoChange,
}) {
  const [selectedFee, setSelectedFee] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fees = useMemo(() => {
    if (!company) {
      return [];
    }

    const { fees: data } = company || {};

    if (!data) {
      return [];
    }

    return data;
  }, [company]);

  const handleAddFee = useCallback(() => {
    setSelectedFee(null);
    setIsModalOpen(true);
  }, []);

  const handleEditFee = useCallback((id) => {
    setSelectedFee(null);

    const fee = fees.find((fee) => fee.id === id);

    setSelectedFee(fee);
    setIsModalOpen(true);
  }, [fees]);

  const handleDeleteFee = useCallback((id) => {
    const { id: company_id } = company || {};

    onDeleteFee(company_id, id, (updatedCompany) => {
      onCompanyInfoChange(updatedCompany);
    });
  }, [onDeleteFee, onCompanyInfoChange, company]);

  return (
    <Card className="mt-3">
      <FeeModal
        company={company}
        isVisible={isModalOpen}
        fee={selectedFee}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
        isLoading={isLoading}
        onAddFee={onAddFee}
        onUpdateFee={onUpdateFee}
        onCompanyInfoChange={onCompanyInfoChange}
      />
      <CardHeader
        title="Honorários"
        description="Cadastre aqui os valores de honorários cobrados do cliente para um melhor controle."
        sideContent={(
          <Button className="mb-4" onClick={handleAddFee}>
            Novo Honorário
          </Button>
        )}
      />
      <Table
        key="id"
        data={fees}
        defaultColumns={DEFAULT_COLUMNS}
        columns={TABLE_COLUMNS}
        onEdit={handleEditFee}
        onDelete={handleDeleteFee}
        hover
      />
    </Card>
  );
}

Fees.propTypes = {
  company: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onAddFee: PropTypes.func.isRequired,
  onUpdateFee: PropTypes.func,
  onDeleteFee: PropTypes.func,
  onCompanyInfoChange: PropTypes.func.isRequired,
};

export default Fees;
