import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import FORMATTERS from 'helpers/formatters';
import { Card, CardHeader, CardBody } from '_components/_core';

import { StyledDescription } from '../../styles';

function BasicInfo({ employee }) {
  const {
    document_number,
    document_type,
    birthdate,
    date_admission,
    rg_number,
    ctps_number,
    pis_pasep_number,
    email,
    phone_number,
    salary,
    mother_name,
  } = employee || {};

  return (
    <Card className="mb-3">
      <CardHeader title="Informações Cadastrais" />
      <CardBody>
        <Form>
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>{document_type}</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.CPF_CNPJ(document_number)}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Data de Nascimento</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.DATE_DDMMYYYY(birthdate)}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Data de Admissão</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.DATE_DDMMYYYY(date_admission)}
                </StyledDescription>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>RG</Form.Label>
                <StyledDescription column>
                  {rg_number}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Número da CTPS</Form.Label>
                <StyledDescription column>
                  {ctps_number || '-'}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Número do PIS</Form.Label>
                <StyledDescription column>
                  {pis_pasep_number || '-'}
                </StyledDescription>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>E-mail</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.LOWERCASE(email)}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Telefone</Form.Label>
                <StyledDescription column>
                  {phone_number}
                </StyledDescription>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Salário</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.NUMBER(salary)}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Nome da Mãe</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.UPPERCASE(mother_name) || '-'}
                </StyledDescription>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}

BasicInfo.propTypes = {
  employee: PropTypes.object.isRequired,
};

export default BasicInfo;
