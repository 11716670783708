import styled, { css } from 'styled-components';

import Button from '_components/_core/Button';

export const StyledButton = styled(Button)`
  font-size: 13px;
  padding: 0;

  ${({ styleOnHover }) => styleOnHover && css`
    text-decoration: none !important;
    color: inherit;
    font-weight: inherit;

    &:hover {
      text-decoration: underline !important;
      color: #007bff;
    }
  `}
`;

export default StyledButton;
