import { useDispatch, useSelector } from 'react-redux';

const useTransferForm = () => {
  const dispatch = useDispatch();

  return {
    isTransferFormOpen: useSelector((state) => state.companyTransactions.isTransferFormOpen),
    onToggleTransferForm: () => dispatch({ type: 'TOGGLE_TRANSFER_FORM' }),
  };
};

export default useTransferForm;
