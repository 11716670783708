import { DateTime } from 'luxon';

import FORMATTERS from 'helpers/formatters';
import ValidationSchema from './ValidationSchema';

const PAYMENT_FORM = {
  [null]: 'Não informado',
  BANK_SLIP: 'Boleto',
  CREDIT_CARD: 'Cartão de crédito',
  DEBIT_CARD: 'Cartão de débito',
  BANK_CHECK: 'Cheque',
  DIRECT_DEBIT: 'Débito em conta',
  CASH: 'Dinheiro',
  PIX: 'PIX',
  TRANSFER: 'Transferência',
  REMITTANCE: 'Remessa Bancária',
};

const VARIABLES = [
  {
    name: '[DATA_ATUAL]',
    description: 'Data de emissão do recibo (dd/MM/aaaa)',
    group: 'DEFAULT',
    getter: () => DateTime.local().toFormat('dd/MM/yyyy'),
  },
  {
    name: '[HORA_ATUAL]',
    description: 'Hora de emissão do recibo (HH:mm:ss)',
    group: 'DEFAULT',
    getter: () => DateTime.local().toFormat('HH:mm:ss'),
  },
  {
    name: '[DATA_HORA_ATUAL]',
    description: 'Data e Hora de emissão do recibo (dd/MM/aaaa HH:mm:ss)',
    group: 'DEFAULT',
    getter: () => DateTime.local().toFormat('dd/MM/yyyy HH:mm:ss'),
  },
  {
    name: '[MES_ANO_ATUAL]',
    description: 'Mês e Ano de emissão do recibo (MM/aaaa)',
    group: 'DEFAULT',
    getter: () => DateTime.local().toFormat('MM/yyyy'),
  },
  {
    name: 'https://via.placeholder.com/80',
    description: 'Url do avatar da minha empresa/negócio',
    group: 'DEFAULT',
    getter: ({ company = {} }) => company.company_avatar_url || 'https://via.placeholder.com/80',
    hidden: true,
  },
  {
    name: '[MEU_NOME]',
    description: 'Nome do usuário logado no momento da emissão',
    group: 'COMPANY',
    getter: ({ user = {} }) => user.full_name,
  },

  {
    name: '[MINHA_EMPRESA_RAZAO_SOCIAL]',
    description: 'Razão Social da minha empresa/negócio',
    group: 'COMPANY',
    getter: ({ company = {} }) => company.company_name,
  },
  {
    name: '[MINHA_EMPRESA_NOME_FANTASIA]',
    description: 'Nome Fantasia da minha empresa/negócio',
    group: 'COMPANY',
    getter: ({ company = {} }) => company.trading_name,
  },
  {
    name: '[MINHA_EMPRESA_DOCUMENTO]',
    description: 'Número do documento da minha empresa/negócio',
    group: 'COMPANY',
    getter: ({ company = {} }) => FORMATTERS.CPF_CNPJ(company.document_number),
  },
  {
    name: '[MINHA_EMPRESA_CIDADE]',
    description: 'Cidade da minha empresa/negócio',
    group: 'COMPANY',
    getter: ({ company = {} }) => company.address_city,
  },
  {
    name: '[MINHA_EMPRESA_ESTADO]',
    description: 'Estado da minha empresa/negócio',
    group: 'COMPANY',
    getter: ({ company = {} }) => company.address_state,
  },
  {
    name: '[MINHA_EMPRESA_LOGOMARCA]',
    description: 'Logomarca da minha empresa/negócio',
    group: 'COMPANY',
    getter: ({ company = {} }) => `<img style="border-radius: 4px;" src="${company.company_avatar_url || 'https://via.placeholder.com/80'}" width="80" height="80">`,
  },
  {
    name: '[ITEM_CODIGO]',
    description: 'Código único da movimentação, gerado pelo sistema',
    group: 'TRANSACTION',
    getter: ({ transaction = {} }) => transaction.id,
  },
  {
    name: '[ITEM_DATA]',
    description: 'Data da movimentação',
    group: 'TRANSACTION',
    getter: ({ transaction = {} }) => FORMATTERS.DATE_DDMMYYYY(transaction.event_date),
  },
  {
    name: '[ITEM_DESCRICAO]',
    description: 'Descrição da movimentação',
    group: 'TRANSACTION',
    getter: ({ transaction = {} }) => transaction.description || 'Não informado',
  },
  {
    name: '[ITEM_VALOR]',
    description: 'Valor da movimentação',
    group: 'TRANSACTION',
    getter: ({ transaction = {} }) => FORMATTERS.NUMBER(transaction.amount),
  },
  {
    name: '[ITEM_CONTA_BANCARIA]',
    description: 'Nome da conta bancária da movimentação',
    group: 'TRANSACTION',
    getter: ({ transaction = {} }) => transaction.account.description || 'Não informado',
  },
  {
    name: '[ITEM_CATEGORIA]',
    description: 'Categoria da movimentação',
    group: 'TRANSACTION',
    getter: ({ transaction = {} }) => transaction.category.description || 'Não informado',
  },
  {
    name: '[ITEM_CENTRO_DE_CUSTO]',
    description: 'Centro de Custo da movimentação',
    group: 'TRANSACTION',
    getter: ({ transaction = {} }) => transaction.cost_center.description || 'Não informado',
  },
  {
    name: '[ITEM_TAGS]',
    description: 'Tags da movimentação (separadas por vírgula)',
    group: 'TRANSACTION',
    getter: ({ transaction = {} }) => transaction.tags.map((tag) => tag.description).join(', ') || 'Não informado',
  },
  {
    name: '[ITEM_NUMERO_DOCUMENTO]',
    description: 'Número do documento da movimentação',
    group: 'TRANSACTION',
    getter: ({ transaction = {} }) => transaction.document_number || 'Não informado',
  },
  {
    name: '[ITEM_FORMA_PAGAMENTO]',
    description: 'Forma de pagamento da movimentação',
    group: 'TRANSACTION',
    getter: ({ transaction = {} }) => PAYMENT_FORM[transaction.payment_form],
  },
  {
    name: '[ITEM_OBSERVACOES]',
    description: 'Observações da movimentação',
    group: 'TRANSACTION',
    getter: ({ transaction = {} }) => transaction.comment || 'Não informado',
  },
  {
    name: '[ITEM_PARCELA_ATUAL]',
    description: 'O número da parcela atual da movimentação (somente para itens parcelados)',
    group: 'TRANSACTION',
    getter: ({ transaction = {} }) => transaction.frequency_number || '',
  },
  {
    name: '[ITEM_TOTAL_PARCELAS]',
    description: 'O número total de parcelas (somente para itens parcelados)',
    group: 'TRANSACTION',
    getter: ({ transaction = {} }) => transaction.frequency_total || '',
  },
  {
    name: '[CONTATO_NOME]',
    description: 'Nome do Cliente/Fornecedor/Funcionário',
    group: 'CONTACT',
    getter: ({ contact = {} }) => contact.name || 'Não informado',
  },
  {
    name: '[CONTATO_DOCUMENTO]',
    description: 'CPF ou CNPJ do Cliente/Fornecedor/Funcionário',
    group: 'CONTACT',
    getter: ({ contact = {} }) => FORMATTERS.CPF_CNPJ(contact.document_number),
  },
  {
    name: '[CONTATO_EMAIL]',
    description: 'E-mail do Cliente/Fornecedor/Funcionário',
    group: 'CONTACT',
    getter: ({ contact = {} }) => contact.email || 'Não informado',
  },
  {
    name: '[CONTATO_TELEFONE]',
    description: 'Telefone do Cliente/Fornecedor/Funcionário',
    group: 'CONTACT',
    getter: ({ contact = {} }) => contact.phone_number || 'Não informado',
  },
  {
    name: '[CONTATO_ENDERECO_LOGRADOURO]',
    description: 'Logradouro do Endereço do Cliente/Fornecedor/Funcionário',
    group: 'CONTACT',
    getter: ({ contact = {} }) => contact.address_street || 'Não informado',
  },
  {
    name: '[CONTATO_ENDERECO_NUMERO]',
    description: 'Número do Endereço do Cliente/Fornecedor/Funcionário',
    group: 'CONTACT',
    getter: ({ contact = {} }) => contact.address_number || 'Não informado',
  },
  {
    name: '[CONTATO_ENDERECO_COMPLEMENTO]',
    description: 'Complemento do Endereço do Cliente/Fornecedor/Funcionário',
    group: 'CONTACT',
    getter: ({ contact = {} }) => contact.address_complement || 'Não informado',
  },
  {
    name: '[CONTATO_ENDERECO_BAIRRO]',
    description: 'Bairro do Endereço do Cliente/Fornecedor/Funcionário',
    group: 'CONTACT',
    getter: ({ contact = {} }) => contact.address_district || 'Não informado',
  },
  {
    name: '[CONTATO_ENDERECO_CEP]',
    description: 'CEP do Endereço do Cliente/Fornecedor/Funcionário',
    group: 'CONTACT',
    getter: ({ contact = {} }) => contact.address_zip_code || 'Não informado',
  },
  {
    name: '[CONTATO_ENDERECO_CIDADE]',
    description: 'Cidade do Endereço do Cliente/Fornecedor/Funcionário',
    group: 'CONTACT',
    getter: ({ contact = {} }) => contact.address_city || 'Não informado',
  },
  {
    name: '[CONTATO_ENDERECO_ESTADO]',
    description: 'Estado do Endereço do Cliente/Fornecedor/Funcionário',
    group: 'CONTACT',
    getter: ({ contact = {} }) => contact.address_state || 'Não informado',
  },
];

const TEMPLATES = [
  {
    title: '[EXEMPLO SEÇÃO] Cabeçalho padrão',
    description: 'Cabeçalho padrão com dados da minha empresa.',
    content: `
    <table style="border-collapse: collapse; width: 100%; height: 19.5938px; border-width: 0px;" border="1"><colgroup><col style="width: 100%;"></colgroup>
    <tbody>
    <tr style="height: 19.5938px;">
    <td style="height: 19.5938px; border-width: 0px;">
    <table style="border-collapse: collapse; width: 100%; border-width: 0px; height: 61.5782px; border-spacing: 3px;" border="1"><colgroup><col style="width: 49.9645%;"><col style="width: 49.9645%;"></colgroup>
    <tbody>
    <tr style="height: 22.3906px;">
    <td style="height: 22.3906px; border-width: 0px; padding: 3px;"><strong><span style="font-size: 16px;">[MINHA_EMPRESA_RAZAO_SOCIAL]</span></strong></td>
    <td style="text-align: right; height: 22.3906px; border-width: 0px; padding: 3px;"><strong>Data:</strong> [DATA_HORA_ATUAL]</td>
    </tr>
    <tr style="height: 19.5938px;">
    <td style="height: 19.5938px; border-width: 0px; padding: 3px;">[MINHA_EMPRESA_DOCUMENTO]</td>
    <td style="height: 19.5938px; border-width: 0px; padding: 3px;">&nbsp;</td>
    </tr>
    <tr style="height: 19.5938px;">
    <td style="height: 19.5938px; border-width: 0px; padding: 3px;">[MINHA_EMPRESA_CIDADE] - [MINHA_EMPRESA_ESTADO]</td>
    <td style="height: 19.5938px; border-width: 0px; padding: 3px;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    </td>
    </tr>
    </tbody>
    </table>
    `,
  },
  {
    title: '[EXEMPLO SEÇÃO] Cabeçalho com logomarca',
    description: 'Cabeçalho padrão com dados da minha empresa e logomarca.',
    content: `
    <table style="border-collapse: collapse; width: 100%; height: 19.5938px; border-width: 0px;" border="1"><colgroup><col style="width: 7.88114%;"><col style="width: 92.1189%;"></colgroup>
    <tbody>
    <tr style="height: 19.5938px;">
    <td style="height: 20px; text-align: center; vertical-align: middle; border-width: 0px;"><img style="border-radius: 4px;" src="https://via.placeholder.com/80" width="80" height="80"></td>
    <td style="height: 19.5938px; border-width: 0px;">
    <table style="border-collapse: collapse; width: 100%; border-width: 0px; height: 61.5782px; border-spacing: 3px;" border="1"><colgroup><col style="width: 49.9645%;"><col style="width: 49.9645%;"></colgroup>
    <tbody>
    <tr style="height: 22.3906px;">
    <td style="height: 22.3906px; border-width: 0px; padding: 3px;"><strong><span style="font-size: 16px;">[MINHA_EMPRESA_RAZAO_SOCIAL]</span></strong></td>
    <td style="text-align: right; height: 22.3906px; border-width: 0px; padding: 3px;"><strong>Data:</strong> [DATA_HORA_ATUAL]</td>
    </tr>
    <tr style="height: 19.5938px;">
    <td style="height: 19.5938px; border-width: 0px; padding: 3px;">[MINHA_EMPRESA_DOCUMENTO]</td>
    <td style="height: 19.5938px; border-width: 0px; padding: 3px;">&nbsp;</td>
    </tr>
    <tr style="height: 19.5938px;">
    <td style="height: 19.5938px; border-width: 0px; padding: 3px;">[MINHA_EMPRESA_CIDADE] - [MINHA_EMPRESA_ESTADO]</td>
    <td style="height: 19.5938px; border-width: 0px; padding: 3px;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    </td>
    </tr>
    </tbody>
    </table>
    `,
  },
  {
    title: '[EXEMPLO SEÇÃO] Dados do contato (Cliente/Fornecedor/Funcionário)',
    description: 'Exemplo de seção com dados do contato (Cliente/Fornecedor/Funcionário)',
    content: `
    <p style="text-align: center;"><span style="font-size: 16px;"><strong>DADOS DO CONTATO</strong></span></p>
    <table style="border-collapse: collapse; width: 100%; height: 78.3752px; border-spacing: 3px; border: 1px solid rgb(206, 212, 217);" border="1"><colgroup><col style="width: 6.72269%;"><col style="width: 65%;"><col style="width: 5%;"><col style="width: 25%;"></colgroup>
    <tbody>
    <tr style="height: 19.5938px;">
    <td style="padding: 3px; text-align: right; height: 19.5938px; border: 1px hidden rgb(206, 212, 217);"><strong>Nome:&nbsp;</strong></td>
    <td style="height: 19.5938px; padding: 3px; border: 1px hidden rgb(206, 212, 217);">[CONTATO_NOME]</td>
    <td style="padding: 3px; text-align: right; height: 19.5938px; border: 1px hidden rgb(206, 212, 217);"><strong>CPF/CNPJ:</strong></td>
    <td style="height: 19.5938px; padding: 3px; border: 1px hidden rgb(206, 212, 217);">[CONTATO_DOCUMENTO]</td>
    </tr>
    <tr style="height: 19.5938px;">
    <td style="padding: 3px; text-align: right; height: 19.5938px; border: 1px hidden rgb(206, 212, 217);"><strong>Endere&ccedil;o:</strong></td>
    <td style="height: 19.5938px; padding: 3px; border: 1px hidden rgb(206, 212, 217);">[CONTATO_ENDERECO_LOGRADOURO], [CONTATO_ENDERECO_NUMERO]</td>
    <td style="padding: 3px; text-align: right; height: 19.5938px; border: 1px hidden rgb(206, 212, 217);"><strong>Bairro:</strong></td>
    <td style="height: 19.5938px; padding: 3px; border: 1px hidden rgb(206, 212, 217);">[CONTATO_ENDERECO_BAIRRO]</td>
    </tr>
    <tr style="height: 19.5938px;">
    <td style="padding: 3px; text-align: right; height: 19.5938px; border: 1px hidden rgb(206, 212, 217);"><strong>Cidade:</strong></td>
    <td style="height: 19.5938px; padding: 3px; border: 1px hidden rgb(206, 212, 217);">[CONTATO_ENDERECO_CIDADE]</td>
    <td style="padding: 3px; text-align: right; height: 19.5938px; border: 1px hidden rgb(206, 212, 217);"><strong>CEP:</strong></td>
    <td style="height: 19.5938px; padding: 3px; border: 1px hidden rgb(206, 212, 217);">[CONTATO_ENDERECO_CEP]</td>
    </tr>
    <tr style="height: 19.5938px;">
    <td style="padding: 3px; text-align: right; height: 19.5938px; border: 1px hidden rgb(206, 212, 217);"><strong>Telefone:</strong></td>
    <td style="height: 19.5938px; padding: 3px; border: 1px hidden rgb(206, 212, 217);">[CONTATO_TELEFONE]</td>
    <td style="padding: 3px; text-align: right; height: 19.5938px; border: 1px hidden rgb(206, 212, 217);"><strong>E-mail:</strong></td>
    <td style="height: 19.5938px; padding: 3px; border: 1px hidden rgb(206, 212, 217);">[CONTATO_EMAIL]</td>
    </tr>
    </tbody>
    </table>
    `,
  },
  {
    title: '[EXEMPLO SEÇÃO] Dados da movimentação',
    description: 'Exemplo de seção com dados da movimentação',
    content: `
    <p style="text-align: center;"><span style="font-size: 16px;"><strong>DADOS DA MOVIMENTA&Ccedil;&Atilde;O</strong></span></p>
    <table style="border-collapse: collapse; width: 100%; height: 85.6876px; border-spacing: 3px; border: 1px solid rgb(206, 212, 217);" border="1"><colgroup><col style="width: 10.1109%;"><col style="width: 47.7495%;"><col style="width: 22.0483%;"><col style="width: 20.0913%;"></colgroup>
    <tbody>
    <tr style="height: 21.4219px;">
    <td style="padding: 3px; text-align: right; border: 1px hidden rgb(206, 212, 217); height: 21.4219px;"><strong>Identificador:</strong></td>
    <td style="padding: 3px; border: 1px hidden rgb(206, 212, 217); height: 21.4219px;">[ITEM_CODIGO]</td>
    <td style="padding: 3px; text-align: right; border: 1px hidden rgb(206, 212, 217); height: 21.4219px;"><strong>N&uacute;mero documento:</strong></td>
    <td style="padding: 3px; border: 1px hidden rgb(206, 212, 217); height: 21.4219px;">[ITEM_NUMERO_DOCUMENTO]</td>
    </tr>
    <tr style="height: 21.4219px;">
    <td style="padding: 3px; text-align: right; height: 21.4219px; border: 1px hidden rgb(206, 212, 217);"><strong>Data:&nbsp;</strong></td>
    <td style="height: 21.4219px; padding: 3px; border: 1px hidden rgb(206, 212, 217);">[ITEM_DATA]</td>
    <td style="padding: 3px; text-align: right; height: 21.4219px; border: 1px hidden rgb(206, 212, 217);"><strong>Categoria:</strong></td>
    <td style="height: 21.4219px; padding: 3px; border: 1px hidden rgb(206, 212, 217);">[ITEM_CATEGORIA]</td>
    </tr>
    <tr style="height: 21.4219px;">
    <td style="padding: 3px; text-align: right; height: 21.4219px; border: 1px hidden rgb(206, 212, 217);"><strong>Valor:</strong></td>
    <td style="height: 21.4219px; padding: 3px; border: 1px hidden rgb(206, 212, 217);">[ITEM_VALOR]</td>
    <td style="padding: 3px; text-align: right; height: 21.4219px; border: 1px hidden rgb(206, 212, 217);"><strong>Centro de custo:</strong></td>
    <td style="height: 21.4219px; padding: 3px; border: 1px hidden rgb(206, 212, 217);">[ITEM_CENTRO_DE_CUSTO]</td>
    </tr>
    <tr style="height: 21.4219px;">
    <td style="padding: 3px; text-align: right; height: 21.4219px; border: 1px hidden rgb(206, 212, 217);"><strong>Descri&ccedil;&atilde;o:</strong></td>
    <td style="height: 21.4219px; padding: 3px; border: 1px hidden rgb(206, 212, 217);">[ITEM_DESCRICAO]</td>
    <td style="padding: 3px; text-align: right; height: 21.4219px; border: 1px hidden rgb(206, 212, 217);"><strong>Forma de pagamento:</strong></td>
    <td style="height: 21.4219px; padding: 3px; border: 1px hidden rgb(206, 212, 217);">[ITEM_FORMA_PAGAMENTO]</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="border-collapse: collapse; width: 100%; height: 67.6875px; border-spacing: 3px; border: 1px solid rgb(206, 212, 217);" border="1"><colgroup><col style="width: 10%;"><col style="width: 88.6497%;"></colgroup>
    <tbody>
    <tr style="height: 10px;">
    <td style="padding: 3px; text-align: right; border: 1px hidden rgb(206, 212, 217); height: 22.5625px;"><strong>Oberserva&ccedil;&otilde;es:</strong></td>
    <td style="padding: 3px; border: 1px hidden rgb(206, 212, 217); height: 22.5625px;">[ITEM_OBSERVACOES]</td>
    </tr>
    </tbody>
    </table>
    `,
  },
  {
    title: '[EXEMPLO SEÇÃO] Assinaturas (2)',
    description: 'Exemplo de seção com 2 assinaturas',
    content: `
    <table style="border-collapse: collapse; width: 100%; height: 39.1876px; border-width: 1px; border-style: hidden;" border="1"><colgroup><col style="width: 15%;"><col style="width: 25%;"><col style="width: 25%;"><col style="width: 15%;"></colgroup>
    <tbody>
    <tr style="height: 19.5938px;">
    <td style="height: 19.5938px; border-style: hidden;">&nbsp;</td>
    <td style="height: 19.5938px; text-align: center; border-style: hidden;">__________________________________________</td>
    <td style="height: 19.5938px; text-align: center; border-style: hidden;">__________________________________________</td>
    <td style="height: 19.5938px; border-style: hidden;">&nbsp;</td>
    </tr>
    <tr style="height: 19.5938px;">
    <td style="height: 19.5938px; border-style: hidden;">&nbsp;</td>
    <td style="height: 19.5938px; text-align: center; border-style: hidden;"><strong>ASSINATURA 1</strong></td>
    <td style="height: 19.5938px; text-align: center; border-style: hidden;"><strong>ASSINATURA 2</strong></td>
    <td style="height: 19.5938px; border-style: hidden;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    `,
  },
  {
    title: '[EXEMPLO SEÇÃO] Assinaturas (4)',
    description: 'Exemplo de seção com 4 assinaturas',
    content: `
    <table style="border-collapse: collapse; width: 100%; height: 39.1876px; border-width: 1px; border-style: hidden;" border="1"><colgroup><col style="width: 18.7256%;"><col style="width: 31.2744%;"><col style="width: 31.2744%;"><col style="width: 18.7256%;"></colgroup>
    <tbody>
    <tr style="height: 19.5938px;">
    <td style="height: 19.5938px; border-style: hidden;">&nbsp;</td>
    <td style="height: 19.5938px; text-align: center; border-style: hidden;">__________________________________________</td>
    <td style="height: 19.5938px; text-align: center; border-style: hidden;">__________________________________________</td>
    <td style="height: 19.5938px; border-style: hidden;">&nbsp;</td>
    </tr>
    <tr style="height: 19.5938px;">
    <td style="height: 19.5938px; border-style: hidden;">&nbsp;</td>
    <td style="height: 19.5938px; text-align: center; border-style: hidden;"><strong>ASSINATURA 1</strong></td>
    <td style="height: 19.5938px; text-align: center; border-style: hidden;"><strong>ASSINATURA 2</strong></td>
    <td style="height: 19.5938px; border-style: hidden;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="border-collapse: collapse; width: 100%; height: 39.1876px; border-width: 1px; border-style: hidden;" border="1"><colgroup><col style="width: 15%;"><col style="width: 25%;"><col style="width: 25%;"><col style="width: 15%;"></colgroup>
    <tbody>
    <tr style="height: 19.5938px;">
    <td style="height: 19.5938px; border-style: hidden;">&nbsp;</td>
    <td style="height: 19.5938px; text-align: center; border-style: hidden;">__________________________________________</td>
    <td style="height: 19.5938px; text-align: center; border-style: hidden;">__________________________________________</td>
    <td style="height: 19.5938px; border-style: hidden;">&nbsp;</td>
    </tr>
    <tr style="height: 19.5938px;">
    <td style="height: 19.5938px; border-style: hidden;">&nbsp;</td>
    <td style="height: 19.5938px; text-align: center; border-style: hidden;"><strong>ASSINATURA 3</strong></td>
    <td style="height: 19.5938px; text-align: center; border-style: hidden;"><strong>ASSINATURA 4</strong></td>
    <td style="height: 19.5938px; border-style: hidden;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    `,
  },
  {
    title: '[EXEMPLO SEÇÃO] Rodapé padrão',
    description: 'Rodapé com a data e hora de emissão.',
    content: '<p>Emitido em: [DATA_HORA_ATUAL]</p>',
  },
  {
    title: '[EXEMPLO MODELO] Recebimento simples',
    description: 'Recibo simples onde minha empresa é a beneficiária.',
    content: `
    <p style="text-align: center;">&nbsp;</p>
    <p style="text-align: center;">&nbsp;</p>
    <p style="text-align: center;"><span style="font-size: 18px;"><strong>R E C I B O</strong></span></p>
    <p style="text-align: center;">&nbsp;</p>
    <p style="text-align: center;">&nbsp;</p>
    <p style="text-align: center;">RECEBEMOS DE [CONTATO_NOME], A QUANTIA DE [ITEM_VALOR], CORRESPONDENTE A [ITEM_DESCRICAO], E PARA CLAREZA FIRMAMOS O PRESENTE NA CIDADE DE [MINHA_EMPRESA_CIDADE], [MINHA_EMPRESA_ESTADO] NO DIA [DATA_ATUAL].</p>
    <p style="text-align: center;">&nbsp;</p>
    <p style="text-align: center;">&nbsp;</p>
    <p style="text-align: center;">________________________________________</p>
    <p style="text-align: center;"><strong>[MINHA_EMPRESA_RAZAO_SOCIAL], EM [DATA_ATUAL]</strong></p>
    `,
  },
  {
    title: '[EXEMPLO MODELO] Pagamento simples',
    description: 'Recibo simples onde o contato da transação é o beneficiário.',
    content: `
    <p style="text-align: center;">&nbsp;</p>
    <p style="text-align: center;">&nbsp;</p>
    <p style="text-align: center;"><span style="font-size: 18px;"><strong>R E C I B O</strong></span></p>
    <p style="text-align: center;">&nbsp;</p>
    <p style="text-align: center;">&nbsp;</p>
    <p style="text-align: center;">RECEBEMOS DE [MINHA_EMPRESA_RAZAO_SOCIAL], A QUANTIA DE [ITEM_VALOR], CORRESPONDENTE A [ITEM_DESCRICAO], E PARA CLAREZA FIRMAMOS O PRESENTE NA CIDADE DE [MINHA_EMPRESA_CIDADE], [MINHA_EMPRESA_ESTADO] NO DIA [DATA_ATUAL].</p>
    <p style="text-align: center;">&nbsp;</p>
    <p style="text-align: center;">&nbsp;</p>
    <p style="text-align: center;">________________________________________</p>
    <p style="text-align: center;"><strong>[CONTATO_NOME], EM [DATA_ATUAL]</strong></p>
    <p>&nbsp;</p>`,
  },
];

export {
  ValidationSchema,
  VARIABLES,
  TEMPLATES,
};
