import styled, { css } from 'styled-components';

export const Table = styled.table`

  small {
    font-size: 100%;
  }

  strong {
    text-transform: uppercase;
    font-size: 100%;
  }

`;

export const Label = styled.small`
  font-weight: bold;
`;

export const StyledHeader = styled.div`
  font-size: 1.2em;
  font-weight: 700;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 51px;
`;

export const StyledP = styled.p`
  font-size: 1em;
  margin-top: 8px;
  margin-bottom: 8px;

  ${({ smallMargin }) => smallMargin && css`
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  `}
`;

export const StyledHr = styled.hr`
  width: 100%;
  border-color: #354168;
`;

export const SignatureDiv = styled.div`
  width: 40%;
  margin-top: 80px;
  text-align: center;
  align-items: center;
`;

export const StyledDiv = styled.div`
  justify-content: space-around;
`;

export const StyledContainer = styled.div`
  padding: 0px 10px;
`;
