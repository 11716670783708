import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';

const goToCheckoutSession = ({ user_id, plan_id }, callback) => {
  return (dispatch) => {
    dispatch(request());

    service.createCheckoutSession({
      user_id,
      plan_id,
    }).then(
      (response) => {
        const { session_id } = response.data;

        dispatch(success(session_id));

        if (callback) {
          callback(session_id);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.CREATE_CHECKOUT_SESSION_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.CREATE_CHECKOUT_SESSION_SUCCESS,
      payload: {
        data,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.CREATE_CHECKOUT_SESSION_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default goToCheckoutSession;
