import React from 'react';
import PropTypes from 'prop-types';

import FORMATTERS from 'helpers/formatters';

import { PayrollContentTable } from './styles';

function SalaryReceipt({
  company,
  payroll,
  className,
}) {
  const { company_name, document_number } = company || {};
  const {
    date_competence,
    employee_name,
    employee_role,
    employee_admission_date,
    child_events = [],
    income_total,
    deduction_total,
    net_amount,
    income_salary,
    fgts_amount,
    deduction_inss,
  } = payroll || {};

  return (
    <div className={className}>
      <PayrollContentTable className="table table-bordered">
        <tbody>
          <tr className="row-header">
            <td>Empresa</td>
            <td>Mês/Ano</td>
          </tr>
          <tr className="row-values">
            <td>
              {FORMATTERS.UPPERCASE(company_name)}
              <span>{FORMATTERS.CPF_CNPJ(document_number)}</span>
            </td>
            <td>
              {FORMATTERS.DATE_MMMMYYYY(date_competence)}
            </td>
          </tr>
          <tr className="row-header">
            <td>Nome do Funcionário / Função</td>
            <td>Admissão</td>
          </tr>
          <tr className="row-values">
            <td>
              {employee_name}
              <span className="employee-role">{employee_role}</span>
            </td>
            <td>{employee_admission_date}</td>
          </tr>
        </tbody>
      </PayrollContentTable>

      <PayrollContentTable className="table table-bordered mt-3">
        <tbody>
          <tr className="row-header normal with-background text-center">
            <td width="5%">Código</td>
            <td width="50%" className="text-left">Descrição</td>
            <td width="5%">Referência</td>
            <td width="20%" className="text-right">Vencimentos</td>
            <td width="20%" className="text-right">Descontos</td>
          </tr>
          {child_events.map((child_event, index) => (
            <tr key={child_event.id} className="row-values normal text-right">
              <td width="5%">{index + 1}</td>
              <td width="50%" className="text-left">{child_event.description}</td>
              <td width="5%">{FORMATTERS.PAYROLL_REFERENCE(child_event.reference, child_event.reference_type)}</td>
              <td width="20%">
                {child_event.operation === 'SALARY'
                  ? FORMATTERS.NUMBER_WITHOUT_CURRENCY(child_event.amount) : ''}
              </td>
              <td width="20%">
                {child_event.operation === 'DEDUCTION'
                  ? FORMATTERS.NUMBER_WITHOUT_CURRENCY(child_event.amount) : ''}
              </td>
            </tr>
          ))}

          <tr className="row-values normal text-right">
            <td className="no-border" colSpan={3}>&nbsp;</td>
            <td className="total-cell"><strong>Total Vencimentos</strong></td>
            <td className="total-cell"><strong>Total Descontos</strong></td>
          </tr>
          <tr className="row-values normal text-right">
            <td className="no-border" colSpan={3}>&nbsp;</td>
            <td>{FORMATTERS.NUMBER_WITHOUT_CURRENCY(income_total)}</td>
            <td>{FORMATTERS.NUMBER_WITHOUT_CURRENCY(deduction_total)}</td>
          </tr>
          <tr className="row-values normal text-right">
            <td className="no-border" colSpan={3}>&nbsp;</td>
            <td className="with-background"><strong>Valor Líquido</strong></td>
            <td className="with-background">{FORMATTERS.NUMBER_WITHOUT_CURRENCY(net_amount)}</td>
          </tr>
        </tbody>
      </PayrollContentTable>

      <PayrollContentTable className="table table-bordered mt-3">
        <tbody>
          <tr className="row-header">
            <td>Salário Base</td>
            <td width="20%">Base INSS</td>
            <td width="20%">Base Cálc. FGTS</td>
            <td width="20%">FGTS do Mês</td>
            <td width="20%">Base Cálc. IRRF</td>
          </tr>
          <tr className="row-values">
            <td>{FORMATTERS.NUMBER_WITHOUT_CURRENCY(income_salary)}</td>
            <td>{FORMATTERS.NUMBER_WITHOUT_CURRENCY(income_salary)}</td>
            <td>{FORMATTERS.NUMBER_WITHOUT_CURRENCY(income_salary)}</td>
            <td>{FORMATTERS.NUMBER_WITHOUT_CURRENCY(fgts_amount)}</td>
            <td>{FORMATTERS.NUMBER_WITHOUT_CURRENCY(income_salary - deduction_inss)}</td>
          </tr>
        </tbody>
      </PayrollContentTable>
      <PayrollContentTable className="table d-none d-print-block mt-1">
        <div className="col-12 justify-content-around d-flex">
          <td>
            Declaro ter recebido a importância líquida discriminada neste recibo:
          </td>
        </div>
        <div className="col-12 justify-content-around d-flex mt-3">
          <td className="col-5 text-center">
            <hr />
            Data
          </td>
          <td className="col-5 text-center">
            <hr />
            Assinatura
          </td>
        </div>
      </PayrollContentTable>
    </div>
  );
}

SalaryReceipt.defaultProps = {
  payroll: {
    child_events: [],
  },
  company: {},
  className: null,
};

SalaryReceipt.propTypes = {
  company: PropTypes.object,
  payroll: PropTypes.object,
  className: PropTypes.string,
};

export default SalaryReceipt;
