import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IoSearch } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import {
  Card,
  CardBody,
  Button,
  Skeleton,
} from '_components/_core';
import { EmptyIcon } from '_components/_shared';
import FORMATTERS from 'helpers/formatters';

import { TradingName, CompanyInfo, AlertMessage } from './styles';

export default function MediumLayout({
  company,
  isLoading,
  companies,
  onModalToggle,
}) {
  const history = useHistory();

  const { trading_name, document_number } = company || {};

  const formattedCnpj = useMemo(() => {
    if (!document_number) {
      return '';
    }

    return FORMATTERS.CPF_CNPJ(document_number);
  }, [document_number]);

  const renderContent = useCallback(() => {
    if (isLoading) {
      return (
        <>
          <img
            src="assets/img/company-logo.png"
            className="img-lg mb-2"
            alt="Empresa Ativa"
          />
          <div className="d-flex flex-column align-items-center mt-2 mb-3">
            <Skeleton width="80%" className="mb-2" />
            <Skeleton width="50%" />
          </div>
          <hr />
        </>
      );
    }

    if (isEmpty(companies)) {
      return (
        <>
          <EmptyIcon size="medium" />
          <AlertMessage>
            Nenhuma empresa encontrada
          </AlertMessage>
        </>
      );
    }

    if (!company) {
      return (
        <>
          <EmptyIcon size="medium" />
          <AlertMessage>Nenhuma empresa ativa</AlertMessage>
        </>
      );
    }

    return (
      <>
        <img
          src="assets/img/company-logo.png"
          className="img-lg mb-2"
          alt="Empresa Ativa"
        />
        <CompanyInfo onClick={() => history.push('/empresa-ativa')}>
          <TradingName>
            {trading_name && trading_name.toLowerCase()}
          </TradingName>
          <p className="text-muted">{formattedCnpj}</p>
        </CompanyInfo>
        <hr />
      </>
    );
  }, [company, companies, history, formattedCnpj, isLoading, trading_name]);

  const renderButton = useCallback(() => {
    if (isEmpty(companies)) {
      return (
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={() => history.push('/empresas/criar')}
        >
          Cadastrar Empresa
        </Button>
      );
    }

    return (
      <Button
        icon={<IoSearch />}
        disabled={isLoading}
        isLoading={isLoading}
        onClick={onModalToggle}
      >
        Trocar Empresa
      </Button>
    );
  }, [companies, history, isLoading, onModalToggle]);

  return (
    <>
      <Card className="text-center">
        <CardBody className="card-body">
          {renderContent()}
          <Row>
            <Col>
              {renderButton()}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

MediumLayout.propTypes = {
  companies: PropTypes.array,
  company: PropTypes.object,
  isLoading: PropTypes.bool,
  onModalToggle: PropTypes.func.isRequired,
};
