import FORMATTERS from 'helpers/formatters';

import constants from '../../_constants/company.constants';

const fetchCnpjCertificate = (cnpj) => {
  return (dispatch) => {
    dispatch(request());

    const api_url = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');

    window.open(`${api_url}/cnpj-certificate/${FORMATTERS.CLEAN_CPF_CNPJ(cnpj)}?token=${token}`, '_blank');
  };

  function request() {
    return {
      type: constants.COMPANY_FETCH_CNPJ_CERTIFICATE_REQUEST,
      payload: {},
    };
  }
};

export default fetchCnpjCertificate;
