import constants from '../../_constants/accounts.constants';

const newAccount = () => {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: constants.ACCOUNT_NEW_REQUEST, payload: {} };
  }
};

export default newAccount;
