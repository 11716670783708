import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/accounts.constants';
import service from '../../../services/accounts.service';
import alertActionCreators from '../alert.actions';

const deleteAccount = (id, callback) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Exclusão de Conta Contábil',
      message: 'Tem certeza que deseja excluir esta conta contábil? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, deletar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service.remove(id).then(() => {
          dispatch(success(id));
          dispatch(alertActionCreators.success('Conta Contábil excluída com sucesso!'));
          callback();
        }).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request(id) {
    return {
      type: constants.ACCOUNT_DELETE_REQUEST,
      payload: { id },
    };
  }
  function success(account) {
    return {
      type: constants.ACCOUNT_DELETE_SUCCESS,
      payload: { account },
    };
  }
  function failure(error) {
    return {
      type: constants.ACCOUNT_DELETE_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default deleteAccount;
