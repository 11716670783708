import React from 'react';

function AlwaysXDayHelpText() {
  return (
    <p>
      Esta opção irá manter as datas dos
      lançamentos selecionados, alterando somente o dia para o que foi informado no campo acima:
      <br />
      <br />
      Exemplo: se você definir o dia
      {' '}
      <strong>20</strong>
      <br />
      <ul className="mt-2 mb-0">
        <li>
          Lançamento com a data
          {' '}
          <strong>15/01/2021</strong>
          {' '}
          será alterado para
          {' '}
          <strong>20/01/2021</strong>
        </li>
        <li>
          Lançamento com a data
          {' '}
          <strong>25/01/2021</strong>
          {' '}
          será alterado para
          {' '}
          <strong>20/01/2021</strong>
        </li>
        <li>
          Lançamento com a data
          {' '}
          <strong>13/02/2021</strong>
          {' '}
          será alterado para
          {' '}
          <strong>20/02/2021</strong>
        </li>
      </ul>
      <br />
      Caso o dia escolhido não exista naquele mês, será utilizado o primeiro dia anterior válido.
      {' '}
      <br />
      Exemplo:
      dia selecionado
      {' '}
      <strong>31</strong>
      {' '}
      se torna
      {' '}
      <strong>28/02/2021</strong>
      {' '}
      no caso do mês de Fevereiro.
    </p>
  );
}

export default AlwaysXDayHelpText;
