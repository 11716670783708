import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/taxes.constants';
import service from '../../../services/taxes.service';

import alertActionCreators from '../alert.actions';

const deleteMultipleDarfs = (params, callback) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Exclusão de Guias',
      message: 'Tem certeza que deseja excluir as guias selecionadas? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, deletar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(params));

        service.deleteMultipleDarfs(params).then(() => {
          dispatch(success(params));
          dispatch(alertActionCreators.success('Guias excluídas com sucesso.'));

          if (callback) {
            callback();
          }
        }).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request() {
    return {
      type: constants.DELETE_DARF_REQUEST,
      payload: { },
    };
  }
  function success(data) {
    return {
      type: constants.DELETE_DARF_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_DARF_FAILURE,
      payload: error,
    };
  }
};

export default deleteMultipleDarfs;
