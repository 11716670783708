import { connect } from 'react-redux';

import employeesActionCreators from '_store/_actions/employees';

import List from './List';

const mapStateToProps = (state) => ({
  employees: state.employees.employees,
  total: state.employees.total,
});

const mapDispatchToProps = {
  onFetchEmployees: employeesActionCreators.getAllEmployees,
  onDeleteEmployee: employeesActionCreators.deleteEmployee,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(List);
