import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { IoCalendarClearOutline, IoArrowDown, IoArrowUp } from 'react-icons/io5';

import {
  Alert,
  Button,
  FormSelectField,
  FormDateField,
} from '_components/_core';
import {
  NewDateHelpText,
  AlwaysFirstDayHelpText,
  AlwaysLastDayHelpText,
  AlwaysXDayHelpText,
} from './components';
import { CHANGE_TYPE, DAYS } from './utilities';
import { StyledModal, ModalFooter } from './styles';

const UpdateEntriesDate = ({
  isLoading,
  selectedItems,
  onUpdateEntries,
  onComplete,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHelpVisible, setIsHelpVisible] = useState(false);

  const handleUpdateDates = useCallback((values, { resetForm }) => {
    onUpdateEntries({
      ids: selectedItems,
      type: 'date',
      payload: {
        ...values,
      },
    }, () => {
      resetForm();
      setIsVisible(false);

      if (onComplete) {
        onComplete();
      }
    });
  }, [selectedItems, onComplete, onUpdateEntries]);

  const isValid = useCallback((values) => {
    if (!values) {
      return false;
    }

    if (values.change_type === 'always_first_day' || values.change_type === 'always_last_day') {
      return true;
    }

    if (values.change_type === 'new_date' && !values.event_date) {
      return false;
    }

    if (values.change_type === 'always_x_day' && !values.fixed_day) {
      return false;
    }

    return true;
  }, []);

  const renderModalFooter = useCallback((handleSubmit, values) => (
    <ModalFooter>
      <Button variant="secondary" onClick={() => setIsVisible(false)}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={isLoading || !isValid(values)}
      >
        {`Alterar data de ${selectedItems.length} ${pluralize('lançamento', selectedItems.length)}`}
      </Button>
    </ModalFooter>
  ), [isLoading, selectedItems, isValid]);

  const HELP_TEXT = useMemo(() => ({
    new_date: <NewDateHelpText />,
    always_first_day: <AlwaysFirstDayHelpText />,
    always_last_day: <AlwaysLastDayHelpText />,
    always_x_day: <AlwaysXDayHelpText />,
  }), []);

  return (
    <Formik
      initialValues={{
        change_type: 'new_date',
        event_date: null,
        fixed_day: null,
      }}
      onSubmit={handleUpdateDates}
      enableReinitialize
    >
      {({
        handleSubmit,
        values,
      }) => (
        <>
          <Button
            className="mr-2 btn-sm"
            variant="light"
            onClick={() => setIsVisible(true)}
            icon={<IoCalendarClearOutline />}
          >
            Alterar Data
          </Button>
          <StyledModal
            title="Alterar Data"
            isVisible={isVisible}
            toggleModal={() => setIsVisible(false)}
            footer={renderModalFooter(handleSubmit, values)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="6" className="mb-0">
                  <Form.Label>Tipo de Alteração</Form.Label>
                  <FormSelectField name="change_type" options={CHANGE_TYPE} />
                </Form.Group>
                {values.change_type === 'new_date' && (
                  <Form.Group as={Col} md="6" className="mb-0">
                    <Form.Label>Nova Data</Form.Label>
                    <FormDateField
                      name="event_date"
                      placeholder="Data do Lançamento"
                    />
                  </Form.Group>
                )}
                {values.change_type === 'always_x_day' && (
                  <Form.Group as={Col} md="6" className="mb-0">
                    <Form.Label>Selecione um dia</Form.Label>
                    <FormSelectField
                      name="fixed_day"
                      placeholder="Selecione um dia (de 1 até 31)"
                      options={DAYS}
                    />
                  </Form.Group>
                )}
              </Form.Row>
              <Button
                className="pl-0 pb-0 mt-2 mb-1"
                variant="link"
                icon={isHelpVisible ? <IoArrowUp /> : <IoArrowDown />}
                onClick={() => setIsHelpVisible(!isHelpVisible)}
              >
                {isHelpVisible ? 'Esconder ajuda' : 'Exibir ajuda'}
              </Button>
              {isHelpVisible && (
                <Form.Row className="mt-3">
                  <Col>
                    <Alert
                      title={CHANGE_TYPE.find((type) => type.value === values.change_type).label}
                      variant="info"
                      description={HELP_TEXT[values.change_type]}
                    />
                  </Col>
                </Form.Row>
              )}
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default UpdateEntriesDate;

UpdateEntriesDate.defaultProps = {
  isLoading: false,
  onComplete: null,
};

UpdateEntriesDate.propTypes = {
  isLoading: PropTypes.func,
  selectedItems: PropTypes.array.isRequired,
  onUpdateEntries: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
};
