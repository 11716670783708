import { connect } from 'react-redux';

import transactionsActionCreators from '_store/_actions/companyTransactions';

import ReconcileContainer from './Reconcile';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  narrations: [],
  transactions: state.companyTransactions.bankStatementTransactions,
  total: state.companyTransactions.bankStatementTransactionsTotal,
  accounts: state.companyTransactions.accounts,
  isLoading: state.companyTransactions.isLoading,
  recipients: state.companyTransactions.recipients,
  categories: state.companyTransactions.categories,
  bankStatementSummary: state.companyTransactions.bankStatementSummary,
});

const mapDispatchToProps = {
  onFetchAccounts: transactionsActionCreators.fetchAllAccounts,
  onConfirmBankStatementUpload: transactionsActionCreators.confirmBankStatementUpload,
  onAbortBankStatementUpload: transactionsActionCreators.abortBankStatementUpload,
  onFetchBankStatementTransactions: transactionsActionCreators.fetchBankStatementTransactions,
  onFetchBankStatementSummary: transactionsActionCreators.fetchBankStatementSummary,
  onFetchCategories: transactionsActionCreators.fetchAllCategories,
  onFetchRecipients: transactionsActionCreators.fetchAllRecipients,
  onFetchTags: transactionsActionCreators.fetchAllTags,
  onFetchCostCenters: transactionsActionCreators.fetchAllCostsCenter,
  onUpdateMultipleTransactions: transactionsActionCreators.updateMultipleTransactions,
  onUpdateTransactionsDescriptions: transactionsActionCreators.updateTransactionsDescriptions,
  onUpdateTransactionsTransferDetails: transactionsActionCreators.updateTransactionsTransferDetails,
  onDeleteMultipleTransactions: transactionsActionCreators.deleteMultipleTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReconcileContainer);
