import styled, { css } from 'styled-components';

import { FormSelectField, Button } from '_components/_core';
import { CategorySelect as CustomCategorySelect } from '_components/_shared';
import { CustomSelectField } from '_components/Financial/BankStatement/Reconcile/components';

export const StyledFormSelectField = styled(FormSelectField)`

`;

export const StyledContainer = styled.div`
  background-color: var(--card-bg-color);
  padding-top: 1em;
  padding-left: 15px;
  padding-right: 15px;
  border-top-left-radius: 8px;

  ${({ variant }) => variant === 'selectedItems' && css`
    height: 59.9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    padding-left: 15px;
    font-size: 1.2em;
    line-height: 28px;
    background-color: #393a3d;
    color: #d4d7dc;

    svg {
      cursor: pointer;
    }
  `}

  ${({ isMobile }) => isMobile && css`
    padding-top: 0;
  `}
`;

export const StyledToggleButton = styled(Button)`
  font-size: 0.8rem !important;

  &:hover {
    color: #000 !important;
  }

  &:active,
  &:visited,
  &:focus, 
  &:hover {
    text-decoration: none !important;
  }
`;

export const CategorySelect = styled(CustomCategorySelect)`
  width: 200px;
  margin-right: 12px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-top: 8px;
  }
`;

export const RecipientSelect = styled(CustomSelectField)`
  width: 220px;
  margin-right: 12px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-top: 8px;
  }
`;

export const StyledDisabledControl = styled.input`
  font-size: 0.8rem;
  vertical-align: top;
  font-weight: 500;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: 0 !important;
  background-color: #e9ecef;
  border: 1px solid #E8E7EA;
  border-radius: 8px;
  line-height: 14px;
  width: 134.5px;
  height: 32.8px;

  cursor: not-allowed;
`;

export const StyledDeleteButton = styled(Button)`
  color:#e3e5e8 !important;
  border: 2px solid #8D9096;
  border-radius: 8px;
  background: transparent !important;
  
`;
