import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';

import { PageHeader, TableLookup, Tabs } from '_components/_core';

import { Container } from 'react-bootstrap';
import { DEFAULT_COLUMNS, TABLE_COLUMNS } from './utilities';
import CategoryForm from './CategoryForm';

function Categories({
  activeCompany,
  categories,
  onFetchCategories,
  onDeleteCategory,
  onCreateCategory,
  onUpdateCategory,
}) {
  const [activeTab, setActiveTab] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);

  const [sorting, setSorting] = useState({
    field: 'description',
    order: 'asc',
  });

  useEffect(() => {
    onFetchCategories();
  }, [onFetchCategories, activeCompany]);

  const handleEdit = useCallback((id) => {
    setSelectedItem(null);

    const item = categories.find((item) => item.id === id);

    setSelectedItem(item);
  }, [categories]);

  const handleClear = useCallback(() => {
    setSelectedItem(null);
  }, []);

  const handleDelete = useCallback((id) => {
    onDeleteCategory(id);
  }, [onDeleteCategory]);

  const handleSorting = useCallback(({ field, order }) => {
    setSorting({
      field,
      order,
    });
  }, []);

  const sortedData = useMemo(() => {
    if (!sorting) {
      return categories;
    }

    return orderBy(categories, sorting.field, sorting.order);
  }, [categories, sorting]);

  const getTabs = useCallback(() => [
    {
      id: 1,
      title: 'Recebimentos',
      content: (
        <TableLookup
          keyName="id"
          data={sortedData.filter((item) => item.type === 'INCOME')}
          onEdit={handleEdit}
          onDelete={handleDelete}
          sorting={sorting}
          onSorting={handleSorting}
          columns={TABLE_COLUMNS}
          defaultColumns={DEFAULT_COLUMNS}
        />
      ),
    },
    {
      id: 2,
      title: 'Despesas Fixas',
      content: (
        <TableLookup
          keyName="id"
          data={sortedData.filter(
            (item) => item.type === 'EXPENSE' && item.sub_type === 'FIXED_EXPENSE',
          )}
          onEdit={handleEdit}
          onDelete={handleDelete}
          sorting={sorting}
          onSorting={handleSorting}
          columns={TABLE_COLUMNS}
          defaultColumns={DEFAULT_COLUMNS}
        />
      ),
    },
    {
      id: 3,
      title: 'Despesas Variáveis',
      content: (
        <TableLookup
          keyName="id"
          data={sortedData.filter(
            (item) => item.type === 'EXPENSE' && item.sub_type === 'VARIABLE_EXPENSE',
          )}
          onEdit={handleEdit}
          onDelete={handleDelete}
          sorting={sorting}
          onSorting={handleSorting}
          columns={TABLE_COLUMNS}
          defaultColumns={DEFAULT_COLUMNS}
        />
      ),
    },
    {
      id: 4,
      title: 'Pessoas',
      content: (
        <TableLookup
          keyName="id"
          data={sortedData.filter(
            (item) => item.type === 'EXPENSE' && item.sub_type === 'PEOPLE',
          )}
          onEdit={handleEdit}
          onDelete={handleDelete}
          sorting={sorting}
          onSorting={handleSorting}
          columns={TABLE_COLUMNS}
          defaultColumns={DEFAULT_COLUMNS}
        />
      ),
    },
    {
      id: 5,
      title: 'Impostos',
      content: (
        <TableLookup
          keyName="id"
          data={sortedData.filter(
            (item) => item.type === 'EXPENSE' && item.sub_type === 'TAXES',
          )}
          onEdit={handleEdit}
          onDelete={handleDelete}
          sorting={sorting}
          onSorting={handleSorting}
          columns={TABLE_COLUMNS}
          defaultColumns={DEFAULT_COLUMNS}
        />
      ),
    },
  ], [
    sortedData,
    handleEdit,
    handleDelete,
    sorting,
    handleSorting,
  ]);

  const formProps = useMemo(() => {
    switch (activeTab) {
      case '1':
        return {
          type: 'INCOME',
          sub_type: null,
          category: selectedItem,
          onCreateCategory,
          onUpdateCategory,
          onClearCategory: handleClear,
        };
      case '2':
        return {
          type: 'EXPENSE',
          sub_type: 'FIXED_EXPENSE',
          category: selectedItem,
          onCreateCategory,
          onUpdateCategory,
          onClearCategory: handleClear,
        };
      case '3':
        return {
          type: 'EXPENSE',
          sub_type: 'VARIABLE_EXPENSE',
          category: selectedItem,
          onCreateCategory,
          onUpdateCategory,
          onClearCategory: handleClear,
        };
      case '4':
        return {
          type: 'EXPENSE',
          sub_type: 'PEOPLE',
          category: selectedItem,
          onCreateCategory,
          onUpdateCategory,
          onClearCategory: handleClear,
        };
      case '5':
        return {
          type: 'EXPENSE',
          sub_type: 'TAXES',
          category: selectedItem,
          onCreateCategory,
          onUpdateCategory,
          onClearCategory: handleClear,
        };
      default:
        return {
          type: 'INCOME',
          sub_type: null,
          category: selectedItem,
          onCreateCategory,
          onUpdateCategory,
          onClearCategory: handleClear,
        };
    }
  }, [activeTab, selectedItem, handleClear, onCreateCategory, onUpdateCategory]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const categoryId = urlParams.get('category_id');
    const type = urlParams.get('type');
    const sub_type = urlParams.get('sub_type');

    if (categoryId) {
      const tabName = type === 'INCOME' ? 'INCOME' : sub_type;

      const tabIdName = {
        INCOME: '1',
        FIXED_EXPENSE: '2',
        VARIABLE_EXPENSE: '3',
        PEOPLE: '4',
        TAXES: '5',
      };

      setActiveTab(tabIdName[tabName]);
      handleEdit(categoryId);
    }
  }, [handleEdit]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader title="Categorias" variant="small" />
      <h4 className="mb-3 mt-4 mt-lg-0">
        {selectedItem ? 'Editar Categoria' : 'Criar Categoria'}
      </h4>
      <CategoryForm {...formProps} />
      <Tabs
        tabs={getTabs()}
        variant="secondary"
        onTabChange={(tab) => setActiveTab(tab)}
        activeTab={activeTab}
      />
    </Container>
  );
}

Categories.defaultProps = {
  categories: [],
};

Categories.propTypes = {
  activeCompany: PropTypes.object,
  categories: PropTypes.any,
  isLoading: PropTypes.func,
  onFetchCategories: PropTypes.func.isRequired,
  onDeleteCategory: PropTypes.func.isRequired,
  onCreateCategory: PropTypes.func.isRequired,
  onUpdateCategory: PropTypes.func.isRequired,
};

export default Categories;
