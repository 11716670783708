import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';

import { StyledAlertHeading } from './styles';

export default function ImportAlert({ activeCompany }) {
  const {
    id: company_id,
  } = activeCompany || {};

  return (
    <Alert variant="danger">
      <StyledAlertHeading>
        Atenção!
      </StyledAlertHeading>
      <p>
        Para realizar a
        {' '}
        <b>Importação de Notas Fiscais</b>
        {' '}
        é necessário completar as seguintes informações no cadastro de empresas:
      </p>
      <ul>
        <li>Cidade</li>
        <li>Estado</li>
      </ul>
      <p>
        <Link to={`/empresas/${company_id}/editar`}>
          Clique aqui
        </Link>
        {' '}
        para alterar as informações da empresa.
      </p>
    </Alert>
  );
}

ImportAlert.propTypes = {
  activeCompany: PropTypes.func.isRequired,
};
