import styled from 'styled-components';
import ListGroup from 'react-bootstrap/ListGroup';

export const Menu = styled(ListGroup)`
  font-family: var(--font-family);
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
  rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
  rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, 
  rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: var(--card-border);
  background-color: var(--card-bg-color);

  button {
    cursor: pointer;
    border: none;
    border-radius: 0 !important;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 32px;
  }

  button.active {
    color: var(--accent-color);
    background-color: var(--accent-color-light);
    font-weight: 500;
  }
`;

export const MenuCategory = styled.span`
  padding: 18px 20px 14px 20px;
  color: rgba(26,51,83,.6);
  font-size: 12px;
  line-height: 1.5;
  font-weight: 700;
  text-transform: uppercase;
`;

export const MenuItem = styled(ListGroup.Item)`
  
`;
