import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from 'react-bootstrap/ProgressBar';
import classNames from 'classnames';

import {
  Card,
  CardBody,
  CardHeader,
  HintIcon,
} from '_components/_core';
import FORMATTERS from 'helpers/formatters';
import { round } from 'lodash';

function AttachmentsQuota({ consumedStorage }) {
  const Line = ({ label, value, muted }) => (
    <small className={classNames('d-flex justify-content-between align-items-center mt-2 mb-2', muted ? 'text-muted' : '')}>
      <span>
        {label}
      </span>
      <span>
        {value}
      </span>
    </small>
  );

  return (
    <Card>
      <CardHeader
        title="Espaço de comprovantes"
        sideContent={(
          <HintIcon hint="Aqui você pode ver o total de espaço gasto e disponível para os anexos de suas movimentações" />
        )}
        leftColSize={10}
        rightColSize={2}
        adjustSideContentMobile={false}
      />
      <CardBody
        noPaddingTop
        style={{
          marginTop: '-20px',
        }}
      >
        <Line
          label={(
            <span className="d-flex justify-content-center align-items-center">
              {FORMATTERS.PERCENTAGE(consumedStorage.percentage_used)}
            </span>
          )}
          value={(
            <span className="d-flex justify-content-center align-items-center">
              <span className="mr-2">Total:</span><strong className="mr-2">{round(consumedStorage.total || 0, 2)}GB</strong>/
              <span className="ml-2">Disponível:</span><strong className="ml-2">{round(consumedStorage.remaining || 0, 2)}GB</strong>
            </span>
          )}
        />
        <ProgressBar
          style={{
            height: '1rem',
          }}
          now={consumedStorage.percentage_used || 0}
          variant="default"
        />
      </CardBody>
    </Card>
  );
}

AttachmentsQuota.defaultProps = {
  consumedStorage: {
    percentage_used: 0,
    total: 0,
    remaining: 0,
  },
};

AttachmentsQuota.propTypes = {
  consumedStorage: PropTypes.object,
};

export default AttachmentsQuota;
