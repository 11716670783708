import constants from '../../_constants/accounts.constants';
import service from '../../../services/accounts.service';

const getAllAccounts = () => {
  return (dispatch) => {
    dispatch(request());

    service.getAll().then(
      (response) => {
        dispatch(success(response.data));
      },
    );
  };

  function request() {
    return { type: constants.ACCOUNT_GET_ALL_REQUEST, payload: {} };
  }
  function success(accounts) {
    return {
      type: constants.ACCOUNT_GET_ALL_SUCCESS,
      payload: { accounts },
    };
  }
};

export default getAllAccounts;
