import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import { useHistory } from 'react-router-dom';

import { PageHeader, Tabs } from '_components/_core';
import { InvoiceIssuingPaywall } from '_components/_shared';

import InvoiceForm from './components/InvoiceForm';
import InvoiceHistory from './components/InvoiceHistory';
import InvoicePersons from './components/InvoicePersons/InvoicePersons';

function Create({
  persons,
  personsTotal,
  states,
  cities,
  activeCompany,
  isLoading,
  isLoadingParams,
  invoiceGenerationParams,
  issuedInvoices,
  issuedInvoicesTotal,
  isLoadingCep,
  onFetchInvoiceGenerationParams,
  onCreateIssuedInvoice,
  onUpdateIssuedInvoice,
  onFetchIssuedInvoices,
  onFetchIssuedInvoiceById,
  onConfirmInvoicesIssuing,
  onDeleteIssuedInvoices,
  onDuplicateIssuedInvoices,
  onDeleteIssuedInvoice,
  onAddPerson,
  onUpdatePerson,
  onDeletePerson,
  onFetchPersons,
  onFetchCities,
  onFetchStates,
  onFetchCep,
}) {
  const history = useHistory();
  const { allow_invoice_issuing } = activeCompany || {};
  const [activeTab, setActiveTab] = useState(1);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  useEffect(() => {
    if (!allow_invoice_issuing) {
      return;
    }

    onFetchInvoiceGenerationParams();
  }, [onFetchInvoiceGenerationParams, allow_invoice_issuing, activeCompany]);

  const handleEditIssuedInvoice = useCallback((id) => {
    setActiveTab(1);
    onFetchIssuedInvoiceById(id, (issuedInvoice) => {
      setSelectedInvoice(issuedInvoice);
    });
  }, [onFetchIssuedInvoiceById]);

  const handleCancelEdit = useCallback((callback) => {
    setSelectedInvoice(null);

    if (callback) {
      callback();
    }
  }, []);

  const getTabs = useCallback(() => {
    const props = {
      persons,
      personsTotal,
      states,
      cities,
      activeCompany,
      isLoading,
      isLoadingParams,
      invoiceGenerationParams,
      issuedInvoices,
      issuedInvoicesTotal,
      isLoadingCep,
      selectedInvoice,
      onFetchInvoiceGenerationParams,
      onCreateIssuedInvoice,
      onFetchIssuedInvoices,
      onUpdateIssuedInvoice,
      onEditIssuedInvoice: handleEditIssuedInvoice,
      onCancelEdit: handleCancelEdit,
      onConfirmInvoicesIssuing,
      onDeleteIssuedInvoices,
      onDeleteIssuedInvoice,
      onDuplicateIssuedInvoices,
      onAddPerson,
      onUpdatePerson,
      onDeletePerson,
      onFetchPersons,
      onFetchCities,
      onFetchStates,
      onFetchCep,
    };

    return [
      {
        id: 1,
        title: 'Emitir Nota Fiscal',
        content: <InvoiceForm {...props} />,
      },
      {
        id: 2,
        title: 'Lista de Emissões',
        content: <InvoiceHistory {...props} />,
      },
      {
        id: 3,
        title: 'Clientes',
        content: <InvoicePersons {...props} />,
      },
    ];
  }, [
    persons,
    personsTotal,
    states,
    cities,
    activeCompany,
    isLoading,
    isLoadingParams,
    invoiceGenerationParams,
    issuedInvoices,
    issuedInvoicesTotal,
    isLoadingCep,
    selectedInvoice,
    onFetchInvoiceGenerationParams,
    onCreateIssuedInvoice,
    onFetchIssuedInvoices,
    onUpdateIssuedInvoice,
    handleEditIssuedInvoice,
    handleCancelEdit,
    onConfirmInvoicesIssuing,
    onDeleteIssuedInvoices,
    onDeleteIssuedInvoice,
    onDuplicateIssuedInvoices,
    onFetchPersons,
    onUpdatePerson,
    onDeletePerson,
    onFetchCities,
    onFetchStates,
    onFetchCep,
    onAddPerson,
  ]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Emissão de Notas Fiscais"
        description="Economize horas de trabalho manual fazendo a emissão das Notas Fiscais pelo Contabills."
      />
      {!allow_invoice_issuing && (
        <InvoiceIssuingPaywall onShowActivateForm={() => history.push('/empresa-ativa?tab=emissao-nfse')} />
      )}
      {allow_invoice_issuing && (
        <Tabs
          variant="secondary"
          tabs={getTabs()}
          onTabChange={(tab) => setActiveTab(tab)}
          activeTab={activeTab}
        />
      )}
    </Container>
  );
}

Create.defaultProps = {
  invoiceGenerationParams: null,
  activeCompany: {},
  isLoading: false,
  isLoadingParams: false,
};

Create.propTypes = {
  persons: PropTypes.array,
  personsTotal: PropTypes.number,
  invoiceGenerationParams: PropTypes.object,
  activeCompany: PropTypes.object,
  isLoading: PropTypes.bool,
  isLoadingParams: PropTypes.bool,
  isLoadingCep: PropTypes.bool.isRequired,
  issuedInvoices: PropTypes.array,
  issuedInvoicesTotal: PropTypes.number,
  onFetchInvoiceGenerationParams: PropTypes.func.isRequired,
  onCreateIssuedInvoice: PropTypes.func.isRequired,
  onUpdateIssuedInvoice: PropTypes.func.isRequired,
  onFetchIssuedInvoices: PropTypes.func.isRequired,
  onFetchIssuedInvoiceById: PropTypes.func.isRequired,
  onConfirmInvoicesIssuing: PropTypes.func.isRequired,
  onDeleteIssuedInvoices: PropTypes.func.isRequired,
  onDeleteIssuedInvoice: PropTypes.func.isRequired,
  onDuplicateIssuedInvoices: PropTypes.func.isRequired,
  onFetchPersons: PropTypes.func.isRequired,
  onUpdatePerson: PropTypes.func.isRequired,
  onDeletePerson: PropTypes.func.isRequired,
  onFetchStates: PropTypes.func.isRequired,
  onFetchCities: PropTypes.func.isRequired,
  onFetchCep: PropTypes.func.isRequired,
  onAddPerson: PropTypes.func.isRequired,
  states: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
};

export default Create;
