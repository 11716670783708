import { entryConstants } from '../_constants';

const INITIAL_STATE = {
  entries: [],
  total: 0,
  type: '1D1C',
  isLoading: false,
  entry: {
    amount: 0,
    history: '',
  },
  last_entry_id: 0,
  child_entry: {},
  child_entries: [
    {},
  ],
  total_debit: 0,
  total_credit: 0,
  difference: 0,
  ui: {
    isLoading: false,
    hasErrors: false,
    errors: [],
  },
};

function getEntryTotals(state) {
  const {
    type, entry, childEntries,
  } = state;

  let totalDebit = 0;
  let totalCredit = 0;
  let difference = 0;

  switch (type) {
    case '1D1C':
      totalDebit = entry.amount;
      totalCredit = entry.amount;
      difference = totalDebit - totalCredit;
      break;
    case '1DVC':
      childEntries.forEach((item) => {
        if (item.amount) {
          totalCredit += item.amount;
        }
      });

      totalDebit = entry.amount;
      difference = totalDebit - totalCredit;
      break;
    default:
      break;
  }

  return {
    totalDebit,
    totalCredit,
    difference,
  };
}

export function entries(state = INITIAL_STATE, action) {
  switch (action.type) {
    case entryConstants.ENTRY_GET_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case entryConstants.ENTRY_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case entryConstants.ENTRY_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case entryConstants.ENTRY_GET_ALL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case entryConstants.ENTRY_GET_ALL_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        total,
        entries: data,
        isLoading: false,
      };
    }
    case entryConstants.ENTRY_GET_ALL_FAILURE: {
      return {
        ...state,
        total: 0,
        entries: [],
        isLoading: false,
      };
    }
    case entryConstants.ENTRY_ADD_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case entryConstants.ENTRY_ADD_SUCCESS: {
      return {
        ...state,
        last_entry_id: action.payload.entry.id,
        isLoading: false,
      };
    }
    case entryConstants.ENTRY_ADD_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case entryConstants.ENTRY_DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case entryConstants.ENTRY_DELETE_SUCCESS: {
      const { entry_id } = action.payload;

      const updated_entries = state.entries.filter((entry) => entry.id !== entry_id);

      return {
        ...state,
        isLoading: false,
        entries: [...updated_entries],
      };
    }
    case entryConstants.ENTRY_DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case entryConstants.ENTRY_UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case entryConstants.ENTRY_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case entryConstants.ENTRY_UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case entryConstants.ENTRY_NEW_REQUEST:
      return {
        ...state,
        type: '1D1C',
        entry: {
          amount: 0,
          history: '',
        },
        last_entry_id: 0,
        child_entry: {},
        child_entries: [],
        total_debit: 0,
        total_credit: 0,
        difference: 0,
        ui: {
          ...state.ui,
          isLoading: false,
          hasErrors: true,
          errors: action.payload.errors,
        },
      };
    case entryConstants.ENTRY_SET_ENTRY_TYPE:
      return {
        ...state,
        type: action.payload.type,
        entry: {
          amount: 0,
          history: '',
        },
        last_entry_id: 0,
        child_entry: {},
        child_entries: [
          {

          },
        ],
        total_debit: 0,
        total_credit: 0,
        difference: 0,
        ui: {
          errors: [],
          hasErrors: false,
          isLoading: false,
        },
      };
    case entryConstants.ENTRY_SET_EVENT_DATE:
      return {
        ...state,
        entry: {
          ...state.entry,
          event_date: action.payload.event_date,
        },
      };
    case entryConstants.ENTRY_SET_DEBIT_ACCOUNT:
      return {
        ...state,
        entry: {
          ...state.entry,
          debit_account_id: action.payload.debit_account.id,
          debit_account_classifier: action.payload.debit_account.classifier,
        },
      };
    case entryConstants.ENTRY_SET_CREDIT_ACCOUNT:
      return {
        ...state,
        entry: {
          ...state.entry,
          credit_account_id: action.payload.credit_account.id,
          credit_account_classifier: action.payload.credit_account.classifier,
        },
      };
    case entryConstants.ENTRY_SET_AMOUNT:
      return {
        ...state,
        entry: {
          ...state.entry,
          amount: action.payload.amount,
        },
      };
    case entryConstants.ENTRY_SET_HISTORY:
      return {
        ...state,
        entry: {
          ...state.entry,
          history: action.payload.history,
        },
      };
    case entryConstants.ENTRY_NEW_CHILD_ENTRY:
      return {
        ...state,
        child_entries: [
          ...state.child_entries,
          {},
        ],
      };
    case entryConstants.ENTRY_SET_CHILD_ENTRY_DEBIT_ACCOUNT:
      return {
        ...state,
        child_entry: {
          ...state.child_entry,
          debit_account_id: action.payload.debit_account.id,
          debit_account_classifier: action.payload.debit_account.classifier,
          debit_account: action.payload.debit_account,
        },
      };
    case entryConstants.ENTRY_SET_CHILD_ENTRY_CREDIT_ACCOUNT:
      return {
        ...state,
        child_entries: state.child_entries.map(
          (entry, i) => (i === action.payload.index ? {
            ...entry,
            credit_account_id: action.payload.credit_account.id,
            credit_account_classifier: action.payload.credit_account.classifier,
            credit_account: action.payload.credit_account,
          }
            : entry),
        ),
      };
    case entryConstants.ENTRY_SET_CHILD_ENTRY_AMOUNT:
      return {
        ...state,
        child_entries: state.child_entries.map(
          (entry, i) => (i === action.payload.index ? {
            ...entry,
            amount: action.payload.amount,
          }
            : entry),
        ),
      };
    case entryConstants.ENTRY_SET_CHILD_ENTRY_HISTORY:
      return {
        ...state,
        child_entries: state.child_entries.map(
          (entry, i) => (i === action.payload.index ? {
            ...entry,
            history: action.payload.history,
          }
            : entry),
        ),
      };
    case entryConstants.ENTRY_SET_CHILD_ENTRY_EVENT_DATE:
      return {
        ...state,
        child_entry: {
          ...state.child_entry,
          event_date: action.payload.event_date,
        },
      };
    case entryConstants.ENTRY_ADD_CHILD_ENTRY:
      return {
        ...state,
        child_entries: [...state.child_entries, state.child_entry],
        child_entry: {},
      };
    case entryConstants.ENTRY_REMOVE_CHILD_ENTRY: {
      const entryIndex = action.payload.index;

      return {
        ...state,
        child_entries: state.child_entries.filter((_item, index) => index !== entryIndex),
      };
    }
    case entryConstants.ENTRY_SET_ENTRY_TOTALS: {
      const entryTotals = getEntryTotals(state);

      return {
        ...state,
        total_debit: entryTotals.total_debit,
        total_credit: entryTotals.total_credit,
        difference: entryTotals.difference,
      };
    }

    case entryConstants.ENTRY_ADD_MANY_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case entryConstants.ENTRY_ADD_MANY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case entryConstants.ENTRY_ADD_MANY_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case entryConstants.ENTRY_UPDATE_MANY_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case entryConstants.ENTRY_UPDATE_MANY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case entryConstants.ENTRY_UPDATE_MANY_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case entryConstants.ENTRY_DELETE_MANY_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case entryConstants.ENTRY_DELETE_MANY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case entryConstants.ENTRY_DELETE_MANY_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
}
