import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button } from '_components/_core';

const DeleteMultipleContacts = ({
  isLoading,
  selectedItems,
  onDeleteMultipleContacts,
  onComplete,
}) => {
  const handleDeleteContacts = useCallback(() => {
    onDeleteMultipleContacts({
      ids: selectedItems,
    }, () => {
      if (onComplete) {
        onComplete();
      }
    });
  }, [selectedItems, onDeleteMultipleContacts, onComplete]);

  return (
    <Button
      className="mr-2 btn-sm"
      variant="outline-danger"
      onClick={handleDeleteContacts}
      disabled={isLoading}
    >
      Excluir
    </Button>
  );
};

export default DeleteMultipleContacts;

DeleteMultipleContacts.defaultProps = {
  isLoading: false,
  onComplete: null,
};

DeleteMultipleContacts.propTypes = {
  isLoading: PropTypes.bool,
  selectedItems: PropTypes.array.isRequired,
  onDeleteMultipleContacts: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
};
