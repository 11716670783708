import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from '_components/_core/Button';
import Card from '_components/_core/Card';
import CardHeader from '_components/_core/CardHeader';
import CardBody from '_components/_core/CardBody';
import Table from '_components/_core/Table';

import { TABLE_COLUMNS } from '../../utils';
import InviteUserModal from '../InviteUserModal';
import styles from './styles.module.scss';

function Container({ userState, userActions }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { getAllUsers, inviteUser } = userActions;
  const { users } = userState;
  const { data } = users;

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  const handleModalOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleUserInvite = (email) => {
    setIsLoading(true);
    inviteUser(email).then(() => {
      setIsLoading(false);
      setIsOpen(false);
    });
  };

  return (
    <div className="content-wrapper">
      <Row>
        <Col>
          <Card>
            <CardHeader
              title="Usuários"
              description="Gerencie os colaboradores da sua empresa de contabilidade por aqui."
              sideContent={<Button variant="primary" onClick={handleModalOpen}>Convidar Usuário</Button>}
            />
            <CardBody noPadding>
              <InviteUserModal
                isOpen={isOpen}
                isLoading={isLoading}
                onHide={() => setIsOpen(false)}
                onUserInvite={handleUserInvite}
              />
              <Table
                className={styles.table}
                columns={TABLE_COLUMNS}
                data={data}
                responsive
                striped
                hover
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

Container.propTypes = {
  userState: PropTypes.object,
  userActions: PropTypes.object,
};

export default withRouter(Container);
