import { connect } from 'react-redux';
import { compose } from 'redux';

import sidebarActionCreators from '_store/_actions/sidebar';
import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Reports from './Reports';

const mapStateToProps = state => ({
  isLoading: state.companyTransactions.isLoading,
  reports: state.companyTransactions.reports,
  activeCompany: state.company.activeCompany,
  collapsed: state.sidebar.collapsed,
  selected_account_id: state.companyTransactions.selected_account_id,
  cost_center_id: state.companyTransactions.cost_center_ids,
  tag_id: state.companyTransactions.tag_ids,
  tags: state.companyTransactions.tags,
  accounts: state.companyTransactions.accounts,
  balance: state.companyTransactions.balance,
  blockedPeriod: state.company.blockedPeriod,
});

const mapDispatchToProps = {
  onGenerateTransactionReport:
    companyTransactionsActionCreators.generateTransactionReport,
  onSidebarToggle: sidebarActionCreators.toggleSidebar,
  onFetchBankAccounts: companyTransactionsActionCreators.fetchAllAccounts,
  onFetchCostsCenter: companyTransactionsActionCreators.fetchAllCostsCenter,
  onFetchTags: companyTransactionsActionCreators.fetchAllTags,
  onFetchRecipients: companyTransactionsActionCreators.fetchAllRecipients,
  onFetchCategories: companyTransactionsActionCreators.fetchAllCategories,
  onFetchBankAccountBalance: companyTransactionsActionCreators.fetchBankAccountBalance,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['reports_view']),
)(Reports);
