import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import sumBy from 'lodash/sumBy';

import {
  PageHeader,
  Card,
  CardHeader,
  CardBody,
} from '_components/_core';
import FORMATTERS from 'helpers/formatters';

import { EmployeeList } from './components';

const Dashboard = ({
  activeCompany,
  onFetchEmployees,
  employees,
}) => {
  useEffect(() => {
    onFetchEmployees();
  }, [onFetchEmployees, activeCompany]);

  const payrollEstimate = useMemo(
    () => FORMATTERS.NUMBER(sumBy(employees, 'salary')),
    [employees],
  );

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Visão Geral - Folha"
      />
      <Row>
        <Col xs={12} sm={12} lg={3}>
          <Card>
            <CardHeader
              title="Folha de Pagamento"
              description="Estimativa do valor da folha de pagamento"
            />
            <CardBody noPaddingTop>
              <h3 className="card-title mb-0">
                {payrollEstimate}
              </h3>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={12} lg={3}>
          <Card>
            <CardHeader
              title="Colaboradores"
              description="Quantidade de sócios/colaboradores ativos"
            />
            <CardBody noPaddingTop>
              <h3 className="card-title mb-0">
                {`${employees.length}`}
              </h3>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} sm={12} lg={6}>
          <EmployeeList employees={employees} />
        </Col>
      </Row>
    </Container>
  );
};

Dashboard.defaultProps = {
  employees: [],
};

Dashboard.propTypes = {
  activeCompany: PropTypes.object,
  onFetchEmployees: PropTypes.func.isRequired,
  employees: PropTypes.array,
};

export default Dashboard;
