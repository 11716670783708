import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';

const addCompanyFee = (company_id, fee, callback) => {
  return async (dispatch) => {
    dispatch(request(fee));

    service.addFee(company_id, fee).then(
      (response) => {
        dispatch(success(response));
        dispatch(alertActionCreators.success('Honorário adicionado com sucesso!'));

        if (callback) {
          service.getById(company_id).then((response) => {
            callback(response.data);
          });
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
      dispatch(alertActionCreators.error('Erro ao adicionar honorário!'));
    });
  };

  function request() {
    return {
      type: constants.ADD_COMPANY_FEE_REQUEST,
      payload: { fee },
    };
  }
  function success(response) {
    return {
      type: constants.ADD_COMPANY_FEE_SUCCESS,
      payload: { response },
    };
  }
  function failure(error) {
    return {
      type: constants.ADD_COMPANY_FEE_FAILURE,
      payload: { error },
    };
  }
};

export default addCompanyFee;
