import React from 'react';
import PropTypes from 'prop-types';
import { FaPlus } from 'react-icons/fa';

import { FormSelectField } from '_components/_core';

function CustomFormSelectField({
  name,
  innerRef,
  options,
  creatable,
  metadata,
  isLoading,
  width,
  ...restProps
}) {
  return (
    <FormSelectField
      name={name}
      innerRef={innerRef}
      options={options}
      creatable={creatable}
      isLoading={isLoading}
      metadata={metadata}
      customStyles={{
        menu: (baseStyles) => ({
          ...baseStyles,
          width,
        }),
        groupHeading: (baseStyles) => ({
          ...baseStyles,
          fontSize: '12px',
          paddingBottom: '8px',
          borderBottom: '1px solid #e0e0e0',
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          cursor: 'pointer',
          fontSize: '13px',
          backgroundColor: state.isFocused ? '#eceef1' : 'transparent',
          color: state.isFocused ? '#00784e' : 'rgb(0, 0, 0)',
          fontWeight: state.isFocused ? 500 : 400,
        }),

      }}
      formatCreateLabel={(userInput) => (
        <span id="creatable-option" className="d-flex justify-content-start align-items-center">
          <FaPlus size="1em" className="mr-2" />
          {`Cadastrar "${userInput}"`}
        </span>
      )}
      {...restProps}
    />
  );
}

CustomFormSelectField.defaultProps = {
  innerRef: null,
  options: [],
  metadata: {},
  isLoading: false,
  width: '150%',
};

CustomFormSelectField.propTypes = {
  width: PropTypes.string,
  name: PropTypes.string.isRequired,
  innerRef: PropTypes.object,
  options: PropTypes.array,
  creatable: PropTypes.string,
  metadata: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default CustomFormSelectField;
