import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import { Formik } from 'formik';

import { PageHeader } from '_components/_core';

import { Filters, Results } from './components';

function EmploymentContract({
  isLoading,
  activeCompany,
  onFetchEmployees,
  employees,
}) {
  useEffect(() => {
    onFetchEmployees();
  }, [onFetchEmployees]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Contrato de Trabalho"
        description="Gere um contrato de trabalho para o funcionário selecionado."
      />
      <Formik
        initialValues={{
          selectedEmployeeId: null,
        }}
        onSubmit={() => {}}
        enableReinitialize
      >
        {({ handleSubmit, values }) => (
          <>
            <Tab.Container>
              <Row>
                <Col lg={3} md={12}>
                  <Filters
                    isLoading={isLoading}
                    handleSubmit={handleSubmit}
                    employees={employees}
                  />
                </Col>
                <Col lg={9} md={12}>
                  <Results
                    values={values}
                    activeCompany={activeCompany}
                    employees={employees}
                  />
                </Col>
              </Row>
            </Tab.Container>
          </>
        )}
      </Formik>
    </Container>
  );
}

EmploymentContract.defaultProps = {
  isLoading: false,
};

EmploymentContract.propTypes = {
  isLoading: PropTypes.bool,
  activeCompany: PropTypes.object,
  onFetchEmployees: PropTypes.func.isRequired,
  employees: PropTypes.any,
};

export default EmploymentContract;
