import { connect } from 'react-redux';

import reportsActionCreators from '_store/_actions/reports';

import RevenueChart from './RevenueChart';

const mapStateToProps = (state) => ({
  revenue: state.reports.revenue,
  isLoading: state.reports.isLoading,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onFetchRevenueReport: reportsActionCreators.getRevenueReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RevenueChart);
