import React from 'react';

import { Alert } from '_components/_core';

function EditIssuedInvoiceAlert() {
  const text = {
    LINE_1: 'Como esta Nota Fiscal ainda não foi emitida, fique à vontade para alterar os dados e depois clique em',
    LINE_2: <strong>Confirmar Alterações</strong>,
    LINE_3: '1. Você cobra do seu cliente o valor que quiser pelo serviço de emissão, exemplo: R$ 150,00',
    LINE_4: '2. Você emite as Notas pelo Contabills preenchendo apenas alguns campos.',
    LINE_5: '3. Sem precisar digitar senhas repetidas vezes, salvar PDF\'s, o Contabills faz tudo automaticamente.',
  };

  return (
    <Alert
      title="Edição de Nota Fiscal"
      variant="secondary"
      description={(
        <p>
          {text.LINE_1}
          {text.LINE_2}
          .
          {' '}
          <br />
          Para cancelar a edição clique em
          {' '}
          <strong>Cancelar</strong>
          .
        </p>
      )}
      className="mb-3"
    />
  );
}

export default EditIssuedInvoiceAlert;
