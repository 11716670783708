import fetchAllCompanyMeis from './fetchAllCompanyMeis';
import createMei from './createMei';
import updateMei from './updateMei';
import deleteMei from './deleteMei';
import fetchMeiDas from './fetchMeiDas';

export default {
  fetchAllCompanyMeis,
  createMei,
  updateMei,
  deleteMei,
  fetchMeiDas,
};
