export const reportsConstants = {
  GET_REVENUE_REPORT_REQUEST: 'GET_REVENUE_REPORT_REQUEST',
  GET_REVENUE_REPORT_SUCCESS: 'GET_REVENUE_REPORT_SUCCESS',
  GET_REVENUE_REPORT_FAILURE: 'GET_REVENUE_REPORT_FAILURE',
  CLEAR_REVENUE_REPORT_REQUEST: 'CLEAR_REVENUE_REPORT_REQUEST',
  GET_ISS_REPORT_REQUEST: 'GET_ISS_REPORT_REQUEST',
  GET_ISS_REPORT_SUCCESS: 'GET_ISS_REPORT_SUCCESS',
  GET_ISS_REPORT_FAILURE: 'GET_ISS_REPORT_FAILURE',
  CLEAR_ISS_REPORT_REQUEST: 'CLEAR_ISS_REPORT_REQUEST',
};

export default reportsConstants;
