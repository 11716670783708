import constants from '../../_constants/employees.constants';
import service from '../../../services/employees.service';

import getAllEmployeeRoles from './getEmployeeRoles';

const addEmployeeRole = (role) => {
  return (dispatch) => {
    dispatch(request());

    service.addRole(role).then(
      (response) => {
        const createdRole = response.data;

        dispatch(success(createdRole));
        dispatch(getAllEmployeeRoles());
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.EMPLOYEE_ROLES_ADD_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.EMPLOYEE_ROLES_ADD_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.EMPLOYEE_ROLES_ADD_FAILURE,
      payload: error,
    };
  }
};

export default addEmployeeRole;
