import FORMATTERS from 'helpers/formatters';

export const DEFAULT_COLUMNS = [
  'start_date',
  'deduction_per_dependent',
  'max_value',
  'aliquot',
  'deduction',
];

export const TABLE_COLUMNS = [
  {
    label: 'Vigência',
    keyName: 'start_date',
    align: 'left',
    width: 80,
    formatter: FORMATTERS.DATE_DDMMYYYY,
    order: 1,
  },
  {
    label: 'Dedução por Dependente',
    keyName: 'deduction_per_dependent',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 2,
  },
  {
    label: 'Limite',
    keyName: 'max_value',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 3,
  },
  {
    label: 'Alíquota',
    keyName: 'aliquot',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.PERCENTAGE,
    order: 4,
  },
  {
    label: 'Dedução',
    keyName: 'deduction',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 5,
  },
];

export default TABLE_COLUMNS;
