export const invoicesConstants = {
  INVOICES_GET_ALL_REQUEST: 'INVOICES_GET_ALL_REQUEST',
  INVOICES_GET_ALL_SUCCESS: 'INVOICES_GET_ALL_SUCCESS',
  INVOICES_GET_ALL_FAILURE: 'INVOICES_GET_ALL_FAILURE',

  INVOICES_UPLOAD_REQUEST: 'INVOICES_UPLOAD_REQUEST',
  INVOICES_UPLOAD_SUCCESS: 'INVOICES_UPLOAD_SUCCESS',
  INVOICES_UPLOAD_FAILURE: 'INVOICES_UPLOAD_FAILURE',

  INVOICES_DELETE_MANY_REQUEST: 'INVOICES_DELETE_MANY_REQUEST',
  INVOICES_DELETE_MANY_SUCCESS: 'INVOICES_DELETE_MANY_SUCCESS',
  INVOICES_DELETE_MANY_FAILURE: 'INVOICES_DELETE_MANY_FAILURE',

  GET_FISCAL_OVERVIEW_REQUEST: 'GET_FISCAL_OVERVIEW_REQUEST',
  GET_FISCAL_OVERVIEW_SUCCESS: 'GET_FISCAL_OVERVIEW_SUCCESS',
  GET_FISCAL_OVERVIEW_FAILURE: 'GET_FISCAL_OVERVIEW_FAILURE',

  CLEAR_UPLOAD_RESULTS: 'CLEAR_UPLOAD_RESULTS',
  CLEAR_INVOICES: 'CLEAR_INVOICES',

  GENERATE_INVOICE_ENTRIES_REQUEST: 'GENERATE_INVOICE_ENTRIES_REQUEST',
  GENERATE_INVOICE_ENTRIES_SUCCESS: 'GENERATE_INVOICE_ENTRIES_SUCCESS',
  GENERATE_INVOICE_ENTRIES_FAILURE: 'GENERATE_INVOICE_ENTRIES_FAILURE',

  SYNC_INVOICES_REQUEST: 'SYNC_INVOICES_REQUEST',
  SYNC_INVOICES_SUCCESS: 'SYNC_INVOICES_SUCCESS',
  SYNC_INVOICES_FAILURE: 'SYNC_INVOICES_FAILURE',

  GET_INVOICE_GENERATION_PARAMS_REQUEST: 'GET_INVOICE_GENERATION_PARAMS_REQUEST',
  GET_INVOICE_GENERATION_PARAMS_SUCCESS: 'GET_INVOICE_GENERATION_PARAMS_SUCCESS',
  GET_INVOICE_GENERATION_PARAMS_FAILURE: 'GET_INVOICE_GENERATION_PARAMS_FAILURE',

  CREATE_ISSUED_INVOICE_REQUEST: 'CREATE_ISSUED_INVOICE_REQUEST',
  CREATE_ISSUED_INVOICE_SUCCESS: 'CREATE_ISSUED_INVOICE_SUCCESS',
  CREATE_ISSUED_INVOICE_FAILURE: 'CREATE_ISSUED_INVOICE_FAILURE',

  UPDATE_ISSUED_INVOICE_REQUEST: 'UPDATE_ISSUED_INVOICE_REQUEST',
  UPDATE_ISSUED_INVOICE_SUCCESS: 'UPDATE_ISSUED_INVOICE_SUCCESS',
  UPDATE_ISSUED_INVOICE_FAILURE: 'UPDATE_ISSUED_INVOICE_FAILURE',

  DELETE_MULTIPLE_ISSUED_INVOICES_REQUEST: ' DELETE_MULTIPLE_ISSUED_INVOICES_REQUEST',
  DELETE_MULTIPLE_ISSUED_INVOICES_SUCCESS: ' DELETE_MULTIPLE_ISSUED_INVOICES_SUCCESS',
  DELETE_MULTIPLE_ISSUED_INVOICES_FAILURE: ' DELETE_MULTIPLE_ISSUED_INVOICES_FAILURE',

  GET_ISSUED_INVOICES_REQUEST: 'GET_ISSUED_INVOICES_REQUEST',
  GET_ISSUED_INVOICES_SUCCESS: 'GET_ISSUED_INVOICES_SUCCESS',
  GET_ISSUED_INVOICES_FAILURE: 'GET_ISSUED_INVOICES_FAILURE',

  GET_ISSUED_INVOICE_BY_ID_REQUEST: 'GET_ISSUED_INVOICE_BY_ID_REQUEST',
  GET_ISSUED_INVOICE_BY_ID_SUCCESS: 'GET_ISSUED_INVOICE_BY_ID_SUCCESS',
  GET_ISSUED_INVOICE_BY_ID_FAILURE: 'GET_ISSUED_INVOICE_BY_ID_FAILURE',

  CONFIRM_INVOICE_ISSUING_REQUEST: 'CONFIRM_INVOICE_ISSUING_REQUEST',
  CONFIRM_INVOICE_ISSUING_SUCCESS: 'CONFIRM_INVOICE_ISSUING_SUCCESS',
  CONFIRM_INVOICE_ISSUING_FAILURE: 'CONFIRM_INVOICE_ISSUING_FAILURE',

  DELETE_ISSUED_INVOICE_REQUEST: 'DELETE_ISSUED_INVOICE_REQUEST',
  DELETE_ISSUED_INVOICE_SUCCESS: 'DELETE_ISSUED_INVOICE_SUCCESS',
  DELETE_ISSUED_INVOICE_FAILURE: 'DELETE_ISSUED_INVOICE_FAILURE',

  DUPLICATE_ISSUED_INVOICES_REQUEST: 'DUPLICATE_ISSUED_INVOICES_REQUEST',
  DUPLICATE_ISSUED_INVOICES_SUCCESS: 'DUPLICATE_ISSUED_INVOICES_SUCCESS',
  DUPLICATE_ISSUED_INVOICES_FAILURE: 'DUPLICATE_ISSUED_INVOICES_FAILURE',
};

export default invoicesConstants;
