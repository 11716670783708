import Swal from 'sweetalert2';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const uploadContactsSpreadsheet = (formData, callback, errorCallback) => {
  return async (dispatch) => {
    dispatch(request());

    service.uploadContactsSpreadsheet(formData).then(
      (response) => {
        dispatch(success(response.data));

        if (callback) {
          callback(response.data);
        }
      },
    ).catch(
      (err) => {
        dispatch(failure(err));

        if (errorCallback) {
          errorCallback(err);
        }

        Swal.close();
      },
    );
  };

  function request() {
    return {
      type: constants.UPLOAD_CONTACTS_SPREADSHEET_REQUEST,
      payload: {},
    };
  }
  function success(payload) {
    return {
      type: constants.UPLOAD_CONTACTS_SPREADSHEET_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return {
      type: constants.UPLOAD_CONTACTS_SPREADSHEET_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default uploadContactsSpreadsheet;
