import * as yup from 'yup';
import { ptShort } from 'yup-locale-pt';

yup.setLocale(ptShort);

const CreateEntrySchema = yup.object().shape({
  pending_entries: yup.array()
    .of(
      yup.object().shape({
        debit_account_id: yup.string().required().nullable(),
        credit_account_id: yup.string().required().nullable(),
        event_date: yup.date(),
        narration: yup.string().required().nullable(),
        amount: yup.number().required(),
      }),
    ),
});

export default CreateEntrySchema;
