import confirmDialog from 'helpers/confirmDialog';

import FORMATTERS from 'helpers/formatters';
import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';

const activateInvoiceIssuing = (values, callback) => {
  return async (dispatch) => {
    const { company_id, new_plan_price } = values;

    confirmDialog.warning({
      title: 'Confirmar Ativação',
      message: `Após ativar a emissão de NFS-e para este cliente, 
      o novo valor do seu plano mensal será de: <strong>${FORMATTERS.NUMBER(new_plan_price)}<strong/>. 
      <br /> Deseja continuar?`,
      confirmButtonText: 'Confirmar',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(values));

        service.activateInvoiceIssuing(company_id, values).then((response) => {
          dispatch(success(response));

          if (callback) {
            service.getById(company_id).then((response) => {
              callback(response.data);
              alertActionCreators.success('Emissão de NFS-e ativada com sucesso!');
            });
          }
        }).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request(values) {
    return {
      type: constants.ACTIVATE_INVOICE_ISSUING_REQUEST,
      payload: { values },
    };
  }
  function success(response) {
    return {
      type: constants.ACTIVATE_INVOICE_ISSUING_SUCCESS,
      payload: { response },
    };
  }
  function failure(error) {
    return {
      type: constants.ACTIVATE_INVOICE_ISSUING_FAILURE,
      payload: { error },
    };
  }
};

export default activateInvoiceIssuing;
