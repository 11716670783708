import { connect } from 'react-redux';

import invoicesActionCreators from '_store/_actions/invoices';
import invoicesSelectors from '_store/_selectors/invoices';

import InvoicesReport from './InvoicesReport';

const mapStateToProps = (state) => ({
  isLoading: invoicesSelectors.isLoadingInvoicesSelector(state),
  invoices: invoicesSelectors.invoicesSelector(state),
  total: state.invoices.total,
  uploadResults: invoicesSelectors.uploadResultsSelector(state),
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onFetchInvoices: invoicesActionCreators.getAllInvoices,
  onClearInvoices: invoicesActionCreators.clearInvoices,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesReport);
