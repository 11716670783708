import clearForm from './clearForm';
import login from './login';
import logout from './logout';
import signUp from './signUp';
import passwordReset from './passwordReset';
import passwordUpdate from './passwordUpdate.js';
import completeSignUp from './completeSignUp';
import confirmEmailToken from './confirmEmailToken';
import requestEmailConfirmToken from './requestEmailConfirmToken';

export default {
  clearForm,
  login,
  logout,
  signUp,
  passwordReset,
  passwordUpdate,
  completeSignUp,
  confirmEmailToken,
  requestEmailConfirmToken,
};
