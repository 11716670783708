import axios from 'axios';

import constants from '../../../_constants/company-transactions.constants';
import service from '../../../../services/company-transactions.service';
import alertActionCreators from '../../alert.actions';

const uploadContactsFromInvoices = (payload, callback) => {
  return (dispatch) => {
    dispatch(request(payload));

    axios.post(`${process.env.REACT_APP_INVOICES_URL}/api/upload`, payload)
      .then((response) => {
        const { data } = response;

        service.uploadContactsFromInvoices({
          invoices: data,
        }).then((response) => {
          dispatch(success(response.data));

          dispatch(alertActionCreators.success('Clientes importados com sucesso!'));

          if (callback) {
            callback();
          }
        })
          .catch((error) => {
            dispatch(failure(error));
            dispatch(alertActionCreators.error('Erro ao salvar no Contabills'));
          });
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(alertActionCreators.error('Erro ao importar Notas Fiscais'));
      });
  };

  function request(payload) {
    return {
      type: constants.UPLOAD_CONTACTS_FROM_INVOICES_REQUEST,
      payload: { payload },
    };
  }
  function success(uploadResults) {
    return {
      type: constants.UPLOAD_CONTACTS_FROM_INVOICES_SUCCESS,
      payload: { uploadResults },
    };
  }
  function failure(error) {
    return {
      type: constants.UPLOAD_CONTACTS_FROM_INVOICES_FAILURE,
      payload: { error },
    };
  }
};

export default uploadContactsFromInvoices;
