import React from 'react';
import PropTypes from 'prop-types';
import { InternetConnection } from '_components/_shared';

export default function UnauthorizedLayout({ children }) {
  return (
    <>
      <InternetConnection withPaddingTop={false} />
      {children}
    </>
  );
}
UnauthorizedLayout.propTypes = {
  children: PropTypes.node,
};
