import * as yup from 'yup';
import { ptShort } from 'yup-locale-pt';

yup.setLocale(ptShort);

const ValidationSchema = yup.object().shape({
  active_period_start: yup.date().required().nullable(),
  active_period_end: yup.date().required().nullable(),
});

export default ValidationSchema;
