import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useFormikContext } from 'formik';

import {
  FormTextField,
  FormMaskedInput,
  Select,
} from '_components/_core';

import { SearchButton } from './styles';

function AddressFormFields({
  isLoadingCities,
  isLoadingStates,
  isLoadingCep,
  cities,
  states,
  onFetchStates,
  onFetchCities,
  onFetchCep,
  variant,
  columnSizes,
}) {
  const formik = useFormikContext();

  const handleCepSearch = useCallback(async (values, setFieldValue) => {
    onFetchCep(values.address_zip_code, (address) => {
      const {
        bairro,
        complemento,
        localidade,
        logradouro,
        uf,
        ibge,
        ddd,
      } = address;

      setFieldValue('address_street', logradouro);
      setFieldValue('address_number', null);
      setFieldValue('address_complement', complemento);
      setFieldValue('address_district', bairro);
      setFieldValue('address_state', uf);
      setFieldValue('address_state_ibge', Number(ddd));
      setFieldValue('address_number', '');
      onFetchCities(Number(ddd), () => {
        setFieldValue('address_city_ibge', Number(ibge));
        setFieldValue('address_city', localidade);
      });
    });
  }, [onFetchCep, onFetchCities]);

  const handleStateIbgeChange = useCallback((option, setFieldValue) => {
    setFieldValue('address_state_ibge', option.value);
    setFieldValue('address_state', option.sigla);

    onFetchCities(option.sigla, () => {
      setFieldValue('address_city', null);
      setFieldValue('address_city_ibge', null);
    });
  }, [onFetchCities]);

  const handleCityIbgeChange = useCallback((option, setFieldValue) => {
    setFieldValue('address_city_ibge', option.value);
    setFieldValue('address_city', option.label);
  }, []);

  const getSelectedState = useCallback((values) => {
    if (!values.address_state_ibge) {
      return null;
    }

    return states.find((state) => state.value === Number(values.address_state_ibge));
  }, [states]);

  const getSelectedCity = useCallback(
    (values) => {
      if (!values.address_city_ibge) {
        return null;
      }

      return cities.find((city) => Number(city.value) === Number(values.address_city_ibge));
    }, [cities],
  );

  useEffect(() => {
    onFetchStates();
  }, [onFetchStates]);

  useEffect(() => {
    if (formik.values.address_state) {
      onFetchCities(formik.values.address_state);
    }
  }, [formik.values.address_state, onFetchCities]);

  if (variant === 'STATE_AND_CITY') {
    return (
      <Form.Row>
        <Form.Group as={Col} lg="6">
          <Form.Label>Estado</Form.Label>
          <Select
            name="address_state_ibge"
            onChange={(option) => handleStateIbgeChange(option, formik.setFieldValue)}
            value={getSelectedState(formik.values)}
            options={states}
            isLoading={isLoadingStates}
            clearable
          />
        </Form.Group>
        <Form.Group as={Col} lg="6">
          <Form.Label>Cidade</Form.Label>
          <Select
            name="address_city_ibge"
            onChange={(option) => handleCityIbgeChange(option, formik.setFieldValue)}
            value={getSelectedCity(formik.values)}
            options={cities}
            isLoading={isLoadingCities}
          />
        </Form.Group>
      </Form.Row>
    );
  }

  return (
    <>
      <Form.Row>
        <Form.Group as={Col} xs={columnSizes.cep}>
          <Form.Label>CEP</Form.Label>
          <FormMaskedInput
            type="ZIP_CODE"
            name="address_zip_code"
            placeholder="CEP"
          />
        </Form.Group>
        <Form.Group as={Col} xs={columnSizes.searchButton}>
          <SearchButton
            disabled={!formik.values.address_zip_code}
            type="button"
            variant="secondary"
            isLoading={isLoadingCep}
            onClick={() => handleCepSearch(formik.values, formik.setFieldValue)}
          >
            Buscar CEP
          </SearchButton>
        </Form.Group>
        <Form.Group as={Col} xs={columnSizes.street}>
          <Form.Label>Logradouro</Form.Label>
          <FormTextField
            name="address_street"
            placeholder="Logradouro"
          />
        </Form.Group>
        <Form.Group as={Col} xs={columnSizes.number}>
          <Form.Label>Número</Form.Label>
          <FormTextField name="address_number" placeholder="Número" />
        </Form.Group>
        <Form.Group as={Col} xs={columnSizes.district}>
          <Form.Label>Bairro</Form.Label>
          <FormTextField name="address_district" placeholder="Bairro" />
        </Form.Group>
        <Form.Group as={Col} xs={columnSizes.complement}>
          <Form.Label>Complemento</Form.Label>
          <FormTextField
            name="address_complement"
            placeholder="Complemento"
          />
        </Form.Group>
        <Form.Group as={Col} xs={columnSizes.state}>
          <Form.Label>Estado</Form.Label>
          <Select
            name="address_state_ibge"
            onChange={(option) => handleStateIbgeChange(option, formik.setFieldValue)}
            value={getSelectedState(formik.values)}
            options={states}
            isLoading={isLoadingStates}
          />
        </Form.Group>
        <Form.Group as={Col} xs={columnSizes.city}>
          <Form.Label>Cidade</Form.Label>
          <Select
            name="address_city_ibge"
            onChange={(option) => handleCityIbgeChange(option, formik.setFieldValue)}
            value={getSelectedCity(formik.values)}
            options={cities}
            isLoading={isLoadingCities}
          />
        </Form.Group>
      </Form.Row>
    </>
  );
}

export default AddressFormFields;

AddressFormFields.defaultProps = {
  isLoadingCep: false,
  isLoadingCities: false,
  isLoadingStates: false,
  variant: 'default',
  columnSizes: {
    cep: '4',
    searchButton: '3',
    street: '4',
    number: '4',
    district: '4',
    complement: '4',
    state: '4',
    city: '4',
  },
};

AddressFormFields.propTypes = {
  columnSizes: PropTypes.object,
  variant: PropTypes.string,
  states: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  isLoadingCep: PropTypes.func,
  isLoadingCities: PropTypes.func,
  isLoadingStates: PropTypes.func,
  onFetchStates: PropTypes.func.isRequired,
  onFetchCities: PropTypes.func.isRequired,
  onFetchCep: PropTypes.func.isRequired,
};
