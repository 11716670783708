import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';

const goToUpdatePaymentSession = (callback) => {
  return (dispatch) => {
    dispatch(request());

    service.createUpdatePaymentSession().then(
      (response) => {
        const session_url = response.data;

        dispatch(success(session_url));

        if (callback) {
          callback(session_url);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.CREATE_UPDATE_PAYMENT_SESSION_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.CREATE_UPDATE_PAYMENT_SESSION_SUCCESS,
      payload: {
        data,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.CREATE_UPDATE_PAYMENT_SESSION_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default goToUpdatePaymentSession;
