import styled, { css } from 'styled-components';

import { FormCurrencyField, Button } from '_components/_core';

export const StyledFormCurrencyField = styled(FormCurrencyField)`
  color: #11924F;
  font-size: 40px;
  font-weight: 800;
  border: 0;
  text-align: center;

  ${({ selectedType }) => selectedType === 'EXPENSE' && css`
    color: #d52b1e !important;
  `}

  ${({ selectedType }) => selectedType === 'TRANSFER' && css`
    color: #6255ff !important;
  `}

  :focus {
    color: #11924F;

    ${({ selectedType }) => selectedType === 'EXPENSE' && css`
      color: #d52b1e !important;
    `}

    ${({ selectedType }) => selectedType === 'TRANSFER' && css`
      color: #6255ff !important;
    `}
  }

`;

export const StyledPaidToggle = styled.div`
  display: flex;
  padding: 5px 20px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #F6F5FA;
`;

export const StyledButton = styled(Button)`
  background-color: #11924F;
  border: 0;
  min-width: 200px;
  
  :hover {
    background-color: #0A502B;
  }
  
  :active {
    background-color: #0A502B;
  }
`;

export const StyledFileList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 8px;

  li {
    display: flex;
    align-items: center;
    font-weight: 400;
    

    &:hover {
      color: #11924F;
      cursor: pointer;

      svg {
        fill: unset;
      }
    }
  }

  svg {
    margin-left: 8px;
    cursor: pointer;

    &:hover {
      fill: #d52b1e;
    }
  }
`;

export const StyledSubmit = styled(Button)`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    padding: 0;
    margin: 0;
  }
`;
