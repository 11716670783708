import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const updateSplitTransaction = (id, params, callback) => {
  return async (dispatch) => {
    dispatch(request(params));

    service.updateSplitTransaction(id, params).then(
      (response) => {
        dispatch(success(response.data));
        dispatch(alertActionCreators.success('A divisão foi atualizada com sucesso!'));

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(params) {
    return {
      type: constants.UPDATE_SPLIT_TRANSACTION_REQUEST,
      payload: params,
    };
  }
  function success(data) {
    return {
      type: constants.UPDATE_SPLIT_TRANSACTION_SUCCESS,
      payload: {
        transactions: data,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.UPDATE_SPLIT_TRANSACTION_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default updateSplitTransaction;
