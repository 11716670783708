import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';

const updatePreferences = (options) => {
  return (dispatch) => {
    dispatch(request(options));

    service.updateUserPreferences(options).then(
      (response) => {
        const updatedUser = response.data;

        const {
          show_all_bank_accounts,
          show_old_items_payment_confirmation,
          receive_expiring_transactions_mail,
        } = updatedUser;

        const user = JSON.parse(localStorage.getItem('user'));

        user.preferences = {
          show_all_bank_accounts,
          show_old_items_payment_confirmation,
          receive_expiring_transactions_mail,
        };

        localStorage.setItem('user', JSON.stringify(user));

        dispatch(success(updatedUser));
      },
    ).catch(({ response }) => {
      if (response.status === 422) {
        const { message } = response.data;

        dispatch(failure(message));
      }
    });
  };

  function request(options) {
    return { type: constants.USER_UPDATE_PREFERENCES_REQUEST, payload: { options } };
  }
  function success(options) {
    return {
      type: constants.USER_UPDATE_PREFERENCES_SUCCESS,
      payload: { options },
    };
  }
  function failure(message) {
    return {
      type: constants.USER_UPDATE_PREFERENCES_FAILURE,
      payload: { message },
    };
  }
};

export default updatePreferences;
