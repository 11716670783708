import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { DateTime } from 'luxon';

import {
  Card,
  CardBody,
  FormSelectField,
  FormCurrencyField,
  FormMonthPickerField,
  FormDateField,
  Button,
  CardFooter,
  CardHeader,
  Tag,
} from '_components/_core';
import { LoadingIcon } from '_components/_shared';

import { DEFAULT_COLUMNS, TABLE_COLUMNS } from './utilities';
import { StyledTable } from './styles';

function GenerateDarf({
  activeCompany,
  darfs,
  isLoading,
  selectedDate,
  onFetchCompaniesForSelect,
  onFetchManagerDarfs,
  onDeleteDarf,
  onGenerateDarfs,
}) {
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    onFetchCompaniesForSelect();
  }, [onFetchCompaniesForSelect]);

  useEffect(() => {
    onFetchManagerDarfs({
      year: selectedDate.year,
    });
  }, [selectedDate, activeCompany, onFetchManagerDarfs]);

  const handleDeleteDarf = useCallback((id) => {
    onDeleteDarf(id, () => {
      onFetchManagerDarfs({
        year: selectedDate.toFormat('yyyy'),
      });
    });
  }, [onDeleteDarf, onFetchManagerDarfs, selectedDate]);

  const taxesOptions = [
    {
      label: 'PIS',
      value: 'PIS',
    },
    {
      label: 'COFINS',
      value: 'COFINS',
    },
    {
      label: 'IRPJ',
      value: 'IRPJ',
    },
    {
      label: 'CSLL',
      value: 'CSLL',
    },
    {
      label: 'IRRF',
      value: 'IRRF',
    },
  ];

  const checkIfDarfsAreReady = useCallback((darf_ids) => {
    const intervalId = setInterval(() => {
      const params = {
        year: selectedDate.toFormat('yyyy'),
        hideLoading: true,
      };

      onFetchManagerDarfs(params, (newDarfs) => {
        let isDone = false;

        newDarfs.forEach((darf) => {
          if (darf_ids.includes(darf._id) && ['issued', 'issued_error'].includes(darf.status)) {
            isDone = true;
          }
        });

        if (isDone) {
          clearInterval(intervalId);
          setIsRefreshing(false);
        }
      });
    }, 2000);
  }, [onFetchManagerDarfs, selectedDate]);

  const handleGenerateDarfs = useCallback((values) => {
    const darfs = [
      {
        company_id: activeCompany?.id,
        tax_name: values.tax_name,
        competence: values.date_competence,
        amount: values.amount,
      },
    ];

    onGenerateDarfs(darfs, (darf_ids) => {
      onFetchManagerDarfs({
        year: selectedDate.toFormat('yyyy'),
      });
      setIsRefreshing(true);
      checkIfDarfsAreReady(darf_ids);
    });
  }, [onGenerateDarfs, onFetchManagerDarfs, selectedDate, activeCompany, checkIfDarfsAreReady]);

  return (
    <>
      <Row>
        <Col>
          <Formik
            initialValues={{
              company_id: activeCompany?.id,
              tax_name: null,
              date_competence: null,
              amount: 0,
              date_consolidation: DateTime.now().toFormat('yyyy-MM-dd'),
            }}
            onSubmit={handleGenerateDarfs}
            enableReinitialize
          >
            {({ handleSubmit }) => (
              <Card>
                <CardBody noPaddingBottom>
                  <Form onSubmit={handleSubmit}>
                    <Form.Row>
                      <Form.Group as={Col} md="3">
                        <Form.Label>Tributo</Form.Label>
                        <FormSelectField
                          name="tax_name"
                          options={taxesOptions}
                          placeholder="Tributo"
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="3">
                        <Form.Label>Competência</Form.Label>
                        <FormMonthPickerField
                          name="date_competence"
                          placeholder="Competência"
                          variant="input"
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="3">
                        <Form.Label>Valor</Form.Label>
                        <FormCurrencyField
                          name="amount"
                          placeholder="Valor"
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="3">
                        <Form.Label>Data de Consolidação</Form.Label>
                        <FormDateField
                          name="date_consolidation"
                          placeholder="Data de Consolidação"
                        />
                      </Form.Group>
                    </Form.Row>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    isLoading={isLoading}
                    disabled={isLoading}
                    onClick={() => handleSubmit()}
                  >
                    Emitir
                  </Button>
                </CardFooter>
              </Card>
            )}
          </Formik>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card>
            <CardHeader
              title="Guias"
              description="Lista de guias geradas"
              sideContent={(
                <>
                  {isRefreshing && (
                    <Tag className="mr-4">
                      <LoadingIcon text="Atualizando em Tempo Real" />
                    </Tag>
                  )}
                </>
              )}
            />
            <CardBody>
              <div className="table-responsive">
                <StyledTable
                  keyName="_id"
                  name="darfs-table"
                  className="table-hover table-striped"
                  data={darfs}
                  columns={TABLE_COLUMNS}
                  defaultColumns={DEFAULT_COLUMNS}
                  onDelete={handleDeleteDarf}
                  deleteHint="Excluir guia"
                  showFooter
                  showColumnToggle
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

GenerateDarf.defaultProps = {
  activeCompany: {},
  darfs: [],
  isLoading: false,
  selectedDate: {},
};

GenerateDarf.propTypes = {
  activeCompany: PropTypes.object,
  selectedDate: PropTypes.object,
  darfs: PropTypes.array,
  isLoading: PropTypes.bool,
  onFetchManagerDarfs: PropTypes.func.isRequired,
  onDeleteDarf: PropTypes.func.isRequired,
  onGenerateDarfs: PropTypes.func.isRequired,
  onFetchCompaniesForSelect: PropTypes.func.isRequired,
};

export default GenerateDarf;
