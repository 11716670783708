import confirmDialog from 'helpers/confirmDialog';

import FORMATTERS from 'helpers/formatters';
import constants from '../../_constants/taxes.constants';
import service from '../../../services/taxes.service';

import alertActionCreators from '../alert.actions';

const generateMessage = (params) => {
  const rows = params.map((param) => `<tr>
    <td class="p-2">
      ${param.tax_name}
    </td>
    <td class="p-2">
      ${FORMATTERS.DATE_MMYYYY(param.competence)}
    </td>
    <td class="p-2">
      ${FORMATTERS.NUMBER(param.amount)}
    </td>
  </tr>`);

  const message = `
  <div>
  <p>
    As guias abaixo serão emitidas, confira o nome do tributo, <br /> competência e valor depois clique em <strong>confirmar</strong>.
  </p>
  <table class="mt-3 table w-100">
    <thead>
      <tr>
        <th class="p-2" scope="col">Tributo</th>
        <th class="p-2" scope="col">Competência</th>
        <th class="p-2" scope="col">Valor</th>
      </tr>
    </thead>
    <tbody>
      ${rows}
    </tbody>
  </table>
  </div>`;

  return message;
};

const generateDarfs = (params, callback) => {
  return (dispatch) => {
    confirmDialog.info({
      title: 'Emissão de Guias',
      message: generateMessage(params),
      confirmButtonText: 'Confirmar',
      onConfirm: () => {
        dispatch(request());

        service.generateDarfs(params).then(
          (response) => {
            const { data } = response;

            if (callback) {
              callback(data);
            }

            dispatch(alertActionCreators.success('Guias enviadas para a fila de emissão.'));
            dispatch(success(data));
          },
        ).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request() {
    return {
      type: constants.GENERATE_DARFS_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.GENERATE_DARFS_SUCCESS,
      payload: data,
    };
  }
  function failure(data) {
    return {
      type: constants.GENERATE_DARFS_FAILURE,
      payload: {
        data,
      },
    };
  }
};

export default generateDarfs;
