import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { TRANSACTION_TYPE_SUB_TYPE_OPTIONS, useMediaQuery } from 'helpers';
import checkBlockedPeriod from 'helpers/checkBlockedPeriod';

import {
  FormTextField,
  FormDateField,
  FormCurrencyField,
  FormTextAreaField,
  Button,
  FormSwitchField,
} from '_components/_core';

// TODO: Mover para _shared
import CustomFormSelectField from '_components/Transactions/components/CustomFormSelectField/CustomFormSelectField';
import { SplitTransactionModal } from '_components/_shared';

import FORMATTERS from 'helpers/formatters';

import { CategorySelect } from '../../..';
import { SplitEditContainer, StyledPaidToggle } from './styles';
import { DateTime } from 'luxon';

function TransactionDetails({
  transaction,
  values,
  setFieldValue,
  handleSubmit,
  recipients,
  accounts,
  tags,
  costsCenter,
  disabledFields,
  hiddenFields,
  availableTypeSubTypes,
  mode,
  onAfterSaveSplitCallback,
  onSelectNewTransaction,
  blockedPeriod,
}) {
  const { isMobile } = useMediaQuery();

  const isDisabled = useCallback(
    field => {
      if (disabledFields.includes(field)) {
        return true;
      }

      return false;
    },
    [disabledFields],
  );

  const isHidden = useCallback(
    field => {
      if (hiddenFields.includes(field)) {
        return true;
      }

      if (
        mode === 'report-edit' &&
        field === 'type_sub_type' &&
        transaction.type === 'TRANSFER'
      ) {
        return true;
      }

      return false;
    },
    [hiddenFields, transaction, mode],
  );

  const formattedRecipients = useMemo(() => {
    const formatted = recipients.map(recipient => ({
      value: recipient.id,
      label: recipient.name,
    }));

    formatted.unshift({
      value: null,
      label: '--',
    });

    return formatted;
  }, [recipients]);

  const formattedBankAccounts = useMemo(() => {
    const formatted = accounts.map(account => ({
      value: account.id,
      label: account.description,
    }));

    return formatted;
  }, [accounts]);

  const formattedCostsCenter = useMemo(() => {
    const formatted = costsCenter.map(costCenter => ({
      value: costCenter.id,
      label: costCenter.description,
    }));

    formatted.unshift({
      value: null,
      label: '--',
    });

    return formatted;
  }, [costsCenter]);

  const formattedTags = useMemo(() => {
    const formatted = tags.map(tag => ({
      value: tag.id,
      label: tag.description,
    }));

    return formatted;
  }, [tags]);

  const originAccountName = useMemo(() => {
    const { transfer_details } = transaction || {};
    const { account_id_origin } = transfer_details || {};

    if (account_id_origin) {
      const originAccount = accounts.find(acc => acc.id === account_id_origin);

      return originAccount.description;
    }

    return '';
  }, [transaction, accounts]);

  const destinationAccountName = useMemo(() => {
    const { transfer_details } = transaction || {};
    const { account_id_destination } = transfer_details || {};

    if (account_id_destination) {
      const destinationAccount = accounts.find(acc => acc.id === account_id_destination);

      return destinationAccount.description;
    }

    return '';
  }, [transaction, accounts]);

  const handleSelectNewTransaction = useCallback(
    splitId => {
      onSelectNewTransaction(splitId);
    },
    [onSelectNewTransaction],
  );

  const isDayBlocked = useCallback(datePickerDate => {
    const day = datePickerDate.getDate();
    const month = datePickerDate.getMonth() + 1;
    const year = datePickerDate.getFullYear();

    const luxonDate = DateTime.fromObject({
      day,
      month,
      year,
    }).toFormat('yyyy-MM-dd');

    return checkBlockedPeriod(blockedPeriod, luxonDate);
  }, []);

  return (
    <Form onSubmit={handleSubmit} className="mr-3 ml-3">
      {transaction.type !== 'TRANSFER' && !transaction.split && !transaction.split_id && (
        <>
          {!isHidden('type_sub_type') && (
            <Form.Row>
              <Form.Group as={Col} xl={12}>
                <Form.Label>Tipo</Form.Label>
                <CustomFormSelectField
                  isClearable
                  name="type_sub_type"
                  options={availableTypeSubTypes}
                  placeholder="Aplicar o tipo..."
                  width="100%"
                  disabled={isDisabled('type_sub_type')}
                />
              </Form.Group>
            </Form.Row>
          )}
        </>
      )}
      <Form.Row>
        <Form.Group as={Col} lg={12}>
          <Form.Label>Data</Form.Label>
          <FormDateField
            withPortal={isMobile}
            disabled={isDisabled('event_date')}
            name="event_date"
            placeholder="Data"
            filterDate={isDayBlocked}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} lg={12}>
          <Form.Label>Descrição</Form.Label>
          <FormTextField name="description" placeholder="Descrição" />
        </Form.Group>
      </Form.Row>
      {transaction.type !== 'TRANSFER' && !values.split && (
        <>
          <Form.Row>
            <Form.Group as={Col} lg={12}>
              <Form.Label>
                {['INCOME::'].includes(values.type_sub_type) ? 'Recebido de' : 'Pago a'}
              </Form.Label>
              <CustomFormSelectField
                name="recipient_id"
                options={formattedRecipients}
                creatable="recipient"
                metadata={{
                  type: null,
                }}
                loadingMessage={() => 'Carregando...'}
                onCreateCallback={created_recipient => {
                  if (created_recipient) {
                    setFieldValue('recipient_id', created_recipient.id);
                  }
                }}
                width="100%"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg={12}>
              <Form.Label>Categoria</Form.Label>
              <CategorySelect
                isClearable
                name="category_id"
                placeholder="Aplicar a categoria..."
                onChange={value => setFieldValue('category_id', value)}
                value={values.category_id}
                typeSubType={values.type_sub_type}
              />
            </Form.Group>
          </Form.Row>
        </>
      )}
      {!values.split && !values.split_id && (
        <>
          <Form.Row>
            <Form.Group as={Col} lg={4} xs={4}>
              <Form.Label>Valor</Form.Label>
              <FormCurrencyField
                disabled={isDisabled('amount')}
                name="amount"
                placeholder="Valor"
              />
            </Form.Group>
            {values.type !== 'TRANSFER' && (
              <Form.Group as={Col} lg={3} xs={3} className="d-flex align-items-center">
                <div className="mt-3">
                  <Button
                    size="sm"
                    variant="link"
                    className="m-0 p-0"
                    onClick={() => setFieldValue('show_split_modal', true)}
                    disabled={!values.amount || values.amout === 0 || !values.id}
                    data-place="bottom"
                    data-tip="Salve a transação para detalhar o valor"
                    id="btn-split-transaction"
                  >
                    Detalhar valor
                  </Button>
                </div>
              </Form.Group>
            )}
            <Form.Group as={Col} lg={5} xs={5}>
              <Form.Label>&nbsp;</Form.Label>
              <StyledPaidToggle className="p-2 tab-content">
                <Form.Label className={classNames({ 'mb-0 pl-1': true })}>
                  Pago?
                </Form.Label>
                <FormSwitchField name="paid" disabled={isDisabled('paid')} />
              </StyledPaidToggle>
            </Form.Group>
          </Form.Row>
        </>
      )}

      {values.split_id && mode === 'report-edit' && (
        <>
          <Form.Row>
            <Form.Group as={Col} lg={6} xs={6}>
              <Form.Label>Valor</Form.Label>
              <FormCurrencyField disabled name="amount" placeholder="Valor" />
            </Form.Group>
            <Form.Group as={Col} lg={6} xs={6}>
              <Form.Label>&nbsp;</Form.Label>
              <StyledPaidToggle className="p-2 tab-content">
                <Form.Label className={classNames({ 'mb-0 pl-1': true })}>
                  Pago?
                </Form.Label>
                <FormSwitchField name="paid" disabled={isDisabled('paid')} />
              </StyledPaidToggle>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg={12} xs={12}>
              <SplitEditContainer>
                <div className="with-separator">
                  <strong>ITEM DETALHADO</strong>
                  <small className="text-muted">
                    Este item é relacionado a um detalhamento de um valor maior.
                  </small>
                </div>
                <div>
                  <Button
                    size="sm"
                    variant="link"
                    className="m-0 p-0"
                    onClick={() => handleSelectNewTransaction(values.split_id)}
                  >
                    Ver item principal...
                  </Button>
                </div>
              </SplitEditContainer>
            </Form.Group>
          </Form.Row>
        </>
      )}

      {/* <Form.Row>
        <Form.Group as={Col} lg={6}>
          <Form.Label>Valor</Form.Label>
          <FormCurrencyField
            disabled={isDisabled('amount')}
            name="amount"
            placeholder="Valor"
            forceZero
          />
        </Form.Group>
        {isDisabled('paid') && (
          <Form.Group as={Col} lg={6}>
            <Form.Label>Pago?</Form.Label>
            <div>
              {!values.paid && (
              <BsToggleOff
                size="2.2em"
                className="text-muted"
              />
              )}
              {values.paid && (
              <BsToggleOn
                size="2.2em"
                className="text-success"
              />
              )}
            </div>
          </Form.Group>
        )}
        {!isDisabled('paid') && (
          <Form.Group as={Col} lg={6}>
            <Form.Label>Pago?</Form.Label>
            <div>
              {!values.paid && (
              <BsToggleOff
                size="2.2em"
                className="text-muted"
                onClick={() => setFieldValue('paid', true)}
              />
              )}
              {values.paid && (
              <BsToggleOn
                size="2.2em"
                className="text-success"
                onClick={() => setFieldValue('paid', false)}
              />
              )}
            </div>
          </Form.Group>
        )}
      </Form.Row> */}
      {values.split && (
        <Form.Row>
          <Form.Group as={Col} lg={12} xs={12}>
            <SplitEditContainer>
              <div className="with-separator">
                <strong>Valor</strong>
                <strong style={{ fontWeight: '600' }}>
                  {FORMATTERS.TRANSACTION_AMOUNT(transaction)}
                </strong>
              </div>
              <div>
                <strong>ITEM DETALHADO</strong>
                <small className="text-muted">
                  {isEmpty(values.splits)
                    ? 'Este item possui divisões'
                    : `Este item possui ${values.splits.length} divisões`}
                </small>
                <Button
                  size="sm"
                  variant="link"
                  className="m-0 p-0 mt-1"
                  onClick={() => setFieldValue('show_split_modal', true)}
                >
                  Ver detalhamento
                </Button>
              </div>
            </SplitEditContainer>
          </Form.Group>
        </Form.Row>
      )}
      {transaction.type === 'TRANSFER' && (
        <>
          <Form.Row>
            <Form.Group as={Col} lg={12}>
              <Form.Label>Conta de Origem</Form.Label>
              <Form.Control type="text" disabled value={originAccountName} />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg={12}>
              <Form.Label>Conta de Destino</Form.Label>
              <Form.Control type="text" disabled value={destinationAccountName} />
            </Form.Group>
          </Form.Row>
        </>
      )}

      {transaction.type !== 'TRANSFER' && (
        <>
          <Form.Row>
            <Form.Group as={Col} lg={12}>
              <Form.Label>Conta bancária</Form.Label>
              <CustomFormSelectField
                name="account_id"
                disabled={isDisabled('account_id')}
                options={formattedBankAccounts}
                creatable="bank_account"
                metadata={{
                  type: transaction.type,
                  sub_type: transaction.sub_type,
                  transaction_id: transaction.id,
                }}
                loadingMessage={() => 'Carregando...'}
                onCreateCallback={created => {
                  if (created) {
                    setFieldValue('account_id', created.id);
                  }
                }}
                width="100%"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg={6}>
              <Form.Label>Competência</Form.Label>
              <FormDateField
                name="due_date"
                placeholder="Competência"
                withPortal={isMobile}
                allowClearWithPortal
              />
            </Form.Group>
            <Form.Group as={Col} lg={6}>
              <Form.Label>Forma de pagamento</Form.Label>
              <CustomFormSelectField
                name="payment_form"
                options={[
                  { value: null, label: '--' },
                  { value: 'BANK_SLIP', label: 'Boleto' },
                  { value: 'CREDIT_CARD', label: 'Cartão de crédito' },
                  { value: 'DEBIT_CARD', label: 'Cartão de débito' },
                  { value: 'BANK_CHECK', label: 'Cheque' },
                  { value: 'DIRECT_DEBIT', label: 'Débito em conta' },
                  { value: 'CASH', label: 'Dinheiro' },
                  { value: 'PIX', label: 'PIX' },
                  { value: 'TRANSFER', label: 'Transferência' },
                  { value: 'REMITTANCE', label: 'Remessa Bancária' },
                ]}
                loadingMessage={() => 'Carregando...'}
                width="100%"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg={12}>
              <Form.Label>Número de documento / Nosso número</Form.Label>
              <FormTextField name="document_number" placeholder="Número de documento" />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg={12}>
              <Form.Label>Centro de custo</Form.Label>
              <CustomFormSelectField
                name="cost_center_id"
                options={formattedCostsCenter}
                creatable="cost_center"
                metadata={{}}
                loadingMessage={() => 'Carregando...'}
                onCreateCallback={created => {
                  if (created) {
                    setFieldValue('cost_center_id', created.id);
                  }
                }}
                width="100%"
                disabled={values.split_id}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg={12}>
              <Form.Label>Tags</Form.Label>
              <CustomFormSelectField
                name="tag_ids"
                options={formattedTags}
                isMulti
                multiple
                isClearable
                creatable="tag"
                loadingMessage={() => 'Carregando...'}
                metadata={{
                  transaction_id: transaction.id,
                }}
                useValueForArrayOption
                onCreateCallback={created => {
                  if (created) {
                    setFieldValue('tag_ids', [...values.tag_ids, created.id]);
                  }
                }}
                disabled={values.split_id}
                width="100%"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg={12}>
              <Form.Label>Observações</Form.Label>
              <FormTextAreaField rows={3} name="comment" placeholder="Observações" />
            </Form.Group>
          </Form.Row>
        </>
      )}
      {values.show_split_modal && (
        <SplitTransactionModal
          isEditing
          isVisible={values.show_split_modal}
          transaction={values}
          recipients={recipients}
          onModalToggle={() =>
            setFieldValue('show_split_modal', !values.show_split_modal)
          }
          onAfterSaveCallback={onAfterSaveSplitCallback}
        />
      )}
    </Form>
  );
}

TransactionDetails.defaultProps = {
  transaction: {},
  values: {},
  disabledFields: [],
  hiddenFields: [],
  accounts: [],
  tags: [],
  costsCenter: [],
  availableTypeSubTypes: TRANSACTION_TYPE_SUB_TYPE_OPTIONS,
  blockedPeriod: {},
};

TransactionDetails.propTypes = {
  transaction: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  disabledFields: PropTypes.array,
  hiddenFields: PropTypes.array,
  recipients: PropTypes.array,
  accounts: PropTypes.array,
  tags: PropTypes.array,
  costsCenter: PropTypes.array,
  availableTypeSubTypes: PropTypes.array,
  mode: PropTypes.string,
  onAfterSaveSplitCallback: PropTypes.func,
  onSelectNewTransaction: PropTypes.func,
  blockedPeriod: PropTypes.object,
};

export default TransactionDetails;
