import styled, { css } from 'styled-components';

export const OfflineBannerContainer = styled.div`
  height: 48px;
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 996;
  background-color: #e74c3c;
  color: #fff;
  
  p {
    font-size: 15px;
  }

  svg {

  }

  ${({ withPaddingTop }) => withPaddingTop && css`
    /* top: 66px; */
  `}
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 995;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const CloseContainer = styled.div`
  cursor: pointer;
`;
