import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { CreatableSelect } from '_components/_shared';
import { StyledSelect } from './styles';

function AccountSelect({
  accounts,
  multiple,
  value,
  placeholder,
  creatable,
  disabled,
  ...rest
}) {
  const selectedValue = useMemo(() => {
    if (!value || !accounts || isEmpty(accounts)) {
      return null;
    }

    if (Array.isArray(value)) {
      return accounts.filter((account) => value.includes(account.value));
    }

    return accounts.find((account) => account.value === value);
  }, [value, accounts]);

  return (
    <>
      {!creatable && (
        <StyledSelect
          options={accounts}
          isMulti={multiple}
          value={selectedValue}
          placeholder={placeholder}
          disabled={disabled}
          {...rest}
        />
      )}
      {creatable && (
        <CreatableSelect
          options={accounts}
          isMulti={multiple}
          value={selectedValue}
          placeholder={placeholder}
          creatable={creatable}
          disabled={disabled}
          {...rest}
        />
      )}
    </>
  );
}

AccountSelect.propTypes = {
  accounts: [],
  multiple: false,
  disabled: false,
  placeholder: 'Selecione...',
};

AccountSelect.propTypes = {
  accounts: PropTypes.array,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  creatable: PropTypes.string,
};

export default AccountSelect;
