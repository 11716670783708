export const CHANGE_TYPE = [
  {
    value: 'new_date',
    label: 'Definir uma nova data para os lançamentos.',
  },
  {
    value: 'always_first_day',
    label: 'Manter datas e fixar sempre o primeiro dia do mês.',
  },
  {
    value: 'always_last_day',
    label: 'Manter datas e fixar sempre o último dia do mês.',
  },
  {
    value: 'always_x_day',
    label: 'Manter datas e fixar um dia específico do mês.',
  },
];

export const DAYS = Array.from({ length: 31 }, (_, i) => i + 1).map((day) => ({
  value: day,
  label: `Dia ${day}`,
}));
