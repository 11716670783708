import Swal from 'sweetalert2';
import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';
import getAllCompanies from './getAllCompanies';
import getActiveCompany from './getActiveCompany';

const deleteFinancialCompany = (id, name) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: `Excluir a empresa <br /> "${name}"`,
      dangerMode: true,
      message: `
        Tem certeza que deseja excluir esta empresa? Todas as <strong>movimentações</strong>, <strong>transferências</strong>, <strong>arquivos</strong>, <strong>categorias</strong>, <strong>contas bancárias</strong> e outras informações relacionadas a ela serão excluídas <strong>definitivamente</strong>. <br /><small>Dica: Faça um back-up antes de qualquer exclusão na tela Configurações - Exportar Dados</small>`,
      confirmButtonText: 'Sim, excluir empresa',
      icon: 'warning',
      input: 'text',
      inputAttributes: {
        style: 'width: 80%',
      },
      inputPlaceholder: `Digite "excluir ${name}"`,
      preConfirm: (inputValue) => {
        if (inputValue !== `excluir ${name}`) {
          Swal.showValidationMessage(`Por favor, digite "excluir ${name}" para confirmar a exclusão.`);
        }
      },
      onConfirm: () => {
        dispatch(request(id));

        service.deleteFinancialCompany(id).then(
          () => {
            dispatch(success(id));
            dispatch(getActiveCompany());
            dispatch(alertActionCreators.success('Empresa excluída com sucesso!'));
            dispatch(getAllCompanies());
          },
        );
      },
      onCancel: () => {},
    });
  };

  function request(id) {
    return { type: constants.DELETE_FINANCIAL_COMPANY_REQUEST, payload: { id } };
  }
  function success(id) {
    return {
      type: constants.COMPANY_DELETE_SUCCESS,
      payload: {
        id,
      },
    };
  }
};

export default deleteFinancialCompany;
