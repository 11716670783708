import React from 'react';
import PropTypes from 'prop-types';

function EmptyIcon({ size }) {
  const configuration = {
    small: {
      width: 44,
    },
    medium: {
      width: 75,
    },
    large: {
      width: 100,
    },
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={configuration[size].width} height="60" viewBox="0 0 64 40" fill="none">
      <g clipPath="url(#clip0_26_9)">
        <path fill="#354168" fillOpacity="0.1" d="M32 39.9998C49.6731 39.9998 64 36.9423 64 33.1706C64 29.3989 49.6731 26.3413 32 26.3413C14.3269 26.3413 0 29.3989 0 33.1706C0 36.9423 14.3269 39.9998 32 39.9998Z" />
        <path fill="#FAFAFA" fillOpacity="0.4" d="M55 13.4244L44.854 2.2029C44.367 1.43802 43.656 0.975586 42.907 0.975586H21.093C20.344 0.975586 19.633 1.43802 19.146 2.20193L9 13.4253V22.439H55V13.4244Z" />
        <path stroke="#354168" strokeOpacity="0.4" d="M44.4322 2.47144L44.4549 2.50698L44.4831 2.53824L54.5 13.6169V21.939H9.5V13.6178L19.5169 2.53723L19.5451 2.5061L19.5676 2.47071C19.9906 1.80716 20.5609 1.47559 21.093 1.47559H42.907C43.4391 1.47559 44.0092 1.80705 44.4322 2.47144Z" />
        <path fill="#FAFAFA" d="M41.613 16.5182C41.613 14.9523 42.607 13.6597 43.84 13.6587H55V31.3533C55 33.4245 53.68 35.1221 52.05 35.1221H11.95C10.32 35.1221 9 33.4236 9 31.3533V13.6587H20.16C21.393 13.6587 22.387 14.9494 22.387 16.5153V16.5367C22.387 18.1026 23.392 19.367 24.624 19.367H39.376C40.608 19.367 41.613 18.0909 41.613 16.525V16.5182V16.5182Z" />
        <path stroke="#354168" strokeOpacity="0.4" d="M42.113 16.5182C42.113 15.1033 42.9936 14.1595 43.8402 14.1587H54.5V31.3533C54.5 33.2704 53.2959 34.6221 52.05 34.6221H11.95C10.7042 34.6221 9.5 33.2695 9.5 31.3533V14.1587H20.16C21.0066 14.1587 21.887 15.1006 21.887 16.5153V16.5367C21.887 18.2636 23.0133 19.867 24.624 19.867H39.376C40.989 19.867 42.113 18.249 42.113 16.525V16.5182Z" />
      </g>
      <defs>
        <clipPath id="clip0_26_9">
          <rect width="64" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

EmptyIcon.defaultProps = {
  size: 'small',
};

EmptyIcon.propTypes = {
  size: PropTypes.string,
};

export default EmptyIcon;
