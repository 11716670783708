import axios from 'axios';
import { push } from 'connected-react-router';

import constants from '../../_constants/invoices.constants';
import service from '../../../services/invoices.service';
import alertActionCreators from '../alert.actions';

const uploadInvoices = (payload) => {
  return (dispatch) => {
    dispatch(request(payload));

    axios.post(`${process.env.REACT_APP_INVOICES_URL}/api/upload`, payload)
      .then((response) => {
        const { data } = response;

        service.upload({
          invoices: data,
        }).then((response) => {
          dispatch(success(response.data));
          dispatch(push('/fiscal/notas-fiscais/consultar'));
        })
          .catch((error) => {
            dispatch(failure(error));
            dispatch(alertActionCreators.error('Erro ao salvar no Contabills'));
          });
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(alertActionCreators.error('Erro ao importar Notas Fiscais'));
      });
  };

  function request(payload) {
    return {
      type: constants.INVOICES_UPLOAD_REQUEST,
      payload: { payload },
    };
  }
  function success(uploadResults) {
    return {
      type: constants.INVOICES_UPLOAD_SUCCESS,
      payload: { uploadResults },
    };
  }
  function failure(error) {
    return {
      type: constants.INVOICES_UPLOAD_FAILURE,
      payload: { error },
    };
  }
};

export default uploadInvoices;
