import * as Yup from 'yup';

import { SPECIAL_COLUMNS } from '_components/_core/Table';
import FORMATTERS from 'helpers/formatters';

export const DEFAULT_COLUMNS = [
  'description',
  'initial_balance',
  'account_type',
  'is_principal',
  'id',
];

export const TABLE_COLUMNS = [
  {
    label: 'Ações',
    keyName: 'id',
    align: 'center',
    width: 10,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'Conta',
    keyName: 'id',
    formatter: FORMATTERS.BANK_ACCOUNT_NAME,
    align: 'left',
    width: 75,
    order: 2,
  },

  {
    label: 'Saldo Inicial',
    keyName: 'initial_balance',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 3,
  },
  {
    label: 'Tipo',
    keyName: 'account_type',
    align: 'center',
    formatter: FORMATTERS.BANK_ACCOUNT_TYPE,
    width: 5,
    order: 4,
  },
];

export const ValidationSchema = Yup.object().shape({
  description: Yup.string().required('Campo obrigatório').nullable(),
  initial_balance: Yup.number().required('Campo obrigatório').nullable(),
  account_type: Yup.string().required('Campo obrigatório').nullable(),
});
