import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';
import companySelectors from '_store/_selectors/company';

import InvoiceSettings from './InvoiceSettings';

const mapStateToProps = (state) => ({
  isLoading: companySelectors.isLoadingCompanySelector(state),
  activeCompany: companySelectors.activeCompanySelector(state),
  subscription: state.subscription.subscription,
});

const mapDispatchToProps = {
  onFetchCompany: companyActionCreators.getCompanyById,
  onFetchCnpjCertificate: companyActionCreators.fetchCnpjCertificate,
  onActivateInvoiceIssuing: companyActionCreators.activateInvoiceIssuing,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvoiceSettings);
