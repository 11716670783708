import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { REPORT_NAMES } from '_components/Financial/Reports/utilities';
import { StyledTable } from '../../../../styles';
import Header from './components/Header';
import Body from './components/Body';

function ReportTable({ name, onFilter, onResetReport, isMobile, ...props }) {
  const [selectedSubType, setSelectedSubType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [selectedCostCenter, setSelectedCostCenter] = useState(null);

  const [selectedNavigation, setSelectedNavigation] = useState(null);

  const [showNavigationHeader, setShowNavigationHeader] = useState(false);

  const isSubTypeReport = useMemo(() => {
    const { EXPENSES_BY_TYPE } = REPORT_NAMES;

    return name === EXPENSES_BY_TYPE && selectedSubType && !selectedCategory;
  }, [name, selectedSubType, selectedCategory]);

  const isSubTypeReportWithCategorySelected = useMemo(() => {
    const { EXPENSES_BY_TYPE } = REPORT_NAMES;

    return name === EXPENSES_BY_TYPE && selectedSubType && selectedCategory;
  }, [name, selectedSubType, selectedCategory]);

  const isCategoryReportWithCategorySelected = useMemo(() => {
    const { EXPENSES_BY_CATEGORY, INCOME_BY_CATEGORY } = REPORT_NAMES;

    return [EXPENSES_BY_CATEGORY, INCOME_BY_CATEGORY].includes(name) && selectedCategory;
  }, [name, selectedCategory]);

  const isRecipientReportWithRecipientSelected = useMemo(() => {
    const { INCOMES_BY_RECIPIENT, EXPENSES_BY_RECIPIENT } = REPORT_NAMES;

    return (
      [INCOMES_BY_RECIPIENT, EXPENSES_BY_RECIPIENT].includes(name) && selectedRecipient
    );
  }, [name, selectedRecipient]);

  const isCostCenterReportWithRecipientSelected = useMemo(() => {
    const { INCOMES_BY_COST_CENTER, EXPENSES_BY_COST_CENTER } = REPORT_NAMES;

    return (
      [INCOMES_BY_COST_CENTER, EXPENSES_BY_COST_CENTER].includes(name) &&
      selectedCostCenter
    );
  }, [name, selectedCostCenter]);

  const handleResetReport = useCallback(() => {
    setSelectedNavigation(null);
    setShowNavigationHeader(false);
    setSelectedSubType(null);
    setSelectedCategory(null);
    setSelectedRecipient(null);
    setSelectedCostCenter(null);

    onResetReport();
  }, [onResetReport]);

  const breadcrumbs = useMemo(() => {
    if (!selectedNavigation) {
      return [];
    }

    if (isSubTypeReport) {
      return [
        {
          label: selectedSubType.label,
          path: selectedSubType.path,
          value: selectedSubType.value,
        },
      ];
    }

    if (isSubTypeReportWithCategorySelected) {
      return [
        {
          label: selectedSubType.label,
          path: selectedSubType.path,
          value: selectedSubType.value,
          onClick: () => {
            setSelectedCategory(null);

            onFilter({
              sub_type: selectedSubType.value,
              category_id: null,
              category_name: null,
              cost_center_name: null,
            });
          },
        },
        {
          label: selectedCategory.label,
          path: selectedCategory.path,
          value: selectedCategory.value,
        },
      ];
    }

    if (isCategoryReportWithCategorySelected) {
      return [
        {
          label: selectedCategory.label,
          path: selectedCategory.path,
          value: selectedCategory.value,
        },
      ];
    }

    if (isRecipientReportWithRecipientSelected) {
      return [
        {
          label: selectedRecipient.label,
          path: selectedRecipient.path,
          value: selectedRecipient.value,
        },
      ];
    }

    if (isCostCenterReportWithRecipientSelected) {
      return [
        {
          label: selectedCostCenter.label,
          path: selectedCostCenter.path,
          value: selectedCostCenter.value,
        },
      ];
    }

    return [];
  }, [
    isSubTypeReport,
    isSubTypeReportWithCategorySelected,
    isCategoryReportWithCategorySelected,
    isRecipientReportWithRecipientSelected,
    isCostCenterReportWithRecipientSelected,
    selectedSubType,
    selectedCategory,
    selectedRecipient,
    selectedCostCenter,
    selectedNavigation,
    onFilter,
  ]);

  const handleNavigation = useCallback(navigation => {
    switch (navigation.path) {
      case 'sub_type':
        setSelectedNavigation(navigation);
        setSelectedSubType(navigation);
        setShowNavigationHeader(true);
        break;
      case 'category_id':
        setSelectedNavigation(navigation);
        setSelectedCategory(navigation);
        setShowNavigationHeader(true);
        break;
      case 'recipient_id':
        setSelectedNavigation(navigation);
        setSelectedRecipient(navigation);
        setShowNavigationHeader(true);
        break;
      case 'cost_center_id':
        setSelectedNavigation(navigation);
        setSelectedCostCenter(navigation);
        setShowNavigationHeader(true);
        break;
      default:
        break;
    }
  }, []);

  const isShowingTransactions = useMemo(
    () =>
      isSubTypeReportWithCategorySelected ||
      isCategoryReportWithCategorySelected ||
      isRecipientReportWithRecipientSelected ||
      isCostCenterReportWithRecipientSelected,
    [
      isSubTypeReportWithCategorySelected,
      isCategoryReportWithCategorySelected,
      isRecipientReportWithRecipientSelected,
      isCostCenterReportWithRecipientSelected,
    ],
  );

  useEffect(() => {
    onFilter({
      category_id: selectedCategory ? selectedCategory.value : null,
      category_name: selectedCategory ? selectedCategory.label : null,
      sub_type: selectedSubType ? selectedSubType.value : null,
      recipient_id: selectedRecipient ? selectedRecipient.value : null,
      cost_center_id: selectedCostCenter ? selectedCostCenter.value : null,
      cost_center_name: selectedCostCenter ? selectedCostCenter.label : null,
    });
  }, [
    onFilter,
    selectedCategory,
    selectedCostCenter,
    selectedRecipient,
    selectedSubType,
  ]);

  return (
    <div className={classNames({ 'table-responsive': isMobile })}>
      <StyledTable className="table table-hover">
        <thead>
          <Header
            {...props}
            name={name}
            breadcrumbs={breadcrumbs}
            onResetReport={handleResetReport}
            isShowingNavigationHeader={showNavigationHeader}
            isShowingTransactions={isShowingTransactions}
          />
        </thead>
        <tbody className="report-body">
          <Body
            {...props}
            name={name}
            onNavigate={handleNavigation}
            isShowingTransactions={isShowingTransactions}
          />
        </tbody>
      </StyledTable>
    </div>
  );
}

ReportTable.propTypes = {
  name: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onFilter: PropTypes.func.isRequired,
  onResetReport: PropTypes.func.isRequired,
};
export default ReportTable;
