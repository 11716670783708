import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';

import { Button, FormDateField } from '_components/_core';

import { ValidationSchema } from '../../utilities/index';

function ActivePeriodForm({
  onSetActivePeriod,
  cancelEdit,
  activeCompany,
}) {
  const active_period = useMemo(() => ({
    active_period_start: activeCompany.active_period_start || null,
    active_period_end: activeCompany.active_period_end || null,
  }), [activeCompany]);

  const handleUpdateActivePeriod = useCallback((values) => {
    onSetActivePeriod(values.active_period_start, values.active_period_end);
    cancelEdit();
  }, [onSetActivePeriod, cancelEdit]);

  return (
    <Formik
      initialValues={{
        active_period_start: active_period.active_period_start,
        active_period_end: active_period.active_period_end,
      }}
      onSubmit={handleUpdateActivePeriod}
      enableReinitialize
      validationSchema={ValidationSchema}
    >
      {({
        handleSubmit,
      }) => (
        <>
          <Form className="mt-1" onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="6">
                <Form.Label>
                  Período Inicial
                </Form.Label>
                <FormDateField
                  name="active_period_start"
                  placeholder="Período Inicial"
                />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>
                  Período Final
                </Form.Label>
                <FormDateField
                  name="active_period_end"
                  placeholder="Período Final"
                />
              </Form.Group>
            </Form.Row>
            <Button variant="primary mr-2" onClick={handleSubmit}>
              Salvar
            </Button>
            <Button variant="secondary" onClick={() => cancelEdit()}>
              Cancelar
            </Button>
            <Form.Row />
          </Form>
        </>
      )}
    </Formik>
  );
}

ActivePeriodForm.propTypes = {
  cancelEdit: PropTypes.func.isRequired,
  onSetActivePeriod: PropTypes.func.isRequired,
  activeCompany: PropTypes.object.isRequired,
};

export default ActivePeriodForm;
