import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';
import alertActionCreators from '../alert.actions';

const updateEmailConfirm = (data, callback, errorCallback) => {
  return (dispatch) => {
    dispatch(request(data));

    service.updateEmailConfirm(data).then(
      (response) => {
        dispatch(success(response.data));

        if (callback) {
          callback(response.data);
        }

        dispatch(alertActionCreators.success('E-mail atualizado com sucesso. Você será redirecionado para a tela de login.'));
      },
    ).catch((error) => {
      dispatch(failure(error));

      if (errorCallback) {
        errorCallback(error);
      }
    });
  };

  function request() {
    return { type: constants.UPDATE_EMAIL_CONFIRM_REQUEST };
  }
  function success(data) {
    return {
      type: constants.UPDATE_EMAIL_CONFIRM_SUCCESS,
      payload: data,
    };
  }
  function failure(message) {
    return {
      type: constants.UPDATE_EMAIL_CONFIRM_FAILURE,
      payload: { message },
    };
  }
};

export default updateEmailConfirm;
