import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { IoFilterSharp } from 'react-icons/io5';

import {
  Button,
  PageHeader,
  ActiveFilters,
  TableLookup,
} from '_components/_core';
import {
  Pagination,
  ItemsPerPage,
  TableActions,
} from '_components/_core/Table/components';
import { useTable } from '_components/_core/Table/utils';

import {
  FILTERS_CONFIGURATION,
  DEFAULT_COLUMNS,
  TABLE_COLUMNS,
  getTableTopDistance,
} from './utilities';
import {
  SearchForm,
  UpdateEntriesHistory,
  UpdateEntriesCounterpart,
  DeleteEntries,
  ImportEntries,
  DeleteDuplicatedEntries,
} from './components';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { field: 'event_date', order: 'asc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

export default function Reconcile({
  isLoading,
  isLoadingEntries,
  entries,
  total,
  onFetchAccounts,
  onSubmitEntries,
  onEntriesIgnore,
  onFetchBankStatementEntries,
  onUpdateMany,
}) {
  useEffect(() => {
    onFetchAccounts();
  }, [onFetchAccounts, onFetchBankStatementEntries]);

  const {
    filters,
    sorting,
    pagination,
    selectedItems,
    displayFilters,
    onFilter,
    onSorting,
    onPageChange,
    onPageSizeChange,
    onItemSelected,
    onSelectAll,
    onToggleFilters,
  } = useTable({
    ...tableConfig,
    data: entries,
    onFetchData: onFetchBankStatementEntries,
  });

  return (
    <Container fluid className="content-wrapper fixedHeader pb-5">
      <PageHeader
        title="Importar Lançamentos"
        description="Concilie os lançamentos gerados e depois clique em Importar"
        fixedHeader
        withBackButton
        sideContent={(
          <div>
            <Button
              icon={<IoFilterSharp />}
              variant="outline-primary"
              onClick={onToggleFilters}
            >
              Filtrar
            </Button>
          </div>
        )}
      />
      <SearchForm
        isVisible={displayFilters}
        onToggleVisibility={onToggleFilters}
        onSearch={onFilter}
      />
      <Row>
        <Col>
          <ActiveFilters
            filtersConfiguration={FILTERS_CONFIGURATION}
            filters={filters}
            onFilter={onFilter}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TableLookup
            keyName="id"
            name="bank-statement-entries-lookup-table"
            data={entries}
            columns={TABLE_COLUMNS}
            defaultColumns={DEFAULT_COLUMNS}
            sorting={sorting}
            onSorting={onSorting}
            selectedItems={selectedItems}
            onItemSelected={onItemSelected}
            onSelectAll={onSelectAll}
            isLoading={isLoading}
            topDistance={getTableTopDistance()}
            showColumnToggle
            showFooter
          />
          <TableActions
            filters={filters}
            onFilter={onFilter}
            selectedItems={selectedItems}
            tableActions={[
              UpdateEntriesHistory,
              UpdateEntriesCounterpart,
              DeleteDuplicatedEntries,
              DeleteEntries,
              ImportEntries,
            ]}
            customProps={{
              isLoading,
              isLoadingEntries,
              entries,
              onUpdateMany,
              onSubmitEntries,
              onEntriesIgnore,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <ItemsPerPage
            itemsPerPage={pagination.itemsPerPage}
            onChange={onPageSizeChange}
            className="mt-3"
          />
        </Col>
        <Col className="d-flex flex-row-reverse">
          <Pagination
            {...pagination}
            total={total}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
    </Container>
  );
}

Reconcile.defaultProps = {
  isLoading: false,
  isLoadingEntries: false,
  entries: [],
};

Reconcile.propTypes = {
  total: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  isLoadingEntries: PropTypes.bool,
  entries: PropTypes.array,
  onFetchAccounts: PropTypes.func.isRequired,
  onSubmitEntries: PropTypes.func.isRequired,
  onFetchBankStatementEntries: PropTypes.func.isRequired,
  onEntriesIgnore: PropTypes.func.isRequired,
  onUpdateMany: PropTypes.func.isRequired,
};
