const filterTransactions = (allTransactions = [], textFilter = '') => {
  const filtered = allTransactions.filter((transaction) => {
    const {
      description: originalDescription,
      amount,
      recipient,
      category,
      cost_center,
      tags = [],
    } = transaction;

    const {
      name: recipientName,
    } = recipient || {};

    const {
      description: categoryDesc,
    } = category || {};

    const {
      description: costCenterDesc,
    } = cost_center || {};

    const tagsDesc = tags.map((tag) => tag.description).join(' ');

    const description = originalDescription || '';

    const descriptionMatch = description.toLowerCase().includes(textFilter.toLowerCase());
    const amountMatch = amount.toString().replaceAll('.', '').replaceAll(',', '').includes(textFilter.replaceAll('.', '').replaceAll(',', '').toLowerCase());
    const recipientMatch = recipientName ? recipientName.toLowerCase().includes(
      textFilter.toLowerCase(),
    ) : false;
    const categoryMatch = categoryDesc ? categoryDesc.toLowerCase().includes(
      textFilter.toLowerCase(),
    ) : false;
    const costCenterMatch = costCenterDesc ? costCenterDesc.toLowerCase().includes(
      textFilter.toLowerCase(),
    ) : false;
    const tagsMatch = tagsDesc.toLowerCase().includes(textFilter.toLowerCase());

    return descriptionMatch
    || amountMatch
    || recipientMatch
    || categoryMatch
    || costCenterMatch
    || tagsMatch;
  });

  return filtered;
};

export default filterTransactions;
