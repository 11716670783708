import { SPECIAL_COLUMNS } from '_components/_core/Table';
import isValidEmail from './isValidEmail';

const TABLE_COLUMNS = [
  {
    label: 'Código',
    keyName: 'id',
    sortingKey: 'id',
    align: 'center',
    width: 5,
  },
  {
    label: 'Avatar',
    keyName: 'avatar_url',
    sortingKey: 'avatar_url',
    width: 5,
  },
  {
    label: 'Nome',
    keyName: 'first_name',
    sortingKey: 'first_name',
    width: 20,
  },
  {
    label: 'E-mail',
    keyName: 'email',
    sortingKey: 'email',
    align: 'center',
    width: 20,
  },
  {
    label: 'Cargo',
    keyName: 'role',
    sortingKey: 'role',
    align: 'center',
    width: 10,
  },
  {
    label: 'Ações',
    keyName: 'id',
    align: 'center',
    width: 10,
    specialType: SPECIAL_COLUMNS.ACTIONS,
  },
];

export {
  isValidEmail,
  TABLE_COLUMNS,
};
