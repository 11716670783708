import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import { FaCheckCircle, FaFilter, FaInbox } from 'react-icons/fa';

export const StyledForm = styled(Form)`  
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: unset !important;
  }
`;

export const SectionHeader = styled.p`
  font-size: 14px;
  margin-bottom: 4px;
  color: var(--accent-color);
  font-weight: 600;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15%;

  @media screen and (max-width: 768px) {
    padding-top: 24px;
  }
`;

export const Card = styled.div`
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 32px 32px;
  width: 100%;
  /* text-align: center; */

  @media screen and (min-width: 1400px) {
    width: 45%;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
  margin-bottom: 8px;
`;

export const Text = styled.p`
  font-size: 14px;
  color: grey;
  margin-bottom: 0;
`;

export const EmptyIcon = styled(FaInbox)`
  font-size: 48px;
  color: #2980b9;
  margin-bottom: 16px;
`;

export const FilterIcon = styled(FaFilter)`
  font-size: 48px;
  color: #2980b9;
  margin-bottom: 16px;
`;

export const SuccessIcon = styled(FaCheckCircle)`
  font-size: 48px;
  color: #2ecc71;
  margin-bottom: 16px;
`;
