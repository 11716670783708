import FORMATTERS from 'helpers/formatters';
import { SPECIAL_COLUMNS } from '_components/_core/Table';

import UsersSchema from './UsersSchema';

export const DEFAULT_COLUMNS = [
  'full_name',
  'email',
  'document_number',
  'id',
];

export const TABLE_COLUMNS = [
  {
    label: 'Ações',
    keyName: 'id',
    align: 'center',
    width: 10,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'CPF',
    keyName: 'document_number',
    sortingKey: 'document_number',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.CPF_CNPJ,
    order: 2,
  },
  {
    label: 'E-mail',
    keyName: 'email',
    sortingKey: 'email',
    align: 'left',
    width: 10,
    order: 3,
  },
  {
    label: 'Nome',
    keyName: 'full_name',
    sortingKey: 'full_name',
    align: 'left',
    width: 60,
    formatter: FORMATTERS.UPPERCASE,
    order: 4,
  },
];

export { UsersSchema };
