import { push } from 'connected-react-router';

import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';

const setSelectedCompany = (id) => {
  return (dispatch) => {
    dispatch(request(id));

    service.getById(id).then(
      (response) => {
        dispatch(success(response.data));

        dispatch(push(`/empresas/${id}/editar`));
      },
    );
  };

  function request(id) {
    return { type: constants.SET_SELECTED_COMPANY_REQUEST, payload: { id } };
  }
  function success(company) {
    return {
      type: constants.SET_SELECTED_COMPANY_SUCCESS,
      payload: { company },
    };
  }
};

export default setSelectedCompany;
