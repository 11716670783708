import styled from 'styled-components';

import Popover from 'react-bootstrap/Popover';

export const CurrentProgress = styled.h4`
  color: rgb(64, 64, 64);
  font-size: 1.5em;
  font-weight: 500;
  font-family: Poppins;
  fill: rgb(64, 64, 64);
`;

export const StickyContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #666;
  padding: 40px;
  font-size: 25px;
`;

export const StyledPopover = styled(Popover)`
  z-index: 995 !important;
  min-width: 340px;

  div.arrow {
    &::after {
      border-bottom-color: var(--card-bg-color) !important; 
    }
  }
`;

export const StyledPopoverContent = styled(Popover.Content)`

  color: var(--color-text-primary) !important;
  font-family: var(--font-family);
  padding: 16px;

  border: var(--card-border);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
  background-color: var(--card-bg-color) !important; 

  p {
    font-size: 12.8px;
    line-height: 19.2px;
  }
`;
