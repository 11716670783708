import React from 'react';

import { Card, CardHeader, CardBody } from '_components/_core';

function Address() {
  return (
    <Card>
      <CardHeader title="Em Breve" />
      <CardBody />
    </Card>
  );
}

export default Address;
