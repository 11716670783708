import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import { ResultList, AlertHeading } from './styles';

function UploadAlert({ uploadResults, onClearUploadResults }) {
  const [isVisible, setIsVisible] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      const { pathname } = location;

      if (pathname !== '/fiscal/notas-fiscais/importar') {
        onClearUploadResults();
      }
    });

    return () => {
      unlisten();
    };
  }, [history, onClearUploadResults]);

  const handleCloseAlert = useCallback(() => {
    onClearUploadResults();
    setIsVisible(false);
  }, [onClearUploadResults]);

  if (!uploadResults) {
    return null;
  }

  const {
    total,
    created,
    cancelled,
    ignored,
  } = uploadResults || {};

  return (
    <Alert
      variant="success"
      transition={false}
      dismissible
      show={isVisible}
      onClose={handleCloseAlert}
    >
      <AlertHeading>
        Resultado da Importação
      </AlertHeading>
      <ResultList>
        <li>{`Arquivos Processados: ${total}`}</li>
        <li>{`Total de Notas Criadas: ${created}`}</li>
        <li>{`Total de Notas Canceladas: ${cancelled}`}</li>
        <li>{`Total de Notas Ignoradas (já existiam): ${ignored}`}</li>
      </ResultList>
    </Alert>
  );
}

UploadAlert.defaultProps = {
  uploadResults: null,
};

UploadAlert.propTypes = {
  uploadResults: PropTypes.object,
  onClearUploadResults: PropTypes.func.isRequired,
};

export default UploadAlert;
