import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';
import companySelectors from '_store/_selectors/company';

import CompaniesModal from './CompaniesModal';

const mapStateToProps = (state) => ({
  activeCompany: companySelectors.activeCompanySelector(state),
  companies: companySelectors.companiesSelector(state),
  total: state.company.total,
  isLoading: companySelectors.isLoadingCompanySelector(state),
});

const mapDispatchToProps = {
  onFetchCompanies: companyActionCreators.getAllCompanies,
  onActivateCompany: companyActionCreators.setActiveCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesModal);
