import React from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardHeader,
  CardBody,
  HintIcon,
} from '_components/_core';

function DashboardCard({
  title,
  content,
  hint,
  infoMessage,
  className,
  contentClassName,
}) {
  return (
    <Card className={className}>
      <CardHeader
        title={title}
        leftColSize={10}
        rightColSize={2}
        adjustSideContentMobile={false}
        sideContent={hint && (
          <HintIcon hint={hint} />
        )}
      />
      <CardBody noPaddingTop className="d-flex align-items-center">
        <h3 className={`display-4 mb-0 ${contentClassName}`}>
          {content}
        </h3>
        {infoMessage && (
          <HintIcon
            icon="info"
            hint={infoMessage}
            className="ml-2 mt-1"
            place="bottom"
          />
        )}
      </CardBody>
    </Card>
  );
}

DashboardCard.defaultProps = {
  title: '',
  className: '',
  contentClassName: '',
};

DashboardCard.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any,
  hint: PropTypes.string,
  infoMessage: PropTypes.string,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
};

export default DashboardCard;
