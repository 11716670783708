import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';

const fetchMarketSegments = () => {
  return (dispatch) => {
    dispatch(request());

    service.getMarketSegments().then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return {
      type: constants.FETCH_COMPANY_MARKET_SEGMENT_REQUEST,
      payload: {},
    };
  }
  function success(segments) {
    return {
      type: constants.FETCH_COMPANY_MARKET_SEGMENT_SUCCESS,
      payload: segments,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_COMPANY_MARKET_SEGMENT_FAILURE,
      payload: { error },
    };
  }
};

export default fetchMarketSegments;
