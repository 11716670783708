import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';
import { AiOutlineDollarCircle } from 'react-icons/ai';

import { Button, FormCurrencyField } from '_components/_core';

import { StyledModal, ModalFooter } from './styles';

const UpdateEntriesAmount = ({
  isLoading,
  selectedItems,
  onUpdateEntries,
  onComplete,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleUpdateAmount = useCallback((values, { resetForm }) => {
    onUpdateEntries({
      ids: selectedItems,
      type: 'amount',
      payload: {
        ...values,
      },
    }, () => {
      resetForm();
      setIsVisible(false);

      if (onComplete) {
        onComplete();
      }
    });
  }, [selectedItems, onComplete, onUpdateEntries]);

  const renderModalFooter = useCallback((handleSubmit, values) => (
    <ModalFooter>
      <Button variant="secondary" onClick={() => setIsVisible(false)}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={isLoading || !values.amount}
      >
        {`Alterar valor de ${selectedItems.length} lançamentos`}
      </Button>
    </ModalFooter>
  ), [isLoading, selectedItems]);

  return (
    <Formik
      initialValues={{
        amount: null,
      }}
      onSubmit={handleUpdateAmount}
      enableReinitialize
    >
      {({
        handleSubmit,
        values,
      }) => (
        <>
          <Button
            className="mr-2 btn-sm"
            variant="light"
            onClick={() => setIsVisible(true)}
            icon={<AiOutlineDollarCircle />}
          >
            Alterar Valor
          </Button>
          <StyledModal
            title=" Alterar Valor"
            isVisible={isVisible}
            toggleModal={() => setIsVisible(false)}
            footer={renderModalFooter(handleSubmit, values)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12" className="mb-0">
                  <Form.Label>Novo Valor</Form.Label>
                  <FormCurrencyField name="amount" placeholder="Valor" />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default UpdateEntriesAmount;

UpdateEntriesAmount.defaultProps = {
  isLoading: false,
  onComplete: null,
};

UpdateEntriesAmount.propTypes = {
  isLoading: PropTypes.func,
  selectedItems: PropTypes.array.isRequired,
  onUpdateEntries: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
};
