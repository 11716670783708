import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Table,
  LoadingOverlay,
} from '_components/_core';

import { TABLE_COLUMNS, DEFAULT_COLUMNS } from './utilities';
import { AddDependentModal } from './components';

function Dependents({
  isLoading,
  employeeDependents,
  onFetchEmployeeDependents,
  onAddEmployeeDependent,
  onUpdateEmployeeDependent,
  onDeleteEmployeeDependent,
}) {
  const [selectedDependent, setSelectedDependent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    onFetchEmployeeDependents(id);
  }, [id, onFetchEmployeeDependents]);

  const handleAddEmployeeDependent = useCallback(() => {
    setSelectedDependent(null);
    setIsModalOpen(true);
  }, []);

  const handleEditEmployeeDependent = useCallback((id) => {
    setSelectedDependent(null);

    const dependent = employeeDependents.find((dependent) => dependent._id === id);

    setSelectedDependent(dependent);
    setIsModalOpen(true);
  }, [employeeDependents]);

  const handleDeleteEmployeeDependent = useCallback((dependentId) => {
    onDeleteEmployeeDependent(id, dependentId);
  }, [onDeleteEmployeeDependent, id]);

  return (
    <>
      <AddDependentModal
        isVisible={isModalOpen}
        isLoading={isLoading}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
        onAddEmployeeDependent={onAddEmployeeDependent}
        onUpdateEmployeeDependent={onUpdateEmployeeDependent}
        selectedDependent={selectedDependent}
        employeeId={id}
      />
      <LoadingOverlay active={isLoading}>
        <Card className="mb-3">
          <CardHeader
            title="Dependentes"
            description="Gerencie os dependentes do funcionário selecionado"
            sideContent={(
              <Button className="mb-4" onClick={handleAddEmployeeDependent}>
                Novo Dependente
              </Button>
          )}
          />
          <CardBody>
            <div className="table-responsive">
              <Table
                className="table-hover"
                keyName="_id"
                data={employeeDependents}
                columns={TABLE_COLUMNS}
                defaultColumns={DEFAULT_COLUMNS}
                onEdit={handleEditEmployeeDependent}
                onDelete={handleDeleteEmployeeDependent}
                showFooter
              />
            </div>
          </CardBody>
        </Card>
      </LoadingOverlay>

    </>
  );
}

Dependents.defaultProps = {
  isLoading: false,
};

Dependents.propTypes = {
  employeeDependents: PropTypes.array,
  isLoading: PropTypes.func,
  onFetchEmployeeDependents: PropTypes.func.isRequired,
  onAddEmployeeDependent: PropTypes.func.isRequired,
  onUpdateEmployeeDependent: PropTypes.func.isRequired,
  onDeleteEmployeeDependent: PropTypes.func.isRequired,
};

export default Dependents;
