import styled, { css } from 'styled-components';

export const Container = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  height: calc(100% - 20px);
  margin: 10px;
  z-index: 1005;
  box-shadow: 0 0 0 1px rgba(89, 105, 129, 0.1),
              0 3px 20px 0 rgba(89, 105, 129, 0.3), 
              0 1px 2px 0 rgba(0, 0, 0, 0.05);
  width: 500px;
  border-radius: 6px;
  background-color: var(--color-sidebar-bg);

  ${({ side }) => side === 'right' && css`
    right: -500px;
    transition: right .4s;
  `}

  ${({ side }) => side === 'left' && css`
    left:-500px;
    transition: left .4s;
  `}

  ${({ fullHeight }) => fullHeight && css`
    margin: 0;
    border-radius: 0;
    height: 100%;
  `}

  ${({ isVisible }) => isVisible && css`
    ${({ side }) => side === 'left' && css`
      left: 0 !important;
      transition: left .4s;
    `}

    ${({ side }) => side === 'right' && css`
      right: 0 !important;
      transition: right .4s;
    `}
  `}

  @media (max-width: 768px) {
    width: 100vw;
    
    ${({ side }) => side === 'right' && css`
      right: -100vw;
      transition: right .4s;
    `}

    ${({ side }) => side === 'left' && css`
      left: -100vw;
      transition: left .4s;
    `}
  }
`;

export const Header = styled.div`
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;

  h5 {
    margin-bottom: 0;
  }

  svg {
    cursor: pointer;
  }
`;

export const Body = styled.div`
  padding: 20px 10px;
  height: 100%;
  overflow: auto;
`;

export const Footer = styled.div`
  
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: calc(-100vw);
  bottom: 0;  
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.7s;

  ${({ isVisible }) => isVisible && css`
    background-color: rgba(0, 0, 0, 0.5);
    right: 0 !important;
    left: 0;
    transition: background-color 0.7s;
  `}
`;

export const BeforeFooter = styled.div`
  
`;
