import styled, { css } from 'styled-components';
import { Tag } from '_components/_core';

export const StyledTitle = styled.h1`
  font-size: 1.13rem;
  font-weight: 600;
  line-height: 1.2;
`;

export const StyledText = styled.h1`
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1.71;
  padding: 5px 0 10px 0;
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
`;

export const StyledTag = styled(Tag)`
  color: black;
  cursor: pointer;
  font-size: 14px;
  margin-right: 15px;

  :hover {
    cursor: pointer;
  }
  :focus {
    
  }  
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 24px;
`;

export const Tab = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding: 14px 8px;
  cursor: pointer;


  ${({ active }) => active && css`
    color: var(--accent-color);
    font-weight: 500;
  `}
`;

export const ActionsContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin-top: 8px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: 400;

    ${({ inline }) => inline && css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;      
    `}

    li {
      display: flex;
      align-items: center;
      
      padding: 4px 12px;
      cursor: pointer;

      :hover {
        background-color: #f5f5f5;
      }

      :last-child {
        border-bottom: none;
      }

      :focus {
        outline: none;
      }

      :active {
        background-color: #f5f5f5;
      }
    }

  }
`;

export const EditorContainer = styled.div`
  /* margin: 35px auto;
  max-width: 820px; */
`;

export const ReceiptsListContainer = styled.div`

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 8px 12px;
      font-size: 13px;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      :hover {
        cursor: pointer;
        background-color: #f1f1fa;
      }

      &.active {
        background-color: #f1f1fa;
        border-left: 3px solid var(--title-color);
      }
    }
  }
`;

export const GridContainer = styled.div`
  /* margin-left: -28px;
  margin-right: -28px;
  margin-top: -15px; */

  /* display: grid;
  grid-template-columns: 25% 75%;

  @media (min-width: 1440px) {
    grid-template-columns: 15% 85%;
  } */
`;

export const LeftPanel = styled.div`
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
`;

export const RightPanel = styled.div`
  padding: 0;
  /* min-height: 80vh; */
`;

export const StyledVariableList = styled.ul`
  list-style: none !important;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: 400 !important;

  li {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    padding: 4px 12px !important;

    :hover {
      background-color: #f5f5f5 !important;
      cursor: pointer !important;
    }
  }
`;

export const Toolbar = styled.div`
  display: flex;
  flex-direction: row;

  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;

  padding-bottom: 8px;
  box-sizing: content-box;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 13px;
    font-weight: 400;
    padding: 8px 16px;
    border-right: 1px solid #ebeaf2;

    :hover {
      cursor: pointer;
      color: var(--accent-color);
    }
  }

  &::-webkit-scrollbar {
    height: 8px;
    padding-top: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
  }

`;

export const ToolbarItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
  ${({ isActive }) => isActive && css`
    color: var(--accent-color);
    font-weight: 700 !important;

    border-bottom: 2px solid var(--accent-color);
  `}
`;

export const StyledContainer = styled.div`
  width: 93vw;
  height: 60vh;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
`;
