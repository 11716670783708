import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Tab from 'react-bootstrap/Tab';
import { IoPrintOutline } from 'react-icons/io5';
import ReactToPrint from 'react-to-print';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';
import {
  Report,
  ReportPage,
  ReportHeader,
  ReportSignature,
} from '_components/_shared';

import { StyledTable, StyledField } from './styles';

function Results({
  values,
  activeCompany,
  DRE,
}) {
  const reportRef = useRef();

  const { company_name, document_number } = activeCompany || {};

  if (isEmpty(DRE)) {
    return null;
  }

  return (
    <Tab.Content>
      <div className="d-flex align-items-end">
        <ReactToPrint
          trigger={() => (
            <button type="button" placement="left" className="btn btn-sm btn-icons btn-secondary mr-2 mb-3">
              <IoPrintOutline />
            </button>
          )}
          content={() => reportRef.current}
          documentTitle="DRE"
        />
      </div>
      <Report ref={reportRef}>
        <ReportPage>
          <ReportHeader
            name="DEMONSTRAÇÃO DO RESULTADO DO EXERCÍCIO"
            companyName={company_name}
            companyDocumentNumber={FORMATTERS.CPF_CNPJ(document_number)}
            startDate={values.start_date}
            endDate={values.end_date}
            currentPage={1}
            totalPages={1}
          />
          <div className="table table-responsive">
            <StyledTable className="mt-3">
              <tbody>
                <tr>
                  <td className="text-left">
                    <span className="font-weight-bold text-uppercase">
                      Receita Operacional Bruta
                    </span>
                  </td>
                  <td className="text-right">
                    {FORMATTERS.DRE_DEBIT_CREDIT(DRE.operational_revenue.total)}
                  </td>
                </tr>
                {DRE.operational_revenue.items.map((item) => (
                  <tr>
                    <td className="text-left">
                      <StyledField className="operational-revenue-description">
                        {FORMATTERS.UPPERCASE(item.description)}
                      </StyledField>
                    </td>
                    <td className="text-right">
                      {FORMATTERS.DRE_DEBIT_CREDIT(item.amount)}
                    </td>
                  </tr>
                ))}
                {DRE.operational_revenue_deductions.items.map((item) => (
                  <tr>
                    <td className="text-left">
                      <StyledField className="operational-revenue-deduction-description">
                        {FORMATTERS.UPPERCASE(item.description)}
                      </StyledField>
                    </td>
                    <td className="text-right">
                      {`(${FORMATTERS.DRE_DEBIT_CREDIT(item.amount)})`}
                    </td>
                  </tr>
                ))}

              </tbody>
            </StyledTable>

            <StyledTable className="mt-3">
              <tbody>
                <tr>
                  <td className="text-left">
                    <span className="font-weight-bold text-uppercase">
                      Receita Operacional Líquida
                    </span>
                  </td>
                  <td className="text-right">
                    {FORMATTERS.DRE_DEBIT_CREDIT(DRE.net_operational_revenue)}
                  </td>
                </tr>
              </tbody>
            </StyledTable>

            {!isEmpty(DRE.cmv.items) && (
              <StyledTable className="mt-3">
                <tbody>
                  <tr>
                    <td className="text-left">
                      <span className="font-weight-bold text-uppercase">
                        CMV
                      </span>
                    </td>
                    <td className="text-right">
                      {FORMATTERS.DRE_DEBIT_CREDIT(DRE.cmv.total)}
                    </td>
                  </tr>
                  {DRE.cmv.items.map((item) => (
                    <tr>
                      <td className="text-left">
                        <StyledField className="cmv-amount">
                          {FORMATTERS.UPPERCASE(item.description)}
                        </StyledField>
                      </td>
                      <td className="text-right">
                        {FORMATTERS.DRE_DEBIT_CREDIT(item.amount)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            )}

            <StyledTable className="mt-3">
              <tbody>
                <tr>
                  <td className="text-left">
                    <span className="font-weight-bold text-uppercase">
                      Lucro Bruto
                    </span>
                  </td>
                  <td className="text-right">
                    {FORMATTERS.DRE_DEBIT_CREDIT(DRE.gross_profit)}
                  </td>
                </tr>
              </tbody>
            </StyledTable>

            <StyledTable className="mt-3">
              <tbody>
                <tr>
                  <td className="text-left">
                    <span className="font-weight-bold text-uppercase">
                      Despesas Operacionais
                    </span>
                  </td>
                  <td className="text-right">
                    {`(${FORMATTERS.DRE_DEBIT_CREDIT(DRE.operational_expenses.total)})`}
                  </td>
                </tr>
                {DRE.operational_expenses.items.map((item) => (
                  <tr>
                    <td className="text-left">
                      <StyledField>
                        {FORMATTERS.UPPERCASE(item.description)}
                      </StyledField>
                    </td>
                    <td className="text-right">
                      {`(${FORMATTERS.DRE_DEBIT_CREDIT(item.amount)})`}
                    </td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>

            <StyledTable className="mt-3">
              <tbody>
                <tr>
                  <td className="text-left">
                    <span className="font-weight-bold text-uppercase">
                      Lucro Operacional
                    </span>
                  </td>
                  <td className="text-right">
                    {FORMATTERS.DRE_DEBIT_CREDIT(DRE.operational_profit)}
                  </td>
                </tr>
              </tbody>
            </StyledTable>

            {!isEmpty(DRE.non_operational_revenue.items) && (
              <StyledTable className="mt-3">
                <tbody>
                  <tr>
                    <td className="text-left">
                      <span className="font-weight-bold text-uppercase">
                        Receitas / Despesas não operacionais
                      </span>
                    </td>
                    <td className="text-right">
                      {FORMATTERS.DRE_DEBIT_CREDIT(DRE.non_operational_revenue.total)}
                    </td>
                  </tr>
                  {DRE.non_operational_revenue.items.map((item) => (
                    <tr>
                      <td className="text-left">
                        <StyledField>
                          {FORMATTERS.UPPERCASE(item.description)}
                        </StyledField>
                      </td>
                      <td className="text-right">
                        {FORMATTERS.DRE_DEBIT_CREDIT(item.amount)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            )}

            {DRE.ir_csll_provision > 0 && (
              <StyledTable className="mt-3">
                <tbody>
                  <tr>
                    <td className="text-left">
                      <span className="font-weight-bold text-uppercase">
                        Provisão de IR CSLL
                      </span>
                    </td>
                    <td className="text-right">
                      {FORMATTERS.DRE_DEBIT_CREDIT(DRE.ir_csll_provision)}
                    </td>
                  </tr>
                </tbody>
              </StyledTable>
            )}

            <StyledTable className="mt-3">
              <tbody>
                <tr>
                  <td className="text-left">
                    <span className="font-weight-bold text-uppercase">
                      Lucro ou Prejuízo do Exercício
                    </span>
                  </td>
                  <td className="text-right">
                    {FORMATTERS.DRE_DEBIT_CREDIT(DRE.net_profit)}
                  </td>
                </tr>
              </tbody>
            </StyledTable>

          </div>
          <ReportSignature viewType="signature_field" />
        </ReportPage>
      </Report>
    </Tab.Content>
  );
}

Results.defaultProps = {
  DRE: [],
};

Results.propTypes = {
  values: PropTypes.object,
  activeCompany: PropTypes.object,
  DRE: PropTypes.array,
};

export default Results;
