import { connect } from 'react-redux';

import automationsActionCreators from '_store/_actions/automations';

import Automations from './Automations';

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
});

const mapDispatchToProps = {
  onFetchAutomationSettings: automationsActionCreators.fetchAutomationSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(Automations);
