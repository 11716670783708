import { useMemo } from 'react';

import transactionsService from 'services/company-transactions.service';

const useRecipients = ({ recipients }) => {
  const recipientsOptions = useMemo(() => {
    if (!recipients) {
      return [];
    }

    const formatted = recipients.map((recipient) => ({
      value: recipient.id,
      label: recipient.name,
    }));

    return formatted;
  }, [recipients]);

  const handleLoadSuggestions = async (params, callback) => {
    const response = await transactionsService.getSuggestions({
      suggestion_for: 'recipient_id',
      ...params,
    });

    if (callback) {
      callback(response.data);
    }
  };

  return {
    recipients,
    options: recipientsOptions,
    onLoadSuggestions: handleLoadSuggestions,
  };
};

export default useRecipients;
