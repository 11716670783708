export const automationsConstants = {
  SEARCH_PDF_INVOICES_REQUEST: 'SEARCH_PDF_INVOICES_REQUEST',
  SEARCH_PDF_INVOICES_SUCCESS: 'SEARCH_PDF_INVOICES_SUCCESS',
  SEARCH_PDF_INVOICES_FAILURE: 'SEARCH_PDF_INVOICES_FAILURE',

  FETCH_AUTOMATION_SETTINGS_REQUEST: 'FETCH_AUTOMATION_SETTINGS_REQUEST',
  FETCH_AUTOMATION_SETTINGS_SUCCESS: 'FETCH_AUTOMATION_SETTINGS_SUCCESS',
  FETCH_AUTOMATION_SETTINGS_FAILURE: 'FETCH_AUTOMATION_SETTINGS_FAILURE',

  ADD_AUTOMATION_SETTINGS_REQUEST: 'ADD_AUTOMATION_SETTINGS_REQUEST',
  ADD_AUTOMATION_SETTINGS_SUCCESS: 'ADD_AUTOMATION_SETTINGS_SUCCESS',
  ADD_AUTOMATION_SETTINGS_FAILURE: 'ADD_AUTOMATION_SETTINGS_FAILURE',

  UPDATE_AUTOMATION_SETTINGS_REQUEST: 'UPDATE_AUTOMATION_SETTINGS_REQUEST',
  UPDATE_AUTOMATION_SETTINGS_SUCCESS: 'UPDATE_AUTOMATION_SETTINGS_SUCCESS',
  UPDATE_AUTOMATION_SETTINGS_FAILURE: 'UPDATE_AUTOMATION_SETTINGS_FAILURE',

  DELETE_AUTOMATION_SETTINGS_REQUEST: 'DELETE_AUTOMATION_SETTINGS_REQUEST',
  DELETE_AUTOMATION_SETTINGS_SUCCESS: 'DELETE_AUTOMATION_SETTINGS_SUCCESS',
  DELETE_AUTOMATION_SETTINGS_FAILURE: 'DELETE_AUTOMATION_SETTINGS_FAILURE',

  EXECUTE_AUTOMATION_REQUEST: 'EXECUTE_AUTOMATION_REQUEST',
  EXECUTE_AUTOMATION_SUCCESS: 'EXECUTE_AUTOMATION_SUCCESS',
  EXECUTE_AUTOMATION_FAILURE: 'EXECUTE_AUTOMATION_FAILURE',
};

export default automationsConstants;
