import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Card, CardBody } from '_components/_core';

import {
  Container,
  SelectItems,
  Actions,
} from './styles';

function TableActions({
  variant,
  selectedItems,
  tableActions,
  filters,
  onFilter,
  customProps,
  className,
  forceVisible,
  customContent,
}) {
  const handleComplete = useCallback((options) => {
    onFilter(filters, true, options);
  }, [onFilter, filters]);

  const renderContent = useCallback(() => {
    if (variant === 'top') {
      return (
        <>
          <CardBody className="p-3 pl-4 pr-4 d-flex align-items-center">
            <SelectItems>
              {`${selectedItems.length} itens selecionados`}
            </SelectItems>
            |
            <Actions className="ml-3" variant={variant}>
              {customContent}
              {tableActions.map((Component) => (
                <Component
                  selectedItems={selectedItems}
                  onComplete={handleComplete}
                  {...customProps}
                />
              ))}
            </Actions>
          </CardBody>
        </>
      );
    }

    return (
      <Card>
        <CardBody className="p-3 pl-4 pr-4 d-flex align-items-center">
          <SelectItems>
            {`${selectedItems.length} itens selecionados`}
          </SelectItems>
          <Actions>
            {tableActions.map((Component) => (
              <Component
                selectedItems={selectedItems}
                onComplete={handleComplete}
                {...customProps}
              />
            ))}
          </Actions>
        </CardBody>
      </Card>
    );
  }, [
    variant,
    selectedItems,
    tableActions,
    handleComplete,
    customProps,
    customContent,
  ]);

  if (isEmpty(selectedItems) && !forceVisible) {
    return null;
  }

  return (
    <Container variant={variant} className={className}>
      {renderContent()}
    </Container>
  );
}

TableActions.defaultProps = {
  selectedItems: [],
  tableActions: [],
  variant: 'default',
  className: '',
  forceVisible: false,
  customContent: null,
};

TableActions.propTypes = {
  customContent: PropTypes.node,
  selectedItems: PropTypes.array,
  tableActions: PropTypes.array,
  filters: PropTypes.object,
  onFilter: PropTypes.func.isRequired,
  customProps: PropTypes.any,
  variant: PropTypes.string,
  className: PropTypes.string,
  forceVisible: PropTypes.bool,
};

export default TableActions;
