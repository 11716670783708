import calculateTaxes from './calculateTaxes';
import fetchTaxes from './fetchTaxes';
import clearTaxes from './clearTaxes';
import generateTaxesEntries from './generateTaxesEntries';
import fetchDarfs from './fetchDarfs';
import fetchManagerDarfs from './fetchManagerDarfs';
import generateDarfs from './generateDarfs';
import deleteDarf from './deleteDarf';
import deleteMultipleDarfs from './deleteMultipleDarfs';

export default {
  calculateTaxes,
  fetchTaxes,
  clearTaxes,
  generateTaxesEntries,
  fetchDarfs,
  generateDarfs,
  deleteDarf,
  deleteMultipleDarfs,
  fetchManagerDarfs,
};
