import { connect } from 'react-redux';

import authActionCreators from '_store/_actions/auth';

import SignIn from './SignIn';

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
});

const mapDispatchToProps = {
  onSignIn: authActionCreators.login,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
