import { parsePaginationParams } from 'helpers';

import constants from '../../_constants/logs.constants';
import service from '../../../services/logs.service';

const fetchLogs = (params = {}) => {
  return (dispatch) => {
    dispatch(request());

    const parsedParams = parsePaginationParams(params);

    service.fetchLogs(parsedParams).then((response) => {
      dispatch(success(response.data));
    })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request() {
    return {
      type: constants.FETCH_LOGS_REQUEST,
    };
  }
  function success(data) {
    return {
      type: constants.FETCH_LOGS_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_LOGS_FAILURE,
      payload: { error },
    };
  }
};

export default fetchLogs;
