import FORMATTERS from 'helpers/formatters';

export const DEFAULT_COLUMNS = [
  'name',
  'salary',
];

export const TABLE_COLUMNS = [
  {
    label: 'Nome',
    keyName: 'name',
    align: 'left',
    width: 80,
    formatter: FORMATTERS.UPPERCASE,
    order: 1,
  },
  {
    label: 'Salário',
    keyName: 'salary',
    align: 'right',
    width: 20,
    formatter: FORMATTERS.NUMBER,
    order: 2,
    sumOnFooter: true,
  },
];

export default TABLE_COLUMNS;
