import fetchEntries from './fetchEntries';
import addEntry from './addEntry';
import deleteEntry from './deleteEntry';
import updateEntry from './updateEntry';
import fetchEntry from './fetchEntry';
import addManyEntries from './addManyEntries';
import updateManyEntries from './updateManyEntries';
import deleteManyEntries from './deleteManyEntries';

export default {
  fetchEntries,
  addEntry,
  deleteEntry,
  updateEntry,
  fetchEntry,
  addManyEntries,
  updateManyEntries,
  deleteManyEntries,
};
