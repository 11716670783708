import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { IoCalculator, IoClose } from 'react-icons/io5';
import clamp from 'lodash/clamp';

import FORMATTERS from 'helpers/formatters';
import {
  CalculatorButton,
  CalculatorButtonsContainer,
  CalculatorContainer,
  CalculatorContent,
  CalculatorFooter,
  CalculatorInputContainer,
  CalculatorResultContainer,
  CloseButton,
  CustomFilterButton,
  StyledBackspace,
  StyledDropdown,
} from './styles';

import { VALID_OPERATIONS, CONTROLS, MAX_NUMBER } from './constants';

function Calculator({ value, onChange, getValueFn }) {
  const inputRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [expression, setExpression] = useState(() => {
    if (value) {
      return FORMATTERS.NUMBER_WITHOUT_CURRENCY(value);
    }

    return '';
  });
  const [lastResult, setLastResult] = useState(0);
  const [validForEval, setValidForEval] = useState(false);

  const handleToggle = useCallback((isOpen, _ev, metadata) => {
    if (metadata.source === 'select') {
      setOpen(true);
      return;
    }

    setOpen(isOpen);
  }, []);

  const sanitizedInput = useMemo(() => {
    let correctOperation = expression || '';

    VALID_OPERATIONS.forEach((parser) => {
      correctOperation = correctOperation.replace(parser.text, parser.value);
    });

    return correctOperation;
  }, [expression]);

  const result = useMemo(() => {
    if (!sanitizedInput) {
      return 0;
    }

    try {
      // biome-ignore lint/security/noGlobalEval: <explanation>
      let calculatedResult = eval(sanitizedInput);

      calculatedResult = clamp(calculatedResult, -1 * MAX_NUMBER, MAX_NUMBER);

      setValidForEval(true);

      return calculatedResult || lastResult;
    } catch {
      setValidForEval(false);

      return lastResult;
    }
  }, [sanitizedInput, lastResult]);

  const resultCurrency = useMemo(() => FORMATTERS.NUMBER_WITHOUT_CURRENCY(result) || '0,00', [result]);

  const handleButtonClick = useCallback((value) => {
    setExpression((prev) => prev + value);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setLastResult(result);
  }, [result]);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();

      if (getValueFn) {
        const value = getValueFn();

        if (value) {
          setExpression(FORMATTERS.NUMBER_WITHOUT_CURRENCY(value));
        }
      }
    }
  }, [open, getValueFn]);

  const handleSubmit = useCallback(() => {
    if (onChange) {
      onChange(result < 0 ? result * -1 : result);
    }

    setOpen(false);
  }, [onChange, result]);

  const handleBackspace = useCallback(() => {
    setExpression((prev) => prev.slice(0, -1));
  }, []);

  return (
    <StyledDropdown
      id="calculator"
      show={open}
      onToggle={handleToggle}
      drop="right"
      align="center"
    >
      <CustomFilterButton tabIndex="-1" variant="link" as={Dropdown.Toggle}>
        <IoCalculator size="1.2em" className="text-muted" />
      </CustomFilterButton>
      <CalculatorContainer align="center">
        <CalculatorContent>
          <CloseButton onClick={() => setOpen(false)}>
            <IoClose size="1.3em" />
          </CloseButton>
          <CalculatorResultContainer>
            {resultCurrency}
          </CalculatorResultContainer>
          <CalculatorInputContainer hasError={!validForEval}>
            <input
              ref={inputRef}
              type="text"
              value={expression}
              onChange={(e) => setExpression(e.target.value)}
              onKeyPress={(e) => {
                e.stopPropagation();

                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
            />
            <StyledBackspace onClick={handleBackspace} />
          </CalculatorInputContainer>
          <CalculatorButtonsContainer>
            {CONTROLS.map(({ text, value, isOperator }) => (
              <CalculatorButton
                key={text}
                isOperator={isOperator}
                onClick={() => handleButtonClick(value)}
              >
                {text}
              </CalculatorButton>
            ))}
          </CalculatorButtonsContainer>
          <CalculatorFooter>
            <div onClick={handleSubmit}>
              Transferir
            </div>
            <div onClick={() => setExpression('')}>
              C
            </div>
          </CalculatorFooter>
        </CalculatorContent>
      </CalculatorContainer>
    </StyledDropdown>
  );
}

Calculator.defaultProps = {
  getValueFn: null,
};

Calculator.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  getValueFn: PropTypes.func,
};

export default Calculator;
