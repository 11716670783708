const transformToFormValues = (invoice, params) => {
  if (!invoice) {
    return null;
  }

  const { customers, services } = params || {};

  if (!customers || !services) {
    return null;
  }

  const service_taker_cpf = invoice.tomador.cpf;
  const service_taker_cnpj = invoice.tomador.cnpj;
  const service_taker_type = service_taker_cpf ? 'cpf' : 'cnpj';

  const selectedCustomer = customers.find(
    (customer) => customer.document_number === invoice.tomador[service_taker_type],
  );

  const selectedService = services.find(
    (service) => service.service_district_tax_code === invoice.servico.codigo_tributario_municipio,
  );

  return {
    id: invoice.id,
    emission_date: invoice.data_emissao,
    nature_of_operation: invoice.natureza_operacao,
    special_tax_regime: invoice.regime_especial_tributacao,
    simples_nacional_optant: invoice.optante_simples_nacional,
    service_taker: {
      customer_id: selectedCustomer.value,
      document_number: service_taker_cpf || service_taker_cnpj,
      name: selectedCustomer.name,
      email: selectedCustomer.email,
      phone_number: selectedCustomer.phone_number,
      address: {
        address_zip_code: selectedCustomer.address_zip_code,
        address_street: selectedCustomer.address_street,
        address_number: selectedCustomer.address_number,
        address_complement: selectedCustomer.address_complement,
        address_district: selectedCustomer.address_district,
        address_city: selectedCustomer.address_city,
        address_city_ibge: selectedCustomer.address_city_ibge,
        address_state: selectedCustomer.address_state,
        address_state_ibge: selectedCustomer.address_state_ibge,
      },
    },
    service: {
      service_list_item_id: selectedService.value,
      service_list_item_code: invoice.servico.item_lista_servico,
      service_district_tax_code: invoice.servico.codigo_tributario_municipio,
      description: invoice.servico.discriminacao,
      amount: invoice.servico.valor_servicos,
      city_ibge: invoice.servico.codigo_municipio,
    },
  };
};

export default transformToFormValues;
