import styled from 'styled-components';

export const RadioButtonListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioButtonOptionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const RadioButtonLabel = styled.label`
  margin-left: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 0;
  font-weight: 400;
`;

export const RadioButtonInput = styled.input`
  appearance: none;
  height: 24px;
  width: 24px;
  border: 2px solid #ccc;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;

  &:before {
    content: '';
    display: block;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #11924F;
    opacity: 0;
    margin: 0;
    top: 4px;
    left: 4px;
    position: relative;
  }

  &:hover {
    border-color: #999;
  }

  &:checked {
    border-color: #999;

    &:before {
      opacity: 1;
    }
  }
`;
