import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';
import { IoCalculatorSharp } from 'react-icons/io5';
import { Button, FormDateField } from '_components/_core';

import { ValidationSchema } from './utilities';
import { StyledModal, ModalFooter } from './styles';

const AccountingIntegrationModal = ({
  isLoadingPayroll,
  isLoadingInvoices,
  isLoadingTaxes,
  type,
  onGenerateInvoiceEntries,
  onGeneratePayrollEntries,
  onGenerateTaxesEntries,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const isLoading = useMemo(
    () => isLoadingPayroll || isLoadingInvoices || isLoadingTaxes,
    [isLoadingPayroll, isLoadingInvoices, isLoadingTaxes],
  );

  const handleGenerateEntries = useCallback((values, { resetForm }) => {
    if (type === 'invoices') {
      onGenerateInvoiceEntries(values, () => {
        resetForm();
        setIsVisible(false);
      });
    }

    if (type === 'payroll') {
      onGeneratePayrollEntries(values, () => {
        resetForm();
        setIsVisible(false);
      });
    }

    if (type === 'taxes') {
      onGenerateTaxesEntries(values, () => {
        resetForm();
        setIsVisible(false);
      });
    }
  }, [onGenerateInvoiceEntries, onGeneratePayrollEntries, onGenerateTaxesEntries, type]);

  const renderModalFooter = useCallback((handleSubmit, isValid) => (
    <ModalFooter>
      <Button
        variant="secondary"
        onClick={() => setIsVisible(false)}
      >
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        disabled={!isValid}
        isLoading={isLoading}
      >
        Regerar Lançamentos Contábeis
      </Button>
    </ModalFooter>
  ), [isLoading]);

  return (
    <Formik
      initialValues={{
        start_date: null,
        end_date: null,
      }}
      onSubmit={handleGenerateEntries}
      validationSchema={ValidationSchema}
      enableReinitialize
    >
      {({ handleSubmit, isValid }) => (
        <>
          <Button
            variant="secondary"
            icon={<IoCalculatorSharp />}
            onClick={() => setIsVisible(true)}
            className="mr-3"
          >
            Integração Contábil
          </Button>
          <StyledModal
            title="Integração Contábil"
            isVisible={isVisible}
            toggleModal={() => setIsVisible(false)}
            footer={renderModalFooter(handleSubmit, isValid)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>Data Inicial</Form.Label>
                  <FormDateField name="start_date" placeholder="Data Inicial" />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Data Final</Form.Label>
                  <FormDateField name="end_date" placeholder="Data Final" />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default AccountingIntegrationModal;

AccountingIntegrationModal.defaultProps = {
  isLoadingPayroll: false,
  isLoadingInvoices: false,
  isLoadingTaxes: false,
};

AccountingIntegrationModal.propTypes = {
  isLoadingPayroll: PropTypes.bool,
  isLoadingInvoices: PropTypes.bool,
  isLoadingTaxes: PropTypes.bool,
  type: PropTypes.oneOf(['invoices', 'payroll', 'taxes']).isRequired,
  onGenerateInvoiceEntries: PropTypes.func.isRequired,
  onGeneratePayrollEntries: PropTypes.func.isRequired,
  onGenerateTaxesEntries: PropTypes.func.isRequired,
};
