import { userConstants } from '../_constants/user.constants';

const INITIAL_STATE = {
  users: [],
  user: {},
  ui: {
    isLoading: false,
    hasErrors: false,
    errors: [],
  },
  isLoading: false,
  usersForSelect: [],
  userPermissions: {},
};

export function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case userConstants.USER_GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.USER_GET_ALL_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        total,
        users: data,
        isLoading: false,
      };
    }
    case userConstants.USER_GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case userConstants.USER_GET_REQUEST:
      return {
        ...state,
      };
    case userConstants.USER_GET_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
      };
    case userConstants.USER_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.USER_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case userConstants.USER_UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case userConstants.USER_INVITE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.USER_INVITE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case userConstants.USER_INVITE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case userConstants.USER_UPDATE_PREFERENCES_REQUEST:
      return {
        ...state,
      };
    case userConstants.USER_UPDATE_PREFERENCES_SUCCESS:
      return {
        ...state,
      };
    case userConstants.USER_UPDATE_PREFERENCES_FAILURE:
      return {
        ...state,
      };
    case userConstants.USER_GET_PREFERENCES_REQUEST:
      return {
        ...state,
      };
    case userConstants.USER_GET_PREFERENCES_SUCCESS:
      return {
        ...state,
        preferences: action.payload.preferences,
      };
    case userConstants.FETCH_USERS_FOR_SELECT_REQUEST:
      return {
        ...state,
      };
    case userConstants.FETCH_USERS_FOR_SELECT_SUCCESS:
      return {
        ...state,
        usersForSelect: action.payload,
      };
    case userConstants.FETCH_USERS_FOR_SELECT_FAILURE:
      return {
        ...state,
      };
    case userConstants.USER_AVATAR_UPLOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.USER_AVATAR_UPLOAD_SUCCESS: {
      const { avatar_url } = action.payload;

      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          avatar_url,
        },
      };
    }
    case userConstants.USER_AVATAR_UPLOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case userConstants.USER_AVATAR_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.USER_AVATAR_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          avatar_url: null,
        },
      };
    }
    case userConstants.USER_AVATAR_DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
