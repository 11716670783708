import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';

import { StyledModal, StyledModalBody, StyledModalFooter } from './styles';

export default function CustomModal({
  isVisible,
  toggleModal,
  title,
  body,
  footer,
  children,
  className,
  containerClassName,
  headerClassName,
  footerClassName,
  showHeader = true,
  noMaxWidth,
  keyboard,
  ...restProps
}) {
  const renderFooter = () => {
    if (!footer) {
      return null;
    }

    return (
      <StyledModalFooter className={classNames(footerClassName)}>
        {footer}
      </StyledModalFooter>
    );
  };

  return (
    <>
      <StyledModal
        noMaxWidth={noMaxWidth}
        className={classNames(className, containerClassName)}
        show={isVisible}
        onHide={toggleModal}
        keyboard={keyboard}
        {...restProps}
      >
        {showHeader && (
          <Modal.Header className={classNames(headerClassName)} closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        )}
        <StyledModalBody className={classNames(className)}>
          {children}
        </StyledModalBody>
        {renderFooter()}
      </StyledModal>
    </>
  );
}

CustomModal.defaultProps = {
  footer: null,
  children: null,
  className: null,
  body: null,
  containerClassName: null,
  headerClassName: null,
  footerClassName: null,
  showHeader: true,
  noMaxWidth: false,
  keyboard: true,
};

CustomModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func,
  title: PropTypes.string.isRequired,
  body: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  showHeader: PropTypes.bool,
  noMaxWidth: PropTypes.bool,
  keyboard: PropTypes.bool,
};
