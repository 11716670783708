import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Tab from 'react-bootstrap/Tab';
import { IoPrintOutline } from 'react-icons/io5';
import ReactToPrint from 'react-to-print';
import { DateTime } from 'luxon';

import { Report, ReportPage } from '_components/_shared';
import FORMATTERS from 'helpers/formatters';

import {
  Table,
  Label,
  StyledP,
  StyledHr,
  SignatureDiv,
  StyledDiv,
  StyledHeader,
  StyledContainer,
} from './styles';

function Results({
  values,
  activeCompany,
  employees,
}) {
  const contractRef = useRef();

  const employee = useMemo(
    () => employees.find((employee) => employee.id === values.selectedEmployeeId),
    [employees, values],
  );

  if (!employee) {
    return null;
  }

  const {
    company_name,
    document_number,
    address_zip_code,
    address_street,
    address_number,
    address_district,
    address_complement,
    address_city,
    address_state,
  } = activeCompany || {};
  const {
    name,
    document_type,
    document_number: employee_document_number,
    ctps_number,
    salary,
    role,
    role_cbo,
    journey,
    address_zip_code: employee_address_zip_code,
    address_street: employee_address_street,
    address_number: employee_address_number,
    address_district: employee_address_district,
    address_complement: employee_address_complement,
    address_city: employee_address_city,
    address_state: employee_address_state,
  } = employee || {};

  const actualDate = DateTime.now().setLocale('pt-BR').toLocaleString({ day: 'numeric', month: 'long', year: 'numeric' });

  return (
    <Tab.Content className="mt-3 mt-lg-0">
      <div className="d-flex align-items-end">
        <ReactToPrint
          trigger={() => (
            <button
              type="button"
              placement="left"
              className="btn btn-sm btn-icons btn-secondary mr-2 mb-3"
            >
              <IoPrintOutline />
            </button>
          )}
          content={() => contractRef.current}
          documentTitle="Contrato de Trabalho"
        />
      </div>

      <Report ref={contractRef}>
        <ReportPage contentEditable>
          <StyledContainer>
            <StyledHeader>
              <strong>CONTRATO DE TRABALHO COM PRAZO INDETERMINADO</strong>
            </StyledHeader>
            <StyledP>Pelo presente instrumento particular, de um lado:</StyledP>
            <Table>
              <tr>
                <td style={{ width: '9%' }}>
                  <Label>
                    <strong>Nome:</strong>
                  </Label>
                </td>
                <td style={{ width: '78%' }}>
                  <small><strong>{FORMATTERS.NORMAL_CASE(company_name)}</strong></small>
                </td>
              </tr>
              <tr>
                <td style={{ width: '9%' }}>
                  <Label>CNPJ:</Label>
                </td>
                <td style={{ width: '78%' }}>
                  <small>{FORMATTERS.CNPJ(document_number)}</small>
                </td>
              </tr>
              <tr>
                <td style={{ width: '9%' }}>
                  <Label>
                    <strong>Endereço:</strong>
                  </Label>
                </td>
                <td style={{ width: '78%' }}>
                  <small>{`${FORMATTERS.NORMAL_CASE(address_street)}, `}</small>
                  <small>{`${FORMATTERS.NORMAL_CASE(address_number)}, `}</small>
                  <small>{`${FORMATTERS.NORMAL_CASE(address_district)} - `}</small>
                  {address_complement && (<small>{`${FORMATTERS.NORMAL_CASE(address_complement)} - `}</small>)}
                  <small>{`${FORMATTERS.NORMAL_CASE(address_city)}, `}</small>
                  <small>{`${address_state}`}</small>
                  <small>{` - CEP ${FORMATTERS.NORMAL_CASE(address_zip_code)}`}</small>
                </td>
              </tr>
            </Table>
            <StyledP>
              Ora em diante denominado EMPREGADOR.
            </StyledP>
            <StyledP className="mt-4">
              E de outro lado:
            </StyledP>
            <Table>
              <tr>
                <td style={{ width: '9%' }}>
                  <Label>
                    <strong>Nome:</strong>
                  </Label>
                </td>
                <td style={{ width: '78%' }}>
                  <small><strong>{name}</strong></small>
                </td>
              </tr>
              <tr>
                <td style={{ width: '9%' }}>
                  <Label>
                    {document_type}
                    :
                  </Label>
                </td>
                <td style={{ width: '78%' }}>
                  <small>{employee_document_number}</small>
                </td>
              </tr>
              {ctps_number && (
                <tr>
                  <td style={{ width: '9%' }}>
                    <Label>
                      CTPS:
                    </Label>
                  </td>
                  <td style={{ width: '78%' }}>
                    <small>
                      {ctps_number}
                    </small>
                  </td>
                </tr>
              )}
              <tr>
                <td style={{ width: '9%' }}>
                  <Label>
                    <strong>Endereço:</strong>
                  </Label>
                </td>
                <td style={{ width: '78%' }}>
                  {employee_address_zip_code && (
                    <>
                      <small>{`${FORMATTERS.NORMAL_CASE(employee_address_street)}, `}</small>
                      <small>{`${FORMATTERS.NORMAL_CASE(employee_address_number)}, `}</small>
                      <small>{`${FORMATTERS.NORMAL_CASE(employee_address_district)} - `}</small>
                      {employee_address_complement && (<small>{`${FORMATTERS.NORMAL_CASE(employee_address_complement)} - `}</small>)}
                      <small>{`${FORMATTERS.NORMAL_CASE(employee_address_city)}, `}</small>
                      <small>{`${employee_address_state}`}</small>
                      <small>{` - CEP ${FORMATTERS.NORMAL_CASE(employee_address_zip_code)}`}</small>
                    </>
                  )}
                </td>
              </tr>

            </Table>
            <StyledP>
              Ora em diante denominado EMPREGADO.
            </StyledP>
            <StyledP className="mt-4 mb-4">
              Firmam um contrato com as seguintes cláusulas e condições:
            </StyledP>
            <div className="ml-4">
              <StyledP>1 - O Contrato de Trabalho será pelo PRAZO INDETERMINADO;</StyledP>
              <StyledP>
                {`2 - O salário será de ${FORMATTERS.NUMBER(salary)} a ser pago mensalmente; `}
              </StyledP>
              <StyledP>
                {`3 - O EMPREGADO exercerá a função de: ${role} - CBO: ${role_cbo}`}
              </StyledP>
              <StyledP>
                {`4 - A carga horária será de ${FORMATTERS.JOURNEY(journey)},
              em horário que lhe for estipulado;`}
              </StyledP>
              <StyledP>
                5 - O presente Contrato de Trabalho fica subordinado a consolidação
                das leis do trabalho (C.L.T),
                sob quais se regerão as relações de trabalho;
              </StyledP>
              <StyledP>
                {`6 - Fica eleito o foro da comarca de ${address_city} - ${address_state}
              para dirimir quaisquer dúvidas que possam emanar do presente.`}
              </StyledP>
            </div>
            <StyledP className="mt-4">
              E por estarem juntos e contratados,
              assinam o presente em duas vias de igual teor e forma,
              testemunhados pelos abaixo assinados.
            </StyledP>
            <StyledP>
              {`${FORMATTERS.NORMAL_CASE(address_city)}, ${actualDate}.`}
            </StyledP>
            <StyledDiv className="row">
              <SignatureDiv>
                <StyledHr />
                <div>
                  <StyledP smallMargin>
                    {FORMATTERS.NORMAL_CASE(name)}
                  </StyledP>
                  <StyledP smallMargin>
                    {employee_document_number}
                  </StyledP>
                </div>
              </SignatureDiv>
              <SignatureDiv>
                <StyledHr />
                <div>
                  <StyledP smallMargin>
                    {FORMATTERS.NORMAL_CASE(company_name)}
                  </StyledP>
                  <StyledP smallMargin>
                    {FORMATTERS.CNPJ(document_number)}
                  </StyledP>
                </div>
              </SignatureDiv>
            </StyledDiv>
            <StyledDiv className="row">
              <SignatureDiv>
                <StyledHr />
                <div>
                  <StyledP>
                    TESTEMUNHA 1
                  </StyledP>
                </div>
              </SignatureDiv>
              <SignatureDiv>
                <StyledHr />
                <div>
                  <StyledP>
                    TESTEMUNHA 2
                  </StyledP>
                </div>
              </SignatureDiv>
            </StyledDiv>
          </StyledContainer>
        </ReportPage>
      </Report>

    </Tab.Content>
  );
}

Results.propTypes = {
  employees: PropTypes.array,
  values: PropTypes.object,
  activeCompany: PropTypes.object,
};

export default Results;
