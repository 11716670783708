import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import companyActionCreators from '_store/_actions/company';

import TransactionForm from './TransactionForm';

const mapStateToProps = state => ({
  categories: state.companyTransactions.categories,
  recipients: state.companyTransactions.recipients,
  accounts: state.companyTransactions.accounts,
  costsCenter: state.companyTransactions.costsCenter,
  tags: state.companyTransactions.tags,
  selectedAccountIds: state.companyTransactions.selected_account_ids,
  isEditing: state.companyTransactions.isEditing,
  isCreating: state.companyTransactions.isCreating,
  blockedPeriod: state.company.blockedPeriod,
});

const mapDispatchToProps = {
  onEditTransaction: companyTransactionsActionCreators.editTransaction,
  onCreateTransaction: companyTransactionsActionCreators.createTransaction,
  onDeleteTransaction: companyTransactionsActionCreators.deleteTransaction,
  onFetchTransaction: companyTransactionsActionCreators.fetchTransaction,
  onFilesUpload: companyTransactionsActionCreators.uploadTransactionFiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionForm);
