import { connect } from 'react-redux';

import employeeActionCreators from '_store/_actions/employees';
import ibgeActionCreators from '_store/_actions/ibge';

import Create from './Create';

const mapStateToProps = (state) => ({
  isLoading: state.employees.isLoading,
  statesReady: state.ibge.statesReady,
});

const mapDispatchToProps = {
  onFetchCreateEmployeeParams: employeeActionCreators.getAddEmployeeParams,
  onFetchCities: ibgeActionCreators.fetchCities,
  onCreateEmployee: employeeActionCreators.addEmployee,
  onFetchEmployee: employeeActionCreators.getEmployeeById,
  onUpdateEmployee: employeeActionCreators.updateEmployee,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Create);
