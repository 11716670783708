import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-bootstrap/Popover';

import { EmptyIcon } from '_components/_shared';
import SmallLayout from '../SmallLayout';

import {
  Separator,
  PopoverButton,
  StyledPopover,
  StyledOverlayTrigger,
} from './styles';

export default function PopoverLayout({
  isLoading,
  company,
  collapsed,
  onModalToggle,
  companies,
}) {
  const { trading_name } = company || {};

  const formattedName = useMemo(() => {
    if (!trading_name) {
      return '';
    }

    let splittedWords = trading_name.split(' ');

    splittedWords = splittedWords.filter((word) => word.length > 1);

    const [first_word, last_word] = splittedWords;

    let formatted = '';

    if (first_word) {
      formatted += first_word[0];
    }

    if (last_word) {
      formatted += ` ${last_word[0]}`;
    }

    return formatted;
  }, [trading_name]);

  const popover = (
    <StyledPopover id="popover-basic">
      <Popover.Content>
        <SmallLayout
          isLoading={isLoading}
          company={company}
          onModalToggle={onModalToggle}
          collapsed={collapsed}
          companies={companies}
        />
      </Popover.Content>
    </StyledPopover>
  );

  return (
    <>
      <StyledOverlayTrigger
        trigger="click"
        placement="right"
        overlay={popover}
        rootClose
      >
        <PopoverButton variant="secondary">
          {company ? formattedName : <EmptyIcon size="small" />}
        </PopoverButton>

      </StyledOverlayTrigger>
      <Separator />
    </>

  );
}

PopoverLayout.propTypes = {
  company: {},
  isLoading: false,
};

PopoverLayout.propTypes = {
  company: PropTypes.object,
  companies: PropTypes.array,
  isLoading: PropTypes.func.isRequired,
  onModalToggle: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
};
