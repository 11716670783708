import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

const StyledFormCheck = styled(Form.Check)`
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.8em;

  .form-check-input {
    margin-top: 0;
    margin-left: 0;
    width: 17px !important;
    height: 17px !important;
    z-index: 1;

    &:hover {
      cursor: pointer !important;
    }

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      bottom: -10px;
      left: -10px;
      right: -10px;
      background: transparent;
      z-index: 0;
    }
  }
`;

export default StyledFormCheck;
