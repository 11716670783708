import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';

const fetchUsersForSelect = () => {
  return (dispatch) => {
    dispatch(request());

    service.getUsersForSelect().then(
      (response) => {
        const users = response.data;

        dispatch(success(users));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.FETCH_USERS_FOR_SELECT_REQUEST,
      payload: { },
    };
  }
  function success(users) {
    return {
      type: constants.FETCH_USERS_FOR_SELECT_SUCCESS,
      payload: users,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_USERS_FOR_SELECT_FAILURE,
      payload: error,
    };
  }
};

export default fetchUsersForSelect;
