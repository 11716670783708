import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { sortBy } from 'lodash';

const useTable = ({
  data,
  keyName = 'id',
  onFetchData,
  defaultFilters,
  defaultSorting,
  defaultPagination,
  requiredFilters,
}) => {
  const activeCompany = useSelector((state) => state.company.activeCompany);
  const [filters, setFilters] = useState(() => {
    const pathName = window.location.pathname;
    const savedFilters = localStorage.getItem(`${pathName}_filters`);

    const localStorageFilters = JSON.parse(savedFilters);

    if (localStorageFilters) {
      return localStorageFilters;
    }

    return defaultFilters;
  });
  const [sorting, setSorting] = useState(() => {
    const pathName = window.location.pathname;
    const savedSorting = localStorage.getItem(`${pathName}_sorting`);

    const localStorageSorting = JSON.parse(savedSorting);

    if (localStorageSorting) {
      return localStorageSorting;
    }

    return defaultSorting;
  });
  const [pagination, setPagination] = useState(defaultPagination);
  const [selectedItems, setSelectedItems] = useState([]);

  const [filtersVisible, setIsFiltersVisible] = useState(false);

  const handleItemSelected = useCallback((selectedItem, eraseAll = false, customOrder = []) => {
    if (eraseAll) {
      setSelectedItems([]);
      return;
    }

    if (selectedItems.includes(selectedItem)) {
      setSelectedItems((previous) => [...previous.filter((p) => p !== selectedItem)]);
    } else {
      setSelectedItems((previous) => {
        const newState = [...previous, selectedItem];

        if (!isEmpty(customOrder)) {
          return sortBy(newState, (item) => customOrder.indexOf(item));
        }

        return newState;
      });
    }
  }, [selectedItems]);

  const handleSelectAll = useCallback(() => {
    if (isEmpty(selectedItems)) {
      setSelectedItems(data.map((i) => i[keyName]));
    } else {
      setSelectedItems([]);
    }
  }, [selectedItems, data, keyName]);

  const handleToggleFilters = useCallback(() => {
    setIsFiltersVisible((previous) => !previous);
  }, []);

  const updateFilters = async (filters) => {
    setFilters(filters);
  };

  const handleFilter = useCallback(async (filters, forceRefetch = false, options = null) => {
    await updateFilters(filters);

    const path = window.location.pathname;
    localStorage.setItem(`${path}_filters`, JSON.stringify(filters));

    if (options) {
      const { clearSelectedItems = false } = options || {};

      if (clearSelectedItems) {
        setSelectedItems([]);
      }
    }

    setIsFiltersVisible(false);
    setPagination(() => ({ ...pagination, currentPage: 1 }));

    if (forceRefetch) {
      onFetchData({
        sorting,
        filters,
        pagination,
        activeCompany,
      });
    }
  }, [onFetchData, activeCompany, pagination, sorting]);

  const handleSorting = useCallback((sorting) => {
    setSorting(sorting);

    const path = window.location.pathname;
    localStorage.setItem(`${path}_sorting`, JSON.stringify(sorting));
  }, []);

  const handlePageChange = useCallback((page) => {
    setPagination((previous) => ({ ...previous, currentPage: page }));
  }, []);

  const handlePageSizeChange = useCallback((size) => {
    setPagination((previous) => ({ ...previous, itemsPerPage: size }));
  }, []);

  const hasRequiredFilters = useCallback(() => {
    if (isEmpty(requiredFilters)) {
      return true;
    }

    const existingFilters = Object.keys(filters);

    let hasAllFilters = true;

    requiredFilters.forEach((filterName) => {
      if (!existingFilters.includes(filterName)) {
        hasAllFilters = false;
      }
    });

    return hasAllFilters;
  }, [requiredFilters, filters]);

  useEffect(() => {
    if (!onFetchData) {
      return;
    }

    if (hasRequiredFilters()) {
      onFetchData({
        sorting,
        filters,
        pagination,
        activeCompany,
      });
    }
  },
  [
    sorting,
    filters,
    hasRequiredFilters,
    pagination,
    activeCompany,
    onFetchData,
  ]);

  return {
    filters,
    pagination,
    sorting,
    selectedItems,
    displayFilters: filtersVisible,
    onToggleFilters: handleToggleFilters,
    onFilter: handleFilter,
    onSorting: handleSorting,
    onPageChange: handlePageChange,
    onPageSizeChange: handlePageSizeChange,
    onItemSelected: handleItemSelected,
    onSelectAll: handleSelectAll,
    onClearSelectedItems: () => setSelectedItems([]),
  };
};

export default useTable;
