import { connect } from 'react-redux';

import subscriptionActionCreators from '_store/_actions/subscription';

import PlansModal from './PlansModal';

const mapStateToProps = (state) => ({
  isLoading: state.subscription.isLoading,
  plans: state.subscription.plans,
  user: state.auth.user,
});

const mapDispatchToProps = {
  onCreateAuthorizedCheckoutSession: subscriptionActionCreators.goToAuthorizedCheckoutSession,
  onUpdatePlan: subscriptionActionCreators.updatePlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlansModal);
