import axios from 'axios';

import constants from '../../_constants/ibge.constants';

const fetchCities = (stateId, callback) => (dispatch) => {
  dispatch(({ type: constants.FETCH_CITIES_REQUEST }));

  axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios?orderBy=nome`)
    .then((response) => {
      const { data } = response;

      const formattedCities = data.map((city) => ({
        value: city.id,
        label: city.nome,
      }));

      dispatch({
        type: constants.FETCH_CITIES_SUCCESS,
        payload: {
          cities: formattedCities,
        },
      });

      if (callback) {
        callback();
      }
    }).catch((error) => {
      dispatch(({ type: constants.FETCH_CITIES_FAILURE, payload: error }));
    });
};

export default fetchCities;
