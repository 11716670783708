import * as yup from 'yup';

import {
  REQUIRED,
  TOO_LONG,
} from 'helpers/validation-messages';

const CompanySchema = yup.object().shape({
  company_name: yup.string().required(REQUIRED),
  document_number: yup.string().max(18, TOO_LONG).nullable(true),
  address_city: yup.string().max(255).nullable(true),
  address_state: yup.string().max(2).nullable(true),
  address_city_ibge: yup.number().nullable(true),
  address_state_ibge: yup.number().nullable(true),
});

export default CompanySchema;
