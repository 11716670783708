import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function LogoZenpaper({ width }) {
  const publicFolder = process.env.PUBLIC_URL;

  return (
    <Link className="navbar-brand m-0" to="/dashboard">
      <img
        src={`${publicFolder}/assets/img/logo-zenply-icon.svg`}
        alt="logo"
        width={width}
        className="ml-1 mr-1"
      />
    </Link>
  );
}

LogoZenpaper.defaultProps = {
  width: 105,
};

LogoZenpaper.propTypes = {
  width: PropTypes.number,
};
