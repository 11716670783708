import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { ReportSelectFields, ReportSignatureFields } from './components';

function ReportSignature({
  viewType,
  onFetchAccountants,
  onFetchEmployees,
  accountants,
  employees,
}) {
  useEffect(() => {
    onFetchAccountants();
    onFetchEmployees();
  }, [onFetchAccountants, onFetchEmployees]);

  return (
    <>
      {viewType === 'select_field' && (
        <ReportSelectFields
          accountants={accountants}
          employees={employees}
        />
      )}
      {viewType === 'signature_field' && (
        <ReportSignatureFields
          accountants={accountants}
          employees={employees}
        />
      )}
    </>
  );
}

ReportSignature.defaultProps = {
  viewType: 'signature_field',
};

ReportSignature.propTypes = {
  viewType: PropTypes.oneOf(['select_field', 'signature_field']),
  onFetchAccountants: PropTypes.func.isRequired,
  onFetchEmployees: PropTypes.func.isRequired,
  accountants: PropTypes.array,
  employees: PropTypes.array,
};

export default ReportSignature;
