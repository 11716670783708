import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const applyTagMultipleTransactions = (params, callback) => {
  return async (dispatch) => {
    dispatch(request(params));

    service.applyTagMultipleTransactions(params).then(
      (response) => {
        const tags = response.data;

        dispatch(success({
          transaction_id: params.transaction_id,
          transaction_ids: params.transaction_ids,
          tags,
        }));
        dispatch(alertActionCreators.success('Transação atualizada com sucesso!'));

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(params) {
    return {
      type: constants.APPLY_TAGS_TO_TRANSACTION_REQUEST,
      payload: params,
    };
  }
  function success({
    transaction_id,
    transaction_ids,
    tags,
  }) {
    return {
      type: constants.APPLY_TAGS_TO_TRANSACTION_SUCCESS,
      payload: {
        transaction_id,
        transaction_ids,
        tags,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.APPLY_TAGS_TO_TRANSACTION_FAILURE,
      payload: error,
    };
  }
};

export default applyTagMultipleTransactions;
