import constants from '../../_constants/user.constants';
import service from '../../../services/user.service';
import alertActionCreators from '../alert.actions';

const inviteUser = (email, callback) => {
  return (dispatch) => {
    dispatch(request());

    service.invite(email).then(
      (response) => {
        const userInvitation = response.data;

        dispatch(success(userInvitation));
        dispatch(alertActionCreators.success('Convite enviado com sucesso!'));

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.USER_INVITE_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.USER_INVITE_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.USER_INVITE_FAILURE,
      payload: error,
    };
  }
};

export default inviteUser;
