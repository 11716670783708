import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { IoCheckmark } from 'react-icons/io5';

import { Button } from '_components/_core';

const DeleteIssuedInvoices = ({
  isLoading,
  issuedInvoices,
  selectedItems,
  onDeleteIssuedInvoices,
  onComplete,
}) => {
  const hasInvalidInvoice = useMemo(() => {
    const affectedInvoices = issuedInvoices.filter((invoice) => selectedItems.includes(invoice.id));

    const invalid_status = ['authorized', 'issued'];

    return affectedInvoices.some((invoice) => invalid_status.includes(invoice.status));
  }, [issuedInvoices, selectedItems]);

  const handleDelete = useCallback(() => {
    const affectedInvoices = issuedInvoices.filter((entry) => selectedItems.includes(entry.id));

    onDeleteIssuedInvoices({
      invoice_ids: affectedInvoices.map((invoice) => invoice.id),
    }, () => {
      onComplete({
        clearSelectedItems: true,
      });
    });
  }, [selectedItems, issuedInvoices, onComplete, onDeleteIssuedInvoices]);

  return (
    <Button
      className="mr-2 btn-sm"
      variant="danger"
      onClick={handleDelete}
      isLoading={isLoading}
      disabled={isLoading || hasInvalidInvoice}
      icon={<IoCheckmark />}
    >
      {`Excluir ${selectedItems.length} NFS-e`}
    </Button>
  );
};

export default DeleteIssuedInvoices;

DeleteIssuedInvoices.defaultProps = {
  isLoading: false,
  onComplete: null,
  issuedInvoices: [],
};

DeleteIssuedInvoices.propTypes = {
  issuedInvoices: PropTypes.array,
  isLoading: PropTypes.bool,
  selectedItems: PropTypes.array.isRequired,
  onComplete: PropTypes.func,
  onDeleteIssuedInvoices: PropTypes.func.isRequired,
};
