const typeSubtypeStringToObject = (typeSubtypeString) => {
  if (!typeSubtypeString) {
    return {
      type: null,
      sub_type: null,
    };
  }

  const [type, sub_type] = typeSubtypeString.split('::');

  return {
    type,
    sub_type: sub_type || null,
  };
};

export default typeSubtypeStringToObject;
