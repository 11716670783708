import styled from 'styled-components';

export const StyledTable = styled.table`
  width: 100%;
  font-size: 0.9em;

  tbody tr:first-child td {
    border: none;
    height: 24px;
  }

  strong {
    font-weight: 600;
  }

  td {
    height: 23px;
    padding: 3px 3px 3px 22px;
    color: #BABEC5;
  }

  td svg {
    font-size: 14px;
    float: left;
    margin-left: -20px;
    margin-top: 3px;
  }

  .result td {
    font-weight: 600;
    text-transform: uppercase;
    border-top: 1px solid #E3E5E8;
    color: #6B6C72;
    padding-top: 5px;
  }

  .column-amount {
    width: 90px;
    text-align: right;
    font-weight: 600;
    padding: 3px;
    white-space: nowrap;
  }

  #tableDreSummary.example {
    color: #8D9096;
  }

  #tableDreSummary.example tr:hover {
      background: none;
  }

  #tableDreSummary.example th,#tableDreSummary.example td {
      border-color: #BABEC5;
      background: none;
  }

  #tableDreSummary.example th .muted,#tableDreSummary.example td .muted {
      color: #D4D7DC;
  }
`;
