import constants from '../../_constants/automations.constants';
import service from '../../../services/automations.service';
import alertActionCreators from '../alert.actions';

const executeAutomation = (values, callback) => {
  return async (dispatch) => {
    dispatch(request());

    service.executeAutomation(values).then(
      (response) => {
        dispatch(success(response));
        dispatch(alertActionCreators.success('Execução inicializada com sucesso!'));

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      dispatch(alertActionCreators.error('A execução não foi inicializada!'));
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.EXECUTE_AUTOMATION_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.EXECUTE_AUTOMATION_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.EXECUTE_AUTOMATION_FAILURE,
      payload: error,
    };
  }
};

export default executeAutomation;
