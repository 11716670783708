import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import Form from 'react-bootstrap/Form';

import classNames from 'classnames';
import { StyeldFormCheck } from './styles';

function FormPaidField({ containerClassName, ...props }) {
  const formik = useFormikContext();
  const [field, meta] = useField(props);

  const error = meta.error && meta.touched ? meta.error : '';

  return (
    <>
      <Form
        inline
        className={classNames({
          'mt-0': true,
          [containerClassName]: containerClassName !== '',
        })}
      >
        <StyeldFormCheck
          inline
          id="paid_pago"
          label="Pagos"
          name={props.name}
          type="checkbox"
          checked={field.value.includes('paid')}
          onClick={() => {
            if (field.value.includes('paid')) {
              formik.setFieldValue(field.name, field.value.filter((v) => v !== 'paid'));
            } else {
              const newValues = [...field.value, 'paid'];
              formik.setFieldValue(field.name, newValues);
            }
          }}
          className="mt-1"
        />
        <StyeldFormCheck
          inline
          id="paid_nao_pago"
          label="Não Pagos"
          name={props.name}
          type="checkbox"
          checked={field.value.includes('unpaid')}
          onClick={() => {
            if (field.value.includes('unpaid')) {
              formik.setFieldValue(field.name, field.value.filter((v) => v !== 'unpaid'));
            } else {
              const newValues = [...field.value, 'unpaid'];
              formik.setFieldValue(field.name, newValues);
            }
          }}
          className="mt-1"
        />
      </Form>
      {error && <small className="text-danger">{error}</small>}
    </>
  );
}

FormPaidField.defaultProps = {
  containerClassName: '',
};

FormPaidField.propTypes = {
  name: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
};

export default FormPaidField;
