import React from 'react';
import { IoArrowForward } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';

import { Button } from '_components/_core';

import {
  Container,
  Card,
  Title,
  Text,
  SuccessIcon,
} from './styles';

const SuccessMessage = () => {
  const history = useHistory();

  return (
    <Container>
      <Card>
        <SuccessIcon />
        <Title>
          Assinatura ativada com sucesso!
        </Title>
        <Text>
          Obrigado! Você já pode acessar o Zenply e começar a usar.
        </Text>
        <Button icon variant="success-2" className="mt-3" onClick={() => history.push('/entrar')}>
          Ir para o Login
          <IoArrowForward />
        </Button>
      </Card>
    </Container>
  );
};

export default SuccessMessage;
