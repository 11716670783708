import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Tab from 'react-bootstrap/Tab';
import { IoPrintOutline } from 'react-icons/io5';
import ReactToPrint from 'react-to-print';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';
import { Report, ReportPage, ReportHeader } from '_components/_shared';

import { paginateResults, TABLE_COLUMNS, DEFAULT_COLUMNS } from './utilities';
import { StyledTable, AccountName } from './styles';

function Results({
  values,
  activeCompany,
  ledger,
}) {
  const reportRef = useRef();

  const { company_name, document_number } = activeCompany || {};

  const paginatedLedger = useMemo(() => {
    if (isEmpty(ledger)) {
      return [];
    }

    const results = paginateResults(ledger);

    return results;
  }, [ledger]);

  if (isEmpty(ledger)) {
    return null;
  }

  return (
    <Tab.Content>
      <div className="d-flex align-items-end">
        <ReactToPrint
          trigger={() => (
            <button type="button" placement="left" className="btn btn-sm btn-icons btn-secondary mr-2 mb-3">
              <IoPrintOutline />
            </button>
          )}
          content={() => reportRef.current}
          documentTitle="Razão Contábil"
        />
      </div>
      <Report ref={reportRef}>
        {Object.keys(paginatedLedger).map((accountClassifier) => {
          const { account, entries } = paginatedLedger[accountClassifier];

          return (
            <div>
              {Object.keys(entries).map((key, index) => (
                <ReportPage>
                  <ReportHeader
                    name="RAZÃO CONTÁBIL"
                    companyName={company_name}
                    companyDocumentNumber={FORMATTERS.CPF_CNPJ(document_number)}
                    startDate={values.start_date}
                    endDate={values.end_date}
                    currentPage={index + 1}
                    totalPages={paginatedLedger ? Object.keys(entries).length : 0}
                  />
                  <AccountName>
                    {`${account.classifier} - ${account.description}`}
                  </AccountName>
                  <div className="table-responsive">
                    <StyledTable
                      key={accountClassifier}
                      keyName="id"
                      className="razao-table table-hover mt-3"
                      data={entries[key]}
                      columns={TABLE_COLUMNS}
                      defaultColumns={DEFAULT_COLUMNS}
                      footerData={ledger ? ledger[accountClassifier].entries : []}
                      showFooter={Number(key) === Object.keys(entries).length}
                    />
                  </div>
                </ReportPage>
              ))}
            </div>
          );
        })}
      </Report>
    </Tab.Content>
  );
}

Results.defaultProps = {
  ledger: null,
};

Results.propTypes = {
  values: PropTypes.object,
  activeCompany: PropTypes.object,
  ledger: PropTypes.object,
};

export default Results;
