import FORMATTERS from 'helpers/formatters';

export const DEFAULT_COLUMNS = [
  'id',
  'start_date',
  'end_date',
  'acquisition_period',
  'abono_pecuniario',
  'total_days',
  'total_days_remaining',
  'total_days_used',
];

export const TABLE_COLUMNS = [
  {
    label: 'Período Aquisitivo',
    keyName: 'acquisition_period',
    align: 'center',
    width: 10,
    order: 2,
  },
  {
    label: 'Dias',
    keyName: 'total_days',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.INTEGER,
    order: 4,
  },
  {
    label: 'Dias Concedidos/Vendidos',
    keyName: 'total_days_used',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.INTEGER,
    order: 5,
  },
  {
    label: 'Abono Pecuniário',
    keyName: 'abono_pecuniario',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.YES_NO,
    order: 5,
  },
  {
    label: 'Dias devidos',
    keyName: 'total_days_remaining',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.INTEGER,
    order: 6,
  },
  {
    label: 'Períodos de Férias já Programados',
    keyName: 'total_days_remaining',
    align: 'left',
    width: 60,
    formatter: FORMATTERS.INTEGER,
    order: 6,
  },
];

export default TABLE_COLUMNS;
