import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import RowForm from './RowForm';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  accounts: state.companyTransactions.accounts,
  recipients: state.companyTransactions.recipients,
});

const mapDispatchToProps = {
  onChangeTransactionRecipient: companyTransactionsActionCreators.changeTransactionRecipient,
  onChangeTransactionCategory: companyTransactionsActionCreators.changeTransactionCategory,
  onChangeOriginAccount: companyTransactionsActionCreators.changeTransferOriginAccount,
  onChangeDestinationAccount: companyTransactionsActionCreators.changeTransferDestinationAccount,
  onUpdateTransactionsTransferDetails:
  companyTransactionsActionCreators.updateTransactionsTransferDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(RowForm);
