import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';

import { Button, FormTextField, Tag } from '_components/_core';

import { StyledModal, ModalFooter } from './styles';

const UpdateEmailModal = ({
  isVisible,
  onModalToggle,
  onUpdateEmailRequest,
  onUpdateEmailConfirm,
  onLogout,
}) => {
  const [step, setStep] = useState('REQUEST');
  const [isLoading, setIsLoading] = useState(false);

  const handleModalToggle = useCallback(() => {
    setStep('REQUEST');
    setIsLoading(false);

    onModalToggle();
  }, [onModalToggle]);

  const handleRequestMailChange = useCallback((values) => {
    const errorCallback = () => {
      setIsLoading(false);
    };

    if (step === 'REQUEST') {
      setIsLoading(true);

      const successCallback = () => {
        setStep('CONFIRM');
        setIsLoading(false);
      };

      onUpdateEmailRequest(values, successCallback, errorCallback);
    }

    if (step === 'CONFIRM') {
      setIsLoading(true);

      const successCallback = () => {
        setStep('REQUEST');
        setIsLoading(false);
        handleModalToggle();

        setTimeout(() => {
          onLogout();
        }, 3000);
      };

      onUpdateEmailConfirm(values, successCallback, errorCallback);
    }
  }, [onUpdateEmailRequest, onUpdateEmailConfirm, handleModalToggle, onLogout, step]);

  const renderModalFooter = useCallback((handleSubmit) => (
    <ModalFooter>
      <Button variant="secondary" onClick={handleModalToggle}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="dark"
        onClick={handleSubmit}
        disabled={isLoading}
        isLoading={isLoading}
      >
        {step === 'REQUEST' ? 'Próximo passo' : 'Confirmar alteração'}
      </Button>
    </ModalFooter>
  ), [handleModalToggle, isLoading, step]);

  if (!isVisible) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        email: null,
        token: null,
      }}
      onSubmit={handleRequestMailChange}
      enableReinitialize
    >
      {({ handleSubmit, isValid, values }) => (
        <>
          <StyledModal
            title="Alterar e-mail de acesso"
            isVisible={isVisible}
            toggleModal={handleModalToggle}
            footer={renderModalFooter(handleSubmit, isValid)}
          >
            <Form onSubmit={handleSubmit}>
              {step === 'REQUEST' && (
                <>
                  <Tag className="p-3">
                    Um código de confirmação será enviado para o novo e-mail. <br />
                    Após a confirmação, você será desconectado e deverá realizar o login novamente.
                  </Tag>
                  <Form.Row className="mt-3">
                    <Form.Group as={Col} md="12">
                      <Form.Label>E-mail</Form.Label>
                      <FormTextField
                        name="email"
                        type="email"
                        placeholder="Digite seu novo e-mail"
                      />
                    </Form.Group>
                  </Form.Row>

                </>
              )}
              {step === 'CONFIRM' && (
                <>
                  <Tag className="p-3">
                    Código de confirmação enviado para:&nbsp;<strong>{values.email}</strong>
                  </Tag>
                  <Form.Row className="mt-3">
                    <Form.Group as={Col} md="12">
                      <Form.Label>Código de confirmação</Form.Label>
                      <FormTextField
                        name="token"
                        placeholder="Digite o código de confirmação, enviado para o novo e-mail"
                      />
                    </Form.Group>
                  </Form.Row>
                </>
              )}
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default UpdateEmailModal;

UpdateEmailModal.defaultProps = {
  isVisible: false,
};

UpdateEmailModal.propTypes = {
  isVisible: PropTypes.bool,
  onModalToggle: PropTypes.func.isRequired,
  onUpdateEmailRequest: PropTypes.func.isRequired,
  onUpdateEmailConfirm: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};
