import styled, { css } from 'styled-components';

export const Skeleton = styled.div`
  width: 100%;
  border-radius: 4px;

  ${({ width }) => width && css`
    width: ${width} !important;
  `}

  ${({ height }) => height && css`
    height: ${height} !important;
  `}

  background-image: linear-gradient(-90deg, #e7edf1 0%, #f8f8f8 50%, #e7edf1 100%);
  background-size: 400% 400%;
  animation shimmer 1.2s ease-in-out infinite;

  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }

    0% {
      background-position: -135% 0%;
    }
  }
`;

export default Skeleton;
