export const accountantsConstants = {
  ACCOUNTANTS_GET_ALL_REQUEST: 'ACCOUNTANTS_GET_ALL_REQUEST',
  ACCOUNTANTS_GET_ALL_SUCCESS: 'ACCOUNTANTS_GET_ALL_SUCCESS',
  ACCOUNTANTS_GET_ALL_FAILURE: 'ACCOUNTANTS_GET_ALL_FAILURE',
  ACCOUNTANT_ADD_REQUEST: 'ACCOUNTANT_ADD_REQUEST',
  ACCOUNTANT_ADD_SUCCESS: 'ACCOUNTANT_ADD_SUCCESS',
  ACCOUNTANT_ADD_FAILURE: 'ACCOUNTANT_ADD_FAILURE',
  ACCOUNTANT_UPDATE_REQUEST: 'ACCOUNTANT_UPDATE_REQUEST',
  ACCOUNTANT_UPDATE_SUCCESS: 'ACCOUNTANT_UPDATE_SUCCESS',
  ACCOUNTANT_UPDATE_FAILURE: 'ACCOUNTANT_UPDATE_FAILURE',
  ACCOUNTANT_DELETE_REQUEST: 'ACCOUNTANT_DELETE_REQUEST',
  ACCOUNTANT_DELETE_SUCCESS: 'ACCOUNTANT_DELETE_SUCCESS',
  ACCOUNTANT_DELETE_FAILURE: 'ACCOUNTANT_DELETE_FAILURE',
};

export default accountantsConstants;
