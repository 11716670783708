import React, {
  useState,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Card } from '_components/_core';
import { SortableColumns } from '_components/_core/Table/components';

import Row from './components/Row/Row';
import RowForm from './components/RowForm/RowFormContainer';

import { StyledTable, StyledFormCheck } from '../../styles';

function Table({
  transactions,
  accounts,
  selectedItems,
  sorting,
  sortableColumns,
  bank_statement_account_id,
  onItemSelected,
  onSelectAll,
  onUpdateMultipleTransactions,
  onReconcileTransactions,
}) {
  const transactionFormRef = useRef();

  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [selectedFieldName, setSelectedFieldName] = useState(null);

  const handleEditTransaction = useCallback((id, fieldName) => {
    setSelectedTransactionId(id);
    setSelectedFieldName(fieldName);
  }, []);

  const handleCancelEditTransaction = useCallback(() => {
    setSelectedTransactionId(null);
    setSelectedFieldName(null);
  }, []);

  const handleSubmitTransaction = useCallback((values) => {
    onUpdateMultipleTransactions({
      ids: [values.id],
      ...values,
    }, () => {
      onReconcileTransactions();
    });

    setSelectedTransactionId(null);
  }, [onUpdateMultipleTransactions, onReconcileTransactions]);

  return (
    <Card>
      <div>
        <StyledTable className="bg-white" hover>
          <thead>
            <tr>
              <th className="checkbox-column">
                <StyledFormCheck
                  type="checkbox"
                  checked={!isEmpty(transactions) && selectedItems.length === transactions.length}
                  onClick={onSelectAll}
                />
              </th>
              <SortableColumns sorting={sorting} columns={sortableColumns} />
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <>
                {selectedTransactionId !== transaction.id && (
                  <Row
                    key={`normal-row-${transaction.id}`}
                    index={index}
                    total={transactions.length}
                    transaction={transaction}
                    accounts={accounts}
                    selectedItems={selectedItems}
                    selectedTransactionId={selectedTransactionId}
                    onItemSelected={onItemSelected}
                    onEditTransaction={handleEditTransaction}
                    transactionFormRef={transactionFormRef}
                  />
                )}
                {selectedTransactionId !== null && selectedTransactionId === transaction.id && (
                  <RowForm
                    key={`form-row-${transaction.id}`}
                    transaction={transaction}
                    transactionFormRef={transactionFormRef}
                    selectedFieldName={selectedFieldName}
                    onSubmit={handleSubmitTransaction}
                    onCancelEditTransaction={handleCancelEditTransaction}
                    bank_statement_account_id={bank_statement_account_id}
                    onReconcileTransactions={onReconcileTransactions}
                  />
                )}
              </>
            ))}
          </tbody>
        </StyledTable>
      </div>
    </Card>
  );
}

Table.defaultProps = {
  transactions: [],
  accounts: [],
  selectedItems: [],
  sorting: {},
  sortableColumns: [],
  bank_statement_account_id: null,
};

Table.propTypes = {
  transactions: PropTypes.array,
  accounts: PropTypes.array,
  sortableColumns: PropTypes.array,
  sorting: PropTypes.object,
  selectedItems: PropTypes.array,
  bank_statement_account_id: PropTypes.string,
  onItemSelected: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onUpdateMultipleTransactions: PropTypes.func.isRequired,
  onReconcileTransactions: PropTypes.func.isRequired,
};

export default Table;
