import constants from '../../_constants/accountants.constants';
import service from '../../../services/accountants.service';
import alertActionCreators from '../alert.actions';

import getAllAccountants from './getAllAccountants';

const updateAccountant = (id, accountant, callback) => {
  return (dispatch) => {
    dispatch(request(accountant));

    service.update(id, accountant).then(
      (accountant) => {
        dispatch(success(accountant));
        dispatch(alertActionCreators.success('Contador atualizado com sucesso!'));
        dispatch(getAllAccountants());

        if (callback) {
          callback();
        }
      },
    );
  };

  function request(accountant) {
    return {
      type: constants.ACCOUNTANT_UPDATE_REQUEST,
      payload: { accountant },
    };
  }
  function success(accountant) {
    return {
      type: constants.ACCOUNTANT_UPDATE_SUCCESS,
      payload: { accountant },
    };
  }
};

export default updateAccountant;
