import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

import fetchAllAccounts from './fetchAllAccounts';

const editTransactionAccount = (id, values, callback, errorCallback) => {
  return async dispatch => {
    dispatch(request(id, values));

    service
      .updateTransactionAccount(id, values)
      .then(response => {
        dispatch(success(response));
        dispatch(alertActionCreators.success('Conta editada com sucesso!'));
        dispatch(fetchAllAccounts());

        if (callback) {
          callback();
        }
      })
      .catch(error => {
        dispatch(failure(error));

        if (errorCallback) {
          errorCallback();
        }
      });
  };

  function request(id, values) {
    return {
      type: constants.UPDATE_TRANSACTION_ACCOUNT_REQUEST,
      payload: {
        id,
        values,
      },
    };
  }
  function success(response) {
    return {
      type: constants.UPDATE_TRANSACTION_ACCOUNT_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.UPDATE_TRANSACTION_ACCOUNT_FAILURE,
      payload: error,
    };
  }
};

export default editTransactionAccount;
