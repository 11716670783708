import { connect } from 'react-redux';

import entriesActionCreators from '_store/_actions/entries';

import DeleteEntries from './DeleteEntries';

const mapStateToProps = (state) => ({
  isLoading: state.entries.isLoading,
});

const mapDispatchToProps = {
  onDeleteEntries: entriesActionCreators.deleteManyEntries,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteEntries);
