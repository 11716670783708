import { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import { TiArrowSortedDown } from 'react-icons/ti';
import { DateTime } from 'luxon';
import { IoTrash } from 'react-icons/io5';

import MaskedInput from '_components/_core/MaskedInput';

import './styles.css';
import { StyledDropdownCalendar } from './styles';

registerLocale('pt-BR', ptBR);
moment.tz.setDefault('America/Sao_Paulo');

export default function DatePicker({
  placeholder,
  selectedDate,
  inline,
  onChange,
  onValueChange,
  onMonthChange,
  placement,
  hideCalendar,
  innerRef,
  variant,
  triggerText,
  inputStyleProps,
  withPortal,
  allowClearWithPortal,
  filterDate,
  ...restProps
}) {
  const startRef = useRef();

  const formattedValue = useCallback(() => {
    if (!selectedDate) return placeholder;

    return DateTime.fromJSDate(selectedDate, { zone: 'utc' })
      .set({
        hour: 12,
      })
      .toFormat('dd/MM/yyyy');
  }, [placeholder, selectedDate]);

  const renderCustomInput = useCallback(() => {
    if (variant === 'dropdown') {
      return (
        <StyledDropdownCalendar variant="link" className="p-0">
          {triggerText}
          <TiArrowSortedDown className="ml-1" />
        </StyledDropdownCalendar>
      );
    }

    if (withPortal) {
      return (
        <div style={{ ...inputStyleProps }}>
          {formattedValue()}
          {selectedDate && allowClearWithPortal && (
            <IoTrash
              className="text-danger ml-2"
              size="1em"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();

                onChange(null);
              }}
              style={{
                cursor: 'pointer',
              }}
            />
          )}
        </div>
      );
    }

    return (
      <MaskedInput
        style={{
          ...inputStyleProps,
        }}
        innerRef={innerRef}
        maskType="DATE"
        guide
        onChange={onValueChange}
      />
    );
  }, [
    variant,
    triggerText,
    innerRef,
    inputStyleProps,
    formattedValue,
    withPortal,
    allowClearWithPortal,
    selectedDate,
    onChange,
    onValueChange,
  ]);

  const onKeyDown = e => {
    if (e.keyCode === 9 || e.which === 9) {
      if (innerRef && innerRef.current) {
        innerRef.current.setOpen(false);
      } else if (startRef && startRef.current) {
        startRef.current.setOpen(false);
      }
    }
  };

  return (
    <ReactDatePicker
      customInput={renderCustomInput()}
      className="form-control"
      dateFormat="dd/MM/yyyy"
      locale="pt-BR"
      inline={inline}
      placeholderText={placeholder}
      selected={selectedDate}
      onChange={onChange}
      onMonthChange={onMonthChange}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      popperPlacement={placement}
      hideCalendar={hideCalendar}
      enableTabLoop={false}
      peekNextMonth={false}
      onKeyDown={onKeyDown}
      filterDate={filterDate}
      ref={innerRef || startRef}
      withPortal={withPortal}
      portalId="root-portal"
      {...restProps}
    />
  );
}

DatePicker.defaultProps = {
  placeholder: '',
  selectedDate: null,
  inline: false,
  placement: 'bottom-start',
  hideCalendar: false,
  onValueChange: () => {},
  innerRef: null,
  inputStyleProps: {},
  withPortal: false,
  allowClearWithPortal: false,
  filterDate: null,
};

DatePicker.propTypes = {
  placeholder: PropTypes.string,
  placement: PropTypes.string,
  selectedDate: PropTypes.instanceOf(Date),
  inline: PropTypes.bool,
  onChange: PropTypes.func,
  onMonthChange: PropTypes.func,
  hideCalendar: PropTypes.bool,
  onValueChange: PropTypes.func,
  innerRef: PropTypes.object,
  variant: PropTypes.string,
  triggerText: PropTypes.string,
  inputStyleProps: PropTypes.object,
  withPortal: PropTypes.bool,
  allowClearWithPortal: PropTypes.bool,
  filterDate: PropTypes.func,
};
