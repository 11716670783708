import { SPECIAL_COLUMNS } from '_components/_core/Table';
import FORMATTERS from 'helpers/formatters';
import getTableTopDistance from './getTableTopDistance';

export {
  getTableTopDistance,
};

export const DEFAULT_COLUMNS = [
  'event_date',
  'narration',
  'amount',
  'debit_account_id',
  'credit_account_id',
  'created_at',
  'id',
];

export const TABLE_COLUMNS = [
  {
    label: 'Ações',
    keyName: 'id',
    align: 'center',
    width: 5,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'Data',
    keyName: 'event_date',
    sortingKey: 'event_date',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.DATE_DDMMYYYY,
    order: 2,
  },
  {
    label: 'Débito',
    keyName: 'debit_account_id',
    sortingKey: 'debit_account_id',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.ACCOUNT_NAME,
    order: 3,
  },
  {
    label: 'Crédito',
    keyName: 'credit_account_id',
    sortingKey: 'credit_account_id',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.ACCOUNT_NAME,
    order: 4,
  },
  {
    label: 'Histórico',
    keyName: 'narration',
    sortingKey: 'narration',
    align: 'left',
    width: 40,
    formatter: FORMATTERS.UPPERCASE,
    order: 5,
  },
  {
    label: 'Valor',
    keyName: 'amount',
    sortingKey: 'amount',
    align: 'center',
    width: 20,
    formatter: FORMATTERS.NUMBER,
    sumOnFooter: true,
    order: 6,
  },
  {
    label: 'Criado em',
    keyName: 'created_at',
    sortingKey: 'created_at',
    align: 'center',
    width: 5,
    order: 7,
    formatter: FORMATTERS.DATE_DDMMYYYYHHMMSS,
  },
];

export const INVOICE_STATUS = [
  {
    label: 'Todos',
    value: null,
  },
  {
    label: 'Normal',
    value: 'NORMAL',
  },
  {
    label: 'Cancelada',
    value: 'CANCELLED',
  },
];

export const ISS_WITHHELD = [
  {
    label: 'Todos',
    value: null,
  },
  {
    label: 'Sim',
    value: true,
  },
  {
    label: 'Não',
    value: false,
  },
];

export const FILTERS_CONFIGURATION = {
  event_date_start: {
    label: 'Data do Lançamento a partir de',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  event_date_end: {
    label: 'Data do Lançamento até',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  amount_start: {
    label: 'Valor do Lançamento a partir de',
    formatter: FORMATTERS.NUMBER,
  },
  amount_end: {
    label: 'Valor do Lançamento até',
    formatter: FORMATTERS.NUMBER,
  },
  narration: {
    label: 'Histórico contém',
    formatter: FORMATTERS.STRING_WITH_QUOTES,
  },
};
