import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';

import { useMediaQuery } from 'helpers';

import {
  Container,
  Title,
  Description,
  StyledCol,
} from './styles';

export default function CardHeader({
  title,
  description,
  sideContent,
  icon,
  leftColSize,
  rightColSize,
  adjustSideContentMobile,
  className,
  containerClassName,
}) {
  const { isMobile } = useMediaQuery();

  return (
    <Container className={containerClassName}>
      <Row>
        <Col
          xs={(adjustSideContentMobile && isMobile) || !sideContent ? 12 : leftColSize}
          className={classNames(className || (sideContent ? '' : 'mb-3'))}
        >
          <div className="d-flex flex-column justify-content-center">
            <Title className="card-title d-flex align-items-center">
              {title}
              {icon && icon}
            </Title>
            {description && (
              <Description className="card-description">
                {description}
              </Description>
            )}
          </div>
        </Col>
        {sideContent && (
          <StyledCol
            xs={adjustSideContentMobile && isMobile ? 12 : rightColSize}
            adjustSideContentMobile={isMobile && adjustSideContentMobile}
            className="d-flex flex-row-reverse align-items-center mb-3"
          >
            {sideContent}
          </StyledCol>
        )}
      </Row>
    </Container>
  );
}

CardHeader.defaultProps = {
  icon: null,
  leftColSize: 8,
  rightColSize: 4,
  adjustSideContentMobile: true,
  className: null,
  containerClassName: null,
};

CardHeader.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  adjustSideContentMobile: PropTypes.bool,
  description: PropTypes.any,
  sideContent: PropTypes.node,
  leftColSize: PropTypes.number,
  rightColSize: PropTypes.number,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};
