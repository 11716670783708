import { connect } from 'react-redux';

import ibgeActionCreators from '_store/_actions/ibge';
import companyActionCreators from '_store/_actions/company';

import EditFinancialCompanyModal from './EditFinancialCompanyModal';

const mapStateToProps = (state) => ({
  isLoadingCities: state.ibge.isLoadingCities,
  isLoadingStates: state.ibge.isLoadingStates,
  isLoading: state.company.isLoading,
  cities: state.ibge.cities,
  states: state.ibge.states,
});

const mapDispatchToProps = {
  onFetchStates: ibgeActionCreators.fetchStates,
  onFetchCities: ibgeActionCreators.fetchCities,
  onUpdateFinancialCompany: companyActionCreators.updateFinancialCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFinancialCompanyModal);
