import React, {
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';

import {
  Button,
  FormTextField,
  FormDateField,
  FormSelectField,
  FormCpfCnpjField,
  FormMaskedInput,
  Select,
} from '_components/_core';
import { DOCUMENT_TYPE_OPTIONS } from 'helpers/constants';

import { AccountantSchema, accountantFormFields } from '../../utilities';
import {
  SearchButton,
  StyledModal,
  ModalFooter,
  StyledHr,
} from './styles';

const AccountantModal = ({
  accountant,
  states,
  cities,
  isVisible,
  isLoading,
  isLoadingCep,
  onModalToggle,
  onAddAccountant,
  onUpdateAccountant,
  onFetchCities,
  onFetchStates,
  onFetchCep,
}) => {
  const handleAddEditAccountant = useCallback((values, { resetForm }) => {
    if (values.id) {
      onUpdateAccountant(values.id, values, () => {
        resetForm();
        onModalToggle();
      });
    } else {
      onAddAccountant(values, () => {
        resetForm();
        onModalToggle();
      });
    }
  }, [onUpdateAccountant, onAddAccountant, onModalToggle]);

  const handleCepSearch = useCallback(async (values, setFieldValue) => {
    onFetchCep(values.address_zip_code, (address) => {
      const {
        bairro,
        complemento,
        localidade,
        logradouro,
        uf,
        ibge,
        ddd,
      } = address;

      setFieldValue('address_street', logradouro);
      setFieldValue('address_number', null);
      setFieldValue('address_complement', complemento);
      setFieldValue('address_district', bairro);
      setFieldValue('address_state', uf);
      setFieldValue('address_state_ibge', Number(ddd));
      onFetchCities(Number(ddd), () => {
        setFieldValue('address_city_ibge', Number(ibge));
        setFieldValue('address_city', localidade);
      });
    });
  }, [onFetchCep, onFetchCities]);

  const handleStateIbgeChange = useCallback((option, setFieldValue) => {
    setFieldValue('address_state_ibge', option.value);
    setFieldValue('address_state', option.sigla);

    onFetchCities(option.sigla, () => {
      setFieldValue('address_city', null);
      setFieldValue('address_city_ibge', null);
    });
  }, [onFetchCities]);

  const handleCityIbgeChange = useCallback((option, setFieldValue) => {
    setFieldValue('address_city_ibge', option.value);
    setFieldValue('address_city', option.label);
  }, []);

  const handleCrcStateChange = useCallback((option, setFieldValue) => {
    setFieldValue('crc_uf', option.value);
  }, []);

  const renderModalFooter = useCallback((handleSubmit, isValid) => (
    <ModalFooter>
      <Button variant="secondary" onClick={onModalToggle}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={!isValid || isLoading}
      >
        Salvar
      </Button>
    </ModalFooter>
  ), [onModalToggle, isLoading]);

  useEffect(() => {
    onFetchStates();
  }, [onFetchStates]);

  const getSelectedState = useCallback((values) => {
    if (!values.address_state_ibge) {
      return null;
    }

    return states.find((state) => state.value === Number(values.address_state_ibge));
  }, [states]);

  const getCrcState = useCallback((values) => {
    if (!values.crc_uf) {
      return null;
    }

    return states.find((state) => state.value === Number(values.crc_uf));
  }, [states]);

  const getSelectedCity = useCallback(
    (values) => {
      if (!values.address_city_ibge) {
        return null;
      }

      return cities.find((city) => Number(city.value) === Number(values.address_city_ibge));
    }, [cities],
  );

  const formInitialValues = useMemo(() => {
    if (accountant) {
      const { address_state } = accountant;

      if (address_state) {
        onFetchCities(address_state, () => accountant);

        return accountant;
      }

      return accountant;
    }

    return accountantFormFields;
  }, [accountant, onFetchCities]);

  if (!isVisible) {
    return null;
  }

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={AccountantSchema}
      onSubmit={handleAddEditAccountant}
      enableReinitialize
    >
      {({
        handleSubmit,
        isValid,
        values,
        setFieldValue,
      }) => (
        <>
          <StyledModal
            title={accountant ? 'Editar Contador' : 'Cadastrar novo Contador'}
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit, isValid)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="4">
                  <Form.Label>Tipo de Documento</Form.Label>
                  <FormSelectField
                    name="document_type"
                    options={DOCUMENT_TYPE_OPTIONS}
                  />
                </Form.Group>
                <Form.Group as={Col} md="8">
                  <Form.Label>{values.document_type}</Form.Label>
                  <FormCpfCnpjField name="document_number" placeholder={values.document_type} type={values.document_type} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>{values.document_type === 'CPF' ? 'Nome completo' : 'Razão Social'}</Form.Label>
                  <FormTextField
                    name="name"
                    placeholder="Digite o nome do Contador."
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>E-mail</Form.Label>
                  <FormTextField
                    name="email"
                    placeholder="E-mail"
                  />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Telefone</Form.Label>
                  <FormMaskedInput
                    name="phone_number"
                    placeholder="Telefone"
                    type="PHONE"
                  />
                </Form.Group>
              </Form.Row>
              <StyledHr />
              <Form.Row>
                <Form.Group as={Col} md="4">
                  <Form.Label>CRC</Form.Label>
                  <FormTextField
                    name="crc_number"
                    placeholder="Numero do CRC"
                  />
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Label>Data de Validade</Form.Label>
                  <FormDateField
                    name="crc_expire_date"
                    placeholder="Data de Validade"
                  />
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Label>Unidade Federativa</Form.Label>
                  <Select
                    name="crc_uf"
                    onChange={(option) => handleCrcStateChange(option, setFieldValue)}
                    value={getCrcState(values)}
                    options={states}
                  />
                </Form.Group>
              </Form.Row>
              <StyledHr />
              <Form.Row>
                <Form.Group as={Col} md="4">
                  <Form.Label>CEP</Form.Label>
                  <FormMaskedInput
                    type="ZIP_CODE"
                    name="address_zip_code"
                    placeholder="CEP"
                  />
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <SearchButton
                    disabled={!values.address_zip_code}
                    type="button"
                    variant="secondary"
                    isLoading={isLoadingCep}
                    onClick={() => handleCepSearch(values, setFieldValue)}
                  >
                    Buscar CEP
                  </SearchButton>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="4">
                  <Form.Label>Logradouro</Form.Label>
                  <FormTextField
                    name="address_street"
                    placeholder="Logradouro"
                  />
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Label>Número</Form.Label>
                  <FormTextField name="address_number" placeholder="Número" />
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Label>Bairro</Form.Label>
                  <FormTextField name="address_district" placeholder="Bairro" />
                </Form.Group>

              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="4">
                  <Form.Label>Complemento</Form.Label>
                  <FormTextField
                    name="address_complement"
                    placeholder="Complemento"
                  />
                </Form.Group>
                <Form.Group as={Col} lg="4">
                  <Form.Label>Unidade Federativa</Form.Label>
                  <Select
                    name="address_state_ibge"
                    onChange={(option) => handleStateIbgeChange(option, setFieldValue)}
                    value={getSelectedState(values)}
                    options={states}
                    menuPlacement="top"
                  />
                </Form.Group>
                <Form.Group as={Col} lg="4">
                  <Form.Label>Cidade</Form.Label>
                  <Select
                    name="address_city_ibge"
                    onChange={(option) => handleCityIbgeChange(option, setFieldValue)}
                    value={getSelectedCity(values)}
                    options={cities}
                    menuPlacement="top"
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default AccountantModal;

AccountantModal.defaultProps = {
  isVisible: false,
  accountant: null,
  isLoading: false,
};

AccountantModal.propTypes = {
  isVisible: PropTypes.bool,
  accountant: PropTypes.object,
  onModalToggle: PropTypes.func.isRequired,
  onAddAccountant: PropTypes.func.isRequired,
  onUpdateAccountant: PropTypes.func.isRequired,
  isLoadingCep: PropTypes.bool.isRequired,
  onFetchStates: PropTypes.func.isRequired,
  onFetchCities: PropTypes.func.isRequired,
  onFetchCep: PropTypes.func.isRequired,
  states: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  isLoading: PropTypes.func,
};
