import { ibgeConstants } from '../_constants';

const INITIAL_STATE = {
  cities: [],
  states: [],
  isLoadingCities: false,
  isLoadingStates: false,
  isLoadingCep: false,
  statesReady: false,
  citiesReady: false,
  isLoadingCnae: false,
};

export function ibge(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ibgeConstants.FETCH_STATES_REQUEST: {
      return {
        ...state,
        isLoadingStates: true,
        statesReady: false,
      };
    }
    case ibgeConstants.FETCH_STATES_SUCCESS: {
      const { states } = action.payload;

      return {
        ...state,
        isLoadingStates: false,
        states,
        statesReady: true,
      };
    }
    case ibgeConstants.FETCH_STATES_FAILURE: {
      return {
        ...state,
        isLoadingStates: false,
        statesReady: false,
      };
    }
    case ibgeConstants.FETCH_CITIES_REQUEST: {
      return {
        ...state,
        isLoadingCities: true,
        statesReady: false,
      };
    }
    case ibgeConstants.FETCH_CITIES_SUCCESS: {
      const { cities } = action.payload;

      return {
        ...state,
        isLoadingCities: false,
        cities,
        citiesReady: true,
      };
    }
    case ibgeConstants.FETCH_CITIES_FAILURE: {
      return {
        ...state,
        isLoadingCities: false,
        statesReady: false,
      };
    }
    case ibgeConstants.FETCH_CEP_REQUEST: {
      return {
        ...state,
        isLoadingCep: true,
      };
    }
    case ibgeConstants.FETCH_CEP_SUCCESS: {
      return {
        ...state,
        isLoadingCep: false,
      };
    }
    case ibgeConstants.FETCH_CEP_FAILURE: {
      return {
        ...state,
        isLoadingCep: false,
      };
    }
    case ibgeConstants.FETCH_CNAE_REQUEST: {
      return {
        ...state,
        isLoadingCnae: true,
      };
    }
    case ibgeConstants.FETCH_CNAE_SUCCESS: {
      return {
        ...state,
        isLoadingCnae: false,
      };
    }
    case ibgeConstants.FETCH_CNAE_FAILURE: {
      return {
        ...state,
        isLoadingCnae: false,
      };
    }
    default:
      return state;
  }
}
