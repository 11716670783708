import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import AttachmentsQuota from './AttachmentsQuota';

const mapStateToProps = (state) => ({
  consumedStorage: state.companyTransactions.consumedStorage,
});

const mapDispatchToProps = {
  onChangeSelectedAccountIds: companyTransactionsActionCreators.setSelectedAccountIds,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AttachmentsQuota);
