import { SPECIAL_COLUMNS } from '_components/_core/Table';

export const DEFAULT_COLUMNS = [
  'description',
  'id',
];

export const TABLE_COLUMNS = [
  {
    label: 'Ações',
    keyName: 'id',
    align: 'center',
    width: 10,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'Categoria',
    keyName: 'description',
    sortingKey: 'description',
    align: 'left',
    width: 90,
    order: 2,
  },
];
