import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';

const getAllCompanies = (params = {}) => {
  return (dispatch) => {
    dispatch(request());

    const {
      sorting = {},
      filters = {},
      pagination = {},
    } = params;

    const parsedParams = {
      ...filters,
    };

    if (sorting && sorting.field) {
      parsedParams.order_by = `${sorting.field}${sorting.order === 'desc' ? '-' : ''}`;
    }

    if (pagination) {
      const { currentPage, itemsPerPage } = pagination;

      parsedParams.page = currentPage;
      parsedParams.limit = itemsPerPage;
    }

    service.getAll(parsedParams).then(
      (response) => {
        const { total, data } = response.data;

        dispatch(success({ total, data }));
      },
    );
  };

  function request() {
    return { type: constants.COMPANY_GET_ALL_REQUEST, payload: {} };
  }
  function success(companies) {
    return {
      type: constants.COMPANY_GET_ALL_SUCCESS,
      payload: companies,
    };
  }
};

export default getAllCompanies;
