import { connect } from 'react-redux';

import invoicesActionCreators from '_store/_actions/invoices';
import reportsActionCreators from '_store/_actions/reports';

import Dashboard from './Dashboard';

const mapStateToProps = (state) => ({
  revenue: state.reports.revenue,
  isLoadingRevenueReport: state.reports.isLoading,
  fiscalOverview: state.invoices.fiscalOverview,
  isLoadingFiscalOverview: state.invoices.isLoading,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onFetchRevenueReport: reportsActionCreators.getRevenueReport,
  onFetchFiscalOverview: invoicesActionCreators.getFiscalOverview,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
