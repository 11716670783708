import * as yup from 'yup';
import { ptShort } from 'yup-locale-pt';

yup.setLocale(ptShort);

const PasswordResetSchema = yup.object().shape({
  email: yup.string().email().required(),
});

export default PasswordResetSchema;
