import { connect } from 'react-redux';

import entriesActionCreators from '_store/_actions/entries';

import UpdateEntriesAmount from './UpdateEntriesAmount';

const mapStateToProps = (state) => ({
  isLoading: state.entries.isLoading,
});

const mapDispatchToProps = {
  onUpdateEntries: entriesActionCreators.updateManyEntries,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateEntriesAmount);
