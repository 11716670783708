import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { useMediaQuery } from 'helpers';
import {
  Button,
  Card,
  CardBody,
  PageHeader,
  Tag,
} from '_components/_core';

import FORMATTERS from 'helpers/formatters';
import { CompanyAvatarUpload, EditFinancialCompanyModal } from './components';

function Companies({
  activeCompany,
  companies,
  subscription,
  user,
  onDeleteFinancialCompany,
  onUpdateFinancialCompany,
  onCompanyAvatarUpload,
  onCompanyAvatarDelete,
  onFetchCompanies,
  onFetchMarketSegments,
}) {
  const history = useHistory();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isDesktopExtraLarge } = useMediaQuery();

  const { plan_usage } = subscription || {};
  const { companies: companiesUsage = {} } = plan_usage || {};
  const { used: usedCompanies = 0, max = 0 } = companiesUsage;

  const usedText = `${usedCompanies.toString().padStart(2, '0')}/${max.toString().padStart(2, '0')} empresas`;

  useEffect(() => {
    onFetchMarketSegments();
  }, [onFetchMarketSegments]);

  const handleEditCompany = useCallback((company) => {
    setSelectedCompany(company);
    setIsModalOpen(true);
  }, []);

  const shouldHideDeleteButton = useMemo(() => {
    if (!user) {
      return false;
    }

    return user.manager_id !== null;
  }, [user]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Empresas"
        description={usedText}
        variant="small"
        sideContent={(
          <Button
            variant="dark"
            icon={<BsPlus />}
            onClick={() => history.push('/nova-empresa')}
          >
            Nova Empresa
          </Button>
        )}
      />
      <EditFinancialCompanyModal
        isVisible={isModalOpen}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
        onUpdateFinancialCompany={onUpdateFinancialCompany}
        selectedCompany={selectedCompany}
      />
      <Row>
        {companies.map((company) => {
          const { id } = company;

          return (
            <>
              <Col key={id} xl={isDesktopExtraLarge ? 6 : 12}>
                <Card className="mt-3">
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <div>
                        <CompanyAvatarUpload
                          key={id}
                          company_id={company.id}
                          company_avatar_url={company.company_avatar_url}
                          onCompanyAvatarUpload={onCompanyAvatarUpload}
                          onCompanyAvatarDelete={onCompanyAvatarDelete}
                          onFetchCompanies={onFetchCompanies}
                        />
                      </div>
                      <div>
                        <h5>
                          {company.company_name}
                          {activeCompany && activeCompany.id === id && (
                          <Tag className="ml-3">
                            ATIVA
                          </Tag>
                          )}
                        </h5>
                        <p className="text-muted mb-0">
                          {FORMATTERS.CPF_CNPJ(company.document_number)}
                        </p>
                      </div>
                      <div className="ml-auto">
                        <Button
                          noMargin
                          variant="inverse-dark btn-sm mr-2"
                          icon={<FaPencilAlt />}
                          onClick={() => handleEditCompany(company)}
                        />
                        {!shouldHideDeleteButton && (
                          <Button
                            noMargin
                            variant="inverse-danger btn-sm"
                            icon={<FaTrash />}
                            onClick={() => onDeleteFinancialCompany(id, company.company_name)}
                          />
                        )}
                      </div>
                    </div>
                    {/* <hr />
                    <div className="d-flex align-items-center" style={{ gap: '24px' }}>
                      <div className="d-flex align-items-center">
                        <div>
                          <small style={{ fontWeight: '600', fontSize: '12px' }}>
                            Receitas
                          </small>
                          <p className="m-0">R$ 1.036.836,81</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div>
                          <small style={{ fontWeight: '600', fontSize: '12px' }}>Despesas</small>
                          <p className="m-0">R$ 569.208,40</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div>
                          <small style={{ fontWeight: '600', fontSize: '12px' }}>Resultado</small>
                          <p className="m-0">R$ 467.628,41</p>
                        </div>
                      </div>
                      <div className="ml-auto">
                        <Tag variant="warning">
                          Controle Empresarial
                        </Tag>
                      </div>
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
            </>
          );
        })}
      </Row>
    </Container>
  );
}

Companies.defaultProps = {
  companies: [],
  activeCompany: {},
  subscription: {},
  user: null,
};

Companies.propTypes = {
  companies: PropTypes.array,
  activeCompany: PropTypes.object,
  subscription: PropTypes.object,
  user: PropTypes.object,
  onDeleteFinancialCompany: PropTypes.func.isRequired,
  onUpdateFinancialCompany: PropTypes.func.isRequired,
  onCompanyAvatarUpload: PropTypes.func,
  onCompanyAvatarDelete: PropTypes.func,
  onFetchCompanies: PropTypes.func,
  onFetchMarketSegments: PropTypes.func.isRequired,
};

export default Companies;
