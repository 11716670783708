import styled, { css } from 'styled-components';
import { darken } from 'polished';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { Table, Button } from '_components/_core';

export const StyledTable = styled(Table)`
  &&& {
    thead tr th {
      padding: 10px 15px !important;
    }
    tbody tr td {
      padding: 10px 15px !important;
    }
    tfoot tr td {
      padding: 10px 15px !important;
    }
  }
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  border: none;
`;

export const StyledButton = styled(Button)`
  background-color: #FFF;
  border: none;
  padding: 12px;

  &:hover {
    background-color: ${darken(0.05, '#FFF')};
  }
  &:active {
    color: #727cf5;
    background-color: ${darken(0.05, '#FFF')} !important;
  }
  &:disabled {
    color: #727cf5 !important;
    background-color: ${darken(0.05, '#FFF')} !important;
    cursor: not-allowed;
  }

  ${({ special }) => special && css`
    color: #727cf5;
    cursor: default !important;

    &:hover {
      color: #727cf5 !important;
      background-color: ${darken(0.05, '#FFF')} !important;
    }
    &:active {
      color: #727cf5 !important;
      background-color: ${darken(0.05, '#FFF')} !important;
    }
  `}
`;
