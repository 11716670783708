import * as Yup from 'yup';
import { ptShort } from 'yup-locale-pt';

Yup.setLocale(ptShort);

const CompanyCreateSchema = Yup.object().shape({
  document_number: Yup.string().max(18).required(),
  company_name: Yup.string().max(255).required().nullable(),
  trading_name: Yup.string().max(255).required().nullable(),
  municipal_registration: Yup.string().nullable(),
  date_founded: Yup.date().required(),
  date_closed: Yup.date().nullable().default(null),
  status_irs: Yup.string().required(),
  phone_number: Yup.string(),
  email: Yup.string().email(),
  address_zip_code: Yup.string(),
  address_street: Yup.string().required(),
  address_number: Yup.number().required(),
  address_district: Yup.string().required(),
  address_complement: Yup.string().nullable().default(null),
  address_city: Yup.string(),
  address_city_ibge: Yup.number().required(),
  address_state: Yup.string(),
  address_state_ibge: Yup.number().required(),
  cnaes: Yup.array().test('cnae-error', 'É necessário incluir pelo menos um CNAE principal', (cnaes) => cnaes.some((cnae) => cnae.is_primary)),
  tax_regimes: Yup.array().min(1).required(),
});

export default CompanyCreateSchema;
