import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';

import { useMediaQuery } from 'helpers';
import {
  AvatarMenu,
  ActivatedCompany,
  TrialCounter,
} from '_components/_shared';

import Logo from './components/Logo';
import LogoSmall from './components/LogoSmall';
import LogoZenpaper from './components/LogoZenpaper';

import {
  StyledNavbar,
  NavbarContent,
  StyledButton,
  StyledNavbarBrandDesktop,
  NavbarBrandContainerDesktop,
} from './styles';
import SearchIcon from './components/SearchIcon/SearchIcon';
import LogoZenpaperSmall from './components/LogoZenpaperSmall';
import SettingsMenu from './components/SettingsMenu/SettingsMenuContainer';

export default function NavBar({
  activeCompany,
  collapsed,
  user,
  onSidebarToggle,
  onLoadUserPermissions,
  onLogout,
  onFetchBlockedPeriod,
}) {
  const { isMobile, isTablet } = useMediaQuery();
  const location = useLocation();

  const [pageTitle, setPageTitle] = useState(document.title);

  useEffect(() => {
    onLoadUserPermissions(user.id);
    onFetchBlockedPeriod();

    const handleRouteChange = () => {
      setTimeout(() => {
        setPageTitle(
          document.title
            .replace(' - Zenply', '')
            .replace('Extrato Bancário', ''),
        );
      }, 0);
    };

    handleRouteChange();
    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [location, user, activeCompany, onLoadUserPermissions, location.pathname]);

  const renderLogo = useCallback(() => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    if (product === 'contabills-empresas') {
      return <LogoZenpaper />;
    }

    if (collapsed && !isMobile && !isTablet) {
      return <LogoSmall />;
    }

    return <Logo />;
  }, [collapsed, isMobile, isTablet]);

  const renderCenterLogo = useCallback(() => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    if (product === 'contabills-empresas') {
      return <LogoZenpaperSmall />;
    }

    return <Logo />;
  }, []);

  const getPropertyRecursive = useCallback((items, pathname) => {
    let result = null;

    items.forEach(item => {
      if (item.path === pathname) {
        result = item.title;
      }

      if (item.children) {
        result = getPropertyRecursive(item.children, pathname);
      }
    });

    return result;
  }, []);

  if (isMobile) {
    return (
      <StyledNavbar
        isMobile={isMobile}
        expand="lg"
        fixed="top"
        className="no-print"
      >
        <h6 className="m-0 text-uppercase">{pageTitle}</h6>
        <div className="d-flex justify-content-center align-items-center">
          <ActivatedCompany widgetType="NAVBAR" />
          <SettingsMenu
            user={user}
            isMobile={isMobile || isTablet}
            onLogout={onLogout}
          />
        </div>
      </StyledNavbar>
    );
  }

  return (
    <StyledNavbar expand="lg" fixed="top" className="no-print">
      {!isMobile && !isTablet && (
        <>
          <NavbarBrandContainerDesktop className="mr-3" collapsed={collapsed}>
            <StyledNavbarBrandDesktop href="#dashboard">
              {renderLogo()}
            </StyledNavbarBrandDesktop>
          </NavbarBrandContainerDesktop>
          <ActivatedCompany widgetType="NAVBAR" />
        </>
      )}
      <NavbarContent isMobile={isMobile || isTablet}>
        {!isMobile && !isTablet && <span>&nbsp;</span>}
        {(isMobile || isTablet) && (
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <StyledButton
              className="p-0 mr-3"
              variant="link"
              onClick={onSidebarToggle}
            >
              {!collapsed && <AiOutlineMenuFold size="1.5rem" />}
              {collapsed && <AiOutlineMenuUnfold size="1.5rem" />}
            </StyledButton>
            {renderCenterLogo()}
          </span>
        )}
        <div className="row mr-0 mr-lg-4 d-flex justify-content-center align-items-center">
          {(isMobile || isTablet) && <ActivatedCompany widgetType="NAVBAR" />}
          <TrialCounter />
          <SearchIcon isMobile={isMobile || isTablet} />
          <AvatarMenu />
          <SettingsMenu
            user={user}
            isMobile={isMobile || isTablet}
            onLogout={onLogout}
          />
        </div>
      </NavbarContent>
    </StyledNavbar>
  );
}

NavBar.defaultProps = {
  user: {},
};

NavBar.propTypes = {
  activeCompany: PropTypes.object,
  user: PropTypes.object,
  collapsed: PropTypes.bool.isRequired,
  onSidebarToggle: PropTypes.func.isRequired,
  onLoadUserPermissions: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};
