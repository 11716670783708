import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { FormSelectField, Menu } from '_components/_core';
import { MenuCategory } from '_components/_core/Menu/Menu';

function Filters({
  handleSubmit,
  employees,
}) {
  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }, [handleSubmit]);

  const formattedEmployees = useMemo(
    () => employees.map((employee) => ({
      value: employee.id,
      label: employee.name,
    })),
    [employees],
  );

  return (
    <Menu className="mb-3 mb-md-0" onKeyPress={handleKeyPress}>
      <MenuCategory>
        Filtros
      </MenuCategory>
      <div className="ml-4 mr-4">
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>Funcionário</Form.Label>
              <FormSelectField name="selectedEmployeeId" options={formattedEmployees} />
            </Form.Group>
          </Col>
        </Form.Row>
      </div>
    </Menu>
  );
}

Filters.defaultProps = {
  employees: [],
};

Filters.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  employees: PropTypes.array,
};

export default Filters;
