import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { BsPlus } from 'react-icons/bs';
import { FaPencilAlt, FaTrash, FaPlay } from 'react-icons/fa';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { isEmpty } from 'lodash';
import sortBy from 'lodash/sortBy';
import ReactTooltip from 'react-tooltip';

import {
  Button,
  Card,
  CardBody,
  Tag,
  LoadingOverlay,
} from '_components/_core';

import SearchPDFInvoicesModal from './components/SearchPDFInvoicesModal/SearchPDFInvoicesModal';
import {
  Plan,
  PlanName,
  PlanInfo,
} from './styles';

function SearchInvoicesConfiguration({
  automationSettings,
  companies,
  onFetchAutomationSettings,
  onAddAutomationSettings,
  onDeleteAutomationSettings,
  onUpdateAutomationSettings,
  isLoading,
  onFetchCompaniesForSelect,
  onExecuteAutomation,
}) {
  const [selectedConfiguration, setSelectedConfiguration] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    onFetchCompaniesForSelect();
  }, [onFetchCompaniesForSelect]);

  const handleSearch = useCallback(() => {
    setSelectedConfiguration(null);
    setIsModalOpen(true);
  }, []);

  const orderedAutomationSettings = useMemo(
    () => sortBy(automationSettings, 'settings.day_of_month'),
    [automationSettings],
  );

  const renderStatusTag = (automation) => {
    const { settings } = automation || {};
    const { is_on } = settings || {};

    if (!is_on) {
      return (
        <Tag variant="danger" className="ml-2">
          Inativo
        </Tag>
      );
    }

    if (is_on) {
      return (
        <Tag variant="success" className="ml-2">
          Ativo
        </Tag>
      );
    }

    return null;
  };

  const renderDescription = (automation) => {
    const { settings } = automation || {};
    const { day_of_month } = settings || {};

    return `Todo dia ${day_of_month.toString().padStart(2, '0')}`;
  };

  const renderCompanies = useCallback((automation) => {
    if (isEmpty(companies)) {
      return null;
    }

    const { company_ids = [] } = automation || {};

    const relatedCompanies = companies.filter(
      (company) => company_ids.includes(company.id),
    );

    const company_names = relatedCompanies.map((company) => company.company_name).sort();

    return (
      `${company_names.join('<br />')}`
    );
  }, [companies]);

  const handleDelete = useCallback((id) => {
    onDeleteAutomationSettings(id, () => {
      onFetchAutomationSettings();
    });
  }, [onDeleteAutomationSettings, onFetchAutomationSettings]);

  const handleEdit = useCallback((automation) => {
    setSelectedConfiguration(automation);
    setIsModalOpen(true);
  }, []);

  const handleExecute = useCallback((automation) => {
    onExecuteAutomation(automation);
  }, [onExecuteAutomation]);

  return (
    <>
      <SearchPDFInvoicesModal
        companies={companies}
        isVisible={isModalOpen}
        selectedConfiguration={selectedConfiguration}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
        onAddAutomationSettings={onAddAutomationSettings}
        onUpdateAutomationSettings={onUpdateAutomationSettings}
        onFetchAutomationSettings={onFetchAutomationSettings}
        isLoading={isLoading}
      />
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h4 className="mb-0">
          Importação Automática de NFS-e
          <p className="text-muted mt-2">O Contabills irá buscar automaticamente as Notas de Serviço das empresas configuradas abaixo</p>
        </h4>
        <Button
          variant="primary"
          icon={<BsPlus />}
          onClick={handleSearch}
        >
          Nova configuração
        </Button>
      </div>
      <LoadingOverlay active={isLoading}>
        <Row className="mb-3">
          {orderedAutomationSettings.map((automation) => {
            const { _id, company_ids } = automation || {};
            return (
              <Col key={_id} xs={12} sm={12} lg={6} xl={4} className="mb-3">
                <Card>
                  <CardBody className="p-3">
                    <Row>
                      <Col>
                        <Plan>
                          <PlanInfo>
                            <div>
                              <PlanName className="mb-0">
                                {renderDescription(automation)}
                              </PlanName>
                              <p className="mb-0 text-muted">
                                Importação Automática de NFS-e
                              </p>
                            </div>
                          </PlanInfo>
                          <div>
                            {renderStatusTag(automation)}
                          </div>
                        </Plan>
                      </Col>
                    </Row>
                    <hr className="mt-3 mb-3" />
                    <div className="d-flex align-items-center justify-content-between">
                      <ReactTooltip place="bottom" html />
                      <Button data-tip={renderCompanies(automation)} variant="link btn-sm" className="p-0 m-0">
                        {`Empresas afetadas (${company_ids.length})`}
                      </Button>
                      <div>
                        <Button
                          noMargin
                          variant="inverse-primary btn-sm mr-2"
                          icon={<FaPlay />}
                          onClick={() => handleExecute(automation)}
                        />
                        <Button
                          noMargin
                          variant="inverse-primary btn-sm mr-2"
                          icon={<FaPencilAlt />}
                          onClick={() => handleEdit(automation)}
                        />
                        <Button
                          noMargin
                          variant="inverse-danger btn-sm"
                          icon={<FaTrash />}
                          onClick={() => handleDelete(_id)}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      </LoadingOverlay>
    </>
  );
}

SearchInvoicesConfiguration.defaultProps = {
  isLoading: false,
};

SearchInvoicesConfiguration.propTypes = {
  automationSettings: PropTypes.array,
  companies: PropTypes.array,
  onFetchAutomationSettings: PropTypes.func.isRequired,
  onAddAutomationSettings: PropTypes.func.isRequired,
  onDeleteAutomationSettings: PropTypes.func.isRequired,
  onUpdateAutomationSettings: PropTypes.func.isRequired,
  onFetchCompaniesForSelect: PropTypes.func.isRequired,
  isLoading: PropTypes.func,
  onExecuteAutomation: PropTypes.func.isRequired,
};

export default SearchInvoicesConfiguration;
