import { Form } from 'react-bootstrap';
import styled from 'styled-components';

export const TagsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 8px;
`;

export const StyledTag = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  span {
    cursor: pointer;
  }
`;

export const Checkbox = styled(Form.Check)`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-check-input {
    margin-top: 0;
    margin-left: 0;

    width: 15px !important;
    height: 15px !important;

    &:hover {
      cursor: pointer !important;
    }
  }
`;
