import axios from 'axios';
import FORMATTERS from 'helpers/formatters';

import constants from '../../_constants/ibge.constants';

const fetchCnae = (cnae, isPrimary, callback) => (dispatch) => {
  dispatch(({ type: constants.FETCH_CNAE_REQUEST }));

  const cleanCnae = FORMATTERS.CLEAN_CNAE(cnae);

  axios.get(`https://servicodados.ibge.gov.br/api/v2/cnae/subclasses/${cleanCnae}`)
    .then((response) => {
      const [cnae] = response.data || [];

      if (!cnae) {
        callback(null);
        dispatch({
          type: constants.FETCH_CNAE_SUCCESS,
          payload: {
            cnae: null,
          },
        });
      }

      const formattedCnae = {
        id: null,
        is_primary: isPrimary,
        description: cnae.descricao,
        cnae: cnae.id,
      };

      callback(formattedCnae);

      dispatch({
        type: constants.FETCH_CNAE_SUCCESS,
        payload: {
          cnae: formattedCnae,
        },
      });
    }).catch((error) => {
      dispatch(({
        type: constants.FETCH_CNAE_FAILURE,
        payload: error,
      }));
    });
};

export default fetchCnae;
