import { connect } from 'react-redux';

import taxesActionCreators from '_store/_actions/taxes';
import companyActionCreators from '_store/_actions/company';

import IssueDarfs from './IssueDarfs';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onFetchManagerDarfs: taxesActionCreators.fetchManagerDarfs,
  onFetchCompaniesForSelect: companyActionCreators.fetchCompaniesForSelect,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IssueDarfs);
