import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import {
  Card,
  CardHeader,
  CardBody,
  Table,
} from '_components/_core';

import { DEFAULT_COLUMNS, TABLE_COLUMNS } from './utilities';
import { CnaeForm } from './components';

function ActivitiesTab({
  errors,
  onFetchCnae,
  isLoadingCnae,
}) {
  const formik = useFormikContext();

  const data = useMemo(() => {
    const { cnaes = [] } = formik.values || {};

    return cnaes;
  }, [formik]);

  const handleCreate = useCallback((cnae) => {
    formik.setFieldValue('cnaes', [...data, cnae]);
  }, [formik, data]);

  const handleDelete = useCallback((cnae) => {
    const updatedCnaes = data.filter((item) => item.cnae !== cnae);

    formik.setFieldValue('cnaes', updatedCnaes);
  }, [formik, data]);

  return (
    <Card noBorders>
      <CardHeader
        title="Atividades"
        description="Preencha os CNAES utilizados pela empresa"
      />
      <CardBody>
        <CnaeForm
          isLoading={isLoadingCnae}
          onFetchCnae={onFetchCnae}
          onAddCnae={handleCreate}
          cnaes={data}
        />
        <Row>
          <Col sm={12}>
            {errors.cnaes && <small className="text-danger">{errors.cnaes}</small>}
            <Table
              key="cnae"
              defaultColumns={DEFAULT_COLUMNS}
              columns={TABLE_COLUMNS}
              data={data}
              onDelete={handleDelete}
              hover
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

ActivitiesTab.defaultProps = {
  isLoadingCnae: false,
  errors: {},
};

ActivitiesTab.propTypes = {
  errors: PropTypes.object,
  isLoadingCnae: PropTypes.bool,
  onFetchCnae: PropTypes.func.isRequired,
};

export default ActivitiesTab;
