import React from 'react';
import { IoArrowForward } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';

import { Button } from '_components/_core';

import {
  Container,
  Card,
  Title,
  Text,
  WarningIcon,
} from './styles';

const PastDueMessage = () => {
  const location = useLocation();

  const payment_url = new URLSearchParams(location.search).get('payment_url');

  const handleRedirectToPayment = () => {
    window.location.href = payment_url;
  };

  return (
    <Container>
      <Card>
        <WarningIcon />
        <Title>
          Sua assinatura está vencida
        </Title>
        <Text>
          Sua assinatura venceu após 3 tentativas de cobrança falharem. <br />
          Na página a seguir você pode atualizar sua forma de pagamento e reativar sua assinatura.
        </Text>
        <Button icon variant="success-2" className="mt-3" onClick={handleRedirectToPayment}>
          Atualizar forma de pagamento
          <IoArrowForward />
        </Button>
      </Card>
    </Container>
  );
};

export default PastDueMessage;
