import constants from '../../_constants/invoices.constants';
import service from '../../../services/invoices.service';

const syncInvoices = (params, callback) => {
  return (dispatch) => {
    dispatch(request());

    service.syncInvoices(params).then(
      (response) => {
        const { data } = response;

        dispatch(success(data));

        if (callback) {
          callback();
        }
      },
    ).catch((err) => {
      dispatch(failure(err));
    });
  };

  function request() {
    return {
      type: constants.SYNC_INVOICES_REQUEST,
      payload: { },
    };
  }
  function success(data) {
    return {
      type: constants.SYNC_INVOICES_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.SYNC_INVOICES_FAILURE,
      payload: error,
    };
  }
};

export default syncInvoices;
