import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { getQueryVariable } from 'helpers';
import {
  Button,
  FormTextField,
  FormPasswordField,
} from '_components/_core';
import { StyledForm, StyledButton } from '../../styles';

import { PasswordUpdateSchema } from './utilities';

function PasswordUpdateForm({ isLoading, history, onPasswordUpdate }) {
  const handlePasswordUpdate = useCallback(async (values) => {
    onPasswordUpdate(
      values.token,
      values.password,
      values.password_confirmation,
    );
  }, [onPasswordUpdate]);

  const ZenpaperPasswordUpdateForm = () => (
    <>
      <StyledForm>
        <Row>
          <Col>
            <Formik
              enableReinitialize
              initialValues={{
                token: getQueryVariable('token'),
                email: getQueryVariable('email'),
                password: '',
                password_confirmation: '',
              }}
              validationSchema={PasswordUpdateSchema}
              onSubmit={handlePasswordUpdate}
            >
              {({ handleSubmit, isValid }) => (
                <>
                  <Form.Row>
                    <Form.Group as={Col} lg="12">
                      <FormTextField name="email" placeholder="E-mail" disabled />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} lg="12">
                      <FormPasswordField name="password" autoComplete="new-password" placeholder="Nova senha" />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} lg="12">
                      <FormPasswordField name="password_confirmation" autoComplete="new-password" placeholder="Confirmar nova senha" />
                    </Form.Group>
                  </Form.Row>
                  <StyledButton
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isLoading || !isValid}
                    isLoading={isLoading}
                  >
                    Redefinir Senha
                  </StyledButton>
                  <div>
                    <Button className="ml-0 pl-0 pt-3" variant="link" onClick={() => history.push('/entrar')}>Voltar para login</Button>
                  </div>
                </>
              )}
            </Formik>
          </Col>
        </Row>
      </StyledForm>
    </>
  );

  const renderBody = useCallback(() => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    if (product === 'contabills-empresas') {
      return <ZenpaperPasswordUpdateForm />;
    }

    return (
      <Form>
        <Row>
          <Col>
            <Formik
              enableReinitialize
              initialValues={{
                token: getQueryVariable('token'),
                email: getQueryVariable('email'),
                password: '',
                password_confirmation: '',
              }}
              validationSchema={PasswordUpdateSchema}
              onSubmit={handlePasswordUpdate}
            >
              {({ handleSubmit, isValid }) => (
                <>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <FormTextField name="email" placeholder="E-mail" disabled />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <FormPasswordField name="password" autoComplete="new-password" placeholder="Nova senha" />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <FormPasswordField name="password_confirmation" autoComplete="new-password" placeholder="Confirmar nova senha" />
                    </Form.Group>
                  </Form.Row>
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isLoading || !isValid}
                    isLoading={isLoading}
                  >
                    Redefinir Senha
                  </Button>
                  <div>
                    <Button className="ml-0 pl-0 pt-3" variant="link" onClick={() => history.push('/entrar')}>Voltar para login</Button>
                  </div>
                </>
              )}
            </Formik>
          </Col>
        </Row>
      </Form>
    );
  }, [handlePasswordUpdate, history, isLoading]);

  return (
    <>
      {renderBody()}
    </>
  );
}

PasswordUpdateForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  onPasswordUpdate: PropTypes.func.isRequired,
};

export default withRouter(PasswordUpdateForm);
