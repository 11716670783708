import { connect } from 'react-redux';

import invoicesActionCreators from '_store/_actions/invoices';
import fiscalActionsCreators from '_store/_actions/fiscal';
import ibgeActionCreators from '_store/_actions/ibge';

import Create from './Create';

const mapStateToProps = (state) => ({
  invoiceGenerationParams: state.invoices.invoiceGenerationParams,
  isLoading: state.invoices.isLoading,
  isLoadingParams: state.invoices.isLoadingParams,
  activeCompany: state.company.activeCompany,
  issuedInvoices: state.invoices.issued_invoices,
  issuedInvoicesTotal: state.invoices.issued_invoices_total,
  isLoadingCities: state.ibge.isLoadingCities,
  isLoadingStates: state.ibge.isLoadingStates,
  isLoadingCep: state.ibge.isLoadingCep,
  cities: state.ibge.cities,
  states: state.ibge.states,
  persons: state.fiscal.persons,
  personsTotal: state.fiscal.total,
});

const mapDispatchToProps = {
  onFetchInvoiceGenerationParams: invoicesActionCreators.getInvoiceGenerationParams,
  onCreateIssuedInvoice: invoicesActionCreators.createIssuedInvoice,
  onUpdateIssuedInvoice: invoicesActionCreators.updateIssuedInvoice,
  onDeleteIssuedInvoices: invoicesActionCreators.deleteMultipleIssuedInvoices,
  onFetchIssuedInvoices: invoicesActionCreators.getIssuedInvoices,
  onFetchIssuedInvoiceById: invoicesActionCreators.getIssuedInvoiceById,
  onConfirmInvoicesIssuing: invoicesActionCreators.confirmInvoicesIssuing,
  onDeleteIssuedInvoice: invoicesActionCreators.deleteIssuedInvoice,
  onDuplicateIssuedInvoices: invoicesActionCreators.duplicateIssuedInvoices,
  onFetchPersons: fiscalActionsCreators.getAllPersons,
  onAddPerson: fiscalActionsCreators.addPerson,
  onUpdatePerson: fiscalActionsCreators.updatePerson,
  onDeletePerson: fiscalActionsCreators.deletePerson,
  onFetchStates: ibgeActionCreators.fetchStates,
  onFetchCities: ibgeActionCreators.fetchCities,
  onFetchCep: ibgeActionCreators.fetchCep,
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
