import getCreateEmployeeTabs from './getCreateEmployeeTabs';
import validationSchema from './validationSchema';

const employeeFormFields = {
  employee_type: '',
  employee_role: '',
  employee_journey: '',
  name: '',
  salary: '',
  date_admission: '',
  birthdate: '',
  document_type: 'CPF',
  document_number: '',
  rg_number: '',
  ctps_number: null,
  pis_pasep_number: null,
  mother_name: '',
  email: '',
  phone_number: '',
};

export {
  getCreateEmployeeTabs,
  employeeFormFields,
  validationSchema,
};
