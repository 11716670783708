import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;

  input {
    padding: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    font-size: 16px;
    margin-right: 14px;
    font-weight: 600;
    height: 40px;
  }
`;
