import FORMATTERS from 'helpers/formatters';

export const DEFAULT_COLUMNS = [
  'start_date',
  'max_value',
  'amount',
];

export const TABLE_COLUMNS = [
  {
    label: 'Vigência',
    keyName: 'start_date',
    align: 'left',
    width: 80,
    formatter: FORMATTERS.DATE_DDMMYYYY,
    order: 1,
  },
  {
    label: 'Limite',
    keyName: 'max_value',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 2,
  },
  {
    label: 'Valor',
    keyName: 'amount',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 3,
  },
];

export default TABLE_COLUMNS;
