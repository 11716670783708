import styled from 'styled-components';

export const StyledContainer = styled.div`
 
`;

export const ContainerScroller = styled.div``;

export const PageBodyWrapper = styled.div`
  height: 100%;
  display: flex;
`;
