import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';

const deleteCompanyAvatar = (company_id, callback) => {
  return async (dispatch) => {
    dispatch(request(company_id));
    service.deleteAvatar(company_id).then(
      (response) => {
        const { company_avatar_url } = response.data;

        dispatch(success(company_id, company_avatar_url));
        dispatch(alertActionCreators.success('Avatar excluído com sucesso!'));

        if (callback) {
          callback();
        }
      },
    ).catch(
      (err) => {
        dispatch(failure(err));
      },
    );
  };

  function request() {
    return {
      type: constants.DELETE_COMPANY_AVATAR_REQUEST,
      payload: { company_id },
    };
  }
  function success(company_id, company_avatar_url) {
    return {
      type: constants.DELETE_COMPANY_AVATAR_SUCCESS,
      payload: { company_id, company_avatar_url },
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_COMPANY_AVATAR_FAILURE,
      payload: { error },
    };
  }
};

export default deleteCompanyAvatar;
