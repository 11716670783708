import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/entries.constants';
import service from '../../../services/entry.service';
import alertActionCreators from '../alert.actions';

const deleteManyEntries = (params, callback) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Exclusão de Lançamentos',
      message: 'Tem certeza que deseja excluir estes lançamentos? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, deletar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(params));

        service.removeMany(params).then(() => {
          dispatch(success(params));
          dispatch(alertActionCreators.success('Lançamentos excluídos com sucesso.'));

          if (callback) {
            callback();
          }
        }).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request(ids) {
    return {
      type: constants.ENTRY_DELETE_MANY_REQUEST,
      payload: { ids },
    };
  }
  function success(ids) {
    return {
      type: constants.ENTRY_DELETE_MANY_SUCCESS,
      payload: {
        entry_ids: ids,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.ENTRY_DELETE_MANY_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default deleteManyEntries;
