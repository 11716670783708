import { SPECIAL_COLUMNS } from '_components/_core/Table';
import FORMATTERS from 'helpers/formatters';

export const DEFAULT_COLUMNS = [
  'document_number',
  'company_name',
  'trading_name',
  'municipal_registration',
  'date_founded',
  'tax_regime',
  'certificate_expire_date',
  'certificate_url',
  'id',
];

export const TABLE_COLUMNS = [
  {
    label: 'Ações',
    keyName: 'id',
    align: 'center',
    width: 15,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'CNPJ',
    keyName: 'document_number',
    sortingKey: 'document_number',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.CPF_CNPJ,
    order: 2,
  },
  {
    label: 'Razão Social',
    keyName: 'company_name',
    sortingKey: 'company_name',
    width: 30,
    formatter: FORMATTERS.UPPERCASE,
    specialType: SPECIAL_COLUMNS.REDIRECT,
    redirectPath: '/empresas/[key]/detalhes',
    redirectKey: 'id',
    order: 3,
  },
  {
    label: 'Nome Fantasia',
    keyName: 'trading_name',
    sortingKey: 'trading_name',
    width: 30,
    formatter: FORMATTERS.UPPERCASE,
    order: 4,
  },
  {
    label: 'Tributação',
    keyName: 'tax_regime',
    sortingKey: 'tax_regime',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.TAX_REGIME,
    order: 5,
  },
  {
    label: 'Vencimento do Certificado',
    keyName: 'certificate_expire_date',
    sortingKey: 'certificate_expire_date',
    align: 'center',
    width: 5,
    formatter: FORMATTERS.DATE_DDMMYYYY,
    order: 6,
  },
  {
    label: 'Download do Certificado',
    keyName: 'certificate_url',
    sortingKey: 'certificate_url',
    align: 'center',
    formatter: FORMATTERS.DOWNLOAD_LINK,
    width: 10,
    order: 7,
  },
  {
    label: 'Data de Abertura',
    keyName: 'date_founded',
    sortingKey: 'date_founded',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.DATE_DDMMYYYY,
    order: 8,
  },
  {
    label: 'Telefone',
    keyName: 'phone_number',
    sortingKey: 'phone_number',
    align: 'center',
    width: 10,
    order: 9,
  },
  {
    label: 'E-mail',
    keyName: 'email',
    sortingKey: 'email',
    align: 'center',
    width: 10,
    order: 10,
  },
  {
    label: 'Cliente desde',
    keyName: 'client_since',
    sortingKey: 'client_since',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.DATE_DDMMYYYY,
    order: 11,
  },
  {
    label: 'Inscrição Municipal',
    keyName: 'municipal_registration',
    sortingKey: 'municipal_registration',
    align: 'center',
    width: 10,
    order: 12,
  },
  {
    label: 'Status RFB',
    keyName: 'status_irs',
    sortingKey: 'status_irs',
    align: 'center',
    formatter: FORMATTERS.STATUS_IRS,
    width: 10,
    order: 13,
  },
  {
    label: 'CEP',
    keyName: 'address_zip_code',
    sortingKey: 'address_zip_code',
    align: 'center',
    formatter: FORMATTERS.CEP,
    width: 11,
    order: 14,
  },

  {
    label: 'Logradouro',
    keyName: 'address_street',
    sortingKey: 'address_street',
    formatter: FORMATTERS.UPPERCASE,
    width: 12,
    order: 15,
  },
  {
    label: 'Número',
    keyName: 'address_number',
    sortingKey: 'address_number',
    align: 'center',
    width: 13,
    order: 16,
  },
  {
    label: 'Bairro',
    keyName: 'address_district',
    sortingKey: 'address_district',
    formatter: FORMATTERS.UPPERCASE,
    width: 14,
    order: 17,
  },
  {
    label: 'Complemento',
    keyName: 'address_complement',
    sortingKey: 'address_complement',
    formatter: FORMATTERS.UPPERCASE,
    width: 30,
    order: 18,
  },
  {
    label: 'Cidade',
    keyName: 'address_city',
    sortingKey: 'address_city',
    formatter: FORMATTERS.UPPERCASE,
    align: 'center',
    width: 15,
    order: 19,
  },
  {
    label: 'Estado',
    keyName: 'address_state',
    sortingKey: 'address_state',
    formatter: FORMATTERS.UPPERCASE,
    align: 'center',
    width: 16,
    order: 20,
  },
];

const FILTERS_CONFIGURATION = {
  document_number: {
    label: 'Número do Documento',
    formatter: FORMATTERS.CPF_CNPJ,
  },
  company_name: {
    label: 'Razão Social contém',
    formatter: FORMATTERS.STRING_WITH_QUOTES,
  },
  trading_name: {
    label: 'Nome Fantasia contém',
    formatter: FORMATTERS.STRING_WITH_QUOTES,
  },
  date_founded_start: {
    label: 'Data de Abertura a partir de',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  date_founded_end: {
    label: 'Data de Abertura até',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  client_since_start: {
    label: 'Cliente a partir de',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  client_since_end: {
    label: 'Cliente até',
    formatter: FORMATTERS.DATE_MMYYYY_FROM_STRING_DATE,
  },
  status_irs: {
    label: 'Status RFB',
    formatter: FORMATTERS.STATUS_IRS_TEXT,
  },
  tax_regime: {
    label: 'Tributação',
    formatter: FORMATTERS.TAX_REGIME_TEXT,
  },
  phone_number: {
    label: 'Telefone contém',
  },
  email: {
    label: 'E-mail',
    formatter: FORMATTERS.LOWERCASE,
  },
};

export {
  FILTERS_CONFIGURATION,
};

export default TABLE_COLUMNS;
