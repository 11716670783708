import styled from 'styled-components';

import Button from '_components/_core/Button';

export const SearchButton = styled(Button)`
  margin-top: 26px;
`;

export const SectionHeader = styled.p`
  font-size: 14px;
  margin-bottom: 4px;
  color: rgba(70,77,228,1);
  font-weight: 600;
`;
