import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';

import FORMATTERS from 'helpers/formatters';
import confirmDialog from 'helpers/confirmDialog';
import { isFormValuesChanged, typeSubtypeStringToObject } from 'helpers';

import { FormTextField } from '_components/_core';
import { CategorySelect } from '_components/_shared';

import useCategories from '_store/_hooks/useCategories';

import CustomSelectField from '../../../CustomSelectField/CustomSelectField';
import { StyledRow } from './styles';

const useOutsideClick = (callback) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event) => {
      const isClickOnOption = event.target.id.includes('react-select');
      const isCreatableOption = event.target.id.includes('creatable-option');
      const isDetailButton = event.target.id.includes('details-button');
      const isModalOpen = document.querySelector('.modal.show');
      const isDatePicker = Array.from(event.target.classList).some((c) => c.includes('datepicker'));
      const isPreventClose = document.querySelector('.transaction-form-open');
      const isToggleButton = event.target.id.includes('toggle-payment-button');

      if (ref.current
        && !ref.current.contains(event.target)
        && !isClickOnOption
        && !isModalOpen
        && !isDatePicker
        && !isCreatableOption
        && !isDetailButton
        && !isPreventClose
        && !isToggleButton) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref, callback]);

  return ref;
};

function RowForm({
  transaction,
  transactionFormRef,
  onSubmit,
  accounts,
  recipients,
  selectedFieldName,
  onCancelEditTransaction,
  index,
  total,
  bank_statement_account_id,
  onUpdateTransactionsTransferDetails,
  onReconcileTransactions,
}) {
  const eventDateRef = useRef();
  const descriptionRef = useRef();
  const categoryRef = useRef();
  const typeRef = useRef();
  const recipientRef = useRef();
  const amountRef = useRef();
  const originAccountRef = useRef();
  const destinationAccountRef = useRef();

  const {
    onLoadSuggestions: onLoadCategoriesSuggestions,
  } = useCategories({});

  const handleKeyPress = useCallback((e, handleSubmit) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }, []);

  const handleClickOutside = useCallback(() => {
    if (transactionFormRef.current) {
      transactionFormRef.current.handleSubmit();
    }

    onCancelEditTransaction(transaction.id);
  }, [onCancelEditTransaction, transactionFormRef, transaction]);

  const rowRef = useOutsideClick(handleClickOutside);

  const typeSubtypeOptions = useMemo(() => {
    const { bank_statement_transaction_type } = transaction || {};

    if (bank_statement_transaction_type === 'EXPENSE') {
      return [
        {
          label: 'Despesa Fixa',
          value: 'EXPENSE::FIXED_EXPENSE',
        },
        {
          label: 'Despesa Variável',
          value: 'EXPENSE::VARIABLE_EXPENSE',
        },
        {
          label: 'Despesa com Pessoas',
          value: 'EXPENSE::PEOPLE',
        },
        {
          label: 'Despesa com Impostos',
          value: 'EXPENSE::TAXES',
        },
        {
          label: 'Transferência Enviada',
          value: 'TRANSFER::SENT',
        },
      ];
    }

    if (bank_statement_transaction_type === 'INCOME') {
      return [
        {
          label: 'Receita',
          value: 'INCOME::',
        },
        {
          label: 'Transferência Recebida',
          value: 'TRANSFER::RECEIVED',
        },
      ];
    }

    return [];
  }, [transaction]);

  const recipientsOptions = useMemo(() => {
    const formatted = recipients.map((recipient) => ({
      value: recipient.id,
      label: recipient.name,
    }));

    return formatted;
  }, [recipients]);

  const accountsOptions = useMemo(() => {
    const formatted = accounts.map((account) => ({
      value: account.id,
      label: account.description,
    }));

    return formatted;
  }, [accounts]);

  useEffect(() => {
    if (selectedFieldName === 'event_date') {
      if (eventDateRef.current) {
        // eventDateRef.current.setFocus(true);
        eventDateRef.current.setOpen(true);
      }
    }

    if (selectedFieldName === 'description') {
      if (descriptionRef.current) {
        descriptionRef.current.focus();
        descriptionRef.current.select();
      }
    }

    if (selectedFieldName === 'amount') {
      if (amountRef.current) {
        amountRef.current.theInput.focus();
      }
    }

    if (selectedFieldName === 'type' || selectedFieldName === 'temp_type') {
      if (typeRef.current) {
        typeRef.current.onMenuOpen();
        typeRef.current.focus();
      }
    }

    if (selectedFieldName === 'category') {
      if (categoryRef.current) {
        categoryRef.current.onMenuOpen();
        categoryRef.current.focus();
      }

      if (originAccountRef.current) {
        originAccountRef.current.onMenuOpen();
        originAccountRef.current.focus();
      }
    }

    if (selectedFieldName === 'recipient') {
      if (recipientRef.current) {
        recipientRef.current.onMenuOpen();
        recipientRef.current.focus();
      }

      if (destinationAccountRef.current) {
        destinationAccountRef.current.onMenuOpen();
        destinationAccountRef.current.focus();
      }
    }
  }, [selectedFieldName, descriptionRef, eventDateRef, amountRef]);

  const getInitialTypeSubType = useCallback(() => {
    if (!transaction) {
      return null;
    }

    const { bank_statement_transaction_type } = transaction || {};

    if (transaction.temp_type === 'INCOME') {
      return 'INCOME::';
    }

    if (transaction.temp_type === 'TRANSFER') {
      if (bank_statement_transaction_type === 'INCOME') {
        return 'TRANSFER::RECEIVED';
      }

      return 'TRANSFER::SENT';
    }

    if (transaction.temp_type !== null) {
      return `${transaction.temp_type}::${transaction.temp_sub_type}`;
    }

    return null;
  }, [transaction]);

  const initialValues = useMemo(() => {
    let bank_account_id = null;

    const type_sub_type = getInitialTypeSubType();

    if (['TRANSFER::SENT', 'TRANSFER::RECEIVED'].includes(type_sub_type)) {
      if (transaction.bank_statement_type === 'INCOME') {
        bank_account_id = transaction.transfer_details.account_id_destination;
      } else {
        bank_account_id = transaction.transfer_details.account_id_origin;
      }
    }

    return {
      id: transaction.id,
      description: transaction.description,
      event_date: transaction.event_date,
      amount: transaction.amount,
      recipient_id: transaction.recipient_id,
      category_id: transaction.category_id,
      type_sub_type,
      bank_account_id,
    };
  }, [transaction, getInitialTypeSubType]);

  const handleSubmitTransaction = useCallback((values) => {
    if (values.type_sub_type) {
      const { type, sub_type } = typeSubtypeStringToObject(values.type_sub_type);

      values.type = type;
      values.sub_type = sub_type;
      values.temp_type = type;
      values.temp_sub_type = sub_type;
    }

    const payload = omitBy(values, isEmpty);

    if (['TRANSFER::RECEIVED', 'TRANSFER::SENT'].includes(values.type_sub_type)) {
      if (!values.bank_account_id) {
        confirmDialog.warning({
          title: 'Erro',
          message: 'Para salvar Transferências, você precisa selecionar a conta bancária.',
        });

        return;
      }

      if (!isFormValuesChanged(values, initialValues)) {
        return;
      }

      delete values.type_sub_type;

      onUpdateTransactionsTransferDetails({
        ids: [values.id],
        bank_statement_account_id,
        ...payload,
      }, () => {
        onReconcileTransactions();
      });

      return;
    }

    delete payload.type_sub_type;
    delete payload.bank_account_id;

    if (!isFormValuesChanged(values, initialValues)) {
      return;
    }

    onSubmit(payload);
  },
  [
    onSubmit,
    initialValues,
    bank_statement_account_id,
    onReconcileTransactions,
    onUpdateTransactionsTransferDetails,
  ]);

  const renderTransferOriginAccountField = useCallback((values, setFieldValue) => {
    if (values.type_sub_type === 'TRANSFER::SENT') {
      const account = accountsOptions.find(
        (option) => option.value === bank_statement_account_id,
      ) || {};

      return (
        <Form.Control value={`Origem: ${account.label}`} disabled />
      );
    }

    if (values.type_sub_type === 'TRANSFER::RECEIVED') {
      const availableAccounts = accountsOptions.filter(
        (option) => option.value !== bank_statement_account_id,
      );

      return (
        <CustomSelectField
          isClearable
          options={availableAccounts}
          innerRef={originAccountRef}
          name="bank_account_id"
          placeholder="Definir conta de origem"
          creatable="bank_account"
          loadingMessage={() => 'Carregando...'}
          onCreateCallback={(created) => {
            if (created) {
              setFieldValue('bank_account_id', created.id);
            }
          }}
        />
      );
    }

    return null;
  }, [accountsOptions, bank_statement_account_id]);

  const renderTransferDestinationAccountField = useCallback((values, setFieldValue) => {
    if (values.type_sub_type === 'TRANSFER::RECEIVED') {
      const account = accountsOptions.find(
        (option) => option.value === bank_statement_account_id,
      ) || {};

      return (
        <Form.Control value={`Destino: ${account.label}`} disabled />
      );
    }

    if (values.type_sub_type === 'TRANSFER::SENT') {
      const availableAccounts = accountsOptions.filter(
        (option) => option.value !== bank_statement_account_id,
      );

      return (
        <CustomSelectField
          isClearable
          options={availableAccounts}
          innerRef={destinationAccountRef}
          name="bank_account_id"
          placeholder="Definir conta de destino"
          creatable="bank_account"
          loadingMessage={() => 'Carregando...'}
          onCreateCallback={(created) => {
            if (created) {
              setFieldValue('bank_account_id', created.id);
            }
          }}
        />
      );
    }

    return null;
  }, [accountsOptions, bank_statement_account_id]);

  return (
    <>
      <Formik
        innerRef={transactionFormRef}
        initialValues={initialValues}
        onSubmit={handleSubmitTransaction}
        enableReinitialize
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <>
            <StyledRow
              ref={rowRef}
              className="transaction-form-row"
              onKeyPress={(e) => handleKeyPress(e, handleSubmit)}
            >
              <td>
                &nbsp;
              </td>
              <td className="text-center">
                {FORMATTERS.BANK_STATEMENT_TRANSACTION_STATUS(transaction.status)}
              </td>
              <td>
                <input
                  type="text"
                  disabled
                  className="form-control"
                  value={FORMATTERS.DATE_DDMMYYYY(transaction.event_date)}
                />
              </td>
              <td>
                <FormTextField
                  innerRef={descriptionRef}
                  name="description"
                  placeholder="Descrição"
                  autoComplete="off"
                />
              </td>
              <td>
                <CustomSelectField
                  innerRef={typeRef}
                  name="type_sub_type"
                  placeholder="Tipo"
                  options={typeSubtypeOptions}
                  menuPlacement={(total - (index + 1)) <= 8 ? 'top' : 'bottom'}
                  menuWidth="150%"
                />
              </td>
              <td>
                {FORMATTERS.BANK_STATEMENT_TRANSACTION_AMOUNT(
                  transaction.amount, transaction.bank_statement_transaction_type,
                )}
              </td>
              <td>
                {!['TRANSFER::SENT', 'TRANSFER::RECEIVED'].includes(values.type_sub_type) && (
                  <CustomSelectField
                    isClearable
                    name="recipient_id"
                    options={recipientsOptions}
                    innerRef={recipientRef}
                    placeholder="Contato"
                    creatable="recipient"
                    loadingMessage={() => 'Carregando...'}
                    menuPlacement={(total - (index + 1)) <= 8 ? 'top' : 'bottom'}
                    onCreateCallback={(created_recipient) => {
                      if (created_recipient) {
                        setFieldValue('recipient_id', created_recipient.id);
                      }
                    }}
                    menuWidth="120%"
                    onChange={(option) => {
                      setFieldValue('recipient_id', option ? option.value : null);

                      if (!option) {
                        return;
                      }

                      const {
                        type,
                        sub_type,
                      } = typeSubtypeStringToObject(values.type_sub_type) || {};

                      const params = {
                        suggestion_for: 'category_id',
                        recipient_id: option.value,
                        type,
                        sub_type,
                      };

                      onLoadCategoriesSuggestions(params, (found) => {
                        const [first] = found || [];

                        if (first && !values.category_id) {
                          setFieldValue('category_id', first.value);
                        }
                      });
                    }}
                  />
                )}
                {renderTransferOriginAccountField(values, setFieldValue)}
              </td>
              <td>
                {!['TRANSFER::SENT', 'TRANSFER::RECEIVED'].includes(values.type_sub_type) && (
                  <CategorySelect
                    innerRef={categoryRef}
                    name="category_id"
                    onChange={(value) => {
                      setFieldValue('category_id', value);
                    }}
                    placeholder="Categoria"
                    value={values.category_id}
                    menuPlacement={(total - (index + 1)) <= 8 ? 'top' : 'bottom'}
                    width="150%"
                  />
                )}
                {renderTransferDestinationAccountField(values, setFieldValue)}
              </td>
            </StyledRow>
          </>
        )}
      </Formik>
    </>
  );
}

RowForm.defaultProps = {
  transaction: {},
  recipients: [],
  accounts: [],
  selectedFieldName: null,
  bank_statement_account_id: null,
};

RowForm.propTypes = {
  transaction: PropTypes.object,
  recipients: PropTypes.array,
  accounts: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  onCancelEditTransaction: PropTypes.func.isRequired,
  transactionFormRef: PropTypes.object.isRequired,
  selectedFieldName: PropTypes.string,
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  bank_statement_account_id: PropTypes.string,
  onUpdateTransactionsTransferDetails: PropTypes.func.isRequired,
  onReconcileTransactions: PropTypes.func.isRequired,
};

export default RowForm;
