import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';

import FORMATTERS from 'helpers/formatters';

import ExpenseIncomeList from './ExpenseIncomeList';
import { StyledSection, PerformanceCard } from '../styles';

function OverviewSection({ data }) {
  const incomePerformance = useMemo(() => {
    if (!data) {
      return {};
    }

    if (!data.comparison) {
      return {};
    }

    const { DIFFERENCE } = data.comparison;

    const current = DIFFERENCE.INCOME;

    let label = null;
    let className = null;
    let color = null;

    if (current === 0) {
      label = 'não alteraram';
      className = 'text-muted';
      color = '#6c757d';
    } else {
      label = current > 0 ? 'aumentaram' : 'reduziram';
      className = current > 0 ? 'text-success' : 'text-yellow';
      color = current > 0 ? '#00ab6f' : '#DB504A';
    }

    return {
      amount: current < 0 ? current * -1 : current,
      label,
      className,
      color,
      icon: current > 0 ? <FaCheckCircle size="1.5em" className="mr-2 text-success" /> : <FaExclamationTriangle size="1.5em" className="mr-2 text-yellow" />,
    };
  }, [data]);

  const expensePerformance = useMemo(() => {
    if (!data) {
      return {};
    }

    if (!data.comparison) {
      return {};
    }

    const { DIFFERENCE } = data.comparison;

    const current = DIFFERENCE.EXPENSE;

    let label = null;
    let className = null;
    let color = null;

    if (current === 0) {
      label = 'não alteraram';
      className = 'text-muted';
      color = '#6c757d';
    } else {
      label = current > 0 ? 'aumentaram' : 'reduziram';
      className = current > 0 ? 'text-yellow' : 'text-success';
      color = current > 0 ? '#DB504A' : '#00ab6f';
    }

    return {
      amount: current < 0 ? current * -1 : current,
      label,
      className,
      color,
      icon: current > 0 ? <FaExclamationTriangle size="1.5em" className="mr-2 text-yellow" /> : <FaCheckCircle size="1.5em" className="mr-2 text-success" />,
    };
  }, [data]);

  const expenses = useMemo(() => {
    if (!data) {
      return [];
    }

    if (!data.expenses_by_description) {
      return [];
    }

    const { result } = data.expenses_by_description;

    // return first 3 items

    const items = result.slice(0, 3);

    return items;
  }, [data]);

  const incomes = useMemo(() => {
    if (!data) {
      return [];
    }

    if (!data.incomes_by_description) {
      return [];
    }

    const { result } = data.incomes_by_description;

    // return first 3 items

    const items = result.slice(0, 3);

    return items;
  }, [data]);

  return (
    <StyledSection>
      <Row className="h-100 w-100">
        <Col xs={12} sm={6} xl={5}>
          <PerformanceCard borderColor={expensePerformance.color}>
            <p>
              Os <strong>gastos</strong> {expensePerformance.label} no período:
            </p>
            <h4>
              {FORMATTERS.NUMBER(expensePerformance.amount)}
            </h4>
          </PerformanceCard>
          <PerformanceCard className="mt-3" borderColor={incomePerformance.color}>
            <p>
              as <strong>Receitas</strong> {incomePerformance.label}:
            </p>
            <h4>
              {FORMATTERS.NUMBER(incomePerformance.amount)}
            </h4>
          </PerformanceCard>
        </Col>
        <Col xs={12} sm={6} xl={7} className="d-flex justify-content-center">
          <ExpenseIncomeList
            label="Maiores gastos"
            items={expenses}
            className="text-danger"
          />
          <ExpenseIncomeList
            label="Maiores receitas"
            items={incomes}
            containerClassName="ml-5"
            className="text-success"
          />
        </Col>
      </Row>
    </StyledSection>
  );
}

OverviewSection.defaultProps = {
  data: {},
};

OverviewSection.propTypes = {
  data: PropTypes.object,
};

export default OverviewSection;
