import constants from '../../_constants/employees.constants';
import service from '../../../services/employees.service';
import alertActionCreators from '../alert.actions';

const addEmployee = (employee, callback) => {
  return (dispatch) => {
    dispatch(request(employee));

    const payload = {
      ...employee,
    };

    service.insert(payload).then(
      (response) => {
        const { employee: createdEmployee } = response.data;

        dispatch(success(createdEmployee));
        dispatch(alertActionCreators.success('Cadastrado com sucesso!'));

        if (callback) {
          callback();
        }
      },
    ).catch(({ response }) => {
      if (response.status === 422) {
        const { message } = response.data;

        dispatch(failure(message));
        dispatch(alertActionCreators.error(message));
      }
    });
  };

  function request(employee) {
    return {
      type: constants.EMPLOYEE_ADD_REQUEST,
      payload: { employee },
    };
  }
  function success(employee) {
    return {
      type: constants.EMPLOYEE_ADD_SUCCESS,
      payload: { employee },
    };
  }
  function failure(message) {
    return {
      type: constants.EMPLOYEE_ADD_FAILURE,
      payload: { message },
    };
  }
};

export default addEmployee;
