import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Tab from 'react-bootstrap/Tab';
import { IoPrintOutline } from 'react-icons/io5';
import ReactToPrint from 'react-to-print';

import FORMATTERS from 'helpers/formatters';
import {
  Report,
  ReportPage,
  ReportHeader,
  ReportSignature,
} from '_components/_shared';

import { DEFAULT_COLUMNS, TABLE_COLUMNS, groupByYear } from './utilities';
import { StyledTable } from './styles';

function Results({
  values,
  activeCompany,
  revenueReport,
}) {
  const reportRef = useRef();

  const { company_name, document_number } = activeCompany || {};
  const { all } = revenueReport || {};

  const groupedByYear = useMemo(() => {
    if (!all) {
      return {};
    }

    return groupByYear(all);
  }, [all]);

  if (!revenueReport) {
    return null;
  }

  return (
    <Tab.Content className="mt-3 mt-lg-0">
      <div className="d-flex align-items-end">
        <ReactToPrint
          trigger={() => (
            <button
              type="button"
              placement="left"
              className="btn btn-sm btn-icons btn-secondary mr-2 mb-3"
            >
              <IoPrintOutline />
            </button>
          )}
          content={() => reportRef.current}
          documentTitle="Relatório de Faturamento"
        />
      </div>

      <Report ref={reportRef}>
        <div>
          {Object.keys(groupedByYear).map((key, index) => (
            <ReportPage>
              <ReportHeader
                key={key}
                name="RELATÓRIO DE FATURAMENTO"
                companyName={company_name}
                companyDocumentNumber={FORMATTERS.CPF_CNPJ(document_number)}
                startDate={values.start_date}
                endDate={values.end_date}
                currentPage={index + 1}
                totalPages={groupedByYear ? Object.keys(groupedByYear).length : 0}
              />
              <StyledTable
                keyName="id"
                data={groupedByYear[key]}
                columns={TABLE_COLUMNS}
                defaultColumns={DEFAULT_COLUMNS}
                showFooter
              />
              <ReportSignature viewType="signature_field" />
            </ReportPage>
          ))}
        </div>
      </Report>
    </Tab.Content>
  );
}

Results.defaultProps = {
  revenueReport: null,
};

Results.propTypes = {
  values: PropTypes.object,
  activeCompany: PropTypes.object,
  revenueReport: PropTypes.object,
};

export default Results;
