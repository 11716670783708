import { DateTime } from 'luxon';
import isNull from 'lodash/isNull';

const checkBlockedPeriod = (blocked_period, event_date, paid = null) => {
  if (!blocked_period) {
    return true;
  }

  if (paid !== null && paid === false) {
    return true;
  }

  const { active_period_start, active_period_end } = blocked_period || {};

  if (isNull(active_period_start) || isNull(active_period_end)) {
    return true;
  }

  let startLuxon = DateTime.fromISO(active_period_start, { zone: 'utc' });
  let endLuxon = DateTime.fromISO(active_period_end, { zone: 'utc' });
  let eventLuxon = DateTime.fromISO(event_date, { zone: 'utc' });

  if (eventLuxon >= startLuxon && eventLuxon <= endLuxon) {
    return false;
  }

  return true;
};

export default checkBlockedPeriod;
