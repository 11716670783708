import FORMATTERS from 'helpers/formatters';

export const DEFAULT_COLUMNS = ['cnae', 'is_primary', 'description'];

export const TABLE_COLUMNS = [
  {
    label: 'CNAE',
    keyName: 'cnae',
    align: 'center',
    formatter: FORMATTERS.CNAE,
    width: 15,
  },
  {
    label: 'Tipo',
    keyName: 'is_primary',
    align: 'center',
    formatter: FORMATTERS.CNAE_TYPE,
    width: 15,
  },
  {
    label: 'Descrição',
    keyName: 'description',
    align: 'left',
    formatter: FORMATTERS.UPPERCASE,
    width: 70,
  },
];

export const TAX_REGIME_DEFAULT_COLUMNS = ['id', 'start_date', 'tax_regime', 'metadata'];

export const TAX_REGIME_TABLE_COLUMNS = [
  {
    label: 'Vigência',
    keyName: 'start_date',
    align: 'center',
    width: 15,
    formatter: FORMATTERS.DATE_MMYYYY,
  },
  {
    label: 'Regime de Tributação',
    keyName: 'tax_regime',
    align: 'center',
    width: 15,
    formatter: FORMATTERS.TAX_REGIME,
  },
  {
    label: 'Anexo/Alíquota/Tipo',
    keyName: 'metadata',
    align: 'left',
    width: 70,
    formatter: FORMATTERS.TAX_REGIME_METADATA,
  },
];

const FACTOR_R_OPTIONS = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
];

export {
  FACTOR_R_OPTIONS,
};
