import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import companyActionCreators from '_store/_actions/company';
import companySelectors from '_store/_selectors/company';

import MonthSchedule from './MonthSchedule';

const mapStateToProps = state => ({
  transactions: state.companyTransactions.transactions,
  expiredTransactions: state.companyTransactions.expiredTransactions,
  selectedAccountIds: state.companyTransactions.selected_account_ids,
  activeCompany: companySelectors.activeCompanySelector(state),
  accounts: state.companyTransactions.accounts,
  blockedPeriod: state.company.blockedPeriod,
});

const mapDispatchToProps = {
  onToggleTransactionPaid: companyTransactionsActionCreators.toggleTransactionPaid,
  onFetchCashflow: companyTransactionsActionCreators.fetchCashflow,
  onFetchBankAccountBalance: companyTransactionsActionCreators.fetchBankAccountBalance,
  onUpdateMultipleTransactions:
    companyTransactionsActionCreators.updateMultipleTransactions,
  onFetchExpiredTransactions:
    companyTransactionsActionCreators.fetchAllExpiredTransactions,
  onFetchTransactions: companyTransactionsActionCreators.fetchAllTransactions,
  onFetchBlockedPeriod: companyActionCreators.getBlockedPeriod,
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthSchedule);
