import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { IoCheckmark } from 'react-icons/io5';

import { Button } from '_components/_core';

const ImportEntries = ({
  isLoading,
  isLoadingEntries,
  selectedItems,
  entries,
  onSubmitEntries,
  onComplete,
}) => {
  const hasInvalidEntry = useMemo(() => {
    const affectedEntries = entries.filter((entry) => selectedItems.includes(entry.id));

    return affectedEntries.some((e) => !e.debit_account_id || !e.credit_account_id || !e.narration);
  }, [entries, selectedItems]);

  const handleImportEntries = useCallback(() => {
    const affectedEntries = entries.filter((entry) => selectedItems.includes(entry.id));

    onSubmitEntries({
      entries: affectedEntries,
    }, () => {
      onComplete({
        clearSelectedItems: true,
      });
    });
  }, [selectedItems, entries, onComplete, onSubmitEntries]);

  return (
    <Button
      className="mr-2 btn-sm"
      variant="primary"
      onClick={handleImportEntries}
      isLoading={isLoading || isLoadingEntries}
      disabled={isLoading || isLoadingEntries || hasInvalidEntry}
      icon={<IoCheckmark />}
    >
      {`Importar ${selectedItems.length} ${pluralize('lançamento', selectedItems.length)}`}
    </Button>
  );
};

export default ImportEntries;

ImportEntries.defaultProps = {
  isLoading: false,
  isLoadingEntries: false,
  onComplete: null,
  entries: [],
};

ImportEntries.propTypes = {
  entries: PropTypes.array,
  isLoading: PropTypes.bool,
  isLoadingEntries: PropTypes.bool,
  selectedItems: PropTypes.array.isRequired,
  onComplete: PropTypes.func,
  onSubmitEntries: PropTypes.func.isRequired,
};
