import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { EmptyIcon } from '_components/_shared';

import { isEmpty } from 'lodash';
import { Container } from './styles';

function CustomEmptyMessage({ type, textFilter }) {
  const title = useMemo(() => {
    if (!isEmpty(textFilter)) {
      return `Nenhum resultado para a busca "${textFilter}"`;
    }

    if (type === 'INCOME') {
      return 'Nenhum recebimento encontrado';
    }

    if (type === 'EXPENSE') {
      return 'Nenhuma despesa encontrada';
    }

    if (type === 'TRANSFER') {
      return 'Nenhuma transferência encontrada';
    }

    return '';
  }, [type, textFilter]);

  const description = useMemo(() => {
    if (!isEmpty(textFilter)) {
      return 'Nenhum resultado encontrado, verifique os filtros e tente novamente.';
    }

    if (type === 'INCOME') {
      return 'Clique no botão "Adicionar recebimento" para começar.';
    }

    if (type === 'EXPENSE') {
      return 'Clique no botão "Adicionar despesa" para começar.';
    }

    if (type === 'TRANSFER') {
      return 'Clique no botão "Adicionar transferência" para começar.';
    }

    return '';
  }, [type, textFilter]);

  return (
    <Container id="empty-message-container" className="text-center pt-5 pb-5">
      <EmptyIcon size="large" />
      <p className="display-5 mx-auto">
        {title}
        <br />
        {description && description.split('<br />').map((item, index) => (
          <small
            className={classNames('text-muted d-block w-50 ml-auto mr-auto font-weight-light', index === 0 ? 'mt-2' : '')}
          >
            {item}
          </small>
        ))}
      </p>
    </Container>
  );
}

CustomEmptyMessage.defaultProps = {
  textFilter: null,
};

CustomEmptyMessage.propTypes = {
  type: PropTypes.string,
  textFilter: PropTypes.string,
};

export default CustomEmptyMessage;
