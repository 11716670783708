import constants from '../../../_constants/company-transactions.constants';
import service from '../../../../services/company-transactions.service';
import alertActions from '../../alert.actions';

const updateMultipleContacts = (params, callback) => {
  return async (dispatch) => {
    dispatch(request(params));

    service.updateMultipleContacts(params).then((response) => {
      dispatch(success(response.data));

      dispatch(alertActions.success('Contatos atualizados com sucesso!'));

      if (callback) {
        callback();
      }
    }).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(params) {
    return {
      type: constants.UPDATE_MULTIPLE_CONTACTS_REQUEST,
      payload: {
        params,
      },
    };
  }
  function success(contacts) {
    return {
      type: constants.UPDATE_MULTIPLE_CONTACTS_SUCCESS,
      payload: {
        contacts,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.UPDATE_MULTIPLE_CONTACTS_FAILURE,
      payload: error,
    };
  }
};

export default updateMultipleContacts;
