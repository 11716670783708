import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/invoices.constants';
import service from '../../../services/invoices.service';
import alertActionCreators from '../alert.actions';

const deleteMultipleIssuedInvoices = (params, callback) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Exclusão de Notas Fiscais',
      message: 'Tem certeza que deseja excluir estas notas fiscais? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, deletar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(params));

        service.deleteMultipleIssuedInvoices(params).then(() => {
          dispatch(success(params));
          dispatch(alertActionCreators.success('Notas Fiscais excluídas com sucesso.'));

          if (callback) {
            callback();
          }
        }).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request(ids) {
    return {
      type: constants.DELETE_MULTIPLE_ISSUED_INVOICES_REQUEST,
      payload: { ids },
    };
  }
  function success(ids) {
    return {
      type: constants.DELETE_MULTIPLE_ISSUED_INVOICES_SUCCESS,
      payload: {
        invoice_ids: ids,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_MULTIPLE_ISSUED_INVOICES_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default deleteMultipleIssuedInvoices;
