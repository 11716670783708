import { connect } from 'react-redux';

import employeeActionCreators from '_store/_actions/employees';

import Details from './Details';

const mapStateToProps = (state) => ({
  isLoading: state.employees.isLoading,
});

const mapDispatchToProps = {
  onFetchEmployee: employeeActionCreators.getEmployeeById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Details);
