import styled from 'styled-components';
import { Table } from '_components/_core';

export const AccountName = styled.p`
  font-weight: bold;
`;

export const StyledTable = styled(Table)`
  &&& {

    thead tr th {
      padding: 10px 5px !important;
    }

    tbody tr td {
      padding: 5px 5px !important;
      font-size: 11px !important;
    }

    tbody tr td.text-bold {
      font-weight: bold;
    }

    tbody tr td.account-level-1 {
      & {        
        button {
          text-decoration: none !important;
          color: inherit !important;
          cursor: default !important;
          pointer-events: none;
          font-size: 11px !important;
        }
      }
    }

    tbody tr td.account-level-2 {
      padding-left: 16px  !important;

      & { 
        button {
          text-decoration: none !important;
          color: inherit !important;
          cursor: default !important;
          pointer-events: none;
          font-size: 11px !important;
        }
      }
    }

    tbody tr td.account-level-3 {
      padding-left: 32px  !important;
      
      & { 
        button {
          text-decoration: none !important;
          color: inherit !important;
          cursor: default !important;
          pointer-events: none;
          font-size: 11px !important;
        }
      }
    }

    tbody tr td.account-level-4 {
      padding-left: 42px  !important;

      & { 
        button {
          font-size: 11px !important;
        }
      }
    }

    tbody tr td.spacing-top {
      /* padding-top: 16px !important; */
    }

    thead tr th:nth-child(1),
    tbody tr td:nth-child(1),
    tfoot tr td:nth-child(1) {
      width: 10% !important;
    }

    thead tr th:nth-child(3),
    tbody tr td:nth-child(3),
    tfoot tr td:nth-child(3) {
      width: 10% !important;
    }

    thead tr th:nth-child(2),
    tbody tr td:nth-child(2),
    tfoot tr td:nth-child(2) {
      width: 40% !important;
      white-space: pre-wrap;
    }

    tbody tr td:nth-child(2) {
      line-height: 15px;
    }

    thead tr th:nth-child(4),
    tbody tr td:nth-child(4),
    tfoot tr td:nth-child(4) {
      width: 14% !important;
    }

    thead tr th:nth-child(5),
    tbody tr td:nth-child(5),
    tfoot tr td:nth-child(5) {
      width: 14% !important;
    }

    thead tr th:nth-child(6),
    tbody tr td:nth-child(6),
    tfoot tr td:nth-child(6) {
      width: 10% !important;
    }

    @media print {
      table-layout: fixed;

      thead tr th {
        padding: 10px 5px !important;
      }

      tbody tr td {
        padding: 5px 5px !important;
      }
    }
  }
`;
