import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const ReportPage = ({
  noPadding,
  noMargin,
  children,
  contentEditable,
}) => (
  <Container
    name="report-container"
    noPadding={noPadding}
    noMargin={noMargin}
    contentEditable={contentEditable}
  >
    <div className="card">
      <div className="card-body">
        {children}
      </div>
    </div>
  </Container>
);

export default ReportPage;

ReportPage.defaultProps = {
  noPadding: false,
  noMargin: false,
  contentEditable: false,
};

ReportPage.propTypes = {
  children: PropTypes.any,
  noPadding: PropTypes.bool,
  noMargin: PropTypes.bool,
  contentEditable: PropTypes.bool,
};
