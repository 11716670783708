import Swal from 'sweetalert2';
import { push } from 'connected-react-router';

import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const confirmBankStatementUpload = (callback) => {
  return async (dispatch) => {
    confirmDialog.info({
      title: 'Importar Transações',
      message: 'Deseja importar os lançamentos conciliados? <br /> Se ainda houverem lançamentos não conciliados, eles ficarão disponíveis aqui.',
      confirmButtonText: 'Sim, importar!',
      icon: 'info',
      showLoaderOnConfirm: true,
      onConfirm: () => {
        dispatch(request());

        Swal.fire({
          title: 'Importando Lançamentos...',
          html: 'Aguarde enquanto importamos os lançamentos conciliados.',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();

            service.confirmBankStatementUpload().then(
              (response) => {
                dispatch(success(response));

                if (callback) {
                  callback();
                }

                Swal.close();

                dispatch(push('/dashboard'));
                dispatch(alertActionCreators.success('Transações importadas com sucesso!'));
              },
            ).catch((error) => {
              dispatch(failure(error));

              if (callback) {
                callback();
              }

              Swal.close();
            });
          },
        });
      },
      onCancel: () => {
        if (callback) {
          callback();
        }
      },
    });
  };

  function request() {
    return {
      type: constants.CONFIRM_BANK_STATEMENT_UPLOAD_REQUEST,
      payload: { },
    };
  }
  function success(response) {
    return {
      type: constants.CONFIRM_BANK_STATEMENT_UPLOAD_SUCCESS,
      response,
    };
  }
  function failure(error) {
    return {
      type: constants.CONFIRM_BANK_STATEMENT_UPLOAD_FAILURE,
      payload: error,
    };
  }
};

export default confirmBankStatementUpload;
