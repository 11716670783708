import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  PageHeader,
  Select,
  LoadingOverlay,
  Card,
} from '_components/_core';

import TabPermissions from './components/TabPermissions/TabPermissions';

export default function Permissions({
  activeCompany,
  users,
  allUserPermissions,
  onFetchUsers,
  onFetchUserPermissions,
  onUpdateUserPermissions,
  isLoadingPermissions,
  user,
}) {
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    onFetchUsers();
  }, [onFetchUsers, activeCompany, onFetchUserPermissions]);

  useEffect(() => {
    setSelectedUserId(null);
  }, [activeCompany]);

  const handleSubmit = useCallback((values) => {
    const { user_id } = values;
    const permissions = values;

    delete permissions.user;
    delete permissions.allUserPermissions;
    delete permissions.user_id;

    onUpdateUserPermissions(user_id, permissions);
  }, [onUpdateUserPermissions]);

  return (
    <Container fluid className="content-wrapper">
      <PageHeader title="Permissões" variant="small" />
      <Row className="tab-content">
        <Col>
          <Card className="p-3">
            <h6 className="m-0">
              Selecione um usuário para editar as permissões:
            </h6>
            <hr className="mt-2" />
            <Select
              placeholder="Selecione o usuário"
              name="user_id"
              options={users.filter((u) => u.value !== (user.manager_id ? user.manager_id : u.id))}
              value={users.find((u) => u.value === selectedUserId)}
              onChange={(user) => {
                setSelectedUserId(user.value);

                onFetchUserPermissions(user.value);
              }}
            />
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="p-3">
            <h6 className="m-0">
              Permissões de usuário na empresa:
            </h6>
            <hr className="mt-2" />
            <p className="m-0">
              <strong>
                Razão Social:
              </strong>
              <span className="ml-1">
                {activeCompany?.company_name}
              </span>
            </p>
            <p className="m-0">
              <strong>
                Nome Fantasia:
              </strong>
              <span className="ml-1">
                {activeCompany?.trading_name}
              </span>
            </p>
            <p className="m-0">
              <strong>
                CNPJ:
              </strong>
              <span className="ml-1">
                {activeCompany?.document_number || 'Não informado'}
              </span>
            </p>
          </Card>
        </Col>
      </Row>
      <LoadingOverlay active={isLoadingPermissions}>
        {selectedUserId && (
          <TabPermissions
            selectedUserId={selectedUserId}
            allUserPermissions={allUserPermissions}
            onSubmit={handleSubmit}
          />
        )}
      </LoadingOverlay>
    </Container>
  );
}

Permissions.defaultProps = {
  users: [],
  isLoadingPermissions: false,
  user: {},
};

Permissions.propTypes = {
  activeCompany: PropTypes.object,
  users: PropTypes.array,
  allUserPermissions: PropTypes.object,
  onFetchUsers: PropTypes.func,
  onFetchUserPermissions: PropTypes.func,
  onUpdateUserPermissions: PropTypes.func,
  isLoadingPermissions: PropTypes.bool,
  user: PropTypes.object,
};
