import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { Card, CardBody } from '_components/_core';

import { LoadingIcon } from '..';

function AsyncOperations({ hasPending, pendingAsyncOperation, className }) {
  if (!hasPending) {
    return null;
  }

  return (
    <Card className={className}>
      <CardBody>
        <div className="d-flex justify-content-between">
          <h5>
            {`${pendingAsyncOperation.status_message || ''} (${pendingAsyncOperation.progress || 0}% concluído)`}
          </h5>
          <h5>
            <LoadingIcon text="" />
          </h5>
        </div>
        <ProgressBar animated className="mt-2" now={pendingAsyncOperation.progress} variant="success" />
      </CardBody>
    </Card>
  );
}

AsyncOperations.defaultProps = {
  pendingAsyncOperation: {},
  hasPending: false,
};

AsyncOperations.propTypes = {
  pendingAsyncOperation: PropTypes.object,
  hasPending: PropTypes.bool,
  className: PropTypes.string,
};

export default AsyncOperations;
