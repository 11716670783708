import styled from 'styled-components';

import { Button } from '_components/_core';
import { Dropdown } from 'react-bootstrap';

export const StyledButton = styled(Button)`
  background-color: #11924F;
  border: 0;
  min-width: 200px;
  
  :hover {
    background-color: #0A502B;
  }
  
  :active {
    background-color: #0A502B;
  }
`;

export const StyledFileList = styled.div`
  margin-top: 16px;
`;

export const StyledDropdownItem = styled(Dropdown.Item)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
`;

export const StyledFileName = styled.div`
  padding-left: 8px;

  span {
    font-weight: 500;
  }

  small {

  }
`;

export const StyledDropdown = styled(Dropdown)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
  
  button {
    border-radius: 0px;
    height: 65px;
    background: transparent;
    border: 1px solid #ebe9e9;

    :focus {
      box-shadow: none;
    }
  }

  button:first-child {
    flex: 1 !important;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: none !important;
  }
`;

export const StyledFile = styled.div`
  padding-left: 16px;
  display: flex;
  align-items: center;
  border: 1px solid #ebe9e9;
  flex: 1;
  height: 65px;
  border-right: none;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const StyledDropdownToggle = styled(Dropdown.Toggle)`
  &:hover {
    background-color: #f5f5f5;
  }
`;
