import styled, { css } from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';

export const StyledBadge = styled.div`
  width: 10px;
  height: 10px;
  margin-left: -8px;
  border-radius: 50%;
  background-color: rgb(219, 80, 74);
  position: relative;
  bottom: -5px;
`;

export const StyledDropdown = styled(Dropdown)`
  display: flex;
  align-items: center;
`;

export const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  color: #354168;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;

  :hover {
    background-color: #f5f5f5;
    color: #354168;
    cursor: pointer;
  }
`;

export const StyledDropdownMenu = styled(Dropdown.Menu)`
  width: 310px !important;
  padding: 0;
  padding: 16px;

  h4 {
    color: #8D9096;
    font-weight: 500;
  }
`;

export const StyledHr = styled.hr`
 padding: 0;
 margin: 0;
`;

export const StyledDropdownItem = styled(Dropdown.Item)`
  padding: 0;
  border-radius: 0.25rem;
  white-space: initial;
  color: #8D9096;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  :hover, :active {
    background-color: transparent;
    color: #00784e;
  }

  ${({ disabled }) => disabled && css`
    cursor: normal;
    pointer-events: none;
  `}
`;

export const AlertsContainer = styled.div``;

export const NotificationsContainer = styled.div``;
