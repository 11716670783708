import { automationsConstants } from '../_constants';

const INITIAL_STATE = {
  isLoading: false,
  automationSettings: [],
};

export function automations(state = INITIAL_STATE, action) {
  switch (action.type) {
    case automationsConstants.SEARCH_PDF_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case automationsConstants.SEARCH_PDF_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        total,
        data,
        isLoading: false,
      };
    }
    case automationsConstants.SEARCH_PDF_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case automationsConstants.FETCH_AUTOMATION_SETTINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case automationsConstants.FETCH_AUTOMATION_SETTINGS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        automationSettings: data,
        isLoading: false,
      };
    }
    case automationsConstants.FETCH_AUTOMATION_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case automationsConstants.ADD_AUTOMATION_SETTINGS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case automationsConstants.ADD_AUTOMATION_SETTINGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case automationsConstants.ADD_AUTOMATION_SETTINGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case automationsConstants.UPDATE_AUTOMATION_SETTINGS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case automationsConstants.UPDATE_AUTOMATION_SETTINGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case automationsConstants.UPDATE_AUTOMATION_SETTINGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case automationsConstants.DELETE_AUTOMATION_SETTINGS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case automationsConstants.DELETE_AUTOMATION_SETTINGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case automationsConstants.DELETE_AUTOMATION_SETTINGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
