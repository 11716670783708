import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Form from 'react-bootstrap/Form';

import { StyledFormCheck } from './styles';

function FormRadioField({ className, options, ...props }) {
  const [field, meta, helpers] = useField(props);

  const error = meta.error && meta.touched ? meta.error : '';

  return (
    <>
      <Form inline className={className}>
        {options.map((option) => (
          <StyledFormCheck
            key={option.value}
            style={{
              display: 'inline-block',
            }}
            inline
            id={`${option.value}_${1}`}
            label={option.label}
            name={field.name}
            type="radio"
            checked={field.value === option.value}
            onClick={() => helpers.setValue(option.value)}
          />
        ))}
      </Form>
      {error && <small className="text-danger">{error}</small>}
    </>
  );
}

FormRadioField.defaultProps = {
  className: null,
  options: [],
};

FormRadioField.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
};

export default FormRadioField;
