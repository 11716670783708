import constants from '../../_constants/invoices.constants';
import service from '../../../services/invoices.service';

const getInvoiceGenerationParams = (params) => {
  return (dispatch) => {
    dispatch(request());

    service.getInvoiceGenerationParams(params).then(
      (response) => {
        const { data } = response;

        dispatch(success(data));
      },
    ).catch((err) => {
      dispatch(failure(err));
    });
  };

  function request() {
    return {
      type: constants.GET_INVOICE_GENERATION_PARAMS_REQUEST,
      payload: { },
    };
  }
  function success(data) {
    return {
      type: constants.GET_INVOICE_GENERATION_PARAMS_SUCCESS,
      payload: data,
    };
  }
  function failure(err) {
    return {
      type: constants.GET_INVOICE_GENERATION_PARAMS_FAILURE,
      payload: err,
    };
  }
};

export default getInvoiceGenerationParams;
