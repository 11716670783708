import React from 'react';
import PropTypes from 'prop-types';
import Sheet from 'react-modal-sheet';

function ActionSheet({
  children,
  header,
  isOpen,
  onToggle,
  ...props
}) {
  return (
    <Sheet isOpen={isOpen} onClose={onToggle} {...props}>
      <Sheet.Container>
        {header && <Sheet.Header />}
        <Sheet.Header>
          {header}
        </Sheet.Header>
        <Sheet.Content>
          <div style={{ overflow: 'auto' }}>
            {children}
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onClick={onToggle} />
    </Sheet>
  );
}

ActionSheet.defaultProps = {
  isOpen: false,
  header: null,
};

ActionSheet.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onToggle: PropTypes.func.isRequired,
  header: PropTypes.node,
};

export default ActionSheet;
