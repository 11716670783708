import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Card, CardHeader, Table } from '_components/_core';
import { TAX_REGIME_DEFAULT_COLUMNS, TAX_REGIME_TABLE_COLUMNS } from './utilities';

function TaxRegimes({ company }) {
  const { tax_regimes } = company || {};

  const data = useMemo(() => {
    if (!tax_regimes) {
      return [];
    }

    return tax_regimes;
  }, [tax_regimes]);

  return (
    <Card className="mt-3">
      <CardHeader title="Regime de Tributação" />
      <div className="table-responsive">
        <Table
          key="temp_id"
          data={data}
          defaultColumns={TAX_REGIME_DEFAULT_COLUMNS}
          columns={TAX_REGIME_TABLE_COLUMNS}
          hover
        />
      </div>
    </Card>
  );
}

TaxRegimes.propTypes = {
  company: PropTypes.object.isRequired,
};

export default TaxRegimes;
