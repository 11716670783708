import { connect } from 'react-redux';

import employeeActionCreators from '_store/_actions/employees';

import Dependents from './Dependents';

const mapStateToProps = (state) => ({
  employeeDependents: state.employees.employeeDependents,
  isLoading: state.employees.isLoading,
});

const mapDispatchToProps = {
  onFetchEmployeeDependents: employeeActionCreators.getEmployeeDependents,
  onAddEmployeeDependent: employeeActionCreators.addEmployeeDependent,
  onUpdateEmployeeDependent: employeeActionCreators.updateEmployeeDependent,
  onDeleteEmployeeDependent: employeeActionCreators.deleteEmployeeDependent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dependents);
