import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';

import {
  TRANSACTION_TYPE_SUB_TYPE_OPTIONS,
  INCOME_TYPE_SUB_TYPE_OPTIONS,
  EXPENSE_TYPE_SUB_TYPE_OPTIONS,
} from 'helpers';

import { Select } from '_components/_core';

function FormTypeSubtypeSelectField({
  placeholder,
  multiple,
  disabled,
  innerRef,
  showOnly,
  ...props
}) {
  const formik = useFormikContext();
  const [field] = useField(props);

  const handleChange = useCallback((option) => {
    const [typeValue, subTypeValue] = option.value.split('::');

    formik.setFieldValue('type', typeValue);
    formik.setFieldValue('sub_type', subTypeValue);
  }, [formik]);

  const value = useMemo(() => {
    const { type, sub_type } = formik.values;

    if (!type && !sub_type) {
      return null;
    }

    const formatted = `${type}::${sub_type || ''}`;

    return TRANSACTION_TYPE_SUB_TYPE_OPTIONS.find((option) => option.value === formatted);
  }, [formik]);

  const options = useMemo(() => {
    if (showOnly === 'INCOME') {
      return INCOME_TYPE_SUB_TYPE_OPTIONS;
    }

    if (showOnly === 'EXPENSE') {
      return EXPENSE_TYPE_SUB_TYPE_OPTIONS;
    }

    return TRANSACTION_TYPE_SUB_TYPE_OPTIONS;
  }, [showOnly]);

  return (
    <>
      <Select
        options={options}
        name={field.name}
        value={value}
        onChange={handleChange}
        onBlur={field.onBlur}
        isMulti={multiple}
        disabled={disabled}
        innerRef={innerRef}
        placeholder={placeholder}
      />
    </>
  );
}

FormTypeSubtypeSelectField.defaultProps = {
  options: [],
  multiple: false,
  disabled: false,
  innerRef: null,
  showOnly: 'ALL',
};

FormTypeSubtypeSelectField.propTypes = {
  showOnly: PropTypes.oneOf(['INCOME', 'EXPENSE', 'ALL']),
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  innerRef: PropTypes.object,
};

export default FormTypeSubtypeSelectField;
