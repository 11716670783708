import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Button, FormTextField } from '_components/_core';

function TagForm({
  tag,
  isLoading,
  onCreateTag,
  onUpdateTag,
  onClearTag,
  callback,
}) {
  const handleKeyPress = useCallback((e, handleSubmit) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }, []);

  const handleSubmitTag = useCallback((values) => {
    if (values.id) {
      onUpdateTag(values.id, values, () => {
        onClearTag();
        if (callback) {
          callback();
        }
      });
    } else {
      onCreateTag(values, () => {
        onClearTag();
      });
    }
  },
  [onCreateTag, onClearTag, onUpdateTag, callback]);

  return (
    <Formik
      initialValues={{
        id: tag ? tag.id : null,
        description: tag ? tag.description : '',
      }}
      onSubmit={handleSubmitTag}
      enableReinitialize
    >
      {({ handleSubmit, values }) => (
        <>
          <Form as={Row} onSubmit={handleSubmit}>
            <Form.Group as={Col} md="6" className="pr-0">
              <FormTextField
                name="description"
                placeholder="Adicione uma nova tag"
                onKeyPress={(e) => handleKeyPress(e, handleSubmit)}
              />
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Button
                type="submit"
                className="mt-1 btn-sm"
                variant="dark"
                isLoading={isLoading}
                onClick={handleSubmit}
                disabled={!values.description || isLoading}
              >
                {tag ? 'Salvar Alterações' : 'Salvar'}
              </Button>
              {tag && (
                <Button variant="link" className="p-0 m-0 ml-3" onClick={onClearTag}>
                  Cancelar
                </Button>
              )}
            </Form.Group>
          </Form>
        </>
      )}
    </Formik>
  );
}

TagForm.defaultProps = {
  tag: null,
};

TagForm.propTypes = {
  tag: PropTypes.object,
  isLoading: PropTypes.func,
  onCreateTag: PropTypes.func.isRequired,
  onUpdateTag: PropTypes.func.isRequired,
  onClearTag: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
};

export default TagForm;
