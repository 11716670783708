import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Dropzone,
  FormDateField,
  HintIcon,
} from '_components/_core';
import { AccountSelect } from '_components/_shared';
import { useMediaQuery } from 'helpers';

function BankStatementUpload({
  isLoading,
  onFetchAccounts,
  onParseBankStatements,
}) {
  const [selectedBankStatements, setSelectedBankStatements] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const { isMobile, isTablet } = useMediaQuery();

  useEffect(() => {
    onFetchAccounts();
  }, [onFetchAccounts]);

  const handleFilesChanged = useCallback((files) => {
    setSelectedBankStatements(files);
  }, []);

  const handleAccountSelected = useCallback((value) => {
    if (value) {
      setSelectedAccount(value.value);
    } else {
      setSelectedAccount(null);
    }
  }, []);

  const handleSubmitBankStatement = useCallback((values) => {
    if (isEmpty(selectedBankStatements) || !selectedAccount) return;

    onParseBankStatements({
      account_id: selectedAccount,
      bank_statements: selectedBankStatements,
      start_date: values.start_date,
      end_date: values.end_date,
    });
  }, [selectedBankStatements, selectedAccount, onParseBankStatements]);

  return (
    <Formik
      initialValues={{
        account_id: null,
        bank_statements: [],
        start_date: null,
        end_date: null,
      }}
      onSubmit={handleSubmitBankStatement}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <Form>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>Conta bancária</Form.Label>
                        <AccountSelect
                          onChange={handleAccountSelected}
                          value={[selectedAccount]}
                          creatable="transaction_account"
                          account_type="transaction_account"
                          isClearable
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>
                          Filtrar por datas: (opcional)
                        </Form.Label>
                        <FormDateField
                          name="start_date"
                          placeholder="Data Inicial"
                          withPortal={isMobile || isTablet}
                          allowClearWithPortal
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>
                          <HintIcon
                            hint="Caso seja informado, lançamentos fora do período digitado serão ignorados."
                            place="bottom"
                          />
                        </Form.Label>
                        <FormDateField
                          name="end_date"
                          placeholder="Data Final"
                          withPortal={isMobile || isTablet}
                          allowClearWithPortal
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>Selecionar arquivos</Form.Label>
                        <Dropzone
                          onChange={handleFilesChanged}
                          accept=".ofx"
                          readAsTextWithEncoding
                          showLengthMessage
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Row>
                    <Col>
                      <Button
                        onClick={handleSubmit}
                        disabled={isLoading || isEmpty(selectedBankStatements) || !selectedAccount}
                        isLoading={isLoading}
                      >
                        Importar
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

BankStatementUpload.defaultProps = {
  isLoading: false,
};

BankStatementUpload.propTypes = {
  isLoading: PropTypes.bool,
  onParseBankStatements: PropTypes.func.isRequired,
  onFetchAccounts: PropTypes.func.isRequired,
};

export default BankStatementUpload;
