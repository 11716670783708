import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import CurrencyInput from 'react-currency-input';
import { Form } from 'react-bootstrap';

import { DatePicker, InputErrorMessage } from '_components/_core';
import { AccountSelect } from '_components/_shared';

import styles from './styles.module.scss';

const OneDebitMultipleCredit = ({
  type,
  entry,
  child_entries,
  total_debit,
  total_credit,
  difference,
  ui,
  setType,
  setEventDate,
  setDebitAccount,
  setHistory,
  setChildEntryCreditAccount,
  setChildEntryHistory,
  removeChildEntry,
  setNewEntryChild,
  setEntryTotals,
}) => {
  const eventDateRef = useRef(null);

  const { event_date, amount, history } = entry || {};

  function handleDebitAccountSelected(accounts) {
    if (isEmpty(accounts)) {
      setDebitAccount({});
    } else {
      setDebitAccount(accounts[0]);
    }
  }

  function handleCreditAccountSelected(accounts, index) {
    if (isEmpty(accounts)) {
      setChildEntryCreditAccount({}, index);
    } else {
      setChildEntryCreditAccount(accounts[0], index);
    }
  }

  function handleAddNewChildEntry() {
    setNewEntryChild();
  }

  function handleRemoveChildEntry(index) {
    removeChildEntry(index, () => {
      setEntryTotals();
    });
  }

  return (
    <>
      <div className="row">
        <div className="col-xs-2 col-md-2 col-lg-2">
          <Form.Group>
            <Form.Label>Data</Form.Label>
            <DatePicker
              placeholder="Data"
              selectedDate={event_date}
              onChange={(eventDate) => setEventDate(eventDate)}
              ref={eventDateRef}
              onFocus={(e) => e.target.select()}
            />
            <InputErrorMessage fieldName="event_date" errorMessages={ui.errors} />
          </Form.Group>
        </div>
        <div className="col-xs-3 col-md-3 col-lg-3">
          <div className="form-group">
            <Form.Label>Tipo do Lançamento</Form.Label>
            <Form.Control value={type} onChange={(e) => setType(e.target.value)} as="select">
              <option value="1D1C">1 Débito para 1 Crédito</option>
              <option value="1DVC">1 Débito para Vários Créditos</option>
              <option value="1CVD">1 Crédito para Vários Débitos</option>
            </Form.Control>
          </div>
        </div>
        <div className="offset-md-1 col-xs-2 col-md-2 col-lg-2">
          <div className="form-group">
            <Form.Group>
              <Form.Label>Total Débito</Form.Label>
              <CurrencyInput
                type="text"
                className={classNames('form-control')}
                id="event_date"
                name="event_date"
                decimalSeparator=","
                thousandSeparator="."
                required
                disabled
                placeholder="0,00"
                value={total_debit}
              />
            </Form.Group>
          </div>
        </div>
        <div className="col-xs-2 col-md-2 col-lg-2">
          <div className="form-group">
            <Form.Group>
              <Form.Label>Total Crédito</Form.Label>
              <CurrencyInput
                type="text"
                className={classNames('form-control')}
                id="event_date"
                name="event_date"
                decimalSeparator=","
                thousandSeparator="."
                required
                disabled
                placeholder="0,00"
                value={total_credit}
              />
            </Form.Group>
          </div>
        </div>
        <div className="col-xs-2 col-md-2 col-lg-2">
          <div className="form-group">
            <Form.Group>
              <Form.Label>Diferença</Form.Label>
              <CurrencyInput
                type="text"
                className={classNames('form-control', difference !== 0 ? 'text-danger' : 'text-success')}
                id="event_date"
                name="event_date"
                decimalSeparator=","
                thousandSeparator="."
                required
                disabled
                placeholder="0,00"
                value={difference}
              />
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6 col-md-6 col-lg-6">
          <div className="row">
            <div className="col-xs-8 col-md-8 col-lg-8">
              <div className="form-group">
                <Form.Label>Debitar</Form.Label>
                <AccountSelect
                  onChange={(accounts) => handleDebitAccountSelected(accounts)}
                  showClassifier
                  selectTextOnFocus
                />
                <InputErrorMessage fieldName="debit_account_id" errorMessages={ui.errors} />
              </div>
            </div>
            <div className="col-xs-4 col-md-4 col-lg-4">
              <Form.Group>
                <Form.Label>Valor</Form.Label>
                <CurrencyInput
                  className="form-control"
                  placeholder="Valor"
                  id="amount"
                  name="amount"
                  autoComplete="off"
                  decimalSeparator=","
                  thousandSeparator="."
                  value={amount}
                  onFocus={(e) => e.target.select()}
                  onChange={() => {}}
                />
                <InputErrorMessage fieldName="amount" errorMessages={ui.errors} />
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="col-xs-6 col-md-6 col-lg-6">
          <div className="row">
            <div className="col-xs-12 col-md-12 col-lg-12">
              <Form.Group>
                <Form.Label>Histórico</Form.Label>
                <Form.Control
                  data-gramm="false"
                  as="textarea"
                  rows="1"
                  value={history}
                  onChange={(e) => setHistory(e.target.value)}
                />
                <InputErrorMessage fieldName="history" errorMessages={ui.errors} />
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
      {child_entries.map((entry, index) => (
        <div key={`child-entry-${index}`} className="row">
          <div className="col-xs-6 col-md-6 col-lg-6">
            <div className="row">
              <div className="col-xs-8 col-md-8 col-lg-8">
                <Form.Group className={styles.formGroup}>
                  <Form.Label>Creditar</Form.Label>
                  <AccountSelect
                    onChange={(accounts) => handleCreditAccountSelected(accounts, index)}
                    showClassifier
                    selectTextOnFocus
                    defaultAccounts={entry.credit_account ? [entry.credit_account] : []}
                  />
                </Form.Group>
                <div>
                  {index === child_entries.length - 1 && (
                    <button type="button" className={classNames('btn-link', styles.link)} onClick={handleAddNewChildEntry}>
                      Incluir novo Crédito
                    </button>
                  )}
                </div>
              </div>
              <div className="col-xs-4 col-md-4 col-lg-4">
                <Form.Group>
                  <Form.Label>Valor</Form.Label>
                  <CurrencyInput
                    className="form-control"
                    placeholder="Valor"
                    id="amount"
                    name="amount"
                    autoComplete="off"
                    decimalSeparator=","
                    thousandSeparator="."
                    data-index={index}
                    value={entry.amount}
                    onFocus={(e) => e.target.select()}
                    onChangeEvent={() => {}}
                  />
                  <InputErrorMessage fieldName="amount" errorMessages={ui.errors} />
                </Form.Group>
              </div>
            </div>
          </div>
          <div className={classNames(child_entries.length > 1 ? 'col-xs-5 col-md-5 col-lg-5' : 'col-xs-6 col-md-6 col-lg-6')}>
            <div className="row">
              <div className="col-xs-12 col-md-12 col-lg-12">
                <Form.Group>
                  <Form.Label>Histórico</Form.Label>
                  <Form.Control
                    data-gramm="false"
                    as="textarea"
                    rows="1"
                    value={entry.history}
                    onChange={(e) => setChildEntryHistory(e.target.value, index)}
                  />
                  <InputErrorMessage fieldName="history" errorMessages={ui.errors} />
                </Form.Group>
              </div>
            </div>
          </div>
          {child_entries.length > 1 && (
            <div className="col-xs-1 col-md-1 col-lg-1">
              <Form.Group>
                <Form.Label>&nbsp;</Form.Label>
                <br />
                <button
                  onClick={() => handleRemoveChildEntry(index)}
                  type="button"
                  className="btn btn-icons btn-inverse-warning"
                  disabled={ui.isLoading}
                >
                  <i className="mdi mdi-delete" />
                </button>
              </Form.Group>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

OneDebitMultipleCredit.propTypes = {
  type: PropTypes.any,
  entry: PropTypes.any,
  child_entries: PropTypes.any,
  total_debit: PropTypes.any,
  total_credit: PropTypes.any,
  difference: PropTypes.any,
  ui: PropTypes.any,
  setType: PropTypes.any,

  setEventDate: PropTypes.any,
  setDebitAccount: PropTypes.any,
  setHistory: PropTypes.any,

  setChildEntryCreditAccount: PropTypes.any,
  setChildEntryHistory: PropTypes.any,
  removeChildEntry: PropTypes.any,
  setNewEntryChild: PropTypes.any,
  setEntryTotals: PropTypes.any,
};

export default OneDebitMultipleCredit;
