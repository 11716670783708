import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  PopoverLayout,
  SmallLayout,
  MediumLayout,
  CompaniesModal,
  NavbarLayout,
} from './components';

function ActivatedCompany({
  activeCompany,
  isLoading,
  widgetType,
  onFetchActiveCompany,
  onFetchCompanies,
  collapsed,
  companies,
}) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    onFetchActiveCompany();
    onFetchCompanies();
  }, [onFetchActiveCompany, onFetchCompanies]);

  const handleModalToggle = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  return (
    <>
      <CompaniesModal
        isVisible={isVisible}
        onModalToggle={handleModalToggle}
      />
      {widgetType === 'MEDIUM' && (
        <MediumLayout
          isLoading={isLoading}
          company={activeCompany}
          onModalToggle={handleModalToggle}
          companies={companies}
        />
      )}
      {widgetType === 'SMALL' && (
        <SmallLayout
          isLoading={isLoading}
          company={activeCompany}
          onModalToggle={handleModalToggle}
          companies={companies}
        />
      )}
      {widgetType === 'POPOVER' && (
        <PopoverLayout
          isLoading={isLoading}
          company={activeCompany}
          onModalToggle={handleModalToggle}
          collapsed={collapsed}
          companies={companies}
        />
      )}
      {widgetType === 'NAVBAR' && (
        <NavbarLayout
          isLoading={isLoading}
          company={activeCompany}
          onModalToggle={handleModalToggle}
          companies={companies}
        />
      )}
    </>
  );
}

ActivatedCompany.defaultProps = {
  activeCompany: null,
  widgetType: 'MEDIUM',
};

ActivatedCompany.propTypes = {
  activeCompany: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  widgetType: PropTypes.oneOf(['MEDIUM', 'SMALL', 'POPOVER', 'NAVBAR']),
  onFetchActiveCompany: PropTypes.func.isRequired,
  onFetchCompanies: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  companies: PropTypes.array,
};

export default ActivatedCompany;
