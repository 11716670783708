import React from 'react';
import PropTypes from 'prop-types';

import Row from './Row';

import { Container } from './styles';

export default function Body({
  accounts,
  editAccount,
  deleteAccount,
  duplicateAccount,
  company,
}) {
  return (
    <Container>
      {accounts.map((account) => (
        <Row
          key={account.id}
          account={account}
          editAccount={editAccount}
          deleteAccount={deleteAccount}
          duplicateAccount={duplicateAccount}
          company={company}
        />
      ))}
    </Container>
  );
}

Body.propTypes = {
  accounts: PropTypes.array.isRequired,
  editAccount: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  duplicateAccount: PropTypes.func.isRequired,
};
