import { connect } from 'react-redux';
import { compose } from 'redux';

import authSelectors from '_store/_selectors/auth';
import reportsActionCreators from '_store/_actions/reports';
import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Dashboard from './Dashboard';

const mapStateToProps = (state) => ({
  user: authSelectors.currentUserSelector(state),
  revenue: state.reports.revenue,
  isLoading: state.reports.isLoading,
  activeCompany: state.company.activeCompany,
  cashflow: state.companyTransactions.cashflow,
  selected_account_id: state.companyTransactions.selected_account_id,
});

const mapDispatchToProps = {
  onFetchRevenueReport: reportsActionCreators.getRevenueReport,
  onFetchCashflow: companyTransactionsActionCreators.fetchCashflow,
  onFetchSemesterOverview: companyTransactionsActionCreators.fetchSemesterOverview,
  onFetchAccounts: companyTransactionsActionCreators.fetchAllAccounts,
  onFetchTransactionOverview: companyTransactionsActionCreators.fetchTransactionOverview,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['view_dashboard']),
)(Dashboard);
