import React, {
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaArrowRight } from 'react-icons/fa';
import { Formik } from 'formik';

import {
  Card,
  CardBody,
  FormSelectField,
  FormDateField,
  FormTextAreaField,
  Button,
  FormCurrencyField,
  CardFooter,
  Select,
  LoadingOverlay,
} from '_components/_core';
import { CreatableSelect } from '_components/_shared';

import { transformToFormValues } from '../utilities';
import ValidationSchema from '../utilities/ValidationSchema';
import useInvoiceForm from '../utilities/useInvoiceForm';

import EditIssuedInvoiceAlert from './EditIssuedInvoiceAlert';

function InvoiceForm({
  persons,
  activeCompany,
  isLoading,
  isLoadingParams,
  invoiceGenerationParams,
  selectedInvoice,
  onCreateIssuedInvoice,
  onUpdateIssuedInvoice,
  onCancelEdit,
  onFetchPersons,
}) {
  const { id } = activeCompany || {};

  useEffect(() => {
    onFetchPersons(id);
  }, [id, onFetchPersons]);

  const handleSubmitInvoice = (values) => {
    if (values.id) {
      onUpdateIssuedInvoice(
        values.id,
        values,
        () => {
          onCancelEdit();
        },
      );
    } else {
      onCreateIssuedInvoice({
        invoice: values,
      });
    }
  };

  const {
    persons: personsOptions,
    customers,
    services,
    natures,
    taxRegimes,
    onServiceSelected,
    onFindSelectedService,
    onPersonSelected,
    onFindSelectedPerson,
  } = useInvoiceForm({
    invoiceGenerationParams,
    persons,
  });

  const initialValues = useMemo(() => {
    if (!selectedInvoice) {
      return {
        emission_date: null,
        nature_of_operation: 1,
        special_tax_regime: null,
        simples_nacional_optant: null,
        service_taker: {
          customer_id: null,
          label: null,
          name: null,
          document_number: null,
          document_type: null,
          email: null,
          phone_number: null,
          address: {
            address_zip_code: null,
            address_street: null,
            address_number: null,
            address_complement: null,
            address_district: null,
            address_city: null,
            address_city_ibge: null,
            address_state: null,
            address_state_ibge: null,
          },
        },
        service: {
          service_list_item_id: null,
          service_list_item_code: null,
          service_district_tax_code: null,
          description: '',
          amount: null,
          city_ibge: activeCompany ? activeCompany.address_city_ibge : null,
        },
      };
    }

    return transformToFormValues(selectedInvoice, {
      persons,
      customers,
      services,
    });
  }, [activeCompany, selectedInvoice, customers, services, persons]);

  const handleCancelEdit = useCallback((resetForm) => {
    onCancelEdit(() => {
      resetForm(initialValues);
    });
  }, [initialValues, onCancelEdit]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitInvoice}
      validationSchema={ValidationSchema}
      enableReinitialize
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
        resetForm,
        isValid,
      }) => (
        <Row>
          <Col>
            <LoadingOverlay active={isLoading || isLoadingParams}>
              <Row>
                <Col>
                  {values.id && (
                    <EditIssuedInvoiceAlert />
                  )}
                  <Card>
                    <CardBody>
                      <Form onSubmit={handleSubmit}>
                        <Form.Row>
                          <Form.Group as={Col} md="2">
                            <Form.Label>Competência</Form.Label>
                            <FormDateField
                              name="emission_date"
                              placeholder="Data da prestação do serviço"
                            />
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <Form.Label>Cliente</Form.Label>
                            <CreatableSelect
                              isClearable
                              name="person"
                              creatable="person"
                              placeholder="Selecione..."
                              options={personsOptions}
                              onChange={(option) => onPersonSelected(option, setFieldValue)}
                              value={onFindSelectedPerson(values)}
                            />
                          </Form.Group>
                          <Form.Group as={Col} md="4">
                            <Form.Label>Valor</Form.Label>
                            <FormCurrencyField
                              name="service.amount"
                              placeholder="Valor da Nota Fiscal"
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} md="12">
                            <Form.Label>Item da Lista de Serviços</Form.Label>
                            <Select
                              name="service_list_item_code"
                              placeholder="Selecione..."
                              options={services}
                              onChange={(option) => onServiceSelected(option, setFieldValue)}
                              value={onFindSelectedService(values)}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} md="12">
                            <Form.Label>Descrição</Form.Label>
                            <FormTextAreaField
                              name="service.description"
                              placeholder="Descrição dos serviços"
                              rows={3}
                            />
                          </Form.Group>
                        </Form.Row>
                        <hr />
                        <Form.Row>
                          <Form.Group as={Col} md="3">
                            <Form.Label>Natureza da Operação</Form.Label>
                            <FormSelectField name="nature_of_operation" options={natures} />
                          </Form.Group>
                          <Form.Group as={Col} md="3">
                            <Form.Label>Regime Especial de Tributação</Form.Label>
                            <FormSelectField name="special_tax_regime" options={taxRegimes} />
                          </Form.Group>
                          <Form.Group as={Col} md="4">
                            <Form.Label>Optante Simple Nacional?</Form.Label>
                            <FormSelectField
                              name="simples_nacional_optant"
                              options={[
                                { label: 'Sim', value: true },
                                { label: 'Não', value: false },
                              ]}
                            />
                          </Form.Group>
                        </Form.Row>
                      </Form>
                    </CardBody>
                    <CardFooter>
                      <Button
                        isLoading={isLoading}
                        disabled={isLoading || !isValid}
                        icon={<FaArrowRight />}
                        type="submit"
                        onClick={handleSubmit}
                        className="mr-3"
                      >
                        {values.id ? 'Confirmar Alterações' : 'Adicionar à Lista de Emissões'}
                      </Button>
                      {values.id && (
                        <Button variant="secondary" onClick={() => handleCancelEdit(resetForm, setFieldValue)}>
                          Cancelar
                        </Button>
                      )}
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </LoadingOverlay>
          </Col>
        </Row>
      )}
    </Formik>
  );
}

InvoiceForm.defaultProps = {
  invoiceGenerationParams: null,
  activeCompany: {},
  isLoading: false,
  isLoadingParams: false,
  selectedInvoice: null,
};

InvoiceForm.propTypes = {
  persons: PropTypes.array,
  invoiceGenerationParams: PropTypes.object,
  activeCompany: PropTypes.object,
  isLoading: PropTypes.bool,
  isLoadingParams: PropTypes.bool,
  onCreateIssuedInvoice: PropTypes.func.isRequired,
  onUpdateIssuedInvoice: PropTypes.func.isRequired,
  onCancelEdit: PropTypes.func.isRequired,
  selectedInvoice: PropTypes.object,
  onFetchPersons: PropTypes.func.isRequired,
};

export default InvoiceForm;
