import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import {
  FormCpfCnpjField,
  FormTextField,
  FormDateField,
  FormSelectField,
} from '_components/_core';
import { TAX_REGIME_OPTIONS, STATUS_IRS_OPTIONS } from 'helpers/constants';

function CompanyFilters() {
  return (
    <Form>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Número do Documento</Form.Label>
            <FormCpfCnpjField name="document_number" type="CNPJ" placeholder="Número do Documento" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Razão Social</Form.Label>
            <FormTextField name="company_name" placeholder="Razão Social" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Nome Fantasia</Form.Label>
            <FormTextField name="trading_name" placeholder="Nome Fantasia" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Data de Abertura</Form.Label>
            <FormDateField name="date_founded_start" placeholder="Data Inicial" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label />
            <FormDateField name="date_founded_end" placeholder="Data Final" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Clente desde</Form.Label>
            <FormDateField name="client_since_start" placeholder="Data Inicial" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label />
            <FormDateField name="client_since_end" placeholder="Data Final" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Status RFB</Form.Label>
            <FormSelectField name="status_irs" options={STATUS_IRS_OPTIONS} />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Tributação</Form.Label>
            <FormSelectField name="tax_regime" options={TAX_REGIME_OPTIONS} />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Telefone</Form.Label>
            <FormTextField name="phone_number" placeholder="Telefone" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>E-mail</Form.Label>
            <FormTextField name="email" placeholder="E-mail" />
          </Form.Group>
        </Col>
      </Form.Row>
    </Form>
  );
}

export default CompanyFilters;
