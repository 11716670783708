import { parsePaginationParams } from 'helpers';

import constants from '../../_constants/invoices.constants';
import service from '../../../services/invoices.service';

const getIssuedInvoices = (params = {}) => {
  return (dispatch) => {
    dispatch(request());

    const parsedParams = parsePaginationParams(params);

    service.getIssuedInvoices(parsedParams).then(
      (response) => {
        const { total, data } = response.data;

        dispatch(success({ total, data }));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.GET_ISSUED_INVOICES_REQUEST,
      payload: { },
    };
  }
  function success(invoices) {
    return {
      type: constants.GET_ISSUED_INVOICES_SUCCESS,
      payload: invoices,
    };
  }
  function failure(error) {
    return {
      type: constants.GET_ISSUED_INVOICES_FAILURE,
      payload: error,
    };
  }
};

export default getIssuedInvoices;
