import constants from '../../_constants/payroll.constants';
import service from '../../../services/payroll.service';

const getPayrolls = (params) => {
  return (dispatch) => {
    dispatch(request());

    service.getPayrolls(params).then(
      (response) => {
        const { data } = response;

        dispatch(success(data));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(params) {
    return {
      type: constants.PAYROLL_GET_ALL_REQUEST,
      payload: { params },
    };
  }
  function success(data) {
    return {
      type: constants.PAYROLL_GET_ALL_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.PAYROLL_GET_ALL_FAILURE,
      payload: error,
    };
  }
};

export default getPayrolls;
