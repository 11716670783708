import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { DateTime } from 'luxon';

import { DEFAULT_CHART_OPTIONS } from 'helpers/constants';
import {
  Card,
  CardHeader,
  CardBody,
} from '_components/_core';

function RevenueChart({
  activeCompany,
  revenue,
  onFetchRevenueReport,
}) {
  useEffect(() => {
    onFetchRevenueReport({
      start_date: DateTime.now().minus({ months: 12 }).startOf('month').toFormat('yyyy-MM-dd'),
      end_date: DateTime.now().endOf('month').toFormat('yyyy-MM-dd'),
      type: 'month',
    });
  }, [onFetchRevenueReport, activeCompany]);

  const chartOptions = useMemo(() => {
    if (!revenue) {
      return DEFAULT_CHART_OPTIONS;
    }

    const options = {
      ...DEFAULT_CHART_OPTIONS,
      xaxis: {
        categories: revenue.internal_market.map((data) => data.formatted_competence),
      },
    };

    return options;
  }, [revenue]);

  const chartSeries = useMemo(() => {
    if (!revenue) {
      return [];
    }

    const series = [
      {
        name: 'Total',
        data: revenue.all.map((data) => data.gross_revenue),
      },
      {
        name: 'Mercado Interno',
        data: revenue.internal_market.map((data) => data.gross_revenue),
      },
      {
        name: 'Mercado Externo',
        data: revenue.external_market.map((data) => data.gross_revenue),
      },
    ];

    return series;
  }, [revenue]);

  return (
    <Card>
      <CardHeader title="Receita nos últimos 12 meses" />
      <CardBody>
        <Chart
          card
          options={chartOptions}
          series={chartSeries}
          type="line"
          width="100%"
          height="280px"
        />
      </CardBody>
    </Card>
  );
}

RevenueChart.defaultProps = {
  revenue: null,
  activeCompany: null,
};

RevenueChart.propTypes = {
  revenue: PropTypes.object,
  activeCompany: PropTypes.object,
  onFetchRevenueReport: PropTypes.func.isRequired,
};

export default RevenueChart;
