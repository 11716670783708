import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import FORMATTERS from 'helpers/formatters';

import {
  Card,
  CardHeader,
  CardBody,
  Tag,
} from '_components/_core';

import { StyledDescription } from '../../../../styles';

function ActiveState({ company }) {
  const {
    company_name,
    trading_name,
    document_number,
    municipal_registration,
    invoice_issuing_activation_date,
  } = company || {};

  return (
    <Card className="mt-3">
      <CardHeader
        title="Emissão de Notas Fiscais de Serviço"
        description="Economize horas de trabalho manual realizando a emissão de notas fiscais de serviço dos seus clientes pelo Contabills."
      />
      <CardBody>
        <Form>
          <Row>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <StyledDescription column>
                  <Tag variant="success">ATIVO</Tag>
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Data da ativação</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.DATE_DDMMYYYY(invoice_issuing_activation_date)}
                </StyledDescription>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>CNPJ</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.CNPJ(document_number)}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Razão Social</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.UPPERCASE(company_name)}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Nome Fantasia</Form.Label>
                <StyledDescription column>
                  {FORMATTERS.UPPERCASE(trading_name)}
                </StyledDescription>
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Inscrição Municipal</Form.Label>
                <StyledDescription column>
                  {municipal_registration}
                </StyledDescription>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}

ActiveState.defaultProps = {
  company: null,
};

ActiveState.propTypes = {
  company: PropTypes.object,
};

export default ActiveState;
