import FORMATTERS from 'helpers/formatters';
import groupByYear from './groupByYear';

export const DEFAULT_COLUMNS = {
  analytical: [
    'date_emission',
    'invoice_number',
    'service_taker_document_number',
    'service_taker_municipal_registration',
    'service_iss_withheld',
    'service_iss_amount',
  ],
  synthetic: [
    'competence',
    'iss_total',
    'iss_withheld',
    'iss_net',
  ],
};

export const TABLE_COLUMNS = {
  analytical: [
    {
      label: 'Número',
      keyName: 'invoice_number',
      sortingKey: 'invoice_number',
      align: 'center',
      width: 10,
      formatter: FORMATTERS.STRING,
      order: 1,
    },
    {
      label: 'Data de Emissão',
      keyName: 'date_emission',
      sortingKey: 'date_emission',
      align: 'center',
      width: 10,
      formatter: FORMATTERS.DATE_DDMMYYYY,
      order: 2,
    },
    {
      label: 'CNPJ Tomador',
      keyName: 'service_taker_document_number',
      sortingKey: 'service_taker_document_number',
      formatter: FORMATTERS.CPF_CNPJ,
      align: 'center',
      width: 15,
      order: 3,
    },
    {
      label: 'I. Mun. Tomador',
      keyName: 'service_taker_municipal_registration',
      sortingKey: 'service_taker_municipal_registration',
      align: 'center',
      width: 10,
      order: 4,
    },
    {
      label: 'Tomador',
      keyName: 'service_taker_company_name',
      sortingKey: 'service_taker_company_name',
      width: 35,
      formatter: FORMATTERS.UPPERCASE,
      order: 5,
    },
    {
      label: 'Valor Bruto NFS-e',
      keyName: 'service_gross_amount',
      sortingKey: 'service_gross_amount',
      align: 'center',
      width: 5,
      formatter: FORMATTERS.NUMBER,
      order: 6,
      sumOnFooter: true,
    },
    {
      label: 'Valor Líquido NFS-e',
      keyName: 'service_net_amount',
      sortingKey: 'service_net_amount',
      align: 'center',
      width: 5,
      formatter: FORMATTERS.NUMBER,
      order: 7,
      sumOnFooter: true,
    },
    {
      label: 'Retido',
      keyName: 'service_iss_withheld',
      sortingKey: 'service_iss_withheld',
      align: 'center',
      width: 5,
      formatter: FORMATTERS.ISS_WITHHELD,
      order: 8,
    },
    {
      label: 'Valor ISS',
      keyName: 'service_iss_amount',
      sortingKey: 'service_iss_amount',
      align: 'center',
      width: 5,
      formatter: FORMATTERS.NUMBER,
      order: 9,
      sumOnFooter: true,
    },
  ],
  synthetic: [
    {
      label: 'Período',
      keyName: 'competence',
      sortingKey: 'competence',
      align: 'center',
      width: 40,
      formatter: FORMATTERS.STRING,
      order: 1,
    },
    {
      label: 'ISS Total',
      keyName: 'iss_total',
      sortingKey: 'iss_total',
      formatter: FORMATTERS.NUMBER,
      align: 'center',
      width: 20,
      order: 2,
      sumOnFooter: true,
    },
    {
      label: 'ISS Retido',
      keyName: 'iss_withheld',
      sortingKey: 'iss_withheld',
      formatter: FORMATTERS.NUMBER,
      align: 'center',
      width: 20,
      order: 2,
      sumOnFooter: true,
    },
    {
      label: 'ISS à Recolher',
      keyName: 'iss_net',
      sortingKey: 'iss_net',
      formatter: FORMATTERS.NUMBER,
      align: 'center',
      width: 20,
      order: 2,
      sumOnFooter: true,
    },
  ],
};

export {
  groupByYear,
};
