import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { IoArrowForward } from 'react-icons/io5';
import { withRouter } from 'react-router-dom';

import { getQueryVariable } from 'helpers';
import {
  Button,
  FormTextField,
  FormCpfCnpjField,
  FormPasswordField,
} from '_components/_core';
import FORMATTERS from 'helpers/formatters';

import { signUpFormSchema } from './utilities';
import {
  SectionHeader,
  Container,
  Card,
  Title,
  Text,
} from './styles';

const CompleteSignUpForm = ({ isLoading, onCompleteSignUp }) => {
  const handleCreateAccount = useCallback((values) => {
    const user = {
      ...values,
      document_number: FORMATTERS.CLEAN_CPF_CNPJ(values.document_number),
    };

    onCompleteSignUp(user);
  }, [onCompleteSignUp]);

  return (
    <>
      <Container>
        <Card>
          <Title>
            Complete seu cadastro.
          </Title>
          <Text>
            Para acessar o Zenply, você precisa completar seu cadastro.
          </Text>
          <Formik
            enableReinitialize
            initialValues={{
              first_name: '',
              last_name: '',
              document_type: 'PF',
              document_number: '',
              email: getQueryVariable('email'),
              email_confirmation: getQueryVariable('email'),
              token: getQueryVariable('token'),
              password: '',
              password_confirmation: '',
            }}
            validationSchema={signUpFormSchema}
            onSubmit={handleCreateAccount}
          >
            {({
              values,
              handleSubmit,
              isValid,
            }) => (
              <div className="mt-3">
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label>CPF</Form.Label>
                    <FormCpfCnpjField
                      type={values.document_type === 'PF' ? 'CPF' : 'CNPJ'}
                      name="document_number"
                      placeholder="CPF"
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm="12" md="6">
                    <Form.Label>Nome</Form.Label>
                    <FormTextField name="first_name" autoComplete="off" placeholder="Nome" />
                  </Form.Group>
                  <Form.Group as={Col} sm="12" md="6">
                    <Form.Label>Sobrenome</Form.Label>
                    <FormTextField name="last_name" autoComplete="off" placeholder="Sobrenome" />
                  </Form.Group>
                </Form.Row>
                <SectionHeader className="mt-3">
                  Dados de Acesso
                </SectionHeader>
                <hr className="mt-2" />
                <Form.Row>
                  <Form.Group as={Col} xs={12} xl={6}>
                    <Form.Label>E-mail</Form.Label>
                    <FormTextField name="email" placeholder="E-mail" disabled />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} xl={6}>
                    <Form.Label>Confirmar E-mail</Form.Label>
                    <FormTextField name="email_confirmation" autoComplete="off" placeholder="E-mail" disabled />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} xs={12} xl={6}>
                    <Form.Label>Senha</Form.Label>
                    <FormPasswordField name="password" autoComplete="off" type="password" placeholder="Criar uma senha" />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} xl={6}>
                    <Form.Label>Confirmar Senha</Form.Label>
                    <FormPasswordField name="password_confirmation" autoComplete="off" type="password" placeholder="Confirmar senha" />
                  </Form.Group>
                </Form.Row>
                <Button
                  icon
                  type="button"
                  variant="success-2"
                  isLoading={isLoading}
                  disabled={!isValid || isLoading}
                  onClick={handleSubmit}
                >
                  Cadastrar
                  <IoArrowForward />
                </Button>
              </div>
            )}
          </Formik>
        </Card>
      </Container>
    </>
  );
};

CompleteSignUpForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onCompleteSignUp: PropTypes.func.isRequired,
};

export default withRouter(CompleteSignUpForm);
