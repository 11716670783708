import isEmpty from 'lodash/isEmpty';
import * as XLSX from 'sheetjs-style';

import { CELL_TYPES } from './constants';

const reportToExcel = ({
  report_name,
  reports,
}) => {
  const { result, result_other } = reports[report_name] || [];

  if (isEmpty(result)) return;

  const transactions = [...result, ...result_other];

  let total = transactions.reduce((total, transaction) => total + transaction.amount, 0);

  const spreadsheetData = [['Descrição', '-', 'Valor']];

  const transactionsType = [null, 'HEADERS'];

  for (const transaction of transactions) {
    const percent = (transaction.amount / total);

    spreadsheetData.push([transaction.description || '-', percent, transaction.amount *= -1]);

    transactionsType.push('EXPENSE');
  }

  spreadsheetData.push(
    [null, null, null],
    ['Total', '-', total *= -1],
  );

  transactionsType.push(null, 'HEADERS');

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(spreadsheetData);

  worksheet['!cols'] = [{ width: 40 }, { width: 10 }, { width: 20 }];

  for (let i = 1; i <= spreadsheetData.length; i += 1) {
    if (transactionsType[i] !== null) {
      worksheet[`A${i}`].s = CELL_TYPES[transactionsType[i]];
      worksheet[`B${i}`].s = CELL_TYPES[transactionsType[i]];
      worksheet[`C${i}`].s = CELL_TYPES[transactionsType[i]];
    }
    if (i !== 1 && i !== spreadsheetData.length - 1 && i !== spreadsheetData.length) {
      worksheet[`B${i}`].t = 'n';
      worksheet[`B${i}`].z = '0.00%';
      worksheet[`C${i}`].t = 'n';
      worksheet[`C${i}`].z = '#,##0.00';
    } else if (i === spreadsheetData.length) {
      worksheet[`C${i}`].t = 'n';
      worksheet[`C${i}`].z = '#,##0.00';
    }
  }

  worksheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } },
    { s: { r: spreadsheetData.length - 1, c: 0 }, e: { r: spreadsheetData.length - 1, c: 1 } },
  ];

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Despesas_Por_Descrição');

  XLSX.writeFile(workbook, 'Zenply_Despesas_Por_Descricao.xlsx', {
    type: 'array',
    bookType: 'xlsx',
  });
};

export default reportToExcel;
