import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { IoTrash } from 'react-icons/io5';
import { FaPencilAlt, FaDownload } from 'react-icons/fa';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

import FORMATTERS from 'helpers/formatters';
import {
  PageHeader,
  Button,
  Tabs,
  Skeleton,
} from '_components/_core';

import {
  BasicInfo,
  Address,
  TaxRegimes,
  Cnaes,
  Credentials,
  Fees,
  InvoiceSettings,
  PastRevenues,
  Associates,
  DigitalCertificate,
  PastPayrolls,
} from './components';

export default function Details({
  isLoading,
  activeCompany,
  onFetchCompany,
  onFetchCnpjCertificate,
}) {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation();
  const { id } = useParams();

  const {
    id: company_id,
    company_name,
    trading_name,
    document_number,
  } = selectedCompany || {};

  useEffect(() => {
    if (id && !isLoaded) {
      onFetchCompany(id, (company) => {
        setSelectedCompany({
          ...company,
        });
      });
      setIsLoaded(true);
    }
  }, [id, selectedCompany, onFetchCompany, isLoaded]);

  useEffect(() => {
    if (!id) {
      setSelectedCompany({
        ...activeCompany,
      });
    }
  }, [id, activeCompany]);

  const history = useHistory();

  const handleFetchCnpjCertificate = useCallback(() => {
    onFetchCnpjCertificate(document_number);
  }, [document_number, onFetchCnpjCertificate]);

  const handleCompanyInfoChange = useCallback((company) => {
    setSelectedCompany(company);
  }, []);

  const renderButtons = useCallback(() => (
    <>
      <Button
        iconPosition="left"
        variant="outline-danger"
        icon={<IoTrash size="0.9rem" />}
        noMargin
      >
        Excluir
      </Button>
      <Button
        iconPosition="left"
        onClick={() => history.push(`/empresas/${company_id}/editar`)}
        variant="outline-primary mr-2"
        icon={<FaPencilAlt size="0.9rem" />}
        noMargin
      >
        Editar
      </Button>
      <Button
        iconPosition="left"
        icon={<FaDownload size="0.9rem" />}
        variant="outline-info mr-2"
        noMargin
        onClick={handleFetchCnpjCertificate}
      >
        Cartão CNPJ
      </Button>
    </>
  ), [company_id, handleFetchCnpjCertificate, history]);

  const getTabs = useCallback(() => {
    const props = {
      company: selectedCompany,
      onCompanyInfoChange: handleCompanyInfoChange,
    };

    return [
      {
        id: 1,
        title: 'Informações Cadastrais',
        content: <BasicInfo {...props} />,
        shortName: 'informacoes',
      },
      {
        id: 2,
        title: 'Endereço',
        content: <Address {...props} />,
        shortName: 'endereco',
      },
      {
        id: 3,
        title: 'Atividades',
        content: <Cnaes {...props} />,
        shortName: 'atividades',
      },
      {
        id: 4,
        title: 'Regimes de Tributação',
        content: <TaxRegimes {...props} />,
        shortName: 'regimes',
      },
      {
        id: 5,
        title: 'Sócios',
        content: <Associates {...props} />,
        shortName: 'socios',
      },
      {
        id: 6,
        title: 'Certificado Digital',
        content: <DigitalCertificate {...props} />,
        shortName: 'certificado-digital',
      },
      {
        id: 7,
        title: 'Credenciais',
        content: <Credentials {...props} />,
        shortName: 'credenciais',
      },
      {
        id: 8,
        title: 'Honorários',
        content: <Fees {...props} />,
        shortName: 'honorarios',
      },
      {
        id: 9,
        title: 'Emissão de NFS-e',
        content: <InvoiceSettings {...props} />,
        shortName: 'emissao-nfse',
      },
      {
        id: 10,
        title: 'Receitas Anteriores',
        content: <PastRevenues {...props} />,
        shortName: 'receitas-anteriores',
      },
      {
        id: 11,
        title: 'Folhas Anteriores',
        content: <PastPayrolls {...props} />,
        shortName: 'folhas-anteriores',
      },
    ];
  }, [selectedCompany, handleCompanyInfoChange]);

  const [activeTab, setActiveTab] = useState(() => {
    const urlParams = new URLSearchParams(location.search);

    const tabName = urlParams.get('tab');
    const foundTab = getTabs().find((tab) => tab.shortName === tabName);
    if (tabName && foundTab) {
      return foundTab.id;
    }

    return 1;
  });

  const companyName = useMemo(() => {
    if (!company_name || isLoading) {
      return <Skeleton width="55%" />;
    }

    return startCase(toLower(company_name));
  }, [isLoading, company_name]);

  const tradingName = useMemo(() => {
    if (!document_number || isLoading) {
      return <Skeleton width="35%" />;
    }

    return `${FORMATTERS.CNPJ(document_number)} - ${trading_name}`;
  }, [isLoading, document_number, trading_name]);

  return (
    <Container className="content-wrapper fixedHeader" fluid>
      <PageHeader
        title={companyName}
        description={tradingName}
        sideContent={renderButtons()}
        fixedHeader
        withBackButton
      />
      <Tabs variant="secondary" tabs={getTabs()} activeTab={activeTab} onTabChange={(newTab) => setActiveTab(newTab)} />
    </Container>
  );
}

Details.defaultProps = {
  isLoading: false,
};

Details.propTypes = {
  isLoading: PropTypes.bool,
  activeCompany: PropTypes.object,
  onFetchCompany: PropTypes.func.isRequired,
  onFetchCnpjCertificate: PropTypes.func.isRequired,
};
