import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Page from '../_components/_core/Page/Page';

import UnauthorizedLayout from '../layouts/UnauthorizedLayout';

export default function UnauthorizedRoute({ title, component: Component, ...rest }) {
  const location = useLocation();

  useEffect(() => {
    const page_path = location.pathname + location.search;

    ReactGA.send({
      hitType: 'pageview',
      page: page_path,
      title,
    });
  }, [location, title]);

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <UnauthorizedLayout>
          <Page title={title}>
            <Component {...matchProps} />
          </Page>
        </UnauthorizedLayout>
      )}
    />
  );
}
