import { connect } from 'react-redux';

import meiActionCreators from '_store/_actions/mei';

import Onboarding from './Onboarding';

const mapDispatchToProps = {
  onFetchAllMeis: meiActionCreators.fetchAllCompanyMeis,
  onFetchMeiDas: meiActionCreators.fetchMeiDas,
  onCreateMei: meiActionCreators.createMei,
  onUpdateMei: meiActionCreators.updateMei,
  onDeleteMei: meiActionCreators.deleteMei,
};

export default connect(null, mapDispatchToProps)(Onboarding);
