import { connect } from 'react-redux';

import bankStatementActionCreators from '_store/_actions/bankStatements';
import entriesActionCreators from '_store/_actions/entries';
import accountsActionCreators from '_store/_actions/accounts';

import ReconcileContainer from './Reconcile';

const mapStateToProps = (state) => ({
  isLoading: state.bankStatements.isLoading,
  isLoadingEntries: state.entries.isLoading,
  entries: state.bankStatements.entries,
  total: state.bankStatements.total,
  narrations: [],
  accounts: state.accounts.accounts,
});

const mapDispatchToProps = {
  onFetchAccounts: accountsActionCreators.getAllAccounts,
  onSubmitEntries: entriesActionCreators.addManyEntries,
  onFetchBankStatementEntries: bankStatementActionCreators.fetchBankStatementEntries,
  onEntriesIgnore: bankStatementActionCreators.ignoreEntries,
  onUpdateMany: bankStatementActionCreators.updateManyBankStatementEntries,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReconcileContainer);
