import { connect } from 'react-redux';

import payrollActionCreators from '_store/_actions/payroll';

import MinimumWageTables from './MinimumWageTables';

const mapStateToProps = (state) => ({
  minimumWage: state.payroll.minimumWageTables,
  total: state.payroll.minimumWageTotal,
});

const mapDispatchToProps = {
  onFetchMinimumWage: payrollActionCreators.getMinimumWageTables,
};

export default connect(mapStateToProps, mapDispatchToProps)(MinimumWageTables);
