import { connect } from 'react-redux';

import CashflowOverview from './CashflowOverview';

const mapStateToProps = (state) => ({
  cashflow: state.companyTransactions.cashflow,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(CashflowOverview);
