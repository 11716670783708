import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, Table } from '_components/_core';

import { DEFAULT_COLUMNS, TABLE_COLUMNS } from './utilities';

function Cnaes({ company }) {
  const { cnaes } = company || {};

  return (
    <Card className="mt-3">
      <CardHeader title="Atividades" />
      <Table
        key="cnae"
        defaultColumns={DEFAULT_COLUMNS}
        columns={TABLE_COLUMNS}
        data={cnaes}
        hover
      />
    </Card>
  );
}

Cnaes.propTypes = {
  company: PropTypes.object.isRequired,
};

export default Cnaes;
