import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  left: var(--sidebar-size);
  bottom: 0;
  right: 0;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px,
                rgb(0 0 0 / 0%) 0px 0px 0px 0px,
                rgb(0 0 0 / 50%) 0px 1px 3px 0px,
                rgb(0 0 0 / 6%) 0px 1px 2px 0px;
  z-index: 3;

  ${({ variant }) => variant === 'top' && css`
    position: sticky;
    top: 0;
    box-shadow: none;
  `}
`;

export const SelectItems = styled.h4`
  color: var(--color-text-primary);
  font-weight: 500 !important;
  font-size: 14px !important;
  margin-bottom: 0;
  margin-right: 8px;
`;

export const Divider = styled.div`
  margin-right: 8px;
`;

export const Actions = styled.div`
  margin-left: auto;

  ${({ variant }) => variant === 'top' && css`
    margin-left: unset;
    width: 100%;
  `}
`;
