import { SPECIAL_COLUMNS } from '_components/_core/Table';
import FORMATTERS from 'helpers/formatters';

import FeesSchema from './FeesSchema';

export const DEFAULT_COLUMNS = [
  'id',
  'start_date',
  'amount',
];

export const TABLE_COLUMNS = [
  {
    label: 'Ações',
    keyName: 'id',
    align: 'center',
    width: 15,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'Vigência',
    keyName: 'start_date',
    align: 'center',
    formatter: FORMATTERS.DATE_DDMMYYYY,
    width: 15,
  },
  {
    label: 'Valor',
    keyName: 'amount',
    align: 'left',
    formatter: FORMATTERS.NUMBER,
    width: 70,
  },
];

export {
  FeesSchema,
};
