import { connect } from 'react-redux';

import payrollActionCreators from '_store/_actions/payroll';

import PastPayrolls from './PastPayrolls';

const mapStateToProps = (state) => ({
  manualPayroll: state.payroll.manual_payroll,
  isLoading: state.payroll.isLoading,
});

const mapDispatchToProps = {
  onFetchManualPayroll: payrollActionCreators.getManuallyInsertedPayroll,
  onSaveManualPayroll: payrollActionCreators.saveManualPayroll,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PastPayrolls);
