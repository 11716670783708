import styled from 'styled-components';

export const EditIcon = styled.img`
  display: inline-flex;
  width: 80px;
  border-radius: 50%;
  margin-right: -80px;
  opacity: 0;

  &:hover {
    opacity: 0.8;
  }
`;

export const AvatarWrapper = styled.div`
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 10%;
`;

export const StyledAvatar = styled.img`
  width: 80px;
  max-height: 80px;
`;

export const StyledInput = styled.input.attrs({
  type: 'file',
})`
 display: none;
`;

export const StyledLabel = styled.label`
  cursor: pointer;
  margin: 0;
  padding-left: 13px;
  padding-right: 30px;
`;
