import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const PrintOptionsContext = createContext();

export const Provider = ({ children }) => {
  const [value, setValue] = useState(() => ({
    logoPosition: localStorage.getItem('logoPosition') || 'hide',
    showCompanyData: localStorage.getItem('showCompanyData') || true,
    showPageData: localStorage.getItem('showPageData') || true,
    isMenuOpen: false,
  }));

  useEffect(() => {
    localStorage.setItem('logoPosition', value.logoPosition);
    localStorage.setItem('showCompanyData', value.showCompanyData);
    localStorage.setItem('showPageData', value.showPageData);
  }, [value]);

  return (
    <PrintOptionsContext.Provider value={{ value, setValue }}>
      {children}
    </PrintOptionsContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};
