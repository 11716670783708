import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withRouter, useHistory } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';

import { Button } from '_components/_core';

import { BasicInfo } from './components';
import {
  Container,
  Card,
  Title,
  Text,
} from './styles';

const ContabillsForm = ({
  isLoading,
  onSignUp,
}) => {
  const history = useHistory();

  return (
    <>
      <Container>
        <Card>
          <Title>
            Crie sua conta
          </Title>
          <Text>
            Para utilizar o Contabills, você precisa criar uma conta.
          </Text>
          <Row>
            <Col>
              <BasicInfo
                onSignUp={onSignUp}
                isLoading={isLoading}
              />
            </Col>
          </Row>
          <div className=" d-flex justify-content-center mt-3">
            <Button className="ml-0 pl-0 d-flex justify-content-center" variant="link" onClick={() => history.push('/entrar')}>
              <IoArrowBack className="mr-3" />
              Voltar para login
            </Button>
          </div>
        </Card>
      </Container>
    </>
  );
};

ContabillsForm.defaultProps = {
  isLoading: false,
};

ContabillsForm.propTypes = {
  onSignUp: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default withRouter(ContabillsForm);
