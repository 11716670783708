import * as XLSX from 'sheetjs-style';

import { CELL_TYPES } from './constants';

const reportToExcel = ({
  report_name,
  reports,
}) => {
  const { results } = reports[report_name];

  if (!results) return;

  const spreadsheetData = [
    ['Receita Bruta', results.gross_income],
    ['Impostos', results.total_taxes *= -1],
    ['Lucro bruto', results.gross_profit],
    ['Despesas Variáveis', results.variable_expenses *= -1],
    ['Lucro Operacional', results.operational_profit],
    ['Despesas Fixas', results.fixed_expenses *= -1],
    ['Gastos com Pessoal', results.people_expenses *= -1],
    ['Resultado do Exercício', results.net_profit],
  ];

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(spreadsheetData);

  worksheet['!cols'] = [{ width: 24 }, { width: 16 }];

  worksheet[`A${1}`].s = CELL_TYPES.HEADERS;
  worksheet[`B${1}`].s = CELL_TYPES.INCOME;
  worksheet[`A${2}`].s = CELL_TYPES.HEADERS;
  worksheet[`B${2}`].s = CELL_TYPES.EXPENSE;
  worksheet[`A${3}`].s = CELL_TYPES.HEADERS;
  worksheet[`B${3}`].s = results.gross_profit < 0 ? CELL_TYPES.EXPENSE : CELL_TYPES.INCOME;
  worksheet[`A${4}`].s = CELL_TYPES.HEADERS;
  worksheet[`B${4}`].s = CELL_TYPES.EXPENSE;
  worksheet[`A${5}`].s = CELL_TYPES.HEADERS;
  worksheet[`B${5}`].s = results.operational_profit < 0 ? CELL_TYPES.EXPENSE : CELL_TYPES.INCOME;
  worksheet[`A${6}`].s = CELL_TYPES.HEADERS;
  worksheet[`B${6}`].s = CELL_TYPES.EXPENSE;
  worksheet[`A${7}`].s = CELL_TYPES.HEADERS;
  worksheet[`B${7}`].s = CELL_TYPES.EXPENSE;
  worksheet[`A${8}`].s = CELL_TYPES.HEADERS;
  worksheet[`B${8}`].s = results.net_profit < 0 ? CELL_TYPES.EXPENSE : CELL_TYPES.INCOME;

  worksheet[`B${1}`].t = 'n';
  worksheet[`B${1}`].z = '#,##0.00';
  worksheet[`B${2}`].t = 'n';
  worksheet[`B${2}`].z = '#,##0.00';
  worksheet[`B${3}`].t = 'n';
  worksheet[`B${3}`].z = '#,##0.00';
  worksheet[`B${4}`].t = 'n';
  worksheet[`B${4}`].z = '#,##0.00';
  worksheet[`B${5}`].t = 'n';
  worksheet[`B${5}`].z = '#,##0.00';
  worksheet[`B${6}`].t = 'n';
  worksheet[`B${6}`].z = '#,##0.00';
  worksheet[`B${7}`].t = 'n';
  worksheet[`B${7}`].z = '#,##0.00';
  worksheet[`B${8}`].t = 'n';
  worksheet[`B${8}`].z = '#,##0.00';

  XLSX.utils.book_append_sheet(workbook, worksheet, 'DRE');

  XLSX.writeFile(workbook, 'Zenply_DRE.xlsx', {
    type: 'array',
    bookType: 'xlsx',
  });
};

export default reportToExcel;
