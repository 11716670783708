import { connect } from 'react-redux';
import { compose } from 'redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Import from './Import';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onFetchBankStatementTransactions:
  companyTransactionsActionCreators.fetchBankStatementTransactions,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['import_files']),
)(Import);
