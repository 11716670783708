import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Dropzone,
} from '_components/_core';

import { ImportAlert } from './components';

function InvoiceUpload({ isLoading, activeCompany, onUploadInvoices }) {
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const handleSubmit = useCallback(() => {
    if (isEmpty(selectedInvoices)) return;

    onUploadInvoices({
      invoices: selectedInvoices,
      company: activeCompany,
      manager_id: activeCompany.manager_id,
      company_id: activeCompany.id,
    });
  }, [selectedInvoices, activeCompany, onUploadInvoices]);

  const handleFilesChanged = useCallback(async (files) => {
    setSelectedInvoices(files);
  }, []);

  const hasCityAndStateIbge = useMemo(() => {
    const { address_city_ibge, address_state_ibge } = activeCompany || {};

    return address_city_ibge && address_state_ibge;
  }, [activeCompany]);

  const renderImportAlert = useCallback(() => {
    if (!hasCityAndStateIbge) {
      return <ImportAlert activeCompany={activeCompany} />;
    }

    return null;
  }, [activeCompany, hasCityAndStateIbge]);

  return (
    <Row>
      <Col>
        {renderImportAlert()}
        <Card>
          <CardHeader title="Selecionar arquivos" />
          <CardBody>
            <Dropzone onChange={handleFilesChanged} accept=".xml" readAsText showLengthMessage />
          </CardBody>
          <CardFooter>
            <Row>
              <Col>
                <Button
                  onClick={handleSubmit}
                  disabled={isLoading || isEmpty(selectedInvoices) || !hasCityAndStateIbge}
                  isLoading={isLoading}
                >
                  Importar
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
}

InvoiceUpload.defaultProps = {
  isLoading: false,
};

InvoiceUpload.propTypes = {
  isLoading: PropTypes.bool,
  activeCompany: PropTypes.object.isRequired,
  onUploadInvoices: PropTypes.func.isRequired,
};

export default InvoiceUpload;
