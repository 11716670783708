import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/employees.constants';
import service from '../../../services/employees.service';
import alertActionCreators from '../alert.actions';

import getEmployeeSalaries from './getEmployeeSalaries';

const deleteEmployeeSalary = (employeeId, salaryId) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Exclusão de salário',
      message: 'Tem certeza que deseja excluir este salário? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, deletar!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(employeeId, salaryId));

        service.deleteSalary(employeeId, salaryId).then(
          () => {
            dispatch(success(employeeId));
            dispatch(alertActionCreators.success('Salário excluído com sucesso!'));
            dispatch(getEmployeeSalaries(employeeId));
          },
        ).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });

    dispatch(request());
  };

  function request() {
    return {
      type: constants.EMPLOYEE_SALARIES_DELETE_REQUEST,
      payload: { },
    };
  }
  function success(id) {
    return {
      type: constants.EMPLOYEE_SALARIES_DELETE_SUCCESS,
      payload: id,
    };
  }
  function failure(error) {
    return {
      type: constants.EMPLOYEE_SALARIES_DELETE_FAILURE,
      payload: error,
    };
  }
};

export default deleteEmployeeSalary;
