import FORMATTERS from 'helpers/formatters';

import formatData from './formatData';

export const DEFAULT_COLUMNS = [
  'tax_name',
  'amount_due_first_month',
  'amount_due_second_month',
  'amount_due_third_month',
  'total',
];

export const TABLE_COLUMNS = [
  {
    label: '',
    keyName: 'tax_name',
    align: 'left',
    width: 60,
    order: 1,
  },
  {
    label: '-',
    keyName: 'amount_due_first_month',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 2,
    sumOnFooter: true,
    customLabel: (item) => FORMATTERS.DATE_MMYYYY(item.first_month),
  },
  {
    label: '-',
    keyName: 'amount_due_second_month',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 2,
    sumOnFooter: true,
    customLabel: (item) => FORMATTERS.DATE_MMYYYY(item.second_month),
  },
  {
    label: '-',
    keyName: 'amount_due_third_month',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 2,
    sumOnFooter: true,
    customLabel: (item) => FORMATTERS.DATE_MMYYYY(item.third_month),
  },
  {
    label: 'Total',
    keyName: 'total',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 2,
    sumOnFooter: true,
  },
];

export {
  formatData,
};
