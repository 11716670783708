import { push } from 'connected-react-router';

import constants from '../../_constants/auth.constants';
import service from '../../../services/auth.service';
import alertActionCreators from '../alert.actions';

const confirmEmailToken = (params, callback, errorCallback) => {
  return (dispatch) => {
    dispatch(request());

    service.confirmEmailToken(params).then(
      (response) => {
        dispatch(success(response));
        dispatch(push('/entrar'));

        dispatch(alertActionCreators.success(response.data.message));

        if (callback) {
          callback(response.data);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));

      if (errorCallback) {
        errorCallback(error);
      }
    });
  };

  function request() {
    return {
      type: constants.EMAIL_CONFIRMATION_REQUEST,
    };
  }
  function success(response) {
    return {
      type: constants.EMAIL_CONFIRMATION_SUCCESS,
      payload: { response },
    };
  }
  function failure(error) {
    return {
      type: constants.EMAIL_CONFIRMATION_FAILURE,
      payload: { error },
    };
  }
};

export default confirmEmailToken;
