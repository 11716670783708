import FORMATTERS from 'helpers/formatters';
import { SPECIAL_COLUMNS } from '_components/_core/Table';

export const DEFAULT_COLUMNS = [
  'id',
  'date_competence',
  'gross_revenue',
  'rbt12',
  'fpt12',
  'fator_r',
  'aliquota_efetiva',
  'simples_nacional',
];

export const TABLE_COLUMNS = [
  {
    label: 'Recalcular',
    keyName: 'id',
    align: 'center',
    width: 10,
    specialType: SPECIAL_COLUMNS.ACTIONS,
    order: 1,
  },
  {
    label: 'Competência',
    keyName: 'date_competence',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.DATE_MMYYYY,
    order: 2,
  },
  {
    label: 'Receita Bruta',
    keyName: 'gross_revenue',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 3,
    sumOnFooter: true,
  },
  {
    label: 'RBT 12',
    keyName: 'rbt12',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 4,
  },
  {
    label: 'FPT 12',
    keyName: 'fpt12',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 5,
  },
  {
    label: 'Fator R',
    keyName: 'fator_r',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.PERCENTAGE_CALCULATED,
    order: 6,
  },
  {
    label: 'Alíquota',
    keyName: 'aliquota_efetiva',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.PERCENTAGE_CALCULATED,
    order: 7,
  },
  {
    label: 'Simples Nacional',
    keyName: 'simples_nacional',
    align: 'center',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 8,
    sumOnFooter: true,
  },
];

export const MONTH_TABLE_DEFAULT_COLUMNS = [
  'tax_name',
  'amount_due',
];

export const MONTH_TABLE_COLUMNS = [
  {
    label: '',
    keyName: 'tax_name',
    align: 'left',
    width: 10,
    order: 1,
  },
  {
    label: 'Valor',
    keyName: 'amount_due',
    align: 'right',
    width: 10,
    formatter: FORMATTERS.NUMBER,
    order: 2,
    sumOnFooter: true,
  },
];
