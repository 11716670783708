import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import isEmpty from 'lodash/isEmpty';

import Button from '_components/_core/Button';
import Modal from '_components/_core/Modal';

import styles from './styles.module.scss';

function AddAssociateModal({
  isOpen,
  isLoading,
  selectedAssociate,
  onModalClose,
  onAddAssociate,
  onUpdateAssociate,
  getAllAssociates,
}) {
  const [name, setName] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [share, setShare] = useState('');

  useEffect(() => {
    if (selectedAssociate) {
      setName(selectedAssociate.name);
      setDocumentNumber(selectedAssociate.document_number);
      setShare(selectedAssociate.share);
    }
  }, [selectedAssociate]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    if (!(name || documentNumber || share)) {
      return;
    }

    if (isEmpty(selectedAssociate)) {
      onAddAssociate({
        name,
        document_number: documentNumber,
        share: Number(share),
      }).then(() => {
        onModalClose();
        setName('');
        setDocumentNumber('');
        setShare('');

        getAllAssociates();
      });
    } else {
      onUpdateAssociate(selectedAssociate.id, {
        name,
        document_number: documentNumber,
        share: Number(share),
      }).then(() => {
        onModalClose();
        setName('');
        setDocumentNumber('');
        setShare('');

        getAllAssociates();
      });
    }
  }, [
    name,
    getAllAssociates,
    onAddAssociate,
    onModalClose,
    onUpdateAssociate,
    documentNumber,
    share,
    selectedAssociate,
  ]);

  const getModalTitle = useCallback(() => {
    if (!selectedAssociate) {
      return 'Incluir novo socio';
    }

    return `Editar ${selectedAssociate.name}`;
  }, [selectedAssociate]);

  const getModalFooter = useCallback(() => (
    <div className={styles.modalFooter}>
      <Button variant="secondary" onClick={onModalClose}>
        Cancelar
      </Button>
      <Button variant="primary" disabled={isLoading} isLoading={isLoading} onClick={handleSubmit}>
        Salvar
      </Button>
    </div>
  ), [onModalClose, handleSubmit, isLoading]);

  return (
    <Modal
      title={getModalTitle()}
      isVisible={isOpen}
      onHide={onModalClose}
      footer={getModalFooter()}
    >
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Nome</Form.Label>
          <Form.Control
            type="name"
            placeholder="Nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>CPF</Form.Label>
              <Form.Control
                type="text"
                placeholder="CPF"
                value={documentNumber}
                onChange={(e) => setDocumentNumber(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Participação</Form.Label>
              <Form.Control
                type="text"
                placeholder="Participação"
                value={share}
                onChange={(e) => setShare(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    </Modal>
  );
}

AddAssociateModal.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  selectedAssociate: PropTypes.object,
  onModalClose: PropTypes.func,
  onAddAssociate: PropTypes.func,
  onUpdateAssociate: PropTypes.func,
  getAllAssociates: PropTypes.func,
};

export default AddAssociateModal;
