import HttpService from './http.service';

export default {
  getAll,
  remove,
  removeMany,
  upload,
  getFiscalOverview,
  generateInvoiceEntries,
  syncInvoices,
  getInvoiceGenerationParams,
  createIssuedInvoice,
  updateIssuedInvoice,
  getIssuedInvoices,
  getIssuedInvoiceById,
  confirmInvoiceIssuing,
  deleteMultipleIssuedInvoices,
  deleteIssuedInvoice,
  duplicate,
};

async function getAll(params) {
  return HttpService.get('/invoices', params);
}

async function remove(id) {
  return HttpService.delete(`/invoices/${id}`);
}

async function removeMany(params) {
  return HttpService.delete('/multiple-invoices', params);
}

async function upload(invoices) {
  return HttpService.post('/import-invoices', invoices);
}

async function getFiscalOverview(params) {
  return HttpService.get('/fiscal/overview', params);
}

async function generateInvoiceEntries(params) {
  return HttpService.post('/invoice-entries', params);
}

async function syncInvoices(params) {
  return HttpService.post('/invoices-sync', params);
}

async function getInvoiceGenerationParams() {
  return HttpService.get('/generate-invoices-params');
}

async function createIssuedInvoice(params) {
  return HttpService.post('/issued-invoices', params);
}

async function updateIssuedInvoice(id, params) {
  return HttpService.put(`/issued-invoices/${id}`, params);
}

async function getIssuedInvoices(params) {
  return HttpService.get('/issued-invoices', params);
}

async function deleteIssuedInvoice(id) {
  return HttpService.delete(`/issued-invoices/${id}`);
}

async function getIssuedInvoiceById(id) {
  return HttpService.get(`/issued-invoices/${id}`);
}

async function confirmInvoiceIssuing(params) {
  return HttpService.post('/generate-invoices', params);
}

async function deleteMultipleIssuedInvoices(params) {
  return HttpService.delete('/multiple-issued-invoices', params);
}

async function duplicate(params) {
  return HttpService.post('/duplicate-issued-invoices', params);
}
