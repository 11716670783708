import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import userActionCreators from '_store/_actions/user';

import Container from './components/Container';

const mapStateToProps = (state) => ({
  userState: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(userActionCreators, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
