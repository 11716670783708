import * as yup from 'yup';

import {
  REQUIRED,
  EMAIL,
} from 'helpers/validation-messages';

const UsersSchema = yup.object().shape({
  email: yup.string()
    .max(255)
    .email(EMAIL)
    .required(REQUIRED)
    .nullable(),
});

export default UsersSchema;
