import * as Yup from 'yup';
import { ptShort } from 'yup-locale-pt';

Yup.setLocale(ptShort);

const FeesSchema = Yup.object().shape({
  start_date: Yup.date().required().nullable(),
  amount: Yup.number().min(0.01).required().nullable(),
});

export default FeesSchema;
