import { connect } from 'react-redux';

import authActionCreators from '_store/_actions/auth';

import SignUpConfirmation from './SignUpConfirmation';

const mapDispatchToProps = {
  onConfirmEmail: authActionCreators.confirmEmailToken,
  onRequestEmailConfirmation: authActionCreators.requestEmailConfirmToken,
};

export default connect(null, mapDispatchToProps)(SignUpConfirmation);
