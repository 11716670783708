import constants from '../../_constants/employees.constants';
import service from '../../../services/employees.service';

const getEmployeeById = (id, callback) => {
  return (dispatch) => {
    dispatch(request(id));

    service.getById(id).then(
      (response) => {
        dispatch(success(response.data));

        if (callback) {
          callback(response.data);
        }
      },
    );
  };

  function request(id) {
    return {
      type: constants.EMPLOYEE_GET_REQUEST,
      payload: { id },
    };
  }
  function success(employee) {
    return {
      type: constants.EMPLOYEE_GET_SUCCESS,
      payload: { employee },
    };
  }
};

export default getEmployeeById;
