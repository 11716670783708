import { connect } from 'react-redux';

import subscriptionActionCreators from '_store/_actions/subscription';

import SubscriptionAlerts from './SubscriptionAlerts';

const mapStateToProps = (state) => ({
  subscription: state.subscription.subscription,
  user: state.auth.user,
});

const mapDispatchToProps = {
  onCreateUpdatePaymentSession: subscriptionActionCreators.goToUpdatePaymentSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionAlerts);
