import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Tab from 'react-bootstrap/Tab';
import { IoPrintOutline, IoDownloadOutline } from 'react-icons/io5';
import ReactToPrint from 'react-to-print';
import { DateTime } from 'luxon';

import FORMATTERS from 'helpers/formatters';
import {
  Report,
  ReportPage,
  ReportHeader,
} from '_components/_shared';
import { LoadingOverlay } from '_components/_core';
import { MenuButton } from '_components/_core/Menu/Menu';

import { StyledTable } from './styles';

function Results({
  values,
  isLoading,
  activeCompany,
  efd,
  onDownloadEFD,
}) {
  const reportRef = useRef();
  const {
    company_name,
    document_number,
  } = activeCompany || {};

  const download = (filename, text) => {
    const element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const handleDownload = useCallback(() => {
    const { start_date, end_date } = values;
    const { company_name } = activeCompany || {};

    const competence = DateTime.fromISO(start_date, { zone: 'utc' }).toFormat('MMyyyy');

    onDownloadEFD(start_date, end_date, (data) => {
      const { efd = [] } = data;

      const formatted = `${efd.join('\r\n')}\r\n`;

      download(`EFD Contribuicoes ${competence} - ${company_name}.txt`, formatted);
    });
  }, [onDownloadEFD, values, activeCompany]);

  if (!efd) {
    return null;
  }

  return (
    <Tab.Content className="mt-3 mt-lg-0">
      <div className="d-flex align-items-end">
        <ReactToPrint
          trigger={() => (
            <button
              type="button"
              placement="left"
              className="btn btn-sm btn-icons btn-secondary mr-2 mb-3"
            >
              <IoPrintOutline />
            </button>
          )}
          content={() => reportRef.current}
          documentTitle="Resumo - EFD Contribuições"
        />
      </div>
      <Report ref={reportRef}>
        <LoadingOverlay active={isLoading}>
          <ReportPage>
            <ReportHeader
              name="RESUMO - EFD CONTRIBUIÇÕES"
              companyName={company_name}
              companyDocumentNumber={FORMATTERS.CPF_CNPJ(document_number)}
              startDate={values.start_date}
              endDate={values.end_date}
              currentPage={1}
              totalPages={1}
            />
            <div className="table table-responsive d-flex justify-content-center">
              <StyledTable className="font-weight-normal">
                <thead>
                  <tr>
                    <td />
                    <td className="font-weight-bold ">PIS</td>
                    <td className="font-weight-bold ">COFINS</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-right">Valor Total da Contribuição Apurada</td>
                    <td>{`${FORMATTERS.NUMBER(efd.taxes.pis_pasep_owed)}`}</td>
                    <td>{`${FORMATTERS.NUMBER(efd.taxes.cofins_owed)}`}</td>
                  </tr>
                  <tr>
                    <td className="text-right">(-) Valor Total de Retenções e Outras Deduções</td>
                    <td>{`${FORMATTERS.NUMBER(efd.taxes.pis_pasep_withheld)}`}</td>
                    <td>{`${FORMATTERS.NUMBER(efd.taxes.cofins_withheld)}`}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td className="text-right">(=) Valor da Contribuição Social à Recolher</td>
                    <td>{`${FORMATTERS.NUMBER(efd.taxes.pis_pasep_due)}`}</td>
                    <td>{`${FORMATTERS.NUMBER(efd.taxes.cofins_due)}`}</td>
                  </tr>
                </tfoot>
              </StyledTable>
            </div>
          </ReportPage>
        </LoadingOverlay>
      </Report>
      <MenuButton isLoading={isLoading} onClick={handleDownload}>
        <IoDownloadOutline className="mr-3" />
        Baixar Arquivo
      </MenuButton>
    </Tab.Content>
  );
}

Results.defaultProps = {
  isLoading: false,
  efd: null,
};

Results.propTypes = {
  values: PropTypes.object,
  isLoading: PropTypes.bool,
  onDownloadEFD: PropTypes.func.isRequired,
  activeCompany: PropTypes.object,
  efd: PropTypes.object,
};

export default Results;
