import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { IoCloseOutline } from 'react-icons/io5';
import { TiArrowSortedDown } from 'react-icons/ti';
import { Dropdown } from 'react-bootstrap';

import { Button } from '_components/_core';

import {
  CustomFilterButton,
  FilterLabel,
  FilterMenu,
  FilterDescription,
  FilterValue,
  InputContainer,
} from '../styles';

const FastFilterText = ({
  id,
  label,
  value,
  onChange,
  className,
  description,
  renderDoubleDots,
  renderEmptyText,
  showValue,
  isChanged,
}) => {
  const [open, setOpen] = useState(false);
  const [tempText, setTempText] = useState(value);
  const inputRef = React.useRef(null);

  const handleToggle = useCallback((isOpen, _ev, metadata) => {
    if (metadata.source === 'select') {
      setOpen(true);
      return;
    }

    setOpen(isOpen);
  }, []);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [open]);

  return (
    <Dropdown
      id={id}
      show={open}
      onToggle={handleToggle}
      className={className}
    >
      <CustomFilterButton isChanged={isChanged} variant="link" as={Dropdown.Toggle}>
        <FilterLabel>
          {label}{renderDoubleDots ? ':' : ''}
        </FilterLabel>
        <FilterValue>
          {renderEmptyText && !value && <i className="text-muted">Não informado</i>}
          {showValue && value && `"${value}"`}
          {showValue && value && (
            <IoCloseOutline
              size="1.4em"
              className="text-danger ml-2"
              onClick={(e) => {
                e.stopPropagation();

                setTempText(null);
                onChange(null);
              }}
            />
          )}
        </FilterValue>
        <TiArrowSortedDown size="1.1em" className="ml-1" />
      </CustomFilterButton>
      <FilterMenu>
        {description && (
          <FilterDescription>
            {description}
          </FilterDescription>
        )}
        <InputContainer>
          <Form.Control
            ref={inputRef}
            type="text"
            value={tempText}
            onChange={(e) => setTempText(e.target.value)}
            autoComplete="off"
            placeholder="Digite aqui e pressione enter..."
            autoFocus
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onChange(tempText);

                setOpen(false);
              }
            }}
          />
          <Button
            className="mt-2 mr-2"
            size="sm"
            variant="dark"
            onClick={() => {
              onChange(tempText);

              setOpen(false);
            }}
          >
            Confirmar
          </Button>
          <Button
            className="mt-2 float-right"
            size="sm"
            variant="link"
            onClick={() => {
              setTempText(null);
              onChange(null);

              setOpen(false);
            }}
          >
            Limpar
          </Button>
        </InputContainer>
      </FilterMenu>
    </Dropdown>
  );
};

export default FastFilterText;

FastFilterText.defaultProps = {
  renderDoubleDots: true,
  renderEmptyText: true,
  showValue: true,
  isChanged: false,
};

FastFilterText.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  renderDoubleDots: PropTypes.bool,
  renderEmptyText: PropTypes.bool,
  showValue: PropTypes.bool,
  id: PropTypes.string,
  isChanged: PropTypes.bool,
};
