import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { IoTrash } from 'react-icons/io5';

import { Button } from '_components/_core';

const DeleteEntries = ({
  isLoading,
  selectedItems,
  onEntriesIgnore,
  onComplete,
}) => {
  const handleDeleteEntries = useCallback(() => {
    onEntriesIgnore({
      ids: selectedItems,
    }, () => {
      if (onComplete) {
        onComplete({
          clearSelectedItems: true,
        });
      }
    });
  }, [selectedItems, onEntriesIgnore, onComplete]);

  return (
    <Button
      className="mr-2 btn-sm"
      variant="danger"
      onClick={handleDeleteEntries}
      disabled={isLoading}
      isLoading={isLoading}
      icon={<IoTrash />}
    >
      {`Ignorar ${selectedItems.length} ${pluralize('lançamento', selectedItems.length)}`}
    </Button>
  );
};

export default DeleteEntries;

DeleteEntries.defaultProps = {
  isLoading: false,
  onComplete: null,
};

DeleteEntries.propTypes = {
  isLoading: PropTypes.func,
  selectedItems: PropTypes.array.isRequired,
  onEntriesIgnore: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
};
