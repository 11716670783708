import constants from '../../_constants/invoices.constants';
import service from '../../../services/invoices.service';

import getIssuedInvoices from './getIssuedInvoices';
import alertActionCreators from '../alert.actions';

const confirmInvoiceIssuing = (params, callback) => {
  return (dispatch) => {
    dispatch(request());

    service.confirmInvoiceIssuing(params).then(
      (response) => {
        const { data } = response;

        dispatch(success(data));
        dispatch(getIssuedInvoices());
        dispatch(alertActionCreators.success('Nota Fiscal inserida com sucesso. Confirme a emissão na aba Lista de Emissões.'));

        if (callback) {
          callback();
        }
      },
    ).catch((err) => {
      dispatch(failure(err));
    });
  };

  function request() {
    return {
      type: constants.CONFIRM_INVOICE_ISSUING_REQUEST,
      payload: { },
    };
  }
  function success(data) {
    return {
      type: constants.CONFIRM_INVOICE_ISSUING_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.CONFIRM_INVOICE_ISSUING_FAILURE,
      payload: error,
    };
  }
};

export default confirmInvoiceIssuing;
