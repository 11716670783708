import constants from '../../_constants/dre.constants';

const eraseDRE = () => {
  return (dispatch) => {
    dispatch(request());

    dispatch(success());
  };

  function request() {
    return {
      type: constants.DRE_ERASE_REQUEST,
    };
  }
  function success() {
    return {
      type: constants.DRE_ERASE_SUCCESS,
    };
  }
};

export default eraseDRE;
