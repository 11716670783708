import constants from '../../_constants/company-transactions.constants';

const setSelectedDate = (date) => {
  return async (dispatch) => {
    dispatch(request(date));
  };

  function request(date) {
    return {
      type: constants.SET_SELECTED_DATE_REQUEST,
      payload: {
        date,
      },
    };
  }
};

export default setSelectedDate;
