import HttpService from './http.service';

export default {
  getIrrfTables,
  getInssTables,
  getChildBenefitTables,
  getMinimumWageTables,
  getManuallyInsertedPayroll,
  saveManualPayroll,
  generatePayrollEntries,
  getPayrolls,
  generatePayroll,
  deletePayroll,
  sendSalaryReceiptMail,
};

async function getIrrfTables(params) {
  return HttpService.get('/tables/irrf', params);
}

async function getInssTables(params) {
  return HttpService.get('/tables/inss', params);
}

async function getChildBenefitTables(params) {
  return HttpService.get('/tables/child-benefit', params);
}

async function getMinimumWageTables(params) {
  return HttpService.get('/tables/minimum-wage', params);
}

async function getManuallyInsertedPayroll(company_id, year) {
  return HttpService.get(`/company/${company_id}/manual-payroll?year=${year}`);
}

async function saveManualPayroll(params) {
  return HttpService.post(`/company/${params.company_id}/manual-payroll`, params);
}

async function generatePayrollEntries(params) {
  return HttpService.post('/payroll-entries', params);
}

async function generatePayroll(params) {
  return HttpService.post('/payroll', params);
}

async function deletePayroll(params) {
  return HttpService.delete('/payroll', params);
}

async function getPayrolls(params) {
  return HttpService.get('/payroll', params);
}

async function sendSalaryReceiptMail(_id, recipients) {
  const body = {
    recipients,
  };
  return HttpService.post(`/salary-receipt-mail/${_id}`, body);
}
