import styled from 'styled-components';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { IoLogOutOutline, IoReaderOutline, IoPersonCircleSharp } from 'react-icons/io5';

export const StyledOverlayTrigger = styled(OverlayTrigger)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: calc(1rem + 12.5px) !important;
`;

export const StyledWrapper = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  width: auto;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }

  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
`;

export const AvatarWrapper = styled.div`
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%; 
`;

export const StyledAvatar = styled.img`
  width: 32px;

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
`;

export const StyledPopover = styled(Popover)`
  margin-top: 0;
  width: 180px;
  border-radius: 0.25rem;
  box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
  animation: fadeIn 0.08s ease-in-out;

  &.popover, .popover-body {
    background-color: #FFFFFF !important;
    border-radius: 8px;
  }

  &.popover .arrow::before, .popover .arrow::after {
    display: none;
  }

  &.bs-popover-bottom .arrow::after {
    display: none;
  }
`;

export const MenuEntry = styled.div`

  &:hover {
    cursor: pointer;
    background-color: #f1f5f9;
  }
`;

export const StyledUserName = styled.h1`
  font-size: 13px;
  font-weight: 600;
  padding-left: 1rem;
  margin-bottom: 0.1rem;
`;

export const StyledUserRole = styled.h2`
  font-size: 12px;
  font-weight: 400;
  padding-left: 1rem;
  margin-bottom: 0;
`;

export const StyledMenuText = styled.h1`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  padding-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledIoPersonCircleSharp = styled(IoPersonCircleSharp)`
  color: var(--title-color);
`;

export const StyledIoReaderOutline = styled(IoReaderOutline)`
  color: var(--title-color);
`;

export const StyledIoLogOutOutline = styled(IoLogOutOutline)`
  color: var(--title-color);
`;
