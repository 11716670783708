import styled from 'styled-components';

export const StyledTable = styled.table`  
  &&& {
    thead tr th {
      height: 32px;
      line-height: 32px;
      vertical-align: middle;
      text-align: left;
      padding: 0 8px 0 9px !important;

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #d5dcec;
      }
    }

    tbody tr td {
      height: 26px;
      line-height: 26px;
      padding: 9px !important;
      vertical-align: middle;
      border-top: 1px solid #D4D7DC;
      cursor: pointer;
      font-weight: 400;
      cursor: default;
    }
  }
`;
