import { connect } from 'react-redux';

import automationsActionCreators from '_store/_actions/automations';
import companyActionCreators from '_store/_actions/company';

import SearchInvoicesConfiguration from './SearchInvoicesConfiguration';

const mapStateToProps = (state) => ({
  companies: state.company.companiesForSelect,
  automationSettings: state.automations.automationSettings,
  isLoading: state.automations.isLoading,
});

const mapDispatchToProps = {
  onFetchAutomationSettings: automationsActionCreators.fetchAutomationSettings,
  onAddAutomationSettings: automationsActionCreators.addAutomationSettings,
  onUpdateAutomationSettings: automationsActionCreators.updateAutomationSettings,
  onDeleteAutomationSettings: automationsActionCreators.deleteAutomationSettings,
  onFetchCompaniesForSelect: companyActionCreators.fetchCompaniesForSelect,
  onExecuteAutomation: automationsActionCreators.executeAutomation,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchInvoicesConfiguration);
