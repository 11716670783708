import parseBankStatements from './parseBankStatements';
import uploadBankStatements from './uploadBankStatements';
import fetchBankStatementEntries from './fetchBankStatementEntries';
import ignoreEntries from './ignoreEntries';
import updateManyBankStatementEntries from './updateManyBankStatementEntries';

export default {
  parseBankStatements,
  uploadBankStatements,
  fetchBankStatementEntries,
  ignoreEntries,
  updateManyBankStatementEntries,
};
