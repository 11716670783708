import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';

import {
  Button,
  FormTextField,
  FormSelectField,
} from '_components/_core';
import { ACCOUNT_TYPE_OPTIONS, ACCOUNT_NATURE_OPTIONS } from 'helpers/constants';

import { AccountSchema } from './utilities';
import {
  StyledModal,
  ModalFooter,
} from './styles';

const AccountFormModal = ({
  account,
  isVisible,
  isLoading,
  onModalToggle,
  onAddAccount,
  onFetchAccounts,
  onUpdateAccount,
  userInputValue,
}) => {
  const handleAddEditAccount = useCallback((values, { resetForm }) => {
    if (values.id) {
      onUpdateAccount(values.id, values, () => {
        resetForm();
        onModalToggle();
        onFetchAccounts();
      });
    } else {
      onAddAccount(values, () => {
        onModalToggle();
        resetForm();
        onFetchAccounts();
      });
    }
  }, [onAddAccount, onModalToggle, onFetchAccounts, onUpdateAccount]);

  const renderModalFooter = useCallback((handleSubmit, isValid) => (
    <ModalFooter>
      <Button variant="secondary" onClick={onModalToggle}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={!isValid || isLoading}
      >
        Salvar
      </Button>
    </ModalFooter>
  ), [onModalToggle, isLoading]);

  if (!isVisible) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        classifier: account ? account.classifier : '',
        description: account ? account.description : userInputValue,
        account_type: account ? account.account_type : '',
        account_nature: account ? account.account_nature : '',
        id: account ? account.id : null,
      }}
      validationSchema={AccountSchema}
      onSubmit={handleAddEditAccount}
      enableReinitialize
    >
      {({
        handleSubmit,
        isValid,
      }) => (
        <>
          <StyledModal
            title={account ? 'Editar Conta' : 'Cadastrar Conta'}
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit, isValid)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>Classificador</Form.Label>
                  <FormTextField
                    name="classifier"
                    placeholder="Classificador"
                  />
                </Form.Group>
                <Form.Group as={Col} md="12">
                  <Form.Label>Descrição</Form.Label>
                  <FormTextField
                    name="description"
                    placeholder="Descrição"
                  />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Tipo de Conta</Form.Label>
                  <FormSelectField
                    name="account_type"
                    options={ACCOUNT_TYPE_OPTIONS}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Natureza</Form.Label>
                  <FormSelectField
                    name="account_nature"
                    options={ACCOUNT_NATURE_OPTIONS}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default AccountFormModal;

AccountFormModal.defaultProps = {
  account: null,
  userInputValue: '',
};

AccountFormModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onAddAccount: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onModalToggle: PropTypes.func.isRequired,
  onFetchAccounts: PropTypes.func.isRequired,
  account: PropTypes.object,
  onUpdateAccount: PropTypes.func.isRequired,
  userInputValue: PropTypes.string,
};
