import FORMATTERS from 'helpers/formatters';
import React from 'react';
import PropTypes from 'prop-types';

import { ReportHeader } from '_components/_shared';

function PrintHeader({
  title,
  description,
  activeCompany,
  formattedPeriod,
  accounts,
  costsCenter,
  account_ids,
  cost_center_ids,
}) {
  const {
    company_name,
    document_number,
    company_avatar_url,
  } = activeCompany || {};

  return (
    <ReportHeader
      name={title}
      description={description}
      formattedPeriod={formattedPeriod}
      companyName={company_name}
      companyDocumentNumber={FORMATTERS.CPF_CNPJ(document_number)}
      currentPage={1}
      totalPages={1}
      className="mt-3 ml-3 mr-3"
      avatar_url={company_avatar_url}
      accounts={accounts}
      costsCenter={costsCenter}
      account_ids={account_ids}
      cost_center_ids={cost_center_ids}
    />
  );
}

PrintHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  activeCompany: PropTypes.shape({
    company_name: PropTypes.string,
    document_number: PropTypes.string,
    company_avatar_url: PropTypes.string,
  }).isRequired,
  formattedPeriod: PropTypes.string.isRequired,
  accounts: PropTypes.array,
  costsCenter: PropTypes.array,
  account_ids: PropTypes.array,
  cost_center_ids: PropTypes.array,
};

export default PrintHeader;
