import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import Container from 'react-bootstrap/Container';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { IoArrowBackOutline, IoArrowForwardOutline } from 'react-icons/io5';
import { isEmpty } from 'lodash';

import {
  PageHeader,
  Button,
  EmptyMessage,
} from '_components/_core';
import {
  AccountingIntegrationModal,
} from '_components/_shared';
import {
  LucroPresumido,
  SimplesNacional,
  Mei,
  CalculateTaxesModal,
  GenerateDarfsModal,
} from './components';

function Taxes({
  activeCompany,
  taxes,
  darfs,
  onFetchTaxes,
  onFetchDarfs,
  onClearTaxes,
  onCalculateTaxes,
  onDeleteDarf,
  onDeleteMultipleDarfs,
  isLoading,
}) {
  const [selectedDate, setSelectedDate] = useState(() => DateTime.now().minus({ months: 1 }));

  const handleYearIncreased = useCallback(() => {
    setSelectedDate(selectedDate.plus({ year: 1 }));
  }, [selectedDate]);

  const handleYearDecreased = useCallback(() => {
    setSelectedDate(selectedDate.minus({ year: 1 }));
  }, [selectedDate]);

  useEffect(() => {
    onClearTaxes();
    onFetchTaxes({
      year: selectedDate.year,
    });
  }, [selectedDate, activeCompany, onClearTaxes, onFetchTaxes]);

  const tax_regime = useMemo(() => {
    const [tax_regime] = taxes.map((tax) => tax.tax_regime_type);

    return tax_regime;
  }, [taxes]);

  const renderContent = useCallback(() => {
    if (isEmpty(taxes)) {
      return (
        <EmptyMessage
          title="Nenhum registro encontrado"
          description={`Não foram encontrados tributos apurados no ano de ${selectedDate.year}.`}
          action={<CalculateTaxesModal selectedDate={selectedDate} />}
        />
      );
    }

    const [tax_regime] = taxes.map((tax) => tax.tax_regime_type);

    const commonProps = {
      selectedDate,
      activeCompany,
      taxes,
      darfs,
      isLoading,
      onCalculateTaxes,
      onFetchTaxes,
      onFetchDarfs,
      onDeleteDarf,
      onDeleteMultipleDarfs,
    };

    if (tax_regime === 'SIMPLES_NACIONAL') {
      return <SimplesNacional {...commonProps} />;
    }

    if (tax_regime === 'LUCRO_PRESUMIDO') {
      return <LucroPresumido {...commonProps} />;
    }

    if (tax_regime === 'MEI') {
      return <Mei {...commonProps} />;
    }

    return (
      <EmptyMessage
        title="Atenção!"
        description={`A tributação ${tax_regime.tax_regime_type} ainda não é suportada pelo Contabills.`}
      />
    );
  }, [
    isLoading,
    selectedDate,
    activeCompany,
    taxes,
    darfs,
    onFetchTaxes,
    onFetchDarfs,
    onCalculateTaxes,
    onDeleteDarf,
    onDeleteMultipleDarfs,
  ]);

  const pageTitle = useMemo(() => {
    switch (process.env.REACT_APP_CONTABILLS_APP) {
      case 'contabills-empresas':
        return 'Contribuição Mensal - MEI';
      default:
        return 'Impostos';
    }
  }, []);

  const renderActionContent = useCallback(() => {
    switch (process.env.REACT_APP_CONTABILLS_APP) {
      case 'contabills-empresas':
        return null;
      default:
        return (
          <div className="d-flex justify-content-between">
            <div>
              <AccountingIntegrationModal type="taxes" />
            </div>
            <div>
              <CalculateTaxesModal selectedDate={selectedDate} />
              {tax_regime === 'LUCRO_PRESUMIDO' && (
                <GenerateDarfsModal
                  selectedDate={selectedDate}
                  taxes={taxes}
                />
              )}
            </div>
          </div>
        );
    }
  }, [selectedDate, tax_regime, taxes]);

  return (
    <Container fluid className="content-wrapper pb-5">
      <PageHeader
        title={pageTitle}
        description="Visão geral de todos os impostos da empresa no período selecionado"
        variant="small"
        sideContent={(
          <div className="float-right">
            <ButtonGroup className="mr-2">
              <Button variant="secondary" onClick={handleYearDecreased}>
                <IoArrowBackOutline />
              </Button>
              <Button variant="secondary">
                <b>{selectedDate.year}</b>
              </Button>
              <Button variant="secondary" onClick={handleYearIncreased}>
                <IoArrowForwardOutline />
              </Button>
            </ButtonGroup>
          </div>
        )}
        actionContent={renderActionContent()}
      />
      {renderContent()}
    </Container>
  );
}

Taxes.defaultProps = {
  taxes: [],
  darfs: [],
  activeCompany: null,
  isLoading: false,
};

Taxes.propTypes = {
  activeCompany: PropTypes.object,
  taxes: PropTypes.array,
  darfs: PropTypes.array,
  onFetchTaxes: PropTypes.func.isRequired,
  onCalculateTaxes: PropTypes.func.isRequired,
  onClearTaxes: PropTypes.func.isRequired,
  onFetchDarfs: PropTypes.func.isRequired,
  onDeleteDarf: PropTypes.func.isRequired,
  onDeleteMultipleDarfs: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default Taxes;
