import { connect } from 'react-redux';

import AccountSelect from './AccountSelect';

const mapStateToProps = (state, { account_type = 'accounting' }) => {
  let selector = null;

  if (account_type === 'accounting') {
    selector = state.accounts.selectAccounts;
  } else if (account_type === 'transaction_account') {
    selector = state.companyTransactions.accountsForSelect;
  }

  return {
    accounts: selector,
  };
};

export default connect(mapStateToProps, null)(AccountSelect);
