export const COMPANY_OPTIONS = [
  {
    value: 'INDUSTRIA',
    label: 'É uma indústria',
  },
  {
    value: 'COMERCIO',
    label: 'É um comércio',
  },
  {
    value: 'ONG',
    label: 'É uma ONG',
  },
  {
    value: 'SERVICOS',
    label: 'É uma prestadora de serviços',
  },
  {
    value: 'CIVIL',
    label: 'É do ramo da construção civil',
  },
  {
    value: 'GOV',
    label: 'É um orgão governamental',
  },
];

export const EMPLOYEES_NUMBER = [
  {
    value: '1',
    label: 'de 1 a 5',
  },
  {
    value: '2',
    label: 'de 6 a 10',
  },
  {
    value: '3',
    label: 'de 11 a 30',
  },
  {
    value: '4',
    label: 'acima de 30',
  },
];

export const SIMPLES_NACIONAL_NFE_OPTION = [
  {
    value: 'SIM',
    label: 'Sim',
  },
  {
    value: 'NAO',
    label: 'Não',
  },
  {
    value: 'EMPTY',
    label: 'Não sei o que é isso',
  },
];
