import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import isEmpty from 'lodash/isEmpty';
import { IoCloseCircle } from 'react-icons/io5';
import { FaSort } from 'react-icons/fa';
import { Formik } from 'formik';

import {
  ActionSheet,
  Button,
  FormButtonGroupField,
  FormGroup,
} from '_components/_core';

import { FIELD_OPTIONS_MOBILE, ORDER_OPTIONS_MOBILE } from '.';

function TransactionSorting({ sorting, onSortTransactions }) {
  const [actionSheetOpen, setActionSheetOpen] = useState(false);

  const handleClose = useCallback(() => {
    setActionSheetOpen(false);
  }, []);

  const handleSubmitSort = useCallback((values) => {
    const { field, order } = values;

    onSortTransactions({
      field,
      order,
    });

    setActionSheetOpen(false);
  }, [onSortTransactions]);

  return (
    <>
      <Button
        className="d-flex justify-content-center align-items-center"
        onClick={() => setActionSheetOpen(true)}
        variant="default"
        size="sm"
      >
        <FaSort size="1.2em" />
      </Button>
      <ActionSheet
        isOpen={actionSheetOpen}
        onToggle={() => setActionSheetOpen(!actionSheetOpen)}
        detent="content-height"
        prefersReducedMotion
      >
        <div>
          <div className="ml-3 mr-3 d-flex justify-content-between align-items-center">
            <h5 className="m-0">
              Ordenar
            </h5>
            <IoCloseCircle className="text-muted" size="2em" onClick={handleClose} />
          </div>
          <div className="p-3 pb-0">
            <Formik
              initialValues={{
                field: !isEmpty(sorting) ? sorting.field : 'event_date',
                order: !isEmpty(sorting) ? sorting.order : 'desc',
              }}
              onSubmit={handleSubmitSort}
              enableReinitialize
            >
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Row>
                    <FormGroup as={Col} xs={12} label="Campo:">
                      <FormButtonGroupField
                        label="Campo"
                        name="field"
                        options={FIELD_OPTIONS_MOBILE}
                        columns={2}
                      />
                    </FormGroup>
                    <FormGroup as={Col} xs={12} label="Ordem:">
                      <FormButtonGroupField
                        label="Ordem"
                        name="order"
                        options={ORDER_OPTIONS_MOBILE}
                        columns={1}
                      />
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup as={Col} xs={12}>
                      <Button variant="dark" className="w-100" onClick={handleSubmit}>
                        Ordenar
                      </Button>
                    </FormGroup>
                  </Form.Row>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </ActionSheet>
    </>
  );
}

TransactionSorting.defaultProps = {
  sorting: null,
};

TransactionSorting.propTypes = {
  sorting: PropTypes.object,
  onSortTransactions: PropTypes.func.isRequired,
};

export default TransactionSorting;
