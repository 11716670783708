import styled, { css } from 'styled-components';

import Button from '_components/_core/Button';
import Modal from '_components/_core/Modal';

export const Plans = styled.ul`
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin-top: 8px;

  @media (max-width: 768px) {
    margin-top: 0px;
    display: flex; 
  }
`;

export const Plan = styled.li`
  display: flex;
  justify-content: space-between;

  padding: 18px 14px;
  border-radius: 6px;
  box-shadow:0 1px 5px -3px rgb(0 0 0 / 75%);
  cursor: pointer;
  border: 2px solid rgba(70, 77, 228, 0.05);
  transition: all 200ms ease;
  transition-property: margin-top, border;
  background-color: #fff;

  div:last-child {
    text-align: right;
  }

  &:not(:first-child) {
    margin-top: 16px;
  }
  
  ${({ active }) => active && css`    
    margin-top: 0px;
    border: 2px solid #00ab6f;
    
  `}

  hr {
    width: 100%;
    border-top: 2px solid rgba(70, 77, 228, 0.05);
    border-radius: 6px;
  }

  @media (max-width: 768px) {
    width: 100%;

    ${({ active }) => active && css`    
      margin-top: 0px;
      border: 2px solid #00ab6f;
    `}
  }
`;

export const PlanName = styled.h4`
  font-size: 1em;
  color: rgba(70, 77, 228, 1);
  color: #00ab6f;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PlanPrice = styled.h5`
  font-size: 2em;
  margin-bottom: 0px;

  small {
    font-size: 0.5em;
    font-weight: 300;
  }
`;

export const PlanFeatures = styled.ul`
  padding: 0;
  list-style: none;
  font-size: 1.1em;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      margin-right: 8px;
      color: #FF6F59;
    }
  }
`;

export const StyledModal = styled(Modal)`
  padding: 0 !important;

  div.modal-dialog {
    overflow-y: initial !important;

    @media (min-width: 576px) {
      max-width: 60%;
    }
    @media (min-width: 1800px) {
      max-width: 40%;
    }
  }

  div.modal-body {
    padding: 24px !important;
    max-height: 60vh;
    overflow-y: auto;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchButton = styled(Button)`
  margin-top: 26px;
  margin-left: 15px;
`;

export const StyledHr = styled.hr`
  margin-bottom: 2rem;
`;

export const StyledCurrentPlan = styled.div`
  /* position: absolute;
  top: 80%; */
`;
